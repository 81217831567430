import * as React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useFragment_experimental, gql } from '@apollo/client'
import Switch from '@mui/material/Switch'
import Stack from '@mui/material/Stack'
import Box from '@mui/material//Box'
import FormControl from '@mui/material/FormControl'

import { InputLabel } from '@jeeves/new-components'

const EnableBindingToggle_BindingFragment = gql`
  fragment EnableBindingToggle_BindingFragment on Binding {
    enabled
  }
`

const EnableBindingToggle = ({ binding }) => {
  const {
    register,
    control,
    formState: { errors },
    watch,
  } = useFormContext()
  const { data } = useFragment_experimental({
    fragment: EnableBindingToggle_BindingFragment,
    fragmentName: 'EnableBindingToggle_BindingFragment',
    from: binding,
  })

  return (
    <FormControl variant="standard" fullWidth>
      <Box
        sx={{
          borderRadius: theme => theme.radii.base,
          border: 1,
          borderColor: 'cyralColors.grey.200',
          padding: 1,
        }}
      >
        <Stack direction="row" alignItems="center">
          <Controller
            control={control}
            name="enabled"
            render={({ field: { onChange, onBlur, value, name, ref } }) => {
              return (
                <Switch
                  name={name}
                  onChange={onChange}
                  checked={value}
                  id="enable-binding-switch"
                />
              )
            }}
          />
          <InputLabel id="enable-binding-label" htmlFor="enable-binding-switch">
            Enable Binding
          </InputLabel>
        </Stack>
      </Box>
    </FormControl>
  )
}

EnableBindingToggle.fragments = {
  EnableBindingToggle_BindingFragment,
}

export default EnableBindingToggle
