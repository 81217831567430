import React, { useLayoutEffect, useState, useRef } from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import SettingsIcon from '@mui/icons-material/Settings'
import IconButton from '@mui/material/IconButton'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

import { Tag } from '@jeeves/new-components'

export const DataLabelHeader = ({ labelExpanded, setLabelExpanded, mapping }) => {
  return (
    <Box sx={{ px: 4, pr: 2.2, py: 2, border: 1, borderColor: 'cyralColors.grey.200' }}>
      <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
        <Stack direction="row" spacing={2.5} sx={{ mb: 1 }}>
          <Stack direction="row">
            <Typography variant="h3">{mapping.node.name}</Typography>
          </Stack>
          <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
            <Tag size="md" color="white">
              {mapping.node.type}
            </Tag>
            {mapping.node.tags.map(tag => (
              // TODO: Need to add valid key to <Tag />
              <Tag size="md">{tag}</Tag>
            ))}
          </Stack>
        </Stack>

        <Typography variant="h5" sx={{ color: 'cyralColors.grey.400', textTransform: 'uppercase' }}>
          {mapping.datamapMappings.length}{' '}
          {mapping.datamapMappings.length === 1 ? `mapping` : `mappings`}
        </Typography>
      </Stack>

      <Stack sx={{ mt: 2 }} direction="row" sx={{ justifyContent: 'space-between' }}>
        <Typography>{mapping.node.description}</Typography>
        <IconButton
          sx={{ marginRight: '1px' }}
          aria-label="expand row"
          size="small"
          onClick={() => setLabelExpanded(!labelExpanded)}
        >
          {labelExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </Stack>
    </Box>
  )
}
