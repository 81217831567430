import { useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'

import {
  Box,
  Divider,
  FormControl,
  Stack,
  RadioGroup,
  FormHelperText,
  FormControlLabel,
  Radio,
  Typography,
} from '@mui/material'
import { useRepositoryDetailContext } from '@jeeves/pages/RepositoryDetail/contexts/RepositoryDetailContext'

import { InlineCode, Input, InputLabel } from '@jeeves/new-components'

import InputTags from '@jeeves/pages/RepositoryDetail/Tabs/Policies/views/AddPolicy/components/InputTags'
import Footer from '@jeeves/pages/RepositoryDetail/Tabs/Policies/views/AddPolicy/components/Footer'

const StepTwo = ({ readLimitInfo, nextStep, prevStep, setReadLimitInfo }) => {
  const { repoId, repoName } = useRepositoryDetailContext()
  const {
    setValue,
    handleSubmit,
    watch,
    control,
    clearErrors,
    register,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      applyTo: readLimitInfo.parameters.dataLabels ? 'specificDataLabels' : 'entireRepository',
      action: readLimitInfo.parameters.action,
      dataLabels: readLimitInfo.parameters.dataLabels,
      rowLimit: readLimitInfo.parameters.rowLimit,
    },
  })

  const onSubmit = data => {
    setReadLimitInfo(info => ({
      ...info,
      parameters: {
        ...info.parameters,
        rowLimit: data.rowLimit,
        dataLabels: data.dataLabels,
        action: data.action,
      },
    }))
  }

  const onDeleteDataLabels = updatedTags => {
    setValue('dataLabels', updatedTags, { shouldDirty: true })
  }

  const onAddDataLabels = updatedTags => {
    setValue('dataLabels', updatedTags, { shouldValidate: true, shouldDirty: true })
  }

  useEffect(() => {
    if (isSubmitSuccessful) {
      nextStep()
    }
  }, [isSubmitSuccessful, nextStep])

  const { dataLabels = [], applyTo } = watch()

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4}>
        <Stack spacing={4} sx={{ maxWidth: '32rem' }}>
          <FormControl variant="standard" error={Boolean(errors?.rowLimit)}>
            <InputLabel htmlFor="rowLimit" required>
              Maximum number of rows that can be read per query
            </InputLabel>
            <Input
              id="rowLimit"
              placeholder="e.g. 20"
              type="number"
              inputProps={{
                ...register('rowLimit', {
                  required:
                    'Please define the maximum number of rows that can be modified per query',
                  min: {
                    value: 1,
                    message: 'Please insert a number greater than zero',
                  },
                }),
              }}
            />
            <FormHelperText
              sx={{
                typography: 'body2',
              }}
              error={!!errors?.rowLimit}
            >
              {errors?.rowLimit?.message}
            </FormHelperText>
          </FormControl>

          <FormControl variant="standard" sx={{ flex: 1 }}>
            <InputLabel component="legend">Apply this policy to:</InputLabel>
            <Controller
              name="applyTo"
              control={control}
              render={({ field }) => (
                <RadioGroup
                  row
                  aria-label="apply-to"
                  {...field}
                  onChange={event => {
                    setValue('applyTo', event.target.value)
                    setValue('dataLabels', event.target.value === 'entireRepository' ? null : [])
                    clearErrors('dataLabels')
                  }}
                >
                  <Stack sx={{ flexGrow: 1 }}>
                    <FormControlLabel
                      value="entireRepository"
                      control={<Radio size="small" disableRipple />}
                      label={
                        <Typography variant="h6" sx={{ color: 'text.primary' }}>
                          The entire <InlineCode>{repoName}</InlineCode> repository
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      value="specificDataLabels"
                      control={<Radio size="small" disableRipple />}
                      label="Specific data labels"
                      slotProps={{
                        typography: {
                          variant: 'h6',
                          sx: {
                            color: 'text.primary',
                          },
                        },
                      }}
                    />

                    {applyTo === 'specificDataLabels' && (
                      <Stack sx={{ paddingLeft: 3 }}>
                        <FormControl variant="standard" error={Boolean(errors?.dataLabels)}>
                          <Controller
                            name="dataLabels"
                            control={control}
                            rules={{
                              validate: dataLabels => {
                                return dataLabels.length > 0 || 'Please enter at least one label'
                              },
                            }}
                            render={() => (
                              <InputTags
                                required
                                tags={dataLabels}
                                isSubmitting={isSubmitting}
                                onDelete={onDeleteDataLabels}
                                onAdd={onAddDataLabels}
                              />
                            )}
                          />
                          <FormHelperText sx={{ typography: 'body2' }} error={!!errors?.dataLabels}>
                            {errors?.dataLabels?.message}
                          </FormHelperText>
                        </FormControl>
                      </Stack>
                    )}
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>

          <FormControl variant="standard" sx={{ flex: 1 }}>
            <InputLabel component="legend">Policy Actions</InputLabel>
            <Controller
              name="action"
              control={control}
              render={({ field }) => (
                <RadioGroup row aria-label="action" {...field}>
                  <Stack sx={{ flexGrow: 1 }}>
                    <FormControlLabel
                      value="alert"
                      control={<Radio size="small" disableRipple />}
                      label="Alert"
                      slotProps={{
                        typography: {
                          variant: 'h6',
                          sx: {
                            color: 'text.primary',
                          },
                        },
                      }}
                    />
                    <FormControlLabel
                      value="alertAndBlock"
                      control={<Radio size="small" disableRipple />}
                      label="Enforce"
                      slotProps={{
                        typography: {
                          variant: 'h6',
                          sx: {
                            color: 'text.primary',
                          },
                        },
                      }}
                    />
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Stack>

        <Divider />

        <Footer repoId={repoId} prevStep={prevStep} policyId={readLimitInfo.id} />
      </Stack>
    </Box>
  )
}

export default StepTwo
