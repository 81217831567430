import { useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import {
  Box,
  Divider,
  FormControl,
  Stack,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
} from '@mui/material'

import { useRepositoryDetailContext } from '@jeeves/pages/RepositoryDetail/contexts/RepositoryDetailContext'
import { InputLabel } from '@jeeves/new-components'
import Footer from '@jeeves/pages/RepositoryDetail/Tabs/Policies/views/AddPolicy/components/Footer'
import DatabaseAccountCustomOption from '../../../../DatabaseAccountCustomOption'
import IdentitiesCustomOption from '../../../../IdentitiesCustomOption'

const StepThree = ({ policyInfo, nextStep, prevStep, setPolicyInfo }) => {
  const { repoId } = useRepositoryDetailContext()
  const {
    setValue,
    handleSubmit,
    watch,
    control,
    clearErrors,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      applyTo: policyInfo?.parameters?.identitiesAppliedTo?.modifier ?? 'ALL',
      specificIdentities: policyInfo?.parameters?.identitiesAppliedTo?.identities ?? [],
      applyWhen: policyInfo?.parameters?.databaseAccountsAppliedTo?.modifier ?? 'ALL',
      databaseAccounts: policyInfo?.parameters?.databaseAccountsAppliedTo?.databaseAccounts ?? [],
    },
  })

  const onSubmit = data => {
    const { applyWhen, applyTo, specificIdentities, databaseAccounts } = data

    const shouldSetDatabaseAccounts = applyWhen === 'ONLY' || applyWhen === 'ALL_EXCEPT'
    const shouldSetSpecificIdentities = applyTo === 'ONLY' || applyTo === 'ALL_EXCEPT'

    setPolicyInfo(info => {
      return {
        ...info,
        parameters: {
          ...info.parameters,
          databaseAccountsAppliedTo: {
            modifier: data.applyWhen,
            ...(shouldSetDatabaseAccounts && { databaseAccounts: databaseAccounts }),
          },
          identitiesAppliedTo: {
            modifier: data.applyTo,
            ...(shouldSetSpecificIdentities && {
              identities: specificIdentities,
            }),
          },
        },
      }
    })
  }

  const onDeleteDatabaseAccounts = updatedTags => {
    setValue('databaseAccounts', updatedTags, { shouldDirty: true })
  }

  const onAddDatabaseAccounts = updatedTags => {
    setValue('databaseAccounts', updatedTags, { shouldValidate: true, shouldDirty: true })
  }

  const onDeleteSpecificIdentities = updatedTags => {
    setValue('specificIdentities', updatedTags, { shouldDirty: true })
  }

  const onAddSpecificIdentities = updatedTags => {
    setValue('specificIdentities', updatedTags, { shouldValidate: true, shouldDirty: true })
  }

  useEffect(() => {
    if (isSubmitSuccessful) {
      nextStep()
    }
  }, [isSubmitSuccessful, nextStep])

  const { specificIdentities = [], databaseAccounts = [], applyWhen, applyTo } = watch()

  const highlightLabel = ({ label, highlight }) => (
    <>
      {label}{' '}
      <Typography
        variant="h6"
        component="span"
        sx={{
          color: 'primary.main',
          textTransform: 'uppercase',
        }}
      >
        {highlight}
      </Typography>
    </>
  )

  const identitiesOptions = [
    { value: 'ONLY', label: 'Specific identities' },
    {
      value: 'ALL_EXCEPT',
      label: highlightLabel({ label: 'All identities,', highlight: 'except' }),
    },
  ]

  const databaseAccountsOptions = [
    { value: 'ONLY', label: 'Specific database accounts' },
    {
      value: 'ALL_EXCEPT',
      label: highlightLabel({ label: 'Any database accounts,', highlight: 'except' }),
    },
  ]

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4}>
        <Stack spacing={4} sx={{ maxWidth: '32rem' }}>
          <FormControl variant="standard" sx={{ flex: 1 }}>
            <InputLabel component="legend">Apply this policy to</InputLabel>
            <Controller
              name="applyTo"
              control={control}
              render={({ field }) => (
                <RadioGroup
                  row
                  aria-label="apply-to"
                  {...field}
                  onChange={event => {
                    setValue('applyTo', event.target.value)
                    setValue('specificIdentities', [])
                    clearErrors('specificIdentities')
                  }}
                >
                  <Stack sx={{ flexGrow: 1 }}>
                    <FormControlLabel
                      value="ALL"
                      control={<Radio size="small" disableRipple />}
                      label="All identities"
                      slotProps={{
                        typography: {
                          variant: 'h6',
                        },
                      }}
                    />

                    {identitiesOptions.map(option => (
                      <IdentitiesCustomOption
                        {...option}
                        key={option.value}
                        valueKey="specificIdentities"
                        isSubmitting={isSubmitting}
                        tags={specificIdentities}
                        isSelected={applyTo === option.value}
                        control={control}
                        error={errors?.specificIdentities}
                        onDeleteSpecificIdentities={onDeleteSpecificIdentities}
                        onAddSpecificIdentities={onAddSpecificIdentities}
                      />
                    ))}
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>

          <FormControl variant="standard" sx={{ flex: 1 }}>
            <InputLabel component="legend">Apply this policy when using</InputLabel>
            <Controller
              name="applyWhen"
              control={control}
              render={({ field }) => (
                <RadioGroup
                  row
                  aria-label="apply-when"
                  {...field}
                  onChange={event => {
                    setValue('applyWhen', event.target.value)
                    setValue('databaseAccounts', [])
                    clearErrors('databaseAccounts')
                  }}
                >
                  <Stack sx={{ flexGrow: 1 }}>
                    <FormControlLabel
                      value="ALL"
                      control={<Radio size="small" disableRipple />}
                      label={<Typography variant="h6">Any database accounts</Typography>}
                    />

                    {databaseAccountsOptions.map(option => (
                      <DatabaseAccountCustomOption
                        {...option}
                        key={option.value}
                        isSubmitting={isSubmitting}
                        valueKey="databaseAccounts"
                        databaseAccountTags={databaseAccounts}
                        isSelected={applyWhen === option.value}
                        control={control}
                        error={errors?.databaseAccounts}
                        onAddDatabaseAccounts={onAddDatabaseAccounts}
                        onDeleteDatabaseAccounts={onDeleteDatabaseAccounts}
                      />
                    ))}
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Stack>

        <Divider />

        <Footer repoId={repoId} prevStep={prevStep} policyId={policyInfo.id} />
      </Stack>
    </Box>
  )
}

export default StepThree
