import * as React from 'react'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'

const UserAccountListItem = ({ name, isActive, onClick }) => {
  //   [`&:hover`]: {
  //     color: theme.palette.primary.main,
  //   },

  return (
    <ListItem disablePadding>
      <ListItemButton
        selected={isActive}
        onClick={onClick}
        disableRipple
        sx={{
          borderRadius: theme => theme.radii.base,

          '&.Mui-selected': {
            backgroundColor: 'cyralColors.primary.100',
          },
        }}
      >
        <ListItemText
          primary={name}
          primaryTypographyProps={{
            variant: 'h4',
            sx: {
              color: isActive ? 'primary.main' : 'text.secondary',
            },
            noWrap: true,
          }}
        />
      </ListItemButton>
    </ListItem>
  )
}

export default UserAccountListItem
