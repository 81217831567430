/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { useState } from 'react'
import { Fade } from '@material-ui/core/'

import { useMount } from '@jeeves/hooks'
import { IntegrationCard } from '@jeeves/pages/Integrations/primitives'
import View from './View'
import Edit from './Edit'

const IntegrationForm = ({ integration, onSave, onCancel, onDelete, creating = false }) => {
  const [editing, setEditing] = useState(false)

  useMount(() => {
    if (creating) {
      setEditing(true)
    }
  })

  const handleCancelEditing = () => {
    setEditing(false)

    if (onCancel) {
      onCancel()
    }
  }

  return (
    <IntegrationCard>
      {editing ? (
        <Fade in={editing}>
          <div>
            <Edit
              integration={integration}
              setEditing={setEditing}
              onSave={async payload => {
                if (creating) {
                  await onSave(payload)
                  setEditing(false)
                  onCancel()
                } else {
                  await onSave(payload, integration.id)
                  setEditing(false)
                }
              }}
              onCancel={handleCancelEditing}
            />
          </div>
        </Fade>
      ) : (
        <Fade in={!editing}>
          <div>
            <View
              integration={integration}
              setEditing={setEditing}
              onDelete={() => {
                onDelete(integration.id)
                setEditing(false)
              }}
            />
          </div>
        </Fade>
      )}
    </IntegrationCard>
  )
}

export default IntegrationForm
