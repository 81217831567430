import { useState } from 'react'
import { get as lodashGet } from 'lodash'

import ExpressClient from '@jeeves/clients/express'
import { useAuth } from '@jeeves/components/Auth'
import usePopup from '@jeeves/hooks/usePopup'

const useSlack = () => {
  const { setPopup, popupTypes } = usePopup()
  const { getTokenSilently, user } = useAuth()
  const ec = new ExpressClient(getTokenSilently)

  const [integrations, setIntegrations] = useState([])

  const [addField, setAddField] = useState(integrations.length === 0 ? 1 : 0)

  const handleCreateAddField = () => setAddField(1)
  const handleRemoveAddField = () => setAddField(0)

  const addIntegration = async input => {
    try {
      const integration = await ec
        .post('/integrations/notifications/slack', input)
        .then(res => res.data)
      updateIntegrations()
      return integration
    } catch (e) {
      const popupMessage = lodashGet(e, 'response.data.error.message', '')
      setPopup(popupTypes.ERROR, popupMessage)
      throw e
    }
  }

  const putIntegration = async (input, integrationID) => {
    try {
      const integration = await ec
        .put(`/integrations/notifications/slack/${integrationID}`, input)
        .then(res => res.data)
      updateIntegrations()
      return integration
    } catch (e) {
      const popupMessage = lodashGet(e, 'response.data.error.message', '')
      setPopup(popupTypes.ERROR, popupMessage)
    }
  }

  const deleteIntegration = async integrationID => {
    try {
      const integration = await ec
        .delete(`/integrations/notifications/slack/${integrationID}`)
        .then(res => res.data)

      updateIntegrations()
      return integration
    } catch (e) {
      const popupMessage = lodashGet(e, 'response.data.error.message', '')
      setPopup(popupTypes.ERROR, popupMessage)
    }
  }

  const updateIntegrations = async () => {
    try {
      const { data } = await ec.get('/integrations/notifications/slack')
      setIntegrations(data)
    } catch (e) {
      const popupMessage = lodashGet(e, 'response.data.error.message', '')
      setPopup(popupTypes.ERROR, popupMessage)
      throw e
    }
  }

  return {
    handleCreateAddField,
    handleRemoveAddField,
    updateIntegrations,
    integrations,
    addIntegration,
    putIntegration,
    deleteIntegration,
    addField,
    user,
    lodashGet,
  }
}

export default useSlack
