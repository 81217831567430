import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { Box, Divider, FormControl, Stack, FormHelperText } from '@mui/material'
import { useRepositoryDetailContext } from '@jeeves/pages/RepositoryDetail/contexts/RepositoryDetailContext'

import { Input, InputLabel } from '@jeeves/new-components'
import InputTags from '@jeeves/pages/RepositoryDetail/Tabs/Policies/views/AddPolicy/components/InputTags'
import Footer from '@jeeves/pages/RepositoryDetail/Tabs/Policies/views/AddPolicy/components/Footer'

const StepOne = ({ policyInfo, nextStep, setPolicyInfo }) => {
  const { repoId } = useRepositoryDetailContext()
  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      name: policyInfo.name ?? '',
      description: policyInfo.description ?? '',
      tags: policyInfo.tags,
    },
  })

  const onSubmit = data => {
    setPolicyInfo(info => {
      return {
        ...info,
        name: data.name,
        description: data.description,
        tags: data.tags,
      }
    })
  }

  const onDeleteTags = updatedTags => {
    setValue('tags', updatedTags, { shouldDirty: true })
  }

  const onAddTags = updatedTags => {
    setValue('tags', updatedTags, { shouldValidate: true, shouldDirty: true })
  }

  useEffect(() => {
    if (isSubmitSuccessful) {
      nextStep()
    }
  }, [isSubmitSuccessful, nextStep])

  const { tags = [] } = watch()

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4}>
        <Stack spacing={4} sx={{ maxWidth: '32rem' }}>
          <FormControl variant="standard" error={Boolean(errors?.name)}>
            <InputLabel htmlFor="policy-name" required>
              Policy Name
            </InputLabel>
            <Input
              id="policy-name"
              inputProps={{
                ...register('name', {
                  required: 'Please enter a Policy Name',
                }),
              }}
            />
            <FormHelperText
              sx={{
                typography: 'body2',
              }}
              error={!!errors?.name}
            >
              {errors?.name?.message}
            </FormHelperText>
          </FormControl>
          <FormControl variant="standard" error={Boolean(errors?.description)}>
            <InputLabel htmlFor="policy-description">Policy Description</InputLabel>
            <Input
              id="policy-description"
              multiline
              rows={3}
              inputProps={{
                ...register('description'),
              }}
            />
          </FormControl>

          <InputTags
            inputLabel="Policy Types"
            tags={tags}
            isSubmitting={isSubmitting}
            onDelete={onDeleteTags}
            onAdd={onAddTags}
          />
        </Stack>

        <Divider />

        <Footer repoId={repoId} policyId={policyInfo.id} />
      </Stack>
    </Box>
  )
}

export default StepOne
