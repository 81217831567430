import { useMutation } from '@apollo/client'

import { FragmentType, graphql, useFragment } from '@jeeves/graphql'

import { useToast } from '@jeeves/new-components'
import { getGraphQLErrorMessage } from '@jeeves/utils/helpers'

const DELETE_AWS_IAM_INTEGRATION = graphql(`
  mutation DeleteAwsIamIntegration($deleteAwsIamIntegrationId: ID!) {
    deleteAwsIamIntegration(id: $deleteAwsIamIntegrationId) {
      deletedAwsIamIntegrationId
    }
  }
`)

const useAwsIntegrationItem_awsIamIntegrationFragment = graphql(`
  fragment useAwsIntegrationItem_awsIamIntegration on AwsIamIntegration {
    id
  }
`)

interface useAwsIntegrationItemProps {
  integration: FragmentType<typeof useAwsIntegrationItem_awsIamIntegrationFragment>
}

export const useAwsIntegrationItem = ({
  integration: integrationProp,
}: useAwsIntegrationItemProps) => {
  const integration = useFragment(useAwsIntegrationItem_awsIamIntegrationFragment, integrationProp)

  const { toast } = useToast()

  const [deleteAwsIamIntegration, { loading }] = useMutation(DELETE_AWS_IAM_INTEGRATION, {
    variables: {
      deleteAwsIamIntegrationId: integration.id,
    },
    onError: error => {
      toast({
        variant: 'error',
        description:
          getGraphQLErrorMessage(error) ||
          'An error ocurred while deleting this integration, please try again.',
      })
    },
    update(cache, { data }) {
      const cacheId = cache.identify({
        id: data?.deleteAwsIamIntegration?.deletedAwsIamIntegrationId,
        __typename: integration.__typename,
      })
      cache.evict({ id: cacheId })
      cache.gc()
    },
  })

  return {
    loading,
    deleteAwsIamIntegration,
  }
}
