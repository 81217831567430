import { ApolloError } from '@apollo/client'
import { Box, Table, TableHead, TableBody } from '@mui/material'

import { FragmentType, graphql, useFragment } from '@jeeves/graphql'
import { TableContainer } from '@jeeves/new-components'

import AccessTokenRow from './AccessTokenRow'
import EmptyState from './EmptyState'
import { Views } from '../../../types'
import { LoadingState, ErrorState } from '../../../components'

const AccessTokenTable_QueryFragment = graphql(`
  fragment AccessTokenTable_QueryFragment on Query {
    accessTokens {
      id
      ...AccessTokenRow_AccessTokenFragment
    }
  }
`)
interface Props {
  setView: React.Dispatch<React.SetStateAction<Views>>
  loading: boolean
  error?: ApolloError
  query?: FragmentType<typeof AccessTokenTable_QueryFragment> | null
  setSelectedAccessTokenId: React.Dispatch<React.SetStateAction<string>>
}

const AccessTokenTable = ({
  query: queryProp,
  loading,
  error,
  setView,
  setSelectedAccessTokenId,
}: Props) => {
  const query = useFragment(AccessTokenTable_QueryFragment, queryProp)

  const accessTokens = !loading && !error ? query!.accessTokens : []

  const handleDelete = (id: string) => {
    setView('delete')
    setSelectedAccessTokenId(id)
  }

  const handleEdit = (id: string) => {
    setView('edit')
    setSelectedAccessTokenId(id)
  }

  return (
    <TableContainer
      sx={{
        ...(!loading && {
          borderBottomWidth: 1,
          borderBottomStyle: 'solid',
          borderColor: 'cyralColors.grey.200',
        }),
      }}
    >
      <Table>
        <TableHead>
          <Box component="tr">
            <Box component="th" scope="col" sx={{ whiteSpace: 'nowrap' }}>
              Name
            </Box>
            <Box component="th" scope="col">
              Created
            </Box>
            <Box component="th" scope="col">
              Expiration
            </Box>
            <Box component="th" scope="col"></Box>
          </Box>
        </TableHead>
        <TableBody>
          {accessTokens.map(accessToken => (
            <AccessTokenRow
              key={accessToken.id}
              accessToken={accessToken}
              handleDelete={() => handleDelete(accessToken.id)}
              handleEdit={() => handleEdit(accessToken.id)}
            />
          ))}
        </TableBody>
      </Table>
      {loading ? (
        <LoadingState />
      ) : error ? (
        <ErrorState />
      ) : (
        accessTokens.length === 0 && <EmptyState />
      )}
    </TableContainer>
  )
}

export default AccessTokenTable
