import React from 'react'
import Stack from '@mui/material/Stack'
import MuiDivider from '@mui/material/Divider'
import MuiTabs from '@mui/material/Tabs'
import MuiTab from '@mui/material/Tab'
import { styled } from '@mui/material/styles'

const Card = styled(
  props => (
    <Stack
      {...props}
      divider={
        <MuiDivider
          sx={{
            borderBottomColor: 'cyralColors.grey.200',
          }}
        />
      }
    />
  ),
  {}
)(({ theme }) => ({
  overflow: 'hidden',
  backgroundColor: theme.palette.common.white,
  border: `1px solid ${theme.palette.cyralColors.grey[200]}`,
  borderRadius: theme.radii.xl,
}))

const Header = styled(
  'div',
  {}
)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(3, 4),
}))

const Body = styled('div')(({ theme }) => ({
  flex: '1',
  display: 'flex',
  overflowY: 'hidden',
  // justifyContent: 'space-between',
  // padding: theme.spacing(4, 5),
}))

Card.Header = Header
Card.Body = Body

export default Card
