import * as React from 'react'

import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'

import IconButton from '@mui/material/IconButton'
import { EditMapping } from '../../Dialogs/Edit/EditMapping'
import { DeleteMapping } from '../../Dialogs/Delete/DeleteMapping'
import { combineKeyAttributeComponentsIfS3 } from '@jeeves/utils/helpers'

import {
  MappingsTableRow,
  MappingsTableCell,
} from '@jeeves/pages/DataLabels/components/RepoRow/MappingTable/MappingTable.styles'

export const MappingRow = ({ mapping, labelName, datalabels, repoId, repoType }) => {
  const [editOpen, setEditOpen] = React.useState(false)
  const [deleteOpen, setDeleteOpen] = React.useState(false)
  const { raw: rawAttribute, parsed } = mapping.field

  const parsedAttribute = combineKeyAttributeComponentsIfS3(parsed, repoType)

  return (
    <React.Fragment>
      <DeleteMapping
        repoId={repoId}
        rawAttribute={rawAttribute}
        open={deleteOpen}
        setOpen={setDeleteOpen}
        labelName={labelName}
      ></DeleteMapping>
      <EditMapping
        repoId={repoId}
        datalabels={datalabels}
        labelName={labelName}
        mapping={mapping}
        open={editOpen}
        setOpen={setEditOpen}
        repoType={repoType}
      ></EditMapping>
      <MappingsTableRow>
        {parsedAttribute.map((attributeComponent, index) => (
          <MappingsTableCell key={`repo-details-mapping-cell-${index}`}>{attributeComponent}</MappingsTableCell>
        ))}
        {repoType === 's3' && parsedAttribute.length === 1 && (
          <MappingsTableCell>{}</MappingsTableCell>
        )}
        <MappingsTableCell align="right">
          <IconButton fontSize="small" sx={{ mr: 3.5 }} onClick={() => setEditOpen(true)}>
            <EditIcon sx={{ color: 'cyralColors.grey.300' }} />
          </IconButton>
          <IconButton onClick={() => setDeleteOpen(true)}>
            <DeleteIcon fontSize="small" sx={{ color: 'cyralColors.grey.300' }} />
          </IconButton>
        </MappingsTableCell>
      </MappingsTableRow>
    </React.Fragment>
  )
}
