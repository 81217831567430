import React, { useState, useRef } from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Popper from '@mui/material/Popper'
import Fade from '@mui/material/Fade'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Button from '@mui/material/Button'
import FilterListIcon from '@mui/icons-material/FilterList'

import { Filter } from './Filter'

const PopoverContentWrapper = styled(
  Box,
  {}
)(({ theme }) => ({
  //   overflow: 'hidden',
  marginTop: theme.spacing(1),
  padding: theme.spacing(3),
  backgroundColor: theme.palette.common.white,
  border: `1px solid ${theme.palette.cyralColors.grey[200]}`,
  borderRadius: theme.radii.base,
  maxWidth: '475px',
  boxShadow: theme.cyralShadows['2dp'],
  //   height: '100%',
}))

const Popover = ({ isOpen, handleClose, anchorRef, handleFilter, filters, hideTags }) => {
  return (
    <Popper
      open={isOpen}
      anchorEl={anchorRef.current}
      placement="bottom-start"
      transition
      sx={{ minWidth: '550px' }}
    >
      {({ TransitionProps }) => (
        <Fade
          {...TransitionProps}
          timeout={350}
          //   onExited={() => setShowAccessToken(false)}
          unmountOnExit
        >
          <Box>
            <ClickAwayListener onClickAway={handleClose}>
              <PopoverContentWrapper>
                <Stack divider={<Divider orientation="horizontal" flexItem />} spacing={2}>
                  <Filter
                    handleClose={handleClose}
                    handleFilter={handleFilter}
                    filters={filters}
                    hideTags={hideTags}
                  ></Filter>
                </Stack>
              </PopoverContentWrapper>
            </ClickAwayListener>
          </Box>
        </Fade>
      )}
    </Popper>
  )
}

export const FilterPopover = ({ handleFilter, filters, hideTags }) => {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)
  const numFilters = Object.values(filters).filter(val => val?.length > 0).length

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = event => {
    // Prevents selects in 'Request Access' section from closing popover
    if (event?.target?.localName === 'body') {
      event.preventDefault()
      return
    }

    if (anchorRef.current?.contains(event?.target)) return

    setOpen(false)
  }

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        startIcon={<FilterListIcon />}
        onClick={handleToggle}
        sx={{ color: 'secondary.dark' }}
        ref={anchorRef}
      >
        <span>Filter</span>
        {numFilters > 0 && <span style={{ 'margin-left': '8px' }}>{numFilters}</span>}
      </Button>
      <Popover
        isOpen={open}
        anchorRef={anchorRef}
        handleClose={handleClose}
        handleFilter={handleFilter}
        filters={filters}
        hideTags={hideTags}
      />
    </>
  )
}
