import * as React from 'react'

import { InfoAlert, LocalAccountDiscoveryRow } from './components'

import { TableContainer, Button } from '@jeeves/new-components'
import { Box, Stack, Typography } from '@mui/material'
import { LearnMore } from '@jeeves/pages/Overview/components/LearnMore'

import { parseISO, formatDistanceToNowStrict } from 'date-fns'

import * as _ from 'lodash'

import {
  prepareDataForTable,
  exportAsPDF,
  transformData,
  addNonesToDiscoveredAccounts,
  formatForPDFTable,
  sortDiscoveredAccounts,
  formatForUITable,
} from './helpers'

const Overview = ({ repo, mappedDatalabelsRelationship, discoveredAccounts, workerRuns }) => {
  // transforming data
  let transformedData = transformData(discoveredAccounts, repo.type)
  let transformedDataWithNones = addNonesToDiscoveredAccounts(transformedData, repo.type)
  let transformedDataSorted = sortDiscoveredAccounts(transformedDataWithNones)
  const discoveredAccountsTable = formatForUITable(transformedDataSorted)
  const discoveredAccountsPDFTable = formatForPDFTable(transformedDataSorted, repo.type)

  let thereIsAnOracleMaintainedAccount = false

  if (repo.type === 'oracle') {
    for (let index = 0; index < discoveredAccountsTable.length; index++) {
      if (discoveredAccountsTable[index].isOracleMaintained) {
        thereIsAnOracleMaintainedAccount = true
        break
      }
    }
  }

  let tableData = discoveredAccountsTable
  let numAccounts = discoveredAccounts.length

  // Latest worker run
  let workerAux = workerRuns
  workerAux = _.reverse(
    _.sortBy(
      workerAux.filter(run => {
        return run.state == 'COMPLETED'
      }),
      'updatedAt'
    )
  )
  const [lastScanTimestamp, setLastScanTimestamp] = React.useState(
    workerAux.length > 0 ? workerAux[0].updatedAt : 0
  )

  let alertPanel = null

  if (mappedDatalabelsRelationship && mappedDatalabelsRelationship?.edges.length > 0) {
    const mappingsNames = mappedDatalabelsRelationship?.edges?.map(e => e.node.name)
    const mapsString = mappingsNames.reduce(
      (p, v, i) => (p == null ? v : i == mappingsNames.length - 1 ? p + ' and ' + v : p + ', ' + v),
      null
    )
    alertPanel = (
      <InfoAlert>
        {`${mappingsNames.length} sensitive field${
          mappingsNames.length > 1 ? 's' : ''
        } of type ${mapsString}`}
      </InfoAlert>
    )
  }

  let numberOfItems = tableData.length

  return (
    <React.Fragment>
      <Box sx={{ px: 8, py: 4 }}>
        <Stack spacing={4}>
          <Typography variant="body1" sx={{ color: '#808e95' }}>
            Discover and manage database accounts for this data repository.{' '}
            <LearnMore
              docsPath={'/manage-repositories/local-account-discovery'}
              useVersionedDocsURL={true}
            />
          </Typography>
          {alertPanel}
          <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
            <Box sx={{ fontStyle: 'italic' }}>
              {workerRuns.length > 0 && lastScanTimestamp != 0 && (
                <Typography variant="body2" sx={{ color: '#808e95' }}>
                  {`Discovered ${numAccounts} account${numAccounts === 1 ? '' : 's'}, `}
                  {`last scanned ${formatDistanceToNowStrict(parseISO(lastScanTimestamp), {
                    addSuffix: true,
                  })}`}
                </Typography>
              )}
            </Box>
            <Button
              disabled={numberOfItems == 0}
              variant="outlined"
              color="secondary"
              onClick={() =>
                exportAsPDF(
                  repo,
                  lastScanTimestamp,
                  numAccounts,
                  discoveredAccountsPDFTable,
                  thereIsAnOracleMaintainedAccount
                )
              }
            >
              Generate Audit Report
            </Button>
          </Stack>
          {repo.type != 'sqlserver' && (
            <TableContainer>
              <table>
                <thead>
                  <Box component="tr">
                    <Box component="th" scope="col">
                      <div>DATABASE</div>
                      <div>ACCOUNT</div>
                    </Box>
                    <Box component="th" scope="col">
                      <div>ROLES</div>
                    </Box>
                    <Box component="th" scope="col">
                      <div>ACCOUNT</div>
                      <div>STATUS</div>
                    </Box>
                    <Box component="th" scope="col">
                      <div>CONTROLS</div>
                      <div>APPLIED</div>
                    </Box>
                    <Box component="th" scope="col">
                      <div>USERS</div>
                      <Typography variant="body2" sx={{ color: '#808e95' }}>
                        (in last 7 days)
                      </Typography>
                    </Box>
                    <Box component="th" scope="col">
                      LAST USED
                    </Box>
                  </Box>
                </thead>
                <tbody>
                  {tableData.map(row => (
                    <LocalAccountDiscoveryRow
                      key={row.databaseAccount}
                      account={row}
                      repoType={repo.type}
                    />
                  ))}
                </tbody>
              </table>
            </TableContainer>
          )}
          {repo.type == 'oracle' && thereIsAnOracleMaintainedAccount && (
            <Box sx={{ fontStyle: 'italic' }}>
              <Typography variant="body2" sx={{ color: '#808e95' }}>
                <sup>**</sup> Oracle maintained account
              </Typography>
            </Box>
          )}
          {repo.type === 'sqlserver' && (
            <TableContainer style={{ overflowX: 'scroll' }}>
              <table>
                <thead>
                  <Box component="tr">
                    <Box component="th" scope="col">
                      <div>DATABASE</div>
                      <div>ACCOUNT</div>
                    </Box>
                    <Box component="th" scope="col">
                      <div>SERVER</div>
                      <div>ROLES</div>
                    </Box>
                    <Box component="th" scope="col">
                      <div>DATABASE</div>
                    </Box>
                    <Box component="th" scope="col">
                      <div>DATABASE</div>
                      <div>USER</div>
                    </Box>
                    <Box component="th" scope="col">
                      <div>DATABASE</div>
                      <div>ROLES</div>
                    </Box>
                    <Box component="th" scope="col">
                      <div>ACCOUNT</div>
                      <div>STATUS</div>
                    </Box>
                    <Box component="th" scope="col">
                      <div>CONTROLS</div>
                      <div>APPLIED</div>
                    </Box>
                    <Box component="th" scope="col">
                      <div>USERS</div>
                      <Typography variant="body2" sx={{ color: '#808e95' }}>
                        (in last 7 days)
                      </Typography>
                    </Box>
                    <Box component="th" scope="col" style={{ minWidth: '150px' }}>
                      LAST USED
                    </Box>
                  </Box>
                </thead>
                <tbody>
                  {tableData.map(row => (
                    <LocalAccountDiscoveryRow
                      key={row.databaseAccount + '_' + row.database}
                      account={row}
                      repoType={repo.type}
                    />
                  ))}
                </tbody>
              </table>
            </TableContainer>
          )}
          {numberOfItems == 0 && (
            <Typography variant="body1" sx={{ alignSelf: 'center', color: '#808e95' }}>
              No discovered accounts.
            </Typography>
          )}
        </Stack>
      </Box>
    </React.Fragment>
  )
}

export default Overview
