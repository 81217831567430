import { useContext } from 'react'
import ExpressClient from '@jeeves/clients/express'
import { useAuth } from '@jeeves/components/Auth'

import { AlertsContext } from '../contexts/AlertsContext'

import moment from 'moment-timezone'
import { get as lodashGet } from 'lodash'

const useAlerts = () => {
  const [state, setState] = useContext(AlertsContext)
  const { alerts } = state
  // TO DO: refactor this, fetch in the hook, not outside

  const { getTokenSilently } = useAuth()
  const ec = new ExpressClient(getTokenSilently)

  const handleRequestSort = (event, property) => {
    const orderBy = property
    let order = 'asc'
    if (state.orderBy === property && state.order === 'asc') {
      order = 'desc'
    }
    setState(state => ({ ...state, order, orderBy }))
  }

  const handleChangePage = (event, page) => {
    setState(state => ({ ...state, page }))
  }

  const handleChangeRowsPerPage = event => {
    setState(state => ({ ...state, rowsPerPage: event.target.value }))
  }

  const severityMap = new Map([
    [0, 'Low'],
    [1, 'Medium'],
    [2, 'High'],
  ])

  // TO DO: should this be here?
  const chartColors = [
    {
      hover: '#f00c0c90',
      background: '#f00c0c90',
      border: '#f00c0c90',
    },
    {
      hover: '#d26e0090',
      background: '#d26e0090',
      border: '#d26e0090',
    },
    {
      hover: '#36A2EB90',
      background: '#36A2EB90',
      border: '#36A2EB90',
    },
    {
      hover: '#dee02d90',
      background: '#dee02d90',
      border: '#dee02d90',
    },
    {
      hover: '#82b93b90',
      background: '#82b93b90',
      border: '#82b93b90',
    },
    {
      hover: '#55d18890',
      background: '#55d18890',
      border: '#55d18890',
    },
    {
      hover: '#39e3cc90',
      background: '#39e3cc90',
      border: '#39e3cc90',
    },
    {
      hover: '#69f0ff90',
      background: '#69f0ff90',
      border: '#69f0ff90',
    },
  ]

  const formatDate = isoDate => {
    return moment.unix(isoDate).format('MMM DD, YYYY hh:mm:ss A ')
  }

  const setTimeSpan = timeSpan => {
    setState(state => ({ ...state, timeSpan: timeSpan }))
  }

  const getTimeSpan = timeSpan => {
    const SECOND = 1000
    const MINUTE = SECOND * 60
    const HOUR = MINUTE * 60
    const DAY = HOUR * 24
    const WEEK = DAY * 7
    const MONTH = WEEK * 4

    const timeSpanMap = new Map([
      ['FIFTEEN_MINS', { value: MINUTE * 15, label: 'Last 15 minutes' }],
      ['HOUR', { value: HOUR, label: 'Last hour' }],
      ['DAY', { value: DAY, label: 'Last day' }],
      ['WEEK', { value: WEEK, label: 'Last week' }],
      ['MONTH', { value: MONTH, label: 'Last month' }],
    ])

    return timeSpanMap.get(timeSpan)
  }

  return {
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
    severityMap,
    chartColors,
    formatDate,
    lodashGet,
    timeSpan: state.timeSpan,
    setTimeSpan,
    getTimeSpan,
    ec,
    setState,
    alerts,
  }
}

export default useAlerts
