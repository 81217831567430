import React, { useState } from 'react'

const DataMapContext = React.createContext([{}, () => {}])

const DataMapProvider = props => {
  const [state, setState] = useState({
    reports: [],
    datamap: '',
    crudCount: 0,
    page: 0,
    rowsPerPage: 10,
    order: 'asc',
    orderBy: 'report.date',
    currentDialog: '',
  })

  return (
    <DataMapContext.Provider value={[state, setState]}>{props.children}</DataMapContext.Provider>
  )
}

export { DataMapContext, DataMapProvider }
