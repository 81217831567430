import { Button } from '@jeeves/new-components'
import { Box, Typography } from '@mui/material'

interface AuditFooterProps {
  hasMoreLogs: boolean
  fetchingMore: boolean
  handleLoadMore: () => void
}

const AuditFooter = ({ hasMoreLogs, fetchingMore, handleLoadMore }: AuditFooterProps) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
      {hasMoreLogs ? (
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleLoadMore}
          loading={fetchingMore}
        >
          Load more logs
        </Button>
      ) : (
        <Typography variant="h6" sx={{ color: 'text.secondary' }}>
          No more logs to load
        </Typography>
      )}
    </Box>
  )
}

export default AuditFooter
