import * as React from 'react'

import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import IconButton from '@mui/material/IconButton'

import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'

import {
  RecommendationCard,
  AttributeFieldValue,
  AttributeField,
  AttributeValue,
} from '../Recommendations.styles'

import { gql, useMutation } from '@apollo/client'
import { FETCH_MAPPINGS, FETCH_RECOMMENDATIONS } from '../../Mappings'
import { combineKeyAttributeComponentsIfS3 } from '@jeeves/utils/helpers'
import moment from 'moment'

const UPDATE_RECOMMENDATION_STATUS = gql`
  mutation UpdateDatamapRecommendationStatus(
    $repoId: String!
    $recommendationId: String!
    $status: DatamapRecommendationStatus!
  ) {
    updateDatamapRecommendationStatus(
      repoId: $repoId
      recommendationId: $recommendationId
      status: $status
    ) {
      message
    }
  }
`

export const PendingRecommendationCard = ({
  recommendation,
  repoType,
  repoId,
  recommendationStatus,
}) => {
  const [updateRecommendationStatus, { data, loading, error }] = useMutation(
    UPDATE_RECOMMENDATION_STATUS,
    {
      errorPolicy: 'all',
      refetchQueries: [FETCH_MAPPINGS, FETCH_RECOMMENDATIONS],
    }
  )

  const onSubmit = async status => {
    try {
      updateRecommendationStatus({
        variables: {
          repoId: repoId,
          recommendationId: recommendation.id,
          status: status,
        },
      })
    } catch (e) {
      console.error(e)
    }
  }

  const generateFieldLabels = () => {
    if (repoType === 'mongodb') {
      return ['Database', 'Collection', 'Field']
    } else if (repoType === 's3') {
      return ['Bucket', 'Key']
    } else if (repoType === 'dynamodb' || repoType === 'dynamodbstreams') {
      return ['Table', 'Field']
    } else {
      return ['Schema', 'Table', 'Column']
    }
  }
  const fieldLabels = generateFieldLabels()

  const { parsed } = recommendation.attribute
  
  const parsedAttribute = combineKeyAttributeComponentsIfS3(parsed, repoType)

  return (
    <RecommendationCard>
      <Stack sx={{ width: '75%' }}>
        <Stack direction="row" spacing={3} sx={{ justifyContent: 'space-between' }}>
          <AttributeFieldValue>
            <AttributeField variant="h5">{fieldLabels[0]}</AttributeField>
            <AttributeValue variant="data">{parsedAttribute[0]}</AttributeValue>
          </AttributeFieldValue>

          <AttributeFieldValue>
            <AttributeField variant="h5">{fieldLabels[1]}</AttributeField>
            <AttributeValue variant="data">{parsedAttribute[1]}</AttributeValue>
          </AttributeFieldValue>

          {repoType !== 's3' && repoType !== 'dynamodb' && repoType !== 'dynamodbstreams' && (
            <AttributeFieldValue>
              <AttributeField variant="h5">{fieldLabels[2]}</AttributeField>
              <AttributeValue variant="data">{parsedAttribute[2]}</AttributeValue>
            </AttributeFieldValue>
          )}

          <AttributeFieldValue>
            <AttributeField variant="h5">Proposed Label</AttributeField>
            <AttributeValue variant="data" sx={{ color: 'cyralColors.primary.300' }}>
              {recommendation.datalabel.name}
            </AttributeValue>
          </AttributeFieldValue>
        </Stack>

        <Stack direction="row" spacing={3} sx={{ mt: 2 }}>
          <Typography variant="h6" sx={{ color: 'cyralColors.grey.400' }}>
            Created: {moment(recommendation.createdAt).format('MMMM DD, YYYY')}
          </Typography>
        </Stack>
      </Stack>
      {recommendationStatus === 'RECOMMENDED' && (
        <Stack direction="row" spacing={4} sx={{ alignSelf: 'center' }}>
          <IconButton onClick={() => onSubmit('ACCEPTED')}>
            <CheckIcon fontSize="medium" sx={{ color: 'cyralColors.grey.300' }}></CheckIcon>
          </IconButton>
          <IconButton onClick={() => onSubmit('DISMISSED')}>
            <ClearIcon fontSize="medium" sx={{ color: 'cyralColors.grey.300' }}></ClearIcon>
          </IconButton>
        </Stack>
      )}
    </RecommendationCard>
  )
}
