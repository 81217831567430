import * as React from 'react'
import { styled } from '@mui/material/styles'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'

export const Accordion = styled(props => (
  <MuiAccordion
    elevation={0}
    disableGutters
    square
    {...props}
    // sx={{
    //   '&.Mui-expanded': {
    //     ':before': {
    //       opacity: 1,
    //     },
    //   },
    // }}
  />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,

  '&:first-child': {
    borderTopLeftRadius: theme.radii.base,
    borderTopRightRadius: theme.radii.base,
  },

  '&:last-child': {
    borderBottomLeftRadius: theme.radii.base,
    borderBottomRightRadius: theme.radii.base,
  },

  '&:not(:last-child)': {
    borderBottom: 0,
  },
  //   '&:before': {
  //     display: 'none',
  //   },
}))

export const AccordionSummary = styled(props => (
  <MuiAccordionSummary
    expandIcon={<ExpandMoreIcon sx={{ color: 'secondary.main' }} />}
    {...props}
  />
))(({ theme }) => ({
  padding: theme.spacing(2, 3),

  '& .MuiAccordionSummary-content': {
    margin: 0,
  },
}))

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(3, 3, 2),
}))
