import React from 'react'
import { InlineCode } from '@jeeves/new-components'

import { getDisplayedPassword } from '../utils'

import { DBeaver } from './components/DBeaver'
import { Toad } from './components/Toad'
import { SqlDeveloper } from './components/SqlDeveloper'

const getConnectionTypes = (connectionInfo, options) => {
  const { ports, ...rest } = connectionInfo
  const { showPassword } = options

  const sqlPlusCommandConnectionInfo = {
    ...rest,
    port: ports[0],
  }

  return [
    {
      label: 'SQL*Plus',
      value: 'sqlplus',
      content: (
        <InlineCode sx={{ backgroundColor: 'common.white' }} noWrap>
          {getSqlPlusCommand({
            connectionInfo: sqlPlusCommandConnectionInfo,
            redactPassword: !showPassword,
          })}
        </InlineCode>
      ),
      copyValue: getSqlPlusCommand({ connectionInfo: sqlPlusCommandConnectionInfo }),
    },
    {
      label: 'DBeaver',
      value: 'dbeaver',
      content: <DBeaver />,
      copyValue: null,
    },
    {
      label: 'Toad',
      value: 'toad',
      content: <Toad />,
      copyValue: null,
    },
    {
      label: 'SQL Developer',
      value: 'sql-developer',
      content: <SqlDeveloper />,
      copyValue: null,
    },
  ]
}

const getSqlPlusCommand = ({ connectionInfo, redactPassword = false }) => {
  const {
    username,
    host,
    port,
    password: connectionInfoPassword,
    database: connectionInfoDatabase,
    clientTLSEnabled,
  } = connectionInfo

  const password = getDisplayedPassword(connectionInfoPassword, redactPassword)
  const database = connectionInfoDatabase || '<DATABASE>'

  if (!clientTLSEnabled) {
    // Uses EZConnect naming method
    return `sqlplus '"${username}"/${password}@//${host}:${port}/${database}'`
  } else {
    // Uses TNS string to connect
    return `sqlplus '"${username}"/${password}@(DESCRIPTION=(ADDRESS=(PROTOCOL=TCPS)(HOST=${host})(PORT=${port}))(CONNECT_DATA=(SID=${database}))(SECURITY=(SSL_SERVER_CERT_DN="C=US,ST=CA,L=Redwood City,O=Cyral Inc.,OU=Cyral")))'`
  }
}

export default getConnectionTypes
