import * as React from 'react'
import { useMutation, gql } from '@apollo/client'
import Typography from '@mui/material/Typography'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

import { Button, Dialog, DialogActions } from '@jeeves/new-components'

import { SERVICE_ACCOUNT_FIELDS } from '../../../../hooks/useRepositoryDetail'

const DELETE_SERVICE_ACCOUNT = gql`
  mutation DeleteServiceAccount($repoId: String!, $serviceAccountId: String!) {
    deleteServiceAccount(repoId: $repoId, serviceAccountId: $serviceAccountId) {
      serviceAccountId
      repo {
        id
        ... on DenodoRepo {
          serviceAccounts {
            ...serviceAccountFields
          }
        }
        ... on PostgreSQLRepo {
          serviceAccounts {
            ...serviceAccountFields
          }
        }
        ... on RedshiftRepo {
          serviceAccounts {
            ...serviceAccountFields
          }
        }
        ... on SnowflakeRepo {
          serviceAccounts {
            ...serviceAccountFields
          }
        }
      }
    }
  }
  ${SERVICE_ACCOUNT_FIELDS}
`

const DeleteServiceAccountModal = ({ open, closeModal, serviceAccountId, repoId }) => {
  const [deleteServiceAccount, { data, loading, error }] = useMutation(DELETE_SERVICE_ACCOUNT, {
    variables: {
      repoId,
      serviceAccountId,
    },
    update(cache, { data: { deleteServiceAccount } }) {
      const normalizedId = cache.identify({
        id: deleteServiceAccount.serviceAccountId,
        __typename: 'ServiceAccount',
      })
      cache.evict({ id: normalizedId })
      cache.gc()
    },
    onCompleted: data => {
      closeModal()
    },
  })

  const onDelete = async () => {
    try {
      await deleteServiceAccount()
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <Dialog onClose={closeModal} open={open}>
      <DialogTitle variant="h3">Remove Database Account</DialogTitle>
      <DialogContent>
        <Typography variant="body1" sx={{ color: 'text.secondary' }}>
          Are you sure you want to remove this database account from Cyral?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={closeModal} autoFocus>
          Cancel
        </Button>
        <Button variant="outlined" color="error" onClick={onDelete} loading={loading} autoFocus>
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteServiceAccountModal
