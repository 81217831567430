import * as React from 'react'
import Box from '@mui/material/Box'

import EmptyState from './components/EmptyState'
import UserAccountList from './components/UserAccountList'
import UserAccountDetailView from './components/UserAccountDetailView'
import UpsertUserAccountWizard from './components/UpsertUserAccountWizard'
import Header from './components/Header'

import {
  UserAuthenticationProvider,
  useUserAuthenticationContext,
} from './contexts/UserAuthenticationContext'

const UserAuthentication = () => {
  const { isRegistering, isUpdating, userAccounts } = useUserAuthenticationContext()

  return (
    <Box sx={{ px: 8, py: 4 }}>
      {userAccounts.length === 0 && !isRegistering ? (
        <EmptyState />
      ) : (
        <Box>
          <Header />

          <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(6, minmax(0, 1fr))' }}>
            <Box sx={{ pt: 4, pr: 2, borderRight: 1, borderColor: 'cyralColors.grey.200' }}>
              <UserAccountList />
            </Box>
            <Box sx={{ gridColumn: 'span 5 / span 5', pt: 4, pl: 4 }}>
              {isRegistering || isUpdating ? (
                <UpsertUserAccountWizard />
              ) : (
                <UserAccountDetailView />
              )}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  )
}

// eslint-disable-next-line import/no-anonymous-default-export
export default props => (
  <UserAuthenticationProvider userAccounts={props.userAccounts}>
    <UserAuthentication />
  </UserAuthenticationProvider>
)
