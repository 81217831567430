import { useState } from 'react'
import { IconButton, Menu, MenuItem, Typography } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'

interface Props {
  openEdit: () => void
}

const ActionsButton = ({ openEdit }: Props) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)

  const open = Boolean(anchorEl)

  const closeMenu = () => {
    setAnchorEl(null)
  }

  return (
    <IconButton
      disableRipple
      disableFocusRipple
      sx={{
        borderRadius: theme => theme.radii.sm,
        padding: 0,
        '&:hover': {
          backgroundColor: theme => theme.palette.cyralColors.grey[100],
        },
      }}
    >
      <MoreVertIcon
        sx={{ color: 'secondary.main' }}
        onClick={event => {
          setAnchorEl(event.currentTarget)
        }}
      />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={() => {
            closeMenu()
            openEdit()
          }}
          disableRipple
          sx={{ width: '166px' }}
        >
          <Typography variant="body2" sx={{ color: 'text.primary' }}>
            Edit
          </Typography>
        </MenuItem>
      </Menu>
    </IconButton>
  )
}

export default ActionsButton
