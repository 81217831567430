import { useFormContext } from 'react-hook-form'
import { Box, FormControl, FormHelperText } from '@mui/material'

import { Input, InputLabel } from '@jeeves/new-components'

const UpsertRepoStandardFields = () => {
  const { register, formState } = useFormContext()

  const hostError = formState.errors?.host
  const portError = formState.errors?.port

  return (
    <Box sx={{ display: 'grid', gridTemplateColumns: '2fr 1fr', gap: 1 }}>
      <FormControl variant="standard" error={Boolean(hostError)}>
        <InputLabel htmlFor="host" required>
          Hostname
        </InputLabel>
        <Input
          id="host"
          defaultValue=""
          inputProps={{
            ...register('host'),
          }}
        />
        <FormHelperText
          sx={{
            typography: 'body2',
          }}
          error={Boolean(hostError)}
        >
          {hostError?.message}
        </FormHelperText>
      </FormControl>
      <FormControl variant="standard" error={Boolean(portError)}>
        <InputLabel htmlFor="port" required>
          Port
        </InputLabel>
        <Input
          id="port"
          defaultValue=""
          inputProps={{
            ...register('port', {
              setValueAs: value => (value === '' ? undefined : Number(value)),
            }),
          }}
        />
        <FormHelperText
          sx={{
            typography: 'body2',
          }}
          error={Boolean(portError)}
        >
          {portError?.message}
        </FormHelperText>
      </FormControl>
    </Box>
  )
}

export default UpsertRepoStandardFields
