import * as React from 'react'
import Divider from '@mui/material/Divider'

import { StyledDialogActions } from './Dialog.styles'

export const DialogActions = ({ children, ...props }) => {
  return (
    <React.Fragment>
      <Divider
        variant="middle"
        sx={{
          '&.MuiDivider-middle': {
            mx: 4,
          },
        }}
      />
      <StyledDialogActions {...props}>{children}</StyledDialogActions>
    </React.Fragment>
  )
}
