import * as React from 'react'
import { useForm, Controller, FormProvider } from 'react-hook-form'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'

import { Button, Input, InputLabel } from '@jeeves/new-components'

import ConfigInstructions from './components/ConfigInstructions'

const EnvironmentVariable = ({ databaseAccountInfo, handleMutateUserAccount, footer }) => {
  const methods = useForm({
    defaultValues: {
      variableName: databaseAccountInfo?.credentials?.variableName ?? '',
    },
  })

  const { register, handleSubmit, formState } = methods
  const { errors } = formState

  // React.useEffect(() => {
  //   if (isSubmitSuccessful) {
  //     // closeWizard()
  //   }
  // }, [isSubmitSuccessful, closeWizard])

  const onSubmit = async ({ variableName }) => {

    const userAccount = {
      ...databaseAccountInfo,
      credentials: {
        type: databaseAccountInfo.credentials.type,
        variableName,
      },
    }

    await handleMutateUserAccount(userAccount)
  }

  return (
    <FormProvider {...methods}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4}>
          {/* <ConfigInstructions /> */}

          <FormControl variant="standard" error={Boolean(errors.variableName)}>
            <InputLabel htmlFor="variableName">Variable Name</InputLabel>
            <Input
              id="variableName"
              inputProps={{ ...register('variableName', { required: 'This field is required.' }) }}
              fullWidth
            />
            <FormHelperText
              sx={{
                typography: 'body2',
              }}
              error={!!errors?.variableName}
            >
              {errors?.variableName?.message ||
                'Specify the name of the variable that contains the database account credentials.'}
            </FormHelperText>
          </FormControl>

          {footer}
        </Stack>
      </Box>
    </FormProvider>
  )
}

export default EnvironmentVariable
