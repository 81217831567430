import { useAuth } from '../../../components/Auth'
import ExpressClient from '@jeeves/clients/express'
import usePopup from '@jeeves/components/PopupMessage/hooks/usePopup'
import { get as lodashGet } from 'lodash'

const useProfile = () => {
  const { getTokenSilently } = useAuth()
  const { setPopup, popupTypes } = usePopup()
  const ec = new ExpressClient(getTokenSilently)

  const fetchUserConnections = async () => {
    try {
      const userConnections = await ec.get('/users/connections/names').then(res => res.data)
      return userConnections
    } catch (e) {
      const popupMessage = lodashGet(e, 'response.data.error.message', '')
      setPopup(popupTypes.ERROR, popupMessage)
      throw e
    }
  }

  return {
    fetchUserConnections,
  }
}

export default useProfile
