import React from 'react'
import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { LocalAccountDiscoveryRole } from './index'

const StyledLocalAccountDiscoveryRow = styled('tr', {
  name: 'LocalAccountDiscoveryRow',
  slot: 'Root',
})(({ theme }) => ({
  ':hover': {
    backgroundColor: theme.palette.grey[100],
  },
  td: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}))

const createLine = (account, repoType, lineColor, setIsHoveringOtherElement) => {
  if (repoType === 'sqlserver') {
    return (
      <StyledLocalAccountDiscoveryRow>
        <Box component="td">
          <Typography variant="body2" sx={{ color: lineColor }}>
            {account.databaseAccount}
          </Typography>
        </Box>
        <Box component="td" style={{ flexDirection: 'row' }}>
          <LocalAccountDiscoveryRole
            roles={account.unifiedServerRoles}
            lineColor={lineColor}
            setIsHoveringOtherElement={setIsHoveringOtherElement}
          />
        </Box>
        <Box component="td">
          <Typography variant="body2" sx={{ color: lineColor }}>
            {account.database}
          </Typography>
        </Box>
        <Box component="td">
          <Typography variant="body2" sx={{ color: lineColor }}>
            {account.databaseUser}
          </Typography>
        </Box>
        <Box component="td">
          <LocalAccountDiscoveryRole
            roles={account.unifiedDatabaseRoles}
            lineColor={lineColor}
            setIsHoveringOtherElement={setIsHoveringOtherElement}
          />
        </Box>
        <Box component="td">
          <Typography variant="body2" sx={{ color: lineColor }}>
            {account.status}
          </Typography>
        </Box>
        <Box component="td">
          <Typography variant="body2" sx={{ color: lineColor }}>
            {account.controlsApplied}
          </Typography>
        </Box>
        <Box component="td">
          <Typography variant="body2" sx={{ color: lineColor }}>
            {account.usersLast7Days}
          </Typography>
        </Box>
        <Box component="td">
          <Typography variant="body2" sx={{ color: lineColor }}>
            {account.lastUsedTime}
          </Typography>
        </Box>
      </StyledLocalAccountDiscoveryRow>
    )
  }
  return (
    <StyledLocalAccountDiscoveryRow>
      <Box component="td">
        <Typography variant="body2" sx={{ color: lineColor }}>
          {account.databaseAccount}
          {account.isOracleMaintained ? <sup>**</sup> : ''}
        </Typography>
      </Box>
      <Box component="td" style={{ flexDirection: 'row' }}>
        <LocalAccountDiscoveryRole
          roles={account.unifiedRoles}
          lineColor={lineColor}
          setIsHoveringOtherElement={setIsHoveringOtherElement}
        />
      </Box>
      <Box component="td">
        <Typography variant="body2" sx={{ color: lineColor }}>
          {account.status}
        </Typography>
      </Box>
      <Box component="td">
        <Typography variant="body2" sx={{ color: lineColor }}>
          {account.controlsApplied}
        </Typography>
      </Box>
      <Box component="td">
        <Typography variant="body2" sx={{ color: lineColor }}>
          {account.usersLast7Days}
        </Typography>
      </Box>
      <Box component="td">
        <Typography variant="body2" sx={{ color: lineColor }}>
          {account.lastUsedTime}
        </Typography>
      </Box>
    </StyledLocalAccountDiscoveryRow>
  )
}

const LocalAccountDiscoveryRow = ({ account, repoType }) => {
  const [isHoveringOtherElement, setIsHoveringOtherElement] = React.useState(false)
  const [isHoveringLine, setIsHoveringLine] = React.useState(false)

  if (!account.managedByCyral) {
    return createLine(account, repoType, 'red', setIsHoveringOtherElement)
  } else {
    return createLine(account, repoType, 'black', setIsHoveringOtherElement)
  }
}

export default LocalAccountDiscoveryRow
