import React from 'react'
import { Stack, Typography, Divider } from '@mui/material'
import NorthEastIcon from '@mui/icons-material/NorthEast'
import SouthEastIcon from '@mui/icons-material/SouthEast'
import { formatNumber } from '@jeeves/utils/formatNumber'

const ActivityChange = ({ change }) =>
  !isNaN(change) && (
    <Stack
      direction="row"
      spacing={0.5}
      sx={{
        bgcolor: change < 0 ? 'cyralColors.red.100' : 'cyralColors.green.100',
        px: 1,
        py: 0.25,
        alignItems: 'center',
      }}
    >
      <Typography
        variant="tag"
        sx={{ color: change < 0 ? 'cyralColors.red.500' : 'cyralColors.green.500' }}
      >
        {formatNumber(change)}
      </Typography>
      {change < 0 ? (
        <SouthEastIcon sx={{ color: 'cyralColors.red.300', fontSize: '14px' }} />
      ) : (
        <NorthEastIcon sx={{ color: 'cyralColors.green.300', fontSize: '14px' }} />
      )}
    </Stack>
  )

const ActivityItem = ({ activityTitle, activityData, activityChange }) => {
  return (
    <Stack>
      <Typography variant="h5" sx={{ color: 'text.secondary' }}>
        {activityTitle}
      </Typography>
      <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
        <Typography variant="h2" sx={{ color: 'text.primary' }}>
          {formatNumber(activityData)}
        </Typography>

        <ActivityChange change={activityChange} />
      </Stack>
    </Stack>
  )
}

export const Activity = ({ queryResponse, serviceAccountsConfigured }) => {
  const { data, loading } = queryResponse
  return (
    <Stack spacing={4}>
      <Stack spacing={2}>
        <Typography variant="h4" sx={{ color: 'text.primary' }}>
          Activity in the last 7 days
        </Typography>
        <Divider />
      </Stack>
      <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
        <ActivityItem
          activityTitle={'SSO Sessions'}
          activityData={data?.numSSOSessions}
          activityChange={data?.numSSOSessionsDelta}
        />

        <ActivityItem
          activityTitle={'Queries'}
          activityData={data?.numQueries}
          activityChange={data?.numQueriesDelta}
        />

        <ActivityItem
          activityTitle={'SSO Users'}
          activityData={data?.numSSOUsers}
          activityChange={data?.numSSOUsersDelta}
        />

        {serviceAccountsConfigured && (
          <ActivityItem
            activityTitle={'Apps & BI Tools Users'}
            activityData={data?.numAppsAndBIToolsUsers}
            activityChange={data?.numAppsAndBIToolsUsersDelta}
          />
        )}
      </Stack>
    </Stack>
  )
}
