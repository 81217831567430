import { useFieldArray, useFormContext } from 'react-hook-form'

import { FormControl, FormHelperText, IconButton, Stack } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'

import { Button, Input, InputLabel } from '@jeeves/new-components'
import { AwsIamFormSchema } from '../helpers'

const IamRolesField = () => {
  const {
    control,
    formState: { errors },
    register,
  } = useFormContext<AwsIamFormSchema>()

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'iamRoles',
  })

  const isRemoveDisabled = fields?.length <= 1

  return (
    <Stack spacing={1}>
      <InputLabel htmlFor="iamRoles" required error={Boolean(errors.iamRoles)}>
        IAM Role ARN(s)
      </InputLabel>

      <Stack id="iamRoles" spacing={1}>
        {fields.map((field, index) => {
          const error = errors?.iamRoles?.[index]?.value

          return (
            <Stack direction="row" spacing={1} key={field.id}>
              <FormControl variant="standard" error={Boolean(error)} sx={{ flexGrow: 1 }}>
                <Input type="text" inputProps={{ ...register(`iamRoles.${index}.value`) }} />
                <FormHelperText sx={{ typography: 'body2' }}>{error?.message}</FormHelperText>
              </FormControl>
              <IconButton
                disabled={isRemoveDisabled}
                onClick={() => remove(index)}
                sx={{ alignSelf: 'flex-start' }}
              >
                <DeleteIcon />
              </IconButton>
            </Stack>
          )
        })}
      </Stack>

      <Button
        variant="outlined"
        color="secondary"
        onClick={() => append({ value: '' })}
        sx={{ alignSelf: 'flex-start' }}
      >
        Add
      </Button>
    </Stack>
  )
}

export default IamRolesField
