import React from 'react'
import GeneralEventRow from './GeneralEventRow'

const EventRow = ({ eventType, ...rest }) => {
  const eventTypes = {
    general: <GeneralEventRow {...rest} />,
  }

  return eventTypes[eventType]
}

export default EventRow
