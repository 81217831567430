import React from 'react'
import { IconButton, InputAdornment, TextField } from '@material-ui/core'
import { Search } from '@material-ui/icons'

const SearchIcon = props => (
  <InputAdornment position="end">
    <IconButton aria-label="Search ETCD" {...props}>
      <Search />
    </IconButton>
  </InputAdornment>
)

export const EtcdSearchField = ({ onClick, ...props }) => (
  <TextField
    variant="outlined"
    label="Search"
    InputProps={{
      endAdornment: <SearchIcon onClick={onClick} />,
    }}
    {...props}
  />
)
