import React from 'react'
import IntegrationForm from '../IntegrationForm'

import usePopup from '@jeeves/components/PopupMessage/hooks/usePopup'

const Existing = ({ integration, putIntegration, deleteIntegration }) => {
  const { setPopup, popupTypes } = usePopup()
  const handleDelete = async () => {
    try {
      await deleteIntegration({ variables: { id: integration.id, purpose: 'authorization' } })
    } catch (e) {
      setPopup(popupTypes.ERROR, e.message)
    }
  }

  const handleSave = (name, apiToken) => {
    return putIntegration({
      variables: {
        id: integration.id,
        confExtensionInstance: {
          name,
          category: 'builtin',
          templateType: 'pagerduty',
          purpose: 'authorization',
          parameters: `{\"apiToken\":\"${apiToken}\"}`,
        },
      },
    })
  }

  return <IntegrationForm integration={integration} onDelete={handleDelete} onSave={handleSave} />
}

export default Existing
