import React, { useState } from 'react'
import ExpressClient from '../../../clients/express'
import { useAuth } from '../../../components/Auth'

const useEtcdViewer = () => {
  const { getTokenSilently } = useAuth()
  const ec = new ExpressClient(getTokenSilently)
  const [state, setState] = useState({
    etcdContent: '',
    isAllExpanded: null,
    page: 0,
    rowsPerPage: 100,
    order: 'asc',
    orderBy: 'name',
  })

  const handleRequestSort = (event, property) => {
    const orderBy = property
    let order = 'asc'
    if (state.orderBy === property && state.order === 'asc') {
      order = 'desc'
    }
    setState(state => ({ ...state, order, orderBy }))
  }

  const toggleIsAllExpanded = () => {
    setState(state => ({ ...state, isAllExpanded: !state.isAllExpanded }))
  }

  return {
    ec,
    state,
    setState,
    handleRequestSort,
    toggleIsAllExpanded,
  }
}

export default useEtcdViewer
