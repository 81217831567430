/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import React, { useState, useEffect, Fragment } from 'react'
import { Formik, Form } from 'formik'
import { withStyles } from '@material-ui/core/styles'

import { Paper } from '@jeeves/components/Primitives'

import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
  FileCopy as FileCopyIcon,
} from '@material-ui/icons'

import Card from '@material-ui/core/Card'
import InputAdornment from '@material-ui/core/InputAdornment'
import CardHeader from '@material-ui/core/CardHeader'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Avatar from '@material-ui/core/Avatar'
import { Typography } from '@material-ui/core'

import ExpressClient from '@jeeves/clients/express'
import axios from 'axios'
import { useAuth } from '@jeeves/components/Auth'
import usePopup from '@jeeves/components/PopupMessage/hooks/usePopup'
import { get as lodashGet } from 'lodash'
import { ButtonLoading } from '@jeeves/components/Loading'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Loading from '@jeeves/components/Loading'

const styles = theme => ({
  picture: {
    width: 128,
    height: 'auto',
  },
  icon: {
    padding: theme.spacing.unit / 2,
  },
  actions: {
    padding: '24px',
  },
  content: {
    padding: '24px',
  },
  title: {
    fontSize: '24px',
    padding: '24px',
  },
  updateButton: {
    padding: theme.spacing.unit,
  },
  jwtTextField: {
    padding: 0,
  },
})

const Personal = ({ classes, className, user, onUpdate, secondaryButton, reinitialize }) => {
  const [resetLoading, setResetLoading] = useState(false)
  const [resetDone, setResetDone] = useState(false)
  const [jwtVisible, setJwtVisible] = useState(false)
  const { getTokenSilently } = useAuth()
  const ec = new ExpressClient(getTokenSilently)
  const [jwt, setJwt] = useState('')
  const { showError } = usePopup()

  useEffect(() => {
    const getToken = async () => {
      try {
        setJwt(await getTokenSilently())
      } catch (e) {
        const popupMessage = lodashGet(e, 'response.data.error.message', '')
        showError(popupMessage)
        throw Error(popupMessage)
      }
    }
    getToken()
  }, [])

  return (
    <Fragment>
      <CardHeader
        titleTypographyProps={{ variant: 'h6' }}
        className={classes.title}
        title="Personal Information"
      />

      <Formik
        initialValues={{
          given_name: user.given_name,
          family_name: user.family_name,
          email: user.email,
          picture: user.picture,
        }}
        onSubmit={async (values, actions) => {
          actions.setSubmitting(true)
          try {
            await onUpdate(values)
            if (reinitialize) await reinitialize()
          } catch (e) {
            const popupMessage = lodashGet(e, 'response.data.error.message', '')
            showError(popupMessage)
          }
          actions.setSubmitting(false)
        }}
      >
        {({ values, handleChange, handleBlur, handleSubmit, isSubmitting, handleReset, dirty }) => (
          <Form>
            <CardContent className={classes.content}>
              <Grid container spacing={32}>
                <Grid item xs={12}>
                  <Grid container spacing={24} alignItems="center">
                    <Grid item xs={2}>
                      <Typography css={{ fontWeight: 'bold', textAlign: 'right' }}>
                        First Name
                      </Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <TextField
                        variant="outlined"
                        name="given_name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.given_name}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Typography css={{ fontWeight: 'bold', textAlign: 'right' }}>
                        Last Name
                      </Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <TextField
                        variant="outlined"
                        name="family_name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.family_name}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Typography css={{ fontWeight: 'bold', textAlign: 'right' }}>
                        Email
                      </Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <TextField
                        variant="outlined"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>

            <div css={{ padding: '8px 24px' }}>
              <Grid container spacing={32}>
                <Grid item xs={12}>
                  <Grid container spacing={24} alignItems="center" justify="flex-end">
                    <Grid item>
                      <Button
                        variant="contained"
                        className={classes.updateButton}
                        disabled={isSubmitting || !dirty}
                        css={{ boxShadow: 'none' }}
                        onClick={handleReset}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <div css={{ position: 'relative' }}>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          className={classes.updateButton}
                          disabled={isSubmitting || !dirty}
                          css={{ boxShadow: 'none' }}
                        >
                          Save
                        </Button>
                        {isSubmitting && <Loading></Loading>}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>

            <CardContent className={classes.content}>
              <Typography variant="caption" color="textSecondary">
                * We may require you to login again after you made changes to your personal
                information
              </Typography>
            </CardContent>
          </Form>
        )}
      </Formik>
    </Fragment>
  )
}

export default withStyles(styles)(Personal)
