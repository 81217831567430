import React from 'react'
import EditIcon from '@mui/icons-material/Edit'
import { Box, Stack, Typography, IconButton } from '@mui/material'
import Edit from './Edit'
import { ServiceIcon } from '@jeeves/new-components'

const EDITABLE_PORT_TYPES = ['mysql', 'mariadb', 'galera', 'sqlserver']

const SmartPort = ({ smartPort }) => {
  const { repoType, numBindings, port } = smartPort
  const [editOpen, setEditOpen] = React.useState(false)
  return (
    <Box
      sx={{
        border: 1,
        borderColor: 'cyralColors.grey.200',
        borderRadius: theme => theme.radii.base,
        p: 4,
      }}
    >
      <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
          <ServiceIcon type={repoType} sx={{ fontSize: '36px' }} />
          <Stack spacing={1}>
            <Typography variant="h4" sx={{ color: 'text.primary' }}>
              {port}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary', whiteSpace: 'nowrap' }}>
              {`${numBindings} ${numBindings > 1 ? 'repositories' : 'repository'}`}
            </Typography>
          </Stack>
        </Stack>
        {EDITABLE_PORT_TYPES.includes(smartPort.repoType) && (
          <IconButton onClick={() => setEditOpen(true)}>
            <EditIcon sx={{ color: 'secondary.main' }} />
          </IconButton>
        )}
      </Stack>
      <Edit open={editOpen} onClose={() => setEditOpen(false)} smartPort={smartPort} />
    </Box>
  )
}

export default SmartPort
