/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { useState } from 'react'
import {
  Grid,
  TextField,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core'

import { ButtonLoading } from '@jeeves/components/Loading'
import { Button, Dialog } from '@jeeves/components/Primitives'
import { useAuth } from '@jeeves/components/Auth'
import ExpressClient from '@jeeves/clients/express'

const Delete = ({
  element,
  elementMeta,
  updateElement = () => {},
  open,
  setOpen,
  onDelete = () => {},
}) => {
  const { name, type } = elementMeta
  const { getTokenSilently } = useAuth()
  const [submitting, setSubmitting] = useState(false)
  const ec = new ExpressClient(getTokenSilently)

  const [input, setInput] = useState('')
  const [error, setError] = useState(null)

  const handleOnClose = () => {
    setInput('')
    setOpen(false)
  }

  const handleValidationChange = e => {
    if (e.target.value !== name) {
      let typeLabel = type
      if (type === 'wrapper') typeLabel = 'sidecar'
      setError(`Confirmed ${typeLabel} name does not match. Please re-enter the ${typeLabel} name.`)
    } else {
      setError('')
    }
    setInput(e.target.value)
  }

  const urlSwitch = elementType => {
    let url = ''
    switch (elementType) {
      case 'wrapper':
        url = `/sidecars/${element.id}`
        break
      case 'policy':
        url = `/policies/${element.id}`
        break
      case 'repository':
        url = `/repos/${element.id}`
        break
      default:
        console.error('Delete.urlSwitch.elementType unexpected:', JSON.stringify(elementType))
    }
    return url
  }

  const handleOnDelete = async () => {
    const url = urlSwitch(type)
    setSubmitting(true)

    try {
      if (error === '') {
        await ec.delete(url).catch(e => console.log('handleOnDelete error: ', e))
        updateElement()
        handleOnClose()

        onDelete()
      } else {
        console.log('please check validation input')
      }
    } catch (e) {
      console.log('handleOnDelete failure ')
      console.log(e)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Dialog open={open} onClose={handleOnClose} fullWidth>
      <DialogTitle>Confirm Deletion</DialogTitle>
      <DialogContent>
        <Typography>
          This action is irreversible!
          <br />
          Are you sure you want to delete the {type === 'wrapper' ? 'sidecar' : type}&nbsp;
          <span css={{ fontWeight: 600 }}>{name}</span>?
        </Typography>
        <TextField
          margin="dense"
          label={`Re-enter the ${type === 'wrapper' ? 'sidecar' : type} name`}
          fullWidth
          onChange={handleValidationChange}
          name="confirmDelete"
          value={input}
        />
        <Typography color="secondary">{error}</Typography>
      </DialogContent>
      <DialogActions>
        <Grid container justify="space-between">
          <Button onClick={handleOnClose} color="primary">
            <span>Cancel</span>
          </Button>
          <div css={{ position: 'relative' }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleOnDelete}
              disabled={submitting}
            >
              Delete
            </Button>
            {submitting && <ButtonLoading />}
          </div>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default Delete
