import { Divider, Stack } from '@mui/material'

import { LastUpdatedBy } from './components/LastUpdatedBy'
import { PolicyTypes } from './components/PolicyTypes'

const AdditionalDetails = () => {
  return (
    <Stack
      spacing={4}
      divider={<Divider orientation="horizontal" flexItem />}
      sx={{
        paddingLeft: 2,
      }}
    >
      <LastUpdatedBy />
      <PolicyTypes />
    </Stack>
  )
}

export default AdditionalDetails
