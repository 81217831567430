import * as React from 'react'
import { useMutation, gql } from '@apollo/client'
import Typography from '@mui/material/Typography'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

import { Button, Dialog, DialogActions } from '@jeeves/new-components'
import { useRepositoryDetailContext } from '@jeeves/pages/RepositoryDetail/contexts/RepositoryDetailContext'
import { useUserAuthenticationContext } from '@jeeves/pages/RepositoryDetail/Tabs/UserAuthentication/contexts/UserAuthenticationContext'

const DELETE_USER_ACCOUNT = gql`
  mutation DeleteUserAccount($repoId: ID!, $userAccountId: ID!) {
    deleteUserAccount(repoId: $repoId, userAccountId: $userAccountId) {
      repo {
        id
        ... on RepoWithSSOFields {
          userAccounts {
            id
          }
        }
      }
    }
  }
`

const DeleteUserAccountModal = ({ open, closeModal }) => {
  const { selectedUserAccountId, onSelectUserAccount } = useUserAuthenticationContext()

  const { repoId, repoType } = useRepositoryDetailContext()
  const [deleteUserAccount, { data, loading, error }] = useMutation(DELETE_USER_ACCOUNT, {
    variables: {
      repoId,
      userAccountId: selectedUserAccountId,
    },
    update(cache) {
      cache.gc()
    },
    onQueryUpdated(query, __, lastDiff) {
      if (query.queryName !== 'RepoDetails') {
        return false
      }

      const userAccounts = lastDiff?.result?.repo?.userAccounts

      const selectedUserIndex = userAccounts.findIndex(
        account => account.id === selectedUserAccountId
      )

      const newSelectedUserAccount =
        userAccounts?.[selectedUserIndex + 1] ?? userAccounts?.[selectedUserIndex - 1]

      if (newSelectedUserAccount) {
        onSelectUserAccount(newSelectedUserAccount?.id || '')
      }

      return true
    },
    onCompleted: data => {
      closeModal()
    },
  })

  const onDelete = async () => {
    try {
      await deleteUserAccount()
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <Dialog onClose={closeModal} open={open}>
      <DialogTitle variant="h3">
        {repoType === 's3' ? 'Delete IAM Role' : 'Delete Database Account'}
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" sx={{ color: 'text.secondary' }}>
          {`Are you sure you want to delete this ${
            repoType === 's3' ? 'IAM role' : 'database account'
          }?`}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={closeModal} autoFocus>
          Cancel
        </Button>
        <Button variant="outlined" color="error" onClick={onDelete} loading={loading} autoFocus>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteUserAccountModal
