import { Dispatch, SetStateAction } from 'react'
import { Box } from '@mui/material'

import { Button } from '@jeeves/new-components'

import SetupWizardStepper from './SetupWizardStepper'
import useSetupWizardActions from './useSetupWizardActions'

interface Props {
  currentStep: number
  stepsNumber: number
  setCurrentStep: Dispatch<SetStateAction<number>>
}

const SetupWizardActions = ({ currentStep, stepsNumber, setCurrentStep }: Props) => {
  const { isNotLastStep, loading, onClickContinue, onClickBack } = useSetupWizardActions({
    currentStep,
    stepsNumber,
    setCurrentStep,
  })

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        marginTop: 'auto',
      }}
    >
      <Button color="secondary" variant="outlined" loading={loading} onClick={onClickBack}>
        Back
      </Button>

      <SetupWizardStepper
        currentStep={currentStep}
        stepsNumber={stepsNumber}
        setCurrentStep={setCurrentStep}
      />

      <Button color="primary" variant="contained" loading={loading} onClick={onClickContinue}>
        {isNotLastStep ? 'Continue' : 'Launch Cyral'}
      </Button>
    </Box>
  )
}

export default SetupWizardActions
