import { FormProvider } from 'react-hook-form'

import { Box, Stack, Typography } from '@mui/material'

import { Button } from '@jeeves/new-components'
import { FragmentType, graphql, useFragment } from '@jeeves/graphql'

import { AliasInput, NameInput, TagsInput } from './components'
import { useGeneral } from './useGeneral'

export const General_queryFragment = graphql(`
  fragment General_query on Query {
    ...useGeneral_query
  }
`)

interface GeneralProps {
  query: FragmentType<typeof General_queryFragment>
}

const General = ({ query: queryProp }: GeneralProps) => {
  const query = useFragment(General_queryFragment, queryProp)

  const { methods, loading, tags, handleUpdateTags, onSubmit } = useGeneral({ query })

  const {
    formState: { isDirty },
    reset,
  } = methods

  const isDisabled = loading || !isDirty

  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        onSubmit={onSubmit}
        sx={{
          border: 1,
          borderColor: 'cyralColors.grey.200',
          p: 4,
          borderRadius: theme => theme.radii.base,
        }}
      >
        <Stack spacing={6}>
          <Typography variant="h3" sx={{ color: 'text.primary' }}>
            General
          </Typography>

          <Stack spacing={4} sx={{ width: '50%' }}>
            <NameInput />

            <TagsInput
              inputLabel="Tags"
              tags={tags}
              isSubmitting={loading}
              onDelete={handleUpdateTags}
              onAdd={handleUpdateTags}
            />

            <AliasInput />
          </Stack>

          <Stack direction="row" sx={{ justifyContent: 'flex-end' }} spacing={2}>
            <Button variant="text" disabled={isDisabled} onClick={() => reset()}>
              Cancel
            </Button>
            <Button type="submit" disabled={isDisabled} loading={loading}>
              Save
            </Button>
          </Stack>
        </Stack>
      </Box>
    </FormProvider>
  )
}

export default General
