import { Box, Container, Stack, ThemeProvider, Typography } from '@mui/material'
import { ChromeReaderModeTwoTone, Code, SupportAgentTwoTone } from '@mui/icons-material'

import { useEntitlements } from '@jeeves/hooks'
import { getDocsURL } from '@jeeves/utils/docsURL'
import { v5Theme } from '@jeeves/theme'

import { AllowCyralAccess, HelpAndSupportCard, HelpAndSupportCardIcon } from './components'
import { HelpAndSupportCardProps } from './components/HelpAndSupportCard'

const helpAndSupportCards: HelpAndSupportCardProps[] = [
  {
    helperText: 'A comprehensive guide for using Cyral',
    href: getDocsURL(),
    icon: (
      <HelpAndSupportCardIcon>
        <ChromeReaderModeTwoTone fontSize="inherit" />
      </HelpAndSupportCardIcon>
    ),
    title: 'Read the docs',
  },
  {
    helperText: 'Submit a question or report an issue to our support team',
    href: 'https://support.cyral.com/support/tickets/new',
    icon: (
      <HelpAndSupportCardIcon>
        <SupportAgentTwoTone fontSize="inherit" />
      </HelpAndSupportCardIcon>
    ),
    title: 'Submit a ticket',
  },
  {
    helperText: '',
    href: getDocsURL({ docsPath: 'api-ref' }),
    icon: (
      <HelpAndSupportCardIcon>
        <Code fontSize="inherit" />
      </HelpAndSupportCardIcon>
    ),
    title: 'API Docs',
  },
]

const Help = () => {
  const { enableSupportLoginToggle } = useEntitlements()

  return (
    <ThemeProvider theme={v5Theme}>
      <Container maxWidth="xl">
        <Stack spacing={4} sx={{ alignItems: 'center', justifyContent: 'center' }}>
          <Typography variant="h2">We're here to help!</Typography>

          <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 10 }}>
            {helpAndSupportCards.map(card => (
              <HelpAndSupportCard key={card.title} {...card} />
            ))}
          </Box>

          {enableSupportLoginToggle && <AllowCyralAccess />}
        </Stack>
      </Container>
    </ThemeProvider>
  )
}

export default Help
