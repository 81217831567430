import { TablePagination as TablePaginationMui, styled } from '@mui/material'

const TablePagination = styled(TablePaginationMui)(({ theme }) => ({
  border: 'none',
  color: theme.palette.cyralColors.grey[400],
  fontSize: '14px',
  '& .MuiSelect-select': {
    border: 'solid 1px',
    borderColor: theme.palette.cyralColors.grey[200],
    borderRadius: theme.radii.base,
    '&:focus': {
      borderRadius: theme.radii.base,
    },
  },
  '& .MuiTablePagination-spacer': {
    display: 'none',
  },
  '& .MuiSvgIcon-root': {
    fill: theme.palette.cyralColors.grey[300],
    fontSize: '18px',
    top: '6px',
  },
  '& .MuiTablePagination-selectLabel': {
    color: theme.palette.text.secondary,
    margin: 0,
    ...theme.typography.h6,
  },
  '& .MuiTablePagination-toolbar': {
    padding: theme.spacing(2, 0),
  },
  '& .MuiTablePagination-displayedRows': {
    margin: 0,
  },
  '& .MuiInputBase-root': {
    margin: theme.spacing(0, 2),
  },
})) as typeof TablePaginationMui

export default TablePagination
