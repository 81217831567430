import { styled } from '@mui/material/styles'
import { Box, Typography, Stack } from '@mui/material'

import { Button } from '@jeeves/new-components'
import Header from '../components/Header'
import LoggingIntegrationIcon from '../../LoggingIntegrationIcon'

const integrations = [
  {
    label: 'CloudWatch',
    id: 'CloudWatchIntegration',
  },
  {
    label: 'Datadog',
    id: 'DatadogIntegration',
  },
  {
    label: 'Splunk',
    id: 'SplunkIntegration',
  },
  {
    label: 'Custom',
    id: 'CustomLoggingIntegration',
  },
  {
    label: 'ELK',
    id: 'ELKIntegration',
  },
  {
    label: 'Sumo Logic',
    id: 'SumoLogicIntegration',
  },
]

const Card = styled(Box)(({ theme }) => ({
  outline: `1px solid ${theme.palette.cyralColors.grey[200]}`,
  borderRadius: theme.radii.base,
  padding: theme.spacing(2),
  '&:hover': {
    outline: `2px solid ${theme.palette.primary.main}`,
  },
}))

const SelectIntegrationCard = ({ label, id, onClick }) => {
  return (
    <Card onClick={onClick} sx={{ cursor: 'pointer' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <LoggingIntegrationIcon integrationType={id} />
        <Typography variant="h4">{label}</Typography>
      </Box>
    </Card>
  )
}

const SelectIntegration = ({ setIntegration, onClose }) => {
  return (
    <Stack spacing={4}>
      <Header title="New Logging Integration" onClose={onClose} />
      <Stack spacing={1}>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          Select an integration platform.
        </Typography>
        <Box
          sx={{
            display: 'grid',
            gap: 2,
            gridTemplateColumns: 'repeat(2, 1fr)',
          }}
        >
          {integrations.map(integration => (
            <SelectIntegrationCard
              onClick={() =>
                setIntegration({
                  __typename: integration.id,
                })
              }
              key={integration.id}
              {...integration}
            />
          ))}
        </Box>
      </Stack>
      <Box sx={{ display: 'flex', justifyContent: 'end' }}>
        <Button variant="text" color="secondary" onClick={onClose}>
          Cancel
        </Button>
      </Box>
    </Stack>
  )
}

export default SelectIntegration
