import { useQuery, gql, NetworkStatus } from '@apollo/client'

const ACCESS_PORTAL = gql`
  query User(
    $first: Int
    $after: String
    $filters: IdentityReposFilters
    $accessibility: ReposAccessibilityState!
  ) {
    user {
      ... on ControlPlaneUser {
        id
        repos(first: $first, after: $after, filters: $filters, accessibility: $accessibility) {
          edges {
            node {
              id
              name
              type
              tags
              ...MongoAccordionContent_RepoFragment
              config {
                authentication {
                  allowNativeAuthentication
                  otpDbuser
                }
                TLS {
                  enableClientSidecarTLS
                }
              }
              ... on MongoDBReplicaSetRepo {
                replicaSetName
              }
            }
            accessibleUserAccounts {
              edges {
                node {
                  id
                  name
                  ... on MongoDBUserAccount {
                    authenticationDatabase
                  }
                  ...StepTwo_UserAccountFragment
                }
                validUntil
                accessRestrictionSummaries {
                  name
                  type
                }
              }
            }
            accessPortalBindingRelationship {
              edge {
                node {
                  id
                  ... on ClusterBinding {
                    boundListenersRelationship {
                      edges {
                        node {
                          id
                          port
                        }
                      }
                    }
                  }
                  ... on S3Binding {
                    listenerSet {
                      proxyListener {
                        id
                        port
                      }
                      browserListener {
                        id
                        port
                      }
                    }
                  }
                  ... on SingleListenerBinding {
                    listener {
                      id
                      port
                    }
                  }
                }
                sidecar {
                  name
                  endpoint
                  userEndpoint
                }
              }
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    }
  }
`

const useAccessPortal = options => {
  const { data, loading, error, fetchMore, refetch, networkStatus } = useQuery(
    ACCESS_PORTAL,
    options
  )

  return {
    loading,
    error,
    data: {
      repoEdgesUserCanAccess: data?.user?.repos?.edges,
      hasNextPage: data?.user?.repos?.pageInfo?.hasNextPage,
    },
    getNextPage:
      data?.user?.repos?.pageInfo?.hasNextPage &&
      (() => fetchMore({ variables: { after: data?.user?.repos?.pageInfo?.endCursor } })),
    refetch,
    fetchingMore: networkStatus === NetworkStatus.fetchMore,
    firstLoading: networkStatus === NetworkStatus.loading,
  }
}

export default useAccessPortal
