import * as React from 'react'
import { styled } from '@mui/material/styles'

export const TableContainer = styled('div', {
  name: 'V3Table',
  slot: 'Root',
})(({ theme }) => ({
  table: {
    borderCollapse: 'separate',
    borderSpacing: 0,
    width: '100%',
  },

  'td, th': {
    textAlign: 'left',
    padding: theme.spacing(1, 2),
  },
  th: {
    ...theme.typography.h5,
    color: theme.palette.text.secondary,

    backgroundColor: theme.palette.cyralColors.grey[100],

    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderTopStyle: 'solid',
    borderBottomStyle: 'solid',
    borderColor: theme.palette.cyralColors.grey[200],
  },
  tr: {
    '& td': {
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderColor: theme.palette.cyralColors.grey[200],
    },

    '& th:first-of-type': {
      borderLeftWidth: '1px',
      borderLeftStyle: 'solid',
      borderTopLeftRadius: theme.radii.base,
      borderBottomLeftRadius: theme.radii.base,
    },
  
    '& th:last-of-type': {
      borderRightWidth: '1px',
      borderRightStyle: 'solid',
      borderTopRightRadius: theme.radii.base,
      borderBottomRightRadius: theme.radii.base,
    },

    ':last-child': {
      '& td': {
        border: 'none'
      }
    },
  },
}))