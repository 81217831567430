import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const LookerIcon = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 256 413" preserveAspectRatio="xMidYMid">
      <path
        d="M127.12849 0c-13.3307.00585-25.57249 7.36006-31.83796 19.1266-6.26548 11.76655-5.53408 26.02876 1.90221 37.09263l15.41354-15.3918a14.95702 14.95702 0 0 1-.7609-4.76102c0-8.4046 6.81328-15.21788 15.21789-15.21788 8.4046 0 15.21788 6.81328 15.21788 15.21788 0 8.4046-6.81328 15.21788-15.21788 15.21788a14.93526 14.93526 0 0 1-4.73929-.7609l-15.3918 15.3918c12.81764 8.69328 29.75442 8.23276 42.08071-1.1442 12.3263-9.37696 17.28985-25.57664 12.33234-40.24933C156.3877 9.84896 142.61604-.02058 127.12849 0Z"
        fill="#34A853"
      />
      <path
        d="M112.7803 105.11211c.0235-12.18329-3.92202-24.04273-11.2396-33.7837L81.54007 91.30733c6.25492 11.44042 4.004 25.66048-5.47844 34.60981l10.86992 26.56607c16.10557-10.37254 25.84121-28.21439 25.84876-47.37109Z"
        fill="#FBBC04"
      />
      <path
        d="M56.8871 133.78695h-.52176c-12.2678.00106-23.1795-7.79636-27.1525-19.403-3.973-11.60664-.12852-24.45518 9.56656-31.97211 9.69508-7.51693 23.09627-8.03956 33.34732-1.3005L91.9317 61.30635c-19.25798-15.61268-46.45388-16.81704-67.01656-2.96778C4.35246 72.18782-4.75374 97.84215 2.4755 121.55637c7.22924 23.7142 29.0982 39.9258 53.88984 39.94887a57.6763 57.6763 0 0 0 11.39167-1.13048l-10.86992-26.58781Z"
        fill="#EA4335"
      />
      <path
        d="M127.88938 156.76595a127.98238 127.98238 0 0 0-36.95771 5.41322L106.78 200.8978a87.37236 87.37236 0 0 1 21.08763-2.5653c41.18314.012 76.6234 29.1124 84.64871 69.50604 8.02532 40.39364-13.59922 80.83155-51.65 96.58557-38.05078 15.75403-81.9313 2.43694-104.80821-31.80775C33.18122 298.3717 37.67876 252.736 66.80046 223.61594a87.2202 87.2202 0 0 1 20.58762-15.21788L71.71366 169.7881c-58.42709 28.61441-86.09038 96.509-64.2982 157.80834 21.79219 61.29934 86.1049 96.49605 149.48293 81.80821 63.37805-14.68784 105.65252-74.5861 98.25917-139.22237-7.39336-64.63627-62.10175-113.44124-127.15948-113.43808l-.1087.02174Z"
        fill="#4285F4"
      />
    </SvgIcon>
  )
}

export default LookerIcon
