/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import React, { useState, useEffect, Fragment } from 'react'
import { useForm } from 'react-hook-form'
import { usePopup } from '@jeeves/hooks'
import { TextField, MenuItem, Typography } from '@material-ui/core'
import IDPSelectWithController from '@jeeves/pages/RepositoryDetail/Tabs/Advanced/components/IDPSelectWithController'
import Button from '@material-ui/core/Button'

const Preferences = ({}) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty, isSubmitting },
  } = useForm({
    defaultValues: { idpPreference: window.localStorage.getItem('idpPreference') || 'None' },
  })

  const { showSuccess } = usePopup()

  const onSubmit = ({ idpPreference }) => {
    window.localStorage.setItem('idpPreference', idpPreference === 'None' ? '' : idpPreference)
    showSuccess('Success!')
  }

  return (
    <Fragment>
      <div css={{ padding: '24px 24px 12px 24px' }}>
        <Typography variant="h6">Preferences</Typography>
        <Typography css={{ fontSize: '1rem', marginTop: '0.75rem' }}>Identity Provider</Typography>
      </div>
      <div css={{ padding: '0 8px 24px 24px' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <IDPSelectWithController name="idpPreference" control={control} includeNone />
          <div css={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div css={{ padding: '12px' }}>
              <Button
                variant="contained"
                disabled={isSubmitting || !isDirty}
                css={{ boxShadow: 'none', padding: '0.5rem' }}
                onClick={() => reset()}
              >
                Cancel
              </Button>
            </div>
            <div css={{ padding: '12px' }}>
              <Button
                variant="contained"
                disabled={isSubmitting || !isDirty}
                type="submit"
                css={{ boxShadow: 'none', padding: '0.5rem' }}
              >
                Save
              </Button>
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  )
}

export default Preferences
