import { gql, useMutation } from '@apollo/client'
import { Stack, Box, DialogContent, DialogTitle, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import { Dialog, Button, Input, DialogActions, useToast } from '@jeeves/new-components'
import { REPOS_WITH_UTILITIES } from '@jeeves/pages/Repositories/hooks/useReposWithUtilities'
import { getGraphQLErrorMessage } from '@jeeves/utils/helpers'

const DELETE_REPO = gql`
  mutation DeleteRepo($deleteRepoId: ID!) {
    deleteRepo(id: $deleteRepoId) {
      deletedRepoId
    }
  }
`

const Delete = ({ onClose, repo, open }) => {
  const history = useHistory()
  const { toast } = useToast()

  const {
    reset,
    register,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      confirmation: '',
    },
  })

  const confirmation = watch('confirmation')

  const [deleteRepo] = useMutation(DELETE_REPO, {
    refetchQueries: [REPOS_WITH_UTILITIES],
    onError: error => {
      toast({
        variant: 'error',
        description:
          getGraphQLErrorMessage(error) ||
          'An error occurred while deleting this repository, please try again later.',
      })
    },
    onCompleted: () => {
      history.push('/repositories')
    },
  })

  const onSubmit = async () => {
    await deleteRepo({ variables: { deleteRepoId: repo?.id } })
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionProps={{
        onExited: () => reset(),
      }}
    >
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>
          <Typography variant="h3" sx={{ color: 'text.primary' }}>
            Delete Data Repository
          </Typography>
        </DialogTitle>

        <DialogContent>
          <Stack spacing={4}>
            <Typography component="span" variant="h6" sx={{ color: 'text.secondary' }}>
              Are you sure you want to delete this data repository?
              <Typography
                component="span"
                variant="h6"
                sx={{ color: 'error.main', fontStyle: 'italic' }}
              >
                This action can not be undone.
              </Typography>
            </Typography>
            <Stack spacing={1}>
              <Typography sx={{ color: 'text.secondary' }} variant="h6">
                Please type{' '}
                <Typography component="span" sx={{ color: 'primary.main' }} variant="h6">
                  {repo?.name}
                </Typography>{' '}
                to confirm
              </Typography>
              <Input inputProps={{ ...register('confirmation') }}></Input>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Stack direction="row" spacing={2} sx={{ justifyContent: 'flex-end' }}>
            <Button variant="text" onClick={onClose}>
              Cancel
            </Button>
            <Button
              loading={isSubmitting}
              variant="outlined"
              type="submit"
              disabled={repo?.name !== confirmation.trim()}
              color="error"
            >
              Delete
            </Button>
          </Stack>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default Delete
