import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'

import { Button } from '@jeeves/new-components'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'

const BackButton = () => {
  const { id } = useParams()
  const policiesMainPageUrl = `/repositories/${id}/policies`

  return (
    <Button
      component={Link}
      to={policiesMainPageUrl}
      variant="text"
      color="primary"
      startIcon={<KeyboardArrowLeftIcon />}
      sx={{ color: 'text.primary' }}
    >
      View Policies
    </Button>
  )
}

export default BackButton
