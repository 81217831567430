import React from 'react'
import { Snackbar } from '@material-ui/core'

import PopupWrapper from './components/PopupWrapper'
import usePopup from './hooks/usePopup'

const PopupMessage = () => {
  const { clearPopup, isDisplay, displayTime } = usePopup()

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    clearPopup()
  }

  return (
    <>
      <Snackbar
        key={new Date().getTime()}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={isDisplay}
        autoHideDuration={displayTime}
        onClose={handleClose}
      >
        <PopupWrapper onClose={handleClose} />
      </Snackbar>
    </>
  )
}

export default PopupMessage
