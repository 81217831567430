const popupTypes = {
  SUCCESS: 'success',
  ERROR: 'error',
}

const defaultMessages = {
  success: 'Success!',
  error: 'Input error. Please try again.',
}

const defaultDisplayTimes = {
  success: 2000,
  error: 6000,
}

export { popupTypes, defaultMessages, defaultDisplayTimes }
