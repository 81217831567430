import * as React from 'react'
import { NetworkStatus } from '@apollo/client'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'

import { useRepositoryDetailContext } from '@jeeves/pages/RepositoryDetail/contexts/RepositoryDetailContext'

import { GrantedApprovals } from './components/GrantedApprovals'
import { ConfigureAutoApprovals } from './components/ConfigureAutoApprovals'
import { PendingApprovalsAccordion } from './components/PendingApprovalsAccordion'
import { PendingAmendmentsAccordion } from './components/PendingAmendmentsAccordion'

import useApprovals from './useApprovals'
import useApprovalTypesExist from './useApprovalTypesExist'

const ApprovalsMain = ({ userAccounts }) => {
  const [pendingApprovalsSearchValue, setPendingApprovalsSearchValue] = React.useState('')
  const [pendingAmendmentsSearchValue, setPendingAmendmentsSearchValue] = React.useState('')
  const [grantedApprovalsSearchValue, setGrantedApprovalsSearchValue] = React.useState('')
  const { repoId } = useRepositoryDetailContext()
  const { pendingApprovalsExist, grantedApprovalsExist, pendingAmendmentsExist } =
    useApprovalTypesExist(repoId)

  const pendingApprovalsQuery = useApprovals({
    variables: {
      repoId,
      filter: {
        status: 'PENDING',
        isAmendment: {
          eq: false,
        },
        identity: {
          name: {
            containsCaseInsensitive: pendingApprovalsSearchValue,
          },
        },
      },
      first: 10,
    },
  })

  const pendingAmendmentsQuery = useApprovals({
    variables: {
      repoId,
      filter: {
        status: 'PENDING',
        isAmendment: {
          eq: true,
        },
        identity: {
          name: {
            containsCaseInsensitive: pendingAmendmentsSearchValue,
          },
        },
      },
      first: 10,
    },
  })

  const grantedApprovalsQuery = useApprovals({
    variables: {
      repoId,
      filter: {
        status: 'GRANTED',
        identity: {
          name: {
            containsCaseInsensitive: grantedApprovalsSearchValue,
          },
        },
      },
      first: 25,
    },
  })

  // This will only be true when loading queries for the first time
  const loading =
    pendingApprovalsQuery.networkStatus === NetworkStatus.loading ||
    grantedApprovalsQuery.networkStatus === NetworkStatus.loading ||
    pendingAmendmentsQuery.networkStatus === NetworkStatus.loading

  return (
    <Box sx={{ px: 8, py: 4 }}>
      <Stack spacing={4}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="body1" sx={{ color: 'text.secondary' }}>
            View approvals that were completed via the Just-In-Time Access Approval workflow.
          </Typography>

          <ConfigureAutoApprovals userAccounts={userAccounts} />
        </Box>

        {loading ? (
          <Loading />
        ) : (
          <React.Fragment>
            {/* As long as pending approvals exist in the cache, render the accordion. We can't
            depend on the 'data' returned by the useQuery call because the search value that the
            user has input might return an empty list of pending approvals if none fit the search
            criteria. However, this doesn't mean that there are no outstanding pending approvals. */}
            {pendingApprovalsExist && (
              <PendingApprovalsAccordion
                pendingApprovalsConnection={pendingApprovalsQuery?.data?.repo?.approvals}
                fetchMore={pendingApprovalsQuery.fetchMore}
                loadingNextPage={pendingApprovalsQuery.networkStatus === NetworkStatus.fetchMore}
                refetching={pendingApprovalsQuery.networkStatus === NetworkStatus.setVariables}
                setSearchValue={setPendingApprovalsSearchValue}
              />
            )}

            {pendingAmendmentsExist && (
              <PendingAmendmentsAccordion
                pendingAmendmentsConnection={pendingAmendmentsQuery?.data?.repo?.approvals}
                fetchMore={pendingAmendmentsQuery.fetchMore}
                loadingNextPage={pendingAmendmentsQuery.networkStatus === NetworkStatus.fetchMore}
                refetching={pendingAmendmentsQuery.networkStatus === NetworkStatus.setVariables}
                setSearchValue={setPendingAmendmentsSearchValue}
              />
            )}

            <GrantedApprovals
              grantedApprovalsConnection={grantedApprovalsQuery?.data?.repo?.approvals}
              grantedApprovalsExist={grantedApprovalsExist}
              fetchMore={grantedApprovalsQuery.fetchMore}
              loadingNextPage={grantedApprovalsQuery.networkStatus === NetworkStatus.fetchMore}
              refetching={grantedApprovalsQuery.networkStatus === NetworkStatus.setVariables}
              setSearchValue={setGrantedApprovalsSearchValue}
            />
          </React.Fragment>
        )}
      </Stack>
    </Box>
  )
}

const Loading = () => {
  return (
    <Stack spacing={2} sx={{ alignItems: 'center' }}>
      <CircularProgress size={64} />
      <Typography variant="h6" sx={{ color: 'text.secondary' }}>
        Loading approvals...
      </Typography>
    </Stack>
  )
}

export default ApprovalsMain
