import * as React from 'react'
import { useForm, Controller, FormProvider } from 'react-hook-form'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

import { Button, Input, InputLabel } from '@jeeves/new-components'

import ConfigInstructions from './components/ConfigInstructions'

const HashicorpVault = ({ databaseAccountInfo, handleMutateUserAccount, footer }) => {
  const methods = useForm({
    defaultValues: {
      path: databaseAccountInfo?.credentials?.path ?? '',
      isDynamicUserAccount: databaseAccountInfo?.credentials?.isDynamicUserAccount ?? false,
    },
  })

  const { control, register, handleSubmit, formState } = methods
  const { errors } = formState

  const onSubmit = async ({ path, isDynamicUserAccount }) => {
    const userAccount = {
      ...databaseAccountInfo,
      credentials: {
        type: databaseAccountInfo.credentials.type,
        path,
        isDynamicUserAccount,
      },
    }

    await handleMutateUserAccount(userAccount)
  }

  return (
    <FormProvider {...methods}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4}>
          {/* <ConfigInstructions /> */}

          <FormControl variant="standard" error={Boolean(errors.path)}>
            <InputLabel htmlFor="secretPath">Secret Path</InputLabel>
            <Input
              id="secretPath"
              inputProps={{ ...register('path', { required: 'This field is required.' }) }}
              fullWidth
            />
            <FormHelperText
              sx={{
                typography: 'body2',
              }}
              error={!!errors?.path}
            >
              {errors?.path?.message ||
                'Specify the secret path for the secret you created in your HashiCorp Vault.'}
            </FormHelperText>
          </FormControl>

          <FormControlLabel
            control={
              <Controller
                name="isDynamicUserAccount"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    checked={field.value}
                    onChange={e => field.onChange(e.target.checked)}
                    disableRipple
                  />
                )}
              />
            }
            label="Username is dynamic"
          />

          {footer}
        </Stack>
      </Box>
    </FormProvider>
  )
}

export default HashicorpVault
