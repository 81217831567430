import { Alert, Typography } from '@mui/material'

const SidecarInstanceDetailsUnavailable = () => {
  return (
    <Alert
      severity="info"
      sx={{
        color: 'cyralColors.yellow.500',
        backgroundColor: 'cyralColors.yellow.100',
        '& .MuiAlert-icon': {
          color: 'inherit',
        },
      }}
    >
      <Typography variant="h6" sx={{ color: 'cyralColors.yellow.500' }}>
        Details are only available for sidecar instances running on v4.10 and later.
      </Typography>
    </Alert>
  )
}

export default SidecarInstanceDetailsUnavailable
