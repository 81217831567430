/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { useState, useEffect } from 'react'
import { IconButton, Fade, MenuItem } from '@material-ui/core/'
import EditIcon from '@material-ui/icons/Edit'

import { ButtonLoading } from '@jeeves/components/Loading'
import { useAuth } from '@jeeves/components/Auth'
import { Button } from '@jeeves/components/Primitives'
import {
  DeleteIntegrationButton,
  IntegrationCard,
  IntegrationEditActions,
  IntegrationFieldList,
  IntegrationHeader,
  IntegrationTitle,
  Label,
  LabelValuePair,
  TextField,
  Value,
} from '../../../primitives'

// TO DO: better front end validation for integrations (all)

const AddForm = ({ integration = null, onSave, onDelete, onCancel, creating = false }) => {
  const [id, setId] = useState('')
  const [name, setName] = useState('')
  const [server, setServer] = useState('')
  const [authMethod, setAuthMethod] = useState('')
  const [editing, setEditing] = useState(false)
  const [saving, setSaving] = useState(false)
  const { hasPermission } = useAuth()

  const configureIntegration = () => {
    if (integration) {
      setId(integration.id)
      setName(integration.integration.name)
      setServer(integration.integration.server)
      setAuthMethod(integration.integration.authMethod)
    } else {
      setId('')
      setName('')
      setServer('')
      setAuthMethod('')
    }
  }

  const configureEditing = () => {
    if (creating) {
      setEditing(true)
    }
  }

  useEffect(() => {
    configureIntegration()
    configureEditing()
  }, []) // eslint-disable-line

  const handleCancelEditing = () => {
    setEditing(false)

    if (onCancel) {
      onCancel()
    }
  }

  const handleOnSave = async () => {
    setSaving(true)
    try {
      await onSave({
        name,
        server,
        authMethod,
      })
    } catch (e) {
      console.log({ e })
    } finally {
      setSaving(false)
      setEditing(false)
    }
  }

  return (
    <IntegrationCard>
      {!editing && (
        <Fade in={!editing}>
          <div>
            <IntegrationHeader>
              <IntegrationTitle gutterBottom component="h2">
                {name}
              </IntegrationTitle>
              <div css={{ flexGrow: 1 }} />
              <IconButton
                onClick={() => setEditing(true)}
                disabled={!hasPermission('integrations:update')}
              >
                <EditIcon />
              </IconButton>
              <DeleteIntegrationButton
                onDelete={onDelete}
                disabled={!hasPermission('integrations:delete')}
              />
            </IntegrationHeader>
            <LabelValuePair>
              <Label>ID</Label>
              <Value>{id}</Value>
            </LabelValuePair>
            <LabelValuePair>
              <Label>Server</Label>
              <Value>{server}</Value>
            </LabelValuePair>
          </div>
        </Fade>
      )}
      {editing && (
        <Fade in={editing}>
          <div>
            <IntegrationFieldList>
              <TextField
                value={name}
                label="Integration Name"
                onChange={e => setName(e.target.value)}
                autofocus
              />
              <TextField value={server} label="Server" onChange={e => setServer(e.target.value)} />
              <TextField
                multiline
                variant="outlined"
                rows={8}
                value={authMethod}
                label="Method section of auto_auth config file"
                onChange={e => setAuthMethod(e.target.value)}
                css={css`
                  & textarea {
                    font-family: 'Monospaced', 'Monospace', monospace;
                  }
                  margin-bottom: 10px;
                `}
              >
                {['AWS', 'Kubernetes'].map(option => (
                  <MenuItem value={option}>{option}</MenuItem>
                ))}
              </TextField>
            </IntegrationFieldList>
            <IntegrationEditActions>
              <Button onClick={handleCancelEditing} color="primary" disabled={saving}>
                Cancel
              </Button>
              <div css={theme => ({ position: 'relative', marginLeft: theme.spacing[5] })}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={saving}
                  onClick={handleOnSave}
                >
                  Save
                </Button>
                {saving && <ButtonLoading />}
              </div>
            </IntegrationEditActions>
          </div>
        </Fade>
      )}
    </IntegrationCard>
  )
}

export default AddForm
