import React from 'react'

import { ScreenshotLayout } from '../../../components'
import SqlDeveloperConnectionScreen from './SqlDeveloper.png'

const SqlDeveloper = () => {
  return (
    <ScreenshotLayout
      imgSrc={SqlDeveloperConnectionScreen}
      title="Connecting with SQL Developer"
      steps={[
        'In SQL Developer, click on the plus icon in the top left corner to add a new database connection.',
        'Fill out the highlighted fields with the connection info displayed above.',
      ]}
    />
  )
}

export default SqlDeveloper
