import * as React from 'react'
import { Link } from 'react-router-dom'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

import { Button, InlineCode } from '@jeeves/new-components'
import { useWindowFocus } from '@jeeves/hooks'

const StepThree = ({
  prevStep,
  nextStep,
  fetchGroups,
  setDatabaseAccountInfo,
  samlIntegrations,
  refetch,
}) => {
  const [checked, setChecked] = React.useState(fetchGroups)
  const { isWindowFocused } = useWindowFocus()

  React.useEffect(() => {
    if (isWindowFocused) {
      refetch()
    }
  }, [isWindowFocused, refetch])

  const handleChange = event => {
    setChecked(event.target.checked)
  }

  const handleClick = () => {
    setDatabaseAccountInfo(info => {
      return {
        ...info,
        fetchGroups: checked,
      }
    })

    nextStep()
  }

  // Case #1: CP has at least one IdP integration configured with SCIM enabled
  const IdpIntegrationsWithScimEnabled = samlIntegrations.filter(
    integration => integration?.scimIntegration?.enabled
  )

  return (
    <Stack spacing={4}>
      <FormControlLabel
        checked={checked}
        onChange={handleChange}
        control={<Checkbox disableRipple />}
        label="Retrieve SSO group membership for users accessing data through this application."
        componentsProps={{
          typography: {
            variant: 'h4',
            sx: {
              color: 'text.primary',
            },
          },
        }}
      />

      {checked ? (
        <EdgeCaseNotification
          IdpIntegrationsWithScimEnabled={IdpIntegrationsWithScimEnabled}
          samlIntegrations={samlIntegrations}
        />
      ) : (
        <Alert
          severity="info"
          sx={{
            color: 'primary.main',
            backgroundColor: 'cyralColors.primary.100',

            '& .MuiAlert-icon': {
              color: 'inherit',
            },

            '& .MuiAlert-message': {
              typography: 'h6',
            },
          }}
        >
          SSO Group Attribution will be disabled for this database account. This can be enabled
          later on.
        </Alert>
      )}

      <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
        <Button variant="outlined" onClick={prevStep}>
          Back
        </Button>

        <Button
          onClick={handleClick}
          disabled={checked && IdpIntegrationsWithScimEnabled.length === 0}
        >
          Next
        </Button>
      </Stack>
    </Stack>
  )
}

const EdgeCaseNotification = ({ IdpIntegrationsWithScimEnabled, samlIntegrations }) => {
  // Case #2: CP has IdP integration configured that supports SCIM (Okta, Azure AD), but SCIM is not enabled
  const IDPIntegrationsWithSCIMSupported = samlIntegrations.filter(
    integration =>
      integration?.identityProviderType === 'okta' ||
      integration?.identityProviderType === 'aad' ||
      integration?.__typename === 'GenericSAMLIntegrationConfigured'
  )

  // Case #3: CP has ONLY configured an IdP that doesn't support SCIM
  // const IdpIntegrationsWithoutScimSupport = samlIntegrations.filter(
  //   integration =>
  //     integration?.identityProviderType !== 'okta' || integration?.identityProviderType !== 'aad'
  // )

  // Case #4: CP doesn't have any IdP integrations configured
  // const enabledIdpIntegrations = samlIntegrations.filter(integration => integration.enabled)

  return IdpIntegrationsWithScimEnabled?.length > 0 ? (
    <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
      <Typography variant="body1" sx={{ color: 'text.primary' }}>
        SSO group membership will be retrieved from the following IdP(s):
      </Typography>

      <Box>
        {IdpIntegrationsWithScimEnabled.map((integration, idx) => {
          return (
            <React.Fragment key={integration.id}>
              {idx > 0 && (
                <Typography variant="body1" component="span">
                  ,{' '}
                </Typography>
              )}
              <InlineCode>{integration.displayName}</InlineCode>
            </React.Fragment>
          )
        })}
      </Box>
    </Stack>
  ) : (
    <Box
      sx={{
        position: 'relative',
        border: 2,
        borderColor: 'primary.main',
        padding: 4,
        borderRadius: theme => theme.radii.base,
      }}
    >
      <Stack spacing={2} sx={{ alignItems: 'flex-start' }}>
        <Typography variant="body1" sx={{ color: 'text.primary' }}>
          {IDPIntegrationsWithSCIMSupported?.length > 0
            ? 'SSO Group Attribution requires SCIM integration with your IdP.'
            : 'To enable SSO Group Attribution, please configure a SAML IdP integration with SCIM configured.'}
        </Typography>

        <Button
          component={Link}
          to="/integrations"
          target="_blank"
          rel="noopener noreferrer"
          endIcon={<OpenInNewIcon />}
        >
          Configure IdP Integration
        </Button>
      </Stack>
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          right: 0,
          py: 1,
          px: 2,
          backgroundColor: 'primary.main',
          borderTopLeftRadius: theme => theme.radii.base,
        }}
      >
        <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
          <ErrorOutlineIcon fontSize="small" sx={{ color: 'white' }} />
          <Typography variant="h6" sx={{ color: 'white' }}>
            Incomplete
          </Typography>
        </Stack>
      </Box>
    </Box>
  )
}

export default StepThree
