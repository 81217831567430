/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useState } from 'react'
import styled from '@emotion/styled'
import { css, jsx } from '@emotion/react'

import {
  Button,
  CardActions,
  CircularProgress,
  Divider as MuiDivider,
  Grid,
} from '@material-ui/core/'
import Instructions from './AddFormInstructions'
import IntegrationPanel from './AddFormPanel'
import { TextFieldWithToolTip } from '../components'

const FullWidth = styled.div`
  width: 100%;
`

const Divider = styled(MuiDivider)`
  margin-top: -15px;
  margin-bottom: 15px;
  width: 100%;
`

const StyledCircularProgress = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
`

const ActionsContainer = styled(Grid)`
  justify-content: flex-end;
`

const SaveButtonContainer = styled.div`
  position: relative;
`

const AddForm = ({ addIntegration, handleRemoveAddField }) => {
  const [displayName, setDisplayName] = useState('')
  const [samlMetadataURL, setSamlMetadataURL] = useState('')
  const [loading, setLoading] = useState(false)

  const checkValidInput = () => {
    const isValid = samlMetadataURL
    if (!isValid) {
      const popupMessage = 'Missing required fields.'
      throw Error(popupMessage)
    }
  }

  const handleOnAddIntegration = async () => {
    const integration = {
      identityProvider: 'aad',
      samlMetadataURL,
      displayName: displayName,
    }
    try {
      setLoading(true)
      checkValidInput()
      await addIntegration(integration)
      handleRemoveAddField()
    } catch {
    } finally {
      setLoading(false)
    }
  }

  return (
    <FullWidth>
      <Divider />
      <Instructions />

      <TextFieldWithToolTip
        onChange={e => setSamlMetadataURL(e.target.value.trim())}
        value={samlMetadataURL}
        label="SAML Metadata URL"
        helpText="URL used to send authentication requests."
      />

      <TextFieldWithToolTip
        onChange={e => setDisplayName(e.target.value.trim())}
        value={displayName}
        label="Display Name"
        helpText="Name for the integration."
      />

      <ActionsContainer item container xs={12}>
        <CardActions>
          <Button color="primary" size="small" onClick={handleRemoveAddField}>
            Cancel
          </Button>
          <SaveButtonContainer>
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={handleOnAddIntegration}
              disabled={loading}
            >
              Save
            </Button>
            {loading && <StyledCircularProgress size={24} />}
          </SaveButtonContainer>
        </CardActions>
      </ActionsContainer>
    </FullWidth>
  )
}

const AddModal = props => (
  <IntegrationPanel title={props.name} expanded={true}>
    <AddForm {...props} />
  </IntegrationPanel>
)

export default AddModal
