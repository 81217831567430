import { createMuiTheme } from '@material-ui/core/styles'

const sizes = [8, 10, 12, 16, 18, 20, 24, 32, 48, 64].map(x => `${x}px`)
const breakpoints = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
}

export const baseTheme = {
  fontFamily: 'Open Sans, sans-serif',
  typography: {
    useNextVariants: true,
    fontSize: 16,
    fontFamily: 'Open Sans, sans-serif',
    monospaced: '"Roboto Mono", Consolas, Menlo, monospace',
  },
  fontSize: sizes,
  spacing: sizes,
  breakpoints,
  breakpointsPx: Object.entries(breakpoints).reduce((obj, [label, value]) => {
    return {
      ...obj,
      [label]: `${value}px`,
    }
  }, {}),
  breakpointsMedia: Object.entries(breakpoints).reduce((obj, [label, value]) => {
    return {
      ...obj,
      [label]: `@media (min-width: ${value}px)`,
    }
  }, {}),
  palette: {
    blue: ['#061748', '#393d74', '#2fa0e0', '#0072ae', '#abe4ff'],
    brightBlue: '#2FA0E0',
    darkBlue: '#0072AE',
    hoverBlue: '#393d74',
    lightBlue: '#abe4ff',
    navyBlue: '#061748',
    background: {
      primary: '#fafafa',
      secondary: '#eef3f8',
      ghostly: 'transparent',
    },
    text: {
      white: '#fff',
      black: '#000',
    },
    primary: {
      light: '#e6f6ff',
      main: '#26459e',
      contrastText: '#ffffff',
    },
    neutral: {
      light: '#f0f2f5',
    },
    secondary: {
      main: '#808E95',
      contrastText: '#ffffff',
    },
    green: {
      main: '#33CC47',
      dark: '#139B25',
    },
    warning: {
      main: '#f5a623',
    },
    success: {
      main: '#417505',
    },
    row: {
      alternate: '#fafafa',
    },
  },
  overrides: {
    Button: {
      letterSpacing: 1.16,
    },
    MuiButton: {
      root: {
        fontWeight: 500,
        fontFamily: ['Roboto', 'sans-serif'],
      },
    },
  },
  drawer: {
    width: 240,
  },
}

export const muiTheme = createMuiTheme(baseTheme)
