import { Dispatch, SetStateAction } from 'react'
import { Stack } from '@mui/material'

import StepperDot from './StepperDot'

interface Props {
  currentStep: number
  stepsNumber: number
  setCurrentStep: Dispatch<SetStateAction<number>>
}

const SetupWizardStepper = ({ currentStep, stepsNumber, setCurrentStep }: Props) => {
  return (
    <Stack direction="row" spacing={3}>
      {Array.from({ length: stepsNumber }).map((_, index) => {
        const step = index + 1

        return (
          <StepperDot
            key={`step-${step}`}
            active={step === currentStep}
            onClick={() => setCurrentStep(step)}
          />
        )
      })}
    </Stack>
  )
}

export default SetupWizardStepper
