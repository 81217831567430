import {
  EnhancedAuditLogActionCategory,
  EnhancedAuditLogFilterInput,
} from '@jeeves/graphql/graphql'

export interface FilterData {
  eventType?: string
  cyralUser?: string
  dateRangeFrom: string
  dateRangeTo: string
  dateRange: string
}

// Parse a *FilterData* object to an *EnhancedAuditLogFilterInput* object
export const parseFilter = (filterData: FilterData): EnhancedAuditLogFilterInput => {
  const isEventTypeFilterApplied = filterData.eventType && filterData.eventType !== 'all'
  const category = isEventTypeFilterApplied
    ? (filterData.eventType as EnhancedAuditLogActionCategory)
    : undefined
  const action = category ? { category } : undefined
  const subject = filterData.cyralUser || undefined

  return {
    action,
    subject,
    timestamp: {
      from: filterData.dateRangeFrom,
      to: filterData.dateRangeTo,
    },
  }
}
