import { Controller, useFormContext } from 'react-hook-form'
import { FormControl, FormHelperText } from '@mui/material'
import { InputLabel, Select } from '@jeeves/new-components'

const connectionFormatOptions = [
  {
    label: 'Host/Port',
    value: 'StaticNodeSpecification',
  },
  {
    label: 'SRV record',
    value: 'SRVRecordSpecification',
  },
]

const ConnectionFormatSelect = () => {
  const { control, formState } = useFormContext()

  const connectionFormatError = formState.errors?.connectionFormat

  return (
    <FormControl variant="standard" error={Boolean(connectionFormatError)}>
      <InputLabel id="connectionFormatLabel" htmlFor="connectionFormat" required>
        Connection format
      </InputLabel>
      <Controller
        name="connectionFormat"
        control={control}
        defaultValue="StaticNodeSpecification"
        render={({ field }) => (
          <Select
            labelId="connectionFormatLabel"
            id="connectionFormat"
            options={connectionFormatOptions}
            {...field}
          />
        )}
      />
      <FormHelperText
        sx={{
          typography: 'body2',
        }}
        error={Boolean(connectionFormatError)}
      >
        {connectionFormatError?.message}
      </FormHelperText>
    </FormControl>
  )
}

export default ConnectionFormatSelect
