import { get as lodashGet } from 'lodash'
import ExpressClient from '@jeeves/clients/express'
import { useAuth } from '@jeeves/components/Auth'
import usePopup from '@jeeves/components/PopupMessage/hooks/usePopup'

import { usePoliciesContext } from '../contexts/PoliciesContext'

const usePolicies = () => {
  const { user, getTokenSilently } = useAuth()
  const ec = new ExpressClient(getTokenSilently)
  const { state, dispatch } = usePoliciesContext()
  const { setPopup, popupTypes } = usePopup()

  const firstName = user.given_name ? `${user.given_name}` : `${user.email}`
  const lastName = user.family_name ? `${user.family_name}` : ''
  const userName = firstName + ' ' + lastName

  // TABLE FOOTER/PAGINATION FUNCTIONS
  const handleRequestSort = (event, property) => {
    let order = 'asc'
    if (state.orderBy === property && state.order === 'asc') {
      order = 'desc'
    }

    dispatch({
      type: 'handleRequestSort',
      payload: {
        order,
        orderBy: property,
      },
    })
  }

  const handleChangePage = (event, page) => {
    dispatch({
      type: 'handleChangePage',
      payload: {
        page,
      },
    })
  }

  const handleChangeRowsPerPage = event => {
    dispatch({
      type: 'handleChangeRowsPerPage',
      payload: {
        rowsPerPage: event.target.value,
      },
    })
  }

  // REFRESH/UPDATE POLICIES
  const updatePolicies = () => {
    dispatch({ type: 'updatePolicies' })
  }

  const setPolicies = () => {
    return ec.get('/policies').then(policies =>
      dispatch({
        type: 'setPolicies',
        payload: {
          policies: policies.data,
        },
      })
    )
  }

  /**
   * PATCH FUNCTIONS
   *
   */

  const handleOnToggle = async (id, enabled) => {
    try {
      await ec.request({
        method: 'PATCH',
        url: `/policies/${id}`,
        data: {
          author: userName,
          enabled,
        },
        params: {
          action: 'enabled',
        },
      })
      updatePolicies()
    } catch (e) {
      const popupMessage = lodashGet(e, 'response.data.error.message', '')
      setPopup(popupTypes.ERROR, popupMessage)
    }
  }

  /**
   * UPDATE FUNCTIONS
   */

  const updatePolicy = async (id, body) => {
    try {
      await ec.request({
        method: 'PUT',
        url: `/policies/${id}`,
        data: { author: userName, body },
      })
      updatePolicies()
    } catch (e) {
      const popupMessage = lodashGet(e, 'response.data.error.message', '')
      setPopup(popupTypes.ERROR, popupMessage)
      throw e
    }
  }

  const updatePolicyMeta = async ({ id, name, tags, description }) => {
    try {
      await ec.request({
        method: 'PATCH',
        url: `/policies/${id}/meta`,
        data: { id, name, tags, description },
      })
      updatePolicies()
    } catch (e) {
      const popupMessage = lodashGet(e, 'response.data.error.message', '')
      setPopup(popupTypes.ERROR, popupMessage)
      throw e
    }
  }

  const handleUpdateTags = async (policyName, tags) => {
    try {
      await ec.request({
        method: 'PATCH',
        url: `/policies/${policyName}`,
        data: { author: userName, labels: tags },
        params: {
          action: 'labels',
        },
      })
      updatePolicies()
    } catch (e) {
      const popupMessage = lodashGet(e, 'response.data.error.message', '')
      setPopup(popupTypes.ERROR, popupMessage)
      throw e
    }
  }

  const handleOnCreate = async (inputs, body) => {
    try {
      const policy = await ec
        .post('/policies', { author: userName, inputs, body })
        .then(res => res.data)
      return policy
    } catch (e) {
      const popupMessage = lodashGet(e, 'response.data.error.message', '')
      setPopup(popupTypes.ERROR, popupMessage)
    }
  }

  const handleOnBuild = async policyBody => {
    try {
      const policy = await ec
        .post(
          '/policies',
          { policy: policyBody, author: userName },
          { params: { format: 'builder' } }
        )
        .then(res => res.data)
      return policy
    } catch (e) {
      const popupMessage = lodashGet(e, 'response.data.error.message', '')
      setPopup(popupTypes.ERROR, popupMessage)
    }
  }
  const defaultLabels = ['HIPAA', 'HITRUST', 'PCI', 'PII']

  return {
    defaultLabels,
    ec,
    handleChangePage,
    handleChangeRowsPerPage,
    handleOnBuild,
    handleOnCreate,
    handleOnToggle,
    handleRequestSort,
    handleUpdateTags,
    setPolicies,
    updatePolicies,
    updatePolicy,
    user,
    userName,
    updatePolicyMeta,
    state,
  }
}

export default usePolicies
