/** @jsxRuntime classic */
/** @jsx jsx */
import styled from '@emotion/styled'
import { jsx, css } from '@emotion/react'

import MaterialCard from '@material-ui/core/Card'
import MaterialDivider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'

import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import ArrowIcon from '@material-ui/icons/KeyboardBackspace'
import { CircularProgress } from '@material-ui/core'

import { ServiceIcon } from '@jeeves/components/ServiceIcon'

const Card = styled(MaterialCard)`
  height: 250px;
  margin-top: 12px;
  padding: 24px;
  display: flex;
  align-items: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12),
    0px 1px 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
`

const Label = styled(Typography)`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: bold;
  font-size: 14px;
  color: #061748;
  line-height: 16px;
  margin-bottom: 6px;
`

const Value = styled(Typography)`
  font-family: ${({ theme, monospaced }) =>
    monospaced ? theme.typography.monospaced : theme.typography.fontFamily};
  font-size: 16px;
  line-height: 18px;
  flex-grow: 1;
`

const IconValue = styled(Typography)`
  font-family: ${({ theme, monospaced }) =>
    monospaced ? theme.typography.monospaced : theme.typography.fontFamily};
  font-size: 16px;
  font-weight: bold;
  line-height: 18px;
`

const RepoInfo = ({ repoName, repoType }) => {
  return (
    <div
      css={() => css`
        display: flex;
        flex-direction: row;
        padding-right: 120px;
      `}
    >
      {repoType && (
        <ServiceIcon
          css={() => css`
            max-width: 40px;
            max-height: 10px;
            margin-right: 8px;
          `}
          type={repoType ? repoType : ''}
        ></ServiceIcon>
      )}

      <IconValue>{repoName || ''}</IconValue>
    </div>
  )
}

const UserInfo = ({ username }) => {
  return (
    <div
      css={() => css`
        display: flex;
        flex-direction: row;
      `}
    >
      <AccountCircleIcon
        css={() => css`
          margin-right: 8px;
        `}
        fontSize="large"
      ></AccountCircleIcon>
      <IconValue>{username || ''}</IconValue>
    </div>
  )
}

const LoadingCard = () => (
  <Card
    css={() => css`
      width: 800px;
      padding: 0;
      flex-direction: rpw;
      align-items: cenmter;
      justify-content: center;
    `}
  >
    <CircularProgress />
  </Card>
)

const RequestCard = ({ children, config, repoType, loading }) => {
  if (loading) return <LoadingCard></LoadingCard>
  return (
    <Card
      css={() => css`
        width: 800px;
        padding: 0;
        flex-direction: column;
        align-items: initial;
      `}
    >
      <div
        css={() => css`
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding: 24px 48px;
        `}
      >
        <UserInfo username={config.username || ''}></UserInfo>
        <span>
          <ArrowIcon
            css={() => css`
              transform: rotate(180deg);
              font-size: 24px;
              color: #666;
            `}
          />
        </span>

        <RepoInfo repoName={config.repoName || ''} repoType={repoType}></RepoInfo>
      </div>
      <MaterialDivider></MaterialDivider>
      <div
        css={() => css`
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding: 24px 48px;
          align-items: flex-end;
        `}
      >
        <div>
          <div
            css={() => css`
              padding-bottom: 16px;
            `}
          >
            <Label>GRANT DURATION</Label>
            <Value>{`${config.accessDuration || ''} ${config.durationUnit || ''}`}</Value>
          </div>

          <Label>ACCESS WITHIN</Label>
          <Value>{`${config.accessWithin || ''} ${config.accessUnit || ''}`}</Value>
        </div>
        {children}
      </div>
    </Card>
  )
}

export default RequestCard
