import * as React from 'react'

const RepositoriesContext = React.createContext()

const RepositoriesProvider = ({ children, ...rest }) => {
  const value = {
    ...rest,
  }

  return <RepositoriesContext.Provider value={value}>{children}</RepositoriesContext.Provider>
}

const useRepositoriesContext = () => {
  const context = React.useContext(RepositoriesContext)
  if (context === undefined) {
    throw new Error('useRepositoriesContext must be used within a RepositoriesProvider')
  }

  return context
}

export { RepositoriesProvider, useRepositoriesContext }
