import * as React from 'react'
import { parseISO, format, isAfter } from 'date-fns'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import PersonIcon from '@mui/icons-material/Person'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'
import AccessTimeTwoToneIcon from '@mui/icons-material/AccessTimeTwoTone'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import { ServiceIcon } from '@jeeves/new-components'

import { RowActionButton } from './AccessRulesTable.style'
import EditAccessRuleModal from './EditAccessRuleModal'

const Row = ({ rule, openDeleteRuleModal, userAccountId }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: rule.id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  const currentDate = new Date()
  const validFrom = rule.validFrom && parseISO(rule.validFrom)

  // rule.validUntil might not exist
  const validUntil = rule.validUntil && parseISO(rule.validUntil)

  const ruleNotYetActive = validFrom ? isAfter(validFrom, currentDate) : false
  const ruleHasExpired = validUntil ? isAfter(currentDate, validUntil) : false

  const hasPagerdutyAccessRestriction = rule?.accessRestrictions?.some(
    restriction => restriction.__typename === 'PagerdutyIntegration'
  )
  const hasDuoAccessRestriction = rule?.accessRestrictions?.some(
    restriction => restriction.__typename === 'DuoIntegration'
  )

  return (
    <Box component="tr" ref={setNodeRef} style={style}>
      <Box component="td">
        <RowActionButton>
          <DragIndicatorIcon {...attributes} {...listeners} />
        </RowActionButton>
      </Box>
      <Box
        component="td"
        sx={{
          color: 'text.primary',
          typography: 'h5',
        }}
      >
        {rule.priority}
      </Box>
      <Box component="td">
        <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
          {rule.identity.type === 'group' ? (
            <SupervisorAccountIcon color="secondary" />
          ) : (
            <PersonIcon color="secondary" />
          )}

          <Typography variant="body2" sx={{ color: 'text.primary' }}>
            {rule.identity.name}
          </Typography>
        </Stack>
      </Box>
      <Box component="td">
        <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
          {hasPagerdutyAccessRestriction && (
            <ServiceIcon type="pagerduty-letter" sx={{ fontSize: '1rem' }} />
          )}
          {hasDuoAccessRestriction && <ServiceIcon type="duo" />}
        </Stack>
      </Box>
      <Box component="td">
        <Box
          component="span"
          sx={{ display: 'inline-flex', alignItems: 'center', whiteSpace: 'pre-wrap' }}
        >
          {ruleNotYetActive && (
            <Box component="span">
              <AccessTimeTwoToneIcon color="cyralColors.yellow.300" fontSize="small" />
            </Box>
          )}
          <Typography
            sx={{
              color: ruleNotYetActive || ruleHasExpired ? 'cyralColors.grey.300' : 'text.primary',
            }}
            component="span"
            variant="body2"
          >
            {validFrom ? `${format(validFrom, 'd MMM yy')} — ` : validUntil ? 'Valid until ' : ''}
          </Typography>
        </Box>

        <Box component="span" sx={{ display: 'inline-flex', alignItems: 'center' }}>
          {ruleHasExpired && (
            <Box component="span">
              <AccessTimeTwoToneIcon color="cyralColors.yellow.300" fontSize="small" />
            </Box>
          )}
          <Typography
            sx={{
              color: ruleNotYetActive || ruleHasExpired ? 'cyralColors.grey.300' : 'text.primary',
            }}
            component="span"
            variant="body2"
          >
            {validUntil ? format(validUntil, 'd MMM yy') : 'Unlimited'}
          </Typography>
        </Box>
      </Box>
      <Box component="td">
        <Actions
          rule={rule}
          openDeleteRuleModal={openDeleteRuleModal}
          userAccountId={userAccountId}
        />
      </Box>
    </Box>
  )
}

const Actions = ({ rule, openDeleteRuleModal, userAccountId }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [editRuleModalOpen, setEditRuleModalOpen] = React.useState(false)
  const open = Boolean(anchorEl)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOpenDeleteRuleModal = () => {
    handleClose()
    openDeleteRuleModal(rule.id)
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleToggleOpenEditRuleModal = React.useCallback(() => {
    setEditRuleModalOpen(oldEditRuleModalOpen => !oldEditRuleModalOpen)
  }, [])

  const handleOpenEditRuleModal = () => {
    handleClose()
    handleToggleOpenEditRuleModal()
  }
  return (
    <React.Fragment>
      <RowActionButton>
        <MoreVertIcon onClick={handleClick} />
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={handleOpenEditRuleModal} disableRipple>
            Edit
          </MenuItem>
          <MenuItem onClick={handleOpenDeleteRuleModal} disableRipple>
            Delete
          </MenuItem>
        </Menu>
      </RowActionButton>

      <EditAccessRuleModal
        open={editRuleModalOpen}
        onClose={handleToggleOpenEditRuleModal}
        accessRule={rule}
        userAccountId={userAccountId}
      />
    </React.Fragment>
  )
}

export default Row
