import { Box, IconButton, Typography } from '@mui/material'
import CloseIcon from '@material-ui/icons/Close'

const Header = ({ icon = null, title, onClose }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        {icon}
        <Typography variant="h3" sx={{ color: 'text.primary' }}>
          {title}
        </Typography>
      </Box>
      <IconButton aria-label="close" onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </Box>
  )
}

export default Header
