import { Typography } from '@mui/material'

interface Props {
  label1: string
  label2: string
  label3?: string
}

const HighlightedTitle = ({ label1 = '', label2 = '', label3 = '' }: Props) => {
  return (
    <Typography variant="h6" sx={{ color: 'text.secondary' }}>
      {label1}{' '}
      <Typography
        variant="h6"
        component="span"
        sx={{
          color: 'primary.main',
          textTransform: 'uppercase',
        }}
      >
        {label2}
      </Typography>{' '}
      {label3}
    </Typography>
  )
}

export default HighlightedTitle
