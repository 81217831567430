import * as React from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'

import useApprovalDetailQuery from './useApprovalDetailQuery'

import ApprovalDetailHeader from './components/ApprovalDetailHeader'
import SensitiveFields from './components/SensitiveFields'
import ApprovalHistory from './components/ApprovalHistory'

const ApprovalDetail = () => {
  const { data, loading, error } = useApprovalDetailQuery()

  const approval = data?.approval

  const approvalType = approval?.__typename

  return (
    <Box sx={{ px: 8, py: 4 }}>
      {loading ? (
        <Loading />
      ) : (
        <Stack spacing={4}>
          <ApprovalDetailHeader approval={approval} />

          {approvalType === 'GrantedApproval' && (
            <SensitiveFields sensitiveFields={approval.sensitiveFields} />
          )}

          <ApprovalHistory approval={approval} />
        </Stack>
      )}
    </Box>
  )
}

const Loading = () => {
  return (
    <Stack spacing={2} sx={{ alignItems: 'center' }}>
      <CircularProgress size={64} />
      <Typography variant="h6" sx={{ color: 'text.secondary' }}>
        Loading approval details...
      </Typography>
    </Stack>
  )
}

export default ApprovalDetail
