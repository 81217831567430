import React from 'react'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

const ScreenshotLayout = ({ imgSrc, title, steps, inlineCode }) => {
  return (
    <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" gap={2}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box
          component="img"
          src={imgSrc}
          sx={{
            width: '100%',
          }}
        />
      </Box>
      <Box sx={{ padding: 8, overflow: 'hidden' }}>
        <Stack spacing={4}>
          <Typography variant="h4" sx={{ color: 'text.primary' }}>
            {title}
          </Typography>

          <Stack spacing={2}>
            {steps.map((step, idx) => {
              return (
                <Typography key={idx} variant="h6" sx={{ color: 'primary.main' }}>
                  {idx + 1}. {step}
                </Typography>
              )
            })}
            {inlineCode && inlineCode}
          </Stack>
        </Stack>
      </Box>
    </Box>
  )
}

export default ScreenshotLayout
