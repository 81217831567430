import React from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import Box from '@mui/material/Box'

export const ShowMore = ({ allRepos, setAllRepos, handleShow }) => {
  return (
    <Box>
      <Stack
        direction="row"
        sx={{
          py: 2,
          pl: 4,
          pr: 2,
          justifyContent: 'space-between',
          border: 1,
          borderTop: 0,
          borderColor: 'cyralColors.grey.200',

          '& > *': { borderBottom: 'unset' },
          justifyContent: 'center',
          cursor: 'pointer',
        }}
        onClick={handleShow}
      >
        <Stack direction="row" spacing={2} sx={{ justifyContent: 'center' }}>
          <Typography variant="h4">{allRepos ? `Show less` : `Show all repositories`}</Typography>
        </Stack>
      </Stack>
    </Box>
  )
}
