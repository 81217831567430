import { useState } from 'react'
import { Box, Popper } from '@mui/material'

import { Tag } from '@jeeves/new-components'

const SidecarHeaderOverflowTags = ({ overflowTags }) => {
  const [anchorEl, setAnchorEl] = useState()
  const numberOfTags = overflowTags.length

  if (numberOfTags === 0) {
    return null
  }

  const overflowTagsPopperOpen = Boolean(anchorEl)
  const id = overflowTagsPopperOpen ? 'overflow-tags-popper' : undefined

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Tag
        color="grey"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        sx={{ cursor: 'pointer' }}
      >
        {`+ ${numberOfTags}`}
      </Tag>

      <Popper id={id} open={overflowTagsPopperOpen} anchorEl={anchorEl}>
        <Box
          sx={{
            border: 1,
            borderRadius: theme => theme.radii.base,
            borderColor: 'cyralColors.grey.200',
            p: 1,
            mt: 1,
            backgroundColor: 'common.white',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 1,
            }}
          >
            {overflowTags.map(tag => (
              <Tag key={tag}>{tag}</Tag>
            ))}
          </Box>
        </Box>
      </Popper>
    </>
  )
}

export default SidecarHeaderOverflowTags
