/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import styled from '@emotion/styled'
import { useContext } from 'react'
import { MenuList } from '@material-ui/core'
import { blue, green, blueGrey } from '@material-ui/core/colors'

import { SetupContext } from '@jeeves/pages/Setup'
import { MenuItem } from './components'

const Colors = {
  Active: blue[600],
  Completed: green[600],
  Inactive: blueGrey[600],
}

const Stages = {
  Active: 'Active',
  Completed: 'Completed',
  Inactive: 'Inactive',
}

const NoHoverMenuItem = styled(MenuItem)`
  cursor: auto;
  &:hover {
    background: inherit;
  }
`

const StepNumber = ({ step, stage = Stages.Inactive }) => (
  <div
    css={() => css`
      color: white;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      width: 24px;
      height: 24px;
      background-color: ${Colors[stage]};
    `}
  >
    {step}
  </div>
)

const SetupMenu = ({ iconOnly }) => {
  // eslint-disable-next-line
  const { setupStep, setSetupStep, Steps } = useContext(SetupContext)

  const getStage = step =>
    setupStep === step ? Stages.Active : setupStep > step ? Stages.Completed : Stages.Inactive

  const state = {
    configure: setupStep === Steps.Welcome ? Stages.Active : Stages.Completed,
    addUsers: getStage(Steps.AddUsers),
    sandbox: getStage(Steps.Sandbox),
    finish: getStage(Steps.Finish),
  }

  return (
    <MenuList
      css={() => css`
        margin-top: 64px;
      `}
    >
      <NoHoverMenuItem
        primaryText="Configure"
        listItemIcon={<StepNumber step={1} stage={state.configure} />}
        iconOnly={iconOnly}
      />
      <NoHoverMenuItem
        primaryText="Add Users"
        listItemIcon={<StepNumber step={2} stage={state.addUsers} />}
        iconOnly={iconOnly}
      />
      <NoHoverMenuItem
        primaryText="Explore sandbox"
        listItemIcon={<StepNumber step={3} stage={state.sandbox} />}
        iconOnly={iconOnly}
      />
      <NoHoverMenuItem
        primaryText="Finish"
        listItemIcon={<StepNumber step={4} stage={state.finish} />}
        iconOnly={iconOnly}
      />
    </MenuList>
  )
}

export default SetupMenu
