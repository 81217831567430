import { useFormContext } from 'react-hook-form'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'

import { InputLabel, Input } from '@jeeves/new-components'
import { MIN_PORT_NUMBER, MAX_PORT_NUMBER } from '@jeeves/utils/helpers'

const ProxyPort = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext()

  const error = errors?.proxyPort

  return (
    <FormControl variant="standard" error={Boolean(error)}>
      <InputLabel id="proxy-port-input-label" htmlFor="proxy-port-input">
        Proxy Port
      </InputLabel>

      <Input
        id="proxy-port-input"
        type="number"
        defaultValue=""
        inputProps={{
          ...register('proxyPort', {
            required: 'This field is required.',
            min: {
              value: MIN_PORT_NUMBER,
              message: `Value must be greater than or equal to ${MIN_PORT_NUMBER}.`,
            },
            max: {
              value: MAX_PORT_NUMBER,
              message: `Value must be less than or equal to ${MAX_PORT_NUMBER}.`,
            },
          }),
        }}
      />

      {error && (
        <FormHelperText
          sx={{
            typography: 'body2',
          }}
        >
          {error.message}
        </FormHelperText>
      )}
    </FormControl>
  )
}

export default ProxyPort
