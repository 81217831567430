import { POLICY_ENFORCEMENT_MATRIX } from '../components'

export const REPO_CONFIGS = {
  accessPortal: {
    id: 'accessPortal',
    displayName: 'Access Portal',
  },
  authentication: {
    id: 'authentication',
    displayName: 'Authentication',
  },
  dataActivityLogs: {
    id: 'dataActivityLogs',
    displayName: 'Data Activity Logs',
  },
  alerts: {
    id: 'alerts',
    displayName: 'Alerts',
  },
  policyEnforcement: {
    id: 'policyEnforcement',
    displayName: 'Policy Enforcement',
  },
  tls: {
    id: 'tls',
    displayName: 'TLS',
  },
}

// hasRepoConfig returns true if a given repoType supports a specific
// repoConfig (E.g.: Policy Enforcement). This function can be extended
// in the future to handle other types of repo configurations if needed.
export const hasRepoConfig = (repoConfig, repoType) => {
  const { policyEnforcement } = REPO_CONFIGS
  return {
    [policyEnforcement.id]: hasPolicyEnforcementConfig(repoType),
  }[repoConfig]
}

const hasPolicyEnforcementConfig = repoType => {
  const supportedPolicyEnforcements = POLICY_ENFORCEMENT_MATRIX[repoType]
  return Array.isArray(supportedPolicyEnforcements) && supportedPolicyEnforcements.length !== 0
}
