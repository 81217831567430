/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Tooltip, Typography } from '@material-ui/core'

const BaseBadge = ({ label, textColor, backgroundColor, borderColor, ...props }) => {
  return (
    <div
      css={{
        backgroundColor: backgroundColor,
        border: borderColor ? `1px solid ${borderColor}` : null,
        borderRadius: '2px',
        padding: '0.1rem 0.4rem',
        margin: '0 0.5rem',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      {...props}
    >
      <Typography
        css={{
          fontSize: '.7rem',
          letterSpacing: '.07rem',
          color: textColor,
          textTransform: 'uppercase',
          fontWeight: 600,
          fontFamily: 'Open Sans, sans-serif',
        }}
      >
        {label}
      </Typography>
    </div>
  )
}

export const Badge = ({ label, helpText, textColor, backgroundColor, borderColor, ...props }) => {
  if (helpText) {
    return (
      <Tooltip title={helpText}>
        <BaseBadge
          label={label}
          textColor={textColor}
          backgroundColor={backgroundColor}
          borderColor={borderColor}
          {...props}
        />
      </Tooltip>
    )
  }
  return (
    <BaseBadge
      label={label}
      textColor={textColor}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      {...props}
    />
  )
}

export const StatusIcon = ({ enabled, ...props }) => {
  const status = enabled
    ? {
        label: 'enabled',
        textColor: '#487961',
        backgroundColor: '#e7f3ed',
      }
    : {
        label: 'disabled',
        textColor: '#838a8e',
        backgroundColor: '#dedede',
      }

  return (
    <Badge
      label={status.label}
      textColor={status.textColor}
      backgroundColor={status.backgroundColor}
      {...props}
    />
  )
}
