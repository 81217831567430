import { Stack } from '@mui/material'
import PolicyRule from '../../../../PolicyRule'
import { Variation1 } from '../Variation1'
import { useReadLimitVariation } from '../useReadLimitVariation'

const Variation4 = () => {
  const { identities } = useReadLimitVariation()

  return (
    <Stack spacing={4}>
      <Variation1 />
      <PolicyRule title="This policy applies to these identities:" content={identities} />
    </Stack>
  )
}

export default Variation4
