/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import styled from '@emotion/styled'
import { Fragment } from 'react'
import DOMPurify from 'dompurify'

const ContentWrapper = styled.div`
  height: 85vh;
  margin-top: 7vh;
  padding: 25px;
  white-space: pre-wrap;
  font-family: 'Roboto Mono', sans-serif;
  font-size: 0.75rem;
  background: white;
  overflow-y: scroll;

  color: ${({ loading, theme }) => (loading ? theme.palette.background.default : 'inherit')};
  background-color: ${({ loading, theme }) =>
    loading ? theme.palette.background.default : 'white'};
`

const Content = ({ isLoading, content, contentType = 'html' }) => {
  return (
    <Fragment>
      {contentType === 'html' ? (
        <div
          css={() => css`
            overflow: hidden;
          `}
        >
          <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }} />
        </div>
      ) : (
        <ContentWrapper loading={isLoading}>{content}</ContentWrapper>
      )}
    </Fragment>
  )
}

export default Content
