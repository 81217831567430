import { FormProvider } from 'react-hook-form'

import {
  Box,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Stack,
  Typography,
} from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'
import { useAuth } from '@jeeves/hooks'
import {
  Dialog,
  Button,
  Input,
  DialogActions,
  IconButton,
  InputLabel,
} from '@jeeves/new-components'

import { RepoTypeSelect, AddRepoStandardFields, AddRepoMongoDBFields } from './components'
import useAddRepositoryModal from './useAddRepositoryModal'

const getAdditionalFormFields = repositoryType => {
  switch (repositoryType) {
    case 'mongodb':
      return <AddRepoMongoDBFields />
    default:
      return <AddRepoStandardFields />
  }
}

const AddRepositoryModal = () => {
  const { methods, onSubmit, open, closeModal, openModal } = useAddRepositoryModal()
  const { hasPermission } = useAuth()

  const repositoryType = methods.watch('repositoryType')

  return (
    <>
      <Button disabled={!hasPermission('repo:create')} onClick={openModal}>
        Add Repository
      </Button>
      <Dialog open={open} onClose={closeModal} fullWidth>
        <FormProvider {...methods}>
          <Box component="form" onSubmit={onSubmit}>
            <DialogTitle
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Typography variant="h3" sx={{ color: 'text.primary' }}>
                Add Repository
              </Typography>
              <IconButton aria-label="close" onClick={closeModal}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Stack spacing={2}>
                <RepoTypeSelect />
                <FormControl
                  variant="standard"
                  error={Boolean(methods.formState.errors?.repositoryName)}
                >
                  <InputLabel htmlFor="repository-name" required>
                    Repository name
                  </InputLabel>
                  <Input
                    id="repository-name"
                    inputProps={{
                      ...methods.register('repositoryName'),
                    }}
                  />
                  <FormHelperText
                    sx={{
                      typography: 'body2',
                    }}
                    error={Boolean(methods.formState.errors?.repositoryName)}
                  >
                    {methods.formState.errors?.repositoryName?.message}
                  </FormHelperText>
                  <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
                    The name{' '}
                    <Typography component="span" variant="body2" sx={{ fontWeight: 'bold' }}>
                      cannot be changed
                    </Typography>{' '}
                    once you add this repository.
                  </Typography>
                </FormControl>
                {repositoryType && getAdditionalFormFields(repositoryType)}
              </Stack>
            </DialogContent>
            <DialogActions>
              <Stack direction="row" spacing={2} sx={{ justifyContent: 'flex-end' }}>
                <Button variant="text" onClick={closeModal}>
                  Cancel
                </Button>
                <Button
                  loading={methods.formState.isSubmitting}
                  variant="contained"
                  type="submit"
                  color="primary"
                >
                  Add
                </Button>
              </Stack>
            </DialogActions>
          </Box>
        </FormProvider>
      </Dialog>
    </>
  )
}

export default AddRepositoryModal
