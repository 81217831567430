import React from 'react'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'

export const RecommendationsContainer = styled(
  props => <Box {...props} />,
  {}
)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  padding: theme.spacing(2, 4),
  paddingBottom: theme.spacing(1),
}))

export const RecommendationsHeader = styled(
  props => <Stack direction="row" {...props} />,
  {}
)(({ theme }) => ({
  justifyContent: 'space-between',
  alignItems: 'center',
}))

export const RecommendationCard = styled(
  props => <Stack direction="row" {...props} />,
  {}
)(({ theme }) => ({
  border: `1px solid`,
  borderColor: theme.palette.cyralColors.grey[200],
  backgroundColor: 'white',
  padding: theme.spacing(2, 4),

  justifyContent: 'space-between',
  marginBottom: theme.spacing(1),
}))

export const AttributeFieldValue = styled(
  props => <Stack {...props} />,
  {}
)(({ theme }) => ({
  width: '25%',
}))

export const AttributeField = styled(
  props => <Typography variant="h5" {...props} />,
  {}
)(({ theme }) => ({
  color: theme.palette.cyralColors.grey[400],
  textTransform: 'uppercase',
}))

export const AttributeValue = styled(
  props => <Typography variant="code" {...props} />,
  {}
)(({ theme }) => ({
  ...theme.typography.code,
  color: theme.palette.cyralColors.grey[500],
}))

export const TimestampContainer = styled(
  props => <Stack direction="row" spacing={3} {...props} />,
  {}
)(({ theme }) => ({
  marginTop: theme.spacing(2),
}))

export const Timestamp = styled(
  props => <Typography variant="h6" {...props} />,
  {}
)(({ theme }) => ({
  color: theme.palette.grey[400],
}))

export const AttributeActionsContainer = styled(
  props => <Stack direction="row" spacing={4} {...props} />,
  {}
)(({ theme }) => ({
  alignSelf: 'center',
}))
