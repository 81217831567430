import { useState, useCallback } from 'react'

import { useRepositoryDetailContext } from '@jeeves/pages/RepositoryDetail/contexts/RepositoryDetailContext'
import { policyIdentities } from '@jeeves/pages/RepositoryDetail/Tabs/Policies/helpers/policyIdentities'

import { useCreatePolicy, useUpdatePolicy } from '../hooks'

const useDataProtection = ({ policy, template }) => {
  const { repoId } = useRepositoryDetailContext()

  const [snackBarOpen, setSnackBarOpen] = useState(false)
  const [activeStep, setActiveStep] = useState(0)
  const [dataProtectionInfo, setDataProtectionInfo] = useState({
    id: policy?.id ?? '',
    name: policy?.name ?? '',
    description: policy?.description ?? '',
    tags: policy?.tags ?? template.tags,
    template: policy?.template ?? template,
    parameters: {
      dataLabels: policy?.parameters?.dataLabels ?? [],
      databaseAccountsAppliedTo: {
        modifier: policy?.parameters?.databaseAccountsAppliedTo?.modifier ?? 'ALL',
        databaseAccounts: policy?.parameters?.databaseAccountsAppliedTo?.databaseAccounts ?? [],
      },
      identitiesAppliedTo: {
        modifier: policy?.parameters?.identitiesAppliedTo?.modifier ?? 'ALL',
        identities: policy?.parameters?.identitiesAppliedTo?.identities ?? [],
      },
      restrictedOperations: {
        read: policy?.parameters?.restrictedOperations.read ?? true,
        update: policy?.parameters?.restrictedOperations.update ?? true,
        delete: policy?.parameters?.restrictedOperations.delete ?? true,
      },
      action: policy?.parameters?.action ?? 'alert',
    },
  })

  const isEditing = Boolean(policy?.id)

  const [createDataProtectionPolicy, { loading: createLoading, error: createError }] =
    useCreatePolicy({
      onError: _ => {
        setSnackBarOpen(true)
      },
    })

  const [updateDataProtectionPolicy, { loading: updateLoading, error: updateError }] =
    useUpdatePolicy({
      onError: _ => {
        setSnackBarOpen(true)
      },
    })

  const prevStep = () => setActiveStep(currStep => currStep - 1)
  const nextStep = useCallback(() => {
    setActiveStep(currStep => currStep + 1)
  }, [])

  const handleCloseSnackbar = () => {
    setSnackBarOpen(false)
  }

  const handlePolicySubmit = async () => {
    const shapedIdentities = dataProtectionInfo?.parameters?.identitiesAppliedTo?.identities?.map(
      identity => {
        return { type: policyIdentities[identity.__typename], name: identity.name }
      }
    )

    const input = {
      dataProtection: {
        name: dataProtectionInfo.name,
        description: dataProtectionInfo.description,
        tags: dataProtectionInfo.tags,
        dataLabels: dataProtectionInfo.parameters.dataLabels,
        databaseAccountsAppliedTo: dataProtectionInfo?.parameters?.databaseAccountsAppliedTo,
        identitiesAppliedTo: {
          modifier: dataProtectionInfo.parameters.identitiesAppliedTo.modifier,
          identities: shapedIdentities,
        },
        restrictedOperations: {
          read: dataProtectionInfo.parameters.restrictedOperations.read,
          update: dataProtectionInfo.parameters.restrictedOperations.update,
          delete: dataProtectionInfo.parameters.restrictedOperations.delete,
        },
        action: dataProtectionInfo.parameters.action,
      },
    }

    try {
      if (isEditing) {
        const policyId = policy.id
        return await updateDataProtectionPolicy({
          variables: {
            policyId,
            input,
          },
        })
      }

      return await createDataProtectionPolicy({
        variables: {
          repoId,
          input,
        },
      })
    } catch (e) {
      console.error(e)
    }
  }

  return {
    dataProtectionInfo,
    activeStep,
    snackBarOpen,
    loading: createLoading || updateLoading,
    errorMessage: createError?.message || updateError?.message,
    setDataProtectionInfo,
    prevStep,
    nextStep,
    handlePolicySubmit,
    handleCloseSnackbar,
  }
}

export default useDataProtection
