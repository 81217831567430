import { Box, FormControl, FormControlLabel, Radio, FormHelperText } from '@mui/material'

import { Input, InputLabel } from '@jeeves/new-components'

const MaskingReplacementOption = ({ errors, register, isMaskingTypeConstant }) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <FormControlLabel
        value="CONSTANT"
        control={<Radio size="small" disableRipple />}
        label="Constant Mask"
        slotProps={{
          typography: {
            variant: 'h6',
            sx: {
              color: 'text.primary',
            },
          },
        }}
      />
      <Box sx={{ paddingLeft: 3 }}>
        <FormControl
          variant="standard"
          error={Boolean(errors?.replacementValue)}
          disabled={!isMaskingTypeConstant}
          sx={{ flexGrow: 1 }}
          fullWidth
        >
          <InputLabel htmlFor="replacement-value">Replacement Value</InputLabel>
          <Input
            id="replacement-value"
            placeholder="e.g. *****"
            inputProps={{
              ...register('replacementValue', {
                validate: {
                  required: value => {
                    if (!value && isMaskingTypeConstant)
                      return 'Please provide a replacement for constant mask'
                    return true
                  },
                },
              }),
            }}
          />
          <FormHelperText sx={{ typography: 'body2' }} error={!!errors?.replacementValue}>
            {errors?.replacementValue?.message}
          </FormHelperText>
        </FormControl>
      </Box>
    </Box>
  )
}

export default MaskingReplacementOption
