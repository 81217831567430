import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const GcpSecretManagerIcon = props => {
  return (
    <SvgIcon {...props} fill="none" viewBox="0 0 41 24">
      <path
        d="M40.328 0v23.774h-7.924a.377.377 0 0 1-.378-.378v-2.264c0-.208.17-.377.378-.377l4.904-.001V3.018h-4.904a.377.377 0 0 1-.378-.376V.377c0-.208.17-.377.378-.377h7.924ZM8.946 0c.209 0 .378.169.378.377v2.265a.377.377 0 0 1-.378.377l-5.6-.001v17.736h5.567c.208 0 .377.17.377.378v2.264a.377.377 0 0 1-.377.378H.328V0h8.618Zm22.236 7.736v2.912l.122.05 2.7-.955.56 1.64-2.725.93-.073.195 1.873 2.52-1.41 1.029-1.825-2.521h-.17l-1.825 2.52-1.411-1.027 1.849-2.521-.049-.196-2.725-.93.56-1.64 2.676.955.146-.049V7.736h1.727Zm-20.462 0v2.883l.122.049 2.7-.945.56 1.623-2.725.921-.073.194 1.873 2.495-1.41 1.018-1.825-2.495h-.17l-1.825 2.495-1.411-1.018 1.849-2.495-.05-.194-2.724-.92.56-1.624 2.676.945.146-.049V7.736h1.727Zm10.189 0v2.883l.121.049 2.7-.945.56 1.623-2.724.921-.073.194 1.873 2.495-1.411 1.018-1.825-2.495h-.17l-1.825 2.495-1.41-1.018 1.848-2.495-.048-.194-2.725-.92.56-1.624 2.675.945.146-.049V7.736h1.728Z"
        fill="#4285F4"
      />
    </SvgIcon>
  )
}

export default GcpSecretManagerIcon
