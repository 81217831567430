import * as React from 'react'

import { Typography, Box, Stack } from '@mui/material'
import LaunchIcon from '@mui/icons-material/Launch'
import { getDocsURL } from '@jeeves/utils/docsURL'

// docsPath (string): relative path to a specific page/area in the Cyral docs
//  When this prop is not provided, the LearnMore component will link to the Cyral docs homepage by default.
export const LearnMore = ({ docsPath = '' }) => {
  return (
    <Box component="span" sx={{ ml: 0.75 }}>
      <a
        href={getDocsURL({ docsPath })}
        target="_blank"
        rel="noreferrer"
        style={{ display: 'inline-flex', gap: '2px', alignItems: 'center' }}
      >
        <Typography component="span" variant="h4" sx={{ color: 'primary.main' }}>
          Learn More
        </Typography>
        <LaunchIcon sx={{ color: 'primary.main', fontSize: '20px' }} />
      </a>
    </Box>
  )
}
