import React, { useState } from 'react'

import Card from './Card/Card'
import IntegrationDialog from './IntegrationDialog'

const Integration = ({ integration, configured }) => {
  const [selected, setSelected] = useState(false)
  const handleOnClose = () => setSelected(false)
  const handleOnClick = () => setSelected(true)

  if (!integration) {
    return null
  }

  const isConfigured = configured.find(s => s === integration.metadata.slug) !== undefined

  return (
    <>
      <IntegrationDialog integration={integration} open={selected} handleOnClose={handleOnClose} />
      <Card
        metadata={integration.metadata}
        handleOnClick={handleOnClick}
        isConfigured={isConfigured}
      />
    </>
  )
}

export default Integration
