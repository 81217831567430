import * as React from 'react'
import Stack from '@mui/material/Stack'

import useUpsertUserAccountWizard from './useUpsertUserAccountWizard'
import Stepper from './Stepper'
import StepFooter from './steps/credentials/components/StepFooter'

const UpsertUserAccountWizard = () => {
  const { activeStep, steps, prevStep } = useUpsertUserAccountWizard()

  const renderedStep = steps[activeStep]
  const isLastStep = activeStep === steps.length - 1

  const renderedStepWithFooter = React.cloneElement(renderedStep, {
    footer: <StepFooter activeStep={activeStep} prevStep={prevStep} isLastStep={isLastStep} />,
  })

  return (
    <Stack spacing={4}>
      {steps.length > 1 && <Stepper steps={steps} activeStep={activeStep} />}
      {renderedStepWithFooter}
    </Stack>
  )
}

export default UpsertUserAccountWizard
