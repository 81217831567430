/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'

import { SvgIcon } from '@material-ui/core'

export const MenuOpenIcon = props => {
  return (
    <SvgIcon
      {...props}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3 18h13v-2H3v2zm0-5h10v-2H3v2zm0-7v2h13V6H3zm18 9.59L17.42 12 21 8.41 19.59 7l-5 5 5 5L21 15.59z" />
    </SvgIcon>
  )
}
