import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Box, Divider, FormControl, Stack, FormHelperText, Typography } from '@mui/material'

import { Input, InputLabel, InlineCode } from '@jeeves/new-components'
import { useRepositoryDetailContext } from '@jeeves/pages/RepositoryDetail/contexts/RepositoryDetailContext'
import Footer from '@jeeves/pages/RepositoryDetail/Tabs/Policies/views/AddPolicy/components/Footer'

const StepFour = ({ policyInfo, nextStep, prevStep, setPolicyInfo }) => {
  const { repoId } = useRepositoryDetailContext()
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      restriction: policyInfo.parameters.restriction,
    },
  })

  const onSubmit = data => {
    setPolicyInfo(info => {
      return {
        ...info,
        parameters: {
          ...info.parameters,
          restriction: data.restriction,
        },
      }
    })
  }

  useEffect(() => {
    if (isSubmitSuccessful) {
      nextStep()
    }
  }, [isSubmitSuccessful, nextStep])

  const renderDataLabels = labels => {
    return labels.map((label, index) => {
      const shouldAddComma = labels.length > 1 && index !== labels.length - 1
      if (shouldAddComma) {
        return (
          <>
            <InlineCode key={label}>{label}</InlineCode>
            {', '}
          </>
        )
      }
      return <InlineCode key={label}>{label}</InlineCode>
    })
  }

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4}>
        <Stack spacing={4}>
          <Stack spacing={2}>
            <FormControl variant="standard" error={Boolean(errors?.restriction)}>
              <InputLabel htmlFor="restriction" required>
                Apply the following restriction when anyone attempts to read data labeled{' '}
                {renderDataLabels(policyInfo.parameters.dataLabels)} from{' '}
                <InlineCode>{policyInfo.parameters.dataset}</InlineCode>
              </InputLabel>
              <Input
                id="restriction"
                inputProps={{
                  ...register('restriction', {
                    required: 'Please enter a restriction',
                  }),
                }}
              />
              <FormHelperText
                sx={{
                  typography: 'body2',
                }}
                error={!!errors?.restriction}
              >
                {errors?.restriction?.message}
              </FormHelperText>
            </FormControl>
            <Stack spacing={1}>
              <InputLabel>Example</InputLabel>
              <Typography
                component="code"
                variant="code"
                sx={{
                  border: '1px solid',
                  borderRadius: theme => theme.radii.base,
                  borderColor: 'cyralColors.grey.200',
                  color: 'cyralColors.grey.400',
                  display: 'inline',
                  backgroundColor: 'cyralColors.grey.100',
                  padding: 1,
                }}
              >
                (company_name = ‘Cyral’ OR company_name = ‘Microsoft’) AND country = ‘US’
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        <Divider />

        <Footer repoId={repoId} prevStep={prevStep} policyId={policyInfo.id} />
      </Stack>
    </Box>
  )
}

export default StepFour
