/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import styled from '@emotion/styled'
import { CircularProgress } from '@material-ui/core'

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: transparent;
  z-index: 4;
  position: absolute;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
`

const Loading = ({ ...props }) => (
  <LoadingWrapper {...props}>
    <CircularProgress />
  </LoadingWrapper>
)

export default Loading
