import { useMutation } from '@apollo/client'
import { useForm } from 'react-hook-form'

import { graphql, useFragment } from '@jeeves/graphql'
import { useToast } from '@jeeves/new-components'
import { getGraphQLErrorMessage } from '@jeeves/utils/helpers'

const useOperationalMode_QueryFragment = graphql(`
  fragment useOperationalMode_QueryFragment on Query {
    sidecar(id: $sidecarId) {
      id
      passthroughConfiguration
    }
  }
`)

const UPDATE_SIDECAR_PASSTHROUGH_CONFIGURATION = graphql(`
  mutation UpdateSidecarPassthroughConfiguration(
    $updateSidecarPassthroughConfigurationId: ID!
    $passthroughConfiguration: SidecarPassthroughConfiguration!
  ) {
    updateSidecarPassthroughConfiguration(
      id: $updateSidecarPassthroughConfigurationId
      passthroughConfiguration: $passthroughConfiguration
    ) {
      sidecar {
        id
        passthroughConfiguration
      }
    }
  }
`)

export const useOperationalMode = ({ query }) => {
  const { sidecar } = useFragment(useOperationalMode_QueryFragment, query)

  const { toast } = useToast()

  const [updateSidecarPassthroughConfiguration, { loading }] = useMutation(
    UPDATE_SIDECAR_PASSTHROUGH_CONFIGURATION,
    {
      onError: error => {
        toast({
          variant: 'error',
          description:
            getGraphQLErrorMessage(error) ||
            'An error occurred while updating the sidecar passthrough configuration, please try again.',
        })
      },
      onCompleted: () => {
        methods.reset(methods.getValues())
      },
    }
  )

  const methods = useForm({
    defaultValues: {
      passthrough: sidecar.passthroughConfiguration === 'always',
      failover: ['failover', 'always'].includes(sidecar.passthroughConfiguration),
    },
  })

  const onSubmit = async formData => {
    const { passthrough, failover } = formData

    await updateSidecarPassthroughConfiguration({
      variables: {
        updateSidecarPassthroughConfigurationId: sidecar.id,
        passthroughConfiguration: passthrough ? 'always' : failover ? 'failover' : 'never',
      },
    })
  }

  return {
    loading,
    methods,
    onSubmit,
  }
}
