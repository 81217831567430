/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import styled from '@emotion/styled'
import React, { useState } from 'react'
import { CardActions, CircularProgress } from '@material-ui/core/'
import { Button, FlexGrow } from '@jeeves/components/Primitives'
import { CheckBoxWithToolTip, TextFieldWithToolTip } from '../components'
import Typography from '@material-ui/core/Typography'
import SCIM from '../scim'
import { ThemeProvider } from '@mui/material/styles'
import { v5Theme } from '@jeeves/theme'
import { getDocsURL } from '@jeeves/utils/docsURL'

const StyledButton = styled(Button)`
  box-shadow: none;
  border-radius: 20px;
  font-size: 12px;
  letter-spacing: 1px;
  margin-top: 12px;
  padding: 4px 0px;
  font-weight: bold;
  font-family: ${({ theme }) => theme.fontFamily};
`

const ButtonLoading = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
`

const ActionsContainer = styled(CardActions)`
  width: 100%;
  margin-top: 24px;
`

const UpdateButtonContainer = styled.div`
  position: relative;
`

const AdvancedContainer = styled.div`
  margin-top: 2rem;
`

const Edit = ({
  createSCIMIntegration,
  createSCIMIntegrationData,
  updateSCIMIntegrationStatus,
  generateSCIMIntegrationCreds,
  updateIntegrations,
  handleSetEdit,
  putIntegration,
  integration,
}) => {
  // standard configuration settings
  const [displayName, setDisplayName] = useState(integration.displayName)
  const [signInEndpoint, setSignInEndpoint] = useState(integration?.singleSignOnServiceURL)
  const [signOutEndpoint, setSignOutEndpoint] = useState(integration?.singleLogoutServiceURL)
  const [cert, setCert] = useState(integration?.signingCertificate)
  const [disableValidateSignature, setDisableValidateSignature] = useState(
    integration?.disableValidateSignature
  )
  //additional configuration settings
  const [backChannelLogout, setBackChannelLogout] = useState(integration?.backChannelSupported)
  const [disableWantAuthnRequestsSigned, setDisableWantAuthnRequestsSigned] = useState(
    integration.disableWantAuthnRequestsSigned
  )
  const [disableWantAssertionsSigned, setDisableWantAssertionsSigned] = useState(
    integration.disableWantAssertionsSigned
  )
  const [wantAssertionsEncrypted, setWantAssertionsEncrypted] = useState(
    integration.wantAssertionsEncrypted
  )
  const [disableForceAuthentication, setDisableForceAuthentication] = useState(
    integration.disableForceAuthentication
  )
  const [allowedClockSkew, setAllowedClockSkew] = useState(integration.allowedClockSkew)

  const [showAdvanced, setShowAdvanced] = useState(false)
  const [updateLoading, setUpdateLoading] = useState()

  // SCIM configuration
  const [showSCIM, setShowSCIM] = useState(integration?.scimIntegration?.enabled)
  const [regenerateTokenClicked, setRegenerateTokenClicked] = useState(false)
  const [fieldsDirty, setFieldsDirty] = useState(false)
  const [bearerToken, setBearerToken] = useState(null)
  const [expiresIn, setExpiresIn] = useState(null)

  const fieldsSame = () => {
    const displayNameSame = displayName == integration.displayName
    const signInEndpointSame = signInEndpoint == integration.singleSignOnServiceURL
    const signOutEndpointSame = signOutEndpoint == integration.singleLogoutServiceURL
    const certSame = cert == integration.signingCertificate
    const disableValidateSignatureSame =
      disableValidateSignature == integration.disableValidateSignature
    const backChannelLogoutSame = backChannelLogout == integration.backChannelSupported
    const disableWantAuthnRequestsSignedSame =
      disableWantAuthnRequestsSigned == integration.disableWantAuthnRequestsSigned
    const disableWantAssertionsSignedSame =
      disableWantAssertionsSigned == integration.disableWantAssertionsSigned
    const wantAssertionsEncryptedSame =
      wantAssertionsEncrypted == integration.wantAssertionsEncrypted
    const disableForceAuthenticationSame =
      disableForceAuthentication == integration.disableForceAuthentication
    const allowedClockSkewSame = allowedClockSkew == integration.allowedClockSkew
    return (
      displayNameSame &&
      signInEndpointSame &&
      signOutEndpointSame &&
      certSame &&
      disableValidateSignatureSame &&
      backChannelLogoutSame &&
      disableWantAuthnRequestsSignedSame &&
      disableWantAssertionsSignedSame &&
      wantAssertionsEncryptedSame &&
      disableForceAuthenticationSame &&
      allowedClockSkewSame
    )
  }

  const showSCIMSame = () => {
    return (
      (showSCIM && integration?.scimIntegration?.enabled) ||
      (!showSCIM && !integration?.scimIntegration?.enabled)
    )
  }

  const resetStandardConfigurations = () => {
    setDisplayName(integration.displayName)
    setSignInEndpoint(integration.singleSignOnServiceURL)
    setSignOutEndpoint(integration.singleLogoutServiceURL)
    setCert(integration.signingCertificate)
    setDisableValidateSignature(integration.disableValidateSignature)
  }

  const resetAdditionalConfigurations = () => {
    setBackChannelLogout(integration.backChannelSupported)
    setDisableWantAuthnRequestsSigned(integration.disableWantAuthnRequestsSigned)
    setDisableWantAssertionsSigned(integration.disableWantAssertionsSigned)
    setWantAssertionsEncrypted(integration.wantAssertionsEncrypted)
    setDisableForceAuthentication(integration.disableForceAuthentication)
    setAllowedClockSkew(integration.allowedClockSkew)
  }

  const resetSCIMConfigurations = () => {
    setShowSCIM(integration?.scimIntegration?.enabled)
  }

  const resetInput = () => {
    resetStandardConfigurations()
    resetAdditionalConfigurations()
    resetSCIMConfigurations()
  }

  const handleCancel = () => {
    handleSetEdit(false)
    resetInput()
    updateIntegrations()
  }

  // primary handler for save button
  const handleSave = async () => {
    if (!fieldsSame()) {
      await updateAADInputs()
    }

    // creating SCIM && AAD without scim
    if (!integration?.scimIntegration && showSCIM) {
      try {
        const data = await createSCIMIntegration({
          variables: {
            scimIntegration: { IDPID: integration.id, enabled: true, authMode: 'HTTPHeader' },
          },
        })

        setBearerToken(data?.data?.createSCIMIntegration?.authSecret)
        setExpiresIn(data?.data?.createSCIMIntegration?.authSecretExpiry)
      } catch (e) {
        console.error(e)
      }
    }

    // enabling SCIM && AAD with disabled SCIM
    if (!integration?.scimIntegration?.enabled && showSCIM) {
      try {
        await updateSCIMIntegrationStatus({ variables: { IDPID: integration.id, enabled: true } })
        updateIntegrations()
      } catch (e) {
        console.error(e)
      }
    }

    //disabling SCIM && AAD with enabled SCIM
    if (integration?.scimIntegration?.enabled == true && !showSCIM) {
      try {
        await updateSCIMIntegrationStatus({ variables: { IDPID: integration.id, enabled: false } })
        updateIntegrations()
      } catch (e) {
        console.error(e)
      }
    }

    // regenerate bearer token
    if (regenerateTokenClicked) {
      try {
        const data = await generateSCIMIntegrationCreds({ variables: { idpid: integration.id } })
        setBearerToken(data?.data?.generateSCIMIntegrationCreds?.authSecret)
        setExpiresIn(data?.data?.generateSCIMIntegrationCreds?.authSecretExpiry)
      } catch (e) {
        console.error(e)
      }
    }
  }

  const updateAADInputs = async () => {
    setUpdateLoading(true)
    const integrationObj = {
      identityProvider: 'aad',
      ldapGroupAttribute: integration.ldapGroupAttribute,
      samlp: {
        alias: integration.id,
        providerID: 'saml',
        disabled: false,
        firstBrokerLoginFlowAlias: 'SAML_First_Broker',
        displayName,
        storeToken: false,
        addReadTokenRoleOnCreate: false,
        trustEmail: false,
        linkOnly: false,
        internalID: integration.internalID,
        config: {
          disableUsingJWKSUrl: false,
          syncMode: 'FORCE',
          nameIDPolicyFormat: 'urn:oasis:names:tc:SAML:1.1:nameid-format:unspecified',
          principalType: 'SUBJECT',
          signatureType: 'RSA_SHA256',
          samlXmlKeyNameTranformer: 'KEY_ID',
          disableHidingOnLoginPage: true,
          backChannelSupported: backChannelLogout,
          disableWantAuthnRequestsSigned: disableWantAuthnRequestsSigned,
          disableWantAssertionsSigned: disableWantAssertionsSigned,
          wantAssertionsEncrypted: wantAssertionsEncrypted,
          disableForceAuthentication: disableForceAuthentication,
          disableValidateSignature,
          singleSignOnServiceURL: signInEndpoint,
          singleLogoutServiceURL: signOutEndpoint,
          xmlSigKeyInfoKeyNameTransformer: 'KEY_ID',
          signingCertificate: cert,
          allowedClockSkew: allowedClockSkew,
        },
      },
    }

    await putIntegration(integrationObj)
  }

  const handleOnUpdate = async () => {
    try {
      setUpdateLoading(true)

      await handleSave()
      setFieldsDirty(false)
    } catch (e) {
    } finally {
      setUpdateLoading(false)
    }
  }

  React.useEffect(() => {
    setFieldsDirty(!fieldsSame() || !showSCIMSame() || (showSCIM && regenerateTokenClicked))
  }, [
    displayName,
    signInEndpoint,
    signOutEndpoint,
    cert,
    disableValidateSignature,
    backChannelLogout,
    disableWantAuthnRequestsSigned,
    disableWantAssertionsSigned,
    wantAssertionsEncrypted,
    disableForceAuthentication,
    allowedClockSkew,
    showSCIM,
    regenerateTokenClicked,
  ])

  return (
    <React.Fragment>
      <TextFieldWithToolTip
        onChange={e => setDisplayName(e.target.value.trim())}
        value={displayName}
        label="Display Name"
        helpText="Display name used to identify the integration."
      />

      <TextFieldWithToolTip
        onChange={e => setSignInEndpoint(e.target.value.trim())}
        value={signInEndpoint}
        label="Sign-In URL"
        helpText="URL used to send authentication requests."
      />

      <TextFieldWithToolTip
        onChange={e => setSignOutEndpoint(e.target.value.trim())}
        value={signOutEndpoint}
        label="Sign-Out URL"
        helpText="URL used to send logout requests."
      />

      <CheckBoxWithToolTip
        checked={!disableValidateSignature}
        onChange={() => setDisableValidateSignature(!disableValidateSignature)}
        value="validateSignature"
        color="primary"
        label="Validate Signature"
        helpText="Enable/Disable signature validation of SAML responses."
      />

      <TextFieldWithToolTip
        multiline
        rows={8}
        disabled={disableValidateSignature}
        label="X.509 Certificate"
        onChange={e => setCert(e.target.value.trim())}
        value={cert}
        helpText="The signing certificate used to validate signatures."
      />

      <div css={{ marginBottom: '8px' }}>
        <CheckBoxWithToolTip
          checked={showSCIM}
          onChange={() => setShowSCIM(!showSCIM)}
          value="showSCIM"
          color="primary"
          label={
            <Typography>
              Enable service account resolution.{' '}
              <a
                href={getDocsURL({
                  docsPath: '/integrations/authentication/idp/azure-ad#scim-with-azure-ad',
                })}
                target="_blank"
                css={{ textDecoration: 'none', color: '#26459E', fontWeight: 600 }}
              >
                Learn More
              </a>
            </Typography>
          }
        />
      </div>
      <ThemeProvider theme={v5Theme}>
        {showSCIM && (
          <SCIM
            expiresIn={expiresIn}
            bearerToken={bearerToken}
            integration={integration}
            regenerateTokenClicked={regenerateTokenClicked}
            setRegenerateTokenClicked={setRegenerateTokenClicked}
          ></SCIM>
        )}
      </ThemeProvider>

      <StyledButton onClick={() => setShowAdvanced(!showAdvanced)}>
        {showAdvanced ? 'Hide Advanced Settings' : 'Show Advanced Settings'}
      </StyledButton>

      {/* additional config */}
      {showAdvanced && (
        <AdvancedContainer>
          <CheckBoxWithToolTip
            checked={backChannelLogout}
            onChange={() => setBackChannelLogout(!backChannelLogout)}
            value="backChannelLougout"
            label="Back Channel Logout"
            helpText="Indicates whether or not the external IdP supports backchannel logout."
          />

          <CheckBoxWithToolTip
            checked={disableWantAuthnRequestsSigned}
            onChange={() => setDisableWantAuthnRequestsSigned(!disableWantAuthnRequestsSigned)}
            value="disableWantsAuthnRequestsSigned"
            label="Wants AuthnRequests Unsigned"
            helpText="Indicates whether the identity provider expects a signed AuthnRequest."
          />

          <CheckBoxWithToolTip
            checked={disableWantAssertionsSigned}
            onChange={() => setDisableWantAssertionsSigned(!disableWantAssertionsSigned)}
            value="disableWantsAssertionsSigned"
            label="Wants Assertions Unsigned"
            helpText="Indicates whether this service provider expects a signed Assertion."
          />

          <CheckBoxWithToolTip
            checked={wantAssertionsEncrypted}
            onChange={() => setWantAssertionsEncrypted(!wantAssertionsEncrypted)}
            value="wantsAssertionsEncrypted"
            label="Wants Assertions Encrypted"
            helpText="Indicates whether this service provider expects an encrypted Assertion."
          />

          <CheckBoxWithToolTip
            checked={disableForceAuthentication}
            onChange={() => setDisableForceAuthentication(!disableForceAuthentication)}
            value="disableForceAuthentication"
            label="Disable Force Authentication"
            helpText="Indicates whether the identity provider must authenticate the presenter directly
            rather than rely on a previous security context."
          />

          <TextFieldWithToolTip
            css={{ marginTop: '1rem' }}
            onChange={e => setAllowedClockSkew(e.target.value.trim())}
            value={allowedClockSkew}
            label="Allowed Clock Skew"
            type="number"
            helpText="Clock skew in seconds that is tolerated when validating identity provider tokens."
          />
        </AdvancedContainer>
      )}

      <ActionsContainer>
        <FlexGrow>&nbsp;</FlexGrow>
        <Button color="primary" size="small" onClick={() => handleCancel()}>
          Cancel
        </Button>
        <UpdateButtonContainer>
          <Button
            color="primary"
            onClick={() => handleOnUpdate()}
            disabled={updateLoading || !fieldsDirty}
            size="small"
            variant="contained"
          >
            Save
          </Button>
          {updateLoading && <ButtonLoading size={24} />}
        </UpdateButtonContainer>
      </ActionsContainer>
    </React.Fragment>
  )
}

export default Edit
