import { Stack } from '@mui/material'
import PolicyRule from '../../../../PolicyRule'
import { useDataProtectionVariation } from '../useDataProtectionVariation'

const Variation1 = () => {
  const { action, operations, dataLabels } = useDataProtectionVariation()

  return (
    <Stack spacing={1}>
      <PolicyRule title="This policy will" content={action} />
      <PolicyRule title="whenever any" content={operations} />
      <PolicyRule title="is made to any data labeled as" content={dataLabels} />
    </Stack>
  )
}

export default Variation1
