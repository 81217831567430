import { useContext } from 'react'
import ExpressClient from '@jeeves/clients/express'
import { useAuth } from '@jeeves/components/Auth'
import usePopup from '@jeeves/components/PopupMessage/hooks/usePopup'

import { AlertsContext } from '../contexts/AlertsContext'

import moment from 'moment-timezone'
import { get as lodashGet } from 'lodash'

const useAlerts = () => {
  const [state, setState] = useContext(AlertsContext)

  const { setPopup, popupTypes } = usePopup()

  const { getTokenSilently, user } = useAuth()
  const ec = new ExpressClient(getTokenSilently)

  const handleRequestSort = (event, property) => {
    const orderBy = property
    let order = 'asc'
    if (state.orderBy === property && state.order === 'asc') {
      order = 'desc'
    }
    setState(state => ({ ...state, order, orderBy }))
  }

  const handleChangePage = (event, page) => {
    setState(state => ({ ...state, page }))
  }

  const handleChangeRowsPerPage = event => {
    setState(state => ({ ...state, rowsPerPage: event.target.value }))
  }

  const severityMap = new Map([
    [0, 'Low'],
    [1, 'Medium'],
    [2, 'High'],
  ])

  const chartColors = [
    {
      hover: '#f00c0c90',
      background: '#f00c0c90',
      border: '#f00c0c90',
    },
    {
      hover: '#d26e0090',
      background: '#d26e0090',
      border: '#d26e0090',
    },
    {
      hover: '#ec9c9d90',
      background: '#ec9c9d90',
      border: '#ec9c9d90',
    },
    {
      hover: '#dee02d90',
      background: '#dee02d90',
      border: '#dee02d90',
    },
    {
      hover: '#82b93b90',
      background: '#82b93b90',
      border: '#82b93b90',
    },
    {
      hover: '#55d18890',
      background: '#55d18890',
      border: '#55d18890',
    },
    {
      hover: '#39e3cc90',
      background: '#39e3cc90',
      border: '#39e3cc90',
    },
    {
      hover: '#69f0ff90',
      background: '#69f0ff90',
      border: '#69f0ff90',
    },
  ]

  const formatDate = isoDate => {
    return (
      moment
        // .local()
        .unix(isoDate)
        .format('MMMM Do YYYY, h:mm:ss a z')
    )
  }

  const handleTimeSpan = timeSpan => {
    setState(state => ({ ...state, timeSpan: timeSpan }))
  }

  const getTimeSpan = timeSpan => {
    const SECOND = 1000
    const MINUTE = SECOND * 60
    const HOUR = MINUTE * 60
    const DAY = HOUR * 24
    const WEEK = DAY * 7
    const MONTH = WEEK * 4

    const timeSpanMap = new Map([
      ['FIFTEEN_MINS', { value: MINUTE * 15, label: 'Last 15 minutes' }],
      ['HOUR', { value: HOUR, label: 'Last hour' }],
      ['DAY', { value: DAY, label: 'Last day' }],
      ['WEEK', { value: WEEK, label: 'Last week' }],
      ['MONTH', { value: MONTH, label: 'Last month' }],
    ])

    return timeSpanMap.get(timeSpan)
  }

  const getMetrics = async () => {
    const _getChartData = (metricsData, chartLabels, windowStart, windowEnd, bucketLength) => {
      let data = []
      try {
        for (var type in metricsData) {
          let values = new Array(chartLabels.length).fill(0)
          let severity = new Array(chartLabels.length).fill(0)
          for (var timestamp in metricsData[type]) {
            if (
              windowStart <= parseInt(timestamp) &&
              parseInt(timestamp) <= windowEnd + bucketLength
            ) {
              let occurrences = metricsData[type][timestamp].occurrences
              let severityValue = metricsData[type][timestamp].severity
              let bucketIndex = parseInt(Math.abs(timestamp - windowStart) / bucketLength)
              values[bucketIndex] += occurrences
              severity[bucketIndex] = severityValue
            }
          }
          data.push({ label: type, values: values, severity: severity })
        }
        return data
      } catch (e) {
        throw e
      }
    }

    const _getMetricsData = async () => {
      try {
        const alerts = await ec.get(`/alerts/all`).then(res => res.data)

        let metricsData = {}

        alerts.map(alert => {
          let type = alert.alerttype

          let alertEpochTime = alert.time * 1000

          if (!metricsData[type]) {
            metricsData[type] = {}
          }
          metricsData[type][alertEpochTime] = {
            occurrences: alert.count,
            severity: alert.severity,
          }
        })
        return metricsData
      } catch (e) {
        throw e
      }
    }

    const _getChartLabels = (windowStart, windowEnd, bucketLength) => {
      try {
        let chartLabels = []
        for (let i = windowStart; i <= windowEnd; i += bucketLength) {
          chartLabels.push(i)
        }
        return chartLabels
      } catch (e) {
        throw e
      }
    }

    const _getMetricsConfig = () => {
      try {
        const ONE_SECOND = 1000
        const ONE_MINUTE = ONE_SECOND * 60
        const ONE_HOUR = ONE_MINUTE * 60

        let bucketLength, windowLength

        switch (state.timeSpan) {
          case 'Last day':
            bucketLength = ONE_MINUTE * 30
            windowLength = ONE_HOUR * 24
            break
          case 'Last 15 minutes':
            bucketLength = ONE_MINUTE * 1
            windowLength = ONE_MINUTE * 15
            break
        }

        const metricsConfig = { bucketLength, windowLength }
        return metricsConfig
      } catch (e) {
        throw e
      }
    }

    const _getWindowEnd = bucketLength => {
      try {
        let windowEnd = moment.utc().valueOf()
        windowEnd = windowEnd - (windowEnd % bucketLength)
        return windowEnd
      } catch (e) {
        throw e
      }
    }

    try {
      const metricsData = await _getMetricsData()
      const metricsConfig = _getMetricsConfig()

      const bucketLength = metricsConfig.bucketLength
      const windowEnd = _getWindowEnd(bucketLength)
      const windowStart = windowEnd - metricsConfig.windowLength

      const chartLabels = _getChartLabels(windowStart, windowEnd, bucketLength)

      const chartData = {
        chartLabels,
        data: _getChartData(metricsData, chartLabels, windowStart, windowEnd, bucketLength),
      }
      return chartData
    } catch (e) {
      console.error(e)
      throw e
    }
  }

  return {
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
    severityMap,
    chartColors,
    formatDate,
    getMetrics,
    lodashGet,
    timeSpan: state.timeSpan,
    handleTimeSpan,
    getTimeSpan,
    ec,
  }
}

export default useAlerts
