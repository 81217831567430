import { Stack, Typography } from '@mui/material'

import { LearnMore } from '@jeeves/new-components'

import { CustomDeploymentModal, DeploymentMethod, ExpressDeploymentModal } from './components'

import { DeploymentMethodProps } from './components/DeploymentMethod'

const QUICKSTART_GUIDE_URL = 'https://cyral-quickstart.github.io'

const Deployment = () => {
  const deploymentMethods: DeploymentMethodProps[] = [
    {
      title: 'Express',
      description: (
        <>
          Quickly deploy a linux sidecar with a single command.{' '}
          <LearnMore docsPath="/sidecars/deployment/#express" useVersionedDocsURL />
        </>
      ),
      action: <ExpressDeploymentModal />,
    },
    {
      title: 'Cyral Templates',
      description: (
        <>
          Explore our <LearnMore text="Quickstart guide" href={QUICKSTART_GUIDE_URL} /> for prebuilt
          deployment solutions. Discover a variety of templates and examples compatible with tools
          like Terraform, AWS CloudFormation, and Helm, as well as platforms including AWS EC2,
          Kubernetes, and more.{' '}
          <LearnMore docsPath="/sidecars/deployment/#cyral-templates" useVersionedDocsURL />
        </>
      ),
      action: <CustomDeploymentModal />,
    },
    {
      title: 'DIY',
      description: (
        <>
          Choose to deploy our single container image, adaptable for use across a spectrum of
          containerization platforms. This approach is particularly useful in scenarios where our
          Quickstart Library templates might not be applicable.{' '}
          <LearnMore docsPath="/sidecars/deployment/custom" useVersionedDocsURL />
        </>
      ),
      action: <CustomDeploymentModal />,
    },
  ]

  return (
    <Stack spacing={4}>
      <Typography variant="body2" color="text.secondary">
        Deploy this sidecar using one of the three methods below.{' '}
      </Typography>

      <Stack spacing={2}>
        {deploymentMethods.map(deploymentMethod => (
          <DeploymentMethod {...deploymentMethod} key={deploymentMethod.title} />
        ))}
      </Stack>
    </Stack>
  )
}

export default Deployment
