import * as React from 'react'
import { useFragment_experimental, gql } from '@apollo/client'

import { Button } from '@jeeves/new-components'

import BindDataRepoDialog from './BindDataRepoDialog'

const BindDataRepo_queryFragment = gql`
  fragment BindDataRepo_query on Query {
    ...BindDataRepoDialog_query
  }
  ${BindDataRepoDialog.fragments.BindDataRepoDialog_queryFragment}
`

const BindDataRepo = ({ query, refreshCurrentTableViewCallbackRef }) => {
  const { data } = useFragment_experimental({
    fragment: BindDataRepo_queryFragment,
    fragmentName: 'BindDataRepo_query',
    from: query,
  })

  const [open, setOpen] = React.useState(false)

  const openDialog = () => setOpen(true)
  const closeDialog = () => setOpen(false)

  return (
    <React.Fragment>
      <Button onClick={openDialog}>Bind Data Repo</Button>

      <BindDataRepoDialog open={open} onClose={closeDialog} query={data} refreshCurrentTableViewCallbackRef={refreshCurrentTableViewCallbackRef} />
    </React.Fragment>
  )
}

BindDataRepo.fragments = {
  BindDataRepo_queryFragment,
}

export default BindDataRepo
