import * as React from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { Button } from '@jeeves/new-components'

import AccessRulesTable from './components/AccessRulesTable/AccessRulesTable'
import AddAccessRuleModal from './components/AddAccessRuleModal'

const AccessRules = ({ userAccount }) => {
  const [addModalOpen, setAddModalOpen] = React.useState(false)

  const toggleAddAccessRuleModal = React.useCallback(() => {
    setAddModalOpen(oldAddModalOpen => !oldAddModalOpen)
  }, [])

  const userAccountId = userAccount.id
  const rules = userAccount.accessRules

  return (
    <React.Fragment>
      <Stack spacing={2}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h4" sx={{ color: 'text.secondary' }}>
            Access Rules
          </Typography>

          {rules.length > 0 && (
            <Button variant="outlined" color="secondary" onClick={toggleAddAccessRuleModal}>
              Add Rule
            </Button>
          )}
        </Box>

        <Stack spacing={5}>
          <AccessRulesTable userAccount={userAccount} />

          {rules.length === 0 && <EmptyState openAddAccessRuleModal={toggleAddAccessRuleModal} />}
        </Stack>
      </Stack>

      <AddAccessRuleModal
        open={addModalOpen}
        onClose={toggleAddAccessRuleModal}
        userAccountId={userAccountId}
      />
    </React.Fragment>
  )
}

const EmptyState = ({ openAddAccessRuleModal }) => {
  return (
    <Stack spacing={2} sx={{ alignItems: 'center' }}>
      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        Specify who can access this account.
      </Typography>
      <Button variant="outlined" color="secondary" onClick={openAddAccessRuleModal}>
        Add Rule
      </Button>
    </Stack>
  )
}

export default AccessRules
