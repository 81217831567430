import { useState, useCallback } from 'react'

import { useRepositoryDetailContext } from '@jeeves/pages/RepositoryDetail/contexts/RepositoryDetailContext'
import { policyIdentities } from '@jeeves/pages/RepositoryDetail/Tabs/Policies/helpers/policyIdentities'

import { useCreatePolicy, useUpdatePolicy } from '../hooks'

const useReadLimit = ({ policy, template }) => {
  const { repoId } = useRepositoryDetailContext()

  const [snackBarOpen, setSnackBarOpen] = useState(false)
  const [activeStep, setActiveStep] = useState(0)
  const [readLimitInfo, setReadLimitInfo] = useState({
    id: policy?.id ?? '',
    name: policy?.name ?? '',
    description: policy?.description ?? '',
    tags: policy?.tags ?? template.tags,
    template: policy?.template ?? template,
    parameters: {
      action: policy?.parameters?.action ?? 'alert',
      dataLabels: policy?.parameters?.readLimitDataLabels ?? null,
      databaseAccountsAppliedTo: {
        modifier: policy?.parameters?.databaseAccountsAppliedTo?.modifier ?? 'ALL',
        databaseAccounts: policy?.parameters?.databaseAccountsAppliedTo?.databaseAccounts ?? [],
      },
      identitiesAppliedTo: {
        modifier: policy?.parameters?.identitiesAppliedTo?.modifier ?? 'ALL',
        identities: policy?.parameters?.identitiesAppliedTo?.identities ?? [],
      },
      rowLimit: policy?.parameters?.rowLimit ?? null,
    },
  })

  const isEditing = Boolean(policy?.id)

  const [createReadLimitPolicy, { loading: createLoading, error: createError }] = useCreatePolicy({
    onError: _ => {
      setSnackBarOpen(true)
    },
  })

  const [updateReadLimitPolicy, { loading: updateLoading, error: updateError }] = useUpdatePolicy({
    onError: _ => {
      setSnackBarOpen(true)
    },
  })

  const prevStep = () => setActiveStep(currStep => currStep - 1)
  const nextStep = useCallback(() => {
    setActiveStep(currStep => currStep + 1)
  }, [])

  const handleCloseSnackbar = () => {
    setSnackBarOpen(false)
  }

  const handlePolicySubmit = async () => {
    const shapedIdentities = readLimitInfo?.parameters?.identitiesAppliedTo?.identities?.map(
      identity => ({ type: policyIdentities[identity.__typename], name: identity.name })
    )

    const input = {
      readLimit: {
        name: readLimitInfo.name,
        description: readLimitInfo.description,
        tags: readLimitInfo.tags,
        action: readLimitInfo.parameters.action,
        dataLabels: readLimitInfo.parameters.dataLabels,
        databaseAccountsAppliedTo: readLimitInfo?.parameters?.databaseAccountsAppliedTo,
        identitiesAppliedTo: {
          modifier: readLimitInfo.parameters.identitiesAppliedTo.modifier,
          identities: shapedIdentities,
        },
        rowLimit: Number(readLimitInfo.parameters.rowLimit),
      },
    }

    try {
      if (isEditing) {
        const policyId = policy.id
        return await updateReadLimitPolicy({
          variables: {
            policyId,
            input,
          },
        })
      }

      return await createReadLimitPolicy({
        variables: {
          repoId,
          input,
        },
      })
    } catch (e) {
      console.error(e)
    }
  }

  return {
    readLimitInfo,
    activeStep,
    snackBarOpen,
    loading: createLoading || updateLoading,
    errorMessage: createError?.message || updateError?.message,
    setReadLimitInfo,
    prevStep,
    nextStep,
    handlePolicySubmit,
    handleCloseSnackbar,
  }
}

export default useReadLimit
