import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import { useMutation } from '@apollo/client'

import { graphql } from '@jeeves/graphql'
import { useToast } from '@jeeves/new-components'
import { useSidecarDetailContext } from '@jeeves/pages/WrapperDetail/contexts/SidecarDetailContext'
import { getGraphQLErrorMessage } from '@jeeves/utils/helpers'

const DELETE_SIDECAR = graphql(`
  mutation DeleteSidecar($id: ID!) {
    deleteSidecar(id: $id) {
      deletedSidecarId
    }
  }
`)

export const useDeleteSidecarModal = () => {
  const history = useHistory()

  const { sidecarId } = useSidecarDetailContext()

  const { toast } = useToast()

  const [deleteSidecar, { loading }] = useMutation(DELETE_SIDECAR, {
    onError: error => {
      toast({
        variant: 'error',
        description:
          getGraphQLErrorMessage(error) ||
          'An error occurred while deleting this sidecar. Please try again.',
      })
    },
    onCompleted: () => {
      history.push('/sidecars')
    },
  })

  const methods = useForm({
    defaultValues: {
      confirmation: '',
    },
  })

  const onSubmit = async () => {
    await deleteSidecar({
      variables: {
        id: sidecarId,
      },
    })
  }

  return {
    loading,
    methods,
    onSubmit: methods.handleSubmit(onSubmit),
  }
}
