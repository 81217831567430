import * as React from 'react'

import { Box, Typography, TypographyTypeMap } from '@mui/material'
import LaunchIcon from '@mui/icons-material/Launch'
import { getDocsURL } from '@jeeves/utils/docsURL'

type BaseProps = {
  text?: string
  variant?: TypographyTypeMap['props']['variant']
}

type ExternalLinkProps = BaseProps & {
  href: string
  docsPath?: never
  useVersionedDocsURL?: never
}

type DocsLinkProps = BaseProps & {
  href?: never
  docsPath: string
  useVersionedDocsURL?: boolean
}

type Props = ExternalLinkProps | DocsLinkProps

const LearnMore = ({
  text = 'Learn More',
  variant = 'h6',
  docsPath,
  useVersionedDocsURL,
  href,
}: Props) => {
  return (
    <Box
      component="a"
      href={href || getDocsURL({ docsPath, useVersionedDocsURL })}
      target="_blank"
      rel="noreferrer"
      sx={{
        display: 'inline-flex',
        gap: '2px',
        alignItems: 'center',
        cursor: 'pointer',
        textDecoration: 'none',
      }}
    >
      <Typography component="span" variant={variant} sx={{ color: 'primary.main' }}>
        {text}
      </Typography>

      <LaunchIcon sx={{ color: 'primary.main', fontSize: '16px' }} />
    </Box>
  )
}

export default LearnMore
