import { useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'

import { Box, Divider, FormControl, Stack, FormHelperText } from '@mui/material'
import { useRepositoryDetailContext } from '@jeeves/pages/RepositoryDetail/contexts/RepositoryDetailContext'

import InputTags from '@jeeves/pages/RepositoryDetail/Tabs/Policies/views/AddPolicy/components/InputTags'
import Footer from '@jeeves/pages/RepositoryDetail/Tabs/Policies/views/AddPolicy/components/Footer'

const StepTwo = ({ serviceAccountAbuseInfo, nextStep, prevStep, setServiceAccountAbuseInfo }) => {
  const { repoId } = useRepositoryDetailContext()
  const {
    setValue,
    handleSubmit,
    watch,
    control,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      action: serviceAccountAbuseInfo.parameters.action,
      serviceAccounts: serviceAccountAbuseInfo.parameters.serviceAccounts,
    },
  })

  const onSubmit = data => {
    setServiceAccountAbuseInfo(info => {
      return {
        ...info,
        parameters: {
          ...info.parameters,
          action: data.action,
          serviceAccounts: data.serviceAccounts,
        },
      }
    })
  }

  useEffect(() => {
    if (isSubmitSuccessful) {
      nextStep()
    }
  }, [isSubmitSuccessful, nextStep])

  const { serviceAccounts = [] } = watch()

  const onAddServiceAccounts = updatedTags => {
    setValue('serviceAccounts', updatedTags, { shouldValidate: true, shouldDirty: true })
  }

  const onDeleteServiceAccounts = updatedTags => {
    setValue('serviceAccounts', updatedTags, { shouldDirty: true })
  }

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4}>
        <Stack spacing={4} sx={{ maxWidth: '32rem' }}>
          <FormControl
            variant="standard"
            error={Boolean(errors?.serviceAccounts)}
            sx={{ display: 'flex' }}
          >
            <Controller
              name="serviceAccounts"
              control={control}
              rules={{
                validate: tags => {
                  return tags?.length > 0 || 'Please enter at least one service account'
                },
              }}
              render={() => (
                <InputTags
                  inputLabel="Specify service accounts for which end user attribution is always required"
                  required
                  tags={serviceAccounts}
                  isSubmitting={isSubmitting}
                  onAdd={onAddServiceAccounts}
                  onDelete={onDeleteServiceAccounts}
                />
              )}
            />
            <FormHelperText sx={{ typography: 'body2' }} error={!!errors?.serviceAccounts}>
              {errors?.serviceAccounts?.message}
            </FormHelperText>
          </FormControl>
        </Stack>
        <Divider />
        <Footer repoId={repoId} prevStep={prevStep} policyId={serviceAccountAbuseInfo.id} />
      </Stack>
    </Box>
  )
}

export default StepTwo
