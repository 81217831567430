import { Stack, Typography } from '@mui/material'
import useAccessTokenStructure from './useAccessTokenStructure'

import {
  ManageAccessToken,
  GenerateAccessToken,
  DeleteAccessToken,
  EditAccessToken,
} from './components'

const EmbeddedAccessToken = () => {
  const {
    view,
    setView,
    selectedAccessTokenId,
    setSelectedAccessTokenId,
    data,
    loading,
    error,
    goBackToManage,
    handleDone,
  } = useAccessTokenStructure()

  const views = {
    manage: {
      component: (
        <ManageAccessToken
          query={data}
          loading={loading}
          error={error}
          setView={setView}
          setSelectedAccessTokenId={setSelectedAccessTokenId}
        />
      ),
      title: 'Access Tokens',
    },
    create: {
      component: <GenerateAccessToken handleBack={handleDone} query={data!} />,
      title: 'Generate Access Token',
    },
    delete: {
      component: (
        <DeleteAccessToken
          selectedAccessTokenId={selectedAccessTokenId}
          goBackToManage={goBackToManage}
          query={data!}
        />
      ),
      title: 'Delete Access Token',
    },
    edit: {
      component: (
        <EditAccessToken
          selectedAccessTokenId={selectedAccessTokenId}
          goBackToManage={goBackToManage}
          query={data!}
        />
      ),
      title: 'Edit Access Token',
    },
  }

  const { component, title } = views[view]

  return (
    <Stack spacing={4} sx={{ p: 4 }}>
      <Typography variant="h3" sx={{ color: 'text.primary' }}>
        {title}
      </Typography>
      {component}
    </Stack>
  )
}

export default EmbeddedAccessToken
