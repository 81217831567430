/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Typography, Link } from '@material-ui/core'
import { isValidSemver } from '../../../utils'
import { getDocsURL } from '@jeeves/utils/docsURL'

const ControlPlaneVersion = () => {
  return isValidSemver(window._env_.control_plane_version) ? (
    <Typography
      css={theme => css`
        text-align: center;
        font-size: ${theme.fontSize[2]};
        font-family: ${theme.typography.monospaced};
      `}
    >
      <Link
        css={theme => ({
          color: '#5e97b3',
          '&:hover': {
            color: theme.palette.blue[4],
          },
        })}
        href={getDocsURL({ docsPath: 'release-notes/whats-new/' })}
        target="_blank"
      >
        {window._env_.control_plane_version}
      </Link>
    </Typography>
  ) : null
}

export default ControlPlaneVersion
