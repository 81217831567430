import { parse, parseISO } from 'date-fns'
import { format, formatInTimeZone } from 'date-fns-tz'
import { getLocalTimeZone } from '@internationalized/date'

// formatLocalizedTimestamp takes an ISO8601-formatted timestamp as input and returns
//    it in a human-friendly format — by default, the output only includes the date.
//  For example:
//   input: 2022-07-03T18:30:00Z
//   output: July 3rd, 2022
//  If the `includeTime` option is specified, then the time will be included in the output
//    with the user's local timezone
//  For example:
//   input: 2022-07-03T18:30:00Z, options = { includeTime: true }
//   output: July 3rd, 2022 at 6:30pm PT
export const formatLocalizedTimestamp = (timestamp, options = {}) => {
  const { includeTime = true } = options
  const formattedLongLocalizedDate = formatLocalDate(timestamp)

  if (!includeTime) {
    return formattedLongLocalizedDate
  }

  const formattedLocalTimeWithTZ = formatLocalTime(timestamp)

  return `${formattedLongLocalizedDate} at ${formattedLocalTimeWithTZ}`
}

// 'timestamp' should be an ISO8601-formatted string
export const formatLocalDate = (timestamp, dateFormat = 'PPP') => {
  const parsedTimeStamp = parseISO(timestamp)

  return format(parsedTimeStamp, dateFormat)
}

// 'timestamp' should be an ISO8601-formatted string
export const formatLocalTime = timestamp => {
  const localTimeZone = getLocalTimeZone()
  const parsedTimeStamp = parseISO(timestamp)

  return format(parsedTimeStamp, 'h:mm aaa zzz', {
    timeZone: localTimeZone,
  })
}

// 'timestamp' should be an ISO8601-formatted string
export const formatLocalTimeWithSeconds = timestamp => {
  const localTimeZone = getLocalTimeZone()
  const parsedTimeStamp = parseISO(timestamp)

  return format(parsedTimeStamp, 'h:mm:ssaaa zzz', {
    timeZone: localTimeZone,
  })
}

export const formatAuditLogTimestamp = timestampStr => {
  try {
    const parsedTimestamp = _parseAuditLogTimestamp(timestampStr)
    return formatTimestampAbsolute(parsedTimestamp)
  } catch (e) {
    // console.error(e)
    return timestampStr
  }
}

/**
 * Parses a timestamp string as formatted in the audit log and returns a Date object
 */
export const _parseAuditLogTimestamp = timestampStr => {
  try {
    const formatStr = "yyyy-MM-dd HH:mm:ss.SSS xx 'UTC'"
    const parsedTimestamp = parse(timestampStr, formatStr, new Date())
    return parsedTimestamp
  } catch (e) {
    throw e
  }
}

/**
 * Takes a Date object and returns it as an absolute timestamp string in a standard format.
 * The timestamp will be localized to align with the client's system timezone.
 */
export const formatTimestampAbsolute = parsedTimestamp => {
  try {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    return formatInTimeZone(parsedTimestamp, timeZone, 'MMM dd, yyyy HH:mm:ss zzz')
  } catch (e) {
    throw e
  }
}
