import { Box, Stack, Typography } from '@mui/material'
import DnsIcon from '@mui/icons-material/Dns'

const UnableToRequestAccess = () => {
  return (
    <Box sx={{ pt: 4 }}>
      <Stack spacing={2} sx={{ alignItems: 'center' }}>
        <DnsIcon sx={{ fontSize: 64, color: 'secondary.main' }} />

        <Stack spacing={1} sx={{ alignItems: 'center' }}>
          <Typography variant="h6" sx={{ color: 'text.secondary' }}>
            No Data Repositories
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            You can request access once an admin has added a data repository and made it visible for
            the Data Access Portal.
          </Typography>
        </Stack>
      </Stack>
    </Box>
  )
}

export default UnableToRequestAccess
