import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import AddEmail from '../AddEmail'

let id = 0
function createData(email, role) {
  id += 1
  return { id, email, role }
}

const rows = [
  createData('john.walker@healthyhearts.com'),
  createData('jeremy.traore@healthyhearts.com'),
  createData('samantha.gorg@healthyhearts.com'),
]

const styles = theme => ({
  content: {
    padding: 0,
  },
})

const Users = ({ classes, className }) => {
  return (
    <Card className={className}>
      <CardHeader
        title="Billing contact"
        action={<AddEmail />}
        subheader={
          <Typography variant="caption">
            Email address which billing notification will be send to
          </Typography>
        }
      />

      <CardContent className={classes.content}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>cynthia.spencer@healthyhearts.com</TableCell>
              <TableCell align="right">
                <Typography className={classes.default}>Primary Admin</Typography>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {rows.map(row => (
              <TableRow key={row.id} hover>
                <TableCell>{row.email}</TableCell>
                <TableCell align="right">
                  <Button variant="outlined" color="secondary" size="small">
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>

      <CardContent>
        <Typography variant="caption" color="textSecondary">
          Please note: The email address of primary admin can’t be removed
        </Typography>
      </CardContent>
    </Card>
  )
}

export default withStyles(styles)(Users)
