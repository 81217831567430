/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import styled from '@emotion/styled'
import { Typography } from '@material-ui/core'
import { Tag } from '@jeeves/components/Tag'

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
`

const Pair = ({ label, children }) => (
  <Flex css={{ width: '100%' }}>
    <Label>{label}</Label>
    <Value>{children}</Value>
  </Flex>
)

const Label = styled(Typography)`
  color: #061748;
  font-weight: 600;
  text-transform: uppercase;
  margin-right: 16px;
`

const Value = styled(Typography)`
  color: #000022;
`

const Row = styled.div`
  margin-bottom: 12px;
`

export const RepositoryInfo = ({ repo }) => {
  let { sidecars, repoHost, tags: labels, repoPort, analysis } = repo
  labels = labels.filter(label => label.charAt(0) !== '_')
  return (
    <Flex
      css={() => css`
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 40px;
      `}
    >
      <Row>
        <Value>
          {repoHost}:{repoPort}
        </Value>
      </Row>
      <Row>
        {[...labels, ...(analysis?.enableDataMasking ? ['masking-enabled'] : [])].map(label => (
          <Tag css={{ margin: '0 5px 0 0' }} key={`${label}-row-tag`}>
            {label}
          </Tag>
        ))}
      </Row>
      <Row>
        <Pair label="Sidecars">
          {sidecars.length > 0 ? (
            sidecars.map(sidecar => sidecar.sidecar.name).join(', ')
          ) : (
            <span
              css={() => css`
                font-style: italic;
              `}
            >
              None
            </span>
          )}
        </Pair>
      </Row>
    </Flex>
  )
}
