import * as React from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import { FixedSizeList } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'
import { format, parseISO } from 'date-fns'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import MuiLink from '@mui/material/Link'
import CircularProgress from '@mui/material/CircularProgress'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

import { InlineCode } from '@jeeves/new-components'

import path from 'path-browserify'

const GUTTER_SIZE = 16

const PendingApprovalsList = ({ loadingNextPage, nodes, hasNextPage, loadNextPage }) => {
  const numNodes = nodes?.length || 0

  // Every row is loaded except for our loading indicator row.
  const isNodeLoaded = index => !hasNextPage || index < numNodes

  // If there are more items to be loaded, then add an extra row to hold a loading indicator.
  const itemCount = hasNextPage ? numNodes + 1 : numNodes

  // Pass an empty callback to InfiniteLoader in case it asks us to load more than once
  const loadMore = loadingNextPage ? () => {} : loadNextPage

  return (
    <InfiniteLoader
      isItemLoaded={isNodeLoaded}
      itemCount={itemCount}
      loadMoreItems={loadMore}
      threshold={5}
    >
      {({ onItemsRendered, ref }) => (
        <FixedSizeList
          onItemsRendered={onItemsRendered}
          ref={ref}
          itemData={nodes}
          itemCount={itemCount}
          itemSize={116 + GUTTER_SIZE}
          height={300}
          width="100%"
        >
          {props => {
            return <ListItem {...props} isNodeLoaded={isNodeLoaded} />
          }}
        </FixedSizeList>
      )}
    </InfiniteLoader>
  )
}

const PendingApprovalItem = ({ approval }) => {
  const { url } = useRouteMatch()
  const { id, requester, userAccount, createdDate } = approval

  const requesterIdentity = requester.name
  const formattedCreatedDate = format(parseISO(createdDate), 'MMMM do, yyyy')

  return (
    <Box
      sx={{
        bgcolor: 'cyralColors.white',
        border: 1,
        borderColor: 'cyralColors.grey.200',
        borderRadius: theme => theme.radii.base,
        p: 4,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Stack spacing={1}>
        <Typography variant="body2" sx={{ color: 'text.primary' }}>
          {requesterIdentity}
        </Typography>

        <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={2}>
          <Stack direction="row" spacing={1}>
            <Typography variant="h6" sx={{ color: 'text.secondary' }}>
              Database Account:
            </Typography>

            <InlineCode>{userAccount.name}</InlineCode>
          </Stack>

          <Typography variant="body2">Access requested on {formattedCreatedDate}</Typography>
        </Stack>
      </Stack>

      <MuiLink component={Link} to={path.join(url, id)} underline="none" variant="h6">
        <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
          <Box component="span">View</Box>
          <ArrowForwardIcon sx={{ fontSize: 16 }} />
        </Stack>
      </MuiLink>
    </Box>
  )
}

// Render an item or a loading indicator.
const ListItem = ({ data, index, style, isNodeLoaded }) => {
  let content
  if (!isNodeLoaded(index)) {
    content = (
      <Stack spacing={2} sx={{ alignItems: 'center' }}>
        <CircularProgress size={64} />
        <Typography variant="h6" sx={{ color: 'text.secondary' }}>
          Loading approval requests...
        </Typography>
      </Stack>
    )
  } else {
    content = <PendingApprovalItem approval={data[index]} />
  }

  return (
    <Box
      style={{
        ...style,
        height: style.height - GUTTER_SIZE,
      }}
    >
      {content}
    </Box>
  )
}
export default PendingApprovalsList
