export const formatNumber = (
  number: number,
  opts?: {
    locales?: string | string[]
    numberFormatOpts?: Intl.NumberFormatOptions
  }
) => {
  const formatter = new Intl.NumberFormat(opts?.locales, opts?.numberFormatOpts)
  return formatter.format(number)
}
