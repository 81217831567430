import { Controller, useFormContext } from 'react-hook-form'
import { FormControl } from '@mui/material'

import { Select } from '@jeeves/new-components'
import { SidecarServiceLogLevel } from '@jeeves/graphql/graphql'

const defaultOptions = [
  { label: 'Info', value: SidecarServiceLogLevel.Info },
  { label: 'Debug', value: SidecarServiceLogLevel.Debug },
  { label: 'Trace', value: SidecarServiceLogLevel.Trace },
]

const extraOptions = [
  { label: 'Error', value: SidecarServiceLogLevel.Error },
  { label: 'Fatal', value: SidecarServiceLogLevel.Fatal },
  { label: 'Panic', value: SidecarServiceLogLevel.Panic },
  { label: 'Warn', value: SidecarServiceLogLevel.Warn },
]

const getOptions = serviceLogLevel => {
  const extraOption = extraOptions.find(extraOption => extraOption.value === serviceLogLevel)

  return [...defaultOptions, ...(extraOption ? [extraOption] : [])]
}

const LogLevelSelect = ({ name }) => {
  const { control, getValues } = useFormContext()

  const options = getOptions(getValues(name))

  return (
    <FormControl fullWidth variant="standard">
      <Controller
        name={name}
        control={control}
        defaultValue="info"
        render={({ field }) => <Select options={options} {...field} />}
      />
    </FormControl>
  )
}

export default LogLevelSelect
