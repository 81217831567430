import { gql } from '@apollo/client'
import { graphql } from '@jeeves/graphql'

export const ACCESS_RULE_FIELDS = gql`
  fragment accessRuleFields on AccessRule {
    id
    validFrom
    validUntil
    identity {
      type
      name
    }
    accessRestrictions {
      ... on PagerdutyIntegration {
        id
      }
      ... on DuoIntegration {
        id
      }
    }
  }
`

export const CREDENTIALS_FIELDS = graphql(`
  fragment credentialsFields on UserAccountCredentials {
    ... on AWSIAMUserAccountCredentials {
      roleARN
    }
    ... on AWSSecretsManagerUserAccountCredentials {
      secretARN
    }
    ... on EnvironmentVariableUserAccountCredentials {
      variableName
    }
    ... on HashicorpVaultUserAccountCredentials {
      path
      isDynamicUserAccount
    }
    ... on KubernetesSecretUserAccountCredentials {
      secretName
      secretKey
    }
    ... on GCPSecretManagerUserAccountCredentials {
      secretName
    }
    ... on CyralStorageUserAccountCredentials {
      password
    }
  }
`)

export const USER_ACCOUNT_FIELDS = gql`
  fragment userAccountFields on UserAccount {
    id
    name
    autoApproval {
      enabled
      duration
    }
  }
`

export const REPO_USER_ACCOUNTS = gql`
  fragment repoUserAccounts on RepoWithSSOFields {
    userAccounts {
      ...userAccountFields
    }
  }
  ${USER_ACCOUNT_FIELDS}
`
