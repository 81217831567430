import React from 'react'
import { NavLink } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Menu from '@material-ui/core/MenuList'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Link from '@material-ui/core/Link'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import FileIcon from '@material-ui/icons/FileCopy'

const styles = theme => ({
  content: {
    padding: 0,
  },
  link: {},
})

const Documents = ({ classes, className }) => {
  return (
    <Card className={className}>
      <CardHeader title="Contract & Invoices" />

      <CardContent className={classes.content}>
        <Menu dense>
          <MenuItem component={NavLink} to="/" color="primary">
            <ListItemIcon>
              <PictureAsPdfIcon color="primary" fontSize="small" />
            </ListItemIcon>
            <Typography color="primary">View the current contract</Typography>
          </MenuItem>

          <MenuItem component={NavLink} to="/account/invoices">
            <ListItemIcon>
              <FileIcon color="primary" fontSize="small" />
            </ListItemIcon>
            <Typography color="primary">See all past invoices</Typography>
          </MenuItem>
        </Menu>
      </CardContent>

      <CardContent>
        <Typography variant="caption" color="textSecondary">
          If you have any quesions or feedback, please{' '}
          <Link href="mailto:product@cyral.com">send us a note</Link>
        </Typography>
      </CardContent>
    </Card>
  )
}

export default withStyles(styles)(Documents)
