import { IconButton, Typography } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { formatLocalDate } from '@jeeves/utils/datetime'

import { graphql, useFragment, FragmentType } from '@jeeves/graphql'

const CredentialsRow_SidecarCredentialFragment = graphql(`
  fragment CredentialsRow_SidecarCredentialFragment on SidecarCredential {
    clientId
    createdAt
  }
`)

interface Props {
  sidecarCredential: FragmentType<typeof CredentialsRow_SidecarCredentialFragment>
  setSelectedCredentialToDelete: React.Dispatch<React.SetStateAction<string>>
}

const CredentialsRow = ({
  sidecarCredential: sidecarCredentialProp,
  setSelectedCredentialToDelete,
}: Props) => {
  const sidecarCredential = useFragment(
    CredentialsRow_SidecarCredentialFragment,
    sidecarCredentialProp
  )

  return (
    <tr>
      <td>
        <Typography variant="code" sx={{ color: 'text.primary' }}>
          {sidecarCredential.clientId}
        </Typography>
      </td>
      <td>
        <Typography variant="body2" sx={{ color: 'text.primary' }}>
          {formatLocalDate(sidecarCredential.createdAt, 'MMM do, yyyy')}
        </Typography>
      </td>
      <td>
        <IconButton
          aria-label="delete"
          onClick={() => setSelectedCredentialToDelete(sidecarCredential.clientId)}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      </td>
    </tr>
  )
}

export default CredentialsRow
