import React, { useEffect } from 'react'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import Tooltip from '@mui/material/Tooltip'
import useCopy from '@react-hook/copy'

import { IconButton } from '@jeeves/new-components'

// TODO: Add focus state

const IconCopyButton = ({ text, ...rest }) => {
  const { copied, copy, reset } = useCopy(text)

  useEffect(() => {
    if (!copied) return

    const resetTimer = setTimeout(reset, 2000)

    return () => {
      clearTimeout(resetTimer)
    }
  }, [copied, reset])

  return (
    <Tooltip
      componentsProps={{
        tooltip: { sx: { marginBottom: '0 !important' } },
      }}
      open={copied}
      title="Copied!"
      placement="top"
      disableHoverListener
      disableFocusListener
      disableTouchListener
      arrow
    >
      <IconButton {...rest} onClick={copy}>
        <ContentCopyIcon fontSize="small" sx={{ color: 'cyralColors.grey.300' }} />
      </IconButton>
    </Tooltip>
  )
}

export default IconCopyButton
