import React, { useState, useCallback } from 'react'
import { Link } from 'react-router-dom'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import lodash from 'lodash'

import { SearchInput, Button } from '@jeeves/new-components'

import { ExpandableDataRepoCard, GeneralDataRepoCard } from './components/RepoCards'
import { EmptyStateGraphic } from './components/EmptyStateGraphic'
import { FilterPopover } from './components/FilterPopover'
import useAccessPortal from './useAccessPortal'
import { AccessToken } from '../../components/AccessToken'
import RequestAccess from './components/RequestAccess'

const AccessPortalMain = () => {
  const [searchValue, setSearchValue] = useState('')
  const [filters, setFilters] = useState({
    repoTypes: [],
    repoTags: [],
  })

  const { firstLoading, error, data, getNextPage, refetch, fetchingMore } = useAccessPortal({
    variables: { first: 10, accessibility: 'HAS_ACCESS' },
    notifyOnNetworkStatusChange: true,
  })

  const handleFilter = ({ repoType, repoTags }) => {
    setFilters({ repoTypes: repoType ? [repoType] : [], repoTags })
    refetch({
      filters: {
        ...(searchValue ? { repoName: searchValue } : {}),
        repoTypes: repoType ? [repoType] : [],
        repoTags,
      },
    })
  }

  const submitSearch = useCallback(
    lodash.debounce(e => {
      refetch({
        filters: {
          repoName: e.target.value,
          ...filters,
        },
      })
    }, 300),
    [filters]
  )

  const handleChange = e => {
    setSearchValue(e.target.value)
    submitSearch(e)
  }

  if (firstLoading) return 'Loading...'
  if (error) return `Error! ${error.message}`

  return (
    <Container maxWidth="xl">
      <Stack spacing={4}>
        <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
          <Stack spacing={1} direction="row" alignItems="center">
            <Typography variant="h2" sx={{ color: 'text.primary' }}>
              Your Data Repositories
            </Typography>
          </Stack>
        </Stack>
        <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
          <Stack direction="row" spacing={2}>
            <SearchInput
              value={searchValue}
              onChange={handleChange}
              placeholder="Search by repository name"
              sx={{ minWidth: '300px' }}
            />
            <FilterPopover handleFilter={handleFilter} filters={filters}></FilterPopover>
          </Stack>

          <Stack direction="row" spacing={2}>
            <AccessToken />
            {data.repoEdgesUserCanAccess?.length > 0 && <RequestAccess />}
          </Stack>
        </Stack>
        {data.repoEdgesUserCanAccess?.length > 0 ? (
          <Stack spacing={3}>
            {data.repoEdgesUserCanAccess.map(edge => {
              switch (edge.node.type) {
                case 'postgresql':
                case 'redshift':
                case 'mongodb':
                case 'mariadb':
                case 'oracle':
                case 'sqlserver':
                case 'denodo':
                case 'dremio':
                case 'dynamodb':
                case 'dynamodbstreams':
                case 'mysql':
                  return <GeneralDataRepoCard key={edge.node.id} repoEdge={edge} />
                case 's3':
                  return <ExpandableDataRepoCard key={edge.node.id} repoEdge={edge} />
                default:
                  return null
              }
            })}
          </Stack>
        ) : (
          <Box paddingTop="150px">
            <EmptyStateGraphic />
          </Box>
        )}
        {data?.hasNextPage && (
          <Button
            variant="outlined"
            color="secondary"
            sx={{ alignSelf: 'center' }}
            onClick={getNextPage}
            loading={fetchingMore}
          >
            Load More
          </Button>
        )}
      </Stack>
    </Container>
  )
}

export default AccessPortalMain
