import { TablePagination, styled } from '@mui/material'

export const RepoTablePagination = styled(TablePagination)(({ theme }) => ({
  border: 'none',
  color: theme.palette.cyralColors.grey[400],
  fontSize: '14px',
  '& .MuiSelect-select': {
    border: 'solid 1px',
    borderColor: theme.palette.cyralColors.grey[200],
    borderRadius: theme.radii.base,
    marginRight: '-2px',
    '&:focus': {
      borderRadius: theme.radii.base,
    },
  },
  '& .MuiTablePagination-spacer': {
    display: 'none',
  },
  '& .MuiSvgIcon-root': {
    fill: theme.palette.cyralColors.grey[300],
    fontSize: '18px',
    top: '6px',
  },
}))

export const CustomSelectDropdown = styled('div')(({ theme }) => ({
  position: 'absolute',
  marginLeft: '70px',
  marginTop: '-12px',
  minWidth: '60px',
  '& .MuiMenuItem-root': {
    margin: '8px',
    borderRadius: theme.radii.base,
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: theme.palette.cyralColors.primary[100],
    },
    "&[class*='Mui-selected']": {
      backgroundColor: theme.palette.cyralColors.primary[100],
      '&:hover': {
        backgroundColor: theme.palette.cyralColors.primary[100],
      },
    },
  },
  '& .MuiMenu-list': {
    padding: '0px',
  },
}))
