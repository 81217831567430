import { useMutation } from '@apollo/client'
import { graphql } from '@jeeves/graphql'
import { useRepositoryDetailContext } from '@jeeves/pages/RepositoryDetail/contexts/RepositoryDetailContext'
import { DisableNetworkShieldModal, EnableNetworkShieldModal } from './components'

const TOGGLE_NETWORK_SHIELD_ENABLED = graphql(`
  mutation ToggleNetworkShieldStatus($repoId: String!, $enabled: Boolean!) {
    toggleNetworkShieldStatus(repoId: $repoId, enabled: $enabled) {
      networkShield {
        id
        enabled
      }
    }
  }
`)

interface Props {
  open: boolean
  closeModal: () => void
  networkShieldEnabled: boolean
}

const ToggleNetworkShieldStatusModal = ({ open, closeModal, networkShieldEnabled }: Props) => {
  const { repoId } = useRepositoryDetailContext()

  const [toggleNetworkShieldStatus, { loading }] = useMutation(TOGGLE_NETWORK_SHIELD_ENABLED, {
    variables: {
      repoId,
      enabled: !networkShieldEnabled,
    },
    onCompleted: () => {
      closeModal()
    },
  })

  return networkShieldEnabled ? (
    <DisableNetworkShieldModal
      open={open}
      closeModal={closeModal}
      onConfirm={toggleNetworkShieldStatus}
      loading={loading}
    />
  ) : (
    <EnableNetworkShieldModal
      open={open}
      closeModal={closeModal}
      onConfirm={toggleNetworkShieldStatus}
      loading={loading}
    />
  )
}
export default ToggleNetworkShieldStatusModal
