import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import { graphql } from '@jeeves/graphql'
import {
  useCreateLoggingIntegration,
  useUpdateLoggingIntegration,
} from '@jeeves/pages/Integrations/Services/Logging/components/NewIntegrationDialog/hooks'

const getDefaultValues = integration => ({
  name: integration?.name || '',
  esUrl: integration?.esUrl || '',
  credentials: {
    username: integration?.ELKCredentials?.username || '',
    password: integration?.ELKCredentials?.password || '',
  },
  kibanaUrl: integration?.kibanaUrl || '',
})

const schema = z
  .object({
    name: z.string().min(1, { message: 'Please enter a name.' }),
    esUrl: z
      .string()
      .trim()
      .min(1, { message: 'Please enter an Elasticsearch URL.' })
      .url({ message: 'Please enter a valid URL.' }),
    credentials: z.object({
      username: z.string().optional(),
      password: z.string().optional(),
    }),
    kibanaUrl: z
      .string()
      .trim()
      .url({ message: 'Please enter a valid URL.' })
      .optional()
      .or(z.literal('')),
  })
  .superRefine((val, ctx) => {
    const usernameHasValue = Boolean(val.credentials.username)
    const passwordHasValue = Boolean(val.credentials.password)

    if (!usernameHasValue && passwordHasValue) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Please enter an Elasticsearch User.',
        path: ['credentials.username'],
      })
    }

    if (!passwordHasValue && usernameHasValue) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Please enter an Elasticsearch Password.',
        path: ['credentials.password'],
      })
    }
  })

export const useELK_ELKIntegrationFragment = graphql(`
  fragment useELK_ELKIntegration on ELKIntegration {
    ELKCredentials {
      password
      username
    }
    esUrl
    kibanaUrl
  }
`)

export const useELK = ({ integration, onClose, isEditing }) => {
  const methods = useForm({
    defaultValues: getDefaultValues(integration),
    resolver: zodResolver(schema),
  })

  const [createLoggingIntegration, { loading: loadingCreateLoggingIntegration }] =
    useCreateLoggingIntegration({
      onCompleted: () => {
        onClose()
      },
    })

  const [updateLoggingIntegration, { loading: loadingUpdateLoggingIntegration }] =
    useUpdateLoggingIntegration({
      onCompleted: () => {
        onClose()
      },
    })

  const getELKCredentials = ({ username, password }) => {
    if (username && password) {
      return {
        username,
        password,
      }
    }
    return null
  }

  const createELKIntegration = async ({ name, esUrl, credentials, kibanaUrl }) => {
    await createLoggingIntegration({
      variables: {
        integration: {
          name,
          config: {
            ELK: {
              ELKCredentials: getELKCredentials(credentials),
              esUrl,
              kibanaUrl,
            },
          },
        },
      },
    })
  }

  const updateELKIntegration = async ({ name, esUrl, credentials, kibanaUrl }) => {
    await updateLoggingIntegration({
      variables: {
        updateLoggingIntegrationId: integration.id,
        input: {
          name,
          config: {
            ELK: {
              ELKCredentials: getELKCredentials(credentials),
              esUrl,
              kibanaUrl,
            },
          },
        },
      },
    })
  }

  const onSubmit = async formData => {
    if (isEditing) {
      await updateELKIntegration(formData)
    } else {
      await createELKIntegration(formData)
    }
  }

  return {
    loading: loadingCreateLoggingIntegration || loadingUpdateLoggingIntegration,
    methods,
    onSubmit: methods.handleSubmit(onSubmit),
  }
}
