/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import React, { useState } from 'react'
import { withStyles, TableCell, TableRow, Paper, Typography } from '@material-ui/core'

import { ExpandButton, ExpandableRow } from '@jeeves/components/Table'

import stringify from 'json-stringify-pretty-compact'

const styles = theme => ({
  container: {
    backgroundColor: '#552583',
    padding: theme.spacing.unit * 3,
  },
  etcdKey: {
    color: 'yellow',
    fontSize: '14px',
    fontWeight: 'bold',
  },
  etcdContent: {
    color: 'yellow',
    fontSize: '12px',
    whiteSpace: 'pre',
    height: '100%',
  },
  grow: {
    flexGrow: 1,
  },
})

const Row = ({ classes, etcdKey, etcdValue }) => {
  const [expanded, setExpanded] = useState(false)

  const prettifyValue = value => {
    try {
      const parsed = JSON.parse(value)
      const prettified = stringify(parsed)

      return prettified
    } catch {
      return value
    }
  }

  return (
    <React.Fragment>
      <TableRow role="checkbox" tabIndex={-1}>
        <TableCell onClick={() => setExpanded(!expanded)}>{etcdKey}</TableCell>

        <TableCell align="right" padding="none">
          <ExpandButton onClick={() => setExpanded(!expanded)} />
        </TableCell>
      </TableRow>

      <ExpandableRow expanded={expanded}>
        <Paper className={classes.container}>
          <Typography className={classes.etcdContent}>{prettifyValue(etcdValue)}</Typography>
        </Paper>
      </ExpandableRow>
    </React.Fragment>
  )
}

export default withStyles(styles)(Row)
