import { Stack, Typography, TableFooter, TablePagination, TableRow, styled } from '@mui/material'
import { ChevronLeft, ChevronRight, KeyboardArrowDown } from '@mui/icons-material'

import { Button } from '@jeeves/new-components'
import { pageSizeOptions } from '../SidecarsTable/SidecarsTable'

const SidecarsTablePagination = styled(TablePagination)(({ theme }) => ({
  border: 'none',
  color: theme.palette.cyralColors.grey[400],
  fontSize: '14px',
  '& .MuiSelect-select': {
    border: 'solid 1px',
    borderColor: theme.palette.cyralColors.grey[200],
    borderRadius: theme.radii.base,
    marginRight: '-2px',
    '&:focus': {
      borderRadius: theme.radii.base,
    },
  },
  '& .MuiTablePagination-spacer': {
    display: 'none',
  },
  '& .MuiSvgIcon-root': {
    fill: theme.palette.cyralColors.grey[300],
    fontSize: '18px',
    top: '6px',
  },
  '& .MuiTablePagination-selectLabel': {
    color: theme.palette.text.secondary,
    ...theme.typography.h6,
  },
})) as typeof TablePagination

interface TablePaginationActionsProps {
  page: number
  onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void
}

interface Props {
  availableSidecarsAmount: number
  pageSize: number
  pageIndex: number
  setPageSize: React.Dispatch<React.SetStateAction<(typeof pageSizeOptions)[number]>>
  setPageIndex: React.Dispatch<React.SetStateAction<number>>
}

const SidecarsFooter = ({
  availableSidecarsAmount,
  pageSize,
  pageIndex,
  setPageSize,
  setPageIndex,
}: Props) => {
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPageIndex(newPage)
  }

  const TablePaginationActions = (props: TablePaginationActionsProps) => {
    const { page, onPageChange } = props
    const totalPages = Math.ceil(availableSidecarsAmount / pageSize)

    const hasPreviousPage = page > 0
    const hasNextPage = page < totalPages - 1

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, page - 1)
    }

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, page + 1)
    }

    return (
      <Stack sx={{ width: '100%', justifyContent: 'flex-end' }} direction="row" spacing={2}>
        {hasPreviousPage && (
          <Button
            variant="text"
            color="secondary"
            onClick={handleBackButtonClick}
            startIcon={<ChevronLeft />}
          >
            <Typography variant="h6" sx={{ color: 'text.secondary' }}>
              Back
            </Typography>
          </Button>
        )}
        {hasNextPage && (
          <Button
            variant="text"
            color="secondary"
            onClick={handleNextButtonClick}
            endIcon={<ChevronRight />}
          >
            <Typography variant="h6" sx={{ color: 'text.secondary' }}>
              Next
            </Typography>
          </Button>
        )}
      </Stack>
    )
  }

  return (
    <TableFooter>
      <TableRow sx={{ '& td': { borderBottom: 'none !important' } }}>
        <SidecarsTablePagination
          SelectProps={{
            IconComponent: KeyboardArrowDown,
          }}
          labelRowsPerPage="Sidecars per page"
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} of ${count} sidecars`}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[...pageSizeOptions]}
          ActionsComponent={TablePaginationActions}
          page={pageIndex}
          count={availableSidecarsAmount}
          rowsPerPage={pageSize}
          onRowsPerPageChange={e => {
            setPageSize(Number(e.target.value) as (typeof pageSizeOptions)[number])
            setPageIndex(0)
          }}
        />
      </TableRow>
    </TableFooter>
  )
}

export default SidecarsFooter
