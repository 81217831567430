import React, { useEffect, useState } from 'react'
import { withStyles, CircularProgress, Paper } from '@material-ui/core'

import Header from '../Dashboard/Header'
import PopupMessage from '../PopupMessage'

import ExpressClient from '../../clients/express'
import { useAuth } from '../Auth'
import usePopup from '../../components/PopupMessage/hooks/usePopup'

import classnames from 'classnames'
import path from 'path-browserify'

const styles = theme => ({
  dashboard: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  main: {
    padding: theme.spacing.unit * 3,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  heightGrow: {
    height: '100vh',
    width: '100%',
  },

  appBarSpacer: theme.mixins.toolbar,
})

const ContentDownloader = props => {
  const { classes, match } = props

  const { setPopup, popupTypes } = usePopup()

  const [isLoading, setIsLoading] = useState(true)
  const { getTokenSilently } = useAuth()
  const ec = new ExpressClient(getTokenSilently)

  const getParams = () => {
    let params = match.params[0]
    if (params.slice(-1) === '/') {
      return params.slice(0, params.length - 1)
    }
    return params
  }
  let params = getParams()

  useEffect(() => {
    const downloadContent = async () => {
      try {
        setIsLoading(true)

        let axiosConfig = {}
        let binaryFileExtensions = ['.iso', '.deb']
        if (binaryFileExtensions.indexOf(path.extname(params)) > -1) {
          axiosConfig.responseType = 'arraybuffer'
        }

        await ec.get(`/${params}`, axiosConfig).then(res => {
          downloadFile(res)
        })
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
        let popupMessage = 'Unable to download document'
        if (e.response && e.response.status === 404) {
          popupMessage = 'The specified document does not exist'
        }
        setPopup(popupTypes.ERROR, popupMessage)
      }
    }
    downloadContent()
  }, [])

  const downloadFile = content => {
    let type = content.headers['content-type'] ? content.headers['content-type'] : null
    const element = document.createElement('a')
    const file = new Blob([content.data], { type: type })
    element.href = URL.createObjectURL(file)
    element.download = params
    document.body.appendChild(element)
    element.click()
  }

  return (
    <div className={classes.dashboard}>
      <Header isViewContent={true} />
      <main className={classnames(classes.main, { [classes.heightGrow]: isLoading })}>
        <div className={classes.appBarSpacer} />
        <div>{isLoading && <CircularProgress></CircularProgress>}</div>

        <PopupMessage />
      </main>
    </div>
  )
}

export default withStyles(styles)(ContentDownloader)
