import { ReactNode } from 'react'
import ReactMarkdown from 'react-markdown'

import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { InlineCode } from '@jeeves/new-components'

interface ICode {
  children: ReactNode
}

interface IPlainText {
  children: ReactNode
}

interface IMarkdown {
  text: string
}

const StyledMarkdown = styled(ReactMarkdown)`
  line-height: 1.75;
  margin-top: 0px !important;
  margin-bottom: 0;
`

const Code = ({ children }: ICode) => {
  return <InlineCode sx={{ overflowWrap: 'anywhere' }}>{children}</InlineCode>
}

const PlainText = ({ children }: IPlainText) => {
  return (
    <Typography variant="body2" sx={{ display: 'inline', lineHeight: '20px' }}>
      {children}
    </Typography>
  )
}

const renderers = {
  inlineCode: Code,
  text: PlainText,
}

const Markdown = ({ text }: IMarkdown) => {
  return <StyledMarkdown renderers={renderers}>{text}</StyledMarkdown>
}

export default Markdown
