/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { Fragment } from 'react'
import EditIcon from '@material-ui/icons/Edit'
import { IconButton } from '@material-ui/core/'
import { get } from 'lodash'

import {
  DeleteIntegrationButton,
  IntegrationHeader,
  IntegrationTitle,
  Label,
  LabelValuePair,
  Value,
} from '@jeeves/pages/Integrations/primitives'
import { FlexGrow } from '@jeeves/components/Primitives'
import { useAuth } from '@jeeves/hooks'

const View = ({ integration = null, setEditing, onDelete }) => {
  const { hasPermission } = useAuth()

  return (
    <Fragment>
      <IntegrationHeader>
        <IntegrationTitle gutterBottom component="h2">
          {get(integration, 'integration.name')}
        </IntegrationTitle>
        <FlexGrow />
        <IconButton
          onClick={() => setEditing(true)}
          disabled={!hasPermission('integrations:update')}
        >
          <EditIcon />
        </IconButton>
        <DeleteIntegrationButton
          onDelete={onDelete}
          disabled={!hasPermission('integrations:delete')}
        />
      </IntegrationHeader>

      <LabelValuePair>
        <Label css={{ marginRight: '24px' }}>URL</Label>
        <Value>{get(integration, 'integration.url')}</Value>
      </LabelValuePair>
    </Fragment>
  )
}

export default View
