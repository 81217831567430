import { REPO_TYPES_THAT_SUPPORT_PORT_MULTIPLEXING } from '@jeeves/constants'

export const getUsername = (user, repo, userAccountEdge) => {
  const idpPrefix = repo?.config?.authentication?.allowNativeAuthentication ? 'idp' : ''
  const ssoUser = repo?.config?.authentication?.otpDbuser || user?.email || '<SSO_USER>'
  const userAccount = userAccountEdge?.node?.name || ''
  const REPO_TYPES_THAT_SUPPORT_PORT_MULTIPLEXING_TYPENAMES =
    REPO_TYPES_THAT_SUPPORT_PORT_MULTIPLEXING.map(repoDisplayMeta => repoDisplayMeta.typeName)

  // Removes falsy values from array and combines all parts together with ':' as delimiter
  return [
    idpPrefix,
    ssoUser,
    ...(REPO_TYPES_THAT_SUPPORT_PORT_MULTIPLEXING_TYPENAMES.includes(repo.type) ? [repo.name] : []),
    userAccount,
  ]
    .filter(Boolean)
    .join(':')
}

export const getHost = accessPortalSidecar => {
  return accessPortalSidecar?.userEndpoint || accessPortalSidecar?.endpoint || '<SIDECAR_HOST>'
}

export const getPorts = binding => {
  const DEFAULT_RETURN = '<SIDECAR_PORT>'

  if (binding.__typename === 'ClusterBinding') {
    return binding.boundListenersRelationship.edges.map(edge => edge.node.port) || DEFAULT_RETURN
  }

  if (binding.__typename === 'S3Binding') {
    return [binding.listenerSet.proxyListener.port || DEFAULT_RETURN]
  }

  return [binding.listener.port || DEFAULT_RETURN]
}
