import { FormProvider } from 'react-hook-form'

import { Dialog } from '@material-ui/core'
import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  Stack,
  ThemeProvider,
  Typography,
} from '@mui/material'

import { v5Theme } from '@jeeves/theme'
import { Button, Input, InputLabel, ServiceIcon } from '@jeeves/new-components'

import { IamRolesField } from './components'
import { useUpsertAwsIamIntegrationModal } from './useUpsertAwsIamIntegrationModal'
import { FragmentType, graphql, useFragment } from '@jeeves/graphql'

const UpsertAwsIamIntegrationModal_awsIamIntegrationFragment = graphql(`
  fragment UpsertAwsIamIntegrationModal_awsIamIntegration on AwsIamIntegration {
    ...useUpsertAwsIamIntegrationModal_awsIamIntegration
  }
`)

interface UpsertAwsIamIntegrationModalProps {
  integration?: FragmentType<typeof UpsertAwsIamIntegrationModal_awsIamIntegrationFragment>
  isOpen: boolean
  onClose: () => void
}

const UpsertAwsIamIntegrationModal = ({
  integration: integrationProp,
  isOpen,
  onClose,
}: UpsertAwsIamIntegrationModalProps) => {
  const integration = useFragment(
    UpsertAwsIamIntegrationModal_awsIamIntegrationFragment,
    integrationProp
  )

  const { isEditing, loading, methods, onSubmit } = useUpsertAwsIamIntegrationModal({
    integration,
    onClose,
  })

  const {
    reset,
    formState: { errors },
    register,
  } = methods

  const handleClose = () => {
    reset()
    onClose()
  }

  return (
    <Dialog open={isOpen} fullWidth maxWidth="md">
      <ThemeProvider theme={v5Theme}>
        <FormProvider {...methods}>
          <Stack component="form" spacing={4} onSubmit={onSubmit} sx={{ padding: 4 }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                <ServiceIcon type="awsIam" />
                <Typography variant="h3" sx={{ color: 'text.primary' }}>
                  AWS IAM Authenticator
                </Typography>
              </Stack>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>

            <FormControl variant="standard" error={Boolean(errors.name)} fullWidth>
              <InputLabel htmlFor="name" required>
                Name
              </InputLabel>
              <Input
                id="name"
                type="text"
                inputProps={{
                  ...register('name'),
                }}
              />
              <FormHelperText sx={{ typography: 'body2' }}>{errors.name?.message}</FormHelperText>
            </FormControl>

            <IamRolesField />

            <FormControl variant="standard" error={Boolean(errors.description)} fullWidth>
              <InputLabel htmlFor="description">Description</InputLabel>
              <Input
                id="description"
                type="text"
                inputProps={{
                  ...register('description'),
                }}
                multiline
                rows={5}
              />
              <FormHelperText sx={{ typography: 'body2' }}>
                {errors.description?.message}
              </FormHelperText>
            </FormControl>

            <Stack
              direction="row"
              spacing={2}
              sx={{ justifyContent: 'flex-end', alignItems: 'center' }}
            >
              <Button variant="text" color="secondary" disabled={loading} onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="contained" type="submit" color="primary" loading={loading}>
                {isEditing ? 'Save' : 'Create'}
              </Button>
            </Stack>
          </Stack>
        </FormProvider>
      </ThemeProvider>
    </Dialog>
  )
}

export default UpsertAwsIamIntegrationModal
