import * as React from 'react'
import { Box, Typography } from '@mui/material'

const variantToColorMap = {
  success: 'green',
  error: 'red',
  warning: 'yellow',
  primary: 'primary',
  neutral: 'grey',
}

interface BadgeProps {
  variant: keyof typeof variantToColorMap
  children: React.ReactNode
}

const Badge = ({ variant, children }: BadgeProps) => {
  const color = variantToColorMap[variant]

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Typography
        component="span"
        noWrap
        variant="badge"
        sx={{
          backgroundColor: `cyralColors.${color}.100`,
          border: 1,
          borderRadius: theme => theme.radii.sm,
          borderColor: `cyralColors.${color}.300`,
          color: `cyralColors.${color}.500`,
          maxWidth: '200px',
          paddingX: 1,
          paddingY: 0.25,
        }}
      >
        {children}
      </Typography>
    </Box>
  )
}

export default Badge
