import { Box } from '@mui/material'

import { Button, SearchInput } from '@jeeves/new-components'

interface Props {
  onSearch: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
  setCreateSidecarModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const SidecarsHeader = ({ onSearch, setCreateSidecarModalOpen }: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <SearchInput
        sx={{ width: '360px' }}
        onChange={onSearch}
        placeholder="Search by sidecar name"
      />
      <Button variant="contained" color="primary" onClick={() => setCreateSidecarModalOpen(true)}>
        Create Sidecar
      </Button>
    </Box>
  )
}

export default SidecarsHeader
