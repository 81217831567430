/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import styled from '@emotion/styled'
import { Route, Redirect } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { CardContent, Typography, Grid, ButtonBase, withStyles } from '@material-ui/core'
import { blue } from '@material-ui/core/colors'
import { Paper } from '@jeeves/components/Primitives'
import ReactMarkdown from 'react-markdown'
import Handlebars from 'handlebars'
import Toolbar from '@jeeves/components/Toolbar'
import ReactPlayer from 'react-player/youtube'

import useSandbox from './useSandbox'
import { useAppConfig } from '@jeeves/hooks'

// TO DO: match sidecar instructions to this
const GREY = '#DEDEDE'

const styles = theme => ({
  markdown: {
    fontFamily: theme.fontFamily,
    '& *': {
      fontFamily: `${theme.fontFamily} !important`,
    },
    '& h2': {
      fontSize: theme.spacing.unit * 2,
      fontWeight: 400,
    },
    '& h3': {
      fontSize: theme.spacing.unit * 1.75,
      fontWeight: 400,
    },
    '& ol': {
      marginTop: theme.spacing.unit * 3,
      marginBottom: theme.spacing.unit * 3,
      color: blue,
    },
    '& li': {
      marginTop: theme.spacing.unit,
      marginBottom: theme.spacing.unit,
      fontSize: '1.25rem',
      lineHeight: 1.6,
    },
    '& pre': {
      fontFamily: ["'Roboto Mono', sans-serif"],
      fontSize: '1.25rem',
      background: theme.palette.background.default,
      padding: theme.spacing.unit,
      overflowX: 'auto',
    },
    '& a span': {
      fontFamily: `${theme.fontFamily} !important`,
    },
    '& a': {
      color: theme.palette.primary.main,
    },
    '& p': {
      marginBottom: theme.spacing.unit,
      fontSize: '1.25rem',
    },
  },
})

const Sandbox = withStyles(styles)(({ classes }) => {
  const { placeHolders, sandboxContent } = useSandbox()
  const { config } = useAppConfig()
  // const sandboxContent = require('./stepper-content.json')
  const [activeStep, setActiveStep] = useState(sandboxContent[0])

  const replaceString = source => {
    const template = Handlebars.compile(source)
    const context = {
      sandboxRepoName: placeHolders.sandboxRepoName,
      sandboxRepoHost: placeHolders.sandboxRepoHost,
      sandboxRepoPort: placeHolders.sandboxRepoPort,
      sandboxSidecarHost: placeHolders.sandboxSidecarHost,
      sandboxSidecarPort: placeHolders.sandboxSidecarPort,
      nativeRepoUser: placeHolders.nativeRepoUser,
      nativeRepoPass: placeHolders.nativeRepoPass,
      idpUser: placeHolders.idpUser,
      ssoAuthToken: placeHolders.ssoAuthToken,
    }
    const subbedMarkdown = template(context)

    return subbedMarkdown
  }

  const handleChange = newActiveStepId => {
    if (!activeStep || activeStep.id !== newActiveStepId) {
      setActiveStep(sandboxContent.find(step => step.id === newActiveStepId))
    }
  }

  const isActiveStep = id => {
    return activeStep ? id === activeStep.id : false
  }

  useEffect(() => {
    if (sandboxContent && sandboxContent.length > 0) setActiveStep(sandboxContent[0])
  }, [sandboxContent])

  if (!config?.hasSandbox) return <Redirect to={{ pathname: '/home' }} />
  if (!placeHolders || !sandboxContent) return null
  return (
    <Paper>
      <Toolbar
        title="Testing Cyral Features in Playground"
        css={{
          borderBottom: `1px solid ${GREY}`,
        }}
      ></Toolbar>
      <div>
        <Grid container spacing={3}>
          <Grid
            container
            item
            xs
            direction="column"
            alignItems="flex-start"
            justify="flex-start"
            css={{ backgroundColor: '#fafafa' }}
          >
            {sandboxContent.map(step => (
              <Grid
                item
                xs={3}
                css={{
                  minWidth: '100%',
                  flexBasis: 0,
                }}
              >
                <ButtonBase
                  css={{ width: '100%', textAlign: 'left' }}
                  color="primary"
                  onClick={() => handleChange(step.id)}
                >
                  <div css={{ width: '100%' }}>
                    <Typography
                      css={{
                        padding: '1rem',
                        backgroundColor: isActiveStep(step.id) ? 'white' : '',
                        color: isActiveStep(step.id) ? '#26459e' : 'black',
                        fontWeight: isActiveStep(step.id) ? 600 : 400,
                        fontSize: '1.25rem',
                      }}
                    >
                      {step.title}
                    </Typography>
                  </div>
                </ButtonBase>
              </Grid>
            ))}
          </Grid>
          <Grid item xs={9}>
            {activeStep && (
              <div css={{ minHeight: '35rem', paddingLeft: '24px' }}>
                {activeStep.media && (
                  <div css={{ width: '60%' }}>
                    {activeStep.media.type === 'video' ? (
                      <ReactPlayer url={activeStep.media.url} />
                    ) : (
                      <img css={{ width: '100%', height: '100%' }} src={activeStep.media.url}></img>
                    )}
                  </div>
                )}
                <Typography css={{ fontSize: '1rem' }}>
                  <ReactMarkdown
                    className={classes.markdown}
                    source={replaceString(activeStep.body)}
                  />
                </Typography>
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    </Paper>
  )
})

export default Sandbox
