import React, { useState, useEffect } from 'react'
import { Container, Stack, ThemeProvider } from '@mui/material'
import { useQuery } from '@apollo/client'

import { useAuth } from '@jeeves/components/Auth'
import { graphql } from '@jeeves/graphql'
import { v5Theme } from '@jeeves/theme'

import useAccounts from '../hooks/useAccounts'

import UsersManagement from '../UsersManagement'
import {
  AddUser,
  EditUser,
  UsersErrorState,
  UsersHeader,
  UsersLoadingState,
  UsersTable,
} from './components'
import SsoGroupsModal from './components/SsoGroupsModal'
import { UsersProvider } from './context'

const CONTROL_PLANE_USERS = graphql(`
  query ControlPlaneUsers {
    ...UsersTable_QueryFragment
  }
`)

const Users = () => {
  const { hasPermission } = useAuth()
  const { data, loading, error, refetch } = useQuery(CONTROL_PLANE_USERS)

  const {
    getRoles,
    editUser,
    editDialogIsOpen,
    openEdit,
    closeEdit,
    roles,
    deleteUser,
    crudCount,
    closeAdd,
    createUser,
    addDialogIsOpen,
    openAdd,
    permissionsDialogIsOpen,
    openPermissionsDialog,
    closePermissionsDialog,
  } = useAccounts()

  const [ssoGroups, setSsoGroups] = useState([])
  const [ssoGroupsModalOpen, setSsoGroupsModalOpen] = useState(false)

  const openSsoGroupsModal = ssoGroups => {
    setSsoGroups(ssoGroups)
    setSsoGroupsModalOpen(true)
  }

  useEffect(() => {
    getRoles()
  }, []) // eslint-disable-line

  useEffect(() => {
    getRoles()
  }, [crudCount]) // eslint-disable-line

  if (!hasPermission('users:read')) {
    return null
  }

  return (
    <>
      <ThemeProvider theme={v5Theme}>
        <Container maxWidth="xl">
          {loading ? (
            <UsersLoadingState />
          ) : error ? (
            <UsersErrorState />
          ) : (
            <Stack spacing={4}>
              <UsersProvider>
                <UsersHeader onClickAddUser={openAdd} onClickManageRoles={openPermissionsDialog} />

                <UsersTable
                  query={data}
                  openEdit={openEdit}
                  openSsoGroupsModal={openSsoGroupsModal}
                />
              </UsersProvider>
            </Stack>
          )}
        </Container>

        <SsoGroupsModal
          modalOpen={ssoGroupsModalOpen}
          ssoGroups={ssoGroups}
          closeModal={() => setSsoGroupsModalOpen(false)}
        />
      </ThemeProvider>

      <AddUser
        onClose={closeAdd}
        onSubmit={async values => {
          await createUser(values)
          refetch()
        }}
        dialogOpen={addDialogIsOpen}
        roles={roles}
      />

      <UsersManagement
        onClose={() => {
          getRoles()
          closePermissionsDialog()
        }}
        dialogOpen={permissionsDialogIsOpen}
      />

      <EditUser
        user={editUser}
        onClose={closeEdit}
        dialogOpen={editDialogIsOpen}
        roles={roles}
        deleteUser={async () => {
          await deleteUser()
          refetch()
        }}
      />
    </>
  )
}

export default Users
