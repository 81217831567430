import React from 'react'
import Box from '@mui/material/Box'
import InputAdornment from '@mui/material/InputAdornment'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { IconCopyButton, Input, Select } from '@jeeves/new-components'
import { graphql } from '@jeeves/graphql'

const handleFocus = event => event.target.select()

const AwsCli = ({ repoEdge }) => {
  return (
    <Stack spacing={2}>
      <StepOne />
      <StepTwo repoEdge={repoEdge} />
      <StepThree />
    </Stack>
  )
}

const StepOne = () => {
  const pip3InstallCommand = 'pip3 install cyral'

  return (
    <Stack spacing={1}>
      <Typography variant="h6" sx={{ color: 'primary.main' }}>
        1. Install the Cyral CLI tool
      </Typography>
      <Stack direction="row" spacing={1} sx={{ alignItems: 'flex-end' }}>
        <Stack spacing={0.5} sx={{ flex: 1 }}>
          <Typography variant="h6" sx={{ color: 'text.secondary' }}>
            Please use Python 3.9 or higher.
          </Typography>
          <Input
            defaultValue={pip3InstallCommand}
            onFocus={handleFocus}
            endAdornment={
              <InputAdornment position="end" sx={{ marginLeft: 0 }}>
                <IconCopyButton text={pip3InstallCommand} />
              </InputAdornment>
            }
            sx={{
              typography: 'code',
            }}
            readOnly
            fullWidth
          />
        </Stack>
      </Stack>
    </Stack>
  )
}

export const StepTwo_UserAccountFragment = graphql(`
  fragment StepTwo_UserAccountFragment on UserAccount {
    id
    name
    credentials {
      ... on AWSIAMUserAccountCredentials {
        roleARN
      }
    }
  }
`)

const StepTwo = ({ repoEdge }) => {
  const roles = repoEdge.accessibleUserAccounts.edges.map(edge => edge.node)

  const [role, setRole] = React.useState(roles[0])

  let control_plane_address
  if (window._env_.control_plane_address && window._env_.keycloak_frontend_port) {
    control_plane_address = `${window._env_.control_plane_address}:${window._env_.keycloak_frontend_port}`
  } else if (window._env_.control_plane_address) {
    control_plane_address = window._env_.control_plane_address
  } else {
    control_plane_address = '<CONTROL_PLANE_ADDRESS>'
  }

  const accountName = encodeURI(role.name) || '<ACCOUNT_NAME>'
  const s3ConfigurationCommand = `cyral --cp-address ${control_plane_address} access s3 --account-name '${accountName}'`

  const onChangeRole = event => {
    const newRole = roles.find(role => role.id === event.target.value)
    setRole(newRole)
  }

  const options = roles.map(role => ({
    label: (
      <Box>
        <Typography variant="h6" sx={{ color: 'text.primary' }}>
          {role.name}
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.primary' }}>
          {role.credentials.roleARN}
        </Typography>
      </Box>
    ),
    value: role.id,
  }))

  return (
    <Stack spacing={1}>
      <Typography variant="h6" sx={{ color: 'primary.main' }}>
        2. Set up your S3 Access Configuration
      </Typography>

      <Stack spacing={2}>
        <Stack spacing={1}>
          <Typography variant="h6" sx={{ color: 'text.secondary' }}>
            Select an IAM role
          </Typography>

          <Select value={role.id} options={options} onChange={onChangeRole} />
        </Stack>

        <Stack spacing={1}>
          <Typography variant="h6" sx={{ color: 'text.secondary' }}>
            Connect to your S3 repository
          </Typography>

          <Input
            value={s3ConfigurationCommand}
            onFocus={handleFocus}
            endAdornment={
              <InputAdornment position="end" sx={{ marginLeft: 0 }}>
                <IconCopyButton text={s3ConfigurationCommand} />
              </InputAdornment>
            }
            sx={{
              typography: 'code',
            }}
            readOnly
            fullWidth
          />
        </Stack>
      </Stack>
    </Stack>
  )
}

const StepThree = () => {
  const command = 'aws s3 ls --profile cyral'

  return (
    <Stack spacing={1}>
      <Typography variant="h6" sx={{ color: 'primary.main' }}>
        3. View which S3 Buckets you have access to
      </Typography>
      <Input
        defaultValue={command}
        onFocus={handleFocus}
        endAdornment={
          <InputAdornment position="end" sx={{ marginLeft: 0 }}>
            <IconCopyButton text={command} />
          </InputAdornment>
        }
        sx={{
          typography: 'code',
        }}
        readOnly
        fullWidth
      />
    </Stack>
  )
}

export default AwsCli
