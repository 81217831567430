import React from 'react'

import {
  AwsIamIcon,
  AwsIcon,
  AzureIcon,
  CyralIcon,
  DenodoIcon,
  DremioIcon,
  DuoIcon,
  DynamoDBIcon,
  DynamoDBStreamsIcon,
  GaleraIcon,
  GcpIcon,
  LookerIcon,
  MariaDBIcon,
  MongoDBIcon,
  MySQLIcon,
  OracleIcon,
  PagerdutyIcon,
  PagerdutyLetterIcon,
  PostgreSQLIcon,
  RedshiftIcon,
  RestIcon,
  S3Icon,
  SnowflakeIcon,
  SQLServerIcon,
  TableauIcon,
} from '@jeeves/new-components'

const ServiceIcon = ({ type, ...rest }) => {
  let Icon

  switch (type) {
    case 'aws':
      Icon = AwsIcon
      break
    case 'azure':
      Icon = AzureIcon
      break
    case 'awsIam':
      Icon = AwsIamIcon
      break
    case 'cyral':
      Icon = CyralIcon
      break
    case 'looker':
      Icon = LookerIcon
      break
    case 'tableau':
      Icon = TableauIcon
      break
    case 'denodo':
      Icon = DenodoIcon
      break
    case 'dremio':
      Icon = DremioIcon
      break
    case 'duo':
      Icon = DuoIcon
      break
    case 'dynamodb':
      Icon = DynamoDBIcon
      break
    case 'dynamodbstreams':
      Icon = DynamoDBStreamsIcon
      break
    case 'galera':
      Icon = GaleraIcon
      break
    case 'gcp':
      Icon = GcpIcon
      break
    case 'pagerduty':
      Icon = PagerdutyIcon
      break
    case 'pagerduty-letter':
      Icon = PagerdutyLetterIcon
      break
    case 'postgresql':
      Icon = PostgreSQLIcon
      break
    case 'mongodb':
      Icon = MongoDBIcon
      break
    case 'redshift':
      Icon = RedshiftIcon
      break
    case 'rest':
      Icon = RestIcon
      break
    case 'mariadb':
      Icon = MariaDBIcon
      break
    case 'mysql':
      Icon = MySQLIcon
      break
    case 'oracle':
      Icon = OracleIcon
      break
    case 's3':
      Icon = S3Icon
      break
    case 'snowflake':
      Icon = SnowflakeIcon
      break
    case 'sqlserver':
      Icon = SQLServerIcon
      break
    default:
      Icon = null
      break
  }

  return <Icon {...rest} />
}

export default ServiceIcon
