import MuiStepper from '@mui/material/Stepper'
import MuiStep from '@mui/material/Step'
import MuiStepLabel from '@mui/material/StepLabel'

const Stepper = ({ steps, activeStep }) => {
  return (
    <MuiStepper activeStep={activeStep}>
      {steps.map((step, index) => {
        return (
          <MuiStep
            key={index}
            sx={{
              ...(index === 0 && {
                pl: 0,
              }),
              ...(index === steps.length - 1 && {
                pr: 0,
              }),
            }}
          >
            <MuiStepLabel
              StepIconProps={{
                sx: {
                  '& .MuiStepIcon-text': {
                    fontWeight: theme => theme.typography.h4.fontWeight,
                  },
                },
              }}
              sx={{
                '& .MuiStepLabel-iconContainer': {
                  pr: 0,
                },
              }}
            />
          </MuiStep>
        )
      })}
    </MuiStepper>
  )
}

export default Stepper
