/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { useState, useCallback } from 'react'
import lodash from 'lodash'

import { Box, Stack, Typography, Table, TableHead, TableBody, TableFooter } from '@mui/material'
import { ChevronLeft, ChevronRight, KeyboardArrowDown } from '@mui/icons-material'

import { RepoTablePagination, CustomSelectDropdown } from './components/RepoTablePagination'

import { useAuth } from '@jeeves/components/Auth'
import { TableContainer, SearchInput, Button } from '@jeeves/new-components'
import { FilterPopover } from '@jeeves/pages/AccessPortal/components/AccessPortalMain/components/FilterPopover'
import { Paper } from '@jeeves/components/Primitives'

import { AddRepositoryModal, RepositoryRow } from './components'

import { useReposWithUtilities } from './hooks'

const Repositories = () => {
  const [reposPerPage, setReposPerPage] = useState(10)
  const [pageIndex, setPageIndex] = useState(0)

  const { hasPermission } = useAuth()
  const {
    loading,
    data,
    refetch,
    fetchingMore,
    getNextPage,
    getPreviousPage,
    hasNextPage,
    hasPreviousPage,
    numRepos,
  } = useReposWithUtilities({
    variables: { first: reposPerPage },
    notifyOnNetworkStatusChange: true,
  })

  const [searchValue, setSearchValue] = useState('')
  const [filters, setFilters] = useState({
    repoType: null,
    repoTags: [],
  })

  const submitSearch = useCallback(
    lodash.debounce(e => {
      refetch({
        filters: {
          repoName: e.target.value,
          ...filters,
        },
      })
    }, 300),
    [filters]
  )

  const handleChange = e => {
    setSearchValue(e.target.value)
    submitSearch(e)
  }

  const handleFilter = ({ repoType, repoTags }) => {
    setFilters({ repoType, repoTags })
    refetch({
      filters: {
        ...(searchValue ? { repoName: searchValue } : {}),
        repoType,
        repoTags,
      },
    })
  }

  if (!hasPermission('repo:read')) {
    return null
  }

  const TablePaginationActions = () => {
    return (
      <Stack sx={{ width: '100%', justifyContent: 'flex-end' }} direction="row" spacing={2}>
        {hasPreviousPage && (
          <Button
            variant="text"
            color="secondary"
            onClick={() => {
              getPreviousPage()
              setPageIndex(pageIndex - 1)
            }}
            disabled={fetchingMore}
            startIcon={<ChevronLeft />}
          >
            <Typography variant="h6" sx={{ color: 'text.secondary' }}>
              Back
            </Typography>
          </Button>
        )}
        {hasNextPage && (
          <Button
            variant="text"
            color="secondary"
            onClick={() => {
              getNextPage()
              setPageIndex(pageIndex + 1)
            }}
            disabled={fetchingMore}
            endIcon={<ChevronRight />}
          >
            <Typography variant="h6" sx={{ color: 'text.secondary' }}>
              Next
            </Typography>
          </Button>
        )}
      </Stack>
    )
  }

  return (
    <Paper css={{ padding: '32px' }}>
      <Stack spacing={4}>
        <Typography variant="h2">Data Repositories</Typography>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" spacing={2}>
            <SearchInput
              value={searchValue}
              onChange={handleChange}
              placeholder="Search by repository name"
              sx={{ minWidth: '300px' }}
            />

            <FilterPopover handleFilter={handleFilter} filters={filters} hideTags />
          </Stack>
          <AddRepositoryModal />
        </Stack>
        <TableContainer
          sx={{
            'tr:last-child': {
              '& td': {
                borderBottomWidth: 1,
                borderBottomStyle: 'solid',
                borderColor: 'cyralColors.grey.200',
              },
            },
          }}
        >
          <Table>
            <TableHead>
              <Box component="tr">
                <Box
                  component="th"
                  scope="col"
                  sx={{ textAlign: 'center !important', whiteSpace: 'nowrap', width: '200px' }}
                >
                  Type
                </Box>
                <Box component="th" scope="col" sx={{ width: '600px' }}>
                  Name
                </Box>
                <Box component="th" scope="col">
                  Tags
                </Box>
              </Box>
            </TableHead>
            <TableBody>
              {!loading && data.repoEdges?.map(edge => <RepositoryRow repo={edge.node} />)}
            </TableBody>
            <TableFooter>
              <RepoTablePagination
                SelectProps={{
                  IconComponent: KeyboardArrowDown,
                  MenuProps: {
                    component: CustomSelectDropdown,
                  },
                }}
                labelRowsPerPage={
                  <Typography variant="h6" sx={{ color: 'text.secondary' }}>
                    Repositories per page
                  </Typography>
                }
                labelDisplayedRows={({ from, to, count }) =>
                  !loading && `${from}-${to} of ${count} repositories`
                }
                rowsPerPageOptions={[10, 25, 50]}
                ActionsComponent={TablePaginationActions}
                colSpan={8}
                page={pageIndex}
                count={numRepos}
                rowsPerPage={reposPerPage}
                onRowsPerPageChange={e => {
                  setReposPerPage(e.target.value)
                  setPageIndex(0)
                }}
              />
            </TableFooter>
          </Table>
        </TableContainer>
      </Stack>
    </Paper>
  )
}

export default Repositories
