import * as React from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { InlineCode } from '@jeeves/new-components'

import { getCredentialsTypeMetadata } from '@jeeves/pages/RepositoryDetail/Tabs/UserAuthentication/helpers'

const CredentialsInfo = ({ credentials }) => {
  const { label, secretIdentifiers } = getCredentialsTypeMetadata(credentials)

  return (
    <Box
      sx={{
        borderRadius: theme => theme.radii.base,
        border: 1,
        borderColor: 'cyralColors.grey.200',
        p: 2,
        flex: 1,
      }}
    >
      <Stack direction="row" spacing={2}>
        <Stack>
          <Typography variant="h5" sx={{ color: 'text.secondary' }}>
            {credentials.__typename === 'AWSIAMUserAccountCredentials'
              ? 'Authentication Method'
              : 'Credentials Storage'}
          </Typography>
          <Typography variant="h4" sx={{ color: 'text.primary' }}>
            {label}
          </Typography>
        </Stack>

        {secretIdentifiers.map((identifier, idx) => {
          return (
            <Stack key={idx} spacing={0.5} sx={{ alignItems: 'flex-start' }}>
              <Typography variant="h5" sx={{ color: 'text.secondary' }}>
                {identifier.label}
              </Typography>
              <InlineCode>{identifier.value}</InlineCode>
            </Stack>
          )
        })}
      </Stack>
    </Box>
  )
}

export default CredentialsInfo
