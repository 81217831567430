import { Stack } from '@mui/material'
import PolicyRule from '../../../../PolicyRule'
import { HighlightedTitle } from '@jeeves/pages/RepositoryDetail/Tabs/Policies/components/HighlightedTitle'
import { Variation1 } from '../Variation1'
import { useRepositoryProtectionVariation } from '../useRepositoryProtectionVariation'

const Variation9 = () => {
  const { databaseAccounts, identities } = useRepositoryProtectionVariation()

  return (
    <Stack spacing={4}>
      <Variation1 />
      <Stack spacing={1}>
        <PolicyRule
          title={
            <HighlightedTitle
              label1="This policy"
              label2="does not"
              label3="apply to any of these identities:"
            />
          }
          content={identities}
        />
        <PolicyRule
          title="or when any of the following database accounts are used"
          content={databaseAccounts}
        />
      </Stack>
    </Stack>
  )
}

export default Variation9
