/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import styled from '@emotion/styled'
import { useState } from 'react'
import { CardContent, TextField, Typography, Grid } from '@material-ui/core'
import { Edit as EditIcon } from '@material-ui/icons'
import { useFormik } from 'formik'
import * as yup from 'yup'

import { usePopup } from '@jeeves/hooks'
import { Button } from '@jeeves/components/Primitives'
import ConfirmDialog from '@jeeves/components/ConfirmDialog'
import { ActionButton, FlexGrow } from '@jeeves/components/Primitives'
import { SectionTitle, Feature, WrapperCard } from './styles'

const LinkFeature = styled(Feature)`
  cursor: pointer;
  text-decoration: underline;
`

const Label = styled(Typography)`
  font-size: 16px;
  font-weight: 600;
  margin-right: 6px;
`

const Value = styled(Typography)`
  font-size: 16px;
  margin-right: 24px;
`

const ContactLine = ({ contactInfo }) => (
  <Grid container>
    <Grid item xs={3}>
      <Label>Billing:</Label>
    </Grid>
    <Grid item xs={9}>
      <Value>{contactInfo.billingContact}</Value>
    </Grid>

    <Grid item xs={3} css={{ marginTop: '8px' }}>
      <Label>Support:</Label>
    </Grid>
    <Grid item xs={9} css={{ marginTop: '8px' }}>
      <Value>{contactInfo.supportContact}</Value>
    </Grid>
  </Grid>
)

const ContactDialog = ({ open, setOpen, contactInfo, updateContactInfo, fetchContactInfo }) => {
  const { showSuccess, showError } = usePopup()
  // console.log('contactDialog: ', contactInfo)
  const {
    getFieldProps,
    touched,
    errors,
    handleSubmit,
    resetForm,
    isValid,
    isSubmitting,
  } = useFormik({
    initialValues: {
      billing: contactInfo ? contactInfo.billingContact : '',
      support: contactInfo ? contactInfo.supportContact : '',
    },
    validationSchema: yup.object({
      billing: yup
        .string()
        .email()
        .required(),
      support: yup
        .string()
        .email()
        .required(),
    }),
    onSubmit: async (values, actions) => {
      actions.setSubmitting(true)
      try {
        await updateContactInfo({ billingContact: values.billing, supportContact: values.support })
        actions.resetForm()
        await fetchContactInfo()
        setOpen(false)
        actions.setSubmitting(false)
      } catch (e) {
        showError('Something went wrong. Please try again!')
      }
      // setTimeout(() => {
      //   console.log({ values })
      //   actions.resetForm()
      //   showSuccess('Thank you! An invite has been sent.')
      //   setOpen(false)
      //   actions.setSubmitting(false)
      // }, 500)
    },
  })

  // const showError = errors.Email && touched.Email && true

  return (
    <ConfirmDialog
      maxWidth="sm"
      fullWidth
      title="Edit contact information"
      open={open}
      setOpen={setOpen}
      confirmButton={
        <div css={{ position: 'relative' }}>
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            // disabled={isSubmitting}
          >
            Save
          </Button>
          {/* {isSubmitting && <ButtonLoading />} */}
        </div>
      }
    >
      <TextField
        {...getFieldProps('billing')}
        fullWidth
        label="Billing email"
        // helperText={showError && errors.Email}
        // error={showError}
        disabled={isSubmitting}
        css={{ marginBottom: '12px' }}
      />
      <TextField
        {...getFieldProps('support')}
        fullWidth
        label="Support email"
        // helperText={showError && errors.Email}
        // error={showError}
        disabled={isSubmitting}
      />
    </ConfirmDialog>
  )
}

export const Contact = ({ contactInfo, updateContactInfo, fetchContactInfo }) => {
  // console.log('contactInfo: ', contactInfo)
  const [contactOpen, setContactOpen] = useState(false)
  return (
    <WrapperCard>
      <CardContent>
        <div css={{ display: 'flex' }}>
          <FlexGrow>
            <SectionTitle>CONTACT INFORMATION</SectionTitle>
          </FlexGrow>
          <ActionButton size="small" onClick={() => setContactOpen(true)}>
            <EditIcon />
          </ActionButton>
        </div>
        {contactInfo && <ContactLine contactInfo={contactInfo}></ContactLine>}

        {contactInfo && (
          <ContactDialog
            fetchContactInfo={fetchContactInfo}
            updateContactInfo={updateContactInfo}
            contactInfo={contactInfo}
            open={contactOpen}
            setOpen={setContactOpen}
          />
        )}
      </CardContent>
    </WrapperCard>
  )
}
