import { FragmentType, graphql, useFragment } from '@jeeves/graphql'

const useAmountOfAvailableTokens_QueryFragment = graphql(`
  fragment useAmountOfAvailableTokens_QueryFragment on Query {
    accessTokenConfiguration {
      maxNumberOfTokensPerUser
    }
    accessTokens {
      id
    }
  }
`)

const useAmountOfAvailableTokens = ({
  query: queryProp,
}: {
  query: FragmentType<typeof useAmountOfAvailableTokens_QueryFragment>
}) => {
  const query = useFragment(useAmountOfAvailableTokens_QueryFragment, queryProp)

  const maxTokensPerUser = query.accessTokenConfiguration.maxNumberOfTokensPerUser
  const amountOfAvailableTokens = Math.max(0, maxTokensPerUser - query.accessTokens.length)

  return amountOfAvailableTokens
}

export default useAmountOfAvailableTokens
