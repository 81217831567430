import {
  Box,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  IconButton,
  FormHelperText,
} from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'
import { Dialog, Button, DialogActions, Input, InputLabel } from '@jeeves/new-components'
import useDeleteCredentialsModal from '../../hooks/useDeleteCredentialsModal'

interface Props {
  selectedCredentialToDelete: string
  setSelectedCredentialToDelete: React.Dispatch<React.SetStateAction<string>>
}

const DeleteCredentialsModal = ({
  selectedCredentialToDelete,
  setSelectedCredentialToDelete,
}: Props) => {
  const { handleDeleteConfirm, closeModal, loading } = useDeleteCredentialsModal({
    selectedCredentialToDelete,
    setSelectedCredentialToDelete,
  })
  const open = !!selectedCredentialToDelete

  return (
    <>
      <Dialog open={open} onClose={closeModal} fullWidth>
        <DialogTitle
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h3" sx={{ color: 'text.primary' }}>
            Delete Credentials
          </Typography>
          <IconButton aria-label="close" onClick={closeModal}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Are you sure you want to delete the sidecar client credentials associated with the
              following Client ID?
            </Typography>
            <Stack spacing={2}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <InputLabel>Client ID</InputLabel>
                <Input
                  id="clientId"
                  sx={{
                    fontFamily: theme => theme.typography.code,
                  }}
                  readOnly
                  value={selectedCredentialToDelete}
                  type="text"
                />
              </Box>
              <FormHelperText
                error
                sx={{
                  typography: 'body2',
                }}
              >
                Warning: This will invalidate existing credentials.
              </FormHelperText>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Stack direction="row" spacing={2} sx={{ justifyContent: 'flex-end' }}>
            <Button variant="text" color="secondary" onClick={closeModal}>
              Cancel
            </Button>
            <Button
              variant="outlined"
              color="error"
              onClick={handleDeleteConfirm}
              loading={loading}
            >
              Delete
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default DeleteCredentialsModal
