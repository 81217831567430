import { ReactNode } from 'react'
import { Box, Stack, Typography } from '@mui/material'

interface Props {
  title: string
  description: string | ReactNode
  image: ReactNode
}

const SetupWizardStep6Card = ({ title, description, image }: Props) => {
  return (
    <Box
      sx={{
        border: 1,
        borderRadius: theme => theme.radii.base,
        borderColor: 'primary.main',
        position: 'relative',
        width: '360px',
        height: '240px',
        overflow: 'hidden',
      }}
    >
      <Stack spacing={1} sx={{ paddingTop: 4, paddingX: 4 }}>
        <Typography
          sx={{
            color: 'text.primary',
            fontSize: '1.375rem',
            fontWeight: 600,
            lineHeight: '35.2px',
          }}
        >
          {title}
        </Typography>

        {description}
      </Stack>

      {image}
    </Box>
  )
}

export default SetupWizardStep6Card
