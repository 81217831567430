import React, { useState, useEffect } from 'react'
import { withStyles, Table, TableBody, Typography, Toolbar } from '@material-ui/core'
import Fuse from 'fuse.js'
import { stableSort, getSorting } from '@jeeves/utils'
import { TableHead, ExpandButton } from '@jeeves/components/Table'
import useInterval from '@jeeves/hooks/useInterval'
import { EtcdSearchField } from './SearchField'
import Row from './Row'

import useEtcdViewer from './hooks/useEtcdViewer'

import stringify from 'json-stringify-pretty-compact'
const styles = theme => ({
  container: {
    backgroundColor: '#552583',
    padding: theme.spacing.unit * 3,
  },
  etcdKey: {
    color: 'yellow',
    fontSize: '14px',
    fontWeight: 'bold',
  },
  etcdContent: {
    color: 'yellow',
    fontSize: '12px',
    whiteSpace: 'pre',
    height: '100%',
  },
  grow: {
    flexGrow: 1,
  },
})

const EtcdViewer = ({ classes, repos, colorIndex }) => {
  const { state, setState, ec, handleRequestSort, toggleIsAllExpanded } = useEtcdViewer()
  const { page, order, orderBy, etcdContent, isAllExpanded } = state
  const rowsPerPage = 25
  const [searchValue, setSearchValue] = useState('')
  const [etcdCollection, setEtcdCollection] = useState([])
  const [filteredEtcdCollection, setFilteredEtcdCollection] = useState([])
  const [firstLoad, setFirstLoad] = useState(true)

  useEffect(() => {
    setEtcdCollection(etcdContent)

    if (firstLoad && etcdContent.length > 0) {
      setFilteredEtcdCollection(etcdContent)
      setFirstLoad(false)
    }
  }, [etcdContent])

  useInterval(() => {
    return ec
      .get('/api/etcd')
      .then(res => setState(state => ({ ...state, etcdContent: res.data })))
      .catch(e => console.log('EtcdViewer.useInterval error: ', e))
  }, 3000)

  const prettifyValue = value => {
    try {
      const parsed = JSON.parse(value)
      const prettified = stringify(parsed)
      //   console.log(prettified)
      return prettified
    } catch {
      return value
    }
  }

  const search = () => {
    if (!searchValue) {
      return setFilteredEtcdCollection(etcdCollection)
    }

    var lcSearchValue = searchValue.toLowerCase()
    const searchResults = etcdCollection.filter(
      entry =>
        entry['key'].toLowerCase().includes(lcSearchValue) ||
        entry['value'].toLowerCase().includes(lcSearchValue)
    )
    setFilteredEtcdCollection(searchResults)
  }

  const sorted = stableSort(
    filteredEtcdCollection.map(({ key, value }) => [key, value]),
    getSorting(order, orderBy)
  )

  return (
    <>
      <Toolbar>
        <Typography variant="headline">ETCD Viewer</Typography>
        <div className={classes.grow} />
        <EtcdSearchField
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
          onClick={search}
          onKeyUp={e => e.keyCode == 13 && search()}
        />
        <ExpandButton onClick={toggleIsAllExpanded} expanded={isAllExpanded} />
      </Toolbar>

      <Table className={classes.table} aria-labelledby="tableTitle">
        <TableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          columns={[
            { id: 'Key', numeric: false, disablePadding: false, label: 'Key' },
            // { id: 'lastAccessed', numeric: false, disablePadding: false, label: 'Last accessed' },
            { id: 'Expand', numeric: false, disablePadding: true, label: null },
          ]}
          rowCount={filteredEtcdCollection.length}
        />

        <TableBody>
          {sorted
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map(([key, value], index) => (
              <Row etcdKey={key} etcdValue={value} key={index} />
            ))}
        </TableBody>
      </Table>
    </>
  )
}

export default withStyles(styles)(EtcdViewer)
