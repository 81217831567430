import { Stack, FormControlLabel, Switch, Typography, FormControl } from '@mui/material'
import { Controller, useFormContext, useWatch } from 'react-hook-form'

const PassthroughSwitch = () => {
  const { control } = useFormContext()

  return (
    <Stack spacing={1}>
      <FormControl fullWidth variant="standard">
        <Controller
          name="passthrough"
          control={control}
          render={({ field: { value, ...field } }) => (
            <FormControlLabel
              control={<Switch checked={value} {...field} />}
              label={
                <Typography variant="h4" sx={{ color: 'text.primary' }}>
                  Switch to passthrough mode for sidecar
                </Typography>
              }
            />
          )}
        />
      </FormControl>

      <Typography variant="body2" sx={{ color: 'text.secondary', pl: 6 }}>
        <li>All sidecar services are off</li>
        <li>Users must use native credentials to log into the repo</li>
      </Typography>
    </Stack>
  )
}

const FailoverSwitch = () => {
  const { control } = useFormContext()

  const passthrough = useWatch({ name: 'passthrough' })

  return (
    <Stack spacing={1}>
      <FormControl fullWidth variant="standard">
        <Controller
          name="failover"
          control={control}
          render={({ field: { value, ...field } }) => (
            <FormControlLabel
              control={<Switch checked={value || passthrough} {...field} disabled={passthrough} />}
              label={
                <Typography variant="h4" sx={{ color: 'text.primary' }}>
                  Enable resiliency to sidecar errors
                </Typography>
              }
            />
          )}
        />
      </FormControl>

      <Typography variant="body2" sx={{ color: 'text.secondary', pl: 6 }}>
        <li>In the event of an error, existing client sessions will continue uninterrupted</li>
        <li>
          Once an error happens, all subsequent inline authorization checks in existing sessions are
          ignored
        </li>
        <li>All new sessions will be established normally, and all checks will be enforced</li>
      </Typography>
    </Stack>
  )
}

const PassthroughMode = () => {
  return (
    <Stack spacing={4}>
      <PassthroughSwitch />
      <FailoverSwitch />
    </Stack>
  )
}

export default PassthroughMode
