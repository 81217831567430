/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import styled from '@emotion/styled'
import { useEffect, useState } from 'react'

import Loading from '@jeeves/components/Loading'
import ExpressClient from '@jeeves/clients/express'
import usePopup from '@jeeves/components/PopupMessage/hooks/usePopup'

import Content from './components/Content'
import PopupMessage from '../PopupMessage'
import { useAuth } from '../Auth'

const Dashboard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Main = styled.main`
  overflow-x: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ loading }) => (loading ? '100vh' : 'auto')};
`

const ContentViewer = ({ match }) => {
  const { setPopup, popupTypes } = usePopup()
  const [content, setContent] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const { getTokenSilently } = useAuth()
  const ec = new ExpressClient(getTokenSilently)

  useEffect(() => {
    const fetchGuide = async () => {
      try {
        setIsLoading(true)
        await ec.get(`/${match.params[0]}`).then(res => {
          setContent(res.data)
        })
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
        let popupMessage = 'Unable to load document'
        if (e.response && e.response.status === 404) {
          popupMessage = 'The specified document does not exist'
        }
        setPopup(popupTypes.ERROR, popupMessage)
      }
    }
    fetchGuide()
  }, [])

  return (
    <div css={{ height: '100vh', overflow: 'auto' }}>
      <PopupMessage />
      {isLoading ? <Loading /> : <Content isLoading={isLoading} content={content} />}
    </div>
  )
}

export default ContentViewer
