import React, { forwardRef, useEffect, useState } from 'react'
import Stack from '@mui/material/Stack'

import { ServiceIcon } from '@jeeves/new-components'

export const AccessRestrictionInfo = ({ accessRestrictionSummaries=[] }) => {
  const [pagerdutyExists, setPagerdutyExists] = useState(false)
  const [duoExists, setDuoExists] = useState(false)

  useEffect(() => {
    setPagerdutyExists(accessRestrictionSummaries.find(summary => summary.type === 'pagerduty'))
    setDuoExists(accessRestrictionSummaries.find(summary => summary.type === 'duo'))
  }, [accessRestrictionSummaries])
  
  return (
    <Stack direction="row" spacing={2} alignItems="center">
      {pagerdutyExists && <ServiceIcon type="pagerduty-letter" sx={{ fontSize: '1rem' }}/>}
      {duoExists && <ServiceIcon type="duo"/>}
    </Stack>
  )
}
