import { Stack, Typography } from '@mui/material'
import { ErrorOutline as ErrorOutlineIcon } from '@mui/icons-material'

const ErrorState = () => {
  return (
    <Stack spacing={2} sx={{ alignItems: 'center', justifyContent: 'center' }}>
      <ErrorOutlineIcon sx={{ fontSize: 56, color: 'cyralColors.grey.300' }} />
      <Typography variant="h4" sx={{ color: 'cyralColors.grey.500' }}>
        Unable to fetch policies.
      </Typography>
    </Stack>
  )
}

export default ErrorState
