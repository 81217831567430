import { Box, Stack, Typography } from '@mui/material'

import PoliciesCard from '../PoliciesCard'

const PoliciesList = ({ status, list }) => {
  return (
    <Stack spacing={2}>
      <Typography variant="h4" sx={{ color: 'text.primary' }}>
        {`${status} Policies (${list?.length ?? 0})`}
      </Typography>

      {list?.length > 0 ? (
        <Box
          sx={{
            display: 'grid',
            gap: 2,
            gridTemplateColumns: 'repeat(3, 1fr)',
          }}
        >
          {list.map(({ id, name, description, tags, template }) => (
            <PoliciesCard
              key={id}
              id={id}
              name={name}
              description={description}
              tags={tags}
              templateName={template.name}
            />
          ))}
        </Box>
      ) : (
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {`No ${status} Policies.`}
        </Typography>
      )}
    </Stack>
  )
}

export default PoliciesList
