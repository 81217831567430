import { Stack } from '@mui/material'
import PolicyRule from '../../../../PolicyRule'
import { HighlightedTitle } from '@jeeves/pages/RepositoryDetail/Tabs/Policies/components/HighlightedTitle'
import { Variation1 } from '../Variation1'
import { useRepositoryProtectionVariation } from '../useRepositoryProtectionVariation'

const Variation8 = () => {
  const { databaseAccounts, identities } = useRepositoryProtectionVariation()

  return (
    <Stack spacing={4}>
      <Variation1 />
      <PolicyRule
        title="This policy applies to these database accounts:"
        content={databaseAccounts}
      />
      <PolicyRule
        title={
          <HighlightedTitle
            label1="This policy"
            label2="does not"
            label3="apply to the following identities regardless of the database account they're using"
          />
        }
        content={identities}
      />
    </Stack>
  )
}

export default Variation8
