import React from 'react'
import { Stack, Typography, Divider } from '@mui/material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { ServiceIcon } from '@jeeves/new-components'

import { LearnMore } from './LearnMore'

const Configured = () => (
  <Stack direction="row" spacing={1}>
    <CheckCircleOutlineIcon sx={{ color: 'cyralColors.green.400' }} />
    <Typography sx={{ color: 'cyralColors.green.400' }} variant="h6">
      Configured
    </Typography>
  </Stack>
)

const SetupItem = ({ setupTitle, configured = false, docsPath }) => {
  return (
    <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
      <Typography variant="h5" sx={{ color: 'text.secondary' }}>
        {setupTitle}
      </Typography>

      {configured ? <Configured /> : <LearnMore docsPath={docsPath} />}
    </Stack>
  )
}

export const Setup = ({ queryResponse }) => {
  const { data, loading } = queryResponse

  const repoTypes = data?.repoTypes

  return (
    <Stack spacing={4}>
      <Stack spacing={2}>
        <Typography variant="h4" sx={{ color: 'text.primary' }}>
          Setup
        </Typography>
        <Divider />
      </Stack>

      <Stack spacing={2}>
        <Stack direction="row" spacing={4}>
          <Stack sx={{ flex: 1 }}>
            <Typography variant="h5" sx={{ color: 'text.secondary' }}>
              Sidecars
            </Typography>
            <Typography variant="h2" sx={{ color: 'text.primary' }}>
              {data?.numSidecars}
            </Typography>
          </Stack>
          <Stack sx={{ flex: 3 }}>
            <Typography variant="h5" sx={{ color: 'text.secondary' }}>
              Repository Types
            </Typography>
            <Stack direction="row" spacing={2} sx={{ marginTop: '4px' }}>
              {repoTypes?.map(repoType => {
                if (repoType === 'rest') return null // Not supporting rest
                return <ServiceIcon type={repoType} sx={{ height: '32px', width: '32px' }} />
              })}
            </Stack>
          </Stack>
        </Stack>
        <Divider />
      </Stack>

      <Stack spacing={4}>
        <SetupItem
          setupTitle={'Identity Provider'}
          configured={data?.idpConfigured}
          docsPath={'/integrations/authentication/idp'}
        />
        <SetupItem
          setupTitle={'Multifactor Authentication'}
          configured={data?.mfaConfigured}
          docsPath={'/authentication/mfa/duo'}
        />
        <SetupItem
          setupTitle={'Approvals'}
          configured={data?.approvalsConfigured}
          docsPath={'/manage-user-access/approvals'}
        />
        <SetupItem
          setupTitle={'Service Account Resolution'}
          configured={data?.serviceAccountsConfigured}
          docsPath={'/using-cyral/identify-users-behind-apps'}
        />
      </Stack>
    </Stack>
  )
}
