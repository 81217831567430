/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import React from 'react'
import { jsx, css } from '@emotion/react'
import styled from '@emotion/styled'

import { useAuth } from '@jeeves/hooks'
import { AddIntegrationButtonUnformatted } from '@jeeves/pages/Integrations/primitives'

const NewLoggingIntegrationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const NewLoggingIntegration = ({ handleNewLoggingIntegration }) => {
  const { hasPermission } = useAuth()

  return (
    <NewLoggingIntegrationWrapper>
      <AddIntegrationButtonUnformatted
        setOpen={handleNewLoggingIntegration}
        disabled={!hasPermission('integrations:create')}
      />
    </NewLoggingIntegrationWrapper>
  )
}

export default NewLoggingIntegration
