import React from 'react'
import { Box, Stack, Typography } from '@mui/material'

import { FragmentType, graphql, useFragment } from '@jeeves/graphql'

import LogLevelServiceLabel from './LogLevelServiceLabel'
import LogLevelSelect from './LogLevelSelect'
import useLogLevel from './useLogLevel'

const LogLevel_queryFragment = graphql(`
  fragment LogLevel_queryFragment on Query {
    ...useLogLevel_queryFragment
  }
`)

interface Props {
  query: FragmentType<typeof LogLevel_queryFragment>
}

const LogLevel = ({ query: queryProp }: Props) => {
  const query = useFragment(LogLevel_queryFragment, queryProp)

  const services = useLogLevel({ query })

  return (
    <Stack spacing={2}>
      <Typography variant="h6" sx={{ color: 'text.secondary' }}>
        Specify the verbosity level for diagnostics logs for each component below:
      </Typography>

      <Box sx={{ display: 'grid', gridTemplateColumns: '2fr 1fr', rowGap: 1, columnGap: 2 }}>
        {services.map(service => {
          const displayedServices = 'repos' in service ? service.repos : [{ label: service.label }]

          return (
            <React.Fragment key={service.name}>
              <LogLevelServiceLabel services={displayedServices} />
              <LogLevelSelect name={`diagnosticLogsServices.${service.name}`} />
            </React.Fragment>
          )
        })}
      </Box>
    </Stack>
  )
}

export default LogLevel
