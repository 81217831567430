/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { CircularProgress } from '@material-ui/core'

/**
 * This component is to be used together with buttons inside a <div>
 * with position: relative.
 */
export const ButtonLoading = () => (
  <CircularProgress
    size={24}
    css={css`
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -12px;
      margin-left: -12px;
    `}
  />
)
