import React from 'react'

import { ScreenshotLayout } from '../../../components'
import ToadConnectionScreen from './Toad.png'

const Toad = () => {
  return (
    <ScreenshotLayout
      imgSrc={ToadConnectionScreen}
      title="Connecting with Toad"
      steps={[
        'In Toad, click on New Connection under the Session tab',
        'Fill out the highlighted fields with the connection info displayed above.',
      ]}
    />
  )
}

export default Toad
