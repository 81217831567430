import * as React from 'react'

import { Button } from '@jeeves/new-components'

import ConfigureAutoApprovalsDialog from './ConfigureAutoApprovalsDialog'

const ConfigureAutoApprovals = ({ userAccounts }) => {
  const [dialogOpen, setDialogOpen] = React.useState(false)

  const openDialog = () => setDialogOpen(true)
  const closeDialog = () => setDialogOpen(false)

  return (
    <React.Fragment>
      <Button variant="outlined" color="secondary" onClick={openDialog}>
        Configure Auto Approvals
      </Button>

      <ConfigureAutoApprovalsDialog
        open={dialogOpen}
        onClose={closeDialog}
        userAccounts={userAccounts}
      />
    </React.Fragment>
  )
}

export default ConfigureAutoApprovals
