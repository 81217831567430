import { FormControl, FormHelperText, Stack } from '@mui/material'

import { Input, InputLabel } from '@jeeves/new-components'

import { LoggingIntegrationIcon } from '@jeeves/pages/Integrations/Services/Logging/components'

import Header from '@jeeves/pages/Integrations/Services/Logging/components/NewIntegrationDialog/components/Header'
import Footer from '@jeeves/pages/Integrations/Services/Logging/components/NewIntegrationDialog/components/Footer'

import { useDatadog } from './useDatadog'

const Datadog = ({ integration, resetIntegration, onClose }) => {
  const isEditing = Boolean(integration?.id)

  const { loading, methods, onSubmit } = useDatadog({ integration, onClose, isEditing })

  const {
    formState: { errors },
    register,
  } = methods

  return (
    <Stack spacing={4} component="form" onSubmit={onSubmit}>
      <Header
        icon={<LoggingIntegrationIcon integrationType="DatadogIntegration" />}
        title="Datadog"
        onClose={onClose}
      />

      <FormControl variant="standard" error={Boolean(errors?.name)}>
        <InputLabel htmlFor="name" required>
          Name
        </InputLabel>
        <Input
          id="name"
          inputProps={{
            ...register('name'),
          }}
        />
        <FormHelperText
          sx={{
            typography: 'body2',
          }}
          error={Boolean(errors?.name)}
        >
          {errors?.name?.message}
        </FormHelperText>
      </FormControl>

      <FormControl variant="standard" error={Boolean(errors?.apiKey)}>
        <InputLabel htmlFor="apiKey" required>
          API Key
        </InputLabel>
        <Input
          id="apiKey"
          inputProps={{
            ...register('apiKey'),
          }}
        />
        <FormHelperText
          sx={{
            typography: 'body2',
          }}
          error={Boolean(errors?.apiKey)}
        >
          {errors?.apiKey?.message}
        </FormHelperText>
      </FormControl>

      <Footer
        loading={loading}
        isEditing={isEditing}
        resetIntegration={resetIntegration}
        onClose={onClose}
      />
    </Stack>
  )
}

export default Datadog
