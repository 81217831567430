import { Stack } from '@mui/material'
import PolicyRule from '../../../../PolicyRule'
import { HighlightedTitle } from '@jeeves/pages/RepositoryDetail/Tabs/Policies/components/HighlightedTitle'
import { useDataProtectionVariation } from '../useDataProtectionVariation'
import { Variation1 } from '../Variation1'

const Variation7 = () => {
  const { identities } = useDataProtectionVariation()

  return (
    <Stack spacing={4}>
      <Variation1 />
      <PolicyRule
        title={
          <HighlightedTitle
            label1="This policy"
            label2="does not"
            label3="apply to any of these identities:"
          />
        }
        content={identities}
      />
    </Stack>
  )
}

export default Variation7
