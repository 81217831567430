/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import CloudOutlinedIcon from '@material-ui/icons/CloudOutlined'
import { Avatar, TableCell, TableRow, withStyles, Tooltip } from '@material-ui/core'
import { get as lodashGet } from 'lodash'

const styles = () => ({
  hideEditButton: {
    display: 'none',
  },
})

// eslint-disable-next-line
const hideOnMd = css`
  display: none;

  @media (min-width: 1024px) {
    display: flex;
  }
`

// eslint-disable-next-line
const showOnMd = css`
  display: inline;

  @media (min-width: 1024px) {
    display: none;
  }
`

/* 
04/29/21
Temporary solution while we have unknown role sources is as follows:
Show manually assigned role if it exists, else show first unknown role
*/
const getRole = user => {
  if (user.roleSources && user.roleSources.manual && user.roleSources.manual[0]) {
    return user.roleSources.manual[0]
  }
  if (user.roleSources && user.roleSources.unknown && user.roleSources.unknown[0]) {
    return user.roleSources.unknown[0]
  }

  return null
}

const IsSSOColumn = ({ isSSO }) => {
  return (
    <TableCell css={{ paddingLeft: '1rem' }} padding="none" align="right">
      {isSSO && (
        <Tooltip title={'SSO user'}>
          <CloudOutlinedIcon></CloudOutlinedIcon>
        </Tooltip>
      )}
    </TableCell>
  )
}

const UserRow = ({ user, canEdit, openEdit }) => {
  return (
    <TableRow
      key={user.id}
      hover
      onClick={() => {
        if (canEdit) {
          openEdit(user)
        }
      }}
      css={() => ({ cursor: canEdit ? 'pointer' : 'auto' })}
    >
      <IsSSOColumn isSSO={user.isSSO} />
      <TableCell>{user.name}</TableCell>

      <TableCell>{user.email}</TableCell>
      <TableCell>{getRole(user)}</TableCell>
    </TableRow>
  )
}

export default withStyles(styles)(UserRow)
