import { NavLink } from 'react-router-dom'
import MenuList from '@material-ui/core/MenuList'
import HomeIcon from '@material-ui/icons/Home'

import { useAuth } from '@jeeves/hooks'
import { MenuItem } from './components/MenuItem'

const FirstSection = ({ iconOnly = false }) => {
  const { hasPermission } = useAuth()

  return (
    <MenuList>
      <MenuItem
        component={NavLink}
        to="/overview"
        disabled={!hasPermission('repo:read')}
        iconOnly={iconOnly}
        primaryText="Overview"
        listItemIcon={<HomeIcon fontSize="small" />}
      />
    </MenuList>
  )
}

export default FirstSection
