import * as React from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'

import { ApprovalsMain } from './views/ApprovalsMain'
import { ApprovalDetail } from './views/ApprovalDetail'

const Approvals = ({ userAccounts }) => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={path}>
        <ApprovalsMain userAccounts={userAccounts} />
      </Route>
      <Route path={`${path}/:approvalId`}>
        <ApprovalDetail />
      </Route>
    </Switch>
  )
}

export default Approvals
