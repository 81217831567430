import styled from '@emotion/styled'
import MuiTextField from '@material-ui/core/TextField'

export const TextField = styled(MuiTextField)`
  margin: 12px 0;
`

TextField.defaultProps = {
  fullWidth: true,
}

export { default } from './Users'
