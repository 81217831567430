import React, { useState, useMemo, useEffect } from 'react'
import { useQuery, gql, NetworkStatus } from '@apollo/client'
import { parse, end } from 'iso8601-duration'
import * as _ from 'lodash'

import { useAuth } from '@jeeves/components/Auth'

export const REPOS_WITH_UTILITIES = gql`
  query ReposWithUtilities(
    $filters: ReposWithUtilitiesFilters
    $first: Int
    $after: String
    $last: Int
    $before: String
  ) {
    reposWithUtilities(
      filters: $filters
      first: $first
      after: $after
      last: $last
      before: $before
    ) {
      edges {
        node {
          id
          name
          type
          tags
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`

const useReposWithUtilities = options => {
  const { data, loading, error, fetchMore, refetch, networkStatus } = useQuery(
    REPOS_WITH_UTILITIES,
    options
  )

  const reposPerPage = options.variables?.first || 10

  return {
    loading,
    error,
    data: {
      repoEdges: data?.reposWithUtilities?.edges,
    },
    numRepos: data?.reposWithUtilities?.totalCount,
    hasNextPage: data?.reposWithUtilities?.pageInfo?.hasNextPage,
    hasPreviousPage: data?.reposWithUtilities?.pageInfo?.hasPreviousPage,
    getNextPage:
      data?.reposWithUtilities?.pageInfo?.hasNextPage &&
      ((pageSize = reposPerPage) => fetchMore({ variables: { after: data?.reposWithUtilities?.pageInfo?.endCursor, first: pageSize, last: undefined } })),
    getPreviousPage:
      data?.reposWithUtilities?.pageInfo?.hasPreviousPage &&
      ((pageSize = reposPerPage) => fetchMore({ variables: { before: data?.reposWithUtilities?.pageInfo?.startCursor, last: pageSize, first: undefined } })),
    refetch,
    fetchingMore: networkStatus === NetworkStatus.fetchMore,
    firstLoading: networkStatus === NetworkStatus.loading,
  }
}

export default useReposWithUtilities
