import { Box, Typography } from '@mui/material'

const UsersErrorState = () => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
      <Typography variant="h6" sx={{ color: 'text.secondary' }}>
        Sorry, an error occurred while fetching the users.
      </Typography>
    </Box>
  )
}

export default UsersErrorState
