import { useFormContext } from 'react-hook-form'
import { FormControl, FormHelperText } from '@mui/material'
import { Input, InputLabel } from '@jeeves/new-components'

const NumNodes = ({ isMongoDBShardedCluster }) => {
  const { register, formState } = useFormContext()

  const numNodesError = formState.errors?.numNodes

  return (
    <FormControl variant="standard" error={Boolean(numNodesError)}>
      <InputLabel htmlFor="numNodes" required>
        Number of {isMongoDBShardedCluster ? <i>mongos</i> : ''} nodes
      </InputLabel>
      <Input
        type="number"
        id="numNodes"
        defaultValue={1}
        inputProps={{
          min: 1,
          ...register('numNodes', {
            setValueAs: value => (value === '' ? undefined : Number(value)),
          }),
        }}
      />
      <FormHelperText
        sx={{
          typography: 'body2',
        }}
        error={Boolean(numNodesError)}
      >
        {numNodesError?.message}
      </FormHelperText>
    </FormControl>
  )
}

export default NumNodes
