/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { useState, useEffect } from 'react'
import { IconButton, Button, Fade } from '@material-ui/core/'
import EditIcon from '@material-ui/icons/Edit'
import { ButtonLoading } from '@jeeves/components/Loading'
import { useAuth } from '@jeeves/components/Auth'
import {
  DeleteIntegrationButton,
  IntegrationCard,
  IntegrationEditActions,
  IntegrationFieldList,
  IntegrationHeader,
  IntegrationTitle,
  Label,
  LabelValuePair,
  TextField,
  Value,
} from '../../../primitives'

const AddForm = ({ integration = null, onSave, onDelete, onCancel, creating = false }) => {
  const [name, setName] = useState('')
  const [endpoint, setEndpoint] = useState('')
  const [apiKey, setApiKey] = useState('')

  const [editing, setEditing] = useState(false)
  const [saving, setSaving] = useState(false)
  const { hasPermission } = useAuth()

  const configureIntegration = () => {
    if (integration) {
      setName(integration.integration.name)
      setEndpoint(integration.integration.endpoint)
      setApiKey(integration.integration.apiKey)
    } else {
      setName('')
      setEndpoint('')
      setApiKey('')
    }
  }

  const configureEditing = () => {
    if (creating) {
      setEditing(true)
    }
  }

  useEffect(() => {
    configureIntegration()
    configureEditing()
  }, [])

  const handleCancelEditing = () => {
    setEditing(false)

    if (onCancel) {
      onCancel()
    }
  }

  const handleOnSave = async () => {
    setSaving(true)
    try {
      await onSave({
        name: name,
        endpoint: endpoint,
        apiKey: apiKey,
      })
    } catch (e) {
      console.log({ e })
    } finally {
      setSaving(false)
      setEditing(false)
    }
  }

  return (
    <IntegrationCard>
      {!editing && (
        <Fade in={!editing}>
          <div>
            <IntegrationHeader>
              <IntegrationTitle gutterBottom component="h2">
                {name}
              </IntegrationTitle>
              <div css={{ flexGrow: 1 }} />
              <IconButton
                onClick={() => setEditing(true)}
                disabled={!hasPermission('integrations:update')}
              >
                <EditIcon />
              </IconButton>
              <DeleteIntegrationButton
                onDelete={onDelete}
                disabled={!hasPermission('integrations:delete')}
              />
            </IntegrationHeader>
            <LabelValuePair>
              <Label>Endpoint</Label>
              <Value>{endpoint}</Value>
            </LabelValuePair>
            <LabelValuePair>
              <Label>API Key</Label>
              <Value>{apiKey}</Value>
            </LabelValuePair>
          </div>
        </Fade>
      )}
      {editing && (
        <Fade in={editing}>
          <div>
            <IntegrationFieldList>
              <TextField
                value={name}
                label="Integration Name"
                onChange={e => setName(e.target.value)}
                autofocus
              />
              <TextField
                value={endpoint}
                label="Endpoint"
                onChange={e => setEndpoint(e.target.value)}
              />
              <TextField value={apiKey} label="API Key" onChange={e => setApiKey(e.target.value)} />
            </IntegrationFieldList>
            <IntegrationEditActions>
              <Button onClick={handleCancelEditing} color="primary" disabled={saving}>
                Cancel
              </Button>
              <div css={theme => ({ position: 'relative', marginLeft: theme.spacing[5] })}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={saving}
                  onClick={handleOnSave}
                >
                  Save
                </Button>
                {saving && <ButtonLoading />}
              </div>
            </IntegrationEditActions>
          </div>
        </Fade>
      )}
    </IntegrationCard>
  )
}

export default AddForm
