import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Stack,
  Switch,
  Typography,
} from '@mui/material'

import { Input, InputLabel } from '@jeeves/new-components'

import { LoggingIntegrationIcon } from '@jeeves/pages/Integrations/Services/Logging/components'

import Header from '@jeeves/pages/Integrations/Services/Logging/components/NewIntegrationDialog/components/Header'
import Footer from '@jeeves/pages/Integrations/Services/Logging/components/NewIntegrationDialog/components/Footer'

import { useSplunk } from './useSplunk'
import { Controller } from 'react-hook-form'

const Splunk = ({ integration, resetIntegration, onClose }) => {
  const isEditing = Boolean(integration?.id)

  const { loading, methods, onSubmit } = useSplunk({ integration, onClose, isEditing })

  const {
    control,
    formState: { errors },
    register,
  } = methods

  return (
    <Stack spacing={4} component="form" onSubmit={onSubmit}>
      <Header
        icon={<LoggingIntegrationIcon integrationType="SplunkIntegration" />}
        title="Splunk"
        onClose={onClose}
      />

      <FormControl variant="standard" error={Boolean(errors?.name)}>
        <InputLabel htmlFor="name" required>
          Name
        </InputLabel>
        <Input
          id="name"
          inputProps={{
            ...register('name'),
          }}
        />
        <FormHelperText
          sx={{
            typography: 'body2',
          }}
          error={Boolean(errors?.name)}
        >
          {errors?.name?.message}
        </FormHelperText>
      </FormControl>

      <Box sx={{ display: 'grid', gridTemplateColumns: '2fr 1fr', gap: 2 }}>
        <FormControl variant="standard" error={Boolean(errors?.hostname)}>
          <InputLabel htmlFor="hostname" required>
            Host
          </InputLabel>
          <Input
            id="hostname"
            inputProps={{
              ...register('hostname'),
            }}
          />
          <FormHelperText
            sx={{
              typography: 'body2',
            }}
            error={Boolean(errors?.hostname)}
          >
            {errors?.hostname?.message}
          </FormHelperText>
        </FormControl>

        <FormControl variant="standard" error={Boolean(errors?.hecPort)}>
          <InputLabel htmlFor="hecPort" required>
            Port
          </InputLabel>
          <Input
            id="hecPort"
            inputProps={{
              ...register('hecPort'),
            }}
          />
          <FormHelperText
            sx={{
              typography: 'body2',
            }}
            error={Boolean(errors?.hecPort)}
          >
            {errors?.hecPort?.message}
          </FormHelperText>
        </FormControl>
      </Box>

      <FormControl variant="standard" error={Boolean(errors?.accessToken)}>
        <InputLabel htmlFor="accessToken" required>
          Token
        </InputLabel>
        <Input
          id="accessToken"
          inputProps={{
            ...register('accessToken'),
          }}
        />
        <FormHelperText
          sx={{
            typography: 'body2',
          }}
          error={Boolean(errors?.accessToken)}
        >
          {errors?.accessToken?.message}
        </FormHelperText>
      </FormControl>

      <FormControl variant="standard" error={Boolean(errors?.index)}>
        <InputLabel htmlFor="index">Index</InputLabel>
        <Input
          id="index"
          inputProps={{
            ...register('index'),
          }}
        />
        <FormHelperText
          sx={{
            typography: 'body2',
          }}
          error={Boolean(errors?.index)}
        >
          {errors?.index?.message}
        </FormHelperText>
      </FormControl>

      <Controller
        name="useTLS"
        control={control}
        render={({ field: { value, ...field } }) => (
          <FormControlLabel
            control={<Switch color="primary" {...field} checked={value} />}
            label={
              <Typography variant="h4" sx={{ color: 'text.primary' }}>
                TLS
              </Typography>
            }
          />
        )}
      />

      <Footer
        loading={loading}
        isEditing={isEditing}
        resetIntegration={resetIntegration}
        onClose={onClose}
      />
    </Stack>
  )
}

export default Splunk
