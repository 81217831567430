import { useState, useCallback } from 'react'

import { useRepositoryDetailContext } from '@jeeves/pages/RepositoryDetail/contexts/RepositoryDetailContext'
import { policyIdentities } from '@jeeves/pages/RepositoryDetail/Tabs/Policies/helpers/policyIdentities'

import { useCreatePolicy, useUpdatePolicy } from '../hooks'

const useUserSegmentation = ({ policy, template }) => {
  const { repoId } = useRepositoryDetailContext()

  const [snackBarOpen, setSnackBarOpen] = useState(false)
  const [activeStep, setActiveStep] = useState(0)

  const [userSegmentationInfo, setUserSegmentationInfo] = useState({
    id: policy?.id ?? '',
    name: policy?.name ?? '',
    description: policy?.description ?? '',
    tags: policy?.tags ?? template.tags,
    template: policy?.template ?? template,
    parameters: {
      dataLabels: policy?.parameters?.dataLabels ?? [],
      scope: policy?.parameters?.scope ?? {
        identities: [],
      },
      dataset: policy?.parameters?.dataset ?? '',
      restriction: policy?.parameters?.restriction ?? '',
    },
  })

  const isEditing = Boolean(policy?.id)

  const [createUserSegmentationPolicy, { loading: createLoading, error: createError }] =
    useCreatePolicy({
      onError: _ => {
        setSnackBarOpen(true)
      },
    })

  const [updateUserSegmentationPolicy, { loading: updateLoading, error: updateError }] =
    useUpdatePolicy({
      onError: _ => {
        setSnackBarOpen(true)
      },
    })

  const prevStep = () => setActiveStep(currStep => currStep - 1)
  const nextStep = useCallback(() => {
    setActiveStep(currStep => currStep + 1)
  }, [])

  const handleCloseSnackbar = () => {
    setSnackBarOpen(false)
  }

  const transformScope = scope => {
    const scopeType = 'databaseAccounts' in scope ? 'databaseAccounts' : 'identities'

    if (scopeType === 'databaseAccounts') {
      return {
        scope: {
          databaseAccounts: scope.databaseAccounts,
        },
      }
    }

    if (scopeType === 'identities') {
      const transformedIdentities = scope.identities.map(identity => {
        return {
          type: policyIdentities[identity.__typename],
          name: identity.name,
        }
      })

      return {
        scope: {
          identities: transformedIdentities,
        },
      }
    }
  }

  const handlePolicySubmit = async () => {
    const shapedScope = transformScope(userSegmentationInfo.parameters.scope)

    const input = {
      userSegmentation: {
        name: userSegmentationInfo.name,
        description: userSegmentationInfo.description,
        tags: userSegmentationInfo.tags,
        dataLabels: userSegmentationInfo.parameters.dataLabels,
        ...shapedScope,
        dataset: userSegmentationInfo.parameters.dataset,
        restriction: userSegmentationInfo.parameters.restriction,
      },
    }

    try {
      if (isEditing) {
        const policyId = policy.id
        return await updateUserSegmentationPolicy({
          variables: {
            policyId,
            input,
          },
        })
      }

      return await createUserSegmentationPolicy({
        variables: {
          repoId,
          input,
        },
      })
    } catch (e) {
      console.error(e)
    }
  }

  return {
    userSegmentationInfo,
    activeStep,
    snackBarOpen,
    loading: createLoading || updateLoading,
    errorMessage: createError?.message || updateError?.message,
    setUserSegmentationInfo,
    prevStep,
    nextStep,
    handlePolicySubmit,
    handleCloseSnackbar,
  }
}

export default useUserSegmentation
