import { Typography } from '@mui/material'

import { usePolicySummary } from '@jeeves/pages/RepositoryDetail/Tabs/Policies/components/PolicySummary/PolicySummaryContext'

import { PolicyScopeModifier } from '@jeeves/graphql/graphql'

import {
  Variation1,
  Variation2,
  Variation3,
  Variation4,
  Variation5,
  Variation6,
  Variation7,
  Variation8,
  Variation9,
} from './components/Variations'

const Error = (
  <Typography variant="h6" sx={{ color: 'text.secondary' }}>
    An error occurred rendering this policy
  </Typography>
)

const readLimitVariationsMap = {
  ALL: {
    ALL: <Variation1 />,
    ONLY: <Variation2 />,
    ALL_EXCEPT: <Variation3 />,
  },
  ONLY: {
    ALL: <Variation4 />,
    ONLY: <Variation5 />,
    ALL_EXCEPT: <Variation6 />,
  },
  ALL_EXCEPT: {
    ALL: <Variation7 />,
    ONLY: <Variation8 />,
    ALL_EXCEPT: <Variation9 />,
  },
}

const ReadLimit = () => {
  const { policy } = usePolicySummary()

  const { identitiesAppliedTo, databaseAccountsAppliedTo } = policy.parameters

  const identitiesModifier: PolicyScopeModifier = identitiesAppliedTo.modifier
  const databaseAccountsModifier: PolicyScopeModifier = databaseAccountsAppliedTo.modifier

  return readLimitVariationsMap[identitiesModifier][databaseAccountsModifier] ?? Error
}

export default ReadLimit
