import { DialogContent, DialogTitle, Stack, Typography, IconButton } from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'
import { useMutation } from '@apollo/client'
import {
  Button,
  Dialog,
  DialogActions,
  InlineCode,
  LearnMore,
  useToast,
} from '@jeeves/new-components'
import { getGraphQLErrorMessage } from '@jeeves/utils/helpers'
import { FragmentType, graphql, useFragment } from '@jeeves/graphql'

const UpgradeInstanceModal_SidecarFragment = graphql(`
  fragment UpgradeInstanceModal_SidecarFragment on Sidecar {
    id
    version
  }
`)

const UPGRADE_SIDECAR_INSTANCE = graphql(`
  mutation UpgradeSidecarInstance($sidecarId: ID!, $instanceId: ID!) {
    upgradeSidecarInstance(sidecarId: $sidecarId, instanceId: $instanceId) {
      instance {
        id
        version
        health
        isRecycling
      }
    }
  }
`)

interface Props {
  sidecar: FragmentType<typeof UpgradeInstanceModal_SidecarFragment>
  selectedInstanceToUpgrade: string
  setSelectedInstanceToUpgrade: React.Dispatch<React.SetStateAction<string>>
}

const UpgradeInstanceModal = ({
  sidecar: sidecarProp,
  selectedInstanceToUpgrade,
  setSelectedInstanceToUpgrade,
}: Props) => {
  const { toast } = useToast()
  const sidecar = useFragment(UpgradeInstanceModal_SidecarFragment, sidecarProp)
  const open = !!selectedInstanceToUpgrade

  const [upgradeSidecarInstance, { loading: loadingUpgrade }] = useMutation(
    UPGRADE_SIDECAR_INSTANCE,
    {
      variables: {
        sidecarId: sidecar.id,
        instanceId: selectedInstanceToUpgrade,
      },
      onCompleted: () => {
        toast({
          variant: 'success',
          description: (
            <Typography variant="body2" sx={{ color: 'text.primary' }}>
              Upgrade started for sidecar instance:{' '}
              <InlineCode>{selectedInstanceToUpgrade}</InlineCode>. The upgraded instance will have
              a new ID.
            </Typography>
          ),
        })
        setSelectedInstanceToUpgrade('')
      },
      onError: error => {
        toast({
          variant: 'error',
          description:
            getGraphQLErrorMessage(error) ||
            'An error occurred while upgrading instance. Please try again later.',
        })
      },
    }
  )

  const closeModal = () => {
    setSelectedInstanceToUpgrade('')
  }

  return (
    <Dialog open={open} onClose={closeModal} fullWidth>
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h3" sx={{ color: 'text.primary' }}>
          Upgrade Sidecar Instance
        </Typography>
        <IconButton aria-label="close" onClick={closeModal}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Are you sure you want to upgrade this sidecar instance?
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            This sidecar instance will be upgraded to version:&nbsp;
            <InlineCode>{sidecar.version}</InlineCode>
          </Typography>
          <Typography variant="body2" sx={{ color: 'error.main' }}>
            NOTE: This sidecar will be unreachable until the upgrade is complete.{' '}
            <LearnMore docsPath="" useVersionedDocsURL />
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2} sx={{ justifyContent: 'flex-end' }}>
          <Button variant="text" color="secondary" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            loading={loadingUpgrade}
            onClick={() => upgradeSidecarInstance()}
          >
            Upgrade
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}

export default UpgradeInstanceModal
