import React, { forwardRef } from 'react'
import MuiFormLabel from '@mui/material/FormLabel'

import { styled } from '@mui/material/styles'

const FormLabel = forwardRef((props, ref) => (
  <MuiFormLabel ref={ref} {...props} color="secondary" />
)) as typeof MuiFormLabel

const StyledFormLabel = styled(
  FormLabel,
  {}
)(({ theme }) => ({
  ...theme.typography.h6,

  '.MuiFormLabel-asterisk': {
    color: theme.palette.error.main,
  },

  '&.Mui-focused': {
    color: theme.palette.primary.main,
  },

  '&.Mui-error': {
    color: theme.palette.error.main,
  },
})) as typeof MuiFormLabel

export default StyledFormLabel
