import { Stack, Typography } from '@mui/material'
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'

import { Button } from '@jeeves/new-components'

import { ReactComponent as SlackLogo } from './SlackLogo.svg'
import useSlackIntegration from './useSlackIntegration'

const SetupWizardStep2 = () => {
  const { loading, slackConnected, postSlackInvite } = useSlackIntegration()

  return (
    <Stack spacing={2} sx={{ alignItems: 'center', marginTop: 'auto' }}>
      <SlackLogo />

      <Typography variant="h2" sx={{ color: 'text.primary' }}>
        Join us on Slack
      </Typography>

      <Typography
        sx={{ color: 'text.primary', fontSize: '1.125rem', fontWeight: 400, lineHeight: '28.8px' }}
      >
        Cyral engineers are available on it.
      </Typography>

      {slackConnected ? (
        <Stack spacing={1} direction="row" sx={{ alignItems: 'center' }}>
          <CheckCircleOutlineRoundedIcon sx={{ color: 'cyralColors.green.300' }} />

          <Typography
            sx={{
              color: 'cyralColors.green.300',
              fontSize: '1.125rem',
              fontWeight: 600,
              lineHeight: '28.8px',
            }}
          >
            Invite Sent! Please check your email.
          </Typography>
        </Stack>
      ) : (
        <>
          <Button
            loading={loading}
            onClick={postSlackInvite}
            sx={{
              backgroundColor: 'cyralColors.primary.100',
              border: 1,
              color: 'primary.main',
              '&:hover': {
                backgroundColor: 'cyralColors.primary.100',
                color: 'primary.main',
              },
            }}
          >
            Join Now
          </Button>

          <Typography
            sx={{
              color: 'text.primary',
              fontSize: '1.125rem',
              fontWeight: 400,
              lineHeight: '28.8px',
            }}
          >
            (You can do this later.)
          </Typography>
        </>
      )}
    </Stack>
  )
}

export default SetupWizardStep2
