import axios from 'axios'
import urljoin from 'url-join'

const CLIENT_REQUEST_TIMEOUT = parseInt(window._env_.client_request_timeout, 10) || 20000

class ExpressClient {
  constructor(getTokenSilently) {
    const APPLICATION_JSON_MIME_TYPE = 'application/json'

    const cancelToken = axios.CancelToken
    this.source = cancelToken.source()

    this.instance = axios.create({
      baseURL: window.location.origin,
      timeout: CLIENT_REQUEST_TIMEOUT,
      cancelToken: this.source.token,
      headers: {
        common: { Accept: APPLICATION_JSON_MIME_TYPE },
        post: { 'Content-Type': APPLICATION_JSON_MIME_TYPE },
        put: { 'Content-Type': APPLICATION_JSON_MIME_TYPE },
      },
    })
    this.getTokenSilently = getTokenSilently
    // this.instance.defaults.headers['Content-Type'] = APPLICATION_JSON_MIME_TYPE
  }

  cancel() {
    // Cancel/abort any existing requests to Cyral
    this.source.cancel('Request canceled')
  }

  async insertToken(config) {
    const _config = { ...config }
    const token = await this.getTokenSilently().catch(error => {
      console.log(
        'Error from getTokenSilently in ExpressClient.insertToken: ',
        JSON.stringify(error)
      )
      throw error
    })
    if (_config.headers) {
      _config.headers.Authorization = `Bearer ${token}`
    } else {
      _config.headers = { Authorization: `Bearer ${token}` }
    }
    return _config
  }

  async request(config, useToken = true) {
    const newConfig = useToken ? await this.insertToken(config) : config
    return this.instance.request(newConfig)
  }

  async get(url, config = {}, useToken = true) {
    const newConfig = useToken ? await this.insertToken(config) : config
    return this.instance.get(url, newConfig)
  }

  async put(url, data = {}, config = {}, useToken = true) {
    const newConfig = useToken ? await this.insertToken(config) : config
    return this.instance.put(url, data, newConfig)
  }

  async post(url, data = {}, config = {}, useToken = true) {
    const newConfig = useToken ? await this.insertToken(config) : config
    return this.instance.post(url, data, newConfig)
  }

  async patch(url, data = {}, config = {}, useToken = true) {
    const newConfig = useToken ? await this.insertToken(config) : config
    return this.instance.patch(url, data, newConfig)
  }

  async delete(url, config = {}, useToken = true) {
    const newConfig = useToken ? await this.insertToken(config) : config
    return this.instance.delete(url, newConfig)
  }

  async getRepos() {
    const endpoint = '/repos'
    let repos
    try {
      repos = await this.get(endpoint).then(res => res.data)
      //   repos = await this.instance.get(endpoint).then(res => res.data)
    } catch (error) {
      console.log('getRepos error: ', error)
      throw error
    }
    return repos
  }

  async putRepo(repoId, repoObj) {
    const endpoint = urljoin('/repos', repoId)
    let repos
    try {
      repos = await this.put(endpoint, repoObj).then(res => res.data)
    } catch (error) {
      console.log('putRepo error:', error)
      throw error
    }
    return repos
  }

  async patchRepo(repoId, repoStatus) {
    const endpoint = urljoin('/repos', repoId)
    let repos
    const data = { toggle: !repoStatus }
    try {
      repos = await this.patch(endpoint, data).then(res => res.data)
    } catch (error) {
      console.log('patchRepo error:', error)
      throw error
    }
    return repos
  }

  async postRepos(repoObj) {
    const endpoint = '/repos'
    let repos
    try {
      repos = await this.post(endpoint, repoObj).then(res => res.data)
    } catch (error) {
      console.log('postRepos error: ', error)
      throw error
    }
    return repos
  }

  async deleteRepo(repoId) {
    const endpoint = urljoin('/repos', repoId)
    let repos
    try {
      repos = await this.delete(endpoint).then(res => res.data)
    } catch (error) {
      console.log('deleteRepo error: ', error)
      throw error
    }
    return repos
  }

  async getWrappers() {
    const endpoint = '/wrappers-api/wrappers'
    let response
    try {
      response = await this.get(endpoint).then(res => res.data)
    } catch (error) {
      console.log('getWrappers error: ', error)
      throw error
    }
    return response
  }

  async patchWrapper(wrapperId, anyRepoEnabled) {
    const endpoint = urljoin('/wrappers-api/wrappers', wrapperId)
    let response
    const data = { wrapperStatus: anyRepoEnabled }
    try {
      response = await this.patch(endpoint, data).then(res => res.data)
    } catch (error) {
      console.log('getWrappers error: ', error)
      throw error
    }
    return response
  }

  async patchWrapperRepoBinding(wrapperId, repoId, enabled) {
    const endpoint = urljoin('/wrappers-api/wrappers', wrapperId, '/repos/', repoId)
    const data = { enabled: enabled }
    let response
    try {
      response = this.patch(endpoint, data).then(res => res.data)
    } catch (error) {
      console.log('putWrapperRepoBinding() error: ', error)
      throw error
    }
    return response
  }

  async putWrapperRepoBinding(wrapperId, binding) {
    const endpoint = urljoin('/wrappers-api/wrappers', wrapperId)
    let response
    try {
      response = this.put(endpoint, binding).then(res => res.data)
    } catch (error) {
      console.log('putWrapperRepoBinding() error: ', error)
      throw error
    }
    return response
  }

  async deleteWrapperRepoBinding(wrapperId, repoId) {
    const endpoint = urljoin('/wrappers-api/wrappers', wrapperId, '/repos/', repoId)

    let response
    try {
      response = this.delete(endpoint).then(res => res.data)
    } catch (error) {
      console.log('putWrapperRepoBinding() error: ', error)
      throw error
    }
    return response
  }

  async getGrants(repoId) {
    const endpoint = urljoin('/grants-api/grants', repoId)
    let response
    try {
      response = await this.get(endpoint).then(res => res.data)
    } catch (error) {
      console.log('getGrants error() error: ', error)
      throw error
    }
    return response
  }

  async postGrants(repoId, grant) {
    const endpoint = urljoin('/grants-api/grants', repoId)
    let response
    try {
      response = await this.post(endpoint, grant).then(res => res.data)
    } catch (error) {
      console.log('putGrants error() error: ', error)
      throw error
    }
    return response
  }

  async patchGrantsRepoAuth(repoId, requireGrant) {
    const endpoint = urljoin('/grants-api/grants', repoId, 'auth')
    let response
    const data = { requireGrant }
    try {
      response = await this.patch(endpoint, data).then(res => res.data)
    } catch (error) {
      console.log('putGrants error() error: ', error)
      throw error
    }
    return response
  }

  async deleteGrant(repoId, user) {
    const endpoint = urljoin('/grants-api/grants', repoId)
    const params = { user: user }
    let response
    try {
      response = await this.delete(endpoint, { params }).then(res => res.data)
    } catch (error) {
      console.log('putGrants error() error: ', error)
      throw error
    }
    return response
  }

  async postPolicy(userName, meta, policyBody) {
    const endpoint = '/policies'
    let response

    const policyObj = { author: userName, inputs: meta, body: policyBody }

    try {
      response = await this.post(endpoint, policyObj).then(res => res.data)
    } catch (error) {
      console.log('createPolicy error: ', error.response.data)
      throw error
    }
    return response
  }

  async postPolicyBuilder(userName, policyBody) {
    const endpoint = '/policies'
    let response
    const policyObj = { author: userName, policy: policyBody }
    const params = { format: 'builder' }

    try {
      response = await this.post(endpoint, policyObj, { params: params }).then(res => res.data)
    } catch (error) {
      console.log('createPolicy error: ', error.response.data)
      throw error
    }
    return response
  }

  async getPolicies() {
    const endpoint = '/policies'
    let response
    try {
      response = this.get(endpoint).then(res => res.data)
    } catch (error) {
      console.log('getPolicies error: ', error)
      throw error
    }
    return response
  }

  async putPolicy(userName, policyName, policyBody) {
    const endpoint = urljoin('/policies', policyName)
    const policyObj = { author: userName, body: policyBody }
    let response
    try {
      response = await this.put(endpoint, policyObj).then(res => res.data)
    } catch (error) {
      console.log('createPolicy error: ', error.response.data)
      throw error
    }
    return response
  }

  async patchPolicy(policyName, action, userName, patchObj) {
    const endpoint = urljoin('/policies', policyName)
    let response, params, data
    if (action === 'enabled') {
      data = { author: userName, enabled: patchObj.enabled }
      params = { action: 'enabled' }
    } else if (action === 'rename') {
      data = { author: userName, newName: patchObj.newName }
      params = { action: 'rename' }
    }

    try {
      response = await this.patch(endpoint, data, { params: params }).then(res => res.data)
    } catch (error) {
      console.log('createPolicy error: ', error.response.data)
      throw error
    }
    return response
  }

  async patchUser(values) {
    const endpoint = urljoin('/users', values.id)
    let response
    try {
      response = await this.put(endpoint, values).then(res => res.data)
    } catch (error) {
      console.log('createPolicy error: ', error.response.data)
      throw error
    }
    return response
  }

  async postUser(user) {
    const endpoint = '/users'
    let response
    try {
      response = await this.post(endpoint, user).then(res => res.data)
    } catch (error) {
      console.log('createPolicy error: ', error.response.data)
      throw error
    }
    return response
  }

  async getUsers() {
    const endpoint = '/users'
    let response
    try {
      response = await this.get(endpoint).then(res => res.data)
    } catch (error) {
      console.log('createPolicy error: ', error.response.data)
      throw error
    }
    return response
  }

  async getRoles() {
    const endpoint = '/users/roles'
    let response
    try {
      response = await this.get(endpoint).then(res => res.data)
    } catch (error) {
      console.log('createPolicy error: ', error.response.data)
      throw error
    }
    return response
  }

  async deleteUser(user) {
    const endpoint = urljoin('/users', user.id)
    let response
    try {
      response = await this.delete(endpoint).then(res => res.data)
    } catch (error) {
      console.log('createPolicy error: ', error.response.data)
      throw error
    }
    return response
  }

  async getSlackbotURL() {
    try {
      const endpoint = '/slackbotURL'
      return await this.get(endpoint).then(res => res.data)
    } catch (error) {
      throw error
    }
  }

  async changePlanRequest(user, license) {
    const contactName = user.given_name
    const currentPlan = license.userFriendlyName
    const companyName = license.customer.name
    const customerEmail = user.email

    const data = { contactName, customerEmail, companyName, currentPlan }

    // console.log('tenantName: ', tenantName)
    // console.log('user: ', user)
    // console.log('license: ', license)
    // console.log('data: ', data)
    try {
      // return 'ok'
      return await this.post('/integrations/changePlanRequest', data).then(res => res.data)
    } catch (e) {
      throw e
    }
  }
}

export default ExpressClient
