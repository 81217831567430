/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import React from 'react'
import { IconButton, Tooltip } from '@material-ui/core/'
import EditIcon from '@material-ui/icons/Edit'
import { useAuth } from '@jeeves/components/Auth'
import { FlexGrow } from '@jeeves/components/Primitives'

import {
  DeleteIntegrationButton,
  IntegrationHeader,
  IntegrationTitle,
  Label,
  LabelValuePair,
  Value,
} from '../../../primitives'

export default ({ setEditing, onDelete, integration }) => {
  const { hasPermission } = useAuth()

  return (
    <React.Fragment>
      <IntegrationHeader>
        <IntegrationTitle gutterBottom component="h2">
          {integration?.displayName ?? ''}
        </IntegrationTitle>
        <FlexGrow />

        <IconButton
          onClick={() => setEditing(true)}
          disabled={!hasPermission('integrations:update')}
        >
          <EditIcon />
        </IconButton>
        <DeleteIntegrationButton
          onDelete={onDelete}
          disabled={!hasPermission('integrations:delete')}
        />
      </IntegrationHeader>

      <LabelValuePair>
        <Label>Single Sign-On Endpoint</Label>
        <Value>{integration?.singleSignOnServiceURL ?? ''}</Value>
      </LabelValuePair>
    </React.Fragment>
  )
}
