import * as React from 'react'
import { parseISO, isAfter } from 'date-fns'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'

import { Button, InlineCode, Tag } from '@jeeves/new-components'

import RevokeDialog from '../RevokeDialog'
import GrantedApprovalDates from './GrantedApprovalDates'

const getTags = approval => {
  const approvalType = approval.__typename
  const tags = []

  if (approvalType === 'PendingApproval') {
    const tag = {
      text: 'New Approval Request',
      color: 'yellow',
    }

    tags.push(tag)
  }

  if (approvalType === 'RejectedApproval') {
    const tag = {
      text: 'Rejected',
      color: 'red',
    }

    tags.push(tag)
  }

  if (approvalType === 'GrantedApproval') {
    if (approval.pendingAmendment) {
      const tag = {
        text: 'Pending Modification Request',
        color: 'yellow',
      }

      tags.push(tag)
    }

    if (isAfter(parseISO(approval.effectiveStartDate), new Date())) {
      const tag = {
        text: 'Inactive',
        color: 'grey',
      }

      tags.push(tag)
    }
  }

  if (approvalType === 'RevokedApproval') {
    const tag = {
      text: 'Revoked',
      color: 'grey',
    }

    tags.push(tag)
  }

  return tags
}

const ApprovalDetailHeader = ({ approval }) => {
  const [revokeDialogOpen, setRevokeDialogOpen] = React.useState(false)

  const approvalType = approval.__typename
  const ssoUser = approval.requester.name
  const databaseAccount = approval.userAccount.name
  const tags = getTags(approval)

  const isGrantedApproval = approvalType === 'GrantedApproval'

  return (
    <React.Fragment>
      <Stack spacing={2}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
            <Typography variant="h3" sx={{ color: 'text.primary' }}>
              {ssoUser}
            </Typography>

            {tags.map(tag => {
              return (
                <Tag key={tag.text} color={tag.color}>
                  {tag.text}
                </Tag>
              )
            })}
          </Stack>

          {isGrantedApproval && (
            <Button variant="outlined" color="error" onClick={() => setRevokeDialogOpen(true)}>
              Revoke
            </Button>
          )}
        </Box>

        <Stack spacing={2} direction="row" divider={<Divider orientation="vertical" flexItem />}>
          <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
            <Typography variant="h6" sx={{ color: 'text.secondary' }}>
              Database Account:
            </Typography>

            <InlineCode>{databaseAccount}</InlineCode>
          </Stack>

          {isGrantedApproval && <GrantedApprovalDates approval={approval} />}
        </Stack>
      </Stack>

      <RevokeDialog
        open={revokeDialogOpen}
        closeDialog={() => setRevokeDialogOpen(false)}
        ssoUser={ssoUser}
        approvalId={approval.id}
      />
    </React.Fragment>
  )
}

export default ApprovalDetailHeader
