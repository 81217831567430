import { Stack } from '@mui/material'
import PolicyRule from '../PolicyRule'

import { useRepositoryDetailContext } from '@jeeves/pages/RepositoryDetail/contexts/RepositoryDetailContext'
import { usePolicySummary } from '@jeeves/pages/RepositoryDetail/Tabs/Policies/components/PolicySummary/PolicySummaryContext'

const ServiceAccountAbuse = () => {
  const { repoName } = useRepositoryDetailContext()
  const { policy } = usePolicySummary()
  const { action, serviceAccounts } = policy.parameters

  const actionMap = {
    alert: 'ALERT',
    alertAndBlock: 'ALERT and BLOCK',
  }

  const actionString = actionMap[action as keyof typeof actionMap]

  return (
    <Stack spacing={1}>
      <PolicyRule title="This policy will" content={actionString} />
      <PolicyRule title="whenever the following service accounts" content={serviceAccounts} />
      <PolicyRule title="try to read, update, or delete data from" content={repoName} />
      <PolicyRule title="without end user attribution." content="" />
    </Stack>
  )
}

export default ServiceAccountAbuse
