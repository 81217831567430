import * as React from 'react'
import Stack from '@mui/material/Stack'

import { DataLabelCard } from './DataLabelCard/DataLabelCard'

export const PredefinedLabels = ({ dataLabels }) => {
  return (
    <Stack spacing={3}>
      {dataLabels.map(dataLabel => (
        <DataLabelCard key={dataLabel.name} dataLabel={dataLabel} />
      ))}
    </Stack>
  )
}
