import { Box, Stack, FormControlLabel, Radio, Typography } from '@mui/material'

import { InlineCode } from '@jeeves/new-components'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

const MaskingOption = ({ value, label, info, replacement }) => {
  return (
    <Stack spacing={1}>
      <FormControlLabel
        value={value}
        control={<Radio size="small" disableRipple />}
        label={label}
        slotProps={{
          typography: {
            variant: 'h6',
            sx: {
              color: 'text.primary',
            },
          },
        }}
      />
      <Box sx={{ paddingLeft: 3 }}>
        <Stack direction="row" sx={{ alignItems: 'center' }} spacing={1}>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            e.g.
          </Typography>
          <InlineCode>{info}</InlineCode>
          <ArrowForwardIcon
            size="small"
            sx={{ fontSize: 'inherit', color: 'cyralColors.grey.300' }}
          />
          <InlineCode>{replacement}</InlineCode>
        </Stack>
      </Box>
    </Stack>
  )
}

export default MaskingOption
