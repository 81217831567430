/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { useAuth } from '../../components/Auth'
import { useEffect } from 'react'
import ExpressClient from '../../clients/express'

const Logout = ({ ...props }) => {
  const { authClient, getTokenSilently } = useAuth()
  const ec = new ExpressClient(getTokenSilently)

  useEffect(async () => {
    const data = {
      client_id: authClient.clientId,
      refresh_token: authClient.refreshToken,
    }

    try {
      await ec.post(`/logout`, data).then(res => res.data)
    } catch (error) {
      console.error(error)
    }
    window.location.href = '../'
  }, [])

  return <div></div>
}

export default Logout
