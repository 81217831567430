import React from 'react'
import IntegrationForm from '../IntegrationForm'

const Existing = ({ integration, putIntegration, deleteIntegration }) => {
  const handleDelete = () => {
    deleteIntegration(integration.id)
  }

  const handleSave = payload => {
    return putIntegration(payload, integration.id)
  }

  return <IntegrationForm integration={integration} onSave={handleSave} onDelete={handleDelete} />
}

export default Existing
