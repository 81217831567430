import { useFormContext } from 'react-hook-form'
import { FormControl, FormHelperText } from '@mui/material'
import { Input, InputLabel } from '@jeeves/new-components'

const NameInput = () => {
  const {
    formState: { errors },
    register,
  } = useFormContext()

  return (
    <FormControl variant="standard" error={Boolean(errors?.name)}>
      <InputLabel htmlFor="name" required>
        Name
      </InputLabel>
      <Input
        id="name"
        inputProps={{
          ...register('name'),
        }}
      />
      <FormHelperText
        sx={{
          typography: 'body2',
        }}
      >
        {errors?.name?.message}
      </FormHelperText>
    </FormControl>
  )
}

export default NameInput
