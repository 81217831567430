import React, { Fragment } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import IconAccountBox from '@material-ui/icons/AccountBox'
import IconDone from '@material-ui/icons/Done'

const styles = theme => ({
  DialogTitle: {
    padding: 25,
  },
  DialogContent: {
    padding: 25,
    paddingTop: 0,
  },
  DialogContentText: {
    color: '#757575',
  },
  DialogActions: {
    padding: 25,
    paddingTop: 0,
  },
  List: {
    backgroundColor: theme.palette.background.primary,
    padding: 20,
    marginTop: 24,
    '& *': {
      fontSize: 12,
    },
  },
  ListItem: {
    margin: 0,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 0,
    paddingRight: 0,
    '& *': {
      paddingRight: 0,
      marginRight: 0,
      color: '#757575',
    },
    '& svg': {
      color: '#e0e0e0',
    },
  },
  ListItemTitle: {
    margin: 0,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 0,
    paddingRight: 0,
    '& svg': {
      color: '#000000',
    },
    '& *': {
      paddingRight: 0,
      marginRight: 0,
      color: '#212121',
    },
  },
  ListItemActive: {
    margin: 0,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 0,
    paddingRight: 0,
    '& svg': {
      color: '#354da0',
    },
    '& *': {
      paddingRight: 0,
      marginRight: 0,
      color: '#354da0',
    },
  },
})

class AddEmail extends React.Component {
  state = {
    open: false,
    permition: '',
    name: 'hai',
  }
  handleClickOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  componentDidMount() {
    this.setState({
      labelWidth: ReactDOM.findDOMNode(this.InputLabelRef),
    })
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  render() {
    const { classes } = this.props
    return (
      <Fragment>
        <Button size={'large'} variant="contained" color="primary" onClick={this.handleClickOpen}>
          Add email
        </Button>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title" className={classes.DialogTitle}>
            Add email
          </DialogTitle>
          <DialogContent className={classes.DialogContent}>
            <DialogContentText>Please add information about new email.</DialogContentText>
            <TextField margin="dense" id="email" label="Email address" type="email" fullWidth />
            <FormControl margin="dense" fullWidth>
              <InputLabel htmlFor="permition-edit">Role</InputLabel>
              <Select
                value={this.state.permition}
                onChange={this.handleChange}
                name="permition"
                inputProps={{
                  id: 'permition-edit',
                }}
              >
                <MenuItem value="None" />
                <MenuItem value={'admin'}>Admin</MenuItem>
                <MenuItem value={'primary-admin'}>Primary Admin</MenuItem>
                <MenuItem value={'user'}>User</MenuItem>
              </Select>
              <Grid container>
                <Grid item xs={6}>
                  <List className={classes.List}>
                    <ListItem className={classes.ListItemTitle}>
                      <ListItemIcon>
                        <IconAccountBox />
                      </ListItemIcon>
                      <ListItemText primary="Permissions details:" />
                    </ListItem>
                    <ListItem className={classes.ListItem}>
                      <ListItemIcon>
                        <IconDone />
                      </ListItemIcon>
                      <ListItemText primary="Diagnose the service" />
                    </ListItem>
                    <ListItem className={classes.ListItem}>
                      <ListItemIcon>
                        <IconDone />
                      </ListItemIcon>
                      <ListItemText primary="View documentation" />
                    </ListItem>
                    <ListItem className={classes.ListItem}>
                      <ListItemIcon>
                        <IconDone />
                      </ListItemIcon>
                      <ListItemText primary="Engage with support" />
                    </ListItem>
                    <ListItem className={classes.ListItem}>
                      <ListItemIcon>
                        <IconDone />
                      </ListItemIcon>
                      <ListItemText primary="Configure the service" />
                    </ListItem>
                    <ListItem className={classes.ListItem}>
                      <ListItemIcon>
                        <IconDone />
                      </ListItemIcon>
                      <ListItemText primary="User management (on all Users)" />
                    </ListItem>
                    <ListItem className={classes.ListItem}>
                      <ListItemIcon>
                        <IconDone />
                      </ListItemIcon>
                      <ListItemText primary="Handle Billing and Subscription" />
                    </ListItem>
                    <ListItem className={classes.ListItem}>
                      <ListItemIcon>
                        <IconDone />
                      </ListItemIcon>
                      <ListItemText primary="User management (on all Admins)" />
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </FormControl>
          </DialogContent>
          <DialogActions className={classes.DialogActions}>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={this.handleClose}>
              Add Email
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    )
  }
}

AddEmail.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(AddEmail)
