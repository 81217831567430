/** @jsxRuntime classic */
/** @jsx jsx */
import { useState, useEffect, useRef, Fragment } from 'react'
import { useHistory, useParams, useLocation, Link } from 'react-router-dom'
import { jsx, css } from '@emotion/react'
import { useAuth, useValidateAccessToken } from '@jeeves/hooks'
import {
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableSortLabel,
  TableCell as MuiTableCell,
  Toolbar,
  Stack,
  InputAdornment,
  Select,
  MenuItem,
  Checkbox,
  Pagination,
  Popover,
  Autocomplete,
  TextField as MuiTextField,
  Divider,
  Backdrop,
  Box,
  CircularProgress,
} from '@mui/material'
import { LoadingButton as MuiButton } from '@mui/lab'
// import { TableHead } from '@jeeves/components/Table'
import { styled } from '@mui/material/styles'
import { PlainLayout } from '@jeeves/components/Dashboard'
import Card from './components/Card'
import Button from '@jeeves/new-components/Button'
import Input, { InputProps } from '@jeeves/new-components/Input'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import SearchIcon from '@mui/icons-material/Search'
import FilterListIcon from '@mui/icons-material/FilterList'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined'
import UploadIcon from '@mui/icons-material/Upload'
import CheckIcon from '@mui/icons-material/Check'
import Filter1Icon from '@mui/icons-material/Filter1'
import Filter2Icon from '@mui/icons-material/Filter2'
import Filter3Icon from '@mui/icons-material/Filter3'
import S3 from 'aws-sdk/clients/s3'
import Loading from '@jeeves/components/Loading'
import RowSpan from '@jeeves/components/RowSpan'
import { useDropzone } from 'react-dropzone'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

import prettyBytes from 'pretty-bytes'
import bytes from 'bytes'

import moment from 'moment'
import moment_timezone from 'moment-timezone'

import usePopup from '@jeeves/components/PopupMessage/hooks/usePopup'

import { InfoSnackbar } from '@jeeves/pages/RepositoryDetail/Tabs/RepositoryAccounts'

import { RoleSelect, ReadOnlyRoleDisplay } from './components/Role'
import ErrorDialog from './components/ErrorDialog'
import DeletionDialog from './components/DeletionDialog'

// Note: not enough time to change this, but for upload, the uploaded files are called chosen files
// and the ones with a checked box are called selected files.

const getTimestamp = dateObject =>
  dateObject
    ? `${moment(dateObject).format('MMM DD, YYYY HH:mm:ss')} ${moment_timezone()
        .tz(moment.tz.guess())
        .format('z')}`
    : ''

function CircularProgressWithLabel(props) {
  return (
    <Box
      sx={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      {/* This first one is for the background */}
      {props.value && Math.round(props.value) === 100 ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'primary.main',
            borderRadius: '50%',
            height: '36px',
            width: '36px',
          }}
        >
          <CheckIcon sx={{ color: 'white' }} />
        </Box>
      ) : (
        <Fragment>
          <CircularProgress
            variant="determinate"
            sx={{
              color: 'cyralColors.grey.100',
            }}
            value={100}
          />
          <CircularProgress
            disableShrink
            variant="determinate"
            {...props}
            sx={{ position: 'absolute', left: '25%' }}
          />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant="h6" component="div" color="primary.main">
              {`${props.value ? Math.round(props.value) : 0}%`}
            </Typography>
          </Box>
        </Fragment>
      )}
    </Box>
  )
}

const TextField = styled(MuiTextField)(({ theme, size = 'medium' }) => ({
  borderRadius: theme.radii.base,
  overflow: 'hidden',

  transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow'], {
    duration: theme.transitions.duration.shorter,
  }),

  '&.Mui-focused': {
    boxShadow: `${theme.palette.primary.main} 0 0 0 1px`,
    borderColor: theme.palette.primary.main,
  },

  // 'label + &': {
  //   marginTop: theme.spacing(3),
  // },
  '& .MuiInputBase-input': {
    position: 'relative',
    backgroundColor: theme.palette.common.white,

    ...(size === 'medium' && {
      ...theme.typography.body2,
      height: '20px',
      padding: '7px 12px !important',
    }),
    '&.MuiInputBase-inputAdornedEnd': {
      paddingRight: theme.spacing(0),
    },
  },
  '& .MuiOutlinedInput-root': {
    padding: 0,
  },
}))

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

const TableCell = styled(
  MuiTableCell,
  {}
)(({ theme }) => ({
  height: '58px',
}))

const S3Browser = ({
  data,
  accessPortalInfoLoading,
  getNewAccessToken = () => {},
}) => {
  const history = useHistory()
  const location = useLocation()
  const { path } = useParams()
  const { user, getTokenSilently } = useAuth()

  const { token, repoEdgesUserCanAccess } = data
  const { isValid: isTokenValid } = useValidateAccessToken()
  const [sidecarEndpoint, setSidecarEndpoint] = useState('')
  const [roles, setRoles] = useState([])
  const [notSetup, setNotSetup] = useState(false)
  const [roleName, setRoleName] = useState('')
  const [rows, setRows] = useState([])
  const [partialRows, setPartialRows] = useState([])
  const [currentRows, setCurrentRows] = useState([])
  const [displayRows, setDisplayRows] = useState([])
  const [isBucketsView, setIsBucketsView] = useState(true)
  const [bucketName, setBucketName] = useState('')
  const [folderPathArray, setFolderPathArray] = useState([])
  const [selectedObjectPathsSet, setSelectedObjectPathsSet] = useState(new Set())
  const [loading, setLoading] = useState(
    new URLSearchParams(window.location.search).get('upload') !== 'true'
  )
  const [downloadLoading, setDownloadLoading] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [uploadLoading, setUploadLoading] = useState(false)
  const [uploadSuccessful, setUploadSuccessful] = useState(false)
  const [isInUpload, setIsInUpload] = useState(
    new URLSearchParams(window.location.search).get('upload') === 'true'
  )
  const actualChooseFileButtonRef = useRef()
  const actualChooseFolderButtonRef = useRef()
  const [chosenFiles, setChosenFiles] = useState([])
  const [uploadProgressObject, setUploadProgressObject] = useState({})
  const [totalUploadSize, setTotalUploadSize] = useState(0)
  const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone()

  const [isTruncated, setIsTruncated] = useState(false)
  const [resultsAreTruncated, setResultsAreTruncated] = useState(false)

  const [pageSize, setPageSize] = useState(10)
  const [page, setPage] = useState(1)
  const [orderBy, setOrderBy] = useState('')
  const [order, setOrder] = useState('')

  const [nameSearchString, setNameSearchString] = useState('')

  const [fileTypeFilters, setFileTypeFilters] = useState(new Set())

  const [fileSizeFilterDirection, setFileSizeFilterDirection] = useState(1)
  const [fileSizeFilterNumber, setFileSizeFilterNumber] = useState('')
  const [fileSizeFilterUnit, setFileSizeFilterUnit] = useState('bytes')

  const [dateFilterStart, setDateFilterStart] = useState('')
  const [dateFilterEnd, setDateFilterEnd] = useState('')
  const [dateFilterError, setDateFilterError] = useState(false)

  const numFilters = [
    fileTypeFilters.size > 0,
    fileSizeFilterNumber !== '',
    (Boolean(dateFilterStart) || Boolean(dateFilterEnd)) && !dateFilterError,
  ].filter(c => c).length

  const [filterPopoverAnchorEl, setFilterPopoverAnchorEl] = useState(null)
  const filterPopoverOpen = Boolean(filterPopoverAnchorEl)

  const [accessDeniedDialogOpen, setAccessDeniedDialogOpen] = useState(false)
  const [errorText, setErrorText] = useState('')
  const [shouldGoBack, setShouldGoBack] = useState(false)

  const [deleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] = useState(false)

  const { setPopup, popupTypes } = usePopup()

  const getCurrentValidToken = async () => {
    const tokenIsValid = await isTokenValid(token)
    if (tokenIsValid) {
      return token
    }
    return (await getNewAccessToken()).token
  }

  const getRoleFromName = roleName => roles?.find(role => role.name === roleName)

  const getFilePath = file => {
    let fileSubPath =
      file.path !== undefined
        ? file.path
        : file.webkitRelativePath
        ? file.webkitRelativePath
        : file.name !== undefined
        ? file.name
        : ''
    if (fileSubPath.startsWith('/')) {
      fileSubPath = fileSubPath.slice(1)
    }
    return `${folderPathArray.join('/')}${folderPathArray.length !== 0 ? '/' : ''}${fileSubPath}`
  }

  const handleFilterButtonClick = event => {
    setFilterPopoverAnchorEl(event.currentTarget)
  }

  const handleFilterPopoverClose = () => {
    setFilterPopoverAnchorEl(null)
  }

  const handleRoleNameChange = e => {
    setRoleName(e.target.value)
  }

  const handleSelectObject = (object, checkboxEvent, isFile) => {
    if (checkboxEvent) {
      checkboxEvent.stopPropagation()
      checkboxEvent.persist()
    }
    if (
      (checkboxEvent && checkboxEvent.target.checked) ||
      (!checkboxEvent && !selectedObjectPathsSet.has(isFile ? getFilePath(object) : object.path))
    ) {
      setSelectedObjectPathsSet(
        prevSelectedObjectPathsSet =>
          new Set(prevSelectedObjectPathsSet.add(isFile ? getFilePath(object) : object.path))
      )
    } else {
      setSelectedObjectPathsSet(
        prevSelectedObjectPathsSet =>
          new Set(
            [...prevSelectedObjectPathsSet].filter(
              objectPath => objectPath !== (isFile ? getFilePath(object) : object.path)
            )
          )
      )
    }
  }

  const handleSelectAll = e => {
    if (e.target.checked) {
      setSelectedObjectPathsSet(
        new Set(
          isInUpload
            ? chosenFiles.map(file => getFilePath(file))
            : rows.filter(row => !row.isFolder).map(row => row.path)
        )
      )
    } else {
      setSelectedObjectPathsSet(new Set())
    }
  }

  const clearFilters = () => {
    setFileTypeFilters(new Set())
    setFileSizeFilterDirection(1)
    setFileSizeFilterNumber('')
    setFileSizeFilterUnit('bytes')
    setDateFilterStart(null)
    setDateFilterEnd(null)
    setDateFilterError(false)
  }

  const initializeBrowser = path => {
    setRows([])
    setChosenFiles([])
    setUploadProgressObject({})
    setIsTruncated(false)
    setResultsAreTruncated(false)
    setTotalUploadSize(0)
    setUploadSuccessful(false)
    setAccessDeniedDialogOpen(false)
    setShouldGoBack(false)
    setDeleteConfirmationDialogOpen(false)
    setOrder('')
    setOrderBy('')
    setPage(1)
    clearFilters()
    const pathArray = path ? path.split('/') : []
    setIsBucketsView(pathArray.length < 1)
    setBucketName(path ? pathArray[0] : '')
    setFolderPathArray(pathArray.slice(1))
    setSelectedObjectPathsSet(new Set())
    if (!isInUpload && token) {
      if (pathArray.length < 1) {
        getBuckets()
      } else {
        // getObjects(
        //   pathArray[0],
        //   pathArray.length < 2 ? undefined : `${path.replace(`${pathArray[0]}/`, '')}/`,
        //   true
        // )
        getObjects(
          pathArray[0],
          pathArray.length < 2 ? undefined : `${path.replace(`${pathArray[0]}/`, '')}/`
        )
      }
    }
  }

  const setReadableErrorText = error => {
    // default generic error message
    let userFacingMessage =
      'Something went wrong. Please try again or contact your admin for further assistance.'
    if (error.code === 'AuthenticationFailure') {
      // invalid AWS S3 credentials attached to the sidecar (or other identity-related issues)
      // -> use default generic error message
      console.log(error.message)
    } else if (error.code === 'AccessDenied') {
      // AWS Policy Violation (No cyral policy in use, but the AWS IAM role does not allow upload)
      userFacingMessage = `The IAM role you are using ${roleName} is not allowed to perform this action. 
        Please try using another role, or contact your admin for further assistance.`
    } else if (error.code === 'Forbidden') {
      // Cyral Policy Violation (AWS IAM role allows upload but Cyral policies don't)
      // -> use sidecar provided error message
      userFacingMessage = error.message
    }
    setErrorText(userFacingMessage)
  }

  const getBuckets = async () => {
    setLoading(true)
    try {
      const tokenToUse = await getCurrentValidToken()
      const data = await new S3({
        // region: 'us-east-1',
        accessKeyId: `${user.email}:${tokenToUse}${roleName ? `:${roleName}` : ''}`,
        secretAccessKey: 'none',
        endpoint: sidecarEndpoint,
      })
        .listBuckets()
        .promise()
      setRows(data.Buckets.map(bucket => ({ name: bucket.Name, created: bucket.CreationDate })))
      setLoading(false)
    } catch (e) {
      setReadableErrorText(e)
      setAccessDeniedDialogOpen(true)
      setShouldGoBack(false)
    }
  }

  const getObjects = async (
    bucketName,
    folderPath,
    options = { prefix: '', getPartial: false }
  ) => {
    setLoading(true)
    try {
      const tokenToUse = await getCurrentValidToken()
      const objects = await new S3({
        // region: 'us-east-1',
        s3ForcePathStyle: true,
        s3BucketEndpoint: true,
        accessKeyId: `${user.email}:${tokenToUse}${roleName ? `:${roleName}` : ''}`,
        secretAccessKey: 'none',
        endpoint: sidecarEndpoint,
      })
        .listObjectsV2({
          Bucket: bucketName,
          Delimiter: '/',
          ...(folderPath ? { Prefix: `${folderPath}${options.prefix}` } : {}),
          ...(options.getPartial
            ? {
                MaxKeys: 1000,
              }
            : {}),
        })
        .promise()
      if (!options.prefix) {
        setIsTruncated(objects.IsTruncated)
      } else {
        setResultsAreTruncated(objects.IsTruncated)
      }
      const transformedObjects = [
        ...objects.CommonPrefixes.map(prefix => ({
          isFolder: true,
          bucketName,
          type: 'Folder',
          name: prefix.Prefix.replace(folderPath, '').replace('/', ''),
          path: prefix.Prefix,
        })),
        ...objects.Contents.map(object => ({
          bucketName,
          name: object.Key.replace(folderPath, ''),
          path: object.Key,
          type: object.Key.replace(folderPath, '').includes('.')
            ? object.Key.split('.')[object.Key.split('.').length - 1]
            : '-',
          lastModified: object.LastModified,
          size: object.Size,
          storageClass: object.StorageClass,
        })),
      ]
      if (options.getPartial) {
        setPartialRows(transformedObjects)
      } else {
        setRows(transformedObjects)
      }
    } catch (e) {
      setReadableErrorText(e)
      setAccessDeniedDialogOpen(true)
      setShouldGoBack(true)
    }
    setLoading(false)
  }

  const downloadObject = async (bucketName, objectPath) => {
    const tokenToUse = await getCurrentValidToken()
    const s3 = new S3({
      // region: 'us-east-1',
      s3ForcePathStyle: true,
      s3BucketEndpoint: true,
      accessKeyId: `${user.email}:${tokenToUse}${roleName ? `:${roleName}` : ''}`,
      secretAccessKey: 'none',
      endpoint: sidecarEndpoint,
    })
    const url = s3.getSignedUrl('getObject', {
      Bucket: bucketName,
      Key: objectPath,
    })
    // const a = document.createElement('a')
    // document.body.appendChild(a)
    // a.href = url
    // a.download = objectPath.split('/')[objectPath.split('/').length - 1]
    // a.id = objectPath.split('/')[objectPath.split('/').length - 1]
    // return a
    // a.click()
    window.open(url, '_blank')
  }

  const downloadSelectedObjects = async () => {
    setDownloadLoading(true)
    try {
      Array.from(selectedObjectPathsSet).forEach(path => downloadObject(bucketName, path))
    } catch (e) {
      setReadableErrorText(e)
      setAccessDeniedDialogOpen(true)
      setShouldGoBack(false)
    }
    setDownloadLoading(false)
  }

  const deleteObject = async (bucketName, objectPath) => {
    const tokenToUse = await getCurrentValidToken()
    const data = await new S3({
      // region: 'us-east-1',
      s3ForcePathStyle: true,
      s3BucketEndpoint: true,
      accessKeyId: `${user.email}:${tokenToUse}${roleName ? `:${roleName}` : ''}`,
      secretAccessKey: 'none',
      endpoint: sidecarEndpoint,
    })
      .deleteObject({
        Bucket: bucketName,
        Key: objectPath,
      })
      .promise()
  }

  const deleteSelectedObjects = async () => {
    await Promise.all(
      [...selectedObjectPathsSet].map(async path => await deleteObject(bucketName, path))
    )
  }

  const handleDeleteConfirmationDialogButtonClick = async () => {
    try {
      setDeleteLoading(true)
      await deleteSelectedObjects()
      setDeleteLoading(false)
      initializeBrowser(path)
    } catch (e) {
      setReadableErrorText(e)
      setAccessDeniedDialogOpen(true)
      setDeleteConfirmationDialogOpen(false)
      setDeleteLoading(false)
      setShouldGoBack(false)
    }
  }

  const handleUploadButtonClick = () => {
    history.push(`${location.pathname}?upload=true`)
  }

  const handleChooseFileButtonClick = () => {
    const element = actualChooseFileButtonRef.current
    element.click()
  }

  const handleChooseFileChange = e => {
    e.persist()
    setUploadSuccessful(false)
    setChosenFiles(prevChosenFiles => {
      const prevChosenFilesSet = new Set(prevChosenFiles.map(file => getFilePath(file)))
      return [
        ...prevChosenFiles,
        ...[...e.target.files].filter(file => !prevChosenFilesSet.has(getFilePath(file))),
      ]
    })
  }

  const handleChooseFolderButtonClick = () => {
    const element = actualChooseFolderButtonRef.current
    element.click()
  }

  const uploadObject = async (bucketName, objectPath, object) => {
    const tokenToUse = await getCurrentValidToken()
    const upload = new S3({
      // region: 'us-east-1',
      s3ForcePathStyle: true,
      s3BucketEndpoint: true,
      accessKeyId: `${user.email}:${tokenToUse}${roleName ? `:${roleName}` : ''}`,
      secretAccessKey: 'none',
      endpoint: sidecarEndpoint,
    }).upload({
      Bucket: bucketName,
      Key: objectPath,
      Body: object,
    })
    upload.send()
    return upload
  }

  const uploadChosenFiles = async () => {
    setUploadLoading(true)
    try {
      await Promise.all(
        chosenFiles.map(async file => {
          const response = await uploadObject(bucketName, getFilePath(file), file)
          response.on('httpUploadProgress', progress => {
            const progressPercentage = Math.round((progress.loaded / progress.total) * 100)
            setUploadProgressObject(prevObject => ({
              ...prevObject,
              [getFilePath(file)]: progressPercentage,
            }))
          })
          await response.promise()
        })
      )
      setUploadSuccessful(true)
      setPopup(popupTypes.SUCCESS, 'Upload successful.')
      setChosenFiles([])
      setSelectedObjectPathsSet(new Set())
    } catch (e) {
      setReadableErrorText(e)
      setAccessDeniedDialogOpen(true)
      setShouldGoBack(false)
    }
    setUploadLoading(false)
  }

  const removeSelectedFiles = () => {
    setChosenFiles(prevChosenFiles =>
      prevChosenFiles.filter(file => !selectedObjectPathsSet.has(getFilePath(file)))
    )
    setSelectedObjectPathsSet(new Set())
  }

  const handlePageChange = (e, value) => {
    setPage(value)
  }

  const createSortHandler = property => event => {
    handleRequestSort(event, property)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const filterByName = row => {
    if (!nameSearchString || nameSearchString.length === 0) {
      return true
    }

    return row.name.toLowerCase().includes(nameSearchString.toLowerCase())
  }

  const filterByFileType = row => {
    if (fileTypeFilters.size > 0) {
      return fileTypeFilters.has(row.type)
    } else {
      return true
    }
  }

  const filterByFileSize = row => {
    if (fileSizeFilterNumber !== '') {
      return fileSizeFilterDirection < 0
        ? row.size <= bytes(`${fileSizeFilterNumber}${fileSizeFilterUnit}`)
        : row.size >= bytes(`${fileSizeFilterNumber}${fileSizeFilterUnit}`)
    } else {
      return true
    }
  }

  const filterByDate = row => {
    if (dateFilterError) {
      return true
    }
    if (Boolean(dateFilterStart) || Boolean(dateFilterEnd)) {
      return (
        (Boolean(dateFilterStart)
          ? (isBucketsView ? row.created : row.lastModified) > dateFilterStart
          : true) &&
        (Boolean(dateFilterEnd)
          ? (isBucketsView ? row.created : row.lastModified) < dateFilterEnd
          : true)
      )
    } else {
      return true
    }
  }

  useEffect(() => {
    if (token && sidecarEndpoint && roleName) {
      initializeBrowser(path)
    }
  }, [path, location, isInUpload, token, roleName, sidecarEndpoint])

  useEffect(() => {
    setIsInUpload(new URLSearchParams(window.location.search).get('upload') === 'true')
  }, [path, location])

  useEffect(() => {
    setUploadSuccessful(false)
    setChosenFiles(prevChosenFiles => {
      const prevChosenFilesSet = new Set(prevChosenFiles.map(file => getFilePath(file)))
      return [
        ...prevChosenFiles,
        ...acceptedFiles.filter(file => !prevChosenFilesSet.has(getFilePath(file))),
      ]
    })
  }, [acceptedFiles])

  useEffect(() => {
    setTotalUploadSize(
      chosenFiles.map(file => file.size).reduce((prevTotal, curSize) => prevTotal + curSize, 0)
    )
  }, [chosenFiles])

  useEffect(() => {
    let rowsToFilter = rows.length === 0 ? (partialRows.length === 0 ? [] : partialRows) : rows
    setCurrentRows(rowsToFilter)
    setDisplayRows(
      rowsToFilter
        .filter(filterByName)
        .filter(filterByFileType)
        .filter(filterByFileSize)
        .filter(filterByDate)
    )
  }, [
    rows,
    partialRows,
    nameSearchString,
    fileTypeFilters,
    fileSizeFilterDirection,
    fileSizeFilterNumber,
    fileSizeFilterUnit,
    dateFilterStart,
    dateFilterEnd,
    dateFilterError,
    isBucketsView,
  ])

  useEffect(() => {
    if (!isBucketsView && isTruncated) {
      const pathArray = path ? path.split('/') : []
      getObjects(
        pathArray[0],
        pathArray.length < 2 ? undefined : `${path.replace(`${pathArray[0]}/`, '')}/`,
        { prefix: nameSearchString }
      )
    }
  }, [nameSearchString, isTruncated, isBucketsView])

  useEffect(() => {
    setDateFilterError(
      Boolean(dateFilterStart) && Boolean(dateFilterEnd) && dateFilterEnd < dateFilterStart
    )
  }, [dateFilterStart, dateFilterEnd])

  useEffect(() => {
    if (roles.length > 0 && !roleName) {
      setRoleName(roles?.[0]?.name)
    }
  }, [roles, roleName])

  useEffect(() => {
    if (!accessPortalInfoLoading) {
      const testS3RepoEdge = repoEdgesUserCanAccess?.find(edge => edge?.node?.type === 's3')
      if (testS3RepoEdge) {
        const accessPortalBindingEdge = testS3RepoEdge.accessPortalBindingRelationship?.edge
        if (!accessPortalBindingEdge?.node?.listenerSet?.browserListener) {
          setNotSetup(true)
        } else {
          setSidecarEndpoint(
            `https://${
              accessPortalBindingEdge?.sidecar?.userEndpoint ||
              accessPortalBindingEdge?.sidecar?.endpoint
            }:${accessPortalBindingEdge?.node?.listenerSet?.browserListener?.port}`
          )
          setRoles(testS3RepoEdge?.accessibleUserAccounts?.edges?.map(edge => edge.node))
        }
      } else if (repoEdgesUserCanAccess) {
        setNotSetup(true)
      }
    }
  }, [accessPortalInfoLoading, repoEdgesUserCanAccess])

  return (
    <div {...getRootProps({ className: 'dropzone' })}>
      <PlainLayout>
        {notSetup ? (
          <Typography>
            The S3 browser has not been configured properly. Please contact your admin for further
            assistance.
          </Typography>
        ) : (
          <Fragment>
            <Backdrop
              sx={{
                backgroundColor: 'rgba(38, 69, 158, 0.8)',
                color: 'white',
                zIndex: theme => theme.zIndex.drawer + 1,
                display: 'flex',
                alignItems: 'center',
                minHeight: '100vh',
              }}
              css={{ pointerEvents: 'none' }}
              open={isDragActive}
            >
              <Stack
                spacing={2}
                alignItems="center"
                css={{ textAlign: 'center', width: '360px', margin: 'auto' }}
              >
                <UploadIcon fontSize="large" />
                <Typography variant="h3">Upload files and folders by dropping them here</Typography>
              </Stack>
            </Backdrop>

            <ErrorDialog
              accessDeniedDialogOpen={accessDeniedDialogOpen}
              setAccessDeniedDialogOpen={setAccessDeniedDialogOpen}
              errorText={errorText}
            />

            <DeletionDialog
              setDeleteConfirmationDialogOpen={setDeleteConfirmationDialogOpen}
              deleteConfirmationDialogOpen={deleteConfirmationDialogOpen}
              selectedObjectPathsSet={selectedObjectPathsSet}
              deleteLoading={deleteLoading}
              handleDeleteConfirmationDialogButtonClick={handleDeleteConfirmationDialogButtonClick}
            />

            <Card>
              <Card.Header sx={{ paddingLeft: '82px', paddingRight: '82px' }}>
                <Typography variant="h2" sx={{ color: 'text.primary' }}>
                  {isInUpload
                    ? 'Upload'
                    : isBucketsView
                    ? 'Buckets'
                    : folderPathArray.length === 0
                    ? bucketName
                    : folderPathArray[folderPathArray.length - 1]}
                </Typography>

                {roleName ? (
                  roles.length > 1 ? (
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Typography variant="h6" sx={{ color: 'text.secondary' }}>
                        Role
                      </Typography>
                      <RoleSelect
                        roles={roles}
                        role={getRoleFromName(roleName)}
                        handleRoleNameChange={handleRoleNameChange}
                      />
                    </Stack>
                  ) : (
                    <ReadOnlyRoleDisplay role={getRoleFromName(roleName)} />
                  )
                ) : null}
              </Card.Header>
              {!isInUpload && (
                <Fragment>
                  {isTruncated && (
                    <Toolbar sx={{ padding: '12px 82px 0px 82px !important' }}>
                      {' '}
                      <InfoSnackbar
                        css={{ width: '100%' }}
                        text="There are more than 1000 objects in this folder. Filtering and sorting will operate on the first 1000 objects. To show more relevant results, use the prefix search to repopulate the list with objects that match that prefix."
                      ></InfoSnackbar>
                    </Toolbar>
                  )}
                  <Toolbar
                    sx={{
                      justifyContent: 'space-between',
                      paddingLeft: '82px !important',
                      paddingRight: '82px !important',
                    }}
                  >
                    <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
                      <Input
                        startAdornment={
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        }
                        placeholder={isTruncated ? 'Search by prefix' : 'Search by name'}
                        value={nameSearchString}
                        onChange={e => setNameSearchString(e.target.value)}
                      />
                      <MuiButton
                        variant="outlined"
                        color="secondary"
                        startIcon={<FilterListIcon />}
                        onClick={handleFilterButtonClick}
                        sx={{ color: 'secondary.dark' }}
                      >
                        <span>Filter</span>
                        {numFilters > 0 && <span css={{ marginLeft: '8px' }}>{numFilters}</span>}
                      </MuiButton>
                      <Popover
                        open={filterPopoverOpen}
                        anchorEl={filterPopoverAnchorEl}
                        onClose={handleFilterPopoverClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                      >
                        <Stack
                          spacing={2}
                          sx={{
                            padding: '24px',
                            width: '560px',
                          }}
                        >
                          {!isBucketsView && (
                            <Fragment>
                              <Stack spacing={1}>
                                <Typography variant="h6" sx={{ color: 'cyralColors.grey.400' }}>
                                  File Type
                                </Typography>
                                <Autocomplete
                                  multiple
                                  options={[...new Set(currentRows.map(row => row.type))]}
                                  disableCloseOnSelect
                                  getOptionLabel={option => option}
                                  value={[...fileTypeFilters]}
                                  onChange={(event, newValue) => {
                                    setFileTypeFilters(new Set(newValue))
                                  }}
                                  renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                      <Checkbox
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                        size="small"
                                      />
                                      <Typography sx={{ fontSize: '0.875rem' }}>
                                        {option}
                                      </Typography>
                                    </li>
                                  )}
                                  renderInput={params => (
                                    <TextField {...params} placeholder="Select file types..." />
                                  )}
                                />
                              </Stack>
                              <Stack spacing={1}>
                                <Typography variant="h6" sx={{ color: 'cyralColors.grey.400' }}>
                                  File Size
                                </Typography>
                                <Stack direction="row" spacing={1} alignItems="center">
                                  <Select
                                    css={{ flexGrow: 1 }}
                                    input={<Input />}
                                    value={fileSizeFilterDirection}
                                    onChange={e => setFileSizeFilterDirection(e.target.value)}
                                  >
                                    <MenuItem value={1}>greater than</MenuItem>
                                    <MenuItem value={-1}>less than</MenuItem>
                                  </Select>
                                  <Input
                                    type="number"
                                    css={{ flexGrow: 1 }}
                                    value={fileSizeFilterNumber}
                                    onChange={e => setFileSizeFilterNumber(e.target.value)}
                                  />
                                  <Select
                                    css={{ flexGrow: 1 }}
                                    input={<Input />}
                                    value={fileSizeFilterUnit}
                                    onChange={e => setFileSizeFilterUnit(e.target.value)}
                                  >
                                    <MenuItem value={'bytes'}>bytes</MenuItem>
                                    <MenuItem value={'kB'}>kB</MenuItem>
                                    <MenuItem value={'MB'}>MB</MenuItem>
                                    <MenuItem value={'GB'}>GB</MenuItem>
                                  </Select>
                                </Stack>
                              </Stack>
                            </Fragment>
                          )}
                          <Stack spacing={1}>
                            <Typography variant="h6" sx={{ color: 'cyralColors.grey.400' }}>
                              {isBucketsView ? 'Created Date' : 'Last Modified'}
                            </Typography>
                            <Typography sx={{ fontSize: '0.875rem' }}>{`Show all ${
                              isBucketsView ? 'buckets created' : 'files last modified'
                            } between:`}</Typography>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <Stack direction="row" spacing={1} alignItems="center">
                                <DatePicker
                                  value={dateFilterStart}
                                  onChange={newValue => setDateFilterStart(newValue)}
                                  renderInput={({ inputRef, inputProps, InputProps }) => (
                                    <Input
                                      error={dateFilterError}
                                      css={{ paddingRight: '14px', flexGrow: 1 }}
                                      ref={inputRef}
                                      {...inputProps}
                                      endAdornment={InputProps?.endAdornment}
                                    />
                                  )}
                                />
                                <Typography sx={{ fontSize: '0.875rem' }}>and</Typography>
                                <DatePicker
                                  value={dateFilterEnd}
                                  onChange={newValue => setDateFilterEnd(newValue)}
                                  renderInput={({ inputRef, inputProps, InputProps }) => (
                                    <Input
                                      error={dateFilterError}
                                      css={{ paddingRight: '14px', flexGrow: 1 }}
                                      ref={inputRef}
                                      {...inputProps}
                                      endAdornment={InputProps?.endAdornment}
                                    />
                                  )}
                                />
                              </Stack>
                            </LocalizationProvider>
                          </Stack>
                          <Divider />
                          <Stack direction="row" justifyContent="space-between">
                            <MuiButton onClick={clearFilters}>Reset all filters</MuiButton>
                            <Button onClick={handleFilterPopoverClose}>Done</Button>
                          </Stack>
                        </Stack>
                      </Popover>
                      {isBucketsView ? (
                        <Typography>{`${displayRows?.length || 0} buckets`}</Typography>
                      ) : (
                        !isInUpload && (
                          <Fragment>
                            <Stack direction="row" spacing={1}>
                              <Typography>{`${
                                displayRows?.filter(row => row.isFolder)?.length || 0
                              }${
                                (!nameSearchString && isTruncated) ||
                                (nameSearchString && resultsAreTruncated)
                                  ? '+'
                                  : ''
                              } folders`}</Typography>
                              <FolderOpenOutlinedIcon />
                            </Stack>
                            <Stack direction="row" spacing={1}>
                              <Typography>{`${
                                displayRows?.filter(row => !row.isFolder)?.length || 0
                              }${
                                (!nameSearchString && isTruncated) ||
                                (nameSearchString && resultsAreTruncated)
                                  ? `+`
                                  : ''
                              } files`}</Typography>
                              <InsertDriveFileOutlinedIcon />
                            </Stack>
                          </Fragment>
                        )
                      )}
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <MuiButton
                        variant="outlined"
                        color="error"
                        disabled={selectedObjectPathsSet.size === 0}
                        onClick={() => setDeleteConfirmationDialogOpen(true)}
                      >
                        Delete
                      </MuiButton>
                      <MuiButton
                        variant="outlined"
                        color="primary"
                        loading={downloadLoading}
                        onClick={downloadSelectedObjects}
                        disabled={selectedObjectPathsSet.size === 0}
                      >
                        Download
                      </MuiButton>
                      <Button disabled={isBucketsView} onClick={handleUploadButtonClick}>
                        Upload
                      </Button>
                    </Stack>
                  </Toolbar>
                </Fragment>
              )}
              {!isBucketsView && (
                <Toolbar
                  sx={{
                    justifyContent: 'space-between',
                    paddingLeft: '82px !important',
                    paddingRight: '82px !important',
                  }}
                >
                  <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" />}
                    aria-label="breadcrumb"
                  >
                    {[
                      ...['Buckets', bucketName, ...folderPathArray].map((folder, index) => (
                        <Link
                          key={index + 1}
                          color="inherit"
                          to={`/s3Browser${
                            folder === 'Buckets'
                              ? ''
                              : `/${[bucketName, ...folderPathArray].slice(0, index).join('/')}`
                          }`}
                          css={t => `text-decoration: none;
                                  color: ${t.palette.cyralColors.grey[400]};
                                  &:hover {
                        color: ${t.palette.primary.main};
                      }`}
                        >
                          <Typography variant="h6">{folder}</Typography>
                        </Link>
                      )),
                      ...(isInUpload ? [<Typography variant="h6">Upload</Typography>] : []),
                    ]}
                  </Breadcrumbs>
                </Toolbar>
              )}
              <div
                css={
                  isInUpload &&
                  (theme => ({ padding: `${accessPortalInfoLoading ? 0 : theme.spacing(4)} 82px` }))
                }
              >
                {isInUpload &&
                  (accessPortalInfoLoading ? (
                    <Box sx={{ height: '90px', position: 'relative' }}>
                      <Loading />
                    </Box>
                  ) : (
                    <Fragment>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'flex-end',
                          padding: '0 !important',
                          marginBottom: '18px',
                        }}
                      >
                        <Typography>{`Total upload size: ${prettyBytes(
                          totalUploadSize
                        )}`}</Typography>
                        <Stack direction="row" spacing={2}>
                          <MuiButton
                            variant="outlined"
                            color="error"
                            onClick={removeSelectedFiles}
                            disabled={selectedObjectPathsSet.size === 0}
                          >
                            Remove
                          </MuiButton>
                          <input
                            type="file"
                            id="actual-choose-file-button"
                            hidden
                            multiple
                            ref={actualChooseFileButtonRef}
                            onChange={handleChooseFileChange}
                          />
                          <Button variant="outlined" onClick={handleChooseFileButtonClick}>
                            Select File(s)
                          </Button>
                          <input
                            type="file"
                            id="actual-choose-folder-button"
                            hidden
                            webkitdirectory=""
                            directory=""
                            multiple
                            ref={actualChooseFolderButtonRef}
                            onChange={handleChooseFileChange}
                          />
                          <Button variant="outlined" onClick={handleChooseFolderButtonClick}>
                            Select Folder
                          </Button>
                        </Stack>
                      </Box>
                      {chosenFiles.length === 0 && (
                        <div
                          css={theme => ({
                            borderRadius: theme.radii.xl,
                            padding: '48px',
                            minHeight: '500px',
                            display: 'flex',
                            alignItems: 'center',
                            backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%2326459EFF' stroke-width='4' stroke-dasharray='5 15' stroke-dashoffset='90' stroke-linecap='square'/%3e%3c/svg%3e")`,
                          })}
                        >
                          <div css={{ textAlign: 'center', width: '440px', margin: 'auto' }}>
                            <UploadIcon fontSize="large" color="primary" />
                            <Typography css={theme => ({ color: theme.palette.primary.main })}>
                              Drag and drop files and folders you want to upload here, or upload
                              using <strong>Select File(s)</strong> and{' '}
                              <strong>Select Folder</strong>.
                            </Typography>
                          </div>
                        </div>
                      )}
                    </Fragment>
                  ))}
                {!(isInUpload && chosenFiles.length === 0) && (
                  <Table
                    sx={{
                      minWidth: 650,
                      ...(isInUpload
                        ? {
                            overflow: 'hidden',
                            borderCollapse: 'separate',
                            border: 1,
                            borderColor: 'cyralColors.grey.200',
                            borderRadius: 2,
                          }
                        : {}),
                    }}
                    aria-label="simple table"
                  >
                    <TableHead sx={{ backgroundColor: 'cyralColors.grey.100' }}>
                      <TableRow>
                        {isInUpload ? (
                          <Fragment>
                            <TableCell
                              component="th"
                              align="center"
                              sx={{ padding: 0, width: '82px' }}
                            >
                              <Checkbox
                                checked={
                                  selectedObjectPathsSet.size > 0 &&
                                  selectedObjectPathsSet.size === chosenFiles.length
                                }
                                indeterminate={
                                  selectedObjectPathsSet.size > 0 &&
                                  selectedObjectPathsSet.size !== chosenFiles.length
                                }
                                onClick={handleSelectAll}
                              />
                            </TableCell>
                            <TableCell
                              component="th"
                              sx={{
                                paddingLeft: 0,
                                color: 'cyralColors.grey.500',
                                fontWeight: 'bold',
                              }}
                              sortDirection={orderBy === 'name' ? order : false}
                            >
                              <TableSortLabel
                                active={orderBy === 'name'}
                                direction={orderBy === 'name' ? order : 'asc'}
                                onClick={createSortHandler('name')}
                              >
                                <Typography variant="h6">Name</Typography>
                              </TableSortLabel>
                            </TableCell>
                            <TableCell
                              align="left"
                              component="th"
                              sx={{ color: 'cyralColors.grey.500', fontWeight: 'bold' }}
                              sortDirection={orderBy === 'folder' ? order : false}
                            >
                              <TableSortLabel
                                active={orderBy === 'folder'}
                                direction={orderBy === 'folder' ? order : 'asc'}
                                onClick={createSortHandler('folder')}
                              >
                                <Typography variant="h6">Folder</Typography>
                              </TableSortLabel>
                            </TableCell>
                            <TableCell
                              align="left"
                              component="th"
                              sx={{ color: 'cyralColors.grey.500', fontWeight: 'bold' }}
                              sortDirection={orderBy === 'type' ? order : false}
                            >
                              <TableSortLabel
                                active={orderBy === 'type'}
                                direction={orderBy === 'type' ? order : 'asc'}
                                onClick={createSortHandler('type')}
                              >
                                <Typography variant="h6">Type</Typography>
                              </TableSortLabel>
                            </TableCell>
                            <TableCell
                              align="left"
                              component="th"
                              sx={{ color: 'cyralColors.grey.500', fontWeight: 'bold' }}
                              sortDirection={orderBy === 'size' ? order : false}
                            >
                              <TableSortLabel
                                active={orderBy === 'size'}
                                direction={orderBy === 'size' ? order : 'asc'}
                                onClick={createSortHandler('size')}
                              >
                                <Typography variant="h6">Size</Typography>
                              </TableSortLabel>
                            </TableCell>
                          </Fragment>
                        ) : isBucketsView ? (
                          <Fragment>
                            <TableCell
                              component="th"
                              align="center"
                              sx={{ padding: 0, width: '82px' }}
                            ></TableCell>
                            <TableCell
                              component="th"
                              sortDirection={orderBy === 'name' ? order : false}
                            >
                              <TableSortLabel
                                active={orderBy === 'name'}
                                direction={orderBy === 'name' ? order : 'asc'}
                                onClick={createSortHandler('name')}
                              >
                                <Typography variant="h6">Name</Typography>
                              </TableSortLabel>
                            </TableCell>
                            {/* <TableCell align="left" component="th">
                        <Typography variant="h6">AWS Region</Typography>
                      </TableCell> */}
                            <TableCell
                              align="left"
                              component="th"
                              sx={{ color: 'cyralColors.grey.500', fontWeight: 'bold' }}
                              sortDirection={orderBy === 'created' ? order : false}
                            >
                              <TableSortLabel
                                active={orderBy === 'created'}
                                direction={orderBy === 'created' ? order : 'asc'}
                                onClick={createSortHandler('created')}
                              >
                                <Typography variant="h6">Created</Typography>
                              </TableSortLabel>
                            </TableCell>
                          </Fragment>
                        ) : (
                          <Fragment>
                            <TableCell
                              component="th"
                              align="center"
                              sx={{ padding: 0, width: '82px' }}
                            >
                              <Checkbox
                                checked={
                                  selectedObjectPathsSet.size > 0 &&
                                  selectedObjectPathsSet.size ===
                                    rows.filter(row => !row.isFolder).length
                                }
                                indeterminate={
                                  selectedObjectPathsSet.size > 0 &&
                                  selectedObjectPathsSet.size !==
                                    rows.filter(row => !row.isFolder).length
                                }
                                onClick={handleSelectAll}
                              />
                            </TableCell>
                            <TableCell
                              component="th"
                              sx={{
                                paddingLeft: 0,
                                color: 'cyralColors.grey.500',
                                fontWeight: 'bold',
                              }}
                              sortDirection={orderBy === 'name' ? order : false}
                            >
                              <TableSortLabel
                                active={orderBy === 'name'}
                                direction={orderBy === 'name' ? order : 'asc'}
                                onClick={createSortHandler('name')}
                              >
                                <Typography variant="h6">Name</Typography>
                              </TableSortLabel>
                            </TableCell>
                            <TableCell
                              align="left"
                              component="th"
                              sx={{ color: 'cyralColors.grey.500', fontWeight: 'bold' }}
                              sortDirection={orderBy === 'type' ? order : false}
                            >
                              <TableSortLabel
                                active={orderBy === 'type'}
                                direction={orderBy === 'type' ? order : 'asc'}
                                onClick={createSortHandler('type')}
                              >
                                <Typography variant="h6">Type</Typography>
                              </TableSortLabel>
                            </TableCell>
                            <TableCell
                              align="left"
                              component="th"
                              sx={{ color: 'cyralColors.grey.500', fontWeight: 'bold' }}
                              sortDirection={orderBy === 'lastModified' ? order : false}
                            >
                              <TableSortLabel
                                active={orderBy === 'lastModified'}
                                direction={orderBy === 'lastModified' ? order : 'asc'}
                                onClick={createSortHandler('lastModified')}
                              >
                                <Typography variant="h6">Last Modified</Typography>
                              </TableSortLabel>
                            </TableCell>
                            <TableCell
                              align="left"
                              component="th"
                              sx={{ color: 'cyralColors.grey.500', fontWeight: 'bold' }}
                              sortDirection={orderBy === 'size' ? order : false}
                            >
                              <TableSortLabel
                                active={orderBy === 'size'}
                                direction={orderBy === 'size' ? order : 'asc'}
                                onClick={createSortHandler('size')}
                              >
                                <Typography variant="h6">Size</Typography>
                              </TableSortLabel>
                            </TableCell>
                          </Fragment>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {isInUpload ? (
                        chosenFiles.length === 0 ? (
                          <RowSpan colSpan={6}>
                            <Typography>No chosen files</Typography>
                          </RowSpan>
                        ) : (
                          stableSort(chosenFiles, getComparator(order, orderBy))
                            .slice((page - 1) * pageSize, page * pageSize)
                            .map(file => (
                              <TableRow
                                hover
                                key={file.name}
                                sx={{
                                  '&:last-child td, &:last-child th': { border: 0 },
                                  cursor: 'pointer',
                                }}
                                onClick={e => handleSelectObject(file, undefined, true)}
                              >
                                <TableCell align="center" sx={{ padding: 0 }}>
                                  {uploadLoading ? (
                                    <CircularProgressWithLabel
                                      value={uploadProgressObject[getFilePath(file)]}
                                    />
                                  ) : (
                                    <Checkbox
                                      checked={selectedObjectPathsSet.has(getFilePath(file))}
                                      onClick={e => handleSelectObject(file, e, true)}
                                    />
                                  )}
                                </TableCell>
                                <TableCell align="left" sx={{ paddingLeft: 0 }}>
                                  <Stack
                                    direction="row"
                                    spacing={2}
                                    alignItems="center"
                                    sx={{ fontWeight: 600 }}
                                  >
                                    {/* {row.isFolder ? <FolderOpenOutlinedIcon /> : <InsertDriveFileOutlinedIcon />} */}
                                    {file.name}
                                  </Stack>
                                </TableCell>
                                <TableCell align="left">
                                  {file.path
                                    ? file.path.startsWith('/')
                                      ? file.path.split('/').slice(1, -1).join('/')
                                      : ''
                                    : file.webkitRelativePath}
                                </TableCell>
                                <TableCell align="left">{file.type}</TableCell>
                                <TableCell align="left">
                                  {file.size ? prettyBytes(file.size) : ''}
                                </TableCell>
                              </TableRow>
                            ))
                        )
                      ) : loading ? (
                        <RowSpan colSpan={6}>
                          <Loading />
                        </RowSpan>
                      ) : isBucketsView ? (
                        stableSort(displayRows, getComparator(order, orderBy))
                          .slice((page - 1) * pageSize, page * pageSize)
                          .map(row => (
                            <TableRow
                              hover
                              key={row.name}
                              sx={{
                                '&:last-child td, &:last-child th': { border: 0 },
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                clearFilters()
                                setNameSearchString('')
                                history.push(`/s3Browser/${row.name}`)
                              }}
                            >
                              <TableCell sx={{ padding: 0 }}></TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                alignt="left"
                                sx={{ fontWeight: 600 }}
                              >
                                {row.name}
                              </TableCell>
                              {/* <TableCell align="left">{row.AWSRegion}</TableCell> */}
                              <TableCell align="left">{getTimestamp(row.created) || ''}</TableCell>
                            </TableRow>
                          ))
                      ) : (
                        stableSort(displayRows, getComparator(order, orderBy))
                          .slice((page - 1) * pageSize, page * pageSize)
                          .map(row => (
                            <TableRow
                              hover
                              key={row.name}
                              sx={{
                                '&:last-child td, &:last-child th': { border: 0 },
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                if (row.isFolder) {
                                  clearFilters()
                                  setNameSearchString('')
                                  history.push(
                                    `/s3Browser/${row.bucketName}/${row.path.slice(
                                      0,
                                      row.path.length - 1
                                    )}`
                                  )
                                } else {
                                  handleSelectObject(row)
                                }
                              }}
                            >
                              <TableCell align="center" sx={{ padding: 0 }}>
                                <Checkbox
                                  disabled={row.isFolder}
                                  checked={selectedObjectPathsSet.has(row.path)}
                                  onClick={e => handleSelectObject(row, e)}
                                />
                              </TableCell>
                              <TableCell align="left" sx={{ paddingLeft: 0 }}>
                                <Stack direction="row" spacing={2} alignItems="center">
                                  {row.isFolder ? (
                                    <FolderOpenOutlinedIcon />
                                  ) : (
                                    <InsertDriveFileOutlinedIcon />
                                  )}
                                  <Typography sx={{ fontSize: '0.875rem', fontWeight: 600 }}>
                                    {row.name}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell align="left">{row.type}</TableCell>
                              <TableCell align="left">{getTimestamp(row.lastModified)}</TableCell>
                              <TableCell align="left">
                                {row.size ? prettyBytes(row.size) : ''}
                              </TableCell>
                            </TableRow>
                          ))
                      )}
                    </TableBody>
                  </Table>
                )}
              </div>
              {(!isInUpload || chosenFiles.length > 0) && (
                <Toolbar
                  sx={{
                    justifyContent: 'center',
                    paddingLeft: '82px !important',
                    paddingRight: '82px !important',
                  }}
                >
                  <Pagination
                    count={Math.ceil((isInUpload ? chosenFiles : displayRows).length / pageSize)}
                    shape="rounded"
                    color="primary"
                    sx={{ justifyContent: 'center' }}
                    page={page}
                    onChange={handlePageChange}
                  />
                </Toolbar>
              )}
              {isInUpload && (
                <Toolbar
                  sx={{
                    justifyContent: 'flex-end',
                    paddingLeft: '82px !important',
                    paddingRight: '82px !important',
                  }}
                >
                  <Stack direction="row" spacing={1} alignItems="center">
                    {/* {uploadSuccessful && (
                      <CheckCircleOutlineIcon sx={{ color: 'cyralColors.green.300' }} />
                    )} */}
                    <Button
                      loading={uploadLoading}
                      disabled={chosenFiles.length === 0}
                      onClick={uploadChosenFiles}
                    >
                      Upload
                    </Button>
                  </Stack>
                </Toolbar>
              )}
            </Card>
          </Fragment>
        )}
      </PlainLayout>
    </div>
  )
}

export default S3Browser