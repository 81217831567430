import React from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import ShieldOutlinedIcon from '@mui/icons-material/ShieldOutlined'
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined'
import { Button } from '@jeeves/new-components'
import { Card, Tabs, Tab } from './NewPolicies.style'
import PolicyCard from './components/PolicyCard'

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const NewPolicies = () => {
  const [value, setValue] = React.useState(0)

  const handleChange = (_, newValue) => {
    setValue(newValue)
  }

  return (
    <Card>
      <Card.Header>
        <Stack spacing={1} direction="row" alignItems="center">
          <Typography variant="h2" sx={{ color: 'text.primary' }}>
            Policies
          </Typography>
          <InfoOutlinedIcon
            fontSize="small"
            sx={{
              color: 'cyralColors.grey.200',
              // Used to achieve optical alignment to fit Figma design
              transform: 'translateY(3.5px)',
            }}
          />
        </Stack>

        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Security" {...a11yProps(0)} />
          <Tab label="Custom" {...a11yProps(1)} />
        </Tabs>
      </Card.Header>
      <Card.Body
        sx={{
          padding: 4,
        }}
      >
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
            gap: 2,
            width: '100%',
          }}
        >
          {/* IGNORE */}
          <Stack flex="1" spacing={2}>
            <Stack
              bgcolor="cyralColors.grey.100"
              borderRadius={theme => theme.radii.xl}
              flex="1"
              padding={2}
              spacing={2}
            >
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack direction="row" spacing={1} alignItems="center">
                  {/* ICON GOES HERE */}
                  <BlockOutlinedIcon
                    sx={{
                      color: 'text.primary',
                    }}
                  />

                  {/* COLUMN HEADING GOES HERE */}
                  <Typography
                    variant="h4"
                    sx={{
                      color: 'text.primary',
                    }}
                  >
                    IGNORE
                  </Typography>
                </Stack>
                {/* <Typography
                  variant="h4"
                  sx={{
                    color: 'text.primary',
                  }}
                >
                  19
                </Typography> */}
              </Stack>

              <Stack spacing={2}>
                {/* OOTB POLICIES CARDS GO HERE */}
                <PolicyCard
                  tags={['Placeholder 1', 'Placeholder 2', 'Data Infrastructure Protection']}
                />
                {/* <PolicyCard tags={['Data Infrastructure Protection']} />
                <PolicyCard
                  tags={['Placeholder 1', 'Placeholder 2', 'Data Infrastructure Protection']}
                /> */}
              </Stack>
            </Stack>
          </Stack>

          {/* ALERT */}
          <Stack flex="1" spacing={2}>
            <Stack direction="row" spacing={1} alignItems="center" paddingLeft={2}>
              {/* ICON GOES HERE */}
              <WarningAmberOutlinedIcon
                sx={{
                  color: 'text.primary',
                }}
              />

              {/* COLUMN HEADING GOES HERE */}
              <Typography
                variant="h5"
                sx={{
                  color: 'text.primary',
                }}
              >
                ALERT
              </Typography>
            </Stack>
            <Box
              bgcolor="cyralColors.grey.100"
              borderRadius={theme => theme.radii.xl}
              flex="1"
              padding={2}
            >
              <Stack spacing={2}>
                {/* OOTB POLICIES CARDS GO HERE */}
                {/* <PolicyCard
                  tags={['Placeholder 1', 'Placeholder 2', 'Data Infrastructure Protection']}
                />
                <PolicyCard
                  tags={['Placeholder 1', 'Placeholder 2', 'Data Infrastructure Protection']}
                />
                <PolicyCard
                  tags={['Placeholder 1', 'Placeholder 2', 'Data Infrastructure Protection']}
                /> */}
              </Stack>
            </Box>
          </Stack>

          {/* BLOCK */}
          <Stack flex="1" spacing={2}>
            <Stack direction="row" spacing={1} alignItems="center" paddingLeft={2}>
              {/* ICON GOES HERE */}
              <ShieldOutlinedIcon
                sx={{
                  color: 'text.primary',
                }}
              />

              {/* COLUMN HEADING GOES HERE */}
              <Typography
                variant="h5"
                sx={{
                  color: 'text.primary',
                }}
              >
                BLOCK
              </Typography>
            </Stack>
            <Box
              bgcolor="cyralColors.grey.100"
              borderRadius={theme => theme.radii.xl}
              flex="1"
              padding={2}
            >
              <Stack spacing={2}>
                {/* OOTB POLICIES CARDS GO HERE */}
                {/* <PolicyCard
                  tags={['Placeholder 1', 'Placeholder 2', 'Data Infrastructure Protection']}
                /> */}
              </Stack>
            </Box>
          </Stack>
        </Box>
      </Card.Body>
    </Card>
  )
}

export default NewPolicies
