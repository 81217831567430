import { Box, Typography } from '@mui/material'

interface Props {
  number: number
}

const SetupWizardStep3Number = ({ number }: Props) => {
  return (
    <Box
      sx={{
        width: '24px',
        height: '24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'primary.main',
        borderRadius: '50%',
      }}
    >
      <Typography variant="h6" sx={{ color: 'white' }}>
        {number}
      </Typography>
    </Box>
  )
}

export default SetupWizardStep3Number
