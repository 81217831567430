import * as React from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import Row from './Row'
import { TableContainer } from './RulesTable.styles'

const RulesTable = ({ rules }) => {
  return (
    <TableContainer>
      <table aria-label="custom pagination table">
        <thead>
          <Box
            component="tr"
            sx={{
              '& th:first-of-type': {
                borderLeftWidth: '1px',
                borderLeftStyle: 'solid',
                borderTopLeftRadius: theme => theme.radii.base,
                borderBottomLeftRadius: theme => theme.radii.base,
              },

              '& th:last-of-type': {
                borderRightWidth: '1px',
                borderRightStyle: 'solid',
                borderTopRightRadius: theme => theme.radii.base,
                borderBottomRightRadius: theme => theme.radii.base,
              },
            }}
          >
            <Box component="th" scope="col" sx={{ whiteSpace: 'nowrap' }}>
              Rule Name
            </Box>
            <Box component="th" scope="col">
              Description
            </Box>
            <Box component="th" scope="col" sx={{ whiteSpace: 'nowrap' }}>
              Client IP
            </Box>
            <Box component="th" scope="col" sx={{ whiteSpace: 'nowrap' }}>
              Database Account
            </Box>
            <Box component="th" scope="col" />
          </Box>
        </thead>
        <tbody>
          {rules.map(rule => {
            return <Row key={rule.id} rule={rule} />
          })}
        </tbody>
      </table>
    </TableContainer>
  )
}

export default RulesTable
