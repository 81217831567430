import * as React from 'react'

import Dialog from '@mui/material/Dialog'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import Checkbox from '@mui/material/Checkbox'
import { Link } from 'react-router-dom'
import { isEqual } from 'lodash'
import { gql, useMutation } from '@apollo/client'

import {
  DialogTitle,
  DialogActions,
  DialogContent,
  ActionsDivider,
} from '@jeeves/pages/DataLabels/Custom/Dialogs/Dialogs.styles.js'
import { Button, InlineCode } from '@jeeves/new-components'
import { getDocsURL } from '@jeeves/utils/docsURL'

const UPDATE_SIEM = gql`
  mutation UpdateCyralActivityLogsSIEMIntegrationsList($ids: [ID!]!) {
    updateCyralActivityLogsSIEMIntegrationsList(ids: $ids) {
      SIEMIntegrations {
        id
        name
        ... on SIEMIntegrationWithAuditLogForwarding {
          cyralActivityLogsEnabled
        }
      }
    }
  }
`

const SIEM = ({ SIEMIntegrations, open, setOpen }) => {
  const [checkedFields, setCheckedFields] = React.useState(
    SIEMIntegrations.filter(SIEMIntegration => SIEMIntegration.cyralActivityLogsEnabled)
  )

  const [updateSIEM, { data, loading, error }] = useMutation(UPDATE_SIEM)

  const handleCheck = SIEMIntegrationToCheck => {
    if (isChecked(SIEMIntegrationToCheck)) {
      setCheckedFields(prev => prev.filter(p => p.id !== SIEMIntegrationToCheck.id))
    } else {
      setCheckedFields(prev => [...prev, SIEMIntegrationToCheck])
    }
  }

  const isChecked = SIEMIntegrationToCheck => {
    let exists = checkedFields.filter(checkedField => checkedField.id === SIEMIntegrationToCheck.id)
    return exists.length > 0
  }

  const handleClose = () => {
    setOpen(false)
    setCheckedFields(
      SIEMIntegrations.filter(SIEMIntegration => SIEMIntegration.cyralActivityLogsEnabled)
    )
  }

  const handleSave = async () => {
    const ids = checkedFields.map(checkedField => checkedField.id)
    try {
      await updateSIEM({
        variables: {
          ids,
        },
      })
      setOpen(false)
    } catch (e) {
      console.error(e)
    }
  }

  const renderContent = () => {
    if (SIEMIntegrations.length === 1) {
      return (
        <Stack direction="row" spacing={1} sx={{ alignItems: 'center', whiteSpace: 'nowrap' }}>
          <Checkbox
            onChange={() => handleCheck(SIEMIntegrations[0])}
            checked={isChecked(SIEMIntegrations[0])}
            name={SIEMIntegrations[0].name}
          />
          <Typography variant="h6">
            Send these audit logs to your SIEM (<InlineCode>{SIEMIntegrations[0].name}</InlineCode>
            ).
          </Typography>

          <Typography variant="h6">
            <a
              href={getDocsURL({ docsPath: 'reference/audit/#export-cyral-activity-logs' })}
              target="_blank"
              css={{ textDecoration: 'none', color: '#26459E', fontWeight: 600 }}
            >
              Learn More
            </a>
          </Typography>
        </Stack>
      )
    }

    return (
      <React.Fragment>
        <Stack direction="row" spacing={1}>
          <Typography variant="h6">Send these audit logs to your SIEM.</Typography>
          <Typography variant="h6">
            <a
              href={getDocsURL({ docsPath: 'reference/audit/#export-cyral-activity-logs' })}
              target="_blank"
              css={{ textDecoration: 'none', color: '#26459E', fontWeight: 600 }}
            >
              Learn More
            </a>
          </Typography>
        </Stack>

        {SIEMIntegrations.length > 0 ? (
          <Stack spacing={1}>
            <FormGroup>
              {SIEMIntegrations &&
                SIEMIntegrations.map(SIEMIntegration => (
                  <FormControlLabel
                    key={SIEMIntegration.id}
                    control={
                      <Checkbox
                        onChange={() => handleCheck(SIEMIntegration)}
                        checked={isChecked(SIEMIntegration)}
                        name={SIEMIntegration.name}
                      />
                    }
                    label={SIEMIntegration.name}
                  />
                ))}
            </FormGroup>
          </Stack>
        ) : (
          <Box
            sx={{
              position: 'relative',
              border: 2,
              borderColor: 'primary.main',
              padding: 4,
              borderRadius: theme => theme.radii.base,
            }}
          >
            <Stack spacing={2} sx={{ alignItems: 'flex-start' }}>
              <Typography variant="body1" sx={{ color: 'text.primary' }}>
                Please integrate a SIEM before enabling log forwarding.
              </Typography>

              <Button
                component={Link}
                to="/integrations"
                target="_blank"
                rel="noopener noreferrer"
                endIcon={<OpenInNewIcon />}
              >
                View Integrations
              </Button>
            </Stack>
            <Box
              sx={{
                position: 'absolute',
                bottom: 0,
                right: 0,
                py: 1,
                px: 2,
                backgroundColor: 'primary.main',
                borderTopLeftRadius: theme => theme.radii.base,
              }}
            >
              <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                <ErrorOutlineIcon fontSize="small" sx={{ color: 'white' }} />
                <Typography variant="h6" sx={{ color: 'white' }}>
                  Incomplete
                </Typography>
              </Stack>
            </Box>
          </Box>
        )}
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <Dialog open={open} fullWidth>
        <DialogTitle>Configure Log Forwarding</DialogTitle>
        <DialogContent>
          <Stack spacing={2.25}>{renderContent()}</Stack>
        </DialogContent>
        <ActionsDivider />
        <DialogActions>
          <Button variant="text" color="primary" onClick={handleClose}>
            Cancel
          </Button>

          <Button
            disabled={isEqual(
              SIEMIntegrations.filter(SIEMIntegration => SIEMIntegration.cyralActivityLogsEnabled),
              checkedFields
            )}
            type="submit"
            onClick={handleSave}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default SIEM
