/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import React, { useState, useEffect } from 'react'
import { ButtonBase, Typography } from '@material-ui/core'

import { useMount, useAuth } from '@jeeves/hooks'
import usePopup from '@jeeves/components/PopupMessage/hooks/usePopup'
import ExpressClient from '@jeeves/clients/express'
import {
  AddIntegrationButton,
  ErrorState,
  IntegrationDialogContent,
  IntegrationList,
  IntegrationLoading,
  ZeroState,
} from '../../primitives'
import logo from './images/slack.png'

const SlackBot = () => {
  const { hasPermission, getTokenSilently } = useAuth()
  const ec = new ExpressClient(getTokenSilently)
  const [slackbotURL, setSlackbotURL] = useState('')
  const { setPopup, popupTypes } = usePopup()
  const [error, setError] = useState(false)

  const getSlackbotURL = async () => await ec.getSlackbotURL()

  useEffect(() => {
    getSlackbotURL()
      .then(res => setSlackbotURL(res.url))
      .catch(e => {
        const popupMessage = 'Error retrieving slackbot authorization URL'
        setPopup(popupTypes.ERROR, popupMessage)
        setError(true)
      })
  }, [])

  return (
    <IntegrationDialogContent>
      <div css={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <div css={{ flexGrow: 1 }}>
          <Typography variant="caption" css={theme => ({ fontSize: theme.fontSize[3] })}>
            Add the Cyral Slack App to your workspace.
          </Typography>
        </div>
        <ButtonBase
          onClick={() => window.open(slackbotURL)}
          disabled={error}
          css={{ borderRadius: '6px' }}
        >
          <img
            height="40"
            width="139"
            src="https://platform.slack-edge.com/img/add_to_slack.png"
            srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
          />
        </ButtonBase>
      </div>
    </IntegrationDialogContent>
  )
}

SlackBot.metadata = {
  name: 'Slack Bot',
  summary:
    'Find repositories and sidecars, request access, and approve data access requests in Slack.',
  enabled: window._env_.auth_provider === 'keycloak',
  category: 'Chatops',
  slug: 'slackBot',
  logo,
}

export default SlackBot
