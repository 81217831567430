import { useAppConfig } from '@jeeves/hooks'

const useEntitlements = () => {
  const { license } = useAppConfig()

  const hasFeature = featureName =>
    Boolean(license?.features?.some(f => f.internalName === featureName))

  const policyMonitoring = () => {
    return hasFeature('AllowPolicyMonitoring')
  }
  const policyBlocking = () => {
    return hasFeature('AllowPolicyBlocking')
  }

  const isPageEnabled = subFeature => {
    return Boolean(
      license?.features?.some(
        feature =>
          feature.internalName === 'AllowedJeevesFeatures' &&
          feature.internalSubFeatureName === subFeature
      )
    )
  }

  return {
    policyMonitoring,
    policyBlocking,
    hasAutomaticDatamap: hasFeature('AllowDataDiscovery'),
    enableAlerts: isPageEnabled('showAlerts'),
    enableAudit: isPageEnabled('showAudit'),
    enableYAMLPolicies: isPageEnabled('showYAMLPolicies'),
    enableSettings: isPageEnabled('showSettings'),
    enableDataLabels: isPageEnabled('showDataLabels'),
    enableSupportLoginToggle: isPageEnabled('showSupportLoginToggle'),
  }
}

export default useEntitlements
