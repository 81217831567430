import React, { Fragment } from 'react'

import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'

import { useAuth } from '../../../components/Auth'

import JSONPretty from 'react-json-pretty'

const styles = theme => ({
  profile: {
    padding: '10px 30px',
  },
})

const Profile = ({ classes }) => {
  const { user } = useAuth()

  return (
    <Fragment>
      <Paper className={classes.root}>
        <Toolbar>
          <Typography variant="h6" id="profile">
            Auth0 Profile:
          </Typography>
        </Toolbar>
        <Typography className={classes.profile}>
          <JSONPretty id="json-pretty" data={user}></JSONPretty>
        </Typography>
      </Paper>
    </Fragment>
  )
}

export default withStyles(styles)(Profile)
