import { useFormContext } from 'react-hook-form'
import { FormControl, FormHelperText, Stack, Typography } from '@mui/material'
import { Input, InputLabel } from '@jeeves/new-components'

const MIN_AMOUNT_TOKENS = 1
const MAX_AMOUNT_TOKENS = 5

const MaxValidTokens = () => {
  const {
    formState: { errors },
    register,
  } = useFormContext()

  return (
    <Stack spacing={1} sx={{ width: '50%' }}>
      <FormControl variant="standard" error={Boolean(errors?.maxValidTokens)}>
        <InputLabel htmlFor="maxValidTokens" required>
          Max Valid Tokens for a User
        </InputLabel>

        <Input
          id="maxValidTokens"
          type="number"
          inputProps={{
            ...register('maxValidTokens'),
            min: MIN_AMOUNT_TOKENS,
            max: MAX_AMOUNT_TOKENS,
          }}
          fullWidth
        />

        <FormHelperText
          sx={{
            typography: 'body2',
          }}
        >
          {errors?.maxValidTokens?.message}
        </FormHelperText>
      </FormControl>

      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        The max number of concurrent valid access tokens a user can have.
      </Typography>
    </Stack>
  )
}

export default MaxValidTokens
