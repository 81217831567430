/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import React from 'react'
import { jsx, css } from '@emotion/react'
import styled from '@emotion/styled'

const Link = styled.a`
  text-decoration: none;
  color: #26459e;
  font-weight: bold;
`

const LOGGING_SUMMARY_URL = 'https://github.com/cyral-quickstart/?q=Dashboard'

const LoggingSummary = () => {
  return (
    <>
      Send logs to a platform of your choice. For examples of dashboards,{' '}
      <Link href={LOGGING_SUMMARY_URL} target="_blank" rel="noopener noreferrer">
        view the Cyral quickstart guides
      </Link>
      .
    </>
  )
}

export default LoggingSummary
