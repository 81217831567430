import * as React from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

import { Input, InputLabel } from '@jeeves/new-components'

import ConfigInstructions from './components/ConfigInstructions'

const GcpSecretManager = ({ databaseAccountInfo, handleMutateUserAccount, footer }) => {
  const methods = useForm({
    defaultValues: {
      secretName: databaseAccountInfo?.credentials?.secretName ?? '',
    },
  })

  const { register, handleSubmit, formState } = methods
  const { errors } = formState

  const onSubmit = async ({ secretName }) => {
    const userAccount = {
      ...databaseAccountInfo,
      credentials: {
        type: databaseAccountInfo.credentials.type,
        secretName,
      },
    }

    await handleMutateUserAccount(userAccount)
  }

  return (
    <FormProvider {...methods}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4}>
          {/* <ConfigInstructions /> */}

          <FormControl variant="standard" error={Boolean(errors.secretName)}>
            <InputLabel htmlFor="secretName">Secret Name</InputLabel>
            <Input
              id="secretName"
              inputProps={{ ...register('secretName', { required: 'This field is required.' }) }}
              fullWidth
            />
            <FormHelperText
              sx={{
                typography: 'body2',
              }}
              error={!!errors?.secretName}
            >
              {errors?.secretName?.message ||
                'Specify the unique identifier of the secret that holds the database account credentials.'}
            </FormHelperText>
          </FormControl>

          {footer}
        </Stack>
      </Box>
    </FormProvider>
  )
}

export default GcpSecretManager
