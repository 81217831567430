import * as React from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { useFragment_experimental, gql } from '@apollo/client'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

import { Button, Dialog, DialogActions, useToast } from '@jeeves/new-components'

import RepoDisplay from './RepoDisplay'
import SelectPort from '../../../BindDataRepo/BindDataRepoDialog/SelectPort/SelectPort'
import EnableBindingToggle from './EnableBindingToggle'

import useEditBindingDialog from './useEditBindingDialog'

import { repoSupportsPortMultiplexing } from '../../../BindDataRepo/BindDataRepoDialog/utils'
import { getGraphQLErrorMessage } from '@jeeves/utils/helpers'

const EditBindingDialog_queryFragment = gql`
  fragment EditBindingDialog_queryFragment on Query {
    ...SelectPort_query
  }
  ${SelectPort.fragments.SelectPort_queryFragment}
`

const EditBindingDialog_BindingFragment = gql`
  fragment EditBindingDialog_BindingFragment on Binding {
    id
    enabled
    repo {
      id
      type
      ...RepoDisplay_RepoFragment
    }
    ... on SingleListenerBinding {
      listener {
        id
        port
      }
    }
    ... on S3Binding {
      listenerSet {
        proxyListener {
          id
          port
        }
        browserListener {
          id
          port
        }
      }
    }
    ... on ClusterBinding {
      boundListenersRelationship {
        edges {
          node {
            id
            port
          }
        }
      }
    }
    ...EnableBindingToggle_BindingFragment
  }
  ${EnableBindingToggle.fragments.EnableBindingToggle_BindingFragment}
  ${RepoDisplay.fragments.RepoDisplay_RepoFragment}
`

const EditBindingDialog = ({ open, onClose, query, binding }) => {
  const { data } = useFragment_experimental({
    fragment: EditBindingDialog_queryFragment,
    fragmentName: 'EditBindingDialog_queryFragment',
    from: query,
  })

  const { data: bindingData } = useFragment_experimental({
    fragment: EditBindingDialog_BindingFragment,
    fragmentName: 'EditBindingDialog_BindingFragment',
    from: binding,
  })

  const getFormPortFields = bindingData => {
    if (bindingData.__typename === 'S3Binding') {
      return {
        proxyPort: bindingData.listenerSet.proxyListener.port.toString(),
        ...(bindingData.listenerSet.browserListener && {
          browserPort: bindingData.listenerSet.browserListener.port.toString(),
        }),
      }
    } else if (bindingData.__typename === 'ClusterBinding') {
      return {
        ports: bindingData.boundListenersRelationship.edges.map(edge => edge.node.port.toString()),
      }
    } else if (repoSupportsPortMultiplexing(bindingData.repo.type)) {
      return {
        port: {
          id: bindingData.listener.id,
          port: bindingData.listener.port.toString(),
          __typename: bindingData.listener.__typename,
        },
      }
    } else {
      return {
        port: bindingData.listener.port.toString(),
      }
    }
  }

  const getDefaultValues = bindingData => ({
    selectedRepo: bindingData.repo.id,
    bindingId: bindingData.id,
    enabled: bindingData.enabled,
    enableS3Browser: Boolean(
      bindingData.__typename === 'S3Binding' && bindingData.listenerSet.browserListener
    ),
    ...getFormPortFields(bindingData),
  })

  const { toast } = useToast()

  const methods = useForm({
    defaultValues: getDefaultValues(bindingData),
  })

  const { onSubmit, loading } = useEditBindingDialog(binding, methods.formState.dirtyFields)

  const { dirtyFields } = methods.formState

  const dirtyFieldsExist = Object.values(dirtyFields).some(Boolean)

  const handleFormSubmit = async event => {
    try {
      await methods.handleSubmit(onSubmit)(event)
    } catch (error) {
      const graphQLErrorMessage = getGraphQLErrorMessage(error)
      toast({
        variant: 'error',
        description: graphQLErrorMessage || 'Failed to edit repo binding',
      })
      methods.reset()
      console.error(error)
    }
  }

  React.useEffect(() => {
    if (methods.formState.isSubmitSuccessful) {
      onClose()
    }
  }, [methods.formState, onClose])

  React.useEffect(() => {
    if (methods.formState.isSubmitSuccessful) {
      const values = methods.getValues()
      methods.reset(values)
      onClose()
    }
  }, [methods.formState, onClose, methods.reset, methods.getValues])

  React.useEffect(() => {
    methods.reset(getDefaultValues(bindingData))
  }, [methods.reset, bindingData])

  return (
    <Dialog
      onClose={onClose}
      open={open}
      fullWidth
      TransitionProps={{
        onExited: () => methods.reset(),
      }}
    >
      <DialogTitle variant="h3">Edit Binding</DialogTitle>
      <FormProvider {...methods}>
        <Box component="form" onSubmit={handleFormSubmit}>
          <DialogContent>
            <Stack spacing={2}>
              <RepoDisplay repo={bindingData.repo} />
              <SelectPort query={data} />
              <EnableBindingToggle binding={binding} />
            </Stack>
          </DialogContent>

          <DialogActions>
            <Button variant="text" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit" loading={loading} disabled={!dirtyFieldsExist}>
              Save
            </Button>
          </DialogActions>
        </Box>
      </FormProvider>
    </Dialog>
  )
}

EditBindingDialog.fragments = {
  EditBindingDialog_queryFragment,
  EditBindingDialog_BindingFragment,
}

export default EditBindingDialog
