import { useState } from 'react'
import { useHistory } from 'react-router'

import { useAppConfig, useAuth } from '@jeeves/hooks'
import ExpressClient from '@jeeves/clients/express'
import { useSetup } from '@jeeves/pages/Setup/SetupContext'

const useFinishStepper = () => {
  const history = useHistory()
  const { license } = useAppConfig()
  const { getTokenSilently, user } = useAuth()
  const { completeWizard } = useSetup()

  const ec = new ExpressClient(getTokenSilently)

  const [loading, setLoading] = useState(false)

  const postNotification = async () => {
    const notificationBody = {
      type: 'Customer',
      subtype: 'setup_completed',
      subject: 'Registrant completed setup wizard, accessed management console',
      companyName: license.customer.name,
      contactName: user.given_name,
      currentPlan: license.userFriendlyName,
      customerEmail: user.email,
    }

    await ec.post('/integrations/_sendNotification', notificationBody)
  }

  const onClickFinishStepper = async () => {
    setLoading(true)
    try {
      await completeWizard()
      await postNotification()
      history.push('/home')
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  return {
    loading,
    onClickFinishStepper,
  }
}

export default useFinishStepper
