import { Controller, useFormContext } from 'react-hook-form'
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Stack,
} from '@mui/material'

import { graphql, useFragment } from '@jeeves/graphql'
import { InputLabel, Select } from '@jeeves/new-components'
import { isMongoDbRepo } from '@jeeves/graphql/utils'

const AuthenticationMethodRadioGroup_queryFragment = graphql(`
  fragment AuthenticationMethodRadioGroup_query on Query {
    identityProviders {
      id
      displayName
    }
    awsIamIntegrations {
      id
      name
    }
    repo(id: $repoId) {
      type
    }
  }
`)

const AuthenticationMethodRadioGroup = ({ query: queryProp }) => {
  const query = useFragment(AuthenticationMethodRadioGroup_queryFragment, queryProp)

  const { identityProviders, awsIamIntegrations, repo } = query

  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext()

  const authenticationMethodType = watch('authenticationMethodType') ?? 'none'

  const isIdentityProviderSelected = authenticationMethodType === 'identityProvider'
  const isAwsIamIntegrationSelected = authenticationMethodType === 'awsIamIntegration'

  const identityProviderOptions = identityProviders?.map(identityProvider => ({
    label: identityProvider.displayName,
    value: identityProvider.id,
  }))

  const awsIamIntegrationOptions = awsIamIntegrations?.map(awsIamIntegration => ({
    label: awsIamIntegration.name,
    value: awsIamIntegration.id,
  }))

  return (
    <FormControl variant="standard">
      <InputLabel htmlFor="authenticationMethodType">
        Select a user authentication method:
      </InputLabel>

      <Controller
        name="authenticationMethodType"
        control={control}
        defaultValue="none"
        render={({ field }) => (
          <RadioGroup aria-label="authenticationMethodType" {...field}>
            <FormControlLabel
              value="none"
              control={<Radio size="small" />}
              label="None"
              slotProps={{
                typography: {
                  variant: 'h6',
                  sx: {
                    color: 'text.primary',
                  },
                },
              }}
            />

            <FormControl variant="standard" error={errors?.identityProviderId}>
              <Stack spacing={1}>
                <FormControlLabel
                  value="identityProvider"
                  control={<Radio size="small" />}
                  label="Cyral Access Token"
                  slotProps={{
                    typography: {
                      variant: 'h6',
                      sx: {
                        color: 'text.primary',
                      },
                    },
                  }}
                />

                {isIdentityProviderSelected && (
                  <Stack spacing={1} sx={{ paddingLeft: 3 }}>
                    <Controller
                      name="identityProviderId"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <Select fullWidth options={identityProviderOptions} {...field} />
                      )}
                    />

                    <FormHelperText sx={{ typography: 'body2' }}>
                      {errors.identityProviderId?.message}
                    </FormHelperText>
                  </Stack>
                )}
              </Stack>
            </FormControl>

            {isMongoDbRepo(repo) && (
              <FormControl variant="standard" error={errors?.awsIamIntegrationId}>
                <Stack spacing={1}>
                  <FormControlLabel
                    value="awsIamIntegration"
                    control={<Radio size="small" />}
                    label="IAM Authentication"
                    slotProps={{
                      typography: {
                        variant: 'h6',
                        sx: {
                          color: 'text.primary',
                        },
                      },
                    }}
                  />

                  {isAwsIamIntegrationSelected && (
                    <Stack spacing={1} sx={{ paddingLeft: 3 }}>
                      <Controller
                        name="awsIamIntegrationId"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <Select fullWidth options={awsIamIntegrationOptions} {...field} />
                        )}
                      />

                      <FormHelperText sx={{ typography: 'body2' }}>
                        {errors.awsIamIntegrationId?.message}
                      </FormHelperText>
                    </Stack>
                  )}
                </Stack>
              </FormControl>
            )}
          </RadioGroup>
        )}
      />
    </FormControl>
  )
}

export default AuthenticationMethodRadioGroup
