/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import React, { useEffect, useState, useRef } from 'react'
import { Fade } from '@material-ui/core/'
import { IntegrationCard } from '@jeeves/pages/Integrations/primitives'
import ViewDraft from '../view/ViewDraft'
import AddFormDraft from '../create/AddFormDraft'
import Edit from '../update/Edit'

export default ({
  integration = null,
  putIntegration,
  deleteIntegration,
  creating = false,
  addIntegration,
  setOpenIntegration,
  isCreatingIntegration,
  setIsCreatingIntegration,
  last,
  scrollToAndOpen,
}) => {
  const [editing, setEditing] = useState(scrollToAndOpen)
  const configureEditing = () => setEditing(creating)
  const elementRef = useRef()

  useEffect(() => {
    configureEditing()
  }, [])

  const onDelete = async () => {
    await deleteIntegration(integration.id)
    setEditing(false)
  }

  useEffect(() => {
    if (scrollToAndOpen && elementRef.current) elementRef.current.scrollIntoView()
  }, [scrollToAndOpen, elementRef])

  useEffect(() => {
    if (scrollToAndOpen) setEditing(true)
  }, [scrollToAndOpen])

  return (
    <div ref={elementRef}>
      {!editing && integration && (
        <IntegrationCard css={last && { marginBottom: '0' }}>
          <Fade in={!editing}>
            <ViewDraft
              setOpenIntegration={setOpenIntegration}
              setIsCreatingIntegration={setIsCreatingIntegration}
              integration={integration}
              setEditing={() => setEditing(true)}
              onDelete={onDelete}
            />
          </Fade>
        </IntegrationCard>
      )}

      {editing && (
        <Fade in={editing}>
          <AddFormDraft
            integration={integration}
            addIntegration={addIntegration}
            handleRemoveAddField={() => setEditing(false)}
          />
        </Fade>
      )}
    </div>
  )
}
