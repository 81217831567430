/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField as MuiTextField,
  Typography,
} from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import { TagList } from '@jeeves/components/TagList'
import { ButtonLoading } from '@jeeves/components/Loading'

const StyledButton = styled(Button)`
  margin-left: 10px;
`

const TextField = styled(MuiTextField)`
  margin-bottom: 16px;
`

TextField.defaultProps = {
  fullWidth: true,
}

const EditPolicyDialog = ({ policy, open, onCancel, onSave, loading = false }) => {
  const [tags, setTags] = useState([])
  const [description, setDescription] = useState('')
  const [name, setName] = useState('')

  useEffect(() => {
    if (Array.isArray(policy.tags)) {
      setTags(policy.tags)
    }

    setName(policy.name)
    setDescription(policy.description)
  }, [])

  const handleTagDelete = tag => setTags(tags.filter(t => t !== tag))

  const handleAddTag = tag => setTags([...tags, tag])

  const handleOnSave = () => {
    onSave({ tags, name, description })
  }

  return (
    <Dialog open={open} onClose={onCancel} css={{ minWidth: '350px' }} fullWidth>
      <DialogTitle>Edit Policy</DialogTitle>
      <DialogContent>
        <TextField value={name} onChange={e => setName(e.target.value)} label="Name" />
        <TextField
          value={description}
          onChange={e => setDescription(e.target.value)}
          label="Description"
        />
        <Typography
          variant="caption"
          css={theme => css`
            font-size: 1rem;
            color: ${grey[700]};
            margin-bottom: ${theme.spacing[0]};
          `}
        >
          Tags cannot have empty spaces or commas. Use <code>Enter</code> to add tags.
        </Typography>
        <TagList tags={tags} onAdd={handleAddTag} onDelete={handleTagDelete} />
      </DialogContent>
      <DialogActions>
        <Grid container justify="flex-end" css={{ padding: '15px' }}>
          <StyledButton color="primary" onClick={onCancel}>
            Cancel
          </StyledButton>
          <div css={{ position: 'relative' }}>
            <StyledButton
              variant="contained"
              color="primary"
              onClick={handleOnSave}
              disabled={loading}
            >
              Save
            </StyledButton>
            {loading && <ButtonLoading />}
          </div>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default EditPolicyDialog
