import { useQuery } from '@apollo/client'
import { graphql } from '@jeeves/graphql'

const LOGGING_INTEGRATION_QUERY = graphql(`
  query SIEMIntegrations {
    SIEMIntegrations {
      id
      name
      ...useCloudwatch_cloudWatchIntegration
      ...useCustom_customLoggingIntegration
      ...useDatadog_datadogIntegration
      ...useELK_ELKIntegration
      ...useSplunk_splunkIntegration
      ...useSumologic_sumoLogicIntegration
    }
  }
`)

const useLoggingIntegration = () => {
  return useQuery(LOGGING_INTEGRATION_QUERY)
}

export default useLoggingIntegration
