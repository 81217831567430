import React from 'react'

import { MappingsHeaderCell } from '../MappingTable.styles'

export const MongoHeader = ({ children, props, actionsCol }) => {
  return (
    <React.Fragment>
      <MappingsHeaderCell>Database</MappingsHeaderCell>
      <MappingsHeaderCell>Collection</MappingsHeaderCell>
      <MappingsHeaderCell>Field</MappingsHeaderCell>
      {actionsCol && <MappingsHeaderCell></MappingsHeaderCell>}
    </React.Fragment>
  )
}
