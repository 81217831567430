import { useState, useEffect, useCallback } from 'react'
import { useMutation, useLazyQuery } from '@apollo/client'
import orderBy from 'lodash/orderBy'

import { graphql } from '@jeeves/graphql'
import { AccessToken, CreateAccessTokenInput } from '@jeeves/graphql/graphql'

export const ACCESS_TOKENS = graphql(`
  query AccessTokens {
    accessTokens {
      id
      token
      createdAt
      name
      validUntil
    }
  }
`)

const CREATE_ACCESS_TOKEN = graphql(`
  mutation CreateAccessToken($input: CreateAccessTokenInput) {
    createAccessToken(input: $input) {
      accessToken {
        id
        token
        createdAt
        name
        validUntil
      }
    }
  }
`)

const useAccessToken = (input?: CreateAccessTokenInput) => {
  const [accessToken, setAccessToken] = useState<AccessToken>()
  const [getAccessTokens, { error: accessTokensError }] = useLazyQuery(ACCESS_TOKENS)

  const [createAccessToken, { error: createAccessTokenError }] = useMutation(CREATE_ACCESS_TOKEN, {
    variables: {
      input,
    },
    onError: error => {
      console.error('An error occurred while creating an access token: ', error)
    },
    onCompleted: data => {
      const accessToken = data.createAccessToken?.accessToken
      if (accessToken) {
        setAccessToken(accessToken)
      }
    },
  })

  const getNewAccessToken = useCallback(async () => {
    const { data: accessTokensData } = await getAccessTokens()

    if (!accessTokensData) {
      throw Error('Error fetching token')
    }

    const accessTokens = accessTokensData.accessTokens
    const hasAccessTokens = accessTokens.length > 0

    if (!hasAccessTokens) {
      const newAccessToken = await createAccessToken()
      const newAccessTokenString = newAccessToken.data?.createAccessToken?.accessToken
      if (newAccessTokenString) {
        return newAccessTokenString
      }
      throw Error('Error fetching token')
    }

    const sortedAccessTokens = orderBy(accessTokens, ['validUntil'], 'desc')
    const accessTokenWithLongestValidity = sortedAccessTokens[0]

    setAccessToken(accessTokenWithLongestValidity)
    return accessTokenWithLongestValidity
  }, [createAccessToken, getAccessTokens])

  useEffect(() => {
    getNewAccessToken()
  }, [getNewAccessToken])

  const error = accessTokensError || createAccessTokenError
  const loading = !error && !accessToken

  return {
    accessToken,
    getNewAccessToken,
    loading,
    error,
  }
}

export default useAccessToken
