import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const AwsIamIcon = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 256 490" preserveAspectRatio="xMidYMid">
      <path
        d="m21 165.75-21 6.856 21.75 2.519-.75-9.375m-1.045 41.056L128 213.714l108.045-6.908L128 185.75 19.955 206.806M234.5 175.125l21.5-2.519-21.5-5.731v8.25"
        fill="#3C4929"
      />
      <path d="m157.387 352.929 56.606 13.396-56.756 17.116.15-30.512" fill="#B7CA9D" />
      <path
        d="M19.955 92.221V54.019L128 0l.482.405-.248 48.496-.234.102-.405 1.117-59.098 23.856-.542 84.037 31.452-5.29L128 147.002V490.03l-32.369-16.177v-45.771l-28.354-11.338V202.069l-47.322 4.737v-38.195L0 172.606v-72.408l19.955-7.977"
        fill="#4B612C"
      />
      <path
        d="m99.408 152.727-32.131 6.424V73.28l32.131 10.018v69.429m84.517-124.768 52.106 26.06v38.202L256 100.198V172.6l-19.969-3.989v38.195l-25.441-2.538-21.881-2.199v42.939h47.336v39.284l-21.997 1.974v39.611l-53.692 10.672v45.77l53.57-15.899.122 40.38-53.692 21.282v45.771L128 490.03V147.002l28.572 5.71 30.583 4.038V73.966l-58.338-22.498-.817-2.465V0l55.925 27.959"
        fill="#759C3E"
      />
      <path d="M160.356 61.941 128 49.01 67.277 73.28l32.131 10.018 60.948-21.357" fill="#3C4929" />
      <path
        d="M67.277 73.28 128 49.01l12.775 5.104 19.581 7.827 28.353 11.353-1.515 1.541-28.876 8.991-1.74-.528L128 73.28 99.408 83.298 67.277 73.28"
        fill="#3C4929"
      />
      <path d="m156.578 83.298 32.131-10.004v85.864l-32.131-6.446V83.298" fill="#4B612C" />
    </SvgIcon>
  )
}

export default AwsIamIcon
