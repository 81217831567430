import { Stack, Typography } from '@mui/material'
import {
  CheckCircleOutlineRounded,
  ErrorOutline,
  RemoveCircleOutline,
  WarningAmberRounded,
} from '@mui/icons-material'

import { SidecarHealth } from '@jeeves/graphql/graphql'

const healthIndicatorMap = {
  [SidecarHealth.Healthy]: {
    color: 'cyralColors.green.300',
    icon: CheckCircleOutlineRounded,
    label: 'Healthy',
  },
  [SidecarHealth.Unhealthy]: {
    color: 'error.main',
    icon: ErrorOutline,
    label: 'Unhealthy',
  },
  [SidecarHealth.Degraded]: {
    color: 'cyralColors.yellow.300',
    icon: WarningAmberRounded,
    label: 'Degraded',
  },
  [SidecarHealth.Unknown]: {
    color: 'secondary.main',
    icon: RemoveCircleOutline,
    label: 'Unknown',
  },
} as const

interface Props {
  health: SidecarHealth
}

const HealthIndicator = ({ health }: Props) => {
  const { color, icon: Icon, label } = healthIndicatorMap[health]

  return (
    <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
      <Icon sx={{ color }} />
      <Typography variant="h6" sx={{ color }}>
        {label}
      </Typography>
    </Stack>
  )
}

export default HealthIndicator
