/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { useState, useEffect } from 'react'
import { IconButton, Fade, Checkbox, FormControlLabel, Grid } from '@material-ui/core/'
import EditIcon from '@material-ui/icons/Edit'

import { ButtonLoading } from '@jeeves/components/Loading'
import { useAuth } from '@jeeves/components/Auth'
import { Button } from '@jeeves/components/Primitives'
import {
  DeleteIntegrationButton,
  IntegrationCard,
  IntegrationEditActions,
  IntegrationFieldList,
  IntegrationHeader,
  IntegrationTitle,
  Label,
  LabelValuePair,
  TextField,
  Value,
} from '../../../primitives'

const AddForm = ({ integration = null, onSave, onDelete, onCancel, creating = false }) => {
  const [name, setName] = useState('')
  const [apiToken, setApiToken] = useState('')
  const [editing, setEditing] = useState(false)
  const [saving, setSaving] = useState(false)
  const { hasPermission } = useAuth()

  const configureIntegration = () => {
    if (integration) {
      setName(integration.name)
      setApiToken(JSON.parse(integration.parameters || '{}').apiToken)
    } else {
      setName('')
      setApiToken('')
    }
  }

  const configureEditing = () => {
    if (creating) {
      setEditing(true)
    }
  }

  useEffect(() => {
    configureIntegration()
    configureEditing()
  }, []) // eslint-disable-line

  const handleCancelEditing = () => {
    setEditing(false)

    if (onCancel) {
      onCancel()
    }
  }

  const handleOnSave = async () => {
    setSaving(true)
    try {
      await onSave(name, apiToken)
    } catch (e) {
      console.log({ e })
    } finally {
      setSaving(false)
      setEditing(false)
    }
  }

  return (
    <IntegrationCard>
      {!editing && (
        <Fade in={!editing}>
          <div>
            <IntegrationHeader>
              <IntegrationTitle gutterBottom component="h2">
                {name}
              </IntegrationTitle>
              <div css={{ flexGrow: 1 }} />
              <IconButton
                onClick={() => setEditing(true)}
                disabled={!hasPermission('integrations:update')}
              >
                <EditIcon />
              </IconButton>
              <DeleteIntegrationButton
                onDelete={onDelete}
                disabled={!hasPermission('integrations:delete')}
              />
            </IntegrationHeader>
            <LabelValuePair>
              <Label>API Token</Label>
              <Value>{apiToken}</Value>
            </LabelValuePair>
          </div>
        </Fade>
      )}
      {editing && (
        <Fade in={editing}>
          <div>
            <Grid container spacing={24} direction="row" justify="space-between">
              <Grid container item direction="column" xs={8}>
                <Grid item>
                  <TextField
                    value={name}
                    label="Integration Name"
                    onChange={e => setName(e.target.value)}
                    autofocus
                  />
                </Grid>
                <Grid item>
                  <TextField
                    value={apiToken}
                    label="API Token"
                    onChange={e => setApiToken(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <IntegrationEditActions>
              <Button onClick={handleCancelEditing} color="primary" disabled={saving}>
                Cancel
              </Button>
              <div css={theme => ({ position: 'relative', marginLeft: theme.spacing[5] })}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={saving}
                  onClick={handleOnSave}
                >
                  Save
                </Button>
                {saving && <ButtonLoading />}
              </div>
            </IntegrationEditActions>
          </div>
        </Fade>
      )}
    </IntegrationCard>
  )
}

export default AddForm
