import { useQuery, gql } from '@apollo/client'
import { useParams } from 'react-router-dom'

import { useRepositoryDetailContext } from '@jeeves/pages/RepositoryDetail/contexts/RepositoryDetailContext'

export const APPROVAL_FIELDS = gql`
  fragment approvalFields on Approval {
    id
    sensitiveFields
    requester {
      type
      name
    }
    userAccount {
      id
      name
    }
    history {
      action
      comment
      timestamp
      actor {
        type
        name
      }
    }
    validFrom
    validUntil
  }
`

const GET_APPROVAL = gql`
  query GetApproval($repoId: ID!, $approvalId: ID!) {
    approval(repoId: $repoId, approvalId: $approvalId) {
      ... on PendingApproval {
        ...approvalFields
      }
      ... on RejectedApproval {
        ...approvalFields
      }
      ... on RevokedApproval {
        ...approvalFields
      }
      ... on GrantedApproval {
        ...approvalFields
        effectiveStartDate
        pendingAmendment {
          ...approvalFields
        }
      }
    }
  }
  ${APPROVAL_FIELDS}
`

const useApprovalDetailQuery = () => {
  const { approvalId } = useParams()
  const { repoId } = useRepositoryDetailContext()
  const { data, loading, error } = useQuery(GET_APPROVAL, {
    notifyOnNetworkStatusChange: true,
    variables: {
      repoId,
      approvalId,
    },
  })

  return {
    loading,
    error,
    data,
  }
}

export default useApprovalDetailQuery
