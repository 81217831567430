import * as React from 'react'
import Stack from '@mui/material/Stack'

import MultiplexedRepoListenerAutocomplete from './MultiplexedRepoListenerAutocomplete'
import { useMultiplexedRepoPortView } from './MultiplexedRepoPortViewContext'
import {
  MySQLFamilyMultiplexedListenerConfig,
  SQLServerMultiplexedListenerConfig,
} from './MultiplexedListenerConfigurations'

const getMultiplexedListenerConfiguration = listenerRepoType => {
  switch (listenerRepoType) {
    case 'mysql':
    case 'mariadb':
      return <MySQLFamilyMultiplexedListenerConfig listenerRepoType={listenerRepoType} />
    case 'sqlserver':
      return <SQLServerMultiplexedListenerConfig listenerRepoType={listenerRepoType} />
    default:
      return null
  }
}

const MultiplexedRepoPortView = () => {
  const { selectedListener, selectedListenerWillBecomeMultiplexed } = useMultiplexedRepoPortView()

  return (
    <Stack spacing={2}>
      <MultiplexedRepoListenerAutocomplete />
      {selectedListenerWillBecomeMultiplexed &&
        getMultiplexedListenerConfiguration(selectedListener?.repoType)}
    </Stack>
  )
}

export default MultiplexedRepoPortView
