import { useState } from 'react'
import { FragmentType, graphql, useFragment } from '@jeeves/graphql'
import { DatalabelType } from '@jeeves/graphql/graphql'

const useTagsQueryFragment = graphql(`
  fragment useTagsQueryFragment on Query {
    datalabels {
      name
      tags
      type
    }
  }
`)

const isValidDatalabelType = (labelType: DatalabelType) =>
  [DatalabelType.Custom, DatalabelType.Predefined].includes(labelType)

const useTags = ({ query }: { query: FragmentType<typeof useTagsQueryFragment> }) => {
  const { datalabels } = useFragment(useTagsQueryFragment, query)

  const getTagsMap = () => {
    const tagsMap: Record<string, string[]> = {}

    datalabels.forEach(datalabel => {
      if (!datalabel) {
        return
      }

      const invalidDatalabelType = !isValidDatalabelType(datalabel.type)
      const datalabelHasNoTags = datalabel.tags.length === 0
      if (invalidDatalabelType || datalabelHasNoTags) {
        return
      }

      datalabel.tags.forEach(tag => {
        if (!tagsMap[tag]) {
          tagsMap[tag] = []
        }

        tagsMap[tag].push(datalabel.name)
      })
    })

    return tagsMap
  }

  const tagsMap = getTagsMap()

  const [selectedTag, setSelectedTag] = useState('')
  const [filteredTagsMap, setFilteredTags] = useState(tagsMap)
  const [modalOpen, setModalOpen] = useState(false)

  const selectedDatalabels = tagsMap[selectedTag] ?? []

  const onSearch = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const searchValue = event.target.value.trim().toLowerCase()

    const filteredTags = Object.fromEntries(
      Object.entries(tagsMap).filter(([tag]) => tag.trim().toLowerCase().includes(searchValue))
    )

    setFilteredTags(filteredTags)
  }

  const openModal = (tagName: string) => {
    setSelectedTag(tagName)
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  return {
    filteredTagsMap,
    selectedTag,
    selectedDatalabels,
    modalOpen,
    onSearch,
    openModal,
    closeModal,
  }
}

export default useTags
