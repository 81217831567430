import { Box, Stack, Typography } from '@mui/material'
import pluralize from 'pluralize'

import Tag from '@jeeves/pages/DataLabels/components/Tag'

interface Props {
  tag: string
  datalabels: string[]
  openModal: (tagName: string) => void
}

const TagCard = ({ tag, datalabels, openModal }: Props) => {
  const numberOfLabels = datalabels.length

  return (
    <Box
      sx={{
        border: 1,
        borderColor: 'cyralColors.grey.200',
        borderRadius: theme => theme.radii.base,
        cursor: 'pointer',
        padding: 3,
        '&:hover': {
          outline: theme => `2px solid ${theme.palette.primary.main}`,
        },
      }}
      onClick={() => openModal(tag)}
    >
      <Stack spacing={1} sx={{ alignItems: 'flex-start' }}>
        <Tag text={tag} />

        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {numberOfLabels} Data {pluralize('Label', numberOfLabels)}
        </Typography>
      </Stack>
    </Box>
  )
}

export default TagCard
