import { Controller, useFormContext } from 'react-hook-form'
import pluralize from 'pluralize'
import { Box, FormControl, FormHelperText, Stack, Typography } from '@mui/material'

import { Input, InputLabel, Select } from '@jeeves/new-components'

import { VALID_UNITS } from '@jeeves/utils/constants'

const DefaultTokenValidity = () => {
  const {
    formState: { errors },
    register,
    watch,
  } = useFormContext()

  const defaultTokenValidityValue = watch('defaultTokenValidity.value')

  const options = VALID_UNITS.map(unit => ({
    label: pluralize(unit, Number(defaultTokenValidityValue)),
    value: unit,
  }))

  return (
    <Stack spacing={1} sx={{ width: '50%' }}>
      <InputLabel required error={Boolean(errors?.defaultTokenValidity)}>
        Default Token Validity Period
      </InputLabel>

      <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 2fr', gap: 1 }}>
        <FormControl variant="standard" error={Boolean(errors?.defaultTokenValidity?.value)}>
          <Input
            type="number"
            inputProps={{
              ...register('defaultTokenValidity.value'),
              min: 1,
            }}
          />
        </FormControl>

        <FormControl variant="standard" error={Boolean(errors?.defaultTokenValidity?.unit)}>
          <Controller
            name="defaultTokenValidity.unit"
            render={({ field }) => <Select options={options} {...field} />}
          />
        </FormControl>
      </Box>

      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        The initial period that newly issued access tokens will remain valid.
      </Typography>
    </Stack>
  )
}

export default DefaultTokenValidity
