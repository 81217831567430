import { Stack, Typography } from '@mui/material'

interface Props {
  certificateType: 'TLS' | 'CA'
}

const CertificateNotFound = ({ certificateType }: Props) => (
  <Stack spacing={2}>
    <Typography variant="h6" sx={{ color: 'text.primary' }}>
      {certificateType} Certificate
    </Typography>

    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
      {certificateType} certificate not found.
    </Typography>
  </Stack>
)

export default CertificateNotFound
