import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './components/App'
import { AuthProvider } from './components/Auth'

const Application = () => {
  return (
    <Router>
      <AuthProvider
        domain={window._env_.domain}
        client_id={window._env_.client_id}
        audience={window._env_.audience}
        meta={window._env_.meta}
        auth_tenant={window._env_.auth_tenant}
        auth_provider={window._env_.auth_provider}
        redirect_uri={window.location.origin + process.env.PUBLIC_URL}
        keycloak_frontend_port={window._env_.keycloak_frontend_port}
      >
        <App />
      </AuthProvider>
    </Router>
  )
}

ReactDOM.render(<Application />, document.getElementById('root'))
