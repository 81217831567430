import { Controller, useFormContext } from 'react-hook-form'
import { FormHelperText, FormControl } from '@mui/material'

import { useRepoTypeSelectOptions } from '@jeeves/hooks'
import { InputLabel, Select } from '@jeeves/new-components'

const RepoTypeSelect = () => {
  const { control, formState } = useFormContext()
  const { options } = useRepoTypeSelectOptions()

  const error = formState.errors?.repositoryType

  return (
    <FormControl variant="standard" error={Boolean(error)}>
      <InputLabel id="repository-type-label" htmlFor="repository-type" required>
        Repository type
      </InputLabel>
      <Controller
        name="repositoryType"
        control={control}
        render={({ field }) => (
          <Select
            labelId="repository-type-label"
            id="repository-type"
            options={options}
            {...field}
          />
        )}
      />
      <FormHelperText sx={{ typography: 'body2' }} error={Boolean(error)}>
        {error?.message}
      </FormHelperText>
    </FormControl>
  )
}

export default RepoTypeSelect
