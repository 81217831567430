import { FormProvider } from 'react-hook-form'
import { Box, FormControl, Stack, Typography } from '@mui/material'

import { FragmentType, graphql, useFragment } from '@jeeves/graphql'
import { Button, InputLabel } from '@jeeves/new-components'

import LogsSelect from '../LogsSelect'
import useIntegrationSettings from './useIntegrationSettings'

const IntegrationSettings_QueryFragment = graphql(`
  fragment IntegrationSettings_QueryFragment on Query {
    ...LogsSelect_query
    ...useIntegrationSettings_QueryFragment
  }
`)

interface Props {
  query: FragmentType<typeof IntegrationSettings_QueryFragment>
}

const IntegrationSettings = ({ query: queryProp }: Props) => {
  const query = useFragment(IntegrationSettings_QueryFragment, queryProp)

  const { loading, methods, onSubmit } = useIntegrationSettings({ query })

  const {
    formState: { isDirty },
    reset,
  } = methods

  const isDisabled = loading || !isDirty

  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        onSubmit={onSubmit}
        sx={{
          border: 1,
          borderColor: 'cyralColors.grey.200',
          p: 4,
          borderRadius: theme => theme.radii.base,
        }}
      >
        <Stack spacing={6}>
          <Typography variant="h3" sx={{ color: 'text.primary' }}>
            Integration Settings
          </Typography>

          <Box sx={{ width: '50%' }}>
            <FormControl fullWidth variant="standard">
              <InputLabel htmlFor="dataActivityLogsIntegrationId">Data Activity Logs</InputLabel>

              <LogsSelect
                name="dataActivityLogsIntegrationId"
                id="dataActivityLogsIntegrationId"
                query={query}
              />
            </FormControl>
          </Box>

          <Stack direction="row" sx={{ justifyContent: 'flex-end' }} spacing={2}>
            <Button variant="text" disabled={isDisabled} onClick={() => reset()}>
              Cancel
            </Button>
            <Button type="submit" disabled={isDisabled} loading={loading}>
              Save
            </Button>
          </Stack>
        </Stack>
      </Box>
    </FormProvider>
  )
}

export default IntegrationSettings
