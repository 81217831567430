/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import styled from '@emotion/styled'
import React from 'react'
import { TextField as MuiTextField, Tooltip } from '@material-ui/core/'

const TextField = styled(MuiTextField)`
  margin-bottom: 15px;
`

export const TextFieldWithToolTip = ({ helpText = '', ...props }) => (
  <Tooltip title={helpText} placement="left">
    <TextField variant="outlined" fullWidth={true} {...props}></TextField>
  </Tooltip>
)
