import { useFormContext } from 'react-hook-form'
import { Box, Stack, FormControlLabel, Radio } from '@mui/material'
import ReplicaSetFields from '@jeeves/pages/RepositoryDetail/Edit/components/EditMongoDBReplicaSetRepoFields/components/ReplicaSetFields'

const MongoDBReplicaSetOption = () => {
  const { watch } = useFormContext()
  const clusterType = watch('clusterType')

  const isSelected = clusterType === 'replicaSet'

  return (
    <Stack spacing={1}>
      <FormControlLabel
        value="replicaSet"
        control={<Radio size="small" disableRipple />}
        label="Replica set cluster"
        slotProps={{
          typography: {
            variant: 'h6',
            sx: {
              color: 'text.primary',
            },
          },
        }}
      />
      {isSelected && (
        <Box sx={{ paddingLeft: 3 }}>
          <ReplicaSetFields />
        </Box>
      )}
    </Stack>
  )
}

export default MongoDBReplicaSetOption
