export const fontFamily = ['Open Sans', 'sans-serif'].join(',')
export const fontFamilyMonospaced = ['Fira Mono', 'monospace'].join(',')

const h1 = {
  fontSize: '2.25rem',
  fontWeight: 700,
  lineHeight: '56px',
  letterSpacing: '-0.02em',
}

const h2 = {
  fontSize: '1.75rem',
  fontWeight: 600,
  lineHeight: '40px',
  letterSpacing: '-0.02em',
}

const h3 = {
  fontSize: '1.5rem',
  fontWeight: 600,
  lineHeight: '32px',
  letterSpacing: '-0.02em',
}

const h4 = {
  fontSize: '1rem',
  fontWeight: 600,
  lineHeight: '24px',
}

const h5 = {
  fontSize: '0.875rem',
  fontWeight: 700,
  lineHeight: '20px',
  textTransform: 'uppercase' as const,
  letterSpacing: '0.02em',
}

const h6 = {
  fontSize: '0.875rem',
  fontWeight: 600,
  lineHeight: '20px',
}

const body1 = {
  fontSize: '1rem',
  fontWeight: 400,
  lineHeight: '24px',
}

const body2 = {
  fontSize: '0.875rem',
  fontWeight: 400,
  lineHeight: '20px',
}

const tag = {
  fontSize: '0.75rem',
  fontWeight: 700,
  lineHeight: '16px',
  letterSpacing: '0.02em',

  fontFamily,
  textTransform: 'uppercase' as const,
}

const code = {
  fontSize: '0.875rem',
  fontWeight: 400,
  lineHeight: '18px',
  fontFamily: fontFamilyMonospaced,
}

const button = {
  ...h6,
  textTransform: 'none' as const,
}

const badge = {
  fontFamily,
  fontSize: '.75rem',
  fontWeight: 600,
  lineHeight: '18px',
  letterSpacing: '-0.02em',
  textTransform: 'uppercase' as const,
}

export const variants = {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  body1,
  body2,
  button,
  tag,
  code,
  badge,
  // Disabled variants
  subtitle1: undefined,
  subtitle2: undefined,
}
