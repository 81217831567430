import * as React from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

import { InlineCode, CodeBlock } from '@jeeves/new-components'
import { getDocsURL } from '@jeeves/utils/docsURL'

const LookerConfiguration = ({
  checked,
  handleChange,
  serviceAccountName,
  port,
  host,
  repoType,
}) => {
  return (
    <Stack spacing={4}>
      <Stack spacing={2}>
        <Typography variant="h4" sx={{ color: 'text.primary' }}>
          Configure your database connection in your Looker dashboard:
        </Typography>

        <ConfigurationSteps
          serviceAccountName={serviceAccountName}
          port={port}
          host={host}
          repoType={repoType}
        />
      </Stack>

      <FormControlLabel
        checked={checked}
        onChange={handleChange}
        control={<Checkbox disableRipple />}
        label="I have configured my Looker database connection as described above."
        componentsProps={{
          typography: {
            variant: 'h4',
            sx: {
              color: 'text.primary',
            },
          },
        }}
      />
    </Stack>
  )
}

const ConfigurationSteps = ({ serviceAccountName, port, host, repoType }) => {
  // See doc below for more information on the addititonal params required for Looker identity attribution
  // https://cyralinc.atlassian.net/wiki/spaces/EN/pages/1686503427/Looker+configuration+for+Identity+attribution
  const getAdditionaParams = repoType => {
    switch (repoType) {
      case 'mysql':
        return `connectionAttributes=cyral.enduser:{{_user_attributes['email']}}`
      case 'postgresql':
        return `options=-c cyral.enduser={{_user_attributes['email']}}`
      case 'redshift':
        return `options=-c query_group=cyral.enduser={{_user_attributes['email']}}`
      case 'snowflake':
        return `cyral.enduser={{_user_attributes['email']}}`
      case 'sqlserver':
        return `applicationName=cyral.enduser={{_user_attributes['email']}}`
      default:
        return ''
    }
  }

  return (
    <Stack spacing={2} component="ol">
      <Typography component="li" variant="body1" sx={{ color: 'text.primary' }}>
        Select the <Box component="strong">Admin</Box> tab in the sidebar, then navigate to{' '}
        <Box component="strong">Database</Box> &gt; <Box component="strong">Connections</Box>.
      </Typography>

      <Typography component="li" variant="body1" sx={{ color: 'text.primary' }}>
        Edit the database connection for <InlineCode noWrap>{serviceAccountName}</InlineCode> on
        this repository.
      </Typography>

      <Typography component="li" variant="body1" sx={{ color: 'text.primary' }}>
        <Stack spacing={2}>
          <Box>
            Set the <Box component="strong">Remote Host</Box> and <Box component="strong">Port</Box>{' '}
            in your Looker <Box component="strong">Connection Settings</Box> to the values below:
          </Box>
          <Box display="grid" gridTemplateColumns="repeat(4, 1fr)" gap={2}>
            <Stack spacing={1} gridColumn="span 3">
              <Typography variant="h6" sx={{ color: 'text.secondary' }}>
                Remote Host
              </Typography>
              <CodeBlock>{host}</CodeBlock>
            </Stack>
            <Stack spacing={1} gridColumn="span 1">
              <Typography variant="h6" sx={{ color: 'text.secondary' }}>
                Port
              </Typography>
              <CodeBlock>{port}</CodeBlock>
            </Stack>
          </Box>
        </Stack>
      </Typography>

      <Typography component="li" variant="body1" sx={{ color: 'text.primary' }}>
        <Stack spacing={2}>
          <Box>
            Add the following connection parameter into the{' '}
            <Box component="strong">Additional Params</Box> field:
          </Box>

          <Stack spacing={1}>
            <Typography variant="h6" sx={{ color: 'text.secondary' }}>
              Additional Params
            </Typography>
            <CodeBlock>{getAdditionaParams(repoType)}</CodeBlock>
            <Typography variant="body1" sx={{ color: 'text.secondary' }}>
              Note: If you have <Box component="strong">Persistent Derived Tables</Box> enabled,
              please follow{' '}
              <Link
                href={getDocsURL({ docsPath: 'bi-tools/looker' })}
                underline="hover"
                target="_blank"
                rel="noopener"
              >
                these instructions.
              </Link>
            </Typography>
          </Stack>
        </Stack>
      </Typography>

      <Typography component="li" variant="body1" sx={{ color: 'text.primary' }}>
        Click <Box component="strong">Update Connection</Box> to save your change.
      </Typography>
    </Stack>
  )
}

export default LookerConfiguration
