import { Box, IconButton, Typography } from '@mui/material'
import { Delete as DeleteIcon, Edit as EditIcon, HourglassBottom } from '@mui/icons-material'
import { differenceInHours, parseISO } from 'date-fns'

import { FragmentType, graphql, useFragment } from '@jeeves/graphql'
import { Tooltip } from '@jeeves/new-components'
import { formatLocalDate } from '@jeeves/utils/datetime'
import { getRelativeTime } from '@jeeves/utils/duration'

// By a product decision, the token is considered expiring with less than 1 hour to expiration time
const TOKEN_DEFAULT_EXPIRATION_TIME = 1

const AccessTokenRow_AccessTokenFragment = graphql(`
  fragment AccessTokenRow_AccessTokenFragment on AccessToken {
    name
    createdAt
    validUntil
  }
`)

interface Props {
  accessToken: FragmentType<typeof AccessTokenRow_AccessTokenFragment>
  handleDelete: () => void
  handleEdit: () => void
}

const DATE_FORMAT = 'MMMM d, yyyy h:mmaaa'

const AccessTokenRow = ({ accessToken: accessTokenProp, handleDelete, handleEdit }: Props) => {
  const accessToken = useFragment(AccessTokenRow_AccessTokenFragment, accessTokenProp)

  const formattedTimeStampExpiration = formatLocalDate(accessToken.validUntil, DATE_FORMAT)
  const formattedTimeStampCreated = formatLocalDate(accessToken.createdAt, DATE_FORMAT)

  const isAccessTokenExpiring = (validUntil: string) => {
    const now = new Date()
    const expiration = parseISO(validUntil)
    const remainingTime = differenceInHours(expiration, now)

    return remainingTime < TOKEN_DEFAULT_EXPIRATION_TIME
  }

  return (
    <tr>
      <td>
        <Typography variant="body2" sx={{ color: 'text.primary' }}>
          {accessToken.name}
        </Typography>
      </td>
      <td>
        <Tooltip title={formattedTimeStampCreated} placement="bottom-end">
          <Typography variant="body2" sx={{ color: 'text.primary', cursor: 'pointer' }}>
            {getRelativeTime(accessToken.createdAt)} ago
          </Typography>
        </Tooltip>
      </td>
      <td>
        <Tooltip title={formattedTimeStampExpiration} placement="bottom-end">
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, cursor: 'pointer' }}>
            <Typography variant="body2" sx={{ color: 'text.primary' }}>
              In {getRelativeTime(accessToken.validUntil)}{' '}
            </Typography>
            {isAccessTokenExpiring(accessToken.validUntil) && (
              <HourglassBottom fontSize="small" sx={{ color: 'error.main' }} />
            )}
          </Box>
        </Tooltip>
      </td>
      <td>
        <IconButton aria-label="edit" onClick={handleEdit}>
          <EditIcon fontSize="small" />
        </IconButton>
        <IconButton aria-label="delete" onClick={handleDelete}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      </td>
    </tr>
  )
}

export default AccessTokenRow
