import { Stack } from '@mui/material'

import { FragmentType, graphql, useFragment } from '@jeeves/graphql'
import { Button } from '@jeeves/new-components'
import { Views } from '../../types'

import useAmountOfAvailableTokens from './useAmountOfAvailableTokens'

const ManageAccessTokenFooter_QueryFragment = graphql(`
  fragment ManageAccessTokenFooter_QueryFragment on Query {
    ...useAmountOfAvailableTokens_QueryFragment
  }
`)

interface Props {
  query: FragmentType<typeof ManageAccessTokenFooter_QueryFragment>
  setView: React.Dispatch<React.SetStateAction<Views>>
}

const ManageAccessTokenFooter = ({ query: queryProp, setView }: Props) => {
  const query = useFragment(ManageAccessTokenFooter_QueryFragment, queryProp)
  const amountOfAvailableTokens = useAmountOfAvailableTokens({ query })

  return (
    <Stack direction="row" sx={{ justifyContent: 'flex-end' }}>
      <Button
        variant="contained"
        color="primary"
        disabled={amountOfAvailableTokens === 0}
        onClick={() => setView('create')}
      >
        Generate Access Token
      </Button>
    </Stack>
  )
}

export default ManageAccessTokenFooter
