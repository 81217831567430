import { formatLocalDate } from '@jeeves/utils/datetime'

import * as _ from 'lodash'

function formatLocalDateOverview(timestamp) {
  return formatLocalDate(timestamp, 'MMM do, yyyy')
}

// this function will transform the raw graphQL data and add information to it, for example check applied controls
// the return is a list where each item is a row in the UI table.
export function transformData(discoveredAccounts, repoType) {
  let transformedDiscoveredAccounts = []

  discoveredAccounts.forEach((discoveredAccount, accountIndex) => {
    let transformedDiscoveredAccount = {}

    transformedDiscoveredAccount.accountIndex = accountIndex

    transformedDiscoveredAccount.databaseAccount = discoveredAccount.name

    // Controls applied logic
    transformedDiscoveredAccount.controlsApplied = []
    if (discoveredAccount.userAccount?.accessRules?.length > 0) {
      transformedDiscoveredAccount.controlsApplied.push('SSO')
    }
    const MFAEnabled = discoveredAccount.userAccount?.accessRules?.some(accessRule =>
      accessRule.accessRestrictions?.some(
        accessRestriction => accessRestriction.__typename === 'DuoIntegration'
      )
    )
    if (MFAEnabled) {
      transformedDiscoveredAccount.controlsApplied.push('MFA')
    }
    if (
      discoveredAccount.networkShieldRelationship?.edge?.node?.enabled &&
      discoveredAccount.networkShieldRelationship?.edge?.applicableNetworkShieldRules?.length > 0
    ) {
      transformedDiscoveredAccount.controlsApplied.push('Network Shield')
    }
    if (discoveredAccount.serviceAccount?.connectionDriverInstance) {
      transformedDiscoveredAccount.controlsApplied.push('SAR')
    }
    if (
      discoveredAccount.userAccount ||
      discoveredAccount.serviceAccount ||
      transformedDiscoveredAccount.controlsApplied.length > 0
    ) {
      transformedDiscoveredAccount.managedByCyral = true
    } else {
      transformedDiscoveredAccount.managedByCyral = false
    }
    transformedDiscoveredAccount.usersLast7Days = discoveredAccount.userAccount
      ? discoveredAccount.userAccount?.numUsersAccessed
      : discoveredAccount.serviceAccount?.numUsersAccessed
    transformedDiscoveredAccount.lastUsedTime = discoveredAccount.lastUsedTimeInfo?.time || ''
    transformedDiscoveredAccount.lastUsedSource = discoveredAccount.lastUsedTimeInfo?.source || ''

    if (repoType !== 'sqlserver') {
      // Other Repo Types
      transformedDiscoveredAccount.predefinedRoles = []
      transformedDiscoveredAccount.customRoles = []
      discoveredAccount.roles.forEach(roleElement => {
        if (roleElement.type === 'CUSTOM') {
          transformedDiscoveredAccount.customRoles.push(roleElement.name)
        }
        if (roleElement.type === 'PREDEFINED') {
          transformedDiscoveredAccount.predefinedRoles.push(roleElement.name)
        }
      })

      // unifying roles for showing in table
      transformedDiscoveredAccount.unifiedRoles = transformedDiscoveredAccount.customRoles.concat(
        transformedDiscoveredAccount.predefinedRoles
      )
      transformedDiscoveredAccount.status = discoveredAccount.status
        ? discoveredAccount.status
        : 'Unknown'
      transformedDiscoveredAccount.isOracleMaintained = discoveredAccount.isOracleMaintained

      transformedDiscoveredAccounts.push(transformedDiscoveredAccount)
    } else {
      // SQL Server type
      transformedDiscoveredAccount.status =
        typeof discoveredAccount.isDisabled === 'boolean'
          ? discoveredAccount.isDisabled
            ? 'Disabled'
            : 'Enabled'
          : 'Unknown'
      if (discoveredAccount.databaseUser) {
        //USER type
        transformedDiscoveredAccount.serverPredefinedRoles = ['Not applicable']
        transformedDiscoveredAccount.serverCustomRoles = ['Not applicable']
        // unified server roles for showing in UI table
        transformedDiscoveredAccount.unifiedServerRoles = ['Not applicable']
        transformedDiscoveredAccount.database = discoveredAccount.databaseUser.name
        transformedDiscoveredAccount.databaseUser = discoveredAccount.name
        transformedDiscoveredAccount.databasePredefinedRoles = []
        transformedDiscoveredAccount.databaseCustomRoles = []
        discoveredAccount.databaseUser.roles.forEach(roleElement => {
          if (roleElement.type === 'CUSTOM') {
            transformedDiscoveredAccount.databaseCustomRoles.push(roleElement.name)
          }
          if (roleElement.type === 'PREDEFINED') {
            transformedDiscoveredAccount.databasePredefinedRoles.push(roleElement.name)
          }
        })
        // unifying db roles for showing in UI table
        transformedDiscoveredAccount.unifiedDatabaseRoles =
          transformedDiscoveredAccount.databaseCustomRoles.concat(
            transformedDiscoveredAccount.databasePredefinedRoles
          )
        transformedDiscoveredAccounts.push(transformedDiscoveredAccount)
      } else {
        //LOGIN type
        const rowSpan =
          discoveredAccount.databaseUsers.length > 0 ? discoveredAccount.databaseUsers.length : 1
        transformedDiscoveredAccount.rowSpan = rowSpan
        transformedDiscoveredAccount.serverPredefinedRoles = []
        transformedDiscoveredAccount.serverCustomRoles = []
        discoveredAccount.serverLevelRoles.forEach(roleElement => {
          if (roleElement.type === 'CUSTOM') {
            transformedDiscoveredAccount.serverCustomRoles.push(roleElement.name)
          }
          if (roleElement.type === 'PREDEFINED') {
            transformedDiscoveredAccount.serverPredefinedRoles.push(roleElement.name)
          }
        })
        // unifying server roles for showing in the UI table
        transformedDiscoveredAccount.unifiedServerRoles =
          transformedDiscoveredAccount.serverCustomRoles.concat(
            transformedDiscoveredAccount.serverPredefinedRoles
          )
        // Nested rows in table
        discoveredAccount.databaseUsers.forEach((databaseElement, index) => {
          transformedDiscoveredAccount.database = databaseElement.database
          transformedDiscoveredAccount.databaseUser = databaseElement.name
          transformedDiscoveredAccount.databasePredefinedRoles = []
          transformedDiscoveredAccount.databaseCustomRoles = []
          databaseElement.roles.forEach(roleElement => {
            if (roleElement.type === 'CUSTOM') {
              transformedDiscoveredAccount.databaseCustomRoles.push(roleElement.name)
            }
            if (roleElement.type === 'PREDEFINED') {
              transformedDiscoveredAccount.databasePredefinedRoles.push(roleElement.name)
            }
          })

          // unifying db roles for showing in the UI table
          transformedDiscoveredAccount.unifiedDatabaseRoles =
            transformedDiscoveredAccount.databaseCustomRoles.concat(
              transformedDiscoveredAccount.databasePredefinedRoles
            )

          if (index == 0) {
            // First line from nested acc
            transformedDiscoveredAccount.isFirstRowForSQLServerPDF = true
          } else {
            // Other nested lines
            transformedDiscoveredAccount.isFirstRowForSQLServerPDF = false
          }

          // we need to copy tableObj here, otherwise it will repeat the data
          // for all the the nested database elements in that account
          transformedDiscoveredAccounts.push({ ...transformedDiscoveredAccount })
        })
        // account that can login to the server itself but can’t connect to any database
        if (discoveredAccount.databaseUsers.length == 0) {
          transformedDiscoveredAccount.database = ''
          transformedDiscoveredAccount.databaseUser = ''
          transformedDiscoveredAccount.databasePredefinedRoles = []
          transformedDiscoveredAccount.databaseCustomRoles = []
          transformedDiscoveredAccount.unifiedDatabaseRoles = ['None']
          transformedDiscoveredAccount.unifiedServerRoles = ['None']

          transformedDiscoveredAccounts.push(transformedDiscoveredAccount)
        }
      }
    }
  })

  return transformedDiscoveredAccounts
}

// this function will add defaults (mostly are 'none') to the table data, so that they are presented correctly in the UI.
// in the future, if we want to export as csv to the client, we don't need to use this function
export function addNonesToDiscoveredAccounts(discoveredAccounts, repoType) {
  discoveredAccounts.forEach(discoveredAccount => {
    // all accounts need to update this
    discoveredAccount.controlsApplied =
      discoveredAccount.controlsApplied.join(', ') === ''
        ? 'None'
        : discoveredAccount.controlsApplied.join(', ')
    discoveredAccount.usersLast7Days = discoveredAccount.usersLast7Days ?? 'Unknown'
    discoveredAccount.lastUsedTime = discoveredAccount.lastUsedTime
      ? formatLocalDateOverview(discoveredAccount.lastUsedTime)
      : 'Unknown'
    if (repoType !== 'sqlserver') {
      if (discoveredAccount.predefinedRoles?.length == 0) {
        discoveredAccount.predefinedRoles = ['None']
      }
      if (discoveredAccount.customRoles?.length == 0) {
        discoveredAccount.customRoles = ['None']
      }
      if (discoveredAccount.unifiedRoles?.length == 0) {
        discoveredAccount.unifiedRoles = ['None']
      }
    } else {
      if (discoveredAccount.databasePredefinedRoles?.length == 0) {
        discoveredAccount.databasePredefinedRoles = ['None']
      }
      if (discoveredAccount.databaseCustomRoles?.length == 0) {
        discoveredAccount.databaseCustomRoles = ['None']
      }
      if (discoveredAccount.serverPredefinedRoles?.length == 0) {
        discoveredAccount.serverPredefinedRoles = ['None']
      }
      if (discoveredAccount.serverCustomRoles?.length == 0) {
        discoveredAccount.serverCustomRoles = ['None']
      }
      if (discoveredAccount.unifiedServerRoles?.length == 0) {
        discoveredAccount.unifiedServerRoles = ['None']
      }
      if (discoveredAccount.unifiedDatabaseRoles?.length == 0) {
        discoveredAccount.unifiedDatabaseRoles = ['None']
      }
      // in the case where we have databaseUsers.length == 0, there is no database/databaseUser
      discoveredAccount.database = discoveredAccount.database ? discoveredAccount.database : 'None'
      discoveredAccount.databaseUser = discoveredAccount.databaseUser
        ? discoveredAccount.databaseUser
        : 'None'
    }
  })

  return discoveredAccounts
}

//this function sorts the accounts list to appear in the UI.
export function sortDiscoveredAccounts(discoveredAccounts) {
  // sorting list, first for managedByCyral and then alphabetically
  const sortedDiscoveredAccounts = [...discoveredAccounts].sort((a, b) => {
    if (Boolean(a.managedByCyral) === Boolean(b.managedByCyral))
      if (a.databaseAccount === b.databaseAccount)
        // both true or both false
        // both has the same name ( case SQLServer LOGIN type )
        return 0
      else if (a.databaseAccount > b.databaseAccount)
        // 'a' is after 'b'
        return 1
      // 'a' is before 'b'
      else return -1
    else if (a.managedByCyral)
      // a == true ; b == false
      return -1
    // a == false ; b == true
    else return 1
  })

  return sortedDiscoveredAccounts
}

// this functions returns the data to be shown in the UI table
export function formatForUITable(discoveredAccounts) {
  return discoveredAccounts
}

// this functions returns the data to be shown in the PDF table (export)
export function formatForPDFTable(discoveredAccounts, repoType) {
  let discoveredAccountsPDFTable = []
  discoveredAccounts.forEach(discoveredAccount => {
    let discoveredAccountPDFTableObj = {
      metadata: { managedByCyral: discoveredAccount.managedByCyral },
    }

    if (repoType !== 'sqlserver') {
      discoveredAccountPDFTableObj.content = [
        {
          content: discoveredAccount.isOracleMaintained
            ? discoveredAccount.databaseAccount + '**'
            : discoveredAccount.databaseAccount,
        },
        { content: discoveredAccount.predefinedRoles },
        { content: discoveredAccount.customRoles },
        { content: discoveredAccount.status },
        { content: discoveredAccount.controlsApplied },
        { content: discoveredAccount.usersLast7Days },
        { content: discoveredAccount.lastUsedTime },
      ]
    } else {
      if (discoveredAccount.rowSpan > 1) {
        // if there is rowSpan, it means we're dealing with SQLServer login type (nested into PDF Report)
        if (discoveredAccount.isFirstRowForSQLServerPDF) {
          const rowSpan = discoveredAccount.rowSpan
          // header
          discoveredAccountPDFTableObj.content = [
            { content: discoveredAccount.databaseAccount, rowSpan: rowSpan },
            { content: discoveredAccount.serverPredefinedRoles, rowSpan: rowSpan },
            { content: discoveredAccount.serverCustomRoles, rowSpan: rowSpan },
            { content: discoveredAccount.database, rowSpan: 1 },
            { content: discoveredAccount.databaseUser, rowSpan: 1 },
            { content: discoveredAccount.databasePredefinedRoles, rowSpan: 1 },
            { content: discoveredAccount.databaseCustomRoles, rowSpan: 1 },
            { content: discoveredAccount.status, rowSpan: rowSpan },
            { content: discoveredAccount.controlsApplied, rowSpan: rowSpan },
            {
              content: discoveredAccount.usersLast7Days,
              rowSpan: rowSpan,
            },
            {
              content: discoveredAccount.lastUsedTime,
              rowSpan: rowSpan,
            },
          ]
        } else {
          discoveredAccountPDFTableObj.content = [
            { content: discoveredAccount.database, rowSpan: 1 },
            { content: discoveredAccount.databaseUser, rowSpan: 1 },
            { content: discoveredAccount.databasePredefinedRoles, rowSpan: 1 },
            { content: discoveredAccount.databaseCustomRoles, rowSpan: 1 },
          ]
        }
      } else {
        // SQLServer user type
        discoveredAccountPDFTableObj.content = [
          { content: discoveredAccount.databaseAccount },
          { content: discoveredAccount.serverPredefinedRoles },
          { content: discoveredAccount.serverCustomRoles },
          { content: discoveredAccount.database },
          { content: discoveredAccount.databaseUser },
          { content: discoveredAccount.databasePredefinedRoles },
          { content: discoveredAccount.databaseCustomRoles },
          { content: discoveredAccount.status },
          { content: discoveredAccount.controlsApplied },
          { content: discoveredAccount.usersLast7Days },
          { content: discoveredAccount.lastUsedTime },
        ]
      }
    }
    discoveredAccountsPDFTable.push(discoveredAccountPDFTableObj)
  })
  return discoveredAccountsPDFTable
}
