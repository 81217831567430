import { Box, Stack, Typography } from '@mui/material'
import ReportGmailerrorredOutlinedIcon from '@mui/icons-material/ReportGmailerrorredOutlined'

const ErrorState = () => {
  return (
    <Box sx={{ pt: 4 }}>
      <Stack spacing={2} sx={{ alignItems: 'center' }}>
        <ReportGmailerrorredOutlinedIcon sx={{ fontSize: 64, color: 'error.main' }} />
        <Typography variant="h6" sx={{ color: 'text.secondary' }}>
          An error occurred.
        </Typography>
      </Stack>
    </Box>
  )
}

export default ErrorState
