import { Box, Stack, Typography } from '@mui/material'

import SidecarHeaderButton from '../SidecarHeaderButton'

const SidecarError = () => {
  return (
    <Box sx={{ pt: 4 }}>
      <Stack spacing={1}>
        <SidecarHeaderButton />

        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          An error occurred while fetching sidecar details.
        </Typography>
      </Stack>
    </Box>
  )
}

export default SidecarError
