import React from 'react'
import {
  FormControl,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import styled from '@emotion/styled'
import { Delete, Check, Close } from '@material-ui/icons'

import { Button } from '@jeeves/components/Primitives'
import { SectionTitle } from '../../../Text'
import IDPSelect from '@jeeves/pages/RepositoryDetail/Tabs/Advanced/components/IDPSelect'

const AddMappingTableCell = styled(TableCell)`
  padding-top: 10px;
  padding-bottom: 10px;
`

const ZeroStateCell = styled(TableCell)`
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
`

const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px 30px;
`

const TableWrapper = styled.div`
  padding-left: 30px;
  padding-right: 30px;
`

const MappingsForm = props => {
  const {
    mappings = [],
    loading = false,
    setMappingsNameInput,
    setMappingsConnectionInput,
    mappingsNameInput,
    mappingsConnectionInput,
    connectionNames = [],
    handleOnDeleteMapping,
    handleOnAddMapping,
    handleOnCancelMappingInput,
    setAdding,
    adding,
  } = props

  return (
    <div>
      <SectionTitle variant="h6">Map SSO groups to this role</SectionTitle>
      <TableWrapper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>SSO Group Name</TableCell>
              <TableCell>Identity Provider</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mappings.length === 0 && !loading && !adding && (
              <TableRow>
                <ZeroStateCell colSpan={3}>
                  {connectionNames.length === 0
                    ? `There are no SSO integrations configured`
                    : `There are no mappings for the current role`}
                </ZeroStateCell>
              </TableRow>
            )}
            {loading && (
              <TableRow>
                <ZeroStateCell colSpan={3}>Loading mappings...</ZeroStateCell>
              </TableRow>
            )}
            {mappings.map(mapping => (
              <TableRow key={mapping.id}>
                <TableCell>{mapping.groupName}</TableCell>
                <TableCell>{mapping.connectionName}</TableCell>
                <TableCell align="right">
                  <IconButton onClick={() => handleOnDeleteMapping(mapping.id)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            {adding && (
              <TableRow>
                <AddMappingTableCell>
                  <OutlinedInput
                    placeholder="Group name"
                    value={mappingsNameInput}
                    onChange={e => setMappingsNameInput(e.target.value)}
                  />
                </AddMappingTableCell>
                <AddMappingTableCell>
                  <IDPSelect
                    setIDP={idp => setMappingsConnectionInput(idp)}
                    idp={mappingsConnectionInput}
                    connections={connectionNames}
                  />
                </AddMappingTableCell>
                <AddMappingTableCell align="right">
                  <IconButton
                    onClick={() => {
                      handleOnAddMapping()
                      setAdding(false)
                    }}
                  >
                    <Check />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setAdding(false)
                      handleOnCancelMappingInput()
                    }}
                  >
                    <Close />
                  </IconButton>
                </AddMappingTableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableWrapper>
      <ActionButtons>
        <Button
          disabled={adding || connectionNames.length === 0}
          onClick={() => setAdding(true)}
          variant="outlined"
        >
          Add new Mapping
        </Button>
      </ActionButtons>
    </div>
  )
}

export default MappingsForm
