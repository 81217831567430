import { useFormContext } from 'react-hook-form'
import { FormControl, FormHelperText } from '@mui/material'
import { Input, InputLabel } from '@jeeves/new-components'
import { NumNodes } from './shared'

const SRVRecordFields = () => {
  const { register, formState } = useFormContext()

  const SRVRecordError = formState.errors?.SRVRecord

  return (
    <>
      <FormControl variant="standard" error={Boolean(SRVRecordError)}>
        <InputLabel htmlFor="SRVRecord" required>
          SRV record
        </InputLabel>
        <Input id="SRVRecord" inputProps={{ ...register('SRVRecord') }} />
        <FormHelperText
          sx={{
            typography: 'body2',
          }}
          error={Boolean(SRVRecordError)}
        >
          {SRVRecordError?.message}
        </FormHelperText>
      </FormControl>
      <NumNodes />
    </>
  )
}

export default SRVRecordFields
