import React, { forwardRef } from 'react'
import Stack from '@mui/material/Stack'
import InputAdornment from '@mui/material/InputAdornment'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import { FormHelperText } from '@mui/material'

import { IconButton, IconCopyButton, Input, InputGroup } from '@jeeves/new-components'

import { ConnectionInfoProvider, useConnectionInfo } from './ConnectionInfoContext'

export const INPUT_ADDON_WIDTH = '90px'

const ConnectionInfoFields = ({ children, connectionInfo }) => {
  return (
    <ConnectionInfoProvider connectionInfo={connectionInfo}>
      <Stack spacing={0.5}>{children}</Stack>
    </ConnectionInfoProvider>
  )
}

const Host = forwardRef((props, ref) => {
  const { host } = useConnectionInfo()

  return (
    <InputGroup>
      <InputGroup.Text
        sx={{
          minWidth: INPUT_ADDON_WIDTH,
        }}
      >
        Host
      </InputGroup.Text>
      <Input
        defaultValue={host}
        endAdornment={
          <InputAdornment position="end" sx={{ marginLeft: 0 }}>
            <IconCopyButton text={host} />
          </InputAdornment>
        }
        sx={{
          typography: 'code',
        }}
        fullWidth
        readOnly
      />
    </InputGroup>
  )
})

const Port = forwardRef((props, ref) => {
  const { ports } = useConnectionInfo()
  const displayPorts = ports.join(', ')

  return (
    <InputGroup>
      <InputGroup.Text
        sx={{
          minWidth: INPUT_ADDON_WIDTH,
        }}
      >
        Port
      </InputGroup.Text>
      <Input
        defaultValue={displayPorts}
        endAdornment={
          <InputAdornment position="end" sx={{ marginLeft: 0 }}>
            <IconCopyButton text={displayPorts} />
          </InputAdornment>
        }
        sx={{
          typography: 'code',
        }}
        fullWidth
        readOnly
      />
    </InputGroup>
  )
})

const Username = forwardRef((props, ref) => {
  const { username } = useConnectionInfo()

  return (
    <InputGroup>
      <InputGroup.Text
        sx={{
          minWidth: INPUT_ADDON_WIDTH,
        }}
      >
        Username
      </InputGroup.Text>
      <Input
        defaultValue={username}
        endAdornment={
          <InputAdornment position="end" sx={{ marginLeft: 0 }}>
            <IconCopyButton text={username} />
          </InputAdornment>
        }
        sx={{
          typography: 'code',
        }}
        fullWidth
        readOnly
      />
    </InputGroup>
  )
})

const Password = forwardRef(({ showPassword, setShowPassword, setAccessToken }, ref) => {
  const { password } = useConnectionInfo()

  return (
    <>
      <Stack spacing={1}>
        <InputGroup>
          <InputGroup.Text
            sx={{
              minWidth: INPUT_ADDON_WIDTH,
            }}
          >
            Password
          </InputGroup.Text>
          <Input
            type={showPassword ? 'text' : 'password'}
            onChange={e => setAccessToken(e.target.value)}
            placeholder="Enter access token"
            endAdornment={
              <InputAdornment position="end" sx={{ marginLeft: 0 }}>
                {password && (
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? (
                      <VisibilityOffOutlinedIcon
                        fontSize="small"
                        sx={{ color: 'cyralColors.grey.300' }}
                      />
                    ) : (
                      <VisibilityOutlinedIcon
                        fontSize="small"
                        sx={{ color: 'cyralColors.grey.300' }}
                      />
                    )}
                  </IconButton>
                )}
                <IconCopyButton text={password} />
              </InputAdornment>
            }
            sx={{
              typography: 'code',
            }}
            fullWidth
          />
        </InputGroup>
        <FormHelperText sx={{ typography: 'body2' }}>
          Click <i>Manage Access Tokens</i> to generate a token.
        </FormHelperText>
      </Stack>
    </>
  )
})

const Database = forwardRef(({ setDatabase }, ref) => {
  const { database } = useConnectionInfo()

  return (
    <InputGroup>
      <InputGroup.Text
        sx={{
          minWidth: INPUT_ADDON_WIDTH,
        }}
      >
        Database
      </InputGroup.Text>
      <Input
        placeholder="Enter database name"
        defaultValue={database}
        onChange={e => setDatabase(e.target.value)}
        sx={{
          typography: 'code',
        }}
        fullWidth
      />
    </InputGroup>
  )
})

export { Host, Port, Username, Password, Database }
export default ConnectionInfoFields
