/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'

import { SvgIcon } from '@material-ui/core'

export const SidecarIcon = props => {
  return (
    <SvgIcon
      {...props}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10 2.07735C10.6188 1.72008 11.3812 1.72008 12 2.07735L18.2272 5.67265C18.846 6.02992 19.2272 6.69017 19.2272 7.4047V14.5953C19.2272 15.3098 18.846 15.9701 18.2272 16.3274L12 19.9226C11.3812 20.2799 10.6188 20.2799 10 19.9226L3.77276 16.3274C3.15396 15.9701 2.77276 15.3098 2.77276 14.5953V7.4047C2.77276 6.69017 3.15396 6.02992 3.77276 5.67265L10 2.07735Z" />
    </SvgIcon>
  )
}
