import { Box, Typography, FormControlLabel, Checkbox } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'

const AllowPassthroughAuthentication = () => {
  const { control } = useFormContext()

  return (
    <Box>
      <Controller
        name="allowNativeAuthentication"
        control={control}
        render={({ field: { onChange, value, name } }) => (
          <FormControlLabel
            control={<Checkbox name={name} onChange={onChange} checked={value} />}
            label={
              <Typography variant="h4" sx={{ color: 'text.primary' }}>
                Allow passthrough authentication.
              </Typography>
            }
          />
        )}
      />
      <Typography variant="body2" sx={{ color: 'text.secondary', ml: 4 }}>
        Database account credentials can be used to authenticate to the database.
      </Typography>
    </Box>
  )
}

export default AllowPassthroughAuthentication
