/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import styled from '@emotion/styled'

import blueLogoUrl from './Cyral_logo_for_web.png'
import cyralImage from './Vector.svg'
import cyralLogo from './Vector-1.svg'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 72px;
`

const LogoImage = styled.img`
  max-width: 100%;
  height: 24px;
`

export const BlueLogo = props => (
  <Container {...props}>
    <img
      src={blueLogoUrl}
      alt="Cyral"
      css={() => css`
        display: block;
        width: 100%;
      `}
    />
  </Container>
)

const Logo = ({ iconOnly = false, ...props }) => (
  <Container {...props}>
    <LogoImage src={cyralImage} alt="Cyral Inc." />
    {!iconOnly && (
      <LogoImage
        src={cyralLogo}
        alt="Cyral Inc."
        css={() => css`
          height: 20px;
          margin-left: 8px;
        `}
      />
    )}
  </Container>
)

export default Logo
