/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import React from 'react'
import { jsx, css } from '@emotion/react'
import styled from '@emotion/styled'

import { IconButton, Typography } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'

import { useAuth } from '@jeeves/hooks'
import { DeleteIntegrationButton, IntegrationCard } from '@jeeves/pages/Integrations/primitives'

import LoggingIntegrationIcon from '../LoggingIntegrationIcon'
import { useLoggingIntegration } from './useLoggingIntegration'

const IntegrationItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const IntegrationInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

const LoggingIntegration = ({ integration, onEdit }) => {
  const { hasPermission } = useAuth()

  const { loading, deleteLoggingIntegration } = useLoggingIntegration({
    integration,
  })

  return (
    <IntegrationCard>
      <IntegrationItem>
        <IntegrationInfo>
          <LoggingIntegrationIcon integrationType={integration.__typename} />
          <Typography variant="body1" align="center">
            {integration?.name}
          </Typography>
        </IntegrationInfo>
        <div>
          <IconButton disabled={!hasPermission('integrations:update')} onClick={onEdit}>
            <EditIcon />
          </IconButton>
          <DeleteIntegrationButton
            onDelete={deleteLoggingIntegration}
            disabled={!hasPermission('integrations:delete') || loading}
          />
        </div>
      </IntegrationItem>
    </IntegrationCard>
  )
}

export default LoggingIntegration
