import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'

import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'

import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableFooter from '@material-ui/core/TableFooter'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'

import { TablePagination } from '../../../components/Table'

import WithFakeData from './WithFakeData'
import Row from './Row'

const styles = theme => ({})

class Invoices extends Component {
  state = {
    rows: this.props.rows,
    columns: this.props.columns,
    page: 0,
    rowsPerPage: 5,
  }

  handleChangePage = (event, page) => {
    this.setState({ page })
  }

  handleChangeRowsPerPage = event => {
    this.setState({ page: 0, rowsPerPage: event.target.value })
  }

  render() {
    const { rows, columns, rowsPerPage, page } = this.state

    return (
      <Paper>
        <Toolbar>
          <Typography variant="h6" id="Invoices">
            Invoices
          </Typography>
        </Toolbar>

        <Table>
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell numeric={column.numeric} key={column.id}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => (
              <Row row={row} />
            ))}
          </TableBody>

          <TableFooter>
            <TableRow>
              <TablePagination
                count={rows.length}
                colSpan={5}
                page={page}
                rowsPerPage={rowsPerPage}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Paper>
    )
  }
}

export default withStyles(styles)(WithFakeData(Invoices))
