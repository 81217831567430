import { useQuery } from '@apollo/client'
import { Container, Stack, Typography } from '@mui/material'

import { graphql } from '@jeeves/graphql'

import { AccessTokens, ErrorState, LoadingState } from './components'

const SETTINGS_QUERY = graphql(`
  query Settings {
    ...AccessTokens_QueryFragment
  }
`)

const Settings = () => {
  const { data, error, loading } = useQuery(SETTINGS_QUERY)

  return (
    <Container maxWidth="xl">
      <Stack spacing={4}>
        <Typography variant="h2" sx={{ color: 'text.primary' }}>
          Settings
        </Typography>

        {loading ? <LoadingState /> : error ? <ErrorState /> : <AccessTokens query={data!} />}
      </Stack>
    </Container>
  )
}

export default Settings
