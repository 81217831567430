import { Box, Typography } from '@mui/material'

import { Accordion, AccordionDetails, AccordionSummary } from '@jeeves/new-components'
import { FragmentType, graphql, useFragment } from '@jeeves/graphql'

import HealthIndicator from '../HealthIndicator'

const Services_SidecarInstanceFragment = graphql(`
  fragment Services_SidecarInstanceFragment on SidecarInstance {
    id
    services {
      name
      health
    }
  }
`)

interface Props {
  sidecarInstance: FragmentType<typeof Services_SidecarInstanceFragment>
}

const GRID_COLUMNS = 3
const MIN_SERVICES_PER_COLUMN = 10

const Services = ({ sidecarInstance }: Props) => {
  const { services } = useFragment(Services_SidecarInstanceFragment, sidecarInstance)

  const SERVICES_PER_COLUMN = Math.max(
    MIN_SERVICES_PER_COLUMN,
    Math.ceil(services.length / GRID_COLUMNS)
  )

  return (
    <Box>
      <Accordion>
        <AccordionSummary sx={{ p: 4 }}>
          <Typography variant="h4" sx={{ color: 'text.primary' }}>
            Services
          </Typography>
        </AccordionSummary>

        <AccordionDetails sx={{ px: 4, pb: 4, pt: 0 }}>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: `repeat(${GRID_COLUMNS}, auto)`,
              gridTemplateRows: `repeat(${SERVICES_PER_COLUMN}, 1fr)`,
              gridAutoFlow: 'column',
              gap: 1,
              justifyContent: 'space-between',
            }}
          >
            {services.map(service => {
              return (
                <Box
                  key={service.name}
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: 'auto 1fr',
                    gap: 1,
                  }}
                >
                  <Typography variant="h6" sx={{ color: 'text.secondary' }}>
                    {service.name}:
                  </Typography>
                  <Box
                    sx={{
                      justifySelf: 'end',
                    }}
                  >
                    <HealthIndicator health={service.health} />
                  </Box>
                </Box>
              )
            })}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

export default Services
