/** @jsxRuntime classic */
/** @jsx jsx */
import styled from '@emotion/styled'
import { jsx, css } from '@emotion/react'
import { Fragment, useState } from 'react'
import {
  Card,
  DialogContent,
  Grid,
  IconButton,
  TextField as MuiTextField,
  Typography,
} from '@material-ui/core/'
import DeleteIcon from '@material-ui/icons/Delete'
import { grey } from '@material-ui/core/colors'

import { Button } from '@jeeves/components/Primitives'
import ConfirmDialog from '@jeeves/components/ConfirmDialog'
import Loading, { ButtonLoading } from '@jeeves/components/Loading'

const IntegrationDialogContent = styled(DialogContent)`
  padding: ${({ theme }) => theme.spacing[6]};
  background-color: #fafafa;
`

const IntegrationLoading = () => (
  <div
    css={css`
      position: relative;
      min-height: 48px;
      overflow: auto;
      min-width: 200px;
    `}
  >
    <Loading />
  </div>
)

const StateInfo = ({ children }) => (
  <div
    css={css`
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      min-height: 48px;
      overflow: auto;
      min-width: 200px;
      margin: 0 auto;
    `}
  >
    <Typography variant="caption" css={theme => ({ fontSize: theme.fontSize[3] })}>
      {children}
    </Typography>
  </div>
)

const ZeroState = () => <StateInfo>No Integrations added yet</StateInfo>
const ErrorState = () => <StateInfo>There was an error while loading the integrations</StateInfo>

const AddIntegrationButtonUnformatted = ({ disabled, setOpen }) => (
  <Button
    color="primary"
    variant="outlined"
    variant="contained"
    onClick={setOpen}
    disabled={Boolean(disabled)}
  >
    New Integration
  </Button>
)

const AddIntegrationButton = ({ disabled, setOpen }) => (
  <div
    css={css`
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    `}
  >
    <AddIntegrationButtonUnformatted disabled={disabled} setOpen={setOpen} />
  </div>
)

const IntegrationList = styled(Grid)`
  margin-bottom: ${({ theme }) => theme.spacing[3]};
`

const TextField = styled(MuiTextField)`
  margin-bottom: ${({ theme }) => theme.spacing[3]};
`

TextField.defaultProps = {
  fullWidth: true,
}

const Label = styled(Typography)`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: bold;
  width: 30%;
  min-width: 160px;
`

const Value = styled(Typography)`
  font-family: ${({ theme, monospaced }) =>
    monospaced ? theme.typography.monospaced : theme.typography.fontFamily};
  color: ${grey[600]};
  flex-grow: 1;
  overflow-wrap: anywhere;
`

const LabelValuePair = styled.div`
  display: flex;
  flex-direction: row;
`

const IntegrationTitle = styled(Typography)`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: ${({ theme }) => theme.fontSize[5]};
  margin-bottom: ${({ theme }) => theme.spacing[5]};
  white-space: pre;
`

const IntegrationCard = styled(Card)`
  margin-bottom: ${({ theme }) => theme.spacing[5]};
  padding: ${({ theme }) => theme.spacing[5]};
  position: 'relative';
`

const IntegrationHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
`

const DeleteIntegrationButton = ({ onDelete, ...props }) => {
  const [open, setOpen] = useState(false)
  const [deleting, setDeleting] = useState(false)

  const handleOnDelete = () => {
    onDelete()
    setOpen(false)
  }

  return (
    <Fragment>
      <IconButton onClick={() => setOpen(true)} {...props}>
        <DeleteIcon />
      </IconButton>

      <ConfirmDialog
        open={open}
        setOpen={setOpen}
        title="Are you sure you want to delete this integration?"
        description="This action cannot be undone"
        confirmButton={
          <div css={{ position: 'relative' }}>
            <Button
              onClick={async () => {
                setDeleting(true)
                await handleOnDelete()
                setDeleting(false)
              }}
              color="secondary"
              variant="contained"
              disabled={deleting}
            >
              Delete Integration
            </Button>
            {deleting && <ButtonLoading />}
          </div>
        }
      />
    </Fragment>
  )
}

const IntegrationFieldList = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
`

const IntegrationEditActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

export {
  AddIntegrationButtonUnformatted,
  AddIntegrationButton,
  DeleteIntegrationButton,
  ErrorState,
  IntegrationCard,
  IntegrationDialogContent,
  IntegrationEditActions,
  IntegrationFieldList,
  IntegrationHeader,
  IntegrationList,
  IntegrationLoading,
  IntegrationTitle,
  Label,
  LabelValuePair,
  TextField,
  Value,
  ZeroState,
}
