import React from 'react'
import MaterialDialog from '@material-ui/core/Dialog'
import { withStyles } from '@material-ui/core'

const styles = theme => ({
  paper: {
    borderRadius: '12px',
  },
})

const InnerDialog = ({ classes, children, ...props }) => (
  <MaterialDialog classes={{ paper: classes.paper }} {...props}>
    {children}
  </MaterialDialog>
)

export const Dialog = withStyles(styles)(InnerDialog)
