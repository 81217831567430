import styled from '@emotion/styled'

export const TableLink = styled.span`
  color: ${({ theme }) => theme.palette.navyBlue};
  font-weight: normal;
  font-size: 17px;

  a {
    text-decoration: none;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`
