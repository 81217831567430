import { gql, useMutation } from '@apollo/client'
import { isMongoDbClusterRepo } from '@jeeves/graphql/utils'
import { useToast } from '@jeeves/new-components'

export const useEditRepo_repoFragment = gql`
  fragment useEditRepo_repo on Repo {
    id
    ... on MongoDBCluster {
      clusterSpecification {
        ... on StaticNodeSpecification {
          staticNodes {
            host
            port
          }
        }
        ... on SRVRecordSpecification {
          SRVRecord
        }
      }
    }
  }
`

const UPDATE_REPO = gql(`
  mutation UpdateRepo($updateRepoId: ID!, $input: UpdateRepoInput!) {
    updateRepo(id: $updateRepoId, input: $input) {
      repo {
        id
        tags
        ... on MongoDBCluster {
          clusterSpecification {
            ... on StaticNodeSpecification {
              staticNodes {
                host
                port
              }
            }
            ... on SRVRecordSpecification {
              SRVRecord
            }
          }
        }
        ... on MongoDBReplicaSetRepo {
          replicaSetName
        }
        ... on ClusterRepo {
          numNodes
        }
        ... on StandaloneRepo {
          endpoint {
            host
            port
          }
        }
      }
    }
  }
`)

export const useEditRepo = repo => {
  const { toast } = useToast()

  const [updateRepo] = useMutation(UPDATE_REPO, {
    onError: () => {
      toast({
        variant: 'error',
        description: 'An error ocurred when editing this repo, please try again.',
      })
    },
  })

  const tagsInput = tags => tags?.map(tag => tag.name) || []

  const mongoDBReplicaSetRepoInput = data => {
    const { connectionFormat, nodes, numNodes, replicaSetName, SRVRecord, tags } = data

    const clusterSpecification =
      connectionFormat === 'StaticNodeSpecification' ? { staticNodes: nodes } : { SRVRecord }

    return {
      replicaSet: {
        clusterSpecification,
        numNodes,
        replicaSetName,
        tags: tagsInput(tags),
      },
    }
  }

  const mongoDBShardedClusterRepoInput = data => {
    const { connectionFormat, nodes, numNodes, SRVRecord, tags } = data

    const clusterSpecification =
      connectionFormat === 'StaticNodeSpecification' ? { staticNodes: nodes } : { SRVRecord }

    return {
      shardedCluster: {
        clusterSpecification,
        numNodes,
        tags: tagsInput(tags),
      },
    }
  }

  const mongoDBClusterInput = data => {
    let mongoDBCluster = {}

    if (repo.__typename === 'MongoDBReplicaSetRepo') {
      mongoDBCluster = mongoDBReplicaSetRepoInput(data)
    }

    if (repo.__typename === 'MongoDBShardedCluster') {
      mongoDBCluster = mongoDBShardedClusterRepoInput(data)
    }

    return {
      mongoDBCluster,
    }
  }

  const standaloneRepoInput = data => {
    const { host, port, tags } = data
    return {
      standaloneRepo: {
        endpoint: { host, port },
        tags: tagsInput(tags),
      },
    }
  }

  const getInput = data => {
    if (isMongoDbClusterRepo(repo)) {
      return mongoDBClusterInput(data)
    }

    return standaloneRepoInput(data)
  }

  const onSubmit = async data => {
    await updateRepo({
      variables: {
        updateRepoId: repo.id,
        input: getInput(data),
      },
    })
  }

  return {
    onSubmit,
  }
}
