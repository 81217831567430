import { Stack } from '@mui/material'
import PolicyRule from '../../../../PolicyRule'
import { useRateLimitVariation } from '../useRateLimitVariation'

const Variation1 = () => {
  const { action, limit, dataLabels } = useRateLimitVariation()

  const renderSecondRule = () => {
    if (action === 'ENFORCE') {
      return <PolicyRule title="a rate limit of" content={limit} />
    }

    return <PolicyRule title="if the number of rows read per hour exceeds" content={limit} />
  }

  return (
    <Stack spacing={1}>
      <PolicyRule title="This policy will" content={action} />
      {renderSecondRule()}
      <PolicyRule title="for any data labeled as" content={dataLabels} />
    </Stack>
  )
}

export default Variation1
