import { Stack, Typography } from '@mui/material'

import Card from '@jeeves/pages/RepositoryDetail/Tabs/Policies/components/Card'
import Tag from '@jeeves/pages/RepositoryDetail/Tabs/Policies/components/Tag'

const SelectPolicyTypeCard = ({ name, description, tags, onClick }) => {
  return (
    <Card onClick={onClick} sx={{ cursor: 'pointer' }}>
      <Stack spacing={2}>
        <Stack spacing={2}>
          <Stack direction="row" spacing={1}>
            {tags.map(tag => (
              <Tag key={tag} color="primary" text={tag} />
            ))}

            <Typography variant="h4" sx={{ color: 'text.primary' }}>
              {name}
            </Typography>
          </Stack>
        </Stack>

        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {description}
        </Typography>
      </Stack>
    </Card>
  )
}

export default SelectPolicyTypeCard
