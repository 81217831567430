import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import DescriptionIcon from '@material-ui/icons/Description'
import Link from '@material-ui/core/Link'

const styles = theme => ({
  link: {
    display: 'inline-flex',
    alignItems: 'center',
  },
})

const Row = ({ row, classes }) => {
  return (
    <TableRow key={row.id}>
      <TableCell component="th" scope="row">
        {row.date}
      </TableCell>
      <TableCell>{row.period}</TableCell>
      <TableCell>{row.description}</TableCell>
      <TableCell>{row.amount}</TableCell>

      <TableCell align="right">
        <Link className={classes.link} href={row.pdf}>
          <DescriptionIcon fontSize="small" />
          View Invoice
        </Link>
      </TableCell>
    </TableRow>
  )
}

export default withStyles(styles)(Row)
