import * as React from 'react'
import { useWatch } from 'react-hook-form'
import { useFragment_experimental, gql } from '@apollo/client'

import DefaultPortView from './DefaultPortView'
import MultiplexedRepoPortView from './MultiplexedRepoPortView'
import { MultiplexedRepoPortViewProvider } from './MultiplexedRepoPortView/MultiplexedRepoPortViewContext'
import S3PortView from './S3PortView'
import MongoDBReplicaSetPortView from './MongoDBReplicaSetPortView'

import { repoSupportsPortMultiplexing } from '../utils'
import { isClusterRepo } from '@jeeves/graphql/utils'

const SelectPort_queryFragment = gql`
  fragment SelectPort_query on Query {
    ...MultiplexedRepoPortViewProvider_query
    repos {
      id
      type
      ... on ClusterRepo {
        numNodes
      }
    }
  }
  ${MultiplexedRepoPortViewProvider.fragments.MultiplexedRepoPortViewProvider_queryFragment}
`

const SelectPort = ({ query }) => {
  const { data } = useFragment_experimental({
    fragment: SelectPort_queryFragment,
    fragmentName: 'SelectPort_query',
    from: query,
  })
  const selectedRepoId = useWatch({
    name: 'selectedRepo',
  })

  const selectedRepo = data.repos.find(repo => repo.id === selectedRepoId)

  const isSelectClusterRepo = isClusterRepo(selectedRepo?.__typename)

  if (isSelectClusterRepo) {
    return <MongoDBReplicaSetPortView selectedRepo={selectedRepo} />
  }

  if (selectedRepo?.type === 's3') {
    return <S3PortView />
  }

  if (repoSupportsPortMultiplexing(selectedRepo?.type)) {
    return (
      <MultiplexedRepoPortViewProvider query={data} selectedRepo={selectedRepo}>
        <MultiplexedRepoPortView />
      </MultiplexedRepoPortViewProvider>
    )
  }

  return <DefaultPortView />
}

SelectPort.fragments = {
  SelectPort_queryFragment,
}

export default SelectPort
