import { BaseWizard } from '../common'

import { StepOne, StepThreeV2 as StepThree, StepFour, StepReview } from '../common/steps'
import { StepTwo } from './steps'

import useDataFirewall from './useDataFirewall'

const DataFirewall = ({ policy = {}, template }) => {
  const {
    activeStep,
    snackBarOpen,
    loading,
    errorMessage,
    setDataFirewallInfo,
    dataFirewallInfo,
    prevStep,
    nextStep,
    handlePolicySubmit,
    handleCloseSnackbar,
  } = useDataFirewall({ policy, template })

  const steps = [
    {
      title: 'Describe the Data Firewall policy',
      component: (
        <StepOne
          nextStep={nextStep}
          setPolicyInfo={setDataFirewallInfo}
          policyInfo={dataFirewallInfo}
        />
      ),
    },
    {
      title: 'Specify who can see restricted records',
      component: (
        <StepTwo
          prevStep={prevStep}
          nextStep={nextStep}
          setDataFirewallInfo={setDataFirewallInfo}
          dataFirewallInfo={dataFirewallInfo}
        />
      ),
    },
    {
      title: 'Specify when to apply this policy',
      component: (
        <StepThree
          prevStep={prevStep}
          nextStep={nextStep}
          setPolicyInfo={setDataFirewallInfo}
          policyInfo={dataFirewallInfo}
        />
      ),
    },
    {
      title: 'Specify how records will be restricted',
      component: (
        <StepFour
          prevStep={prevStep}
          nextStep={nextStep}
          setPolicyInfo={setDataFirewallInfo}
          policyInfo={dataFirewallInfo}
        />
      ),
    },
    {
      title: 'Review the policy',
      component: (
        <StepReview
          policyInfo={dataFirewallInfo}
          prevStep={prevStep}
          handlePolicySubmit={handlePolicySubmit}
          isSubmitting={loading}
        />
      ),
    },
  ]

  return (
    <BaseWizard
      steps={steps}
      activeStep={activeStep}
      snackBarOpen={snackBarOpen}
      handleCloseSnackbar={handleCloseSnackbar}
      errorMessage={errorMessage}
    />
  )
}

export default DataFirewall
