/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { useEffect, useState } from 'react'
import { Fade } from '@material-ui/core/'
import { IntegrationCard } from '@jeeves/pages/Integrations/primitives'
import View from '../view/View'
import Edit from '../update/Edit'

export default ({
  integration = null,
  putIntegration,
  deleteIntegration,
  creating = false,
  last,
}) => {
  const [editing, setEditing] = useState(false)
  const configureEditing = () => setEditing(creating)

  useEffect(() => {
    configureEditing()
  }, [])

  const onDelete = async () => {
    await deleteIntegration(integration.id)
    setEditing(false)
  }

  return (
    <IntegrationCard css={last && { marginBottom: '0' }}>
      {!editing && integration && (
        <Fade in={!editing}>
          <View integration={integration} setEditing={() => setEditing(true)} onDelete={onDelete} />
        </Fade>
      )}
      {editing && (
        <Fade in={editing}>
          <Edit
            integration={integration}
            handleSetEdit={setEditing}
            putIntegration={putIntegration}
          />
        </Fade>
      )}
    </IntegrationCard>
  )
}
