import * as React from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import PeopleIcon from '@mui/icons-material/People'

import { Button } from '@jeeves/new-components'

import { useRepositoryDetailContext } from '@jeeves/pages/RepositoryDetail/contexts/RepositoryDetailContext'
import { useUserAuthenticationContext } from '@jeeves/pages/RepositoryDetail/Tabs/UserAuthentication/contexts/UserAuthenticationContext'

const EmptyState = () => {
  const { repoType } = useRepositoryDetailContext()
  const { setIsRegistering } = useUserAuthenticationContext()

  return (
    <Box>
      <Stack spacing={1} sx={{ alignItems: 'center' }}>
        <PeopleIcon sx={{ fontSize: 56, color: 'cyralColors.grey.300' }} />

        <Stack spacing={2} sx={{ alignItems: 'center' }}>
          <Typography variant="h4" sx={{ color: 'text.primary' }}>
            {`Specify information for existing ${
              repoType === 's3' ? 'IAM roles' : 'database accounts'
            }.`}
          </Typography>
          <Button onClick={() => setIsRegistering(true)}>
            {repoType === 's3' ? 'Register IAM Role' : 'Register Database Account'}
          </Button>
        </Stack>
      </Stack>
    </Box>
  )
}

export default EmptyState
