import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button, Dialog } from '@jeeves/new-components'

import { Stack, Typography, DialogTitle, DialogContent, Divider } from '@mui/material'
import DnsIcon from '@mui/icons-material/Dns'

import RequestAccess from './RequestAccess'

export const EmptyStateGraphic = () => {
  return (
    <Stack spacing={4} alignItems="center">
      <Stack spacing={1} alignItems="center" sx={{ maxWidth: '400px' }}>
        <DnsIcon sx={{ fontSize: '4rem' }} color="secondary" />
        <Typography variant="h4">No Data Repositories</Typography>
        <Typography variant="body2" color="secondary" align="center">
          All data repositories you have access to will appear here.
        </Typography>
        <Typography variant="body2" color="secondary" align="center">
          Get started by requesting access.
        </Typography>
      </Stack>
      <RequestAccess />
    </Stack>
  )
}
