/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useState, Fragment } from 'react'
import styled from '@emotion/styled'
import { css, jsx } from '@emotion/react'
import { useForm } from 'react-hook-form'
import { usePopup } from '@jeeves/hooks'

import {
  Button,
  CardActions,
  CircularProgress,
  Divider as MuiDivider,
  Grid,
  Typography,
} from '@material-ui/core/'
import IntegrationPanel from './AddFormPanel'
import { IntegrationCard } from '@jeeves/pages/Integrations/primitives'
import { TextFieldWithToolTipController, UploadZone, FieldDetails } from '../components'
import CopyValue from '@jeeves/components/CopyValue'
import { IntegrationTitle } from '../../../primitives'
import { getDocsURL } from '@jeeves/utils/docsURL'

const FullWidth = styled.div`
  width: 100%;
`

const Divider = styled(MuiDivider)`
  margin-top: -15px;
  margin-bottom: 15px;
  width: 100%;
`

const StyledCircularProgress = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
`

const ActionsContainer = styled(Grid)`
  justify-content: flex-end;
`

const SaveButtonContainer = styled.div`
  position: relative;
`

const Link = styled.a`
  text-decoration: none;
  color: #26459e;
  font-weight: 600;
`

const AddFormDraft = ({
  addIntegration,
  handleRemoveAddField,
  setOpenIntegration,
  integration,
}) => {
  const [loading, setLoading] = useState(false)
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm()
  const { setPopup, popupTypes } = usePopup()

  const onSubmit = async ({ samlMetadataURL }) => {
    try {
      const newIntegration = {
        identityProvider: 'pingone',
        displayName: integration.integration.displayName,
        alias: integration.integration.alias,
        samlMetadataURL,
      }

      await handleOnAddIntegration(newIntegration)
      setPopup(popupTypes.SUCCESS, 'Success!', {
        displayTime: 4000,
      })
      handleRemoveAddField()
    } catch (e) {
      console.error(e)
      throw e
    }
  }

  const handleOnAddIntegration = async integration => {
    try {
      setLoading(true)
      await addIntegration(integration)
    } catch (e) {
      throw e
    } finally {
      setLoading(false)
    }
  }

  return (
    <FullWidth>
      <IntegrationTitle
        css={{
          marginBottom: '2rem',
        }}
      >
        {integration.integration.displayName}
      </IntegrationTitle>
      <Divider />

      <div
        css={{ display: 'grid', gridTemplate: 'auto auto / 210px auto 60px', marginBottom: '12px' }}
      >
        {integration.integration?.urls?.ACSUrls.map((ACSUrl, index) => (
          <CopyValue label={index === 0 ? 'ACS URLs' : ''} value={ACSUrl} />
        ))}

        <CopyValue label="Entity ID" value={integration.integration?.urls?.EntityID} />
        <CopyValue label="SLO Endpoint" value={integration.integration?.urls?.SLOEndpoint} />
        <CopyValue
          label="SLO Response Endpoint"
          value={integration.integration?.urls?.SLOResponseEndpoint}
        />
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography
          css={{
            marginBottom: '1rem',
          }}
        >
          Make sure you’ve created your SAML app in PingOne as explained{' '}
          <Link
            href={getDocsURL({ docsPath: '/integrations/authentication/idp/pingone' })}
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </Link>
          . From the SAML app, copy the SAML Metadata URL and paste it here. Click Save to deploy
          this integration. Click Cancel to finish configuration at a later time and keep this SAML
          integration as a draft.
        </Typography>

        <TextFieldWithToolTipController
          label="SAML Metadata URL"
          tooltipText="SAML Metadata URL."
          name="samlMetadataURL"
          control={control}
        />

        <ActionsContainer item container xs={12}>
          <CardActions>
            <Button
              variant="outlined"
              size="small"
              color="secondary"
              onClick={handleRemoveAddField}
            >
              Cancel
            </Button>
            <SaveButtonContainer>
              <Button
                variant="contained"
                type="submit"
                size="small"
                color="primary"
                disabled={isSubmitting}
              >
                Save
              </Button>
              {isSubmitting && <StyledCircularProgress size={24} />}
            </SaveButtonContainer>
          </CardActions>
        </ActionsContainer>
      </form>
    </FullWidth>
  )
}

const AddModal = props => (
  <IntegrationCard>
    <AddFormDraft {...props} />
  </IntegrationCard>
)

export default AddModal
