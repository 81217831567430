import { Button } from '@jeeves/new-components'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { Stack } from '@mui/material'

interface Props {
  page: number
  onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void
  totalPages: number
}

const PaginationActions = ({ page, onPageChange, totalPages }: Props) => {
  const hasPreviousPage = page > 0
  const hasNextPage = page < totalPages - 1

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1)
  }

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1)
  }

  return (
    <Stack sx={{ width: '100%', justifyContent: 'flex-end' }} direction="row" spacing={2}>
      {hasPreviousPage && (
        <Button
          variant="text"
          color="secondary"
          onClick={handleBackButtonClick}
          startIcon={<ChevronLeft />}
        >
          Back
        </Button>
      )}
      {hasNextPage && (
        <Button
          variant="text"
          color="secondary"
          onClick={handleNextButtonClick}
          endIcon={<ChevronRight />}
        >
          Next
        </Button>
      )}
    </Stack>
  )
}

export default PaginationActions
