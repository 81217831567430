import * as React from 'react'
import Stack from '@mui/material/Stack'

import ProxyPort from './ProxyPort'
import EnableS3Browser from './EnableS3Browser'

const S3PortView = () => {
  return (
    <Stack spacing={2}>
      <ProxyPort />
      <EnableS3Browser />
    </Stack>
  )
}

export default S3PortView
