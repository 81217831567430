import {
  Box,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'

import {
  Button,
  Dialog,
  DialogActions,
  IconCopyButton,
  Input,
  InputLabel,
} from '@jeeves/new-components'
import { useCreateSidecarCredential } from '../hooks'

const sidecarExpressURL =
  'https://raw.githubusercontent.com/cyral-quickstart/quickstart-sidecar-express/main/install-sidecar.sh'

const ExpressDeploymentModal = () => {
  const [createSidecarCredential, { data, loading, reset }] = useCreateSidecarCredential()

  const isModalOpen = !!data

  const sidecarId = data?.createSidecarCredential?.sidecarCredential?.sidecar.id
  const clientId = data?.createSidecarCredential?.sidecarCredential?.clientId
  const clientSecret = data?.createSidecarCredential?.clientSecret
  const controlPlaneURL = (window as any)._env_.control_plane_address || '<CONTROL_PLANE_URL>'

  const command =
    `CLIENT_ID=${clientId} ` +
    `CLIENT_SECRET=${clientSecret} ` +
    `SIDECAR_ID=${sidecarId} ` +
    `CONTROL_PLANE=${controlPlaneURL} ` +
    `bash -c "$(curl -fsSL ${sidecarExpressURL})"`

  const onClose = () => {
    reset()
  }

  const onOpen = async () => {
    await createSidecarCredential()
  }

  return (
    <>
      <Button variant="contained" sx={{ flexShrink: 0 }} onClick={onOpen} loading={loading}>
        Generate Install Command
      </Button>

      <Dialog open={isModalOpen} onClose={onClose} fullWidth>
        <DialogTitle
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h3" sx={{ color: 'text.primary' }}>
            Express Install Command
          </Typography>
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <InputLabel>Run the following command on a Linux VM to deploy the sidecar:</InputLabel>
            <Input
              sx={{
                fontFamily: theme => theme.typography.code,
              }}
              value={command}
              type="text"
              endAdornment={
                <InputAdornment position="end">
                  <IconCopyButton text={command} />
                </InputAdornment>
              }
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Stack direction="row" sx={{ justifyContent: 'flex-end' }}>
            <Button variant="contained" color="primary" onClick={onClose}>
              Done
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ExpressDeploymentModal
