/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import styled from '@emotion/styled'
import { useState, useEffect, Fragment } from 'react'
import { useAuth } from '@jeeves/components/Auth'
import ExpressClient from '@jeeves/clients/express'
import { get as lodashGet, cloneDeep } from 'lodash'
import usePopup from '@jeeves/components/PopupMessage/hooks/usePopup'
import Loading from '@jeeves/components/Loading'
import RowSpan from '@jeeves/components/RowSpan'

import { Table, TableBody, TableRow, Typography, TableCell } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'

import { TableHead } from '@jeeves/components/Table'
import Toolbar from '@jeeves/components/Toolbar'
import { Paper } from '@jeeves/components/Primitives'

import { IdentityRow } from './components'
import { IdentityType } from './constants'

import { IDPMenuItemContent } from '@jeeves/pages/RepositoryDetail/Tabs/Advanced/components/IDPSelect'

const DirectoryLabel = styled(Typography)`
  background-color: ${({ theme }) => theme.palette.neutral.light};
  margin: 0 -8px;
  border-radius: 6px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 24px;
`

const COLUMNS = [
  {
    id: 'identityName',
    numeric: false,
    disablePadding: false,
    label: 'Identity',
    styles: { width: '20%', border: 'none' },
  },
  {
    id: 'repository',
    numeric: false,
    disablePadding: false,
    label: 'Data Repositories',
    styles: { width: '20%', paddingLeft: '68px', width: '20%', border: 'none' },
  },
  {
    id: 'repoAccount',
    numeric: false,
    disablePadding: false,
    label: 'Local Accounts',
    styles: { width: '15%', width: '15%', border: 'none' },
  },
  {
    id: 'authorizationPolicy',
    numeric: false,
    disablePadding: false,
    label: 'Access Condition',
    styles: { width: '15%', border: 'none' },
  },
  {
    id: 'created',
    numeric: false,
    disablePadding: false,
    label: 'Created',
    styles: { width: '15%', border: 'none' },
  },
  {
    id: 'expiration',
    numeric: false,
    disablePadding: false,
    label: 'Expiration',
    styles: { width: '15%', border: 'none' },
  },
]

const IDPTableSection = ({ identityProvider, data }) => {
  return (
    <Fragment>
      <TableRow>
        <TableCell
          colSpan={COLUMNS.length}
          css={{ padding: '0 !important', border: 'none', paddingRight: '24px' }}
        >
          <DirectoryLabel>
            {/* <ExpandMore /> {identityProviderDisplayName} */}
            <IDPMenuItemContent connection={identityProvider} standalone />
          </DirectoryLabel>
        </TableCell>
      </TableRow>
      {Object.entries(data).map(([identityName, nameSpecificIdentityMaps], index) => (
        <IdentityRow
          identityProvider={identityProvider}
          identityName={identityName}
          identityMaps={nameSpecificIdentityMaps}
          last={index === Object.entries(data).length - 1}
        ></IdentityRow>
      ))}
    </Fragment>
  )
}

export default function Identities() {
  const { showError } = usePopup()
  const { getTokenSilently } = useAuth()
  const ec = new ExpressClient(getTokenSilently)
  const [identityMaps, setIdentityMaps] = useState(null)
  const [connections, setConnections] = useState([])

  const getIdentityMaps = async () => {
    try {
      const res = await ec.get('/repos/_identities')
      setIdentityMaps(res.data)
    } catch (e) {
      const popupMessage = lodashGet(e, 'response.data.error.message', '')
      showError(popupMessage)
      throw Error(popupMessage)
    }
  }

  const fetchConnections = async () => {
    try {
      setConnections(await ec.get('/users/connections/names').then(res => res.data))
    } catch (e) {
      const popupMessage = lodashGet(e, 'response.data.error.message', '')
      showError(popupMessage)
    }
  }

  useEffect(() => {
    getIdentityMaps()
    fetchConnections()
  }, [])

  return (
    <Paper css={{ width: '100%' }}>
      <Toolbar
        title="Identity Map"
        subtitle="Global read only view of mappings from IDP identities to local accounts for each repository"
        css={{ paddingBottom: '24px' }}
        titleFullWidth
      />
      <div css={{ padding: '0 16px' }}>
        {!identityMaps ? (
          <Table>
            <TableBody>
              <RowSpan colSpan={COLUMNS.length} last>
                <Loading />
              </RowSpan>
            </TableBody>
          </Table>
        ) : (
          <Table>
            <TableHead columns={COLUMNS} rowCount={5} />
            <TableBody>
              {Object.entries(identityMaps).map(([idp, idpSpecificIdentityMaps]) => (
                <IDPTableSection
                  identityProvider={
                    connections.find(connection => connection.name === idp) || { name: idp }
                  }
                  data={idpSpecificIdentityMaps}
                ></IDPTableSection>
              ))}
            </TableBody>
          </Table>
        )}
      </div>

      {/* <DirectoryLabel>
        <ExpandMore /> Active Directory
      </DirectoryLabel>

      <IdentityTable>
        <IdentityRow
          name="Developers"
          info="8 users"
          identityType={IdentityType.Group}
          repositories={[{ name: 'Patient_data', type: 'mysql', usersCount: 2 }]}
          dbUsers={[
            { name: 'read_only', lastCertified: '1 month ago' },
            { name: 'dev', lastCertified: '1 month ago' },
          ]}
        />
        <IdentityRow
          name="Analysts"
          info="5 users"
          identityType={IdentityType.Group}
          repositories={[
            { name: 'Patient_data', type: 'mysql', usersCount: 1, lastCertified: '2 weeks ago' },
            { name: 'Invoices', type: 'postgres', usersCount: 1, lastCertified: '1 month ago' },
          ]}
          dbUsers={[{ name: 'analyst' }, { name: 'analyst' }]}
        />
        <IdentityRow
          name="nancy.drew@hhiu.us"
          info="Analysts, Support, 2 more"
          identityType={IdentityType.User}
          repositories={[
            { name: 'Patient_data', type: 'mysql', usersCount: 2 },
            { name: 'Transactions', type: 'mongodb', usersCount: 2 },
          ]}
          dbUsers={[
            { name: 'admin', lastCertified: '1 day ago', alert: true, timed: true },
            { name: 'read_only', lastCertified: '3 months ago' },
            { name: 'dev', lastCertified: '3 months ago' },
            { name: 'read_only', lastCertified: '3 months ago' },
          ]}
          to="/identities/1"
        />
        <IdentityRow
          name="customer_api"
          identityType={IdentityType.Api}
          repositories={[{ name: 'Transactions', type: 'postgres', usersCount: 1 }]}
          dbUsers={[{ name: 'app', lastCertified: '2 months ago' }]}
        />
      </IdentityTable>

      <DirectoryLabel>
        <ExpandMore /> Okta
      </DirectoryLabel>

      <IdentityTable>
        <IdentityRow
          identityType={IdentityType.User}
          name="frank.hardy@hhiu.us"
          info="Admins, Developers, 3 more"
          repositories={[
            { name: 'Invoices', type: 'postgres', usersCount: 1 },
            { name: 'Transactions', type: 'mongodb', usersCount: 1 },
          ]}
          dbUsers={[
            { name: 'admin', alert: true, lastCertified: '2 weeks ago' },
            { name: 'admin', alert: true, lastCertified: '2 weeks ago' },
          ]}
        />
      </IdentityTable> */}
    </Paper>
  )
}
