import React from 'react'
import MuiDialog from '@mui/material/Dialog'
import MuiDialogActions from '@mui/material/DialogActions'
import { styled } from '@mui/material/styles'

export const Dialog = styled(
  props => <MuiDialog {...props} />,
  {}
)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: theme.radii.xl,
  },
  '& .MuiDialogTitle-root': {
    padding: theme.spacing(4, 4, 2),
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(0, 4, 4),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(3, 4),
  },
}))

export const StyledDialogActions = styled(
  props => <MuiDialogActions {...props} />,
  {}
)(({ theme }) => ({
  '&.MuiDialogActions-spacing': {
    '& > :not(:first-of-type)': {
      marginLeft: 16,
    },
  },
}))
