import { useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Box, Divider, FormControl, Stack, FormHelperText } from '@mui/material'

import { Input, InputLabel } from '@jeeves/new-components'
import { useRepositoryDetailContext } from '@jeeves/pages/RepositoryDetail/contexts/RepositoryDetailContext'
import InputTags from '@jeeves/pages/RepositoryDetail/Tabs/Policies/views/AddPolicy/components/InputTags'
import Footer from '@jeeves/pages/RepositoryDetail/Tabs/Policies/views/AddPolicy/components/Footer'

/*
This is an alternative version of StepThree for Create Policies.

The second version of StepThree contained in this component is used by Data Firewall and User Segmentation Templates,
and is different from the first version used by other policy templates.
*/
const StepThree = ({ policyInfo, nextStep, prevStep, setPolicyInfo }) => {
  const { repoId } = useRepositoryDetailContext()
  const {
    setValue,
    handleSubmit,
    watch,
    register,
    control,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      dataLabels: policyInfo.parameters.dataLabels,
      dataset: policyInfo.parameters.dataset,
    },
  })

  const onSubmit = data => {
    setPolicyInfo(info => {
      return {
        ...info,
        parameters: {
          ...info.parameters,
          dataLabels: data.dataLabels,
          dataset: data.dataset,
        },
      }
    })
  }

  const onDeleteDataLabels = updatedTags => {
    setValue('dataLabels', updatedTags, { shouldDirty: true })
  }

  const onAddDataLabels = updatedTags => {
    setValue('dataLabels', updatedTags, { shouldValidate: true, shouldDirty: true })
  }

  useEffect(() => {
    if (isSubmitSuccessful) {
      nextStep()
    }
  }, [isSubmitSuccessful, nextStep])

  const { dataLabels = [] } = watch()

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4}>
        <Stack spacing={4} sx={{ maxWidth: '32rem' }}>
          <FormControl variant="standard" error={Boolean(errors?.dataLabels)}>
            <Controller
              name="dataLabels"
              control={control}
              rules={{
                validate: dataLabels => {
                  return dataLabels.length > 0 || 'Please enter at least one label'
                },
              }}
              render={() => (
                <InputTags
                  required
                  inputLabel="Data Labels"
                  tags={dataLabels}
                  isSubmitting={isSubmitting}
                  onDelete={onDeleteDataLabels}
                  onAdd={onAddDataLabels}
                />
              )}
            />
            <FormHelperText sx={{ typography: 'body2' }} error={!!errors?.dataLabels}>
              {errors?.dataLabels?.message}
            </FormHelperText>
          </FormControl>

          <FormControl variant="standard" error={Boolean(errors?.dataset)}>
            <InputLabel htmlFor="dataset" required>
              Data Set
            </InputLabel>
            <Input
              id="dataset"
              inputProps={{
                ...register('dataset', {
                  required: 'Please enter a Data Set',
                }),
              }}
            />
            <FormHelperText
              sx={{
                typography: 'body2',
              }}
              error={!!errors?.dataset}
            >
              {errors?.dataset?.message}
            </FormHelperText>
          </FormControl>
        </Stack>

        <Divider />

        <Footer repoId={repoId} prevStep={prevStep} policyId={policyInfo.id} />
      </Stack>
    </Box>
  )
}

export default StepThree
