import React from 'react'
import Stack from '@mui/material/Stack'

import MuiTabs from '@mui/material/Tabs'
import MuiTab from '@mui/material/Tab'
import { styled } from '@mui/material/styles'

const Tabs = styled(MuiTabs)({
  alignItems: 'center',
  '& .MuiTabs-indicator': {
    visibility: 'hidden',
  },
})

const Tab = styled(props => <MuiTab disableRipple {...props} />)(({ theme }) => ({
  padding: theme.spacing(1, 2),

  '&:hover': {
    color: theme.palette.text.primary,
  },

  '&.Mui-selected': {
    backgroundColor: theme.palette.cyralColors.primary[100],
    borderRadius: theme.radii.base,
    [`&:hover`]: {
      color: theme.palette.primary.main,
    },
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#d1eaff',
  },
}))

const Notification = styled(props => <Stack {...props} />)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  padding: theme.spacing(4),
  color: theme.palette.cyralColors.primary[300],

  background: theme.palette.cyralColors.primary[100],
}))

export { Tab, Tabs, Notification }
