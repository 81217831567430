import React from 'react'

import TableHead from '@mui/material/TableHead'

import { MappingsHeaderCell } from '../MappingTable.styles'

export const DynamoHeader = ({ children, props, actionsCol }) => {
  return (
    <React.Fragment>
      <MappingsHeaderCell>Table</MappingsHeaderCell>
      <MappingsHeaderCell>Field</MappingsHeaderCell>

      {actionsCol && <MappingsHeaderCell></MappingsHeaderCell>}
    </React.Fragment>
  )
}
