import { useHistory } from 'react-router-dom'
import { useMutation, MutationHookOptions } from '@apollo/client'

import { useRepositoryDetailContext } from '@jeeves/pages/RepositoryDetail/contexts/RepositoryDetailContext'
import { graphql } from '@jeeves/graphql'
import { CreatePolicyMutation, CreatePolicyMutationVariables } from '@jeeves/graphql/graphql'

const CREATE_POLICY = graphql(`
  mutation CreatePolicy($repoId: ID!, $input: CreatePolicyInput!) {
    createPolicy(repoId: $repoId, input: $input) {
      policy {
        id
        name
        description
        tags
        template {
          id
          description
          name
          tags
        }
        ...DataMaskingFields
        ...DataProtectionFields
        ...DataFirewallFields
        ...ReadLimitFields
        ...ServiceAccountAbuseFields
        ...UserSegmentationFields
        ...RepositoryProtectionFields
        ...RateLimitFields
      }
    }
  }
`)

const useOnCompleted = () => {
  const history = useHistory()
  const { repoId } = useRepositoryDetailContext()

  const onCompleted = (policyId: string) => {
    history.push(`/repositories/${repoId}/policies/${policyId}`)
  }

  return onCompleted
}

type CreatePolicyOptions = MutationHookOptions<CreatePolicyMutation, CreatePolicyMutationVariables>

type OnCompleted = CreatePolicyOptions['onCompleted']
type OnError = CreatePolicyOptions['onError']

const useCreatePolicy = ({
  onCompleted,
  onError,
}: {
  onCompleted?: OnCompleted
  onError?: OnError
}) => {
  const defaultOnCompleted = useOnCompleted()

  const createPolicyMutation = useMutation(CREATE_POLICY, {
    onCompleted:
      onCompleted ||
      (data => {
        const createdPolicyId = data?.createPolicy?.policy?.id

        if (createdPolicyId) {
          defaultOnCompleted(createdPolicyId)
        }
      }),
    ...(onError && { onError }),
  })

  return createPolicyMutation
}

export default useCreatePolicy
