/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import styled from '@emotion/styled'
import {
  ExpansionPanel as MuiExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
} from '@material-ui/core/'
import { ExpandMore } from '@material-ui/icons'

const ExpansionPanel = styled(MuiExpansionPanel)`
  width: 100%;
`

const IntegrationPanel = ({ title, expanded, children, onChange }) => (
  <ExpansionPanel expanded={expanded} onChange={onChange}>
    <ExpansionPanelSummary expandIcon={<ExpandMore />}>
      <Typography>{title}</Typography>
    </ExpansionPanelSummary>
    <ExpansionPanelDetails>{children}</ExpansionPanelDetails>
  </ExpansionPanel>
)

export default IntegrationPanel
