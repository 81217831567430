import React from 'react'
import { Redirect } from 'react-router-dom'

import { useAuth } from '../Auth'

import { get as lodashGet } from 'lodash'

const RootPath = () => {
  const { user } = useAuth()

  // TODO: hacky function - needs to be fixed later on
  function isDbUser() {
    const permissions = lodashGet(user, 'app_metadata.authorization.permissions', [])
    const dbPermissions = [
      'apidocs:read',
      'logs:read',
      'token:read',
      'repo:read',
      'sidecar:read',
      'metrics:read',
      'adminguide:read',
      'support:read',
      'access:read',
    ]

    const diff = permissions.filter(permission => !dbPermissions.includes(permission))

    if (diff.length > 0) return false

    return true
  }

  function getRedirectPath() {
    if (isDbUser()) return '/token'
    return '/home'
  }

  const redirectPath = getRedirectPath()

  return <Redirect to={{ pathname: redirectPath }} />
}

export default RootPath
