import { useParams } from 'react-router'
import { useMutation } from '@apollo/client'

import { getGraphQLErrorMessage } from '@jeeves/utils/helpers'
import { graphql } from '@jeeves/graphql'
import { useToast } from '@jeeves/new-components'

const CREATE_SIDECAR_CREDENTIAL = graphql(`
  mutation CreateDeploymentSidecarCredential($sidecarId: ID!) {
    createSidecarCredential(sidecarId: $sidecarId) {
      clientSecret
      sidecarCredential {
        clientId
        sidecar {
          id
        }
      }
    }
  }
`)

const useSidecarCredentials = () => {
  const { id: sidecarId } = useParams<{ id: string }>()

  const { toast } = useToast()

  return useMutation(CREATE_SIDECAR_CREDENTIAL, {
    variables: {
      sidecarId,
    },
    onError: error => {
      toast({
        variant: 'error',
        description:
          getGraphQLErrorMessage(error) ||
          'An error occurred while creating the sidecar credential. Please try again later.',
      })
    },
  })
}

export default useSidecarCredentials
