/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import styled from '@emotion/styled'
import { Typography as MuiTypography } from '@material-ui/core'
import { Link } from 'react-router-dom'

const Typography = styled(MuiTypography)`
  margin-right: 8px;
  font-size: 12px;
`

export const Breadcrumbs = () => (
  <div
    css={() => css`
      display: flex;
      padding-top: 24px;
      padding-left: 24px;
    `}
  >
    <Link to="/repositories">
      <Typography>Data Repositories</Typography>
    </Link>
    <Typography>&gt;</Typography>
    <Typography>Details</Typography>
  </div>
)
