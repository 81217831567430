import { useState, useCallback } from 'react'

import { useRepositoryDetailContext } from '@jeeves/pages/RepositoryDetail/contexts/RepositoryDetailContext'
import { policyIdentities } from '@jeeves/pages/RepositoryDetail/Tabs/Policies/helpers/policyIdentities'

import { useCreatePolicy, useUpdatePolicy } from '../hooks'

const useDataFirewall = ({ policy, template }) => {
  const { repoId } = useRepositoryDetailContext()

  const [snackBarOpen, setSnackBarOpen] = useState(false)
  const [activeStep, setActiveStep] = useState(0)
  const [dataFirewallInfo, setDataFirewallInfo] = useState({
    id: policy?.id ?? '',
    name: policy?.name ?? '',
    description: policy?.description ?? '',
    tags: policy?.tags ?? template.tags,
    template: policy?.template ?? template,
    parameters: {
      dataLabels: policy?.parameters?.dataLabels ?? [],
      excludedIdentities: policy?.parameters?.excludedIdentities ?? [],
      dataset: policy?.parameters?.dataset ?? '',
      restriction: policy?.parameters?.restriction ?? '',
    },
  })

  const isEditing = Boolean(policy?.id)

  const [createDataFirewallPolicy, { loading: createLoading, error: createError }] =
    useCreatePolicy({
      onError: _ => {
        setSnackBarOpen(true)
      },
    })

  const [updateDataFirewallPolicy, { loading: updateLoading, error: updateError }] =
    useUpdatePolicy({
      onError: _ => {
        setSnackBarOpen(true)
      },
    })

  const prevStep = () => setActiveStep(currStep => currStep - 1)
  const nextStep = useCallback(() => {
    setActiveStep(currStep => currStep + 1)
  }, [])

  const handleCloseSnackbar = () => {
    setSnackBarOpen(false)
  }

  const handlePolicySubmit = async () => {
    const excludedIdentities = dataFirewallInfo.parameters.excludedIdentities.map(identity => {
      return { type: policyIdentities[identity.__typename], name: identity.name }
    })

    const input = {
      dataFirewall: {
        name: dataFirewallInfo.name,
        description: dataFirewallInfo.description,
        tags: dataFirewallInfo.tags,
        dataLabels: dataFirewallInfo.parameters.dataLabels,
        excludedIdentities: excludedIdentities,
        dataset: dataFirewallInfo.parameters.dataset,
        restriction: dataFirewallInfo.parameters.restriction,
      },
    }

    try {
      if (isEditing) {
        const policyId = policy.id
        return await updateDataFirewallPolicy({
          variables: {
            policyId,
            input,
          },
        })
      }

      return await createDataFirewallPolicy({
        variables: {
          repoId,
          input,
        },
      })
    } catch (e) {
      console.error(e)
    }
  }

  return {
    dataFirewallInfo,
    activeStep,
    snackBarOpen,
    loading: createLoading || updateLoading,
    errorMessage: createError?.message || updateError?.message,
    setDataFirewallInfo,
    prevStep,
    nextStep,
    handlePolicySubmit,
    handleCloseSnackbar,
  }
}

export default useDataFirewall
