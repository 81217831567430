/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import React from 'react'
import { TableBody } from '@material-ui/core'

const EventTimelineBody = props => {
  return <TableBody {...props} />
}

export default EventTimelineBody
