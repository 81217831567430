import { Stack, DialogContent, DialogTitle, Typography } from '@mui/material'
import CloseIcon from '@material-ui/icons/Close'

import { Dialog, Button, DialogActions, IconButton } from '@jeeves/new-components'

const EnableNetworkShieldModal = ({ open, closeModal, loading, onConfirm }) => {
  return (
    <Dialog open={open} onClose={closeModal}>
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h3" sx={{ color: 'text.primary' }}>
          Enable Network Shield
        </Typography>
        <IconButton aria-label="close" onClick={closeModal}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
            Are you sure you want to enable the{' '}
            <Typography component="span" variant="h6" sx={{ color: 'primary.main' }}>
              Network Shield
            </Typography>
            ?
          </Typography>
          <Typography component="span" variant="body2" sx={{ color: 'error.main' }}>
            Repository access will be restricted to the database accounts specified below, and only
            when connecting from the designated client IPs.
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2} sx={{ justifyContent: 'flex-end' }}>
          <Button variant="text" onClick={closeModal}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={onConfirm} loading={loading}>
            Enable
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}

export default EnableNetworkShieldModal
