import { useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'

import { Box, Divider, FormControl, Stack, RadioGroup } from '@mui/material'
import { useRepositoryDetailContext } from '@jeeves/pages/RepositoryDetail/contexts/RepositoryDetailContext'

import { InputLabel } from '@jeeves/new-components'
import IdentitiesCustomOption from '../../../IdentitiesCustomOption'
import DatabaseAccountCustomOption from '../../../DatabaseAccountCustomOption'

import Footer from '@jeeves/pages/RepositoryDetail/Tabs/Policies/views/AddPolicy/components/Footer'

const StepTwo = ({ userSegmentationInfo, nextStep, prevStep, setUserSegmentationInfo }) => {
  const { repoId } = useRepositoryDetailContext()

  const scopeInfo = userSegmentationInfo.parameters.scope
  const scopeType = 'databaseAccounts' in scopeInfo ? 'databaseAccounts' : 'identities'

  const {
    setValue,
    handleSubmit,
    watch,
    control,
    clearErrors,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      modifier: scopeType,
      scope: scopeInfo[scopeType],
    },
  })

  const onSubmit = data => {
    const shouldSetDatabaseAccounts = data.modifier === 'databaseAccounts'
    const shouldSetIdentities = data.modifier === 'identities'

    setUserSegmentationInfo(info => {
      return {
        ...info,
        parameters: {
          ...info.parameters,
          scope: {
            ...(shouldSetDatabaseAccounts && {
              databaseAccounts: data.scope,
            }),
            ...(shouldSetIdentities && {
              identities: data.scope,
            }),
          },
        },
      }
    })
  }

  const onDeleteScopeIdentities = updatedTags => {
    setValue('scope', updatedTags, { shouldDirty: true })
  }

  const onAddScopeIdentities = updatedTags => {
    setValue('scope', updatedTags, { shouldValidate: true, shouldDirty: true })
  }

  useEffect(() => {
    if (isSubmitSuccessful) {
      nextStep()
    }
  }, [isSubmitSuccessful, nextStep])

  const { scope = [], modifier } = watch()

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4}>
        <Box sx={{ maxWidth: '32rem' }}>
          <FormControl variant="standard" sx={{ flex: 1 }}>
            <InputLabel component="legend">Apply this policy to</InputLabel>
            <Controller
              name="modifier"
              control={control}
              render={({ field }) => (
                <RadioGroup
                  row
                  aria-label="modifier"
                  {...field}
                  onChange={event => {
                    setValue('scope', [])
                    clearErrors('scope')
                    setValue('modifier', event.target.value)
                  }}
                >
                  <Stack sx={{ flexGrow: 1 }}>
                    <IdentitiesCustomOption
                      value="identities"
                      label="Specify which identities cannot see restricted records"
                      valueKey="scope"
                      isSubmitting={isSubmitting}
                      tags={scope}
                      isSelected={modifier === 'identities'}
                      control={control}
                      error={errors?.scope}
                      onDeleteSpecificIdentities={onDeleteScopeIdentities}
                      onAddSpecificIdentities={onAddScopeIdentities}
                    />

                    <DatabaseAccountCustomOption
                      value="databaseAccounts"
                      label="Specify which database accounts cannot see restricted records"
                      isSubmitting={isSubmitting}
                      valueKey="scope"
                      databaseAccountTags={scope}
                      isSelected={modifier === 'databaseAccounts'}
                      control={control}
                      error={errors?.scope}
                      onAddDatabaseAccounts={onDeleteScopeIdentities}
                      onDeleteDatabaseAccounts={onAddScopeIdentities}
                    />
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Box>

        <Divider />

        <Footer repoId={repoId} prevStep={prevStep} policyId={userSegmentationInfo.id} />
      </Stack>
    </Box>
  )
}

export default StepTwo
