/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Collapse, TableCell, TableRow } from '@material-ui/core'

export const ExpandableRow = ({ expanded, children }) => (
  <TableRow
    css={() =>
      css`
        height: auto;
        border: 0;
      `
    }
  >
    <TableCell
      colSpan={50}
      css={() =>
        css`
          padding: 0;
          border: 0;
          border-bottom: ${expanded ? '1px solid rgba(224, 224, 224, 1)' : 0};
        `
      }
    >
      <Collapse in={expanded}>
        <div
          css={css`
            padding: 16px;
            margin: 16px;
          `}
        >
          {children}
        </div>
      </Collapse>
    </TableCell>
  </TableRow>
)
