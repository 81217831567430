import { NavLink } from 'react-router-dom'
import MenuList from '@material-ui/core/MenuList'
import DnsIcon from '@material-ui/icons/Dns'
import LinkIcon from '@material-ui/icons/Link'

import { useAuth } from '@jeeves/hooks'
import { MenuItem } from './components/MenuItem'
import { SidecarIcon } from './components/SidecarIcon'

const SecondSection = ({ iconOnly = false }) => {
  const { hasPermission } = useAuth()

  return (
    <MenuList>
      <MenuItem
        component={NavLink}
        to="/repositories"
        disabled={!hasPermission('repo:read')}
        iconOnly={iconOnly}
        primaryText="Data Repos"
        listItemIcon={<DnsIcon fontSize="small" />}
      />

      <MenuItem
        component={NavLink}
        to="/sidecars"
        disabled={!hasPermission('sidecar:read')}
        iconOnly={iconOnly}
        primaryText="Sidecars"
        listItemIcon={<SidecarIcon fontSize="small" />}
      />

      <MenuItem
        component={NavLink}
        to="/integrations"
        disabled={!hasPermission('integrations:read')}
        iconOnly={iconOnly}
        primaryText="Integrations"
        listItemIcon={<LinkIcon fontSize="small" />}
      />
    </MenuList>
  )
}

export default SecondSection
