import { Link } from 'react-router-dom'
import { Box, Divider, Stack, Typography } from '@mui/material'
import { Button } from '@jeeves/new-components'
import { useRepositoryDetailContext } from '@jeeves/pages/RepositoryDetail/contexts/RepositoryDetailContext'

import SelectPolicyTypeCard from '../SelectPolicyTypeCard'

const SelectPolicyType = ({ setPolicyTemplateTypeId, implementedTemplates }) => {
  const { repoId } = useRepositoryDetailContext()

  return (
    <Stack spacing={4}>
      <Typography variant="h3" sx={{ color: 'text.primary' }}>
        Select policy template
      </Typography>

      <Box
        sx={{
          display: 'grid',
          gap: 2,
          gridTemplateColumns: 'repeat(2, 1fr)',
        }}
      >
        {implementedTemplates.map(template => (
          <SelectPolicyTypeCard
            onClick={() => setPolicyTemplateTypeId(template.id)}
            key={template.id}
            {...template}
          />
        ))}
      </Box>

      <Divider />
      <Box>
        <Button
          component={Link}
          to={`/repositories/${repoId}/policies`}
          variant="outlined"
          color="secondary"
        >
          Cancel
        </Button>
      </Box>
    </Stack>
  )
}

export default SelectPolicyType
