/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import {
  Group as GroupIcon,
  Person as PersonIcon,
  Settings as SettingsIcon,
} from '@material-ui/icons'
import { Typography } from '@material-ui/core'
import { EllipsisTypography } from '@jeeves/components/Primitives'

import { IdentityType } from '../constants'

export const IdentityName = ({ type, children }) => (
  <div
    css={() => css`
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: flex-start;
    `}
  >
    <div
      css={() => css`
        min-width: 50px;
      `}
    >
      {type === IdentityType.Group && <GroupIcon />}
      {type === IdentityType.User && <PersonIcon />}
      {type === IdentityType.Api && <SettingsIcon />}
    </div>
    <EllipsisTypography width="120px">{children}</EllipsisTypography>
  </div>
)
