import { useMutation } from '@apollo/client'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router'
import { z } from 'zod'

import { FragmentType, graphql, useFragment } from '@jeeves/graphql'
import { SidecarService, SidecarServiceLogLevel } from '@jeeves/graphql/graphql'
import { useToast } from '@jeeves/new-components'
import { getGraphQLErrorMessage } from '@jeeves/utils/helpers'

const useLogging_queryFragment = graphql(`
  fragment useLogging_query on Query {
    sidecar(id: $sidecarId) {
      id
      loggingIntegrations {
        diagnosticLogsIntegration {
          id
          name
        }
      }
      services {
        logLevel
        name
      }
    }
  }
`)

const UPDATE_SIDECAR_LOGGING_INTEGRATIONS = graphql(`
  mutation UpdateSidecarLoggingIntegrations(
    $sidecarId: ID!
    $input: UpdateSidecarLoggingIntegrationsInput!
  ) {
    updateSidecarLoggingIntegrations(sidecarId: $sidecarId, input: $input) {
      sidecar {
        id
        loggingIntegrations {
          dataActivityLogsIntegration {
            id
            name
          }
          diagnosticLogsIntegration {
            id
            name
          }
        }
      }
    }
  }
`)

const UPDATE_SIDECAR_SERVICES_LOG_LEVEL = graphql(`
  mutation UpdateSidecarServiceLogLevel(
    $sidecarId: ID!
    $input: UpdateSidecarServicesLogLevelInput!
  ) {
    updateSidecarServicesLogLevel(sidecarId: $sidecarId, input: $input) {
      sidecar {
        id
        services {
          logLevel
          name
        }
      }
    }
  }
`)

const schema = z.object({
  diagnosticLogsIntegrationId: z.string(),
  diagnosticLogsServices: z.record(
    z.enum([
      'authenticator',
      'dispatcher',
      'dynamodb-wire',
      'mongodb-wire',
      'mysql-wire',
      'oracle-wire',
      'pg-wire',
      'snowflake-wire',
      'sqlserver-wire',
    ]),
    z.enum([
      SidecarServiceLogLevel.Info,
      SidecarServiceLogLevel.Debug,
      SidecarServiceLogLevel.Trace,
    ])
  ),
})

type LoggingFormSchema = z.infer<typeof schema>

interface useLoggingProps {
  query: FragmentType<typeof useLogging_queryFragment>
}

const DEFAULT_ERROR_MESSAGE = 'An error occurred while saving, please try again later.'

export const useLogging = ({ query: queryProp }: useLoggingProps) => {
  const { id: sidecarId } = useParams<{ id: string }>()

  const { toast } = useToast()

  const { sidecar } = useFragment(useLogging_queryFragment, queryProp)

  const getDiagnosticLogsServiceDefaultValue = (services: SidecarService[]) => {
    const diagnosticLogsServices: Record<string, SidecarServiceLogLevel> = {}

    services.forEach(service => {
      diagnosticLogsServices[service.name] = service.logLevel
    })

    return diagnosticLogsServices
  }

  const methods = useForm<LoggingFormSchema>({
    defaultValues: {
      diagnosticLogsIntegrationId:
        sidecar.loggingIntegrations.diagnosticLogsIntegration?.id ?? 'none',
      diagnosticLogsServices: getDiagnosticLogsServiceDefaultValue(sidecar.services),
    },
  })

  const [updateSidecarLoggingIntegration, { loading: loadingUpdateSidecarLoggingIntegration }] =
    useMutation(UPDATE_SIDECAR_LOGGING_INTEGRATIONS, {
      onError: error => {
        toast({
          variant: 'error',
          description: getGraphQLErrorMessage(error) || DEFAULT_ERROR_MESSAGE,
        })
      },
      onCompleted: data => {
        const diagnosticLogsIntegrationId =
          data.updateSidecarLoggingIntegrations?.sidecar?.loggingIntegrations
            .diagnosticLogsIntegration?.id ?? 'none'
        methods.reset({ ...methods.getValues(), diagnosticLogsIntegrationId })
      },
    })

  const [updateSidecarServicesLogLevel, { loading: loadingUpdateSidecarServiceLogLevel }] =
    useMutation(UPDATE_SIDECAR_SERVICES_LOG_LEVEL, {
      onError: error => {
        toast({
          variant: 'error',
          description: getGraphQLErrorMessage(error) || DEFAULT_ERROR_MESSAGE,
        })
      },
      onCompleted: data => {
        const diagnosticLogsServices = getDiagnosticLogsServiceDefaultValue(
          data.updateSidecarServicesLogLevel?.sidecar?.services!
        )
        methods.reset({ ...methods.getValues(), diagnosticLogsServices })
      },
    })

  const onSubmit = async ({
    diagnosticLogsIntegrationId,
    diagnosticLogsServices,
  }: LoggingFormSchema) => {
    const dirtyFields = methods.formState.dirtyFields

    const shouldUpdateDiagnosticLogsIntegrationId = Boolean(dirtyFields.diagnosticLogsIntegrationId)
    if (shouldUpdateDiagnosticLogsIntegrationId) {
      await updateSidecarLoggingIntegration({
        variables: {
          sidecarId,
          input: {
            diagnosticLogsIntegrationId:
              diagnosticLogsIntegrationId !== 'none' ? diagnosticLogsIntegrationId : null,
          },
        },
      })
    }

    const shouldUpdateSidecarServicesLogLevel = Boolean(dirtyFields.diagnosticLogsServices)
    if (shouldUpdateSidecarServicesLogLevel) {
      await updateSidecarServicesLogLevel({
        variables: {
          sidecarId,
          input: {
            services: Object.entries(diagnosticLogsServices).map(([serviceName, logLevel]) => ({
              serviceName,
              logLevel,
            })),
          },
        },
      })
    }
  }

  return {
    loading: loadingUpdateSidecarLoggingIntegration || loadingUpdateSidecarServiceLogLevel,
    methods,
    onSubmit: methods.handleSubmit(onSubmit),
  }
}
