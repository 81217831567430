import { useState } from 'react'
import { Box, ThemeProvider } from '@mui/material'

import { v5Theme } from '@jeeves/theme'

import { ReactComponent as CyralLogo } from './CyralLogo.svg'

import {
  SetupWizardStep1,
  SetupWizardStep2,
  SetupWizardStep3,
  SetupWizardStep4,
  SetupWizardStep5,
  SetupWizardStep6,
  SetupWizardStep7,
} from './SetupWizardSteps'

import { SetupWizardActions } from './SetupWizardActions'
import { useAppConfig } from '@jeeves/hooks'

const SetupWizard = () => {
  const { config } = useAppConfig()
  const [currentStep, setCurrentStep] = useState(0)

  const hasPlayground = Boolean(config?.hasSandbox)

  const stepsWithPlayground = [
    <SetupWizardStep1 setCurrentStep={setCurrentStep} />,
    <SetupWizardStep2 />,
    <SetupWizardStep3 />,
    <SetupWizardStep4 />,
    <SetupWizardStep5 />,
    <SetupWizardStep6 />,
    <SetupWizardStep7 />,
  ]

  const stepsWithoutPlayground = [
    <SetupWizardStep1 setCurrentStep={setCurrentStep} />,
    <SetupWizardStep2 />,
    <SetupWizardStep5 />,
    <SetupWizardStep7 />,
  ]

  const steps = hasPlayground ? stepsWithPlayground : stepsWithoutPlayground

  const hasActions = currentStep > 0

  return (
    <ThemeProvider theme={v5Theme}>
      <Box
        sx={{
          backgroundColor: 'white',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          width: '100%',
          position: 'relative',
        }}
      >
        <Box sx={{ position: 'absolute', top: 0, left: 0, pt: 4, pl: 4 }}>
          <CyralLogo />
        </Box>
        <Box
          sx={{
            width: '976px',
            height: '800px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {steps[currentStep]}

          {hasActions && (
            <SetupWizardActions
              currentStep={currentStep}
              stepsNumber={steps.length - 1}
              setCurrentStep={setCurrentStep}
            />
          )}
        </Box>
      </Box>
    </ThemeProvider>
  )
}

export default SetupWizard
