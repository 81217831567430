import * as React from 'react'

export const PolicySummaryContext = React.createContext()

export const usePolicySummary = () => {
  const context = React.useContext(PolicySummaryContext)
  if (context === undefined) {
    throw new Error('usePolicySummary must be used within a PolicySummaryContext')
  }
  return context
}
