import { TableBody } from '@mui/material'

import { FragmentType, graphql, useFragment } from '@jeeves/graphql'
import { descendingComparator } from '@jeeves/utils/comparation'
import { useUsers } from '@jeeves/pages/Account/Users/context'

import UsersTableRow from './UsersTableRow'

const UsersTableBody_QueryFragment = graphql(`
  fragment UsersTableBody_QueryFragment on Query {
    controlPlaneUsers {
      id
      username
      name
      role {
        name
      }
      ssoGroups {
        __typename
      }
      ...UsersTableRow_ControlPlaneUserFragment
    }
  }
`)

interface Props {
  openEdit: (user: any) => void
  query: FragmentType<typeof UsersTableBody_QueryFragment>
  openSsoGroupsModal: (ssoGroups: string[]) => void
}

const UsersTableBody = ({ openEdit, query: queryProp, openSsoGroupsModal }: Props) => {
  const { controlPlaneUsers } = useFragment(UsersTableBody_QueryFragment, queryProp)

  const { pageIndex, pageSize, searchValue, sortDirection, sortBy } = useUsers()

  const startIndex = pageIndex * pageSize
  const endIndex = startIndex + pageSize

  type ControlPlaneUser = (typeof controlPlaneUsers)[number]

  const controlPlaneUsersComparator = (userA: ControlPlaneUser, userB: ControlPlaneUser) => {
    const comparatorMap = {
      username: {
        asc: userA.username!.localeCompare(userB.username!),
        desc: userB.username!.localeCompare(userA.username!),
      },
      name: {
        asc: userA.name.localeCompare(userB.name),
        desc: userB.name.localeCompare(userA.name),
      },
      role: {
        asc: userA.role.name.localeCompare(userB.role.name),
        desc: userB.role.name.localeCompare(userA.role.name),
      },
      ssoGroups: {
        asc: descendingComparator(userA.ssoGroups.length, userB.ssoGroups.length),
        desc: -descendingComparator(userA.ssoGroups.length, userB.ssoGroups.length),
      },
    }

    return comparatorMap[sortBy][sortDirection]
  }

  const filteredControlPlaneUsers = controlPlaneUsers.filter(
    controlPlaneUser =>
      controlPlaneUser.name.trim().toLowerCase().includes(searchValue) ||
      controlPlaneUser.username?.trim().toLowerCase().includes(searchValue)
  )
  const sortedControlPlaneUsers = filteredControlPlaneUsers
    .slice()
    .sort(controlPlaneUsersComparator)
  const controlPlanesUsersOnPage = sortedControlPlaneUsers.slice(startIndex, endIndex)

  return (
    <TableBody>
      {controlPlanesUsersOnPage.map(controlPlaneUser => (
        <UsersTableRow
          key={controlPlaneUser.id}
          controlPlaneUser={controlPlaneUser}
          openEdit={openEdit}
          openSsoGroupsModal={openSsoGroupsModal}
        />
      ))}
    </TableBody>
  )
}

export default UsersTableBody
