/** @jsxRuntime classic */
/** @jsx jsx */
import styled from '@emotion/styled'
import { jsx, css } from '@emotion/react'
import React from 'react'
import { withStyles, Grid } from '@material-ui/core'

import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { grey } from '@material-ui/core/colors'

import classNames from 'classnames'

import { get as lodashGet } from 'lodash'

const styles = theme => ({
  cell: {
    padding: theme.spacing.unit * 2,
    width: '100%',
  },

  details: {
    marginLeft: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  actions: {
    marginLeft: theme.spacing.unit * 5,
  },
  buttonSpacing: {
    marginLeft: theme.spacing.unit * 2,
  },
  incidentButton: {
    marginRight: theme.spacing.unit * 2,
  },
})

const Label = styled(Typography)`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: bold;
  width: 20%;
  min-width: 160px;
`

const Value = styled(Typography)`
  font-family: ${({ theme, monospaced }) =>
    monospaced ? theme.typography.monospaced : theme.typography.fontFamily};
  color: ${grey[600]};
  flex-grow: 1;
`

const LabelValuePair = styled.div`
  display: flex;
  flex-direction: row;
`

const CodeSnippet = styled(Typography)`
  font-family: ${({ theme }) => theme.typography.monospaced};
  font-size: 14px;
  background: ${({ theme }) => theme.palette.background.default};
  padding: ${({ theme }) => theme.spacing.unit};
  max-width: 650px;
`

const DownloadWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing[5]};
  overflow-wrap: anywhere;
  word-break: break-all;
`

const ExpandedRow = ({ classes, expanded, alert }) => {
  if (!expanded) return null

  const incidentURL = lodashGet(alert, 'thehive.caseurl', null)

  return (
    <TableRow className={classNames({})}>
      <TableCell colSpan={100} className={classes.cell}>
        <Typography variant="h6" id="details" className={classes.details}>
          Details
        </Typography>
        <div
          css={css`
            margin-left: 16px;
          `}
        >
          {alert.alerttype === 'policyViolation' && (
            <React.Fragment>
              <LabelValuePair>
                <Label>Policy Name</Label>
                <Value>
                  {alert.details[0].policy &&
                    alert.details[0].policy.names &&
                    alert.details[0].policy.names[0]}
                </Value>
              </LabelValuePair>

              <LabelValuePair>
                <Label>Data Labels</Label>
                <Value>
                  {alert.details[0].policy && alert.details[0].policy.labels.join(', ')}
                </Value>
              </LabelValuePair>
            </React.Fragment>
          )}
          <LabelValuePair>
            <Label>Action</Label>
            <Value>{alert.action}</Value>
          </LabelValuePair>

          <LabelValuePair>
            <Label>DB User</Label>
            <Value>{alert.identity ? alert.identity.dbuser : `\u2014`}</Value>
          </LabelValuePair>

          <DownloadWrapper>
            <Grid container>
              <Grid item>
                <Label>Command</Label>
              </Grid>
            </Grid>
            <CodeSnippet variant="subtitle1">
              {alert.details[0] && alert.details[0].command}
            </CodeSnippet>
          </DownloadWrapper>

          {alert.details[0].policy && alert.details[0].policy.reasons && (
            <DownloadWrapper>
              <Grid container>
                <Grid item>
                  <Label>Reasons</Label>
                </Grid>
              </Grid>
              <CodeSnippet variant="subtitle1">
                {' '}
                <ul>
                  {alert.details[0].policy.reasons.map((detail, index) => {
                    return <li key={index}>{detail}</li>
                  })}
                </ul>
              </CodeSnippet>
            </DownloadWrapper>
          )}

          <LabelValuePair
            css={css`
              margin-top: 40px;
            `}
          >
            <div
              css={css`
                color: ${grey[600]};
                width: 20%;
                min-width: 160px;
                font-family: ${({ theme }) => theme.typography.fontFamily};
              `}
            >
              Query ID
            </div>
            {/* <Label>Query ID</Label> */}
            <Value
              css={css`
                font-size: 13px;
              `}
            >
              {alert.details[0] && alert.details[0].queryid}
            </Value>
          </LabelValuePair>
        </div>
        <div className={classes.actions}>
          {incidentURL && (
            <Button
              className={classes.incidentButton}
              variant="outlined"
              color="primary"
              size="small"
              onClick={() => window.open(incidentURL, '_blank')}
            >
              View incident
            </Button>
          )}
        </div>
      </TableCell>
    </TableRow>
  )
}

export default withStyles(styles)(ExpandedRow)
