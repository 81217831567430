import React, { useState } from 'react'
import ExpressClient from '@jeeves/clients/express'
import { useAuth, usePopup } from '@jeeves/hooks'
import { get as lodashGet } from 'lodash'

const useADFS = () => {
  const { setPopup, popupTypes } = usePopup()
  const { getTokenSilently, user } = useAuth()
  const ec = new ExpressClient(getTokenSilently)

  const [integrations, setIntegrations] = useState([])

  const handleError = e => {
    const popupMessage = lodashGet(e, 'response.data.error.message', '')
    setPopup(popupTypes.ERROR, popupMessage)
    throw e
  }

  const addIntegration = async samlConfig => {
    try {
      const integration = await ec
        .post('/integrations/saml/fromMetadata', samlConfig)
        .then(res => res.data)
      await updateIntegrations()
      return integration
    } catch (e) {
      handleError(e)
    }
  }

  const putIntegration = async samlConfig => {
    try {
      const integration = await ec.put('/integrations/saml', samlConfig).then(res => res.data)
      await updateIntegrations()
      return integration
    } catch (e) {
      handleError(e)
    }
  }

  const deleteIntegration = async alias => {
    try {
      const integration = await ec.delete(`/integrations/saml/${alias}`).then(res => res.data)
      await updateIntegrations()
      return integration
    } catch (e) {
      handleError(e)
    }
  }

  const updateIntegrations = async () => {
    try {
      const result = await ec
        .get('/integrations/saml?identityProvider=adfs-2016')
        .then(res => res.data)
      setIntegrations(result)
    } catch (e) {
      handleError(e)
    }
  }

  return {
    updateIntegrations,
    integrations,
    addIntegration,
    putIntegration,
    deleteIntegration,
  }
}

export default useADFS
