import React from 'react'
import { Stack, Typography, Container } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { v5Theme } from '@jeeves/theme'

import { Activity, Help, WhatsNew, Notifications, Setup, Snapshot } from './components'
import useOverview from './hooks/useOverview'
import { Section } from './Overview.style'

const Overview = ({}) => {
  const queryResponses = useOverview()

  return (
    <ThemeProvider theme={v5Theme}>
      <Container maxWidth="xl">
        <Stack spacing={2}>
          <Stack direction="row">
            <Typography variant="h2" sx={{ color: 'text.primary' }}>
              Overview
            </Typography>
          </Stack>

          <Stack direction="row" spacing={2}>
            <Stack col1 sx={{ flexBasis: '75%' }} spacing={2}>
              <Section>
                <Activity
                  queryResponse={queryResponses?.activity}
                  serviceAccountsConfigured={queryResponses?.setup?.data?.serviceAccountsConfigured}
                />
              </Section>
              <Section>
                <Setup queryResponse={queryResponses?.setup} />
              </Section>
              <Section sx={{ maxHeight: '500px', overflow: 'auto' }}>
                <Notifications queryResponse={queryResponses?.notifications} />
              </Section>
            </Stack>

            <Stack col2 sx={{ flexBasis: '25%' }} spacing={2}>
              <Section>
                <Snapshot queryResponse={queryResponses?.snapshot} />
              </Section>
              <Section>
                <WhatsNew queryResponse={queryResponses?.whatsNew} />
              </Section>
              <Section sx={{ bgcolor: 'cyralColors.primary.100' }}>
                <Help />
              </Section>
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </ThemeProvider>
  )
}

export default Overview
