import { ReactNode } from 'react'
import { Stack } from '@mui/material'

const SetupWizardStep5Row = ({ children }: { children: ReactNode }) => (
  <Stack direction="row" spacing={1} sx={{ paddingLeft: 2, alignItems: 'flex-start' }}>
    {children}
  </Stack>
)

export default SetupWizardStep5Row
