/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { useState, Fragment, useEffect } from 'react'
import CloudOutlinedIcon from '@material-ui/icons/CloudOutlined'
import {
  Avatar,
  TableRow,
  withStyles,
  Tooltip,
  Checkbox,
  FormControlLabel,
  IconButton,
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { ExpandButton, ExpandableRow, TableCell } from '@jeeves/components/Table'
import { Button } from '@jeeves/components/Primitives'
import { get as lodashGet } from 'lodash'
import classnames from 'classnames'
import ServiceAccountDialog from './ServiceAccountDialog'
import DeleteServiceAccountDialog from './DeleteServiceAccountDialog'

const styles = theme => ({
  button: {
    margin: `0 ${theme.spacing.unit * 2}px`,
  },
  hideEditButton: {
    display: 'none',
  },
  alternateRow: {
    background: theme.palette.row.alternate,
  },
  expanded: {
    '& td': {
      border: 'none',
    },
  },
})

const ServiceAccountRow = ({ classes, serviceAccount, roles, colorIndex, refresh }) => {
  const [editOpen, setEditOpen] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)

  const handleEditOpen = e => {
    e.stopPropagation()
    setEditOpen(true)
  }

  const handleDeleteOpen = e => {
    e.stopPropagation()
    setDeleteOpen(true)
  }

  const handleEditClose = e => {
    setEditOpen(false)
  }

  const handleDeleteClose = e => {
    setDeleteOpen(false)
  }

  return (
    <Fragment>
      <TableRow
        key={serviceAccount.displayName}
        className={classnames(classes.evenTableRow, {
          [classes.alternateRow]: colorIndex % 2 !== 0,
        })}
        tabIndex={-1}
      >
        <TableCell>{serviceAccount.displayName}</TableCell>
        <TableCell>{serviceAccount.clientId}</TableCell>
        {/* <TableCell>
          {serviceAccount.roleIds.map(roleId => (
            <div>{rolesDict[roleId]?.name || roleId}</div>
          ))}
        </TableCell> */}
        <TableCell>
          <div css={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <IconButton className={classes.button} onClick={handleEditOpen}>
              <EditIcon />
            </IconButton>
            <IconButton className={classes.button} onClick={handleDeleteOpen}>
              <DeleteIcon />
            </IconButton>
          </div>
        </TableCell>
      </TableRow>
      <ServiceAccountDialog
        edit
        serviceAccount={serviceAccount}
        refresh={refresh}
        open={editOpen}
        onClose={handleEditClose}
        roles={roles}
      />
      <DeleteServiceAccountDialog
        serviceAccount={serviceAccount}
        refresh={refresh}
        open={deleteOpen}
        onClose={handleDeleteClose}
      />
    </Fragment>
  )
}

export default withStyles(styles)(ServiceAccountRow)
