import { useFormContext } from 'react-hook-form'
import { Box, Stack, FormControlLabel, Radio } from '@mui/material'
import { UpsertRepoStandardFields } from '@jeeves/pages/components'

const MongoDBStandaloneOption = () => {
  const { watch } = useFormContext()
  const clusterType = watch('clusterType') ?? 'standalone'

  const isSelected = clusterType === 'standalone'

  return (
    <Stack spacing={1}>
      <FormControlLabel
        value="standalone"
        control={<Radio size="small" disableRipple />}
        label="Standalone"
        slotProps={{
          typography: {
            variant: 'h6',
            sx: {
              color: 'text.primary',
            },
          },
        }}
      />
      {isSelected && (
        <Box sx={{ paddingLeft: 3 }}>
          <UpsertRepoStandardFields />
        </Box>
      )}
    </Stack>
  )
}

export default MongoDBStandaloneOption
