import React, { useLayoutEffect, useState, useRef } from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import useSize from '@react-hook/size'

import { Tag } from '@jeeves/new-components'

const PolicyCard = ({ id = 'S.3.6', title = 'User is created', status, tags = [] }) => {
  const tagsWrapperRef = useRef(null)
  const contentWrapperRef = useRef(null)
  const tagToAppendRef = useRef(null)

  const [tagsWrapperWidth] = useSize(tagsWrapperRef)
  const [contentWrapperWidth] = useSize(contentWrapperRef)
  const [tagToAppendWidth] = useSize(tagToAppendRef)

  const [tagsState, setTagsState] = useState({
    visible: [],
    hidden: tags,
  })

  useLayoutEffect(() => {
    const latestTagsWrapperWidth = tagsWrapperRef.current.clientWidth
    const latestContentWrapperWidth = contentWrapperRef.current.clientWidth
    const latestTagToAppendWidth = tagToAppendRef.current.clientWidth

    // The '8' below is the margin value added around new tag
    if (latestTagsWrapperWidth + latestTagToAppendWidth + 8 <= latestContentWrapperWidth) {
      // Enough space to add in overflowed tag (if one exists)
      const tagToMakeVisible = tagsState.hidden[0]

      if (!tagToMakeVisible) return

      setTagsState(state => {
        const { visible, hidden } = state

        const visibleCopy = [...visible]
        const hiddenCopy = [...hidden]

        // Remove first item in hidden tags list
        const addedVisibleTag = hiddenCopy.shift()

        // Add removed tag to list of visible tags
        visibleCopy.push(addedVisibleTag)

        return {
          visible: visibleCopy,
          hidden: hiddenCopy,
        }
      })
    }

    if (latestTagsWrapperWidth > latestContentWrapperWidth) {
      // Need to remove tag because it's overflowing

      setTagsState(state => {
        const { visible, hidden } = state

        const visibleCopy = [...visible]
        const hiddenCopy = [...hidden]

        const removedVisibleTag = visibleCopy.pop()

        // Add removed tag to front of list of hidden tags
        removedVisibleTag && hiddenCopy.unshift(removedVisibleTag)

        return {
          visible: visibleCopy,
          hidden: hiddenCopy,
        }
      })
    }
  }, [tagsWrapperWidth, contentWrapperWidth, tagToAppendWidth, tagsState.hidden])

  return (
    <Box
      sx={{
        bgcolor: 'common.white',
        padding: 2,
        border: 1,
        borderColor: 'cyralColors.grey.200',
        borderRadius: theme => theme.radii.base,
      }}
    >
      <Stack spacing={3} ref={contentWrapperRef}>
        <Stack spacing={1}>
          <Stack direction="row" justifyContent="space-between">
            <Tag color="blue" size="md">
              {id}
            </Tag>
            <Tag color="blue" size="md">
              Placeholder
            </Tag>
          </Stack>

          <Typography variant="h4">{title}</Typography>
        </Stack>

        <Box>
          <Stack
            direction="row"
            spacing={1}
            ref={tagsWrapperRef}
            sx={{
              width: 'max-content',
            }}
          >
            {tagsState.visible.map(tag => {
              return <Tag key={tag}>{tag}</Tag>
            })}
            {tagsState.hidden.length > 0 && <Tag color="grey">{`+${tagsState.hidden.length}`}</Tag>}
          </Stack>
          {/* This box is used as a hacky way to measure the dimensions of unrendered overflowed tags */}
          <Box
            sx={{
              display: 'inline-block',
              visibility: 'hidden',
              position: 'absolute',
              zIndex: '-1',
            }}
            ref={tagToAppendRef}
          >
            {tagsState.hidden[0] && <Tag>{tagsState.hidden[0]}</Tag>}
          </Box>
        </Box>
      </Stack>
    </Box>
  )
}

export default PolicyCard
