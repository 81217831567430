/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import styled from '@emotion/styled'

import { IconCopyButton } from '@jeeves/components/CopyButton'
import { Flex } from '@jeeves/components/Primitives'
import {
  CodeSnippet,
  SandboxList,
  SandboxListItem,
  SandboxLayout,
  Tooltip,
  Typography,
  ViewLogsButton,
} from '@jeeves/pages/Setup/components'

const DOCKER_COMMAND = sidecarHost =>
  `docker run --rm -it -e PGPASSWORD=cerZ47NhXEv9 -e ` +
  `PGSSLMODE=require postgres psql -h ${sidecarHost} ` +
  `-p 5433 -d patientdata -U analyst `

const SQL_COMMAND = 'select * from public.inpatient_charges limit 10;'

const StyledPoint = styled(Typography)`
  font-size: 18px;
`

const Notes = () => (
  <ul>
    <li>
      <StyledPoint>
        You can log into the Cyral-provided PostgreSQL database using a typical database user
        “analyst”
      </StyledPoint>
    </li>
    <li>
      <StyledPoint>
        Your connection is brokered through a Cyral sidecar, also running in the sandbox.&nbsp;
        <span css={{ fontStyle: 'italic' }}>
          To learn more about our sidecar architecture, <a href="#">visit here.</a>
        </span>
      </StyledPoint>
    </li>
    <li>
      <StyledPoint>
        We have loaded sample data in this sandbox PG instance for you to play with
      </StyledPoint>
    </li>
    <li>
      <StyledPoint>
        You can view the data activity and some pre-built dashboards in the Cyral-provided Kibana
        instance
      </StyledPoint>
    </li>
  </ul>
)

export const Sandbox = ({ onNext, sidecarHost }) => (
  <SandboxLayout
    title="Play in your Sandbox"
    subtitle="Cyral provides you with a PostgreSQL database, sidecar and Kibana instance to test things out"
    tooltip={`The sandbox is a VPC created just for you and hosts a AWS RDS instance running PostgreSQL database, an ELK instance and a Cyral sidecar.`}
    onNext={onNext}
    notesComponent={<Notes />}
  >
    <SandboxList>
      <SandboxListItem>
        <Typography css={{ marginTop: 0 }}>
          Log into PostgreSQL with database credentials&nbsp;
          <Tooltip
            interactive
            placement="right"
            leaveDelay={2500}
            title={
              <span>
                You can run these commands anywhere that you have Docker installed.
                {/* <a href="https://docs.docker.com/get-docker/" target="_blank">
                  Get Docker here.
                </a> */}
              </span>
            }
          />
        </Typography>
        <Flex>
          <CodeSnippet css={{ marginRight: '8px' }}>{DOCKER_COMMAND(sidecarHost)}</CodeSnippet>
          <IconCopyButton copyText={DOCKER_COMMAND(sidecarHost)} />
        </Flex>
      </SandboxListItem>
      <SandboxListItem>
        <Typography css={{ marginTop: 0 }}>Run a query:</Typography>
        <Flex>
          <CodeSnippet css={{ marginRight: '8px' }}>{SQL_COMMAND}</CodeSnippet>
          <IconCopyButton copyText={SQL_COMMAND} />
        </Flex>
      </SandboxListItem>
      <SandboxListItem>
        <Typography css={{ marginTop: 0 }}>View data activity logs in Kibana</Typography>
        <ViewLogsButton onClick={() => console.log('view logs')} />
      </SandboxListItem>
    </SandboxList>
  </SandboxLayout>
)
