import React from 'react'
import { gql } from '@apollo/client'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import { Stack, Box, DialogContent, DialogTitle, FormControl, Typography } from '@mui/material'

import { Dialog, Button, Input, InputLabel, DialogActions } from '@jeeves/new-components'
import { UpsertRepoStandardFields as EditRepoStandardFields } from '@jeeves/pages/components'

import {
  EditMongoDBReplicaSetRepoFields,
  EditMongoDBShardedClusterRepoFields,
  InputTags,
} from './components'
import { EditRepoHelpers_repoFragment, getDefaultValues } from './helpers'
import { EditRepoSchemas_repoFragment, getFormSchema } from './schemas'
import { useEditRepo_repoFragment, useEditRepo } from './useEditRepo'

export const EditRepo_repoFragment = gql`
  fragment EditRepo_repo on Repo {
    name
    ...EditRepoHelpers_repo
    ...EditRepoSchemas_repo
    ...useEditRepo_repo
  }
  ${EditRepoHelpers_repoFragment}
  ${EditRepoSchemas_repoFragment}
  ${useEditRepo_repoFragment}
`
const getRepoSpecificFields = repo => {
  switch (repo.__typename) {
    case 'MongoDBShardedCluster':
      return <EditMongoDBShardedClusterRepoFields />
    case 'MongoDBReplicaSetRepo':
      return <EditMongoDBReplicaSetRepoFields />
    default:
      return <EditRepoStandardFields />
  }
}

const Edit = ({ repo, onClose, open }) => {
  const { onSubmit } = useEditRepo(repo)

  const defaultValues = getDefaultValues(repo)

  const methods = useForm({
    defaultValues,
    resolver: zodResolver(getFormSchema(repo)),
    shouldUnregister: true,
  })

  const {
    reset,
    handleSubmit,
    getValues,
    formState: { isSubmitting, isSubmitSuccessful, errors },
  } = methods

  const handleOnClose = React.useCallback(() => {
    onClose()
    reset(defaultValues)
  }, [defaultValues, onClose, reset])

  React.useEffect(() => {
    if (isSubmitSuccessful) {
      const formValues = getValues()
      reset(formValues)
      onClose()
    }
  }, [isSubmitSuccessful, reset, onClose, getValues])

  return (
    <Dialog open={open} fullWidth onClose={handleOnClose}>
      <FormProvider {...methods}>
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>
            <Typography variant="h3" sx={{ color: 'text.primary' }}>
              Edit Data Repository
            </Typography>
          </DialogTitle>

          <DialogContent>
            <Stack spacing={2}>
              <FormControl variant="standard" error={Boolean(errors?.name)}>
                <InputLabel htmlFor="name" required>
                  Repository Name
                </InputLabel>
                <Input id="name" value={repo.name} disabled />
              </FormControl>

              {getRepoSpecificFields(repo)}

              <InputTags />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Stack direction="row" spacing={2.5} sx={{ justifyContent: 'flex-end' }}>
              <Button variant="text" onClick={handleOnClose}>
                Cancel
              </Button>
              <Button loading={isSubmitting} variant="contained" type="submit">
                Save
              </Button>
            </Stack>
          </DialogActions>
        </Box>
      </FormProvider>
    </Dialog>
  )
}

export default Edit
