import React from 'react'
import { withStyles, Grid, Typography, Divider, IconButton } from '@material-ui/core/'
import CloseIcon from '@material-ui/icons/Close'

const styles = theme => ({
  img: {
    maxHeight: '75px',
    maxWidth: '200px',
    display: 'block',
    margin: '20px auto',
  },
  imgContainer: {
    display: 'block',
    height: '75px',
  },
  rightContent: {
    padding: '10px 0 0 0px',
  },
  root: {
    paddingBottom: '45px',
    paddingTop: '25px',
  },
  closeButton: {
    position: 'absolute',
    right: '12px',
    top: '12px',
    color: theme.palette.grey[500],
  },
  name: {
    marginBottom: '10px',
  },
})

const Header = ({ classes, metadata, handleOnClose }) => {
  const { name, summary, logo } = metadata
  return (
    <>
      <Grid container className={classes.root}>
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleOnClose}>
          <CloseIcon />
        </IconButton>
        <Grid item xs={3} className={classes.mediaCard}>
          <div className={classes.imgContainer}>
            <img className={classes.img} src={logo} />
          </div>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={7} className={classes.rightContent}>
          <Typography className={classes.name} variant="h5">
            {name}
          </Typography>
          <Typography variant="body2">{summary}</Typography>
        </Grid>
      </Grid>
      <Divider></Divider>
    </>
  )
}

export default withStyles(styles)(Header)
