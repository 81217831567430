import { Stack, Typography } from '@mui/material'
import { useMutation } from '@apollo/client'

import { FragmentType, graphql, useFragment } from '@jeeves/graphql'
import { getGraphQLErrorMessage } from '@jeeves/utils/helpers'
import { Button, Input, InputLabel, useToast } from '@jeeves/new-components'

const DeleteAccessToken_QueryFragment = graphql(`
  fragment DeleteAccessToken_QueryFragment on Query {
    accessTokens {
      id
      name
    }
  }
`)

const DELETE_ACCESS_TOKEN = graphql(`
  mutation DeleteAccessToken($deleteAccessTokenId: ID!) {
    deleteAccessToken(id: $deleteAccessTokenId) {
      deletedAccessTokenId
    }
  }
`)

interface Props {
  selectedAccessTokenId: string
  goBackToManage: () => void
  query: FragmentType<typeof DeleteAccessToken_QueryFragment>
}

const DeleteAccessToken = ({ goBackToManage, selectedAccessTokenId, query: queryProp }: Props) => {
  const { toast } = useToast()
  const query = useFragment(DeleteAccessToken_QueryFragment, queryProp)

  const accessTokenToBeDeleted = query.accessTokens.find(
    accessToken => accessToken.id === selectedAccessTokenId
  )

  const handleDeleteConfirm = async () => {
    await deleteAccessToken({
      variables: {
        deleteAccessTokenId: selectedAccessTokenId,
      },
    })
  }

  const [deleteAccessToken, { loading }] = useMutation(DELETE_ACCESS_TOKEN, {
    onError: error => {
      toast({
        variant: 'error',
        description:
          getGraphQLErrorMessage(error) ||
          'An error occurred while deleting this access token. Please try again.',
      })
    },
    onCompleted: () => {
      goBackToManage()
    },
    update(cache, { data }) {
      const cacheId = cache.identify({
        id: data?.deleteAccessToken?.deletedAccessTokenId,
        __typename: accessTokenToBeDeleted?.__typename,
      })
      cache.evict({ id: cacheId })
      cache.gc()
    },
  })

  return (
    <Stack spacing={4}>
      <Stack spacing={2}>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          Are you sure you want to delete this token?{' '}
          <Typography component="b" variant="h6" sx={{ color: 'error.main' }}>
            It will be invalid for new connections to any data repositories.
          </Typography>
        </Typography>
        {accessTokenToBeDeleted?.name && (
          <Stack spacing={1}>
            <InputLabel htmlFor="name">Name</InputLabel>
            <Input
              id="name"
              sx={{
                fontFamily: theme => theme.typography.code,
              }}
              value={accessTokenToBeDeleted.name}
              readOnly
              type="text"
            />
          </Stack>
        )}
      </Stack>
      <Stack direction="row" spacing={1} sx={{ justifyContent: 'flex-end' }}>
        <Button variant="outlined" color="secondary" onClick={goBackToManage}>
          Cancel
        </Button>
        <Button variant="outlined" color="error" onClick={handleDeleteConfirm} loading={loading}>
          Delete
        </Button>
      </Stack>
    </Stack>
  )
}

export default DeleteAccessToken
