/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import styled from '@emotion/styled'
import { DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core/'
import { Button, Dialog } from '@jeeves/components/Primitives'

const StyledActions = styled(DialogActions)`
  padding: 0 24px 24px 24px;
  margin: 0;
`

const ConfirmDialog = ({
  open,
  setOpen,
  title,
  description,
  confirmButton,
  children,
  ...props
}) => (
  <Dialog
    open={open}
    onClose={() => setOpen(false)}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    {...props}
  >
    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">{description || children}</DialogContentText>
    </DialogContent>
    <StyledActions>
      <Button onClick={() => setOpen(false)} color="primary">
        Cancel
      </Button>
      {confirmButton}
    </StyledActions>
  </Dialog>
)

export default ConfirmDialog
