import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'

const Card = styled(Box)(({ theme }) => ({
  outline: `1px solid ${theme.palette.cyralColors.grey[200]}`,
  borderRadius: theme.radii.base,
  padding: theme.spacing(2),
  '&:hover': {
    outline: `2px solid ${theme.palette.primary.main}`,
  },
})) as typeof Box

export default Card
