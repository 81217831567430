import * as React from 'react'
import { useRouteMatch, Switch, Route, Redirect } from 'react-router-dom'

import YAMLDataMap from '@jeeves/pages/DataMap'
import { useAppConfig, useEntitlements } from '@jeeves/hooks'
import { Mappings } from '../Mappings'
import { AutoUpdates } from '../AutoUpdates'

const Datamap = ({ repoId, repoType }) => {
  const { hasAutomaticDatamap } = useEntitlements()
  const { path } = useRouteMatch()
  const {
    config: { featureFlags },
  } = useAppConfig()

  const hasYamlDatamap = featureFlags?.showYAMLDatamap

  return (
    <Switch>
      <Route path={`${path}/mappings`}>
        <Mappings repoId={repoId} repoType={repoType} />
      </Route>

      {hasYamlDatamap && (
        <Route path={`${path}/yaml`}>
          <YAMLDataMap repoId={repoId} isRepoView={true} repoType={repoType} />
        </Route>
      )}

      {hasAutomaticDatamap && (
        <Route path={`${path}/auto-updates`}>
          <AutoUpdates repoId={repoId} repoType={repoType} />
        </Route>
      )}

      <Redirect to={`${path}/mappings`} />
    </Switch>
  )
}

export default Datamap
