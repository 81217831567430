import styled from '@emotion/styled'
import { Tabs as MaterialTabs, Tab as MaterialTab } from '@material-ui/core'

export const Tabs = styled(MaterialTabs)`
  background-color: #f2f5f6;
  color: #9aa5b1;
`

Tabs.defaultProps = {
  indicatorColor: 'primary',
  textColor: 'primary',
}

export const Tab = styled(MaterialTab)`
  font-weight: 600;
  color: #808e95;
  text-transform: unset;

  &:disabled {
    color: rgb(200, 200, 200);
  }
`
