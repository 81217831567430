import { useFormContext } from 'react-hook-form'
import { Box, FormControl, FormHelperText, Stack, Typography } from '@mui/material'

import { Input, InputLabel } from '@jeeves/new-components'
import { MIN_PORT_NUMBER, MAX_PORT_NUMBER } from '@jeeves/utils/helpers'
import { REPO_METADATA } from '@jeeves/constants'

const MongoDBReplicaSetPortView = ({ selectedRepo }) => {
  const {
    register,
    formState: { defaultValues, errors },
  } = useFormContext()

  const numberOfPorts = selectedRepo.numNodes
  const isAdding = !Boolean(defaultValues.selectedRepo)

  return (
    <Stack spacing={1}>
      <InputLabel error={Boolean(errors?.ports)}>Sidecar ports</InputLabel>

      {[...Array(numberOfPorts).keys()].map(i => {
        const error = errors?.ports?.[i]

        return (
          <FormControl key={i} variant="standard" error={Boolean(error)}>
            <Input
              type="number"
              inputProps={{
                ...register(`ports.${i}`, {
                  required: 'This field is required.',
                  min: {
                    value: MIN_PORT_NUMBER,
                    message: `Value must be greater than or equal to ${MIN_PORT_NUMBER}.`,
                  },
                  max: {
                    value: MAX_PORT_NUMBER,
                    message: `Value must be less than or equal to ${MAX_PORT_NUMBER}.`,
                  },
                }),
              }}
            />

            {error && (
              <FormHelperText
                sx={{
                  typography: 'body2',
                }}
              >
                {error.message}
              </FormHelperText>
            )}
          </FormControl>
        )
      })}

      <Box>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          Clients will connect through these ports and identify other members of the replica set.{' '}
        </Typography>
        {isAdding && (
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            The default port for MongoDB is {REPO_METADATA.MONGODB.defaults.port}.
          </Typography>
        )}
      </Box>
    </Stack>
  )
}

export default MongoDBReplicaSetPortView
