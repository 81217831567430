import { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { Stack } from '@mui/material'
import { Button } from '@jeeves/new-components'

interface Props {
  repoId: string
  prevStep?: () => void | undefined
  children?: ReactNode
  policyId?: string
}

const Footer = ({ repoId, prevStep, children, policyId }: Props) => {
  const defaultCancelUrl = `/repositories/${repoId}/policies`
  const cancelUrl = policyId ? `${defaultCancelUrl}/${policyId}` : defaultCancelUrl
  const shouldRenderPrevStep = typeof prevStep === 'function'

  return (
    <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
      <Button component={Link} to={cancelUrl} variant="outlined" color="secondary">
        Cancel
      </Button>

      <Stack direction="row" spacing={1}>
        {shouldRenderPrevStep && (
          <Button variant="outlined" color="secondary" onClick={prevStep}>
            Back
          </Button>
        )}
        {children ?? (
          <Button type="submit" variant="outlined" color="secondary">
            Next
          </Button>
        )}
      </Stack>
    </Stack>
  )
}

export default Footer
