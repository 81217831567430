import React, { forwardRef } from 'react'
import Stack from '@mui/material/Stack'
import MuiSelect from '@mui/material/Select'
import MuiMenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import CheckIcon from '@mui/icons-material/Check'
import Typography from '@mui/material/Typography'

import { Input } from '@jeeves/new-components'

const Icon = ({ icon, selectSize }) => {
  const fontSize = 'small'

  // if (selectSize === 'small') {
  //   fontSize =
  // } else if (selectSize === 'large') {
  //   fontSize = 'medium'
  // }

  return React.cloneElement(icon, { fontSize })
}

const RenderedSelectOptionContent = ({ icon, label, selectSize }) => {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      {icon && <Icon icon={icon} selectSize={selectSize} />}
      <ListItemText primary={label} primaryTypographyProps={{ variant: 'body2' }} sx={{ my: 0 }} />
    </Stack>
  )
}

export const SelectOption = ({ icon, label, selectSize, ...rest }) => {
  return (
    <MuiMenuItem {...rest} disableRipple>
      <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
        <RenderedSelectOptionContent icon={icon} label={label} selectSize={selectSize} />
        <CheckIcon
          fontSize="small"
          color="primary"
          sx={{
            display: 'none',
            '.Mui-selected &': {
              display: 'inline-block',
            },
          }}
        />
      </Stack>
    </MuiMenuItem>
  )
}

// TODO: Fully match up styles with Figma component
const Select = forwardRef(({ options, size = 'medium', sx = [], ...rest }, ref) => {
  return (
    <MuiSelect
      ref={ref}
      renderValue={val => {
        const selectedValues = Array.isArray(val) ? val : [val]

        const selectedOptions = selectedValues.map(val => {
          return options.find(option => option.value === val)
        })

        return selectedOptions.length === 1 ? (
          <RenderedSelectOptionContent
            icon={selectedOptions[0]?.icon}
            label={selectedOptions[0]?.label}
            selectSize={size}
          />
        ) : (
          <Typography variant="body2" sx={{ color: 'text.primary' }}>
            {selectedOptions.length} selected
          </Typography>
        )
      }}
      {...rest}
      input={<Input size={size} />}
      sx={[
        {
          '& > .MuiSelect-select': {
            borderRadius: theme => theme.radii.none,
          },
        },
        // You cannot spread `sx` directly because `SxProps` (typeof sx) can be an array.
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {options.map(option => {
        return <SelectOption key={option.value} selectSize={size} {...option} />
      })}
    </MuiSelect>
  )
})

export default Select
