import { useState } from 'react'
import { useParams, useRouteMatch, Switch, Route, Redirect, Link } from 'react-router-dom'
import { useQuery, gql, NetworkStatus } from '@apollo/client'
import { Container, Stack } from '@mui/material'

import useInterval from '@jeeves/hooks/useInterval'
import useWrappers from '@jeeves/pages/Wrappers/hooks/useWrappers'
import { WrappersProvider } from '@jeeves/pages/Wrappers/contexts/WrappersContext'

import {
  Advanced,
  Deployment,
  Instances,
  SidecarError,
  SidecarHeader,
  SidecarLoading,
  Tab,
  Tabs,
} from './components'

import { SidecarDetailProvider } from './contexts/SidecarDetailContext'

import { Repositories, RepositoriesProvider } from './tabs'

const SIDECAR_DETAILS = gql`
  query SidecarDetails($sidecarId: String!) {
    ...AdvancedTab_query
    ...Repositories_query
    ...SidecarHeader_query
  }
  ${Repositories.fragments.Repositories_queryFragment}
`

const tabs = [
  {
    label: 'Instances',
    value: 'instances',
  },
  {
    label: 'Bindings',
    value: 'bindings',
  },
  {
    label: 'Deployment',
    value: 'deployment',
  },
  {
    label: 'Settings',
    value: 'settings',
  },
]

const WrapperDetail = () => {
  const { id } = useParams()
  const { path, url } = useRouteMatch()
  const routeMatch = useRouteMatch(tabs.map(tab => `${path}/${tab.value}`))
  const { ec } = useWrappers()

  const {
    data: sidecarDetailsData,
    error: sidecarDetailsError,
    fetchMore,
    refetch,
    networkStatus,
  } = useQuery(SIDECAR_DETAILS, {
    notifyOnNetworkStatusChange: true,
    variables: {
      sidecarId: id,
    },
  })

  const [inFlight, setInFlight] = useState(false)
  const [fetchDataError, setFetchDataError] = useState(false)
  const [wrapper, setWrapper] = useState(null)

  const error = fetchDataError || sidecarDetailsError
  const loading = networkStatus === NetworkStatus.loading

  const fetchData = async () => {
    setInFlight(true)
    try {
      const result = await ec.get(`/sidecars/${id}/details`)
      if (result && result.data && result.data !== {}) {
        setWrapper(result.data)
        setFetchDataError(false)
      }
    } catch (e) {
      setFetchDataError(true)
    } finally {
      setInFlight(false)
    }
  }

  useInterval(async () => {
    if (!inFlight) {
      fetchData()
    }
  }, 30000)

  return (
    <SidecarDetailProvider sidecarId={id}>
      <Container maxWidth="xl">
        {loading ? (
          <SidecarLoading />
        ) : error ? (
          <SidecarError />
        ) : (
          <Stack spacing={4}>
            <SidecarHeader query={sidecarDetailsData} />

            <Tabs value={routeMatch?.url}>
              {tabs.map(tab => (
                <Tab
                  key={tab.value}
                  label={tab.label}
                  value={`${url}/${tab.value}`}
                  component={Link}
                  to={`${url}/${tab.value}`}
                />
              ))}
            </Tabs>

            <Switch>
              <Route path={`${path}/instances`}>
                <Instances />
              </Route>

              <Route path={`${path}/bindings`}>
                <RepositoriesProvider
                  fetchMore={fetchMore}
                  refetch={refetch}
                  networkStatus={networkStatus}
                >
                  <Repositories query={sidecarDetailsData} />
                </RepositoriesProvider>
              </Route>

              <Route path={`${path}/settings`}>
                {wrapper && <Advanced sidecar={wrapper} query={sidecarDetailsData} />}
              </Route>

              <Route path={`${path}/deployment`}>
                <Deployment />
              </Route>

              <Redirect to={`${path}/${tabs?.[0].value}`} />
            </Switch>
          </Stack>
        )}
      </Container>
    </SidecarDetailProvider>
  )
}

export default () => (
  <WrappersProvider>
    <WrapperDetail />
  </WrappersProvider>
)
