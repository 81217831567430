import { Stack } from '@mui/material'

import { usePolicySummary } from '@jeeves/pages/RepositoryDetail/Tabs/Policies/components/PolicySummary/PolicySummaryContext'
import {
  getDisplayedPolicyIdentity,
  PolicyIdentityType,
} from '@jeeves/pages/RepositoryDetail/Tabs/Policies/helpers/policyIdentities'

import PolicyRule from '../PolicyRule'

const UserSegmentation = () => {
  const { policy } = usePolicySummary()
  const { dataLabels, dataset, restriction, scope } = policy.parameters

  const renderFirstRule = () => {
    if ('databaseAccounts' in scope) {
      const title = 'When any of the following database accounts'
      const content = scope.databaseAccounts
      return <PolicyRule title={title} content={content} />
    }

    if ('identities' in scope) {
      const title = 'When any of the following users'
      const content = scope.identities.map(
        (identity: { __typename: PolicyIdentityType; name: string }) => {
          return getDisplayedPolicyIdentity(identity)
        }
      )
      return <PolicyRule title={title} content={content} />
    }
  }

  return (
    <Stack spacing={1}>
      {renderFirstRule()}
      <PolicyRule title="reads data labeled" content={dataLabels} />
      <PolicyRule title="from" content={dataset} />
      <PolicyRule title="the following filter condition is applied:" content={restriction} />
    </Stack>
  )
}

export default UserSegmentation
