/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@material-ui/core/'

const NameDialog = ({ isOpen, value, setValue, handleClose, handleAdd }) => (
  <Dialog open={isOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
    <DialogTitle id="form-dialog-title">Integration name</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Please choose an identifier for the integration. You will not be able to change it later
      </DialogContentText>
      <TextField
        autoFocus
        margin="dense"
        id="name"
        label=""
        type="text"
        variant="outlined"
        fullWidth
        value={value}
        onChange={e => setValue(e.target.value)}
      />
    </DialogContent>
    <DialogActions
      css={css`
        padding: 0 20px 20px 20px;
        margin: 0;
      `}
    >
      <Button onClick={handleClose} variant="outlined" color="secondary">
        Cancel
      </Button>
      <Button onClick={handleAdd} variant="contained" color="primary">
        Add Integration
      </Button>
    </DialogActions>
  </Dialog>
)

export default NameDialog
