import { Box } from '@mui/material'

import useDataMasking from './useDataMasking'

const DataMasking = () => {
  const { policyRules } = useDataMasking()

  return <Box>{policyRules}</Box>
}

export default DataMasking
