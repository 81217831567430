import * as React from 'react'
import { useForm } from 'react-hook-form'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import FormControl from '@mui/material/FormControl'

import { Input, InputLabel, Button } from '@jeeves/new-components'

import { useAppsAndBiToolsState } from '../../../contexts/AppsAndBiToolsContext'

const StepFive = ({
  prevStep,
  closeWizard,
  applicationName: applicationNameProp,
  handleMutateServiceAccount,
  setDatabaseAccountInfo,
}) => {
  const { isRegistering } = useAppsAndBiToolsState()
  const {
    watch,
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      applicationName: applicationNameProp,
    },
  })

  React.useEffect(() => {
    if (isSubmitSuccessful) {
      closeWizard()
    }
  }, [isSubmitSuccessful, closeWizard])

  const onSubmit = async ({ applicationName }) => {
    try {
      setDatabaseAccountInfo(info => {
        return {
          ...info,
          applicationName,
        }
      })

      await handleMutateServiceAccount()
    } catch (error) {}
  }

  const applicationName = watch('applicationName')

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4}>
        <Box>
          <FormControl
            variant="standard"
            error={Boolean(errors?.applicationName)}
            sx={{
              width: '100%',
              maxWidth: '32rem',
            }}
          >
            <InputLabel htmlFor="application-name-input">
              Provide a name for your application
            </InputLabel>
            <Input
              id="application-name-input"
              inputProps={{
                ...register('applicationName', {
                  required: true,
                }),
              }}
            />
          </FormControl>
        </Box>
        <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
          <Button variant="outlined" onClick={prevStep}>
            Back
          </Button>

          <Button type="submit" disabled={!applicationName} loading={isSubmitting}>
            {isRegistering ? 'Register' : 'Update'}
          </Button>
        </Stack>
      </Stack>
    </Box>
  )
}

export default StepFive
