import * as React from 'react'
import { getCoreRowModel, useReactTable } from '@tanstack/react-table'

import { getColumns } from './utils'
import { useBindingsTableContext } from '../BindingsTableContext'

import { gql } from '@apollo/client'

const useBindingsTable_queryFragment = gql`
  fragment useBindingsTable_query on Query {
    ...EditBinding_queryFragment
  }
  ${getColumns.fragments.EditBinding_queryFragment}
`

const useBindingsTable = () => {
  const {
    pageIndex,
    pageSize,
    setPagination,
    currentPageEdges,
    paginate,
    filteredCount,
    previousPageEndCursorStack,
    setPreviousPageEndCursorStack,
  } = useBindingsTableContext()

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  )

  const table = useReactTable({
    data: React.useMemo(() => currentPageEdges?.map(edge => edge.node) ?? [], [currentPageEdges]),
    columns: getColumns(),
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    onPaginationChange: setPagination,
    pageCount: Math.ceil(filteredCount / pageSize) ?? -1,
    state: {
      pagination,
    },
  })

  return {
    table,
    getPreviousPage: async () => {
      const cursor = previousPageEndCursorStack[previousPageEndCursorStack.length - 2]

      // Since we're navigating backwards, we need to remove the last cursor from the stack
      setPreviousPageEndCursorStack(oldPreviousPageEndCursorStack =>
        oldPreviousPageEndCursorStack.slice(0, -1)
      )

      const args = {
        ...(cursor && { after: cursor }),
      }

      await paginate(args)
      table.previousPage()
    },
    getNextPage: async () => {
      const currentPageEndCursor = currentPageEdges?.[currentPageEdges?.length - 1]?.cursor
      setPreviousPageEndCursorStack(oldPreviousPageEndCursorStack => [
        ...oldPreviousPageEndCursorStack,
        currentPageEndCursor,
      ])

      await paginate({ after: currentPageEndCursor })
      table.nextPage()
    },
  }
}

useBindingsTable.fragments = {
  useBindingsTable_queryFragment,
}

export default useBindingsTable
