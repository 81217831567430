import { useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'

import {
  Box,
  Divider,
  FormControl,
  Stack,
  RadioGroup,
  FormHelperText,
  FormControlLabel,
  Radio,
  Checkbox,
  FormGroup,
} from '@mui/material'
import { useRepositoryDetailContext } from '@jeeves/pages/RepositoryDetail/contexts/RepositoryDetailContext'

import { InputLabel } from '@jeeves/new-components'

import InputTags from '@jeeves/pages/RepositoryDetail/Tabs/Policies/views/AddPolicy/components/InputTags'
import Footer from '@jeeves/pages/RepositoryDetail/Tabs/Policies/views/AddPolicy/components/Footer'

const StepTwo = ({ dataProtectionInfo, nextStep, prevStep, setDataProtectionInfo }) => {
  const { repoId } = useRepositoryDetailContext()
  const {
    setValue,
    handleSubmit,
    watch,
    control,
    register,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      restrictedOperations: dataProtectionInfo.parameters.restrictedOperations,
      dataLabels: dataProtectionInfo.parameters.dataLabels,
      action: dataProtectionInfo.parameters.action,
    },
  })

  const onSubmit = data => {
    setDataProtectionInfo(info => {
      return {
        ...info,
        parameters: {
          ...info.parameters,
          dataLabels: data.dataLabels,
          action: data.action,
          restrictedOperations: data.restrictedOperations,
        },
      }
    })
  }

  const onDeleteDataLabels = updatedTags => {
    setValue('dataLabels', updatedTags, { shouldDirty: true })
  }

  const onAddDataLabels = updatedTags => {
    setValue('dataLabels', updatedTags, { shouldValidate: true, shouldDirty: true })
  }

  useEffect(() => {
    if (isSubmitSuccessful) {
      nextStep()
    }
  }, [isSubmitSuccessful, nextStep])

  const { dataLabels = [], restrictedOperations } = watch()

  const validateOperations =
    restrictedOperations.read || restrictedOperations.update || restrictedOperations.delete

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4}>
        <Stack spacing={4} sx={{ maxWidth: '32rem' }}>
          <FormControl fullWidth variant="standard">
            <InputLabel component="legend" required>
              Database Operations
            </InputLabel>

            <Controller
              name="restrictedOperations"
              control={control}
              rules={{
                validate: () => {
                  return validateOperations || 'Please select at least one operation'
                },
              }}
              render={() => (
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox {...register('restrictedOperations.read')} size="small" />}
                    label="Read"
                    checked={restrictedOperations.read}
                  />
                  <FormControlLabel
                    control={<Checkbox {...register('restrictedOperations.update')} size="small" />}
                    label="Update"
                    checked={restrictedOperations.update}
                  />
                  <FormControlLabel
                    control={<Checkbox {...register('restrictedOperations.delete')} size="small" />}
                    label="Delete"
                    checked={restrictedOperations.delete}
                  />
                </FormGroup>
              )}
            />
            <FormHelperText sx={{ typography: 'body2' }} error={!!errors?.restrictedOperations}>
              {errors?.restrictedOperations?.message}
            </FormHelperText>
          </FormControl>

          <FormControl variant="standard" error={Boolean(errors?.dataLabels)}>
            <Controller
              name="dataLabels"
              control={control}
              rules={{
                validate: dataLabels => {
                  return dataLabels.length > 0 || 'Please enter at least one label'
                },
              }}
              render={() => (
                <InputTags
                  required
                  inputLabel="Data Labels"
                  tags={dataLabels}
                  isSubmitting={isSubmitting}
                  onDelete={onDeleteDataLabels}
                  onAdd={onAddDataLabels}
                />
              )}
            />
            <FormHelperText sx={{ typography: 'body2' }} error={!!errors?.dataLabels}>
              {errors?.dataLabels?.message}
            </FormHelperText>
          </FormControl>

          <FormControl variant="standard" sx={{ flex: 1 }}>
            <InputLabel component="legend">Policy Actions</InputLabel>
            <Controller
              name="action"
              control={control}
              render={({ field }) => (
                <RadioGroup row aria-label="action" {...field}>
                  <Stack sx={{ flexGrow: 1 }}>
                    <FormControlLabel
                      value="alert"
                      control={<Radio size="small" disableRipple />}
                      label="Alert"
                      slotProps={{
                        typography: {
                          variant: 'h6',
                          sx: {
                            color: 'text.primary',
                          },
                        },
                      }}
                    />
                    <FormControlLabel
                      value="alertAndBlock"
                      control={<Radio size="small" disableRipple />}
                      label="Alert and Block"
                      slotProps={{
                        typography: {
                          variant: 'h6',
                          sx: {
                            color: 'text.primary',
                          },
                        },
                      }}
                    />
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Stack>

        <Divider />

        <Footer repoId={repoId} prevStep={prevStep} policyId={dataProtectionInfo.id} />
      </Stack>
    </Box>
  )
}

export default StepTwo
