/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Fragment } from 'react'
import { withStyles, TableRow, Typography } from '@material-ui/core'
import classNames from 'classnames'

import { useAppConfig } from '@jeeves/hooks'
import USFlagUrl from './US.png'
import { ExpandButton, TableCell } from '@jeeves/components/Table'
import useAlerts from '@jeeves/pages/Alerts/hooks/useAlerts'
import ExpandedRow from '../ExpandedRow'
import slackRgbUrl from './Slack_RGB.svg'

const styles = theme => ({
  expanded: {
    '& td': {
      border: 'none',
    },
  },
  clickable: {
    cursor: 'pointer',
  },
})

const Row = ({ alert, classes, expanded, handleExpandRow }) => {
  const { formatDate, severityMap } = useAlerts()
  const {
    config: { isDemo },
  } = useAppConfig()

  return (
    <Fragment>
      <TableRow
        tabIndex={-1}
        key={alert.id}
        hover={!expanded}
        className={classNames({
          [classes.expanded]: expanded,
        })}
        css={() => css`
          transition: opacity 0.2s ease-in-out;
          td {
            border-bottom: ${expanded ? 0 : undefined};
          }
        `}
      >
        <TableCell
          preserveHeight
          css={() => css`
            padding: 4px 4px 4px 24px;
          `}
        >
          {alert.alerttype !== 'authenticationFailure' && (
            <ExpandButton onClick={handleExpandRow} expanded={expanded} />
          )}
        </TableCell>
        <TableCell
          preserveHeight
          css={() => css`
            min-width: 220px;
            padding: 4px 24px;
          `}
        >
          <Typography>{formatDate(alert.time)}</Typography>
        </TableCell>
        {isDemo && (
          <TableCell
            preserveHeight
            css={() => css`
              padding: 4px 24px;
            `}
          >
            <img
              css={() => css`
                max-width: 125px;
              `}
              src={slackRgbUrl}
              alt=""
            />
          </TableCell>
        )}

        <TableCell
          preserveHeight
          css={() => css`
            padding: 4px 24px;
          `}
        >
          {alert.category}
        </TableCell>
        <TableCell
          preserveHeight
          numeric
          css={() => css`
            text-align: left;
            padding: 0;
          `}
        >
          {severityMap.get(alert.severity)}
        </TableCell>
        <TableCell preserveHeight>
          {alert.identity && alert.identity.user ? alert.identity.user : `\u2014`}
        </TableCell>
        <TableCell preserveHeight>{alert.reponame || `\u2014`}</TableCell>
        {isDemo && (
          <TableCell preserveHeight css={{ minWidth: '180px' }}>
            <div
              css={() => css`
                display: flex;
                justify-content: flex-start;
                align-items: center;
              `}
            >
              <img src={USFlagUrl} alt="United States Flag" css={{ marginRight: '8px' }} />
              <span>United States</span>
            </div>
          </TableCell>
        )}
      </TableRow>

      <ExpandedRow formatDate={formatDate} alert={alert} expanded={expanded} />
    </Fragment>
  )
}

export default withStyles(styles)(Row)
