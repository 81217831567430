/**
 * Hardcoded entitlements AAA plan synced to:
 * https://github.com/cyralinc/cms/blob/main/deploy/content/entitlements/plans/Enterprise.json
 */
const EnterprisePlan = {
  userFriendlyName: 'Enterprise',
  features: [
    {
      internalName: 'AllowDataClassification',
    },
    {
      internalName: 'AllowDataDiscovery',
    },
    {
      internalName: 'AllowPolicyMonitoring',
    },
    {
      internalName: 'AllowPolicyBlocking',
    },
    {
      internalName: 'AllowOpenTracing',
    },
    {
      internalName: 'AllowedRepositories',
      internalSubFeatureName: 'auroramysql',
    },
    {
      internalName: 'AllowedRepositories',
      internalSubFeatureName: 'aurorapostgres',
    },
    {
      internalName: 'AllowedRepositories',
      internalSubFeatureName: 'bigquery',
    },
    {
      internalName: 'AllowedRepositories',
      internalSubFeatureName: 'databricks',
    },
    {
      internalName: 'AllowedRepositories',
      internalSubFeatureName: 'denodo',
    },
    {
      internalName: 'AllowedRepositories',
      internalSubFeatureName: 'dremio',
    },
    {
      internalName: 'AllowedRepositories',
      internalSubFeatureName: 'dynamodb',
    },
    {
      internalName: 'AllowedRepositories',
      internalSubFeatureName: 'dynamodbstreams',
    },
    {
      internalName: 'AllowedRepositories',
      internalSubFeatureName: 'galera',
    },
    {
      internalName: 'AllowedRepositories',
      internalSubFeatureName: 'kafka',
    },
    {
      internalName: 'AllowedRepositories',
      internalSubFeatureName: 'mariadb',
    },
    {
      internalName: 'AllowedRepositories',
      internalSubFeatureName: 'mongodb',
    },
    {
      internalName: 'AllowedRepositories',
      internalSubFeatureName: 'mysql',
    },
    {
      internalName: 'AllowedRepositories',
      internalSubFeatureName: 'oracle',
    },
    {
      internalName: 'AllowedRepositories',
      internalSubFeatureName: 'postgresql',
    },
    {
      internalName: 'AllowedRepositories',
      internalSubFeatureName: 'redshift',
    },
    {
      internalName: 'AllowedRepositories',
      internalSubFeatureName: 'rest',
    },
    {
      internalName: 'AllowedRepositories',
      internalSubFeatureName: 'restproxy',
    },
    {
      internalName: 'AllowedRepositories',
      internalSubFeatureName: 's3',
    },
    {
      internalName: 'AllowedRepositories',
      internalSubFeatureName: 'snowflake',
    },
    {
      internalName: 'AllowedRepositories',
      internalSubFeatureName: 'sqlserver',
    },
    {
      internalName: 'AllowedAlerting',
      internalSubFeatureName: 'jira',
    },
    {
      internalName: 'AllowedAlerting',
      internalSubFeatureName: 'pagerduty',
    },
    {
      internalName: 'AllowedAlerting',
      internalSubFeatureName: 'slack',
    },
    {
      internalName: 'AllowedAlerting',
      internalSubFeatureName: 'teams',
    },
    {
      internalName: 'AllowedAlerting',
      internalSubFeatureName: 'thehive',
    },
    {
      internalName: 'AllowedLogging',
      internalSubFeatureName: 'elk',
    },
    {
      internalName: 'AllowedLogging',
      internalSubFeatureName: 'kafka',
    },
    {
      internalName: 'AllowedLogging',
      internalSubFeatureName: 'logstash',
    },
    {
      internalName: 'AllowedLogging',
      internalSubFeatureName: 'cloudwatch',
    },
    {
      internalName: 'AllowedLogging',
      internalSubFeatureName: 'datadog',
    },
    {
      internalName: 'AllowedLogging',
      internalSubFeatureName: 'splunk',
    },
    {
      internalName: 'AllowedLogging',
      internalSubFeatureName: 'sumologic',
    },
    {
      internalName: 'AllowedJeevesFeatures',
      internalSubFeatureName: 'showDataLabels',
    },
    {
      internalName: 'AllowedJeevesFeatures',
      internalSubFeatureName: 'showSettings',
    },
    {
      internalName: 'AllowedJeevesFeatures',
      internalSubFeatureName: 'showYAMLPolicies',
    },
    {
      internalName: 'AllowedJeevesFeatures',
      internalSubFeatureName: 'showAudit',
    },
    {
      internalName: 'AllowedJeevesFeatures',
      internalSubFeatureName: 'showAlerts',
    },
    {
      internalName: 'AllowedJeevesFeatures',
      internalSubFeatureName: 'showSupportLoginToggle',
    },
  ],
}

export const entitlementPlans = {
  AAA: JSON.stringify(EnterprisePlan),
}
