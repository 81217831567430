import { Box, CircularProgress, Stack, Typography } from '@mui/material'

const SidecarLoading = () => {
  return (
    <Box sx={{ pt: 4 }}>
      <Stack spacing={2} sx={{ alignItems: 'center' }}>
        <CircularProgress size={64} />

        <Typography variant="h6" sx={{ color: 'text.secondary' }}>
          Loading sidecar details...
        </Typography>
      </Stack>
    </Box>
  )
}

export default SidecarLoading
