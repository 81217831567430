import { Card, CardMedia, Link, Stack, Typography } from '@mui/material'

export interface HelpAndSupportCardProps {
  helperText: string
  href: string
  icon: JSX.Element
  title: string
}

const HelpAndSupportCard = ({ helperText, href, icon, title }: HelpAndSupportCardProps) => {
  return (
    <Link href={href} underline="none" target="_blank">
      <Card
        elevation={0}
        sx={{
          display: 'flex',
          height: '180px',
          borderRadius: theme => theme.radii.xl,
          ':hover': {
            outline: theme => `2px solid ${theme.palette.primary.main}`,
          },
        }}
      >
        <CardMedia
          sx={{
            height: '100%',
            width: '10px',
            backgroundColor: 'primary.main',
          }}
        />
        <Stack sx={{ padding: theme => theme.spacing(5.75, 4) }}>
          <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
            {icon}
            <Typography variant="h4">{title}</Typography>
          </Stack>
          <Typography
            variant="body2"
            sx={{
              color: 'text.secondary',
              paddingLeft: theme => theme.spacing(8),
            }}
          >
            {helperText}
          </Typography>
        </Stack>
      </Card>
    </Link>
  )
}

export default HelpAndSupportCard
