/** @jsxRuntime classic */
/** @jsx jsx */
import { useState, useEffect } from 'react'
import { jsx, css } from '@emotion/react'
import { withStyles, Table, TableBody, TableFooter, TableRow } from '@material-ui/core'
import { get as lodashGet } from 'lodash'

import { Paper } from '@jeeves/components/Primitives'
import { useAuth } from '@jeeves/components/Auth'
import { usePopup } from '@jeeves/hooks'
import ExpressClient from '@jeeves/clients/express'
import Toolbar from '@jeeves/components/Toolbar'
import { stableSort, getSorting } from '@jeeves/utils'
import { TableHead, TablePagination } from '@jeeves/components/Table'
import ServiceAccountRow from './components/ServiceAccountRow'
import RowSpan from '@jeeves/components/RowSpan'
import Loading from '@jeeves/components/Loading'
import CreateServiceAccount from './components/CreateServiceAccount'

const styles = theme => ({
  appBarSpacer: theme.mixins.toolbar,
})

const COLUMNS = [
  { id: 'displayName', numeric: false, disablePadding: false, label: 'Name', sortDefault: true },
  // { id: 'mfa', numeric: false, label: '2FA' },
  { id: 'clientId', numberic: false, disablePadding: false, label: 'Client id' },
  //   { id: 'roles', numeric: false, disablePadding: false, label: 'Roles' },
  { id: 'buttons', label: null },
]

const ServiceAccounts = ({ route, location }) => {
  const { getTokenSilently } = useAuth()
  const ec = new ExpressClient(getTokenSilently)
  const { setPopup, popupTypes } = usePopup()
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('name')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [loading, setLoading] = useState(false)
  const [serviceAccounts, setServiceAccounts] = useState([])
  const [roles, setRoles] = useState([])

  const handleRequestSort = (event, property) => {
    const orderBy = property
    let newOrder = 'asc'
    if (orderBy === property && order === 'asc') {
      newOrder = 'desc'
    }
    setOrder(newOrder)
    setOrderBy(orderBy)
  }

  const handleChangePage = (event, page) => {
    setPage(page)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value)
  }

  const fetchServiceAccountsAndRoles = async () => {
    setLoading(true)
    try {
      const [serviceAccounts, roles] = await Promise.all([
        ec.get('/users/serviceAccounts').then(res => res.data),
        ec.get('/users/roles').then(res => res.data.roles),
      ])
      setServiceAccounts(serviceAccounts)
      setRoles(roles)
    } catch (e) {
      const popupMessage = lodashGet(e, 'response.data.error.message', '')
      if (popupMessage) {
        setPopup(popupTypes.ERROR, popupMessage)
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchServiceAccountsAndRoles()
  }, [])

  return (
    <Paper css={{ position: 'relative' }}>
      <Toolbar
        title="API Access Keys"
        subtitle="Credentials used by clients to access Cyral APIs."
        titleAction={<CreateServiceAccount roles={roles} refresh={fetchServiceAccountsAndRoles} />}
      />
      <Table>
        <TableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          columns={COLUMNS}
          rowCount={serviceAccounts.length}
        />

        <TableBody>
          {loading ? (
            <RowSpan colSpan={COLUMNS.length}>
              <Loading />
            </RowSpan>
          ) : (
            stableSort(serviceAccounts, getSorting(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((serviceAccount, index) => (
                <ServiceAccountRow
                  serviceAccount={serviceAccount}
                  roles={roles}
                  colorIndex={index}
                  refresh={fetchServiceAccountsAndRoles}
                  key={serviceAccount.clientId}
                />
              ))
          )}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TablePagination
              count={serviceAccounts.length}
              colSpan={20}
              page={page}
              rowsPerPage={rowsPerPage}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </Paper>
  )
}

export default withStyles(styles)(ServiceAccounts)
