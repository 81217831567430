/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { IconButton } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

export const ActionButton = ({ children, color = 'primary', ...props }) => (
  <IconButton
    variant="contained"
    css={theme => css`
      background-color: ${theme.palette[color].main};
      color: white;
      padding: 6px;

      &:hover {
        background-color: ${theme.palette[color].dark};
      }
    `}
    {...props}
  >
    {children || <AddIcon />}
  </IconButton>
)
