import { Controller, FormProvider, useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import {
  Box,
  Stack,
  Typography,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  FormControl,
  FormHelperText,
  Link as MuiLink,
} from '@mui/material'

import { graphql, useFragment } from '@jeeves/graphql'
import { LearnMore, Select, useToast } from '@jeeves/new-components'
import { getGraphQLErrorMessage } from '@jeeves/utils/helpers'
import { useRepositoryDetailContext } from '@jeeves/pages/RepositoryDetail/contexts/RepositoryDetailContext'

import AllowPassthroughAuthentication from './AllowPassthroughAuthentication'
import FormFooter from './FormFooter'
import RepoInaccessibleAlert from './RepoInaccessibleAlert'

const MongoDBAuthentication_QueryFragment = graphql(`
  fragment MongoDBAuthentication_QueryFragment on Query {
    awsIamIntegrations {
      id
      name
    }
  }
`)

const MongoDBAuthentication_MongoDBRepoAuthenticationConfigFragment = graphql(`
  fragment MongoDBAuthentication_MongoDBRepoAuthenticationConfigFragment on MongoDBRepoAuthenticationConfig {
    allowNativeAuthentication
    advancedCyralBrokeredAuthentication {
      awsIamIntegration {
        id
        name
      }
      type
    }
  }
`)

const UPDATE_REPO_AUTHENTICATION_CONFIG_MONGODB = graphql(`
  mutation UpdateRepoAuthenticationConfigMongoDb(
    $repoId: ID!
    $input: UpdateRepoAuthenticationConfigInput!
  ) {
    updateRepoAuthenticationConfig(repoId: $repoId, input: $input) {
      repo {
        id
        config {
          authentication {
            ... on MongoDBRepoAuthenticationConfig {
              allowNativeAuthentication
              advancedCyralBrokeredAuthentication {
                awsIamIntegration {
                  id
                  name
                }
                type
              }
            }
          }
        }
      }
    }
  }
`)

const MongoDBAuthentication = ({
  mongoDBRepoAuthenticationConfig: MongoDBRepoAuthenticationConfigProp,
  query: queryProp,
}) => {
  const { awsIamIntegrations } = useFragment(MongoDBAuthentication_QueryFragment, queryProp)
  const { allowNativeAuthentication, advancedCyralBrokeredAuthentication } = useFragment(
    MongoDBAuthentication_MongoDBRepoAuthenticationConfigFragment,
    MongoDBRepoAuthenticationConfigProp
  )
  const { repoId } = useRepositoryDetailContext()
  const { toast } = useToast()

  const methods = useForm({
    defaultValues: {
      allowNativeAuthentication,
      cyralBrokeredAuthentication: {
        enabled: !!advancedCyralBrokeredAuthentication,
        type: advancedCyralBrokeredAuthentication?.type ?? 'CyralAccessToken',
        awsIamIntegrationId: advancedCyralBrokeredAuthentication?.awsIamIntegration?.id ?? '',
      },
    },
  })

  const {
    control,
    handleSubmit,
    watch,
    getValues,
    reset,
    formState: { isDirty },
  } = methods

  const [updateRepoAuthenticationConfigMongoDb, { loading }] = useMutation(
    UPDATE_REPO_AUTHENTICATION_CONFIG_MONGODB,
    {
      onCompleted: () => {
        toast({
          variant: 'success',
          description: 'Authentication preferences updated.',
        })
        reset(getValues())
      },
      onError: error => {
        toast({
          variant: 'error',
          description:
            getGraphQLErrorMessage(error) ||
            'An error occurred while updating preferences. Please try again later.',
        })
      },
    }
  )

  const {
    cyralBrokeredAuthentication,
    allowNativeAuthentication: allowNativeAuthenticationFormValue,
  } = watch()

  const isAwsIamIntegrationSelected = cyralBrokeredAuthentication.type === 'AwsIamIntegration'

  const awsIamIntegrationOptions = awsIamIntegrations.map(awsIamIntegration => ({
    label: awsIamIntegration.name,
    value: awsIamIntegration.id,
  }))

  const onSubmit = async values => {
    const { allowNativeAuthentication, cyralBrokeredAuthentication } = values

    const getAdvancedCyralBrokeredAuthenticationInput = () => {
      if (!cyralBrokeredAuthentication.enabled) {
        return null
      }

      const type = cyralBrokeredAuthentication.type

      return {
        type,
        ...(type === 'AwsIamIntegration' && {
          awsIamIntegrationId: cyralBrokeredAuthentication.awsIamIntegrationId,
        }),
      }
    }

    const input = {
      allowNativeAuthentication,
      cyralBrokeredAuthenticationConfig: {
        advancedCyralBrokeredAuthentication: getAdvancedCyralBrokeredAuthenticationInput(),
      },
    }

    await updateRepoAuthenticationConfigMongoDb({
      variables: {
        repoId,
        input,
      },
    })
  }

  const saveDisabled =
    !isDirty || (isAwsIamIntegrationSelected && !cyralBrokeredAuthentication.awsIamIntegrationId)

  const shouldRenderRepoInaccessibleAlert =
    !allowNativeAuthenticationFormValue && !cyralBrokeredAuthentication.enabled

  return (
    <FormProvider {...methods}>
      <Stack spacing={2} component="form" onSubmit={handleSubmit(onSubmit)}>
        {shouldRenderRepoInaccessibleAlert && <RepoInaccessibleAlert />}
        <Box>
          <Controller
            name="cyralBrokeredAuthentication.enabled"
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <FormControlLabel
                control={<Checkbox name={name} onChange={onChange} checked={value} />}
                label={
                  <Typography variant="h4" sx={{ color: 'text.primary' }}>
                    Enable Cyral brokered authentication to this database.
                  </Typography>
                }
              />
            )}
          />

          {cyralBrokeredAuthentication.enabled && (
            <Controller
              name="cyralBrokeredAuthentication.type"
              control={control}
              render={({ field }) => (
                <RadioGroup aria-label="cyralAuthentication" {...field} sx={{ marginLeft: 4 }}>
                  <FormControlLabel
                    value="CyralAccessToken"
                    control={<Radio size="small" disableRipple />}
                    label={
                      <Typography variant="h6" sx={{ color: 'text.primary' }}>
                        Use Cyral access tokens.{' '}
                        <LearnMore docsPath="/how-to/manage-access-tokens" />
                      </Typography>
                    }
                  />

                  <FormControl variant="standard">
                    <Stack spacing={1}>
                      <FormControlLabel
                        value="AwsIamIntegration"
                        control={<Radio size="small" />}
                        label={
                          <Typography variant="h6" sx={{ color: 'text.primary' }}>
                            Use AWS IAM role-based authentication.{' '}
                            <LearnMore docsPath="/integrations/authentication/aws/iam" />
                          </Typography>
                        }
                        slotProps={{
                          typography: {
                            variant: 'h6',
                            sx: {
                              color: 'text.primary',
                            },
                          },
                        }}
                      />

                      {isAwsIamIntegrationSelected && (
                        <Stack spacing={1} sx={{ paddingLeft: 3 }}>
                          <Controller
                            name="cyralBrokeredAuthentication.awsIamIntegrationId"
                            control={control}
                            render={({ field }) => (
                              <Select options={awsIamIntegrationOptions} {...field} />
                            )}
                          />
                          <FormHelperText sx={{ typography: 'body2' }}>
                            Please choose an AWS IAM authenticator. These are configured on the{' '}
                            <MuiLink
                              component={Link}
                              to="/integrations"
                              underline="none"
                              sx={{ fontWeight: 600 }}
                            >
                              Integrations page.
                            </MuiLink>
                          </FormHelperText>
                        </Stack>
                      )}
                    </Stack>
                  </FormControl>
                </RadioGroup>
              )}
            />
          )}
        </Box>

        <AllowPassthroughAuthentication />

        <FormFooter saveLoading={loading} saveDisabled={saveDisabled} />
      </Stack>
    </FormProvider>
  )
}

export default MongoDBAuthentication
