import * as React from 'react'
import { useMutation, gql } from '@apollo/client'
import { useForm, useFieldArray } from 'react-hook-form'
import { isIPv4 } from 'is-ip'
import isCIDR from 'is-cidr'

import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert'
import Link from '@mui/material/Link'

import DeleteIcon from '@mui/icons-material/Delete'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { getDocsURL } from '@jeeves/utils/docsURL'

import {
  Dialog,
  Button,
  Input,
  InputLabel,
  DialogActions,
  IconButton,
} from '@jeeves/new-components'

import { useRepositoryDetailContext } from '@jeeves/pages/RepositoryDetail/contexts/RepositoryDetailContext'

const CREATE_NETWORK_SHIELD_RULE = gql`
  mutation CreateNetworkShieldRule(
    $repoId: String!
    $networkShieldRule: CreateNetworkShieldRuleInput!
  ) {
    createNetworkShieldRule(repoId: $repoId, networkShieldRule: $networkShieldRule) {
      networkShieldRule {
        id
        name
        clientIPAddresses
        databaseAccounts
        description
      }
      networkShield {
        id
        enabled
        rules {
          id
          name
          description
          clientIPAddresses
          databaseAccounts
        }
      }
    }
  }
`

const UPDATE_NETWORK_SHIELD_RULE = gql`
  mutation UpdateNetworkShieldRule(
    $repoId: String!
    $networkShieldRule: UpdateNetworkShieldRuleInput!
  ) {
    updateNetworkShieldRule(repoId: $repoId, networkShieldRule: $networkShieldRule) {
      networkShieldRule {
        id
        name
        description
        clientIPAddresses
        databaseAccounts
      }
    }
  }
`

const Upsert = ({ open, closeModal, rule }) => {
  const { repoId } = useRepositoryDetailContext()
  const isCreating = Boolean(!rule?.id)

  const [createNetworkShieldRule] = useMutation(CREATE_NETWORK_SHIELD_RULE)
  const [updateNetworkShieldRule] = useMutation(UPDATE_NETWORK_SHIELD_RULE)

  const {
    register,
    reset,
    getValues,
    handleSubmit,
    control,
    watch,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      ruleName: rule?.name || '',
      description: rule?.description || '',
      clientIPs: rule?.clientIPAddresses.map(ipAddress => ({ value: ipAddress })) ?? [],
      dbAccounts: rule?.databaseAccounts.map(dbAccount => ({ value: dbAccount })) ?? [],
    },
  })

  const {
    fields: clientIPFields,
    append: clientIPAppend,
    remove: clientIPRemove,
  } = useFieldArray({
    control,
    name: 'clientIPs',
  })

  const {
    fields: accountFields,
    append: accountAppend,
    remove: accountRemove,
  } = useFieldArray({
    control,
    name: 'dbAccounts',
  })

  React.useEffect(() => {
    if (!isSubmitSuccessful) return

    if (isCreating) {
      reset()
    } else {
      const values = getValues()
      reset(values)
    }

    closeModal()
  }, [isSubmitSuccessful, reset, closeModal, getValues, isCreating])

  const onSubmit = async data => {
    try {
      const networkShieldRule = {
        name: data.ruleName,
        description: data?.description || '',
        clientIPAddresses: data?.clientIPs?.map(ipAddress => ipAddress.value),
        databaseAccounts: data?.dbAccounts?.map(account => account.value),
      }

      if (isCreating) {
        await createNetworkShieldRule({
          variables: {
            repoId,
            networkShieldRule,
          },
        })
      } else {
        await updateNetworkShieldRule({
          variables: {
            repoId,
            networkShieldRule: {
              ...networkShieldRule,
              id: rule.id,
            },
          },
        })
      }
    } catch (error) {}
  }

  const watchClientIPs = watch('clientIPs')
  const watchDBAccounts = watch('dbAccounts')

  return (
    <Dialog open={open} fullWidth>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle variant="h3">{isCreating ? 'Add Rule' : 'Edit Rule'}</DialogTitle>
        <DialogContent
          sx={{
            maxHeight: 'calc(100vh - 64px - 165px)',
          }}
        >
          <Stack spacing={2}>
            <FormControl variant="standard" error={Boolean(errors.ruleName)}>
              <InputLabel htmlFor="ruleName">Rule Name</InputLabel>
              <Input
                id="ruleName"
                inputProps={{ ...register('ruleName', { required: 'Enter a rule name.' }) }}
                fullWidth
                placeholder="Name your rule..."
              />
              {errors.ruleName && (
                <FormHelperText
                  sx={{
                    typography: 'body2',
                  }}
                  error
                >
                  {errors?.ruleName?.message}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl variant="standard" error={Boolean(errors.description)}>
              <InputLabel htmlFor="description-input">Description (Optional)</InputLabel>
              <Input
                id="description-input"
                inputProps={{
                  ...register('description', {
                    maxLength: 250,
                  }),
                }}
                multiline
                rows={4}
                placeholder="Give your rule a description..."
              />
              {errors.description && (
                <FormHelperText
                  sx={{
                    typography: 'body2',
                  }}
                  error
                >
                  The maximum length allowed is 250 characters.
                </FormHelperText>
              )}
            </FormControl>

            <Stack spacing={1}>
              <InputLabel error={Boolean(errors.clientIPs)}>Client IP</InputLabel>
              {clientIPFields.length === 0 ? (
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  Specify IPs to restrict the range of allowed IP addresses for this rule
                </Typography>
              ) : (
                <Stack spacing={1}>
                  {clientIPFields.map((item, index) => {
                    return (
                      <Stack key={item.id} direction="row">
                        <FormControl
                          variant="standard"
                          error={Boolean(errors?.clientIPs?.[index])}
                          sx={{ flex: '1' }}
                        >
                          <Input
                            inputProps={{
                              ...register(`clientIPs[${index}].value`, {
                                required: 'This field is required.',
                                validate: value =>
                                  isIPv4(value) ||
                                  isCIDR.v4(value) ||
                                  'Invalid IP address or CIDR block.',
                              }),
                            }}
                            sx={{
                              typography: 'code',
                            }}
                            placeholder="IP addresses and CIDR blocks are accepted."
                            fullWidth
                          />

                          {errors?.clientIPs?.[index] && (
                            <FormHelperText
                              sx={{
                                typography: 'body2',
                              }}
                              error
                            >
                              {errors.clientIPs[index].value?.message}
                            </FormHelperText>
                          )}
                        </FormControl>

                        <IconButton
                          onClick={() => clientIPRemove(index)}
                          sx={{ alignSelf: 'flex-start' }}
                        >
                          <DeleteIcon fontSize="small" sx={{ color: 'cyralColors.grey.300' }} />
                        </IconButton>
                      </Stack>
                    )
                  })}
                </Stack>
              )}

              <Button
                variant="outlined"
                size="small"
                onClick={() => clientIPAppend({ value: '' })}
                sx={{ alignSelf: 'flex-start' }}
              >
                {clientIPFields.length > 0 ? 'Add IP' : 'Specify IPs'}
              </Button>
            </Stack>

            <Stack spacing={1}>
              <InputLabel error={Boolean(errors?.dbAccounts)}>Database Account</InputLabel>
              {accountFields.length === 0 ? (
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  Specify which accounts this rule applies to.
                </Typography>
              ) : (
                <Stack spacing={1}>
                  {accountFields.map((item, index) => {
                    return (
                      <Stack key={item.id} direction="row">
                        <FormControl
                          variant="standard"
                          error={Boolean(errors?.dbAccounts?.[index])}
                          sx={{ flex: '1' }}
                        >
                          <Input
                            inputProps={{
                              ...register(`dbAccounts[${index}].value`, {
                                required: 'Enter a database account name.',
                              }),
                            }}
                            placeholder="The account name must match an existing account in your database."
                            fullWidth
                          />

                          {errors?.dbAccounts?.[index] && (
                            <FormHelperText
                              sx={{
                                typography: 'body2',
                              }}
                              error
                            >
                              {errors.dbAccounts[index].value?.message}
                            </FormHelperText>
                          )}
                        </FormControl>

                        <IconButton
                          onClick={() => accountRemove(index)}
                          sx={{ alignSelf: 'flex-start' }}
                        >
                          <DeleteIcon fontSize="small" sx={{ color: 'cyralColors.grey.300' }} />
                        </IconButton>
                      </Stack>
                    )
                  })}
                </Stack>
              )}

              <Button
                variant="outlined"
                size="small"
                onClick={() => accountAppend({ value: '' })}
                sx={{ alignSelf: 'flex-start' }}
              >
                {accountFields.length > 0 ? 'Add Account' : 'Specify Accounts'}
              </Button>
            </Stack>

            {(watchClientIPs.length === 0 || watchDBAccounts.length === 0) && (
              <Alert
                severity="info"
                sx={{
                  color: 'cyralColors.yellow.500',
                  backgroundColor: 'cyralColors.yellow.100',

                  '& .MuiAlert-icon': {
                    color: 'inherit',
                  },
                }}
              >
                {watchClientIPs.length === 0 && watchDBAccounts.length === 0 ? (
                  <Typography variant="body2" sx={{ color: 'cyralColors.yellow.500' }}>
                    This rule will allow access to this repository from{' '}
                    <Box component="strong">any client IP</Box> for{' '}
                    <Box component="strong">any database account</Box>. Please note this will
                    effectively override all other network shield rules.
                  </Typography>
                ) : watchClientIPs.length === 0 && watchDBAccounts.length > 0 ? (
                  <Typography variant="body2" sx={{ color: 'cyralColors.yellow.500' }}>
                    This rule will allow access to this repository from{' '}
                    <Box component="strong">any client IP</Box> for{' '}
                    <Box component="strong">the database accounts specified above</Box>.
                  </Typography>
                ) : watchClientIPs.length > 0 && watchDBAccounts.length === 0 ? (
                  <Typography variant="body2" sx={{ color: 'cyralColors.yellow.500' }}>
                    This rule will allow access to this repository from{' '}
                    <Box component="strong">the client IPs specified above</Box> for{' '}
                    <Box component="strong">any database account</Box>.
                  </Typography>
                ) : (
                  <></>
                )}
              </Alert>
            )}
          </Stack>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <Stack direction="row">
            <IconButton
              component={Link}
              href={getDocsURL({ docsPath: 'manage-repositories/network-shield' })}
              target="_blank"
              rel="noopener noreferrer"
            >
              <HelpOutlineIcon fontSize="small" sx={{ color: 'cyralColors.grey.300' }} />
            </IconButton>
          </Stack>
          <Stack direction="row" spacing={0.5}>
            <Button
              variant="text"
              onClick={() => {
                reset()
                closeModal()
              }}
            >
              Cancel
            </Button>
            <Button type="submit" loading={isSubmitting}>
              {isCreating ? 'Add Rule' : 'Save'}
            </Button>
          </Stack>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default Upsert
