import React from 'react'
import { TextField } from '@material-ui/core'
import FormTitle from '../FormTitle'

const GroupName = ({ name }) => (
  <FormTitle title="Role Name" subtitle="Cannot be changed">
    <TextField variant="outlined" value={name} readOnly disabled />
  </FormTitle>
)

export default GroupName
