import { Link, useParams } from 'react-router-dom'

import { Box, Typography, Link as MuiLink } from '@mui/material'

const EmptyState = () => {
  const { id } = useParams<{ id: string }>()
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        py: 5,
      }}
    >
      <Typography variant="h6" sx={{ color: 'text.secondary' }}>
        To deploy a sidecar,{' '}
        <MuiLink component={Link} to={`/sidecars/${id}/deployment`} underline="none">
          visit the Deployment tab.
        </MuiLink>
        <Typography variant="h6" sx={{ color: 'text.secondary' }}>
          Sidecar instances may take a few minutes to appear.
        </Typography>
      </Typography>
    </Box>
  )
}

export default EmptyState
