import * as React from 'react'
import { debounce } from 'lodash'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Alert from '@mui/material/Alert'
import Typography from '@mui/material/Typography'

import { Button, SearchInput } from '@jeeves/new-components'

import GrantedApprovalsTable from './GrantedApprovalsTable/GrantedApprovalsTable'

const GrantedApprovals = ({
  grantedApprovalsConnection,
  fetchMore,
  setSearchValue,
  loadingNextPage,
  grantedApprovalsExist,
  refetching,
}) => {
  const nodes = React.useMemo(
    () => grantedApprovalsConnection?.edges?.map(edge => edge.node) ?? [],
    [grantedApprovalsConnection]
  )

  const pageInfo = grantedApprovalsConnection?.pageInfo

  const debouncedSearch = React.useMemo(() => {
    return debounce(value => setSearchValue(value), 300)
  }, [setSearchValue])

  return (
    <Stack spacing={2}>
      {grantedApprovalsExist && (
        <Stack spacing={2}>
          <Typography variant="h4" sx={{ color: 'text.secondary' }}>
            Granted Approvals
          </Typography>

          <Stack spacing={1} direction="row">
            <SearchInput onChange={e => debouncedSearch(e.target.value)} />

            {/* <Button variant="outlined" color="secondary">
              Filter
            </Button> */}
          </Stack>
        </Stack>
      )}

      <Stack spacing={5}>
        <GrantedApprovalsTable
          grantedApprovals={nodes}
          grantedApprovalsExist={grantedApprovalsExist}
          loadingNextPage={loadingNextPage}
          refetching={refetching}
          loadNextPage={() =>
            fetchMore({
              variables: {
                after: pageInfo.endCursor,
              },
            })
          }
          hasNextPage={pageInfo?.hasNextPage}
        />
      </Stack>
    </Stack>
  )
}

export default GrantedApprovals
