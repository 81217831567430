import { FragmentType, graphql, useFragment } from '@jeeves/graphql'

import { MongoDBAuthentication, DefaultAuthentication } from './components'

const Authentication_QueryFragment = graphql(`
  fragment Authentication_Query on Query {
    ...MongoDBAuthentication_QueryFragment
    repo(id: $repoId) {
      id
      config {
        authentication {
          allowNativeAuthentication
          ... on StandardRepoAuthenticationConfig {
            ...DefaultAuthentication_StandardRepoAuthenticationConfigFragment
          }
          ... on MongoDBRepoAuthenticationConfig {
            ...MongoDBAuthentication_MongoDBRepoAuthenticationConfigFragment
          }
        }
      }
    }
  }
`)

interface Props {
  query: FragmentType<typeof Authentication_QueryFragment>
}

const Authentication = ({ query: queryProp }: Props) => {
  const query = useFragment(Authentication_QueryFragment, queryProp)

  const isMongoDBRepoType =
    query.repo.config.authentication.__typename === 'MongoDBRepoAuthenticationConfig'

  if (isMongoDBRepoType) {
    return (
      <MongoDBAuthentication
        mongoDBRepoAuthenticationConfig={query.repo.config.authentication}
        query={query}
      />
    )
  }

  return (
    // @ts-ignore
    <DefaultAuthentication standardRepoAuthenticationConfig={query.repo.config.authentication} />
  )
}

export default Authentication
