import { FormProvider } from 'react-hook-form'
import { Box, Stack, Typography } from '@mui/material'

import { graphql, useFragment } from '@jeeves/graphql'
import { Button } from '@jeeves/new-components'

import { useOperationalMode } from './useOperationalMode'

import { CertificateManagement, PassthroughMode } from './components'

const OperationalMode_QueryFragment = graphql(`
  fragment OperationalMode_QueryFragment on Query {
    ...useOperationalMode_QueryFragment
  }
`)

const OperationalMode = ({ query: queryProp, sidecar }) => {
  const query = useFragment(OperationalMode_QueryFragment, queryProp)

  const { loading, methods, onSubmit } = useOperationalMode({
    query,
  })

  const {
    formState: { isDirty },
    handleSubmit,
    reset,
  } = methods

  const isDisabled = loading || !isDirty

  const hasCustomCertificateBundleSecrets = Boolean(sidecar?.certificateBundleSecrets?.sidecar)

  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          border: '1px solid',
          borderColor: 'cyralColors.grey.200',
          p: 4,
          borderRadius: theme => theme.radii.base,
        }}
      >
        <Stack spacing={6}>
          <Typography variant="h3" sx={{ color: 'text.primary' }}>
            Operational Mode
          </Typography>

          <Stack spacing={4}>
            <PassthroughMode />

            {hasCustomCertificateBundleSecrets && (
              <CertificateManagement
                customCertificateBundleSecret={sidecar.certificateBundleSecrets.sidecar}
              />
            )}
          </Stack>

          <Stack direction="row" sx={{ justifyContent: 'flex-end' }} spacing={2}>
            <Button variant="text" disabled={isDisabled} onClick={() => reset()}>
              Cancel
            </Button>
            <Button type="submit" disabled={isDisabled} loading={loading}>
              Save
            </Button>
          </Stack>
        </Stack>
      </Box>
    </FormProvider>
  )
}

export default OperationalMode
