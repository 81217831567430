/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Formik, Form } from 'formik'
import { withStyles } from '@material-ui/core/styles'
import { useMutation } from '@apollo/client'
import {
  Typography,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
} from '@material-ui/core'

import { Button, Dialog, FlexGrow } from '@jeeves/components/Primitives'
import { ButtonLoading } from '@jeeves/components/Loading'
import { useAuth } from '@jeeves/components/Auth'
import { TextField } from '@jeeves/pages/Account/Users'
import { isCyralEngineeringUser, isLocalUser } from '@jeeves/utils/user'
import { getValidRoles } from '@jeeves/utils/helpers'
import Permissions from '../Permissions'
import { graphql } from '@jeeves/graphql'
import { getGraphQLErrorMessage } from '@jeeves/utils/helpers'
import { useToast } from '@jeeves/new-components'

const UPDATE_CONTROL_PLANE_USER = graphql(`
  mutation UpdateControlPlaneUser(
    $updateControlPlaneUserId: ID!
    $user: UpdateControlPlaneUserInput!
  ) {
    updateControlPlaneUser(id: $updateControlPlaneUserId, user: $user) {
      user {
        id
        email
        familyName
        givenName
        name
        role {
          id
        }
      }
    }
  }
`)

const styles = theme => ({
  title: {
    padding: theme.spacing.unit * 3,
  },
  content: {
    marginBottom: theme.spacing.unit * 4,
  },
})

const getRoles = (user, roles) => {
  const invalidRoles = [
    '_Everyone',
    ...(!isCyralEngineeringUser(user.identityProviders) ? ['Cyral Admin'] : []),
  ]
  const validRoles = getValidRoles(roles, invalidRoles)

  return validRoles
}

const getRoleIdInitialValue = (user, allRoles) => {
  const everyoneRole = allRoles.find(role => role.name === '_Everyone')
  const userIsAssignedToEveryoneRole = everyoneRole?.id === user.role?.id

  if (userIsAssignedToEveryoneRole) {
    const userRole = allRoles.find(role => role.name === 'User')
    return userRole?.id || ''
  }

  return user.role?.id || ''
}

const EditUser = ({ classes, user, onClose, dialogOpen, roles: allRoles, deleteUser }) => {
  const { hasPermission } = useAuth()
  const { toast } = useToast()

  const [updateControlPlaneUser, { loading }] = useMutation(UPDATE_CONTROL_PLANE_USER, {
    onError: error => {
      toast({
        variant: 'error',
        description:
          getGraphQLErrorMessage(error) ||
          'An error occurred while updating this user, please try again.',
      })
    },
    onCompleted: () => {
      toast({
        variant: 'success',
        description: 'User successfully updated.',
      })
      onClose()
    },
  })

  if (!user) return null

  const isSsoUser = () => {
    return !isLocalUser(user.identityProviders)
  }

  const roles = getRoles(user, allRoles)

  const handleOnEditUser = async values => {
    const { id, roleId, email, last_name, first_name } = values

    await updateControlPlaneUser({
      variables: {
        updateControlPlaneUserId: id,
        user: {
          email,
          familyName: last_name,
          givenName: first_name,
          roleId,
        },
      },
    })
  }

  return (
    <Dialog open={dialogOpen} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title" className={classes.title}>
        Update
        <Typography variant="caption">
          {!isSsoUser()
            ? `Update information about user with email ${user.email}`
            : `${user.email} is an SSO user. Their information can only be updated through the SSO provider. `}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Formik
          initialValues={{
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
            roleId: getRoleIdInitialValue(user, allRoles),
            id: user.id,
          }}
          enableReinitialize={true}
          onSubmit={async (values, actions) => {
            actions.setSubmitting(true)
            await handleOnEditUser(values)
            actions.setSubmitting(false)
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            getFieldProps,
            isValid,
          }) => (
            <Form>
              <TextField
                label="First Name"
                disabled={isSsoUser()}
                {...getFieldProps('first_name')}
                onChange={e => setFieldValue('first_name', e.target.value.trim())}
              />
              <TextField
                label="Last Name"
                disabled={isSsoUser()}
                {...getFieldProps('last_name')}
                onChange={e => setFieldValue('last_name', e.target.value.trim())}
              />

              <TextField
                label="Email"
                {...getFieldProps('email')}
                disabled={isSsoUser()}
                onChange={e => setFieldValue('email', e.target.value.trim())}
              />

              {roles && (
                <FormControl margin="dense" fullWidth css={{ margin: '16px 0' }}>
                  <Permissions
                    roleId={values.roleId}
                    name="roleId"
                    roles={roles}
                    onChange={handleChange}
                  />
                </FormControl>
              )}

              <DialogActions
                css={() => css`
                  display: flex;
                  justify-content: space-between;
                `}
              >
                <Button
                  onClick={deleteUser}
                  color="secondary"
                  disabled={!hasPermission('users:delete')}
                >
                  Delete User
                </Button>

                <FlexGrow />

                <Button onClick={onClose} color="primary" css={{ marginRight: '8px' }}>
                  Cancel
                </Button>

                <div css={{ position: 'relative' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={isSubmitting || !isValid}
                  >
                    Save Changes
                  </Button>
                  {isSubmitting && <ButtonLoading />}
                </div>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}

export default withStyles(styles)(EditUser)
