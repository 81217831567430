import React from 'react'
import { Box, Stack, Typography, Alert } from '@mui/material'

import { InlineCode, LearnMore } from '@jeeves/new-components'

const SidecarCertMessage = ({ customCertificateBundleSecret }) => {
  const certBundleSecretType = customCertificateBundleSecret.type
  const customCertSecretId = customCertificateBundleSecret.secretId
  const storedInText =
    certBundleSecretType === 'aws'
      ? `stored in AWS
Secrets Manager with the ARN`
      : `stored as a Kubernetes Secret with Secret ID`

  return (
    <Typography variant="body2" sx={{ color: 'primary.main' }}>
      This sidecar is currently using the certificate your organization provided, {storedInText}{' '}
      <InlineCode component="code">{customCertSecretId}</InlineCode>. Please refer to our
      documentation to learn how to configure the sidecar to use another CA certificate or a
      self-signed certificate. <LearnMore docsPath={'sidecars/sidecar-certificates'} />
    </Typography>
  )
}

const CertificateManagement = ({ customCertificateBundleSecret }) => {
  return (
    <Stack spacing={2}>
      <Box>
        <Box sx={{ ml: 6 }}>
          <Stack spacing={2}>
            <Typography variant="h4" sx={{ color: 'text.primary' }}>
              Use a CA-signed certificate for TLS connections.
            </Typography>
            <Alert
              severity="info"
              sx={{
                color: 'primary.main',
                backgroundColor: 'cyralColors.primary.100',

                '& .MuiAlert-icon': {
                  color: 'inherit',
                },
              }}
            >
              <SidecarCertMessage customCertificateBundleSecret={customCertificateBundleSecret} />
            </Alert>
          </Stack>
        </Box>
      </Box>
    </Stack>
  )
}

export default CertificateManagement
