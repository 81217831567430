import { useState } from 'react'
import { Box, Container, Stack, Typography } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { useQuery } from '@apollo/client'
import { graphql } from '@jeeves/graphql'
import { SidecarsHeader, SidecarsTable, SidecarsEmptyState, CreateSidecarModal } from './components'

const SIDECARS_QUERY = graphql(`
  query Sidecars($firstBindings: Int) {
    sidecars {
      id
    }
    ...SidecarsTable_QueryFragment
  }
`)

const Sidecars = () => {
  const [searchValue, setSearchValue] = useState('')
  const [pageIndex, setPageIndex] = useState(0)
  const [createSidecarModalOpen, setCreateSidecarModalOpen] = useState(false)

  const { data, loading, error } = useQuery(SIDECARS_QUERY, {
    variables: {
      firstBindings: 1,
    },
  })

  const onSearch = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSearchValue(e.target.value.trim())
    setPageIndex(0)
  }

  const sidecars = data?.sidecars ?? []
  const hasSidecars = sidecars.length > 0

  const LoadingState = () => {
    return (
      <Box sx={{ p: 4 }}>
        <Stack spacing={2} sx={{ alignItems: 'center' }}>
          <CircularProgress size={64} />
          <Typography variant="h6" sx={{ color: 'text.secondary' }}>
            Loading Sidecars...
          </Typography>
        </Stack>
      </Box>
    )
  }

  const ErrorState = () => {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
        <Typography variant="h6" sx={{ color: 'text.secondary' }}>
          Sorry, an error occurred while fetching sidecars
        </Typography>
      </Box>
    )
  }

  return (
    <>
      <Container maxWidth="xl">
        <Stack spacing={4}>
          <Typography variant="h2" sx={{ color: 'text.primary' }}>
            Sidecars
          </Typography>
          {loading ? (
            <LoadingState />
          ) : error ? (
            <ErrorState />
          ) : hasSidecars ? (
            <>
              <SidecarsHeader
                onSearch={onSearch}
                setCreateSidecarModalOpen={setCreateSidecarModalOpen}
              />
              <SidecarsTable
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
                searchValue={searchValue}
                query={data!}
              />
            </>
          ) : (
            <SidecarsEmptyState setCreateSidecarModalOpen={setCreateSidecarModalOpen} />
          )}
        </Stack>
      </Container>
      <CreateSidecarModal
        open={createSidecarModalOpen}
        closeModal={() => setCreateSidecarModalOpen(false)}
      />
    </>
  )
}

export default Sidecars
