import React from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import SettingsIcon from '@mui/icons-material/Settings'
import IconButton from '@mui/material/IconButton'

import Tag from '@jeeves/pages/DataLabels/components/Tag'
import { EditLabel } from '@jeeves/pages/DataLabels/components/EditLabel'

export const DataLabelHeader = ({ dataLabel }) => {
  const [editLabelOpen, setEditLabelOpen] = React.useState(false)

  const handleOpenSettings = () => {
    setEditLabelOpen(true)
  }

  return (
    <>
      <Box sx={{ pl: 4, pr: 2.2, py: 2, border: 1, borderColor: 'cyralColors.grey.200' }}>
        <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
          <Stack direction="row" spacing={2} sx={{ mb: 1 }}>
            <Stack direction="row">
              <Typography variant="h3">{dataLabel.name}</Typography>
            </Stack>
            <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
              {dataLabel.tags.map(tag => (
                <Tag key={tag} text={tag} />
              ))}
            </Stack>
          </Stack>
          <IconButton aria-label="settings" id="basic-button" onClick={handleOpenSettings}>
            <SettingsIcon />
          </IconButton>
        </Stack>

        <Stack sx={{ mt: 2 }} direction="row" sx={{ justifyContent: 'space-between' }}>
          <Typography>{dataLabel.description}</Typography>
        </Stack>
      </Box>
      <EditLabel
        dataLabel={dataLabel}
        editLabelOpen={editLabelOpen}
        setEditLabelOpen={setEditLabelOpen}
      />
    </>
  )
}
