import { useContext } from 'react'

import { UsersContext } from '../contexts/UsersContext'
import { useAuth } from '../../../../components/Auth'
import usePopup from '../../../../components/PopupMessage/hooks/usePopup'
import ExpressClient from '../../../../clients/express'
import { get as lodashGet } from 'lodash'
import { useAppConfig } from '@jeeves/hooks'

const useUsers = () => {
  const [state, setState] = useContext(UsersContext)
  const { roles } = state.roles
  const { getTokenSilently, user } = useAuth()
  const { setPopup, popupTypes } = usePopup()
  const ec = new ExpressClient(getTokenSilently)
  const { license } = useAppConfig()

  const refreshRoles = async () => {
    try {
      const roles = await ec.get('/users/roles').then(res => {
        let defaultRoles = [
          'View Sidecars and Repositories',
          'Modify Sidecars and Repositories',
          'Modify Policies',
          'Modify Integrations',
          'Modify Users',
          'Modify Roles',
        ]
        let roles = defaultRoles.reduce(
          (prevList, curRole) =>
            res.data.roles.find(role => role.description === curRole)
              ? [...prevList, res.data.roles.find(role => role.description === curRole)]
              : prevList,
          []
        )
        return [
          ...roles,
          ...res.data.roles.filter(
            role => !defaultRoles.find(description => description === role.description)
          ),
        ].filter(role => (license.isFreeTrial ? role.description !== 'Modify Policies' : true))
      })
      setState(prevState => ({ ...prevState, roles }))
      return roles
    } catch {
      setState(prevState => ({ ...prevState, roles: [] }))
      return []
    }
  }

  const refreshGroups = async () => {
    try {
      const groups = await ec.get('/users/groups').then(res => {
        const defaultGroups = ['User', 'Admin', 'Super Admin']
        let groups = defaultGroups.reduce(
          (prevList, curGroup) =>
            res.data.groups.find(group => group.description === curGroup)
              ? [...prevList, res.data.groups.find(group => group.description === curGroup)]
              : prevList,
          []
        )
        return [
          ...groups,
          ...res.data.groups.filter(
            group => !defaultGroups.find(description => description === group.description)
          ),
        ]
      })
      setState(prevState => ({ ...prevState, groups }))
      return groups
    } catch {
      setState(prevState => ({ ...prevState, groups: [] }))
      return []
    }
  }

  const refreshConnectionNames = () => {
    return ec
      .get('/users/connections/names')
      .then(res => res.data)
      .then(names => setState(state => ({ ...state, connectionNames: names })))
  }

  const getUserGroup = async groupId => {
    try {
      const userGroup = await ec.get(`/users/groups/${groupId}`).then(res => res.data)
      return userGroup
    } catch (e) {
      const popupMessage = lodashGet(e, 'response.data.error.message', '')
      setPopup(popupTypes.ERROR, popupMessage)
      throw e
    }
  }

  const patchUserGroupRoles = async (groupId, roleIdList) => {
    try {
      const userGroups = await ec
        .patch(`/users/groups/${groupId}/roles`, roleIdList)
        .then(res => res.data)
      return userGroups
    } catch (e) {
      const popupMessage = lodashGet(e, 'response.data.error.message', '')
      setPopup(popupTypes.ERROR, popupMessage)
      throw e
    }
  }

  const getConnectionNames = () => {
    return ec
      .get('/users/connections/names')
      .then(res => res.data)
      .catch(e => {
        console.error('useAccounts.getRoles().error:', JSON.stringify(e))
      })
  }

  const patchUserGroupMappings = (groupId, mappingsName, mappingsConnection) => {
    const mappingObj = [
      {
        groupName: mappingsName,
        connectionName: mappingsConnection,
      },
    ]
    return ec.patch(`/users/groups/${groupId}/mappings`, mappingObj).then(res => res.data)
  }

  const putUserGroupRoles = async (groupId, rolesIdList) => {
    const resp = await ec.put(`/users/groups/${groupId}/roles`, rolesIdList).then(res => res.data)
    return resp
  }

  const postUserGroup = async (groupName, permissions, mappings) => {
    const groupObj = {
      name: groupName,
      description: groupName,
      roles: permissions,
      mappings: mappings,
    }
    try {
      const resp = await ec.post(`/users/groups`, groupObj).then(res => res.data)
      return resp
    } catch (e) {
      console.log('useUsers: ', e.response)
      const popupMessage = lodashGet(e, 'response.data.error.message', '')
      setPopup(popupTypes.ERROR, popupMessage)
      throw e
    }
  }

  const deleteUserGroup = async groupId => {
    const resp = await ec.delete(`/users/groups/${groupId}`).then(res => res.data)
    return resp
  }

  const deleteUserGroupMappings = (groupId, mappingId) => {
    const mappingArr = { data: [mappingId] }
    return ec.delete(`/users/groups/${groupId}/mappings`, mappingArr).then(res => res.data)
  }

  return {
    refreshGroups,
    refreshRoles,
    refreshConnectionNames,
    getConnectionNames,
    getUserGroup,
    patchUserGroupRoles,
    putUserGroupRoles,
    patchUserGroupMappings,
    deleteUserGroupMappings,
    postUserGroup,
    deleteUserGroup,
    roles,
    user,
    lodashGet,
  }
}

export default useUsers
