import React, { createContext, useContext } from 'react'

const ConnectionInfoContext = createContext(undefined)

export const ConnectionInfoProvider = ({ children, connectionInfo }) => {
  return (
    <ConnectionInfoContext.Provider value={connectionInfo}>
      {children}
    </ConnectionInfoContext.Provider>
  )
}

export const useConnectionInfo = () => {
  const context = useContext(ConnectionInfoContext)
  if (context === undefined) {
    throw new Error('useConnectionInfo must be used within a ConnectionInfoProvider')
  }

  return context
}
