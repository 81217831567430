import { useFieldArray, useFormContext } from 'react-hook-form'
import { Box, Stack, FormControl, Typography, FormHelperText } from '@mui/material'
import { Delete as DeleteIcon } from '@mui/icons-material'
import { Button, IconButton, Input, InputLabel, LearnMore } from '@jeeves/new-components'
import { NumNodes } from './shared'
import { useEffect } from 'react'

const StaticNodesFieldsGrid = ({ children }) => (
  <Box
    sx={{
      display: 'grid',
      gap: 1,
      gridTemplateColumns: '5fr 2fr 0.5fr',
    }}
  >
    {children}
  </Box>
)

const StaticNodesFields = () => {
  const { register, control, watch, formState } = useFormContext()

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'nodes',
  })

  const { numNodes = 1, nodes } = watch()

  const nodesError = formState.errors?.nodes

  const isAppendAvailable = nodes?.length < Number(numNodes)
  const isRemoveDisabled = nodes?.length <= 1

  useEffect(() => {
    if (nodes && nodes.length === 0) {
      append([{ host: '', port: '' }])
    }
  }, [append, nodes])

  return (
    <>
      <Stack spacing={1}>
        <NumNodes />
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          Specify at least one host below. The sidecar will discover hosts for any of the remaining
          undesignated nodes. <LearnMore docsPath="" />
        </Typography>
      </Stack>

      <Stack spacing={1}>
        <StaticNodesFieldsGrid>
          <InputLabel required>Hostname</InputLabel>
          <InputLabel required>Port</InputLabel>
        </StaticNodesFieldsGrid>
        {fields.map((field, index) => {
          const hostError = nodesError?.[index]?.host
          const portError = nodesError?.[index]?.port

          return (
            <StaticNodesFieldsGrid key={field.id}>
              <FormControl variant="standard" error={Boolean(hostError)}>
                <Input inputProps={{ ...register(`nodes.${index}.host`) }} />
                <FormHelperText
                  sx={{
                    typography: 'body2',
                  }}
                  error={Boolean(hostError)}
                >
                  {hostError?.message}
                </FormHelperText>
              </FormControl>
              <FormControl variant="standard" error={Boolean(portError)}>
                <Input
                  inputProps={{
                    ...register(`nodes.${index}.port`, {
                      setValueAs: value => (value === '' ? undefined : Number(value)),
                    }),
                  }}
                />
                <FormHelperText
                  sx={{
                    typography: 'body2',
                  }}
                  error={Boolean(portError)}
                >
                  {portError?.message}
                </FormHelperText>
              </FormControl>
              <Box>
                <IconButton
                  variant="outlined"
                  onClick={() => remove(index)}
                  disabled={isRemoveDisabled}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </StaticNodesFieldsGrid>
          )
        })}
        {isAppendAvailable && (
          <Box>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => append({ host: '', port: '' })}
            >
              Add
            </Button>
          </Box>
        )}
      </Stack>
    </>
  )
}

export default StaticNodesFields
