import { Box, Typography } from '@mui/material'

import { FragmentType, graphql, useFragment } from '@jeeves/graphql'

import UpgradeAllInstancesModal from '../UpgradeAllInstancesModal'

const InstancesHeader_SidecarFragment = graphql(`
  fragment InstancesHeader_SidecarFragment on Sidecar {
    id
    instances {
      id
    }
    ...UpgradeAllInstancesModal_SidecarFragment
  }
`)

interface Props {
  sidecar: FragmentType<typeof InstancesHeader_SidecarFragment>
}

const InstancesHeader = ({ sidecar: sidecarProp }: Props) => {
  const sidecar = useFragment(InstancesHeader_SidecarFragment, sidecarProp)

  const instancesAmount = sidecar.instances.length

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography variant="h4" sx={{ color: 'text.primary' }}>
        Instances {!!instancesAmount && `(${instancesAmount})`}
      </Typography>
      {!!instancesAmount && <UpgradeAllInstancesModal sidecar={sidecar} />}
    </Box>
  )
}

export default InstancesHeader
