/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import styled from '@emotion/styled'
import { grey } from '@material-ui/core/colors'

import {
  Title,
  Subtitle,
  Tooltip,
  NextButton,
  CircleProgress,
  SkipLink,
} from '@jeeves/pages/Setup/components'

const ContentWrapper = styled.div`
  padding: 32px;
  position: relative;
  font-size: 24px;
`

const LayoutWithNotes = styled.div`
  width: 100%;
  padding: 24px 24px 0 24px;
`

const Content = styled.div`
  display: flex;
  align-items: baseline;
  align-content: baseline;
  flex-direction: column-reverse;

  ${({ theme }) => css`
    ${theme.breakpointsMedia.md} {
      width: 80%;
      margin: 0 auto;
      flex-direction: row;
      justify-content: space-between;
    }
  `}
`

const MainContent = styled.div`
  width: 100%;

  ${({ theme }) => css`
    ${theme.breakpointsMedia.md} {
      width: 60%;
      margin: 0 auto;
      padding: 0 24px;
    }
  `}
`

const Notes = styled.div`
  width: 100%;
  // color: ${grey[800]};
  background-color: white;
  border-radius: 12px;
  padding-right: 12px;

  ${({ theme }) => css`
    ${theme.breakpointsMedia.md} {
      width: 40%;
      margin: 0 auto;
      padding-right: 12px;
      color: inherit;
    }
  `}
`

export const SandboxLayout = ({
  children,
  onNext,
  subtitle,
  title,
  tooltip,
  notesComponent,
  idp,
}) => (
  <LayoutWithNotes>
    {title && <Title>{title}</Title>}
    {subtitle && (
      <Subtitle>
        {subtitle}
        {tooltip && <Tooltip title={tooltip} />}
      </Subtitle>
    )}
    <div css={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Content>
        <MainContent>
          <ContentWrapper>{children}</ContentWrapper>
        </MainContent>
        <Notes>{notesComponent}</Notes>
      </Content>
      <div
        css={{
          marginBottom: '24px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <NextButton variant="contained" onClick={onNext} />
        <SkipLink onNext={onNext} text="Skip this"></SkipLink>
      </div>
    </div>
  </LayoutWithNotes>
)
