/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'

const CATEGORY_COLORS = {
  authentication: '#f89c6a',
  logging: '#5fb38d',
  alerting: '#687ab8',
  metrics: '#b67abf',
  cloud: '#e890d4',
  cicd: '#293e7b',
  chatops: '#33cccc',
  ['data catalog']: '#ffcc00',
  secrets: '#ff6666',
  'incident response': '#fc46aa',
  mfa: '#f5b5ef',
}

const CardCategory = ({ category = '' }) => {
  const bgColor = category ? CATEGORY_COLORS[category.toLowerCase()] : '#B0BEC5'

  return (
    <div
      css={() => css`
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 7px;
        background-color: ${bgColor};
      `}
    />
  )
}

export default CardCategory
