import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const DuoIcon = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 1332.52 429.933">
      <path
        d="M214.967 429.935H-.001V225.716h429.666c-5.601 113.73-99.581 204.219-214.698 204.219"
        fill="#7bcd54"
      />
      <path
        d="M214.967.001H-.001V204.22h429.666C424.064 90.49 330.084 0 214.967 0M1117.824.001c-115.117 0-209.097 90.49-214.697 204.218h429.397C1326.923 90.49 1232.943 0 1117.824 0"
        fill="#63c43f"
      />
      <path
        d="M1117.824 429.935c-115.117 0-209.097-90.49-214.697-204.219h429.397c-5.601 113.73-99.581 204.219-214.7 204.219"
        fill="#7bcd54"
      />
      <path
        d="M451.43.001v214.967c0 115.117 90.489 209.097 204.218 214.699V0H451.429"
        fill="#63c43f"
      />
      <path d="M881.363 429.935H677.144V0h204.219v429.934" fill="#7bcd54" />
    </SvgIcon>
  )
}

export default DuoIcon
