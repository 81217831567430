/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Typography } from '@material-ui/core'
import { Fragment } from 'react'

import { SubRow } from '.'
import { ServiceIcon } from '@jeeves/components/ServiceIcon'
import { EllipsisTypography } from '@jeeves/components/Primitives'

export const RepositoryList = ({ repositories = [] }) => {
  return (
    <ul
      css={() => css`
        list-style-type: none;
        margin: 0;
        padding: 0;
      `}
    >
      {repositories.map(repository => (
        <Fragment>
          <SubRow>
            <div
              css={() => css`
                width: 32px;
                margin-right: 12px;
              `}
            >
              <ServiceIcon type={repository.type} css={{ minWidth: '25px' }} />
            </div>
            <EllipsisTypography width="120px">{repository.name}</EllipsisTypography>
          </SubRow>
          {repository.identityMapsCount > 1 &&
            Array.from(Array(repository.identityMapsCount - 1)).map(() => <SubRow>&nbsp;</SubRow>)}
        </Fragment>
      ))}
    </ul>
  )
}
