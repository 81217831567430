/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
} from '@material-ui/core/'
import { ExpandMore } from '@material-ui/icons'

const IntegrationPanel = ({ title, expanded, children, onChange }) => (
  <ExpansionPanel
    expanded={expanded}
    onChange={onChange}
    css={css`
      width: 100%;
    `}
  >
    <ExpansionPanelSummary expandIcon={<ExpandMore />}>
      <Typography>{title}</Typography>
    </ExpansionPanelSummary>
    <ExpansionPanelDetails>{children}</ExpansionPanelDetails>
  </ExpansionPanel>
)

export default IntegrationPanel
