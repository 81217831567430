import { TableFooter, TableRow } from '@mui/material'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'

import { FragmentType, graphql, useFragment } from '@jeeves/graphql'
import { TablePagination } from '@jeeves/new-components'
import { useUsers } from '@jeeves/pages/Account/Users/context'

import PaginationActions from './PaginationActions'

const UsersTableFooter_QueryFragment = graphql(`
  fragment UsersTableFooter_QueryFragment on Query {
    controlPlaneUsers {
      id
    }
  }
`)

interface Props {
  query: FragmentType<typeof UsersTableFooter_QueryFragment>
}

const UsersTableFooter = ({ query }: Props) => {
  const { controlPlaneUsers } = useFragment(UsersTableFooter_QueryFragment, query)

  const { pageIndex, pageSize, rowsPerPageOptions, onRowsPerPageChange, onChangePage } = useUsers()

  const numberOfUsers = controlPlaneUsers.length
  const totalPages = Math.ceil(numberOfUsers / pageSize)

  return (
    <TableFooter>
      <TableRow sx={{ '& td': { borderBottom: 'none !important' } }}>
        <TablePagination
          SelectProps={{
            IconComponent: KeyboardArrowDown,
          }}
          labelRowsPerPage="Users per page"
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} of ${count} users`}
          onPageChange={(_, newPage) => onChangePage(newPage)}
          rowsPerPageOptions={rowsPerPageOptions}
          ActionsComponent={props => <PaginationActions totalPages={totalPages} {...props} />}
          page={pageIndex}
          count={numberOfUsers}
          rowsPerPage={pageSize}
          onRowsPerPageChange={event => onRowsPerPageChange(Number(event.target.value))}
        />
      </TableRow>
    </TableFooter>
  )
}

export default UsersTableFooter
