import * as React from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

import { Tag } from '@jeeves/new-components'

import { RowActionButton } from './RulesTable.styles'
import DeleteNetworkShieldRuleModal from './components/DeleteNetworkShieldRuleModal'
import Upsert from '../Upsert'

const Row = ({ rule }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [deleteRuleModalOpen, setDeleteRuleModalOpen] = React.useState(false)
  const [editRuleModalOpen, setEditRuleModalOpen] = React.useState(false)

  const open = Boolean(anchorEl)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleToggleOpenDeleteRuleModal = () => setDeleteRuleModalOpen(!deleteRuleModalOpen)

  const handleOpenDeleteRuleModal = () => {
    handleClose()
    handleToggleOpenDeleteRuleModal()
  }

  const handleToggleOpenEditRuleModal = () => setEditRuleModalOpen(!editRuleModalOpen)

  const handleOpenEditRuleModal = () => {
    handleClose()
    handleToggleOpenEditRuleModal()
  }

  return (
    <React.Fragment>
      <Box component="tr" key={rule.id}>
        <Box
          component="td"
          sx={{
            color: 'text.primary',
            typography: 'body2',
          }}
        >
          {rule.name}
        </Box>
        <Box
          component="td"
          sx={{
            color: 'text.primary',
            typography: 'body2',
            maxWidth: '300px',
          }}
        >
          {rule.description}
        </Box>
        <Box component="td">
          <Stack sx={{ alignItems: 'flex-start' }}>
            {rule.clientIPAddresses.length === 0 ? (
              <Tag color="grey">Any IP</Tag>
            ) : (
              rule.clientIPAddresses.map((ipAddress, idx) => (
                <Typography key={idx} variant="code" sx={{ color: 'text.primary' }}>
                  {ipAddress}
                </Typography>
              ))
            )}
          </Stack>
        </Box>
        <Box component="td">
          <Stack sx={{ alignItems: 'flex-start' }}>
            {rule.databaseAccounts.length === 0 ? (
              <Tag color="grey">Any Database Account</Tag>
            ) : (
              rule.databaseAccounts.map((account, idx) => (
                <Typography key={idx} variant="code" sx={{ color: 'text.primary' }}>
                  {account}
                </Typography>
              ))
            )}
          </Stack>
        </Box>
        <Box component="td">
          <RowActionButton>
            <MoreVertIcon onClick={handleClick} />
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem onClick={handleOpenEditRuleModal} disableRipple>
                Edit
              </MenuItem>
              <MenuItem onClick={handleOpenDeleteRuleModal} disableRipple>
                Delete
              </MenuItem>
            </Menu>
          </RowActionButton>
        </Box>
      </Box>
      <DeleteNetworkShieldRuleModal
        open={deleteRuleModalOpen}
        closeModal={handleToggleOpenDeleteRuleModal}
        rule={rule}
      />
      <Upsert open={editRuleModalOpen} closeModal={handleToggleOpenEditRuleModal} rule={rule} />
    </React.Fragment>
  )
}

export default Row
