import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'
import { useForm, Controller } from 'react-hook-form'

import { Input, Button, Select, Tag, InputLabel } from '@jeeves/new-components'
import { useRepoTypeSelectOptions } from '@jeeves/hooks'

export const Filter = ({ handleClose, handleFilter = () => {}, filters, hideTags = false }) => {
  const {
    reset,
    register,
    control,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm({
    // Remove comment before merging (Note for Josh)
    /* Set default values to filter state. Reopening filter popover should display 
       same filters as previously applied. Clicking "reset all filters" should 
       clear the filter state. The "Cancel" button simply closes the dialog but 
       retains filter state */
    defaultValues: {
      repoType: filters.repoType || '',
      tags: filters.repoTags,
    },
  })

  const { options } = useRepoTypeSelectOptions()

  const handleTagDelete = tag => {
    const currentTags = getValues('tags')
    const updatedTags = currentTags.filter(t => t !== tag)

    setValue('tags', updatedTags, { shouldDirty: true })
  }

  const handleAddTag = () => {
    const currentTags = getValues('tags')
    const currentTag = getValues('currentTag')

    const updatedTags = [...currentTags, currentTag]

    setValue('currentTag', '')
    setValue('tags', updatedTags, { shouldDirty: true })
  }

  const handleTagEnter = event => {
    if (event.key === 'Enter') {
      handleAddTag()
    }
  }

  const handleResetFilters = () => {
    reset({
      repoType: '',
      tags: [],
    })
  }

  // Remove comment before merging (Note for Josh)
  // Need to take values from submit inputs and set filter state at a higher level component
  const onSubmit = async ({ repoType, tags }) => {
    try {
      handleFilter({ repoType: repoType || null, repoTags: tags })
      handleClose()
    } catch (e) {
      console.error(e)
    }
  }

  const { tags = [], currentTag = '', repoType = '' } = watch()
  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={5}>
        <Stack spacing={2}>
          <FormControl variant="standard">
            <InputLabel
              id="repo-type-select-label"
              htmlFor="repo-type-select"
              sx={{
                typography: 'h6',
              }}
              shrink
              disableAnimation
            >
              Repository Type
            </InputLabel>

            <Controller
              name="repoType"
              control={control}
              render={({ field }) => (
                <Select
                  labelId="repo-type-select-label"
                  id="repo-type-select"
                  options={options.filter(option => option.value !== 'snowflake')}
                  {...field}
                />
              )}
            />
          </FormControl>

          {!hideTags && (
            <Stack spacing={1}>
              <InputLabel
                sx={{
                  typography: 'h6',
                }}
                htmlFor="tag-input"
              >
                Tags
              </InputLabel>

              <Box
                sx={{
                  border: 1,
                  borderColor: 'cyralColors.grey.200',
                  borderRadius: theme => theme.radii.base,
                  height: '100px',
                  padding: 1,
                  overflowY: 'auto',
                }}
              >
                <Stack direction="row" sx={{ flexWrap: 'wrap', gap: 1 }}>
                  {tags.map((tag, idx) => (
                    <Tag key={`${tag}_${idx}`} onRemove={() => handleTagDelete(tag)}>
                      {tag}
                    </Tag>
                  ))}
                </Stack>
              </Box>

              <Stack direction="row" spacing={2} sx={{ alignItems: 'baseline' }}>
                <FormControl variant="standard" sx={{ flex: 1 }}>
                  <Input
                    id="tag-input"
                    inputProps={{
                      ...register('currentTag'),
                    }}
                    placeholder="Add a tag..."
                    onKeyDown={handleTagEnter}
                  />
                </FormControl>

                <Button
                  disabled={isSubmitting || currentTag?.length === 0}
                  onClick={handleAddTag}
                  size="small"
                  variant="outlined"
                  color="secondary"
                >
                  Add
                </Button>
              </Stack>
            </Stack>
          )}
        </Stack>

        <Stack spacing={2}>
          <Divider orientation="horizontal" flexItem />
          <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
            <Button variant="text" color="secondary" onClick={handleResetFilters}>
              Reset all filters
            </Button>
            <Stack direction="row" sx={{ gap: 2.5 }}>
              <Button variant="text" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="contained" disabled={!repoType && !tags} type="submit">
                Filter
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  )
}
