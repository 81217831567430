import React from 'react'
import {
  Stack,
  Box,
  DialogContent,
  DialogTitle,
  FormControl,
  Typography,
  FormHelperText,
} from '@mui/material'
import { useForm } from 'react-hook-form'
import { gql, useMutation } from '@apollo/client'
import { useSidecarDetailContext } from '@jeeves/pages/WrapperDetail/contexts/SidecarDetailContext'
import {
  Dialog,
  Button,
  Input,
  InputLabel,
  DialogActions,
  ServiceIcon,
} from '@jeeves/new-components'
import { ALL_REPO_TYPES_WITH_DISPLAY_NAME } from '@jeeves/constants'

const UPDATE_CONFIG = gql`
  mutation UpsertMultiplexedListenerConfig(
    $sidecarId: ID!
    $listenerId: ID!
    $input: UpsertMultiplexListenerSettingsInput!
  ) {
    upsertMultiplexedListenerConfig(sidecarId: $sidecarId, listenerId: $listenerId, input: $input) {
      listener {
        id
        ... on MySQLFamilyMultiplexedListener {
          config {
            advertisedVersion
            characterSet
          }
        }
        ... on SQLServerMultiplexedListener {
          config {
            advertisedVersion
          }
        }
      }
    }
  }
`

const Edit = ({ smartPort, onClose, open }) => {
  const {
    reset,
    register,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      advertisedVersion: smartPort?.config?.advertisedVersion ?? '',
    },
  })
  const { sidecarId } = useSidecarDetailContext()

  const [updateConfig, { data, loading, error }] = useMutation(UPDATE_CONFIG, {})

  const handleCancel = () => {
    reset()
    onClose()
  }

  const onSubmit = async ({ advertisedVersion }) => {
    try {
      const input =
        smartPort?.repoType === 'sqlserver'
          ? {
              SQLServerConfig: {
                advertisedVersion,
              },
            }
          : {
              mysqlFamilyConfig: {
                advertisedVersion,
              },
            }

      await updateConfig({
        variables: {
          sidecarId,
          listenerId: smartPort?.id,
          input,
        },
      })
    } catch (e) {
      console.error(e)
    }
  }

  React.useEffect(() => {
    if (isSubmitSuccessful) {
      reset(getValues())
      onClose()
    }
  }, [isSubmitSuccessful, reset, getValues, onClose])

  return (
    <Dialog open={open} fullWidth onClose={onClose}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle variant="h3" sx={{ color: 'text.primary' }}>
          {smartPort?.port}
        </DialogTitle>

        <DialogContent>
          <Stack spacing={3}>
            <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
              <ServiceIcon type={smartPort?.repoType} sx={{ fontSize: '36px' }} />
              <Stack spacing={1}>
                <Typography variant="body2" sx={{ color: 'text.primary' }}>
                  {
                    ALL_REPO_TYPES_WITH_DISPLAY_NAME.find(
                      repoTypeWithDisplayName =>
                        repoTypeWithDisplayName.typeName === smartPort?.repoType,
                    )?.displayName
                  }
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary', whiteSpace: 'nowrap' }}>
                  {`${smartPort?.numBindings} ${
                    smartPort?.numBindings > 1 ? 'repositories' : 'repository'
                  }`}
                </Typography>
              </Stack>
            </Stack>
            <Typography variant="h4" sx={{ color: 'text.primary' }}>
              Smart Port Properties
            </Typography>
            <FormControl variant="standard" error={Boolean(errors?.advertisedVersion)}>
              <InputLabel htmlFor="advertisedVersion" required>
                Advertised Version
              </InputLabel>
              <Input
                id="advertisedVersion"
                placeholder="e.g. 8.0.4"
                inputProps={{
                  ...register('advertisedVersion', {
                    required: 'Please provide an advertised version number.',
                  }),
                }}
              ></Input>
              {Boolean(errors?.advertisedVersion) && (
                <FormHelperText
                  sx={{
                    typography: 'body2',
                  }}
                >
                  {errors.advertisedVersion.message}
                </FormHelperText>
              )}
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Stack direction="row" spacing={2.5} sx={{ justifyContent: 'flex-end' }}>
            <Button variant="text" onClick={handleCancel}>
              Cancel
            </Button>
            <Button loading={isSubmitting} variant="contained" type="submit">
              Save
            </Button>
          </Stack>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default Edit
