/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import React from 'react'
import Highlight, { defaultProps } from 'prism-react-renderer'
import theme from 'prism-react-renderer/themes/github'

const CodeTheme = theme

const CodeSnippet = ({ code, language, className: wrapperClassName = '' }) => {
  //   const formattedCode = useFormattedCode(code, language)

  return (
    <div
      css={{
        border: '1px solid #CBD2D9',
        borderRadius: '0.25rem',
      }}
      className={wrapperClassName}
    >
      <Highlight
        {...defaultProps}
        code={code}
        language={language}
        theme={CodeTheme}
        // {...rest}
      >
        {({ className, style, tokens, getLineProps, getTokenProps }) => (
          <pre
            className={className}
            css={theme => ({
              // fontSize: '14px',
              padding: '1rem',
              borderRadius: '0.25rem',
              margin: '0',
              overflow: 'auto',
              fontFamily: theme.typography.monospaced,
            })}
            style={style}
          >
            {tokens.map((line, i) => (
              <div {...getLineProps({ line, key: i })}>
                {line.map((token, key) => (
                  <span {...getTokenProps({ token, key })} />
                ))}
              </div>
            ))}
          </pre>
        )}
      </Highlight>
    </div>
  )
}

export default CodeSnippet
