/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import styled from '@emotion/styled'
import Integration from './Integration'

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 54px 28px;
  margin: 0 auto;

  ${({ theme }) => css`
    ${theme.breakpointsMedia.md} {
      grid-template-columns: repeat(2, 1fr);
    }
    ${theme.breakpointsMedia.lg} {
      grid-template-columns: repeat(3, 1fr);
    }
    ${theme.breakpointsMedia.xl} {
      grid-template-columns: repeat(4, 1fr);
    }
  `}
`

const IntegrationsGrid = ({ integrations, configured }) => {
  return (
    <Grid>
      {integrations.map(integration => (
        <Integration
          key={integration.metadata.slug}
          integration={integration}
          configured={configured}
        />
      ))}
    </Grid>
  )
}

export default IntegrationsGrid
