/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import useRepos from '../../pages/Repositories/hooks/useRepos'
import { Tooltip } from '@material-ui/core'

import awsLogo from './AWS.png'
import azureLogo from './azure.png'
import bigqueryLogo from './bigquery.png'
import cassandraLogo from './cassandra.png'
import dockerLogo from './Docker.png'
import dremioLogo from './dremio.png'
import dynamodbLogo from './dynamodb.png'
import dynamodbstreamsLogo from './dynamodbstreams.png'
import ecsLogo from './ECS.svg'
import galeraLogo from './galera.png'
import gcpLogo from './GCP.png'
import gkeLogo from './GKE.png'
import kubernetesLogo from './kubernetes.png'
import mariadbLogo from './mariadb.png'
import mongodbLogo from './mongodb.png'
import mysqlLogo from './mysql.png'
import postgresLogo from './postgres.svg'
import rdsLogo from './RDS.svg'
import snowflakeLogo from './snowflake.png'
import sqlserverLogo from './sqlserver.png'
import s3Logo from './s3.png'
import redshiftLogo from './redshift.png'
import oracleLogo from './oracle.png'
import pagerdutyLogo from './pagerduty.png'
import oktaLogo from './okta.png'
import forgerockLogo from './forgerock.png'
import gsuiteLogo from './gsuite.png'
import azureADLogo from './azure-ad.png'
import adfsLogo from './adfs.jpeg'
import restLogo from './rest.png'
import denodoLogo from './denodo.png'
import duoLogo from '@jeeves/pages/Integrations/Services/Duo/images/duo.png'

// TO DO: make the map automatic so that filenames are single source of truth

const LogoMap = {
  aws: awsLogo,
  azure: azureLogo,
  bigquery: bigqueryLogo,
  cassandra: cassandraLogo,
  dremio: dremioLogo,
  dynamodb: dynamodbLogo,
  dynamodbstreams: dynamodbstreamsLogo,
  docker: dockerLogo,
  ecs: ecsLogo,
  galera: galeraLogo,
  gcp: gcpLogo,
  gke: gkeLogo,
  kubernetes: kubernetesLogo,
  mariadb: mariadbLogo,
  mongodb: mongodbLogo,
  mysql: mysqlLogo,
  postgres: postgresLogo,
  postgresql: postgresLogo,
  rds: rdsLogo,
  aurorapostgres: rdsLogo,
  auroramysql: rdsLogo,
  snowflake: snowflakeLogo,
  redshift: redshiftLogo,
  sqlserver: sqlserverLogo,
  s3: s3Logo,
  oracle: oracleLogo,
  pagerduty: pagerdutyLogo,
  okta: oktaLogo,
  forgerock: forgerockLogo,
  gsuite: gsuiteLogo,
  aad: azureADLogo,
  'adfs-2016': adfsLogo,
  adfs_2016: adfsLogo,
  rest: restLogo,
  denodo: denodoLogo,
  duomfa: duoLogo,
}

export const ServiceIcon = ({ type = '', minWidth = '0px', ...props }) => {
  const { getDisplayName } = useRepos()

  const typeKey = type.toLowerCase()
  if (!(typeKey in LogoMap)) return typeKey
  const logoSrc = LogoMap[typeKey]
  const capitalizedType =
    typeKey === 'pagerduty' ? 'PagerDuty' : typeKey === 'duomfa' ? 'Duo' : getDisplayName(typeKey)

  return (
    <div
      css={css`
        min-width: ${minWidth};
        max-width: 25px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-right: 10px;

        img {
          width: 100%;
        }
      `}
      {...props}
    >
      <Tooltip title={capitalizedType}>
        <img src={logoSrc} alt={capitalizedType} />
      </Tooltip>
    </div>
  )
}
