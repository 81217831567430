/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import React, { useEffect, useState, Fragment } from 'react'
import axios from 'axios'
import { withStyles, Button, Grid, Typography } from '@material-ui/core'

import { ButtonLoading } from '@jeeves/components/Loading'
import Personal from './components/Personal'
import JWT from './components/JWT'
import Password from './components/Password'
import { Paper } from '@jeeves/components/Primitives'
import { secondaryCards, secondaryButtonText } from './constants'
import ExpressClient from '../../clients/express'

import { LOCAL_USER_IDP } from '@jeeves/utils/user'
import { useAuth } from '../../components/Auth'
import usePopup from '@jeeves/components/PopupMessage/hooks/usePopup'
import { get as lodashGet } from 'lodash'

import Dashboard from '@jeeves/components/Dashboard'
import Header from '@jeeves/components/Dashboard/Header'
import Preferences from './components/Preferences'
import useProfile from './hooks/useProfile'

const styles = theme => ({
  section: {
    marginBottom: theme.spacing.unit * 4,
  },
})

const Profile = ({ classes }) => {
  const { user, getTokenSilently, reinitialize } = useAuth()
  const isLocalUser = user.identity_provider === LOCAL_USER_IDP
  const { fetchUserConnections } = useProfile()
  const [userConnections, setUserConnections] = useState([])
  const ec = new ExpressClient(getTokenSilently)
  const { showError } = usePopup()
  const updateUser = async values => {
    try {
      const token = await getTokenSilently()

      await axios.request({
        method: 'patch',
        url: `/users/${user.sub}`,
        data: {
          first_name: values.given_name,
          last_name: values.family_name,
          email: user.email,
          id: user.sub,
          picture: values.picture,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    } catch (error) {
      console.error(error)
      showError(error)
    }
  }

  useEffect(() => {
    async function fetchUserConnectionsAsync() {
      try {
        const resp = await fetchUserConnections()
        setUserConnections(resp)
      } catch (e) {}
    }
    fetchUserConnectionsAsync()
  }, [])

  return (
    <Grid container alignItems="center" spacing={24}>
      <Grid item xs={12}>
        <Paper
          css={{ width: '70%', marginLeft: 'auto', marginRight: 'auto', marginBottom: '24px' }}
        >
          <Personal
            className={classes.section}
            user={user}
            onUpdate={updateUser}
            reinitialize={reinitialize}
          />
        </Paper>
        {/* Only local users can reset their password */}
        {isLocalUser && (
          <Paper
            css={{ width: '70%', marginLeft: 'auto', marginRight: 'auto', marginBottom: '24px' }}
          >
            <Password className={classes.section} user={user} onUpdate={updateUser} />
          </Paper>
        )}
        <Paper
          css={{ width: '70%', marginLeft: 'auto', marginRight: 'auto', marginBottom: '24px' }}
        >
          <Preferences userConnections={userConnections} />
        </Paper>
        <Paper css={{ width: '70%', marginLeft: 'auto', marginRight: 'auto' }}>
          <JWT className={classes.section} user={user} onUpdate={updateUser} />
        </Paper>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(Profile)
