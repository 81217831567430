import { Box, Stack, Typography } from '@mui/material'
import { Refresh as RefreshIcon } from '@mui/icons-material'

import { Button } from '@jeeves/new-components'

import { AuditFilter } from '.'
import { FilterData } from './AuditFilter/utils'
import ExportLogs from './ExportLogs'

interface AuditHeaderProps {
  filter: FilterData
  filterInitialState: FilterData
  handleFilter: (newFilter: FilterData) => void
  handleRefetch: () => void
  hasLogs: boolean
}

const AuditHeader = ({
  filter,
  filterInitialState,
  handleFilter,
  handleRefetch,
  hasLogs,
}: AuditHeaderProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography variant="body2" color="secondary">
        View audit logs for changes and actions performed within this data repository.
      </Typography>
      <Stack direction="row" spacing={2}>
        <Button
          variant="outlined"
          color="secondary"
          sx={{
            minWidth: '36px !important',
            padding: '0 !important',
            '&:hover .MuiSvgIcon-root': {
              color: 'primary.main',
            },
          }}
          onClick={handleRefetch}
        >
          <RefreshIcon sx={{ color: 'secondary.main' }} />
        </Button>
        <AuditFilter
          filter={filter}
          filterInitialState={filterInitialState}
          handleFilter={handleFilter}
        />
        <ExportLogs filter={filter} hasLogs={hasLogs} />
      </Stack>
    </Box>
  )
}

export default AuditHeader
