import React, { useState } from 'react'
// import { getPolicies } from '../pages/Policies/utils/policiesApi'

const UsersContext = React.createContext([{}, () => {}])

const UsersProvider = props => {
  const [state, setState] = useState({
    roles: [],
    groups: [],
    connectionNames: [],
  })

  return <UsersContext.Provider value={[state, setState]}>{props.children}</UsersContext.Provider>
}

export { UsersContext, UsersProvider }
