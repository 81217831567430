export const REPO_TYPES_META = Object.fromEntries(
  Object.entries({
    mysql: {
      displayName: 'MySQL',
      port: { default: 3306 },
      watches: {
        columns: [
          {
            id: 'schema',
            numeric: false,
            disablePadding: false,
            label: 'Schema',
            defaultValue: '',
          },
          { id: 'table', numeric: false, disablePadding: false, label: 'Table', defaultvalue: '' },
          {
            id: 'columns',
            numeric: false,
            disablePadding: false,
            label: 'Columns',
            defaultValue: [],
          },
        ],
        defaultSorting: 'schema',
      },
    },
    snowflake: {
      displayName: 'Snowflake',
      port: { default: 443 },
      watches: {
        columns: [
          {
            id: 'schema',
            numeric: false,
            disablePadding: false,
            label: 'Schema',
            defaultValue: '',
          },
          { id: 'table', numeric: false, disablePadding: false, label: 'Table', defaultValue: '' },
          {
            id: 'columns',
            numeric: false,
            disablePadding: false,
            label: 'Columns',
            defaultValue: [],
          },
        ],
        defaultSorting: 'schema',
      },
    },
    postgresql: {
      displayName: 'PostgreSQL',
      port: { default: 5432 },
      watches: {
        columns: [
          {
            id: 'schema',
            numeric: false,
            disablePadding: false,
            label: 'Schema',
            defaultValue: '',
          },
          { id: 'table', numeric: false, disablePadding: false, label: 'Table', defaultValue: '' },
          {
            id: 'columns',
            numeric: false,
            disablePadding: false,
            label: 'Columns',
            defaultValue: [],
          },
        ],
        defaultSorting: 'schema',
      },
    },
    dremio: {
      displayName: 'Dremio',
      port: { default: 31010 },
      watches: {
        columns: [
          {
            id: 'schema',
            numeric: false,
            disablePadding: false,
            label: 'Schema',
            defaultValue: '',
          },
          { id: 'table', numeric: false, disablePadding: false, label: 'Table', defaultValue: '' },
          {
            id: 'columns',
            numeric: false,
            disablePadding: false,
            label: 'Columns',
            defaultValue: [],
          },
        ],
        defaultSorting: 'schema',
      },
    },
    dynamodb: {
      displayName: 'Amazon DynamoDB',
      port: { default: 443 },
      hostName: { default: 'dynamodb.us-east-1.amazonaws.com', locked: false },
      watches: {
        columns: [
          {
            id: 'table',
            numeric: false,
            disablePadding: false,
            label: 'Table',
            defaultValue: '',
          },          
          {
            id: 'fields',
            numeric: false,
            disablePadding: false,
            label: 'Fields',
            defaultValue: [],
          },
        ],
        defaultSorting: 'table',
      },
    },
    dynamodbstreams: {
      displayName: 'Amazon DynamoDB Streams',
      port: { default: 443 },
      hostName: { default: 'streams.dynamodb.us-east-1.amazonaws.com', locked: false },
      watches: {
        columns: [
          {
            id: 'table',
            numeric: false,
            disablePadding: false,
            label: 'Table',
            defaultValue: '',
          },          
          {
            id: 'fields',
            numeric: false,
            disablePadding: false,
            label: 'Fields',
            defaultValue: [],
          },
        ],
        defaultSorting: 'table',
      },
    },
    mongodb: {
      displayName: 'MongoDB',
      port: { default: 27017 },
      maxAllowedListeners: { default: 1 },
      watches: {
        columns: [
          {
            id: 'database',
            numeric: false,
            disablePadding: false,
            label: 'Database',
            defaultValue: '',
          },
          {
            id: 'collection',
            numeric: false,
            disablePadding: false,
            label: 'Collection',
            defaultValue: '',
          },
          {
            id: 'fields',
            numeric: false,
            disablePadding: false,
            label: 'Fields',
            defaultValue: [],
          },
        ],
        defaultSorting: 'database',
      },
    },
    // We want to remove cassandra from the repo type dropdown but still support it through APIs for developers
    // cassandra: {
    //   displayName: 'Cassandra',
    //   port: { default: 9042 },
    //   watches: {
    //     columns: [
    //       {
    //         id: 'keyspace',
    //         numeric: false,
    //         disablePadding: false,
    //         label: 'Keyspace',
    //         defaultValue: '',
    //       },
    //       { id: 'table', numeric: false, disablePadding: false, label: 'Table', defaultValue: '' },
    //       {
    //         id: 'columns',
    //         numeric: false,
    //         disablePadding: false,
    //         label: 'Columns',
    //         defaultValue: [],
    //       },
    //     ],
    //     defaultSorting: 'keyspace',
    //   },
    // },
    sqlserver: {
      displayName: 'SQLServer',
      port: { default: 1433 },
      watches: {
        columns: [
          {
            id: 'schema',
            numeric: false,
            disablePadding: false,
            label: 'Schema',
            defaultValue: '',
          },
          { id: 'table', numeric: false, disablePadding: false, label: 'Table', defaultValue: '' },
          {
            id: 'columns',
            numeric: false,
            disablePadding: false,
            label: 'Columns',
            defaultValue: [],
          },
        ],
        defaultSorting: 'schema',
      },
    },
    mariadb: {
      displayName: 'MariaDB',
      port: { default: 3306 },
      watches: {
        columns: [
          {
            id: 'schema',
            numeric: false,
            disablePadding: false,
            label: 'Schema',
            defaultValue: '',
          },
          { id: 'table', numeric: false, disablePadding: false, label: 'Table', defaultValue: '' },
          {
            id: 'columns',
            numeric: false,
            disablePadding: false,
            label: 'Columns',
            defaultValue: [],
          },
        ],
        defaultSorting: 'schema',
      },
    },
    galera: {
      displayName: 'Galera',
      port: { default: 3306 },
      watches: {
        columns: [
          {
            id: 'schema',
            numeric: false,
            disablePadding: false,
            label: 'Schema',
            defaultValue: '',
          },
          { id: 'table', numeric: false, disablePadding: false, label: 'Table', defaultValue: '' },
          {
            id: 'columns',
            numeric: false,
            disablePadding: false,
            label: 'Columns',
            defaultValue: [],
          },
        ],
        defaultSorting: 'schema',
      },
    },
    redshift: {
      displayName: 'Amazon Redshift',
      port: { default: 5439 },
      watches: {
        columns: [
          {
            id: 'schema',
            numeric: false,
            disablePadding: false,
            label: 'Schema',
            defaultValue: '',
          },
          { id: 'table', numeric: false, disablePadding: false, label: 'Table', defaultValue: '' },
          {
            id: 'columns',
            numeric: false,
            disablePadding: false,
            label: 'Columns',
            defaultValue: [],
          },
        ],
        defaultSorting: 'schema',
      },
    },
    oracle: {
      displayName: 'Oracle',
      port: { default: 1521 },
      watches: {
        columns: [
          {
            id: 'schema',
            numeric: false,
            disablePadding: false,
            label: 'Schema',
            defaultValue: '',
          },
          { id: 'table', numeric: false, disablePadding: false, label: 'Table', defaultValue: '' },
          {
            id: 'columns',
            numeric: false,
            disablePadding: false,
            label: 'Columns',
            defaultValue: [],
          },
        ],
        defaultSorting: 'schema',
      },
    },
    s3: {
      displayName: 'Amazon S3',
      port: { default: 443, locked: true },
      hostName: { default: 's3.amazonaws.com', locked: true },
      repoName: { default: 'S3', locked: true },
      // tls: {default: true, locked: true},
      clientTLS: { default: 'enableAndVerifyCert', locked: true },
      repoTLS: { default: 'enableAndVerifyCert', locked: true },
      watches: {
        columns: [
          {
            id: 'bucket',
            numeric: false,
            disablePadding: false,
            label: 'Bucket',
            defaultValue: '',
          },
          { id: 'key', numeric: false, disablePadding: false, label: 'Key', defaultValue: '' },
        ],
        defaultSorting: 'bucket',
      },
    },
    rest: {
      displayName: 'API Proxy',
      // port: { default: 443, locked: true },
      // hostName: { default: 's3.amazonaws.com', locked: true },
      // repoName: { default: 'S3', locked: true },
      // tls: {default: true, locked: true},
      // clientTLS: { default: 'enableAndVerifyCert', locked: true },
      // repoTLS: { default: 'enableAndVerifyCert', locked: true },
      watches: {
        columns: [
          {
            id: 'method',
            numeric: false,
            disablePadding: false,
            label: 'Method',
            defaultValue: '',
          },
          {
            id: 'endpoints',
            numeric: false,
            disablePadding: false,
            label: 'Endpoints',
            defaultValue: [],
          },
        ],
        defaultSorting: 'method',
      },
    },
    denodo: {
      displayName: 'Denodo',
      port: { default: 9996 },
      watches: {
        columns: [
          {
            id: 'schema',
            numeric: false,
            disablePadding: false,
            label: 'Schema',
            defaultValue: '',
          },
          { id: 'table', numeric: false, disablePadding: false, label: 'Table', defaultValue: '' },
          {
            id: 'columns',
            numeric: false,
            disablePadding: false,
            label: 'Columns',
            defaultValue: [],
          },
        ],
        defaultSorting: 'schema',
      },
    },
  }).sort(([_, repoA], [__, repoB]) =>
    repoA.displayName.toUpperCase() < repoB.displayName.toUpperCase()
      ? -1
      : repoA.displayName.toUpperCase() > repoB.displayName.toUpperCase()
      ? 1
      : 0
  )
)
