/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Fragment } from 'react'
import { withStyles, TableRow, Typography, Tooltip } from '@material-ui/core'
import classNames from 'classnames'

import { ExpandButton, TableCell } from '@jeeves/components/Table'

import Severity from '../Severity'
import ExpandedRow from '../ExpandedRow'
import useAlerts from '@jeeves/pages/Threats/hooks/useAlerts'

const styles = theme => ({
  expanded: {
    '& td': {
      border: 'none',
    },
  },
  clickable: {
    cursor: 'pointer',
  },
})

const Row = ({
  alert,
  greyedOut,
  closeRow,
  classes,
  handleExpandRow,

  expanded,
}) => {
  const { formatDate } = useAlerts()

  const getTechnique = row => {
    if (!(row.mitre && row.mitre.technique && row.mitre.technique.name)) return '-'
    if (!row.mitre.technique.url) return row.mitre.technique.name
    return (
      <Tooltip title="Learn more about this technique">
        <a
          href={row.mitre.technique.url}
          target="_blank"
          style={{ textDecoration: 'none', color: '#354da1' }}
        >
          {row.mitre.technique.name}
        </a>
      </Tooltip>
    )
  }

  const getTactic = row => {
    if (!(row.mitre && row.mitre.tactic && row.mitre.tactic.name)) return '-'
    if (!row.mitre.tactic.url) return row.mitre.tactic.name
    return (
      <Tooltip title="Learn more about this tactic">
        <a
          href={row.mitre.tactic.url}
          target="_blank"
          style={{ textDecoration: 'none', color: '#354da1' }}
        >
          {row.mitre.tactic.name}
        </a>
      </Tooltip>
    )
  }

  return (
    <Fragment>
      <TableRow
        tabIndex={-1}
        key={alert.id}
        hover={!expanded}
        className={classNames({
          [classes.expanded]: expanded,
        })}
        css={() => css`
          transition: opacity 0.2s ease-in-out;
          td {
            border-bottom: ${expanded ? 0 : undefined};
          }
        `}
      >
        <TableCell>
          {/* <Typography className={classes.flex}>{timestampToStringDate(row.timestamp)}</Typography> */}
          <Typography className={classes.flex}>{formatDate(alert.time)}</Typography>
        </TableCell>

        <TableCell>
          <Tooltip title="Filter alerts">
            {/* <span
              onClick={() => addFilter('severity', severityMap.get(alert.severity))}
              className={classes.clickable}
            > */}
            {/* {severityMap.get(alert.severity)} */}
            <Severity severity={alert.severity}></Severity>
            {/* </span> */}
          </Tooltip>
        </TableCell>
        <TableCell>{getTactic(alert)}</TableCell>
        <TableCell>{getTechnique(alert)}</TableCell>
        <TableCell className={classes.identityCell}>
          {alert.identity && alert.identity.user ? alert.identity.user : `\u2014`}
        </TableCell>
        <TableCell align="right">
          {alert.alerttype !== 'portScan' && alert.alerttype !== 'authenticationFailure' && (
            <ExpandButton onClick={handleExpandRow} />
          )}
        </TableCell>
      </TableRow>

      <ExpandedRow formatDate={formatDate} row={alert} expanded={expanded} />
    </Fragment>
  )
}

export default withStyles(styles)(Row)
