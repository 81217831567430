import { useFormContext } from 'react-hook-form'
import { Stack } from '@mui/material'

import { Button } from '@jeeves/new-components'

interface Props {
  saveLoading: boolean
  saveDisabled?: boolean
}

const FormFooter = ({ saveLoading, saveDisabled }: Props) => {
  const {
    reset,
    formState: { isDirty },
  } = useFormContext()

  const isSaveDisabled = !isDirty

  return (
    <Stack direction="row" sx={{ justifyContent: 'flex-end' }} spacing={2}>
      <Button variant="text" onClick={() => reset()} disabled={!isDirty}>
        Cancel
      </Button>
      <Button type="submit" disabled={saveDisabled ?? isSaveDisabled} loading={saveLoading}>
        Save
      </Button>
    </Stack>
  )
}

export default FormFooter
