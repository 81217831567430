import React from 'react'
import { Stack, Typography, Divider } from '@mui/material'
import DnsIcon from '@mui/icons-material/Dns'
import MapIcon from '@mui/icons-material/Map'
import DescriptionIcon from '@mui/icons-material/Description'
import { formatNumber } from '@jeeves/utils/formatNumber'

const SnapshotIcon = ({ title, ...rest }) => {
  let Icon

  switch (title) {
    case 'Data Repos':
      Icon = DnsIcon
      break
    case 'Data Labels':
      Icon = MapIcon
      break
    case 'Policies':
      Icon = DescriptionIcon
      break
    default:
      throw new Error(`Unable to find icon for type: ${title}`)
  }

  return <Icon {...rest} />
}

const SnapshotItem = ({ snapshotTitle, snapshotData }) => {
  return (
    <Stack direction="row" spacing={3} sx={{ alignItems: 'center' }}>
      <SnapshotIcon title={snapshotTitle} color="primary" sx={{ fontSize: '36px' }} />
      <Stack>
        <Typography variant="h5" sx={{ color: 'text.secondary' }}>
          {snapshotTitle}
        </Typography>

        <Typography variant="h2" sx={{ color: 'text.primary' }}>
          {formatNumber(snapshotData)}
        </Typography>
      </Stack>
    </Stack>
  )
}

export const Snapshot = ({ queryResponse }) => {
  const { data, loading } = queryResponse

  return (
    <Stack spacing={4}>
      <Stack spacing={2}>
        <Typography variant="h4" sx={{ color: 'text.primary' }}>
          Snapshot
        </Typography>
        <Divider />
      </Stack>
      <Stack spacing={4}>
        <SnapshotItem snapshotTitle={'Data Repos'} snapshotData={data?.repos} />
        <SnapshotItem snapshotTitle={'Data Labels'} snapshotData={data?.datalabels} />
        <SnapshotItem snapshotTitle={'Policies'} snapshotData={data?.globalYAMLPolicies} />
      </Stack>
    </Stack>
  )
}
