/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { useForm, Controller } from 'react-hook-form'
import React, { useEffect } from 'react'
import { DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@material-ui/core'

import { ButtonLoading } from '@jeeves/components/Loading'
import { Dialog, Button } from '@jeeves/components/Primitives'
import CodeEditor from '../CodeEditor'
import { TagList } from '@jeeves/components/TagList'

import usePolicies from '../../hooks/usePolicies'
import HelperText from '../HelperText'

const Create = ({ open, setOpen }) => {
  const { handleOnCreate, updatePolicies } = usePolicies()

  const {
    register,
    control,
    trigger,
    getValues,
    reset,
    setValue,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      name: '',
      tags: [],
      body: '',
    },
  })

  const handleOnClose = () => {
    setOpen(false)
    reset()
  }

  // NOTE (Eric): This code should eventually be uncommented and used.
  // It's commented out because isSubmitSuccessful is never triggered to be `true`
  // because there's an issue with keypress on TagList component. Will come back to this.
  // useEffect(() => {
  //   if (isSubmitSuccessful) {
  //     updatePolicies()
  //     handleOnClose()
  //   }
  // }, [isSubmitSuccessful, reset])

  const onSubmit = async () => {
    const inputsAreValid = await trigger()

    if (!inputsAreValid) return

    const { name, tags, body } = getValues()

    try {
      await handleOnCreate({ name, tags }, body).then(res => res)
      updatePolicies()
      handleOnClose()
    } catch (e) {
      console.log('Error while creating new policy')
      console.error(e)
      throw e
    }
  }

  const handleTagDelete = tag => {
    const tags = getValues('tags')
    const newTags = tags.filter(t => t !== tag)

    setValue('tags', newTags)
  }

  const handleAddTag = tag => {
    const tags = getValues('tags')
    const newTags = [...tags, tag]

    setValue('tags', newTags)
  }

  const tags = watch('tags')
  const policyName = register('name', { required: true })

  return (
    <Dialog open={open} onClose={handleOnClose} fullWidth aria-labelledby="form-dialog-title">
      <form>
        <DialogTitle>
          Create Policy
          <Typography variant="caption">
            Create rules that give people, apps, and groups the right to access data in schemas,
            tables, and attributes.
          </Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Name"
            onChange={policyName.onChange}
            onBlur={policyName.onBlur}
            inputRef={policyName.ref}
            name={policyName.name}
            fullWidth
          />

          <div css={theme => ({ marginTop: theme.spacing[3] })}>
            <TagList
              tags={tags}
              onAdd={handleAddTag}
              onDelete={handleTagDelete}
              helperText="Use the field above to set tags for this policy. Press Enter to add a tag."
            />
          </div>

          <div css={theme => ({ marginTop: theme.spacing[3] })}>
            <Typography color="textSecondary">Policy</Typography>
            <CodeEditor control={control} trigger={trigger} name="body" />
            <HelperText />
          </div>
        </DialogContent>
        <DialogActions css={theme => ({ justifyContent: 'flex-end', padding: theme.spacing[3] })}>
          <Button onClick={handleOnClose} color="primary" disabled={isSubmitting}>
            Cancel
          </Button>
          <div css={{ position: 'relative' }}>
            {/* <Button variant="contained" color="primary" type="submit" disabled={isSubmitting}> */}
            <Button variant="contained" color="primary" disabled={isSubmitting} onClick={onSubmit}>
              Create
            </Button>
            {isSubmitting && <ButtonLoading />}
          </div>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default Create
