import * as React from 'react'
import { useFormState } from 'react-hook-form'
import Stack from '@mui/material/Stack'
import { Button } from '@jeeves/new-components'

import { useUserAuthenticationContext } from '@jeeves/pages/RepositoryDetail/Tabs/UserAuthentication/contexts/UserAuthenticationContext'

const StepFooter = ({ activeStep, prevStep, isLastStep }) => {
  const { isSubmitting } = useFormState()
  const {
    userAccounts,
    isUpdating,
    setIsUpdating,
    isRegistering,
    setIsRegistering,
    setSelectedUserAccountId,
  } = useUserAuthenticationContext()

  let cancelButton = null
  if (activeStep === 0) {
    const onCancel = () => {
      if (isUpdating) {
        setIsUpdating(false)
      }

      if (isRegistering) {
        setSelectedUserAccountId(userAccounts?.[0]?.id ?? '')
        setIsRegistering(false)
      }
    }

    cancelButton = (
      <Button variant="outlined" color="secondary" onClick={onCancel}>
        Cancel
      </Button>
    )
  }

  let backButton = null
  if (activeStep > 0) {
    backButton = (
      <Button variant="outlined" color="secondary" onClick={prevStep}>
        Back
      </Button>
    )
  }

  const submitButton = (
    <Button type="submit" loading={isSubmitting}>
      {isLastStep ? (isRegistering ? 'Create' : 'Save') : 'Next'}
    </Button>
  )

  return (
    <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
      {cancelButton || backButton}

      {submitButton}
    </Stack>
  )
}

export default StepFooter
