import { useState } from 'react'

import ExpressClient from '@jeeves/clients/express'
import { useAppConfig, useAuth } from '@jeeves/hooks'
import { useToast } from '@jeeves/new-components'

export const useAllowCyralAccess = () => {
  const { license } = useAppConfig()
  const { getTokenSilently, hasPermission } = useAuth()
  const { toast } = useToast()

  const ec = new ExpressClient(getTokenSilently)

  const [allowCyralAccess, setAllowCyralAccess] = useState(false)
  const [allowCyralAccessIsLoading, setAllowCyralAccessIsLoading] = useState(false)

  const isChecked = license.isFreeTrial || allowCyralAccess

  const isDisabled =
    !hasPermission('users:update') || license.isFreeTrial || allowCyralAccessIsLoading

  const fetchAllowCyralAccess = async () => {
    try {
      const { data } = await ec.get('/preferences')
      setAllowCyralAccess(data.allowCyralAccess)
    } catch (error) {
      toast({
        variant: 'error',
        description: error,
      })
    }
  }

  const handleAllowCyralAccess = async () => {
    try {
      setAllowCyralAccessIsLoading(true)
      await ec.put('/preferences', { allowCyralAccess: !allowCyralAccess })
      await fetchAllowCyralAccess()
      setAllowCyralAccessIsLoading(false)
    } catch (error) {
      toast({
        variant: 'error',
        description: error,
      })
    }
  }

  return {
    isLoading: allowCyralAccessIsLoading,
    isChecked,
    isDisabled,
    fetchAllowCyralAccess,
    handleAllowCyralAccess,
  }
}
