import {
  Box,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'

import { Button, Dialog, DialogActions, Input, InputLabel } from '@jeeves/new-components'

import { useCreateSidecarModal } from './useCreateSidecarModal'

interface CreateSidecarModalProps {
  open: boolean
  closeModal: () => void
}

const CreateSidecarModal = ({ open, closeModal }: CreateSidecarModalProps) => {
  const { loading, methods, onSubmit } = useCreateSidecarModal()

  const {
    formState: { errors },
    register,
    reset,
  } = methods

  const onClose = () => {
    reset()
    closeModal()
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <Box component="form" onSubmit={onSubmit}>
        <DialogTitle
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h3" sx={{ color: 'text.primary' }}>
            Create Sidecar
          </Typography>
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <FormControl variant="standard" error={Boolean(errors.name)} fullWidth>
            <InputLabel htmlFor="name">Sidecar Name</InputLabel>
            <Input
              id="name"
              type="text"
              inputProps={{
                ...register('name'),
              }}
            />
            <FormHelperText sx={{ typography: 'body2' }}>{errors.name?.message}</FormHelperText>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Stack direction="row" spacing={2} sx={{ justifyContent: 'flex-end' }}>
            <Button variant="text" color="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button variant="contained" type="submit" color="primary" loading={loading}>
              Create
            </Button>
          </Stack>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default CreateSidecarModal
