import { useLazyQuery } from '@apollo/client'

import { ACCESS_TOKENS } from './useAccessToken'

const useValidateAccessToken = () => {
  const [getTokens] = useLazyQuery(ACCESS_TOKENS)

  const isValid = async (testAccessToken: string) => {
    const { error, data } = await getTokens()
    if (error) {
      console.error('An error occurred while fetching the access token list: ', error)
    }
    return Boolean(data?.accessTokens.some(accessToken => accessToken.token === testAccessToken))
  }

  return {
    isValid,
  }
}

export default useValidateAccessToken
