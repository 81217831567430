/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import styled from '@emotion/styled'
import { Typography, Card } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'

export const SectionTitle = styled(Typography)`
  margin-bottom: 16px;
  color: ${grey[700]};
  font-size: 14px;
  font-weight: 600;
`

export const Feature = styled(Typography)`
  font-size: 14px;
  margin-bottom: 8px;
`
export const WrapperCard = styled(Card)`
  color: white;
  height: 100%;
  padding: 8px;
  position: relative;
  border-radius: 10px;
  box-shadow: none;
  overflow: hidden;
  //   min-height: 300px;
`
