import React, { useState } from 'react'
import ExpressClient from '../../../../../clients/express'
import { useAuth } from '../../../../../components/Auth'
import { get as lodashGet } from 'lodash'

import usePopup from '../../../../../components/PopupMessage/hooks/usePopup'

const useForgeRock = () => {
  const { setPopup, popupTypes } = usePopup()
  const { getTokenSilently, user } = useAuth()
  const ec = new ExpressClient(getTokenSilently)

  const [integrations, setIntegrations] = useState([])

  const [addField, setAddField] = useState(integrations.length === 0 ? 1 : 0)

  const handleCreateAddField = () => setAddField(1)
  const handleRemoveAddField = () => setAddField(0)

  const addIntegration = async input => {
    try {
      const integration = await ec.post('/integrations/saml', input).then(res => res.data)
      updateIntegrations()
      return integration
    } catch (e) {
      const popupMessage = lodashGet(e, 'response.data.error.message', '')
      setPopup(popupTypes.ERROR, popupMessage)
      throw e
    }
  }

  const putIntegration = async input => {
    try {
      console.log('putIntegration: ', input)
      const integration = await ec.put(`/integrations/saml`, input).then(res => res.data)
      updateIntegrations()
      return integration
    } catch (e) {
      const popupMessage = lodashGet(e, 'response.data.error.message', '')
      setPopup(popupTypes.ERROR, popupMessage)
      throw e
    }
  }

  const deleteIntegration = async integrationID => {
    try {
      const integration = await ec
        .delete(`/integrations/saml/${integrationID}`)
        .then(res => res.data)

      updateIntegrations()
      return integration
    } catch (e) {
      const popupMessage = lodashGet(e, 'response.data.error.message', '')
      setPopup(popupTypes.ERROR, popupMessage)
      throw e
    }
  }

  const updateIntegrations = async () => {
    try {
      let result = await ec
        .get('/integrations/saml?identityProvider=forgerock')
        .then(res => res.data)

      setIntegrations(result)
    } catch (e) {
      const popupMessage = lodashGet(e, 'response.data.error.message', '')
      setPopup(popupTypes.ERROR, popupMessage)
      throw e
    }
  }

  const getSAMLMetadata = async alias => {
    const downloadFile = content => {
      const element = document.createElement('a')
      // eslint-disable-next-line
      const file = new Blob([content], { type: 'text/xml' })
      element.href = URL.createObjectURL(file)
      element.download = 'cyral-sp-metadata.xml'
      document.body.appendChild(element)
      element.click()
    }

    try {
      let result = await ec
        .get(`/integrations/saml/${alias}/export`)
        .then(res => res.data.metadata.metadata)

      downloadFile(result)
    } catch (e) {
      const popupMessage = lodashGet(e, 'response.data.error.message', '')
      setPopup(popupTypes.ERROR, popupMessage)
      throw e
    }
  }

  return {
    handleCreateAddField,
    handleRemoveAddField,
    updateIntegrations,
    integrations,
    addIntegration,
    putIntegration,
    deleteIntegration,
    addField,
    user,
    lodashGet,
    getSAMLMetadata,
  }
}

export default useForgeRock
