const baseStyles = {
  overflow: 'hidden',
  display: '-webkit-box',
  '-webkit-box-orient': 'vertical',
}

export const getLineClampStyles = (lines: number) => ({
  ...baseStyles,
  '-webkit-line-clamp': `${lines}`,
})
