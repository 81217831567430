import { Dispatch, SetStateAction } from 'react'

import useFinishStepper from './useFinishStepper'

const useSetupWizardActions = ({
  currentStep,
  stepsNumber,
  setCurrentStep,
}: {
  currentStep: number
  stepsNumber: number
  setCurrentStep: Dispatch<SetStateAction<number>>
}) => {
  const { loading, onClickFinishStepper } = useFinishStepper()

  const isNotLastStep = currentStep < stepsNumber

  const onClickBack = () => setCurrentStep(currentStep - 1)

  const onClickContinue = async () => {
    if (isNotLastStep) {
      setCurrentStep(currentStep + 1)
    } else {
      await onClickFinishStepper()
    }
  }

  return {
    isNotLastStep,
    loading,
    onClickBack,
    onClickContinue,
  }
}

export default useSetupWizardActions
