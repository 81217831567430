import * as React from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'

import { Button, InlineCode } from '@jeeves/new-components'
import { formatLocalizedTimestamp } from '@jeeves/utils/datetime'

const ActionableRequestDetailsContainer = ({
  approval,
  openRejectApprovalDialog,
  openGrantApprovalDialog,
}) => {
  // Approval type can only be GrantedApproval or PendingApproval
  const approvalType = approval.__typename
  const approvalToUse = approvalType === 'GrantedApproval' ? approval.pendingAmendment : approval

  return (
    <Box
      sx={{
        border: 1,
        borderColor: 'primary.main',
        borderRadius: theme => theme.radii.base,
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          p: 4,
        }}
      >
        <Stack spacing={2} divider={<Divider orientation="horizontal" flexItem />}>
          <Dates validFrom={approvalToUse.validFrom} validUntil={approvalToUse.validUntil} />
          <SensitiveFields sensitiveFields={approvalToUse.sensitiveFields} />
        </Stack>
      </Box>

      <Footer
        openRejectApprovalDialog={openRejectApprovalDialog}
        openGrantApprovalDialog={openGrantApprovalDialog}
      />
    </Box>
  )
}

const Dates = ({ validFrom, validUntil }) => {
  const formattedValidFrom = formatLocalizedTimestamp(validFrom, {
    includeTime: true,
  })

  // validUntil might not exist since it's nullable
  const formattedValidUntil = validUntil
    ? formatLocalizedTimestamp(validUntil, { includeTime: true })
    : 'No expiry'

  return (
    <Stack direction="row" spacing={2}>
      <Stack direction="row" spacing={0.5} sx={{ alignItems: 'center' }}>
        <Typography variant="h6" sx={{ color: 'text.secondary' }}>
          Access starts on:
        </Typography>

        <Typography component="span" variant="h6" sx={{ color: 'text.primary' }}>
          {formattedValidFrom}
        </Typography>
      </Stack>

      <Stack direction="row" spacing={0.5} sx={{ alignItems: 'center' }}>
        <Typography variant="h6" sx={{ color: 'text.secondary' }}>
          Access ends on:
        </Typography>

        <Typography component="span" variant="h6" sx={{ color: 'text.primary' }}>
          {formattedValidUntil}
        </Typography>
      </Stack>
    </Stack>
  )
}

const SensitiveFields = ({ sensitiveFields }) => {
  const numberOfSensitiveFields = sensitiveFields.length

  return (
    <Stack spacing={1}>
      <Typography variant="h6" sx={{ color: 'text.secondary' }}>
        Fields {numberOfSensitiveFields > 0 ? `(${numberOfSensitiveFields})` : ''}
      </Typography>

      {numberOfSensitiveFields > 0 ? (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
          {sensitiveFields.map(field => (
            <InlineCode key={field}>{field}</InlineCode>
          ))}
        </Box>
      ) : (
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          No fields requested
        </Typography>
      )}
    </Stack>
  )
}

const Footer = ({ openRejectApprovalDialog, openGrantApprovalDialog }) => {
  return (
    <Box
      sx={{
        p: 2,
        bgcolor: 'cyralColors.grey.100',
        borderTop: 1,
        borderColor: 'cyralColors.grey.200',
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      <Stack direction="row" spacing={2}>
        <Button
          variant="outlined"
          onClick={openRejectApprovalDialog}
          color="secondary"
        >
          Reject
        </Button>

        <Button onClick={openGrantApprovalDialog}>Grant</Button>
      </Stack>
    </Box>
  )
}

export default ActionableRequestDetailsContainer
