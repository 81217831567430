import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  parseISO,
} from 'date-fns'

import pluralize from 'pluralize'

import { usePolicyDetails } from '@jeeves/pages/RepositoryDetail/Tabs/Policies/views/PolicyDetails/contexts/PolicyDetailsContext'

const getPluralizedOrSingularizedTime = (value, unit) => `${value} ${pluralize(unit, value)} ago`

const getRelativeTime = timestamp => {
  const eventDate = parseISO(timestamp)
  const now = new Date()

  const seconds = differenceInSeconds(now, eventDate)
  if (seconds < 60) {
    return getPluralizedOrSingularizedTime(seconds, 'second')
  }

  const minutes = differenceInMinutes(now, eventDate)
  if (minutes < 60) {
    return getPluralizedOrSingularizedTime(minutes, 'minute')
  }

  const hours = differenceInHours(now, eventDate)
  if (hours < 24) {
    return getPluralizedOrSingularizedTime(hours, 'hour')
  }

  const days = differenceInDays(now, eventDate)
  return getPluralizedOrSingularizedTime(days, 'days')
}

export const useLastUpdatedBy = () => {
  const { policy } = usePolicyDetails()

  const lastUpdated = policy.lastUpdated

  if (lastUpdated) {
    const updatedAt = lastUpdated.timestamp
    const actorName = lastUpdated.actor.name

    return {
      displayedString: `Last updated ${getRelativeTime(updatedAt)}`,
      actorName: `by ${actorName}`,
    }
  }

  const createdAt = policy.created.timestamp
  const actorName = policy.created.actor.name

  return {
    displayedString: `Created ${getRelativeTime(createdAt)}`,
    actorName: `by ${actorName}`,
  }
}
