import { z } from 'zod'
import { hostPortSchema } from '@jeeves/pages/RepositoryDetail/Edit/schemas'
import { REPO_METADATA, ALL_REPO_TYPES_WITH_DISPLAY_NAME } from '@jeeves/constants'

import {
  mongoDBRepoSchema,
  mongoDBStandaloneRepoSchema,
  mongoDBReplicaSetBaseSchema,
  mongoDBReplicaSetStaticNodesSchema,
  mongoDBReplicaSetSrvRecordSchema,
  mongoDBShardedClusterBaseSchema,
  mongoDBShardedClusterStaticNodesSchema,
  mongoDBShardedClusterSrvRecordSchema,
} from './mongo'

import { baseSchema } from './shared'

const standardRepoSchema = baseSchema.merge(
  z
    .object({
      repositoryType: z.enum(
        ALL_REPO_TYPES_WITH_DISPLAY_NAME.map(({ typeName }) => typeName).filter(
          repoType => repoType !== REPO_METADATA.MONGODB.typeName
        )
      ),
    })
    .merge(hostPortSchema)
)

export const validationSchema = z
  .discriminatedUnion('repositoryType', [standardRepoSchema, mongoDBRepoSchema], {
    errorMap: (error, ctx) => {
      switch (error.code) {
        case z.ZodIssueCode.invalid_union_discriminator:
          if (!ctx.data.repositoryType) {
            return { message: 'Please select a repository type.' }
          }
          break
        default:
          return { message: ctx.defaultError }
      }

      return { message: ctx.defaultError }
    },
  })
  .superRefine((obj, ctx) => {
    if (obj.repositoryType === REPO_METADATA.MONGODB.typeName) {
      const mongoDBRes = z
        .discriminatedUnion('clusterType', [
          mongoDBStandaloneRepoSchema,
          mongoDBReplicaSetBaseSchema,
          mongoDBShardedClusterBaseSchema,
        ])
        .refine(_ => {
          if (obj.clusterType === 'replicaSet') {
            const replicaSet = z
              .discriminatedUnion('connectionFormat', [
                mongoDBReplicaSetStaticNodesSchema,
                mongoDBReplicaSetSrvRecordSchema,
              ])
              .safeParse(obj)

            if (!replicaSet.success) {
              replicaSet.error.issues.forEach(ctx.addIssue)
              return z.NEVER
            }

            return true
          }

          if (obj.clusterType === 'shardedCluster') {
            const shardedCluster = z
              .discriminatedUnion('connectionFormat', [
                mongoDBShardedClusterStaticNodesSchema,
                mongoDBShardedClusterSrvRecordSchema,
              ])
              .safeParse(obj)

            if (!shardedCluster.success) {
              shardedCluster.error.issues.forEach(ctx.addIssue)
              return z.NEVER
            }

            return true
          }

          return true
        })
        .safeParse(obj)

      if (!mongoDBRes.success) {
        mongoDBRes.error.issues.forEach(ctx.addIssue)
        return z.NEVER
      }
    }
  })
