import { Typography, Box } from '@mui/material'

import { FragmentType, graphql, useFragment } from '@jeeves/graphql'

import useAmountOfAvailableTokens from './useAmountOfAvailableTokens'

const RemainingTokens_QueryFragment = graphql(`
  fragment RemainingTokens_QueryFragment on Query {
    ...useAmountOfAvailableTokens_QueryFragment
  }
`)

interface Props {
  query: FragmentType<typeof RemainingTokens_QueryFragment>
}

const RemainingTokens = ({ query: queryProp }: Props) => {
  const query = useFragment(RemainingTokens_QueryFragment, queryProp)
  const amountOfAvailableTokens = useAmountOfAvailableTokens({ query })

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        {amountOfAvailableTokens} tokens remaining
      </Typography>
    </Box>
  )
}

export default RemainingTokens
