import React from 'react'
import InputAdornment from '@mui/material/InputAdornment'
import SearchIcon from '@mui/icons-material/Search'

import Input from '../Input'

const SearchInput = ({ placeholder, ...rest }) => {
  return (
    <Input
      placeholder={placeholder || 'Search'}
      startAdornment={
        <InputAdornment position="start" sx={{ ml: 1 }}>
          <SearchIcon />
        </InputAdornment>
      }
      {...rest}
    />
  )
}

export default SearchInput
