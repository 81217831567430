import { Box } from '@mui/material'

import DataFirewall from './components/wizards/DataFirewall'
import DataMasking from './components/wizards/DataMasking'
import DataProtection from './components/wizards/DataProtection'
import ReadLimit from './components/wizards/ReadLimit/ReadLimit'
import RepositoryProtection from './components/wizards/RepositoryProtection'
import ServiceAccountAbuse from './components/wizards/ServiceAccountAbuse'
import UserSegmentation from './components/wizards/UserSegmentation'
import RateLimit from './components/wizards/RateLimit'

const EditPolicy = ({ policy }) => {
  const wizards = {
    DataFirewallPolicy: <DataFirewall policy={policy} />,
    DataMaskingPolicy: <DataMasking policy={policy} />,
    DataProtectionPolicy: <DataProtection policy={policy} />,
    ReadLimitPolicy: <ReadLimit policy={policy} />,
    RepositoryProtectionPolicy: <RepositoryProtection policy={policy} />,
    ServiceAccountAbusePolicy: <ServiceAccountAbuse policy={policy} />,
    UserSegmentationPolicy: <UserSegmentation policy={policy} />,
    RateLimitPolicy: <RateLimit policy={policy} />,
  }

  return <Box sx={{ mx: 8, my: 4 }}>{wizards[policy.__typename]}</Box>
}

export default EditPolicy
