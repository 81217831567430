/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import styled from '@emotion/styled'
import Loading from './'

// TO DO: use this for all intermediate loading, maybe take away the opacity hack

export const IntermediateLoading = ({ loading }) => (
  <div
    css={{
      zIndex: loading ? 3000 : -1,
      opacity: loading ? 1 : 0,
      height: '100%',
      width: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.25)',
      transition: 'opacity 255ms',
    }}
  >
    <Loading />
  </div>
)
