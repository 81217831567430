import * as React from 'react'

import Typography from '@mui/material/Typography'
import { getDocsURL } from '@jeeves/utils/docsURL'

export const LearnMore = ({}) => {
  return (
    <Typography sx={{ mb: 3 }}>
      Learn more about{' '}
      <a href={getDocsURL({ docsPath: 'policy/datamap' })} target="_blank">
        field mappings.
      </a>
    </Typography>
  )
}
