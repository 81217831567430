import * as React from 'react'
import { useQuery } from '@apollo/client'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import CircularProgress from '@mui/material/CircularProgress'

import { graphql } from '@jeeves/graphql'
import { useRepositoryDetailContext } from '@jeeves/pages/RepositoryDetail/contexts/RepositoryDetailContext'
import { Tooltip } from '@jeeves/new-components'

import CredentialsInfo from './CredentialsInfo'
import AccessRules from './components/AccessRules'
import ActionsPopover from './components/ActionsPopover'

import { useUserAuthenticationContext } from '@jeeves/pages/RepositoryDetail/Tabs/UserAuthentication/contexts/UserAuthenticationContext'

const USER_ACCOUNT_DETAIL_VIEW_QUERY = graphql(`
  query RepoUserAccount($repoId: String!, $userAccountId: ID!) {
    repo(id: $repoId) {
      id
      ... on RepoWithSSOFields {
        userAccount(id: $userAccountId) {
          id
          name
          credentials {
            ...credentialsFields
          }
          accessRules {
            ...accessRuleFields
          }
          numUsersAccessed
          ... on MongoDBUserAccount {
            authenticationDatabase
          }
        }
      }
    }
  }
`)

const UserAccountDetailView = () => {
  const { repoId } = useRepositoryDetailContext()
  const { selectedUserAccountId } = useUserAuthenticationContext()

  const { data, loading, error } = useQuery(USER_ACCOUNT_DETAIL_VIEW_QUERY, {
    variables: {
      repoId,
      userAccountId: selectedUserAccountId,
    },
  })

  if (loading) {
    return (
      <Stack spacing={2} sx={{ alignItems: 'center' }}>
        <CircularProgress size={64} />
        <Typography variant="h6" sx={{ color: 'text.secondary' }}>
          Loading database account details...
        </Typography>
      </Stack>
    )
  }

  if (error) {
    return (
      <Stack spacing={2} sx={{ alignItems: 'center' }}>
        <Typography variant="h6" sx={{ color: 'text.secondary' }}>
          Failed to load database account info
        </Typography>
      </Stack>
    )
  }

  const { credentials, name, numUsersAccessed } = data?.repo.userAccount
  const isCyralManaged = credentials.__typename === 'CyralStorageUserAccountCredentials'

  return (
    <Stack spacing={4}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 2 }}>
        <Typography variant="h2" sx={{ color: 'text.primary', flex: 1 }} noWrap>
          {name}
        </Typography>
        {isCyralManaged && (
          <Tooltip
            title={
              <Typography variant="caption">
                This account is managed by Cyral and cannot be modified.
              </Typography>
            }
          >
            <InfoOutlinedIcon sx={{ color: 'cyralColors.grey.400' }} />
          </Tooltip>
        )}
        <ActionsPopover disabled={isCyralManaged} />
      </Box>

      <Stack direction="row" spacing={1}>
        <UsersAccessedMetric numUsersAccessed={numUsersAccessed} />
        <CredentialsInfo credentials={credentials} />
      </Stack>

      <AccessRules userAccount={data?.repo?.userAccount} />
    </Stack>
  )
}

const UsersAccessedMetric = ({ numUsersAccessed }) => {
  return (
    <Box
      sx={{
        borderRadius: theme => theme.radii.base,
        border: 1,
        borderColor: 'cyralColors.grey.200',
        py: 2.5,
        px: 2,
      }}
    >
      <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
        <Typography variant="h3" sx={{ color: 'text.primary' }}>
          {numUsersAccessed}
        </Typography>
        <Stack>
          <Typography variant="h5" sx={{ color: 'text.secondary' }}>
            Users Accessed
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            in the last 7 days
          </Typography>
        </Stack>
      </Stack>
    </Box>
  )
}

export default UserAccountDetailView
