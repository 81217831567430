import * as React from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'

import { Input, InputLabel, InlineCode } from '@jeeves/new-components'

import ConfigInstructions from './components/ConfigInstructions'

const KubernetesSecret = ({ databaseAccountInfo, handleMutateUserAccount, footer }) => {
  const methods = useForm({
    defaultValues: {
      secretName: databaseAccountInfo?.credentials?.secretName ?? '',
      secretKey: databaseAccountInfo?.credentials?.secretKey ?? '',
    },
  })

  const { register, handleSubmit, formState } = methods
  const { errors } = formState

  // React.useEffect(() => {
  //   if (isSubmitSuccessful) {
  //     // closeWizard()
  //   }
  // }, [isSubmitSuccessful, closeWizard])

  const onSubmit = async ({ secretName, secretKey }) => {
    const userAccount = {
      ...databaseAccountInfo,
      credentials: {
        type: databaseAccountInfo.credentials.type,
        secretName,
        secretKey,
      },
    }

    await handleMutateUserAccount(userAccount)
  }

  return (
    <FormProvider {...methods}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4}>
          {/* <ConfigInstructions /> */}

          <FormControl variant="standard" error={Boolean(errors.secretName)}>
            <InputLabel htmlFor="secretName">Secret Name</InputLabel>
            <Input
              id="secretName"
              inputProps={{ ...register('secretName', { required: 'This field is required.' }) }}
              fullWidth
            />
            <FormHelperText
              sx={{
                typography: 'body2',
              }}
              error={!!errors?.secretName}
            >
              {errors?.secretName?.message ||
                'This is the name of the secret in Kubernetes Secrets.'}
            </FormHelperText>
          </FormControl>

          <FormControl variant="standard" error={Boolean(errors.secretKey)}>
            <InputLabel htmlFor="secretKey">Key Name</InputLabel>
            <Input
              id="secretKey"
              inputProps={{ ...register('secretKey', { required: 'This field is required.' }) }}
              fullWidth
            />
            <FormHelperText
              sx={{
                typography: 'body2',
              }}
              error={!!errors?.secretKey}
            >
              {errors?.secretKey?.message || (
                <React.Fragment>
                  This is the key name within the <InlineCode noWrap>stringData</InlineCode> blob in
                  Kubernetes Secrets.
                </React.Fragment>
              )}
            </FormHelperText>
          </FormControl>

          {footer}
        </Stack>
      </Box>
    </FormProvider>
  )
}

export default KubernetesSecret
