import * as React from 'react'
import { useFragment_experimental, gql } from '@apollo/client'
import { flexRender } from '@tanstack/react-table'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'

import { Button } from '@jeeves/new-components'

import { useBindingsTable } from './useBindingsTable'
import { TableContainer } from './BindingsTable.styles'
import EmptyState from './EmptyState'
import UnbindRepoFromSidecarModal from './UnbindRepoFromSidecarModal/UnbindRepoFromSidecarModal'
import { useBindingsTableContext } from './BindingsTableContext'

const BindingsTable_queryFragment = gql`
  fragment BindingsTable_query on Query {
    ...useBindingsTable_query
  }
  ${useBindingsTable.fragments.useBindingsTable_queryFragment}
`

const BindingsTable = ({ query }) => {
  const [unbindingModalOpen, setUnbindingModalOpen] = React.useState(false)
  const [selectedBinding, setSelectedBinding] = React.useState()

  const { table, getPreviousPage, getNextPage } = useBindingsTable()
  const { isPaginating, filteredCount } = useBindingsTableContext()

  const { data } = useFragment_experimental({
    fragment: BindingsTable_queryFragment,
    fragmentName: 'BindingsTable_query',
    from: query,
  })

  const tableRows = table.getRowModel().rows
  const hasPreviousPage = table.getCanPreviousPage()
  const hasNextPage = table.getCanNextPage()

  const closeModal = () => {
    setSelectedBinding()
    setUnbindingModalOpen(false)
  }

  const getPaginationLabel = () => {
    const { pageIndex, pageSize } = table.getState().pagination
    if (filteredCount <= pageSize) {
      return `Showing ${tableRows.length} bound ${
        tableRows.length > 1 ? 'repositories' : 'repository'
      }`
    }
    const start = pageIndex * pageSize + 1
    const end = start + tableRows.length - 1
    return `Showing ${start} - ${end} of ${filteredCount} bound repositories`
  }

  return (
    <React.Fragment>
      <TableContainer>
        <Stack spacing={5}>
          <Stack spacing={4}>
            <table>
              <thead>
                {table.getHeaderGroups().map(headerGroup => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map(header => (
                      <th key={header.id}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(header.column.columnDef.header, header.getContext())}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {!isPaginating &&
                  tableRows.map(row => (
                    <tr key={row.id}>
                      {row.getVisibleCells().map(cell => {
                        if (cell.column.id === 'actions') {
                          return (
                            <RowActionsCell
                              key={cell.id}
                              cell={cell}
                              setUnbindingModalOpen={setUnbindingModalOpen}
                              setSelectedBinding={setSelectedBinding}
                              query={query}
                            />
                          )
                        }

                        return (
                          <td key={cell.id}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </td>
                        )
                      })}
                    </tr>
                  ))}
              </tbody>
            </table>

            {!isPaginating && tableRows.length > 0 && (
              <Box
                component="nav"
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography variant="h6" sx={{ color: 'text.secondary' }}>
                  {getPaginationLabel()}
                </Typography>
                <Stack spacing={2} direction="row">
                  {hasPreviousPage && (
                    <Button
                      variant="text"
                      color="secondary"
                      onClick={getPreviousPage}
                      startIcon={<ChevronLeftIcon sx={{ fontSize: 16 }} />}
                    >
                      Back
                    </Button>
                  )}
                  {hasNextPage && (
                    <Button
                      variant="text"
                      color="secondary"
                      onClick={getNextPage}
                      endIcon={<ChevronRightIcon sx={{ fontSize: 16 }} />}
                    >
                      Next
                    </Button>
                  )}
                </Stack>
              </Box>
            )}
          </Stack>

          <EmptyState />
        </Stack>
      </TableContainer>

      <UnbindRepoFromSidecarModal
        open={unbindingModalOpen}
        binding={selectedBinding}
        closeModal={closeModal}
      />
    </React.Fragment>
  )
}

const RowActionsCell = ({ cell, setUnbindingModalOpen, setSelectedBinding, query }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const open = Boolean(anchorEl)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  function handleOpenUnbindingModal() {
    handleClose()
    setSelectedBinding(cell?.row?.original)
    setUnbindingModalOpen(true)
  }

  const props = {
    ...cell.getContext(),
    actionButton: {
      menuOpen: open,
      anchorEl,
      openMenu: handleClick,
      closeMenu: handleClose,
      openUnbindingModal: handleOpenUnbindingModal,
    },
    query,
  }

  return <td>{flexRender(cell.column.columnDef.cell, props)}</td>
}

BindingsTable.fragments = {
  BindingsTable_queryFragment,
}

export default BindingsTable
