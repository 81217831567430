import * as React from 'react'
import { Link } from 'react-router-dom'
import { createColumnHelper } from '@tanstack/react-table'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import ReportProblemIcon from '@mui/icons-material/ReportProblemOutlined'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MuiLink from '@mui/material/Link'

import { Badge, ServiceIcon, Tag, Tooltip } from '@jeeves/new-components'
import { isMultiplexedListener } from '@jeeves/graphql/utils'

import EditBinding from '../../EditBinding'

const ActionsCell = props => {
  const [renderButtonRef, setRenderButtonRef] = React.useState({ current: null })
  const renderButtonCallbackRef = React.useCallback(node => {
    if (node !== null) {
      setRenderButtonRef({ current: node })
    }
  }, [])

  return (
    <>
      <EditBinding
        renderButtonRef={renderButtonRef}
        binding={props.row.original}
        query={props.query}
      />
      <IconButton
        disableRipple
        disableFocusRipple
        sx={{
          borderRadius: theme => theme.radii.sm,
          padding: 0,
          '&:hover': {
            backgroundColor: theme => theme.palette.cyralColors.grey[100],
          },
        }}
      >
        <MoreVertIcon onClick={props.actionButton.openMenu} />
        <Menu
          anchorEl={props.actionButton.anchorEl}
          open={props.actionButton.menuOpen}
          onClose={props.actionButton.closeMenu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem
            ref={renderButtonCallbackRef}
            onClick={props.actionButton.closeMenu}
            disableRipple
          >
            Edit
          </MenuItem>
          <MenuItem onClick={props.actionButton.openUnbindingModal} disableRipple>
            Delete
          </MenuItem>
        </Menu>
      </IconButton>
    </>
  )
}

export const getColumns = () => {
  const columnHelper = createColumnHelper()

  return [
    columnHelper.accessor('repo.type', {
      header: 'Type',
      cell: info => <ServiceIcon type={info.getValue()} />,
    }),
    columnHelper.accessor('repo.name', {
      header: 'Name',
      cell: info => (
        <MuiLink
          component={Link}
          to={`/repositories/${info.row.original.repo.id}`}
          underline="hover"
        >
          <Typography component="span" variant="h6" sx={{ color: 'primary.main' }}>
            {info.getValue()}
          </Typography>
        </MuiLink>
      ),
    }),
    columnHelper.accessor(row => row, {
      header: 'Ports',
      cell: info => {
        const binding = info.getValue()

        switch (binding.__typename) {
          case 'SingleListenerBinding': {
            const { listener } = binding
            const isMultiplexed = isMultiplexedListener(listener)

            return (
              <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                <Typography component="span" variant="code" sx={{ color: 'text.primary' }}>
                  {listener.port}
                </Typography>
                {isMultiplexed && <Tag>Smart Port</Tag>}
              </Stack>
            )
          }

          case 'S3Binding': {
            const { listenerSet } = binding
            const { proxyListener, browserListener } = listenerSet

            const ports = [proxyListener.port, browserListener?.port].filter(Boolean).join(', ')

            return (
              <Typography component="span" variant="code" sx={{ color: 'text.primary' }}>
                {ports}
              </Typography>
            )
          }

          case 'ClusterBinding': {
            const ports = binding.boundListenersRelationship.edges.map(edge =>
              edge.node.port.toString()
            )

            const displayedPorts = ports.slice(0, 3)
            const overflowPorts = ports.slice(3)

            const hasMissingPorts = binding.repo.numNodes > ports.length

            return (
              <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                <Typography component="span" variant="code" sx={{ color: 'text.primary' }}>
                  {displayedPorts.join(', ')}
                </Typography>
                {overflowPorts.length > 0 && (
                  <Tooltip title={overflowPorts.join(', ')}>
                    <Tag color="grey">{`+${overflowPorts.length} more`}</Tag>
                  </Tooltip>
                )}
                {hasMissingPorts && (
                  <Tooltip title="Allocate additional sidecar ports for this binding.">
                    <ReportProblemIcon color="error" sx={{ cursor: 'pointer' }} />
                  </Tooltip>
                )}
              </Stack>
            )
          }
          default:
            return null
        }
      },
    }),
    columnHelper.accessor('enabled', {
      header: <div style={{ textAlign: 'center' }}>Binding Status</div>,
      cell: info => {
        const isEnabled = info.getValue()
        const variant = isEnabled ? 'success' : 'neutral'
        const text = isEnabled ? 'Enabled' : 'Disabled'
        return <Badge variant={variant}>{text}</Badge>
      },
    }),
    columnHelper.display({
      id: 'actions',
      cell: ActionsCell,
    }),
  ]
}

getColumns.fragments = {
  ...EditBinding.fragments,
}
