import React from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

import IconButton from '@mui/material/IconButton'

import Box from '@mui/material/Box'
import { RepoIcon } from '@jeeves/new-components/Icons'

export const RepoHeader = ({
  children,
  props,
  repoType,
  repoName,
  numMappings,
  mappingsExpanded,
  setMappingsExpanded,
}) => {
  const [open, setOpen] = React.useState(false)

  return (
    <Box>
      <Stack
        direction="row"
        sx={{
          bgcolor: 'cyralColors.grey.100',
          py: 2,
          pl: 4,
          pr: 2,
          justifyContent: 'space-between',
          border: 1,
          borderTop: 0,
          borderColor: 'cyralColors.grey.200',

          '& > *': { borderBottom: 'unset' },
        }}
      >
        <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
          <RepoIcon repoType={repoType} />
          <Typography variant="h4">{repoName}</Typography>
        </Stack>

        <Stack direction="row" spacing={1} sx={{}}>
          <Typography sx={{ alignSelf: 'center' }}>
            {numMappings} {numMappings === 1 ? `field` : `fields`}
          </Typography>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setMappingsExpanded(!mappingsExpanded)}
          >
            {mappingsExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </Stack>
      </Stack>
    </Box>
  )
}
