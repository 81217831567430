import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Tooltip } from '@jeeves/new-components'
import { Box, Menu, MenuItem, Typography } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { usePolicyDetails } from '@jeeves/pages/RepositoryDetail/Tabs/Policies/views/PolicyDetails/contexts/PolicyDetailsContext'
import { DeletePolicyModal } from './DeletePolicyModal'
import { TogglePolicyModal } from './TogglePolicyModal'
import { useRepositoryDetailContext } from '@jeeves/pages/RepositoryDetail/contexts/RepositoryDetailContext'
import { useAuth } from '@jeeves/components/Auth'

const Configure = () => {
  const history = useHistory()
  const { policy } = usePolicyDetails()
  const { repoId } = useRepositoryDetailContext()
  const { hasPermission } = useAuth()

  const [menuEl, setMenuEl] = useState(null)
  const [deletePolicyModalOpen, setDeletePolicyModalOpen] = useState(false)
  const [togglePolicyModalOpen, setTogglePolicyModalOpen] = useState(false)

  const menuOpen = Boolean(menuEl)

  const handleClickMenu = e => {
    setMenuEl(e.currentTarget)
  }

  const handleCloseMenu = e => {
    setMenuEl(null)
  }

  const handleClickToggle = () => {
    setTogglePolicyModalOpen(true)
    handleCloseMenu()
  }

  const handleClickEdit = () => {
    history.push(`/repositories/${repoId}/policies/${policy.id}/edit`)
  }

  const handleClickDelete = () => {
    setDeletePolicyModalOpen(true)
    handleCloseMenu()
  }

  const ConfigureButton = (
    <Button
      onClick={handleClickMenu}
      variant="outlined"
      color="secondary"
      endIcon={<KeyboardArrowDownIcon />}
      disabled={policy.appliedToMultipleRepos}
    >
      Configure
    </Button>
  )

  return (
    <>
      <DeletePolicyModal isOpen={deletePolicyModalOpen} setIsOpen={setDeletePolicyModalOpen} />
      <TogglePolicyModal isOpen={togglePolicyModalOpen} setIsOpen={setTogglePolicyModalOpen} />
      {policy.appliedToMultipleRepos ? (
        <Tooltip
          placement="bottom"
          title={
            <Typography variant="body2">
              This policy applies to multiple repos. Manage this policy via APIs.
            </Typography>
          }
        >
          <Box>{ConfigureButton}</Box>
        </Tooltip>
      ) : (
        ConfigureButton
      )}
      <Menu open={menuOpen} anchorEl={menuEl} onClose={handleCloseMenu}>
        <MenuItem onClick={handleClickToggle} disabled={!hasPermission('policies:update')}>
          {policy?.enabled ? 'Disable' : 'Enable'}
        </MenuItem>
        <MenuItem
          onClick={handleClickEdit}
          disabled={!policy?.parameters || !hasPermission('policies:update')}
        >
          Edit
        </MenuItem>
        <MenuItem onClick={handleClickDelete} disabled={!hasPermission('policies:delete')}>
          Delete
        </MenuItem>
      </Menu>
    </>
  )
}

export default Configure
