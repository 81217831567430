/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { jsx, css } from '@emotion/react'

import { useAuth } from '@jeeves/components/Auth'

import Existing from './Existing'
import IntegrationForm from './IntegrationForm'

import {
  ErrorState,
  ZeroState,
  AddIntegrationButtonUnformatted,
  AddIntegrationButton,
  IntegrationDialogContent,
  IntegrationLoading,
  IntegrationList,
} from '../../primitives'
import logo from './images/duo.png'

import { useConfExtensionInstances } from '@jeeves/hooks/useConfExtensions'

const Duo = () => {
  const {
    getConfExtensionInstancesResponse: { data, loading, error },
  } = useConfExtensionInstances({
    getConfExtensionInstancesOptions: {
      variables: { purpose: 'authorization', templateType: 'duoMfa' },
    },
  })
  const { hasPermission } = useAuth()
  const [adding, setAdding] = useState(false)
  const integrations = data?.confExtensionInstances || []

  return (
    <>
      <IntegrationDialogContent>
        <IntegrationList>
          {loading ? (
            <IntegrationLoading />
          ) : error ? (
            <ErrorState />
          ) : integrations.length === 0 ? (
            <ZeroState />
          ) : (
            integrations.map(integration => (
              <Existing integration={integration} key={integration.id} />
            ))
          )}
          {adding && <IntegrationForm creating={true} onClose={() => setAdding(false)} />}
        </IntegrationList>
        <div
          css={() => css`
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
          `}
        >
          <AddIntegrationButtonUnformatted
            isOpen={adding}
            setOpen={setAdding}
            disabled={!hasPermission('integrations:create') || adding}
          />
        </div>
      </IntegrationDialogContent>
    </>
  )
}

Duo.metadata = {
  name: 'Duo',
  summary: 'Setup  MFA for database authentication.',
  enabled: true,
  category: 'MFA',
  slug: 'duo',
  logo,
}

export default Duo
