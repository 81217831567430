import styled from '@emotion/styled'
import { Typography as MuiTypography } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'

export const Typography = styled(MuiTypography)`
  margin-top: ${({ theme }) => theme.spacing[3]};
  margin-bottom: ${({ theme }) => theme.spacing[3]};
  font-size: 1.35rem;
`

export const Title = styled(MuiTypography)`
  margin-top: ${({ theme }) => theme.spacing[3]};
  margin-bottom: ${({ theme }) => theme.spacing[3]};
  font-size: 42px;
  margin-bottom: 24px;
  text-align: center;
`

export const Subtitle = styled(MuiTypography)`
  margin-top: ${({ theme }) => theme.spacing[3]};
  margin-bottom: ${({ theme }) => theme.spacing[3]};
  font-size: 24px;
  margin-bottom: 20px;
  color: ${grey[800]};
  margin-bottom: 12px;
  text-align: center;
`

export const CenteredText = styled(MuiTypography)`
  margin-top: ${({ theme }) => theme.spacing[3]};
  margin-bottom: ${({ theme }) => theme.spacing[3]};
  font-size: 1.35rem;
  text-align: center;
`

export * from './Button'
export * from './CircleProgress'
export * from './CodeSnippet'
export * from './NextButton'
export * from './SandboxList'
export * from './SandboxLayout'
export * from './SetupLayout'
export * from './Tooltip'
export * from './ViewLogsButton'
export * from './SkipLink'
export { default as SimpleDashboard } from './SimpleDashboard.style'
