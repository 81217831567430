import React, { useState } from 'react'
import ExpressClient from '@jeeves/clients/express'
import { useAuth, usePopup } from '@jeeves/hooks'
import { get as lodashGet } from 'lodash'
import { useQuery, useMutation, gql } from '@apollo/client'

const FETCH_OKTA_INTEGRATIONS = gql`
  query OktaQuery($identityProviderType: SAMLIdentityProviderSupportedType) {
    samlIntegrations(identityProviderType: $identityProviderType) {
      identityProviderType
      id
      displayName
      singleSignOnServiceURL
      enabled
      scimBaseURL
      scimIntegration {
        IDPID
        scimBaseURL
        clientId
        enabled
        authMode
        authSecretExpiry
      }
      singleLogoutServiceURL
      signingCertificate
      disableValidateSignature
      backChannelSupported
      disableWantAuthnRequestsSigned
      disableWantAssertionsSigned
      wantAssertionsEncrypted
      disableForceAuthentication
      allowedClockSkew
      ldapGroupAttribute
      internalID
      nameIDPolicyFormat
    }
  }
`

const CREATE_SCIM_INTEGRATION = gql`
  mutation CreateSCIMIntegration($scimIntegration: CreateSCIMIntegrationInput!) {
    createSCIMIntegration(scimIntegration: $scimIntegration) {
      IDPID
      scimBaseURL
      clientId
      enabled
      authMode
      authSecret
      authSecretExpiry
    }
  }
`

const UPDATE_SCIM_INTEGRATION_STATUS = gql`
  mutation UpdateSCIMIntegrationStatus($IDPID: String!, $enabled: Boolean!) {
    updateSCIMIntegrationStatus(IDPID: $IDPID, enabled: $enabled) {
      message
    }
  }
`

const GENERATE_SCIM_INTEGRATION_CREDS = gql`
  mutation GenerateSCIMIntegrationCreds_Okta($IDPID: String!) {
    generateSCIMIntegrationCreds(IDPID: $IDPID) {
      authSecret
      authSecretExpiry
    }
  }
`

const useOkta = ({ refresh }) => {
  const { setPopup, popupTypes } = usePopup()
  const { getTokenSilently, user } = useAuth()
  const ec = new ExpressClient(getTokenSilently)

  const {
    loading: integrationsLoading,
    error: integrationsError,
    data: integrationsData,
    refetch: updateIntegrations,
  } = useQuery(FETCH_OKTA_INTEGRATIONS, { variables: { identityProviderType: 'okta' } })
  const integrations = integrationsData ? integrationsData.samlIntegrations : undefined
  const [draftIntegrations, setDraftIntegrations] = useState([])

  const handleError = e => {
    const popupMessage = lodashGet(e, 'response.data.error.message', '')
    setPopup(popupTypes.ERROR, popupMessage)
    throw e
  }

  const addDraftIntegration = async draftConfig => {
    try {
      const integration = await ec
        .post('/integrations/saml-drafts', draftConfig)
        .then(res => res.data)
      refresh()
      return integration
    } catch (e) {
      handleError(e)
    }
  }

  const addIntegration = async samlConfig => {
    try {
      const integration = await ec
        .post('/integrations/saml/fromMetadata', samlConfig)
        .then(res => res.data)
      refresh()
      return integration
    } catch (e) {
      handleError(e)
    }
  }

  const putIntegration = async samlConfig => {
    try {
      const integration = await ec.put('/integrations/saml', samlConfig).then(res => res.data)
      refresh()
      return integration
    } catch (e) {
      handleError(e)
    }
  }

  const deleteIntegration = async alias => {
    try {
      const integration = await ec.delete(`/integrations/saml/${alias}`).then(res => res.data)
      refresh()
      return integration
    } catch (e) {
      handleError(e)
    }
  }

  const deleteDraftIntegration = async alias => {
    try {
      const integration = await ec
        .delete(`/integrations/saml-drafts/okta/${alias}`)
        .then(res => res.data)
      refresh()
      return integration
    } catch (e) {
      handleError(e)
    }
  }

  const updateDraftIntegrations = async () => {
    try {
      const drafts = await ec.get('/integrations/saml-drafts/okta').then(res => res.data)
      setDraftIntegrations(drafts)
    } catch (e) {
      handleError(e)
    }
  }

  const [
    createSCIMIntegration,
    {
      data: createSCIMIntegrationData,
      loading: createSCIMIntegrationLoading,
      error: createSCIMIntegrationError,
    },
  ] = useMutation(CREATE_SCIM_INTEGRATION)

  const [
    updateSCIMIntegrationStatus,
    {
      data: updateSCIMIntegrationStatusData,
      loading: updateSCIMIntegrationStatusLoading,
      error: updateSCIMIntegrationStatusError,
    },
  ] = useMutation(UPDATE_SCIM_INTEGRATION_STATUS)

  const [
    generateSCIMIntegrationCreds,
    {
      data: generateSCIMIntegrationCredsData,
      loading: generateSCIMIntegrationCredsLoading,
      error: generateSCIMIntegrationCredsError,
    },
  ] = useMutation(GENERATE_SCIM_INTEGRATION_CREDS)

  return {
    createSCIMIntegrationData,
    updateIntegrations,
    integrations,
    draftIntegrations,
    addIntegration,
    addDraftIntegration,
    putIntegration,
    deleteIntegration,
    deleteDraftIntegration,
    updateDraftIntegrations,
    createSCIMIntegration,
    updateSCIMIntegrationStatus,
    generateSCIMIntegrationCreds,
  }
}

export default useOkta
