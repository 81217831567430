import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const PagerdutyLetterIcon = props => {
  return (
    <SvgIcon {...props} preserveAspectRatio="xMidYMid" viewBox="0 0 256 372">
      <path
        d="M54.554 272.557v98.919H0v-98.919h54.554ZM109.047.001c46.745.051 67.005 2.703 95.795 18.254C236.471 35.237 256 68.988 256 111.018c0 39.058-15.92 72.81-46.488 92.975-28.02 18.68-59.86 21.228-102.315 21.228H0V0Zm8.739 47.753-1.673.007-61.56.425v129.91h65.168c45.639 0 80.451-18.255 80.451-66.44 0-44.79-28.02-64.32-84.06-63.895Z"
        fill="#06AC38"
      />
    </SvgIcon>
  )
}

export default PagerdutyLetterIcon
