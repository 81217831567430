import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const MongoDBIcon = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 48 48">
      <path
        fill="#5d4037"
        d="M 45.681 15.973 C 45.681 40.562 23.989 48 23.989 48 C 23.989 48 2.292 40.562 2.292 15.973 C 2.292 12.974 2.534 10.452 2.776 8.414 C 3.136 5.415 5.184 3.016 8.078 2.298 C 11.815 1.217 17.721 0.018 23.989 0.018 C 30.255 0.018 36.16 1.217 40.017 2.298 C 42.911 3.016 44.958 5.536 45.319 8.414 C 45.442 10.452 45.681 13.092 45.681 15.973 Z"
      />
      <path
        fill="#4caf50"
        d="M 23.989 3.616 C 29.893 3.616 35.438 4.817 39.054 5.776 C 40.5 6.137 41.464 7.335 41.705 8.896 C 41.944 11.173 42.066 13.572 42.066 15.973 C 42.066 34.685 28.205 42.242 23.989 44.041 C 19.768 42.123 5.908 34.564 5.908 15.973 C 5.908 13.572 6.029 11.173 6.27 8.896 C 6.39 7.335 7.474 6.137 8.922 5.776 C 12.537 4.817 18.081 3.616 23.989 3.616 M 23.989 0.018 C 17.721 0.018 11.815 1.217 7.958 2.298 C 5.184 3.016 3.015 5.536 2.776 8.414 C 2.534 10.452 2.292 13.092 2.292 15.973 C 2.292 40.562 23.989 48 23.989 48 C 23.989 48 45.681 40.562 45.681 15.973 C 45.681 12.974 45.442 10.452 45.201 8.414 C 44.839 5.415 42.791 3.016 39.897 2.298 C 36.16 1.217 30.255 0.018 23.989 0.018 L 23.989 0.018 Z"
      />
      <path fill="#dcedc8" d="M 22.783 28.806 L 25.192 28.806 L 25.192 38.404 L 22.783 38.404 Z" />
      <path
        fill="#4caf50"
        d="M 23.989 7.216 C 23.989 7.216 16.754 13.212 16.754 22.811 C 16.754 29.047 20.732 33.005 22.783 34.805 L 23.989 31.208 L 25.192 34.805 C 27.24 33.005 31.218 29.047 31.218 22.811 C 31.218 13.212 23.989 7.216 23.989 7.216 Z"
      />
      <path
        fill="#81c784"
        d="M 23.989 7.216 C 23.989 7.216 16.754 13.212 16.754 22.811 C 16.754 29.047 20.732 33.005 22.783 34.805 L 23.989 31.208 L 23.989 7.216 Z"
      />
    </SvgIcon>
  )
}

export default MongoDBIcon
