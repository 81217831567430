/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import styled from '@emotion/styled'
import { useState, Fragment } from 'react'
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableRow,
  withStyles,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from '@material-ui/core'
import { Delete, Group, Person, VpnKey } from '@material-ui/icons'
import { TableHead, TablePagination } from '@jeeves/components/Table'
import iamLogo from './iam2.svg'
import pagerdutyIcon from './PagerDuty.svg'
import { Button, Dialog } from '@jeeves/components/Primitives'
import { DeleteDialog } from './DeleteDialog'

import { ServiceIcon } from '@jeeves/components/ServiceIcon'
import { EllipsisTypography } from '@jeeves/components/Primitives'

import { parse, end, toSeconds, pattern } from 'iso8601-duration'
import humanizeDuration from 'humanize-duration'
const HD_OPTIONS = {
  round: true,
  largest: 1,
}

const ZeroStateCell = styled(TableCell)`
  height: 100px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
`

// eslint-disable-next-line
const iamIcon = () => {
  return (
    <div
      css={css`
        max-width: 25px;
        max-height: 14px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        vertical-align: center;
        position: relative;
        top: 5px;
        margin-right: 10px;

        img {
          width: 20%;
        }
      `}
    >
      <img src={iamLogo} />
    </div>
  )
}

const COLUMNS = [
  // {
  //   id: 'provider',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Identity Provider',
  // },

  { id: 'identityType', numeric: false, disablePadding: true, label: '' },
  { id: 'identityName', numeric: false, disablePadding: false, label: 'Identity' },
  { id: 'auth', numeric: false, disablePadding: true, label: '' },
  {
    id: 'repoAccount',
    numeric: false,
    disablePadding: false,
    label: 'Local Account',
  },
  {
    id: 'authorizationPolicy',
    numeric: false,
    disablePadding: false,
    label: 'Access Condition',
  },
  { id: 'created', numeric: false, disablePadding: false, label: 'Created' },
  {
    id: 'remainingTotal',
    numeric: false,
    disablePadding: false,
    label: 'Expiration',
  },

  { id: 'actions', numeric: false, disablePadding: true, label: '' },
]

const styles = theme => ({
  deleteButton: {
    color: theme.palette.error.main,
    border: `1px solid ${theme.palette.error.main}`,
  },
  label: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: 'rgba(0, 0, 0, 0.4)',
  },
})

const IdentityMapRow = withStyles(styles)(({ identityMap, handleDelete, isDemo }) => {
  const [deleteOpen, setDeleteOpen] = useState(false)
  console.log(identityMap)

  return (
    <Fragment>
      <DeleteDialog
        open={deleteOpen}
        handleClose={() => setDeleteOpen(false)}
        handleDelete={handleDelete}
        identityName={identityMap.identityName}
        identityType={identityMap.identityType}
        repoAccount={identityMap.repoAccount}
        repoAccountUUID={identityMap.repoAccountUUID}
        policyCategory={identityMap.accessPolicyInstanceKey?.category}
        policyID={identityMap.accessPolicyInstanceKey?.id}
      ></DeleteDialog>
      <TableRow>
        {/* <TableCell>{identityMap.provider}</TableCell> */}
        <TableCell align="right" css={{ paddingRight: 0 }}>
          {identityMap.identityType === 'group' ? <Group /> : <Person />}
        </TableCell>
        <TableCell>
          <EllipsisTypography width="200px">{identityMap.identityName}</EllipsisTypography>
        </TableCell>

        <TableCell
          css={() => css`
            padding: 0;
            text-align: center;
          `}
        >
          {/* {identityMap.auth === 'IAM' ? (
            <img
              css={() => css`
                padding: 0;
                max-width: 35px;
                max-height: 29px;
              `}
              src={iamLogo}
            />
          ) : (
            <VpnKey />
          )} */}
        </TableCell>
        <TableCell>
          <EllipsisTypography width="200px">{identityMap.repoAccount}</EllipsisTypography>
        </TableCell>
        <TableCell>
          {identityMap.authorizationPolicyInstance ? (
            <div css={{ display: 'flex', alignItems: 'center' }}>
              <ServiceIcon
                type={identityMap?.authorizationPolicyInstance?.templateType?.toLowerCase() || ''}
              ></ServiceIcon>
              <EllipsisTypography width="100px">
                {identityMap.authorizationPolicyInstance.name}
              </EllipsisTypography>
            </div>
          ) : (
            '\u2013'
          )}
        </TableCell>
        <TableCell>
          <EllipsisTypography width="140px">
            {`${humanizeDuration(Date.now() - new Date(identityMap.modified), HD_OPTIONS)} ago`}
          </EllipsisTypography>
        </TableCell>
        {/* TO DO: Think of better way to do this */}
        <TableCell
          css={t => ({
            color:
              identityMap.accessDuration !== 'P0D' &&
              end(parse(identityMap.accessDuration), new Date(identityMap.modified)) < Date.now()
                ? t.palette.error.main
                : 'black',
          })}
        >
          <EllipsisTypography width="140px">
            {identityMap.accessDuration === 'P0D'
              ? '\u2013'
              : end(parse(identityMap.accessDuration), new Date(identityMap.modified)) < Date.now()
              ? `Expired ${humanizeDuration(
                  Date.now() -
                    end(parse(identityMap.accessDuration), new Date(identityMap.modified)),
                  HD_OPTIONS
                )} ago`
              : `${humanizeDuration(
                  end(parse(identityMap.accessDuration), new Date(identityMap.modified)) -
                    Date.now(),
                  HD_OPTIONS
                )} remaining`}
            {/* : `${humanizeDuration(
                          end(parse(identityMap.accessDuration), new Date(identityMap.modified)) -
                            Date.now(),
                          HD_OPTIONS
                        )} / ${humanizeDuration(
                          toSeconds(parse(identityMap.accessDuration)) * 1000,
                          HD_OPTIONS
                        )}`} */}
          </EllipsisTypography>
        </TableCell>

        <TableCell>
          <IconButton onClick={() => setDeleteOpen(true)}>
            <Delete></Delete>
          </IconButton>
        </TableCell>
      </TableRow>
    </Fragment>
  )
})

export const IdentityTable = withStyles(styles)(
  ({ identityMaps, updateIdentityMap, deleteIdentityMap, classes, isDemo }) => {
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [page, setPage] = useState(0)

    return (
      <Table aria-labelledby="tableTitle">
        <TableHead columns={COLUMNS} />
        <TableBody>
          {identityMaps.length === 0 && (
            <TableRow>
              <ZeroStateCell colSpan={COLUMNS.length}>None added yet</ZeroStateCell>
            </TableRow>
          )}
          {identityMaps.map(identityMap => (
            <IdentityMapRow
              isDemo={isDemo}
              identityMap={identityMap}
              handleDelete={deleteIdentityMap}
            ></IdentityMapRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              count={identityMaps.length}
              colSpan={7}
              page={0}
              rowsPerPage={rowsPerPage}
              onChangePage={e => setPage(e.target.value)}
              onChangeRowsPerPage={e => setRowsPerPage(e.target.value)}
              page={page}
            />
          </TableRow>
        </TableFooter>
      </Table>
    )
  }
)
