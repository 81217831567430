/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { Redirect } from 'react-router-dom'

import { useAppConfig, useAuth } from '@jeeves/hooks'
import useTotalsHook from './useTotalsHook'

const Home = () => {
  const { config } = useAppConfig()
  const { isDbUser } = useAuth()
  const { wizardComplete, isRegistrant } = useTotalsHook()

  if (!config || wizardComplete === null || isRegistrant === null) {
    return null
  }

  if (!wizardComplete && isRegistrant) {
    return <Redirect to="/setup" />
  }

  if (isDbUser) {
    return <Redirect to="/access-portal" />
  }

  if (config?.hasSandbox) {
    return <Redirect to="/sandbox" />
  }

  return <Redirect to="/overview" />
}

export default Home
