import { useCallback, useEffect } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'

import { ApolloError, useMutation } from '@apollo/client'

import { FragmentType, graphql, useFragment } from '@jeeves/graphql'
import { useToast } from '@jeeves/new-components'
import { getGraphQLErrorMessage } from '@jeeves/utils/helpers'

import { AwsIamFormSchema, awsIamFormSchema, getMutationInput } from './helpers'

const CREATE_AWS_IAM_INTEGRATION = graphql(`
  mutation CreateAwsIamIntegration($input: CreateAwsIamIntegrationInput!) {
    createAwsIamIntegration(input: $input) {
      awsIamIntegration {
        id
        name
        iamRoleARNs
        description
      }
    }
  }
`)

const UPDATE_AWS_IAM_INTEGRATION = graphql(`
  mutation UpdateAwsIamIntegration(
    $updateAwsIamIntegrationId: ID!
    $input: UpdateAwsIamIntegrationInput!
  ) {
    updateAwsIamIntegration(id: $updateAwsIamIntegrationId, input: $input) {
      awsIamIntegration {
        id
        name
        iamRoleARNs
        description
      }
    }
  }
`)

const useUpsertAwsIamIntegrationModal_awsIamIntegrationFragment = graphql(`
  fragment useUpsertAwsIamIntegrationModal_awsIamIntegration on AwsIamIntegration {
    id
    name
    iamRoleARNs
    description
  }
`)

interface useUpsertAwsIamIntegrationModalProps {
  integration?: FragmentType<
    typeof useUpsertAwsIamIntegrationModal_awsIamIntegrationFragment
  > | null
  onClose: () => void
}

export const useUpsertAwsIamIntegrationModal = ({
  integration: integrationProp,
  onClose,
}: useUpsertAwsIamIntegrationModalProps) => {
  const integration = useFragment(
    useUpsertAwsIamIntegrationModal_awsIamIntegrationFragment,
    integrationProp
  )

  const isEditing = Boolean(integration)

  const { toast } = useToast()

  const onError = (error: ApolloError) => {
    const fallbackMessage = isEditing
      ? 'An error occurred while saving changes to your AWS IAM integration'
      : 'An error occurred while creating your AWS IAM integration.'

    toast({
      variant: 'error',
      description: getGraphQLErrorMessage(error) || fallbackMessage,
    })
  }

  const [createAwsIamIntegration, { loading: loadingCreateAwsIamIntegration }] = useMutation(
    CREATE_AWS_IAM_INTEGRATION,
    {
      onError,
      onCompleted: () => {
        onClose()
        reset()
      },
      refetchQueries: ['AwsIamIntegrations'],
    }
  )

  const [updateAwsIamIntegration, { loading: loadingUpdateAwsIamIntegration }] = useMutation(
    UPDATE_AWS_IAM_INTEGRATION,
    { onError, onCompleted: onClose }
  )

  const getDefaultValues = useCallback(
    () => ({
      name: integration?.name ?? '',
      iamRoles: integration?.iamRoleARNs.map(iamRole => ({
        value: iamRole,
      })) ?? [{ value: '' }],
      description: integration?.description ?? '',
    }),
    [integration]
  )

  const methods = useForm<AwsIamFormSchema>({
    defaultValues: getDefaultValues(),
    resolver: zodResolver(awsIamFormSchema),
  })

  const { reset } = methods

  useEffect(() => {
    reset(getDefaultValues())
  }, [getDefaultValues, reset])

  const onSubmit = async (formValues: AwsIamFormSchema) => {
    if (isEditing) {
      await updateAwsIamIntegration({
        variables: {
          updateAwsIamIntegrationId: integration!.id,
          input: getMutationInput(formValues),
        },
      })
    } else {
      await createAwsIamIntegration({
        variables: {
          input: getMutationInput(formValues),
        },
      })
    }
  }

  return {
    isEditing,
    loading: loadingCreateAwsIamIntegration || loadingUpdateAwsIamIntegration,
    methods,
    onSubmit: methods.handleSubmit(onSubmit),
  }
}
