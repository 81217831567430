/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import React from 'react'
import { IconButton, Tooltip, Typography } from '@material-ui/core/'
import EditIcon from '@material-ui/icons/Edit'
import { useAuth } from '@jeeves/components/Auth'
import { FlexGrow, Button } from '@jeeves/components/Primitives'
import { Tag } from '@jeeves/components/Tag'

import {
  DeleteIntegrationButton,
  IntegrationHeader,
  IntegrationTitle,
  Label,
  LabelValuePair,
  Value,
} from '../../../primitives'

import { gql, useMutation } from '@apollo/client'
import { FETCH_INTEGRATIONS } from '../GenericSAML'

const DELETE_INTEGRATION = gql`
  mutation DeleteGenericSAMLIntegration_Draft($deleteGenericSamlIntegrationId: ID!) {
    deleteGenericSAMLIntegration(id: $deleteGenericSamlIntegrationId) {
      deletedGenericSAMLIntegrationId
    }
  }
`

export default ({
  setEditing,
  onDelete,
  integration,
  setOpenIntegration,
  setIsCreatingIntegration,
}) => {
  const { hasPermission } = useAuth()

  const [deleteIntegration, { data, loading, error }] = useMutation(DELETE_INTEGRATION, {
    refetchQueries: [FETCH_INTEGRATIONS],
  })

  const handleOnDelete = async () => {
    try {
      await deleteIntegration({ variables: { deleteGenericSamlIntegrationId: integration.id } })
    } catch (e) {}
  }

  return (
    <React.Fragment>
      <IntegrationHeader css={{ alignItems: 'center' }}>
        <IntegrationTitle component="h2" css={{ marginBottom: '0' }}>
          {integration?.displayName ?? ''}
        </IntegrationTitle>
        <Tag
          css={{ height: '25px', marginTop: '3px', marginLeft: '24px', backgroundColor: '#C2D4D8' }}
        >
          Incomplete
        </Tag>
        <FlexGrow />

        <Button
          onClick={() => {
            setEditing(true)
          }}
          disabled={!hasPermission('integrations:update')}
          variant="contained"
          css={{ marginRight: '12px' }}
        >
          Continue setup
        </Button>
        <DeleteIntegrationButton
          onDelete={handleOnDelete}
          disabled={!hasPermission('integrations:delete')}
        />
      </IntegrationHeader>
    </React.Fragment>
  )
}
