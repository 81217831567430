import Crypto from 'crypto-js'
import { Typography, Stack } from '@mui/material'

const TAG_COLORS = ['green', 'yellow', 'red', 'grey', 'orange', 'purple', 'pink', 'primary']

const getHashValue = (tag: string) => {
  return Crypto.MD5(tag.toLowerCase()).toString(Crypto.enc.Hex).slice(-4)
}

interface Props {
  text: string
  color?: string
}

const getTagColor = (tag: string) => {
  const HEX_BASE = 16
  const intValue = parseInt(getHashValue(tag), HEX_BASE)
  const index = intValue % TAG_COLORS.length

  return TAG_COLORS[index]
}

const Tag = ({ text, color }: Props) => {
  const tagColor = color ?? getTagColor(String(text))

  return (
    <Stack
      spacing={1}
      direction="row"
      sx={{
        backgroundColor: `cyralColors.${tagColor}.100`,
        color: `cyralColors.${tagColor}.500`,
        padding: theme => theme.spacing(0.25, 1),
        borderRadius: theme => theme.radii.sm,
        alignItems: 'center',
      }}
    >
      <Typography variant="tag" component="span" sx={{ textOverflow: 'ellipsis' }}>
        {text.toUpperCase()}
      </Typography>
    </Stack>
  )
}

export default Tag
