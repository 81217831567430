import * as React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { formatISODuration } from 'date-fns'
import { parse } from 'iso8601-duration'
import { useMutation, gql } from '@apollo/client'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  InputLabel,
  Input,
  Button,
  Dialog,
  Tag,
} from '@jeeves/new-components'

import { useRepositoryDetailContext } from '@jeeves/pages/RepositoryDetail/contexts/RepositoryDetailContext'

const UPDATE_AUTO_APPROVAL_CONFIG = gql`
  mutation UpdateAutoApprovalConfig(
    $repoId: ID!
    $userAccountId: ID!
    $input: UpdateAutoApprovalConfigInput!
  ) {
    updateAutoApprovalConfig(repoId: $repoId, userAccountId: $userAccountId, input: $input) {
      userAccount {
        id
        autoApproval {
          enabled
          duration
        }
      }
    }
  }
`
const ConfigureAutoApprovalsDialog = ({ open, onClose, userAccounts }) => {
  const [expandedAccordion, setExpandedAccordion] = React.useState()

  const handleAccordionChange = panel => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? panel : false)
  }

  return (
    <Dialog onClose={onClose} open={open} fullWidth>
      <DialogTitle variant="h3">Configure Auto Approvals</DialogTitle>

      <DialogContent sx={{ mt: 1 }}>
        <Stack spacing={3}>
          <Alert
            severity="error"
            sx={{
              color: 'cyralColors.yellow.500',
              backgroundColor: 'cyralColors.yellow.100',

              '& .MuiAlert-icon': {
                color: 'inherit',
              },
            }}
          >
            <Typography variant="body2" sx={{ color: 'cyralColors.yellow.500' }}>
              Approval requests containing sensitive fields will not be auto-approved.
            </Typography>
          </Alert>

          <Box>
            {userAccounts.map(account => {
              const autoApprovalEnabled = account.autoApproval.enabled

              return (
                <Accordion
                  key={account.id}
                  expanded={expandedAccordion === account.id}
                  onChange={handleAccordionChange(account.id)}
                >
                  <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', flex: 1 }}>
                      <Typography variant="h6" sx={{ color: 'text.primary' }}>
                        {account.name}
                      </Typography>

                      <Tag color={autoApprovalEnabled ? 'green' : 'grey'} sx={{ mr: 3 }}>
                        {autoApprovalEnabled ? 'Enabled' : 'Disabled'}
                      </Tag>
                    </Box>
                  </AccordionSummary>

                  <AccordionDetails>
                    <AutoApproveForm
                      open={open}
                      closeAccordion={handleAccordionChange(account.id)}
                      userAccount={account}
                    />
                  </AccordionDetails>
                </Accordion>
              )
            })}
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}

const AutoApproveForm = ({ open, closeAccordion, userAccount }) => {
  const { repoId } = useRepositoryDetailContext()
  const [updateAutoApprovalConfig, { data, loading, error }] = useMutation(
    UPDATE_AUTO_APPROVAL_CONFIG
  )

  const parsedDuration =
    userAccount.autoApproval.duration && parse(userAccount.autoApproval.duration)

  const {
    register,
    control,
    handleSubmit,
    getValues,
    reset,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      enabled: userAccount.autoApproval.enabled,
      duration: {
        days: parsedDuration?.days ?? '',
        hours: parsedDuration?.hours ?? '',
        minutes: parsedDuration?.minutes ?? '',
      },
    },
  })

  // This useEffect is triggered when the user clicks outside of the modal, causing it to close.
  React.useEffect(() => {
    if (!open) {
      reset()
    }
  }, [open, reset])

  React.useEffect(() => {
    if (isSubmitSuccessful) {
      const values = getValues()

      reset(values)
      closeAccordion()
    }
  }, [isSubmitSuccessful, getValues, reset, closeAccordion])

  const onSubmit = async ({ enabled, duration }) => {
    const iso8601Duration = formatISODuration(duration)

    try {
      await updateAutoApprovalConfig({
        variables: {
          repoId,
          userAccountId: userAccount.id,
          input: {
            enabled,
            duration: iso8601Duration,
          },
        },
      })
    } catch (error) {}
  }

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={5}>
        <Stack spacing={4}>
          <Controller
            name="enabled"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Switch onChange={e => field.onChange(e.target.checked)} checked={field.value} />
                }
                label="Automatically approve access requests if the duration is less than or equal to:"
              />
            )}
          />

          <Stack direction="row" spacing={1}>
            <FormControl variant="standard" sx={{ flexGrow: 1 }}>
              <InputLabel
                // id="data-repository-select-label"
                htmlFor="duration.days"
              >
                Days
              </InputLabel>
              <Input
                id="duration.days"
                type="number"
                inputProps={{
                  ...register('duration.days', {
                    required: true,
                    min: 0,
                  }),
                  min: 0,
                }}
              />
            </FormControl>

            <FormControl variant="standard" sx={{ flexGrow: 1 }}>
              <InputLabel
              // id="data-repository-select-label"
              // htmlFor="data-repository-select"
              >
                Hours
              </InputLabel>
              <Input
                type="number"
                inputProps={{
                  ...register('duration.hours', {
                    required: true,
                    min: 0,
                    max: 23,
                  }),
                  min: 0,
                  max: 23,
                }}
              />
            </FormControl>

            <FormControl variant="standard" sx={{ flexGrow: 1 }}>
              <InputLabel
              // id="data-repository-select-label"
              // htmlFor="data-repository-select"
              >
                Minutes
              </InputLabel>
              <Input
                type="number"
                inputProps={{
                  ...register('duration.minutes', {
                    required: true,
                    min: 0,
                    max: 59,
                  }),
                  min: 0,
                  max: 59,
                }}
              />
            </FormControl>
          </Stack>
        </Stack>

        <Stack direction="row" spacing={2} sx={{ justifyContent: 'flex-end' }}>
          <Button
            variant="text"
            onClick={() => {
              closeAccordion()
              reset()
            }}
          >
            Cancel
          </Button>

          <Button type="submit" loading={isSubmitting}>
            Save
          </Button>
        </Stack>
      </Stack>
    </Box>
  )
}

export default ConfigureAutoApprovalsDialog
