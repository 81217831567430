import { Stack, Typography } from '@mui/material'

import { ReactComponent as PlaygroundDiagram } from './PlaygroundDiagram.svg'
import SetupWizardStep3Number from './SetupWizardStep3Number'

const services = [
  'PostgreSQL DB with test data',
  'ELK-based log tool',
  'An IdP seeded with your account',
  'A database proxy (called Cyral Sidecar)',
]

const Step3 = () => {
  return (
    <Stack spacing={2} sx={{ alignItems: 'center', justifyContent: 'flex-start' }}>
      <Typography variant="h2" sx={{ color: 'text.primary' }}>
        This Free Trial Comes with a Playground
      </Typography>

      <Stack spacing={4} sx={{ alignItems: 'center' }}>
        <PlaygroundDiagram />

        <Stack spacing={2}>
          <Typography sx={{ color: 'text.primary', fontSize: '22px', fontWeight: 600 }}>
            Your playground contains the following:
          </Typography>

          <Stack spacing={1} sx={{ paddingLeft: 2 }}>
            {services.map((service, index) => (
              <Stack key={service} direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                <SetupWizardStep3Number number={index + 1} />

                <Typography
                  sx={{
                    color: 'text.primary',
                    fontSize: '1.125rem',
                    fontWeight: 400,
                    lineHeight: '28.8px',
                  }}
                >
                  {service}
                </Typography>
              </Stack>
            ))}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default Step3
