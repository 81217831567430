import * as React from 'react'
import { Box, Stack } from '@mui/material'
import {
  Toast,
  ToastDescription,
  ToastDetails,
  ToastClose,
  ToastViewport,
  ToastDescriptionProps,
} from './wrappers'

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded'
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'

import { useToast } from './useToast'

import { Provider as ToastProvider } from '@radix-ui/react-toast'

const variantIcons = {
  success: <CheckCircleOutlineRoundedIcon sx={{ color: 'cyralColors.green.300' }} />,
  error: <WarningAmberRoundedIcon sx={{ color: 'error.main' }} />,
  info: <InfoOutlinedIcon sx={{ color: 'primary.main' }} />,
}

const getDuration = (message: ToastDescriptionProps['description']) => {
  const defaultDuration = 7000

  if (typeof message === 'string') {
    return Math.min(Math.max(message.length * 50, 2000), defaultDuration)
  }

  return defaultDuration
}

export function Toaster() {
  const { toasts } = useToast()

  return (
    <ToastProvider>
      {toasts.map(function ({ id, title, description, details, ...props }) {
        const icon = variantIcons[props.variant]

        return (
          <Toast key={id} duration={getDuration(description)} {...props}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
              }}
            >
              <Stack spacing={1} direction="row">
                {icon}
                <Stack spacing={1}>
                  <ToastDescription description={description} />
                  {details && <ToastDetails details={details} />}
                </Stack>
              </Stack>
              <ToastClose />
            </Box>
          </Toast>
        )
      })}
      <ToastViewport />
    </ToastProvider>
  )
}
