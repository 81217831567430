import ConnectionInfoFields, {
  Host,
  Port,
  Username,
  Password,
  Database,
  INPUT_ADDON_WIDTH,
} from './ConnectionInfoFields'

export default ConnectionInfoFields
export { Host, Port, Username, Password, Database, INPUT_ADDON_WIDTH }
