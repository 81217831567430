type HEX = `#${string}`
type ColorPalette = {
  100: HEX
  200: HEX
  300: HEX
  400: HEX
  500: HEX
}

export const white: HEX = '#FFF'
export const black: HEX = '#000'

export const primary: ColorPalette = {
  100: '#E6F6FF',
  200: '#6387EE',
  300: '#26459E',
  400: '#263A73',
  500: '#061748',
}

export const grey: ColorPalette = {
  100: '#F0F2F5',
  200: '#CBD2D9',
  300: '#9AA5B1',
  400: '#616E7C',
  500: '#1F2933',
}

export const red: ColorPalette = {
  100: '#FFBDBD',
  200: '#FF9B9B',
  300: '#EF4E4E',
  400: '#CF1124',
  500: '#AB091E',
}

export const yellow: ColorPalette = {
  100: '#FFF3C4',
  200: '#FCE588',
  300: '#F7C948',
  400: '#F0B429',
  500: '#B44D12',
}

export const green: ColorPalette = {
  100: '#C1F2C7',
  200: '#91E697',
  300: '#31B237',
  400: '#0F8613',
  500: '#07600E',
}

export const orange: ColorPalette = {
  100: '#FFD0B5',
  200: '#FFB088',
  300: '#F9703E',
  400: '#DE3A11',
  500: '#C52707',
}

export const purple: ColorPalette = {
  100: '#DAC4FF',
  200: '#B990FF',
  300: '#9446ED',
  400: '#7A0ECC',
  500: '#580A94',
}

export const pink: ColorPalette = {
  100: '#FFB8D2',
  200: '#FF8CBA',
  300: '#E8368F',
  400: '#BC0A6F',
  500: '#870557',
}
