import { Box, Stack, Typography } from '@mui/material'

import { ReactComponent as DatabaseProxyIcon } from './DatabaseProxyIcon.svg'
import { ReactComponent as LoggingToolIcon } from './LoggingToolIcon.svg'
import { ReactComponent as PostgresDatabaseIcon } from './PostgresDatabaseIcon.svg'

import SetupWizardStep4Card from './SetupWizardStep4Card'

const Step4 = () => {
  return (
    <Stack spacing={4} sx={{ alignItems: 'center', justifyContent: 'flex-start' }}>
      <Typography variant="h2" sx={{ color: 'text.primary' }}>
        Everything is Preconfigured
      </Typography>

      <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: 'repeat(3, 1fr)' }}>
        <SetupWizardStep4Card
          icon={<PostgresDatabaseIcon />}
          title="PostgresSQL DB"
          content={
            <ul>
              <li>Contains test data</li>
              <li>Has two accounts</li>
              <ul>
                <li>Admin</li>
                <li>Readonly</li>
              </ul>
            </ul>
          }
        />

        <SetupWizardStep4Card
          icon={<DatabaseProxyIcon />}
          title="Database Proxy"
          content={
            <ul>
              <li>Brokers access to DB</li>
              <li>Called Cyral Sidecar</li>
            </ul>
          }
        />

        <SetupWizardStep4Card
          icon={<LoggingToolIcon />}
          title="Logging Tool"
          content={
            <ul>
              <li>ELK based</li>
              <li>Captures all logs</li>
            </ul>
          }
        />
      </Box>
    </Stack>
  )
}

export default Step4
