import * as React from 'react'

import Dialog from '@mui/material/Dialog'
import FormControl from '@mui/material/FormControl'

import FormHelperText from '@mui/material/FormHelperText'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'

import { useForm } from 'react-hook-form'

import { Button, Input, InputLabel } from '@jeeves/new-components'
import Tag from '@jeeves/pages/DataLabels/components/Tag'
import { DialogTitle, DialogActions, DialogContent, ActionsDivider } from './Dialogs.styles'

import { gql, useMutation } from '@apollo/client'
import { DATALABELS_QUERY } from '../../DataLabels'

const CREATE_LABEL = gql`
  mutation CreateDataLabel($datalabel: DatalabelInput!) {
    createDatalabel(datalabel: $datalabel) {
      name
      description
      type
      tags
    }
  }
`

export const CreateLabel = () => {
  const [open, setOpen] = React.useState(false)
  const {
    watch,
    getValues,
    reset,
    setValue,
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm({
    mode: 'onChange',
    defaultValues: { tags: [], currentTag: '', name: '', description: '' },
  })

  const [createLabel, { data, loading, error }] = useMutation(CREATE_LABEL, {
    refetchQueries: [DATALABELS_QUERY],
  })

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleOnClose = React.useCallback(() => {
    setOpen(false)
    reset()
  }, [reset])

  React.useEffect(() => {
    if (isSubmitSuccessful) {
      handleOnClose()
    }
  }, [isSubmitSuccessful, reset, handleOnClose])

  const onSubmit = async ({ name, description, tags }) => {
    const datalabel = {
      name,
      description,
      tags,
      type: 'CUSTOM',
    }

    try {
      await createLabel({
        variables: { datalabel },
      })
    } catch (e) {
      console.error(e)
    }
  }

  const handleTagDelete = tag => {
    const currentTags = getValues('tags')
    const updatedTags = currentTags.filter(t => t !== tag)

    setValue('tags', updatedTags)
  }

  const handleAddTag = () => {
    const currentTags = getValues('tags')
    const currentTag = getValues('currentTag')

    const updatedTags = [...currentTags, currentTag]

    setValue('currentTag', '')
    setValue('tags', updatedTags)
  }

  const tags = watch('tags')

  return (
    <React.Fragment>
      <Button onClick={handleClickOpen}>New Data Label</Button>

      <Dialog open={open} onClose={handleOnClose} fullWidth sx={{ radius: '12px' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>New Data Label</DialogTitle>
          <DialogContent>
            <Stack spacing={2}>
              <FormControl variant="standard" error={Boolean(errors.name)}>
                <InputLabel htmlFor="new-data-label-name">Name</InputLabel>
                <Input
                  id="new-data-label-name"
                  inputProps={{
                    ...register('name', {
                      required: true,
                    }),
                  }}
                  placeholder="Name your label..."
                />

                {errors.name && (
                  <FormHelperText
                    sx={{
                      typography: 'body2',
                    }}
                    error
                  >
                    Name is required.
                  </FormHelperText>
                )}
              </FormControl>

              <Stack spacing={1}>
                <Stack direction="row" spacing={2} sx={{ alignItems: 'baseline' }}>
                  <FormControl variant="standard" sx={{ flex: 1 }}>
                    <InputLabel htmlFor="tag-input">Tags</InputLabel>
                    <Input
                      id="tag-input"
                      inputProps={{
                        ...register('currentTag'),
                      }}
                      placeholder="Add a tag..."
                    />
                  </FormControl>

                  <Button disabled={isSubmitting} onClick={handleAddTag} size="small">
                    Add
                  </Button>
                </Stack>

                <Box
                  sx={{
                    border: 1,
                    borderColor: 'cyralColors.grey.200',
                    borderRadius: theme => theme.radii.base,
                    height: '77px',
                    padding: 1,
                    overflowY: 'auto',
                  }}
                >
                  <Stack direction="row" sx={{ flexWrap: 'wrap', gap: 1 }}>
                    {tags.map((tag, index) => (
                      <Tag
                        key={`${tag}-${index}`}
                        text={tag}
                        onRemove={() => handleTagDelete(tag)}
                      />
                    ))}
                  </Stack>
                </Box>
              </Stack>

              <FormControl variant="standard" error={Boolean(errors.description)}>
                <InputLabel htmlFor="description-input">Description</InputLabel>
                <Input
                  id="description-input"
                  inputProps={{
                    ...register('description', {
                      maxLength: 59,
                    }),
                  }}
                  multiline
                  rows={6}
                  placeholder="Add a description..."
                />
                {errors.description && (
                  <FormHelperText
                    sx={{
                      typography: 'body2',
                    }}
                    error
                  >
                    Description has exceeded the maximum length.
                  </FormHelperText>
                )}
              </FormControl>
            </Stack>
          </DialogContent>
          <ActionsDivider />
          <DialogActions>
            <Button
              onClick={handleOnClose}
              variant="text"
              color="secondary"
              disabled={isSubmitting}
            >
              Cancel
            </Button>

            <Button loading={isSubmitting} type="submit">
              Create
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  )
}
