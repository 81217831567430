import React, { useEffect } from 'react'
import { Route, Redirect, useRouteMatch } from 'react-router-dom'

import Agreement from '../../pages/Onboarding/Agreement'
// import Password from '../../pages/Onboarding/Password'

import { useAuth } from '../Auth'
import { get as lodashGet } from 'lodash'

const PrivateRoute = ({
  component: Component,
  requiredPermission,
  path,
  route,
  history,
  ...rest
}) => {
  const { isAuthenticated, loginWithRedirect, user, isDbUser } = useAuth()
  // TO DO: this should probably be handled through some global const
  const dbUserMatch = useRouteMatch('/(token|profile|cli|access-portal|s3Browser)')
  // const dbUserMatch = useRouteMatch('/(profile|cli|access-portal)')

  useEffect(() => {
    const fn = async () => {
      if (!isAuthenticated) {
        await loginWithRedirect({
          appState: { targetUrl: window.location.pathname + window.location.search },
        })
      }
    }
    fn()
  }, [isAuthenticated, loginWithRedirect, path])

  if (!isAuthenticated) return null

  // Check if agreement was accepted
  if (user && user.client && user.clientMetadata) {
    if (user.client && user.clientMetadata) {
      const { agreement } = user.client.clientMetadata

      if (agreement === '0') {
        return <Agreement />
      }
    } else {
      console.error('PrivateRoute.user.client.clientMetadata does not exist')
    }
  }

  function isAuthorized(permission) {
    if (typeof permission === 'object') {
      for (var i = 0; i < permission.length; i++) {
        let perm = permission[i]
        if (lodashGet(user, 'app_metadata.authorization.permissions', []).includes(perm)) {
          return true
        }
      }
    } else {
      return lodashGet(user, 'app_metadata.authorization.permissions', []).includes(permission)
    }
    return false
  }

  if (!dbUserMatch) {
    if (isDbUser) {
      // return <Redirect to={{ pathname: '/token' }} />
      return <Redirect to={{ pathname: '/access-portal' }} />
    }
  }

  if (
    requiredPermission &&
    !isAuthorized(requiredPermission)
    // && path !== '/admin/profile'
  ) {
    return <Redirect to={{ pathname: '/unauthorized' }} />
  }

  const render = props => <Component {...props} route={route} />

  return <Route path={path} render={render} {...rest} />
}

export default PrivateRoute
