import ExpressClient from '@jeeves/clients/express'
import { useAuth } from '@jeeves/components/Auth'
import usePopup from '@jeeves/components/PopupMessage/hooks/usePopup'

import { get as lodashGet } from 'lodash'

const useAccess = () => {
  const { setPopup, popupTypes } = usePopup()
  const { getTokenSilently, user } = useAuth()
  const ec = new ExpressClient(getTokenSilently)

  const getRepoType = async repoId => {
    try {
      const resp = await ec.get(`/repos/${repoId}`).then(res => res.data)
      return resp.repo.type
    } catch (e) {
      console.error('Unable to fetch repository type')
      return null
    }
  }
  const grantAccess = async config => {
    const grantBody = {
      user: config.userName,
      accessDuration: config.accessDuration,
      durationUnit: config.durationUnit,
      accessWithin: config.accessWithin,
      accessUnit: config.accessUnit,
    }
    try {
      const resp = await ec.post('/repos/repoAccessGranted', config).then(res => res.data)
      await ec.post(`/repos/${config.repoID}/grants`, grantBody).then(res => res.data)
      setPopup(popupTypes.SUCCESS, 'Access Granted')
      return resp
    } catch (e) {
      const popupMessage = lodashGet(e, 'response.data.error.message', '')
      setPopup(popupTypes.ERROR, popupMessage)
      throw e
    }
  }

  const revokeAccess = async config => {
    try {
      const resp = await ec.post('/repos/repoAccessRevoked', config).then(res => res.data)
      await ec
        .request({
          method: 'DELETE',
          url: `/repos/${config.repoID}/grants`,
          params: {
            user: config.userName,
          },
        })
        .then(res => res.data)
      setPopup(popupTypes.SUCCESS, 'Access Revoked')
      return resp
    } catch (e) {
      const popupMessage = lodashGet(e, 'response.data.error.message', '')
      setPopup(popupTypes.ERROR, popupMessage)
      throw e
    }
  }

  return {
    getRepoType,
    grantAccess,
    revokeAccess,
  }
}

export default useAccess
