import { gql, useApolloClient } from '@apollo/client'

const GET_REPO_FROM_CACHE = gql`
  query GetRepoFromCache($repoId: String!) {
    repo(id: $repoId) {
      id
    }
  }
`

const useApprovalTypesExist = repoId => {
  const apolloClient = useApolloClient()

  const { repo } = apolloClient.readQuery({
    query: GET_REPO_FROM_CACHE,
    variables: {
      repoId,
    },
  })

  const serializedCache = apolloClient.cache.extract()
  const repoCacheId = apolloClient.cache.identify(repo)

  const repoInCache = serializedCache[repoCacheId] ?? {}

  const allApprovals = Object.entries(repoInCache).reduce((currentApprovals, [key, val]) => {
    if (!key.startsWith('approvals')) return currentApprovals

    const nodes = val.edges.map(edge => edge.node)

    return [...currentApprovals, ...nodes]
  }, [])

  const allApprovalTypes = allApprovals.map(approval => approval.__ref.split(':')[0])

  return {
    pendingApprovalsExist: allApprovalTypes.some(
      approvalType => approvalType === 'PendingApproval'
    ),
    grantedApprovalsExist: allApprovalTypes.some(
      approvalType => approvalType === 'GrantedApproval'
    ),
    pendingAmendmentsExist: allApprovalTypes.some(
      approvalType => approvalType === 'PendingAmendment'
    ),
  }
}

export default useApprovalTypesExist
