import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const OracleIcon = props => {
  return (
    <SvgIcon {...props} viewBox="0 0.3 31.4 19.8">
      <path
        d="M9.9 20.1c-5.5 0-9.9-4.4-9.9-9.9S4.4.3 9.9.3h11.6c5.5 0 9.9 4.4 9.9 9.9s-4.4 9.9-9.9 9.9zm11.3-3.5c3.6 0 6.4-2.9 6.4-6.4 0-3.6-2.9-6.4-6.4-6.4h-11c-3.6 0-6.4 2.9-6.4 6.4s2.9 6.4 6.4 6.4z"
        fill="#c74634"
      />
    </SvgIcon>
  )
}

export default OracleIcon
