import * as React from 'react'
import { useWatch, useFormContext } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { useFragment_experimental, gql } from '@apollo/client'

const MultiplexedRepoPortViewContext = React.createContext()

const MultiplexedRepoPortViewProvider_queryFragment = gql`
  fragment MultiplexedRepoPortViewProvider_query on Query {
    sidecar(id: $sidecarId) {
      id
      listeners {
        id
        repoType
        port
        ... on NormalNonMultiplexedListener {
          binding {
            id
            repo {
              id
              name
            }
          }
        }
      }
    }
  }
`

export const MultiplexedRepoPortViewProvider = ({ children, query, selectedRepo }) => {
  const { id: sidecarId } = useParams()
  const { data } = useFragment_experimental({
    fragment: MultiplexedRepoPortViewProvider_queryFragment,
    from: query,
    variables: {
      sidecarId,
    },
  })
  const [listenersBelongingToThisRepoType, setListenersBelongingToThisRepoType] = React.useState(
    data.sidecar.listeners.filter(listener => listener.repoType === selectedRepo.type)
  )
  const {
    formState: { isSubmitting, isSubmitSuccessful },
    watch,
  } = useFormContext()
  const listenerWatch = useWatch({
    name: 'port',
  })

  React.useEffect(() => {
    // This useEffect aims to prevent updating 'selectedListenerWillBecomeMultiplexed' once
    // the user has clicked "Bind". This is to improve the UX by preventing the UI from changing
    // (due to Apollo Cache being updated) while the modal is still visible.
    if (isSubmitting || isSubmitSuccessful) return

    const listenersBelongingToThisRepoType = data.sidecar.listeners.filter(
      listener => listener.repoType === selectedRepo.type
    )

    setListenersBelongingToThisRepoType(listenersBelongingToThisRepoType)
  }, [isSubmitting, isSubmitSuccessful, selectedRepo.type, data.sidecar.listeners])

  const selectedListener = listenersBelongingToThisRepoType.find(
    listener => listener.id === listenerWatch?.id
  )

  const selectedListenerWillBecomeMultiplexed =
    selectedListener?.__typename === 'NormalNonMultiplexedListener' &&
    Boolean(selectedListener.binding) &&
    selectedListener.binding.id !== watch('bindingId')

  const value = {
    listeners: listenersBelongingToThisRepoType,
    selectedListener,
    selectedListenerWillBecomeMultiplexed,
  }

  return (
    <MultiplexedRepoPortViewContext.Provider value={value}>
      {children}
    </MultiplexedRepoPortViewContext.Provider>
  )
}

MultiplexedRepoPortViewProvider.fragments = {
  MultiplexedRepoPortViewProvider_queryFragment,
}

export const useMultiplexedRepoPortView = () => {
  const context = React.useContext(MultiplexedRepoPortViewContext)
  if (context === undefined) {
    throw new Error(
      'useMultiplexedRepoPortView must be used within a MultiplexedRepoPortViewProvider'
    )
  }

  return context
}
