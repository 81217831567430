import { Stack } from '@mui/material'
import PolicyRule from '../../../../PolicyRule'
import { Variation1 } from '../Variation1'
import { useRateLimitVariation } from '../useRateLimitVariation'

const Variation2 = () => {
  const { databaseAccounts } = useRateLimitVariation()

  return (
    <Stack spacing={4}>
      <Variation1 />
      <PolicyRule
        title="This policy applies to these database accounts:"
        content={databaseAccounts}
      />
    </Stack>
  )
}

export default Variation2
