import { z } from 'zod'

import { VALID_UNITS } from './constants'

export const numberSchema = z
  .number()
  .or(z.string().min(1, { message: 'Please enter a valid number.' }).pipe(z.coerce.number()))

export const timeValiditySchema = z.object({
  unit: z.enum(VALID_UNITS),
  value: numberSchema,
})

export type TimeValidity = z.infer<typeof timeValiditySchema>
