import { Box } from '@mui/material'

interface Props {
  active: boolean
  onClick: React.MouseEventHandler<HTMLDivElement>
}

const StepperDot = ({ active, onClick }: Props) => {
  return (
    <Box
      sx={{
        width: '16px',
        height: '16px',
        backgroundColor: active ? 'primary.main' : '#E1EAEE',
        borderRadius: '50%',
        cursor: 'pointer',
      }}
      onClick={onClick}
    />
  )
}

export default StepperDot
