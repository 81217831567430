/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, css } from '@emotion/react'
import { Fragment, useContext, useState, useEffect } from 'react'
import { Typography, Collapse, Grid, Tooltip } from '@material-ui/core'
import { useForm } from 'react-hook-form'

import { useAppConfig, useMount } from '@jeeves/hooks'
import { Button, Paper } from '@jeeves/components/Primitives'
import { useAuth } from '@jeeves/components/Auth'
import Loading, { ButtonLoading } from '@jeeves/components/Loading'
import Toolbar from '@jeeves/components/Toolbar'
import { DataMapProvider, DataMapContext } from './contexts/DataMapContext'
import useDataMap from './hooks/useDataMap'
import { DatamapViewer } from './components'
import YAML from 'yaml'
import { useQuery, useMutation, gql } from '@apollo/client'

const FETCH_REPO_DATAMAP = gql`
  query RepoDataMapQuery($repoId: String!) {
    repo(id: $repoId) {
      id
      rawDatamap
    }
  }
`

const UPDATE_REPO_DATAMAP = gql`
  mutation UpsertRepoRawDatamap($repoId: String!, $datamap: Object!) {
    upsertRepoRawDatamap(repoId: $repoId, datamap: $datamap) {
      message
    }
  }
`

const ErrorState = () => (
  <div
    css={css`
      display: flex;
      justify-content: center;
      align-items: center;
      vertical-align: middle;
    `}
  >
    <p
      css={theme => css`
        text-align: center;
        width: 60%;
        margin: 20px auto;
        font-family: ${theme.typography.fontFamily};
      `}
    >
      There was an error while loading the datamap. Please try again later or contact support.
    </p>
  </div>
)

const DataMapsActions = props => {
  const { editing, setEditing, error, datamapErrors, reset, isSubmitting } = props
  const { hasPermission } = useAuth()

  const permissions = {
    canCreate: hasPermission('datamap:create'),
    canUpdate: hasPermission('datamap:update'),
  }

  return (
    <Grid
      container
      justify="flex-end"
      css={t => css`
        padding: ${t.spacing[5]};
      `}
    >
      {editing ? (
        <Fragment>
          <Button
            size="small"
            onClick={() => {
              reset()
              setEditing(false)
            }}
            variant="outlined"
            color="primary"
            css={t => css`
              margin-right: 16px;
            `}
          >
            Cancel
          </Button>

          <Tooltip
            title={datamapErrors?.datamapViewer ? 'Unable to submit invalid YAML format' : ''}
          >
            <div css={{ position: 'relative' }}>
              <Button
                size="small"
                variant="contained"
                color="primary"
                type="submit"
                disabled={
                  datamapErrors?.datamapViewer !== undefined ||
                  !(permissions.canCreate || permissions.canUpdate) ||
                  isSubmitting
                }
              >
                Save
              </Button>
              {isSubmitting && <ButtonLoading />}
            </div>
          </Tooltip>
        </Fragment>
      ) : (
        <Button
          variant="contained"
          color="primary"
          onClick={() => setEditing(true)}
          disabled={!permissions.canUpdate || error}
        >
          Edit
        </Button>
      )}
    </Grid>
  )
}

const DataMap = ({ isRepoView = false, repoId, repoType }) => {
  const [state, setState] = useContext(DataMapContext) // eslint-disable-line
  const { ec, putDataMap, setPopup, popupTypes, deleteDataMap } = useDataMap()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [editing, setEditing] = useState(false)

  const { config } = useAppConfig()

  const [updateRepoDataMap, { data: updateData, loading: updateLoading, error: updateError }] =
    useMutation(UPDATE_REPO_DATAMAP, {})

  const {
    data: repoDataMapData,
    loading: repoDataMapLoading,
    error: repoDataMapError,
  } = useQuery(FETCH_REPO_DATAMAP, {
    variables: {
      repoId: repoId,
    },
  })

  const {
    control,
    reset,
    handleSubmit,
    trigger,
    formState: { errors: datamapErrors, isSubmitting },
  } = useForm({
    defaultValues: {
      datamapViewer: '',
    },
    mode: 'onChange',
  })

  const onSubmit = async data => {
    const { datamapViewer } = data

    try {
      if (!isRepoView && datamapViewer === '') {
        await deleteDataMap()
      } else {
        if (isRepoView) {
          await updateRepoDataMap({
            variables: { repoId, datamap: YAML.parse(datamapViewer) },
          })
        } else {
          await putDataMap(datamapViewer)
        }
      }

      const popupMessage = 'Success!'
      setPopup(popupTypes.SUCCESS, popupMessage)
      setEditing(false)
      reset({ datamapViewer })
    } catch (error) {}
  }

  const filterDataMap = obj => {
    // 'attributes' and 'endpoints' can't be passed dynamically, hence the duplicate code
    if (repoType === 'rest') {
      return Object.entries(obj).reduce(
        (prevObj, [label, { attributes, ...restMappingSet }]) => ({
          ...prevObj,
          [label]: restMappingSet,
        }),
        {}
      )
    } else {
      return Object.entries(obj).reduce(
        (prevObj, [label, { endpoints, ...restMappingSet }]) => ({
          ...prevObj,
          [label]: restMappingSet,
        }),
        {}
      )
    }
  }
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        if (isRepoView) {
          let repoYAMLdata = repoDataMapData && filterDataMap(repoDataMapData?.repo?.rawDatamap)
          repoYAMLdata = YAML.stringify(repoYAMLdata)
          if (repoYAMLdata.trim() !== '{}') {
            reset({
              datamapViewer: repoYAMLdata,
            })
          }
        } else {
          const { data } = await ec.get('/datamap')
          if (data.trim() !== '{}') {
            reset({
              datamapViewer: data,
            })
          }
        }
      } catch (e) {
        setError(true)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [repoDataMapData])

  if (!config || !config.enableDatamap) return null

  return (
    <Paper>
      <Toolbar title="Data Map" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          css={t => css`
            position: relative;
            height: 550px;
            padding: 0 24px 24px 24px;
          `}
        >
          {loading && <Loading />}
          {!loading && error && <ErrorState />}
          {!loading && !error && (
            <DatamapViewer control={control} trigger={trigger} editing={editing} />
          )}

          <Collapse in={datamapErrors?.datamapViewer !== undefined}>
            <Typography
              color="error"
              variant="subtitle2"
              css={theme => ({
                padding: theme.spacing[4],
              })}
            >
              Invalid YAML input. Please check indentation and formatting.
            </Typography>
          </Collapse>
        </div>

        <DataMapsActions
          reset={reset}
          datamapErrors={datamapErrors}
          editing={editing}
          setEditing={setEditing}
          error={error}
          isSubmitting={isSubmitting}
        />
      </form>
    </Paper>
  )
}

const DataMapWrapper = props => (
  <DataMapProvider>
    <DataMap {...props} />
  </DataMapProvider>
)

export default DataMapWrapper
