import { useMutation } from '@apollo/client'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router'
import { z } from 'zod'

import { FragmentType, graphql, useFragment } from '@jeeves/graphql'

import { useToast } from '@jeeves/new-components'
import { getGraphQLErrorMessage } from '@jeeves/utils/helpers'

const useIntegrationSettings_QueryFragment = graphql(`
  fragment useIntegrationSettings_QueryFragment on Query {
    sidecar(id: $sidecarId) {
      id
      loggingIntegrations {
        dataActivityLogsIntegration {
          id
          name
        }
      }
    }
  }
`)

const UPDATE_SIDECAR_INTEGRATION_SETTINGS = graphql(`
  mutation UpdateSidecarIntegrationSettings(
    $sidecarId: ID!
    $input: UpdateSidecarLoggingIntegrationsInput!
  ) {
    updateSidecarLoggingIntegrations(sidecarId: $sidecarId, input: $input) {
      sidecar {
        id
        loggingIntegrations {
          dataActivityLogsIntegration {
            id
            name
          }
          diagnosticLogsIntegration {
            id
            name
          }
        }
      }
    }
  }
`)

const schema = z.object({
  dataActivityLogsIntegrationId: z.string(),
})

type IntegrationSettingsFormSchema = z.infer<typeof schema>

interface useIntegrationSettingsProps {
  query: FragmentType<typeof useIntegrationSettings_QueryFragment>
}

const useIntegrationSettings = ({ query: queryProp }: useIntegrationSettingsProps) => {
  const { id: sidecarId } = useParams<{ id: string }>()

  const { toast } = useToast()

  const { sidecar } = useFragment(useIntegrationSettings_QueryFragment, queryProp)

  const methods = useForm<IntegrationSettingsFormSchema>({
    defaultValues: {
      dataActivityLogsIntegrationId:
        sidecar.loggingIntegrations.dataActivityLogsIntegration?.id ?? 'none',
    },
  })

  const [updateSidecarLoggingIntegration, { loading }] = useMutation(
    UPDATE_SIDECAR_INTEGRATION_SETTINGS,
    {
      onError: error => {
        toast({
          variant: 'error',
          description:
            getGraphQLErrorMessage(error) ||
            'An error occurred while saving, please try again later.',
        })
      },
      onCompleted: () => {
        methods.reset(methods.getValues())
        toast({
          variant: 'success',
          description: 'Integration settings updated.',
        })
      },
    }
  )

  const onSubmit = async ({ dataActivityLogsIntegrationId }: IntegrationSettingsFormSchema) => {
    await updateSidecarLoggingIntegration({
      variables: {
        sidecarId,
        input: {
          dataActivityLogsIntegrationId:
            dataActivityLogsIntegrationId !== 'none' ? dataActivityLogsIntegrationId : null,
        },
      },
    })
  }

  return {
    loading,
    methods,
    onSubmit: methods.handleSubmit(onSubmit),
  }
}

export default useIntegrationSettings
