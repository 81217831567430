import React, { useState } from 'react'

const PopupContext = React.createContext([{}, () => {}])

const PopupProvider = ({ children }) => {
  const [state, setState] = useState({
    isDisplay: false,
    variant: null,
    displayMessage: null,
    displayTime: null,
  })

  return <PopupContext.Provider value={[state, setState]}>{children}</PopupContext.Provider>
}

export { PopupContext, PopupProvider }
