import React from 'react'
import { Stack } from '@mui/material'
import { styled } from '@mui/material/styles'

export const Section = styled(
  props => <Stack {...props} />,
  {}
)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.cyralColors.grey[200],
  borderRadius: theme.radii.base,
  padding: theme.spacing(4),
}))
