/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Typography } from '@material-ui/core'
import { EllipsisTypography } from '@jeeves/components/Primitives'
import { SubRow } from '.'

import { ServiceIcon } from '@jeeves/components/ServiceIcon'

export const AuthorizationPolicyList = ({ identityMaps = [] }) => {
  return (
    <ul
      css={() => css`
        list-style: none;
        margin: 0;
        padding: 0;
      `}
    >
      {identityMaps.map(identityMap => (
        <SubRow key={`${identityMap.identityName}authorizationPolicy`}>
          {identityMap.authorizationPolicyInstance ? (
            <div css={{ display: 'flex', alignItems: 'center' }}>
              <ServiceIcon
                type={identityMap?.authorizationPolicyInstance?.templateType?.toLowerCase() || ''}
              ></ServiceIcon>
              <EllipsisTypography width="120px">
                {identityMap.authorizationPolicyInstance.name}
              </EllipsisTypography>
            </div>
          ) : (
            '\u2013'
          )}
        </SubRow>
      ))}
    </ul>
  )
}
