import pluralize from 'pluralize'
import { useForm, Controller } from 'react-hook-form'
import { z } from 'zod'
import { Box, Stack, FormControl, FormHelperText } from '@mui/material'
import { zodResolver } from '@hookform/resolvers/zod'

import { graphql, useFragment } from '@jeeves/graphql'
import { Button, Input, InputLabel, Select } from '@jeeves/new-components'
import {
  parseISO8601DurationToTimeValidity,
  formatTimeValidityToISO8601Duration,
} from '@jeeves/utils/duration'
import { VALID_UNITS } from '@jeeves/utils/constants'
import { timeValiditySchema } from '@jeeves/utils/schemas'

const NewAccessTokenForm_QueryFragment = graphql(`
  fragment NewAccessTokenForm_QueryFragment on Query {
    accessTokenConfiguration {
      defaultDuration
    }
  }
`)

const generateAccessTokenSchema = z.object({
  name: z.string().optional(),
  duration: timeValiditySchema,
})

const NewAccessTokenForm = ({ onCancel, createAccessToken, loading, query: queryProp }) => {
  const query = useFragment(NewAccessTokenForm_QueryFragment, queryProp)

  const { unit, value } = parseISO8601DurationToTimeValidity(
    query?.accessTokenConfiguration.defaultDuration
  )

  const methods = useForm({
    defaultValues: {
      name: '',
      duration: {
        unit,
        value,
      },
    },
    resolver: zodResolver(generateAccessTokenSchema),
  })

  const {
    control,
    formState: { errors },
    register,
    watch,
  } = methods

  const durationValue = watch('duration.value')

  const options = VALID_UNITS.map(unit => ({
    label: pluralize(unit, Number(durationValue)),
    value: unit,
  }))

  const onSubmit = async ({ name, duration }) => {
    const input = {
      name,
      duration: formatTimeValidityToISO8601Duration(duration),
    }

    await createAccessToken({
      variables: {
        input,
      },
    })
  }

  return (
    <Box component="form" onSubmit={methods.handleSubmit(onSubmit)}>
      <Stack spacing={4}>
        <Stack spacing={2}>
          <FormControl variant="standard" error={Boolean(errors.name)} fullWidth>
            <InputLabel htmlFor="name">Name</InputLabel>
            <Input
              id="name"
              type="text"
              inputProps={{
                ...register('name'),
              }}
            />
            <FormHelperText sx={{ typography: 'body2' }}>{errors.name?.message}</FormHelperText>
          </FormControl>

          <Stack spacing={1}>
            <InputLabel id="valid-duration-label" required>
              Validity Period
            </InputLabel>

            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 2fr', gap: 1 }}>
              <FormControl variant="standard" error={Boolean(errors?.duration?.value)}>
                <Input
                  type="number"
                  inputProps={{
                    ...register('duration.value'),
                    min: 1,
                  }}
                />
                <FormHelperText sx={{ typography: 'body2' }}>
                  {errors?.duration?.value?.message}
                </FormHelperText>
              </FormControl>

              <FormControl variant="standard">
                <Controller
                  name="duration.unit"
                  control={control}
                  render={({ field }) => <Select options={options} {...field} />}
                />
                <FormHelperText sx={{ typography: 'body2' }}>
                  {errors?.duration?.unit?.message}
                </FormHelperText>
              </FormControl>
            </Box>
          </Stack>
        </Stack>

        <Stack direction="row" spacing={1} sx={{ justifyContent: 'flex-end' }}>
          <Button variant="outlined" color="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Button variant="contained" type="submit" color="primary" loading={loading}>
            Generate
          </Button>
        </Stack>
      </Stack>
    </Box>
  )
}

export default NewAccessTokenForm
