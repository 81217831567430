import { Box, Stack, Typography } from '@mui/material'

import { BackButton } from './components/BackButton'
import { Configure } from './components/Configure'

import { Badge } from '@jeeves/new-components'
import { usePolicyDetails } from '@jeeves/pages/RepositoryDetail/Tabs/Policies/views/PolicyDetails/contexts/PolicyDetailsContext'

const Header = () => {
  const { policy } = usePolicyDetails()

  return (
    <Stack spacing={2}>
      <Box>
        <BackButton />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
          <Badge variant={policy?.enabled ? 'success' : 'error'}>
            {policy.enabled ? 'Enabled' : 'Disabled'}
          </Badge>
          <Typography variant="h3" sx={{ color: 'text.primary' }}>
            {policy.name}
          </Typography>
        </Stack>
        <Configure />
      </Box>
      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        {policy.description}
      </Typography>
    </Stack>
  )
}

export default Header
