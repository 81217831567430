import * as React from 'react'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'

import { Button } from '@jeeves/new-components'

import {
  InfoAlert,
  WarningAlert,
  RulesTable,
  Upsert,
  ToggleNetworkShieldStatusModal,
} from './components'

const NetworkShield = ({ networkShield }) => {
  const [upsertRuleModalOpen, setUpsertRuleModalOpen] = React.useState(false)
  const [toggleNetworkShieldStatusModalOpen, setToggleNetworkShieldStatusModalOpen] =
    React.useState(false)

  const handleToggleOpenUpsertRuleModal = React.useCallback(() => {
    setUpsertRuleModalOpen(oldUpsertRuleModalOpen => !oldUpsertRuleModalOpen)
  }, [])

  const handleToggleNetworkShieldStatusModal = () => {
    setToggleNetworkShieldStatusModalOpen(
      oldToggleNetworkShieldStatusModalOpen => !oldToggleNetworkShieldStatusModalOpen
    )
  }

  let alertPanel = null

  if (networkShield?.rules.length === 0 && !networkShield?.enabled) {
    alertPanel = (
      <InfoAlert>
        Access to this repository is currently not restricted based on the client's location.
        Clients can connect to this repository from any IP address.
      </InfoAlert>
    )
  }

  if (networkShield?.rules.length === 0 && networkShield?.enabled) {
    alertPanel = (
      <WarningAlert>
        Access to this repository is completely restricted. Add a rule to allow access.
      </WarningAlert>
    )
  }

  if (networkShield?.rules.length > 0 && !networkShield?.enabled) {
    alertPanel = (
      <WarningAlert>
        These rules are not in effect. Clients can connect to this repository from any IP address.
        Enable the toggle above to activate the Network Shield.
      </WarningAlert>
    )
  }

  if (networkShield?.rules.length > 0 && networkShield?.enabled) {
    // Check for "Any/Any" rules

    const networkShieldContainsUnrestrictedAccessRule = networkShield?.rules.some(rule => {
      const clientIPs = rule.clientIPAddresses
      const databaseAccounts = rule.databaseAccounts

      // Need to check for both empty array as well as presence of 0.0.0.0/0
      const anyClientIPAccess =
        clientIPs.length === 0 || clientIPs.some(ipAddress => ipAddress === '0.0.0.0/0')

      const anyDatabaseAccountAccess = databaseAccounts.length === 0

      return anyClientIPAccess && anyDatabaseAccountAccess
    })

    if (networkShieldContainsUnrestrictedAccessRule) {
      alertPanel = (
        <WarningAlert>
          An existing rule currently allows unrestricted access to this repository.
        </WarningAlert>
      )
    }
  }

  return (
    <React.Fragment>
      <Box sx={{ px: 8, py: 4 }}>
        <Stack spacing={4}>
          <FormControlLabel
            control={
              <Switch
                checked={networkShield?.enabled}
                onChange={handleToggleNetworkShieldStatusModal}
              />
            }
            componentsProps={{
              typography: {
                variant: 'h4',
                sx: {
                  color: 'text.primary',
                },
              },
            }}
            label="Restrict access to this repository based on client IP."
          />
          {alertPanel}
          <Stack spacing={2}>
            {networkShield?.rules.length > 0 && (
              <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography
                  variant="body1"
                  sx={{
                    color: 'text.secondary',
                  }}
                >
                  Database accounts listed here can connect only from the client IP addresses
                  assigned to them.
                </Typography>
                <Button onClick={handleToggleOpenUpsertRuleModal}>Add Rule</Button>
              </Stack>
            )}

            <Stack spacing={5}>
              <RulesTable rules={networkShield?.rules} />
              {networkShield?.rules.length === 0 && (
                <Button onClick={handleToggleOpenUpsertRuleModal} sx={{ alignSelf: 'center' }}>
                  Add Rule
                </Button>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Box>
      <Upsert open={upsertRuleModalOpen} closeModal={handleToggleOpenUpsertRuleModal} />
      <ToggleNetworkShieldStatusModal
        open={toggleNetworkShieldStatusModalOpen}
        closeModal={handleToggleNetworkShieldStatusModal}
        networkShieldEnabled={networkShield?.enabled}
      />
    </React.Fragment>
  )
}

export default NetworkShield
