/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { useState, useEffect } from 'react'
import { IconButton, Fade } from '@material-ui/core/'
import EditIcon from '@material-ui/icons/Edit'
import { useAuth } from '@jeeves/components/Auth'
import {
  DeleteIntegrationButton,
  IntegrationCard,
  IntegrationHeader,
  IntegrationTitle,
  Label,
  LabelValuePair,
  Value,
} from '../../../primitives'

import { FormControl, Stack } from '@mui/material'
import { Input, InputLabel, Button } from '@jeeves/new-components'
import { useConfExtensionInstances } from '@jeeves/hooks/useConfExtensions'
import { ThemeProvider } from '@mui/material/styles'
import { v5Theme } from '@jeeves/theme'
import { useForm } from 'react-hook-form'

const AddForm = ({ integration = null, onDelete, onClose, creating = false, onEdit }) => {
  const [editing, setEditing] = useState(creating)
  const { hasPermission } = useAuth()
  const {
    createConfExtensionInstance,
    createConfExtensionInstanceResponse: { error: createError },
  } = useConfExtensionInstances({
    getConfExtensionInstancesOptions: {
      variables: { purpose: 'authorization', templateType: 'duoMfa' },
    },
  })

  const {
    register,
    control,
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { isSubmitting, isDirty },
  } = useForm({
    defaultValues: {
      name: integration?.name ?? '',
      apiHostname: '',
      integrationKey: '',
      secretKey: '',
    },
  })

  const parseParameters = parameters => {
    if (!parameters) return
    try {
      const parsed = JSON.parse(parameters)
      const { apiHostname = '', integrationKey = '', secretKey = '' } = parsed
      setValue('apiHostname', apiHostname)
      setValue('integrationKey', integrationKey)
      setValue('secretKey', secretKey)
    } catch (e) {}
  }

  useEffect(() => {
    parseParameters(integration?.parameters)
  }, [integration]) // eslint-disable-line

  const handleCancelEditing = () => {
    setEditing(false)
    parseParameters(integration?.parameters)
    onClose?.()
  }

  const onSubmit = async ({ name, apiHostname, integrationKey, secretKey }) => {
    try {
      if (creating) {
        const stringifiedParameters = JSON.stringify({ apiHostname, integrationKey, secretKey })
        await createConfExtensionInstance({
          variables: {
            confExtensionInstance: {
              name,
              category: 'builtin',
              templateType: 'duoMfa',
              purpose: 'authorization',
              parameters: stringifiedParameters,
            },
          },
        })
      } else {
        onEdit?.({ name, apiHostname, integrationKey, secretKey })
      }

      onClose?.()
    } catch (e) {
      console.log({ e })
    } finally {
      setEditing(false)
    }
  }

  const { name, apiHostname, integrationKey, secretKey } = watch()

  return (
    <IntegrationCard>
      <form onSubmit={handleSubmit(onSubmit)}>
        {!editing ? (
          <Fade in={!editing}>
            <div>
              <IntegrationHeader>
                <IntegrationTitle gutterBottom component="h2">
                  {integration?.name}
                </IntegrationTitle>
                <div css={{ flexGrow: 1 }} />
                <IconButton
                  onClick={() => setEditing(true)}
                  disabled={!hasPermission('integrations:update')}
                >
                  <EditIcon />
                </IconButton>
                <DeleteIntegrationButton
                  onDelete={onDelete}
                  disabled={!hasPermission('integrations:delete')}
                />
              </IntegrationHeader>
              <LabelValuePair>
                <Label>Integration Key</Label>
                <Value>{getValues('integrationKey')}</Value>
              </LabelValuePair>
              <LabelValuePair>
                <Label>Secret Key</Label>
                <Value>
                  {getValues('secretKey') && '*'.repeat(getValues('secretKey')?.length)}
                </Value>
              </LabelValuePair>
              <LabelValuePair>
                <Label>API Hostname</Label>
                <Value>{getValues('apiHostname')}</Value>
              </LabelValuePair>
            </div>
          </Fade>
        ) : (
          <Fade in={editing}>
            <ThemeProvider theme={v5Theme}>
              <Stack spacing={4}>
                <FormControl variant="standard">
                  <InputLabel htmlFor="display-name">Display Name</InputLabel>
                  <Input id="display-name" inputProps={{ ...register('name') }}></Input>
                </FormControl>
                <FormControl variant="standard">
                  <InputLabel htmlFor="integration-key">Integration Key</InputLabel>
                  <Input
                    id="integration-key"
                    inputProps={{ ...register('integrationKey') }}
                  ></Input>
                </FormControl>
                <FormControl variant="standard">
                  <InputLabel htmlFor="secret-key">Secret Key</InputLabel>
                  <Input id="secret-key" inputProps={{ ...register('secretKey') }}></Input>
                </FormControl>
                <FormControl variant="standard">
                  <InputLabel htmlFor="api-hostname">API Hostname</InputLabel>
                  <Input id="api-hostname" inputProps={{ ...register('apiHostname') }}></Input>
                </FormControl>

                <Stack direction="row" spacing={2.5} sx={{ justifyContent: 'flex-end' }}>
                  <Button variant="text" onClick={handleCancelEditing}>
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    type="submit"
                    loading={isSubmitting}
                    disabled={!name || !apiHostname || !integrationKey || !secretKey || !isDirty}
                  >
                    Save
                  </Button>
                </Stack>
              </Stack>
            </ThemeProvider>
          </Fade>
        )}
      </form>
    </IntegrationCard>
  )
}

export default AddForm
