import * as React from 'react'

import { MappingsTableRow, MappingsTableCell } from './MappingTable.styles'

import { combineKeyAttributeComponentsIfS3 } from '@jeeves/utils/helpers'

export const MappingRow = ({ mapping, repoType }) => {
  const { parsed } = mapping.field

  const parsedAttribute = combineKeyAttributeComponentsIfS3(parsed, repoType)

  return (
    <MappingsTableRow>
      {parsedAttribute.map((attributeComponent, index) => (
        <MappingsTableCell key={`datalabels-mapping-cell-${index}`}>{attributeComponent}</MappingsTableCell>
      ))}
    </MappingsTableRow>
  )
}
