import { useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'

import { Box, Divider, FormControl, Stack, RadioGroup, FormHelperText } from '@mui/material'
import { useRepositoryDetailContext } from '@jeeves/pages/RepositoryDetail/contexts/RepositoryDetailContext'

import { InputLabel } from '@jeeves/new-components'

import InputTags from '@jeeves/pages/RepositoryDetail/Tabs/Policies/views/AddPolicy/components/InputTags'
import Footer from '@jeeves/pages/RepositoryDetail/Tabs/Policies/views/AddPolicy/components/Footer'
import MaskingOption from './components/MaskingOption'
import MaskingReplacementOption from './components/MaskingReplacementOption'

const StepTwo = ({ dataMaskingInfo, nextStep, prevStep, setDataMaskingInfo }) => {
  const { repoId } = useRepositoryDetailContext()
  const {
    register,
    setValue,
    handleSubmit,
    watch,
    control,
    formState: { errors, isSubmitting, isSubmitSuccessful },
    clearErrors,
  } = useForm({
    defaultValues: {
      maskingType: dataMaskingInfo?.parameters?.maskingSpecification?.method ?? 'NULL',
      replacementValue: dataMaskingInfo?.parameters?.maskingSpecification?.replacementValue ?? '',
      dataLabels: dataMaskingInfo?.parameters?.specifiedDataLabels ?? [],
    },
  })

  const onSubmit = data => {
    setDataMaskingInfo(info => {
      return {
        ...info,
        parameters: {
          ...info.parameters,
          specifiedDataLabels: data.dataLabels,
          maskingSpecification: {
            method: data.maskingType,
            ...(data.maskingType === 'CONSTANT' && { replacementValue: data.replacementValue }),
          },
        },
      }
    })
  }

  const onDeleteDataLabels = updatedTags => {
    setValue('dataLabels', updatedTags, { shouldDirty: true })
  }

  const onAddDataLabels = updatedTags => {
    setValue('dataLabels', updatedTags, { shouldValidate: true, shouldDirty: true })
  }

  useEffect(() => {
    if (isSubmitSuccessful) {
      nextStep()
    }
  }, [isSubmitSuccessful, nextStep])

  const { dataLabels = [], maskingType } = watch()

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4}>
        <Stack spacing={4} sx={{ maxWidth: '32rem' }}>
          <FormControl variant="standard" sx={{ flex: 1 }}>
            <InputLabel component="legend">Masking Type</InputLabel>
            <Controller
              name="maskingType"
              control={control}
              render={({ field }) => (
                <RadioGroup
                  row
                  aria-label="masking-type"
                  {...field}
                  onChange={event => {
                    setValue('maskingType', event.target.value)
                    setValue('replacementValue', '')
                    clearErrors('replacementValue')
                  }}
                >
                  <Stack spacing={2} sx={{ flexGrow: 1 }}>
                    <MaskingOption
                      value="NULL"
                      label="Null Mask"
                      info="12i4joainsfo9u123asf"
                      replacement="NULL"
                    />

                    <MaskingReplacementOption
                      errors={errors}
                      register={register}
                      isMaskingTypeConstant={maskingType === 'CONSTANT'}
                    />

                    <MaskingOption
                      value="FORMAT_PRESERVING"
                      label="Format-Preserving Mask"
                      info="MyEmail123@cyral.com"
                      replacement="ZaFxbcd517@dzbxq.pqd"
                    />
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>

          <FormControl variant="standard" error={Boolean(errors?.dataLabels)}>
            <Controller
              name="dataLabels"
              control={control}
              rules={{
                validate: dataLabels => {
                  return dataLabels.length > 0 || 'Please enter at least one label'
                },
              }}
              render={() => (
                <InputTags
                  required
                  inputLabel="Data Labels"
                  tags={dataLabels}
                  isSubmitting={isSubmitting}
                  onDelete={onDeleteDataLabels}
                  onAdd={onAddDataLabels}
                />
              )}
            />
            <FormHelperText sx={{ typography: 'body2' }} error={!!errors?.dataLabels}>
              {errors?.dataLabels?.message}
            </FormHelperText>
          </FormControl>
        </Stack>

        <Divider />

        <Footer repoId={repoId} prevStep={prevStep} policyId={dataMaskingInfo.id} />
      </Stack>
    </Box>
  )
}

export default StepTwo
