/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Typography } from '@material-ui/core'
import { EllipsisTypography } from '@jeeves/components/Primitives'

import { SubRow } from '.'
import humanizeDuration from 'humanize-duration'
const HD_OPTIONS = {
  round: true,
  largest: 1,
}

// TO DO: componentize the duration stuff

export const CreatedList = ({ identityMaps = [] }) => (
  <ul
    css={() => css`
      list-style: none;
      margin: 0;
      padding: 0;
    `}
  >
    {identityMaps.map(identityMap => (
      <SubRow key={identityMap.identityName}>
        <EllipsisTypography width="120px">{`${humanizeDuration(
          Date.now() - new Date(identityMap.modified),
          HD_OPTIONS
        )} ago`}</EllipsisTypography>
      </SubRow>
    ))}
  </ul>
)
