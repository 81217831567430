import { ReactNode } from 'react'
import { Box } from '@mui/material'

interface Props {
  children: ReactNode
  handleClickInstanceCell: () => void
}

const InstanceCell = ({ children, handleClickInstanceCell }: Props) => {
  return (
    <Box component="td" onClick={handleClickInstanceCell} tabIndex={-1} sx={{ cursor: 'pointer' }}>
      {children}
    </Box>
  )
}

export default InstanceCell
