import { Stack } from '@mui/material'
import PolicyRule from '../../../../PolicyRule'
import { Variation1 } from '../Variation1'
import { useReadLimitVariation } from '../useReadLimitVariation'

const Variation5 = () => {
  const { identities, databaseAccounts } = useReadLimitVariation()

  return (
    <Stack spacing={4}>
      <Variation1 />
      <Stack spacing={1}>
        <PolicyRule title="This policy applies to these identities:" content={identities} />
        <PolicyRule
          title="when they are using any of these database accounts:"
          content={databaseAccounts}
        />
      </Stack>
    </Stack>
  )
}

export default Variation5
