import { Box, Typography } from '@mui/material'

const ErrorState = () => {
  return (
    <Box sx={{ pt: 4 }}>
      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        An error occurred while fetching settings.
      </Typography>
    </Box>
  )
}

export default ErrorState
