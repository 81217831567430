import React from 'react'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'

const styles = theme => ({
  divider: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
})

const Information = ({ classes }) => {
  return (
    <Card>
      <CardContent>
        <Typography variant="body1">Package: Premium</Typography>
        <Typography inline variant="caption" color="textSecondary">
          <em>Add ons:</em>
        </Typography>
        <Typography inline> Identity Management</Typography>
      </CardContent>

      <CardContent>
        <Typography variant="body1">Annual fee</Typography>
        <Typography>$10,000</Typography>
        <Typography inline variant="caption" color="textSecondary">
          <em>Next charge is due on</em>
        </Typography>
        <Typography inline>
          {' '}
          <em>Apr 20, 2019</em>
        </Typography>
      </CardContent>

      <CardContent>
        <Typography variant="body1">Monthly fee</Typography>
        <Typography inline>$1,000 </Typography>
        <Typography inline variant="caption" color="textSecondary">
          <em>Payed on Jan, 2019</em>
        </Typography>
        <br />
        <Typography inline variant="caption" color="textSecondary">
          <em>Next payment is due on</em>
        </Typography>
        <Typography inline>
          {' '}
          <em>Feb 15, 2019</em>
        </Typography>
      </CardContent>

      <CardContent>
        <Button variant="contained" color="primary" component={Link} to="/plans">
          Upgrade
        </Button>
      </CardContent>

      <Divider className={classes.divider} />

      <CardContent>
        <Typography variant="h6">Payment Information</Typography>
        <Typography inline>WellsFargo Bank account ending with</Typography>
        <Typography inline className={[classes.textSemiBold]}>
          {' '}
          <strong>**2416</strong>
        </Typography>
      </CardContent>

      <CardContent>
        <Button variant="contained" color="primary">
          Edit payment method
        </Button>
      </CardContent>
    </Card>
  )
}

export default withStyles(styles)(Information)
