import { Controller, useFormContext } from 'react-hook-form'
import sortBy from 'lodash/sortBy'
import { FormControl } from '@mui/material'

import { InputLabel, Select } from '@jeeves/new-components'
import { EnhancedAuditLogActionCategory } from '@jeeves/graphql/graphql'

const options = [
  { label: 'Access Rules', value: EnhancedAuditLogActionCategory.AccessRules },
  { label: 'Approvals', value: EnhancedAuditLogActionCategory.Approval },
  { label: 'Data Map', value: EnhancedAuditLogActionCategory.Datamap },
  { label: 'Network Shield', value: EnhancedAuditLogActionCategory.NetworkAccess },
  { label: 'Policies', value: EnhancedAuditLogActionCategory.RegoPolicyInstance },
]

const EventTypeSelect = () => {
  const { control } = useFormContext()

  return (
    <FormControl variant="standard" fullWidth>
      <InputLabel id="event-type-label" htmlFor="event-type">
        Event Type
      </InputLabel>
      <Controller
        name="eventType"
        control={control}
        render={({ field }) => (
          <Select
            labelId="event-type-label"
            id="event-type"
            options={[
              {
                value: 'all',
                label: 'All',
              },
              ...sortBy(options, ['label']),
            ]}
            {...field}
          />
        )}
      />
    </FormControl>
  )
}

export default EventTypeSelect
