interface IsSidecarOutdated {
  sidecarVersion: string | null
  latestSidecarVersion: string | null
}

export const isSidecarOutdated = ({ sidecarVersion, latestSidecarVersion }: IsSidecarOutdated) => {
  return sidecarVersion !== latestSidecarVersion
}

export function descendingComparator(a: number, b: number) {
  if (b < a) {
    return -1
  }
  if (b > a) {
    return 1
  }
  return 0
}
