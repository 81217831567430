import CloudOffIcon from '@mui/icons-material/CloudOff'

import { FragmentType, graphql, useFragment } from '@jeeves/graphql'
import { CyralIcon, Tooltip } from '@jeeves/new-components'
import { isCyralEngineeringUser, isLocalUser } from '@jeeves/utils/user'

import SamlIcon from './SamlIcon'

const IdentityProviderIcon_ControlPlaneUserFragment = graphql(`
  fragment IdentityProviderIcon_ControlPlaneUserFragment on ControlPlaneUser {
    id
    identityProviders {
      id
    }
  }
`)

interface Props {
  controlPlaneUser: FragmentType<typeof IdentityProviderIcon_ControlPlaneUserFragment>
}

const iconsMap = {
  cyralEngineeringUser: {
    icon: <CyralIcon sx={{ fontSize: 24, cursor: 'pointer' }} />,
    tooltipText: 'Cyral Engineering user',
  },
  localUser: {
    icon: <CloudOffIcon sx={{ fontSize: 24, color: 'secondary.main', cursor: 'pointer' }} />,
    tooltipText: 'Local user',
  },
  ssoUser: {
    icon: <SamlIcon sx={{ fontSize: 24, cursor: 'pointer' }} />,
    tooltipText: 'SSO user',
  },
}

const IdentityProviderIcon = ({ controlPlaneUser }: Props) => {
  const { identityProviders } = useFragment(
    IdentityProviderIcon_ControlPlaneUserFragment,
    controlPlaneUser
  )

  const getIconAndTooltipText = () => {
    if (isLocalUser(identityProviders)) {
      return iconsMap.localUser
    }

    if (isCyralEngineeringUser(identityProviders)) {
      return iconsMap.cyralEngineeringUser
    }

    return iconsMap.ssoUser
  }

  const { icon, tooltipText } = getIconAndTooltipText()

  return (
    <Tooltip title={tooltipText}>
      <span>{icon}</span>
    </Tooltip>
  )
}

export default IdentityProviderIcon
