import React, { forwardRef } from 'react'
import IconButton from '@mui/material/IconButton'

import { styled } from '@mui/material/styles'

const StyledIconButton = styled(
  forwardRef((props, ref) => <IconButton ref={ref} {...props} disableRipple disableFocusRipple />),
  {}
)(({ theme }) => ({
  '&:hover': {
    backgroundColor: 'transparent',

    '& .MuiSvgIcon-root': {
      transition: theme.transitions.create(['color'], {
        duration: theme.transitions.duration.shortest,
      }),

      color: theme.palette.cyralColors.grey[400],
    },
  },
}))

export default StyledIconButton
