import { usePolicySummary } from '@jeeves/pages/RepositoryDetail/Tabs/Policies/components/PolicySummary/PolicySummaryContext'
import { getDisplayedPolicyIdentity } from '@jeeves/pages/RepositoryDetail/Tabs/Policies/helpers/policyIdentities'

const useDataFirewallSummary = () => {
  const { policy } = usePolicySummary()

  const excludedIdentities = policy.parameters.excludedIdentities.map(identity => {
    return getDisplayedPolicyIdentity(identity)
  })

  return {
    excludedIdentities,
    dataLabels: policy.parameters.dataLabels,
    dataset: policy.parameters.dataset,
    restriction: policy.parameters.restriction,
  }
}

export default useDataFirewallSummary
