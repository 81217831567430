import * as React from 'react'
import { styled } from '@mui/material/styles'
import MuiAlert from '@mui/material/Alert'

export const InfoAlert = styled(
  props => <MuiAlert {...props} severity="info" />,
  {}
)(({ theme }) => ({
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.cyralColors.primary[100],

  '& .MuiAlert-icon': {
    color: 'inherit',
  },
  '& .MuiAlert-message': {
    ...theme.typography.h6,
  },
}))

export const WarningAlert = styled(
  props => <MuiAlert {...props} severity="warning" />,
  {}
)(({ theme }) => ({
  color: theme.palette.cyralColors.yellow[500],
  backgroundColor: theme.palette.cyralColors.yellow[100],

  '& .MuiAlert-icon': {
    color: 'inherit',
  },

  '& .MuiAlert-message': {
    ...theme.typography.h6,
  },
}))
