import * as React from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

const getRoleDisplayName = role => {
  const ARNArray = role?.credentials?.roleARN?.split(':')
  const roleNameFromARN = `${ARNArray?.[4]}:${ARNArray?.[5]}`
  if (roleNameFromARN === role?.name) {
    return ARNArray?.[5]?.replace('role/', '')
  } else {
    return role?.name
  }
}

const RoleSelectItem = ({ role, abbreviate }) => {
  const abbreviatedTextStyling = abbreviate
    ? { maxWidth: '300px', textOverflow: 'ellipsis', overflow: 'hidden' }
    : {}
  return (
    <Stack>
      <Typography
        variant="h6"
        sx={{
          ...abbreviatedTextStyling,
        }}
      >
        {getRoleDisplayName(role)}
      </Typography>
      <Typography
        variant="body2"
        sx={{
          ...abbreviatedTextStyling,
        }}
      >
        {role?.credentials?.roleARN || ''}
      </Typography>
    </Stack>
  )
}

export default RoleSelectItem
