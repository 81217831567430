import React from 'react'
import { styled } from '@mui/material/styles'

export const Pre = styled(
  'pre',
  {}
)(({ theme }) => ({
  ...theme.typography.code,

  position: 'relative',

  border: `1px solid ${theme.palette.cyralColors.grey[200]}`,
  borderRadius: theme.radii.base,

  padding: theme.spacing(1, 2),
  margin: 0,
  // overflow-x: auto;
}))
