import { useFormContext } from 'react-hook-form'
import { FormControl, FormHelperText } from '@mui/material'
import { Input, InputLabel } from '@jeeves/new-components'

const AliasInput = () => {
  const {
    formState: { errors },
    register,
  } = useFormContext()

  return (
    <FormControl variant="standard" error={Boolean(errors?.alias)}>
      <InputLabel htmlFor="alias">Endpoint alias</InputLabel>
      <Input
        id="alias"
        inputProps={{
          ...register('alias'),
        }}
      />
      <FormHelperText
        sx={{
          typography: 'body2',
        }}
      >
        {errors?.alias?.message}
      </FormHelperText>
    </FormControl>
  )
}

export default AliasInput
