import * as React from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'

import { Input, InputLabel } from '@jeeves/new-components'

const UserAccountName = ({ repoType, nextStep, setDatabaseAccountInfo, defaultValues, footer }) => {
  const methods = useForm({
    defaultValues,
  })

  const { register, handleSubmit, formState } = methods
  const { errors } = formState

  React.useEffect(() => {
    if (formState.isSubmitSuccessful) {
      nextStep()
    }
  }, [formState, nextStep])

  const onSubmit = ({ username, authenticationDatabase }) => {
    setDatabaseAccountInfo(info => {
      return {
        ...info,
        name: username,
        ...(authenticationDatabase && {
          authenticationDatabase,
        }),
      }
    })
  }

  return (
    <FormProvider {...methods}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4}>
          <FormControl variant="standard" error={Boolean(errors.username)}>
            <InputLabel htmlFor="username">
              {repoType === 's3' ? 'IAM Role Name' : 'Database Account Username'}
            </InputLabel>
            <Input
              id="username"
              inputProps={{ ...register('username', { required: 'This field is required.' }) }}
              fullWidth
            />
            <FormHelperText
              sx={{
                typography: 'body2',
              }}
              error={!!errors?.username}
            >
              {errors?.username?.message ||
                (repoType !== 's3' &&
                  'Username must be an exact match with an existing account in your database.')}
            </FormHelperText>
          </FormControl>

          {repoType === 'mongodb' && (
            <FormControl variant="standard" error={Boolean(errors.authenticationDatabase)}>
              <InputLabel htmlFor="authenticationDatabase">Authentication Database</InputLabel>
              <Input
                id="authenticationDatabase"
                inputProps={{
                  ...register('authenticationDatabase', {
                    required: 'This field is required.',
                  }),
                }}
                defaultValue="admin"
                fullWidth
              />

              {errors.authenticationDatabase && (
                <FormHelperText
                  sx={{
                    typography: 'body2',
                  }}
                  error
                >
                  {errors.authenticationDatabase.message}
                </FormHelperText>
              )}
            </FormControl>
          )}

          {footer}
        </Stack>
      </Box>
    </FormProvider>
  )
}

export default UserAccountName
