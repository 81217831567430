/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import styled from '@emotion/styled'
import { Card as MaterialCard, CardActions, CardContent, Typography } from '@material-ui/core/'
import SettingsIcon from '@material-ui/icons/Settings'
import AddIcon from '@material-ui/icons/AddCircleOutline'

import { Button } from '@jeeves/components/Primitives'
import { useAppConfig } from '@jeeves/hooks'
import CardCategory from './CardCategory'

const ConfigureButton = ({ onClick, isDisabled, isConfigured = false }) => (
  <Button
    size="small"
    color="primary"
    variant="contained"
    onClick={onClick}
    disabled={isDisabled}
    css={t => css`
      margin: 0 auto;

      ${isConfigured &&
        css`
          background-color: ${t.palette.green.main};
          color: white;
          &:focus,
          &:active,
          &:hover {
            background-color: ${t.palette.green.dark};
          }
        `}
    `}
  >
    {isConfigured ? (
      <SettingsIcon fontSize="small" style={{ marginRight: '8px' }} />
    ) : (
      <AddIcon fontSize="small" style={{ marginRight: '8px' }} />
    )}
    {isConfigured ? 'Configure' : 'Setup'}
  </Button>
)

const IntegrationSummary = ({ children }) => (
  <Typography
    variant="caption"
    color="textSecondary"
    component="p"
    css={css`
      min-height: 40px;
      width: 100%;
      margin: 0 auto 32px auto;
      white-space: pre-line;
    `}
  >
    {children}
  </Typography>
)

const WrapperCard = styled(MaterialCard)`
  color: white;
  height: 100%;
  padding: 24px;
  position: relative;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  border-radius: 10px;
  overflow: hidden;
  min-height: 300px;
`

const IntegrationLogo = ({ src, alt }) => (
  <div
    css={css`
      display: flex;
      height: auto;
      justify-content: center;
      align-items: center;
      max-width: 200px;
      max-height: 75px;
      margin: 0 auto;
    `}
  >
    <img
      css={css`
        max-height: 60px;
        max-width: 150px;
        display: block;
        margin: 20px auto;
      `}
      alt={alt}
      src={src}
    />
  </div>
)

const Card = ({ metadata, handleOnClick, isConfigured }) => {
  const {
    config: { isDemo },
  } = useAppConfig()
  const getIsDisabled = () => !metadata.enabled && !isDemo

  return (
    <WrapperCard elevation={0} disabled={getIsDisabled()}>
      <CardCategory category={metadata.category} />
      <IntegrationLogo src={metadata.logo} alt={metadata.name} />

      <CardContent
        css={() => css`
          top: 90px;
          left: 0;
        `}
      >
        {/* <Typography
          css={() => css`
            font-weight: bold;
            color: #061748;
          `}
        >
          {integration.name}
        </Typography> */}
        <Typography
          css={() => css`
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.4px;
            text-transform: uppercase;
            // color: #9091a3;
            margin-bottom: 16px;
          `}
        >
          {metadata.category}
        </Typography>
        <IntegrationSummary>{metadata.summary}</IntegrationSummary>
      </CardContent>

      <CardActions
        css={() => css`
          width: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
          padding-bottom: 16px;
        `}
      >
        <ConfigureButton
          onClick={handleOnClick}
          isDisabled={getIsDisabled()}
          isConfigured={isConfigured}
        />
      </CardActions>
    </WrapperCard>
  )
}

export default Card
