import React from 'react'
import { styled } from '@mui/material/styles'
import MuiInputBase from '@mui/material/InputBase'

const Input = styled(MuiInputBase, {
  shouldForwardProp: prop => prop !== 'size',
})(({ theme, size = 'medium', ...props }) => ({
  border: `1px solid ${theme.palette.cyralColors.grey[200]}`,
  borderRadius: theme.radii.base,
  overflow: 'hidden',
  transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow'], {
    duration: theme.transitions.duration.shorter,
  }),

  'label + &': {
    marginTop: theme.spacing(1),
  },

  '&.Mui-focused': {
    borderColor: theme.palette.primary.main,
    boxShadow: `${theme.palette.primary.main} 0 0 0 1px`,
  },

  '&.Mui-error': {
    borderColor: theme.palette.error.main,

    '&.Mui-focused': {
      boxShadow: `${theme.palette.error.main} 0 0 0 1px`,
    },
  },

  ...(size === 'medium' && {
    ...theme.typography.body2,
    // height: '20px',
    // padding: '7px 12px',
  }),

  '& .MuiInputBase-input': {
    position: 'relative',
    backgroundColor: theme.palette.common.white,

    ...(size === 'medium' && {
      // ...theme.typography.body2,
      height: '20px',
      padding: '7px 12px',
    }),

    '&.MuiInputBase-inputAdornedEnd': {
      paddingRight: theme.spacing(0),
    },
    '&.MuiInputBase-inputAdornedStart': {
      paddingLeft: theme.spacing(0),
    },
  },
}))

Input.id = 'Input'

export default Input
