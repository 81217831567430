import { useMutation } from '@apollo/client'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'

import {
  parseISO8601DurationToTimeValidity,
  formatTimeValidityToISO8601Duration,
} from '@jeeves/utils/duration'
import { FragmentType, graphql, useFragment } from '@jeeves/graphql'
import { AccessTokenConfiguration } from '@jeeves/graphql/graphql'
import { getGraphQLErrorMessage } from '@jeeves/utils/helpers'
import { useToast } from '@jeeves/new-components'

import { AccessTokensFormSchema, accessTokensFormSchema } from './schemas'

const useAccessTokens_QueryFragment = graphql(`
  fragment useAccessTokens_QueryFragment on Query {
    accessTokenConfiguration {
      defaultDuration
      maxDuration
      maxNumberOfTokensPerUser
      offlineTokenValidation
    }
  }
`)

const UPDATE_ACCESS_TOKEN_CONFIGURATION = graphql(`
  mutation UpdateAccessTokenConfiguration($input: UpdateAccessTokenConfigurationInput!) {
    updateAccessTokenConfiguration(input: $input) {
      accessTokenConfiguration {
        defaultDuration
        maxDuration
        maxNumberOfTokensPerUser
        offlineTokenValidation
      }
    }
  }
`)

const useAccessTokens = ({
  query: queryProp,
}: {
  query: FragmentType<typeof useAccessTokens_QueryFragment>
}) => {
  const query = useFragment(useAccessTokens_QueryFragment, queryProp)

  const { toast } = useToast()

  const [updateAccessTokenConfiguration, { loading }] = useMutation(
    UPDATE_ACCESS_TOKEN_CONFIGURATION,
    {
      onError: error => {
        toast({
          variant: 'error',
          description:
            getGraphQLErrorMessage(error) ||
            'An error occurred while updating your access token settings.',
        })
      },
      onCompleted: data => {
        const updatedAccessTokenConfiguration =
          data.updateAccessTokenConfiguration?.accessTokenConfiguration
        if (updatedAccessTokenConfiguration) {
          methods.reset(getDefaultValues(updatedAccessTokenConfiguration))
        }
      },
    }
  )

  const getDefaultValues = (accessTokenConfiguration: AccessTokenConfiguration) => {
    return {
      maxTokenValidity: parseISO8601DurationToTimeValidity(accessTokenConfiguration.maxDuration),
      defaultTokenValidity: parseISO8601DurationToTimeValidity(
        accessTokenConfiguration.defaultDuration
      ),
      maxValidTokens: accessTokenConfiguration.maxNumberOfTokensPerUser,
      useOfflineTokenValidation: accessTokenConfiguration.offlineTokenValidation,
    }
  }

  const methods = useForm<AccessTokensFormSchema>({
    defaultValues: getDefaultValues(query.accessTokenConfiguration),
    resolver: zodResolver(accessTokensFormSchema),
  })

  const onSubmit = async ({
    maxTokenValidity,
    defaultTokenValidity,
    maxValidTokens,
    useOfflineTokenValidation,
  }: AccessTokensFormSchema) => {
    await updateAccessTokenConfiguration({
      variables: {
        input: {
          defaultDuration: formatTimeValidityToISO8601Duration(defaultTokenValidity),
          maxDuration: formatTimeValidityToISO8601Duration(maxTokenValidity),
          maxNumberOfTokensPerUser: maxValidTokens,
          offlineTokenValidation: useOfflineTokenValidation,
        },
      },
    })
  }

  return {
    loading,
    methods,
    onSubmit: methods.handleSubmit(onSubmit),
  }
}

export default useAccessTokens
