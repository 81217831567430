import { Box, Stack, Typography } from '@mui/material'
import LaunchIcon from '@mui/icons-material/Launch'

import { CodeBlock } from '@jeeves/new-components'
import { getDocsURL } from '@jeeves/utils/docsURL'

const ViewDocsLink = () => (
  <Box
    component="a"
    href={getDocsURL({ docsPath: 'data-repos/apps-and-bi-tools/custom' })}
    target="_blank"
    rel="noreferrer"
    sx={{
      display: 'inline-flex',
      gap: '2px',
      alignItems: 'center',
      textDecoration: 'none',
    }}
  >
    <Typography variant="h4" sx={{ color: 'primary.main' }}>
      view our docs
    </Typography>
    <LaunchIcon sx={{ color: 'primary.main', fontSize: '16px' }} />
  </Box>
)

const SQL_COMMAND_FORMAT =
  '/* CyralContext {"user":"some end user","userGroup":"some group","serviceName":"some application","attributes":{"item1":"value1"}} */'

const CustomConfiguration = () => {
  return (
    <Stack spacing={2}>
      <Typography variant="h4" sx={{ color: 'text.secondary' }}>
        Configure your custom connection driver.
      </Typography>

      <Stack spacing={2} sx={{ pl: 1 }}>
        <Typography variant="body1" sx={{ color: 'text.primary' }}>
          Make the following changes to your application:
        </Typography>

        <Typography variant="body1" sx={{ color: 'text.primary' }}>
          Include a SQL comment in the following format as a prefix to any SQL query executed by the
          corresponding application. The SQL comment should include end-user context, such as user
          name and user group information.
        </Typography>

        <Stack spacing={1} sx={{ pl: 3 }}>
          <Typography variant="h6" sx={{ color: 'text.secondary' }}>
            Cyral Context comment format
          </Typography>

          <CodeBlock>{SQL_COMMAND_FORMAT}</CodeBlock>

          <Typography variant="body1" sx={{ color: 'text.secondary' }}>
            Comments can be multi-line or single-line.
          </Typography>
        </Stack>
      </Stack>

      <Typography variant="body1" sx={{ color: 'text.primary' }}>
        For more information, <ViewDocsLink />
      </Typography>
    </Stack>
  )
}
export default CustomConfiguration
