import * as React from 'react'
import { useMutation, gql } from '@apollo/client'
import { useForm } from 'react-hook-form'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import DialogContent from '@mui/material/DialogContent'
import FormControl from '@mui/material/FormControl'
import DialogTitle from '@mui/material/DialogTitle'

import { Input, InputLabel, Button, Dialog, DialogActions } from '@jeeves/new-components'
import { useRepositoryDetailContext } from '@jeeves/pages/RepositoryDetail/contexts/RepositoryDetailContext'

import { APPROVAL_FIELDS } from '../useApprovalDetailQuery'

const GRANT_APPROVAL = gql`
  mutation GrantApproval($repoId: ID!, $approvalId: ID!, $comment: String) {
    grantApproval(repoId: $repoId, approvalId: $approvalId, comment: $comment) {
      grantedApproval {
        ...approvalFields
        effectiveStartDate
        pendingAmendment {
          ...approvalFields
        }
      }
    }
  }
  ${APPROVAL_FIELDS}
`

const GrantApprovalDialog = ({ open, closeDialog, ssoUser, approvalId }) => {
  const { repoId } = useRepositoryDetailContext()
  const {
    reset,
    register,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      reason: '',
    },
  })
  const [grantApproval, { data, loading, error }] = useMutation(GRANT_APPROVAL, {
    onCompleted(data) {
      closeDialog()
    },
    update(cache, { data: { grantApproval } }) {
      // If granted item was a PendingApproval, remove it from the cache since it's now
      // being replaced by a GrantedApproval.
      const cacheId = cache.identify({
        id: grantApproval.grantedApproval.id,
        __typename: 'PendingApproval',
      })

      cache.evict({ id: cacheId })
      cache.gc()
    },
  })

  const onSubmit = async ({ reason }) => {
    try {
      await grantApproval({
        variables: {
          repoId,
          approvalId,
          comment: reason,
        },
      })
    } catch (error) {}
  }

  return (
    <Dialog open={open} onClose={closeDialog}>
      <DialogTitle variant="h3">Grant Approval</DialogTitle>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Stack spacing={2}>
            <Typography variant="body1" sx={{ color: 'text.secondary' }}>
              Are you sure you want to grant this approval request for{' '}
              <Typography component="span" variant="h6">
                {ssoUser}
              </Typography>
              ?
            </Typography>

            <FormControl variant="standard">
              <InputLabel>Reason</InputLabel>
              <Input
                id="description-input"
                inputProps={{
                  ...register('reason'),
                }}
                multiline
                rows={5}
                placeholder="e.g. Approved for contract period."
              />
            </FormControl>
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button variant="text" onClick={closeDialog}>
            Cancel
          </Button>
          <Button type="submit" loading={isSubmitting}>
            Grant
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default GrantApprovalDialog
