/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'

import React, { Fragment } from 'react'
import { Table, IconButton, TableRow, TableCell } from '@material-ui/core'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import EventTimelineBody from './EventTimelineBody'
import EventRow from './EventRow'

import Loading from '@jeeves/components/Loading'
import RowSpan from '@jeeves/components/RowSpan'
import { TableHead, TablePagination } from '@jeeves/components/Table'

const COLUMNS = [
  { id: 'expand', numeric: false, disablePadding: true, label: '' },
  { id: 'action', numeric: false, disablePadding: false, label: 'Action' },
  { id: 'user', numeric: false, disablePadding: false, label: 'User' },
  // { id: 'mfa', numeric: false, label: '2FA' },
  { id: 'description', numeric: false, disablePadding: false, label: 'Description' },
  // { id: 'location', numeric: false, disablePadding: false, label: 'Location' },
  { id: 'time', numeric: false, disablePadding: false, label: 'Time' },
]

const EventTimeline = ({ children, page, onClickForward, onClickBackward, nextPageExists }) => {
  return (
    <Fragment>
      <Table>
        <TableHead
          // order={order}
          // orderBy={orderBy}
          // onRequestSort={handleRequestSort}
          columns={COLUMNS}
          // rowCount={}
        />
        <EventTimelineBody>{children}</EventTimelineBody>
      </Table>
      <div css={{ display: 'flex', justifyContent: 'flex-end', marginRight: '24px' }}>
        <IconButton disabled={page === 0} onClick={onClickBackward}>
          <ArrowBackIosIcon css={{ fontSize: '16px' }} />
        </IconButton>
        <IconButton disabled={!nextPageExists} onClick={onClickForward}>
          <ArrowForwardIosIcon css={{ fontSize: '16px' }} />
        </IconButton>
      </div>
    </Fragment>
  )
}

// TODO: Need to style ErrorRow and EmptyEventsRow
const ErrorRow = ({ children, colSpan = COLUMNS.length }) => {
  return <RowSpan colSpan={colSpan}>{children}</RowSpan>
}

const EmptyEventsRow = ({ children, colSpan = COLUMNS.length }) => {
  return <RowSpan colSpan={colSpan}>{children}</RowSpan>
}

EventTimeline.ErrorRow = ErrorRow
EventTimeline.EmptyEventsRow = EmptyEventsRow
EventTimeline.EventRow = EventRow

export default EventTimeline
