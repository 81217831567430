import { Box, Stack } from '@mui/material'

import { FragmentType, graphql, useFragment } from '@jeeves/graphql'
import { SearchInput } from '@jeeves/new-components'

import { TagCard, TagModal } from './components'
import useTags from './useTags'

export const Tags_QueryFragment = graphql(`
  fragment Tags_QueryFragment on Query {
    ...useTagsQueryFragment
  }
`)

interface Props {
  query: FragmentType<typeof Tags_QueryFragment>
}

const Tags = ({ query: queryProp }: Props) => {
  const query = useFragment(Tags_QueryFragment, queryProp)

  const {
    filteredTagsMap,
    selectedTag,
    selectedDatalabels,
    modalOpen,
    closeModal,
    onSearch,
    openModal,
  } = useTags({ query })

  return (
    <>
      <Stack spacing={4}>
        <SearchInput sx={{ width: '368px' }} onChange={onSearch} placeholder="Search tags" />

        <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 2 }}>
          {Object.entries(filteredTagsMap).map(([tag, datalabels]) => (
            <TagCard key={tag} datalabels={datalabels} tag={tag} openModal={openModal} />
          ))}
        </Box>
      </Stack>

      <TagModal
        modalOpen={modalOpen}
        selectedDatalabels={selectedDatalabels}
        selectedTag={selectedTag}
        closeModal={closeModal}
      />
    </>
  )
}

export default Tags
