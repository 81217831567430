import { IdentityProvider as IdentityProviderType } from '@jeeves/graphql/graphql'

type IdentityProvider = Pick<IdentityProviderType, 'id'>

export const LOCAL_USER_IDP = 'cyral-auth0'
export const CYRAL_ENGINEERING_IDP = 'cyral-engineering'

export const isLocalUser = (identityProviders: IdentityProvider[]) =>
  identityProviders.length === 0 || identityProviders.some(idp => idp.id === LOCAL_USER_IDP)

export const isCyralEngineeringUser = (identityProviders: IdentityProvider[]) =>
  identityProviders.some(idp => idp.id === CYRAL_ENGINEERING_IDP)
