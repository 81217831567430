/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Fragment } from 'react'

import { Typography } from '@material-ui/core'
import { IconCopyButton } from '@jeeves/components/CopyButton'

const CopyValue = ({ label, value, flex }) => (
  <Fragment>
    {label ? (
      <Typography
        css={t => css`
          min-width: 80px;
          font-family: ${t.fontFamily};
          font-weight: bold;
          align-self: center;
          justify-self: stretch;
        `}
      >
        {label}
      </Typography>
    ) : (
      <div></div>
    )}

    <pre
      css={t => css`
        flex-grow: ${flex ? '1' : '0'};
        font-size: ${t.fontSize[3]};
        font-family: ${t.typography.monospaced};
        background-color: #efefef;
        padding: 8px;
        border-radius: 4px;
        margin: 0;
        overflow-x: auto;
        align-self: center;
        justify-self: stretch;
      `}
    >
      {value}
    </pre>
    <IconCopyButton css={{ marginLeft: '12px', alignSelf: 'center' }} copyText={value} />
  </Fragment>
)

export default CopyValue
