import * as React from 'react'
import Box from '@mui/material/Box'

import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import Button from '@mui/material/Button'

import { RecentRunCard } from './components'
import { PendingRecommendations } from '../Mappings/Recommendations/PendingRecommendation/PendingRecommendations'
import {
  RecommendationsContainer,
  RecommendationsHeader,
} from '@jeeves/pages/RepositoryDetail/Tabs/Mappings/Recommendations/Recommendations.styles.js'
import { useQuery, gql } from '@apollo/client'
import { getDocsURL } from '@jeeves/utils/docsURL'

const FETCH_RECOMMENDATIONS = gql`
  query Repo_DatamapRecommendations($repoId: String!, $query: DatamapRecommendationsForRepoQuery) {
    repo(id: $repoId) {
      id
      name
      datamapRecommendations(query: $query) {
        id
        status
        attribute {
          parsed
        }
        source
        createdAt
        updatedAt
        datalabel {
          name
        }
      }
    }
  }
`

const FETCH_RUNS = gql`
  query AutoUpdatesForRepo($repoId: String!, $query: WorkerRunsQuery) {
    workerRuns(repoId: $repoId, query: $query) {
      runId
      workerId
      source
      state
      error
      createdAt
      updatedAt
      data
      modes
    }
  }
`

const NotificationBanner = ({ text }) => (
  <RecommendationsContainer
    sx={{
      mb: 2,
      backgroundColor: 'cyralColors.primary.100',

      pb: 1,
    }}
  >
    <RecommendationsHeader sx={{ mb: 1.5 }}>
      <Stack direction="row">
        <InfoOutlinedIcon
          sx={{
            color: 'cyralColors.primary.300',
          }}
        />

        <Typography
          variant="h6"
          sx={{
            alignSelf: 'center',
            ml: 1,
            color: 'cyralColors.primary.300',
          }}
        >
          {text}
        </Typography>
      </Stack>
    </RecommendationsHeader>
  </RecommendationsContainer>
)

export const AutoUpdates = ({ repoId, repoType }) => {
  const {
    loading: recommendationsLoading,
    error: recommendationsError,
    data: recommendationsData,
  } = useQuery(FETCH_RECOMMENDATIONS, {
    variables: {
      repoId: repoId,
      query: {
        status: 'DISMISSED',
        page: 1,
        itemsPerPage: 50,
      },
    },
  })

  const {
    loading: runsLoading,
    error: runsError,
    data: runsData,
  } = useQuery(FETCH_RUNS, {
    variables: {
      repoId: repoId,
      query: {
        modes: ['DATA_CLASSIFICATION'],
      },
    },
  })

  const SUPPORTED_AUTODATAMAP_REPOS = [
    'postgresql',
    'redshift',
    'denodo',
    'snowflake',
    'mysql',
    'sqlserver',
    'oracle',
  ]

  if (!SUPPORTED_AUTODATAMAP_REPOS.includes(repoType)) {
    return (
      <Box sx={{ px: 8, pt: 4, minHeight: '600px' }}>
        <Typography variant="h3" sx={{ color: 'cyralColors.grey.500', mb: 2 }}>
          Auto Updates
        </Typography>
        <NotificationBanner text="Automatic Data Maps are not availiable for this repository type"></NotificationBanner>
      </Box>
    )
  }

  if (runsData && runsData.workerRuns && runsData.workerRuns.length < 1) {
    return (
      <Box sx={{ px: 8, pt: 4, minHeight: '600px' }}>
        <Typography variant="h3" sx={{ color: 'cyralColors.grey.500', mb: 2 }}>
          Auto Updates
        </Typography>
        <NotificationBanner text="Set up your Automatic Data Maps to access this feature."></NotificationBanner>
        <a href={getDocsURL({ docsPath: 'policy/repo-crawler/use-auto-mapping' })} target="_blank">
          <Button variant="contained">View Docs</Button>
        </a>
      </Box>
    )
  }

  return (
    <Box sx={{ px: 8, pt: 4, minHeight: '600px' }}>
      <Typography variant="h3" sx={{ color: 'cyralColors.grey.500', mb: 2 }}>
        Auto Updates
      </Typography>

      <Typography variant="h4" sx={{ color: 'cyralColors.grey.400', mb: 4 }}>
        Recent Runs
      </Typography>

      {runsData && runsData?.workerRuns.map(run => <RecentRunCard run={run}></RecentRunCard>)}

      <Typography variant="h4" sx={{ color: 'cyralColors.grey.400', my: 4 }}>
        Rejected Mapping Suggestions
      </Typography>

      {recommendationsData && (
        <PendingRecommendations
          repoId={repoId}
          repoType={repoType}
          recommendations={recommendationsData.repo.datamapRecommendations
            .slice(0, 10)
            .filter(recommendation => recommendation.status === 'DISMISSED')}
          recommendationStatus="DISMISSED"
        ></PendingRecommendations>
      )}
    </Box>
  )
}
