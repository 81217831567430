/** @jsxRuntime classic */
/** @jsx jsx */
import styled from '@emotion/styled'
import { css, jsx } from '@emotion/react'
import React, { useEffect, useState } from 'react'
import CopyButton from '@jeeves/components/CopyButton'
import { Typography } from '@material-ui/core/'
import { grey } from '@material-ui/core/colors'
import { FileCopy } from '@material-ui/icons'
import { get as lodashGet } from 'lodash'
import { XMLParser } from 'fast-xml-parser'

const FieldDetailsWrapper = styled.div`
  width: 100%;
  margin-bottom: 1rem;
  // margin-top: 1rem;
  background-color: ${grey[200]};
  padding: 15px;
  border-radius: 4px;
`

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 5px 0;
`

const FieldLabel = styled(Typography)`
  font-weight: 500;
  width: 35%;
`

const FieldValue = styled(Typography)`
  font-family: 'monospace', monospace;
  width: 65%;
  overflow-wrap: break-word;
`

const StyledCopyButton = styled(CopyButton)`
  position: relative;
  cursor: pointer;
  top: 3px;
  left: 5px;
  font-size: 16px;
  color: ${grey[500]};
  transition: color 0.1s linear;
  &:hover {
    color: ${grey[800]};
  }
`

const parseXML = xml => {
  const options = {
    attributeNamePrefix: '@_',
    attrNodeName: 'attr', // default is 'false'
    textNodeName: '#text',
    ignoreAttributes: false,
    ignoreNameSpace: false,
    parseNodeValue: true,
    trimValues: true,
    cdataTagName: '__cdata', // default is 'false'
    cdataPositionChar: '\\c',
    parseTrueNumberOnly: false,
    arrayMode: false, // "strict"
  }

  const parser = new XMLParser(options)

  try {
    return parser.parse(xml, true)
  } catch (err) {
    throw err
  }
}

const getIdpId = SAMLMetadata => {
  const SAMLMetadataJSON = parseXML(SAMLMetadata)

  const ssoURL = SAMLMetadataJSON['md:EntityDescriptor']['@_entityID']
  return new URL(ssoURL).searchParams.get('idpid')
}

const Field = ({ label, children, showCopy = false }) => (
  <FieldWrapper>
    <FieldLabel>{label}</FieldLabel>
    <FieldValue>
      {children}
      {showCopy && (
        <StyledCopyButton copyText={children}>
          <FileCopy fontSize="small" />
        </StyledCopyButton>
      )}
    </FieldValue>
  </FieldWrapper>
)

const FieldDetails = ({ samlMetadata }) => {
  const [idpId, setIdpId] = useState('')
  const domain = lodashGet(window, '_env_.domain')
  const realmName = lodashGet(window, '_env_.auth_tenant')

  useEffect(() => {
    if (samlMetadata && samlMetadata.length > 0 && samlMetadata[0] !== undefined) {
      const samlMetadataFile = samlMetadata[0]

      samlMetadataFile
        .text()
        .then(samlMetaDataFileContent => {
          setIdpId(getIdpId(samlMetaDataFileContent))
        })
        .catch(e => {
          console.error(e)
        })
    }
  }, [samlMetadata])

  return (
    <FieldDetailsWrapper>
      <Field label="ACS URL" showCopy>
        {`https://${domain}:8000/auth/realms/${realmName}/broker/gsuite.${idpId}/endpoint`}
      </Field>
      <Field label="Entity ID" showCopy>
        {`https://${domain}:8000/auth/realms/${realmName}`}
      </Field>
    </FieldDetailsWrapper>
  )
}

export default FieldDetails
