import { z } from 'zod'

import { timeValiditySchema, numberSchema } from '@jeeves/utils/schemas'

export const accessTokensFormSchema = z.object({
  maxTokenValidity: timeValiditySchema,
  defaultTokenValidity: timeValiditySchema,
  maxValidTokens: numberSchema,
  useOfflineTokenValidation: z.boolean(),
})

export type AccessTokensFormSchema = z.infer<typeof accessTokensFormSchema>
