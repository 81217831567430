import * as React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { debounce } from 'lodash'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { Accordion, AccordionSummary, AccordionDetails, SearchInput } from '@jeeves/new-components'

import PendingApprovalsList from './PendingApprovalsList'

const PendingApprovalsAccordion = ({
  pendingApprovalsConnection,
  fetchMore,
  loadingNextPage,
  refetching,
  setSearchValue,
}) => {
  const nodes = pendingApprovalsConnection?.edges?.map(edge => edge.node)
  const numberOfPendingApprovals = nodes?.length || 0

  const debouncedSearch = React.useMemo(() => {
    return debounce(value => setSearchValue(value), 300)
  }, [setSearchValue])

  return (
    <Box>
      <Accordion
        sx={{
          bgcolor: 'cyralColors.primary.100',
        }}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography variant="h4" sx={{ color: 'primary.main' }}>
            New Approval Requests
          </Typography>
        </AccordionSummary>

        <AccordionDetails>
          <Stack spacing={2}>
            <Box>
              <SearchInput
                onChange={e => debouncedSearch(e.target.value)}
                sx={{
                  bgcolor: 'cyralColors.white',
                }}
              />
            </Box>

            <Box
              sx={{
                height: '300px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {refetching ? (
                <Stack spacing={2} sx={{ alignItems: 'center' }}>
                  <CircularProgress size={64} />
                  <Typography variant="h6" sx={{ color: 'text.secondary' }}>
                    Searching...
                  </Typography>
                </Stack>
              ) : numberOfPendingApprovals > 0 ? (
                <PendingApprovalsList
                  loadingNextPage={loadingNextPage}
                  hasNextPage={pendingApprovalsConnection.pageInfo.hasNextPage}
                  nodes={nodes}
                  loadNextPage={() =>
                    fetchMore({
                      variables: {
                        after: pendingApprovalsConnection.pageInfo.endCursor,
                      },
                    })
                  }
                />
              ) : (
                <Box>
                  <Typography variant="h6" sx={{ color: 'text.secondary' }}>
                    No results found.
                  </Typography>
                </Box>
              )}
            </Box>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

export default PendingApprovalsAccordion
