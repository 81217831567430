/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import React from 'react'
import styled from '@emotion/styled'
import { Card as MaterialCard, CardContent, Paper, Typography } from '@material-ui/core'
import { SectionTitle, Feature } from './styles'

const StyledFeature = styled(Feature)`
  border: 2px solid #26459e;
  border-radius: 4px;
  padding: 6px;
  text-align: center;
`

const StyledCard = ({ selected, children }) => {
  return (
    <MaterialCard
      css={() => css`
        color: white;
        padding: 18px;
        ${selected ? 'flex-grow: 1;' : 'height: 100%;'}
        position: relative;
        height: 100%;
        min-height: 300px;
        border-radius: 10px;
        overflow: hidden;
        margin-bottom: 8px;
        border: ${selected ? `5px` : `0px`} dashed #26459e;
      `}
      elevation={0}
    >
      {children}
    </MaterialCard>
  )
}

const StyledTypography = styled(Typography)`
  color: white;
`

const SectionHeader = ({ name, subtitle }) => {
  return (
    <div
      css={{
        backgroundColor: '#26459e',
        padding: '6px',
        borderRadius: '4px',
      }}
    >
      <StyledTypography align="center">{<strong>{name}</strong>}</StyledTypography>
      <StyledTypography align="center">{subtitle}</StyledTypography>
    </div>
  )
}

export const Plan = ({ license, name, features, subtitle, isSelected }) => {
  return (
    <StyledCard selected={isSelected(name)}>
      <SectionHeader name={name} subtitle={subtitle}></SectionHeader>
      <div
        css={{
          marginTop: '6px',
        }}
      >
        {features.map(feature => (
          <StyledFeature>{feature}</StyledFeature>
        ))}
      </div>
    </StyledCard>
  )
}
