import { Link, useParams } from 'react-router-dom'
import { Box, CircularProgress, Stack, Typography } from '@mui/material'

import { useAuth } from '@jeeves/components/Auth'
import { Button } from '@jeeves/new-components'
import { PoliciesList } from './components/PoliciesList'
import { EmptyState } from './components/EmptyState'
import { ErrorState } from './components/ErrorState'
import { usePoliciesMain } from './usePoliciesMain'

const PoliciesMain = () => {
  const { id: repoId } = useParams()
  const { hasPermission } = useAuth()

  const { enabledPoliciesQuery, disabledPoliciesQuery } = usePoliciesMain()

  if (enabledPoliciesQuery.loading || disabledPoliciesQuery.loading) {
    return (
      <Stack spacing={2} sx={{ alignItems: 'center' }}>
        <CircularProgress size={64} />
        <Typography variant="h6" sx={{ color: 'text.secondary' }}>
          Loading policies...
        </Typography>
      </Stack>
    )
  }

  if (enabledPoliciesQuery.error || disabledPoliciesQuery.error) {
    return <ErrorState />
  }

  const getEnabledPolicies = () => {
    const edges = enabledPoliciesQuery.data.repo.policies.edges ?? []
    return edges.map(edge => edge.node)
  }

  const getDisabledPolicies = () => {
    const edges = disabledPoliciesQuery.data.repo.policies.edges ?? []
    return edges.map(edge => edge.node)
  }

  const enabledPolicies = getEnabledPolicies()
  const disabledPolicies = getDisabledPolicies()

  if (enabledPolicies.length === 0 && disabledPolicies.length === 0) {
    return <EmptyState />
  }

  return (
    <Box sx={{ mx: 8, my: 4 }}>
      <Stack spacing={4}>
        <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Add and manage policies for this data repo.
          </Typography>
          <Button
            component={Link}
            to={`/repositories/${repoId}/policies/new`}
            disabled={!hasPermission('policies:create')}
          >
            Add Policy
          </Button>
        </Stack>

        <Stack spacing={4}>
          <PoliciesList status="Enabled" list={enabledPolicies} />
          <PoliciesList status="Disabled" list={disabledPolicies} />
        </Stack>
      </Stack>
    </Box>
  )
}

export default PoliciesMain
