import { TableCell, TableHead, TableSortLabel } from '@mui/material'

import { useUsers } from '@jeeves/pages/Account/Users/context'

const headerCells = [
  {
    id: 'username' as const,
    label: 'Username',
  },
  {
    id: 'name' as const,
    label: 'Name',
  },
  {
    id: 'role' as const,
    label: 'Role',
  },
  {
    id: 'ssoGroups' as const,
    label: 'SSO Groups',
  },
]

const UsersTableHeader = () => {
  const { sortDirection, sortBy, onSort } = useUsers()

  return (
    <TableHead>
      <tr>
        <th></th>
        {headerCells.map(cell => {
          const active = sortBy === cell.id
          const direction = sortBy === cell.id ? sortDirection : 'asc'

          return (
            <TableCell key={cell.id}>
              <TableSortLabel active={active} direction={direction} onClick={() => onSort(cell.id)}>
                {cell.label}
              </TableSortLabel>
            </TableCell>
          )
        })}
        <th></th>
      </tr>
    </TableHead>
  )
}

export default UsersTableHeader
