/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import styled from '@emotion/styled'
import React, { useState } from 'react'
import { CardActions, CircularProgress } from '@material-ui/core/'
import { Button, FlexGrow } from '@jeeves/components/Primitives'
import { CheckBoxWithToolTip, TextFieldWithToolTip } from '../components'

const StyledButton = styled(Button)`
  box-shadow: none;
  border-radius: 20px;
  font-size: 12px;
  letter-spacing: 1px;
  margin-top: 12px;
  padding: 4px 0px;
  font-weight: bold;
  font-family: ${({ theme }) => theme.fontFamily};
`

const ButtonLoading = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
`

const ActionsContainer = styled(CardActions)`
  width: 100%;
  margin-top: 24px;
`

const UpdateButtonContainer = styled.div`
  position: relative;
`

const AdvancedContainer = styled.div`
  margin-top: 2rem;
`

const Edit = props => {
  const { handleSetEdit, putIntegration } = props

  const { integration } = props.integration
  const { samlSetting } = integration

  // standard configuration settings
  const [displayName, setDisplayName] = useState(samlSetting.samlp.displayName)
  const [signInEndpoint, setSignInEndpoint] = useState(
    samlSetting.samlp.config.singleSignOnServiceURL
  )
  const [signOutEndpoint, setSignOutEndpoint] = useState(
    samlSetting.samlp.config.singleLogoutServiceURL
  )
  const [cert, setCert] = useState(samlSetting.samlp.config.signingCertificate)
  const [disableValidateSignature, setDisableValidateSignature] = useState(
    samlSetting.samlp.config.disableValidateSignature
  )

  // additional configuration settings
  const [backChannelLogout, setBackChannelLogout] = useState(
    samlSetting.samlp.config.backChannelSupported
  )
  const [disableWantAuthnRequestsSigned, setDisableWantAuthnRequestsSigned] = useState(
    samlSetting.samlp.config.disableWantAuthnRequestsSigned
  )
  const [disableWantAssertionsSigned, setDisableWantAssertionsSigned] = useState(
    samlSetting.samlp.config.disableWantAssertionsSigned
  )
  const [wantAssertionsEncrypted, setWantAssertionsEncrypted] = useState(
    samlSetting.samlp.config.wantAssertionsEncrypted
  )
  const [disableForceAuthentication, setDisableForceAuthentication] = useState(
    samlSetting.samlp.config.disableForceAuthentication
  )
  const [allowedClockSkew, setAllowedClockSkew] = useState(
    samlSetting.samlp.config.allowedClockSkew
  )

  const [showAdvanced, setShowAdvanced] = useState(false)
  const [updateLoading, setUpdateLoading] = useState()

  const resetStandardConfigurations = () => {
    setDisplayName(samlSetting.samlp.displayName)
    setSignInEndpoint(samlSetting.samlp.config.singleSignOnServiceURL)
    setSignOutEndpoint(samlSetting.samlp.config.singleSignLogoutServiceURL)
    setCert(samlSetting.samlp.config.signingCertificate)
    setDisableValidateSignature(samlSetting.samlp.config.disableValidateSignature)
  }

  const resetAdditionalConfigurations = () => {
    setBackChannelLogout(samlSetting.samlp.config.backChannelSupported)
    setDisableWantAuthnRequestsSigned(samlSetting.samlp.config.disableWantAuthnRequestsSigned)
    setDisableWantAssertionsSigned(samlSetting.samlp.config.disableWantAssertionsSigned)
    setWantAssertionsEncrypted(samlSetting.samlp.config.wantAssertionsEncrypted)
    setDisableForceAuthentication(samlSetting.samlp.config.disableForceAuthentication)
    setAllowedClockSkew(samlSetting.samlp.config.allowedClockSkew)
  }

  const resetInput = () => {
    resetStandardConfigurations()
    resetAdditionalConfigurations()
  }

  const handleCancel = () => {
    handleSetEdit(false)
    resetInput()
  }

  const handleOnUpdate = async () => {
    try {
      setUpdateLoading(true)
      const integrationObj = {
        identityProvider: 'adfs-2016',
        ldapGroupAttribute: samlSetting.ldapGroupAttribute,
        samlp: {
          alias: samlSetting.samlp.alias,
          providerID: 'saml',
          disabled: false,
          firstBrokerLoginFlowAlias: 'SAML_First_Broker',
          displayName,
          storeToken: false,
          addReadTokenRoleOnCreate: false,
          trustEmail: false,
          linkOnly: false,
          internalID: samlSetting.samlp.internalID,
          config: {
            disableUsingJWKSUrl: false,
            syncMode: 'FORCE',
            nameIDPolicyFormat: samlSetting.samlp.config.nameIDPolicyFormat,
            principalType: 'SUBJECT',
            signatureType: 'RSA_SHA256',
            samlXmlKeyNameTranformer: 'KEY_ID',
            hideOnLoginPage: false,
            backChannelSupported: backChannelLogout,
            disableWantAuthnRequestsSigned,
            disableWantAssertionsSigned,
            wantAssertionsEncrypted,
            disableForceAuthentication,
            disableValidateSignature,
            singleSignOnServiceURL: signInEndpoint,
            singleLogoutServiceURL: signOutEndpoint,
            xmlSigKeyInfoKeyNameTransformer: 'KEY_ID',
            signingCertificate: cert,
            allowedClockSkew,
          },
        },
      }

      await putIntegration(integrationObj)
      handleSetEdit(false)
    } catch (e) {
    } finally {
      setUpdateLoading(false)
    }
  }

  return (
    <React.Fragment>
      <TextFieldWithToolTip
        onChange={e => setDisplayName(e.target.value.trim())}
        value={displayName}
        label="Display Name"
        helpText="Display name to be shown to users as a login option."
      />

      <TextFieldWithToolTip
        onChange={e => setSignInEndpoint(e.target.value.trim())}
        value={signInEndpoint}
        label="Sign-In URL"
        helpText="URL used to send authentication requests."
      />

      <TextFieldWithToolTip
        onChange={e => setSignOutEndpoint(e.target.value.trim())}
        value={signOutEndpoint}
        label="Sign-Out URL"
        helpText="URL used to send logout requests."
      />

      <TextFieldWithToolTip
        multiline
        rows={8}
        disabled={disableValidateSignature}
        label="X.509 Certificate"
        onChange={e => setCert(e.target.value.trim())}
        value={cert}
        helpText="The signing certificate used to validate signatures."
      />

      <CheckBoxWithToolTip
        checked={!disableValidateSignature}
        onChange={() => setDisableValidateSignature(!disableValidateSignature)}
        value="validateSignature"
        color="primary"
        label="Validate Signature"
        helpText="Defaults to true. Turning off means that ADFS could easily be spoofed."
      />

      <StyledButton onClick={() => setShowAdvanced(!showAdvanced)}>
        {showAdvanced ? 'Hide Advanced Settings' : 'Show Advanced Settings'}
      </StyledButton>

      {/* additional config */}
      {showAdvanced && (
        <AdvancedContainer>
          <CheckBoxWithToolTip
            checked={backChannelLogout}
            onChange={() => setBackChannelLogout(!backChannelLogout)}
            value="backChannelLogout"
            label="Back Channel Logout"
            helpText="Indicates whether or not the external IdP supports backchannel logout."
          />

          <CheckBoxWithToolTip
            checked={disableWantAuthnRequestsSigned}
            onChange={() => setDisableWantAuthnRequestsSigned(!disableWantAuthnRequestsSigned)}
            value="disableWantAuthnRequestsSigned"
            label="Wants AuthnRequests Unsigned"
            helpText="Indicates whether the identity provider expects a signed AuthnRequest."
          />

          <CheckBoxWithToolTip
            checked={disableWantAssertionsSigned}
            onChange={() => setDisableWantAssertionsSigned(!disableWantAssertionsSigned)}
            value="disableWantAssertionsSigned"
            label="Wants Assertions Unsigned"
            helpText="Indicates whether this service provider expects a signed Assertion."
          />

          <CheckBoxWithToolTip
            checked={wantAssertionsEncrypted}
            onChange={() => setWantAssertionsEncrypted(!wantAssertionsEncrypted)}
            value="wantAssertionsEncrypted"
            label="Wants Assertions Encrypted"
            helpText="Indicates whether this service provider expects an encrypted Assertion."
          />

          <CheckBoxWithToolTip
            checked={disableForceAuthentication}
            onChange={() => setDisableForceAuthentication(!disableForceAuthentication)}
            value="disableForceAuthentication"
            label="Disable Force Authentication"
            helpText="Indicates whether the identity provider must authenticate the presenter directly
            rather than rely on a previous security context."
          />

          <TextFieldWithToolTip
            css={{ marginTop: '1rem' }}
            onChange={e => setAllowedClockSkew(e.target.value.trim())}
            value={allowedClockSkew}
            label="Allowed Clock Skew"
            type="number"
            helpText="Clock skew in seconds that is tolerated when validating identity provider tokens."
          />
        </AdvancedContainer>
      )}

      <ActionsContainer>
        <FlexGrow>&nbsp;</FlexGrow>
        <Button color="primary" size="small" onClick={() => handleCancel()}>
          Cancel
        </Button>
        <UpdateButtonContainer>
          <Button
            color="primary"
            onClick={handleOnUpdate}
            disabled={updateLoading}
            size="small"
            variant="contained"
          >
            Update
          </Button>
          {updateLoading && <ButtonLoading size={24} />}
        </UpdateButtonContainer>
      </ActionsContainer>
    </React.Fragment>
  )
}

export default Edit
