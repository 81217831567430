import { Box } from '@mui/material'

import { usePolicyDetails } from '@jeeves/pages/RepositoryDetail/Tabs/Policies/views/PolicyDetails/contexts/PolicyDetailsContext'
import { PolicySummary } from '@jeeves/pages/RepositoryDetail/Tabs/Policies/components/PolicySummary'
import { AdditionalDetails } from './components/AdditionalDetails'

const Content = () => {
  const { policy } = usePolicyDetails()

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '2fr 1fr',
        paddingBottom: 4,
      }}
    >
      <Box
        sx={{
          borderRight: 1,
          borderColor: 'cyralColors.grey.200',
          paddingRight: 2,
        }}
      >
        <PolicySummary policy={policy} />
      </Box>
      <AdditionalDetails />
    </Box>
  )
}

export default Content
