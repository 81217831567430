import { useQuery, gql } from '@apollo/client'

import { USER_ACCOUNT_FIELDS } from '@jeeves/pages/RepositoryDetail/Tabs/UserAuthentication/graphql/fragments'

import { AccessPortalConfig_repoFragment } from '../Tabs/Config/components/AccessPortal'

import { RepositoryHeader_repoFragment } from '@jeeves/pages/RepositoryDetail/RepositoryHeader/RepositoryHeader.js'
import { ExportTable_repoFragment } from '@jeeves/pages/RepositoryDetail/Tabs/Overview/helpers/exportTable.js'

export const SERVICE_ACCOUNT_FIELDS = gql`
  fragment serviceAccountFields on ServiceAccount {
    id
    name
    fetchGroups
    connectionDriverInstance {
      id
      name
      type
    }
    numUsersAccessed
  }
`

export const NETWORK_SHIELD_FIELDS = gql`
  fragment networkShieldFields on NetworkShield {
    id
    enabled
    rules {
      id
      name
      description
      clientIPAddresses
      databaseAccounts
    }
  }
`

export const DISCOVERED_ACCOUNTS_FIELDS = gql`
  fragment discoveredAccountFields on DiscoveredAccount {
    id
    name
    createdTime
    lastUsedTimeInfo {
      time
      source
    }
    serviceAccount {
      id
      name
      numUsersAccessed
      connectionDriverInstance {
        id
        name
        type
      }
    }
    networkShieldRelationship {
      edge {
        node {
          id
          enabled
        }
        applicableNetworkShieldRules {
          id
          name
          description
        }
      }
    }
    userAccount {
      id
      name
      numUsersAccessed
      accessRules {
        id
        validUntil
        accessRestrictions {
          ... on DuoIntegration {
            id
            name
            integrationKey
          }
        }
      }
    }
  }
`

export const REPOSITORY_DETAIL = gql`
  query RepoDetails($repoId: String!, $workerRunsQuery: WorkerRunsQuery) {
    ...Config_Query
    samlIntegrations {
      id
      displayName
      enabled
      identityProviderType
      scimIntegration {
        IDPID
        enabled
      }
    }
    genericSAMLIntegrations {
      id
      displayName
      scimIntegration {
        IDPID
        enabled
      }
    }
    workerRuns(repoId: $repoId, query: $workerRunsQuery) {
      runId
      workerId
      source
      state
      error
      createdAt
      updatedAt
      data
    }
    repo(id: $repoId) {
      id
      name
      type
      tags
      policyTemplates {
        id
        description
        name
        tags
      }
      sidecars {
        id
        name
      }
      analysis {
        enableDataMasking
      }
      ... on MongoDBReplicaSetRepo {
        numNodes
        replicaSetName
      }
      ...ExportTable_repo
      ...RepositoryHeader_repo
      ... on RepoWithNetworkShieldField {
        networkShield {
          ...networkShieldFields
        }
      }
      ... on RepoWithSSOFields {
        userAccounts {
          ...userAccountFields
        }
      }
      ... on RepoWithServiceAccountsField {
        serviceAccounts {
          ...serviceAccountFields
        }
      }
      ... on SQLServerRepo {
        discoveredAccounts {
          isDisabled
          ...discoveredAccountFields
          ... on SQLServerLoginDiscoveredAccount {
            serverLevelRoles {
              name
              type
            }
            databaseUsers {
              name
              database
              roles {
                name
                type
              }
            }
          }
          ... on SQLServerUserDiscoveredAccount {
            databaseUser {
              name
              database
              roles {
                name
                type
              }
            }
          }
        }
      }
      ... on OracleRepo {
        discoveredAccounts {
          status
          isOracleMaintained
          ...discoveredAccountFields
          roles {
            name
            type
          }
        }
      }
      boundSidecarsRelationship {
        edges {
          bindings {
            id
            enabled
            ... on SingleListenerBinding {
              listener {
                id
                port
              }
            }
          }
          node {
            id
            name
            passthroughConfiguration
            endpoint
            userEndpoint
          }
        }
      }
      ...AccessPortalConfig_repo
      config {
        ...Config_RepoConfigFragment
        accessPortal {
          accessPortalBindingRelationship {
            edge {
              node {
                id
              }
              sidecar {
                id
                name
              }
            }
          }
        }
        dataActivityLogs {
          logSettings
          redactLiteralValues
          enhanceDatabaseLogs
        }
        alerts {
          alertOnViolations
          enablePreconfiguredAlerts
        }
        TLS {
          enableClientSidecarTLS
          enableSidecarRepoTLS
        }
      }
      mappedDatalabelsRelationship {
        edges {
          node {
            name
            description
            type
            classificationRule {
              ruleType
              ruleCode
            }
            tags
          }
        }
      }
    }
  }
  ${NETWORK_SHIELD_FIELDS}
  ${SERVICE_ACCOUNT_FIELDS}
  ${USER_ACCOUNT_FIELDS}
  ${DISCOVERED_ACCOUNTS_FIELDS}
  ${AccessPortalConfig_repoFragment}
  ${RepositoryHeader_repoFragment}
  ${ExportTable_repoFragment}
`

const useRepositoryDetail = options => {
  const { data, loading, error, refetch, fetchMore, networkStatus } = useQuery(REPOSITORY_DETAIL, {
    ...options,
    nextFetchPolicy: 'cache-first',
  })

  const accessGatewaySidecarEdges =
    data?.repo?.boundSidecarsRelationship?.edges?.filter(repoSidecarEdge => {
      const { node: sidecar, bindings } = repoSidecarEdge
      return (
        sidecar.passthroughConfiguration !== 'always' && bindings.some(binding => binding.enabled)
      )
    }) || []

  const configuredGenericSAMLIntegrations =
    data?.genericSAMLIntegrations.filter(
      integration => integration.__typename === 'GenericSAMLIntegrationConfigured'
    ) ?? []

  const allSAMLIntegrations = [
    ...configuredGenericSAMLIntegrations,
    ...(data?.samlIntegrations ?? []),
  ]

  return {
    loading,
    error,
    data: {
      accessGatewaySidecarEdges,
      awsIamIntegrations: data?.awsIamIntegrations,
      identityProviders: data?.identityProviders,
      samlIntegrations: allSAMLIntegrations,
      serviceAccounts: data?.repo?.serviceAccounts ?? [],
      networkShield: data?.repo?.networkShield,
      repo: data?.repo,
      discoveredAccounts: data?.repo?.discoveredAccounts ?? [],
      workerRuns: data?.workerRuns,
      mappedDatalabelsRelationship: data?.repo?.mappedDatalabelsRelationship ?? [],
    },
    refetch,
    fetchMore,
    networkStatus,
  }
}

export default useRepositoryDetail
