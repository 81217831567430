import { useFieldArray, useFormContext } from 'react-hook-form'
import { Box, Stack, FormControl, FormHelperText } from '@mui/material'
import { Input, InputLabel } from '@jeeves/new-components'
import { NumNodes } from '../../EditMongoDBReplicaSetRepoFields/components/shared'
import { useEffect } from 'react'

const StaticNodesFieldsGrid = ({ children }) => (
  <Box
    sx={{
      display: 'grid',
      gap: 1,
      gridTemplateColumns: '5fr 2fr',
    }}
  >
    {children}
  </Box>
)

const StaticNodesShardedClusterFields = () => {
  const { register, control, watch, formState } = useFormContext()

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'nodes',
  })

  const { numNodes = 1, nodes } = watch()

  const nodesError = formState.errors?.nodes

  useEffect(() => {
    if (nodes && nodes.length === 0) {
      append([{ host: '', port: '' }])
    }
  }, [append, nodes])

  // This useEffect is responsible for make the host:port pairs match numNodes
  useEffect(() => {
    if (nodes && nodes.length !== numNodes && numNodes > 0) {
      if (nodes?.length < numNodes) {
        append([{ host: '', port: '' }])
      } else {
        remove(nodes?.length - 1)
      }
    }
  }, [append, nodes, numNodes, remove])

  return (
    <>
      <Stack spacing={1}>
        <NumNodes isMongoDBShardedCluster />
      </Stack>

      <Stack spacing={1}>
        <StaticNodesFieldsGrid>
          <InputLabel required>Hostname</InputLabel>
          <InputLabel required>Port</InputLabel>
        </StaticNodesFieldsGrid>
        {fields.map((field, index) => {
          const hostError = nodesError?.[index]?.host
          const portError = nodesError?.[index]?.port

          return (
            <StaticNodesFieldsGrid key={field.id}>
              <FormControl variant="standard" error={Boolean(hostError)}>
                <Input inputProps={{ ...register(`nodes.${index}.host`) }} />
                <FormHelperText
                  sx={{
                    typography: 'body2',
                  }}
                  error={Boolean(hostError)}
                >
                  {hostError?.message}
                </FormHelperText>
              </FormControl>
              <FormControl variant="standard" error={Boolean(portError)}>
                <Input
                  inputProps={{
                    ...register(`nodes.${index}.port`, {
                      setValueAs: value => (value === '' ? undefined : Number(value)),
                    }),
                  }}
                />
                <FormHelperText
                  sx={{
                    typography: 'body2',
                  }}
                  error={Boolean(portError)}
                >
                  {portError?.message}
                </FormHelperText>
              </FormControl>
            </StaticNodesFieldsGrid>
          )
        })}
      </Stack>
    </>
  )
}

export default StaticNodesShardedClusterFields
