import * as React from 'react'
import { Controller, useForm } from 'react-hook-form'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import FormControl from '@mui/material/FormControl'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Typography from '@mui/material/Typography'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'

import { Button } from '@jeeves/new-components'

const StepTwo = ({
  prevStep,
  nextStep,
  setDatabaseAccountInfo,
  selectedConnectionDriver,
  connectionDrivers,
}) => {
  const { control, watch } = useForm({
    defaultValues: {
      connectionDriverType: selectedConnectionDriver,
    },
  })

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      // console.log('value: ', value)
      // console.log('name: ', name)
      // console.log('type: ', type)

      if (name === 'connectionDriverType') {
        setDatabaseAccountInfo(info => {
          return {
            ...info,
            connectionDriverType: value.connectionDriverType,
          }
        })
      }
    })

    return () => subscription.unsubscribe()
  }, [watch, setDatabaseAccountInfo])

  return (
    <Box>
      <Stack spacing={4}>
        <Box>
          {/* <FormControl variant="standard" error={Boolean(errors?.username)}>
            <InputLabel htmlFor="database-account-username-input">
              Select a connection driver
            </InputLabel>
            <Input
              id="database-account-username-input"
              defaultValue=""
              inputProps={{
                ...register('connectionDriver', {
                  required: true,
                }),
              }}
            />
          </FormControl> */}

          <FormControl component="fieldset" fullWidth>
            {/* <FormControl variant="standard"> */}
            {/* <FormLabel id="demo-radio-buttons-group-label">Select a connection driver</FormLabel> */}
            {/* <InputLabel id="demo-radio-buttons-group-label">Select a connection driver</InputLabel> */}

            <Controller
              name="connectionDriverType"
              control={control}
              render={({ field }) => (
                <RadioGroup
                  {...field}
                  aria-labelledby="demo-radio-buttons-group-label"
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    gap: 2,
                  }}
                >
                  {connectionDrivers.map(driver => {
                    const { label, value, avatar } = driver

                    return (
                      <ConnectionDriverCard
                        key={value}
                        value={value}
                        label={label}
                        avatar={avatar}
                        selectedConnectionDriver={selectedConnectionDriver}
                      />
                    )
                  })}
                </RadioGroup>
              )}
            />
          </FormControl>
        </Box>

        <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
          <Button variant="outlined" onClick={prevStep}>
            Back
          </Button>
          <Button onClick={nextStep}>Next</Button>
        </Stack>
      </Stack>
    </Box>
  )
}

// TODO: Look into the possibility of refactoring this to use <FormControlLabel />
const ConnectionDriverCard = ({ label, value, avatar, selectedConnectionDriver }) => {
  const isSelected = value === selectedConnectionDriver

  return (
    <Card
      component="label"
      variant="outlined"
      role="button"
      sx={{
        cursor: 'pointer',
        transition: theme =>
          theme.transitions.create(['border-color', 'background-color', 'box-shadow'], {
            duration: theme.transitions.duration.shorter,
          }),

        ...(isSelected && {
          borderColor: 'primary.main',
          boxShadow: theme => `${theme.palette.primary.main} 0 0 0 1px`,
        }),
      }}
    >
      <Radio
        value={value}
        inputProps={{
          'aria-label': 'A',
        }}
        sx={{
          opacity: 0,
          position: 'absolute',
        }}
      />
      <CardHeader
        // action={'action'}
        // subheader={'subheader'}
        avatar={avatar}
        title={
          <Typography variant="h4" sx={{ color: 'text.primary' }}>
            {label}
          </Typography>
        }
        disableTypography
        sx={{
          flexDirection: 'column',

          '& .MuiCardHeader-avatar': {
            marginRight: 0,
            marginBottom: 2,
          },
        }}
      />
    </Card>
  )
}

export default StepTwo
