import * as React from 'react'
import { useFragment_experimental, gql } from '@apollo/client'
import pluralize from 'pluralize'
import { FormHelperText, Stack, Typography } from '@mui/material'
import { InputLabel, Select, ServiceIcon } from '@jeeves/new-components'

import { isClusterRepo } from '@jeeves/graphql/utils'

const RepoDisplay_RepoFragment = gql`
  fragment RepoDisplay_RepoFragment on Repo {
    id
    name
    type
    ... on ClusterRepo {
      numNodes
    }
  }
`

const RepoDisplay = ({ repo }) => {
  const { data } = useFragment_experimental({
    fragment: RepoDisplay_RepoFragment,
    fragmentName: 'RepoDisplay_RepoFragment',
    from: repo,
  })

  const isRepoMongoDBReplicaset = repo.__typename === 'MongoDBReplicaSetRepo'
  const mongoDBClusterTypeDisplayName = isRepoMongoDBReplicaset ? 'replica set' : 'sharded cluster'

  const isSelectClusterRepo = isClusterRepo(repo.__typename)

  const helperText = isSelectClusterRepo && (
    <React.Fragment>
      This repository is a {mongoDBClusterTypeDisplayName} with{' '}
      <Typography component="strong" sx={{ fontWeight: '700', color: 'text.secondary' }}>
        {repo.numNodes}
      </Typography>{' '}
      {!isRepoMongoDBReplicaset ? 'mongos ' : ''}
      {pluralize('node', repo.numNodes)}
    </React.Fragment>
  )

  return (
    <Stack spacing={1}>
      <InputLabel id="repository-select-label">Repository Name</InputLabel>

      <Select
        labelId="repository-select-label"
        id="repository-select"
        options={[data].map(repo => ({
          icon: <ServiceIcon type={repo.type} />,
          label: repo.name,
          value: repo.id,
        }))}
        value={data.id}
        IconComponent={null}
        disabled
      />

      {helperText && (
        <FormHelperText
          sx={{
            typography: 'body2',
          }}
        >
          {helperText}
        </FormHelperText>
      )}
    </Stack>
  )
}

RepoDisplay.fragments = {
  RepoDisplay_RepoFragment,
}

export default RepoDisplay
