import React, { Fragment } from 'react'

import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import Paper from '@material-ui/core/Paper'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import AddCircleIcon from '@material-ui/icons/AddCircle'

const styles = theme => ({
  chip: {
    marginLeft: theme.spacing.unit,
    background: theme.palette.background.default,
  },
  grow: {
    flexGrow: 1,
  },
  borderNone: {
    borderWidth: 0,
  },
  mb1: {
    marginBottom: theme.spacing.unit,
  },
  mt2: {
    marginTop: theme.spacing.unit * 2,
  },
  total: {
    fontSize: theme.spacing.unit * 2,
    fontWeight: 'bold',
  },
  additionalInfo: {
    position: 'absolute',
    bottom: -77,
    width: 'calc(100% + 4px)',
    left: -2,
    borderTop: '1px dashed rgba(224, 224, 224, 1)',
    borderLeft: '2px solid #354da1',
    borderRight: '2px solid #354da1',
    borderBottom: '2px solid #354da1',
    borderRadius: '0 0 4px 4px',
    background: theme.palette.background.primary,
    padding: theme.spacing.unit * 2,
    '& svg': {
      color: theme.palette.succes.main,
    },
  },
  table: {
    marginTop: theme.spacing.unit * 4,
    '& td, & th': {
      borderBottomStyle: 'dashed',
      padding: theme.spacing.unit * 2,
    },
    '& th:nth-of-type(3):before': {
      content: '""',
      background: '#fff',
      height: '13px',
      position: 'absolute',
      width: 'calc(100% + 4px)',
      display: 'block',
      left: '-2px',
      top: '-13px',
      borderRadius: '4px 4px 0 0',
      borderLeft: '2px solid #354da1',
      borderRight: '2px solid #354da1',
      borderTop: '2px solid #354da1',
    },
    '& td:nth-of-type(3), & th:nth-of-type(3)': {
      borderLeft: '2px solid #354da1',
      borderRight: '2px solid #354da1',
      position: 'relative',
    },
    '& tr:last-of-type td': {
      borderBottomWidth: 0,
    },
  },
})

const Plans = props => {
  const { classes } = props

  return (
    <Fragment>
      <Typography align="center" className={classes.mt2} variant="h5">
        Whether you need a plan for a hobby project or
        <br /> a G2000 enterprise project, we’ve got you covered
      </Typography>
      <Typography align="center" className={classes.mt2}>
        Total annual fee due today: <span className={classes.total}>$0</span>
      </Typography>

      <Paper>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.borderNone} />
              <TableCell className={classes.borderNone} align="center">
                <Typography variant="h5" className={classes.mb1}>
                  Free
                </Typography>
                <Button variant="contained" fullWidth disabled>
                  Unavailable
                </Button>
              </TableCell>
              <TableCell className={classes.borderNone} align="center">
                <Typography variant="h5" className={classes.mb1}>
                  Essential
                </Typography>
                <Button disabled color="primary" fullWidth>
                  <Typography color="primary">CURRENT</Typography>
                </Button>
              </TableCell>
              <TableCell className={classes.borderNone} align="center">
                <Typography variant="h5" className={classes.mb1}>
                  Premium
                </Typography>
                <Button variant="contained" color="primary" fullWidth>
                  Select
                </Button>
              </TableCell>
              <TableCell className={classes.borderNone} align="center">
                <Typography variant="h5" className={classes.mb1}>
                  Enterprise
                </Typography>
                <Button variant="contained" color="primary" fullWidth>
                  Message us
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography align="right">Annual platform fee</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography inline variant="h4">
                  $0
                </Typography>
                <Typography inline>/ year</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography inline variant="h4">
                  $8,000
                </Typography>
                <Typography inline>/ year</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography inline variant="h4">
                  $35,000
                </Typography>
                <Typography inline>/ year</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography>Custom</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography align="right"># watched request allowed</Typography>
                <Typography variant="caption" color="textSecondary" align="right">
                  <em>Monthly request pricing</em>
                </Typography>
              </TableCell>
              <TableCell>
                <Typography align="center">5K per month</Typography>
                <Typography variant="caption" color="textSecondary" align="center">
                  $0
                </Typography>
              </TableCell>
              <TableCell>
                <Typography align="center">Unlimited</Typography>
                <Typography variant="caption" color="textSecondary" align="center">
                  $4 / million requests / month
                </Typography>
              </TableCell>
              <TableCell>
                <Typography align="center">Unlimited</Typography>
                <Typography variant="caption" color="textSecondary" align="center">
                  $3 / million requests / month
                </Typography>
              </TableCell>
              <TableCell>
                <Typography align="center">Custom</Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography align="right"># authenticated identities</Typography>
                <Typography variant="caption" color="textSecondary" align="right">
                  <em>Overage request pricing</em>
                </Typography>
              </TableCell>
              <TableCell>
                <Typography align="center">1 identity included</Typography>
                <Typography variant="caption" color="textSecondary" align="center">
                  N/A
                </Typography>
              </TableCell>
              <TableCell>
                <Typography align="center">10 identities included</Typography>
                <Typography variant="caption" color="textSecondary" align="center">
                  $8 / additional identity / month
                </Typography>
              </TableCell>
              <TableCell>
                <Typography align="center">100 identities included</Typography>
                <Typography variant="caption" color="textSecondary" align="center">
                  $6 / additional identity / month
                </Typography>
              </TableCell>
              <TableCell>
                <Typography align="center">Custom</Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography align="right"># identity & MF provider integrations</Typography>
                <Typography variant="caption" color="textSecondary" align="right">
                  <em>Overage request pricing</em>
                </Typography>
              </TableCell>
              <TableCell>
                <Typography align="center">1 identity included</Typography>
                <Typography variant="caption" color="textSecondary" align="center">
                  N/A
                </Typography>
              </TableCell>
              <TableCell>
                <Typography align="center">1 provider included</Typography>
                <Typography variant="caption" color="textSecondary" align="center">
                  $500 / additional provider / month
                </Typography>
              </TableCell>
              <TableCell>
                <Typography align="center">5 providers included</Typography>
                <Typography variant="caption" color="textSecondary" align="center">
                  $400 / additional provider / month
                </Typography>
              </TableCell>
              <TableCell>
                <Typography align="center">Custom</Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography align="right">Support</Typography>
              </TableCell>
              <TableCell>
                <Typography align="left">Community & Docs</Typography>
              </TableCell>
              <TableCell>
                <Typography align="left">
                  Community & Docs
                  <br />
                  Email support
                </Typography>
                <ListItem className={classes.additionalInfo}>
                  <ListItemIcon>
                    <AddCircleIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography inline align="left">
                    24x7 phone/chat support
                    <br />
                    $10,000 / year
                  </Typography>
                </ListItem>
              </TableCell>
              <TableCell>
                <Typography align="left">
                  Community & Docs
                  <br />
                  Email support
                  <br />
                  24x7 phone / chat
                </Typography>
              </TableCell>
              <TableCell>
                <Typography align="left">
                  Community & Docs
                  <br />
                  Email support
                  <br />
                  24x7 phone / chat
                  <br />
                  <em>Concierge available</em>
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    </Fragment>
  )
}

export default withStyles(styles)(Plans)
