import * as React from 'react'

const UserAuthenticationContext = React.createContext()

export const UserAuthenticationProvider = ({ children, userAccounts }) => {
  const [isRegistering, setIsRegistering] = React.useState(false)
  const [isUpdating, setIsUpdating] = React.useState(false)
  const [selectedUserAccountId, setSelectedUserAccountId] = React.useState(
    userAccounts?.[0]?.id ?? ''
  )

  const selectedUserAccount = userAccounts.find(account => account.id === selectedUserAccountId)

  const onSelectUserAccount = id => {
    setSelectedUserAccountId(id)
    setIsRegistering(false)
    setIsUpdating(false)
  }

  const contextValue = React.useMemo(() => {
    return {
      isRegistering,
      isUpdating,
      setIsRegistering,
      setIsUpdating,
      userAccounts,
      selectedUserAccount,
      selectedUserAccountId,
      setSelectedUserAccountId,
      onSelectUserAccount,
    }
  }, [isRegistering, isUpdating, userAccounts, selectedUserAccountId, selectedUserAccount])

  return (
    <UserAuthenticationContext.Provider value={contextValue}>
      {children}
    </UserAuthenticationContext.Provider>
  )
}

export const useUserAuthenticationContext = () => {
  const context = React.useContext(UserAuthenticationContext)
  if (context === undefined) {
    throw new Error('useUserAuthenticationContext must be used within a UserAuthenticationProvider')
  }
  return context
}
