/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import styled from '@emotion/styled'
import React from 'react'
import { get as lodashGet } from 'lodash'
import { Typography as MuiTypography } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import { FileCopy as CopyIcon } from '@material-ui/icons'
import CopyButton from '@jeeves/components/CopyButton'
import { getDocsURL } from '@jeeves/utils/docsURL'

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 5px 0;
`

const FieldLabel = styled(MuiTypography)`
  font-weight: 500;
  width: 25%;
`

const FieldValue = styled(MuiTypography)`
  font-family: 'monospace', monospace;
  width: 75%;
  overflow-wrap: break-word;
`

const FieldDetailsWrapper = styled.div`
  width: 100%;
  margin-bottom: 1rem;
  // margin-top: 1rem;
  background-color: ${grey[200]};
  padding: 15px;
  border-radius: 4px;
`

const StyledCopyButton = styled(CopyButton)`
  position: relative;
  cursor: pointer;
  top: 3px;
  left: 5px;
  font-size: 16px;
  color: ${grey[500]};
  transition: color 0.1s linear;
  &:hover {
    color: ${grey[800]};
  }
`

const Typography = styled(MuiTypography)`
  margin-bottom: 1rem;
`

const Link = styled.a`
  text-decoration: none;
  color: #26459e;
  font-weight: 600;
`

const providedValue = `https://${lodashGet(window, '_env_.domain')}:8000/auth/realms/${
  window._env_.auth_tenant
}`

const Field = ({ label, children, showCopy = false }) => (
  <FieldWrapper>
    <FieldLabel>{label}</FieldLabel>
    <FieldValue>
      {children}
      {showCopy && (
        <StyledCopyButton copyText={children}>
          <CopyIcon fontSize="small" />
        </StyledCopyButton>
      )}
    </FieldValue>
  </FieldWrapper>
)

const FieldDetails = ({}) => {
  return (
    <FieldDetailsWrapper>
      <Field showCopy label="Default Identifier">
        {providedValue}
      </Field>
      <Field showCopy label="Reply URl">
        {providedValue}
      </Field>
    </FieldDetailsWrapper>
  )
}

const Instructions = () => {
  return (
    <React.Fragment>
      <Typography>
        First, create a new Enterprise Application in Azure Active Directory using the values
        provided below. See more instructions on this{' '}
        <Link
          href={getDocsURL({
            docsPath: '/integrations/authentication/idp/azure-ad#sso-with-azure-ad',
          })}
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </Link>
        .
      </Typography>
      <FieldDetails></FieldDetails>
      <Typography>
        Provide your application's App Federation Metadata URL below. Then, give the integration a
        name.
      </Typography>
    </React.Fragment>
  )
}

export default Instructions
