import React from 'react'

import AlertsTable from './components/AlertsTable'
import { AlertsProvider } from './contexts/AlertsContext'

const AlertsWrapper = props => (
  <AlertsProvider>
    <AlertsTable />
  </AlertsProvider>
)
export default AlertsWrapper
