import * as React from 'react'
import { useForm } from 'react-hook-form'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'

import { Button, Input, InputLabel } from '@jeeves/new-components'

const StepOne = ({
  onCancel,
  nextStep,
  serviceAccountName: serviceAccountNameProp,
  initialServiceAccountName,
  setDatabaseAccountInfo,
  serviceAccounts,
}) => {
  const {
    watch,
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      serviceAccountName: serviceAccountNameProp,
    },
  })

  React.useEffect(() => {
    if (isSubmitSuccessful) {
      nextStep()
    }
  }, [isSubmitSuccessful, nextStep])

  const onSubmit = ({ serviceAccountName }) => {
    setDatabaseAccountInfo(info => {
      return {
        ...info,
        serviceAccountName,
      }
    })
  }

  const serviceAccountName = watch('serviceAccountName')
  const preexistingServiceAccountNames = serviceAccounts.map(account => account.name)

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4}>
        <Box>
          <FormControl
            variant="standard"
            error={Boolean(errors?.serviceAccountName)}
            sx={{
              width: '100%',
              maxWidth: '32rem',
            }}
          >
            <InputLabel htmlFor="database-account-username-input">
              Database Account Username
            </InputLabel>
            <Input
              id="database-account-username-input"
              inputProps={{
                ...register('serviceAccountName', {
                  required: 'Please specify a database account name.',
                  validate: {
                    isUniqueName: v =>
                      !preexistingServiceAccountNames.includes(v) ||
                      v === initialServiceAccountName ||
                      'This database account is already registered.',
                  },
                }),
              }}
            />
            <FormHelperText
              sx={{
                typography: 'body2',
              }}
              error={!!errors?.serviceAccountName}
            >
              {errors?.serviceAccountName?.message ||
                'Username must be an exact match with an existing account in your database'}
            </FormHelperText>
          </FormControl>
        </Box>
        <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
          <Button variant="outlined" onClick={onCancel}>
            Cancel
          </Button>

          <Button type="submit" disabled={!serviceAccountName}>
            Next
          </Button>
        </Stack>
      </Stack>
    </Box>
  )
}

export default StepOne
