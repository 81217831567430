import { useState } from 'react'
import { get } from 'lodash'

import ExpressClient from '@jeeves/clients/express'
import { useAuth, useMount, usePopup } from '@jeeves/hooks'

const useTeams = () => {
  const { showError } = usePopup()
  const { getTokenSilently } = useAuth()
  const ec = new ExpressClient(getTokenSilently)

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [integrations, setIntegrations] = useState([])

  const updateIntegrations = async () => {
    setLoading(true)
    try {
      const { data } = await ec.get('/integrations/notifications/teams')
      setIntegrations(data)
    } catch (e) {
      const popupMessage = get(e, 'response.data.error.message', '')
      showError(popupMessage)
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  const addIntegration = async input => {
    try {
      const { data } = await ec.post('/integrations/notifications/teams', input)
      updateIntegrations()
      return data
    } catch (e) {
      const popupMessage = get(e, 'response.data.error.message', '')
      showError(popupMessage)
      throw e
    }
  }

  const putIntegration = async (input, integrationID) => {
    try {
      const integration = await ec
        .put(`/integrations/notifications/teams/${integrationID}`, input)
        .then(res => res.data)
      updateIntegrations()
      return integration
    } catch (e) {
      const popupMessage = get(e, 'response.data.error.message', '')
      showError(popupMessage)
    }
  }

  const deleteIntegration = async integrationID => {
    try {
      const integration = await ec
        .delete(`/integrations/notifications/teams/${integrationID}`)
        .then(res => res.data)
      updateIntegrations()
      return integration
    } catch (e) {
      const popupMessage = get(e, 'response.data.error.message', '')
      showError(popupMessage)
    }
  }

  useMount(updateIntegrations)

  return {
    updateIntegrations,
    integrations,
    addIntegration,
    putIntegration,
    deleteIntegration,
    loading,
    error,
  }
}

export default useTeams
