import { Box, TableRow, Typography } from '@mui/material'

import { FragmentType, graphql, useFragment } from '@jeeves/graphql'
import { useAuth } from '@jeeves/hooks'

import IdentityProviderIcon from './IdentityProviderIcon'
import ActionsButton from './ActionsButton'

const UsersTableRow_ControlPlaneUserFragment = graphql(`
  fragment UsersTableRow_ControlPlaneUserFragment on ControlPlaneUser {
    id
    username
    givenName
    familyName
    name
    email
    role {
      id
      name
    }
    ssoGroups {
      name
    }
    ...IdentityProviderIcon_ControlPlaneUserFragment
  }
`)

interface Props {
  controlPlaneUser: FragmentType<typeof UsersTableRow_ControlPlaneUserFragment>
  openEdit: (user: any) => void
  openSsoGroupsModal: (ssoGroups: string[]) => void
}

const UsersTableRow = ({
  controlPlaneUser: controlPlaneUserProp,
  openEdit,
  openSsoGroupsModal,
}: Props) => {
  const { hasPermission } = useAuth()

  const canUpdate = !!hasPermission('users:update')

  const controlPlaneUser = useFragment(UsersTableRow_ControlPlaneUserFragment, controlPlaneUserProp)
  const numOfSsoGroups = controlPlaneUser.ssoGroups.length

  const role = controlPlaneUser.role.name !== '_Everyone' ? controlPlaneUser.role.name : 'User'

  return (
    <TableRow
      hover
      sx={{
        td: {
          py: 2.25,
        },
      }}
    >
      <Box component="td" sx={{ textAlign: 'right !important' }}>
        <IdentityProviderIcon controlPlaneUser={controlPlaneUser} />
      </Box>
      <td>
        <Typography variant="body2" sx={{ color: 'text.primary' }}>
          {controlPlaneUser.username}
        </Typography>
      </td>
      <td>
        <Typography variant="body2" sx={{ color: 'text.primary' }}>
          {controlPlaneUser.name}
        </Typography>
      </td>
      <td>
        <Typography variant="body2" sx={{ color: 'text.primary' }}>
          {role}
        </Typography>
      </td>
      <td>
        {numOfSsoGroups !== 0 ? (
          <Typography
            variant="body2"
            sx={{
              color: 'primary.main',
              cursor: 'pointer',
              fontWeight: 600,
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
            onClick={() => {
              const ssoGroups = controlPlaneUser.ssoGroups
                .map(ssoGroup => ssoGroup?.name)
                .filter(ssoGroup => Boolean(ssoGroup))
                .sort() as string[]
              openSsoGroupsModal(ssoGroups)
            }}
          >
            {numOfSsoGroups}
          </Typography>
        ) : (
          <Typography variant="body2" sx={{ color: 'text.disabled' }}>
            —
          </Typography>
        )}
      </td>
      <td>
        {canUpdate && (
          <ActionsButton
            openEdit={() => {
              const user = {
                ...controlPlaneUser,
                first_name: controlPlaneUser.givenName,
                last_name: controlPlaneUser.familyName,
              }
              openEdit(user)
            }}
          />
        )}
      </td>
    </TableRow>
  )
}

export default UsersTableRow
