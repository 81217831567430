import React from 'react'
import styled from '@emotion/styled'
import { SectionTitle, StyledTypography } from './Text'

const NameWrapper = styled.div`
  margin: 30px 30px 0 30px;
`

const Flex = styled.div`
  display: flex;
  flex-direction: row;
`

const FormTitle = ({ title, subtitle, children }) => {
  return (
    <Flex>
      <div>
        <SectionTitle>{title}</SectionTitle>
        <StyledTypography>{subtitle}</StyledTypography>
      </div>
      <NameWrapper>{children}</NameWrapper>
    </Flex>
  )
}

export default FormTitle
