import { useState, useCallback } from 'react'

import { useRepositoryDetailContext } from '@jeeves/pages/RepositoryDetail/contexts/RepositoryDetailContext'

import { useCreatePolicy, useUpdatePolicy } from '../hooks'

const useServiceAccountAbuse = ({ policy, template }) => {
  const { repoId } = useRepositoryDetailContext()

  const [snackBarOpen, setSnackBarOpen] = useState(false)
  const [activeStep, setActiveStep] = useState(0)
  const [serviceAccountAbuseInfo, setServiceAccountAbuseInfo] = useState({
    id: policy?.id ?? '',
    name: policy?.name ?? '',
    description: policy?.description ?? '',
    tags: policy?.tags ?? template.tags,
    template: policy?.template ?? template,
    parameters: {
      serviceAccounts: policy?.parameters?.serviceAccounts ?? [],
      action: policy?.parameters?.action ?? 'alert',
    },
  })

  const isEditing = Boolean(policy?.id)

  const [createServiceAccountAbusePolicy, { loading: createLoading, error: createError }] =
    useCreatePolicy({
      onError: _ => {
        setSnackBarOpen(true)
      },
    })

  const [updateServiceAccountAbusePolicy, { loading: updateLoading, error: updateError }] =
    useUpdatePolicy({
      onError: _ => {
        setSnackBarOpen(true)
      },
    })

  const prevStep = () => setActiveStep(currStep => currStep - 1)
  const nextStep = useCallback(() => {
    setActiveStep(currStep => currStep + 1)
  }, [])

  const handleCloseSnackbar = () => {
    setSnackBarOpen(false)
  }

  const handlePolicySubmit = async () => {
    const input = {
      serviceAccountAbuse: {
        name: serviceAccountAbuseInfo.name,
        description: serviceAccountAbuseInfo.description,
        tags: serviceAccountAbuseInfo.tags,
        action: serviceAccountAbuseInfo.parameters.action,
        serviceAccounts: serviceAccountAbuseInfo.parameters.serviceAccounts,
      },
    }

    try {
      if (isEditing) {
        const policyId = policy.id
        return await updateServiceAccountAbusePolicy({
          variables: {
            policyId,
            input,
          },
        })
      }

      return await createServiceAccountAbusePolicy({
        variables: {
          repoId,
          input,
        },
      })
    } catch (e) {
      console.error(e)
    }
  }

  return {
    serviceAccountAbuseInfo,
    activeStep,
    snackBarOpen,
    loading: createLoading || updateLoading,
    errorMessage: createError?.message || updateError?.message,
    setServiceAccountAbuseInfo,
    prevStep,
    nextStep,
    handlePolicySubmit,
    handleCloseSnackbar,
  }
}

export default useServiceAccountAbuse
