import { Controller, useFormContext } from 'react-hook-form'
import pluralize from 'pluralize'
import { Box, FormControl, FormHelperText, Stack, Typography } from '@mui/material'

import { Input, InputLabel, Select } from '@jeeves/new-components'

import { VALID_UNITS } from '@jeeves/utils/constants'

const MaxTokenValidity = () => {
  const {
    formState: { errors },
    register,
    watch,
  } = useFormContext()

  const maxTokenValidityValue = watch('maxTokenValidity.value')

  const options = VALID_UNITS.map(unit => ({
    label: pluralize(unit, Number(maxTokenValidityValue)),
    value: unit,
  }))

  return (
    <Stack spacing={1} sx={{ width: '50%' }}>
      <InputLabel required error={Boolean(errors?.maxTokenValidity)}>
        Max Token Validity Period
      </InputLabel>

      <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 2fr', gap: 1 }}>
        <FormControl variant="standard" error={Boolean(errors?.maxTokenValidity?.value)}>
          <Input
            type="number"
            inputProps={{
              ...register('maxTokenValidity.value'),
              min: 1,
            }}
          />

          <FormHelperText sx={{ typography: 'body2' }}>
            {errors?.maxTokenValidity?.value?.message}
          </FormHelperText>
        </FormControl>

        <FormControl variant="standard">
          <Controller
            name="maxTokenValidity.unit"
            render={({ field }) => <Select options={options} {...field} />}
          />
        </FormControl>
      </Box>

      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        The longest period that an access token can remain valid.
      </Typography>
    </Stack>
  )
}

export default MaxTokenValidity
