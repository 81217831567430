export const policyIdentities = {
  PolicyEmailIdentity: 'email',
  PolicyGroupIdentity: 'group',
  PolicyUsernameIdentity: 'username',
}

export type PolicyIdentityType = keyof typeof policyIdentities

export const getDisplayedPolicyIdentity = (identity: {
  __typename: PolicyIdentityType
  name: string
}) => {
  const prefix = policyIdentities[identity.__typename]
  return `${prefix}: ${identity.name}`
}
