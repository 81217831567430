import { useMutation, gql } from '@apollo/client'

import { useToast } from '@jeeves/new-components'
import { getGraphQLErrorMessage } from '@jeeves/utils/helpers'

const REQUEST_ACCESS = gql`
  mutation UpsertApprovalRequest($repoId: ID!, $request: UpsertApprovalRequestInput!) {
    upsertApprovalRequest(repoId: $repoId, request: $request) {
      approvalRequest {
        validUntil
      }
    }
  }
`

const useRequestAccess = ({ handleOnClose }) => {
  const { toast } = useToast()

  const [requestAccess, { loading }] = useMutation(REQUEST_ACCESS, {
    onError: error => {
      toast({
        variant: 'error',
        description:
          getGraphQLErrorMessage(error) ||
          'An error occurred while requesting access, please try again.',
      })
    },
    onCompleted: () => {
      handleOnClose()
      toast({
        variant: 'success',
        description: 'Your access request was successfully submitted.',
      })
    },
  })

  return {
    loading,
    requestAccess,
  }
}

export default useRequestAccess
