import * as React from 'react'
import { useParams } from 'react-router-dom'
import { useFragment_experimental, gql, useQuery } from '@apollo/client'
import { useUpdateEffect } from '@react-hookz/web'

// import EditBinding from '../EditBinding/EditBinding'

const BindingsTableContext = React.createContext()

const DEFAULT_PAGE_SIZE = 10

export const bindingFieldsFragment = gql`
  fragment bindingFields on Binding {
    id
    enabled
    repo {
      id
      name
      type
      ... on ClusterRepo {
        numNodes
      }
    }
    ... on SingleListenerBinding {
      listener {
        id
        port
      }
    }
    ... on S3Binding {
      listenerSet {
        proxyListener {
          id
          port
        }
        browserListener {
          id
          port
        }
      }
    }
    ... on ClusterBinding {
      boundListenersRelationship {
        edges {
          node {
            id
            port
          }
        }
      }
    }
    # ...EditBinding_BindingFragment This should be here, but to avoid circular dependencies,
    # I'm taking it out because the above query already has all these fields
  }
`

export const sidecarBindingsConnectionFragment = gql`
  fragment sidecarBindingsConnectionFields on SidecarBindings_Connection {
    edges {
      cursor
      node {
        ...bindingFields
      }
    }
    filteredCount
    totalCount
  }
  ${bindingFieldsFragment}
`

const PAGINATED_BINDINGS_QUERY = gql`
  query PaginatedBindingsQuery(
    $sidecarId: String!
    $first: Int
    $after: String
    $last: Int
    $before: String
    $bindingsFilters: BindingsFilterInput
  ) {
    sidecar(id: $sidecarId) {
      id
      bindings(
        first: $first
        after: $after
        last: $last
        before: $before
        filters: $bindingsFilters
      ) {
        ...sidecarBindingsConnectionFields
      }
    }
  }
  ${sidecarBindingsConnectionFragment}
`

export const BindingsTableProvider = ({
  children,
  searchValue,
  registerRefreshCurrentTableViewCallback,
}) => {
  const [isPaginating, setIsPaginating] = React.useState(false)
  const [previousPageEndCursorStack, setPreviousPageEndCursorStack] = React.useState([])
  const [currentPaginateArgs, setCurrentPaginateArgs] = React.useState({})
  const { id } = useParams()
  const [{ pageIndex, pageSize }, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: DEFAULT_PAGE_SIZE,
  })

  const filter = React.useMemo(() => {
    return searchValue
      ? {
          repo: {
            name: {
              containsCaseInsensitive: searchValue,
            },
          },
        }
      : undefined
  }, [searchValue])

  const { data, fetchMore, refetch, loading } = useQuery(PAGINATED_BINDINGS_QUERY, {
    variables: {
      sidecarId: id,
      bindingsFilters: filter,
      first: DEFAULT_PAGE_SIZE,
    },
  })

  useUpdateEffect(() => {
    // Reset pagination when search value changes
    setPagination(oldPagination => ({
      ...oldPagination,
      pageIndex: 0,
    }))

    // Reset previous page end cursor stack when search value changes
    setPreviousPageEndCursorStack([])

    refetch({
      bindingsFilters: filter,
    })
  }, [filter, refetch])

  const filteredCount = data?.sidecar?.bindings?.filteredCount

  const currentPageEdgeStartIndex = pageIndex * pageSize
  const currentPageEdgeEndIndex = Math.min((pageIndex + 1) * pageSize, filteredCount) - 1

  const currentPageEdges = React.useMemo(() => {
    const allEdges = data?.sidecar?.bindings?.edges

    return allEdges?.slice(currentPageEdgeStartIndex, currentPageEdgeEndIndex + 1)
  }, [currentPageEdgeEndIndex, currentPageEdgeStartIndex, data?.sidecar?.bindings?.edges])

  const paginate = async ({ after, before, refetchCurrent }) => {
    setIsPaginating(true)
    if (refetchCurrent) {
      after = currentPaginateArgs.after
      before = currentPaginateArgs.before
    }
    const variables = {
      sidecarId: id,
      ...(after
        ? { after, first: pageSize }
        : before
        ? { before, last: pageSize }
        : {
            first: pageSize,
          }),
      filters: filter,
    }

    await fetchMore({
      variables,
    })

    setCurrentPaginateArgs({ after, before })

    setIsPaginating(false)
  }

  registerRefreshCurrentTableViewCallback(() => paginate({ refetchCurrent: true }))

  const value = {
    pageIndex,
    pageSize,
    setPagination,
    filteredCount,
    currentPageEdges,
    filter,
    isPaginating,
    paginate,
    previousPageEndCursorStack,
    setPreviousPageEndCursorStack,
    loading,
  }

  return <BindingsTableContext.Provider value={value}>{children}</BindingsTableContext.Provider>
}

export const useBindingsTableContext = () => {
  const context = React.useContext(BindingsTableContext)
  if (context === undefined) {
    throw new Error('useBindingsTableContext must be used within a BindingsTableProvider')
  }

  return context
}
