import { Dispatch, SetStateAction } from 'react'
import { Stack, Typography } from '@mui/material'

import { useAuth } from '@jeeves/hooks'

import { Button } from '@jeeves/new-components'

interface Props {
  setCurrentStep: Dispatch<SetStateAction<number>>
}

const SetupWizardStep1 = ({ setCurrentStep }: Props) => {
  const { user } = useAuth()

  const name = [user.given_name, user.family_name].join(' ')

  return (
    <Stack spacing={4} sx={{ alignItems: 'center' }}>
      <Stack spacing={1} sx={{ alignItems: 'center' }}>
        <Typography
          sx={{
            color: 'text.primary',
            fontSize: '1.125rem',
            fontWeight: 400,
            lineHeight: '28.8px',
          }}
        >
          Hi, {name}
        </Typography>

        <Typography variant="h2" sx={{ color: 'text.primary' }}>
          Welcome to Database Security with Cyral!
        </Typography>
      </Stack>

      <Typography
        sx={{ color: 'text.primary', fontSize: '1.125rem', fontWeight: 400, lineHeight: '28.8px' }}
      >
        We’ll get you up and running in under 10 minutes.
      </Typography>

      <Button color="primary" variant="contained" onClick={() => setCurrentStep(1)}>
        Start
      </Button>
    </Stack>
  )
}

export default SetupWizardStep1
