import React from 'react'
import { Link } from 'react-router-dom'
import { Stack, Typography } from '@mui/material'

import { Button } from '@jeeves/new-components'

export const Help = () => {
  return (
    <Stack spacing={4}>
      <Typography variant="h2" sx={{ color: 'primary.main' }}>
        Need Help?
      </Typography>
      <Button component={Link} to="/help">
        {'Visit Docs & Support'}
      </Button>
    </Stack>
  )
}
