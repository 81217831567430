/** @jsxRuntime classic */
/** @jsx jsx */
import styled from '@emotion/styled'
import { jsx, css } from '@emotion/react'
import { Fragment, useEffect, useState } from 'react'
import { withStyles, Typography } from '@material-ui/core'
import { Button } from '@jeeves/components/Primitives'
import RequestCard from './components/RequestCard'

import qs from 'qs'
import useAccess from './hooks/useAccess'

const styles = theme => ({
  appBarSpacer: theme.mixins.toolbar,
  header: {
    marginTop: '24px',
  },
})

const ApproveButton = ({ isGrant, onClick, isSubmitted }) => {
  return (
    <Button
      isGrant={isGrant}
      color="primary"
      variant="contained"
      onClick={onClick}
      disabled={isSubmitted}
    >
      APPROVE
    </Button>
  )
}

const RevokeButton = ({ isGrant, onClick, isSubmitted }) => (
  <Button
    css={() => css`
      background-color: red;
      color: white;
    `}
    isGrant={isGrant}
    onClick={onClick}
    variant="contained"
    color="red"
    disabled={isSubmitted}
  >
    REVOKE
  </Button>
)

const AccessRequests = props => {
  const { classes } = props
  let query = props.location ? props.location.search : ''
  query = qs.parse(query, { ignoreQueryPrefix: true })

  const isGrant = props.location.pathname.includes('approve')

  const { grantAccess, revokeAccess, getRepoType } = useAccess()
  const [repoType, setRepoType] = useState('')
  const [loading, setLoading] = useState(true)
  const [isSubmitted, setIsSubmitted] = useState(false)

  const handleApprove = async () => {
    try {
      await grantAccess({
        repoID: query.repoId,
        repoName: query.repoName,
        userName: query.username,
        accessDuration: query.accessDuration,
        durationUnit: query.durationUnit,
        accessWithin: query.accessWithin,
        accessUnit: query.accessUnit,
        alertBaseURL: window.location.origin,
      })
      setIsSubmitted(true)
    } catch (e) {
      console.log('error: ', e)
    }
  }

  const handleRevoke = async () => {
    try {
      await revokeAccess({
        repoID: query.repoId,
        repoName: query.repoName,
        userName: query.username,
        alertBaseURL: window.location.origin,
      })
      setIsSubmitted(true)
    } catch (e) {
      console.log('error: ', e)
    }
  }

  useEffect(() => {
    try {
      getRepoType(query.repoId).then(res => {
        setRepoType(res)
        setLoading(false)
      })
    } catch (e) {
      setLoading(false)
    }
  }, [])

  return (
    <Fragment>
      <Typography variant={'h5'} component={'h5'} className={classes.header}>
        {isGrant ? `Grant Request` : `Grant Revoke`}
      </Typography>

      <Typography variant={'body1'} component={'body1'} className={classes.header}>
        <strong>{query.username || ''}</strong>
        {isGrant ? (
          <span> has requested access to {''}</span>
        ) : (
          <span> currently has access to {''}</span>
        )}

        <strong>{query.repoName || ''}</strong>
      </Typography>

      <RequestCard loading={loading} isGrant={isGrant} config={query} repoType={repoType}>
        {isGrant ? (
          <ApproveButton
            isGrant={isGrant}
            isSubmitted={isSubmitted}
            onClick={() => handleApprove()}
          />
        ) : (
          <RevokeButton
            isGrant={isGrant}
            isSubmitted={isSubmitted}
            onClick={() => handleRevoke()}
          />
        )}
      </RequestCard>
    </Fragment>
  )
}

export default withStyles(styles)(AccessRequests)
