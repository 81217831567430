/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Fragment, useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Table, TableBody, TableFooter, TableRow } from '@material-ui/core'

import Row from './components/Row'
import Create from './components/Create'

import { useAuth } from '@jeeves/components/Auth'
import { stableSort, getSorting } from '@jeeves/utils'
import Loading from '@jeeves/components/Loading'
import RowSpan from '@jeeves/components/RowSpan'
import Toolbar from '@jeeves/components/Toolbar'
import { TableHead, TablePagination } from '@jeeves/components/Table'
import { useAppConfig } from '@jeeves/hooks'
import { Button, Paper } from '@jeeves/components/Primitives'

import { PoliciesProvider } from './contexts/PoliciesContext'
import usePolicies from './hooks/usePolicies'
import HelperText from './components/HelperText'
import Filters from './components/Filters'

const styles = theme => ({
  table: {
    borderCollapse: 'separate',
  },
})

const Policies = ({ classes }) => {
  const [createDialogOpen, setCreateDialogOpen] = useState(false)
  const { hasPermission } = useAuth()
  const { handleRequestSort, handleChangePage, handleChangeRowsPerPage, setPolicies, state } =
    usePolicies()
  const { rowsPerPage, page, order, orderBy, policies, crudCount } = state

  const { config } = useAppConfig()

  const [inFlight, setInFlight] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        setInFlight(true)
        await setPolicies()
        setInFlight(false)
      } catch (error) {
        setError(true)
        console.log('Error', error)
      } finally {
        setLoading(false)
      }
    }

    fetchData()

    const mainInterval = setInterval(async () => {
      if (!inFlight) {
        setInFlight(true)
        await setPolicies()
        setInFlight(false)
      } else {
        console.log('Request in flight, skipping')
      }
    }, 5000)

    return () => clearInterval(mainInterval)
  }, [crudCount]) // eslint-disable-line

  if (!hasPermission('policies:read')) {
    return null
  }

  // description, type, tags, severity, events
  const columns = [
    { id: 'status', label: null },
    // { id: 'id', label: 'Policy ID' },
    { id: 'name', label: 'Name' },
    { id: 'description', label: 'Description' },
    config?.isDemo ? { id: 'violations', label: 'Violations' } : null,
    { id: 'labels', label: 'Tags', disableSort: true },
    { id: 'expand', label: null },
  ]

  return (
    <Fragment>
      <Paper>
        <Toolbar
          title="Authorization Policies"
          subtitle="An authorization policy specifies who can perform reads, updates, and deletes in specified data schemas, tables/collections, and attributes."
          chip={policies?.length}
          titleAction={
            <Fragment>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setCreateDialogOpen(true)}
                disabled={!hasPermission('policies:create')}
              >
                Add Policy
              </Button>
              <Create open={createDialogOpen} setOpen={setCreateDialogOpen} />
            </Fragment>
          }
        >
          {config?.isDemo && <Filters />}
        </Toolbar>

        <Table className={classes.table} aria-labelledby="collectors">
          <TableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            columns={columns}
            rowCount={policies.length}
          />
          <TableBody>
            {loading && (
              <RowSpan colSpan={columns.length}>
                <Loading />
              </RowSpan>
            )}
            {!loading && policies.length === 0 && !error && (
              <RowSpan colSpan={columns.length}>There are no policies added yet</RowSpan>
            )}
            {!loading && error && (
              <RowSpan colSpan={columns.length}>There was a problem loading policies</RowSpan>
            )}
            {!loading &&
              policies.length > 0 &&
              !error &&
              policies
                .sort(getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((policy, index) => (
                  <Row
                    key={policy.id}
                    policy={policy}
                    expandedColSpan={policies.length}
                    colorIndex={index}
                    config={config}
                  />
                ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                count={policies.length}
                colSpan={7}
                page={page}
                rowsPerPage={rowsPerPage}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Paper>
      <HelperText />
    </Fragment>
  )
}

const PoliciesWrapper = props => (
  <PoliciesProvider>
    <Policies {...props} />
  </PoliciesProvider>
)

export default withStyles(styles)(PoliciesWrapper)
