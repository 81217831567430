import { MutationHookOptions, useMutation } from '@apollo/client'
import { graphql } from '@jeeves/graphql'
import {
  CreateLoggingIntegrationMutation,
  CreateLoggingIntegrationMutationVariables,
} from '@jeeves/graphql/graphql'
import { useToast } from '@jeeves/new-components'

const CREATE_LOGGING_INTEGRATION = graphql(`
  mutation CreateLoggingIntegration($integration: CreateLoggingIntegrationInput!) {
    createLoggingIntegration(integration: $integration) {
      loggingIntegration {
        id
        name
        ...useCloudwatch_cloudWatchIntegration
        ...useCustom_customLoggingIntegration
        ...useDatadog_datadogIntegration
        ...useELK_ELKIntegration
        ...useSplunk_splunkIntegration
        ...useSumologic_sumoLogicIntegration
      }
    }
  }
`)

type CreateLoggingIntegrationOptions = MutationHookOptions<
  CreateLoggingIntegrationMutation,
  CreateLoggingIntegrationMutationVariables
>

type OnCompleted = CreateLoggingIntegrationOptions['onCompleted']
type OnError = CreateLoggingIntegrationOptions['onError']

const useCreateLoggingIntegration = ({
  onCompleted,
  onError,
}: {
  onCompleted?: OnCompleted
  onError?: OnError
}) => {
  const { toast } = useToast()

  return useMutation(CREATE_LOGGING_INTEGRATION, {
    onError:
      onError ||
      (_ => {
        toast({
          variant: 'error',
          description:
            'An error occurred while creating this logging integration, please try again.',
        })
      }),
    onCompleted,
    refetchQueries: ['SIEMIntegrations'],
  })
}

export default useCreateLoggingIntegration
