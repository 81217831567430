import { useState } from 'react'
import { Table, TableHead, TableBody, TableCell, TableSortLabel } from '@mui/material'

import { FragmentType, graphql, useFragment } from '@jeeves/graphql'
import { TableContainer } from '@jeeves/new-components'
import { descendingComparator } from '@jeeves/utils/comparation'
import { SidecarsRow, SidecarsFooter } from '../index'

const SidecarsTable_QueryFragment = graphql(`
  fragment SidecarsTable_QueryFragment on Query {
    sidecars {
      id
      name
      labels
      cloud
      instances {
        id
      }
      bindings(first: $firstBindings) {
        totalCount
      }
      ...SidecarsRow_SidecarFragment
    }
    ...SidecarsRow_QueryFragment
  }
`)

const headCells = [
  {
    id: 'name',
    label: 'Name',
    sortable: true,
  },
  {
    id: 'tags',
    label: 'Tags',
    sortable: false,
  },
  {
    id: 'cloud',
    label: 'Cloud',
    sortable: true,
  },
  {
    id: 'instances',
    label: 'Instances',
    sortable: true,
  },
  {
    id: 'bindings',
    label: 'Bindings',
    sortable: true,
  },
]
const DEFAULT_PAGE_SIZE = 10

export const pageSizeOptions = [10, 25, 50] as const

interface Props {
  query: FragmentType<typeof SidecarsTable_QueryFragment>
  searchValue: string
  setPageIndex: React.Dispatch<React.SetStateAction<number>>
  pageIndex: number
}

const SidecarsTable = ({ query: queryProp, searchValue, pageIndex, setPageIndex }: Props) => {
  const query = useFragment(SidecarsTable_QueryFragment, queryProp)
  const sidecars = query.sidecars

  type Sidecar = (typeof sidecars)[number]
  type Order = 'asc' | 'desc'
  type SidecarKeys = keyof Pick<Sidecar, 'name' | 'instances' | 'bindings' | 'cloud'>

  const [pageSize, setPageSize] = useState<(typeof pageSizeOptions)[number]>(DEFAULT_PAGE_SIZE)
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<SidecarKeys>('name')

  const filteredSidecars = sidecars.filter(sidecar => sidecar?.name.includes(searchValue))
  const startIndex = pageIndex * pageSize
  const endIndex = startIndex + pageSize

  const sidecarsComparator = (sidecarA: Sidecar, sidecarB: Sidecar) => {
    const isAsc = order === 'asc'
    if (orderBy === 'name') {
      return isAsc
        ? sidecarA.name.localeCompare(sidecarB.name)
        : sidecarB.name.localeCompare(sidecarA.name)
    }

    if (orderBy === 'bindings') {
      return isAsc
        ? descendingComparator(sidecarA.bindings.totalCount, sidecarB.bindings.totalCount)
        : -descendingComparator(sidecarA.bindings.totalCount, sidecarB.bindings.totalCount)
    }

    if (orderBy === 'instances') {
      return isAsc
        ? descendingComparator(sidecarA.instances.length, sidecarB.instances.length)
        : -descendingComparator(sidecarA.instances.length, sidecarB.instances.length)
    }

    if (orderBy === 'cloud') {
      const cloudA = sidecarA.cloud ?? ''
      const cloudB = sidecarB.cloud ?? ''

      return isAsc ? cloudA.localeCompare(cloudB) : cloudB.localeCompare(cloudA)
    }

    return 0
  }

  const sortedSidecars = filteredSidecars.slice().sort(sidecarsComparator)
  const currentSidecarsOnPage = sortedSidecars.slice(startIndex, endIndex)

  return (
    <TableContainer
      sx={{
        'tr:last-child': {
          '& td': {
            borderBottomWidth: 1,
            borderBottomStyle: 'solid',
            borderColor: 'cyralColors.grey.200',
          },
        },
      }}
    >
      <Table>
        <TableHead>
          <tr>
            <th></th>
            {headCells.map(headCell => {
              if (headCell.sortable) {
                return (
                  <TableCell
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={() => {
                        setOrderBy(headCell.id as SidecarKeys)
                        setOrder(order === 'asc' ? 'desc' : 'asc')
                        setPageIndex(0)
                      }}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                )
              }

              return <th key={headCell.id}>{headCell.label}</th>
            })}
          </tr>
        </TableHead>
        <TableBody>
          {currentSidecarsOnPage.map(sidecar => {
            return <SidecarsRow key={sidecar!.id} sidecar={sidecar!} query={query} />
          })}
        </TableBody>
        <SidecarsFooter
          availableSidecarsAmount={filteredSidecars.length}
          pageSize={pageSize}
          pageIndex={pageIndex}
          setPageSize={setPageSize}
          setPageIndex={setPageIndex}
        />
      </Table>
    </TableContainer>
  )
}

export default SidecarsTable
