import { useState } from 'react'

import {
  Box,
  DialogContent,
  DialogTitle,
  FormHelperText,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from '@mui/material'
import {
  Close as CloseIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material'

import {
  Button,
  Dialog,
  DialogActions,
  IconCopyButton,
  Input,
  InputLabel,
} from '@jeeves/new-components'

import { useCreateSidecarCredential } from '../hooks'

const CustomDeploymentModal = () => {
  const [createSidecarCredential, { data, loading, reset }] = useCreateSidecarCredential()

  const isModalOpen = !!data

  const sidecarId = data?.createSidecarCredential?.sidecarCredential?.sidecar.id
  const clientId = data?.createSidecarCredential?.sidecarCredential?.clientId
  const clientSecret = data?.createSidecarCredential?.clientSecret
  const controlPlaneURL = (window as any)._env_.control_plane_address || '<CONTROL_PLANE_URL>'

  const [clientSecretVisibility, setClientSecretVisibility] = useState(false)

  const onClose = () => {
    reset()
    setClientSecretVisibility(false)
  }

  const onOpen = async () => {
    await createSidecarCredential()
  }

  const toggleVisibility = () => {
    setClientSecretVisibility(!clientSecretVisibility)
  }

  return (
    <>
      <Button variant="contained" sx={{ flexShrink: 0 }} onClick={onOpen} loading={loading}>
        Generate Deployment Parameters
      </Button>

      <Dialog open={isModalOpen} onClose={onClose} fullWidth>
        <DialogTitle
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h3" sx={{ color: 'text.primary' }}>
            Custom Deployment Parameters
          </Typography>
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <InputLabel>Sidecar ID</InputLabel>
              <Input
                sx={{
                  fontFamily: theme => theme.typography.code,
                }}
                value={sidecarId}
                type="text"
                endAdornment={
                  <InputAdornment position="end">
                    <IconCopyButton text={sidecarId} />
                  </InputAdornment>
                }
              />
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <InputLabel>Control Plane URL</InputLabel>
              <Input
                sx={{
                  fontFamily: theme => theme.typography.code,
                }}
                value={controlPlaneURL}
                type="text"
                endAdornment={
                  <InputAdornment position="end">
                    <IconCopyButton text={controlPlaneURL} />
                  </InputAdornment>
                }
              />
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <InputLabel>Client ID</InputLabel>
              <Input
                sx={{
                  fontFamily: theme => theme.typography.code,
                }}
                value={clientId}
                type="text"
                endAdornment={
                  <InputAdornment position="end">
                    <IconCopyButton text={clientId} />
                  </InputAdornment>
                }
              />
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <InputLabel>Client Secret</InputLabel>
              <Input
                sx={{
                  fontFamily: theme => theme.typography.code,
                }}
                value={clientSecret}
                type={clientSecretVisibility ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconCopyButton text={clientSecret} />

                    <IconButton onClick={toggleVisibility}>
                      {clientSecretVisibility ? (
                        <VisibilityOffIcon color="secondary" fontSize="small" />
                      ) : (
                        <VisibilityIcon color="secondary" fontSize="small" />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText
                sx={{
                  typography: 'body2',
                }}
              >
                Make sure to copy the client secret. It will not be shown again.
              </FormHelperText>
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Stack direction="row" sx={{ justifyContent: 'flex-end' }}>
            <Button variant="contained" color="primary" onClick={onClose}>
              Done
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default CustomDeploymentModal
