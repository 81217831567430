import { useState } from 'react'
import { Box, Table, TableHead, TableBody } from '@mui/material'

import { TableContainer } from '@jeeves/new-components'
import DeleteCredentialsModal from '../DeleteCredentialsModal'
import CredentialsRow from '../CredentialsRow'

import { graphql, FragmentType, useFragment } from '@jeeves/graphql'

const CredentialsTable_SidecarFragment = graphql(`
  fragment CredentialsTable_SidecarFragment on Sidecar {
    credentials {
      clientId
      ...CredentialsRow_SidecarCredentialFragment
    }
  }
`)

interface Props {
  sidecar: FragmentType<typeof CredentialsTable_SidecarFragment>
}

const CredentialsTable = ({ sidecar: sidecarProp }: Props) => {
  const [selectedCredentialToDelete, setSelectedCredentialToDelete] = useState('')

  const sidecar = useFragment(CredentialsTable_SidecarFragment, sidecarProp)

  return (
    <>
      <TableContainer
        sx={{
          'tr:last-child': {
            '& td': {
              borderBottomWidth: 1,
              borderBottomStyle: 'solid',
              borderColor: 'cyralColors.grey.200',
            },
          },
        }}
      >
        <Table>
          <TableHead>
            <Box component="tr">
              <Box component="th" scope="col" sx={{ whiteSpace: 'nowrap' }}>
                Client Id
              </Box>
              <Box component="th" scope="col">
                Created
              </Box>
              <Box component="th" scope="col"></Box>
            </Box>
          </TableHead>
          <TableBody>
            {sidecar.credentials?.map(credential => (
              <CredentialsRow
                key={credential.clientId}
                sidecarCredential={credential}
                setSelectedCredentialToDelete={setSelectedCredentialToDelete}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <DeleteCredentialsModal
        setSelectedCredentialToDelete={setSelectedCredentialToDelete}
        selectedCredentialToDelete={selectedCredentialToDelete}
      />
    </>
  )
}

export default CredentialsTable
