/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { useState } from 'react'
import { IconButton, Tooltip } from '@material-ui/core'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import { CopyToClipboard } from 'react-copy-to-clipboard'

export const CopyButton = ({ copyText, children, ...props }) => {
  const [isCopied, setIsCopied] = useState(false)

  const handleOnCopy = () => {
    setIsCopied(true)
    setTimeout(() => {
      setIsCopied(false)
    }, 3000)
  }

  return (
    <CopyToClipboard text={copyText} onCopy={handleOnCopy} {...props}>
      <Tooltip title={isCopied ? 'Copied!' : 'Copy'}>{children}</Tooltip>
    </CopyToClipboard>
  )
}

export const IconCopyButton = ({ copyText, ...props }) => (
  <CopyButton copyText={copyText} {...props}>
    <IconButton color="default">
      <FileCopyIcon />
    </IconButton>
  </CopyButton>
)
