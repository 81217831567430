import { z } from 'zod'

const iamRoleSchema = z.object({
  value: z.string().min(1, { message: 'Please enter an IAM role ARN.' }),
})

export const awsIamFormSchema = z.object({
  name: z.string().min(1, { message: 'Please enter a name.' }),
  iamRoles: z.array(iamRoleSchema).min(1, { message: 'Please enter at least 1 IAM role ARN.' }),
  description: z.string().optional(),
})

export type AwsIamFormSchema = z.infer<typeof awsIamFormSchema>

export const getMutationInput = (formValues: AwsIamFormSchema) => {
  const { name, description, iamRoles } = formValues

  return {
    name,
    description,
    iamRoleARNs: iamRoles.map(role => role.value),
  }
}
