import React, { useState } from 'react'

import { useMount, useAuth } from '@jeeves/hooks'
import {
  AddIntegrationButton,
  ErrorState,
  IntegrationDialogContent,
  IntegrationList,
  IntegrationLoading,
  ZeroState,
} from '../../primitives'
import IntegrationForm from './IntegrationForm'

import useSlack from '../Slack/hooks/useSlack'
import logo from './images/slack.png'

const Slack = () => {
  const {
    updateIntegrations,
    integrations,
    addIntegration,
    putIntegration,
    deleteIntegration,
  } = useSlack()
  const { hasPermission } = useAuth()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [adding, setAdding] = useState(false)

  const fetchData = async () => {
    setLoading(true)
    try {
      await updateIntegrations()
    } catch (e) {
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  useMount(() => {
    fetchData()
  })

  const createIntegration = async payload => {
    await addIntegration(payload)
    setAdding(false)
  }

  return (
    <IntegrationDialogContent>
      <IntegrationList>
        {loading && <IntegrationLoading />}
        {!loading && error && <ErrorState />}
        {!loading && !error && integrations.length === 0 && <ZeroState />}
        {!loading &&
          !error &&
          integrations.length > 0 &&
          integrations.map(integration => (
            <IntegrationForm
              integration={integration}
              key={integration.id}
              onDelete={async () => {
                await deleteIntegration(integration.id)
                fetchData()
              }}
              onSave={async payload => {
                await putIntegration(payload, integration.id)
                fetchData()
              }}
            />
          ))}
        {adding && (
          <IntegrationForm
            integration={null}
            creating={true}
            onSave={async (...params) => {
              await createIntegration(...params)
              fetchData()
            }}
            onCancel={() => setAdding(false)}
          />
        )}
      </IntegrationList>

      <AddIntegrationButton
        isOpen={adding}
        setOpen={setAdding}
        disabled={!hasPermission('integrations:create') || adding}
      />
    </IntegrationDialogContent>
  )
}

Slack.metadata = {
  name: 'Slack',
  summary: 'Send Cyral alerts to your team through Slack messaging.',
  enabled: true,
  category: 'Alerting',
  slug: 'slack',
  logo,
}

export default Slack
