/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { useState } from 'react'
import { withStyles, Typography, Dialog, DialogTitle, DialogActions } from '@material-ui/core'
import { Button } from '@jeeves/components/Primitives'
import { ButtonLoading } from '@jeeves/components/Loading'
import ExpressClient from '@jeeves/clients/express'
import { useAuth } from '@jeeves/components/Auth'

const styles = theme => ({
  hideEditButton: {
    display: 'none',
  },
  inputField: {
    marginBottom: theme.spacing.unit * 2,
  },
  DialogActions: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: theme.spacing.unit * 3,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
    paddingTop: 0,
  },
  deleteButton: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
})

const DeleteServiceAccountDialog = ({ classes, open, onClose, serviceAccount, refresh }) => {
  const [loading, setLoading] = useState(false)
  const { getTokenSilently } = useAuth()
  const ec = new ExpressClient(getTokenSilently)

  const handleDelete = async () => {
    setLoading(true)
    try {
      await ec.delete(`/users/serviceAccounts/${serviceAccount.clientId}`)
      onClose()
      refresh()
    } catch (e) {
      console.error(e)
      throw e
    } finally {
      setLoading(false)
    }
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title" className={classes.DialogTitle}>
        Confirm Deletion
        <Typography variant="caption" css={{ fontSize: '1rem' }}>
          Are you sure you want to delete API client <strong>{serviceAccount.displayName}</strong>?
        </Typography>
      </DialogTitle>
      <DialogActions className={classes.DialogActions}>
        <div css={{ flexGrow: 1 }} />

        <Button variant="contained" onClick={onClose}>
          Cancel
        </Button>
        <div
          css={() => css`
            position: relative;
          `}
        >
          <Button
            onClick={handleDelete}
            variant="contained"
            disabled={loading}
            className={classes.deleteButton}
          >
            Delete
          </Button>
          {loading && <ButtonLoading />}
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default withStyles(styles)(DeleteServiceAccountDialog)
