import React from 'react'
import { TextField } from '@jeeves/components/Primitives'
import Search from '@material-ui/icons/Search'
import InputAdornment from '@material-ui/core/InputAdornment'

// NOTE: 'style' prop was used to style the 'Search' icon because otherwise, MUI would've overridden any styles.
// Once we refactor out the code so that MUI is only used with Emotion, we'll be able to switch to using
// the CSS prop or styled components.
const SearchField = props => {
  return (
    <TextField
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Search
              style={{
                color: '#dadada',
              }}
            />
          </InputAdornment>
        ),
      }}
      {...props}
    />
  )
}

export default SearchField
