import * as React from 'react'

import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Input from '@jeeves/new-components/Input'
import RoleSelectItem from './RoleSelectItem'

const RoleSelect = ({ roles, role, handleRoleNameChange }) => {
  return (
    <Select
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      }}
      input={<Input />}
      value={role?.name}
      onChange={handleRoleNameChange}
      renderValue={() => <RoleSelectItem role={role} abbreviate />}
    >
      {roles.map(role => (
        <MenuItem value={role.name}>
          <RoleSelectItem role={role} />
        </MenuItem>
      ))}
    </Select>
  )
}

export default RoleSelect
