/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Link, TableCell, Tooltip } from '@material-ui/core/'
import { get as lodashGet } from 'lodash'

const DEFAULT_ROLES = ['User', 'Admin', 'Super Admin']

const EditGroupButton = ({ group, openEditGroupDialog, user }) => {
  const isDefaultRole = DEFAULT_ROLES.includes(group)

  const handleOnOpen = () => {
    openEditGroupDialog()
  }

  const hasUpdatePermission = lodashGet(
    user,
    'app_metadata.authorization.permissions',
    []
  ).includes('roles:update')

  return (
    <TableCell
      key={group}
      css={css`
        text-align: center;
        padding: 4px 24px;
        width: 130px;
      `}
    >
      {isDefaultRole ? (
        <Tooltip title="A default role cannot be edited">
          <Link
            css={{ fontFamily: 'Open Sans', fontSize: '12px' }}
            underline="none"
            component="button"
          >
            {group}
          </Link>
        </Tooltip>
      ) : (
        <Tooltip title="Edit role">
          <Link
            css={{ fontFamily: 'Open Sans', fontSize: '12px', textDecoration: 'underline' }}
            component="button"
            onClick={hasUpdatePermission ? handleOnOpen : null}
          >
            {group}
          </Link>
        </Tooltip>
      )}
    </TableCell>
  )
}

export default EditGroupButton
