import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { useAuth } from '@jeeves/components/Auth'
import { ConnectionTypesTabs } from '../../../../components'
import ConnectionFields, {
  Host,
  Port,
  Username,
  Password,
  Database,
} from '../../ConnectionInfoFields'

import { getUsername, getHost, getPorts } from '../../../../utils'

import getConnectionTypes from './connectionTypes'

const RedShiftAccordionContent = ({
  repo,
  accessPortalSidecar,
  accessPortalSidecarBinding,
  userAccountEdge,
}) => {
  const [database, setDatabase] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [accessToken, setAccessToken] = useState('')
  const { user } = useAuth()

  const connectionInfo = {
    username: getUsername(user, repo, userAccountEdge),
    host: getHost(accessPortalSidecar),
    ports: getPorts(accessPortalSidecarBinding),
    password: accessToken,
    database,
  }

  const connectionTypes = getConnectionTypes(connectionInfo, {
    showPassword,
  })

  return (
    <Stack spacing={3}>
      <Box display="grid" gridTemplateColumns="repeat(2, 1fr)">
        <Stack spacing={0.5}>
          <Typography
            variant="h6"
            sx={{
              color: 'text.secondary',
            }}
          >
            Connection Info
          </Typography>

          <ConnectionFields connectionInfo={connectionInfo}>
            <Username />
            <Password
              showPassword={showPassword}
              setShowPassword={setShowPassword}
              setAccessToken={setAccessToken}
            />
            <Host />
            <Port />
            <Database setDatabase={setDatabase} />
          </ConnectionFields>
        </Stack>
      </Box>

      <ConnectionTypesTabs connectionTypes={connectionTypes} />
    </Stack>
  )
}

export default RedShiftAccordionContent
