import { Stack } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import {
  ConnectionFormatSelect,
  SRVRecordFields,
} from '../../EditMongoDBReplicaSetRepoFields/components'

import StaticNodesShardedClusterFields from './StaticNodesShardedClusterFields'

const getConnectionFormatSpecificFields = connectionFormat => {
  switch (connectionFormat) {
    case 'StaticNodeSpecification':
      return <StaticNodesShardedClusterFields />
    case 'SRVRecordSpecification':
      return <SRVRecordFields />
    default:
      return null
  }
}

const ShardedClusterFields = () => {
  const { watch } = useFormContext()

  const connectionFormat = watch('connectionFormat') || 'StaticNodeSpecification'

  return (
    <Stack spacing={2}>
      <ConnectionFormatSelect />

      {getConnectionFormatSpecificFields(connectionFormat)}
    </Stack>
  )
}

export default ShardedClusterFields
