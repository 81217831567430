import { Divider, Stack, Typography } from '@mui/material'

import { FragmentType, graphql, useFragment } from '@jeeves/graphql'
import { getRelativeTime } from '@jeeves/utils/duration'
import { InlineCode } from '@jeeves/new-components'
import HealthIndicator from '../HealthIndicator'

const InstanceInfo_SidecarInstanceFragment = graphql(`
  fragment InstanceInfo_SidecarInstanceFragment on SidecarInstance {
    id
    version
    startTime
    metrics {
      queriesPerSecond
      activeConnections
    }
    recyclable
    versionIsDynamic
    isRecycling
    health
  }
`)

interface Props {
  sidecarInstance: FragmentType<typeof InstanceInfo_SidecarInstanceFragment>
}

const InstanceInfo = ({ sidecarInstance: sidecarInstanceProp }: Props) => {
  const sidecarInstance = useFragment(InstanceInfo_SidecarInstanceFragment, sidecarInstanceProp)

  const {
    health,
    version,
    startTime,
    metrics: { queriesPerSecond, activeConnections },
  } = sidecarInstance

  return (
    <Stack
      spacing={1}
      direction="row"
      divider={<Divider orientation="vertical" flexItem />}
      sx={{ alignItems: 'center' }}
    >
      <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
        <Typography variant="h6" sx={{ color: 'text.secondary' }}>
          Status:
        </Typography>
        <HealthIndicator health={health} />
      </Stack>

      <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
        <Typography variant="h6" sx={{ color: 'text.secondary' }}>
          Instance Version:
        </Typography>
        <InlineCode>{version}</InlineCode>
      </Stack>

      <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
        <Typography variant="h6" sx={{ color: 'text.secondary' }}>
          Age:
        </Typography>
        <Typography variant="h6" sx={{ color: 'text.primary' }}>
          {getRelativeTime(startTime)}
        </Typography>
      </Stack>

      <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
        <Typography variant="h6" sx={{ color: 'text.secondary' }}>
          Throughput (QPS):
        </Typography>
        <Typography variant="h6" sx={{ color: 'text.primary' }}>
          {queriesPerSecond || 'n/a'}
        </Typography>
      </Stack>

      <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
        <Typography variant="h6" sx={{ color: 'text.secondary' }}>
          Active Connections
        </Typography>
        <Typography variant="h6" sx={{ color: 'text.primary' }}>
          {activeConnections || 'n/a'}
        </Typography>
      </Stack>
    </Stack>
  )
}

export default InstanceInfo
