import * as React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'

import { Input, InputLabel } from '@jeeves/new-components'
import { MIN_PORT_NUMBER, MAX_PORT_NUMBER } from '@jeeves/utils/helpers'

const EnableS3Browser = ({ onChange, value }) => {
  const [enableS3Browser, setEnableS3Browser] = React.useState(false)
  const {
    register,
    unregister,
    formState: { errors },
    watch,
    control,
  } = useFormContext()

  const bindingAlreadyExists = Boolean(watch('bindingId'))

  const error = errors?.browserPort

  const controlledEnableS3Browser = watch('enableS3Browser')

  React.useEffect(() => {
    setEnableS3Browser(controlledEnableS3Browser)
  }, [controlledEnableS3Browser])

  return (
    <Box
      sx={{
        border: 1,
        borderColor: 'cyralColors.grey.200',
        borderRadius: theme => theme.radii.base,
        p: 2,
      }}
    >
      <FormControlLabel
        control={
          bindingAlreadyExists ? (
            <Controller
              control={control}
              name="enableS3Browser"
              render={({ field: { onChange, onBlur, value, name, ref } }) => {
                return (
                  <Switch
                    name={name}
                    onChange={onChange}
                    checked={value}
                    id="enable-s3Browser-switch"
                  />
                )
              }}
            />
          ) : (
            <Switch checked={enableS3Browser} onChange={() => setEnableS3Browser(prev => !prev)} />
          )
        }
        label="Enable S3 Browser"
        componentsProps={{
          typography: {
            variant: 'h6',
          },
        }}
      />

      <Collapse in={enableS3Browser} onExiting={() => unregister('browserPort')}>
        <FormControl variant="standard" error={Boolean(error)} sx={{ mt: 2 }} fullWidth>
          <InputLabel htmlFor="s3-browser-port-input">S3 Browser port</InputLabel>

          <Input
            id="s3-browser-port-input"
            type="number"
            defaultValue=""
            inputProps={{
              ...(enableS3Browser &&
                register('browserPort', {
                  required: 'This field is required.',
                  min: {
                    value: MIN_PORT_NUMBER,
                    message: `Value must be greater than or equal to ${MIN_PORT_NUMBER}.`,
                  },
                  max: {
                    value: MAX_PORT_NUMBER,
                    message: `Value must be less than or equal to ${MAX_PORT_NUMBER}.`,
                  },
                })),
            }}
          />

          {error && (
            <FormHelperText
              sx={{
                typography: 'body2',
              }}
            >
              {error.message}
            </FormHelperText>
          )}
        </FormControl>
      </Collapse>
    </Box>
  )
}

export default EnableS3Browser
