import { useContext } from 'react'
import { PopupContext } from '@jeeves/components/PopupMessage/contexts'
import {
  defaultDisplayTimes,
  defaultMessages,
  popupTypes,
} from '@jeeves/components/PopupMessage/constants'

const usePopup = () => {
  const [state, setState] = useContext(PopupContext)
  const { isDisplay, displayMessage, variant, displayTime } = state

  const clearPopup = () => {
    setState(prevState => ({
      ...prevState,
      isDisplay: false,
    }))
  }

  const setPopup = (variant, displayMessage, config = {}) => {
    const { displayTime } = config
    const _displayMessage =
      typeof displayMessage === 'object' ? JSON.stringify(displayMessage) : displayMessage

    setState(prevState => ({
      ...prevState,
      isDisplay: true,
      variant: variant,
      displayMessage: _displayMessage || defaultMessages[variant],
      displayTime: displayTime || defaultDisplayTimes[variant],
    }))

    setTimeout(clearPopup, displayTime || defaultDisplayTimes[variant])
  }

  const showError = errorMessage => setPopup(popupTypes.ERROR, errorMessage)
  const showSuccess = message => setPopup(popupTypes.SUCCESS, message)

  return {
    showError,
    showSuccess,
    setPopup,
    clearPopup,
    isDisplay,
    displayMessage,
    variant,
    popupTypes,
    displayTime,
  }
}

export default usePopup
