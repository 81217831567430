import React, { useState, useEffect } from 'react'
import { useAuth } from '@jeeves/components/Auth'

import useHive from './hooks/useHive'
import Existing from './Existing'
import IntegrationForm from './IntegrationForm'
import {
  ErrorState,
  ZeroState,
  AddIntegrationButton,
  IntegrationDialogContent,
  IntegrationLoading,
  IntegrationList,
} from '../../primitives'
import logo from './images/hive.png'

const Hive = () => {
  const {
    updateIntegrations,
    integrations,
    addIntegration,
    putIntegration,
    deleteIntegration,
  } = useHive()
  const { hasPermission } = useAuth()
  const [adding, setAdding] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)

      try {
        await updateIntegrations()
      } catch (e) {
        setError(true)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  const createIntegration = async payload => {
    await addIntegration(payload)
    setAdding(false)
  }

  return (
    <IntegrationDialogContent>
      <IntegrationList>
        {loading && <IntegrationLoading />}
        {!loading && error && <ErrorState />}
        {!loading && !error && integrations.length === 0 && <ZeroState />}
        {!loading &&
          !error &&
          integrations.length > 0 &&
          integrations.map(integration => (
            <Existing
              integration={integration}
              putIntegration={putIntegration}
              deleteIntegration={deleteIntegration}
              key={integration.id}
            />
          ))}
        {adding && (
          <IntegrationForm
            integration={null}
            creating={true}
            onSave={createIntegration}
            onCancel={() => setAdding(false)}
          />
        )}
      </IntegrationList>

      <AddIntegrationButton
        isOpen={false}
        setOpen={setAdding}
        disabled={!hasPermission('integrations:create') || adding}
      />
    </IntegrationDialogContent>
  )
}

Hive.metadata = {
  name: 'The Hive',
  summary: 'Send alerts to The Hive open source Security Incident Response platform.',
  enabled: true,
  category: 'Alerting',
  slug: 'thehive',
  logo,
}

export default Hive
