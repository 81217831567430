import { MouseEventHandler } from 'react'
import Crypto from 'crypto-js'
import { Typography, Stack } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'

type TagColor = 'primary' | 'grey' | 'red' | 'yellow' | 'green' | 'orange' | 'purple' | 'pink'

const TAG_COLORS: TagColor[] = [
  'primary',
  'green',
  'grey',
  'orange',
  'pink',
  'purple',
  'red',
  'yellow',
]

const getHashValue = (tag: string) => {
  return Crypto.MD5(tag.toLowerCase()).toString(Crypto.enc.Hex).slice(-4)
}

const getTagColor = (tag: string) => {
  const HEX_BASE = 16
  const intValue = parseInt(getHashValue(tag), HEX_BASE)
  const index = intValue % TAG_COLORS.length

  return TAG_COLORS[index]
}

interface Props {
  text: string
  color?: TagColor
  onRemove?: MouseEventHandler<SVGSVGElement>
}

const Tag = ({ text, color, onRemove }: Props) => {
  const tagColor = color ?? getTagColor(text)

  let removeIcon = null
  if (onRemove) {
    removeIcon = <ClearIcon fontSize="inherit" onClick={onRemove} sx={{ cursor: 'pointer' }} />
  }

  return (
    <Stack
      spacing={1}
      direction="row"
      sx={{
        backgroundColor: theme => theme.palette.cyralColors[tagColor][100],
        color: theme => theme.palette.cyralColors[tagColor][500],
        padding: theme => theme.spacing(0.25, 1),
        borderRadius: theme => theme.radii.sm,
        alignItems: 'center',
      }}
    >
      <Typography variant="body2" component="span">
        {text}
      </Typography>

      {removeIcon}
    </Stack>
  )
}

export default Tag
