/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { useCallback, useEffect, useState } from 'react'

import ExpressClient from '@jeeves/clients/express'
import { useAuth } from '@jeeves/components/Auth'
import Loading from '@jeeves/components/Loading'
import { IntegrationsGrid } from './Home/Integration'
import { rawServices, Services, FreeTrialServices } from './Services'
import { useAppConfig } from '@jeeves/hooks'
import { useConfExtensionInstances } from '@jeeves/hooks/useConfExtensions'
import { gql, useQuery } from '@apollo/client'

const FETCH_INTEGRATIONS = gql`
  query IntegrationsID {
    genericSAMLIntegrations {
      id
    }
    SIEMIntegrations {
      id
    }
    awsIamIntegrations {
      id
    }
  }
`

const SERVICES_URLS = {
  slack: '/integrations/notifications/slack',
  teams: '/integrations/notifications/teams',
  okta: '/integrations/saml?identityProvider=okta',
  thehive: '/integrations/thehive',
  hashiCorpVault: '/integrations/secretProviders/hcvault',
  pagerduty: '/integrations/pagerduty',
  forgerock: 'integrations/saml?identityProvider=forgerock',
  gsuite: '/integrations/saml?identityProvider=gsuite',
  'azure-ad': '/integrations/saml?identityProvider=aad',
  adfs: '/integrations/saml?identityProvider=adfs-2016',
  pingone: '/integrations/saml?identityProvider=pingone',
  saml: 'saml',
  duo: 'duo',
  logging: 'logging',
  awsIam: 'awsIam',
}

// TODO: add green bg to configure button

const Integrations = () => {
  const { getTokenSilently, hasPermission } = useAuth()
  const [configured, setConfigured] = useState([])
  const [loading, setLoading] = useState(true)
  const { config, license } = useAppConfig()
  const {
    getConfExtensionInstancesResponse: { data: pagerdutyData, loading: pagerdutyLoading },
  } = useConfExtensionInstances({
    getConfExtensionInstancesOptions: {
      variables: { purpose: 'authorization', templateType: 'pagerduty' },
    },
  })
  const {
    getConfExtensionInstancesResponse: { data: duoData, loading: duoLoading },
  } = useConfExtensionInstances({
    getConfExtensionInstancesOptions: {
      variables: { purpose: 'authorization', templateType: 'duoMfa' },
    },
  })
  const pagerdutyIntegrations = pagerdutyData ? pagerdutyData.confExtensionInstances : []
  const duoIntegrations = duoData ? duoData.confExtensionInstances : []

  const { loading: integrationsLoading, data: integrationsData } = useQuery(FETCH_INTEGRATIONS)

  const isIntegrationConfigured = useCallback(
    async serviceUrl => {
      try {
        const ec = new ExpressClient(getTokenSilently)
        const { data } = await ec.get(serviceUrl)
        return data.length > 0
      } catch (e) {
        return false
      }
    },
    [getTokenSilently]
  )

  const getConfigured = useCallback(async () => {
    setLoading(true)

    const configuredIntegrations = await Promise.all(
      Object.entries(SERVICES_URLS).map(async ([key, url]) => {
        const isConfigured =
          key === 'pagerduty'
            ? pagerdutyIntegrations.length > 0
            : key === 'duo'
            ? duoIntegrations.length > 0
            : key === 'saml'
            ? integrationsData?.genericSAMLIntegrations.length > 0
            : key === 'logging'
            ? integrationsData?.SIEMIntegrations.length > 0
            : key === 'awsIam'
            ? integrationsData?.awsIamIntegrations.length > 0
            : await isIntegrationConfigured(url)

        if (isConfigured) return key
      })
    ).then(res => res.filter(item => item !== undefined))

    // TO DO: this is fake
    setConfigured(config.isDemo ? [...configuredIntegrations, 'awsglue'] : configuredIntegrations)
    setLoading(false)
  }, [config.isDemo, isIntegrationConfigured, pagerdutyData, integrationsData, duoData])

  useEffect(() => {
    if (!pagerdutyLoading && !duoLoading && !integrationsLoading) {
      getConfigured()
    }
  }, [pagerdutyLoading, integrationsLoading, duoLoading])

  if (!hasPermission('integrations:read')) {
    return null
  }

  const getEnabledIntegrations = () => {
    if (license.isFreeTrial) {
      return FreeTrialServices
    }
    if (config.isDemo) {
      return rawServices
    }
    return Services
  }

  const shouldShowIntegrationTile = integration => {
    const integrationIsConfigured = configured.includes(integration.metadata.slug)
    const integrationIsActivelySupported = !integration.metadata.isDeprecated

    return integrationIsConfigured || integrationIsActivelySupported
  }

  const integrations = getEnabledIntegrations().filter(shouldShowIntegrationTile)

  return (
    <div
      css={() => css`
        max-width: 1200px;
        margin: 0 auto;
        position: relative;
      `}
    >
      {loading ? (
        <Loading
          css={() => css`
            background: none;
            min-height: 100px;
          `}
        />
      ) : license ? (
        <IntegrationsGrid configured={configured} integrations={integrations} />
      ) : null}
    </div>
  )
}

export default Integrations
