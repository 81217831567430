/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import classNames from 'classnames'
import { Slide, Drawer, withStyles } from '@material-ui/core'
import Stack from '@mui/material/Stack'

import { useAppConfig } from '@jeeves/hooks'
import SetupMenu from './SetupMenu'
import Logo from '../Logo'

import FirstSection from './FirstSection'
import SecondSection from './SecondSection'
import ThirdSection from './ThirdSection'
import FourthSection from './FourthSection'
import SixthSection from './SixthSection'
import SandboxSection from './SandboxSection'

import { ControlPlaneVersion, ToggleOpenButton } from './components'

const styles = () => ({
  drawerPaper: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#061748',
    paddingTop: '32px',
    paddingBottom: '16px',
    overflowY: 'auto',

    whiteSpace: 'nowrap',
    boxShadow:
      '0px 9px 12px rgba(0, 0, 0, 0.14), 0px 3px 16px rgba(0, 0, 0, 0.12), 0px 5px 6px rgba(0, 0, 0, 0.2);',
    transition: 'width .4s ease',
    overflowX: 'hidden',
    width: '100%',
  },
})

const Navigation = ({ classes, navOpen, setOpen }) => {
  const { pathname } = useLocation()
  const { config } = useAppConfig()

  const setupNavActive = pathname === '/setup'

  return (
    <React.Fragment>
      <ToggleOpenButton navOpen={navOpen} setOpen={setOpen} />

      <Drawer
        variant="permanent"
        classes={{
          paper: classNames(classes.drawerPaper),
        }}
        css={{ flexGrow: 1 }}
      >
        <NavLink
          to="/"
          css={() => css`
            flex-shrink: 0;
            margin-left: ${navOpen ? '24px' : '0px'};
          `}
        >
          <Logo iconOnly={!navOpen} />
        </NavLink>

        <nav
          css={() => css`
            flex-grow: 1;
            position: relative;
            overflow-y: auto;
            margin-top: 32px;
            display: flex;
            flex-direction: column;
            transition: ${!navOpen ? '0.4s' : '0s'} all ease;
          `}
        >
          <Stack spacing="16px" style={{ flexGrow: 1 }}>
            <div css={{ flexGrow: 1 }}>
              {setupNavActive ? (
                <Slide in={setupNavActive} direction="right">
                  <SetupMenu iconOnly={!navOpen} />
                </Slide>
              ) : (
                <Stack spacing="16px">
                  {config?.hasSandbox && <SandboxSection iconOnly={!navOpen} />}
                  <FirstSection iconOnly={!navOpen} />
                  <SecondSection iconOnly={!navOpen} />
                  <ThirdSection iconOnly={!navOpen} />
                  <FourthSection iconOnly={!navOpen} />
                </Stack>
              )}
            </div>

            <div
              css={() => css`
                width: 100%;
                flex-shrink: 0;
              `}
            >
              <Stack spacing="16px">
                <SixthSection iconOnly={!navOpen} />
                <ControlPlaneVersion />
              </Stack>
            </div>
          </Stack>
        </nav>
      </Drawer>
    </React.Fragment>
  )
}

export default withStyles(styles)(Navigation)
