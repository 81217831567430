import * as React from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import { useFragment_experimental, gql } from '@apollo/client'
import pluralize from 'pluralize'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import Typography from '@mui/material/Typography'

import { InputLabel, Select, ServiceIcon } from '@jeeves/new-components'
import { isClusterRepo } from '@jeeves/graphql/utils'

const SelectRepository_queryFragment = gql`
  fragment SelectRepository_query on Query {
    repos {
      id
      name
      type
      ... on ClusterRepo {
        numNodes
      }
    }
  }
`

const SelectRepository = ({ query }) => {
  const {
    formState: { errors },
  } = useFormContext()
  const { data } = useFragment_experimental({
    fragment: SelectRepository_queryFragment,
    from: query,
  })
  const selectedRepoId = useWatch({
    name: 'selectedRepo',
  })
  const selectedRepo = data.repos.find(repo => repo.id === selectedRepoId)

  const repos = data?.repos ?? []
  const error = errors?.selectedRepo

  const isSelectClusterRepo = isClusterRepo(selectedRepo?.__typename)
  const isRepoMongoDBReplicaset = selectedRepo?.__typename === 'MongoDBReplicaSetRepo'

  const mongoDBClusterTypeDisplayName = isRepoMongoDBReplicaset ? 'replica set' : 'sharded cluster'

  const helperText =
    error?.message ||
    (isSelectClusterRepo && (
      <React.Fragment>
        This repository is a {mongoDBClusterTypeDisplayName} with{' '}
        <Typography component="strong" sx={{ fontWeight: '700', color: 'text.secondary' }}>
          {selectedRepo.numNodes}
        </Typography>{' '}
        {!isRepoMongoDBReplicaset ? 'mongos ' : ''}
        {pluralize('node', selectedRepo.numNodes)}
      </React.Fragment>
    )) ||
    ''

  return (
    <FormControl variant="standard" error={Boolean(error)} fullWidth>
      <InputLabel id="repository-select-label">Repository Name</InputLabel>

      <Controller
        name="selectedRepo"
        render={({ field }) => (
          <Select
            labelId="repository-select-label"
            id="repository-select"
            options={repos.map(repo => ({
              icon: <ServiceIcon type={repo.type} />,
              label: repo.name,
              value: repo.id,
            }))}
            {...field}
          />
        )}
        rules={{ required: 'This field is required.' }}
      />

      {helperText && (
        <FormHelperText
          sx={{
            typography: 'body2',
          }}
        >
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  )
}

SelectRepository.fragments = {
  SelectRepository_queryFragment,
}

export default SelectRepository
