import { useState } from 'react'
import { Box, Stack, Typography } from '@mui/material'

import { Button } from '@jeeves/new-components'
import { FragmentType, useFragment, graphql } from '@jeeves/graphql'
import { DeleteSidecarModal } from './components'

export const DeleteSidecar_queryFragment = graphql(`
  fragment DeleteSidecar_query on Query {
    ...DeleteSidecarModal_query
  }
`)

interface DeleteSidecarProps {
  query: FragmentType<typeof DeleteSidecar_queryFragment>
}

const DeleteSidecar = ({ query: queryProp }: DeleteSidecarProps) => {
  const [deleteSidecarModalOpen, setDeleteSidecarModalOpen] = useState(false)
  const query = useFragment(DeleteSidecar_queryFragment, queryProp)

  return (
    <>
      <Stack
        sx={{
          border: 1,
          borderColor: 'error.main',
          p: 4,
          borderRadius: theme => theme.radii.base,
        }}
        spacing={4}
      >
        <Typography variant="h3" sx={{ color: 'text.primary' }}>
          Delete Sidecar
        </Typography>

        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          This sidecar cannot be deleted until all data repository bindings have been removed. Any
          user connections to repositories through this sidecar will be interrupted once this
          sidecar is deleted.{' '}
          <Typography component="span" variant="body2" sx={{ color: 'error.main' }}>
            This action cannot be undone.
          </Typography>
        </Typography>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant="outlined" color="error" onClick={() => setDeleteSidecarModalOpen(true)}>
            Delete
          </Button>
        </Box>
      </Stack>
      <DeleteSidecarModal
        open={deleteSidecarModalOpen}
        query={query}
        closeModal={() => setDeleteSidecarModalOpen(false)}
      />
    </>
  )
}

export default DeleteSidecar
