import React from 'react'

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  withStyles,
} from '@material-ui/core'
import IconDone from '@material-ui/icons/Done'

import { getValidRoles } from '@jeeves/utils/helpers'
import EditGroup from '../EditGroup'
import useUsers from '../../hooks/useUsers'

const styles = theme => ({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  permissionItem: {
    textAlign: 'center',
    padding: '4px 24px',
    '& *': {
      color: theme.palette.primary.main,
    },
  },
  columnLabels: {
    fontWeight: 600,
    '& *': {
      fontWeight: 600,
    },
  },
})

const TableCheckCell = ({ role, group, classes }) => {
  const isChecked = role.groups.includes(group.id)
  return (
    <TableCell key={group.id} className={classes.permissionItem}>
      {isChecked === true ? <IconDone style={{ fontSize: '20px' }} /> : ''}
    </TableCell>
  )
}

const ManagementTable = ({ classes, roles, groups: allGroups, closeUsersManagement, license }) => {
  const { openEditGroupDialog, patchUserGroupRoles } = useUsers()

  const groups = getValidRoles(allGroups, ['_Everyone', 'Cyral Admin'])

  return (
    <Table stickyHeader aria-label="sticky table">
      <TableHead>
        <TableRow>
          <TableCell key={'blank'} />
          {groups.map(group => (
            <EditGroup
              openEditGroupDialog={openEditGroupDialog}
              closeUsersManagement={closeUsersManagement}
              patchUserGroupRoles={patchUserGroupRoles}
              groupId={group.id}
              groupName={group.name}
            />
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {roles.map(role => (
          <TableRow hover role="checkbox" tabIndex={-1} key={role.id}>
            <TableCell>
              <Tooltip title={role.description}>
                <div>{role.name}</div>
              </Tooltip>
            </TableCell>
            {groups.map(group => (
              <TableCheckCell role={role} group={group} classes={classes} />
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default withStyles(styles)(ManagementTable)
