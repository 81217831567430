import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { graphql } from '@jeeves/graphql'

import { Box, Stack, Typography } from '@mui/material'

import NewCredentialsModal from './components/NewCredentialsModal'
import CredentialsTable from './components/CredentialsTable'
import CircularProgress from '@mui/material/CircularProgress'

const SIDECAR_CREDENTIALS = graphql(`
  query SidecarCredential($id: String!) {
    sidecar(id: $id) {
      id
      credentials {
        clientId
      }
      ...CredentialsTable_SidecarFragment
    }
  }
`)

const Credentials = () => {
  const { id } = useParams<{ id: string }>()

  const { data, loading, error } = useQuery(SIDECAR_CREDENTIALS, {
    variables: {
      id,
    },
  })

  const credentials = data?.sidecar.credentials ?? []

  return (
    <Stack
      sx={{
        border: 1,
        borderColor: 'cyralColors.grey.200',
        p: 4,
        borderRadius: theme => theme.radii.base,
      }}
      spacing={4}
    >
      <Stack spacing={6}>
        <Typography variant="h3" sx={{ color: 'text.primary' }}>
          Sidecar Credentials
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          The following credentials will be used by the sidecar to communicate with the control
          plane.
        </Typography>
      </Stack>

      <Box>
        {loading ? (
          <Stack spacing={2} sx={{ alignItems: 'center' }}>
            <CircularProgress size={64} />
            <Typography variant="h6" sx={{ color: 'text.secondary' }}>
              Loading credentials...
            </Typography>
          </Stack>
        ) : error ? (
          <Typography variant="h6" sx={{ color: 'text.secondary' }}>
            Sorry, there was an error while fetching credentials
          </Typography>
        ) : (
          <>
            <CredentialsTable sidecar={data!.sidecar} />

            {credentials.length === 0 && (
              <Box sx={{ py: 7 }}>
                <Typography variant="h6" sx={{ color: 'text.secondary', textAlign: 'center' }}>
                  There are no sidecar credentials available.
                </Typography>
              </Box>
            )}
          </>
        )}
      </Box>

      <NewCredentialsModal />
    </Stack>
  )
}

export default Credentials
