import { Alert, Typography } from '@mui/material'

const RepoInaccessibleAlert = () => {
  return (
    <Alert
      severity="info"
      sx={{
        color: 'cyralColors.yellow.500',
        backgroundColor: 'cyralColors.yellow.100',
        '& .MuiAlert-icon': {
          color: 'inherit',
        },
      }}
    >
      <Typography variant="h6" sx={{ color: 'cyralColors.yellow.500' }}>
        This configuration will cause this data repository to be inaccessible through the sidecar.
      </Typography>
    </Alert>
  )
}

export default RepoInaccessibleAlert
