import { Switch, useRouteMatch } from 'react-router-dom'

import PrivateRoute from '@jeeves/components/PrivateRoute'

import { PoliciesMain } from './views/PoliciesMain'
import { AddPolicy } from './views/AddPolicy'
import { PolicyDetails } from './views/PolicyDetails'

const Policies = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <PrivateRoute path={path} requiredPermission={'policies:read'} exact>
        <PoliciesMain />
      </PrivateRoute>
      <PrivateRoute path={`${path}/new`} requiredPermission={'policies:create'}>
        <AddPolicy />
      </PrivateRoute>
      <PrivateRoute path={`${path}/:policyId`} requiredPermission={'policies:read'}>
        <PolicyDetails />
      </PrivateRoute>
    </Switch>
  )
}

export default Policies
