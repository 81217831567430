import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

import { REPO_METADATA } from '@jeeves/constants'
import { UpsertRepoStandardFields } from '@jeeves/pages/components'

const AddRepoStandardFields = () => {
  const { setValue, watch } = useFormContext()

  const repositoryType = watch('repositoryType')

  useEffect(() => {
    if (repositoryType === 's3') {
      setValue('host', REPO_METADATA.S3.defaults.hostname)
      setValue('port', REPO_METADATA.S3.defaults.port)
    } else {
      setValue('host', '')
      setValue('port', '')
    }
  }, [repositoryType, setValue])

  return <UpsertRepoStandardFields />
}

export default AddRepoStandardFields
