import * as React from 'react'
import Button from '@mui/material/Button'

import Dialog from '@mui/material/Dialog'
import Typography from '@mui/material/Typography'

import Stack from '@mui/material/Stack'

import { useForm, Controller } from 'react-hook-form'

import {
  InputLabel,
  Input,
  DialogTitle,
  DialogActions,
  DialogContent,
  ActionsDivider,
} from './Dialogs.styles'
import { gql, useMutation } from '@apollo/client'
import { DATALABELS_QUERY } from '../../DataLabels'

const DELETE_LABEL = gql`
  mutation DeleteDatalabel($datalabelName: String!) {
    deleteDatalabel(datalabelName: $datalabelName) {
      message
    }
  }
`

export const DeleteLabel = ({ deleteLabelOpen, setDeleteLabelOpen, dataLabel }) => {
  const {
    register,
    control,
    trigger,
    resetField,
    getValues,
    reset,
    setValue,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: { confirmation: '' },
  })

  const [deleteLabel, { data, loading, error }] = useMutation(DELETE_LABEL, {
    refetchQueries: [DATALABELS_QUERY],
  })

  const handleOnClose = () => {
    setDeleteLabelOpen(false)
    reset()
  }

  const onSubmit = async () => {
    const { confirmation } = getValues()

    try {
      await deleteLabel({
        variables: { datalabelName: dataLabel.name },
      })
      handleOnClose()
    } catch (e) {
      console.error(e)
    }
  }

  const watchConfirmation = watch('confirmation')

  return (
    <div>
      <Dialog
        open={deleteLabelOpen}
        onClose={() => setDeleteLabelOpen(false)}
        sx={{ radius: '12px' }}
      >
        <DialogTitle>Delete Data Label</DialogTitle>
        {dataLabel.mappedReposRelationship?.edges?.length > 0 ? (
          <React.Fragment>
            <DialogContent>
              <Typography variant="body2" sx={{ mb: 2, color: 'cyralColors.grey.400' }}>
                This data label cannot be deleted because it has affiliated mappings.
              </Typography>
            </DialogContent>
            <ActionsDivider></ActionsDivider>
            <DialogActions sx={{ mt: 2 }}>
              <Button onClick={handleOnClose}>Okay</Button>
            </DialogActions>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <DialogContent>
              <Stack sx={{ mt: 0.5 }}>
                <Typography variant="body2" sx={{ mb: 2, color: 'cyralColors.grey.400' }}>
                  Are you sure you want to delete this data label?
                </Typography>
                <Typography variant="body2" sx={{ mb: 2, color: 'cyralColors.grey.400' }}>
                  Policies that use this label will not be updated. Rules related to this label
                  cannot be enforced after it has been deleted.
                </Typography>
                <Typography
                  sx={{
                    mb: 3,
                    color: 'cyralColors.red.300',
                    fontStyle: 'italic',
                    fontSize: '14px',
                  }}
                >
                  This action cannot be undone.
                </Typography>
              </Stack>
              <Typography sx={{ mb: 0.5 }} variant="h6">
                Please type {dataLabel.name} to confirm
              </Typography>
              <Controller
                name="confirmation"
                control={control}
                render={({ field }) => <Input {...field} fullWidth></Input>}
              />
            </DialogContent>
            <ActionsDivider></ActionsDivider>
            <DialogActions sx={{ mt: 2 }}>
              <Button onClick={handleOnClose}>Cancel</Button>

              <Button
                variant="contained"
                color="primary"
                onClick={onSubmit}
                disabled={dataLabel.name !== watchConfirmation}
              >
                Delete
              </Button>
            </DialogActions>
          </React.Fragment>
        )}
      </Dialog>
    </div>
  )
}
