import { Link } from 'react-router-dom'

import { Button } from '@jeeves/new-components'

const RequestAccess = () => {
  return (
    <Button component={Link} to="/access-portal/request-access">
      Request Access
    </Button>
  )
}

export default RequestAccess
