import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  parseISO,
  formatISODuration,
  secondsInDay,
  secondsInHour,
  secondsInMinute,
} from 'date-fns'
import pluralize from 'pluralize'
import { parse, toSeconds } from 'iso8601-duration'

import { TimeValidity } from './schemas'

const getPluralizedOrSingularizedTime = (value: number, unit: string) =>
  pluralize(unit, value, true)

export const getRelativeTime = (timestamp: string) => {
  const eventDate = parseISO(timestamp)
  const now = new Date()

  const seconds = Math.abs(differenceInSeconds(now, eventDate))
  if (seconds < 60) {
    return getPluralizedOrSingularizedTime(seconds, 'second')
  }

  const minutes = Math.abs(differenceInMinutes(now, eventDate))
  if (minutes < 60) {
    return getPluralizedOrSingularizedTime(minutes, 'minute')
  }

  const hours = Math.abs(differenceInHours(now, eventDate))
  if (hours < 24) {
    return getPluralizedOrSingularizedTime(hours, 'hour')
  }

  const days = Math.abs(differenceInDays(now, eventDate))
  return getPluralizedOrSingularizedTime(days, 'day')
}

export const formatTimeValidityToISO8601Duration = (timeValidity: TimeValidity) => {
  const { unit, value } = timeValidity
  return formatISODuration({
    ...(unit === 'second' && { seconds: value }),
    ...(unit === 'minute' && { minutes: value }),
    ...(unit === 'hour' && { hours: value }),
    ...(unit === 'day' && { days: value }),
  })
}

export const parseISO8601DurationToTimeValidity = (duration: string) => {
  const seconds = Math.trunc(toSeconds(parse(duration)))

  const days = seconds / secondsInDay
  if (Number.isInteger(days)) {
    return { unit: 'day', value: days } as const
  }

  const hours = seconds / secondsInHour
  if (Number.isInteger(hours)) {
    return { unit: 'hour', value: hours } as const
  }

  const minutes = seconds / secondsInMinute
  if (Number.isInteger(minutes)) {
    return { unit: 'minute', value: minutes } as const
  }

  return { unit: 'second', value: seconds } as const
}
