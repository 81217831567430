/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@material-ui/core/'
import { ButtonLoading } from '@jeeves/components/Loading'

const NameDialog = ({ isOpen, value, setValue, handleClose, handleAdd }) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const handleAddClick = async () => {
    setIsSubmitting(true)
    await handleAdd()
    setIsSubmitting(false)
    handleClose()
  }
  return (
    <Dialog open={isOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Integration name</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Provide a name for this SSO provider integration. This is the name users will see when
          they choose this SSO provider for sign-in. Click Add Integration to generate the values
          you’ll need for creating this integration’s SAML app in Okta.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label=""
          type="text"
          variant="outlined"
          fullWidth
          value={value}
          onChange={e => setValue(e.target.value)}
        />
      </DialogContent>
      <DialogActions
        css={css`
          padding: 0 20px 20px 20px;
          margin: 0;
        `}
      >
        <Button onClick={handleClose} variant="outlined" color="secondary">
          Cancel
        </Button>
        <div css={{ position: 'relative' }}>
          <Button
            onClick={handleAddClick}
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Add Integration
          </Button>
          {isSubmitting && <ButtonLoading />}
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default NameDialog
