import uniq from 'lodash/uniq'

import { FragmentType, graphql, useFragment } from '@jeeves/graphql'
import { RepoType } from '@jeeves/graphql/graphql'

const ALL_NON_REPO_SERVICES = [
  { name: 'authenticator', label: 'Authenticator' },
  { name: 'dispatcher', label: 'Dispatcher' },
]

const ALL_REPO_SERVICES = [
  {
    name: 'dynamodb-wire',
    repos: [
      {
        type: RepoType.Dynamodb,
        label: 'DynamoDB',
      },
    ],
  },
  {
    name: 'mongodb-wire',
    repos: [
      {
        type: RepoType.Mongodb,
        label: 'MongoDB',
      },
    ],
  },
  {
    name: 'mysql-wire',
    repos: [
      { type: RepoType.Mysql, label: 'MySQL' },
      {
        type: RepoType.Mariadb,
        label: 'MariaDB',
      },
      { type: RepoType.Galera, label: 'Galera' },
    ],
  },
  {
    name: 'oracle-wire',
    repos: [{ type: RepoType.Oracle, label: 'Oracle' }],
  },
  {
    name: 'pg-wire',
    repos: [
      {
        type: RepoType.Postgresql,
        label: 'Postgres',
      },
      {
        type: RepoType.Redshift,
        label: 'Redshift',
      },
      { type: RepoType.Denodo, label: 'Denodo' },
    ],
  },
  {
    name: 'snowflake-wire',
    repos: [
      {
        type: RepoType.Snowflake,
        label: 'Snowflake',
      },
    ],
  },
  {
    name: 'sqlserver-wire',
    repos: [
      {
        type: RepoType.Sqlserver,
        label: 'SQLServer',
      },
    ],
  },
]

const useLogLevel_queryFragment = graphql(`
  fragment useLogLevel_queryFragment on Query {
    sidecar(id: $sidecarId) {
      id
      listeners {
        id
        repoType
      }
    }
  }
`)

const useLogLevel = ({
  query: queryProp,
}: {
  query: FragmentType<typeof useLogLevel_queryFragment>
}) => {
  const {
    sidecar: { listeners },
  } = useFragment(useLogLevel_queryFragment, queryProp)

  const getRepoServices = () => {
    const uniqueRepoTypes = uniq(listeners.map(listener => listener.repoType))

    const availableRepoServices = ALL_REPO_SERVICES.filter(repoService =>
      repoService.repos.some(repo => uniqueRepoTypes.includes(repo.type))
    )

    return availableRepoServices.map(service => ({
      ...service,
      repos: service.repos.filter(repo => uniqueRepoTypes.includes(repo.type)),
    }))
  }

  return [...ALL_NON_REPO_SERVICES, ...getRepoServices()]
}

export default useLogLevel
