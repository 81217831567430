import { createTheme } from '@mui/material/styles'
import * as colors from './colors'
import { fontFamily, fontFamilyMonospaced, variants } from './typography'

type CyralColors = typeof colors

// Note: This theme is deep merged with default MUI Theme.
// Please see https://mui.com/customization/default-theme/ for default theme values

declare module '@mui/material/styles' {
  interface Palette {
    cyralColors: CyralColors
  }

  interface PaletteOptions {
    cyralColors: CyralColors
  }
  interface BreakpointOverrides {
    xs: true
    sm: true
    md: true
    lg: true
    xl: true
    '2xl': true
  }

  interface TypographyVariants {
    fontFamilyMonospaced: string
    code: React.CSSProperties
    tag: React.CSSProperties
    badge: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    fontFamilyMonospaced?: string
    code?: React.CSSProperties
    tag?: React.CSSProperties
    badge?: React.CSSProperties
  }

  interface Theme {
    radii: {
      none: string
      sm: string
      base: string
      xl: string
      full: string
    }
    cyralShadows: {
      '1dp': string
      '2dp': string
      '3dp': string
    }
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    radii?: {
      none?: string
      sm?: string
      base?: string
      xl?: string
      full?: string
    }
    cyralShadows?: {
      '1dp'?: string
      '2dp'?: string
      '3dp'?: string
    }
  }
}

// Updates the Typography component's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    code: true
    tag: true
    subtitle1: false
    subtitle2: false
    badge: true
  }
}

const v5Theme = createTheme({
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        noOptions: {
          padding: '6px 16px',
          typography: 'body2',
        },
      },
    },
  },
  // MUI Properties
  palette: {
    cyralColors: {
      ...colors,
    },
    primary: {
      main: colors.primary[300],
      dark: colors.primary[400],
      contrastText: colors.white,
    },
    secondary: {
      main: colors.grey[300],
      dark: colors.grey[400],
      contrastText: colors.black,
    },
    error: {
      main: colors.red[300],
      dark: colors.red[400],
      contrastText: colors.white,
    },
    text: {
      primary: colors.grey[500],
      secondary: colors.grey[400],
      disabled: colors.grey[200],
    },
    divider: colors.grey[200],
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
      '2xl': 1440,
    },
  },
  typography: {
    fontFamily,
    fontFamilyMonospaced,
    ...variants,
  },

  /* == Custom Properties == */
  radii: {
    none: '0',
    sm: '0.125rem',
    base: '0.25rem',
    xl: '0.75rem',
    full: '9999px',
  },
  cyralShadows: {
    '1dp': '0px 4px 4px rgb(0, 0, 0, 20%), 0px 0px 1px rgb(0, 0, 0, 32%)',
    '2dp': '0px 16px 16px rgb(0, 0, 0, 20%), 0px 0px 1px rgb(0, 0, 0, 32%)',
    '3dp': '0px 24px 40px 2px rgb(0 0 0 / 16%), 0px 0px 1px rgb(0, 0, 0, 32%)',
  },
})

export default v5Theme
