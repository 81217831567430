import React from 'react'

import { Button, Input, InputLabel, InlineCode } from '@jeeves/new-components'

import { Dialog } from '@material-ui/core'
import {
  FormControl,
  DialogContent,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Stack,
} from '@mui/material'

import { useForm, Controller } from 'react-hook-form'
import { useMutation, gql } from '@apollo/client'
import { FETCH_INTEGRATIONS } from './GenericSAML'

const CREATE_GENERIC_SAML_INTEGRATION_DRAFT = gql`
  mutation CreateGenericSAMLIntegrationDraft_NameDialog(
    $genericSamlIntegrationDraft: CreateGenericSAMLIntegrationDraftInput!
  ) {
    createGenericSAMLIntegrationDraft(genericSAMLIntegrationDraft: $genericSamlIntegrationDraft) {
      genericSAMLIntegrationDraft {
        id
        displayName
        spMetadataXMLDocument
        spMetadataURL
        isDraft
      }
    }
  }
`

const NameDialog = ({ isOpen, handleClose, defaultAttributes, setNewIntegrationId }) => {
  const {
    reset,
    register,
    control,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      displayName: '',
      firstName: defaultAttributes?.firstName ?? '',
      lastName: defaultAttributes?.lastName ?? '',
      email: defaultAttributes?.email ?? '',
      groups: defaultAttributes?.groups ?? '',
      enableIdPInitiatedLogin: true,
    },
  })

  const [
    createGenericSAMLIntegrationDraft,
    {
      data: createGenericSAMLIntegrationDraftData,
      loading: createGenericSAMLIntegrationDraftLoading,
      error: createGenericSAMLIntegrationDraftError,
    },
  ] = useMutation(CREATE_GENERIC_SAML_INTEGRATION_DRAFT, {
    update(cache, response) {
      // Looks into cache to grab data that exists for the query "FETCH_INTEGRATIONS"
      const data = cache.readQuery({
        query: FETCH_INTEGRATIONS,
      })

      // Update cache for "FETCH_INTEGRATIONS" query
      cache.writeQuery({
        query: FETCH_INTEGRATIONS, // This is the existing list data that we want to update
        data: {
          genericSAMLIntegrations: [
            ...data.genericSAMLIntegrations,
            response.data.createGenericSAMLIntegrationDraft.genericSAMLIntegrationDraft,
          ],
        },
      })
    },
  })

  const handleOnClose = () => {
    handleClose()
    reset()
  }

  const { displayName, firstName, lastName, email, groups } = watch()

  const onSubmit = async ({
    displayName,
    enableIdPInitiatedLogin,
    firstName,
    lastName,
    email,
    groups,
  }) => {
    const genericSamlIntegrationDraft = {
      displayName,
      enableIdPInitiatedLogin,
      firstName,
      lastName,
      email,
      groups,
    }
    try {
      const response = await createGenericSAMLIntegrationDraft({
        variables: { genericSamlIntegrationDraft },
      })
      handleOnClose()

      const id = response.data.createGenericSAMLIntegrationDraft.genericSAMLIntegrationDraft.id
      setNewIntegrationId(id)
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth disableEnforceFocus maxWidth="sm">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Stack spacing={4}>
            <FormControl fullWidth>
              <InputLabel htmlFor="display-name">Display Name</InputLabel>
              <Input id="display-name" inputProps={{ ...register('displayName') }}></Input>
            </FormControl>

            <Stack spacing={1}>
              <Typography variant="h6" sx={{ color: 'text.secondary' }}>
                Attribute Names in SAML Assertion
              </Typography>

              <Typography variant="body2" sx={{ color: 'text.primary' }}>
                Your attribute names here should match the ones in your IdP. The nameID format for
                all attributes should be <InlineCode>undefined</InlineCode>. These attributes{' '}
                <strong>cannot be changed</strong> once the integration has been created.
              </Typography>

              <Stack direction="column" spacing={1}>
                <FormControl sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <InputLabel sx={{ position: 'relative', flexBasis: '25%' }} htmlFor="first-name">
                    First Name
                  </InputLabel>
                  <Input
                    sx={{
                      '& .MuiInputBase-input': { padding: '4px 8px' },
                      'label + &': { marginTop: 0 },
                      flexBasis: '75%',
                    }}
                    id="first-name"
                    inputProps={{ ...register('firstName') }}
                  ></Input>
                </FormControl>

                <FormControl sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <InputLabel sx={{ position: 'relative', flexBasis: '25%' }} htmlFor="last-name">
                    Last Name
                  </InputLabel>
                  <Input
                    sx={{
                      '& .MuiInputBase-input': { padding: '4px 8px' },
                      'label + &': { marginTop: 0 },
                      flexBasis: '75%',
                    }}
                    id="last-name"
                    inputProps={{ ...register('lastName') }}
                  ></Input>
                </FormControl>

                <FormControl sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <InputLabel sx={{ position: 'relative', flexBasis: '25%' }} htmlFor="email">
                    Email
                  </InputLabel>
                  <Input
                    sx={{
                      '& .MuiInputBase-input': { padding: '4px 8px' },
                      'label + &': { marginTop: 0 },
                      flexBasis: '75%',
                    }}
                    id="email"
                    inputProps={{ ...register('email') }}
                  ></Input>
                </FormControl>

                <FormControl sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <InputLabel sx={{ position: 'relative', flexBasis: '25%' }} htmlFor="groups">
                    SSO Groups
                  </InputLabel>
                  <Input
                    sx={{
                      '& .MuiInputBase-input': { padding: '4px 8px' },
                      'label + &': { marginTop: 0 },
                      flexBasis: '75%',
                    }}
                    id="groups"
                    inputProps={{ ...register('groups') }}
                  ></Input>
                </FormControl>
              </Stack>
            </Stack>
            <Controller
              name="enableIdPInitiatedLogin"
              control={control}
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox name={name} onChange={onChange} checked={value} />}
                    label="Enable IdP-initiated Login"
                  />
                </FormGroup>
              )}
            />
          </Stack>
        </DialogContent>
        <Stack direction="row" sx={{ gap: '20px', margin: '20px', justifyContent: 'flex-end' }}>
          <Button variant="text" onClick={handleOnClose}>
            Cancel
          </Button>
          <Button
            loading={isSubmitting}
            variant="contained"
            type="submit"
            disabled={!displayName || !firstName || !lastName || !email || !groups}
          >
            Continue
          </Button>
        </Stack>
      </form>
    </Dialog>
  )
}

export default NameDialog
