/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import React, { useState, useEffect, Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'

import Card from '@material-ui/core/Card'
import InputAdornment from '@material-ui/core/InputAdornment'
import CardHeader from '@material-ui/core/CardHeader'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Avatar from '@material-ui/core/Avatar'
import { Typography } from '@material-ui/core'

import ExpressClient from '@jeeves/clients/express'
import axios from 'axios'
import { useAuth } from '@jeeves/components/Auth'
import usePopup from '@jeeves/components/PopupMessage/hooks/usePopup'
import { get as lodashGet } from 'lodash'
import { ButtonLoading } from '@jeeves/components/Loading'
import { CopyToClipboard } from 'react-copy-to-clipboard'

const styles = theme => ({
  picture: {
    width: 128,
    height: 'auto',
  },
  icon: {
    padding: theme.spacing.unit / 2,
  },
  actions: {
    padding: '24px',
  },
  content: {
    padding: '0 24px 24px 24px',
  },
  title: {
    fontSize: '24px',
    padding: '24px',
  },
  updateButton: {
    padding: theme.spacing.unit,
  },
  jwtTextField: {
    padding: 0,
  },
})

const Password = ({ classes, user }) => {
  const [resetLoading, setResetLoading] = useState(false)
  const [resetDone, setResetDone] = useState(false)
  const { getTokenSilently } = useAuth()
  const ec = new ExpressClient(getTokenSilently)
  const { showError } = usePopup()

  const resetPassword = async () => {
    try {
      setResetDone(false)
      setResetLoading(true)
      const token = await getTokenSilently()

      const res = await axios.request({
        method: 'patch',
        url: `/users/${user.sub}/reset-password`,
        data: {
          email: user.email,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      setResetDone(true)
      setResetLoading(false)
    } catch (error) {
      console.log(error)
      showError(error)
      setResetLoading(false)
    }
  }

  return (
    <Fragment>
      <div css={{ padding: '24px' }}>
        <Typography variant="h6">Password</Typography>
        <Typography css={{ fontSize: '1rem' }}>Click below to reset your password.</Typography>
      </div>
      <CardContent className={classes.content}>
        <Grid container spacing={32}>
          <Grid item xs={12}>
            <Grid container spacing={24} alignItems="center">
              <Grid item>
                <div css={{ position: 'relative', marginRight: '12px' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={resetPassword}
                    disabled={resetLoading}
                    css={{ boxShadow: 'none' }}
                  >
                    Reset Password
                  </Button>
                  {resetLoading && <ButtonLoading />}
                </div>
              </Grid>
              <Grid item>
                {resetDone && (
                  <Typography>
                    A password reset email has been sent to <strong>{user.email}</strong>.
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Fragment>
  )
}

export default withStyles(styles)(Password)
