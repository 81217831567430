// only allowing up to days here
export const durationObjectToIso = (durationObject = {}) =>
  `P${durationObject.days || '0'}DT${durationObject.hours || '0'}H${durationObject.minutes ||
    '0'}M${durationObject.seconds || '0'}S`

export const isoToDurationObject = (iso = '') => {
  const isoRegex = /P(?:(\d+)Y)?(?:(\d+)M)?(?:(\d+)D)?T(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?/
  const match = iso.match(isoRegex)
  return match
    ? normalizeDurationObject({
        years: parseInt(match[1]) || 0,
        months: parseInt(match[2]) || 0,
        days: parseInt(match[3]) || 0,
        hours: parseInt(match[4]) || 0,
        minutes: parseInt(match[5]) || 0,
        seconds: parseInt(match[6]) || 0,
      })
    : null
}
// highest to days
export const normalizeDurationObject = (durationObject = {}) => {
  const returnObject = {
    seconds: 0,
    minutes: 0,
    hours: 0,
    days: 0,
    months: 0,
    years: 0,
  }
  if (durationObject.seconds && durationObject.seconds > 59) {
    returnObject.seconds = durationObject.seconds % 60
    returnObject.minutes = Math.floor(durationObject.seconds / 60)
  } else {
    returnObject.seconds = durationObject.seconds
  }
  if (durationObject.minutes && durationObject.minutes > 59) {
    returnObject.minutes = (returnObject.minutes + durationObject.minutes) % 60
    returnObject.hours = Math.floor((returnObject.minutes + durationObject.minutes) / 60)
  } else {
    returnObject.minutes = durationObject.minutes
  }
  if (durationObject.hours && durationObject.hours > 23) {
    returnObject.hours = (returnObject.hours + durationObject.hours) % 24
    returnObject.days = Math.floor((returnObject.hours + durationObject.hours) / 24)
  } else {
    returnObject.hours = durationObject.hours
  }
  returnObject.days = returnObject.days + (durationObject.days || 0)
  return returnObject
}
