import { Box, Stack, Typography } from '@mui/material'
import { InlineCode } from '@jeeves/new-components'

const PolicyRule = ({ title: titleProp, content, sx = [] }) => {
  let title = titleProp

  if (title?.type !== Typography) {
    title = (
      <Typography variant="h6" sx={{ color: 'text.secondary' }}>
        {title}
      </Typography>
    )
  }

  return (
    <Stack spacing={1} sx={[...(Array.isArray(sx) ? sx : [sx])]}>
      {title}

      <Box
        sx={{
          gap: 1,
          paddingLeft: 3,
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
        }}
      >
        {Array.isArray(content) ? (
          content.map(text => <InlineCode key={text}>{text}</InlineCode>)
        ) : (
          <InlineCode>{content}</InlineCode>
        )}
      </Box>
    </Stack>
  )
}

export default PolicyRule
