import { Box, Stack, Typography } from '@mui/material'

import { FragmentType, graphql, useFragment } from '@jeeves/graphql'

import CertificateDetails from './CertificateDetails'
import CertificateNotFound from './CertificateNotFound'
import DownloadCertificate from './DownloadCertificate'

const CaCertificate_SidecarInstanceFragment = graphql(`
  fragment CaCertificate_SidecarInstanceFragment on SidecarInstance {
    id
    certificates {
      ca {
        certificates {
          expirationDate
          fingerprint
          subject
          issuer
        }
        pemEncodedString
      }
    }
  }
`)

const CA_CERTIFICATE_FILENAME = 'sidecar_ca_certificate'

interface Props {
  sidecarInstance: FragmentType<typeof CaCertificate_SidecarInstanceFragment>
}

const CaCertificate = ({ sidecarInstance: sidecarInstanceProp }: Props) => {
  const sidecarInstance = useFragment(CaCertificate_SidecarInstanceFragment, sidecarInstanceProp)

  if (!sidecarInstance.certificates.ca) {
    return <CertificateNotFound certificateType="CA" />
  }

  const caCertificates = sidecarInstance.certificates.ca.certificates
  const caCertificate = caCertificates[caCertificates.length - 1]

  return (
    <Stack spacing={2}>
      <Typography variant="h6" sx={{ color: 'text.primary' }}>
        CA Certificate
      </Typography>
      <CertificateDetails certificate={caCertificate} />
      <Box>
        <DownloadCertificate
          filename={CA_CERTIFICATE_FILENAME}
          certificate={sidecarInstance.certificates.ca.pemEncodedString}
        />
      </Box>
    </Stack>
  )
}

export default CaCertificate
