import { useState } from 'react'
import {
  Box,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  IconButton,
  InputAdornment,
  FormHelperText,
} from '@mui/material'
import {
  Close as CloseIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material'

import {
  Dialog,
  Button,
  DialogActions,
  Input,
  InputLabel,
  IconCopyButton,
} from '@jeeves/new-components'

import useNewCredentialsModal from '../../hooks/useNewCredentialsModal'

const NewCredentialsModal = () => {
  const [passwordVisibility, setPasswordVisibility] = useState(false)
  const { credentials, closeModal, handleNewCredential, loading } = useNewCredentialsModal()

  const toggleVisibility = () => {
    setPasswordVisibility(!passwordVisibility)
  }

  const open = !!credentials.clientId

  return (
    <Box>
      <Button variant="outlined" color="secondary" loading={loading} onClick={handleNewCredential}>
        New Credentials
      </Button>
      <Dialog open={open} onClose={closeModal} fullWidth>
        <DialogTitle
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h3" sx={{ color: 'text.primary' }}>
            New Credentials
          </Typography>
          <IconButton aria-label="close" onClick={closeModal}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <InputLabel>Client ID</InputLabel>
              <Input
                id="clientId"
                sx={{
                  fontFamily: theme => theme.typography.code,
                }}
                value={credentials.clientId}
                type="text"
                endAdornment={
                  <InputAdornment position="end">
                    <IconCopyButton text={credentials.clientId} />
                  </InputAdornment>
                }
              />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <InputLabel>Client Secret</InputLabel>
              <Input
                id="clientSecret"
                sx={{
                  fontFamily: theme => theme.typography.code,
                }}
                value={credentials.clientSecret}
                type={passwordVisibility ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={toggleVisibility}>
                      {passwordVisibility ? (
                        <VisibilityOffIcon color="secondary" fontSize="small" />
                      ) : (
                        <VisibilityIcon color="secondary" fontSize="small" />
                      )}
                    </IconButton>
                    <IconCopyButton text={credentials.clientSecret} />
                  </InputAdornment>
                }
              />
              <FormHelperText
                sx={{
                  typography: 'body2',
                }}
              >
                Make sure to copy the client secret.{' '}
                <Typography component="b" variant="h6">
                  It will not be shown again.
                </Typography>
              </FormHelperText>
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Stack direction="row" sx={{ justifyContent: 'flex-end' }}>
            <Button variant="contained" color="primary" onClick={closeModal}>
              Done
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default NewCredentialsModal
