import { Stack, Typography } from '@mui/material'
import { useLastUpdatedBy } from './useLastUpdatedBy'

const LastUpdatedBy = () => {
  const { displayedString, actorName } = useLastUpdatedBy()

  return (
    <Stack spacing={1}>
      <Typography variant="h6" sx={{ color: 'text.primary' }}>
        {displayedString}
      </Typography>
      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        {actorName}
      </Typography>
    </Stack>
  )
}

export default LastUpdatedBy
