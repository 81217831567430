import { useState } from 'react'
import { useQuery } from '@apollo/client'

import { graphql } from '@jeeves/graphql'

import { Views } from './types'

const ACCESS_TOKENS = graphql(`
  query AccessPortalAccessTokens {
    ...ManageAccessToken_QueryFragment
    ...GenerateAccessToken_QueryFragment
    ...DeleteAccessToken_QueryFragment
    ...EditAccessToken_QueryFragment
  }
`)

const useAccessTokenStructure = () => {
  const [view, setView] = useState<Views>('manage')
  const [selectedAccessTokenId, setSelectedAccessTokenId] = useState('')

  const { data, loading, error, refetch } = useQuery(ACCESS_TOKENS)

  const goBackToManage = () => {
    setView('manage')
  }

  const handleDone = () => {
    refetch()
    goBackToManage()
  }

  return {
    data,
    loading,
    error,
    refetch,
    view,
    setView,
    selectedAccessTokenId,
    setSelectedAccessTokenId,
    goBackToManage,
    handleDone,
  }
}

export default useAccessTokenStructure
