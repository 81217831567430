import { gql } from '@apollo/client'
import { jsPDF } from 'jspdf'
import autoTable from 'jspdf-autotable'
import { format, parseISO } from 'date-fns'

import logo from '../images/cyral-transparent.png'

export const ExportTable_repoFragment = gql`
  fragment ExportTable_repo on Repo {
    ... on OracleRepo {
      endpoint {
        host
      }
    }
    ... on SQLServerRepo {
      endpoint {
        host
      }
    }
  }
`

export const exportAsPDF = async (
  repo,
  lastScanTimestamp,
  numAccounts,
  discoveredAccountsPDFTable,
  thereIsAnOracleMaintainedAccount
) => {
  const doc = new jsPDF('l', 'pt', 'a4')
  const pdfWidth = doc.internal.pageSize.getWidth()
  const discoveredAccountsRender = discoveredAccountsPDFTable.map(
    discoveredAccount => discoveredAccount.content
  )
  let finalY = 30
  doc.setFontSize(20)
  doc.text('Cyral Audit Report for Discovered Database Accounts', pdfWidth / 2, finalY, {
    align: 'center',
  })
  doc.setFontSize(15)
  doc.text('Data Repo Details', pdfWidth / 2, (finalY += 25), { align: 'center' })
  doc.autoTable({
    startY: (finalY += 10),
    theme: 'grid',
    body: [
      ['Data Repo Type', repo.type],
      ['Data Repo Name', repo.name],
      ['Database Endpoint', repo.endpoint.host],
    ],
    columnStyles: { 0: { fillColor: '#e3f7fe' } },
    bodyStyles: { textColor: 'black' },
  })
  finalY = doc.lastAutoTable.finalY + 10

  if (lastScanTimestamp != 0) {
    doc.text(
      `Scan Date: ${format(parseISO(lastScanTimestamp), 'MMMM dd, yyyy HH:mm (z)')}`,
      pdfWidth / 2,
      (finalY += 10),
      { align: 'center' }
    )
  }
  doc.text(
    `Discovered ${numAccounts} account${numAccounts == 1 ? '' : 's'}`,
    pdfWidth / 2,
    (finalY += 20),
    { align: 'center' }
  )
  doc.setFontSize(14)
  doc.text('Scan Results', pdfWidth / 2, (finalY += 25), { align: 'center' })

  var totalPagesExp = '{total_pages_count_string}'
  if (repo.type === 'sqlserver') {
    const headerSize = 2
    const headerObj = [
      [
        { content: 'Database Account', rowSpan: 2 },
        { content: 'Server Level Roles', colSpan: 2 },
        { content: 'Database Level Roles', colSpan: 4 },
        { content: 'Account Status', rowSpan: 2 },
        { content: 'Controls Applied', rowSpan: 2 },
        { content: 'Users (in last 7 days)', rowSpan: 2 },
        { content: 'Last Used', rowSpan: 2 },
      ],
      [
        { content: 'Predefined Roles', colSpan: 1 },
        { content: 'Custom Roles', colSpan: 1 },
        { content: 'Database', colSpan: 1 },
        { content: 'Database User', colSpan: 1 },
        { content: 'Predefined Roles', colSpan: 1 },
        { content: 'Custom Roles', colSpan: 1 },
      ],
    ]
    headerObj.forEach(row => row.forEach(cellObj => (cellObj.styles = { fillColor: '#e3f7fe' })))
    doc.autoTable({
      startY: (finalY += 10),
      theme: 'grid',
      styles: { minCellWidth: 70 },
      body: [...headerObj, ...discoveredAccountsRender],
      bodyStyles: { halign: 'center' },
      didParseCell: data => {
        if (
          data.section === 'body' &&
          data.row.index >= headerSize &&
          !discoveredAccountsPDFTable[data.row.index - headerSize].metadata.managedByCyral
        ) {
          // Not managed by Cyral
          data.cell.styles.textColor = '#ff0000'
        } else {
          // Managed by Cyral
          data.cell.styles.textColor = '#000000'
        }
      },
      didDrawPage: function (data) {
        var pageSize = doc.internal.pageSize
        var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
        /*
          var msgPages = "Page " + doc.internal.getNumberOfPages()
          // Total page number plugin only available in jspdf v1.0+
          if (typeof doc.putTotalPages === 'function') {
            msgPages = msgPages + " of " + totalPagesExp;
          }
          doc.setFontSize(10);
          doc.text(msgPages, data.settings.margin.right, pageHeight - 10);
          */
        if (logo) {
          doc.addImage(logo, 'JPEG', data.settings.margin.left - 35, pageHeight - 45, 60, 60)
        }
      },
    })
  } else {
    const headerSize = 1
    const headerObj = [
      [
        { content: 'Database Account' },
        { content: 'Predefined Roles' },
        { content: 'Custom Roles' },
        { content: 'Account Status' },
        { content: 'Controls Applied' },
        { content: 'Users (in last 7 days)' },
        { content: 'Last Used' },
      ],
    ]
    headerObj.forEach(row => row.forEach(cellObj => (cellObj.styles = { fillColor: '#e3f7fe' })))
    doc.autoTable({
      startY: (finalY += 10),
      theme: 'grid',
      styles: { minCellWidth: 70 },
      body: [...headerObj, ...discoveredAccountsRender],
      bodyStyles: { halign: 'center' },
      didParseCell: data => {
        if (
          data.section === 'body' &&
          data.row.index >= headerSize &&
          !discoveredAccountsPDFTable[data.row.index - headerSize].metadata.managedByCyral
        ) {
          // No need to remove headerSize
          // Not managed by Cyral
          data.cell.styles.textColor = '#ff0000'
        } else {
          // Managed by Cyral
          data.cell.styles.textColor = '#000000'
        }
      },
      didDrawPage: function (data) {
        var pageSize = doc.internal.pageSize
        var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
        /*
          var msgPages = "Page " + doc.internal.getNumberOfPages()
          // Total page number plugin only available in jspdf v1.0+
          if (typeof doc.putTotalPages === 'function') {
            msgPages = msgPages + " of " + totalPagesExp;
          }
          doc.setFontSize(10);
          doc.text(msgPages, data.settings.margin.right, pageHeight - 10);
          */
        if (logo) {
          doc.addImage(logo, 'JPEG', data.settings.margin.left - 35, pageHeight - 45, 60, 60)
        }
      },
    })
  }
  // Total page number plugin only available in jspdf v1.0+
  if (typeof doc.putTotalPages === 'function') {
    doc.putTotalPages(totalPagesExp)
  }
  finalY = doc.lastAutoTable.finalY

  // at least one account is oracle mantained
  if (repo.type === 'oracle' && thereIsAnOracleMaintainedAccount) {
    doc.setFontSize(12)
    doc.text(`** Oracle maintained account`, 40, (finalY += 20), { align: 'left' })
  }

  doc.setFontSize(14)
  doc.text(
    `Report generated on ${format(new Date(), 'MMMM dd, yyyy HH:mm (z)')}`,
    pdfWidth / 2,
    (finalY += 25),
    { align: 'center' }
  )
  doc.save(repo.name + '_report.pdf')
}
