import React from 'react'
import MuiLoadingButton from '@mui/lab/LoadingButton'

import { styled } from '@mui/material/styles'

const DEFAULT_COLOR = 'primary'

const LoadingButton = React.forwardRef(
  ({ variant = 'contained', color = DEFAULT_COLOR, ...rest }, ref) => {
    return (
      <MuiLoadingButton
        ref={ref}
        variant={variant}
        color={color}
        {...rest}
        loadingPosition="center"
        disableElevation
        disableRipple
      />
    )
  }
) as typeof MuiLoadingButton

// Note: This button component is based on <LoadingButton /> from @mui/lab, meaning that it doesn't yet have a finalized API.
// Be careful when updating @mui/lab library (even minor/patch versions) because it could contain breaking API changes.
const StyledLoadingButton = styled(
  LoadingButton,
  {}
)(({ theme, color = DEFAULT_COLOR }) => ({
  // Contained
  '&.MuiButton-contained': {
    '&.MuiButton-sizeSmall': {
      padding: theme.spacing(0.5, 2),
      // [`&:hover, &.Mui-focusVisible`]: {
      //   boxShadow: `0px 0px 0px 8px ${alpha(theme.palette.success.main, 0.16)}`,
      // },
      // [`&.Mui-active`]: {
      //   boxShadow: `0px 0px 0px 14px ${alpha(theme.palette.success.main, 0.16)}`,
      // },
    },
    '&.MuiButton-sizeMedium': {
      padding: theme.spacing(1, 2),
    },
  },

  // Outlined
  '&.MuiButton-outlined': {
    '&.MuiButton-sizeSmall': {
      padding: '3px 15px',
    },
    '&.MuiButton-sizeMedium': {
      padding: '7px 15px',
    },

    '&.MuiButton-outlinedPrimary': {
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
    },

    '&.MuiButton-outlinedSecondary': {
      color: theme.palette.text.primary,
      border: '1px solid',
      borderColor: theme.palette.cyralColors.grey[200],
      backgroundColor: theme.palette.cyralColors.white,

      '&:hover': {
        color: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },

      '&.Mui-disabled': {
        color: theme.palette.text.disabled,
      },
    },
  },
})) as typeof MuiLoadingButton

export default StyledLoadingButton
