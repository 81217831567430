import React from 'react'
import { styled } from '@mui/material/styles'

import MUITableRow from '@mui/material/TableRow'
import MUITableCell from '@mui/material/TableCell'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Stack from '@mui/material/Stack'

export const MappingsTableCell = styled(props => <MUITableCell {...props} />)(({ theme }) => ({
  paddingLeft: theme.spacing(4),

  ...theme.typography.code,
}))

export const MappingsTableRow = styled(props => <MUITableRow {...props} />)(({ theme }) => ({
  borderLeft: `1px solid ${theme.palette.cyralColors.grey[200]}`,
  borderRight: `1px solid ${theme.palette.cyralColors.grey[200]}`,
}))

export const MappingsHeaderCell = styled(props => <MUITableCell {...props} />)(({ theme }) => ({
  ...theme.typography.h5,
  paddingLeft: theme.spacing(4),
  textTransform: 'uppercase',
  color: theme.palette.cyralColors.grey[400],
}))

const StyledDialogTitle = styled(props => <DialogTitle {...props} />)(({ theme }) => ({
  ...theme.typography.h3,
}))

export const StyledSuggestionsRepo = styled(props => <Stack {...props} />)(({ theme }) => ({
  ...theme.typography.h4,
  paddingLeft: theme.spacing(4),
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  paddingRight: theme.spacing(4),
  backgroundColor: 'white',
  border: `1px solid ${theme.palette.cyralColors.grey[200]}`,
  marginBottom: theme.spacing(1),
  color: theme.palette.text.primary,
  cursor: 'pointer',
}))
