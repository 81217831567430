import { useMutation } from '@apollo/client'

import { FragmentType, graphql, useFragment } from '@jeeves/graphql'
import { getGraphQLErrorMessage } from '@jeeves/utils/helpers'
import { useToast } from '@jeeves/new-components'

import NewAccessTokenInfo from './NewAccessTokenInfo'
import NewAccessTokenForm from './NewAccessTokenForm'

const GenerateAccessToken_QueryFragment = graphql(`
  fragment GenerateAccessToken_QueryFragment on Query {
    ...NewAccessTokenForm_QueryFragment
  }
`)

interface Props {
  handleBack: () => void
  query: FragmentType<typeof GenerateAccessToken_QueryFragment>
}

const CREATE_ACCESS_TOKEN = graphql(`
  mutation AccessPortalCreateAccessToken($input: CreateAccessTokenInput!) {
    createAccessToken(input: $input) {
      accessToken {
        name
        token
        validUntil
        id
        createdAt
      }
    }
  }
`)

const GenerateAccessToken = ({ handleBack, query: queryProp }: Props) => {
  const query = useFragment(GenerateAccessToken_QueryFragment, queryProp)
  const { toast } = useToast()

  const [createAccessToken, { data, loading }] = useMutation(CREATE_ACCESS_TOKEN, {
    onError: error => {
      toast({
        variant: 'error',
        description:
          getGraphQLErrorMessage(error) ||
          'An error occurred while generating a new access token. Please try again later.',
      })
    },
  })

  if (data) {
    return (
      <NewAccessTokenInfo
        handleDone={handleBack}
        name={data.createAccessToken!.accessToken?.name || ''}
        expiration={data.createAccessToken!.accessToken?.validUntil || ''}
        token={data.createAccessToken!.accessToken?.token || ''}
      />
    )
  }

  return (
    <NewAccessTokenForm
      onCancel={handleBack}
      createAccessToken={createAccessToken}
      loading={loading}
      query={query}
    />
  )
}

export default GenerateAccessToken
