/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { NavLink } from 'react-router-dom'
import DashboardIcon from '@material-ui/icons/Dashboard'
import MenuList from '@material-ui/core/MenuList'
import { useAppConfig } from '@jeeves/hooks'
import { MenuItem } from './components/MenuItem'

const SandboxSection = ({ iconOnly }) => {
  const { license } = useAppConfig()

  return (
    <MenuList>
      <MenuItem
        component={NavLink}
        to="/sandbox"
        iconOnly={iconOnly}
        // disabled={!hasPermission('policies:read')}
        primaryText="Start Here"
        listItemIcon={<DashboardIcon fontSize="small" />}
        trialBadge={Boolean(license?.isFreeTrial)}
      />
    </MenuList>
  )
}

export default SandboxSection
