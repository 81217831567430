import React, { useState } from 'react'
import ExpressClient from '@jeeves/clients/express'
import { useAuth, usePopup } from '@jeeves/hooks'
import { get as lodashGet } from 'lodash'

const usePingOne = ({ refresh }) => {
  const { setPopup, popupTypes } = usePopup()
  const { getTokenSilently, user } = useAuth()
  const ec = new ExpressClient(getTokenSilently)

  const [integrations, setIntegrations] = useState([])
  const [draftIntegrations, setDraftIntegrations] = useState([])

  const handleError = e => {
    const popupMessage = lodashGet(e, 'response.data.error.message', '')
    setPopup(popupTypes.ERROR, popupMessage)
    throw e
  }

  const addDraftIntegration = async draftConfig => {
    try {
      const integration = await ec
        .post('/integrations/saml-drafts', draftConfig)
        .then(res => res.data)
      refresh()
      return integration
    } catch (e) {
      handleError(e)
    }
  }

  const addIntegration = async samlConfig => {
    try {
      const integration = await ec
        .post('/integrations/saml/fromMetadata', samlConfig)
        .then(res => res.data)
      refresh()
      return integration
    } catch (e) {
      handleError(e)
    }
  }

  const putIntegration = async samlConfig => {
    try {
      const integration = await ec.put('/integrations/saml', samlConfig).then(res => res.data)
      refresh()
      return integration
    } catch (e) {
      handleError(e)
    }
  }

  const deleteIntegration = async alias => {
    try {
      const integration = await ec.delete(`/integrations/saml/${alias}`).then(res => res.data)
      refresh()
      return integration
    } catch (e) {
      handleError(e)
    }
  }

  const deleteDraftIntegration = async alias => {
    try {
      const integration = await ec
        .delete(`/integrations/saml-drafts/pingone/${alias}`)
        .then(res => res.data)
      refresh()
      return integration
    } catch (e) {
      handleError(e)
    }
  }

  const updateIntegrations = async () => {
    try {
      const result = await ec
        .get('/integrations/saml?identityProvider=pingone')
        .then(res => res.data)
      setIntegrations(result)
    } catch (e) {
      handleError(e)
    }
  }

  const updateDraftIntegrations = async () => {
    try {
      const drafts = await ec.get('/integrations/saml-drafts/pingone').then(res => res.data)
      setDraftIntegrations(drafts)
    } catch (e) {
      handleError(e)
    }
  }

  return {
    updateIntegrations,
    integrations,
    draftIntegrations,
    addIntegration,
    addDraftIntegration,
    putIntegration,
    deleteIntegration,
    deleteDraftIntegration,
    updateDraftIntegrations,
  }
}

export default usePingOne
