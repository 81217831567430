import { Box, Typography } from '@mui/material'

const ErrorState = () => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        An error occurred while fetching access tokens.
      </Typography>
    </Box>
  )
}

export default ErrorState
