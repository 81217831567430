import { useMutation } from '@apollo/client'
import { graphql } from '@jeeves/graphql'

import { useToast } from '@jeeves/new-components'
import { getGraphQLErrorMessage } from '@jeeves/utils/helpers'

const DELETE_SIDECAR_CREDENTIAL = graphql(`
  mutation DeleteSidecarCredential($clientId: ID!) {
    deleteSidecarCredential(clientId: $clientId) {
      deletedClientId
    }
  }
`)

interface Props {
  selectedCredentialToDelete: string
  setSelectedCredentialToDelete: React.Dispatch<React.SetStateAction<string>>
}

const useDeleteCredentialsModal = ({
  selectedCredentialToDelete,
  setSelectedCredentialToDelete,
}: Props) => {
  const { toast } = useToast()

  const closeModal = () => {
    setSelectedCredentialToDelete('')
  }

  const [deleteSidecarCredential, { loading }] = useMutation(DELETE_SIDECAR_CREDENTIAL, {
    variables: {
      clientId: selectedCredentialToDelete,
    },
    onCompleted: () => {
      closeModal()
    },
    onError: error => {
      toast({
        variant: 'error',
        description:
          getGraphQLErrorMessage(error) ||
          'An error occurred when deleting the credential. Please try again later.',
      })
    },
    update: (cache, { data }) => {
      const cacheId = cache.identify({
        clientId: data?.deleteSidecarCredential?.deletedClientId,
        __typename: 'SidecarCredential',
      })
      cache.evict({ id: cacheId })
      cache.gc()
    },
  })

  const handleDeleteConfirm = async () => {
    if (selectedCredentialToDelete) {
      await deleteSidecarCredential()
    }
  }

  return { loading, handleDeleteConfirm, closeModal }
}

export default useDeleteCredentialsModal
