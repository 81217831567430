/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Typography } from '@material-ui/core'
import { EllipsisTypography } from '@jeeves/components/Primitives'
import { SubRow } from '.'
import humanizeDuration from 'humanize-duration'
import { parse, end, toSeconds, pattern } from 'iso8601-duration'
const HD_OPTIONS = {
  round: true,
  largest: 1,
}

export const ExpirationList = ({ identityMaps = [] }) => (
  <ul
    css={() => css`
      list-style: none;
      margin: 0;
      padding: 0;
    `}
  >
    {identityMaps.map(identityMap => (
      <SubRow key={`${identityMap.identityName}expiration`}>
        <EllipsisTypography
          width="120px"
          css={t => ({
            color:
              identityMap.accessDuration !== 'P0D' &&
              end(parse(identityMap.accessDuration), new Date(identityMap.modified)) < Date.now()
                ? t.palette.error.main
                : 'black',
          })}
        >
          {identityMap.accessDuration === 'P0D'
            ? '\u2013'
            : end(parse(identityMap.accessDuration), new Date(identityMap.modified)) < Date.now()
            ? `Expired ${humanizeDuration(
                Date.now() - end(parse(identityMap.accessDuration), new Date(identityMap.modified)),
                HD_OPTIONS
              )} ago`
            : `${humanizeDuration(
                end(parse(identityMap.accessDuration), new Date(identityMap.modified)) - Date.now(),
                HD_OPTIONS
              )} remaining`}
        </EllipsisTypography>
      </SubRow>
    ))}
  </ul>
)
