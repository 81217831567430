import React from 'react'
import Stack from '@mui/material/Stack'
import Accordion from '@mui/material/Accordion'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import { RepoHeaderContent } from '../components'
import StyledDataRepoCard from './ExpandableDataRepoCard.style'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { S3AccordionContent } from './components'

const getAccordionDetailsContent = type => {
  switch (type) {
    case 's3':
      return S3AccordionContent
    default:
      return null
  }
}

const ExpandableDataRepoCard = ({ repoEdge }) => {
  const { node: repo } = repoEdge

  const AccordionDetailsContent = getAccordionDetailsContent(repo.type)

  return (
    <Accordion
      elevation={0}
      disableGutters
      square
      sx={{
        // Same styles as StyledDataRepoCard from GeneralDataRepoCard
        // Todo: Abstract out styles and share them between both components
        border: 1,
        borderColor: 'cyralColors.grey.200',
        overflow: 'hidden',
        backgroundColor: 'common.white',
        flexShrink: 0,
        borderRadius: theme => theme.radii.base,

        '&:before': {
          display: 'none',
        },
      }}
    >
      <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
        <RepoHeaderContent
          repoType={repo.type}
          repoName={repo.name}
          tags={repo.tags}
          sx={{
            flex: 1,
            overflow: 'hidden',
          }}
        />
      </AccordionSummary>
      <AccordionDetails>
        <AccordionDetailsContent repoEdge={repoEdge} />
      </AccordionDetails>
    </Accordion>
  )
}

const AccordionSummary = styled(props => (
  <MuiAccordionSummary
    {...props}
    expandIcon={<ExpandMoreIcon sx={{ color: 'cyralColors.grey.300' }} />}
  />
))(({ theme }) => ({
  backgroundColor: theme.palette.cyralColors.grey[100],
  padding: theme.spacing(2, 3),
  gap: theme.spacing(2),

  '& .MuiAccordionSummary-content': {
    margin: 0,
    overflow: 'hidden',
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(3),
  //   padding: theme.spacing(0, 3, 3),
  borderTop: `1px solid ${theme.palette.cyralColors.grey[200]}`,
}))

export default ExpandableDataRepoCard
