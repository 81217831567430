import { FormControl, FormHelperText, Stack } from '@mui/material'

import { Input, InputLabel } from '@jeeves/new-components'

import { LoggingIntegrationIcon } from '@jeeves/pages/Integrations/Services/Logging/components'

import Header from '@jeeves/pages/Integrations/Services/Logging/components/NewIntegrationDialog/components/Header'
import Footer from '@jeeves/pages/Integrations/Services/Logging/components/NewIntegrationDialog/components/Footer'

import { useCloudwatch } from './useCloudwatch'

const Cloudwatch = ({ integration, resetIntegration, onClose }) => {
  const isEditing = Boolean(integration?.id)

  const { loading, methods, onSubmit } = useCloudwatch({ integration, onClose, isEditing })

  const {
    formState: { errors },
    register,
  } = methods

  return (
    <Stack spacing={4} component="form" onSubmit={onSubmit}>
      <Header
        icon={<LoggingIntegrationIcon integrationType="CloudWatchIntegration" />}
        title="CloudWatch"
        onClose={onClose}
      />

      <FormControl variant="standard" error={Boolean(errors?.name)}>
        <InputLabel htmlFor="name" required>
          Name
        </InputLabel>
        <Input
          id="name"
          inputProps={{
            ...register('name'),
          }}
        />
        <FormHelperText
          sx={{
            typography: 'body2',
          }}
          error={Boolean(errors?.name)}
        >
          {errors?.name?.message}
        </FormHelperText>
      </FormControl>

      <FormControl variant="standard" error={Boolean(errors?.region)}>
        <InputLabel htmlFor="region" required>
          Region
        </InputLabel>
        <Input
          id="region"
          inputProps={{
            ...register('region'),
          }}
        />
        <FormHelperText
          sx={{
            typography: 'body2',
          }}
          error={Boolean(errors?.region)}
        >
          {errors?.region?.message}
        </FormHelperText>
      </FormControl>

      <FormControl variant="standard" error={Boolean(errors?.group)}>
        <InputLabel htmlFor="group" required>
          Group Name
        </InputLabel>
        <Input
          id="group"
          inputProps={{
            ...register('group'),
          }}
        />
        <FormHelperText
          sx={{
            typography: 'body2',
          }}
          error={Boolean(errors?.group)}
        >
          {errors?.group?.message}
        </FormHelperText>
      </FormControl>

      <FormControl variant="standard" error={Boolean(errors?.stream)}>
        <InputLabel htmlFor="stream">Stream Name</InputLabel>
        <Input
          id="stream"
          inputProps={{
            ...register('stream'),
          }}
        />
        <FormHelperText
          sx={{
            typography: 'body2',
          }}
          error={Boolean(errors?.stream)}
        >
          {errors?.stream?.message}
        </FormHelperText>
      </FormControl>

      <Footer
        loading={loading}
        isEditing={isEditing}
        resetIntegration={resetIntegration}
        onClose={onClose}
      />
    </Stack>
  )
}

export default Cloudwatch
