import useAuditExport from '../hooks/useAuditExport'
import { Button } from '@jeeves/new-components'
import { FilterData, parseFilter } from './AuditFilter/utils'

interface ExportLogsProps {
  filter: FilterData
  hasLogs: boolean
}

const ExportLogs = ({ filter, hasLogs }: ExportLogsProps) => {
  const { getData, loading } = useAuditExport({
    variables: {
      filter: parseFilter(filter),
    },
  })

  return (
    <Button onClick={getData} loading={loading} disabled={!hasLogs}>
      Export as CSV
    </Button>
  )
}

export default ExportLogs
