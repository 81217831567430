import generatedPossibleTypes from './fragment-matches'

const possibleTypes = generatedPossibleTypes.possibleTypes

export const isMultiplexedListener = listener => {
  const multiplexedListenerTypes = possibleTypes.MultiplexedListener

  return multiplexedListenerTypes.includes(listener.__typename)
}

export const isMongoDbRepo = repo => {
  const mongoDBRepoTypes = possibleTypes.MongoDBRepo

  return mongoDBRepoTypes.includes(repo.__typename)
}

export const isMongoDbClusterRepo = repo => {
  const mongoDBClusterTypes = possibleTypes.MongoDBCluster

  return mongoDBClusterTypes.includes(repo.__typename)
}

export const isStandaloneRepo = repo => {
  const standaloneRepoTypes = possibleTypes.StandaloneRepo

  return standaloneRepoTypes.includes(repo.__typename)
}

export const isClusterRepo = repoTypename => {
  const clusterRepoTypes = possibleTypes.ClusterRepo

  return clusterRepoTypes.includes(repoTypename)
}

export const isAuditLogForwardingSIEMIntegration = integrationType => {
  const auditLogForwardingSIEMIntegrationTypes = possibleTypes.SIEMIntegrationWithAuditLogForwarding

  return auditLogForwardingSIEMIntegrationTypes.includes(integrationType)
}
