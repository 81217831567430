import React from 'react'
import Box from '@mui/material/Box'

import Collapse from '@mui/material/Collapse'

import { DataLabelHeader } from './DataLabelHeader'
import { MappingsTable } from './MappingsTable/MappingsTable'

export const MappingCard = ({ mapping, repoType, datalabels, repoId }) => {
  const [expanded, setExpanded] = React.useState(false)
  return (
    <Box
      sx={{
        mb: 3,
      }}
    >
      <DataLabelHeader mapping={mapping} labelExpanded={expanded} setLabelExpanded={setExpanded} />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <MappingsTable
          repoId={repoId}
          datalabels={datalabels}
          mapping={mapping}
          repoType={repoType}
        ></MappingsTable>
      </Collapse>
    </Box>
  )
}
