import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const DenodoIcon = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 100 100">
      <path
        d="m19.16 56.69 9.95 17.22-17.23 9.95-9.96-17.24 17.24-9.93zm41.4 23.12H40.37V100h20.19V79.81zm10.32-5.9 9.96-17.22 17.24 9.93-9.96 17.23-17.24-9.94zm17.23-57.05-17.23 9.952 9.956 17.24L98.068 34.1 88.11 16.86z"
        fill="#F0524A"
      />
      <path d="M60.56 40.38H40.37v20.19h20.19V40.38z" fill="#241F21" />
      <path
        d="M60.56 0H40.37v20.19h20.19V0zM11.882 16.857 1.929 34.101l17.235 9.948 9.953-17.244-17.235-9.948z"
        fill="#F0524A"
      />
    </SvgIcon>
  )
}

export default DenodoIcon
