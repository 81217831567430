import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

//TODO BEFORE MERGING: SVG needs to be fixed. Currently not working.
const GaleraIcon = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 256 256">
      <path
        d="M127.715 240C-19.4324 237.533 -23.0333 23.2598 127.715 16L127.715 34.8588C8.05703 34.8594 -2.601 216.376 127.715 221.92V240Z"
        fill="#F66800"
      />
      <ellipse cx="167.457" cy="32.9104" rx="8.78865" ry="8.74634" fill="#F66800" />
      <ellipse cx="200.951" cy="55.2185" rx="8.78865" ry="8.74634" fill="#F66800" />
      <ellipse cx="223.322" cy="88.8523" rx="8.78865" ry="8.74634" fill="#F66800" />
      <ellipse cx="231.211" cy="128.14" rx="8.78865" ry="8.74634" fill="#F66800" />
      <ellipse cx="223.416" cy="167.523" rx="8.78865" ry="8.74634" fill="#F66800" />
      <ellipse cx="200.84" cy="200.801" rx="8.78865" ry="8.74634" fill="#F66800" />
      <ellipse cx="167.38" cy="223.353" rx="8.78865" ry="8.74634" fill="#F66800" />
      <path
        d="M75.4755 143.78C93.1794 203.538 169.105 195.728 176.04 143.78H126.915L142.857 127.914H195.319C195.856 144.916 195.036 153.795 187.904 166.302C174.373 188.043 159.668 199.64 136.069 201.902C110.892 204.317 90.7111 195.408 75.4755 179.38C52.7446 155.467 48.2806 117.261 70.9127 83.2951C95.552 50.6007 147.712 40.4798 186.959 82.2502L175.168 94.0417C154.015 73.9787 141.984 65.9794 113.438 72.9427C80.4034 84.0222 68.7226 113.447 75.4755 143.78Z"
        fill="#F66800"
      />
    </SvgIcon>
  )
}

export default GaleraIcon
