import { Typography } from '@material-ui/core'
import { blueGrey } from '@material-ui/core/colors'
import styled from '@emotion/styled'

export const StyledTypography = styled(Typography)`
  padding: 0 30px;
  color: ${blueGrey[400]};
`

export const SectionTitle = styled(Typography)`
  font-weight: 600;
  padding: 0 30px;
  font-size: 24px;
  margin-top: 24px;
`

SectionTitle.defaultProps = {
  variant: 'h6',
}
