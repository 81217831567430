import { Divider, Stack, Typography } from '@mui/material'

import { PolicySummary } from '@jeeves/pages/RepositoryDetail/Tabs/Policies/components/PolicySummary'
import { useRepositoryDetailContext } from '@jeeves/pages/RepositoryDetail/contexts/RepositoryDetailContext'
import Footer from '@jeeves/pages/RepositoryDetail/Tabs/Policies/views/AddPolicy/components/Footer'
import Tag from '@jeeves/pages/RepositoryDetail/Tabs/Policies/components/Tag'
import { Button } from '@jeeves/new-components'

import { getLineClampStyles } from '@jeeves/new-components'

const StepReview = ({ policyInfo, prevStep, handlePolicySubmit, isSubmitting }) => {
  const { repoId } = useRepositoryDetailContext()

  const handleSubmit = () => {
    handlePolicySubmit()
  }

  const renderTags = tags => {
    return (
      <>
        {tags.map((tag, index) => {
          return <Tag key={index} text={tag} />
        })}
      </>
    )
  }

  return (
    <Stack spacing={4}>
      <Stack spacing={1}>
        <Typography variant="h4" sx={{ color: 'text.primary' }}>
          {policyInfo.name}
        </Typography>
        <Typography variant="body2" sx={{ ...getLineClampStyles(2), color: 'text.secondary' }}>
          {policyInfo.description}
        </Typography>
        <Stack direction="row" spacing={1}>
          {renderTags(policyInfo.tags)}
        </Stack>
      </Stack>
      <PolicySummary policy={policyInfo} />

      <Divider />
      <Footer repoId={repoId} prevStep={prevStep} policyId={policyInfo.id}>
        <Button onClick={handleSubmit} variant="contained" color="primary" loading={isSubmitting}>
          {Boolean(policyInfo.id) ? 'Save' : 'Add'}
        </Button>
      </Footer>
    </Stack>
  )
}

export default StepReview
