import { Controller, useFormContext } from 'react-hook-form'
import sortBy from 'lodash/sortBy'

import { graphql, useFragment } from '@jeeves/graphql'
import { Select } from '@jeeves/new-components'

const LogsSelect_queryFragment = graphql(`
  fragment LogsSelect_query on Query {
    SIEMIntegrations {
      id
      name
    }
  }
`)

const getOptions = SIEMIntegrations => {
  const options = SIEMIntegrations.map(integration => ({
    label: integration.name,
    value: integration.id,
  }))

  return [{ label: 'None', value: 'none' }, ...sortBy(options, ['label'])]
}

const LogsSelect = ({ name, id, query: queryProp }) => {
  const query = useFragment(LogsSelect_queryFragment, queryProp)

  const { control } = useFormContext()

  const options = getOptions(query.SIEMIntegrations)

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => <Select id={id} options={options} {...field} />}
    />
  )
}

export default LogsSelect
