/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import styled from '@emotion/styled'
import React from 'react'
import { CardContent, Paper, Typography } from '@material-ui/core'
import { SectionTitle, Feature, WrapperCard } from './styles'

const LinkFeature = styled(Feature)`
  cursor: pointer;
  text-decoration: underline;
`

export const Contract = ({}) => {
  return (
    <WrapperCard>
      <CardContent>
        <SectionTitle>CONTRACT INFORMATION</SectionTitle>
        <LinkFeature>
          <a href="https://cyral.com/service-terms/">Terms of Service</a>
        </LinkFeature>
        <LinkFeature>
          {' '}
          <a href="https://cyral.com/service-terms/">Purchase Orders</a>
        </LinkFeature>
        <LinkFeature>
          {' '}
          <a href="https://cyral.com/service-terms/">Invoices</a>
        </LinkFeature>
      </CardContent>
    </WrapperCard>
  )
}
