import * as React from 'react'

import { Box, Typography, Stack } from '@mui/material'
import { Tooltip } from '@jeeves/new-components'
import RoleSelectItem from './RoleSelectItem'
import { useAppConfig } from '@jeeves/hooks'

const ReadOnlyRoleDisplay = ({ role }) => {
  const {
    config: { featureFlags },
  } = useAppConfig()

  if (featureFlags.s3BrowserHideSingleRole) return null

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <Typography variant="h6" sx={{ color: 'text.secondary' }}>
        Role
      </Typography>
      <Tooltip title={<RoleSelectItem role={role} />}>
        <Box
          sx={{
            borderRadius: 1,
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: 'cyralColors.grey.200',
            backgroundColor: 'cyralColors.grey.100',
            color: 'text.secondary',
            padding: 1,
          }}
        >
          <RoleSelectItem role={role} abbreviate />
        </Box>
      </Tooltip>
    </Stack>
  )
}

export default ReadOnlyRoleDisplay
