import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { useForm } from 'react-hook-form'
import { gql, useMutation } from '@apollo/client'
import { Stack, Box, DialogContent, DialogTitle, Typography } from '@mui/material'
import CloseIcon from '@material-ui/icons/Close'

import BasicSnackbar from '@jeeves/pages/WrapperDetail/components/BasicSnackbar'
import { usePolicyDetails } from '@jeeves/pages/RepositoryDetail/Tabs/Policies/views/PolicyDetails/contexts/PolicyDetailsContext'
import { useRepositoryDetailContext } from '@jeeves/pages/RepositoryDetail/contexts/RepositoryDetailContext'
import { Dialog, Button, Input, DialogActions, IconButton } from '@jeeves/new-components'

const DELETE_POLICY = gql`
  mutation DeletePolicy($policyId: ID!) {
    deletePolicy(policyId: $policyId) {
      deletedPolicyId
    }
  }
`

const DeletePolicyModal = ({ isOpen, setIsOpen }) => {
  const history = useHistory()
  const { policy } = usePolicyDetails()
  const { repoId } = useRepositoryDetailContext()
  const [snackBarOpen, setSnackBarOpen] = useState(false)

  const [deletePolicy, { error }] = useMutation(DELETE_POLICY, {
    onError: e => {
      const values = getValues()
      reset(values)
      setSnackBarOpen(true)
      console.error(e)
    },
    onCompleted: () => {
      handleCloseSnackbar()
      handleCloseModal()
      history.push(`/repositories/${repoId}/policies`)
    },
    update(cache, { data: { deletePolicy } }) {
      const cacheId = cache.identify({
        id: deletePolicy.deletedPolicyId,
        __typename: policy.__typename,
      })
      cache.evict({ id: cacheId })
      cache.gc()
    },
  })

  const {
    register,
    handleSubmit,
    watch,
    formState: { isSubmitting },
    reset,
    getValues,
  } = useForm({
    defaultValues: {
      confirmation: '',
    },
  })

  const handleCloseSnackbar = () => {
    setSnackBarOpen(false)
  }

  const handleCloseModal = () => {
    setIsOpen(false)
    reset({ confirmation: '' })
  }

  const onSubmit = async () => {
    await deletePolicy({
      variables: {
        policyId: policy.id,
      },
    })
  }

  const confirmation = watch('confirmation')

  const errorMessage = error?.networkError?.result?.errors?.[0]?.message || error?.message

  return (
    <Dialog open={isOpen} onClose={handleCloseModal}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h3" sx={{ color: 'text.primary' }}>
            Delete Policy
          </Typography>
          <IconButton aria-label="close" onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Stack spacing={2}>
            <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
              Are you sure you want to delete this policy?
            </Typography>
            <Stack spacing={1}>
              <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
                Please type{' '}
                <Typography component="span" sx={{ fontWeight: 'bold' }} variant="body2">
                  {policy.name}
                </Typography>{' '}
                to confirm you want to delete this policy.
              </Typography>{' '}
              <Input inputProps={{ ...register('confirmation') }}></Input>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Stack direction="row" spacing={2} sx={{ justifyContent: 'flex-end' }}>
            <Button variant="text" onClick={handleCloseModal}>
              Cancel
            </Button>
            <Button
              loading={isSubmitting}
              variant="outlined"
              type="submit"
              disabled={confirmation.trim() !== policy.name.trim()}
              color="error"
            >
              Delete
            </Button>
          </Stack>
        </DialogActions>
        <BasicSnackbar
          open={snackBarOpen}
          onClose={handleCloseSnackbar}
          severity="error"
          message={`Failed to delete policy: ${errorMessage}`}
        />
      </Box>
    </Dialog>
  )
}

export default DeletePolicyModal
