import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import { graphql } from '@jeeves/graphql'
import {
  useCreateLoggingIntegration,
  useUpdateLoggingIntegration,
} from '@jeeves/pages/Integrations/Services/Logging/components/NewIntegrationDialog/hooks'

const getDefaultValues = integration => ({
  name: integration?.name || '',
  region: integration?.region || '',
  group: integration?.group || '',
  stream: integration?.stream || '',
})

const schema = z.object({
  name: z.string().min(1, { message: 'Please enter a name.' }),
  region: z.string().min(1, { message: 'Please enter a region.' }),
  group: z.string().min(1, { message: 'Please enter a group name.' }),
  stream: z.string(),
})

export const useCloudwatch_cloudWatchIntegrationFragment = graphql(`
  fragment useCloudwatch_cloudWatchIntegration on CloudWatchIntegration {
    group
    region
    stream
  }
`)

export const useCloudwatch = ({ integration, onClose, isEditing }) => {
  const methods = useForm({
    defaultValues: getDefaultValues(integration),
    resolver: zodResolver(schema),
  })

  const [createLoggingIntegration, { loading: loadingCreateLoggingIntegration }] =
    useCreateLoggingIntegration({
      onCompleted: () => {
        onClose()
      },
    })

  const [updateLoggingIntegration, { loading: loadingUpdateLoggingIntegration }] =
    useUpdateLoggingIntegration({
      onCompleted: () => {
        onClose()
      },
    })

  const createCloudwatchIntegration = async ({ name, region, group, stream }) => {
    await createLoggingIntegration({
      variables: {
        integration: {
          name,
          config: {
            cloudWatch: {
              region,
              group,
              stream,
            },
          },
        },
      },
    })
  }

  const updateCloudwatchIntegration = async ({ name, region, group, stream }) => {
    await updateLoggingIntegration({
      variables: {
        updateLoggingIntegrationId: integration.id,
        input: {
          name,
          config: {
            cloudWatch: {
              region,
              group,
              stream,
            },
          },
        },
      },
    })
  }

  const onSubmit = async formData => {
    if (isEditing) {
      await updateCloudwatchIntegration(formData)
    } else {
      await createCloudwatchIntegration(formData)
    }
  }

  return {
    loading: loadingCreateLoggingIntegration || loadingUpdateLoggingIntegration,
    methods,
    onSubmit: methods.handleSubmit(onSubmit),
  }
}
