/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import React, { useState, useEffect, useContext } from 'react'

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  withStyles,
} from '@material-ui/core'

import EditGroupButton from '../ManagementTable/components/EditGroupButton'
import PermissionsForm from './components/PermissionsForm'
import MappingsForm from './components/MappingsForm'
import useUsers from '../../hooks/useUsers'
import Loading from '../../../../../components/Loading'
import { UsersContext } from '../../contexts/UsersContext'
import GroupName from './GroupName'

const styles = theme => ({
  title: {
    padding: theme.spacing.unit * 3,
    paddingLeft: '50px',
    marginBottom: '15px',
    fontWeight: 600,
    fontSize: '22px',
  },
  divider: {
    marginTop: '15px',
    marginBottom: '15px',
  },
  content: {
    marginBottom: theme.spacing.unit * 4,
  },
  deleteIcon: {
    fontSize: 28,
  },
  grow: {
    flexGrow: 1,
  },
  DialogActions: {
    paddingBottom: theme.spacing.unit * 3,
    paddingLeft: '45px',
    paddingRight: '30px',
    paddingTop: 0,
  },
})

const EditGroup = ({ classes, groupId, closeUsersManagement, groupName }) => {
  const [open, setOpen] = useState(false)
  const onClose = () => setOpen(false)
  const onOpen = () => setOpen(true)

  const [groupState, setGroupState] = useState({})
  const [checkedPermissions, setCheckedPermissions] = useState([])
  const [mappingsNameInput, setMappingsNameInput] = useState('')
  const [mappingsConnectionInput, setMappingsConnectionInput] = useState('')

  // Load states
  const [loading, setLoading] = useState(false)
  const [addMappingLoading, setAddMappingLoading] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false) // delete mapping
  const [deletingGroup, setDeletingGroup] = useState(false)
  const [savingGroup, setSavingGroup] = useState(false)

  // eslint-disable-next-line
  const [state, setState] = useContext(UsersContext)
  const [addingMapping, setAddingMapping] = useState(false)

  const {
    getUserGroup,
    patchUserGroupMappings,
    putUserGroupRoles,
    deleteUserGroupMappings,
    refreshGroups,
    refreshRoles,
    deleteUserGroup,
    user,
  } = useUsers()

  const handleOnToggle = value => {
    const currentIndex = checkedPermissions.indexOf(value)
    const newCheckedPermissions = [...checkedPermissions]
    if (currentIndex === -1) {
      newCheckedPermissions.push(value)
    } else {
      newCheckedPermissions.splice(currentIndex, 1)
    }
    setCheckedPermissions(newCheckedPermissions)
  }

  const setInitialChecked = () => {
    const roleIds = groupState.roles ? groupState.roles.map(cp => cp.id) : []
    setCheckedPermissions(roleIds || [])
  }

  const clearMappingsInput = () => {
    setMappingsNameInput('')
    setMappingsConnectionInput('')
  }

  const handleOnCancel = async () => {
    onClose()
    setInitialChecked()
  }

  const handleOnDelete = async () => {
    try {
      setDeletingGroup(true)
      await deleteUserGroup(groupId)
      await refreshGroups()
      await refreshRoles()
      onClose()
    } catch (e) {
    } finally {
      setDeletingGroup(false)
    }
  }

  const updateGroup = () => {
    return getUserGroup(groupId).then(group => {
      setGroupState(group)
    })
  }

  const handleOnAddMapping = async () => {
    try {
      setAddMappingLoading(true)
      await patchUserGroupMappings(groupId, mappingsNameInput, mappingsConnectionInput)
      await updateGroup()
      clearMappingsInput()
    } catch (e) {
    } finally {
      setAddMappingLoading(false)
    }
  }

  const handleOnSave = async () => {
    try {
      setSavingGroup(true)
      await putUserGroupRoles(groupId, checkedPermissions)
      await refreshGroups()
      await refreshRoles()
      clearMappingsInput()
      onClose()
    } catch (e) {
    } finally {
      setSavingGroup(false)
    }
  }

  const handleOnDeleteMapping = async mappingId => {
    try {
      setDeleteLoading(true)
      await deleteUserGroupMappings(groupId, mappingId)
      await updateGroup()
    } catch (e) {
    } finally {
      setDeleteLoading(false)
    }
  }

  useEffect(() => {
    const getUserGroupHandler = async () => {
      const userGroup = await getUserGroup(groupId)
      setGroupState(userGroup)
      const roleIds = userGroup.roles ? userGroup.roles.map(cp => cp.id) : []
      setCheckedPermissions(roleIds || [])
    }

    const fetchData = async () => {
      if (open) {
        try {
          setLoading(true)
          await getUserGroupHandler()
        } catch (e) {
        } finally {
          setLoading(false)
        }
      }
    }

    fetchData()
  }, [open])

  return (
    <React.Fragment>
      <EditGroupButton
        openEditGroupDialog={onOpen}
        closeUsersManagement={closeUsersManagement}
        group={groupName}
        user={user}
      />
      <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
        <DialogContent className={classes.content}>
          {loading && <Loading />}

          {loading ? <GroupName /> : <GroupName name={groupState.name} />}
          <Divider variant="middle" className={classes.divider} />

          <PermissionsForm
            handleOnToggle={handleOnToggle}
            permissions={state.roles || []}
            checked={checkedPermissions || []}
            setInitialChecked={setInitialChecked}
            disabled={loading}
          />
          <Divider variant="middle" className={classes.divider} />

          <div css={{ position: 'relative' }}>
            {(addMappingLoading || deleteLoading) && <Loading />}
            <MappingsForm
              loading={loading}
              connectionNames={state.connectionNames}
              mappings={groupState.mappings}
              setMappingsNameInput={setMappingsNameInput}
              setMappingsConnectionInput={setMappingsConnectionInput}
              mappingsNameInput={mappingsNameInput}
              mappingsConnectionInput={mappingsConnectionInput}
              handleOnDeleteMapping={handleOnDeleteMapping}
              handleOnAddMapping={handleOnAddMapping}
              handleOnCancelMappingInput={clearMappingsInput}
              setAdding={mapping => setAddingMapping(mapping)}
              adding={addingMapping}
            />
          </div>
        </DialogContent>

        <DialogActions className={classes.DialogActions}>
          <div css={{ position: 'relative' }}>
            <Button
              color="secondary"
              variant="outlined"
              onClick={handleOnDelete}
              disabled={loading || addingMapping || deletingGroup || savingGroup}
            >
              Delete
            </Button>
            {deletingGroup && (
              <CircularProgress
                size={24}
                css={css`
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  margin-top: -12px;
                  margin-left: -12px;
                `}
              />
            )}
          </div>
          <div className={classes.grow} />

          <Button color="primary" onClick={handleOnCancel}>
            Cancel
          </Button>
          <div css={{ position: 'relative' }}>
            <Button
              color="primary"
              variant="contained"
              onClick={handleOnSave}
              disabled={loading || addingMapping || deletingGroup || savingGroup}
            >
              Save
            </Button>
            {savingGroup && (
              <CircularProgress
                size={24}
                css={css`
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  margin-top: -12px;
                  margin-left: -12px;
                `}
              />
            )}
          </div>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default withStyles(styles)(EditGroup)
