import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { Box, Stack, FormControl, FormHelperText } from '@mui/material'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@apollo/client'

import { FragmentType, graphql, useFragment } from '@jeeves/graphql'
import { getGraphQLErrorMessage } from '@jeeves/utils/helpers'
import { Button, Input, InputLabel, useToast } from '@jeeves/new-components'

const EditAccessToken_QueryFragment = graphql(`
  fragment EditAccessToken_QueryFragment on Query {
    accessTokens {
      id
      name
    }
  }
`)

const UPDATE_ACCESS_TOKEN = graphql(`
  mutation UpdateAccessToken($updateAccessTokenId: ID!, $input: UpdateAccessTokenInput!) {
    updateAccessToken(id: $updateAccessTokenId, input: $input) {
      accessToken {
        id
        name
      }
    }
  }
`)

const editAccessTokenSchema = z.object({
  name: z.string().optional(),
})

type EditAccessTokenSchema = z.infer<typeof editAccessTokenSchema>

interface Props {
  selectedAccessTokenId: string
  goBackToManage: () => void
  query: FragmentType<typeof EditAccessToken_QueryFragment>
}

const EditAccessToken = ({ selectedAccessTokenId, goBackToManage, query: queryProp }: Props) => {
  const { toast } = useToast()
  const query = useFragment(EditAccessToken_QueryFragment, queryProp)

  const accessTokenToBeEdited = query.accessTokens.find(
    accessToken => accessToken.id === selectedAccessTokenId
  )

  const methods = useForm<EditAccessTokenSchema>({
    defaultValues: {
      name: accessTokenToBeEdited?.name,
    },
    resolver: zodResolver(editAccessTokenSchema),
  })

  const {
    formState: { errors },
    register,
  } = methods

  const [updateAccessToken, { loading }] = useMutation(UPDATE_ACCESS_TOKEN, {
    onError: error => {
      toast({
        variant: 'error',
        description:
          getGraphQLErrorMessage(error) ||
          'An error occurred while updating this access token, please try again.',
      })
    },
    onCompleted: () => {
      goBackToManage()
    },
  })

  const onSubmit = async ({ name }: EditAccessTokenSchema) => {
    await updateAccessToken({
      variables: {
        updateAccessTokenId: selectedAccessTokenId,
        input: {
          name,
        },
      },
    })
  }

  return (
    <Box component="form" onSubmit={methods.handleSubmit(onSubmit)}>
      <Stack spacing={4}>
        <FormControl variant="standard" error={Boolean(errors.name)} fullWidth>
          <InputLabel htmlFor="name">Name</InputLabel>
          <Input
            id="name"
            type="text"
            inputProps={{
              ...register('name'),
            }}
          />
          <FormHelperText sx={{ typography: 'body2' }}>{errors.name?.message}</FormHelperText>
        </FormControl>

        <Stack direction="row" spacing={1} sx={{ justifyContent: 'flex-end' }}>
          <Button variant="outlined" color="secondary" onClick={goBackToManage}>
            Cancel
          </Button>
          <Button variant="contained" type="submit" color="primary" loading={loading}>
            Save
          </Button>
        </Stack>
      </Stack>
    </Box>
  )
}

export default EditAccessToken
