import * as React from 'react'
import { useMutation, gql } from '@apollo/client'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import DialogContent from '@mui/material/DialogContent'
import FormControl from '@mui/material/FormControl'
import DialogTitle from '@mui/material/DialogTitle'

import { Input, InputLabel, Button, Dialog, DialogActions } from '@jeeves/new-components'
import { useRepositoryDetailContext } from '@jeeves/pages/RepositoryDetail/contexts/RepositoryDetailContext'

import { APPROVAL_FIELDS } from '../useApprovalDetailQuery'

const REJECT_PENDING_APPROVAL = gql`
  mutation RejectPendingApproval($repoId: ID!, $approvalId: ID!, $comment: String) {
    rejectApproval(repoId: $repoId, approvalId: $approvalId, comment: $comment) {
      rejectedApproval {
        ...approvalFields
      }
    }
  }
  ${APPROVAL_FIELDS}
`

const RejectPendingApprovalDialog = ({ open, closeDialog, ssoUser, approvalId }) => {
  const [reason, setReason] = React.useState('')
  const { repoId } = useRepositoryDetailContext()
  const [rejectPendingApproval, { data, loading, error }] = useMutation(REJECT_PENDING_APPROVAL, {
    onCompleted(data) {
      closeDialog()
    },
    update(cache, { data: { rejectApproval } }) {
      // Removes PendingApproval from cache (since it's now being replaced by a RejectedApproval).
      // This will essentially trigger a refetch of the query in 'useApprovalDetailQuery' because
      // it won't be able to find the PendingApproval that it references in the cache after it's evicted.
      const cacheId = cache.identify({
        id: rejectApproval?.rejectedApproval.id,
        __typename: 'PendingApproval',
      })
      cache.evict({ id: cacheId })
      cache.gc()
    },
  })

  React.useEffect(() => {
    // Resets the reason if modal closes
    if (!open) {
      setReason('')
    }
  }, [open])

  const rejectApproval = async () => {
    try {
      await rejectPendingApproval({
        variables: {
          repoId,
          approvalId,
          comment: reason,
        },
      })
    } catch (error) {}
  }

  return (
    <Dialog open={open} onClose={closeDialog}>
      <DialogTitle variant="h3">Reject Approval</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography variant="body1" sx={{ color: 'text.secondary' }}>
            Are you sure you want to reject this approval request for{' '}
            <Typography component="span" variant="h6">
              {ssoUser}
            </Typography>
            ?
          </Typography>

          <FormControl variant="standard">
            <InputLabel>Reason</InputLabel>
            <Input
              id="description-input"
              value={reason}
              onChange={e => setReason(e.target.value)}
              multiline
              rows={5}
              placeholder="e.g. Outside of contract period."
            />
          </FormControl>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button variant="text" onClick={closeDialog}>
          Cancel
        </Button>
        <Button variant="outlined" color="error" onClick={rejectApproval} loading={loading}>
          Reject
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default RejectPendingApprovalDialog
