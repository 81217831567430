import React from 'react'
import classNames from 'classnames'

import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const styles = theme => ({
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  open: {
    transform: 'rotate(180deg)',
  },
})

const ExpandButton = ({ classes, expanded, onClick, css }) => (
  <IconButton
    css={css}
    className={classNames(classes.expand, {
      [classes.open]: expanded,
    })}
    onClick={onClick}
    aria-expanded={expanded}
    aria-label="Show more"
  >
    <ExpandMoreIcon />
  </IconButton>
)

const StyledExpandButton = withStyles(styles)(ExpandButton)

export { StyledExpandButton as ExpandButton }
