/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import styled from '@emotion/styled'
import { grey } from '@material-ui/core/colors'
import { ExpandButton } from '@jeeves/components/Table'
import CodeSnippet from '@jeeves/components/CodeSnippet'
import React, { useState, useEffect } from 'react'
import { TableRow, TableCell as MuiTableCell, Collapse } from '@material-ui/core'
import moment from 'moment'
import moment_timezone from 'moment-timezone'
import { isEmpty } from 'lodash'
import useAuditLogs from '../../../pages/AuditLogs/hooks/useAuditLogs'
import axios from 'axios'

import { formatAuditLogTimestamp } from '@jeeves/utils/datetime.js'

function getRandomInt(max) {
  return Math.floor(Math.random() * max)
}

const TableCell = styled(MuiTableCell)`
  border-bottom: none;
`

const GeneralEventRow = ({ event, userConnections }) => {
  const [expanded, setExpanded] = useState(false)
  const [geoLocation, setGeoLocation] = useState()
  const { getDescriptivePhraseForAction } = useAuditLogs()

  const createActionMetadata = eventAction => {
    switch (eventAction) {
      case 'login':
      case 'authentication.login':
        return {
          IDPAlias: event.subject.idp,
          IDPset: userConnections,
        }
      default:
        return undefined
    }
  }

  const handleSetGeoLocation = async ipAddress => {
    try {
      setGeoLocation(
        (await axios.get(`https://get.geojs.io/v1/ip/geo.json?ip=${ipAddress}`)).data[0]
      )
    } catch (e) {
      console.log(e)
    }
  }

  if (!event || !event.subject || !event.details) {
    return null
  }

  const { operation, ...restOfDetails } = event.details

  const eventDetailsCodeSnippet = isEmpty(restOfDetails)
    ? 'No event details.'
    : JSON.stringify(restOfDetails, null, 2)

  const actionPerformed = event.action || operation
  const actionMetadata = createActionMetadata(actionPerformed)

  const user = event.subject.username || event.subject.email || event.subject.clientID
  const descriptiveActionPhrase = getDescriptivePhraseForAction(actionPerformed, actionMetadata)

  return (
    <React.Fragment>
      <TableRow onClick={() => setExpanded(!expanded)}>
        <TableCell>
          <ExpandButton css={{ paddingTop: 0, paddingBottom: 0 }} expanded={expanded} />
        </TableCell>
        <TableCell>{actionPerformed}</TableCell>
        <TableCell>
          <strong>{user}</strong>
        </TableCell>
        <TableCell>{descriptiveActionPhrase}</TableCell>
        <TableCell>{formatAuditLogTimestamp(event.timestamp)}</TableCell>
      </TableRow>
      <TableRow
        css={{
          height: '0',
        }}
      >
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
          <Collapse in={expanded} unmountOnExit>
            <CodeSnippet
              code={eventDetailsCodeSnippet}
              language="json"
              css={{ marginLeft: '3rem', maxWidth: '800px' }}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

export default GeneralEventRow
