import { Table } from '@mui/material'

import { FragmentType, graphql, useFragment } from '@jeeves/graphql'
import { TableContainer } from '@jeeves/new-components'

import UsersTableHeader from './UsersTableHeader'
import UsersTableBody from './UsersTableBody'
import UsersTableFooter from './UsersTableFooter'

const UsersTable_QueryFragment = graphql(`
  fragment UsersTable_QueryFragment on Query {
    ...UsersTableBody_QueryFragment
    ...UsersTableFooter_QueryFragment
  }
`)

interface Props {
  openEdit: (user: any) => void
  query: FragmentType<typeof UsersTable_QueryFragment>
  openSsoGroupsModal: (ssoGroups: string[]) => void
}

const UsersTable = ({ openEdit, query: queryProp, openSsoGroupsModal }: Props) => {
  const query = useFragment(UsersTable_QueryFragment, queryProp)

  return (
    <TableContainer
      sx={{
        'tr:last-child': {
          '& td': {
            borderBottomWidth: 1,
            borderBottomStyle: 'solid',
            borderColor: 'cyralColors.grey.200',
          },
        },
      }}
    >
      <Table>
        <UsersTableHeader />
        <UsersTableBody openEdit={openEdit} query={query} openSsoGroupsModal={openSsoGroupsModal} />
        <UsersTableFooter query={query} />
      </Table>
    </TableContainer>
  )
}

export default UsersTable
