/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { jsx, css } from '@emotion/react'

import { useAuth } from '@jeeves/components/Auth'

import Existing from './Existing'
import IntegrationForm from './IntegrationForm'
import usePagerduty from './hooks/usePagerduty'
import {
  ErrorState,
  ZeroState,
  AddIntegrationButtonUnformatted,
  AddIntegrationButton,
  IntegrationDialogContent,
  IntegrationLoading,
  IntegrationList,
} from '../../primitives'
import logo from './images/pagerduty.png'

import { useConfExtensionInstances } from '@jeeves/hooks/useConfExtensions'

const Pagerduty = () => {
  const {
    getConfExtensionInstancesResponse: { data, loading, error },
    createConfExtensionInstance,
    createConfExtensionInstanceResponse: { error: createError },
    updateConfExtensionInstance,
    updateConfExtensionInstanceResponse: { error: updateError },
    deleteConfExtensionInstance,
    deleteConfExtensionInstanceResponse: { error: deleteError },
  } = useConfExtensionInstances({
    getConfExtensionInstancesOptions: {
      variables: { purpose: 'authorization', templateType: 'pagerduty' },
    },
  })
  const { hasPermission } = useAuth()
  const [adding, setAdding] = useState(false)
  const integrations = data?.confExtensionInstances || []

  const createIntegration = async (name, apiToken) => {
    await createConfExtensionInstance({
      variables: {
        confExtensionInstance: {
          name,
          category: 'builtin',
          templateType: 'pagerduty',
          purpose: 'authorization',
          parameters: `{\"apiToken\":\"${apiToken}\"}`,
        },
      },
    })
    setAdding(false)
  }

  return (
    <>
      <IntegrationDialogContent>
        <IntegrationList>
          {loading && <IntegrationLoading />}
          {!loading && error && <ErrorState />}
          {!loading && !error && integrations.length === 0 && <ZeroState />}
          {!loading &&
            !error &&
            integrations.length > 0 &&
            integrations.map(integration => (
              <Existing
                integration={integration}
                putIntegration={updateConfExtensionInstance}
                deleteIntegration={deleteConfExtensionInstance}
                key={integration.id}
              />
            ))}
          {adding && (
            <IntegrationForm
              integration={null}
              creating={true}
              onSave={createIntegration}
              onCancel={() => setAdding(false)}
            />
          )}
        </IntegrationList>
        <div
          css={() => css`
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
          `}
        >
          <AddIntegrationButtonUnformatted
            isOpen={adding}
            setOpen={setAdding}
            disabled={!hasPermission('integrations:create') || adding}
          />
        </div>
      </IntegrationDialogContent>
    </>
  )
}

Pagerduty.metadata = {
  name: 'PagerDuty',
  summary: 'Integrate incident responses with your existing process.',
  enabled: true,
  category: 'Incident Response',
  slug: 'pagerduty',
  logo,
}

export default Pagerduty
