import React, { useEffect, useState } from 'react'
import { useAuth } from '@jeeves/components/Auth'
import useConfig from '../hooks/useConfig'

const AppContext = React.createContext()

const initialState = {
  config: {},
  license: {},
  entitlementsSpec: {},
}

const AppConfigurationProvider = ({ children }) => {
  const [appConfig, setAppConfig] = useState(initialState)
  const { loading } = useAuth()

  const { setConfig, setLicense } = useConfig(setAppConfig)

  useEffect(() => {
    if (!loading) {
      setConfig()
      setLicense()
    }
  }, [loading])

  if (loading) {
    return <div>Loading...</div>
  }

  return <AppContext.Provider value={appConfig}>{children}</AppContext.Provider>
}

const useAppConfig = () => {
  const context = React.useContext(AppContext)
  if (context === undefined) {
    throw new Error('useAppConfig must be used within a AppConfigurationProvider')
  }
  return context
}

export { AppConfigurationProvider, useAppConfig }
