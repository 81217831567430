import Box from '@mui/material/Box'
import { parseISO, format, isAfter } from 'date-fns'
import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table'

const useGrantedApprovalsTable = ({ data }) => {
  const columnHelper = createColumnHelper()

  const columns = [
    columnHelper.accessor('requester.name', {
      id: 'ssoUser',
      header: () => 'SSO User',
      cell: info => (
        <Box component="span" sx={{ color: 'text.primary', typography: 'body2' }}>
          {info.getValue()}
        </Box>
      ),
    }),
    columnHelper.accessor('userAccount.name', {
      id: 'dbAccount',
      header: () => 'DB Account',
      cell: info => (
        <Box component="span" sx={{ color: 'text.primary', typography: 'body2' }}>
          {info.getValue()}
        </Box>
      ),
    }),
    columnHelper.accessor(row => row?.granter?.name ?? 'Auto-approved', {
      id: 'approver',
      header: () => 'Approver',
      cell: info => (
        <Box component="span" sx={{ color: 'text.primary', typography: 'body2' }}>
          {info.getValue()}
        </Box>
      ),
    }),
    columnHelper.accessor('sensitiveFields', {
      id: 'sensitiveFields',
      header: () => 'Sensitive Fields',
      cell: info => {
        const sensitiveFields = info.getValue()

        return (
          <Box component="span" sx={{ color: 'text.primary', typography: 'body2' }}>
            {sensitiveFields?.length || '—'}
          </Box>
        )
      },
    }),
    columnHelper.accessor(
      row => ({
        effectiveStartDate: row.effectiveStartDate,
        validUntil: row.validUntil,
      }),
      {
        id: 'duration',
        cell: info => {
          const value = info.getValue()

          const effectiveStartDate = format(parseISO(value.effectiveStartDate), 'd MMM yy')

          // validUntil might not exist since it's nullable
          const validUntil =
            (value.validUntil && format(parseISO(value.validUntil), 'd MMM yy')) ?? 'Unlimited'

          const duration = `${effectiveStartDate} — ${validUntil}`

          return (
            <Box component="span" sx={{ color: 'text.primary', typography: 'body2' }}>
              {duration}
            </Box>
          )
        },
        header: 'Duration',
      }
    ),
  ]

  return useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })
}

export default useGrantedApprovalsTable
