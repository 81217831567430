import React, { forwardRef } from 'react'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

const BasicSnackbar = ({ open, onClose, severity, message }) => {
  // Toast display duration varies depending on the length of the toast message to accommodate reading time.
  // This formula is described in detail here: https://ux.stackexchange.com/a/85898/162887.
  const duration = Math.min(Math.max(message?.length * 50, 2000), 7000)

  return (
    <Snackbar
      open={open}
      autoHideDuration={duration}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert onClose={onClose} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  )
}

export default BasicSnackbar
