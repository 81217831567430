/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import styled from '@emotion/styled'
import { useState } from 'react'
import {
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField as MuiTextField,
  Tooltip as MaterialTooltip,
} from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import { Formik, useField } from 'formik'
import { get, isArray } from 'lodash'
import * as yup from 'yup'
import { Clear as ClearIcon } from '@material-ui/icons'
import { EmailCheck, Send } from 'mdi-material-ui'

import { useAuth, useMount } from '@jeeves/hooks'
import useSetup from '../hooks/useSetup'

import { NextButton, SetupLayout, Typography } from '@jeeves/pages/Setup/components'
import { getValidRoles } from '@jeeves/utils/helpers'

const CenteredButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Grid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: calc(5% - 16px) repeat(3, calc(28% - 16px)) repeat(2, calc(5% - 16px));
  gap: 16px;
`

const MissingText = props => (
  <Typography
    {...props}
    css={() => css`
      color: ${grey[700]};
      font-style: italic;
      margin: 0;
    `}
  />
)

const FormikTextField = ({ name, ...props }) => {
  const [field, meta] = useField(name)
  const { error, touched } = meta

  return <MuiTextField error={touched && error && true} {...field} {...props} />
}

const FormikRoleSelect = ({ name, children, label, ...props }) => {
  const [field, meta] = useField(name)
  const { error, touched } = meta

  return (
    <FormControl variant="outlined" error={touched && error && true}>
      <InputLabel>Role</InputLabel>
      <Select input={<OutlinedInput name={name} labelWidth={37} />} {...field} {...props}>
        {children}
      </Select>
    </FormControl>
  )
}

const groupDescriptionMap = {
  User: 'View Cyral',
  Admin: 'Configure Cyral',
  'Super Admin': 'Manage Cyral & other users',
}

const UserRow = ({ onAdd, groups, index }) => (
  <Formik
    validateOnMount={true}
    initialValues={{
      name: '',
      email: '',
      role: '',
    }}
    validationSchema={yup.object({
      name: yup.string().required(),
      email: yup
        .string()
        .email()
        .required(),
      role: yup.string().required(),
    })}
    onSubmit={(values, actions) => {
      onAdd(values)
      actions.resetForm()
    }}
  >
    {({ handleSubmit, isValid, dirty, resetForm }) => (
      <form onSubmit={handleSubmit}>
        <Grid>
          <Typography>{index}.</Typography>
          <FormikTextField autoFocus label="Name" name="name" variant="outlined" />
          <FormikTextField label="Email" name="email" variant="outlined" />
          <FormikRoleSelect
            label="Role"
            name="role"
            variant="outlined"
            disabled={groups === [] || !groups}
            renderValue={value => <span>{value.name}</span>}
          >
            {groups
              .sort((a, b) => a.name.length - b.name.length)
              .map(group => (
                <MenuItem
                  value={group}
                  key={group.id}
                  css={{ paddingTop: '24px', paddingBottom: '24px' }}
                >
                  <ListItemText primary={group.name} secondary={groupDescriptionMap[group.name]} />
                </MenuItem>
              ))}
          </FormikRoleSelect>

          <CenteredButton>
            <MaterialTooltip title="Clear">
              <span>
                <IconButton onClick={() => resetForm()} disabled={!dirty}>
                  <ClearIcon />
                </IconButton>
              </span>
            </MaterialTooltip>
          </CenteredButton>

          <CenteredButton>
            <MaterialTooltip title="Send invite">
              <span>
                <IconButton color="primary" disabled={!isValid || !dirty} type="submit">
                  <Send />
                </IconButton>
              </span>
            </MaterialTooltip>
          </CenteredButton>
        </Grid>
      </form>
    )}
  </Formik>
)

const User = ({ index, name, email, role: roleProp, hideInvited }) => {
  const role = roleProp !== '_Everyone' ? roleProp : 'User'

  const [sending, setSending] = useState(true)

  useMount(() => {
    setTimeout(() => {
      setSending(false)
    }, 2000)
  }, [])

  return (
    <Grid>
      <Typography>{index}.</Typography>
      <Typography>{name || <MissingText>-</MissingText>}</Typography>
      <MaterialTooltip title={email}>
        <Typography
          css={t => css`
            font-family: ${t.typography.monospaced};
            text-overflow: ellipsis;
            display: block;
            overflow: hidden;
            white-space: nowrap;
          `}
        >
          {email}
        </Typography>
      </MaterialTooltip>
      <Typography>{role || <MissingText>-</MissingText>}</Typography>
      <div />
      {hideInvited ? (
        <div />
      ) : (
        <CenteredButton>
          {!sending ? (
            <MaterialTooltip title="Invite sent">
              <EmailCheck nativeColor="#808E95" />
            </MaterialTooltip>
          ) : (
            <MaterialTooltip title="Sending invite">
              <CircularProgress size={24} color="secondary" />
            </MaterialTooltip>
          )}
        </CenteredButton>
      )}
    </Grid>
  )
}

export const AddUsers = ({ onNext, groups, users, setUsers }) => {
  const [id, setId] = useState(users.length)
  // const [users, setUsers] = useState([])
  const { user } = useAuth()
  const { addUser } = useSetup()

  const onRemove = user => {
    setUsers(users.filter(u => u.id !== user.id))
  }
  const lastName = user.family_name ? user.family_name : ''
  const name = user.given_name ? `${user.given_name} ${lastName}` : null
  const roles = get(user, 'app_metadata.authorization.groups', []).filter(role => role[0] !== '_')
  const role = roles[0] || null

  // 'Super Admin' role is hardcoded and needs to be fixed
  return (
    <SetupLayout
      title="Bring in your team!"
      onNext={onNext}
      done={50}
      absoluteIndex={users.length < 3}
      actionButton={<NextButton onClick={onNext} variant={'contained'} />}
    >
      {user && <User name={name} email={user.email} role={'Super Admin'} index={1} hideInvited />}
      {users.length > 0 &&
        Array.isArray(users) &&
        users
          .filter(u => u.email !== user.email)
          .map(({ name, email, role }, index) => (
            <User
              name={name}
              email={email}
              role={role.name}
              index={index + 2}
              onRemove={() => onRemove(user)}
            />
          ))}

      <UserRow
        groups={getValidRoles(groups, ['_Everyone', 'Cyral Admin'])}
        index={id + 1}
        onAdd={user => {
          setUsers([...users, { id, ...user }])
          setId(id + 1)
          addUser(user)
        }}
      />
      <Typography>You can always connect us to your Identity Provider later!</Typography>
    </SetupLayout>
  )
}
