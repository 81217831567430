import { useEffect } from 'react'

import { Card, FormControlLabel, Stack, Switch, Typography } from '@mui/material'

import { useAllowCyralAccess } from './useAllowCyralAccess'

const AllowCyralAccess = () => {
  const { isChecked, isDisabled, fetchAllowCyralAccess, handleAllowCyralAccess } =
    useAllowCyralAccess()

  useEffect(() => {
    if (window._env_.outbound_internet !== 'false') {
      fetchAllowCyralAccess()
    }
  }, [])

  return (
    <Card
      elevation={0}
      sx={{
        width: '100%',
        borderRadius: theme => theme.radii.xl,
        padding: theme => theme.spacing(4),
        ':hover': {
          outline: theme => `2px solid ${theme.palette.primary.main}`,
        },
      }}
    >
      {
        <FormControlLabel
          control={
            <Switch
              checked={isChecked}
              disabled={isDisabled}
              onChange={handleAllowCyralAccess}
              sx={{ color: 'primary.main' }}
            />
          }
          label={
            <Stack spacing={1}>
              <Typography variant="h4">
                Allow Cyral support to access your Management Console
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                Cyral will only use this access for support, troubleshooting, and incident response.
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                All activity will appear in your Audit Logs. Operating with this option disabled is
                not covered by Cyral's SLA.
              </Typography>
            </Stack>
          }
        />
      }
    </Card>
  )
}

export default AllowCyralAccess
