import { Typography, Stack, Dialog, DialogTitle, DialogContent } from '@mui/material'
import { LoadingButton as MuiButton } from '@mui/lab'

const DeletionDialog = ({
  setDeleteConfirmationDialogOpen,
  deleteConfirmationDialogOpen,
  selectedObjectPathsSet,
  deleteLoading,
  handleDeleteConfirmationDialogButtonClick,
}) => {
  return (
    <Dialog
      onClose={() => setDeleteConfirmationDialogOpen(false)}
      open={deleteConfirmationDialogOpen}
    >
      <DialogTitle sx={{ fontSize: '1.25rem', padding: '24px 24px 16px 24px' }}>
        Confirm Deletion
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography sx={{ color: 'text.secondary', width: '400px' }}>
            Are you sure you want to delete{' '}
            <Typography
              display="inline"
              variant="body1"
              sx={{ color: 'error.main', fontWeight: 'bold' }}
            >
              {selectedObjectPathsSet.size}
            </Typography>{' '}
            {`file${selectedObjectPathsSet.size > 1 ? 's' : ''}?`}
          </Typography>
          <Stack justifyContent="flex-end" direction="row" spacing={1}>
            <MuiButton
              disabled={deleteLoading}
              onClick={() => setDeleteConfirmationDialogOpen(false)}
            >
              Cancel
            </MuiButton>
            <MuiButton
              color="error"
              disableElevation
              loading={deleteLoading}
              variant="contained"
              onClick={handleDeleteConfirmationDialogButtonClick}
            >
              Confirm Deletion
            </MuiButton>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}

export default DeletionDialog
