/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import styled from '@emotion/styled'

import {
  Title,
  Subtitle,
  Tooltip,
  NextButton,
  CircleProgress,
} from '@jeeves/pages/Setup/components'

const ContentWrapper = styled.div`
  padding: ${({ hideButton }) => (hideButton ? '32px 32px 0 32px' : '32px')};
  position: relative;
  font-size: 24px;
`

export const SetupLayout = ({
  title,
  subtitle,
  children,
  onNext,
  actionButton,
  tooltip,
  hideButton = false,
  absoluteIndex = false,
  disableContinue = false,
}) => (
  <div
    css={() => css`
      width: 66.6666%;
      margin: 0 auto;
    `}
  >
    {title && <Title>{title}</Title>}
    {subtitle && (
      <Subtitle>
        {subtitle}
        {tooltip && <Tooltip title={tooltip} />}
      </Subtitle>
    )}

    <ContentWrapper hideButton={hideButton}>{children}</ContentWrapper>

    <div
      css={() => css`
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        flex-direction: column;
        margin-top: ${hideButton ? '0' : '24px'};
      `}
    >
      {!hideButton && (
        <div css={{ marginBottom: '24px' }}>
          {actionButton || (onNext && <NextButton onClick={onNext} disabled={disableContinue} />)}
        </div>
      )}
    </div>
  </div>
)
