import * as React from 'react'
import { useMutation, gql } from '@apollo/client'
import Box from '@mui/material/Box'
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'

import { useRepositoryDetailContext } from '@jeeves/pages/RepositoryDetail/contexts/RepositoryDetailContext'

import AccessRulesTableRow from './AccessRulesTableRow'
import { TableContainer } from './AccessRulesTable.style'
import DeleteAccessRuleModal from './DeleteAccessRuleModal'

const UPDATE_ACCESS_RULE_PRIORITY = gql`
  mutation UpdateAccessRulePriority(
    $repoId: ID!
    $userAccountId: ID!
    $accessRuleId: ID!
    $priority: Int!
  ) {
    updateAccessRulePriority(
      repoId: $repoId
      userAccountId: $userAccountId
      accessRuleId: $accessRuleId
      priority: $priority
    ) {
      userAccount {
        id
        accessRules {
          id
        }
      }
    }
  }
`

const AccessRulesTable = ({ userAccount }) => {
  const [deleteRuleModalOpen, setDeleteRuleModalOpen] = React.useState(false)
  const [deleteModalAccessRuleId, setDeleteModalAccessRuleId] = React.useState()
  const { repoId } = useRepositoryDetailContext()

  const rules = userAccount.accessRules
  const userAccountId = userAccount.id

  const [updateAccessRulePriority, { data, loading, error, reset: resetMutation }] = useMutation(
    UPDATE_ACCESS_RULE_PRIORITY,
    {
      variables: {
        repoId,
        userAccountId,
      },
    }
  )

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  const openDeleteRuleModal = ruleId => {
    setDeleteModalAccessRuleId(ruleId)
    setDeleteRuleModalOpen(true)
  }

  const closeDeleteRuleModal = () => setDeleteRuleModalOpen(false)

  const handleDragEnd = async event => {
    const { active, over } = event

    if (active.id !== over.id) {
      const oldIndex = rules.findIndex(rule => rule.id === active.id)
      const newIndex = rules.findIndex(rule => rule.id === over.id)

      const rule = rules[oldIndex]

      await updateAccessRulePriority({
        variables: {
          accessRuleId: rule.id,
          priority: newIndex + 1,
        },
        optimisticResponse: {
          updateAccessRulePriority: {
            userAccount: {
              id: userAccount.id,
              __typename: userAccount.__typename,
              accessRules: arrayMove(rules, oldIndex, newIndex),
            },
          },
        },
      })
    }
  }

  return (
    <React.Fragment>
      <TableContainer>
        <table aria-label="custom pagination table">
          <thead>
            <Box
              component="tr"
              sx={{
                '& th:first-of-type': {
                  borderLeftWidth: '1px',
                  borderLeftStyle: 'solid',
                  borderTopLeftRadius: theme => theme.radii.base,
                  borderBottomLeftRadius: theme => theme.radii.base,
                },

                '& th:last-of-type': {
                  borderRightWidth: '1px',
                  borderRightStyle: 'solid',
                  borderTopRightRadius: theme => theme.radii.base,
                  borderBottomRightRadius: theme => theme.radii.base,
                },
              }}
            >
              <Box component="th" scope="col" />
              <Box component="th" scope="col" sx={{ whiteSpace: 'nowrap' }}>
                Priority
              </Box>
              <Box component="th" scope="col">
                Name
              </Box>
              <Box component="th" scope="col" sx={{ whiteSpace: 'nowrap' }}>
                Conditions
              </Box>
              <Box component="th" scope="col" sx={{ whiteSpace: 'nowrap' }}>
                Duration
              </Box>
              <Box component="th" scope="col" />
            </Box>
          </thead>
          <tbody>
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
              <SortableContext items={rules} strategy={verticalListSortingStrategy}>
                {rules.map((rule, idx) => {
                  return (
                    <AccessRulesTableRow
                      key={rule.id}
                      rule={{
                        ...rule,
                        priority: idx + 1,
                      }}
                      openDeleteRuleModal={openDeleteRuleModal}
                      userAccountId={userAccountId}
                    />
                  )
                })}
              </SortableContext>
            </DndContext>
          </tbody>
        </table>
      </TableContainer>

      <DeleteAccessRuleModal
        open={deleteRuleModalOpen}
        onClose={closeDeleteRuleModal}
        accessRuleId={deleteModalAccessRuleId}
      />
    </React.Fragment>
  )
}

export default AccessRulesTable
