import React from 'react'
import { Box, Typography } from '@mui/material'

import { X509Certificate } from '@jeeves/graphql/graphql'
import { InlineCode } from '@jeeves/new-components'

interface Props {
  certificate: Pick<X509Certificate, 'expirationDate' | 'fingerprint' | 'subject' | 'issuer'>
}

const CertificateDetails = ({ certificate }: Props) => {
  const { expirationDate, fingerprint, subject, issuer } = certificate

  const certificateDetailsInfoArray = [
    {
      label: 'Expiration Date:',
      value: expirationDate,
    },
    {
      label: 'Certificate Fingerprint:',
      value: fingerprint,
    },
    {
      label: 'Subject Name:',
      value: subject,
    },
    {
      label: 'Certificate Authority:',
      value: issuer,
    },
  ]

  return (
    <Box sx={{ display: 'grid', gridTemplateColumns: 'auto 1fr', gap: 1 }}>
      {certificateDetailsInfoArray.map(info => (
        <React.Fragment key={info.value}>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {info.label}
          </Typography>
          <Box>
            <InlineCode>{info.value}</InlineCode>
          </Box>
        </React.Fragment>
      ))}
    </Box>
  )
}

export default CertificateDetails
