import React, { useState } from 'react'
import MUITypography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Collapse from '@mui/material/Collapse'
import { ExpandButton } from '@jeeves/components/Table'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { IconCopyButton, Button } from '@jeeves/new-components'
import { styled } from '@mui/material/styles'
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'

// Figure out where to place this reusable component
export const InlineCodeContainer = styled(props => <Stack disableRipple {...props} />)(
  ({ theme }) => ({
    justifyContent: 'space-between',
    backgroundColor: theme.palette.cyralColors.grey[100],
    padding: theme.spacing(0.5, 1),
    alignItems: 'center',
    borderRadius: theme.spacing(0.5),
    border: '1px solid',
    borderColor: '#F0F2F5',
  })
)
export const Typography = styled(props => <MUITypography {...props} />)(({ theme }) => ({
  color: theme.palette.text.primary,
}))

const SCIM = ({
  integration,
  bearerToken,
  regenerateTokenClicked,
  setRegenerateTokenClicked,
  expiresIn,
}) => {
  const [expanded, setExpanded] = useState(true)

  momentDurationFormatSetup(moment)
  const formatExpiresIn = expiresIn => {
    try {
      var dur = moment.duration(moment(expiresIn).diff(moment()))
      const formattedTime = dur.format('M [months and] d [days]')
      return formattedTime
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <Stack
      spacing={4}
      sx={{
        color: 'cyralColors.grey.400',
        mb: 2,
        border: '1px solid',
        borderColor: '#CBD2D9',
        borderRadius: 0.5,
        padding: 2,
      }}
    >
      <Stack direction="row" sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h4">Configure Your SCIM Integration</Typography>
        <ExpandButton expanded={expanded} onClick={() => setExpanded(!expanded)} />
      </Stack>

      <Collapse in={expanded} unmountOnExit>
        <Stack spacing={3}>
          <Typography>In your Okta admin console, configure your Cyral app as follows: </Typography>

          <Stack spacing={1}>
            <Typography>
              1. Check <strong>Enable SCIM provisioning</strong> in the <strong>General</strong> tab
              under <strong>App Settings.</strong>{' '}
            </Typography>
            <Typography>
              2. Navigate to the <strong>Provisioning</strong> tab to configure the{' '}
              <strong>SCIM Connection</strong> with these values:
            </Typography>
          </Stack>

          <Stack spacing={2}>
            <Stack spacing={1}>
              <Typography variant="h4">SCIM connector base URL</Typography>
              <InlineCodeContainer direction="row">
                <Typography variant="code">{integration?.scimBaseURL}</Typography>
                <IconCopyButton text={integration?.scimBaseURL} />
              </InlineCodeContainer>
            </Stack>

            <Stack spacing={1}>
              <Typography variant="h4">Unique Identifier field for users</Typography>
              <InlineCodeContainer direction="row">
                <Typography variant="code">userName</Typography>
                <IconCopyButton text="userName" />
              </InlineCodeContainer>
            </Stack>

            <Stack spacing={1}>
              <Typography variant="h4">Supported provisioning actions</Typography>
              <Stack direction="row" spacing={1}>
                <CheckBoxIcon sx={{ color: 'cyralColors.grey.400' }} />
                <Typography>Push New Users</Typography>
              </Stack>
              <Stack direction="row" spacing={1}>
                <CheckBoxIcon sx={{ color: 'cyralColors.grey.400' }} />
                <Typography>Push Profile Updates</Typography>
              </Stack>
              <Stack direction="row" spacing={1}>
                <CheckBoxIcon sx={{ color: 'cyralColors.grey.400' }} />
                <Typography>Push Groups</Typography>
              </Stack>
            </Stack>

            <Stack spacing={1}>
              <Typography variant="h4">Authentication Mode</Typography>
              <InlineCodeContainer direction="row">
                <Typography variant="code">HTTP Header</Typography>
              </InlineCodeContainer>
            </Stack>

            <Stack spacing={1}>
              <Typography variant="h4">Bearer Token</Typography>
              <Typography>
                {bearerToken ? (
                  <InlineCodeContainer direction="row">
                    <Typography variant="code" sx={{ overflow: 'auto', maxHeight: '16px' }}>
                      {bearerToken}
                    </Typography>

                    <IconCopyButton text={bearerToken} />
                  </InlineCodeContainer>
                ) : !integration?.scimIntegration ? (
                  <span>
                    Please click <strong>Save</strong> to generate the Bearer Token.
                  </span>
                ) : regenerateTokenClicked ? (
                  <span>
                    Please <strong>Save</strong> this integration to generate a new Bearer Token.
                    Note that this will invalidate the existing token and disrupt the SCIM
                    integration until the new Bearer Token is provided in your Okta console.
                  </span>
                ) : (
                  <Stack spacing={1} sx={{ alignItems: 'flex-start' }}>
                    <Typography>
                      For security reasons, we can only show the bearer token when it was generated.
                    </Typography>
                    <Typography>
                      This token will expire in{' '}
                      <strong>
                        {formatExpiresIn(integration?.scimIntegration?.authSecretExpiry)}
                      </strong>
                      .
                    </Typography>
                    <Button onClick={() => setRegenerateTokenClicked(true)}>
                      Regenerate Token
                    </Button>
                  </Stack>
                )}
              </Typography>
              {bearerToken && (
                <Stack spacing={1}>
                  <Typography variant="body2" sx={{ color: 'cyralColors.red.300' }}>
                    Please be sure to copy this token now because it will not be shown again.
                  </Typography>

                  <Typography variant="body2" sx={{ color: 'cyralColors.red.300' }}>
                    This token expires in <strong>{formatExpiresIn(expiresIn)}</strong>. Before
                    then, you must regenerate the token here and provide the new token in your Okta
                    console.
                  </Typography>
                </Stack>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Collapse>
    </Stack>
  )
}

export default SCIM
