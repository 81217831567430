/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { Fragment, useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { IconButton, TableRow } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'

import { TableLink, StatusIcon } from '@jeeves/components/Primitives'
import { ExpandButton, TableCell } from '@jeeves/components/Table'
import { Tag } from '@jeeves/components/Tag'

import ExpandedRow from '../ExpandedRow'
import usePolicies from '../../hooks/usePolicies'
import EditPolicyDialog from '../EditPolicy'
import classnames from 'classnames'

const styles = theme => ({
  nameRow: {
    marginRight: 20,
  },
  editButtons: {
    margin: 0,
    padding: 5,
  },
  chip: {
    marginLeft: theme.spacing.unit,
    background: theme.palette.background.default,
  },
  textField: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 13,
  },
  editing: {
    borderBottom: '1px solid black',
  },
  grow: {
    flexGrow: 1,
  },
  alternateRow: {
    background: theme.palette.row.alternate,
  },
  hidden: {
    visibility: 'hidden',
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
  active: {
    color: 'green',
  },
  pending: {
    color: 'orange',
  },
  inactive: {
    color: 'red',
  },
  status: {
    '&:first-letter': {
      textTransform: 'capitalize',
    },
  },
  expanded: {
    '& td': {
      border: 'none',
    },
  },
})
const TagList = ({ tags }) =>
  tags ? tags.sort().map(label => <Tag key={label}>{label}</Tag>) : null

const Row = ({ classes, policy, colorIndex, key, config }) => {
  const [expanded, setExpanded] = useState(false)
  const [editTagsOpen, setEditTagsOpen] = useState(false)
  const [tagsLoading, setTagsLoading] = useState(false)
  const { updatePolicyMeta } = usePolicies()

  const handleExpandRow = () => {
    setExpanded(expanded => !expanded)
  }

  const updatePolicy = async ({ description, tags, name }) => {
    setTagsLoading(true)
    try {
      await updatePolicyMeta({ id: policy.id, description, tags, name })
    } catch (e) {
    } finally {
      setTagsLoading(false)
    }
  }

  return (
    <Fragment key={key}>
      <TableRow
        className={classnames(classes.evenTableRow, {
          [classes.alternateRow]: colorIndex % 2 !== 0,
          [classes.expanded]: expanded,
        })}
        hover={!expanded}
        key={key}
        tabIndex={-1}
        css={{ cursor: 'pointer' }}
        onClick={handleExpandRow}
      >
        <TableCell preserveHeight>
          <StatusIcon enabled={policy.status === 'active'} />
        </TableCell>
        {/* <TableCell>{policy.id}</TableCell> */}
        <TableCell className={classes.nameRow} preserveHeight>
          <TableLink>{policy.name}</TableLink>
        </TableCell>
        <TableCell preserveHeight>{policy.description}</TableCell>
        {config && config.isDemo && (
          <TableCell preserveHeight numeric>
            {policy.violations || 0}
          </TableCell>
        )}

        <TableCell preserveHeight>
          <TagList tags={policy.tags} />
        </TableCell>

        <TableCell align="right" preserveHeight>
          <IconButton
            onClick={e => {
              e.stopPropagation()
              setEditTagsOpen(true)
            }}
            css={{ marginRight: '8px' }}
          >
            <EditIcon />
          </IconButton>
          <ExpandButton
            onClick={e => {
              e.stopPropagation()
              handleExpandRow()
            }}
            expanded={expanded}
          />
        </TableCell>
      </TableRow>

      <EditPolicyDialog
        policy={policy}
        open={editTagsOpen}
        onSave={updatePolicy}
        onCancel={() => setEditTagsOpen(false)}
        loading={tagsLoading}
      />

      {expanded && (
        <ExpandedRow
          handleExpandRow={handleExpandRow}
          policy={policy}
          expanded={expanded}
          colorIndex={colorIndex}
        />
      )}
    </Fragment>
  )
}

export default withStyles(styles)(Row)
