import { z } from 'zod'
import { hostPortSchema } from '@jeeves/pages/RepositoryDetail/Edit/schemas'
import { REPO_METADATA } from '@jeeves/constants'

import { baseSchema } from './shared'

// ================================== Shared ===================================

const baseStaticNodesSchema = z.object({
  connectionFormat: z.enum(['StaticNodeSpecification']),
  nodes: z.array(hostPortSchema).min(1, {
    message: 'Please enter at least 1 host:port pair.',
  }),
})

const baseSrvRecordSchema = z.object({
  connectionFormat: z.enum(['SRVRecordSpecification']),
  SRVRecord: z.string().min(1, { message: 'Please enter the SRV record.' }),
})

export const mongoDBRepoSchema = z
  .object({
    repositoryType: z.enum([REPO_METADATA.MONGODB.typeName]),
  })
  .passthrough()

export const mongoDBStandaloneRepoSchema = baseSchema.merge(
  z
    .object({
      repositoryType: z.enum([REPO_METADATA.MONGODB.typeName]),
      clusterType: z.enum(['standalone']),
    })
    .merge(hostPortSchema)
)

// ============================ MongoDB Replica Set ============================

export const mongoDBReplicaSetBaseSchema = baseSchema
  .merge(
    z.object({
      repositoryType: z.enum([REPO_METADATA.MONGODB.typeName]),
      clusterType: z.enum(['replicaSet']),
      replicaSetName: z.string().min(1, { message: 'Please enter a replica set name.' }),
      numNodes: z.number({
        required_error: 'Please enter the number of nodes.',
        invalid_type_error: 'Invalid number of nodes',
      }),
    })
  )
  .passthrough()

export const mongoDBReplicaSetStaticNodesSchema =
  mongoDBReplicaSetBaseSchema.merge(baseStaticNodesSchema)

export const mongoDBReplicaSetSrvRecordSchema =
  mongoDBReplicaSetBaseSchema.merge(baseSrvRecordSchema)

// ========================== MongoDB Sharded Cluster ==========================

export const mongoDBShardedClusterBaseSchema = baseSchema
  .merge(
    z.object({
      repositoryType: z.enum([REPO_METADATA.MONGODB.typeName]),
      clusterType: z.enum(['shardedCluster']),
      numNodes: z.number({
        required_error: 'Please enter the number of nodes.',
        invalid_type_error: 'Invalid number of nodes',
      }),
    })
  )
  .passthrough()

export const mongoDBShardedClusterStaticNodesSchema =
  mongoDBShardedClusterBaseSchema.merge(baseStaticNodesSchema)

export const mongoDBShardedClusterSrvRecordSchema =
  mongoDBShardedClusterBaseSchema.merge(baseSrvRecordSchema)
