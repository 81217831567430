import * as React from 'react'
import { styled } from '@mui/material/styles'
import MuiTooltip, { tooltipClasses } from '@mui/material/Tooltip'

const Tooltip = styled(({ className, ...props }) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: 'black',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: theme.palette.cyralColors.grey[200],
    boxShadow: theme.shadows[8],
  },
}))

export default Tooltip
