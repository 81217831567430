import { useState } from 'react'
import { useAuth } from '../../../components/Auth'
import ExpressClient from '../../../clients/express'
import usePopup from '../../../components/PopupMessage/hooks/usePopup'

import { get as lodashGet } from 'lodash'

const useAccounts = () => {
  const { setPopup, popupTypes } = usePopup()
  const { getTokenSilently } = useAuth()
  const ec = new ExpressClient(getTokenSilently)

  const [roles, setRoles] = useState([])
  const [crudCount, setCrudCount] = useState(0)
  const [editUser, setEditUser] = useState(null)
  const [editDialogIsOpen, setEditDialogIsOpen] = useState(false)
  const [addDialogIsOpen, setAddDialogIsOpen] = useState(false)
  const [permissionsDialogIsOpen, setPermissionsDialogIsOpen] = useState(false)
  const [editGroupDialogIsOpen, setEditGroupDialogIsOpen] = useState(null)

  // TABLE FOOTER/PAGINATION FUNCTIONS
  const openEdit = user => {
    // console.log('useAccounts.openEdit.user:', JSON.stringify(user))
    setEditUser(user)
    setEditDialogIsOpen(true)
  }

  const closeEdit = () => {
    // if (!loading) {
    setEditDialogIsOpen(false)
    setEditUser(null)
    // }
  }

  const deleteUser = async () => {
    // console.log('useAccounts.deleteUser.editUser:', JSON.stringify(editUser))
    try {
      await ec.request({
        method: 'delete',
        url: `/users/${editUser.id}`,
      })
      console.log('Users.deleteUser.deleteComplete.')
      closeEdit()
    } catch (error) {
      console.error('useAccounts.deleteUser.error:', JSON.stringify(error))
      const popupMessage = lodashGet(error, 'response.data.error.message', '')
      setPopup(popupTypes.ERROR, popupMessage)
    }
    // Increase crud count to force users to reload
    setCrudCount(crudCount + 1)
  }

  const openAdd = () => {
    // console.log('useAccounts.openAdd.')
    setAddDialogIsOpen(true)
  }

  const closeAdd = () => {
    // if (!loading) {
    setAddDialogIsOpen(false)
    // setAddUser(null)
    // }
  }

  const openPermissionsDialog = () => setPermissionsDialogIsOpen(true)
  const closePermissionsDialog = () => setPermissionsDialogIsOpen(false)

  const createUser = async values => {
    try {
      await ec.request({
        method: 'post',
        url: `/users`,
        data: values,
      })
      closeAdd()
    } catch (error) {
      console.error('useAccounts.createUser.error:', JSON.stringify(error))
      console.log('useAccounts.createUser.error: ', error.response.data.error.message)
      const popupMessage = lodashGet(error, 'response.data.error.message', '')
      setPopup(popupTypes.ERROR, popupMessage)
    }
    setCrudCount(crudCount + 1)
  }

  // TO DO: no, somehow combine useUsers and useAccounts, and why is getRoles getting groups?
  const getRoles = async () => {
    try {
      const groups = await ec.get('/users/groups').then(res => {
        const defaultGroups = ['User', 'Admin', 'Super Admin']
        let groups = defaultGroups.reduce(
          (prevList, curGroup) =>
            res.data.groups.find(group => group.description === curGroup)
              ? [...prevList, res.data.groups.find(group => group.description === curGroup)]
              : prevList,
          []
        )
        return [
          ...groups,
          ...res.data.groups.filter(
            group => !defaultGroups.find(description => description === group.description)
          ),
        ]
      })
      setRoles(groups)
      return groups
    } catch {
      setRoles([])
      return []
    }
  }

  return {
    openEdit,
    closeEdit,
    editDialogIsOpen,
    editUser,
    roles,
    getRoles,
    createUser,
    deleteUser,
    crudCount,
    openAdd,
    closeAdd,
    addDialogIsOpen,
    permissionsDialogIsOpen,
    openPermissionsDialog,
    closePermissionsDialog,
  }
}

export default useAccounts
