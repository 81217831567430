import React from 'react'

/**
 * Gets only the valid children of a component,
 * and ignores any nullish or falsy child.
 *
 * @param children the children
 */
export const getValidChildren = children => {
  // Removes all falsy (non-rendered) children
  const truthyChildren = React.Children.toArray(children)

  // Filters out non-element children (strings and numbers)
  return truthyChildren.filter(child => React.isValidElement(child))
}
