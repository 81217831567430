import { Link, useParams } from 'react-router-dom'
import { Stack, Typography, Link as MuiLink } from '@mui/material'

import Card from '@jeeves/pages/RepositoryDetail/Tabs/Policies/components/Card'
import Tag from '@jeeves/pages/RepositoryDetail/Tabs/Policies/components/Tag'

import { getLineClampStyles } from '@jeeves/new-components'

const PoliciesCard = ({ id, name, tags, templateName, description }) => {
  const renderTags = tags => {
    const TAGS_TO_DISPLAY = 2
    const displayedTags = tags.slice(0, TAGS_TO_DISPLAY)
    const overflowTags = tags.slice(TAGS_TO_DISPLAY)
    const overflowTagsCount = overflowTags?.length

    return (
      <>
        {displayedTags.map((tag, index) => {
          return <Tag key={index} text={tag} />
        })}

        {Boolean(overflowTagsCount) && <Tag text={`+${overflowTagsCount}`} color="grey" />}
      </>
    )
  }
  const { id: repoId } = useParams()

  return (
    <MuiLink component={Link} to={`/repositories/${repoId}/policies/${id}`} underline="none">
      <Card
        sx={{
          height: '100%',
        }}
      >
        <Stack spacing={2}>
          <Stack spacing={1}>
            <Typography variant="h6" sx={{ color: 'text.primary' }}>
              {name}
            </Typography>
            <Stack direction="row" spacing={1}>
              {renderTags(tags)}
            </Stack>
          </Stack>

          <Stack spacing={1}>
            <Typography variant="h6" sx={{ color: 'text.secondary' }}>
              {templateName}
            </Typography>
            <Typography variant="body2" sx={{ ...getLineClampStyles(2), color: 'text.secondary' }}>
              {description}
            </Typography>
          </Stack>
        </Stack>
      </Card>
    </MuiLink>
  )
}

export default PoliciesCard
