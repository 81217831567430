import { useState } from 'react'
import { DialogContent, DialogTitle, IconButton, Typography } from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'

import { Dialog, Button } from '@jeeves/new-components'

import {
  ManageAccessToken,
  GenerateAccessToken,
  DeleteAccessToken,
  EditAccessToken,
} from './components'
import useAccessTokenStructure from './useAccessTokenStructure'

const AccessToken = () => {
  const [open, setOpen] = useState(false)

  const {
    view,
    setView,
    selectedAccessTokenId,
    setSelectedAccessTokenId,
    data,
    loading,
    error,
    refetch,
    goBackToManage,
    handleDone,
  } = useAccessTokenStructure()

  const closeModal = () => {
    setOpen(false)
  }

  const openModal = () => {
    refetch()
    setOpen(true)
  }

  const views = {
    manage: {
      component: (
        <ManageAccessToken
          query={data}
          loading={loading}
          error={error}
          setView={setView}
          setSelectedAccessTokenId={setSelectedAccessTokenId}
        />
      ),
      title: 'Access Tokens',
      viewSize: 'lg',
    },
    create: {
      component: <GenerateAccessToken handleBack={handleDone} query={data!} />,
      title: 'Generate Access Token',
      viewSize: 'md',
    },
    delete: {
      component: (
        <DeleteAccessToken
          selectedAccessTokenId={selectedAccessTokenId}
          goBackToManage={goBackToManage}
          query={data!}
        />
      ),
      title: 'Delete Access Token',
      viewSize: 'md',
    },
    edit: {
      component: (
        <EditAccessToken
          selectedAccessTokenId={selectedAccessTokenId}
          goBackToManage={goBackToManage}
          query={data!}
        />
      ),
      title: 'Edit Access Token',
      viewSize: 'md',
    },
  }

  const { component, title, viewSize } = views[view]

  return (
    <>
      <Button variant="outlined" color="secondary" onClick={openModal}>
        Manage Access Tokens
      </Button>
      <Dialog
        open={open}
        onClose={closeModal}
        fullWidth
        maxWidth={viewSize}
        TransitionProps={{
          onExited: () => goBackToManage(),
        }}
      >
        <DialogTitle
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h3" sx={{ color: 'text.primary' }}>
            {title}
          </Typography>
          <IconButton aria-label="close" onClick={closeModal}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>{component}</DialogContent>
      </Dialog>
    </>
  )
}

export default AccessToken
