/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { useState } from 'react'

import { useAuth } from '@jeeves/components/Auth'
import useTeams from './hooks/useTeams'
import {
  ErrorState,
  ZeroState,
  AddIntegrationButton,
  IntegrationDialogContent,
  IntegrationLoading,
  IntegrationList,
} from '../../primitives'
import IntegrationForm from './IntegrationForm'
import logo from './images/teams.png'

const Teams = () => {
  const [adding, setAdding] = useState(false)
  const { hasPermission } = useAuth()
  const {
    integrations,
    addIntegration,
    putIntegration,
    deleteIntegration,
    loading,
    error,
  } = useTeams()

  return (
    <IntegrationDialogContent>
      <IntegrationList>
        {loading && <IntegrationLoading />}
        {!loading && error && <ErrorState />}
        {!loading && !error && integrations.length === 0 && <ZeroState />}
        {!loading &&
          !error &&
          integrations.length > 0 &&
          integrations.map(integration => (
            <IntegrationForm
              key={integration.id}
              integration={integration}
              onSave={putIntegration}
              onDelete={deleteIntegration}
            />
          ))}
      </IntegrationList>

      {adding && (
        <IntegrationForm
          integration={null}
          creating={true}
          onSave={addIntegration}
          onCancel={() => setAdding(false)}
        />
      )}

      <AddIntegrationButton
        isOpen={adding}
        setOpen={setAdding}
        disabled={!hasPermission('integrations:create') || adding}
      />
    </IntegrationDialogContent>
  )
}

Teams.metadata = {
  name: 'Microsoft Teams',
  summary: 'Send Cyral alerts to your team through Microsoft Teams messaging.',
  enabled: true,
  category: 'Alerting',
  slug: 'teams',
  logo,
}

export default Teams
