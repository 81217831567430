import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'

import { IconCopyButton } from '@jeeves/new-components'

import { StyledCodeTabsContainer, StyledTab, StyledTabs } from './ConnectionTypesTabs.style'

const ConnectionTypesTabs = ({ connectionTypes, defaultIndex = 0, onTabChange }) => {
  const [openTab, setOpenTab] = useState(connectionTypes[defaultIndex].value)

  const handleChange = (_, newOpenTab) => {
    setOpenTab(newOpenTab)

    // eslint-disable-next-line no-unused-expressions
    onTabChange?.(newOpenTab)
  }

  const currentConnectionType = connectionTypes.find(
    connectionType => connectionType.value === openTab
  )

  const copyValue = currentConnectionType?.copyValue

  return (
    <StyledCodeTabsContainer>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          paddingX: 1,
          bgcolor: 'cyralColors.grey.100',
        }}
      >
        <StyledTabs value={openTab} onChange={handleChange} aria-label="Connection types tabs">
          {connectionTypes.map(({ label, value }) => (
            <StyledTab key={value} label={label} value={value} />
          ))}
        </StyledTabs>
        {copyValue && <IconCopyButton text={copyValue} />}
      </Stack>
      <Box sx={{ overflow: 'auto' }}>
        {connectionTypes.map(({ value, content }) => (
          <TabPanel key={value} openTab={openTab} index={value}>
            {content}
          </TabPanel>
        ))}
      </Box>
    </StyledCodeTabsContainer>
  )
}

const TabPanel = ({ children, openTab, index, ...rest }) => {
  const isOpen = openTab === index

  return (
    <Box
      role="tabpanel"
      hidden={!isOpen}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...rest}
    >
      {isOpen && <Box sx={{ padding: 2 }}>{children}</Box>}
    </Box>
  )
}

export default ConnectionTypesTabs
