/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { IconButton } from '@material-ui/core'
import { MenuOpenIcon } from './MenuOpenIcon'
import MenuIcon from '@material-ui/icons/Menu'

const ToggleOpenButton = ({ navOpen, setOpen }) => {
  return (
    <IconButton
      css={t => css`
        position: absolute;
        top: 24px;
        height: 36px;
        width: 36px;
        right: -18px;
        background-color: ${t.palette.primary.main};
        z-index: 1250;
        // transform: ${navOpen ? 'initial' : 'rotate(-180deg)'};
        transition: 0.3s all;
        padding: 6px;
        &:hover {
          background-color: ${t.palette.primary.dark};
        }
      `}
      onClick={() => setOpen(oldOpen => !oldOpen)}
    >
      {navOpen ? (
        <MenuOpenIcon
          css={() => css`
            color: white;
            font-size: 18px;
          `}
        />
      ) : (
        <MenuIcon
          css={() => css`
            color: white;
            font-size: 18px;
          `}
        />
      )}
    </IconButton>
  )
}

export default ToggleOpenButton
