import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { CircularProgress, Divider, Stack, Typography } from '@mui/material'

import { graphql } from '@jeeves/graphql'

import {
  Certificates,
  SidecarInstanceDetailsHeader,
  Services,
  SidecarInstanceDetailsUnavailable,
} from './components'

const SIDECAR_INSTANCE_QUERY = graphql(`
  query SidecarInstance($sidecarId: String!, $instanceId: ID!) {
    sidecar(id: $sidecarId) {
      id
      ...SidecarInstanceDetailsHeader_SidecarFragment
      instance(id: $instanceId) {
        id
        services {
          name
        }
        ...SidecarInstanceDetailsHeader_SidecarInstanceFragment
        ...Services_SidecarInstanceFragment
        ...Certificates_SidecarInstanceFragment
      }
    }
  }
`)

const InstanceDetails = () => {
  const { id: sidecarId, instanceId } = useParams<{ id: string; instanceId: string }>()

  const { data, loading, error } = useQuery(SIDECAR_INSTANCE_QUERY, {
    variables: {
      sidecarId,
      instanceId,
    },
  })

  if (loading) {
    return (
      <Stack spacing={2} sx={{ alignItems: 'center' }}>
        <CircularProgress size={64} />
        <Typography variant="h6" sx={{ color: 'text.secondary' }}>
          Loading instance details...
        </Typography>
      </Stack>
    )
  }

  if (error) {
    return (
      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        An error occurred while fetching sidecar instance details.
      </Typography>
    )
  }

  // Sidecar instances that are >=4.10 will always contain a non-empty services array
  const sidecarInstanceDetailsUnavailable = data!.sidecar.instance.services.length === 0

  return (
    <Stack spacing={4} divider={<Divider flexItem />}>
      <SidecarInstanceDetailsHeader
        sidecarInstance={data!.sidecar.instance}
        sidecar={data!.sidecar}
      />

      {sidecarInstanceDetailsUnavailable ? (
        <SidecarInstanceDetailsUnavailable />
      ) : (
        <Stack spacing={2}>
          <Services sidecarInstance={data!.sidecar.instance} />

          <Certificates sidecarInstance={data!.sidecar.instance} />
        </Stack>
      )}
    </Stack>
  )
}

export default InstanceDetails
