import { Box, Stack, Typography } from '@mui/material'

import { ReactComponent as Terminal } from './Terminal.svg'
import { ReactComponent as PsqlCommand } from './PsqlCommand.svg'

import SetupWizardStep6Card from './SetupWizardStep6Card'

const STEP_6_URL =
  'https://www.timescale.com/blog/how-to-install-psql-on-mac-ubuntu-debian-windows/'

const Step6Link = () => {
  return (
    <Box
      component="a"
      href={STEP_6_URL}
      target="_blank"
      rel="noreferrer"
      sx={{
        cursor: 'pointer',
        textDecoration: 'none',
      }}
    >
      <Typography
        component="span"
        sx={{ color: 'primary.main', fontSize: '1.125rem', fontWeight: 400, lineHeight: '22.8px' }}
      >
        here
      </Typography>
    </Box>
  )
}

const step6CardProps = [
  {
    title: 'Terminal',
    description: (
      <Typography
        sx={{ color: 'text.primary', fontSize: '1.125rem', fontWeight: 400, lineHeight: '22.8px' }}
      >
        Connect to Cyral VPC services using your command line terminal.
      </Typography>
    ),
    image: (
      <Terminal
        style={{
          position: 'absolute',
          left: '111px',
          top: '138.14px',
          overflow: 'hidden',
          boxShadow: '0px 25px 30px 0px rgba(0, 0, 0, 0.35)',
        }}
      />
    ),
  },
  {
    title: 'psql',
    description: (
      <Typography
        variant="body2"
        sx={{ color: 'text.primary', fontSize: '1.125rem', fontWeight: 400, lineHeight: '22.8px' }}
      >
        The standard postgresql command line tool. Install it from <Step6Link />.
      </Typography>
    ),
    image: (
      <PsqlCommand
        style={{
          position: 'absolute',
          left: '69px',
          bottom: '54px',
          overflow: 'hidden',
          boxShadow: '0px 0px 1px 0px rgba(0, 0, 0, 0.32)',
        }}
      />
    ),
  },
]

const Step6 = () => {
  return (
    <Stack spacing={4} sx={{ alignItems: 'center' }}>
      <Typography variant="h2" sx={{ color: 'text.primary' }}>
        What You'll Need
      </Typography>

      <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: 'repeat(2, 1fr)' }}>
        {step6CardProps.map(props => (
          <SetupWizardStep6Card key={props.title} {...props} />
        ))}
      </Box>
    </Stack>
  )
}

export default Step6
