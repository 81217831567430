import React from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Popper from '@mui/material/Popper'

import { ServiceIcon, Tag } from '@jeeves/new-components'

export const OverflowTagsWithPopper = ({ tags, showOnlyPublic = false, containerProps = {} }) => {
  const [anchorEl, setAnchorEl] = React.useState()

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const overflowTagsPopperOpen = Boolean(anchorEl)
  const id = overflowTagsPopperOpen ? 'simple-popper' : undefined

  const publicTags = tags.filter(tag => tag.startsWith('public:'))

  // Removes the 'public:' prefix from the tags
  const publicTagsWithoutPrefix = publicTags.map(tag => tag.replace('public:', ''))

  const displayedTags = (showOnlyPublic ? publicTagsWithoutPrefix : tags).slice(0, 5)
  const overflowTags = (showOnlyPublic ? publicTagsWithoutPrefix : tags).slice(5)

  return (
      displayedTags.length > 0 && (
        <Stack
          direction="row"
          spacing={0.5}
          {...containerProps}
        >
          {displayedTags.map(tag => (
            <Tag
              key={tag}
              sx={{
                maxWidth: '120px',
              }}
            >
              {tag}
            </Tag>
          ))}
          {overflowTags.length > 0 && (
            <Tag
              color="grey"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
            >{`+ ${overflowTags.length}`}</Tag>
          )}
          <Popper id={id} open={overflowTagsPopperOpen} anchorEl={anchorEl}>
            <Box
              sx={{
                border: 1,
                borderRadius: theme => theme.radii.base,
                borderColor: 'cyralColors.grey.200',
                p: 1,
                backgroundColor: 'common.white',

                minWidth: '200px',
                maxWidth: '320px',
              }}
            >
              <Stack direction="row" sx={{ gap: 1, flexWrap: 'wrap' }}>
                {overflowTags.map(tag => (
                  <Tag key={tag}>{tag}</Tag>
                ))}
              </Stack>
            </Box>
          </Popper>
        </Stack>
      )
  )
}

const RepoHeaderContent = ({ repoType, repoName, tags, sx = [] }) => {
  return (
    <Box
      sx={[
        {
          display: 'flex',
          justifyContent: 'space-between',
          gap: 2,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Stack spacing={1.5} direction="row" sx={{ alignItems: 'center', flex: 1, minWidth: 0 }}>
        <ServiceIcon type={repoType} />
        <Typography variant="h4" sx={{ color: 'text.primary' }} noWrap>
          {repoName}
        </Typography>
      </Stack>
      <OverflowTagsWithPopper
        tags={tags}
        showOnlyPublic
        containerProps={{
          sx: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: 0.5,
            backgroundColor: 'common.white',

            border: 1,
            borderRadius: theme => theme.radii.base,
            borderColor: 'cyralColors.grey.200',
          },
        }}
      />
    </Box>
  )
}

export default RepoHeaderContent
