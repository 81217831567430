import React from 'react'

import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  container: {
    padding: theme.spacing.unit * 2,
  },
})

const NotFound = props => {
  const { classes } = props

  return (
    <div className={classes.container}>
      <Typography variant="h5" component="h3">
        Page not found!
      </Typography>
      <Typography component="p">Whoops.. It seems like this page doesn't exists</Typography>
    </div>
  )
}

export default withStyles(styles)(NotFound)
