import React from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'

import { ScreenshotLayout } from '../../../components'
import { InlineCode } from '@jeeves/new-components'
import { IconCopyButton } from '@jeeves/new-components'
import Studio3TConnectionScreen from './Studio3T.png'

const Studio3T = ({ connectionUri }) => {
  return (
    <ScreenshotLayout
      imgSrc={Studio3TConnectionScreen}
      title="Connecting with Studio 3T"
      steps={[
        'In Studio 3T, create a new connection.',
        `At the bottom of the Server tab, click on “From URI…"`,
        `Enter the following connection URI.`,
      ]}
      inlineCode={
        <Stack direction="row">
          <Box
            sx={{
              overflow: 'auto',
              padding: 1,
              border: '1px solid',
              borderColor: 'cyralColors.grey.200',
              backgroundColor: 'cyralColors.grey.100',
            }}
          >
            <InlineCode noWrap>{connectionUri}</InlineCode>
          </Box>
          <IconCopyButton text={connectionUri} />
        </Stack>
      }
    />
  )
}

export default Studio3T
