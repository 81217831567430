import * as React from 'react'
import { useMutation, gql } from '@apollo/client'
import Typography from '@mui/material/Typography'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { Button, Dialog, DialogActions } from '@jeeves/new-components'

import { ACCESS_RULE_FIELDS } from '@jeeves/pages/RepositoryDetail/Tabs/UserAuthentication/graphql/fragments'
import { useRepositoryDetailContext } from '@jeeves/pages/RepositoryDetail/contexts/RepositoryDetailContext'
import { useUserAuthenticationContext } from '@jeeves/pages/RepositoryDetail/Tabs/UserAuthentication/contexts/UserAuthenticationContext'

const DELETE_ACCESS_RULE = gql`
  mutation DeleteAccessRule($repoId: ID!, $userAccountId: ID!, $accessRuleId: ID!) {
    deleteAccessRule(repoId: $repoId, userAccountId: $userAccountId, accessRuleId: $accessRuleId) {
      userAccount {
        id
        accessRules {
          ...accessRuleFields
        }
      }
    }
  }
  ${ACCESS_RULE_FIELDS}
`

const DeleteAccessRuleModal = ({ open, onClose, accessRuleId }) => {
  const { repoId } = useRepositoryDetailContext()
  const { selectedUserAccountId } = useUserAuthenticationContext()

  const [deleteAccessRule, { data, loading, error }] = useMutation(DELETE_ACCESS_RULE, {
    variables: {
      repoId,
      userAccountId: selectedUserAccountId,
      accessRuleId,
    },
    update(cache) {
      cache.gc()
    },
    onCompleted: _ => onClose(),
  })

  const onDelete = async () => {
    try {
      await deleteAccessRule()
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle variant="h3">Delete Access Rule</DialogTitle>

      <DialogContent>
        <Typography variant="body1" sx={{ color: 'text.secondary' }}>
          Are you sure you want to delete this access rule?
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="outlined" color="error" onClick={onDelete} loading={loading}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteAccessRuleModal
