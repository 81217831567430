import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'

import Users from './components/Users'
import Documents from './components/Documents'
import Information from './components/Information'

const styles = theme => ({
  section: {
    marginBottom: theme.spacing.unit * 4,
  },
})

const Billing = ({ classes }) => {
  return (
    <Fragment>
      <Grid container spacing={24}>
        <Grid item xs={8}>
          {/* Users */}
          <Users className={classes.section} />

          {/* Documents */}
          <Documents className={classes.section} />
        </Grid>

        <Grid item xs={4}>
          {/* Package Information and Payments */}
          <Information className={classes.section} />
        </Grid>
      </Grid>
    </Fragment>
  )
}

export default withStyles(styles)(Billing)
