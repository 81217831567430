import * as React from 'react'
import { useMutation, gql } from '@apollo/client'
import { useForm } from 'react-hook-form'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'

import { Input, InputLabel, Button, Dialog, DialogActions } from '@jeeves/new-components'
import { useRepositoryDetailContext } from '@jeeves/pages/RepositoryDetail/contexts/RepositoryDetailContext'

import { APPROVAL_FIELDS } from '../useApprovalDetailQuery'

const REVOKE_APPROVAL = gql`
  mutation RevokeApproval($repoId: ID!, $approvalId: ID!, $comment: String) {
    revokeApproval(repoId: $repoId, approvalId: $approvalId, comment: $comment) {
      revokedApproval {
        ...approvalFields
      }
    }
  }
  ${APPROVAL_FIELDS}
`

const RevokeDialog = ({ open, closeDialog, ssoUser, approvalId }) => {
  const { repoId } = useRepositoryDetailContext()
  const [revokeApproval, { data, loading, error }] = useMutation(REVOKE_APPROVAL, {
    onCompleted(data) {
      closeDialog()
    },
    update(cache, { data: { revokeApproval } }) {
      // Removes GrantedApproval from cache (since it's now being replaced by a RevokedApproval).
      // This will essentially trigger a refetch of the query in 'useApprovalDetailQuery' because
      // it won't be able to find the GrantedApproval that it references in the cache after it's evicted.
      const cacheId = cache.identify({
        id: revokeApproval?.revokedApproval.id,
        __typename: 'GrantedApproval',
      })
      cache.evict({ id: cacheId })
      cache.gc()
    },
  })
  const {
    reset,
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      reason: '',
    },
  })

  const handleDialogClose = () => {
    reset()
    closeDialog()
  }

  const onSubmit = async ({ reason }) => {
    try {
      await revokeApproval({
        variables: {
          repoId,
          approvalId,
          comment: reason,
        },
      })
    } catch (error) {}
  }

  return (
    <Dialog open={open} onClose={handleDialogClose}>
      <DialogTitle variant="h3">Revoke Approval</DialogTitle>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Stack spacing={2}>
            <Typography variant="body1" sx={{ color: 'text.secondary' }}>
              Are you sure you want to revoke the access approval for{' '}
              <Typography component="span" variant="h6">
                {ssoUser}
              </Typography>
              ?
            </Typography>

            <FormControl variant="standard">
              <InputLabel>Reason</InputLabel>
              <Input
                id="description-input"
                inputProps={{
                  ...register('reason'),
                }}
                multiline
                rows={5}
                placeholder="e.g. User no longer needs access."
              />
            </FormControl>
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button variant="text" onClick={handleDialogClose}>
            Cancel
          </Button>
          <Button type="submit" variant="outlined" color="error" loading={isSubmitting}>
            Revoke
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default RevokeDialog
