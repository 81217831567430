import React, { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Box, Stack, Typography, FormControlLabel, FormControl, Switch } from '@mui/material'
import { Select, InputLabel, Button } from '@jeeves/new-components'
import { LearnMore } from './LearnMore'

import { gql, useMutation } from '@apollo/client'

import { getAccessGatewaySidecarEdges } from '@jeeves/pages/RepositoryDetail/helpers/graphql'

const UPDATE_ACCESS_PORTAL = gql`
  mutation UpdateRepoAccessPortalBinding($repoId: ID!, $sidecarBinding: SidecarBindingInput) {
    updateRepoAccessPortalBinding(repoId: $repoId, sidecarBinding: $sidecarBinding) {
      repo {
        id
        config {
          accessPortal {
            accessPortalBindingRelationship {
              edge {
                node {
                  id
                }
                sidecar {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`

export const AccessPortalConfig_repoFragment = gql`
  fragment AccessPortalConfig_repo on Repo {
    id
    boundSidecarsRelationship {
      edges {
        bindings {
          id
          enabled
          ... on SingleListenerBinding {
            listener {
              id
              port
            }
          }
          ... on S3Binding {
            listenerSet {
              proxyListener {
                id
                port
              }
              browserListener {
                id
                port
              }
            }
          }
          ... on ClusterBinding {
            boundListenersRelationship {
              edges {
                node {
                  id
                  port
                }
              }
            }
          }
        }
        node {
          id
          name
          passthroughConfiguration
          endpoint
          userEndpoint
        }
      }
    }
  }
`

export const AccessPortal = ({ accessPortalData, repo }) => {
  const [selectedAccessPortalSidecar, setSelectedAccessPortalSidecar] = useState()
  const [selectedAccessPortalSidecarPortOptions, setSelectedAccessPortalSidecarPortOptions] =
    useState([])
  const [selectedSidecarHasMultipleBindings, setSelectedSidecarHasMultipleBindings] = useState()
  const accessPortalEdge = accessPortalData?.accessPortalBindingRelationship?.edge
  const accessPortalSidecarId = accessPortalEdge?.sidecar?.id
  const accessPortalBindingId = accessPortalEdge?.node?.id
  const hasMultiplePorts = selectedAccessPortalSidecarPortOptions.some(
    node => node.ports.length > 1
  )
  const accessGatewaySidecarEdges = getAccessGatewaySidecarEdges(repo)
  const {
    control,
    formState: { isDirty, isSubmitting, isSubmitSuccessful },
    handleSubmit,
    getValues,
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      allowAccessPortal: Boolean(accessPortalSidecarId),
      accessPortalSidecar: accessPortalSidecarId ?? null,
      accessPortalBinding: accessPortalBindingId ?? null,
    },
  })

  const [updateAccessPortal, { data, loading, error }] = useMutation(UPDATE_ACCESS_PORTAL)

  const handleCancel = () => {
    reset()
  }

  const onSubmit = async ({ accessPortalSidecar, accessPortalBinding, allowAccessPortal }) => {
    try {
      await updateAccessPortal({
        variables: {
          repoId: repo.id,
          sidecarBinding: allowAccessPortal
            ? {
                sidecarId: accessPortalSidecar,
                bindingId: accessPortalBinding,
              }
            : null,
        },
      })
    } catch (e) {
      console.error(e)
    }
  }

  const selectedAllowAccessPortal = watch('allowAccessPortal')
  const selectedAccessPortalSidecarId = watch('accessPortalSidecar')
  const selectedAccessPortalBindingId = watch('accessPortalBinding')

  const getSelectedSidecarHasMultipleBindings = sidecarId => {
    const foundSidecarEdge = accessGatewaySidecarEdges.find(edge => edge.node.id === sidecarId)
    if (foundSidecarEdge) {
      return foundSidecarEdge.bindings.filter(binding => binding.enabled).length > 0
    } else {
      throw Error('Sidecar not found')
    }
  }

  useEffect(() => {
    if (selectedAccessPortalSidecarId) {
      const foundSidecarEdge = accessGatewaySidecarEdges.find(
        edge => edge.node.id === selectedAccessPortalSidecarId
      )
      if (foundSidecarEdge) {
        setSelectedAccessPortalSidecar(foundSidecarEdge.node)
        const enabledBindings = foundSidecarEdge.bindings.filter(binding => binding.enabled)
        setSelectedAccessPortalSidecarPortOptions(
          enabledBindings.map(binding =>
            'listener' in binding
              ? { id: binding.id, ports: [binding.listener.port] }
              : 'listenerSet' in binding
              ? {
                  id: binding.id,
                  ports: [binding.listenerSet.proxyListener.port],
                }
              : {
                  id: binding.id,
                  ports: binding.boundListenersRelationship.edges.map(edge => edge.node.port),
                }
          )
        )
        const selectedSidecarHasMultipleBindings = enabledBindings.length > 1
        setSelectedSidecarHasMultipleBindings(selectedSidecarHasMultipleBindings)
        if (!selectedSidecarHasMultipleBindings) {
          setValue('accessPortalBinding', enabledBindings[0].id)
        }
      }
    }
  }, [selectedAccessPortalSidecarId])

  React.useEffect(() => {
    if (isSubmitSuccessful) {
      reset(getValues())
    }
  }, [isSubmitSuccessful, reset, getValues])
  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack sx={{ justifyContent: 'space-between' }}>
        <Stack spacing={2} sx={{ pb: 7 }}>
          <Box>
            <Controller
              name="allowAccessPortal"
              control={control}
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <FormControlLabel
                  control={<Switch name={name} onChange={onChange} checked={value} />}
                  label={
                    <Typography variant="h4" sx={{ color: 'text.primary' }}>
                      Allow users to see this repository in the Access Portal.{' '}
                      <LearnMore docsPath="/manage-repositories/repo-show" />
                    </Typography>
                  }
                />
              )}
            />
          </Box>
          {selectedAllowAccessPortal && (
            <Box sx={{ ml: 8 }}>
              <FormControl variant="standard" sx={{ display: 'flex', ml: 6 }}>
                <InputLabel
                  id="data-repository-select-label"
                  htmlFor="data-repository-select"
                  sx={{
                    typography: 'h4',
                  }}
                >
                  Select the sidecar that users will connect through
                </InputLabel>
                <Controller
                  name="accessPortalSidecar"
                  control={control}
                  render={({ field: { onChange, ...restFields } }) => (
                    <Select
                      disabled={!selectedAllowAccessPortal}
                      labelId="sidecar-select-label"
                      id="sidecar-select"
                      options={accessGatewaySidecarEdges.map(sidecarEdge => {
                        return {
                          label: sidecarEdge.node.name,
                          value: sidecarEdge.node.id,
                        }
                      })}
                      onChange={e => {
                        if (getSelectedSidecarHasMultipleBindings(e.target.value)) {
                          setValue('accessPortalBinding', null)
                        }
                        onChange(e.target.value)
                      }}
                      {...restFields}
                    />
                  )}
                  rules={{
                    required: true,
                  }}
                />
              </FormControl>
            </Box>
          )}
          {selectedAllowAccessPortal &&
            selectedAccessPortalSidecar &&
            selectedSidecarHasMultipleBindings && (
              <Box sx={{ ml: 8 }}>
                <FormControl variant="standard" sx={{ display: 'flex', ml: 6 }}>
                  <InputLabel
                    id="data-repository-select-label"
                    htmlFor="data-repository-select"
                    sx={{
                      typography: 'h4',
                    }}
                  >
                    {`Select the ${
                      hasMultiplePorts ? 'ports' : 'port'
                    } that users will connect through`}
                  </InputLabel>
                  <Controller
                    name="accessPortalBinding"
                    control={control}
                    render={({ field }) => (
                      <Select
                        disabled={!selectedAllowAccessPortal || !selectedAccessPortalSidecar}
                        labelId="binding-select-label"
                        id="binding-select"
                        options={selectedAccessPortalSidecarPortOptions.map(portOption => {
                          return {
                            label: portOption.ports.join(', '),
                            value: portOption.id,
                          }
                        })}
                        {...field}
                      />
                    )}
                    rules={{
                      required: true,
                    }}
                  />
                </FormControl>
              </Box>
            )}
        </Stack>
        <Stack direction="row" spacing={2} sx={{ justifyContent: 'flex-end' }}>
          <Button variant="text" onClick={handleCancel} disabled={!isDirty}>
            Cancel
          </Button>
          <Button
            variant="contained"
            type="submit"
            loading={isSubmitting}
            disabled={
              !isDirty ||
              (selectedAllowAccessPortal &&
                (!selectedAccessPortalSidecarId ||
                  (selectedSidecarHasMultipleBindings && !selectedAccessPortalBindingId)))
            }
          >
            Save
          </Button>
        </Stack>
      </Stack>
    </Box>
  )
}
