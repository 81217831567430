import { useForm, FormProvider } from 'react-hook-form'
import { z } from 'zod'
import { Stack } from '@mui/material'
import { useMutation } from '@apollo/client'
import { zodResolver } from '@hookform/resolvers/zod'

import { FragmentType, graphql, useFragment } from '@jeeves/graphql'
import { getGraphQLErrorMessage } from '@jeeves/utils/helpers'
import { useToast } from '@jeeves/new-components'
import { useRepositoryDetailContext } from '@jeeves/pages/RepositoryDetail/contexts/RepositoryDetailContext'

import AllowPassthroughAuthentication from './AllowPassthroughAuthentication'
import EnableBrokeredAuthenticationDefault from './EnableBrokeredAuthenticationDefault'
import FormFooter from './FormFooter'
import RepoInaccessibleAlert from './RepoInaccessibleAlert'

const DefaultAuthentication_StandardRepoAuthenticationConfigFragment = graphql(`
  fragment DefaultAuthentication_StandardRepoAuthenticationConfigFragment on StandardRepoAuthenticationConfig {
    allowNativeAuthentication
    cyralBrokeredAuthentication
  }
`)

const UPDATE_REPO_AUTHENTICATION_CONFIG = graphql(`
  mutation UpdateRepoAuthenticationConfigDefault(
    $repoId: ID!
    $input: UpdateRepoAuthenticationConfigInput!
  ) {
    updateRepoAuthenticationConfig(repoId: $repoId, input: $input) {
      repo {
        id
        config {
          authentication {
            ... on StandardRepoAuthenticationConfig {
              cyralBrokeredAuthentication
              allowNativeAuthentication
            }
          }
        }
      }
    }
  }
`)

interface Props {
  standardRepoAuthenticationConfig: FragmentType<
    typeof DefaultAuthentication_StandardRepoAuthenticationConfigFragment
  >
}

const defaultAuthenticationSchema = z.object({
  cyralBrokeredAuthentication: z.boolean(),
  allowNativeAuthentication: z.boolean(),
})

type DefaultAuthenticationSchema = z.infer<typeof defaultAuthenticationSchema>

const DefaultAuthentication = ({
  standardRepoAuthenticationConfig: standardRepoAuthenticationConfigProp,
}: Props) => {
  const { toast } = useToast()
  const { repoId } = useRepositoryDetailContext()
  const { allowNativeAuthentication, cyralBrokeredAuthentication } = useFragment(
    DefaultAuthentication_StandardRepoAuthenticationConfigFragment,
    standardRepoAuthenticationConfigProp
  )

  const methods = useForm<DefaultAuthenticationSchema>({
    defaultValues: {
      cyralBrokeredAuthentication,
      allowNativeAuthentication,
    },
    resolver: zodResolver(defaultAuthenticationSchema),
  })

  const { handleSubmit, reset, getValues, watch } = methods

  const [updateRepoAuthenticationConfig, { loading }] = useMutation(
    UPDATE_REPO_AUTHENTICATION_CONFIG,
    {
      onCompleted: () => {
        toast({
          variant: 'success',
          description: 'Authentication preferences updated.',
        })
        reset(getValues())
      },
      onError: error => {
        toast({
          variant: 'error',
          description:
            getGraphQLErrorMessage(error) ||
            'An error occurred while updating preferences. Please try again later.',
        })
      },
    }
  )

  const onSubmit = async ({
    allowNativeAuthentication,
    cyralBrokeredAuthentication,
  }: DefaultAuthenticationSchema) => {
    await updateRepoAuthenticationConfig({
      variables: {
        repoId: repoId,
        input: {
          allowNativeAuthentication,
          cyralBrokeredAuthenticationConfig: {
            cyralBrokeredAuthentication,
          },
        },
      },
    })
  }

  const {
    cyralBrokeredAuthentication: cyralBrokeredAuthenticationFormValue,
    allowNativeAuthentication: allowNativeAuthenticationFormValue,
  } = watch()

  const shouldRenderRepoInaccessibleAlert =
    !cyralBrokeredAuthenticationFormValue && !allowNativeAuthenticationFormValue

  return (
    <FormProvider {...methods}>
      <Stack spacing={2} component="form" onSubmit={handleSubmit(onSubmit)}>
        {shouldRenderRepoInaccessibleAlert && <RepoInaccessibleAlert />}
        <Stack spacing={2}>
          <EnableBrokeredAuthenticationDefault />
          <AllowPassthroughAuthentication />
        </Stack>
        <FormFooter saveLoading={loading} />
      </Stack>
    </FormProvider>
  )
}

export default DefaultAuthentication
