import React from 'react'
import { TextField } from '@material-ui/core'
import FormTitle from '../FormTitle'

const AddRoleForm = ({ setGroupNameInput, groupNameInput }) => {
  return (
    <FormTitle title="Role Name" subtitle="Cannot be changed later">
      <TextField
        variant="outlined"
        value={groupNameInput}
        onChange={e => setGroupNameInput(e.target.value)}
        label="Role name"
      />
    </FormTitle>
  )
}

export default AddRoleForm
