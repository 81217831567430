import { SidecarInstance } from '@jeeves/graphql/graphql'

interface InstanceCanUpgradeProps {
  instance: Pick<SidecarInstance, 'recyclable' | 'versionIsDynamic' | 'version' | 'isRecycling'>
  sidecarVersion: string
}

export const instanceCanUpgrade = ({ instance, sidecarVersion }: InstanceCanUpgradeProps) => {
  const instanceIsRecyclable = instance.recyclable
  const instanceIsRecycling = instance.isRecycling
  const instanceVersionIsDynamic = instance.versionIsDynamic
  const instanceVersionIsNotSidecarVersion = instance.version !== sidecarVersion

  if (instanceIsRecycling) {
    return {
      canUpgrade: false,
      reason: 'Instance is being upgraded',
    }
  }

  if (!instanceVersionIsNotSidecarVersion) {
    return {
      canUpgrade: false,
      reason: 'Instance is up to date',
    }
  }

  if (!instanceVersionIsDynamic) {
    return {
      canUpgrade: false,
      reason: 'Version set via deployment',
    }
  }

  if (!instanceIsRecyclable) {
    return {
      canUpgrade: false,
      reason: 'Feature not supported',
    }
  }

  return {
    canUpgrade: true,
    reason: null,
  }
}
