import * as React from 'react'
import { parseAbsoluteToLocal } from '@internationalized/date'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker'
import { PickersModalDialog } from '@mui/x-date-pickers/internals/components/PickersModalDialog'
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker'

import { DialogActions, Input, TimeField } from '@jeeves/new-components'

// Implementation heavily inspired by https://stackoverflow.com/questions/69584676/open-dialog-instead-of-popper-in-desktop-mui-5-datepicker

const DatePicker = ({ onChange, value: valueProp, selectTime = false, sx = [], error = false }) => {
  const [value, setValue] = React.useState(valueProp || new Date().toISOString())
  const [tempValue, setTempValue] = React.useState(value)
  const [open, setOpen] = React.useState(false)

  const openCalendar = () => setOpen(true)
  const closeCalendar = () => setOpen(false)

  const handleTimeChange = value => {
    const ISOValue = value.toAbsoluteString()

    setTempValue(ISOValue)
  }

  const handleAccept = () => {
    setValue(tempValue)
    onChange?.(tempValue)
    closeCalendar()
  }

  React.useEffect(() => {
    const newValue = valueProp || new Date().toISOString()
    setValue(newValue)
    setTempValue(newValue)
  }, [valueProp])

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      {/* Shows Input  */}
      {/* <MobileDatePicker */}
      <MobileDateTimePicker
        open={false} // Disables the dialog picker that comes built-in with 'MobileDatePicker' because we're managing/rendering the dialog ourselves
        label="For mobile"
        value={value}
        onOpen={openCalendar}
        renderInput={params => {
          //   console.log('params: ', params)
          return <Input {...params} sx={sx} error={error} />
        }}
      />

      <PickersModalDialog
        open={open}
        onAccept={handleAccept}
        onDismiss={closeCalendar}
        onClear={closeCalendar}
        onCancel={() => {
          setTempValue(value)
          closeCalendar()
        }}
      >
        <StaticDatePicker
          displayStaticWrapperAs="desktop"
          value={tempValue}
          onChange={newDate => setTempValue(newDate.toISOString())}
          renderInput={params => <Input {...params} />}
          components={{
            ...(selectTime && {
              ActionBar: TimeSelector,
            }),
          }}
          componentsProps={{
            ...(selectTime && {
              actionBar: {
                value: parseAbsoluteToLocal(tempValue),
                onChange: handleTimeChange,
              },
            }),
          }}
        />
      </PickersModalDialog>
    </LocalizationProvider>
  )
}

const TimeSelector = ({ value, onChange }) => {
  return (
    <DialogActions
      sx={{
        px: 4,
        justifyContent: 'center',
      }}
    >
      <TimeField value={value} onChange={onChange} />
    </DialogActions>
  )
}

export default DatePicker
