import React, { Component } from 'react'

let counter = 0
function createData(date, period, description, amount, pdf) {
  counter += 1
  return { id: counter, date, period, description, amount, pdf }
}

const columns = [
  { id: 'date', label: 'Invoice Date' },
  { id: 'perioud', label: 'Invoice Period' },
  { id: 'description', label: 'Description' },
  { id: 'amount', label: 'Amount' },
  { id: 'pdf', label: 'PDF', numeric: true },
]

const rows = [
  createData('03/29/2019', 'March 2019', 'Cyral Service Fee', '$2,000', 'View Invoice'),
  createData('02/28/2019', 'February 2019', 'Cyral Service Fee', '$2,000', 'View Invoice'),
  createData('01/31/2019', 'January 2019', 'Cyral Service Fee', '$2,000', 'View Invoice'),
  createData('12/31/2018', 'December 2018', 'Cyral Service Fee', '$2,000', 'View Invoice'),
  createData('11/30/2018', 'November 2018', 'Cyral Service Fee', '$2,000', 'View Invoice'),
  createData('03/29/2019', 'March 2019', 'Cyral Service Fee', '$2,000', 'View Invoice'),
  createData('02/28/2019', 'February 2019', 'Cyral Service Fee', '$2,000', 'View Invoice'),
  createData('01/31/2019', 'January 2019', 'Cyral Service Fee', '$2,000', 'View Invoice'),
  createData('12/31/2018', 'December 2018', 'Cyral Service Fee', '$2,000', 'View Invoice'),
  createData('11/30/2018', 'November 2018', 'Cyral Service Fee', '$2,000', 'View Invoice'),
  createData('03/29/2019', 'March 2019', 'Cyral Service Fee', '$2,000', 'View Invoice'),
  createData('02/28/2019', 'February 2019', 'Cyral Service Fee', '$2,000', 'View Invoice'),
  createData('01/31/2019', 'January 2019', 'Cyral Service Fee', '$2,000', 'View Invoice'),
]

const WithFakeData = WrappedComponent => {
  return class extends Component {
    render() {
      return <WrappedComponent {...this.props} columns={columns} rows={rows} />
    }
  }
}

export default WithFakeData
