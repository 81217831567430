import { useCallback, useContext, useState, useEffect } from 'react'
import { useMutation, gql } from '@apollo/client'
import ExpressClient from '@jeeves/clients/express'
import { useAuth } from '@jeeves/components/Auth'
import usePopup from '@jeeves/components/PopupMessage/hooks/usePopup'

import { useAppConfig } from '@jeeves/hooks'

import { ReposContext } from '../contexts/ReposContext'
import { get as lodashGet } from 'lodash'

import { REPO_TYPES_META } from '../constants/repoTypes'
import useReposWithUtilities from './useReposWithUtilities'

const CREATE_REPO = gql`
  mutation CreateRepo($input: CreateRepoInput!) {
    createRepo(input: $input) {
      repo {
        id
      }
    }
  }
`

const useRepos = () => {
  const [state, setState] = useContext(ReposContext)
  const { loading, error, data, refetch } = useReposWithUtilities({ variables: { first: 1, filters: { repoType: 's3' } } })
  const { repos } = state
  const [s3Exists, setS3Exists] = useState(null)
  const [enabledRepos, setEnabledRepos] = useState([])
  const {
    config: { isDemo },
  } = useAppConfig()

  const [createRepo, createRepoResponse] = useMutation(CREATE_REPO)

  const { setPopup, popupTypes } = usePopup()

  const { getTokenSilently, user } = useAuth()
  const ec = new ExpressClient(getTokenSilently)

  const errorMap = {
    'unable to add repository since license is expired':
      'Sorry, your license has expired. Please renew it to add a data repository.',
    'unable to update repository since license is expired':
      'Sorry, your license has expired. Please renew it to edit a data repository.',
  }

  const handleOnToggle = async (repoId, repoStatus) => {
    try {
      await ec.request({
        method: 'PATCH',
        url: `/repos/${repoId}`,
        data: { toggle: repoStatus },
      })
      // updateRepos()
    } catch (e) {
      console.log('handleOnToggle error: ', e.response.data.error.message)
      const popupMessage = lodashGet(e, 'response.data.error.message', '')
      setPopup(popupTypes.ERROR, popupMessage)
    }
  }

  // TODO: pass this into the delete component instead of specifying handler in component itself conditionally
  // const handleOnDelete = async repoId => {
  //   try {
  //     await ec.request({
  //       method: 'DELETE',
  //       url: `/repo-api/repos/${repoId}`,
  //     })
  //     setPopup(popupTypes.SUCCESS)
  //   } catch (e) {
  //     const popupMessage = lodashGet(e, 'response.data.e', '')
  //     setPopup(popupTypes.ERROR, popupMessage)
  //   }
  // }

  const handleOnCreate = async input => {
    try {
      const submittedRepoId = (
        await createRepo({
          variables: { input },
        })
      ).data?.createRepo?.repo?.id
      await ec.patch(`/repos/${submittedRepoId}/conf/analysis`, {
        logGroups: ['everything'],
      })
      return submittedRepoId
    } catch (e) {
      const expiredError = 'unable to add repository since license is expired'
      let popupMessage = lodashGet(e, 'response.data.error.message', '')

      if (popupMessage.includes(expiredError)) {
        popupMessage = errorMap[expiredError]
      }
      setPopup(popupTypes.ERROR, popupMessage)
      throw e
    }
  }

  const handleOnUpdate = async (input, repoName) => {
    try {
      const submittedRepo = await ec.put(`/repos/${repoName}`, input).then(res => res.data)
      // updateRepos()
      return submittedRepo
    } catch (e) {
      const expiredError = 'unable to update repository since license is expired'
      let popupMessage = lodashGet(e, 'response.data.error.message', '')

      if (popupMessage.includes(expiredError)) {
        popupMessage = errorMap[expiredError]
      }
      setPopup(popupTypes.ERROR, popupMessage)
    }
  }

  const getRepoTypes = () => {
    return Object.keys(REPO_TYPES_META)
  }

  const getDisplayName = repoType => {
    if (!REPO_TYPES_META[repoType]) return repoType
    return REPO_TYPES_META[repoType].displayName || repoType
  }

  //TO DO: make this better

  const defaultPort = useCallback(repoType => {
    return REPO_TYPES_META?.[repoType]?.port?.default
  }, [])

  const defaultPortLocked = useCallback(repoType => {
    if (!REPO_TYPES_META[repoType]) return null
    return REPO_TYPES_META[repoType].port ? REPO_TYPES_META[repoType].port.locked || false : false
  }, [])

  const defaultHostName = useCallback(repoType => {
    return REPO_TYPES_META?.[repoType]?.hostName?.default
  }, [])

  const defaultHostNameLocked = useCallback(repoType => {
    if (!REPO_TYPES_META[repoType]) return null
    return REPO_TYPES_META[repoType].hostName
      ? REPO_TYPES_META[repoType].hostName.locked || false
      : false
  }, [])

  const defaultTLS = useCallback(repoType => {
    if (!REPO_TYPES_META[repoType]) return null
    return REPO_TYPES_META[repoType].tls ? REPO_TYPES_META[repoType].tls.default : null
  }, [])

  const defaultTLSLocked = useCallback(repoType => {
    if (!REPO_TYPES_META[repoType]) return null
    return REPO_TYPES_META[repoType].tls ? REPO_TYPES_META[repoType].tls.locked || false : false
  }, [])

  const defaultRepoName = useCallback(repoType => {
    return REPO_TYPES_META?.[repoType]?.repoName?.default
  }, [])

  const defaultRepoNameLocked = useCallback(repoType => {
    if (!REPO_TYPES_META[repoType]) return null
    return REPO_TYPES_META[repoType].repoName
      ? REPO_TYPES_META[repoType].repoName.locked || false
      : false
  }, [])

  const defaultMaxAllowedListeners = useCallback(repoType => {
    return REPO_TYPES_META?.[repoType]?.maxAllowedListeners?.default
  }, [])

  const defaultMaxAllowedListenersLocked = useCallback(repoType => {
    if (!REPO_TYPES_META[repoType]) return null
    return REPO_TYPES_META[repoType].maxAllowedListeners
      ? REPO_TYPES_META[repoType].maxAllowedListeners.locked || false
      : false
  }, [])

  const isRepoTypeEnabledWithReason = repoType => {
    // TO DO: improve this code
    if (repoType === 'bigquery' || repoType === 'rest') {
      return { hide: true }
    }

    if (!enabledRepos.includes(repoType)) {
      return { enabled: false, reason: 'Contact support to enable this repository.' }
    }
    if (repoType === 's3') {
      return {
        enabled: s3Exists === null ? null : !s3Exists,
        reason: s3Exists
          ? 'An S3 repository has already been added. No more than one S3 repository is allowed.'
          : '',
      }
    } else {
      return { enabled: true, reason: '' }
    }
  }

  const patchConfAnalysis = async (repoId, data) => {
    try {
      await ec.request({
        method: 'PATCH',
        url: `/repos/${repoId}/conf/analysis`,
        data,
      })
    } catch (e) {
      console.log('patchConfAnalysis error: ', e.response.data.error.message)
      const popupMessage = lodashGet(e, 'response.data.error.message', '')
      setPopup(popupTypes.ERROR, popupMessage)
    }
  }

  const fetchLicense = async () => {
    try {
      const license = await ec.get('/entitlements/licenses').then(res => res.data)
      const repos = license.features.filter(
        feature => feature.internalName === 'AllowedRepositories'
      )
      const enabledRepoTypes = repos.map(r => r.internalSubFeatureName)

      setEnabledRepos(enabledRepoTypes)
      return license
    } catch (e) {
      setEnabledRepos(Object.keys(REPO_TYPES_META))
      console.log('plans.fetchLicense error: ', e)
    }
  }

  useEffect(() => {
    if (data?.repoEdges) setS3Exists(data?.repoEdges?.find(edge => edge.node.type === 's3'))
  }, [data])

  useEffect(() => {
    // TO DO: hook should not being doing this, context should, figure it out
    // updateRepos()
    // fetchLicense()
  }, [])

  return {
    handleOnToggle,
    handleOnCreate,
    handleOnUpdate,
    repos,
    getRepoTypes,
    getDisplayName,
    defaultPort,
    defaultTLS,
    defaultHostName,
    defaultRepoName,
    defaultPortLocked,
    defaultTLSLocked,
    defaultHostNameLocked,
    defaultRepoNameLocked,
    defaultMaxAllowedListeners,
    defaultMaxAllowedListenersLocked,
    patchConfAnalysis,
    ec,
    user,
    isRepoTypeEnabledWithReason,
    fetchLicense,
  }
}

export default useRepos
