import { useState } from 'react'
import { Box, FormControl, Stack } from '@mui/material'
import { Controller } from 'react-hook-form'

import { Button, Input, InputLabel, Select } from '@jeeves/new-components'
import Tag from '@jeeves/pages/RepositoryDetail/Tabs/Policies/components/Tag'
import { policyIdentities } from '@jeeves/pages/RepositoryDetail/Tabs/Policies/helpers/policyIdentities'

const InputTagsIdentity = ({
  inputLabel,
  tags,
  isSubmitting,
  required = false,
  control,
  onAdd,
  onDelete,
}) => {
  const [currentTag, setCurrentTag] = useState({
    __typename: 'PolicyUsernameIdentity',
    name: '',
  })
  const shouldDisableAddButton = isSubmitting || currentTag?.name?.length === 0
  const identitiesList = [
    {
      label: 'SSO user (username)',
      value: 'PolicyUsernameIdentity',
    },
    {
      label: 'Email',
      value: 'PolicyEmailIdentity',
    },
    {
      label: 'Group',
      value: 'PolicyGroupIdentity',
    },
  ]

  const handleTagDelete = tag => {
    const updatedTags = tags.filter(t => t !== tag)
    onDelete(updatedTags)
  }

  const handleAddTag = () => {
    const updatedTags = [...tags, currentTag]

    setCurrentTag({
      __typename: 'PolicyUsernameIdentity',
      name: '',
    })
    onAdd(updatedTags)
  }

  return (
    <Stack spacing={1}>
      {inputLabel && (
        <InputLabel required={required} htmlFor="tag-input">
          {inputLabel}
        </InputLabel>
      )}

      <Box
        sx={{
          border: 1,
          borderColor: 'cyralColors.grey.200',
          borderRadius: theme => theme.radii.base,
          height: '72px',
          padding: 1,
          overflowY: 'auto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 1,
          }}
        >
          {tags?.map((tag, idx) => (
            <Tag
              key={`${tag.name}_${idx}`}
              text={`${policyIdentities[tag.__typename]}: ${tag.name}`}
              color="grey"
              onRemove={() => handleTagDelete(tag)}
            />
          ))}
        </Box>
      </Box>

      <Stack direction="row" spacing={2} sx={{ alignItems: 'baseline' }}>
        <Controller
          name="identity"
          defaultValue="PolicyUsernameIdentity"
          control={control}
          render={({ field: { onChange, ...rest } }) => (
            <Select
              options={identitiesList}
              onChange={newValue => {
                setCurrentTag(info => {
                  return {
                    ...info,
                    __typename: newValue.target.value,
                  }
                })
                onChange(newValue)
              }}
              {...rest}
            />
          )}
        />
        <FormControl variant="standard" sx={{ flex: 1 }}>
          <Input
            id="tag-input"
            value={currentTag.name}
            onChange={newValue =>
              setCurrentTag(info => {
                return {
                  ...info,
                  name: newValue.target.value,
                }
              })
            }
          />
        </FormControl>

        <Button
          disabled={shouldDisableAddButton}
          onClick={handleAddTag}
          variant="outlined"
          color="secondary"
        >
          Add
        </Button>
      </Stack>
    </Stack>
  )
}

export default InputTagsIdentity
