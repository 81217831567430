import { usePolicySummary } from '@jeeves/pages/RepositoryDetail/Tabs/Policies/components/PolicySummary/PolicySummaryContext'
import {
  getDisplayedPolicyIdentity,
  PolicyIdentityType,
} from '@jeeves/pages/RepositoryDetail/Tabs/Policies/helpers/policyIdentities'
import { useRepositoryDetailContext } from '@jeeves/pages/RepositoryDetail/contexts/RepositoryDetailContext'
import pluralize from 'pluralize'

export const useRepositoryProtectionVariation = () => {
  const { policy } = usePolicySummary()
  const { repoName } = useRepositoryDetailContext()

  const { restrictedOperations, identitiesAppliedTo, databaseAccountsAppliedTo, limit } =
    policy.parameters

  const getIdentities = () => {
    const identities = identitiesAppliedTo.identities?.map(
      (identity: { __typename: PolicyIdentityType; name: string }) => {
        return getDisplayedPolicyIdentity(identity)
      }
    )
    return identities ?? []
  }

  const getOperations = () => {
    // Array of operations that are enabled
    const operations = Object.keys(restrictedOperations).filter(
      key => restrictedOperations[key] === true
    )
    return operations.map(operation => operation.toUpperCase())
  }

  const getRowsDisplay = () => {
    return `${limit} ${pluralize('row', limit)}`
  }

  return {
    operations: getOperations(),
    identities: getIdentities(),
    databaseAccounts: databaseAccountsAppliedTo.databaseAccounts,
    limit: getRowsDisplay(),
    repoName,
  }
}
