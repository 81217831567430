/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { TableRow, Typography } from '@material-ui/core'
import { Fragment, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Button } from '@jeeves/components/Primitives'
import { TableCell } from '@jeeves/components/Table'
import {
  RepoAccountList,
  IdentityName,
  CreatedList,
  ExpirationList,
  RepositoryList,
  AuthorizationPolicyList,
  SubRow,
} from '.'

export const IdentityRow = ({ identityProvider, identityName, identityMaps, last }) => {
  const history = useHistory()

  return (
    <Fragment>
      <TableRow
        onClick={() =>
          history.push(
            `/identityMap/${identityProvider.name}/${
              identityMaps[Object.keys(identityMaps)[0]].identityMaps[0].identityType
            }/${identityName}`
          )
        }
        css={{ cursor: 'pointer' }}
      >
        <TableCell css={{ paddingRight: '24px', ...(last && { borderBottom: 'none' }) }}>
          <IdentityName
            type={identityMaps[Object.keys(identityMaps)[0]].identityMaps[0].identityType}
          >
            {identityName}
          </IdentityName>
          {/* {info && (
            <Typography
              css={() => css`
                position: relative;
                margin: 0 0 0 48px;
                font-size: 12px;
                font-style: italic;
              `}
            >
              {info}
            </Typography>
          )} */}
        </TableCell>
        <TableCell css={{ paddingRight: '24px', ...(last ? { borderBottom: 'none' } : {}) }}>
          <RepositoryList
            repositories={Object.entries(identityMaps).map(([repoId, { identityMaps, repo }]) => ({
              ...repo.repo,
              identityMapsCount: identityMaps.length,
            }))}
          />
        </TableCell>
        <TableCell css={{ paddingRight: '24px', ...(last ? { borderBottom: 'none' } : {}) }}>
          <RepoAccountList
            identityMaps={Object.entries(identityMaps).reduce(
              (prevList, [repoId, { identityMaps }]) => [...prevList, ...identityMaps],
              []
            )}
          />
        </TableCell>

        <TableCell css={{ paddingRight: '24px', ...(last ? { borderBottom: 'none' } : {}) }}>
          <AuthorizationPolicyList
            identityMaps={Object.entries(identityMaps).reduce(
              (prevList, [repoId, { identityMaps }]) => [...prevList, ...identityMaps],
              []
            )}
          />
        </TableCell>

        <TableCell css={{ paddingRight: '24px', ...(last ? { borderBottom: 'none' } : {}) }}>
          <CreatedList
            identityMaps={Object.entries(identityMaps).reduce(
              (prevList, [repoId, { identityMaps }]) => [...prevList, ...identityMaps],
              []
            )}
          />
        </TableCell>
        <TableCell css={{ paddingRight: '24px', ...(last ? { borderBottom: 'none' } : {}) }}>
          <ExpirationList
            identityMaps={Object.entries(identityMaps).reduce(
              (prevList, [repoId, { identityMaps }]) => [...prevList, ...identityMaps],
              []
            )}
          />
        </TableCell>

        {/* <TableCell>
          {dbUsers.map(() => (
            <SubRow>
              <Button
                css={() => css`
                  margin-right: 8px;
                `}
                color="secondary"
              >
                Revoke
              </Button>
              <Button color="primary">Recertify</Button>
            </SubRow>
          ))}
        </TableCell> */}
      </TableRow>
    </Fragment>
  )
}
