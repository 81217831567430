import { Stack } from '@mui/material'
import PolicyRule from '../../../../PolicyRule'
import { HighlightedTitle } from '@jeeves/pages/RepositoryDetail/Tabs/Policies/components/HighlightedTitle'
import { Variation1 } from '../Variation1'
import { useRateLimitVariation } from '../useRateLimitVariation'

const Variation6 = () => {
  const { identities, databaseAccounts } = useRateLimitVariation()

  return (
    <Stack spacing={4}>
      <Variation1 />
      <Stack spacing={1}>
        <PolicyRule title="This policy applies to these identities:" content={identities} />
        <PolicyRule
          title={<HighlightedTitle label1="using any database accounts" label2="except" />}
          content={databaseAccounts}
        />
      </Stack>
    </Stack>
  )
}

export default Variation6
