import React, { Fragment, useState, useEffect } from 'react'
import { withStyles, Grid } from '@material-ui/core/'
import Paper from '@material-ui/core/Paper'
import Circle from '@material-ui/icons/FiberManualRecord'
import moment from 'moment'

const styles = theme => ({
  chartContainer: {
    width: '100%',
    height: '100%',
    paddingBottom: '30px',
    // padding: '10px',
    // backgroundColor: 'whitesmoke',
  },
  circle: {
    fontSize: '12px',
  },
  empty: {
    fontSize: '11px',
    color: 'grey',
  },
  filled: {
    fontSize: '11px',
    color: 'red',
  },
  high: {
    fontSize: '12px',
    color: 'red',
  },
})

const chartData = {
  type: 'pie',
  data: {
    labels: ['apps', 'users', 'tools'],
    datasets: [
      {
        data: [44, 24, 14],
        backgroundColor: ['#354da1', '#3588a1', '#42bac7'],
      },
    ],
  },
}

const Severity = ({ classes, severity }) => {
  return (
    <Grid container>
      <Grid item>
        <Circle classes={{ root: severity >= 0 ? classes.filled : classes.empty }}></Circle>
      </Grid>
      <Grid item>
        <Circle classes={{ root: severity >= 1 ? classes.filled : classes.empty }}></Circle>
      </Grid>
      <Grid item>
        <Circle classes={{ root: severity >= 2 ? classes.filled : classes.empty }}></Circle>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(Severity)
