import { useState } from 'react'
import { FormControl, FormHelperText, InputAdornment, Stack } from '@mui/material'
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material'

import { useAppConfig } from '@jeeves/hooks'
import { IconButton, Input, InputLabel } from '@jeeves/new-components'

import { LoggingIntegrationIcon } from '@jeeves/pages/Integrations/Services/Logging/components'

import Header from '@jeeves/pages/Integrations/Services/Logging/components/NewIntegrationDialog/components/Header'
import Footer from '@jeeves/pages/Integrations/Services/Logging/components/NewIntegrationDialog/components/Footer'

import { useELK } from './useELK'

const ELK = ({ integration, resetIntegration, onClose }) => {
  const { config } = useAppConfig()
  const showKibanaUrlInput = Boolean(config?.featureFlags?.showQueryLogs)

  const isEditing = Boolean(integration?.id)

  const { loading, methods, onSubmit } = useELK({ integration, onClose, isEditing })

  const {
    formState: { errors },
    register,
    watch,
  } = methods

  const {
    credentials: { username, password },
  } = watch()

  const isUsernameAndPasswordRequired = Boolean(username) || Boolean(password)

  const [passwordVisibility, setPasswordVisibility] = useState(false)

  const toggleVisibility = () => {
    setPasswordVisibility(!passwordVisibility)
  }

  return (
    <Stack spacing={4} component="form" onSubmit={onSubmit}>
      <Header
        icon={<LoggingIntegrationIcon integrationType="ELKIntegration" />}
        title="ELK"
        onClose={onClose}
      />

      <FormControl variant="standard" error={Boolean(errors?.name)}>
        <InputLabel htmlFor="name" required>
          Name
        </InputLabel>
        <Input
          id="name"
          inputProps={{
            ...register('name'),
          }}
        />
        <FormHelperText
          sx={{
            typography: 'body2',
          }}
          error={Boolean(errors?.name)}
        >
          {errors?.name?.message}
        </FormHelperText>
      </FormControl>

      <FormControl variant="standard" error={Boolean(errors?.esUrl)}>
        <InputLabel htmlFor="esUrl" required>
          Elasticsearch URL
        </InputLabel>
        <Input
          id="esUrl"
          inputProps={{
            ...register('esUrl'),
          }}
        />
        <FormHelperText
          sx={{
            typography: 'body2',
          }}
          error={Boolean(errors?.esUrl)}
        >
          {errors?.esUrl?.message}
        </FormHelperText>
      </FormControl>

      <FormControl variant="standard" error={Boolean(errors?.credentials?.username)}>
        <InputLabel htmlFor="username" required={isUsernameAndPasswordRequired}>
          Elasticsearch User
        </InputLabel>
        <Input
          id="username"
          inputProps={{
            ...register('credentials.username'),
          }}
        />
        <FormHelperText
          sx={{
            typography: 'body2',
          }}
          error={Boolean(errors?.credentials?.username)}
        >
          {errors?.credentials?.username?.message}
        </FormHelperText>
      </FormControl>

      <FormControl variant="standard" error={Boolean(errors?.credentials?.password)}>
        <InputLabel htmlFor="password" required={isUsernameAndPasswordRequired}>
          Elasticsearch Password
        </InputLabel>
        <Input
          id="password"
          type={passwordVisibility ? 'text' : 'password'}
          inputProps={{
            ...register('credentials.password'),
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={toggleVisibility}>
                {passwordVisibility ? (
                  <VisibilityOffIcon color="secondary" fontSize="small" />
                ) : (
                  <VisibilityIcon color="secondary" fontSize="small" />
                )}
              </IconButton>
            </InputAdornment>
          }
        />
        <FormHelperText
          sx={{
            typography: 'body2',
          }}
          error={Boolean(errors?.credentials?.password)}
        >
          {errors?.credentials?.password?.message}
        </FormHelperText>
      </FormControl>

      {showKibanaUrlInput && (
        <FormControl variant="standard" error={Boolean(errors?.kibanaUrl)}>
          <InputLabel htmlFor="kibanaUrl">Kibana URL</InputLabel>
          <Input
            id="kibanaUrl"
            inputProps={{
              ...register('kibanaUrl'),
            }}
          />
          <FormHelperText
            sx={{
              typography: 'body2',
            }}
            error={Boolean(errors?.kibanaUrl)}
          >
            {errors?.kibanaUrl?.message}
          </FormHelperText>
        </FormControl>
      )}

      <Footer
        loading={loading}
        isEditing={isEditing}
        resetIntegration={resetIntegration}
        onClose={onClose}
      />
    </Stack>
  )
}

export default ELK
