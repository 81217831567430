import * as React from 'react'
import { StyledSuggestionsRepo } from '@jeeves/pages/DataLabels/components/RepoRow/MappingTable/MappingTable.styles.js'
import Typography from '@mui/material/Typography'

import Stack from '@mui/material/Stack'
import { RepoIcon } from '@jeeves/new-components/Icons'
import { useHistory, Link } from 'react-router-dom'

export const RepoCard = ({ repo }) => {
  const history = useHistory()
  return (
    <Link
      to={{
        pathname: `/repositories/${repo.id}`,

        state: { tab: 'Mappings' },
      }}
      style={{ textDecoration: 'none' }}
    >
      <StyledSuggestionsRepo
        direction="row"
        sx={{ justifyContent: 'space-between', textDecoration: 'none' }}
      >
        <Stack direction="row" spacing={1}>
          <RepoIcon repoType={repo.type}></RepoIcon>
          <Typography variant="h4">{repo.name}</Typography>
        </Stack>
      </StyledSuggestionsRepo>
    </Link>
  )
}
