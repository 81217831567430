import React from 'react'
import { InlineCode } from '@jeeves/new-components'

import { getDisplayedPassword } from '../utils'

const getConnectionTypes = (connectionInfo, options) => {
  const { ports, ...rest } = connectionInfo
  const { showPassword } = options

  const mysqlCliShellCommandConnectionInfo = {
    ...rest,
    port: ports[0],
  }

  return [
    {
      label: 'MySQL CLI',
      value: 'mysql-cli',
      content: (
        <InlineCode sx={{ backgroundColor: 'common.white' }} noWrap>
          {getMysqlCliShellCommand({
            connectionInfo: mysqlCliShellCommandConnectionInfo,
            redactPassword: !showPassword,
          })}
        </InlineCode>
      ),
      copyValue: getMysqlCliShellCommand({
        connectionInfo: mysqlCliShellCommandConnectionInfo,
      }),
    },
  ]
}

const getMysqlCliShellCommand = ({ connectionInfo, redactPassword = false }) => {
  const { username, host, port, password: connectionInfoPassword } = connectionInfo

  const password = getDisplayedPassword(connectionInfoPassword, redactPassword)

  const additionalOptions = '--default-auth=mysql_clear_password --ssl'

  return `mysql -u ${username} -p${password} -h ${host} -P ${port} ${additionalOptions}`
}

export default getConnectionTypes
