/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import moment from 'moment'
import React from 'react'
import { Typography } from '@material-ui/core'
import { Button } from '@jeeves/components/Primitives'
import { useAppConfig } from '@jeeves/hooks'

const Expiration = ({ setContactOpen }) => {
  const { license } = useAppConfig()

  if (!license?.isFreeTrial || !license?.endDate) return null

  const isExpired = () => {
    const endDate = new Date(license.endDate)
    const currentDate = new Date()
    return currentDate > endDate
  }

  return (
    <div>
      <div
        css={{
          backgroundColor: '#ffebe8',
          padding: '.4rem .5rem',
          margin: '0 1rem',
          borderRadius: '4px',
          border: '1px solid #f1c7c0',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography
          css={{
            fontSize: '.75rem',
            padding: '0 .5rem',
            lineHeight: '21px',
          }}
        >
          {isExpired() ? 'Your free trial has expired' : 'Your Free Trial expires on '}
          <span
            css={{
              fontWeight: 600,
            }}
          >
            {!isExpired() && moment(license.endDate).format('MMMM DD, YYYY')}
          </span>
          .
        </Typography>
        <Button
          css={{
            marginTop: 0,
            marginBottom: 0,
            paddingTop: 0,
            paddingBottom: 0,
            borderRadius: '3px',
            fontSize: '.75rem',
            color: '#e85e47',
          }}
          onClick={() => setContactOpen(true)}
        >
          contact us to purchase
        </Button>
      </div>
    </div>
  )
}

export default Expiration
