import { useQuery } from '@apollo/client'

import { getAccessTokenName } from '@jeeves/utils/accessToken'
import { graphql } from '@jeeves/graphql'
import { useAccessToken } from '@jeeves/hooks'

import S3Browser from './S3Browser'

const S3_BROWSER = graphql(`
  query S3Browser(
    $first: Int
    $after: String
    $filters: IdentityReposFilters
    $accessibility: ReposAccessibilityState!
  ) {
    user {
      ... on ControlPlaneUser {
        id
        repos(first: $first, after: $after, filters: $filters, accessibility: $accessibility) {
          edges {
            node {
              id
              name
              type
              tags
              config {
                authentication {
                  allowNativeAuthentication
                  otpDbuser
                }
                TLS {
                  enableClientSidecarTLS
                }
              }
              ... on MongoDBReplicaSetRepo {
                replicaSetName
              }
            }
            accessibleUserAccounts {
              edges {
                node {
                  id
                  name
                  credentials {
                    ... on AWSIAMUserAccountCredentials {
                      roleARN
                    }
                  }
                  ... on MongoDBUserAccount {
                    authenticationDatabase
                  }
                }
                validUntil
                accessRestrictionSummaries {
                  name
                  type
                }
              }
            }
            accessPortalBindingRelationship {
              edge {
                node {
                  id
                  ... on ClusterBinding {
                    boundListenersRelationship {
                      edges {
                        node {
                          id
                          port
                        }
                      }
                    }
                  }
                  ... on S3Binding {
                    listenerSet {
                      proxyListener {
                        id
                        port
                      }
                      browserListener {
                        id
                        port
                      }
                    }
                  }
                  ... on SingleListenerBinding {
                    listener {
                      id
                      port
                    }
                  }
                }
                sidecar {
                  name
                  endpoint
                  userEndpoint
                }
              }
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    }
  }
`)

const S3BrowserWrapper = () => {
  const {
    accessToken,
    loading: accessTokenLoading,
    getNewAccessToken,
  } = useAccessToken({
    name: getAccessTokenName('s3-browser'),
  })

  const { data, loading } = useQuery(S3_BROWSER, {
    variables: {
      first: 1,
      accessibility: 'HAS_ACCESS',
      filters: { repoTypes: ['s3'] },
    },
  })

  const infos = {
    token: accessToken?.token,
    repoEdgesUserCanAccess: data?.user?.repos?.edges,
    hasNextPage: data?.user?.repos?.pageInfo?.hasNextPage,
  }

  return (
    <S3Browser
      accessPortalInfoLoading={accessTokenLoading || loading}
      data={infos}
      getNewAccessToken={getNewAccessToken}
    />
  )
}

export default S3BrowserWrapper
