import { Box } from '@mui/material'

import { graphql, useFragment } from '@jeeves/graphql'
import { Tag } from '@jeeves/new-components'

import SidecarHeaderOverflowTags from './SidecarHeaderOverflowTags'

const SidecarHeaderTags_queryFragment = graphql(`
  fragment SidecarHeaderTags_query on Query {
    sidecar(id: $sidecarId) {
      id
      labels
      properties
    }
  }
`)

const NUMBER_OF_DISPLAYED_TAGS = 5

const SidecarHeaderTags = ({ query: queryProp }) => {
  const query = useFragment(SidecarHeaderTags_queryFragment, queryProp)

  const { labels, properties } = query.sidecar

  const propertiesList = properties
    ? Object.entries(properties)
        .filter(([_, value]) => value)
        .map(([key, value]) => `${key}:${value}`)
    : []

  const tags = [...propertiesList, ...labels]

  const displayedTags = tags.slice(0, NUMBER_OF_DISPLAYED_TAGS)
  const overflowTags = tags.slice(NUMBER_OF_DISPLAYED_TAGS)

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, flexWrap: 'wrap' }}>
      {displayedTags.map(tag => (
        <Tag key={tag}>{tag}</Tag>
      ))}
      <SidecarHeaderOverflowTags overflowTags={overflowTags} />
    </Box>
  )
}

export default SidecarHeaderTags
