import { FormProvider } from 'react-hook-form'
import { Box, Stack, Typography } from '@mui/material'

import { Accordion, AccordionDetails, AccordionSummary, Button } from '@jeeves/new-components'
import { FragmentType, graphql, useFragment } from '@jeeves/graphql'

import useAccessTokens from './useAccessTokens'
import MaxTokenValidity from './MaxTokenValidity'
import DefaultTokenValidity from './DefaultTokenValidity'
import MaxValidTokens from './MaxValidTokens'
import OfflineTokenValidation from './OfflineTokenValidation'

const AccessTokens_QueryFragment = graphql(`
  fragment AccessTokens_QueryFragment on Query {
    ...useAccessTokens_QueryFragment
  }
`)

interface Props {
  query: FragmentType<typeof AccessTokens_QueryFragment>
}

const AccessTokens = ({ query: queryProp }: Props) => {
  const query = useFragment(AccessTokens_QueryFragment, queryProp)

  const { loading, methods, onSubmit } = useAccessTokens({ query })

  const {
    formState: { isDirty },
    reset,
  } = methods

  const isDisabled = loading || !isDirty

  return (
    <Box>
      <Accordion>
        <AccordionSummary sx={{ p: 4 }}>
          <Typography variant="h3" sx={{ color: 'text.primary' }}>
            Access Tokens
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ px: 4, pb: 4, pt: 0 }}>
          <FormProvider {...methods}>
            <Stack component="form" spacing={4} onSubmit={onSubmit}>
              <MaxTokenValidity />

              <DefaultTokenValidity />

              <MaxValidTokens />

              <OfflineTokenValidation />

              <Stack direction="row" sx={{ justifyContent: 'flex-end' }} spacing={2}>
                <Button variant="text" disabled={isDisabled} onClick={() => reset()}>
                  Cancel
                </Button>
                <Button type="submit" disabled={isDisabled} loading={loading}>
                  Save
                </Button>
              </Stack>
            </Stack>
          </FormProvider>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

export default AccessTokens
