/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import React, { useRef, useEffect, useState } from 'react'
import { withStyles, Typography, Tooltip } from '@material-ui/core'

export const EllipsisTypography = ({ width = '120px', text, children, ...props }) => {
  const [overFlowed, setOverFlowed] = useState(false)
  const textRef = useRef(null)

  useEffect(() => {
    if (textRef) {
      setOverFlowed(textRef.current.scrollWidth > textRef.current.clientWidth)
    }
  }, [textRef])

  // Figure out how to grab refs from mui typography component, so we can use it instead of p

  return (
    <Typography>
      <Tooltip
        title={overFlowed ? (text ? text : typeof children === 'string' ? children : '') : ''}
      >
        <p
          ref={textRef}
          css={{
            width,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            margin: 0,
          }}
          {...props}
        >
          {children}
        </p>
      </Tooltip>
    </Typography>
  )
}
