import { Typography, Stack, Dialog, DialogTitle, DialogContent } from '@mui/material'

import Button from '@jeeves/new-components/Button'

const ErrorDialog = ({ setAccessDeniedDialogOpen, accessDeniedDialogOpen, errorText }) => {
  return (
    <Dialog onClose={() => setAccessDeniedDialogOpen(false)} open={accessDeniedDialogOpen}>
      <DialogTitle
        sx={{ fontSize: '1.25rem', color: 'error.main', padding: '24px 24px 16px 24px' }}
      >
        Error processing request
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography sx={{ color: 'cyralColors.grey.400', width: '400px' }}>
            {errorText}
          </Typography>
          <Stack justifyContent="flex-end" direction="row">
            <Button onClick={() => setAccessDeniedDialogOpen(false)}>{'Okay'}</Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}

export default ErrorDialog
