import React from 'react'
import Stack from '@mui/material/Stack'
import MuiDivider from '@mui/material/Divider'
import MuiTabs from '@mui/material/Tabs'
import MuiTab from '@mui/material/Tab'
import { styled } from '@mui/material/styles'

const StyledDataRepoCard = styled(
  props => (
    <Stack
      {...props}
      divider={
        <MuiDivider
          sx={{
            borderBottomColor: 'cyralColors.grey.200',
          }}
        />
      }
    />
  ),
  {}
)(({ theme }) => ({
  overflow: 'hidden',
  backgroundColor: theme.palette.common.white,
  border: `1px solid ${theme.palette.cyralColors.grey[200]}`,
  borderRadius: theme.radii.base,
  flexShrink: 0,
}))

export default StyledDataRepoCard
