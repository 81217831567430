import {
  Box,
  Stack,
  FormControlLabel,
  Radio,
  Typography,
  FormHelperText,
  FormControl,
} from '@mui/material'

import { Controller } from 'react-hook-form'

import InputTags from '@jeeves/pages/RepositoryDetail/Tabs/Policies/views/AddPolicy/components/InputTags'

const DatabaseAccountCustomOption = ({
  value,
  label,
  isSubmitting,
  valueKey,
  databaseAccountTags,
  isSelected,
  control,
  error,
  onAddDatabaseAccounts,
  onDeleteDatabaseAccounts,
}) => {
  return (
    <Stack spacing={1}>
      <FormControlLabel
        value={value}
        control={<Radio size="small" disableRipple />}
        label={<Typography variant="h6">{label}</Typography>}
      />
      {isSelected && (
        <Box sx={{ paddingLeft: 3 }}>
          <FormControl variant="standard" error={Boolean(error)} sx={{ display: 'flex' }}>
            <Controller
              name={valueKey}
              control={control}
              rules={{
                validate: databaseAccountTags => {
                  return (
                    (isSelected && databaseAccountTags.length > 0) ||
                    'Please enter at least one database account'
                  )
                },
              }}
              render={() => (
                <InputTags
                  required
                  tags={databaseAccountTags}
                  isSubmitting={isSubmitting}
                  onAdd={onAddDatabaseAccounts}
                  onDelete={onDeleteDatabaseAccounts}
                />
              )}
            />
            <FormHelperText sx={{ typography: 'body2' }} error={!!error}>
              {error?.message}
            </FormHelperText>
          </FormControl>
        </Box>
      )}
    </Stack>
  )
}

export default DatabaseAccountCustomOption
