/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import styled from '@emotion/styled'
import { Chip, Toolbar as MuiToolbar, Typography } from '@material-ui/core'

const BREAKPOINT = 1439

export const StyledToolbar = styled(MuiToolbar)`
  padding-top: ${({ theme }) => theme.spacing[6]};
  align-items: normal;
  flex-wrap: wrap;
  position: relative;
  min-height: 90px;

  @media (min-width: ${BREAKPOINT}px) {
    margin-top: 0;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`

export const StyledTitleArea = styled.div`
  flex-direction: column;
  max-width: auto;
  flex-grow: 0;

  @media (min-width: ${BREAKPOINT}px) {
    max-width: ${props => (props.titlefullWidth ? '100%' : '35%')};
  }

  h1 {
    font-size: 24px;
  }
`

export const StyledActionsArea = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
`

export const StyledChip = styled(Chip)`
  margin-left: ${({ theme }) => theme.spacing[3]};
  background: transparent;
  width: 26px;
  height: 26px;
  border: 1px solid #808e95;
  font-size: 12px;
  align-items: center;
  align-content: center;
  justify-content: center;

  span {
    color: #808e95;
    padding: 0;
  }
`

const Toolbar = ({
  title,
  subtitle,
  link,
  chip,
  children,
  titleAction,
  titleFullWidth,
  ...props
}) => (
  <StyledToolbar {...props}>
    <StyledTitleArea titlefullWidth>
      <Typography
        variant="h1"
        id="repositories"
        css={() => css`
          display: flex;
          flex-direction: row;
          align-items: center;
        `}
      >
        <span>{title}</span>
        {chip && chip !== 0 ? <StyledChip label={chip} /> : undefined}
        {titleAction && (
          <span
            css={theme => css`
              margin-left: ${theme.spacing[3]};
              display: inline-block;
            `}
          >
            {titleAction}
          </span>
        )}
      </Typography>
      <Typography
        variant="caption"
        css={() => css`
          margin-top: 10px;
          width: 100%;
          font-size: 1rem;
        `}
      >
        {subtitle}
        {link && (
          <a
            href={link}
            css={{ textDecoration: 'none', color: '#26459e' }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong>{' Learn more'}</strong>
          </a>
        )}
      </Typography>
    </StyledTitleArea>
    <StyledActionsArea>{children}</StyledActionsArea>
  </StyledToolbar>
)

export default Toolbar
