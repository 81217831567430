/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import React, { useState, useEffect } from 'react'
import { useRouteMatch, NavLink, matchPath, Link } from 'react-router-dom'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import { useWindowScroll } from 'react-use'

import { ThemeProvider } from '@mui/material/styles'
import { v5Theme } from '@jeeves/theme'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

import { withStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Avatar from '@material-ui/core/Avatar'
import MuiAppBar from '@material-ui/core/AppBar'
import Tooltip from '@material-ui/core/Tooltip'
import Grid from '@material-ui/core/Grid'

import { useAuth } from '../Auth'
import ExpressClient from '../../clients/express'
import CopyButton from '../../components/CopyButton'
import { ContactDialog } from './ContactDialog'
import Expiration from './Expiration'

import { Button } from '@jeeves/components/Primitives'

const styles = () => ({
  userIcon: {
    padding: 4,
  },
})

const Header = ({ classes, location }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [contactOpen, setContactOpen] = useState(false)
  const { user, loading, getTokenSilently, hasPermission, isDbUser } = useAuth()
  const accessPortalMatch = useRouteMatch('/access-portal')
  const s3BrowserMatch = useRouteMatch('/s3Browser')

  const showAccessPortalButton = !(accessPortalMatch?.isExact || s3BrowserMatch)

  const ec = new ExpressClient(getTokenSilently)
  const open = Boolean(anchorEl)

  const handleMenu = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  if (loading) {
    return <div>Loading...</div>
  }

  const getFirstName = () => {
    if (!user) return ''

    if (user.given_name) {
      return user.given_name
    } else {
      if (user.name) return user.name
      if (user.email) return user.email
      return ''
    }
  }

  const getLastName = firstName => {
    if (!user || !user.given_name || !user.family_name) return ''
    if (user.given_name && user.family_name && user.given_name === firstName)
      return user.family_name

    return ''
  }

  const getUserEmail = () => {
    if (!user) return ''
    if (user.email) return user.email
    return ''
  }

  const getAvatar = () => {
    const getFirstInitial = () => {
      const firstName = getFirstName()
      if (firstName) return firstName.charAt(0)
      return ''
    }
    const getLastInitial = () => {
      const lastName = getLastName()
      if (lastName) return lastName.charAt(0)
      return ''
    }
    if (user.picture) return <Avatar alt="Name" src={user.picture} />
    return (
      <Avatar css={{ backgroundColor: '#1abc9d', fontSize: '16px' }} alt="Name">
        {getFirstInitial()}
        {getLastInitial()}
      </Avatar>
    )
  }

  const canAccessToken = () => !inAccessView() && hasPermission('token:read')

  const canAccessMgmtConsole = () => inAccessView() && !isDbUser

  function stripTrailingSlash(str) {
    if (str.substr(-1) === '/') {
      return str.substr(0, str.length - 1)
    }
    return str
  }

  const inAccessView = () => {
    // TODO: use matchPath for this
    // https://reactrouter.com/web/api/matchPath
    return (
      matchPath(location.pathname, { path: '/app/token' }) ||
      matchPath(location.pathname, { path: '/token' }) ||
      matchPath(location.pathname, { path: '/app/approveGrant' }) ||
      matchPath(location.pathname, { path: '/approveGrant' }) ||
      matchPath(location.pathname, { path: '/cli' })
    )
  }

  const accessPortalButton = (
    <Link to={'/access-portal'} css={{ textDecoration: 'none' }}>
      <Button
        variant="outlined"
        color="primary"
        css={{
          marginRight: '1rem',
        }}
      >
        Data Access Portal
      </Button>
    </Link>
  )

  return (
    <AppBar>
      <Toolbar
        css={() => css`
          display: flex;
          padding-left: 30px;
          padding-top: 16px;
          padding-bottom: 16px;
        `}
      >
        {!isDbUser && <Expiration setContactOpen={setContactOpen} />}

        <Grid
          container
          css={{ width: 'auto', marginLeft: 'auto' }}
          spacing={8}
          justify="center"
          alignItems="center"
        >
          {/* button for switching between management console and Access Portal */}
          <Grid item>
            {showAccessPortalButton && accessPortalButton}
          </Grid>
          <Grid item>
            <Typography align="right">{`${getFirstName()} ${getLastName()}`}</Typography>
            <Typography align="right" variant="caption" color="textSecondary">
              {getUserEmail()}
            </Typography>
          </Grid>

          <Grid item>
            <IconButton
              aria-owns={open ? 'user-appbar-menu' : undefined}
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
              className={classes.userIcon}
            >
              {/* <Avatar alt="Name" src={user.picture} /> */}
              {getAvatar()}
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>

      <ContactDialog open={contactOpen} setOpen={setContactOpen} />

      <ThemeProvider theme={v5Theme}>
        <UserMenu handleClose={handleClose} anchorEl={anchorEl} />
      </ThemeProvider>
    </AppBar>
  )
}

export const AppBar = ({ children, ...props }) => {
  const { y } = useWindowScroll()

  return (
    <MuiAppBar
      {...props}
      css={theme => css`
        background-color: #f0f2f5;
        box-shadow: ${y > 40 ? '0 0 8px 2px rgb(0,0,0,0.2)' : 'none'};
        transition: box-shadow 0.3s ease-in-out;
        position: sticky;
        top: 0;
        flex-shrink: 0;

        z-index: ${theme.zIndex.drawer};
      `}
      elevation={0}
    >
      {children}
    </MuiAppBar>
  )
}

const UserMenu = ({ handleClose, anchorEl }) => {
  const { logout } = useAuth()

  const logoutWithRedirect = () => {
    return logout({ federated: true, returnTo: `${window.location.origin}/app` })
  }
  const open = Boolean(anchorEl)

  return (
    <Menu
      id="user-appbar-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      autoFocus={false}
      disableScrollLock
    >
      <MenuItem
        tabIndex={-1}
        autoFocus={false}
        component={NavLink}
        to="/profile"
        onClick={handleClose}
      >
        Profile
      </MenuItem>
      <MenuItem onClick={logoutWithRedirect}>Logout</MenuItem>
    </Menu>
  )
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withRouter(withStyles(styles)(Header))
