import { gql, useApolloClient } from '@apollo/client'

const GET_SIDECAR_FROM_CACHE = gql`
  query GetSidecarFromCache($sidecarId: String!) {
    sidecar(id: $sidecarId) {
      id
    }
  }
`

const useBindingsExist = sidecarId => {
  const apolloClient = useApolloClient()

  const { sidecar } = apolloClient.readQuery({
    query: GET_SIDECAR_FROM_CACHE,
    variables: {
      sidecarId,
    },
  })

  const serializedCache = apolloClient.cache.extract()
  const sidecarCacheId = apolloClient.cache.identify(sidecar)

  const sidecarInCache = serializedCache[sidecarCacheId] ?? {}

  const allBindings = Object.entries(sidecarInCache).reduce((currentBindings, [key, val]) => {
    if (!key.startsWith('bindings')) return currentBindings

    const nodes = val.edges.map(edge => edge.node)

    return [...currentBindings, ...nodes]
  }, [])

  return allBindings.length > 0
}

export default useBindingsExist
