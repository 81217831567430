import React from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Box, Stack, Typography, FormControlLabel, Checkbox, Switch } from '@mui/material'
import { Button } from '@jeeves/new-components'
import { LearnMore } from './LearnMore'
import { gql, useMutation } from '@apollo/client'

const UPDATE_ALERTS = gql`
  mutation UpdateRepoAlertConfig($repoId: ID!, $input: UpdateRepoAlertConfigInput!) {
    updateRepoAlertConfig(repoId: $repoId, input: $input) {
      repo {
        id
        name
        config {
          alerts {
            alertOnViolations
            enablePreconfiguredAlerts
          }
        }
      }
    }
  }
`

export const Alerts = ({ repoId = '', alertsData }) => {
  const {
    control,
    reset,
    formState: { isDirty, isSubmitting, isSubmitSuccessful },
    handleSubmit,
    getValues,
  } = useForm({
    defaultValues: {
      alertOnViolations: alertsData?.alertOnViolations,
      enablePreconfiguredAlerts: alertsData?.enablePreconfiguredAlerts,
    },
  })

  const [updateAlerts, { data, loading, error }] = useMutation(UPDATE_ALERTS)

  const onSubmit = async ({ alertOnViolations, enablePreconfiguredAlerts }) => {
    try {
      await updateAlerts({
        variables: {
          repoId,
          input: {
            alertOnViolations,
            enablePreconfiguredAlerts,
          },
        },
      })
    } catch (e) {
      console.error(e)
    }
  }

  const handleCancel = () => {
    reset()
  }

  React.useEffect(() => {
    if (isSubmitSuccessful) {
      reset(getValues())
    }
  }, [isSubmitSuccessful, reset, getValues])

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack sx={{ justifyContent: 'space-between' }}>
        <Stack spacing={2} sx={{ pb: 7 }}>
          <Box>
            <Controller
              name="alertOnViolations"
              control={control}
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <FormControlLabel
                  control={<Switch name={name} onChange={onChange} checked={value} />}
                  label={
                    <Typography variant="h4" sx={{ color: 'text.primary' }}>
                      Send alert notifications when policy violations occur.{' '}
                      <LearnMore docsPath="/reference/alerts" />
                    </Typography>
                  }
                />
              )}
            />
          </Box>
          <Box>
            <Controller
              name="enablePreconfiguredAlerts"
              control={control}
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <FormControlLabel
                  sx={{ ml: '-11px' }}
                  control={<Switch name={name} onChange={onChange} checked={value} />}
                  label={
                    <Typography variant="h4" sx={{ color: 'text.primary' }}>
                      Use preconfigured alerts to notify admins about any privileged or suspicious
                      activity. <LearnMore docsPath="/reference/alerts#preconfigured-alerts" />
                    </Typography>
                  }
                />
              )}
            />
          </Box>
        </Stack>
        <Stack direction="row" spacing={2.5} sx={{ justifyContent: 'flex-end' }}>
          <Button variant="text" onClick={handleCancel} disabled={!isDirty}>
            Cancel
          </Button>
          <Button variant="contained" type="submit" loading={isSubmitting} disabled={!isDirty}>
            Save
          </Button>
        </Stack>
      </Stack>
    </Box>
  )
}
