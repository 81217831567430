import { RepoDetailsQuery } from '@jeeves/graphql/graphql'

export const getAccessGatewaySidecarEdges = (repo: RepoDetailsQuery['repo']) => {
  return repo.boundSidecarsRelationship.edges.filter(repoSidecarEdge => {
    const { node: sidecar, bindings } = repoSidecarEdge
    return (
      sidecar.passthroughConfiguration !== 'always' && bindings.some(binding => binding.enabled)
    )
  })
}
