import { Switch, Route, useRouteMatch, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import { Box, CircularProgress, Divider, Stack, Typography } from '@mui/material'

import { graphql } from '@jeeves/graphql'
import PrivateRoute from '@jeeves/components/PrivateRoute'

import { Content } from './components/Content'
import { Header } from './components/Header'
import { ErrorState } from './components/ErrorState'
import { PolicyDetailsContext } from './contexts/PolicyDetailsContext'
import { EditPolicy } from '../AddPolicy'

const POLICY_DETAILS = graphql(`
  query PolicyDetails($policyId: ID!) {
    policy(id: $policyId) {
      id
      enabled
      name
      description
      appliedToMultipleRepos
      tags
      created {
        actor {
          name
          type
        }
        timestamp
      }
      lastUpdated {
        actor {
          name
          type
        }
        timestamp
      }
      repos {
        id
      }
      template {
        id
        description
        name
        tags
      }
      ...DataMaskingFields
      ...DataProtectionFields
      ...DataFirewallFields
      ...ReadLimitFields
      ...ServiceAccountAbuseFields
      ...UserSegmentationFields
      ...RepositoryProtectionFields
      ...RateLimitFields
    }
  }
`)

const PolicyDetails = () => {
  const { path } = useRouteMatch()
  const { id: repoId, policyId } = useParams()

  const { data, loading, error } = useQuery(POLICY_DETAILS, {
    variables: {
      policyId,
    },
  })

  if (loading) {
    return (
      <Stack spacing={2} sx={{ alignItems: 'center' }}>
        <CircularProgress size={64} />
        <Typography variant="h6" sx={{ color: 'text.secondary' }}>
          Loading policy...
        </Typography>
      </Stack>
    )
  }

  if (error) {
    return <ErrorState />
  }

  const policyBelongsToRepo = data.policy.repos.some(repo => repo.id === repoId)

  if (!policyBelongsToRepo) {
    return <ErrorState />
  }

  return (
    <Switch>
      <Route exact path={path}>
        <PolicyDetailsContext.Provider value={{ policy: data.policy }}>
          <Box sx={{ mx: 8, my: 4 }}>
            <Stack spacing={4} divider={<Divider orientation="horizontal" flexItem />}>
              <Header />
              <Content />
            </Stack>
          </Box>
        </PolicyDetailsContext.Provider>
      </Route>

      <PrivateRoute path={`${path}/edit`} requiredPermission={'policies:update'}>
        <EditPolicy policy={data.policy} />
      </PrivateRoute>
    </Switch>
  )
}

export default PolicyDetails
