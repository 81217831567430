import { styled } from '@mui/material/styles'

export const TableContainer = styled(
  'div',
  {}
)(({ theme }) => ({
  table: {
    borderCollapse: 'separate',
    borderSpacing: 0,
    width: '100%',
  },

  'td, th': {
    textAlign: 'left',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  td: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  th: {
    ...theme.typography.h5,
    color: theme.palette.text.secondary,

    backgroundColor: theme.palette.cyralColors.grey[100],

    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderTopStyle: 'solid',
    borderBottomStyle: 'solid',
    borderColor: theme.palette.cyralColors.grey[200],
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  tr: {
    '& td': {
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderColor: theme.palette.cyralColors.grey[200],
    },

    '& th:first-of-type': {
      borderLeftWidth: '1px',
      borderLeftStyle: 'solid',
      borderTopLeftRadius: theme.radii.base,
      borderBottomLeftRadius: theme.radii.base,
    },

    '& th:last-of-type': {
      borderRightWidth: '1px',
      borderRightStyle: 'solid',
      borderTopRightRadius: theme.radii.base,
      borderBottomRightRadius: theme.radii.base,
    },
  },
}))
