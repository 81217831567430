import { useParams } from 'react-router'
import { useQuery, gql } from '@apollo/client'

const POLICIES_LIST = gql`
  query PoliciesList($repoId: String!, $filter: PolicyFilter, $first: Int, $last: Int) {
    repo(id: $repoId) {
      id
      policies(filter: $filter, first: $first, last: $last) {
        edges {
          node {
            id
            name
            description
            enabled
            tags
            template {
              id
              description
              name
              tags
            }
          }
        }
      }
    }
  }
`

export const usePoliciesMain = () => {
  const { id: repoId } = useParams()

  const enabledPoliciesQuery = useQuery(POLICIES_LIST, {
    variables: {
      repoId,
      filter: {
        enabled: {
          eq: true,
        },
      },
      first: 1000,
    },
  })

  const disabledPoliciesQuery = useQuery(POLICIES_LIST, {
    variables: {
      repoId,
      filter: {
        enabled: {
          eq: false,
        },
      },
      first: 1000,
    },
  })

  return {
    enabledPoliciesQuery,
    disabledPoliciesQuery,
  }
}
