import * as React from 'react'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Collapse from '@mui/material/Collapse'

import {
  RecommendationsContainer,
  RecommendationsHeader,
} from '@jeeves/pages/RepositoryDetail/Tabs/Mappings/Recommendations/Recommendations.styles.js'
import { RepoCard } from './RepoCard'

import IconButton from '@mui/material/IconButton'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import NotificationsIcon from '@mui/icons-material/Notifications'

export const Recommendations = ({ recommendations }) => {
  const [open, setOpen] = React.useState(false)
  if (recommendations.length < 1) return null

  return (
    <React.Fragment>
      <RecommendationsContainer
        sx={{ mb: open ? 2 : 2, backgroundColor: 'cyralColors.primary.100', pb: open ? 2 : 1 }}
      >
        <RecommendationsHeader sx={{ mb: open ? 1.5 : 1.5 }}>
          <Stack direction="row">
            <NotificationsIcon sx={{ color: 'cyralColors.primary.300' }}></NotificationsIcon>
            <Typography variant="h6" sx={{ color: 'primary.main', alignSelf: 'center', ml: 1 }}>
              {recommendations.length}{' '}
              {recommendations.length === 1
                ? `repo has pending data label suggestions`
                : `repos have pending data label suggestions`}
            </Typography>
          </Stack>

          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </RecommendationsHeader>
        <Collapse in={open} timeout="auto" unmountOnExit>
          {recommendations.map(recommendation => (
            <RepoCard repo={recommendation.repo}></RepoCard>
          ))}
        </Collapse>
      </RecommendationsContainer>
    </React.Fragment>
  )
}
