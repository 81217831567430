import { BaseWizard } from '../common'

import { StepOne, StepThree, StepReview } from '../common/steps'
import { StepTwo } from './steps'

import useDataMasking from './useDataMasking'

const DataMasking = ({ policy = {}, template }) => {
  const {
    activeStep,
    snackBarOpen,
    loading,
    errorMessage,
    setDataMaskingInfo,
    dataMaskingInfo,
    prevStep,
    nextStep,
    handlePolicySubmit,
    handleCloseSnackbar,
  } = useDataMasking({ policy, template })

  const steps = [
    {
      title: 'Describe the Data Masking policy',
      component: (
        <StepOne
          nextStep={nextStep}
          setPolicyInfo={setDataMaskingInfo}
          policyInfo={dataMaskingInfo}
        />
      ),
    },
    {
      title: 'Specify masking parameters',
      component: (
        <StepTwo
          dataMaskingInfo={dataMaskingInfo}
          prevStep={prevStep}
          nextStep={nextStep}
          setDataMaskingInfo={setDataMaskingInfo}
        />
      ),
    },
    {
      title: 'Specify who this policy applies to',
      component: (
        <StepThree
          policyInfo={dataMaskingInfo}
          prevStep={prevStep}
          nextStep={nextStep}
          setPolicyInfo={setDataMaskingInfo}
        />
      ),
    },
    {
      title: 'Review the policy',
      component: (
        <StepReview
          policyInfo={dataMaskingInfo}
          prevStep={prevStep}
          handlePolicySubmit={handlePolicySubmit}
          isSubmitting={loading}
        />
      ),
    },
  ]

  return (
    <BaseWizard
      steps={steps}
      activeStep={activeStep}
      snackBarOpen={snackBarOpen}
      handleCloseSnackbar={handleCloseSnackbar}
      errorMessage={errorMessage}
    />
  )
}

export default DataMasking
