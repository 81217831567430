import { Divider, Stack, Typography } from '@mui/material'
import { ServiceIcon } from '@jeeves/new-components'
import { RepoType } from '@jeeves/graphql/graphql'

interface Props {
  services: {
    label: string
    type?: RepoType
  }[]
}

const LogLevelServiceLabel = ({ services }: Props) => {
  return (
    <Stack
      spacing={1}
      direction="row"
      divider={<Divider orientation="vertical" flexItem />}
      sx={{ alignItems: 'center' }}
    >
      {services.map(service => (
        <Stack key={service.label} spacing={0.5} direction="row" sx={{ alignItems: 'center' }}>
          {service.type && <ServiceIcon type={service.type} />}

          <Typography variant="body2" sx={{ color: 'text.primary' }}>
            {service.label}
          </Typography>
        </Stack>
      ))}
    </Stack>
  )
}

export default LogLevelServiceLabel
