import React, { forwardRef } from 'react'
import Typography from '@mui/material/Typography'
import Crypto from 'crypto-js'
import ClearIcon from '@mui/icons-material/Clear'

import { StyledTag } from './Tag.style'

const TAG_COLORS = ['green', 'yellow', 'red', 'grey', 'orange', 'purple', 'pink', 'primary']

const getHashValue = tag => {
  return Crypto.MD5(tag.toLowerCase()).toString(Crypto.enc.Hex).slice(-4)
}

const getTagColor = tag => {
  const HEX_BASE = 16
  const intValue = parseInt(getHashValue(tag), HEX_BASE)
  const index = intValue % TAG_COLORS.length

  return TAG_COLORS[index]
}

const Tag = forwardRef(
  ({ children, className, sx = [], size = 'sm', color, onRemove, ...props }, ref) => {
    if (typeof children !== 'string' && typeof children !== 'number') {
      throw new Error(
        `Children must be of type string or number, but is instead of type ${typeof children}`
      )
    }

    // Determine color based off of content if prop is undefined
    const tagColor = color ?? getTagColor(String(children))

    let removeIcon = null
    if (onRemove) {
      removeIcon = <ClearIcon onClick={onRemove} />
    }

    return (
      <StyledTag
        ref={ref}
        className={className}
        size={size}
        color={tagColor}
        onRemove={onRemove}
        sx={[...(Array.isArray(sx) ? sx : [sx])]}
        {...props}
      >
        <Typography variant="tag" component="span" noWrap>
          {children}
        </Typography>
        {removeIcon}
      </StyledTag>
    )
  }
)

export default Tag
