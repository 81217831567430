import { useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'

import { Box, Divider, FormControl, Stack, FormHelperText } from '@mui/material'
import { useRepositoryDetailContext } from '@jeeves/pages/RepositoryDetail/contexts/RepositoryDetailContext'

import { InputLabel } from '@jeeves/new-components'
import InputTagsIdentity from '@jeeves/pages/RepositoryDetail/Tabs/Policies/views/AddPolicy/components/InputTagsIdentity'

import Footer from '@jeeves/pages/RepositoryDetail/Tabs/Policies/views/AddPolicy/components/Footer'

const StepTwo = ({ dataFirewallInfo, nextStep, prevStep, setDataFirewallInfo }) => {
  const { repoId } = useRepositoryDetailContext()
  const {
    setValue,
    handleSubmit,
    watch,
    control,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      excludedIdentities: dataFirewallInfo?.parameters?.excludedIdentities,
    },
  })

  const onSubmit = data => {
    setDataFirewallInfo(info => {
      return {
        ...info,
        parameters: {
          ...info.parameters,
          excludedIdentities: data.excludedIdentities,
        },
      }
    })
  }

  const onDeleteExcludedIdentities = updatedTags => {
    setValue('excludedIdentities', updatedTags, { shouldDirty: true })
  }

  const onAddExcludedIdentities = updatedTags => {
    setValue('excludedIdentities', updatedTags, { shouldValidate: true, shouldDirty: true })
  }

  useEffect(() => {
    if (isSubmitSuccessful) {
      nextStep()
    }
  }, [isSubmitSuccessful, nextStep])

  const { excludedIdentities = [] } = watch()

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4}>
        <Box sx={{ maxWidth: '32rem' }}>
          <FormControl fullWidth variant="standard">
            <InputLabel
              htmlFor="excludedIdentities"
              component="legend"
              required
              sx={{ marginBottom: 1 }}
            >
              Specify exempt identities
            </InputLabel>

            <Controller
              name="excludedIdentities"
              control={control}
              rules={{
                validate: identities => {
                  return identities?.length > 0 || 'Please specify at least one identity'
                },
              }}
              render={() => (
                <InputTagsIdentity
                  required
                  tags={excludedIdentities}
                  isSubmitting={isSubmitting}
                  control={control}
                  onAdd={onAddExcludedIdentities}
                  onDelete={onDeleteExcludedIdentities}
                />
              )}
            />
            <FormHelperText sx={{ typography: 'body2' }} error={!!errors?.excludedIdentities}>
              {errors?.excludedIdentities?.message}
            </FormHelperText>
          </FormControl>
        </Box>

        <Divider />

        <Footer repoId={repoId} prevStep={prevStep} policyId={dataFirewallInfo.id} />
      </Stack>
    </Box>
  )
}

export default StepTwo
