import React from 'react'
import { Dialog } from '@material-ui/core/'

import Header from './Header'

const IntegrationDialog = ({ open, handleOnClose, integration }) => {
  const IntegrationDialogProp = integration

  return (
    <Dialog
      open={open}
      onClose={handleOnClose}
      fullWidth
      maxWidth="md"
      aria-labelledby="form-dialog-title"
    >
      <Header metadata={integration.metadata} handleOnClose={handleOnClose} />
      <IntegrationDialogProp />
    </Dialog>
  )
}

export default IntegrationDialog
