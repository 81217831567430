import { useState } from 'react'
import { DialogContent, DialogTitle, Stack, Typography, IconButton } from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'
import { useMutation } from '@apollo/client'

import {
  Button,
  Dialog,
  DialogActions,
  InlineCode,
  LearnMore,
  useToast,
} from '@jeeves/new-components'
import { FragmentType, graphql, useFragment } from '@jeeves/graphql'
import { getGraphQLErrorMessage } from '@jeeves/utils/helpers'
import { instanceCanUpgrade } from '@jeeves/pages/WrapperDetail/components/Instances/helpers'

const UpgradeAllInstancesModal_SidecarFragment = graphql(`
  fragment UpgradeAllInstancesModal_SidecarFragment on Sidecar {
    id
    version
    instances {
      id
      version
      recyclable
      versionIsDynamic
      isRecycling
    }
  }
`)

const UPGRADE_ALL_SIDECAR_INSTANCES = graphql(`
  mutation UpgradeAllSidecarInstances($sidecarId: ID!) {
    upgradeAllSidecarInstances(sidecarId: $sidecarId) {
      sidecar {
        id
        instances {
          id
          version
          health
          isRecycling
        }
      }
    }
  }
`)

interface Props {
  sidecar: FragmentType<typeof UpgradeAllInstancesModal_SidecarFragment>
}

const UpgradeAllInstancesModal = ({ sidecar: sidecarProp }: Props) => {
  const [open, setOpen] = useState(false)
  const sidecar = useFragment(UpgradeAllInstancesModal_SidecarFragment, sidecarProp)
  const { toast } = useToast()

  const [upgradeAllSidecarInstances, { loading: loadingUpgrade }] = useMutation(
    UPGRADE_ALL_SIDECAR_INSTANCES,
    {
      variables: { sidecarId: sidecar.id },
      onCompleted: () => {
        closeModal()
        toast({
          variant: 'info',
          description: 'Upgrade started for sidecar instances',
        })
      },
      onError: error => {
        toast({
          variant: 'error',
          description:
            getGraphQLErrorMessage(error) ||
            'An error occurred while upgrading instances. Please try again later.',
        })
      },
    }
  )

  const { instances, version: sidecarVersion } = sidecar
  const enableUpgradeAll = instances.some(instance => {
    const { canUpgrade } = instanceCanUpgrade({
      instance,
      sidecarVersion,
    })

    return canUpgrade
  })

  const closeModal = () => {
    setOpen(false)
  }

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        disabled={!enableUpgradeAll}
        onClick={() => setOpen(true)}
      >
        Upgrade all instances
      </Button>
      <Dialog open={open} onClose={closeModal} fullWidth>
        <DialogTitle
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h3" sx={{ color: 'text.primary' }}>
            Upgrade All Instances
          </Typography>
          <IconButton aria-label="close" onClick={closeModal}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Are you sure you want to upgrade all sidecar instances?
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              All sidecar instances will be upgraded to version:&nbsp;
              <InlineCode>{sidecar.version}</InlineCode>
            </Typography>
            <Typography variant="body2" sx={{ color: 'error.main' }}>
              NOTE: This sidecar will be unreachable until the upgrade is complete.{' '}
              <LearnMore docsPath="" useVersionedDocsURL />
            </Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Stack direction="row" spacing={2} sx={{ justifyContent: 'flex-end' }}>
            <Button variant="text" color="secondary" onClick={closeModal}>
              Cancel
            </Button>
            <Button
              variant="contained"
              loading={loadingUpgrade}
              color="primary"
              onClick={() => upgradeAllSidecarInstances()}
            >
              Upgrade
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default UpgradeAllInstancesModal
