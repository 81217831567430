import { useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'

import {
  Box,
  Divider,
  FormControl,
  Stack,
  FormHelperText,
  FormControlLabel,
  Checkbox,
  FormGroup,
} from '@mui/material'
import { useRepositoryDetailContext } from '@jeeves/pages/RepositoryDetail/contexts/RepositoryDetailContext'

import { Input, InputLabel } from '@jeeves/new-components'

import Footer from '@jeeves/pages/RepositoryDetail/Tabs/Policies/views/AddPolicy/components/Footer'

const StepTwo = ({ repositoryProtectionInfo, nextStep, prevStep, setRepositoryProtectionInfo }) => {
  const { repoId } = useRepositoryDetailContext()
  const {
    handleSubmit,
    watch,
    control,
    register,
    formState: { errors, isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      restrictedOperations: repositoryProtectionInfo.parameters.restrictedOperations,
      limit: repositoryProtectionInfo.parameters.limit,
    },
  })

  const onSubmit = data => {
    setRepositoryProtectionInfo(info => {
      return {
        ...info,
        parameters: {
          ...info.parameters,
          restrictedOperations: data.restrictedOperations,
          limit: data.limit,
        },
      }
    })
  }

  useEffect(() => {
    if (isSubmitSuccessful) {
      nextStep()
    }
  }, [isSubmitSuccessful, nextStep])

  const { restrictedOperations } = watch()

  const validateOperations =
    restrictedOperations.read || restrictedOperations.update || restrictedOperations.delete

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4}>
        <Stack spacing={4} sx={{ maxWidth: '32rem' }}>
          <FormControl fullWidth variant="standard">
            <InputLabel component="legend" required>
              Database Operations
            </InputLabel>

            <Controller
              name="restrictedOperations"
              control={control}
              rules={{
                validate: () => {
                  return validateOperations || 'Please select at least one operation'
                },
              }}
              render={() => (
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox {...register('restrictedOperations.update')} size="small" />}
                    label="Update"
                    checked={restrictedOperations.update}
                  />
                  <FormControlLabel
                    control={<Checkbox {...register('restrictedOperations.delete')} size="small" />}
                    label="Delete"
                    checked={restrictedOperations.delete}
                  />
                </FormGroup>
              )}
            />
            <FormHelperText sx={{ typography: 'body2' }} error={!!errors?.restrictedOperations}>
              {errors?.restrictedOperations?.message}
            </FormHelperText>
          </FormControl>

          <FormControl variant="standard" error={Boolean(errors?.limit)}>
            <InputLabel htmlFor="limit" required>
              Maximum number of rows that can be modified per query
            </InputLabel>
            <Input
              id="limit"
              placeholder="e.g. 20"
              type="number"
              inputProps={{
                ...register('limit', {
                  required:
                    'Please define the maximum number of rows that can be modified per query',
                  min: {
                    value: 1,
                    message: 'Please insert a number greater than zero',
                  },
                }),
              }}
            />
            <FormHelperText
              sx={{
                typography: 'body2',
              }}
              error={!!errors?.limit}
            >
              {errors?.limit?.message}
            </FormHelperText>
          </FormControl>
        </Stack>

        <Divider />

        <Footer repoId={repoId} prevStep={prevStep} policyId={repositoryProtectionInfo.id} />
      </Stack>
    </Box>
  )
}

export default StepTwo
