import { usePolicySummary } from '@jeeves/pages/RepositoryDetail/Tabs/Policies/components/PolicySummary/PolicySummaryContext'
import {
  getDisplayedPolicyIdentity,
  PolicyIdentityType,
} from '@jeeves/pages/RepositoryDetail/Tabs/Policies/helpers/policyIdentities'

export const useDataProtectionVariation = () => {
  const { policy } = usePolicySummary()

  const {
    action,
    restrictedOperations,
    dataLabels,
    identitiesAppliedTo,
    databaseAccountsAppliedTo,
  } = policy.parameters

  const actionMap = {
    alert: 'ALERT',
    alertAndBlock: 'ALERT and BLOCK',
  }

  const getIdentities = () => {
    const identities = identitiesAppliedTo.identities?.map(
      (identity: { __typename: PolicyIdentityType; name: string }) => {
        return getDisplayedPolicyIdentity(identity)
      }
    )
    return identities ?? []
  }

  const getOperations = () => {
    // Array of operations that are enabled
    const operations = Object.keys(restrictedOperations).filter(
      key => restrictedOperations[key] === true
    )
    return operations.map(operation => operation.toUpperCase())
  }

  return {
    action: actionMap[action as keyof typeof actionMap],
    operations: getOperations(),
    dataLabels,
    identities: getIdentities(),
    databaseAccounts: databaseAccountsAppliedTo.databaseAccounts,
  }
}
