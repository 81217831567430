import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const SQLServerIcon = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 48 48">
      <path
        fill="#cfd8dc"
        d="M 22.341 9.95 C 20.383 7.013 19.959 3.086 19.867 1.473 C 14.892 2.55 9.948 4.48 10.235 5.901 C 10.271 6.079 10.496 6.294 10.87 6.503 L 10.285 7.55 C 9.569 7.15 9.169 6.687 9.059 6.136 C 8.736 4.527 10.558 3.072 14.627 1.686 C 17.405 0.741 20.206 0.176 20.326 0.153 L 21.042 0.009 L 21.042 0.742 C 21.042 0.793 21.073 5.885 23.34 9.285 L 22.341 9.95 Z"
      />
      <path
        fill="#cfd8dc"
        d="M 24.341 48 L 24.041 48 C 14.215 48 3.279 44.915 1.987 39.749 C 1.199 36.595 3.776 33.109 9.44 29.673 L 10.063 30.697 C 4.967 33.79 2.512 36.9 3.151 39.459 C 4.274 43.943 14.636 46.737 23.725 46.799 C 24.806 45.184 30.613 35.692 27.299 22.963 L 28.459 22.661 C 32.236 37.163 24.599 47.653 24.522 47.758 L 24.341 48 Z"
      />
      <path
        fill="#cfd8dc"
        d="M 6.566 43.569 C 5.919 43.569 5.599 43.498 5.546 43.485 C 5.335 43.433 5.169 43.272 5.112 43.063 C 5.053 42.854 5.113 42.63 5.267 42.478 C 5.353 42.392 13.898 33.833 15.052 26.911 L 16.235 27.107 C 15.244 33.061 9.381 39.85 7.053 42.35 C 9.378 42.216 15.251 41.128 26.524 34.817 C 17.727 33.852 16.334 26.809 16.32 26.734 L 17.499 26.516 C 17.556 26.811 18.97 33.727 28.587 33.727 C 28.59 33.727 28.593 33.727 28.596 33.727 L 28.596 33.727 C 28.869 33.727 29.106 33.909 29.175 34.17 C 29.248 34.431 29.133 34.709 28.898 34.846 C 15.422 42.653 8.888 43.568 6.566 43.569 Z"
      />
      <path
        fill="#cfd8dc"
        d="M 12.075 46.441 C 11.826 46.441 11.601 46.288 11.514 46.051 C 11.418 45.798 11.505 45.511 11.726 45.353 C 11.766 45.323 13.9 43.748 16.171 40.282 C 15.298 39.896 14.414 39.444 13.537 38.918 C 12.471 38.278 11.667 37.593 11.065 36.889 C 7.983 37.703 5.138 38.024 2.64 37.811 L 2.74 36.618 C 4.99 36.808 7.548 36.542 10.322 35.85 C 8.435 32.626 10.168 29.425 10.192 29.382 L 11.239 29.967 C 11.159 30.113 9.723 32.809 11.539 35.523 C 14.179 34.766 16.995 33.644 19.909 32.186 C 20.58 29.986 21.104 27.482 21.378 24.659 C 21.397 24.465 21.509 24.293 21.676 24.197 C 21.844 24.1 22.049 24.091 22.225 24.171 C 22.263 24.19 26.141 25.96 28.946 27.115 C 29.151 27.199 29.293 27.389 29.316 27.608 C 29.34 27.828 29.24 28.041 29.056 28.165 C 26.31 30.037 23.583 31.66 20.927 33.014 C 20.084 35.683 19.042 37.905 17.977 39.72 C 22.417 41.379 26.271 41.404 26.32 41.404 C 26.594 41.404 26.832 41.589 26.901 41.854 C 26.969 42.118 26.851 42.397 26.612 42.529 C 21.166 45.555 12.497 46.407 12.132 46.441 C 12.113 46.44 12.093 46.441 12.075 46.441 Z M 17.334 40.763 C 16.087 42.7 14.861 44.09 13.945 44.999 C 16.34 44.645 20.543 43.873 24.03 42.432 C 22.36 42.227 19.942 41.772 17.334 40.763 Z M 12.366 36.523 C 12.838 36.993 13.425 37.452 14.154 37.89 C 15.036 38.419 15.931 38.87 16.816 39.252 C 17.72 37.735 18.616 35.915 19.385 33.773 C 16.962 34.926 14.607 35.846 12.366 36.523 Z M 22.483 25.605 C 22.237 27.725 21.859 29.66 21.387 31.423 C 23.379 30.361 25.412 29.148 27.46 27.793 C 25.602 27.006 23.559 26.092 22.483 25.605 Z"
      />
      <path
        fill="#cfd8dc"
        d="M 16.152 9.137 C 16.012 9.137 15.873 9.088 15.763 8.994 C 13.831 7.341 11.559 3.977 11.463 3.836 C 11.308 3.606 11.331 3.3 11.518 3.095 C 11.703 2.891 12.008 2.843 12.247 2.973 C 12.26 2.977 13.372 3.436 20.904 4.691 C 21.12 4.727 21.299 4.878 21.371 5.084 C 21.444 5.29 21.397 5.52 21.251 5.684 C 19.143 8.025 16.479 9.058 16.365 9.099 C 16.296 9.125 16.223 9.137 16.152 9.137 Z M 13.421 4.516 C 14.179 5.533 15.269 6.909 16.266 7.836 C 16.88 7.558 18.254 6.855 19.558 5.68 C 16.46 5.15 14.569 4.775 13.421 4.516 Z"
      />
      <path
        fill="#b71c1c"
        d="M 20.853 25.69 C 20.771 25.69 20.689 25.673 20.612 25.639 C 20.374 25.534 20.23 25.287 20.258 25.028 C 20.608 21.731 15.548 20.408 15.497 20.395 C 15.288 20.343 15.122 20.181 15.066 19.974 C 15.009 19.767 15.068 19.543 15.221 19.39 C 15.469 19.143 21.384 13.277 28.807 10.055 C 29.014 9.965 29.25 9.998 29.425 10.139 C 29.599 10.279 29.678 10.506 29.633 10.726 C 28.211 17.615 21.585 25.171 21.304 25.488 C 21.186 25.62 21.02 25.69 20.853 25.69 Z M 16.79 19.556 C 18.21 20.089 20.697 21.324 21.326 23.581 C 23.065 21.412 26.775 16.416 28.161 11.665 C 22.899 14.196 18.467 18.012 16.79 19.556 Z"
      />
      <path
        fill="#b71c1c"
        d="M 10.342 30.44 L 9.771 29.384 C 12.03 28.166 14.618 25.927 16.737 23.322 C 14.932 24.557 13.9 25.403 13.867 25.431 L 13.101 24.508 C 13.148 24.468 15.217 22.772 18.655 20.645 C 19.599 19.127 20.326 17.565 20.699 16.069 C 20.762 15.814 20.817 15.568 20.862 15.331 C 18.553 14.508 16.422 14.018 14.959 13.809 C 14.737 13.778 14.552 13.625 14.478 13.415 C 14.404 13.205 14.453 12.97 14.603 12.807 C 14.705 12.697 17.141 10.087 20.184 8.654 C 20.422 8.542 20.704 8.597 20.882 8.791 C 20.962 8.878 22.609 10.728 22.191 14.554 C 23.576 15.094 25.003 15.75 26.378 16.53 C 29.688 15.043 33.445 13.704 37.447 12.896 C 37.653 12.855 37.882 12.929 38.025 13.096 C 38.165 13.265 38.203 13.497 38.124 13.701 C 36.564 17.697 32.384 21.966 32.205 22.146 C 32.085 22.268 31.92 22.329 31.747 22.324 C 31.575 22.314 31.417 22.233 31.309 22.098 C 29.965 20.409 28.209 19.01 26.316 17.872 C 23.707 19.076 21.396 20.364 19.498 21.529 C 17.075 25.311 13.418 28.784 10.342 30.44 Z M 27.628 17.289 C 29.176 18.287 30.617 19.46 31.808 20.826 C 32.801 19.748 35.184 17.026 36.535 14.321 C 33.358 15.053 30.349 16.113 27.628 17.289 Z M 22 15.761 C 21.96 15.957 21.914 16.157 21.865 16.361 C 21.613 17.367 21.211 18.401 20.699 19.433 C 22.013 18.682 23.467 17.907 25.038 17.157 C 24.034 16.624 23.008 16.161 22 15.761 Z M 16.301 12.822 C 17.633 13.084 19.276 13.512 21.028 14.125 C 21.21 11.914 20.613 10.56 20.253 9.96 C 18.601 10.822 17.156 12.034 16.301 12.822 Z"
      />
      <path
        fill="#b71c1c"
        d="M 16.303 18.614 C 16.303 23.867 11.926 28.041 8.771 31.088 C 8.243 31.604 6.456 31.688 6.456 31.688 C 6.9 31.232 7.404 30.752 7.944 30.236 C 10.942 27.334 15.104 23.364 15.104 18.614 C 15.104 12.988 12.838 10.77 11.05 8.994 C 10.259 8.191 9.587 7.423 9.179 6.487 L 9.551 6.331 C 9.959 6.511 10.427 6.715 10.786 6.87 C 11.074 7.291 11.459 7.698 11.902 8.141 C 13.737 9.978 16.303 12.496 16.303 18.614 Z"
      />
      <path
        fill="#b71c1c"
        d="M 46.152 20.166 C 46.152 20.166 45.425 20.269 45.223 20.293 C 34.715 21.457 23.872 24.479 17.671 26.961 C 15.428 27.861 13.101 28.953 10.977 30.176 C 10.966 30.188 10.954 30.188 10.93 30.2 C 9.682 30.919 6.649 32.407 4.19 33.715 C 7.788 29.888 14.529 26.938 17.215 25.858 C 23.297 23.424 33.743 20.484 44.023 19.226 C 42.811 17.81 39.849 14.812 34.019 12.581 C 30.613 11.261 25.431 10.516 20.862 9.858 C 13.593 8.814 9.454 8.118 9.059 6.139 C 8.975 5.744 9.035 5.347 9.215 4.964 C 9.611 5.611 10.175 6.068 10.547 6.331 C 10.726 6.451 10.858 6.523 10.906 6.547 L 10.918 6.558 C 12.62 7.459 17.215 8.118 21.042 8.67 C 25.672 9.341 30.913 10.097 34.451 11.452 C 42.691 14.632 45.535 19.177 45.654 19.368 C 45.762 19.55 46.152 20.166 46.152 20.166 Z"
      />
    </SvgIcon>
  )
}

export default SQLServerIcon
