import { ChangeEvent, createContext, ReactNode, useContext, useState } from 'react'

import { SortDirection, UsersTableSortBy, PageSize } from './types'
import { rowsPerPageOptions } from './constants'

interface IUsersContext {
  pageIndex: number
  pageSize: PageSize
  searchValue: string
  sortDirection: SortDirection
  sortBy: UsersTableSortBy
  rowsPerPageOptions: number[]
  onSort: (sortBy: UsersTableSortBy) => void
  onRowsPerPageChange: (pageSize: number) => void
  onChangePage: (pageIndex: number) => void
  onSearch: (event: any) => void
}

const UsersContext = createContext<IUsersContext | null>(null)

const DEFAULT_PAGE_SIZE = rowsPerPageOptions[0]
const INITIAL_PAGE = 0

const UsersProvider = ({ children }: { children: ReactNode }) => {
  const [pageIndex, setPageIndex] = useState(INITIAL_PAGE)
  const [pageSize, setPageSize] = useState<PageSize>(DEFAULT_PAGE_SIZE)
  const [searchValue, setSearchValue] = useState('')
  const [sortDirection, setSortDirection] = useState<SortDirection>('asc')
  const [sortBy, setSortBy] = useState<UsersTableSortBy>('username')

  const onSort = (sortBy: UsersTableSortBy) => {
    setPageIndex(INITIAL_PAGE)
    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
    setSortBy(sortBy)
  }

  const onRowsPerPageChange = (pageSize: number) => {
    setPageIndex(INITIAL_PAGE)
    setPageSize(pageSize as PageSize)
  }

  const onChangePage = (newPage: number) => {
    setPageIndex(newPage)
  }

  const onSearch = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSearchValue(event.target.value.trim().toLowerCase())
    setPageIndex(INITIAL_PAGE)
  }

  return (
    <UsersContext.Provider
      value={{
        pageIndex,
        pageSize,
        rowsPerPageOptions,
        searchValue,
        sortDirection,
        sortBy,
        onSort,
        onRowsPerPageChange,
        onChangePage,
        onSearch,
      }}
    >
      {children}
    </UsersContext.Provider>
  )
}

const useUsers = () => {
  const usersContext = useContext(UsersContext)

  if (!usersContext) {
    throw new Error('useUsers has to be used within <UsersProvider>')
  }

  return usersContext
}

export { UsersProvider, useUsers }
