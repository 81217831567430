/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { useState, useEffect } from 'react'
import { IconButton, Fade } from '@material-ui/core/'
import EditIcon from '@material-ui/icons/Edit'

import { Button } from '@jeeves/components/Primitives'
import { ButtonLoading } from '@jeeves/components/Loading'
import { useAuth } from '@jeeves/components/Auth'
import {
  DeleteIntegrationButton,
  IntegrationCard,
  IntegrationEditActions,
  IntegrationFieldList,
  IntegrationHeader,
  IntegrationTitle,
  Label,
  LabelValuePair,
  TextField,
  Value,
} from '../../../primitives'

const View = ({ name, url, onDelete, setEditing }) => {
  const { hasPermission } = useAuth()

  return (
    <div>
      <IntegrationHeader>
        <IntegrationTitle gutterBottom component="h2">
          {name}
        </IntegrationTitle>
        <div css={{ flexGrow: 1 }} />
        <IconButton
          onClick={() => setEditing(true)}
          disabled={!hasPermission('integrations:update')}
        >
          <EditIcon />
        </IconButton>
        <DeleteIntegrationButton
          onDelete={onDelete}
          disabled={!hasPermission('integrations:delete')}
        />
      </IntegrationHeader>
      <LabelValuePair>
        <Label>Webhook URL</Label>
        <Value>{url}</Value>
      </LabelValuePair>
    </div>
  )
}

const IntegrationForm = ({ integration = null, onSave, onDelete, onCancel, creating = false }) => {
  const [name, setName] = useState('')
  const [url, setUrl] = useState('')
  const [editing, setEditing] = useState(false)
  const [saving, setSaving] = useState(false)

  const configureIntegration = () => {
    if (integration) {
      setName(integration.integration.name)
      setUrl(integration.integration.url)
    } else {
      setName('')
      setUrl('')
    }
  }

  const configureEditing = () => {
    if (creating) {
      setEditing(true)
    }
  }

  useEffect(() => {
    configureIntegration()
    configureEditing()
  }, []) // eslint-disable-line

  const handleCancelEditing = () => {
    setEditing(false)

    if (onCancel) {
      onCancel()
    }
  }

  const handleOnSave = async () => {
    setSaving(true)
    try {
      await onSave({ name, url })
    } catch (e) {
      console.log({ e })
    } finally {
      setSaving(false)
      setEditing(false)
    }
  }

  return (
    <IntegrationCard>
      {!editing && (
        <Fade in={!editing}>
          <View name={name} url={url} onDelete={onDelete} setEditing={setEditing} />
        </Fade>
      )}
      {editing && (
        <Fade in={editing}>
          <div>
            <IntegrationFieldList>
              <TextField
                fullWidth
                label="Name"
                value={name}
                onChange={e => setName(e.target.value)}
              />
              <TextField
                fullWidth
                label="Webhook URL"
                value={url}
                onChange={e => setUrl(e.target.value)}
              />
            </IntegrationFieldList>
            <IntegrationEditActions>
              <Button onClick={handleCancelEditing} color="primary" disabled={saving}>
                Cancel
              </Button>
              <div css={theme => ({ position: 'relative', marginLeft: theme.spacing[5] })}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={saving}
                  onClick={handleOnSave}
                >
                  Save
                </Button>
                {saving && <ButtonLoading />}
              </div>
            </IntegrationEditActions>
          </div>
        </Fade>
      )}
    </IntegrationCard>
  )
}

export default IntegrationForm
