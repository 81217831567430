import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'

import { InstanceDetails, InstancesMain } from './views'

const Instances = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route path={path} exact>
        <InstancesMain />
      </Route>

      <Route path={`${path}/:instanceId`}>
        <InstanceDetails />
      </Route>

      <Redirect to={path} />
    </Switch>
  )
}

export default Instances
