/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { NavLink } from 'react-router-dom'
import { Typography } from '@material-ui/core'
import { getDocsURL } from '@jeeves/utils/docsURL'

export default props => (
  <Typography
    variant="caption"
    css={theme =>
      css`
        margin-top: ${theme.spacing[3]};
        margin-right: 4px;
      `
    }
    {...props}
  >
    Learn more about building policies and how they work&nbsp;
    <a
      href={getDocsURL({ docsPath: 'policy/overview' })}
      target="_blank"
      rel="noopener noreferrer"
      css={{ textDecoration: 'none', color: '#26459e' }}
    >
      <strong>here</strong>
    </a>
    .
  </Typography>
)
