import styled from '@emotion/styled'
import { withStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { blueGrey } from '@material-ui/core/colors'

export const CodeSnippetStyles = theme => ({
  fontFamily: theme.typography.monospaced,
  background: blueGrey[800],
  color: blueGrey[100],
  padding: '12px',
  maxWidth: 'auto',
  whiteSpace: 'pre-wrap',
  borderRadius: '8px',
})

export const CodeSnippet = styled(Typography)(props => CodeSnippetStyles(props.theme))

export const CodeSpan = styled(CodeSnippet)`
  display: inline-block;
  padding: 6px 8px;
`
