import { DialogContent, DialogTitle, Stack, Typography, IconButton, Box } from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'
import pluralize from 'pluralize'

import { Button, Dialog, DialogActions } from '@jeeves/new-components'

interface Props {
  modalOpen: boolean
  ssoGroups: string[]
  closeModal: () => void
}

const SsoGroupsModal = ({ modalOpen, ssoGroups, closeModal }: Props) => {
  const numberOfSsoGroups = ssoGroups.length

  return (
    <Dialog open={modalOpen} onClose={closeModal} fullWidth>
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
        }}
      >
        <Stack spacing={2}>
          <Typography variant="h3" sx={{ color: 'text.primary' }}>
            SSO Groups
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            This user is a member of {numberOfSsoGroups} SSO {pluralize('group', numberOfSsoGroups)}
            .
          </Typography>
        </Stack>
        <IconButton aria-label="close" onClick={closeModal}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Box
          sx={{
            border: 1,
            borderColor: 'cyralColors.grey.200',
            borderRadius: theme => theme.radii.base,
            maxHeight: '352px',
            overflowY: 'auto',
          }}
        >
          {ssoGroups.map(ssoGroup => (
            <Box
              key={ssoGroup}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                padding: 3,
                '&:not(:last-child)': {
                  borderBottom: 1,
                  borderColor: 'cyralColors.grey.200',
                },
              }}
            >
              <Typography variant="h6" sx={{ color: 'text.primary' }}>
                {ssoGroup}
              </Typography>
            </Box>
          ))}
        </Box>
      </DialogContent>

      <DialogActions>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant="contained" color="primary" onClick={closeModal}>
            Close
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default SsoGroupsModal
