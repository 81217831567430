import { useState, useEffect } from 'react'

import { useAuth } from '@jeeves/components/Auth'
import ExpressClient from '@jeeves/clients/express'
import { get as lodashGet, cloneDeep } from 'lodash'
import usePopup from '@jeeves/components/PopupMessage/hooks/usePopup'

// TO DO: do not fetch this stuff every time the tab is switched, should fetch everything once when repo details is opened, funnel all get and post calls through one repo context, load entire repo details page on update/load like on logs tab

// This is not the best way to do this, but it's temporary and will go away once we convert to GraphQL
const SECRET_PATH_KEYS = {
  awsSecretsManager: 'secretARN',
  kubernetesSecret: 'secretName',
  gcpSecretManager: 'secretName',
  cyralStorage: 'password',
  awsIAM: 'roleARN',
  hashicorpVault: 'path',
  environmentVariable: 'variableName',
}
export const getStorageMethod = repoAccount =>
  Object.keys(repoAccount?.repoAccount)?.find(repoAccountKey =>
    Object.keys(SECRET_PATH_KEYS).includes(repoAccountKey)
  )

const useRepoAccounts = (repo, onSubmit) => {
  const { showError } = usePopup()
  const { getTokenSilently } = useAuth()
  const ec = new ExpressClient(getTokenSilently)
  const [repoAccounts, setRepoAccounts] = useState(repo.repoAccounts)

  const REPO_ACCOUNT_ID_ENDPOINT = `/repos/${repo.id}/repoAccounts`

  const createRepoAccount = async repoAccount => {
    try {
      await ec.post(REPO_ACCOUNT_ID_ENDPOINT, repoAccount)
      onSubmit()
    } catch (e) {
      const popupMessage = lodashGet(e, 'response.data.error.message', '')
      showError(popupMessage)
      // throw Error(popupMessage)
    }
  }

  const updateRepoAccount = async (repoAccountUUID, repoAccount) => {
    try {
      await ec.put(`${REPO_ACCOUNT_ID_ENDPOINT}/${repoAccountUUID}`, repoAccount)
      onSubmit()
    } catch (e) {
      const popupMessage = lodashGet(e, 'response.data.error.message', '')
      showError(popupMessage)
      // throw Error(popupMessage)
    }
  }

  const deleteRepoAccount = async repoAccountUUID => {
    try {
      ec.delete(`${REPO_ACCOUNT_ID_ENDPOINT}/${repoAccountUUID}`)
      onSubmit()
    } catch (e) {
      const popupMessage = lodashGet(e, 'response.data.error.message', '')
      showError(popupMessage)
      // throw Error(popupMessage)
    }
  }

  // useEffect(() => {
  //     getRepoAccounts().then(res => setRepoAccounts(transformRepoAccounts(res)))
  // }, [])

  // const getRepoAccounts = async () => {
  //     try {
  //         return (await ec.get(`/repos/${repo.id}/repoAccounts`)).data
  //     } catch(e) {
  //         const popupMessage = lodashGet(e, 'response.data.error.message', '')
  //         showError(popupMessage)
  //         throw Error(popupMessage)
  //     }
  // }

  // function transformRepoAccounts(repoAccounts){
  //     return repoAccounts.map(repoAccount => ({
  //         id: repoAccount.uuid,
  //         username: repoAccount.repoAccount[Object.keys(repoAccount.repoAccount)[0]].repoAccount,
  //         secretsManager: Object.keys(repoAccount.repoAccount)[0]
  //     }))
  // }

  useEffect(() => {
    setRepoAccounts(repo.repoAccounts)
  }, [repo])

  return {
    repoAccounts,
    createRepoAccount,
    updateRepoAccount,
    deleteRepoAccount,
  }
}

export default useRepoAccounts
