/** @jsxRuntime classic */
/** @jsx jsx */
import { useState, useEffect, Fragment, useLayoutEffect } from 'react'
import { jsx, css } from '@emotion/react'
import { withStyles, Typography } from '@material-ui/core'
import { Link, useHistory, useParams, Redirect } from 'react-router-dom'
import { Paper, Button } from '@jeeves/components/Primitives'
import Loading, { IntermediateLoading } from '@jeeves/components/Loading'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { red, green, yellow, grey } from '@material-ui/core/colors'
import Header from '@jeeves/components/Dashboard/Header'
import { PlainLayout } from '@jeeves/components/Dashboard'
import PopupMessage from '@jeeves/components/PopupMessage'
import { useAuth } from '@jeeves/components/Auth'
import { get as lodashGet } from 'lodash'
import { usePopup, useMount } from '@jeeves/hooks'
import ExpressClient from '@jeeves/clients/express'
import qs from 'qs'
import Status from '@jeeves/pages/Wrappers/components/Status'
import { ServiceIcon } from '@jeeves/components/ServiceIcon'
import { parse, end, toSeconds, pattern } from 'iso8601-duration'
import { getStorageMethod } from '@jeeves/pages/RepositoryDetail/Tabs/RepositoryAccounts/hooks/useRepoAccounts'
import humanizeDuration from 'humanize-duration'
const HD_OPTIONS = {
  round: true,
}

const styles = theme => ({
  appBarSpacer: theme.mixins.toolbar,
  label: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: 'rgba(0, 0, 0, 0.4)',
  },
  link: {
    textDecoration: 'none',
    cursor: 'pointer',

    ['&:hover']: {
      textDecoration: 'underline',
    },
  },
  deleteButton: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
    // border: `1px solid ${theme.palette.error.main}`,
  },
})

// TO DO: componentize this

const Pair = withStyles(styles)(({ label, value, valueError, classes }) => {
  return (
    <div css={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
      <Typography className={classes.label}>{label}</Typography>
      <Typography
        css={t => ({ color: valueError ? t.palette.error.main : 'black' })}
        className={classes.value}
      >
        {value}
      </Typography>
    </div>
  )
})

const NewAccessRequests = ({ isRevoke, location, classes }) => {
  const { user, getTokenSilently } = useAuth()
  const ec = new ExpressClient(getTokenSilently)
  const { setPopup, popupTypes, showError } = usePopup()
  const queryString = location ? location.search : ''
  const { repoID, userName: identityName, repoAccountUUID, accessDuration } = qs.parse(
    queryString,
    { ignoreQueryPrefix: true }
  )
  const [repo, setRepo] = useState(null)
  const [repoAccount, setRepoAccount] = useState(null)
  const [loading, setLoading] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [approved, setApproved] = useState(false)
  const [revoked, setRevoked] = useState(false)
  const [alreadyRevoked, setAlreadyRevoked] = useState(false)
  const history = useHistory()

  const fetchRepo = async () => {
    try {
      const repos = await ec.get('/repos/repoAccounts').then(res => res.data)
      const foundRepo = repos.find(repo => repo.id === repoID)
      if (!foundRepo) throw 'Repo not found for that ID.'
      setRepo(foundRepo)
      const foundRepoAccount = foundRepo.repoAccounts.find(
        repoAccount => repoAccount.uuid === repoAccountUUID
      )
      if (!foundRepoAccount) throw 'Local account not found for that UUID.'
      setRepoAccount(foundRepoAccount)
    } catch (e) {
      // TO DO: standardize all error handling
      showError(e)
      throw e
    }
  }

  const fetchIdentityMap = async () => {
    try {
      setLoading(true)
      const identityMapResponse = await ec
        .get(`/repos/${repoID}/identityMaps/user/${identityName}/${repoAccountUUID}`)
        .then(res => res.data)
      if (identityMapResponse.message === "Doesn't exist") {
        setAlreadyRevoked(true)
        setRevoked(true)
      }
      setLoading(false)
    } catch (e) {
      // TO DO: standardize all error handling
      showError(e)
      throw e
    }
  }

  const redirectWithDelay = () => {
    setTimeout(() => {
      history.push('/home')
    }, 2000)
  }

  const handleSubmit = async isRevoke => {
    try {
      setSubmitting(true)
      if (isRevoke) {
        await ec.delete(`/repos/${repoID}/identityMaps/user/${identityName}/${repoAccountUUID}`)
        await ec.post('/repos/repoAccessRevoked', {
          repoName: repo.repo.name,
          userName: identityName,
          repoAccountUUID,
          repoAccountName: repoAccount.repoAccount[getStorageMethod(repoAccount)].repoAccount,
        })
        setSubmitting(false)
        setRevoked(true)
      } else {
        await ec.put(`/repos/${repoID}/identityMaps/user/${identityName}/${repoAccountUUID}`, {
          accessDuration,
        })
        await ec.post('/repos/repoAccessGranted', {
          repoID,
          repoName: repo.repo.name,
          userName: identityName,
          repoAccountUUID,
          repoAccountName: repoAccount.repoAccount[getStorageMethod(repoAccount)].repoAccount,
          accessDuration,
          alertBaseURL: window.location.origin,
        })
        setSubmitting(false)
        setApproved(true)
      }
    } catch (e) {
      // TO DO: standardize all error handling
      showError(e)
      throw e
    }
  }

  useEffect(() => {
    if (!repoID) {
      showError('repoID must be provided')
    } else if (!identityName) {
      showError('userName must be provided')
    } else if (!repoAccountUUID) {
      showError('repoAccountUUID must be provided')
    } else {
      fetchRepo()
    }
  }, [repoID])

  useEffect(() => {
    if (approved || revoked) redirectWithDelay()
  }, [approved, revoked])

  useLayoutEffect(() => {
    if (isRevoke) fetchIdentityMap()
  }, [isRevoke])

  // TO DO: main stuff should be abstracted away, same for token

  return (
    <PlainLayout>
      <div css={{ position: 'relative', display: 'flex', alignItems: 'center', height: '100%' }}>
        {!repo || !repoAccount || loading ? (
          <Loading></Loading>
        ) : (
          <Paper
            css={{
              position: 'relative',
              margin: 'auto',
              width: '600px',
              minHeight: approved || revoked ? '120px' : '391px',
              padding: '36px',
              transition: 'min-height 0.5s ease-out',
            }}
          >
            <div
              css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'absolute',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                left: '50%',
                opacity: approved || revoked ? 1 : 0,
                transition: 'opacity 0.5s ease-out',
              }}
            >
              <CheckCircleIcon fontSize="large" style={{ color: green[500] }}></CheckCircleIcon>
              <Typography css={{ marginLeft: '12px', fontSize: '1.5rem' }}>
                {isRevoke ? (alreadyRevoked ? 'Already revoked' : 'Revoked') : 'Approved'}
              </Typography>
            </div>
            {!approved && !revoked && (
              <Fragment>
                <IntermediateLoading loading={submitting}></IntermediateLoading>
                <Typography variant="h6" css={{ marginBottom: '24px' }}>
                  {isRevoke ? 'Revoke Access' : 'Approve Access Request'}
                </Typography>
                <div
                  css={{
                    marginBottom: '24px',
                    padding: '24px 24px 24px 78px',
                    border: '1px solid rgb(0, 0, 0, 0.15)',
                    borderRadius: '10px',
                    position: 'relative',
                  }}
                >
                  <ServiceIcon
                    type={repo.repo.type}
                    css={() => css`
                      max-width: 30px;
                      position: absolute;
                      left: 24px;
                      top: 24px;
                      height: 36px;
                      display: flex;
                      align-items: center;
                      margin-right: 0;
                    `}
                  ></ServiceIcon>
                  <div
                    css={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <Link to={`/repositories/${repo.id}`} className={classes.link}>
                      <Typography variant="h6">{repo.repo.name}</Typography>
                    </Link>
                  </div>
                  <div
                    css={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      padding: '12px calc(12px + .5rem)',
                      borderRadius: '5px',
                      backgroundColor: 'rgba(0, 0, 0, 0.05)',
                      margin: '12px 0',
                    }}
                  >
                    <div css={{ padding: '0 12px 0 0' }}>
                      <Pair label="Identity" value={identityName}></Pair>
                    </div>
                    <div css={{ padding: '0 12px' }}>
                      <Typography>{'\u2192'}</Typography>
                    </div>
                    <div css={{ padding: '0 0 0 12px' }}>
                      <Pair
                        label="Local Account"
                        value={repoAccount.repoAccount[getStorageMethod(repoAccount)].repoAccount}
                      ></Pair>
                    </div>
                  </div>
                  <div>
                    <Typography>
                      <strong>Duration: </strong>
                      {accessDuration === '' || accessDuration === 'P0D'
                        ? 'Unlimited'
                        : humanizeDuration(toSeconds(parse(accessDuration)) * 1000, HD_OPTIONS)}
                    </Typography>
                  </div>
                </div>
                <div css={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    variant="contained"
                    color={!isRevoke && 'primary'}
                    className={isRevoke && classes.deleteButton}
                    onClick={() => handleSubmit(isRevoke)}
                  >
                    {isRevoke ? 'Revoke' : 'Approve'}
                  </Button>
                </div>
              </Fragment>
            )}
          </Paper>
        )}
      </div>
    </PlainLayout>
  )
}

export default withStyles(styles)(NewAccessRequests)
