import * as React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { Button } from '@jeeves/new-components'

import { useUserAuthenticationContext } from '@jeeves/pages/RepositoryDetail/Tabs/UserAuthentication/contexts/UserAuthenticationContext'
import { useRepositoryDetailContext } from '@jeeves/pages/RepositoryDetail/contexts/RepositoryDetailContext'

const Header = () => {
  const { repoType } = useRepositoryDetailContext()
  const { isRegistering, setIsRegistering, isUpdating, setSelectedUserAccountId } =
    useUserAuthenticationContext()

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        borderBottom: 1,
        borderColor: 'cyralColors.grey.200',
        paddingBottom: 4,

        alignItems: 'center',
      }}
    >
      <Box>
        {isRegistering || isUpdating ? (
          <Typography variant="h3" sx={{ color: 'text.primary' }}>
            {isUpdating
              ? `Edit ${repoType === 's3' ? 'IAM Role' : 'Database Account'}`
              : `Register ${repoType === 's3' ? 'IAM Role' : 'Database Account'}`}
          </Typography>
        ) : (
          <Typography variant="body1" sx={{ color: 'text.secondary' }}>
            {`Specify ${
              repoType === 's3' ? 'IAM Roles' : 'database accounts'
            } that users are allowed to log in to based on their SSO
            username or group membership.`}
          </Typography>
        )}
      </Box>

      {!isRegistering && !isUpdating && (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <Button
            onClick={() => {
              setSelectedUserAccountId('')
              setIsRegistering(true)
            }}
          >
            {repoType === 's3' ? 'Register IAM Role' : 'Register Database Account'}
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default Header
