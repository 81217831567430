/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { LaunchRounded as LaunchIcon } from '@material-ui/icons'
import { Button, withStyles } from '@material-ui/core'
import useWatches from '@jeeves/pages/Watches/hooks/useWatches'
import useRepos from '@jeeves/pages/Repositories/hooks/useRepos'
import { CodeSnippet, CodeSnippetStyles } from './CodeSnippet'
import { ReposProvider } from '@jeeves/pages/Repositories/contexts/ReposContext'

const styles = theme => ({
  root: {
    ...CodeSnippetStyles(theme),
    '&:hover': {
      backgroundColor: '#1f292d',
    },
  },
  // disabled: {
  //   color: 'red'
  // }
})

const ViewLogsButtonContents = ({ onClick, classes, ...props }) => {
  const { handleViewLogs } = useWatches()
  const { logsLink } = useRepos()
  return (
    <Button
      classes={{ root: classes.root }}
      onClick={() => {
        handleViewLogs()
      }}
      disabled={!logsLink}
      {...props}
    >
      VIEW LOGS&nbsp;
      <span
        css={() => css`
          display: inline-flex;
        `}
      >
        <LaunchIcon />
      </span>
    </Button>
  )
}

export const ViewLogsButton = withStyles(styles)(({ ...props }) => (
  <ReposProvider>
    <ViewLogsButtonContents {...props}></ViewLogsButtonContents>
  </ReposProvider>
))
