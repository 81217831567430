/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import React from 'react'
import { jsx, css } from '@emotion/react'

import sortBy from 'lodash/sortBy'

import {
  ErrorState,
  IntegrationDialogContent,
  IntegrationLoading,
  ZeroState,
} from '@jeeves/pages/Integrations/primitives'

import { LoggingIntegration, LoggingSummary, NewLoggingIntegration } from './components'
import logo from './assets/loggingIcon.svg'

import { useLoggingIntegration } from './hooks'
import NewIntegrationDialog from './components/NewIntegrationDialog'

const Logging = () => {
  const { data, loading, error } = useLoggingIntegration()
  const [newIntegrationDialogOpen, setNewIntegrationDialogOpen] = React.useState(false)
  const [integration, setIntegration] = React.useState()

  const openNewIntegrationDialog = () => setNewIntegrationDialogOpen(true)

  const handleNewLoggingIntegration = () => {
    setIntegration()
    openNewIntegrationDialog()
  }

  if (loading) {
    return (
      <IntegrationDialogContent>
        <IntegrationLoading />
      </IntegrationDialogContent>
    )
  }

  if (error) {
    return (
      <IntegrationDialogContent>
        <ErrorState />
      </IntegrationDialogContent>
    )
  }

  const integrations = data.SIEMIntegrations
  const noIntegrations = integrations.length === 0

  if (noIntegrations) {
    return (
      <>
        <IntegrationDialogContent>
          <ZeroState />
          <NewLoggingIntegration handleNewLoggingIntegration={handleNewLoggingIntegration} />
        </IntegrationDialogContent>
        <NewIntegrationDialog
          isOpen={newIntegrationDialogOpen}
          onClose={() => setNewIntegrationDialogOpen(false)}
          integration={integration}
          setIntegration={setIntegration}
        />
      </>
    )
  }

  return (
    <>
      <IntegrationDialogContent>
        {sortBy(integrations, ['__typename', 'name']).map(integration => (
          <LoggingIntegration
            key={integration.id}
            integration={integration}
            onEdit={() => {
              setIntegration(integration)
              openNewIntegrationDialog()
            }}
          />
        ))}
        <NewLoggingIntegration handleNewLoggingIntegration={handleNewLoggingIntegration} />
      </IntegrationDialogContent>
      <NewIntegrationDialog
        isOpen={newIntegrationDialogOpen}
        onClose={() => setNewIntegrationDialogOpen(false)}
        integration={integration}
        setIntegration={setIntegration}
      />
    </>
  )
}

Logging.metadata = {
  name: 'Logging',
  summary: <LoggingSummary />,
  enabled: true,
  category: 'Logging',
  slug: 'logging',
  logo,
}

export default Logging
