import React, { forwardRef } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { getValidChildren } from '@jeeves/utils/children'

// TODO: Look into adding escape hatch for styling purposes
// TODO: Implement 'fluid', 'disabled', and 'readOnly' props

const InputGroup = forwardRef(
  ({ fluid, disabled, readOnly, children, size = 'medium', ...rest }, ref) => {
    const validChildren = getValidChildren(children)

    if (validChildren.length <= 1) {
      throw new Error('An input group component must have at least 2 children.')
    }

    validChildren.forEach(child => {
      // if (!styles) return

      // if (input && child.type.id === 'InputLeftElement') {
      //    Adds padding-left
      //   inputStyles.paddingStart = input.height ?? input.h
      // }

      // if (input && child.type.id === 'InputRightElement') {
      //    Adds padding-right
      //   inputStyles.paddingEnd = input.height ?? input.h
      // }
      // }
    })

    const transformedChildren = validChildren.map(child => {
      /**
       * Make it possible to override the InputGroup size and variant from children
       */
      const theming = {
        size: child.props?.size || size,
        // variant: child.props?.variant || props.variant,
      }

      return child.type.id !== 'Input'
        ? React.cloneElement(child, theming)
        : React.cloneElement(
            child,
            Object.assign(theming, child.props, {
              sx: {
                ...child.props?.sx,
              },
            })
          )
    })

    return (
      <Box
        // className={__className}
        ref={ref}
        sx={{
          display: 'flex',
          width: '100%',
          position: 'relative',

          '& > :not(:last-child)': {
            marginRight: '-1px',
            borderTopRightRadius: theme => theme.radii.none,
            borderBottomRightRadius: theme => theme.radii.none,
          },
          '& > :not(:first-child)': {
            marginLeft: '-1px',
            borderTopLeftRadius: theme => theme.radii.none,
            borderBottomLeftRadius: theme => theme.radii.none,
          },

          '& > .Mui-focused': {
            zIndex: 1,
          },
        }}
        {...rest}
      >
        {/* <StylesProvider value={styles}> */}
        {transformedChildren}
        {/* </StylesProvider> */}
      </Box>
    )
  }
)

const Text = ({ children, sx = [] }) => {
  return (
    <Typography
      variant="body2"
      component="span"
      sx={[
        {
          color: 'text.primary',
          bgcolor: 'cyralColors.grey.100',
          px: 1,
          border: 1,
          borderColor: 'cyralColors.grey.200',
          borderRadius: theme => theme.radii.base,

          flex: 'none',
          display: 'flex',
          alignItems: 'center',
          whiteSpace: 'nowrap',
        },
        // You cannot spread `sx` directly because `SxProps` (typeof sx) can be an array.
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {children}
    </Typography>
  )
}

InputGroup.Text = Text

export default InputGroup
