import * as React from 'react'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Collapse from '@mui/material/Collapse'

import { PendingRecommendationCard } from './PendingRecommendationCard'

import { RecommendationsContainer, RecommendationsHeader } from '../Recommendations.styles'

import IconButton from '@mui/material/IconButton'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import NotificationsIcon from '@mui/icons-material/Notifications'

export const PendingRecommendations = ({
  recommendations,
  repoType,
  repoId,
  recommendationStatus,
}) => {
  const [open, setOpen] = React.useState(false)
  return (
    <React.Fragment>
      <RecommendationsContainer
        sx={{
          mb: open ? 2 : 2,
          backgroundColor:
            recommendationStatus === 'RECOMMENDED'
              ? 'cyralColors.primary.100'
              : 'cyralColors.grey.100',
          pb: open ? 2 : 1,
        }}
      >
        <RecommendationsHeader sx={{ mb: open ? 1.5 : 1.5 }}>
          <Stack direction="row">
            <NotificationsIcon
              sx={{
                color:
                  recommendationStatus === 'RECOMMENDED'
                    ? 'cyralColors.primary.300'
                    : 'cyralColors.grey.300',
              }}
            ></NotificationsIcon>
            <Typography
              variant="h6"
              sx={{
                alignSelf: 'center',
                ml: 1,
                color:
                  recommendationStatus === 'RECOMMENDED' ? 'primary.main' : 'cyralColors.grey.400',
              }}
            >
              You have {recommendations.length}{' '}
              {recommendationStatus === 'RECOMMENDED' ? 'pending' : 'rejected'}{' '}
              {recommendations.length === 1 ? `suggestion.` : `suggestions.`}
            </Typography>
          </Stack>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </RecommendationsHeader>
        <Collapse in={open} timeout="auto" unmountOnExit>
          {recommendations.map(recommendation => (
            <PendingRecommendationCard
              recommendationStatus={recommendationStatus}
              repoId={repoId}
              repoType={repoType}
              recommendation={recommendation}
            ></PendingRecommendationCard>
          ))}
          {recommendationStatus === 'RECOMMENDED' && (
            <Typography variant="body2" sx={{ color: 'primary.main', mt: 2 }}>
              Suggested mappings can only be applied to one label at a time. Rejected suggestions
              cannot be reaccepted.
            </Typography>
          )}
          {recommendationStatus === 'DISMISSED' && (
            <Typography variant="body2" sx={{ color: 'cyralColors.grey.400', mt: 2 }}>
              Rejected suggestions can no longer be accepted.
            </Typography>
          )}
        </Collapse>
      </RecommendationsContainer>
    </React.Fragment>
  )
}
