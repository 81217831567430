import { Stack, Typography } from '@mui/material'
import { ShardedClusterFields } from './components'

const EditMongoDBShardedClusterRepoFields = () => {
  return (
    <Stack spacing={2}>
      <Typography variant="h4" sx={{ color: 'text.primary' }}>
        Sharded Cluster Settings
      </Typography>

      <ShardedClusterFields />
    </Stack>
  )
}

export default EditMongoDBShardedClusterRepoFields
