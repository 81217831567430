/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import React, { useState, useEffect, Fragment } from 'react'
import { Formik, Form } from 'formik'
import { withStyles } from '@material-ui/core/styles'

import { Paper } from '@jeeves/components/Primitives'

import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
  FileCopy as FileCopyIcon,
} from '@material-ui/icons'

import Card from '@material-ui/core/Card'
import InputAdornment from '@material-ui/core/InputAdornment'
import CardHeader from '@material-ui/core/CardHeader'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Avatar from '@material-ui/core/Avatar'
import { Typography } from '@material-ui/core'

import ExpressClient from '@jeeves/clients/express'
import axios from 'axios'
import { useAuth } from '@jeeves/components/Auth'
import usePopup from '@jeeves/components/PopupMessage/hooks/usePopup'
import { get as lodashGet } from 'lodash'
import { ButtonLoading } from '@jeeves/components/Loading'
import { CopyToClipboard } from 'react-copy-to-clipboard'

const styles = theme => ({
  picture: {
    width: 128,
    height: 'auto',
  },
  icon: {
    padding: theme.spacing.unit / 2,
  },
  actions: {
    padding: '24px',
  },
  content: {
    padding: '24px',
  },
  title: {
    fontSize: '24px',
    padding: '24px',
  },
  updateButton: {
    padding: theme.spacing.unit,
  },
  jwtTextField: {
    padding: 0,
  },
})

export const CopyViewTextField = ({ value, ...props }) => {
  const [visible, setVisible] = useState(false)
  return (
    <TextField
      css={{ height: '58.7px' }}
      variant="outlined"
      value={value}
      disabled
      type={visible ? 'text' : 'password'}
      InputProps={{
        startAdornment: (
          <InputAdornment
            position="start"
            css={{
              marginLeft: '-14px',
              height: '58.7px',
              maxHeight: '58.7px',
              marginRight: 0,
            }}
          >
            <CopyToClipboard text={value}>
              <Button
                variant="outlined"
                css={{
                  height: '100%',
                  borderRadius: '4px 0 0 4px',
                  borderLeft: 'none',
                  borderTop: 'none',
                  borderBottom: 'none',
                  backgroundColor: 'rgba(0,0,0,0)',
                }}
              >
                <FileCopyIcon></FileCopyIcon>
              </Button>
            </CopyToClipboard>
            <Button
              variant="outlined"
              css={{
                height: '100%',
                borderRadius: 0,
                borderLeft: 'none',
                borderTop: 'none',
                borderBottom: 'none',
                marginLeft: '-1px',
                backgroundColor: visible ? 'rgba(0, 0, 0, 0.12)' : 'rgba(0,0,0,0)',
              }}
              onClick={() => setVisible(prevVisible => !prevVisible)}
            >
              {visible ? (
                <VisibilityOffIcon></VisibilityOffIcon>
              ) : (
                <VisibilityIcon></VisibilityIcon>
              )}
            </Button>
          </InputAdornment>
        ),
      }}
      inputProps={{
        style: {
          paddingLeft: '14px',
          backgroundColor: 'rgba(0,0,0,0.12)',
          borderRadius: '0 4px 4px 0',
        },
      }}
      {...props}
    ></TextField>
  )
}

const JWT = ({ classes, className, user, onUpdate, secondaryButton }) => {
  const { getTokenSilently } = useAuth()
  const ec = new ExpressClient(getTokenSilently)
  const [jwt, setJwt] = useState('')
  const { showError } = usePopup()

  useEffect(() => {
    const getToken = async () => {
      try {
        setJwt(await getTokenSilently())
      } catch (e) {
        const popupMessage = lodashGet(e, 'response.data.error.message', '')
        showError(popupMessage)
        throw Error(popupMessage)
      }
    }
    getToken()
  }, [])

  // For dev only
  // setInterval(() => {
  //   const getToken = async () => {
  //     try {
  //       setJwt(await getTokenSilently())
  //     } catch (e) {
  //       const popupMessage = lodashGet(e, 'response.data.error.message', '')
  //       showError(popupMessage)
  //       throw Error(popupMessage)
  //     }
  //   }

  //   getToken()
  // }, 60000 * 5)

  return (
    <Fragment>
      <div css={{ padding: '24px' }}>
        <Typography variant="h6">API Access</Typography>
        <Typography css={{ fontSize: '1rem' }}>
          Use this JWT as a bearer token to make authenticated API requests.
        </Typography>
      </div>
      <CardContent className={classes.content} css={{ paddingTop: 0 }}>
        <Grid container spacing={32}>
          <Grid item xs={12}>
            <Grid container spacing={24} alignItems="center">
              {/* <Grid item xs={2}>
                <Typography css={{ fontWeight: 'bold', textAlign: 'right' }}>JWT</Typography>
              </Grid> */}
              <Grid item xs={12}>
                <CopyViewTextField value={jwt} fullWidth></CopyViewTextField>
              </Grid>

              {/* <Grid item xs={12}>
                      <TextField
                        label="Avatar"
                        name="picture"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.picture}
                        fullWidth
                      />
                    </Grid> */}
            </Grid>
          </Grid>

          {/* <Grid item xs={3}>
                  <Avatar alt="Name" src={values.picture} className={classes.picture} />
                </Grid> */}
        </Grid>
      </CardContent>
    </Fragment>
  )
}

export default withStyles(styles)(JWT)
