import { Box, Stack, Typography, InputAdornment } from '@mui/material'
import { Button, Input, InputLabel, IconCopyButton, InlineCode } from '@jeeves/new-components'
import { getRelativeTime } from '@jeeves/utils/duration'

interface Props {
  handleDone: () => void
  name: string
  expiration: string
  token: string
}

const NewAccessTokenInfo = ({ handleDone, name, expiration, token }: Props) => {
  return (
    <Stack spacing={4}>
      <Stack spacing={2}>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          Please store the token in a secure place before dismissing this dialog.{' '}
          <Typography component="b" variant="h6" sx={{ color: 'error.main' }}>
            It will not be shown again.
          </Typography>
        </Typography>

        {name && (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <InputLabel htmlFor="name">Name</InputLabel>
            <Input
              id="name"
              sx={{
                fontFamily: theme => theme.typography.code,
              }}
              value={name}
              type="text"
            />
          </Box>
        )}

        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <InputLabel htmlFor="accessToken">Access Token</InputLabel>
          <Input
            id="accessToken"
            sx={{
              fontFamily: theme => theme.typography.code,
            }}
            value={token}
            type="text"
            endAdornment={
              <InputAdornment position="end">
                <IconCopyButton text={token} />
              </InputAdornment>
            }
          />
        </Box>
      </Stack>
      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        This token will expire in&nbsp;<InlineCode>{getRelativeTime(expiration)}</InlineCode>
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
        <Button variant="contained" color="primary" onClick={handleDone}>
          Done
        </Button>
      </Box>
    </Stack>
  )
}

export default NewAccessTokenInfo
