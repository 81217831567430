/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import React from 'react'
import Menu from '@material-ui/core/Menu'
import Notes from '@material-ui/icons/Notes'

import { MenuItem } from './MenuItem'
import { useAuth } from '@jeeves/hooks'
import ExpressClient from '@jeeves/clients/express'

export default function LogsMenu({ iconOnly, primaryText }) {
  const [elkIntegrations, setElkIntegrations] = React.useState([])
  const [anchorEl, setAnchorEl] = React.useState(null)
  const { getTokenSilently } = useAuth()
  const ec = new ExpressClient(getTokenSilently)

  const fetchElkIntegrations = async () => {
    try {
      const integrations = await ec.get('/integrations/elk').then(res => res.data)
      setElkIntegrations(integrations)
    } catch (e) {
      console.error(e)
    }
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  React.useEffect(() => {
    fetchElkIntegrations()
  }, [])

  if (elkIntegrations.length === 0) {
    return null
  }

  if (elkIntegrations.length === 1) {
    return (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={elkIntegrations[0].integration.kibanaUrl}
        css={() => css`
          text-decoration: none;
          background-color: grey;
        `}
      >
        <MenuItem
          css={{ backgroundColor: '#061748' }}
          color="inherit"
          listItemIcon={<Notes fontSize="small" />}
          iconOnly={iconOnly}
          isExternal
          primaryText={primaryText}
          onClick={() => document.activeElement.blur()}
        ></MenuItem>
      </a>
    )
  }

  return (
    <div>
      <MenuItem
        listItemIcon={<Notes fontSize="small" />}
        iconOnly={iconOnly}
        primaryText={primaryText}
        aria-owns={open ? 'logs-menu' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        color="inherit"
      ></MenuItem>

      <Menu
        id="logs-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ horizontal: 'right' }}
        autoFocus={false}
      >
        {elkIntegrations &&
          elkIntegrations.map(elkIntegration => (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={elkIntegration.integration.kibanaUrl}
              css={() => css`
                text-decoration: none;
                background-color: grey;
              `}
            >
              <MenuItem
                css={{ backgroundColor: '#061748' }}
                primaryText={elkIntegration.integration.name}
                color="inherit"
                iconOnly={iconOnly}
                onClick={handleClose}
              ></MenuItem>
            </a>
          ))}
      </Menu>
    </div>
  )
}
