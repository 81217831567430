import React, { useState, useEffect } from 'react'
import ExpressClient from '@jeeves/clients/express'
import { useAuth } from '@jeeves/components/Auth'

const ReposContext = React.createContext([{}, () => {}])

const ReposProvider = props => {
  const { getTokenSilently, user } = useAuth()
  const ec = new ExpressClient(getTokenSilently)
  const [state, setState] = useState({
    repos: undefined,
    logsLink: '',
    crudCount: 0,
    page: 0,
    rowsPerPage: 25,
    order: 'asc',
    orderBy: 'name',
    expanded: [],
    renameError: '',
  })
  useEffect(() => {
    const fetchData = async () => {
      const logPath = await ec
        .get('/integrations/first-kibana')
        .then(result => {
          return result.data
        })
        .then(path => {
          return path || ''
        })
        .catch(err => {
          console.error(`Primary.getLogLink.error: ${JSON.stringify(err)}`)
          return ''
        })
      setState(state => ({ ...state, logsLink: logPath }))
    }

    fetchData()
  }, [])

  return <ReposContext.Provider value={[state, setState]}>{props.children}</ReposContext.Provider>
}

export { ReposContext, ReposProvider }
