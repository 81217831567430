import React from 'react'
import { NavLink } from 'react-router-dom'
import { MenuList } from '@material-ui/core'
import SettingsIcon from '@material-ui/icons/Settings'
import PeopleIcon from '@material-ui/icons/People'
import FavoriteIcon from '@material-ui/icons/Favorite'
import VpnKeyIcon from '@material-ui/icons/VpnKey'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'

import { useAppConfig, useEntitlements } from '@jeeves/hooks'
import { useAuth } from '../Auth'
import { MenuItem } from './components/MenuItem'

const SixthSection = ({ iconOnly }) => {
  const { hasPermission } = useAuth()
  const { config } = useAppConfig()
  const { enableSettings } = useEntitlements()

  return (
    <MenuList>
      <Stack spacing="16px">
        <Box>
          {enableSettings && (
            <MenuItem
              component={NavLink}
              to="/settings"
              iconOnly={iconOnly}
              primaryText="Settings"
              listItemIcon={<SettingsIcon fontSize="small" />}
            />
          )}

          <MenuItem
            component={NavLink}
            to="/account/users"
            disabled={!hasPermission('users:read')}
            iconOnly={iconOnly}
            primaryText="Cyral Users"
            listItemIcon={<PeopleIcon fontSize="small" />}
          />

          <MenuItem
            component={NavLink}
            to="/serviceAccounts"
            iconOnly={iconOnly}
            primaryText="Cyral API Keys"
            listItemIcon={<VpnKeyIcon fontSize="small" />}
          />
        </Box>

        {config && config.helpType === 'support' && (
          <MenuItem
            component={NavLink}
            to="/help"
            disabled={!hasPermission('support:read')}
            iconOnly={iconOnly}
            primaryText="Docs &amp; Support"
            listItemIcon={<FavoriteIcon fontSize="small" />}
          />
        )}
      </Stack>
    </MenuList>
  )
}

export default SixthSection
