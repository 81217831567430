import * as React from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { flexRender } from '@tanstack/react-table'
import { useVirtual } from 'react-virtual'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import SearchOffIcon from '@mui/icons-material/SearchOff'

import { TableContainer } from './GrantedApprovalsTable.styles'
import useGrantedApprovalsTable from './useGrantedApprovalsTable'

const GrantedApprovalsTable = ({
  grantedApprovals,
  loadNextPage,
  loadingNextPage,
  refetching,
  hasNextPage,
  grantedApprovalsExist,
}) => {
  const { url } = useRouteMatch()
  const history = useHistory()
  const table = useGrantedApprovalsTable({ data: grantedApprovals })

  //we need a reference to the scrolling element for logic down below
  const tableContainerRef = React.useRef()

  const { rows } = table.getRowModel()

  const estimateSize = React.useCallback(() => {
    return 52
  }, [])

  const rowVirtualizer = useVirtual({
    size: rows.length,
    parentRef: tableContainerRef,
    estimateSize,
    overscan: 1,
  })

  const { virtualItems: virtualRows, totalSize } = rowVirtualizer

  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0
  const paddingBottom =
    virtualRows.length > 0 ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0) : 0

  //called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
  const fetchMoreOnBottomReached = React.useCallback(
    containerRefElement => {
      if (!containerRefElement) return

      const { scrollHeight, scrollTop, clientHeight } = containerRefElement

      //once the user has scrolled within 300px of the bottom of the table, fetch more data if there is any
      if (scrollHeight - scrollTop - clientHeight < 300 && hasNextPage && !loadingNextPage) {
        loadNextPage()
      }
    },
    [loadNextPage, hasNextPage, loadingNextPage]
  )

  // Check on mount and after a fetch to see if the table is already scrolled to the bottom and immediately needs to fetch more data
  React.useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current)
  }, [fetchMoreOnBottomReached])

  return (
    <TableContainer
      ref={tableContainerRef}
      onScroll={e => fetchMoreOnBottomReached(e.target)}
      sx={{ overflowY: 'auto', height: '500px' }}
    >
      <Stack spacing={5}>
        <Box component="table" aria-label="custom pagination table">
          <Box component="thead" sx={{ position: 'sticky', top: 0 }}>
            {table.getHeaderGroups().map(headerGroup => (
              <Box
                key={headerGroup.id}
                component="tr"
                sx={{
                  '& th:first-of-type': {
                    borderLeftWidth: '1px',
                    borderLeftStyle: 'solid',
                    borderTopLeftRadius: theme => theme.radii.base,
                    borderBottomLeftRadius: theme => theme.radii.base,
                  },

                  '& th:last-of-type': {
                    borderRightWidth: '1px',
                    borderRightStyle: 'solid',
                    borderTopRightRadius: theme => theme.radii.base,
                    borderBottomRightRadius: theme => theme.radii.base,
                  },
                }}
              >
                {headerGroup.headers.map(header => (
                  <Box key={header.id} component="th" scope="col" sx={{ whiteSpace: 'nowrap' }}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                  </Box>
                ))}
              </Box>
            ))}
          </Box>
          <Box component="tbody">
            {paddingTop > 0 && (
              <Box component="tr">
                <Box component="td" sx={{ height: paddingTop }} />
              </Box>
            )}
            {virtualRows.map(virtualRow => {
              const row = rows[virtualRow.index]

              return (
                <Box
                  key={row.id}
                  component="tr"
                  onClick={() => history.push(`${url}/${row.original.id}`)}
                  sx={{
                    cursor: 'pointer',

                    '&:hover': {
                      bgcolor: 'cyralColors.grey.100',
                    },
                  }}
                >
                  {row.getVisibleCells().map(cell => (
                    <Box key={cell.id} component="td" sx={{ py: 2 }}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </Box>
                  ))}
                </Box>
              )
            })}
            {paddingBottom > 0 && (
              <Box component="tr">
                <Box component="td" sx={{ height: paddingBottom }} />
              </Box>
            )}
          </Box>
        </Box>

        <EmptyState
          grantedApprovalsExist={grantedApprovalsExist}
          grantedApprovals={grantedApprovals}
          refetching={refetching}
        />
      </Stack>
    </TableContainer>
  )
}

const EmptyState = ({ grantedApprovals, grantedApprovalsExist, refetching }) => {
  if (!grantedApprovalsExist) {
    return (
      <Typography variant="body2" sx={{ color: 'text.secondary', alignSelf: 'center' }}>
        No granted approvals.
      </Typography>
    )
  }

  if (refetching) {
    return (
      <Stack spacing={2} sx={{ alignItems: 'center' }}>
        <CircularProgress size={64} />
        <Typography variant="h6" sx={{ color: 'text.secondary' }}>
          Searching...
        </Typography>
      </Stack>
    )
  }

  if (grantedApprovals.length === 0) {
    return (
      <Stack spacing={2} sx={{ alignItems: 'center' }}>
        <SearchOffIcon color="secondary" sx={{ fontSize: 64 }} />

        <Typography variant="h6" sx={{ color: 'text.secondary' }}>
          No results found.
        </Typography>
      </Stack>
    )
  }

  return null
}

export default GrantedApprovalsTable
