import {
  AwsIamIcon,
  AwsSecretsManagerIcon,
  EnvironmentVariableIcon,
  GcpSecretManagerIcon,
  HashiCorpVaultIcon,
  KubernetesSecretIcon,
} from '@jeeves/new-components'

export const authenticationMethods = [
  {
    label: 'AWS IAM',
    value: 'awsIAM',
    avatar: AwsIamIcon,
  },
]

const authenticationMethodsMap = {
  mongodb: ['awsIAM'],
  mysql: ['awsIAM'],
  postgresql: ['awsIAM'],
  s3: ['awsIAM'],
}

export const getAuthenticationMethods = repoType => {
  const authenticationMethodsVals = authenticationMethodsMap[repoType] ?? []
  return authenticationMethods.filter(authMethod =>
    authenticationMethodsVals?.includes(authMethod.value)
  )
}

export const credentialStorageTypes = [
  {
    label: 'Environment Variable',
    value: 'environmentVariable',
    avatar: EnvironmentVariableIcon,
  },
  {
    label: 'AWS Secrets Manager',
    value: 'awsSecretsManager',
    avatar: AwsSecretsManagerIcon,
  },
  {
    label: 'HashiCorp Vault',
    value: 'hashicorpVault',
    avatar: HashiCorpVaultIcon,
  },
  {
    label: 'Kubernetes Secret',
    value: 'kubernetesSecret',
    avatar: KubernetesSecretIcon,
  },
  {
    label: 'GCP Secret Manager',
    value: 'gcpSecretManager',
    avatar: GcpSecretManagerIcon,
  },
]

export const allCredentialTypes = [...credentialStorageTypes, ...authenticationMethods]

export const getCredentialsTypeMetadata = (credentials = {}) => {
  const { __typename } = credentials

  switch (__typename) {
    case 'AWSSecretsManagerUserAccountCredentials':
      return {
        ...credentialStorageTypes.find(item => item.value === 'awsSecretsManager'),
        secretIdentifiers: [
          {
            label: 'ARN',
            value: credentials.secretARN,
          },
        ],
      }
    case 'AWSIAMUserAccountCredentials':
      return {
        ...authenticationMethods.find(item => item.value === 'awsIAM'),
        secretIdentifiers: [
          {
            label: 'ARN',
            value: credentials.roleARN,
          },
        ],
      }
    case 'EnvironmentVariableUserAccountCredentials':
      return {
        ...credentialStorageTypes.find(item => item.value === 'environmentVariable'),
        secretIdentifiers: [
          {
            label: 'Variable Name',
            value: credentials.variableName,
          },
        ],
      }
    case 'GCPSecretManagerUserAccountCredentials':
      return {
        ...credentialStorageTypes.find(item => item.value === 'gcpSecretManager'),
        secretIdentifiers: [
          {
            label: 'Secret Name',
            value: credentials.secretName,
          },
        ],
      }
    case 'HashicorpVaultUserAccountCredentials':
      return {
        ...credentialStorageTypes.find(item => item.value === 'hashicorpVault'),
        secretIdentifiers: [
          {
            label: 'Secret Path',
            value: credentials.path,
          },
        ],
      }
    case 'KubernetesSecretUserAccountCredentials':
      return {
        ...credentialStorageTypes.find(item => item.value === 'kubernetesSecret'),
        secretIdentifiers: [
          {
            label: 'Secret Name',
            value: credentials.secretName,
          },
          {
            label: 'Key',
            value: credentials.secretKey,
          },
        ],
      }
    case 'CyralStorageUserAccountCredentials':
      return {
        label: 'Cyral Storage',
        value: 'cyralStorage',
        secretIdentifiers: [
          {
            label: 'Password',
            value: credentials.password,
          },
        ],
      }
    default:
      return
  }
}
