import { Stack } from '@mui/material'
import PolicyRule from '../../../../PolicyRule'
import { HighlightedTitle } from '@jeeves/pages/RepositoryDetail/Tabs/Policies/components/HighlightedTitle'
import { Variation1 } from '../Variation1'
import { useReadLimitVariation } from '../useReadLimitVariation'

const Variation3 = () => {
  const { databaseAccounts } = useReadLimitVariation()

  return (
    <Stack spacing={4}>
      <Variation1 />
      <PolicyRule
        title={
          <HighlightedTitle
            label1="These database accounts are"
            label2="exempt"
            label3="from this policy:"
          />
        }
        content={databaseAccounts}
      />
    </Stack>
  )
}

export default Variation3
