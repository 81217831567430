import { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Box, FormControl, FormHelperText, Stack } from '@mui/material'

import { Button, Input, InputLabel, Tag } from '@jeeves/new-components'

const TagsInput = ({ inputLabel, tags = [], isSubmitting, required = false, onAdd, onDelete }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext()

  const [currentTag, setCurrentTag] = useState('')
  const shouldDisableAddButton = isSubmitting || currentTag?.length === 0

  const handleTagDelete = tag => {
    const updatedTags = tags.filter(t => t !== tag)
    onDelete(updatedTags)
  }

  const handleAddTag = () => {
    const tagAlreadyExists = tags.some(element => element === currentTag)

    if (!tagAlreadyExists) {
      const updatedTags = [...tags, currentTag]

      onAdd(updatedTags)
    }
    setCurrentTag('')
  }

  return (
    <FormControl variant="standard" error={Boolean(errors?.tags)}>
      <Controller
        name="tags"
        control={control}
        render={() => (
          <Stack spacing={1}>
            {inputLabel && (
              <InputLabel required={required} htmlFor="tag-input">
                {inputLabel}
              </InputLabel>
            )}

            <Box
              sx={{
                border: 1,
                borderColor: 'cyralColors.grey.200',
                borderRadius: theme => theme.radii.base,
                height: '72px',
                padding: 1,
                overflowY: 'auto',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: 1,
                }}
              >
                {tags?.map((tag, idx) => (
                  <Tag key={`${tag}_${idx}`} onRemove={() => handleTagDelete(tag)}>
                    {tag}
                  </Tag>
                ))}
              </Box>
            </Box>

            <Stack direction="row" spacing={1} sx={{ alignItems: 'baseline' }}>
              <FormControl variant="standard" sx={{ flex: 1 }}>
                <Input
                  id="tag-input"
                  value={currentTag}
                  onChange={newValue => setCurrentTag(newValue.target.value)}
                />
              </FormControl>

              <Button
                disabled={shouldDisableAddButton}
                onClick={handleAddTag}
                variant="outlined"
                color="secondary"
              >
                Add
              </Button>
            </Stack>
          </Stack>
        )}
      />
      <FormHelperText sx={{ typography: 'body2' }}>{errors?.tags?.message}</FormHelperText>
    </FormControl>
  )
}

export default TagsInput
