/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useState } from 'react'
import styled from '@emotion/styled'
import { css, jsx } from '@emotion/react'
import {
  Button,
  CardActions,
  CircularProgress,
  Divider,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  MenuItem,
  Tooltip,
} from '@material-ui/core/'

import usePopup from '@jeeves/components/PopupMessage/hooks/usePopup'

const StyledTextField = ({ helpText = '', ...props }) => (
  <Tooltip title={helpText} placement="left">
    <TextField {...props} css={{ marginBottom: '15px' }}></TextField>
  </Tooltip>
)

const StyledButton = styled(Button)`
  box-shadow: none;
  border-radius: 20px;
  font-size: 12px;
  letter-spacing: 1px;
  margin-top: 12px;
  padding: 4px 0px;
  font-weight: bold;
  font-family: ${({ theme }) => theme.fontFamily};
`

StyledTextField.defaultProps = {
  variant: 'outlined',
  fullWidth: true,
}

const StyledCheckbox = ({ checked, onChange, value, label, helpText = '' }) => (
  <div>
    <Tooltip title={helpText} placement="left">
      <FormControlLabel
        control={<Checkbox checked={checked} onChange={onChange} value={value} color="primary" />}
        label={label}
      ></FormControlLabel>
    </Tooltip>
  </div>
)

const AddForm = ({
  addIntegration,
  handleRemoveAddField,
  name,
  tenantName,
  tenantNameWithRegion,
}) => {
  // standard configuration settings
  // view details here https://cyralinc.atlassian.net/wiki/spaces/EN/pages/1014923269/WIP+Generic+SAML+SSO+Integration+with+ForgeRock+Support
  // const [displayName, setDisplayName] = useState('')
  const [signInEndpoint, setSignInEndpoint] = useState('') //SSO URL required
  const [signOutEndpoint, setSignOutEndpoint] = useState('') //SLO URL
  const [ldapGroupAttribute, setLdapGroupAttribute] = useState('cn')
  const [cert, setCert] = useState('') //signing x.509 cert
  const [disableValidateSignature, setDisableValidateSignature] = useState(false) // disable validate signature

  //additional configuration settings

  const [backChannelLogout, setBackChannelLogout] = useState(false)
  const [disableWantsAuthnRequestsSigned, setDisableWantsAuthnRequestsSigned] = useState(false)
  const [disableWantsAssertionsSigned, setDisableWantsAssertionsSigned] = useState(false)
  const [wantsAssertionsEncrypted, setWantsAssertionsEncrypted] = useState(false)
  const [disableForceAuthentication, setDisableForceAuthentication] = useState(false)
  const [allowedClockSkew, setAllowedClockSkew] = useState(0)
  const [httpType, setHTTPType] = useState('HTTP-POST')

  const [loading, setLoading] = useState(false)
  const [addAttempted, setAddAttempted] = useState(false)
  const [showAdvanced, setShowAdvanced] = useState(false)

  const { setPopup, popupTypes } = usePopup()

  const isValidInput = () => {
    return signInEndpoint && cert
  }

  // If HTTP-Post is selected, all of the following fields will be false (the default value),
  // and if HTTP-Redirect is selected, all the following fields would be sent as True.
  const getHTTPType = () => (httpType === 'HTTP-POST' ? false : true)

  const handleOnAddIntegration = async () => {
    const integration = {
      identityProvider: 'forgerock',
      ldapGroupAttribute: ldapGroupAttribute,
      samlp: {
        providerID: 'saml',
        disabled: false,
        firstBrokerLoginFlowAlias: 'SAML_First_Broker',
        displayName: name,
        storeToken: false,
        addReadTokenRoleOnCreate: false,
        trustEmail: false,
        linkOnly: false,
        config: {
          disableUsingJWKSUrl: false,
          syncMode: 'FORCE',
          nameIDPolicyFormat: 'urn:oasis:names:tc:SAML:1.1:nameid-format:unspecified',
          principalType: 'SUBJECT',
          signatureType: 'RSA_SHA256',
          samlXmlKeyNameTranformer: 'KEY_ID',
          disableHidingOnLoginPage: true,
          backChannelSupported: backChannelLogout,
          disablePostBindingResponse: getHTTPType(),
          disablePostBindingAuthnRequest: getHTTPType(),
          disablePostBindingLogout: getHTTPType(),
          disableWantAuthnRequestsSigned: disableWantsAuthnRequestsSigned,
          disableWantAssertionsSigned: disableWantsAssertionsSigned,
          wantAssertionsEncrypted: wantsAssertionsEncrypted,
          disableForceAuthentication: disableForceAuthentication,
          disableValidateSignature,
          singleSignOnServiceURL: signInEndpoint,
          singleLogoutServiceURL: signOutEndpoint,
          xmlSigKeyInfoKeyNameTransformer: 'KEY_ID',
          signingCertificate: cert ? cert.trim() : cert,
          allowedClockSkew: allowedClockSkew,
        },
      },
    }
    try {
      setLoading(true)
      setAddAttempted(true)
      if (!isValidInput()) {
        const popupMessage = 'Missing required fields.'
        throw Error(popupMessage)
      }

      await addIntegration(integration)
      handleRemoveAddField()
    } catch {
    } finally {
      setLoading(false)
    }
  }

  return (
    <div>
      <Divider css={{ marginTop: '-15px', marginBottom: '15px', width: '100%' }} />

      <StyledTextField
        onChange={e => setSignInEndpoint(e.target.value.trim())}
        value={signInEndpoint}
        label="Sign-In URL"
        error={addAttempted && !signInEndpoint}
        helperText={addAttempted && !signInEndpoint && 'Sign-In URL is a required field'}
        helpText="URL used to send authentication requests."
      />

      <StyledTextField
        onChange={e => setSignOutEndpoint(e.target.value.trim())}
        value={signOutEndpoint}
        label="Sign-Out URL"
        helpText="URL used to send logout requests."
      />

      <StyledTextField
        onChange={e => setLdapGroupAttribute(e.target.value.trim())}
        value={ldapGroupAttribute}
        label="LDAP Group Attribute"
        helpText="LDAP Group Attribute corresponds to the LDAP Groups Search Attribute in ForgeRock's Identity Store."
      />

      <StyledTextField
        multiline
        rows={8}
        disabled={disableValidateSignature}
        label="X.509 Certificate"
        onChange={e => setCert(e.target.value)}
        value={cert}
        error={addAttempted && !cert}
        helperText={addAttempted && !cert && 'X.509 Certificate is a required field'}
        helpText="The signing certificate used to validate signatures."
      />

      <StyledCheckbox
        checked={!disableValidateSignature}
        onChange={() => setDisableValidateSignature(!disableValidateSignature)}
        value="validateSignature"
        color="primary"
        label="Validate Signature"
        helpText="Enable/Disable signature validation of SAML responses."
      />

      <StyledButton onClick={() => setShowAdvanced(!showAdvanced)}>
        {showAdvanced ? 'Hide Advanced Settings' : 'Show Advanced Settings'}
      </StyledButton>

      {/* additional config */}
      {showAdvanced && (
        <div css={{ marginTop: '2rem' }}>
          <StyledCheckbox
            checked={backChannelLogout}
            onChange={() => setBackChannelLogout(!backChannelLogout)}
            value="backChannelLougout"
            color="primary"
            label="Back Channel Logout"
            helpText="Indicates whether or not the external IdP supports backchannel logout."
          />

          <StyledCheckbox
            checked={disableWantsAuthnRequestsSigned}
            onChange={() => setDisableWantsAuthnRequestsSigned(!disableWantsAuthnRequestsSigned)}
            value="disableWantsAuthnRequestsSigned"
            color="primary"
            label="Wants AuthnRequests Unsigned"
            helpText="Indicates whether the identity provider expects a signed AuthnRequest."
          />

          <StyledCheckbox
            checked={disableWantsAssertionsSigned}
            onChange={() => setDisableWantsAssertionsSigned(!disableWantsAssertionsSigned)}
            value="disableWantsAssertionsSigned"
            color="primary"
            label="Wants Assertions Unsigned"
            helpText="Indicates whether this service provider expects a signed Assertion."
          />

          <StyledCheckbox
            checked={wantsAssertionsEncrypted}
            onChange={() => setWantsAssertionsEncrypted(!wantsAssertionsEncrypted)}
            value="wantsAssertionsEncrypted"
            color="primary"
            label="Wants Assertions Encrypted"
            helpText="Indicates whether this service provider expects an encrypted Assertion."
          />

          <StyledCheckbox
            checked={disableForceAuthentication}
            onChange={() => setDisableForceAuthentication(!disableForceAuthentication)}
            value="disableForceAuthentication"
            color="primary"
            label="Disable Force Authentication"
            helpText="Indicates whether the identity provider must authenticate the presenter directly
            rather than rely on a previous security context."
          />

          <StyledTextField
            css={{ marginTop: '1rem' }}
            onChange={e => setAllowedClockSkew(e.target.value.trim())}
            value={allowedClockSkew}
            label="Allowed Clock Skew"
            type="number"
            helpText="Clock skew in seconds that is tolerated when validating identity provider tokens."
          />

          <StyledTextField
            onChange={e => setHTTPType(e.target.value)}
            value={httpType}
            label="SAML Binding Type"
            select
            helpText="The SAML binding type used to send AuthN and Logout requests and responses."
          >
            {['HTTP-POST', 'HTTP-REDIRECT'].map(option => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </StyledTextField>
        </div>
      )}

      <Grid
        item
        container
        xs={12}
        css={css`
          justify-content: flex-end;
        `}
      >
        <CardActions>
          <Button color="primary" size="small" onClick={handleRemoveAddField}>
            Cancel
          </Button>
          <div
            css={css`
              position: relative;
            `}
          >
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={handleOnAddIntegration}
              disabled={loading}
            >
              Save
            </Button>
            {loading && (
              <CircularProgress
                size={24}
                css={css`
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  margin-top: -12px;
                  margin-left: -12px;
                `}
              />
            )}
          </div>
        </CardActions>
      </Grid>
    </div>
  )
}

export default AddForm
