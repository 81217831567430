import React from 'react'

import { ScreenshotLayout } from '../../../components'
import DBeaverConnectionScreen from './DBeaver.png'

const DBeaver = () => {
  return (
    <ScreenshotLayout
      imgSrc={DBeaverConnectionScreen}
      title="Connecting with DBeaver"
      steps={[
        'In DBeaver, select SQL Server as your database type.',
        'Fill out the highlighted fields with the connection info displayed above.',
      ]}
    />
  )
}

export default DBeaver
