import { Stack, Typography } from '@mui/material'

import Stepper from '@jeeves/pages/RepositoryDetail/Tabs/Policies/views/AddPolicy/components/Stepper'
import BasicSnackbar from '@jeeves/pages/WrapperDetail/components/BasicSnackbar'

interface BaseWizardProps {
  steps: {
    title: string
    component: JSX.Element
  }[]
  activeStep: number
  snackBarOpen: boolean
  handleCloseSnackbar: () => void
  errorMessage?: string
}

const BaseWizard = ({
  steps,
  activeStep,
  snackBarOpen,
  handleCloseSnackbar,
  errorMessage,
}: BaseWizardProps) => {
  const { title, component } = steps[activeStep]

  return (
    <Stack spacing={4}>
      <Typography variant="h3" sx={{ color: 'text.primary' }}>
        {title}
      </Typography>
      <Stepper steps={steps} activeStep={activeStep} />
      {component}
      <BasicSnackbar
        open={snackBarOpen}
        onClose={handleCloseSnackbar}
        severity="error"
        message={errorMessage || 'Failed'}
      />
    </Stack>
  )
}

export default BaseWizard
