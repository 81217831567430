import React from 'react'
import IntegrationForm from '../IntegrationForm'

import usePopup from '@jeeves/components/PopupMessage/hooks/usePopup'
import { useConfExtensionInstances } from '@jeeves/hooks/useConfExtensions'

const Existing = ({ integration }) => {
  const { setPopup, popupTypes } = usePopup()
  const {
    updateConfExtensionInstance,
    updateConfExtensionInstanceResponse: { error: updateError },
    deleteConfExtensionInstance,
    deleteConfExtensionInstanceResponse: { error: deleteError },
  } = useConfExtensionInstances({
    getConfExtensionInstancesOptions: {
      variables: { purpose: 'authorization', templateType: 'duoMfa' },
    },
  })

  const handleDelete = async () => {
    try {
      await deleteConfExtensionInstance({
        variables: { id: integration.id, purpose: 'authorization' },
      })
    } catch (e) {
      setPopup(popupTypes.ERROR, e.message)
    }
  }

  const handleSave = ({ name, apiHostname, integrationKey, secretKey }) => {
    const stringifiedParameters = JSON.stringify({ apiHostname, integrationKey, secretKey })
    return updateConfExtensionInstance({
      variables: {
        id: integration.id,
        confExtensionInstance: {
          name,
          category: 'builtin',
          templateType: 'duoMfa',
          purpose: 'authorization',
          parameters: stringifiedParameters,
        },
      },
    })
  }

  return <IntegrationForm integration={integration} onDelete={handleDelete} onEdit={handleSave} />
}

export default Existing
