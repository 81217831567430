import { Alert, Stack, Typography } from '@mui/material'

import { PolicySummaryContext } from './PolicySummaryContext'

import {
  DataFirewall,
  DataMasking,
  DataProtection,
  ReadLimit,
  ServiceAccountAbuse,
  UserSegmentation,
  RepositoryProtection,
} from './components'
import { RateLimit } from './components/RateLimit'

const SummaryError = ({ children }) => (
  <Alert
    severity="info"
    sx={{
      color: 'primary.main',
      backgroundColor: 'cyralColors.primary.100',
      '& .MuiAlert-icon': {
        color: 'inherit',
      },
      '& .MuiAlert-message': {
        typography: 'h6',
      },
    }}
  >
    {children}
  </Alert>
)

const PolicySummary = ({ policy }) => {
  const policyTemplateName = policy.template.name

  const summaries = {
    dataFirewall: <DataFirewall />,
    dataMasking: <DataMasking />,
    dataProtection: <DataProtection />,
    rateLimit: <RateLimit />,
    readLimit: <ReadLimit />,
    repositoryProtection: <RepositoryProtection />,
    serviceAccountAbuse: <ServiceAccountAbuse />,
    userSegmentation: <UserSegmentation />,
  }

  const policyNotImplemented = !Boolean(summaries[policy.template.id])
  const policyConfiguredViaAPIs = !Boolean(policy.parameters)

  if (policyNotImplemented) {
    return <SummaryError>Unable to summarize this policy.</SummaryError>
  }

  if (policyConfiguredViaAPIs) {
    return <SummaryError>This policy was configured via APIs and cannot be displayed.</SummaryError>
  }

  return (
    <PolicySummaryContext.Provider value={{ policy }}>
      <Stack spacing={1}>
        <Typography variant="h4" sx={{ color: 'text.primary' }}>
          {policyTemplateName}
        </Typography>
        {summaries[policy.template.id]}
      </Stack>
    </PolicySummaryContext.Provider>
  )
}

export default PolicySummary
