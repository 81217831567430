import { useHistory } from 'react-router-dom'
import { Typography } from '@mui/material'
import {
  CheckCircleOutlineRounded,
  ErrorOutline,
  RemoveCircleOutline,
  WarningAmberRounded,
} from '@mui/icons-material'

import { SidecarHealth } from '@jeeves/graphql/graphql'
import { Tooltip } from '@jeeves/new-components'
import { getRelativeTime } from '@jeeves/utils/duration'
import { FragmentType, graphql, useFragment } from '@jeeves/graphql'
import { instanceCanUpgrade } from '@jeeves/pages/WrapperDetail/components/Instances/helpers'

import InstanceCell from './InstanceCell'
import UpgradeInstanceButton from './UpgradeInstanceButton'

const InstancesRow_SidecarFragment = graphql(`
  fragment InstancesRow_SidecarFragment on Sidecar {
    id
    version
  }
`)

const InstancesRow_SidecarInstanceFragment = graphql(`
  fragment InstancesRow_SidecarInstanceFragment on SidecarInstance {
    id
    metrics {
      activeConnections
      queriesPerSecond
    }
    startTime
    version
    recyclable
    versionIsDynamic
    health
    isRecycling
    ...UpgradeInstanceButton_SidecarInstanceFragment
  }
`)

const variantIcons = {
  [SidecarHealth.Healthy]: <CheckCircleOutlineRounded sx={{ color: 'cyralColors.green.300' }} />,
  [SidecarHealth.Unhealthy]: <ErrorOutline sx={{ color: 'error.main' }} />,
  [SidecarHealth.Degraded]: <WarningAmberRounded sx={{ color: 'cyralColors.yellow.300' }} />,
  [SidecarHealth.Unknown]: <RemoveCircleOutline sx={{ color: 'cyralColors.grey.300' }} />,
} as const

interface Props {
  sidecarInstance: FragmentType<typeof InstancesRow_SidecarInstanceFragment>
  sidecar: FragmentType<typeof InstancesRow_SidecarFragment>
  setSelectedInstanceToUpgrade: React.Dispatch<React.SetStateAction<string>>
}

const InstancesRow = ({
  sidecarInstance: sidecarInstanceProp,
  sidecar: sidecarProp,
  setSelectedInstanceToUpgrade,
}: Props) => {
  const history = useHistory()

  const instance = useFragment(InstancesRow_SidecarInstanceFragment, sidecarInstanceProp)
  const sidecar = useFragment(InstancesRow_SidecarFragment, sidecarProp)

  const { startTime, id, version, metrics, health } = instance
  const sidecarVersion = sidecar.version
  const icon = variantIcons[health]

  const { canUpgrade, reason } = instanceCanUpgrade({
    instance,
    sidecarVersion,
  })

  const upgradeButton = (
    <UpgradeInstanceButton
      sidecarInstance={instance}
      disabled={!canUpgrade}
      onClick={() => {
        setSelectedInstanceToUpgrade(id)
      }}
    />
  )

  const handleClickInstanceCell = () => {
    history.push(`/sidecars/${sidecar.id}/instances/${id}`)
  }

  return (
    <tr>
      <InstanceCell handleClickInstanceCell={handleClickInstanceCell}>{icon}</InstanceCell>
      <InstanceCell handleClickInstanceCell={handleClickInstanceCell}>
        <Typography variant="body2" sx={{ color: 'text.primary' }}>
          {id}
        </Typography>
      </InstanceCell>
      <InstanceCell handleClickInstanceCell={handleClickInstanceCell}>
        <Typography variant="body2" sx={{ color: 'text.primary' }}>
          {version}
        </Typography>
      </InstanceCell>
      <InstanceCell handleClickInstanceCell={handleClickInstanceCell}>
        <Typography variant="body2" sx={{ color: 'text.primary' }}>
          {getRelativeTime(startTime)}
        </Typography>
      </InstanceCell>
      <InstanceCell handleClickInstanceCell={handleClickInstanceCell}>
        <Typography variant="body2" sx={{ color: 'text.primary' }}>
          {metrics.queriesPerSecond}
        </Typography>
      </InstanceCell>
      <InstanceCell handleClickInstanceCell={handleClickInstanceCell}>
        <Typography variant="body2" sx={{ color: 'text.primary' }}>
          {metrics.activeConnections}
        </Typography>
      </InstanceCell>
      <td>
        {!canUpgrade ? (
          <Tooltip title={reason} placement="bottom-end">
            <div>{upgradeButton}</div>
          </Tooltip>
        ) : (
          upgradeButton
        )}
      </td>
    </tr>
  )
}

export default InstancesRow
