import { Alert, Typography } from '@mui/material'

import { FragmentType, graphql, useFragment } from '@jeeves/graphql'

import useAmountOfAvailableTokens from './useAmountOfAvailableTokens'

const MaxTokensAlert_QueryFragment = graphql(`
  fragment MaxTokensAlert_QueryFragment on Query {
    ...useAmountOfAvailableTokens_QueryFragment
  }
`)

interface Props {
  query: FragmentType<typeof MaxTokensAlert_QueryFragment>
}

const MaxTokensAlert = ({ query: queryProp }: Props) => {
  const query = useFragment(MaxTokensAlert_QueryFragment, queryProp)
  const amountOfAvailableTokens = useAmountOfAvailableTokens({ query })

  if (amountOfAvailableTokens > 0) {
    return null
  }

  return (
    <Alert
      severity="info"
      sx={{
        color: 'primary.main',
        backgroundColor: 'cyralColors.primary.100',
        '& .MuiAlert-icon': {
          color: 'inherit',
        },
      }}
    >
      <Typography variant="h6">
        Maximum number of tokens reached. Please delete an existing token to generate a new one.
      </Typography>
    </Alert>
  )
}

export default MaxTokensAlert
