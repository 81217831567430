import * as React from 'react'

export const PolicyDetailsContext = React.createContext()

export const usePolicyDetails = () => {
  const context = React.useContext(PolicyDetailsContext)
  if (context === undefined) {
    throw new Error('usePolicyDetails must be used within a PolicyDetailsProvider')
  }
  return context
}
