/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useState } from 'react'
import styled from '@emotion/styled'
import { css, jsx } from '@emotion/react'

import { ThemeProvider } from '@mui/material/styles'
import { v5Theme } from '@jeeves/theme'
import { Stack, FormControl, Typography, Box } from '@mui/material'

import { InputLabel, Input, Button, CodeBlock } from '@jeeves/new-components'
import { IntegrationCard } from '@jeeves/pages/Integrations/primitives'

import { Divider as MuiDivider } from '@material-ui/core/'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'

import { useForm } from 'react-hook-form'
import { gql, useMutation } from '@apollo/client'
import { FETCH_INTEGRATIONS } from '../GenericSAML'
import usePopup from '@jeeves/components/PopupMessage/hooks/usePopup'

const COMPLETE_GENERIC_SAML_INTEGRATION = gql`
  mutation CompleteGenericSAMLIntegration(
    $completeGenericSamlIntegrationId: ID!
    $genericSamlIntegration: CompleteGenericSAMLIntegrationInput!
  ) {
    completeGenericSAMLIntegration(
      id: $completeGenericSamlIntegrationId
      genericSAMLIntegration: $genericSamlIntegration
    ) {
      genericSAMLIntegration {
        id
      }
    }
  }
`

const Divider = styled(MuiDivider)`
  margin-top: -15px;
  margin-bottom: 15px;
  width: 100%;
`

const AddFormDraft = ({
  addIntegration,
  handleRemoveAddField,
  setOpenIntegration,
  integration,
}) => {
  const [uploadedFile, setUploadedFile] = useState(null)
  const [base64EncodedMetadata, setBase64EncodedMetadata] = useState('')
  const {
    reset,
    register,
    control,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      idpMetadataURL: '',
    },
  })

  const { setPopup, popupTypes } = usePopup()

  const [
    completeGenericSAMLIntegration,
    {
      data: completeGenericSAMLIntegrationData,
      loading: completeGenericSAMLIntegrationLoading,
      error: completeGenericSAMLIntegrationError,
    },
  ] = useMutation(COMPLETE_GENERIC_SAML_INTEGRATION, {
    refetchQueries: [FETCH_INTEGRATIONS],
  })

  const downloadFile = () => {
    const element = document.createElement('a')
    // eslint-disable-next-line
    const file = new Blob([integration.spMetadataXMLDocument], { type: 'text/xml' })
    element.href = URL.createObjectURL(file)
    element.download = 'cyral-sp-metadata.xml'
    document.body.appendChild(element)
    element.click()
  }

  const handleOnUpload = e => {
    setUploadedFile(e.target.files[0])
    const file = e.target.files[0]

    const reader = new FileReader()
    reader.onload = e => {
      const base64EncodedData = e.target.result.split(',')[1]
      setBase64EncodedMetadata(base64EncodedData)
    }
    reader.readAsDataURL(file)
  }

  const onSubmit = async ({ idpMetadataURL }) => {
    try {
      const response = await completeGenericSAMLIntegration({
        variables: {
          completeGenericSamlIntegrationId: integration.id,
          genericSamlIntegration: {
            ...(base64EncodedMetadata && {
              idpMetadataXML: base64EncodedMetadata,
            }),
            ...(idpMetadataURL && {
              idpMetadataURL: idpMetadataURL.trim(),
            }),
          },
        },
      })
      handleRemoveAddField()
    } catch (e) {
      setPopup(popupTypes.ERROR, 'Invalid IdP metadata')
      console.error(e)
    }
  }

  const idpMetadataURL = watch('idpMetadataURL')

  return (
    <ThemeProvider theme={v5Theme}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <IntegrationCard css={{ marginBottom: '20px' }}>
          <Box sx={{ p: 2.5 }}>
            <Stack spacing={2}>
              <Stack spacing={2}>
                <Typography sx={{ fontSize: '1.25rem' }}>{integration.displayName}</Typography>
                <Divider />
              </Stack>
              <Stack spacing={5}>
                <Stack spacing={4}>
                  <Stack spacing={2}>
                    <Typography variant="body1" sx={{ color: 'cyralColors.grey.500' }}>
                      1. Copy the SP metadata URL and use it to configure Cyral as a service
                      provider in your IdP.
                    </Typography>
                    <InputLabel>SP Metadata URL</InputLabel>
                    <CodeBlock>{integration.spMetadataURL}</CodeBlock>
                    <Typography variant="body2" sx={{ color: 'cyralColors.grey.500' }}>
                      Alternatively, you can{' '}
                      <Typography
                        sx={{ cursor: 'pointer', color: 'cyralColors.primary.300' }}
                        onClick={downloadFile}
                        display="inline"
                        variant="body2"
                      >
                        download the SP metadata file
                      </Typography>
                    </Typography>
                  </Stack>
                  <Stack spacing={2}>
                    <Typography variant="body1" sx={{ color: 'cyralColors.grey.500' }}>
                      2. Enter the IdP metadata URL obtained from your identity provider.
                    </Typography>

                    <FormControl>
                      <InputLabel htmlFor="idp-metadata-url">IdP Metadata URL</InputLabel>
                      <Input
                        id="idp-metadata-url"
                        inputProps={{ ...register('idpMetadataURL') }}
                      ></Input>
                    </FormControl>
                    {!uploadedFile && (
                      <Typography sx={{ color: 'cyralColors.grey.500' }} variant="body2">
                        Alternatively, you can{' '}
                        <label style={{ cursor: 'pointer', color: '#26459E' }} for="file-upload">
                          upload a metadata document
                        </label>
                      </Typography>
                    )}

                    <input
                      style={{ display: 'none' }}
                      type="file"
                      id="file-upload"
                      onChange={handleOnUpload}
                    ></input>
                    {uploadedFile && (
                      <Stack direction="row" spacing={4}>
                        <Stack direction="row" spacing={1}>
                          <InsertDriveFileOutlinedIcon sx={{ color: 'cyralColors.grey.300' }} />
                          <Typography variant="body1" sx={{ color: 'cyralColors.grey.400' }}>
                            {uploadedFile.name}
                          </Typography>
                        </Stack>

                        <Typography
                          variant="body1"
                          sx={{ color: 'primary.main', cursor: 'pointer' }}
                          onClick={() => setUploadedFile(null)}
                        >
                          Remove
                        </Typography>
                      </Stack>
                    )}
                  </Stack>
                  <Typography variant="body1" sx={{ color: 'cyralColors.grey.500' }}>
                    Click Save to deploy this integration. Click Cancel to finish this configuration
                    later and keep this SAML integration as a draft.
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={2} sx={{ justifyContent: 'flex-end' }}>
                  <Button variant="text" onClick={handleRemoveAddField} disabled={isSubmitting}>
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    type="submit"
                    loading={isSubmitting}
                    disabled={!uploadedFile && !idpMetadataURL}
                  >
                    Save
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </Box>
        </IntegrationCard>
      </form>
    </ThemeProvider>
  )
}

export default AddFormDraft
