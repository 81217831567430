import { useMemo } from 'react'
import prettier from 'prettier/esm/standalone'
import prettierBabel from 'prettier/esm/parser-babel'
import prettierPostCss from 'prettier/esm/parser-postcss'

const PrettierParser = {
  javascript: 'babel',
  jsx: 'babel',
  css: 'css',
}

const useFormattedCode = (code, language) => {
  return useMemo(() => {
    const parser = PrettierParser[language]

    if (parser) {
      return prettier
        .format(code, {
          parser: parser,
          plugins: [prettierBabel, prettierPostCss],
          tabWidth: 4,
          arrowParens: 'avoid',
          printWidth: 100,
          trailingComma: 'none',
        })
        .replace('>;', '>')
        .trim()
    }

    return code.trim()
  }, [code, language])
}

export default useFormattedCode
