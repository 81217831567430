/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import styled from '@emotion/styled'
import React, { useCallback, useState, Fragment } from 'react'
import { useDropzone } from 'react-dropzone'
import { Button, Typography, InputLabel } from '@material-ui/core'
import QueueOutlinedIcon from '@material-ui/icons/QueueOutlined'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import { grey } from '@material-ui/core/colors'
import mergeRefs from 'react-merge-refs'

const UploadZoneWrapper = styled.div`
  margin-top: 0.25rem;
  margin-bottom 1rem;
  display: flex;
  justify-content: center;
  padding: 1.25rem 1.5rem 1.5rem;
  border: 2px dashed ${grey[400]};
  border-radius: 0.375rem;
  cursor: pointer;
`

export const UploadZone = ({ register, setValue, samlMetadata }) => {
  let fileName

  if (samlMetadata && samlMetadata.length > 0 && samlMetadata[0] !== undefined) {
    fileName = samlMetadata[0].name
  }

  const { getRootProps, getInputProps } = useDropzone()

  const { ref: dropzoneRef, dropzoneOnChange, ...restDropzoneInputProps } = getInputProps()
  const { ref: rhfRef, rhfOnChange, ...restRhfProps } = register('samlMetadata')

  return (
    <UploadZoneWrapper {...getRootProps()}>
      <div css={{ textAlign: 'center' }}>
        {fileName ? (
          <FileCopyOutlinedIcon
            fontSize="large"
            css={{
              color: `${grey[500]}`,
            }}
          />
        ) : (
          <QueueOutlinedIcon
            fontSize="large"
            css={{
              color: `${grey[500]}`,
            }}
          />
        )}

        <div
          css={{
            marginTop: '4px',
            display: 'flex',
          }}
        >
          {fileName ? (
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography>{fileName}</Typography>

              <Button color="primary" size="small" onClick={() => setValue('samlMetadata', null)}>
                Clear
              </Button>
            </div>
          ) : (
            <Fragment>
              <InputLabel
                htmlFor="file-upload"
                css={{
                  position: 'relative',
                  cursor: 'pointer',
                  backgroundColor: 'white',
                  borderRadius: '0.375rem',
                }}
              >
                <Typography
                  css={theme => ({
                    color: theme.palette.primary.main,
                    fontWeight: '600',
                  })}
                >
                  Upload a file
                </Typography>

                <input
                  ref={mergeRefs([dropzoneRef, rhfRef])}
                  onChange={() => {
                    dropzoneOnChange()
                    rhfOnChange()
                  }}
                  {...restDropzoneInputProps}
                  {...restRhfProps}
                />
              </InputLabel>
              {/* <Typography css={{ paddingLeft: '4px' }}>or drag and drop</Typography> */}
            </Fragment>
          )}
        </div>
      </div>
    </UploadZoneWrapper>
  )
}
