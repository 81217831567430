/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Fragment } from 'react'
import styled from '@emotion/styled'
import { Schedule as ScheduleIcon, Error as ErrorIcon } from '@material-ui/icons'
import { red, grey } from '@material-ui/core/colors'

import { SubRow } from '.'
import { Typography } from '@material-ui/core'
import { EllipsisTypography } from '@jeeves/components/Primitives'
import { getStorageMethod } from '@jeeves/pages/RepositoryDetail/Tabs/RepositoryAccounts/hooks/useRepoAccounts'

const IconWrapper = styled.div`
  min-width: 24px;
  position: relative;
  top: 4px;
`

export const RepoAccountList = ({ identityMaps = [], disableIcons = false }) => {
  return (
    <ul
      css={() => css`
        list-style-type: none;
        margin: 0;
        padding: 0;
      `}
    >
      {identityMaps.map(identityMap => (
        <SubRow>
          {/* {!disableIcons && (
            <Fragment>
              <IconWrapper>
                {identityMap.accessExpirationTime && <ScheduleIcon css={{ fill: grey[600] }} />}
              </IconWrapper>
              <IconWrapper>
                {identityMap.alert && <ErrorIcon css={{ fill: red[300] }} />}
              </IconWrapper>
            </Fragment>
          )} */}
          <EllipsisTypography css={{ marginLeft: '4px' }} width="120px">
            {
              identityMap.repoAccount.repoAccount[getStorageMethod(identityMap.repoAccount)]
                .repoAccount
            }
          </EllipsisTypography>
        </SubRow>
      ))}
    </ul>
  )
}
