/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { useFormik } from 'formik'
import { get } from 'lodash'

import { ButtonLoading } from '@jeeves/components/Loading'
import { Button } from '@jeeves/components/Primitives'
import {
  IntegrationEditActions,
  IntegrationFieldList,
  TextField,
} from '@jeeves/pages/Integrations/primitives'

const Edit = ({ integration, onCancel, onSave }) => {
  const { handleSubmit, getFieldProps, isSubmitting, setSubmitting } = useFormik({
    initialValues: {
      name: get(integration, 'integration.name', ''),
      url: get(integration, 'integration.url', ''),
    },
    onSubmit: async values => {
      try {
        await onSave(values)
      } catch (e) {
        console.log('deu ruiiim')
      } finally {
        setSubmitting(false)
      }
    },
  })

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <IntegrationFieldList>
          <TextField label="Integration Name" {...getFieldProps('name')} autofocus />
          <TextField label="URL" {...getFieldProps('url')} />
        </IntegrationFieldList>
        <IntegrationEditActions>
          <Button onClick={onCancel} color="primary" disabled={isSubmitting}>
            Cancel
          </Button>
          <div css={theme => ({ position: 'relative', marginLeft: theme.spacing[5] })}>
            <Button variant="contained" color="primary" disabled={isSubmitting} type="submit">
              Save
            </Button>
            {isSubmitting && <ButtonLoading />}
          </div>
        </IntegrationEditActions>
      </form>
    </div>
  )
}

export default Edit
