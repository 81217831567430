import * as React from 'react'

const RepositoryDetailContext = React.createContext()

export const RepositoryDetailProvider = ({
  children,
  repoId,
  repoType,
  repoName,
  policyTemplates,
}) => {
  const contextValue = {
    repoId,
    repoType,
    repoName,
    policyTemplates,
  }

  return (
    <RepositoryDetailContext.Provider value={contextValue}>
      {children}
    </RepositoryDetailContext.Provider>
  )
}

export const useRepositoryDetailContext = () => {
  const context = React.useContext(RepositoryDetailContext)
  if (context === undefined) {
    throw new Error('useRepositoryDetailContext must be used within a RepositoryDetailProvider')
  }
  return context
}
