import { usePolicySummary } from '@jeeves/pages/RepositoryDetail/Tabs/Policies/components/PolicySummary/PolicySummaryContext'
import {
  getDisplayedPolicyIdentity,
  PolicyIdentityType,
} from '@jeeves/pages/RepositoryDetail/Tabs/Policies/helpers/policyIdentities'
import pluralize from 'pluralize'

export const useRateLimitVariation = () => {
  const { policy } = usePolicySummary()
  const { identitiesAppliedTo, databaseAccountsAppliedTo, rateLimit, dataLabels, action } =
    policy.parameters

  const actionMap = {
    alert: 'ALERT',
    alertAndBlock: 'ENFORCE',
  } as const

  const getIdentities = () => {
    const identities = identitiesAppliedTo.identities?.map(
      (identity: { __typename: PolicyIdentityType; name: string }) => {
        return getDisplayedPolicyIdentity(identity)
      }
    )
    return identities ?? []
  }

  const getRowsDisplay = () => {
    return `${rateLimit} ${pluralize('row', rateLimit)}`
  }

  return {
    dataLabels,
    identities: getIdentities(),
    databaseAccounts: databaseAccountsAppliedTo.databaseAccounts,
    limit: getRowsDisplay(),
    action: actionMap[action as keyof typeof actionMap],
  }
}
