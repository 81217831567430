import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { blueGrey } from '@material-ui/core/colors'

export const SandboxList = styled.ol`
  list-style-type: decimal;
  padding: 0;
  margin: 0 auto;
  width: 100%;
  font-family: ${({ theme }) => theme.fontFamily};

  ${({ theme }) => css`
    ${theme.breakpointsMedia.md} {
      width: 60;
    }
  `}
`

export const SandboxListItem = styled.li`
  padding: 16px 0;
  margin: 0;
  border-bottom: 1px solid ${blueGrey[200]};

  &:last-child {
    border-bottom: none;
  }
`
