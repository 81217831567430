import { useState } from 'react'

import { useQuery } from '@apollo/client'
import sortBy from 'lodash/sortBy'

import { graphql } from '@jeeves/graphql'
import { AwsIamIntegrationsQuery } from '@jeeves/graphql/graphql'
import {
  ErrorState,
  IntegrationDialogContent,
  IntegrationLoading,
  ZeroState,
} from '@jeeves/pages/Integrations/primitives'

import {
  AwsIamIntegrationItem,
  NewAwsIamIntegration,
  UpsertAwsIamIntegrationModal,
} from './components'

import logo from './assets/awsIamIcon.svg'

const AWS_IAM_INTEGRATIONS = graphql(`
  query AwsIamIntegrations {
    awsIamIntegrations {
      id
      ...AwsIamIntegrationItem_awsIamIntegration
      ...UpsertAwsIamIntegrationModal_awsIamIntegration
    }
  }
`)

const AwsIam = () => {
  const { data, loading, error } = useQuery(AWS_IAM_INTEGRATIONS)

  const integrations = data?.awsIamIntegrations ?? []

  const [isUpsertModalOpen, setIsUpsertModalOpen] = useState(false)
  const [integration, setIntegration] =
    useState<AwsIamIntegrationsQuery['awsIamIntegrations'][number]>()

  const handleNewAwsIamIntegration = () => {
    setIntegration(undefined)
    setIsUpsertModalOpen(true)
  }

  const renderContent = () => {
    if (loading) {
      return <IntegrationLoading />
    }

    if (error) {
      return <ErrorState />
    }

    const isEmpty = integrations.length === 0
    if (isEmpty) {
      return (
        <>
          <ZeroState />
          <NewAwsIamIntegration handleNewAwsIamIntegration={handleNewAwsIamIntegration} />
        </>
      )
    }

    return (
      <>
        {sortBy(integrations, ['name']).map(integration => (
          <AwsIamIntegrationItem
            key={integration.id}
            integration={integration}
            onEdit={() => {
              setIntegration(integration)
              setIsUpsertModalOpen(true)
            }}
          />
        ))}
        <NewAwsIamIntegration handleNewAwsIamIntegration={handleNewAwsIamIntegration} />
      </>
    )
  }

  return (
    <>
      <IntegrationDialogContent>{renderContent()}</IntegrationDialogContent>
      <UpsertAwsIamIntegrationModal
        isOpen={isUpsertModalOpen}
        onClose={() => setIsUpsertModalOpen(false)}
        integration={integration}
      />
    </>
  )
}

AwsIam.metadata = {
  name: 'AWS IAM Authenticator',
  summary: 'Configure AWS IAM role-based authentication.',
  enabled: true,
  category: 'authentication',
  slug: 'awsIam',
  logo,
}

export default AwsIam
