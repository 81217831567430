import styled from '@emotion/styled'
import { IconButton, Typography } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'

import { AwsIamIntegration } from '@jeeves/graphql/graphql'
import { useAuth } from '@jeeves/hooks'
import { DeleteIntegrationButton, IntegrationCard } from '@jeeves/pages/Integrations/primitives'

import { useAwsIntegrationItem } from './useAwsIntegrationItem'
import { FragmentType, graphql, useFragment } from '@jeeves/graphql'

const IntegrationItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const IntegrationInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

const AwsIamIntegrationItem_awsIamIntegrationFragment = graphql(`
  fragment AwsIamIntegrationItem_awsIamIntegration on AwsIamIntegration {
    name
    ...useAwsIntegrationItem_awsIamIntegration
  }
`)

interface AwsIamIntegrationItemProps {
  integration: FragmentType<typeof AwsIamIntegrationItem_awsIamIntegrationFragment>
  onEdit: () => void
}

const AwsIamIntegrationItem = ({
  integration: integrationProp,
  onEdit,
}: AwsIamIntegrationItemProps) => {
  const integration = useFragment(AwsIamIntegrationItem_awsIamIntegrationFragment, integrationProp)

  const { hasPermission } = useAuth()

  const { loading, deleteAwsIamIntegration } = useAwsIntegrationItem({ integration })

  return (
    <IntegrationCard>
      <IntegrationItem>
        <IntegrationInfo>
          <Typography variant="body1" align="center">
            {integration?.name}
          </Typography>
        </IntegrationInfo>
        <div>
          <IconButton disabled={!hasPermission('integrations:update')} onClick={onEdit}>
            <EditIcon />
          </IconButton>
          <DeleteIntegrationButton
            onDelete={deleteAwsIamIntegration}
            disabled={!hasPermission('integrations:delete') || loading}
          />
        </div>
      </IntegrationItem>
    </IntegrationCard>
  )
}

export default AwsIamIntegrationItem
