import { Stack, Typography } from '@mui/material'

export interface DeploymentMethodProps {
  title: string
  description: React.ReactNode
  action: React.ReactNode
}

export const DeploymentMethod = ({ title, description, action }: DeploymentMethodProps) => {
  return (
    <Stack
      direction="row"
      spacing={4}
      sx={{
        border: 1,
        borderColor: 'cyralColors.grey.200',
        borderRadius: theme => theme.radii.base,
        p: 4,
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Stack spacing={2}>
        <Typography variant="h3" color="text.primary">
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {description}
        </Typography>
      </Stack>

      {action}
    </Stack>
  )
}

export default DeploymentMethod
