import { Stack, Typography } from '@mui/material'

import { usePolicySummary } from '@jeeves/pages/RepositoryDetail/Tabs/Policies/components/PolicySummary/PolicySummaryContext'
import { policyIdentities } from '@jeeves/pages/RepositoryDetail/Tabs/Policies/helpers/policyIdentities'

import PolicyRule from '../PolicyRule'

const useDataMasking = () => {
  const { policy } = usePolicySummary()
  const {
    databaseAccountsAppliedTo,
    identitiesAppliedTo,
    specifiedDataLabels,
    maskingSpecification,
  } = policy.parameters

  const { modifier: identitiesModifier } = identitiesAppliedTo
  const { modifier: databaseAccountsModifier } = databaseAccountsAppliedTo

  const getDatalabelsPolicyRule = () => {
    if (!specifiedDataLabels.length > 0) {
      return null
    }

    if (maskingSpecification.method === 'NULL') {
      return (
        <PolicyRule
          title="Apply null masking to any data labeled as"
          content={specifiedDataLabels}
        />
      )
    }

    if (maskingSpecification.method === 'FORMAT_PRESERVING') {
      return (
        <PolicyRule
          title="Apply format-preserving masking to any data labeled as"
          content={specifiedDataLabels}
        />
      )
    }

    return (
      <Stack spacing={1}>
        <PolicyRule
          title="Apply constant masking that replaces values with"
          content={maskingSpecification.replacementValue}
        />
        <PolicyRule title="to any data labeled as" content={specifiedDataLabels} />
      </Stack>
    )
  }

  const getIdentitiesPolicyRule = () => {
    const identitiesAccountRuleTitleMap = {
      ONLY: {
        ONLY: 'This policy applies to these identities:',
        ALL_EXCEPT: 'This policy applies to these identities:',
        ALL: 'This policy applies to these identities:',
      },
      ALL_EXCEPT: {
        ONLY: (
          <Typography variant="h6" sx={{ color: 'text.secondary' }}>
            This policy{' '}
            <Typography
              variant="h6"
              component="span"
              sx={{
                color: 'primary.main',
                textTransform: 'uppercase',
              }}
            >
              does not
            </Typography>{' '}
            apply to these identities regardless of the database account they're using:
          </Typography>
        ),
        ALL_EXCEPT: (
          <Typography variant="h6" sx={{ color: 'text.secondary' }}>
            This policy{' '}
            <Typography
              variant="h6"
              component="span"
              sx={{
                color: 'primary.main',
                textTransform: 'uppercase',
              }}
            >
              does not
            </Typography>{' '}
            apply to any of these identities:
          </Typography>
        ),
        ALL: (
          <Typography variant="h6" sx={{ color: 'text.secondary' }}>
            This policy{' '}
            <Typography
              variant="h6"
              component="span"
              sx={{
                color: 'primary.main',
                textTransform: 'uppercase',
              }}
            >
              does not
            </Typography>{' '}
            apply to any of these identities:
          </Typography>
        ),
      },
    }

    const identitiesRuleTitle =
      identitiesAccountRuleTitleMap[identitiesModifier]?.[databaseAccountsModifier]

    if (identitiesRuleTitle) {
      const identities = identitiesAppliedTo.identities.map(identity => {
        const prefix = policyIdentities[identity.__typename]
        return `${prefix}: ${identity.name}`
      })

      return <PolicyRule title={identitiesRuleTitle} content={identities} sx={{ mt: 4 }} />
    }

    return null
  }

  const getDatabaseAccountsRule = identityPolicyRule => {
    const databaseAccounts = databaseAccountsAppliedTo.databaseAccounts

    if (identityPolicyRule) {
      const databaseAccountRulePropsMap = {
        ONLY: {
          ONLY: {
            title: 'when they are using any of these database accounts:',
            content: databaseAccounts,
            sx: { mt: 1 },
          },
          ALL_EXCEPT: {
            title: 'This policy applies to these database accounts:',
            content: databaseAccounts,
            sx: { mt: 4 },
          },
        },
        ALL_EXCEPT: {
          ONLY: {
            title: (
              <Typography variant="h6" sx={{ color: 'text.secondary' }}>
                using any database accounts{' '}
                <Typography
                  variant="h6"
                  component="span"
                  sx={{
                    color: 'primary.main',
                    textTransform: 'uppercase',
                  }}
                >
                  except
                </Typography>
              </Typography>
            ),
            content: databaseAccounts,
            sx: { mt: 1 },
          },
          ALL_EXCEPT: {
            title: 'or when any of the following database accounts are used',
            content: databaseAccounts,
            sx: { mt: 1 },
          },
        },
      }

      const policyRuleProps =
        databaseAccountRulePropsMap[databaseAccountsModifier]?.[identitiesModifier]

      return policyRuleProps && <PolicyRule {...policyRuleProps} />
    }

    const sharedProps = {
      content: databaseAccounts,
      sx: { mt: 4 },
    }

    const databaseAccountRulePropsMap = {
      ONLY: {
        ...sharedProps,
        title: 'This policy applies to these database accounts:',
      },
      ALL_EXCEPT: {
        ...sharedProps,
        title: (
          <Typography variant="h6" sx={{ color: 'text.secondary' }}>
            These database accounts are{' '}
            <Typography
              variant="h6"
              component="span"
              sx={{
                color: 'primary.main',
                textTransform: 'uppercase',
              }}
            >
              exempt
            </Typography>{' '}
            from this policy:
          </Typography>
        ),
      },
    }

    const props = databaseAccountRulePropsMap[databaseAccountsModifier]

    return props && <PolicyRule {...props} />
  }

  const getPolicyRules = () => {
    const datalabelsPolicyRule = getDatalabelsPolicyRule()
    const identitiesPolicyRule = getIdentitiesPolicyRule()
    const databaseAccountsPolicyRule = getDatabaseAccountsRule(identitiesPolicyRule)

    // Specific condition where the databaseAccountsPolicyRule needs to be rendered before identitiesPolicyRule
    if (identitiesModifier === 'ALL_EXCEPT' && databaseAccountsModifier === 'ONLY') {
      return [datalabelsPolicyRule, databaseAccountsPolicyRule, identitiesPolicyRule]
    }

    return [datalabelsPolicyRule, identitiesPolicyRule, databaseAccountsPolicyRule]
  }

  return {
    policyRules: getPolicyRules(),
  }
}

export default useDataMasking
