import { useEffect } from 'react'

export const useEffectOnce = effect => {
  useEffect(effect, []) // eslint-disable-line
}

export const useMount = (fn = () => {}) => {
  useEffectOnce(() => {
    fn()
  })
}
