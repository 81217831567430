import { RepoDetailsQuery } from '@jeeves/graphql/graphql'

type Repo = RepoDetailsQuery['repo']

export const getProperties = (repo: Repo) => {
  const sharedProperties = getSharedProperties(repo)

  if (repo.__typename === 'MongoDBReplicaSetRepo') {
    sharedProperties.push('replica set')
  }

  return sharedProperties
}

function getSharedProperties(repo: Repo) {
  const properties = []

  if (repo.analysis.enableDataMasking) {
    properties.push('data masking')
  }

  return properties
}
