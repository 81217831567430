import * as React from 'react'
import Stack from '@mui/material/Stack'

import { Button } from '@jeeves/new-components'

import LookerConfiguration from './LookerConfiguration'
import TableauConfiguration from './TableauConfiguration'
import CustomConfiguration from './CustomConfiguration'

const StepFour = ({
  prevStep,
  nextStep,
  serviceAccountName,
  selectedConnectionDriverType,
  selectedSidecarEdge,
  repoType,
}) => {
  const [checked, setChecked] = React.useState(false)

  const handleChange = event => {
    setChecked(event.target.checked)
  }

  const host =
    selectedSidecarEdge?.node?.userEndpoint ||
    selectedSidecarEdge?.node?.endpoint ||
    '<SIDECAR_HOST>'

  const port = selectedSidecarEdge?.bindings?.[0]?.listener?.port || '<SIDECAR_PORT>'

  const nextButtonDisabled = selectedConnectionDriverType === 'cyralContext' ? false : !checked

  const connectionDriverInstructions = {
    looker: (
      <LookerConfiguration
        checked={checked}
        handleChange={handleChange}
        serviceAccountName={serviceAccountName}
        repoType={repoType}
        port={port}
        host={host}
      />
    ),
    tableau: (
      <TableauConfiguration
        checked={checked}
        handleChange={handleChange}
        serviceAccountName={serviceAccountName}
        repoType={repoType}
        port={port}
        host={host}
      />
    ),
    cyralContext: <CustomConfiguration />,
  }[selectedConnectionDriverType]

  return (
    <Stack spacing={4}>
      {connectionDriverInstructions}

      <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
        <Button variant="outlined" onClick={prevStep}>
          Back
        </Button>

        <Button onClick={nextStep} disabled={nextButtonDisabled}>
          Next
        </Button>
      </Stack>
    </Stack>
  )
}

export default StepFour
