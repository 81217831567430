import React from 'react'
import { Box } from '@mui/material'

import Header from './components/Header'

const Card = ({ sx }) => {
  return (
    <Box
      sx={{
        width: '5rem',
        height: '5rem',

        bgcolor: 'common.white',
        overflow: 'hidden',
        border: 1,
        borderRadius: theme => theme.radii.xl,
        borderColor: 'cyralColors.grey.200',
        // position: relative;
        ...sx,
      }}
    />
  )
}

Card.Header = Header

export default Card

// sx={{
//     display: 'flex',
//     flexDirection: { xs: 'column', md: 'row' },
//     alignItems: 'center',
//   }}
