import { BaseWizard } from '../common'

import { StepOne, StepThreeV2 as StepThree, StepFour, StepReview } from '../common/steps'
import { StepTwo } from './steps'

import useUserSegmentation from './useUserSegmentation'

const UserSegmentation = ({ policy = {}, template }) => {
  const {
    activeStep,
    snackBarOpen,
    loading,
    errorMessage,
    setUserSegmentationInfo,
    userSegmentationInfo,
    prevStep,
    nextStep,
    handlePolicySubmit,
    handleCloseSnackbar,
  } = useUserSegmentation({ policy, template })

  const steps = [
    {
      title: 'Describe the User Segmentation policy',
      component: (
        <StepOne
          nextStep={nextStep}
          setPolicyInfo={setUserSegmentationInfo}
          policyInfo={userSegmentationInfo}
        />
      ),
    },
    {
      title: 'Specify who is limited to a subset of records',
      component: (
        <StepTwo
          userSegmentationInfo={userSegmentationInfo}
          prevStep={prevStep}
          nextStep={nextStep}
          setUserSegmentationInfo={setUserSegmentationInfo}
        />
      ),
    },
    {
      title: 'Specify when to apply this policy',
      component: (
        <StepThree
          prevStep={prevStep}
          nextStep={nextStep}
          setPolicyInfo={setUserSegmentationInfo}
          policyInfo={userSegmentationInfo}
        />
      ),
    },
    {
      title: 'Specify how records will be restricted',
      component: (
        <StepFour
          prevStep={prevStep}
          nextStep={nextStep}
          setPolicyInfo={setUserSegmentationInfo}
          policyInfo={userSegmentationInfo}
        />
      ),
    },
    {
      title: 'Review the policy',
      component: (
        <StepReview
          policyInfo={userSegmentationInfo}
          prevStep={prevStep}
          handlePolicySubmit={handlePolicySubmit}
          isSubmitting={loading}
        />
      ),
    },
  ]

  return (
    <BaseWizard
      steps={steps}
      activeStep={activeStep}
      snackBarOpen={snackBarOpen}
      handleCloseSnackbar={handleCloseSnackbar}
      errorMessage={errorMessage}
    />
  )
}

export default UserSegmentation
