/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { TextField, MenuItem } from '@material-ui/core'
import useAlerts from '../hooks/useAlerts'

const TimeSpanSelector = ({ timeSpan, setTimeSpan }) => {
  const { getTimeSpan } = useAlerts()

  return (
    <TextField
      select
      label="Time Period"
      value={timeSpan}
      onChange={e => setTimeSpan(e.target.value)}
      css={() => css`
        min-width: 150px;
      `}
    >
      {['FIFTEEN_MINS', 'HOUR', 'DAY'].map(option => (
        <MenuItem key={option} value={option}>
          {getTimeSpan(option).label}
        </MenuItem>
      ))}
    </TextField>
  )
}

export default TimeSpanSelector
