/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { useState, Fragment } from 'react'
import { useForm } from 'react-hook-form'
import {
  Grid,
  Paper,
  TableCell,
  TableRow,
  Typography,
  withStyles,
  Collapse,
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import green from '@material-ui/core/colors/green'
import classnames from 'classnames'

import { ButtonLoading } from '@jeeves/components/Loading'
import { Button, FlexGrow } from '@jeeves/components/Primitives'
import { useAuth } from '@jeeves/components/Auth'
import DeleteDialog from '@jeeves/components/Delete'
import usePolicies from '../../hooks/usePolicies'
import usePopup from '@jeeves/components/PopupMessage/hooks/usePopup'
import CodeEditor from '../CodeEditor'
import { ExpandableRow } from '@jeeves/components/Table'

const styles = theme => ({
  row: {
    background: theme.palette.background.default,
  },
  cell: {
    padding: theme.spacing.unit * 2,
  },
  notification: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: theme.spacing.unit,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    borderStyle: 'solid',
    borderWidth: 'thin',
  },
  alternateRow: {
    background: theme.palette.row.alternate,
  },
  button: {
    margin: theme.spacing.unit,
  },
  greenOutlined: {
    color: green[500],
    border: '1px solid green',
    '&:hover': {
      backgroundColor: green[100],
    },
  },
  greenContained: {
    backgroundColor: green[500],
    color: 'white',
    '&:hover': {
      backgroundColor: green[400],
      color: '#FFF',
    },
  },
})

const EnableButton = withStyles(styles)(({ classes, status, policyId, enabled }) => {
  const [changingStatus, setChangingStatus] = useState(false)
  const { handleOnToggle } = usePolicies()
  const { hasPermission } = useAuth()

  const isEnabled = status === 'active'
  const isPending = status === 'pending'

  if (isPending) {
    return null
  }

  const handleOnClick = async () => {
    setChangingStatus(true)
    await handleOnToggle(policyId, enabled)
    setChangingStatus(false)
  }

  return (
    <div css={{ position: 'relative' }}>
      <Button
        size="small"
        variant="outlined"
        color={isEnabled ? `secondary` : ``}
        className={classnames(classes.button, { [classes.greenOutlined]: !isEnabled })}
        onClick={handleOnClick}
        disabled={!hasPermission('policies:update') || changingStatus}
      >
        {isEnabled ? `Disable` : `Enable`}
      </Button>
      {changingStatus && <ButtonLoading />}
    </div>
  )
})

const TriActionButtons = withStyles(styles)(({ classes, status }) => {
  const { hasPermission } = useAuth()

  if (status !== 'pending') {
    return null
  }

  return (
    <Fragment>
      <Button
        size="small"
        variant="contained"
        className={classnames(classes.greenContained, classes.button)}
        disabled={!hasPermission('policies:update')}
      >
        Accept
      </Button>
      {/* <Button size="small" variant="contained" color="primary" className={classes.button}>
        Assign
      </Button> */}
      <Button
        size="small"
        variant="contained"
        color="secondary"
        className={classes.button}
        disabled={!hasPermission('policies:update')}
      >
        Reject
      </Button>
    </Fragment>
  )
})

const ExpandedRow = ({ classes, expanded, handleExpandRow, policy, colorIndex }) => {
  const { updatePolicy, updatePolicies } = usePolicies()
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const { yamlBody, name, enabled, status } = policy
  const { hasPermission } = useAuth()
  const { showError } = usePopup()

  const {
    control,
    trigger,
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      body: yamlBody ?? '',
    },
  })
  const handleOnCancel = () => {
    handleExpandRow()
    reset()
  }

  const onSubmit = async ({ body }) => {
    try {
      await updatePolicy(policy.id, body)
      handleExpandRow()
    } catch (e) {
      showError('There was an error updating the policy. Please try again or contact support.')
      throw e
    }
  }

  return (
    <ExpandableRow expanded={expanded}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={16} alignItems="center">
          <Typography className={classes.notification}>
            <EditIcon className={classes.icon} fontSize="small" />
            Edit Policy
          </Typography>

          <FlexGrow />

          <EnableButton status={status} policyId={policy.id} enabled={enabled} />

          {status !== 'pending' && (
            <Button
              variant="contained"
              size="small"
              color="secondary"
              onClick={() => setDeleteModalOpen(true)}
              disabled={!hasPermission('policies:delete')}
            >
              Delete Policy
            </Button>
          )}

          <TriActionButtons status={status} />

          <DeleteDialog
            open={deleteModalOpen}
            setOpen={setDeleteModalOpen}
            updateElement={updatePolicies}
            element={policy}
            elementMeta={{
              name: name,
              type: 'policy',
              condition: enabled,
            }}
          />
        </Grid>

        <Paper elevation={0} className={classes.paper}>
          <CodeEditor control={control} trigger={trigger} name="body" />
        </Paper>

        <div
          css={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            className={classes.button}
            size="small"
            onClick={handleOnCancel}
            variant="outlined"
            color="primary"
            disabled={isSubmitting}
          >
            Cancel
          </Button>
          <div css={{ position: 'relative' }}>
            <Button
              className={classes.button}
              size="small"
              variant="contained"
              color="primary"
              type="submit"
              disabled={!hasPermission('policies:update') || isSubmitting}
            >
              Save
            </Button>
            {isSubmitting && <ButtonLoading />}
          </div>
        </div>
      </form>
    </ExpandableRow>
  )
}

export default withStyles(styles)(ExpandedRow)
