import * as React from 'react'
import { isAfter, parseISO } from 'date-fns'
import { useQuery, gql } from '@apollo/client'
import { Controller, useFormState, useWatch, useFormContext } from 'react-hook-form'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import DialogContent from '@mui/material/DialogContent'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'

import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { DatePicker, Input, FormLabel, InputGroup, Select } from '@jeeves/new-components'

const CONFIGURED_ACCESS_RESTRICTION_INTEGRATIONS = gql`
  query ConfiguredAccessRestrictionIntegrations {
    duoIntegrations {
      id
      name
    }
    pagerdutyIntegrations {
      id
      name
    }
  }
`

const AccessRuleModalContent = () => {
  const {
    loading: accessRestrictionsLoading,
    error: accessRestrictionsError,
    data: accessRestrictionsData,
  } = useQuery(CONFIGURED_ACCESS_RESTRICTION_INTEGRATIONS)

  const pagerdutyIntegrations = accessRestrictionsData?.pagerdutyIntegrations
  const duoIntegrations = accessRestrictionsData?.duoIntegrations

  return (
    <DialogContent>
      <Stack spacing={4}>
        <Stack spacing={2}>
          <Identity />

          <AccessDuration />
        </Stack>

        <AdditionalAccessRestrictions
          pagerdutyIntegrations={pagerdutyIntegrations}
          duoIntegrations={duoIntegrations}
        />
      </Stack>
    </DialogContent>
  )
}

const Identity = () => {
  const { register, unregister } = useFormContext()

  const { errors } = useFormState()

  const identityType = useWatch({
    name: 'identity.type',
  })

  React.useEffect(() => {
    if (identityType === 'group') {
      unregister('identity.userField', {
        keepDefaultValue: true,
      })
    }
  }, [identityType, unregister])

  return (
    <Stack spacing={2}>
      <FormControl component="fieldset" variant="standard">
        <FormLabel
        // id="local-account-select-label"
        // htmlFor="local-account-select"
        >
          Grant access to this repository for a:
        </FormLabel>
        <Controller
          name="identity.type"
          render={({ field }) => {
            return (
              <RadioGroup
                // aria-labelledby="demo-radio-buttons-group-label"
                // defaultValue="group"
                aria-label="duration"
                {...field}
                sx={{
                  'legend + &': {
                    marginTop: 3,
                  },
                }}
                row
              >
                <FormControlLabel
                  value="group"
                  control={<Radio size="small" disableRipple />}
                  label="SSO Group"
                />
                <FormControlLabel
                  value="user"
                  control={<Radio size="small" disableRipple />}
                  label="SSO User"
                />
              </RadioGroup>
            )
          }}
        />
      </FormControl>

      {identityType === 'group' ? (
        <FormControl variant="standard" error={Boolean(errors?.identity?.name)}>
          {/* <InputLabel htmlFor="secretName">Secret Name</InputLabel> */}
          <Input
            id="identity-name"
            inputProps={{
              ...register('identity.name', { required: 'This field is required.' }),
            }}
            // fullWidth
          />
          {errors?.identity?.name && (
            <FormHelperText
              sx={{
                typography: 'body2',
              }}
            >
              {errors.identity.name.message}
            </FormHelperText>
          )}
        </FormControl>
      ) : (
        <InputGroup fullWidth>
          <Controller
            name="identity.userField"
            defaultValue="username"
            render={({ field }) => (
              <Select
                // labelId="data-repository-select-label"
                // id="data-repository-select"
                options={[
                  {
                    label: 'Username',
                    value: 'username',
                  },
                  {
                    label: 'Email',
                    value: 'email',
                  },
                ]}
                {...field}
              />
            )}
            rules={{
              required: true,
            }}
          />
          <FormControl
            variant="standard"
            error={Boolean(errors?.identity?.name)}
            component={React.Fragment}
          >
            <Input
              id="identity-name"
              inputProps={{
                ...register('identity.name', { required: 'This field is required.' }),
              }}
              sx={{
                flex: 1,
              }}
            />
          </FormControl>
        </InputGroup>
      )}
    </Stack>
  )
}

const AccessDuration = () => {
  const { getValues, unregister } = useFormContext()
  const { errors } = useFormState()

  const accessDurationType = useWatch({
    name: 'accessDurationType',
  })

  React.useEffect(() => {
    if (accessDurationType === 'unlimited') {
      unregister('validUntil')
    }
  }, [accessDurationType, unregister])

  return (
    <Stack spacing={2}>
      <FormControl component="fieldset" variant="standard">
        <FormLabel
        // id="local-account-select-label"
        >
          When is this access permitted?
        </FormLabel>
        <Controller
          name="accessDurationType"
          render={({ field }) => {
            return (
              <RadioGroup
                // aria-labelledby="demo-radio-buttons-group-label"
                // defaultValue="group"
                aria-label="duration"
                {...field}
                sx={{
                  'legend + &': {
                    marginTop: 3,
                  },
                }}
                row
              >
                <FormControlLabel
                  value="unlimited"
                  control={<Radio size="small" disableRipple />}
                  label="Always"
                />
                <FormControlLabel
                  value="limited"
                  control={<Radio size="small" disableRipple />}
                  label="Specify Duration:"
                />
              </RadioGroup>
            )
          }}
        />
      </FormControl>

      <Stack spacing={1} direction="row" sx={{ alignItems: 'baseline' }}>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          From
        </Typography>

        <Controller
          name="validFrom"
          render={({ field: { onChange, onBlur, value, name, ref } }) => {
            return (
              <DatePicker
                onChange={onChange}
                value={value}
                sx={{
                  flex: 1,
                }}
                selectTime
              />
            )
          }}
        />

        {accessDurationType === 'limited' && (
          <React.Fragment>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              to
            </Typography>

            <FormControl error={Boolean(errors?.validUntil)} sx={{ flex: 1 }}>
              <Controller
                name="validUntil"
                defaultValue={new Date().toISOString()}
                render={({ field: { onChange, onBlur, value, name, ref } }) => {
                  return (
                    <DatePicker
                      onChange={onChange}
                      value={value}
                      error={Boolean(errors?.validUntil)}
                      selectTime
                    />
                  )
                }}
                rules={{
                  validate: validUntil => {
                    const validFrom = getValues('validFrom')

                    return (
                      !isAfter(parseISO(validFrom), parseISO(validUntil)) ||
                      'End date must be after start date.'
                    )
                  },
                }}
              />

              {errors?.validUntil && (
                <FormHelperText
                  sx={{
                    typography: 'body2',
                  }}
                >
                  {errors.validUntil.message}
                </FormHelperText>
              )}
            </FormControl>
          </React.Fragment>
        )}
      </Stack>
    </Stack>
  )
}

const AdditionalAccessRestrictions = ({ pagerdutyIntegrations = [], duoIntegrations = [] }) => {
  return (
    <Accordion
      elevation={0}
      disableGutters
      square
      sx={{
        border: 1,
        borderColor: 'cyralColors.grey.200',
        overflow: 'hidden',
        backgroundColor: 'common.white',
        flexShrink: 0,
        borderRadius: theme => theme.radii.base,

        '&:before': {
          display: 'none',
        },
      }}
      // sx={{
      //   '&.Mui-expanded': {
      //     ':before': {
      //       opacity: 1,
      //     },
      //   },
      // }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h6" sx={{ color: 'text.secondary' }}>
          Additional Access Restrictions
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={2}>
          <FormControl
            variant="standard"
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(4, 1fr)',
              gap: 2,
            }}
          >
            <FormLabel id="pagerduty-integrations-select-label" sx={{ alignSelf: 'center' }}>
              On Call Access
            </FormLabel>

            <Box gridColumn="span 3">
              <Controller
                name="pagerdutyIntegrations"
                render={({ field: { onChange, ...rest } }) => (
                  <Select
                    labelId="pagerduty-integrations-select-label"
                    id="pagerduty-integrations-select"
                    options={pagerdutyIntegrations.map(integration => ({
                      label: integration.name,
                      value: integration.id,
                    }))}
                    onChange={onChange}
                    sx={{
                      width: '100%',
                    }}
                    multiple
                    {...rest}
                  />
                )}
              />
            </Box>
          </FormControl>
          <FormControl
            variant="standard"
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(4, 1fr)',
              gap: 2,
            }}
          >
            <FormLabel id="duo-integrations-select-label" sx={{ alignSelf: 'center' }}>
              MFA
            </FormLabel>

            <Box gridColumn="span 3">
              <Controller
                name="duoIntegrations"
                render={({ field: { onChange, ...rest } }) => (
                  <Select
                    labelId="duo-integrations-select-label"
                    id="duo-integrations-select"
                    options={duoIntegrations.map(integration => ({
                      label: integration.name,
                      value: integration.id,
                    }))}
                    onChange={onChange}
                    sx={{
                      width: '100%',
                    }}
                    multiple
                    {...rest}
                  />
                )}
              />
            </Box>
          </FormControl>
        </Stack>
      </AccordionDetails>
    </Accordion>
  )
}

export default AccessRuleModalContent
