/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { useEffect, useState } from 'react'
import { Fade } from '@material-ui/core/'
import { IntegrationCard } from '@jeeves/pages/Integrations/primitives'
import View from '../view/View'
import Edit from '../update/Edit'

export default ({
  integration = null,
  creating = false,
  last,
  createSCIMIntegration,
  updateSCIMIntegrationStatus,
  createSCIMIntegrationData,
  generateSCIMIntegrationCreds,
  refetchIntegrations,
}) => {
  const [editing, setEditing] = useState(false)
  const configureEditing = () => setEditing(creating)

  useEffect(() => {
    configureEditing()
  }, [])

  const onDelete = async () => {
    setEditing(false)
  }

  return (
    <IntegrationCard
      css={(last && { marginBottom: '0' }, { maxHeight: '696px', overflow: 'auto' })}
    >
      {!editing && integration && (
        <Fade in={!editing}>
          <View integration={integration} setEditing={() => setEditing(true)} onDelete={onDelete} />
        </Fade>
      )}
      {editing && (
        <Fade in={editing}>
          <Edit
            generateSCIMIntegrationCreds={generateSCIMIntegrationCreds}
            createSCIMIntegrationData={createSCIMIntegrationData}
            createSCIMIntegration={createSCIMIntegration}
            updateSCIMIntegrationStatus={updateSCIMIntegrationStatus}
            integration={integration}
            handleSetEdit={setEditing}
            refetchIntegrations={refetchIntegrations}
          />
        </Fade>
      )}
    </IntegrationCard>
  )
}
