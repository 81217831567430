import React, { useState, useMemo } from 'react'
import { useQuery, useMutation, gql } from '@apollo/client'

const GET_CONF_EXTENSION_INSTANCES = gql`
  query ConfExtensionInstances(
    $purpose: ConfExtensionInstancePurpose
    $name: String
    $templateType: ConfExtensionInstanceTemplateType
  ) {
    confExtensionInstances(purpose: $purpose, name: $name, templateType: $templateType) {
      id
      name
      templateId
      ... on OtherConfExtensionInstance {
        purpose
        category
        parameters
        templateType
      }
    }
  }
`

const CREATE_CONF_EXTENSION_INSTANCE = gql`
  mutation CreateConfExtensionInstance($confExtensionInstance: CreateConfExtensionInstanceInput!) {
    createConfExtensionInstance(confExtensionInstance: $confExtensionInstance) {
      confExtensionInstance {
        id
        name
        templateId
        ... on OtherConfExtensionInstance {
          purpose
          category
          parameters
          templateType
        }
      }
    }
  }
`

const UPDATE_CONF_EXTENSION_INSTANCE = gql`
  mutation UpdateConfExtensionInstance(
    $id: String!
    $confExtensionInstance: CreateConfExtensionInstanceInput!
  ) {
    updateConfExtensionInstance(id: $id, confExtensionInstance: $confExtensionInstance) {
      confExtensionInstance {
        id
        name
        templateId
        ... on OtherConfExtensionInstance {
          purpose
          category
          parameters
          templateType
        }
      }
    }
  }
`

const DELETE_CONF_EXTENSION_INSTANCE = gql`
  mutation DeleteConfExtensionInstance($id: String!, $purpose: ConfExtensionInstancePurpose!) {
    deleteConfExtensionInstance(id: $id, purpose: $purpose) {
      id
    }
  }
`

export const useConfExtensionInstances = ({ getConfExtensionInstancesOptions }) => {
  const getConfExtensionInstancesResponse = useQuery(
    GET_CONF_EXTENSION_INSTANCES,
    getConfExtensionInstancesOptions
  )
  const mutationOptions = {
    refetchQueries: [
      {
        query: GET_CONF_EXTENSION_INSTANCES,
        ...(getConfExtensionInstancesOptions.variables
          ? { variables: getConfExtensionInstancesOptions.variables }
          : {}),
      },
    ],
  }
  const [createConfExtensionInstance, createConfExtensionInstanceResponse] = useMutation(
    CREATE_CONF_EXTENSION_INSTANCE,
    mutationOptions
  )
  const [updateConfExtensionInstance, updateConfExtensionInstanceResponse] = useMutation(
    UPDATE_CONF_EXTENSION_INSTANCE,
    mutationOptions
  )
  const [deleteConfExtensionInstance, deleteConfExtensionInstanceResponse] = useMutation(
    DELETE_CONF_EXTENSION_INSTANCE,
    mutationOptions
  )
  return {
    getConfExtensionInstancesResponse,
    createConfExtensionInstance,
    createConfExtensionInstanceResponse,
    updateConfExtensionInstance,
    updateConfExtensionInstanceResponse,
    deleteConfExtensionInstance,
    deleteConfExtensionInstanceResponse,
  }
}
