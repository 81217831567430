import { useParams } from 'react-router-dom'
import { NetworkStatus } from '@apollo/client'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'

import { useRepositoriesContext } from '@jeeves/pages/WrapperDetail/tabs/Repositories/contexts/RepositoriesContext'

import useBindingsExist from '../useBindingsExist'
import { useBindingsTableContext } from './BindingsTableContext'

const EmptyState = () => {
  const { id: sidecarId } = useParams()
  const bindingsExist = useBindingsExist(sidecarId)
  const { networkStatus } = useRepositoriesContext()
  const { isPaginating, loading, currentPageEdges } = useBindingsTableContext()
  const currentlyDisplayingAnyEdges = currentPageEdges?.length > 0

  const isRefetching = networkStatus === NetworkStatus.setVariables

  if (isPaginating || isRefetching || (loading && !currentlyDisplayingAnyEdges)) {
    return (
      <Stack spacing={2} sx={{ alignItems: 'center' }}>
        <CircularProgress size={64} />
        <Typography variant="h6" sx={{ color: 'text.secondary' }}>
          Loading bindings...
        </Typography>
      </Stack>
    )
  }

  if (!bindingsExist) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          No bound repositories.
        </Typography>
      </Box>
    )
  }

  return null
}

export default EmptyState
