import { ComponentPropsWithoutRef, ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { Box, Typography, Link as MuiLink } from '@mui/material'
import { Root, Description, Action, Close, Viewport } from '@radix-ui/react-toast'
import CloseIcon from '@mui/icons-material/Close'
import { IconButton } from '@jeeves/new-components'
import { styled } from '@mui/material/styles'

type Variant = 'info' | 'success' | 'error'

export type ToastProps = ComponentPropsWithoutRef<typeof Root> & {
  variant: Variant
}

export const Toast = styled(Root, {
  shouldForwardProp: prop => prop !== 'variant',
})<ToastProps>(({ theme, variant }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.radii.base,
  boxShadow: theme.cyralShadows['1dp'],
  background: theme.palette.cyralColors.white,
  width: '420px',

  ...(variant === 'success' && {
    borderLeft: `8px solid ${theme.palette.cyralColors.green[300]}`,
  }),

  ...(variant === 'info' && {
    borderLeft: `8px solid ${theme.palette.primary.main}`,
  }),

  ...(variant === 'error' && {
    borderLeft: `8px solid ${theme.palette.error.main}`,
  }),
}))

export type ToastDetailsProps = {
  details: {
    label?: string
    href: string
  }
}

export const ToastDetails = ({ details }: ToastDetailsProps) => {
  const sharedProps = {
    variant: 'h6',
    sx: {
      color: 'primary.main',
    },
    underline: 'hover',
  } as const

  const { href } = details
  const isExternalLink = href.startsWith('http')
  const label = details.label ?? 'View details'

  if (isExternalLink) {
    return (
      <Action altText={label} asChild>
        <MuiLink target="_blank" rel="noopener noreferrer" href={href} {...sharedProps}>
          {label}
        </MuiLink>
      </Action>
    )
  }

  return (
    <Action altText={label} asChild>
      <MuiLink component={Link} to={href} {...sharedProps}>
        {label}
      </MuiLink>
    </Action>
  )
}

export type ToastDescriptionProps = {
  description: ReactNode
}

export const ToastDescription = ({ description }: ToastDescriptionProps) => {
  if (typeof description === 'string') {
    return (
      <Description asChild>
        <Typography variant="body2" sx={{ color: 'text.primary' }}>
          {description}
        </Typography>
      </Description>
    )
  }

  return <Description asChild>{description}</Description>
}

export const ToastClose = ({ ...props }) => (
  <Close aria-label="Close" asChild {...props}>
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <IconButton
        sx={{
          padding: '0.25rem',
          color: 'cyralColors.grey.400',
          '&:hover': {
            backgroundColor: 'cyralColors.grey.100',
            borderRadius: theme => theme.radii.base,
          },
        }}
      >
        <CloseIcon sx={{ fontSize: '0.875rem' }} />
      </IconButton>
    </Box>
  </Close>
)

export const ToastViewport = styled(Viewport)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  right: 0,
  zIndex: 1400,
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(2),
  listStyle: 'none',
}))
