import { Stack, DialogContent, DialogTitle, Typography } from '@mui/material'
import CloseIcon from '@material-ui/icons/Close'

import { Dialog, Button, DialogActions, IconButton } from '@jeeves/new-components'

const DisableNetworkShieldModal = ({ open, closeModal, loading, onConfirm }) => {
  return (
    <Dialog open={open} onClose={closeModal}>
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h3" sx={{ color: 'text.primary' }}>
          Disable Network Shield
        </Typography>
        <IconButton aria-label="close" onClick={closeModal}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
            Are you sure you want to disable the{' '}
            <Typography component="span" variant="h6" sx={{ color: 'primary.main' }}>
              Network Shield
            </Typography>
            ?
          </Typography>
          <Typography component="span" variant="body2" sx={{ color: 'error.main' }}>
            This will disable any client IP-related access restrictions to this repository.
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2} sx={{ justifyContent: 'flex-end' }}>
          <Button variant="text" onClick={closeModal}>
            Cancel
          </Button>
          <Button variant="outlined" color="error" onClick={onConfirm} loading={loading}>
            Disable
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}

export default DisableNetworkShieldModal
