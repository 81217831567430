/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx, css } from '@emotion/react'
import styled from '@emotion/styled'
import React, { useState, useEffect, useRef, Fragment } from 'react'
import {
  TextField,
  InputAdornment,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel as MuiFormLabel,
  FormControl,
  withStyles,
  Stepper,
  Step,
  StepLabel,
  Collapse,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  Checkbox,
  Switch,
} from '@material-ui/core'
import { ActionButton } from '@jeeves/components/Primitives'
import CloseIcon from '@material-ui/icons/Close'
import { ExpandableRow } from '@jeeves/components/Table'
import SearchIcon from '@material-ui/icons/Search'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import InfoIcon from '@material-ui/icons/Info'
import { Button } from '@jeeves/components/Primitives'
import Loading from '@jeeves/components/Loading'
import { EllipsisTypography } from '@jeeves/components/Primitives'
import useRepoAccounts from './hooks/useRepoAccounts'
import { DurationInput } from '@jeeves/pages/RepositoryDetail/Tabs/IdentityMaps/components/AddDialog'
import { isoToDurationObject, durationObjectToIso } from '@jeeves/utils/functions'
import _ from 'lodash'
import { getDocsURL } from '@jeeves/utils/docsURL'

function isPrimitive(val) {
  return val !== Object(val)
}

const timeCustomizer = (a, b) => {
  if (isPrimitive(a) && isPrimitive(b)) {
    return !a ? !!a == !!b : a == b
  } else {
    return undefined
  }
}

const styles = theme => ({
  formControl: {
    margin: '12px 0',
  },
  first: {
    marginTop: '0',
  },
  last: {
    marginBottom: '0',
  },
  formControlInput: {
    marginTop: '12px',
  },
  descriptionFormLabel: {
    fontSize: '1em',
  },
  deleteButton: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  deleteColor: {
    color: theme.palette.error.main,
  },
})

// TO DO: fix the margins on this page
// TO DO: split this file up into multiple component files

const ENV_VAR_PREFIX = 'CYRAL_DBSECRETS_'

const StepContent = styled.div`
  margin-top: 8px;
  margin-left: 12px;
  margin-bottom: ${props => (props.last ? '0' : '24px')};
  padding-left: 20px;
  display: flex;
  flex-direction: column;
`

const FormLabel = styled(MuiFormLabel)`
  color: black;
`

export const InfoSnackbar = ({ disabled, showLearnMore, text, ...props }) => {
  return (
    <div
      css={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: disabled ? 'rgba(0,0,0,0.12)' : '#e0ebfd',
        borderRadius: '4px',
        padding: '14px',
      }}
      {...props}
    >
      <div
        css={{
          marginRight: '14px',
          backgroundColor: 'white',
          borderRadius: '50%',
          height: '15px',
          width: '15px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <InfoIcon fontSize="small" color={disabled ? 'secondary' : 'primary'} />
      </div>
      <Typography
        css={{
          flexGrow: 1,
          fontSize: '14px',
          color: disabled ? 'rgba(0, 0, 0, 0.38)' : 'rgba(0, 0, 0, 0.87)',
          float: 'left',
        }}
      >
        {text}
        {showLearnMore &&
          (disabled ? (
            //removed float: right
            <Typography
              css={{ fontSize: '14px', fontWeight: 'bold', display: 'inline' }}
              component="span"
              color="secondary"
            >
              {' Learn more \u2192'}
            </Typography>
          ) : (
            <a
              href={getDocsURL()}
              css={{ textDecoration: 'none' }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Typography
                css={{ fontSize: '14px', fontWeight: 'bold', display: 'inline' }}
                component="span"
                color="primary"
              >
                {' Learn more \u2192'}
              </Typography>
            </a>
          ))}
      </Typography>
    </div>
  )
}

const UsernameInput = withStyles(styles)(({ add, username, handleChange, classes, disabled }) => (
  <FormControl disabled={disabled} className={`${classes.formControl} ${classes.first}`}>
    {!add && (
      <FormLabel className={add ? classes.descriptionFormLabel : ''}>
        {add ? 'Account username that exists on this data repository' : 'Username'}
      </FormLabel>
    )}
    <TextField
      disabled={disabled}
      variant="outlined"
      className={!add && classes.formControlInput}
      name="username"
      value={username}
      placeholder={add && 'e.g. sample_user'}
      css={{
        fontSize: '.75rem',
        backgroundColor: disabled ? 'rgba(0, 0, 0, 0.12)' : 'rgba(0,0,0,0)',
        borderRadius: '4px',
      }}
      onChange={handleChange}
    />
  </FormControl>
))

const DatabaseNameInput = withStyles(styles)(
  ({ add, databaseName, handleChange, classes, disabled }) => {
    return (
      <FormControl className={classes.formControl} disabled={disabled}>
        <FormLabel className={add ? classes.descriptionFormLabel : ''}>
          {add
            ? 'Enter the authentication database associated with the account:'
            : 'Authentication Database'}
        </FormLabel>
        <TextField
          disabled={disabled}
          variant="outlined"
          className={classes.formControlInput}
          name="databaseName"
          value={databaseName}
          css={{
            fontSize: '.75rem',
            backgroundColor: disabled ? 'rgba(0, 0, 0, 0.12)' : 'rgba(0,0,0,0)',
            borderRadius: '4px',
          }}
          onChange={handleChange}
        />
      </FormControl>
    )
  }
)

const SecretsManagerInput = withStyles(styles)(
  ({ add, secretsManager, handleChange, classes, disabled, repoType }) => (
    <FormControl
      className={`${classes.formControl}${add ? classes.first : ''}`}
      disabled={disabled}
    >
      {add ? (
        <InfoSnackbar
          disabled={disabled}
          showLearnMore
          text="The credentials for this account should be stored in a secrets manager or as an environment variable for the sidecar, or the account should be configured for IAM authentication."
        />
      ) : (
        <FormLabel>Secrets Manager</FormLabel>
      )}
      <div css={{ display: 'flex', alignItems: 'center' }}>
        <RadioGroup
          css={{ flexDirection: 'row', alignItems: 'center' }}
          name="secretsManager"
          value={secretsManager}
          onChange={handleChange}
        >
          <FormControlLabel
            control={<Radio color="primary" />}
            label="AWS Secrets Manager"
            value="awsSecretsManager"
          />
          <FormControlLabel
            control={<Radio color="primary" />}
            label="Kubernetes Secret"
            value="kubernetesSecret"
          />
          <FormControlLabel
            control={<Radio color="primary" />}
            label="GCP Secret Manager"
            value="gcpSecretManager"
          />
          {(repoType === 'postgresql' || repoType === 'mysql') && (
            <FormControlLabel control={<Radio color="primary" />} label="AWS IAM" value="awsIAM" />
          )}
          <FormControlLabel
            control={<Radio color="primary" />}
            label="Environment Variable"
            value="environmentVariable"
          />
          <FormControlLabel
            control={<Radio color="primary" />}
            label="Hashicorp Vault"
            value="hashicorpVault"
          />
        </RadioGroup>
      </div>
    </FormControl>
  )
)

const SecretIdentifierInput = withStyles(styles)(
  ({ add, secretIdentifier, handleChange, classes, disabled, label, secretsManager, ...props }) => {
    const ADD_LABELS = {
      awsSecretsManager: 'secret ARN',
      kubernetesSecret: 'secret name',
      gcpSecretManager: 'secret name',
      awsIAM: 'role ARN',
      hashicorpVault: 'secret path',
      environmentVariable: 'variable name',
      cyralStorage: 'secret',
    }
    const EDIT_LABELS = {
      awsSecretsManager: 'Secret ARN',
      kubernetesSecret: 'Secret Name',
      gcpSecretManager: 'Secret Name',
      awsIAM: 'Role ARN',
      hashicorpVault: 'Secret Path',
      environmentVariable: 'Variable Name',
      cyralStorage: 'Secret',
    }
    const singleLineSecretManagers = ['environmentVariable', 'kubernetesSecret']
    const isMultiline = !singleLineSecretManagers.includes(secretsManager)

    return (
      <FormControl className={classes.formControl} disabled={disabled} {...props}>
        <FormLabel className={add ? classes.descriptionFormLabel : ''}>
          {add ? `Enter the ${ADD_LABELS[secretsManager]}:` : EDIT_LABELS[secretsManager]}
        </FormLabel>
        <TextField
          disabled={disabled}
          multiline={isMultiline}
          rows={isMultiline ? 4 : 1}
          variant="outlined"
          className={classes.formControlInput}
          name="secretIdentifier"
          value={secretIdentifier}
          onChange={handleChange}
          placeholder={
            add &&
            {
              awsSecretsManager:
                'E.g. arn:aws:secretsmanager:region:123456789012:secret:example/example-secret-jiObOV',
              awsIAM: 'E.g. arn:aws:iam::728433162697:role/example-rds-role-name',
              gcpSecretManager:
                'The unique identifier of the secret in GCP Secret Manager. E.g. \nprojects/<project-name>/secrets/<secret-name> or \nprojects/<project-name>/secrets/<secret-name>/versions/<version>',
              hashicorpVault: '',
              kubernetesSecret: '',
            }[secretsManager]
          }
          css={{
            backgroundColor: disabled ? 'rgba(0, 0, 0, 0.12)' : 'rgba(0,0,0,0)',
            fontSize: '.75rem',
            borderRadius: '4px',
          }}
          InputProps={
            secretsManager === 'environmentVariable' && {
              startAdornment: (
                <InputAdornment
                  css={{ fontSize: 'inherit' }}
                  component={() => (
                    <Typography css={{ fontSize: 'inherit' }}>{ENV_VAR_PREFIX}</Typography>
                  )}
                  position="start"
                />
              ),
            }
          }
        />
      </FormControl>
    )
  }
)

const SecretKeyInput = withStyles(styles)(
  ({ add, secretKey, handleChange, classes, disabled, ...props }) => {
    return (
      <FormControl className={classes.formControl} disabled={disabled} {...props}>
        <FormLabel className={add ? classes.descriptionFormLabel : ''}>
          {add ? 'Enter the key name:' : 'Key Name'}
        </FormLabel>
        <TextField
          disabled={disabled}
          variant="outlined"
          className={classes.formControlInput}
          name="secretKey"
          value={secretKey}
          onChange={handleChange}
          css={{
            backgroundColor: disabled ? 'rgba(0, 0, 0, 0.12)' : 'rgba(0,0,0,0)',
            fontSize: '.75rem',
            borderRadius: '4px',
          }}
        />
      </FormControl>
    )
  }
)

const S3BucketsInput = withStyles(styles)(
  ({ add, s3Buckets, handleChange, classes, disabled, ...props }) => {
    return (
      <FormControl className={classes.formControl} disabled={disabled} {...props}>
        <FormLabel className={add ? classes.descriptionFormLabel : ''}>
          {add
            ? 'Enter the S3 buckets in a comma separated list:'
            : 'S3 buckets in a comma separated list'}
        </FormLabel>
        <TextField
          disabled={disabled}
          variant="outlined"
          className={classes.formControlInput}
          name="s3Buckets"
          value={s3Buckets}
          onChange={handleChange}
          css={{
            backgroundColor: disabled ? 'rgba(0, 0, 0, 0.12)' : 'rgba(0,0,0,0)',
            fontSize: '.75rem',
            borderRadius: '4px',
          }}
        />
      </FormControl>
    )
  }
)

const IsDynamicRepoAccountInput = withStyles(styles)(
  ({ add, isDynamicRepoAccount, handleChange, classes, disabled, ...props }) => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={isDynamicRepoAccount}
            onChange={handleChange}
            name="isDyanmicRepoAccount"
            color="primary"
          />
        }
        disabled={disabled}
        label="Username is dynamic"
        className={classes.formControlInput}
        {...props}
      />
    )
  }
)

const AutoApproveInput = withStyles(styles)(
  ({
    autoApprove,
    handleChange = () => {},
    classes,
    disabled,
    maxAutoApproveDurationObject,
    ...props
  }) => {
    return (
      <Fragment>
        <div
          css={{
            border: '1px solid rgb(0, 0, 0, 0.15)',
            borderRadius: '4px',
            padding: '24px',
          }}
          className={classes.formControl}
        >
          <FormLabel css={{ marginBottom: '12px' }} disabled={disabled}>
            Auto Approval
          </FormLabel>

          <div>
            <FormControlLabel
              control={
                <Switch
                  // checked={isDynamicRepoAccount}
                  // onChange={handleChange}
                  name="autoApprove"
                  color="primary"
                  checked={autoApprove}
                  onChange={handleChange}
                />
              }
              disabled={disabled}
              label="Automatically approve access requests for this local account for durations up to"
              className={classes.formControlInput}
              {...props}
            />
          </div>
          <div css={{ marginLeft: '48px' }}>
            <DurationInput
              title="Auto Approval"
              classes={classes}
              disabled={!autoApprove}
              durationObject={maxAutoApproveDurationObject}
              handleChange={handleChange}
            />
          </div>
        </div>
      </Fragment>
    )
  }
)

const DeleteRepositoryAccountDialog = withStyles(styles)(
  ({ open, onClose, repoAccount, handleDelete, classes }) => {
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogContent>
          <Typography>
            Please delete all mappings to the local account <strong>{repoAccount.username}</strong>{' '}
            before continuing.
          </Typography>
        </DialogContent>
        <DialogActions css={{ display: 'flex', padding: '24px', justifyContent: 'flex-end' }}>
          {/* <Button onClick={onClose}>Cancel</Button> */}
          <Button
            variant="contained"
            color="primary"
            // className={classes.deleteButton}
            onClick={() => {
              onClose()
              //   handleDelete(repoAccount.uuid)
            }}
          >
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
)

const S3RepositoryAccountForm = withStyles(styles)(
  ({ repoAccount, add, onClose, classes, handleCreate, handleUpdate, handleDelete }) => {
    const [secretIdentifier, setSecretIdentifier] = useState(
      add ? '' : repoAccount.secretIdentifier
    )
    const [s3Buckets, setS3Buckets] = useState(add ? '' : repoAccount.s3Buckets)
    const [autoApprove, setAutoApprove] = useState(add ? false : repoAccount?.autoApprove)
    const [maxAutoApproveDurationObject, setMaxAutoApproveDurationObject] = useState(
      add ? {} : isoToDurationObject(repoAccount.maxAutoApproveDuration) || {}
    )
    const [saved, setSaved] = useState(false)
    const [addReady, setAddReady] = useState(false)
    const [deleteOpen, setDeleteOpen] = useState(false)

    const handleChange = event => {
      event.persist()
      if (event.target.name === 'secretIdentifier') {
        setSecretIdentifier(event.target.value)
      } else if (event.target.name === 's3Buckets') {
        setS3Buckets(event.target.value)
      } else if (event.target.name === 'autoApprove') {
        setAutoApprove(event.target.checked)
      } else if (event.target.name === 'days') {
        setMaxAutoApproveDurationObject(prevObj => ({ ...prevObj, days: event.target.value }))
      } else if (event.target.name === 'hours') {
        setMaxAutoApproveDurationObject(prevObj => ({ ...prevObj, hours: event.target.value }))
      } else if (event.target.name === 'minutes') {
        setMaxAutoApproveDurationObject(prevObj => ({ ...prevObj, minutes: event.target.value }))
      } else if (event.target.name === 'seconds') {
        setMaxAutoApproveDurationObject(prevObj => ({ ...prevObj, seconds: event.target.value }))
      }
    }

    const resetForm = () => {
      setSecretIdentifier(add ? '' : repoAccount.secretIdentifier)
      setAutoApprove(add ? false : repoAccount.autoApprove)
      setMaxAutoApproveDurationObject(
        add ? {} : isoToDurationObject(repoAccount.maxAutoApproveDuration) || {}
      )
    }

    const handleClose = () => {
      onClose()
      resetForm()
    }

    useEffect(() => {
      setAddReady(
        !!secretIdentifier &&
          (!autoApprove ||
            (maxAutoApproveDurationObject &&
              !_.isEqualWith(maxAutoApproveDurationObject, {}, timeCustomizer)))
      )
    }, [secretIdentifier, autoApprove, maxAutoApproveDurationObject])

    useEffect(() => {
      if (!add) {
        const autoApproveSame = !!autoApprove === !!repoAccount.autoApprove
        const maxAutoApproveDurationSame = _.isEqualWith(
          maxAutoApproveDurationObject,
          isoToDurationObject(repoAccount.maxAutoApproveDuration),
          timeCustomizer
        )
        setSaved(
          secretIdentifier === repoAccount.secretIdentifier &&
            s3Buckets === repoAccount.s3Buckets &&
            autoApproveSame &&
            maxAutoApproveDurationSame
        )
      }
    }, [secretIdentifier, s3Buckets, repoAccount, autoApprove, maxAutoApproveDurationObject])

    useEffect(() => {
      resetForm()
    }, [repoAccount])

    if (add)
      return (
        <Fragment>
          <div
            css={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
              marginBottom: '12px',
            }}
          >
            <Typography variant="h6">Track Account</Typography>
            <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <SecretIdentifierInput
            add
            fullWidth
            secretIdentifier={secretIdentifier}
            handleChange={handleChange}
            secretsManager="awsIAM"
            css={{ marginTop: 0, marginBottom: '12px' }}
          />
          {window._env_.show_s3_buckets_input === 'true' && (
            <S3BucketsInput add fullWidth s3Buckets={s3Buckets} handleChange={handleChange} />
          )}
          <AutoApproveInput
            handleChange={handleChange}
            autoApprove={autoApprove}
            maxAutoApproveDurationObject={maxAutoApproveDurationObject}
          />
          <div
            css={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
            className={`${classes.formControl} ${classes.last}`}
          >
            <Button
              variant="contained"
              color="primary"
              disabled={!addReady}
              onClick={() => {
                handleCreate({
                  secretIdentifier,
                  s3Buckets,
                  secretsManager: 'awsIAM',
                  ...(autoApprove ? { autoApprove } : {}),
                  ...(maxAutoApproveDurationObject &&
                  !_.isEqualWith(maxAutoApproveDurationObject, {}, timeCustomizer)
                    ? { maxAutoApproveDuration: durationObjectToIso(maxAutoApproveDurationObject) }
                    : {}),
                })
                handleClose()
              }}
            >
              Track
            </Button>
          </div>
        </Fragment>
      )
    else
      return (
        <div
          css={{ display: 'flex', flexDirection: 'column', margin: '-8px', marginRight: '-32px' }}
        >
          <DeleteRepositoryAccountDialog
            open={deleteOpen}
            onClose={() => setDeleteOpen(false)}
            repoAccount={repoAccount}
            handleDelete={handleDelete}
          />
          <SecretIdentifierInput
            fullWidth
            secretIdentifier={secretIdentifier}
            handleChange={handleChange}
            secretsManager="awsIAM"
            css={{ marginTop: 0, marginBottom: '12px' }}
            disabled={repoAccount.secretsManager === 'cyralStorage'}
          />
          {window._env_.show_s3_buckets_input === 'true' && (
            <S3BucketsInput fullWidth s3Buckets={s3Buckets} handleChange={handleChange} />
          )}
          <AutoApproveInput
            autoApprove={autoApprove}
            handleChange={handleChange}
            disabled={repoAccount.secretsManager === 'cyralStorage'}
            maxAutoApproveDurationObject={maxAutoApproveDurationObject}
          />
          <div
            css={{ display: 'flex', alignItems: 'center' }}
            className={`${classes.formControl} ${classes.last}`}
          >
            <div css={{ flexGrow: 1 }}>
              <Button
                className={classes.deleteButton}
                variant="contained"
                disabled={repoAccount.secretsManager === 'cyralStorage'}
                onClick={() => {
                  if (repoAccount.inUse) {
                    setDeleteOpen(true)
                  } else {
                    handleDelete(repoAccount.uuid)
                  }
                }}
              >
                Delete
              </Button>
            </div>
            <Button css={{ marginRight: '12px' }} onClick={handleClose}>
              Cancel
            </Button>
            <Button
              css={{ width: '67.3px' }}
              variant="contained"
              color="primary"
              disabled={saved || !addReady || repoAccount.secretsManager === 'cyralStorage'}
              onClick={() => {
                handleUpdate(repoAccount.uuid, {
                  secretIdentifier,
                  s3Buckets,
                  secretsManager: 'awsIAM',
                  ...(autoApprove ? { autoApprove } : {}),
                  ...(maxAutoApproveDurationObject &&
                  !_.isEqualWith(maxAutoApproveDurationObject, {}, timeCustomizer)
                    ? { maxAutoApproveDuration: durationObjectToIso(maxAutoApproveDurationObject) }
                    : {}),
                })
              }}
            >
              {/* {saved ? 'Saved' : 'Save'} */}
              Save
            </Button>
          </div>
        </div>
      )
  }
)

const RepositoryAccountForm = withStyles(styles)(
  ({ repoAccount, add, onClose, classes, handleCreate, handleUpdate, handleDelete, repoType }) => {
    const [username, setUsername] = useState(add ? '' : repoAccount.username)
    const [secretsManager, setSecretsManager] = useState(
      add ? 'awsSecretsManager' : repoAccount.secretsManager
    )
    const [secretIdentifier, setSecretIdentifier] = useState(
      add
        ? ''
        : repoAccount.secretsManager === 'environmentVariable'
        ? repoAccount.secretIdentifier.slice(ENV_VAR_PREFIX.length)
        : repoAccount.secretIdentifier
    )
    const [secretKey, setSecretKey] = useState(add ? '' : repoAccount.secretKey)
    const [databaseName, setDatabaseName] = useState(add ? 'admin' : repoAccount.databaseName || '')
    const [isDynamicRepoAccount, setIsDynamicRepoAccount] = useState(
      add ? false : repoAccount.isDynamicRepoAccount
    )
    const [autoApprove, setAutoApprove] = useState(add ? false : repoAccount?.autoApprove)
    const [maxAutoApproveDurationObject, setMaxAutoApproveDurationObject] = useState(
      add ? {} : isoToDurationObject(repoAccount.maxAutoApproveDuration) || {}
    )
    const [activeStep, setActiveStep] = useState(0)
    const [saved, setSaved] = useState(true)
    const [addReady, setAddReady] = useState(false)
    const [deleteOpen, setDeleteOpen] = useState(false)

    const handleChange = event => {
      event.persist()
      if (event.target.name === 'username') {
        setUsername(event.target.value)
      } else if (event.target.name === 'secretsManager') {
        setSecretsManager(event.target.value)
      } else if (event.target.name === 'secretIdentifier') {
        setSecretIdentifier(event.target.value)
      } else if (event.target.name === 'secretKey') {
        setSecretKey(event.target.value)
      } else if (event.target.name === 'databaseName') {
        setDatabaseName(event.target.value)
      } else if (event.target.name === 'isDyanmicRepoAccount') {
        setIsDynamicRepoAccount(event.target.checked)
      } else if (event.target.name === 'autoApprove') {
        setAutoApprove(event.target.checked)
      } else if (event.target.name === 'days') {
        setMaxAutoApproveDurationObject(prevObj => ({ ...prevObj, days: event.target.value }))
      } else if (event.target.name === 'hours') {
        setMaxAutoApproveDurationObject(prevObj => ({ ...prevObj, hours: event.target.value }))
      } else if (event.target.name === 'minutes') {
        setMaxAutoApproveDurationObject(prevObj => ({ ...prevObj, minutes: event.target.value }))
      } else if (event.target.name === 'seconds') {
        setMaxAutoApproveDurationObject(prevObj => ({ ...prevObj, seconds: event.target.value }))
      }
    }

    const resetForm = () => {
      setUsername(add ? '' : repoAccount.username)
      setSecretsManager(add ? 'awsSecretsManager' : repoAccount.secretsManager)
      setSecretIdentifier(
        add
          ? ''
          : repoAccount.secretsManager === 'environmentVariable'
          ? repoAccount.secretIdentifier.slice(ENV_VAR_PREFIX.length)
          : repoAccount.secretIdentifier
      )
      setSecretKey(add ? '' : repoAccount.secretKey)
      setDatabaseName(add ? 'admin' : repoAccount.databaseName)
      setIsDynamicRepoAccount(add ? false : repoAccount.isDynamicRepoAccount)
      setAutoApprove(add ? false : repoAccount.autoApprove)
      setMaxAutoApproveDurationObject(
        add ? {} : isoToDurationObject(repoAccount.maxAutoApproveDuration) || {}
      )
      setActiveStep(0)
    }

    const handleClose = () => {
      onClose()
      resetForm()
    }

    useEffect(() => {
      setActiveStep(!!username && (repoType !== 'mongodb' || !!databaseName) ? 1 : 0)
    }, [username, databaseName])

    useEffect(() => {
      setAddReady(
        !!username &&
          !!secretsManager &&
          !!secretIdentifier &&
          (secretsManager !== 'kubernetesSecret' || !!secretKey) &&
          (repoType !== 'mongodb' || !!databaseName) &&
          (!autoApprove ||
            (maxAutoApproveDurationObject &&
              !_.isEqualWith(maxAutoApproveDurationObject, {}, timeCustomizer)))
      )
    }, [
      username,
      secretsManager,
      secretIdentifier,
      secretKey,
      databaseName,
      autoApprove,
      maxAutoApproveDurationObject,
    ])

    useEffect(() => {
      if (!add) {
        const usernameSame = username === repoAccount.username
        const secretsManagerSame = secretsManager === repoAccount.secretsManager
        const secretIdentifierSame =
          (secretsManager === 'environmentVariable' &&
            `${ENV_VAR_PREFIX}${secretIdentifier}`.toUpperCase() ===
              repoAccount.secretIdentifier) ||
          (secretsManager !== 'environmentVariable' &&
            secretIdentifier === repoAccount.secretIdentifier)
        const secretKeySame =
          secretsManager !== 'kubernetesSecret' || secretKey === repoAccount.secretKey
        const databaseNameSame = repoType !== 'mongodb' || databaseName === repoAccount.databaseName
        // Comparing truthy versions of isDynamicRepoAccount and repoAccount.isDynamicRepoAccount to eliminate comparisons with undefined
        const isDynamicRepoAccountSame =
          !!isDynamicRepoAccount === !!repoAccount.isDynamicRepoAccount

        const autoApproveSame = !!autoApprove === !!repoAccount.autoApprove
        const maxAutoApproveDurationSame = _.isEqualWith(
          maxAutoApproveDurationObject,
          isoToDurationObject(repoAccount.maxAutoApproveDuration),
          timeCustomizer
        )

        // Check if front end inputs are considered the same as backend fields
        setSaved(
          usernameSame &&
            secretsManagerSame &&
            secretIdentifierSame &&
            secretKeySame &&
            databaseNameSame &&
            isDynamicRepoAccountSame &&
            maxAutoApproveDurationSame &&
            autoApproveSame
        )
      }
    }, [
      username,
      secretsManager,
      secretIdentifier,
      secretKey,
      databaseName,
      isDynamicRepoAccount,
      autoApprove,
      maxAutoApproveDurationObject,
      repoAccount,
    ])

    // isDynamicRepoAccount is only for hashicorp vault
    useEffect(() => {
      if (secretsManager !== 'hashicorpVault') {
        setIsDynamicRepoAccount(false)
      } else if (!add && isDynamicRepoAccount !== repoAccount.isDynamicRepoAccount) {
        // Only check this condition if in edit mode
        // If they changed isDynamicRepoAccount and switched secrets managers and then switched back to hashicorpVault without saving, use the original setting
        setIsDynamicRepoAccount(repoAccount.isDynamicRepoAccount)
      }
    }, [secretsManager])

    useEffect(() => {
      resetForm()
    }, [repoAccount])

    if (repoType === 's3')
      return (
        <S3RepositoryAccountForm
          repoAccount={repoAccount}
          add={add}
          onClose={onClose}
          handleCreate={handleCreate}
          handleUpdate={handleUpdate}
          handleDelete={handleDelete}
        />
      )

    if (add)
      return (
        <Fragment>
          <div
            css={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
              marginBottom: '12px',
            }}
          >
            <Typography variant="h6">Track Account</Typography>
            <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <Stepper
            orientation="vertical"
            activeStep={activeStep}
            connector={null}
            css={{ padding: 0 }}
          >
            <Step>
              <StepLabel>
                <FormLabel>Account username that exists on this data repository</FormLabel>
              </StepLabel>
              <StepContent>
                <UsernameInput add username={username} handleChange={handleChange} />
                {repoType === 'mongodb' && (
                  <DatabaseNameInput add databaseName={databaseName} handleChange={handleChange} />
                )}
              </StepContent>
            </Step>
            <Step>
              <StepLabel>
                <FormLabel disabled={add && activeStep < 1}>Credentials for this account</FormLabel>
              </StepLabel>
              <StepContent last>
                <SecretsManagerInput
                  add
                  secretsManager={secretsManager}
                  handleChange={handleChange}
                  disabled={add && activeStep < 1}
                  repoType={repoType}
                />
                <SecretIdentifierInput
                  add
                  secretIdentifier={secretIdentifier}
                  handleChange={handleChange}
                  disabled={add && activeStep < 1}
                  secretsManager={secretsManager}
                />
                {secretsManager === 'kubernetesSecret' && (
                  <SecretKeyInput
                    add
                    secretKey={secretKey}
                    handleChange={handleChange}
                    disabled={add && activeStep < 1}
                  />
                )}
                {secretsManager === 'hashicorpVault' && (
                  <IsDynamicRepoAccountInput
                    add
                    isDynamicRepoAccount={isDynamicRepoAccount}
                    handleChange={handleChange}
                    disabled={add && activeStep < 1}
                  />
                )}
                <AutoApproveInput
                  disabled={add && activeStep < 1}
                  handleChange={handleChange}
                  autoApprove={autoApprove}
                  maxAutoApproveDurationObject={maxAutoApproveDurationObject}
                />
              </StepContent>
            </Step>
          </Stepper>
          <div
            css={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
            className={`${classes.formControl} ${classes.last}`}
          >
            <Button
              variant="contained"
              color="primary"
              disabled={!addReady}
              onClick={() => {
                handleCreate({
                  username,
                  secretsManager,
                  secretIdentifier:
                    secretsManager === 'environmentVariable'
                      ? `${ENV_VAR_PREFIX}${secretIdentifier}`.toUpperCase()
                      : secretIdentifier,
                  ...(secretsManager === 'kubernetesSecret' ? { secretKey } : {}),
                  ...(repoType === 'mongodb' ? { databaseName } : {}),
                  ...(secretsManager === 'hashicorpVault' ? { isDynamicRepoAccount } : {}),
                  ...(autoApprove ? { autoApprove } : {}),
                  ...(maxAutoApproveDurationObject &&
                  !_.isEqualWith(maxAutoApproveDurationObject, {}, timeCustomizer)
                    ? { maxAutoApproveDuration: durationObjectToIso(maxAutoApproveDurationObject) }
                    : {}),
                })
                handleClose()
              }}
            >
              Track
            </Button>
          </div>
        </Fragment>
      )
    else
      return (
        <div
          css={{ display: 'flex', flexDirection: 'column', margin: '-8px', marginRight: '-32px' }}
        >
          <DeleteRepositoryAccountDialog
            open={deleteOpen}
            onClose={() => setDeleteOpen(false)}
            repoAccount={repoAccount}
            handleDelete={handleDelete}
          />
          <UsernameInput
            username={username}
            handleChange={handleChange}
            disabled={repoAccount.secretsManager === 'cyralStorage'}
          />
          <SecretsManagerInput
            secretsManager={secretsManager}
            handleChange={handleChange}
            disabled={repoAccount.secretsManager === 'cyralStorage'}
            repoType={repoType}
          />
          {repoType === 'mongodb' && (
            <DatabaseNameInput
              databaseName={databaseName}
              handleChange={handleChange}
              disabled={repoAccount.secretsManager === 'cyralStorage'}
            />
          )}
          <SecretIdentifierInput
            secretIdentifier={secretIdentifier}
            handleChange={handleChange}
            disabled={repoAccount.secretsManager === 'cyralStorage'}
            secretsManager={secretsManager}
          />
          {secretsManager === 'kubernetesSecret' && (
            <SecretKeyInput secretKey={secretKey} handleChange={handleChange} />
          )}
          {secretsManager === 'hashicorpVault' && (
            <IsDynamicRepoAccountInput
              isDynamicRepoAccount={isDynamicRepoAccount}
              handleChange={handleChange}
              disabled={repoAccount.secretsManager === 'cyralStorage'}
            />
          )}
          <AutoApproveInput
            autoApprove={autoApprove}
            handleChange={handleChange}
            disabled={repoAccount.secretsManager === 'cyralStorage'}
            maxAutoApproveDurationObject={maxAutoApproveDurationObject}
          />
          <div
            css={{ display: 'flex', alignItems: 'center' }}
            className={`${classes.formControl} ${classes.last}`}
          >
            <div css={{ flexGrow: 1 }}>
              <Button
                className={classes.deleteButton}
                variant="contained"
                disabled={repoAccount.secretsManager === 'cyralStorage'}
                onClick={() => {
                  if (repoAccount.inUse) {
                    setDeleteOpen(true)
                  } else {
                    handleDelete(repoAccount.uuid)
                  }
                }}
              >
                Delete
              </Button>
            </div>
            <Button css={{ marginRight: '12px' }} onClick={handleClose}>
              Cancel
            </Button>
            <Button
              css={{ width: '67.3px' }}
              variant="contained"
              color="primary"
              disabled={saved || !addReady || repoAccount.secretsManager === 'cyralStorage'}
              onClick={() => {
                handleUpdate(repoAccount.uuid, {
                  username,
                  secretsManager,
                  secretIdentifier:
                    secretsManager === 'environmentVariable'
                      ? `${ENV_VAR_PREFIX}${secretIdentifier}`.toUpperCase()
                      : secretIdentifier,
                  ...(secretsManager === 'kubernetesSecret' ? { secretKey } : {}),
                  ...(repoType === 'mongodb' ? { databaseName } : {}),
                  ...(secretsManager === 'hashicorpVault' ? { isDynamicRepoAccount } : {}),
                  ...(autoApprove ? { autoApprove } : {}),
                  ...(maxAutoApproveDurationObject &&
                  !_.isEqualWith(maxAutoApproveDurationObject, {}, timeCustomizer)
                    ? { maxAutoApproveDuration: durationObjectToIso(maxAutoApproveDurationObject) }
                    : {}),
                })
              }}
            >
              {/* {saved ? 'Saved' : 'Save'} */}
              Save
            </Button>
          </div>
        </div>
      )
  }
)

const RepositoryAccountRow = withStyles(styles)(
  ({
    repoAccount,
    openRow,
    setOpenRow,
    menuOpen,
    setMenuOpen,
    classes,
    handleUpdate,
    handleDelete,
    repoType,
  }) => {
    const [anchorEl, setAnchorEl] = useState(null)
    const [deleteOpen, setDeleteOpen] = useState(false)

    const open = repoAccount.uuid === openRow

    return (
      <Fragment>
        <DeleteRepositoryAccountDialog
          open={deleteOpen}
          onClose={() => {
            setMenuOpen(false)
            setDeleteOpen(false)
          }}
          repoAccount={repoAccount}
          handleDelete={handleDelete}
        />
        <TableRow>
          <TableCell css={{ width: '80%' }}>
            <EllipsisTypography width="600px">{repoAccount.username}</EllipsisTypography>
          </TableCell>
          {/* <TableCell css={{ width: '70%' }}></TableCell> */}
          {/* <TableCell align="right">Updated 2m ago</TableCell>
                <TableCell css={{width: '10%'}}>Avatar</TableCell> */}
          <TableCell css={{ width: '10%' }} align="right">
            <IconButton
              size="small"
              onClick={e => {
                if (!open) {
                  setAnchorEl(e.target)
                  setMenuOpen(!menuOpen)
                } else {
                  setOpenRow(null)
                }
              }}
            >
              {!open ? <MoreVertIcon /> : <KeyboardArrowUpIcon />}
            </IconButton>
            <Menu open={menuOpen} onClose={() => setMenuOpen(false)} anchorEl={anchorEl}>
              <MenuItem
                onClick={() => {
                  setOpenRow(repoAccount.uuid)
                  setMenuOpen(false)
                }}
              >
                {repoAccount.secretsManager === 'cyralStorage' ? 'View' : 'Edit'}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  if (repoAccount.inUse) {
                    setDeleteOpen(true)
                  } else {
                    setMenuOpen(false)
                    handleDelete(repoAccount.uuid)
                  }
                }}
                className={classes.deleteColor}
                css={{ border: 'none' }}
                disabled={repoAccount.secretsManager === 'cyralStorage'}
              >
                Delete
              </MenuItem>
            </Menu>
          </TableCell>
        </TableRow>
        <ExpandableRow expanded={open}>
          <RepositoryAccountForm
            repoAccount={repoAccount}
            handleUpdate={handleUpdate}
            onClose={() => setOpenRow(null)}
            handleDelete={handleDelete}
            repoType={repoType}
          />
        </ExpandableRow>
      </Fragment>
    )
  }
)

const AddRepositoryAccount = ({ adding, handleClose, handleCreate, repoType }) => (
  <Collapse in={adding}>
    <div css={{ border: '1px solid rgb(224, 224, 224, 1)', padding: '24px', borderRadius: '8px' }}>
      <RepositoryAccountForm
        add
        onClose={handleClose}
        handleCreate={handleCreate}
        repoType={repoType}
      />
    </div>
  </Collapse>
)

const ZeroStateCell = styled(TableCell)`
  height: 100px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
`

export const RepositoryAccounts = withStyles(styles)(({ repo, onSubmit, classes }) => {
  const [openRow, setOpenRow] = useState(null)
  const [menuOpenRow, setMenuOpenRow] = useState(null)
  const [adding, setAdding] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const { repoAccounts, createRepoAccount, updateRepoAccount, deleteRepoAccount } = useRepoAccounts(
    repo,
    onSubmit
  )
  const [displayedRepoAccounts, setDisplayedRepoAccounts] = useState(repoAccounts)

  useEffect(() => {
    if (!!searchQuery) {
      setDisplayedRepoAccounts(
        repoAccounts.filter(repoAccount =>
          repoAccount.username.toLowerCase().includes(searchQuery.toLowerCase())
        )
      )
    } else {
      setDisplayedRepoAccounts(repoAccounts)
    }
  }, [searchQuery])

  useEffect(() => {
    setDisplayedRepoAccounts(repo.repoAccounts)
  }, [repo])

  return (
    <div css={{ padding: '0 36px', width: '900px', position: 'relative' }}>
      {/* <Typography variant="h6">Repository Accounts</Typography> */}
      {repoAccounts === null ? (
        <Loading />
      ) : (
        <Fragment>
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              height: '29px',
              marginBottom: '24px',
            }}
          >
            <Typography css={{ flexGrow: 1 }}>
              {`${
                repo.type === 's3' ? 'IAM roles' : 'Native accounts'
              } created locally for this data repository`}
            </Typography>
            {!adding && <ActionButton onClick={() => setAdding(true)} />}
          </div>
          <AddRepositoryAccount
            handleClose={() => setAdding(false)}
            adding={adding}
            handleCreate={createRepoAccount}
            repoType={repo.type}
          />
          {/* <div css={{display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', margin: '24px 0 12px 0'}}>
                            <TextField 
                                variant="outlined"
                                label="Search..." 
                                margin="dense"
                                value={searchQuery}
                                onChange={e => setSearchQuery(e.target.value)}
                                InputProps={{startAdornment: <InputAdornment position="start"><SearchIcon css={{ color: '#dadada' }} /></InputAdornment>}}>
                            </TextField>
                            {!adding && <Button variant="contained" color="primary" onClick={() => setAdding(true)}>Track Account</Button>}
                        </div> */}
          <div
            css={{
              border: '1px solid rgb(224, 224, 224, 1)',
              borderBottom: 'none',
              marginTop: adding ? '24px' : 0,
              // borderRadius: '8px',
            }}
          >
            <Table>
              <TableBody>
                {displayedRepoAccounts.length === 0 ? (
                  <ZeroStateCell>None added yet</ZeroStateCell>
                ) : (
                  displayedRepoAccounts.map(repoAccount => (
                    <RepositoryAccountRow
                      key={`${repoAccount.uuid}_row`}
                      repoAccount={repoAccount}
                      openRow={openRow}
                      setOpenRow={setOpenRow}
                      menuOpen={repoAccount.uuid === menuOpenRow}
                      setMenuOpen={open => setMenuOpenRow(open ? repoAccount.uuid : null)}
                      handleUpdate={updateRepoAccount}
                      handleDelete={deleteRepoAccount}
                      repoType={repo.type}
                    />
                  ))
                )}
              </TableBody>
            </Table>
          </div>
        </Fragment>
      )}
    </div>
  )
})
