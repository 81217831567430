import { Box } from '@mui/material'

interface HelpAndSupportCardIconProps {
  children: JSX.Element
}

const HelpAndSupportCardIcon = ({ children }: HelpAndSupportCardIconProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'cyralColors.primary.100',
        borderRadius: theme => theme.radii.base,
        padding: theme => theme.spacing(1),
        color: 'primary.main',
        fontSize: 32,
      }}
    >
      {children}
    </Box>
  )
}

export default HelpAndSupportCardIcon
