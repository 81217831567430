import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { useAuth } from '@jeeves/components/Auth'
import ConnectionFields, { Host, Port, Username, Password } from '../../ConnectionInfoFields'

import { getUsername, getHost, getPorts } from '../../../../utils'

const DenodoAccordionContent = ({
  repo,
  accessPortalSidecar,
  accessPortalSidecarBinding,
  userAccountEdge,
}) => {
  const [showPassword, setShowPassword] = useState(false)
  const [accessToken, setAccessToken] = useState('')
  const { user } = useAuth()

  const connectionInfo = {
    username: getUsername(user, repo, userAccountEdge),
    host: getHost(accessPortalSidecar),
    ports: getPorts(accessPortalSidecarBinding),
    password: accessToken,
  }

  return (
    <Stack spacing={3}>
      <Box display="grid" gridTemplateColumns="repeat(2, 1fr)">
        <Stack spacing={0.5}>
          <Typography
            variant="h6"
            sx={{
              color: 'text.secondary',
            }}
          >
            Connection Info
          </Typography>

          <ConnectionFields connectionInfo={connectionInfo}>
            <Username />
            <Password
              showPassword={showPassword}
              setShowPassword={setShowPassword}
              setAccessToken={setAccessToken}
            />
            <Host />
            <Port />
          </ConnectionFields>
        </Stack>
      </Box>
    </Stack>
  )
}

export default DenodoAccordionContent
