import React from 'react'
import { Checkbox, List, ListItem, ListItemIcon, ListItemText, withStyles } from '@material-ui/core'
import { SectionTitle } from '../../../Text'

const styles = theme => ({
  listItems: {
    paddingTop: 0,
    paddingBottom: 0,
    cursor: 'pointer',
    height: '30px',
  },
  list: {
    display: 'grid',
    gridGap: '0',
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  listItemText: {
    marginLeft: 0,
    padding: 0,
  },
  listItemIcon: {
    marginRight: 0,
  },
})

const PermissionsForm = ({ classes, permissions = [], checked, handleOnToggle, disabled }) => (
  <div>
    <SectionTitle variant="h6">Permissions</SectionTitle>
    <List className={classes.list}>
      {permissions.map((permission, index) => (
        <ListItem
          dense
          className={classes.listItems}
          key={permission.id}
          disabled={disabled}
          onClick={() => handleOnToggle(permission.id)}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <Checkbox
              color="primary"
              edge="start"
              checked={checked.includes(permission.id)}
              tabIndex={-1}
              disableRipple
              disabled={disabled}
            />
          </ListItemIcon>
          <ListItemText primary={`${permission.name}`} className={classes.listItemText} />
        </ListItem>
      ))}
    </List>
  </div>
)

export default withStyles(styles)(PermissionsForm)
