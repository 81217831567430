import React, { useState } from 'react'

const AlertsContext = React.createContext([{}, () => {}])

const AlertsProvider = props => {
  const [state, setState] = useState({
    alerts: undefined,
    page: 0,
    rowsPerPage: 25,
    order: 'desc',
    orderBy: 'time',
    timeSpan: 'FIFTEEN_MINS',
  })

  return <AlertsContext.Provider value={[state, setState]}>{props.children}</AlertsContext.Provider>
}

export { AlertsContext, AlertsProvider }
