import * as React from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { Accordion, AccordionSummary, AccordionDetails, InlineCode } from '@jeeves/new-components'

const SensitiveFields = ({ sensitiveFields }) => {
  const numberOfSensitiveFields = sensitiveFields.length

  return (
    <Box>
      <Accordion>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography variant="h6" sx={{ color: 'text.secondary' }}>
            Fields {numberOfSensitiveFields > 0 ? `(${numberOfSensitiveFields})` : ''}
          </Typography>
        </AccordionSummary>

        <AccordionDetails>
          <Stack spacing={2}>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {numberOfSensitiveFields > 0
                ? `This user has access to ${numberOfSensitiveFields} sensitive fields:`
                : 'No fields requested'}
            </Typography>

            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {sensitiveFields.map(field => (
                <InlineCode key={field}>{field}</InlineCode>
              ))}
            </Box>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

export default SensitiveFields
