import { FormControl, FormHelperText, Box, Stack } from '@mui/material'

import { Input, InputLabel } from '@jeeves/new-components'
import { getDocsURL } from '@jeeves/utils/docsURL'

import { LoggingIntegrationIcon } from '@jeeves/pages/Integrations/Services/Logging/components'

import Header from '@jeeves/pages/Integrations/Services/Logging/components/NewIntegrationDialog/components/Header'
import Footer from '@jeeves/pages/Integrations/Services/Logging/components/NewIntegrationDialog/components/Footer'

import { useCustom } from './useCustom'

const CONFIG_PLACEHOLDER = `[FILTER]
    Name  grep
    Match *
    Regex log foo

[OUTPUT]
    Name  stdout
    Match *
`

const Custom = ({ integration, resetIntegration, onClose }) => {
  const isEditing = Boolean(integration?.id)

  const { loading, methods, onSubmit } = useCustom({ integration, onClose, isEditing })

  const {
    formState: { errors },
    register,
  } = methods

  return (
    <Stack spacing={4} component="form" onSubmit={onSubmit}>
      <Header
        icon={<LoggingIntegrationIcon integrationType="CustomLoggingIntegration" />}
        title="Custom"
        onClose={onClose}
      />

      <FormControl variant="standard" error={Boolean(errors?.name)}>
        <InputLabel htmlFor="name" required>
          Name
        </InputLabel>
        <Input
          id="name"
          inputProps={{
            ...register('name'),
          }}
        />
        <FormHelperText
          sx={{
            typography: 'body2',
          }}
          error={Boolean(errors?.name)}
        >
          {errors?.name?.message}
        </FormHelperText>
      </FormControl>

      <FormControl variant="standard" error={Boolean(errors?.config)}>
        <InputLabel htmlFor="config" required>
          Config
        </InputLabel>
        <Input
          id="config"
          multiline
          rows={8}
          inputProps={{
            ...register('config'),
          }}
          placeholder={CONFIG_PLACEHOLDER}
        />
        <FormHelperText
          sx={{
            typography: 'body2',
          }}
        >
          {errors?.config?.message || (
            <>
              Visit our{' '}
              <Box
                component="a"
                href={getDocsURL({ docsPath: 'integrations/siem/custom' })}
                target="_blank"
                rel="noreferrer"
              >
                public docs
              </Box>{' '}
              for more information and configuration examples
            </>
          )}
        </FormHelperText>
      </FormControl>

      <Footer
        loading={loading}
        isEditing={isEditing}
        resetIntegration={resetIntegration}
        onClose={onClose}
      />
    </Stack>
  )
}

export default Custom
