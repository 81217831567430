import { Stack } from '@mui/material'
import { ApolloError } from '@apollo/client'

import { FragmentType, graphql, useFragment } from '@jeeves/graphql'

import AccessTokenTable from './AccessTokenTable'
import MaxTokensAlert from './MaxTokensAlert'
import ManageAccessTokenFooter from './ManageAccessTokenFooter'
import RemainingTokens from './RemainingTokens'
import { Views } from '../../types'

const ManageAccessToken_QueryFragment = graphql(`
  fragment ManageAccessToken_QueryFragment on Query {
    ...AccessTokenTable_QueryFragment
    ...MaxTokensAlert_QueryFragment
    ...ManageAccessTokenFooter_QueryFragment
    ...RemainingTokens_QueryFragment
  }
`)

interface Props {
  loading: boolean
  error?: ApolloError
  query?: FragmentType<typeof ManageAccessToken_QueryFragment>
  setView: React.Dispatch<React.SetStateAction<Views>>
  setSelectedAccessTokenId: React.Dispatch<React.SetStateAction<string>>
}

const ManageAccessToken = ({
  loading,
  error,
  query: queryProp,
  setView,
  setSelectedAccessTokenId,
}: Props) => {
  const query = useFragment(ManageAccessToken_QueryFragment, queryProp)

  return (
    <Stack spacing={4}>
      {!loading && !error && <MaxTokensAlert query={query!} />}

      <Stack spacing={2}>
        <AccessTokenTable
          loading={loading}
          setView={setView}
          error={error}
          query={query}
          setSelectedAccessTokenId={setSelectedAccessTokenId}
        />
        {!loading && !error && <RemainingTokens query={query!} />}
      </Stack>

      {!loading && !error && <ManageAccessTokenFooter query={query!} setView={setView} />}
    </Stack>
  )
}

export default ManageAccessToken
