import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const RedshiftIcon = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 1615 1783.7">
      <path d="m807.5 1363.8 678.3 161.5V254.8L807.5 416.3z" fill="#205b97" />
      <path
        d="m1485.8 254.8 129.2 64.6v1141.3l-129.2 64.6zm-678.3 1109-678.3 161.5V254.8l678.3 161.5z"
        fill="#5193ce"
      />
      <path d="M129.2 254.8 0 319.4v1141.3l129.2 64.6z" fill="#205b97" />
      <path d="m979.8 1783.7 258.4-129.2V129.2L979.8 0l-79 847z" fill="#5193ce" />
      <path d="m635.2 1783.7-258.4-129.2V129.2L635.2 0l79 847z" fill="#205b97" />
      <path d="M635.2 0h348.1v1780.1H635.2z" fill="#2e73b7" />
    </SvgIcon>
  )
}

export default RedshiftIcon
