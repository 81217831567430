import { useParams } from 'react-router-dom'

import { useMutation } from '@apollo/client'
import { graphql } from '@jeeves/graphql'

import { useToast } from '@jeeves/new-components'
import { getGraphQLErrorMessage } from '@jeeves/utils/helpers'

const CREATE_SIDECAR_CREDENTIAL = graphql(`
  mutation CreateSidecarCredential($sidecarId: ID!) {
    createSidecarCredential(sidecarId: $sidecarId) {
      clientSecret
      sidecarCredential {
        clientId
        sidecar {
          id
          credentials {
            clientId
          }
        }
      }
    }
  }
`)

const useNewCredentialsModal = () => {
  const { id: sidecarId } = useParams<{ id: string }>()
  const { toast } = useToast()

  const [createSidecarCredential, { data, loading, reset }] = useMutation(
    CREATE_SIDECAR_CREDENTIAL,
    {
      variables: {
        sidecarId,
      },
      onError: error => {
        toast({
          variant: 'error',
          description:
            getGraphQLErrorMessage(error) ||
            'An error occurred when generating a new credential. Please try again later.',
        })
      },
    }
  )

  const handleNewCredential = async () => {
    await createSidecarCredential()
  }

  const closeModal = () => {
    reset()
  }

  return {
    handleNewCredential,
    closeModal,
    loading,
    credentials: {
      clientSecret: data?.createSidecarCredential?.clientSecret,
      clientId: data?.createSidecarCredential?.sidecarCredential?.clientId,
    },
  }
}

export default useNewCredentialsModal
