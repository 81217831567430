/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useState, Fragment } from 'react'
import { jsx, css } from '@emotion/react'
import { TextField, MenuItem, Menu } from '@material-ui/core'
import useAuditLogs from './hooks/useAuditLogs'
import { Button } from '@jeeves/components/Primitives'
import { ButtonLoading } from '@jeeves/components/Loading'
import usePopup from '@jeeves/components/PopupMessage/hooks/usePopup'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { parse } from 'json2csv'

const FORMATS = [
  { id: 'json', label: 'as JSON' },
  { id: 'csv', label: 'as CSV' },
]

const Downloader = ({ auditLogs }) => {
  const [downloading, setDownloading] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const { setPopup, popupTypes } = usePopup()

  const handleClick = e => {
    setAnchorEl(e.currentTarget)
  }

  const downloadFile = async format => {
    setDownloading(true)
    handleClose()
    try {
      const element = document.createElement('a')
      // eslint-disable-next-line
      let file
      if (format === 'json') {
        file = new Blob([JSON.stringify(auditLogs, null, 2)])
        element.download = 'auditLogs.json'
      } else {
        file = new Blob([parse(auditLogs)])
        element.download = 'auditLogs.csv'
      }
      element.href = URL.createObjectURL(file)
      document.body.appendChild(element)
      element.click()
    } catch {
      const popupMessage = 'Error while downloading audit logs'
      setPopup(popupTypes.ERROR, popupMessage)
    }
    setDownloading(false)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <div css={{ position: 'relative' }}>
        <Button variant="contained" onClick={handleClick} disabled={downloading}>
          Download
          <KeyboardArrowDownIcon fontSize="small" css={{ marginLeft: '8px' }} />
        </Button>
        {downloading && <ButtonLoading />}
      </div>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {FORMATS.map(format => (
          <MenuItem key={format.id} onClick={() => downloadFile(format.id)}>
            {format.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

export default Downloader
