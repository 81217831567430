/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import styled from '@emotion/styled'
import MuiTooltip from '@material-ui/core/Tooltip'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import { blueGrey } from '@material-ui/core/colors'

const Icon = styled.span`
  display: inline-block;
  position: relative;
  top: 4px;
  margin: 0 8px;
`

export const Tooltip = ({ title, ...props }) => (
  <MuiTooltip aria-label={title} {...props} title={title}>
    <Icon>
      <InfoIcon nativeColor={blueGrey[600]} />
    </Icon>
  </MuiTooltip>
)
