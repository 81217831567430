import { Stack } from '@mui/material'
import PolicyRule from '../../../../PolicyRule'
import { useRepositoryProtectionVariation } from '../useRepositoryProtectionVariation'

const Variation1 = () => {
  const { operations, repoName, limit } = useRepositoryProtectionVariation()

  return (
    <Stack spacing={1}>
      <PolicyRule title="This policy will" content="ALERT" />
      <PolicyRule title="whenever any" content={operations} />
      <PolicyRule title="of more than:" content={limit} />
      <PolicyRule title="are made to the entire repository" content={repoName} />
    </Stack>
  )
}

export default Variation1
