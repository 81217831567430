import styled from '@emotion/styled'

import { useAuth } from '@jeeves/hooks'
import { AddIntegrationButtonUnformatted } from '@jeeves/pages/Integrations/primitives'

const NewAwsIamIntegrationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

interface NewAwsIamIntegrationProps {
  handleNewAwsIamIntegration: () => void
}

const NewAwsIamIntegration = ({ handleNewAwsIamIntegration }: NewAwsIamIntegrationProps) => {
  const { hasPermission } = useAuth()

  return (
    <NewAwsIamIntegrationWrapper>
      <AddIntegrationButtonUnformatted
        setOpen={handleNewAwsIamIntegration}
        disabled={!hasPermission('integrations:create')}
      />
    </NewAwsIamIntegrationWrapper>
  )
}

export default NewAwsIamIntegration
