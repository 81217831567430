import React from 'react'
import Link from '@mui/material/Link'

import { InlineCode } from '@jeeves/new-components'
import { getPosticoUri, getPsqlCommand } from '../utils'

import { DBeaver } from './components/DBeaver'

const getConnectionTypes = (connectionInfo, options) => {
  const { ports, ...rest } = connectionInfo
  const { showPassword } = options

  //   const generalUri = getGeneralPostgresUri({ connectionInfo, redactPassword: !showPassword })
  const escapedPosticoUri = getPosticoUri({
    connectionInfo,
  })

  const psqlCommandConnectionInfo = {
    ...rest,
    port: ports[0],
  }

  return [
    // {
    //   label: 'General',
    //   value: 'general',
    //   content: (
    //     <InlineCode sx={{ backgroundColor: 'common.white' }} noWrap>
    //       {generalUri.unescaped}
    //     </InlineCode>
    //   ),
    //   copyValue: generalUri.escaped,
    // },
    {
      label: 'PSQL',
      value: 'psql',
      content: (
        <InlineCode sx={{ backgroundColor: 'common.white' }} noWrap>
          {getPsqlCommand({
            connectionInfo: psqlCommandConnectionInfo,
            redactPassword: !showPassword,
          })}
        </InlineCode>
      ),
      copyValue: getPsqlCommand({ connectionInfo: psqlCommandConnectionInfo }),
    },
    {
      label: 'Postico',
      value: 'postico',
      content: (
        <InlineCode sx={{ backgroundColor: 'common.white' }} noWrap>
          <Link href={escapedPosticoUri} underline="hover">
            {getPosticoUri({
              connectionInfo,
              options: {
                showPassword,
              },
            })}
          </Link>
        </InlineCode>
      ),
      copyValue: escapedPosticoUri,
    },
    {
      label: 'DBeaver',
      value: 'dbeaver',
      content: <DBeaver />,
      copyValue: null,
    },
  ]
}

export default getConnectionTypes
