/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useState, Fragment } from 'react'
import styled from '@emotion/styled'
import { css, jsx } from '@emotion/react'
import { useForm } from 'react-hook-form'
import { usePopup } from '@jeeves/hooks'
import { getDocsURL } from '@jeeves/utils/docsURL'

import {
  Button,
  CardActions,
  CircularProgress,
  Divider as MuiDivider,
  Grid,
  Typography,
} from '@material-ui/core/'
import IntegrationPanel from './AddFormPanel'
import { TextFieldWithToolTipController, UploadZone, FieldDetails } from '../components'

const FullWidth = styled.div`
  width: 100%;
`

const Divider = styled(MuiDivider)`
  margin-top: -15px;
  margin-bottom: 15px;
  width: 100%;
`

const StyledCircularProgress = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
`

const ActionsContainer = styled(Grid)`
  justify-content: flex-end;
`

const SaveButtonContainer = styled.div`
  position: relative;
`

const Link = styled.a`
  text-decoration: none;
  color: #26459e;
  font-weight: 600;
`

const createbase64SamlMetadataDocument = async files => {
  const samlMetadataFile = files?.[0]
  const samlMetaDataFileContent = await samlMetadataFile.text()

  return btoa(samlMetaDataFileContent)
}

const AddForm = ({ addIntegration, handleRemoveAddField }) => {
  const [loading, setLoading] = useState(false)
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm()
  const { setPopup, popupTypes } = usePopup()

  const samlMetadata = watch('samlMetadata')

  const onSubmit = async ({ displayName, samlMetadata }) => {
    try {
      const base64SamlMetadataDocument = await createbase64SamlMetadataDocument(samlMetadata)

      const integration = {
        identityProvider: 'gsuite',
        displayName,
        base64SamlMetadataDocument,
      }

      await handleOnAddIntegration(integration)
      setPopup(
        popupTypes.SUCCESS,
        'Success! Please allow up to 48 hours for changes to propagate and take effect.',
        {
          displayTime: 4000,
        }
      )
      handleRemoveAddField()
    } catch (e) {
      console.error(e)
      throw e
    }
  }

  const handleOnAddIntegration = async integration => {
    try {
      setLoading(true)
      await addIntegration(integration)
    } catch {
    } finally {
      setLoading(false)
    }
  }

  return (
    <FullWidth>
      <Divider />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography
          css={{
            marginBottom: '1rem',
          }}
        >
          First, set up a new SAML app inside GSuite. See more instructions on this{' '}
          <Link
            href={getDocsURL({ docsPath: '/integrations/authentication/idp/gsuite' })}
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </Link>
          .
        </Typography>
        <Typography
          css={{
            marginBottom: '1rem',
          }}
        >
          Give your integration a display name.
        </Typography>

        <TextFieldWithToolTipController
          label="Display Name"
          tooltipText="Name for the integration."
          name="displayName"
          control={control}
        />

        <Typography
          css={{
            marginBottom: '1rem',
          }}
        >
          Upload the SAML metadata document you obtained from GSuite.
        </Typography>

        <UploadZone samlMetadata={samlMetadata} register={register} setValue={setValue} />

        {samlMetadata ? (
          <Fragment>
            <Typography
              css={{
                marginBottom: '1rem',
              }}
            >
              Copy/Paste the ACS URL and Entity ID given below into GSuite.
            </Typography>
            <FieldDetails samlMetadata={samlMetadata} />
          </Fragment>
        ) : null}

        <ActionsContainer item container xs={12}>
          <CardActions>
            <Button color="primary" size="small" onClick={handleRemoveAddField}>
              Cancel
            </Button>
            <SaveButtonContainer>
              <Button
                variant="contained"
                type="submit"
                size="small"
                color="primary"
                disabled={loading}
              >
                Save
              </Button>
              {loading && <StyledCircularProgress size={24} />}
            </SaveButtonContainer>
          </CardActions>
        </ActionsContainer>
      </form>
    </FullWidth>
  )
}

const AddModal = props => (
  <IntegrationPanel title={props.name} expanded={true}>
    <AddForm {...props} />
  </IntegrationPanel>
)

export default AddModal
