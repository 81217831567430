import React, { useState } from 'react'
import MUITypography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Collapse from '@mui/material/Collapse'
import { ExpandButton } from '@jeeves/components/Table'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { IconCopyButton, Button } from '@jeeves/new-components'
import { styled } from '@mui/material/styles'
import { InlineCodeContainer } from '@jeeves/pages/Integrations/Services/Okta/scim/SCIM'
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'

export const Typography = styled(props => <MUITypography {...props} />)(({ theme }) => ({
  color: theme.palette.text.primary,
}))

const SCIM = ({
  integration,
  bearerToken,
  regenerateTokenClicked,
  setRegenerateTokenClicked,
  expiresIn,
}) => {
  const [expanded, setExpanded] = useState(true)

  momentDurationFormatSetup(moment)
  const formatExpiresIn = expiresIn => {
    try {
      var dur = moment.duration(moment(expiresIn).diff(moment()))
      const formattedTime = dur.format('M [months and] d [days]')
      return formattedTime
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <Stack
      spacing={4}
      sx={{
        color: 'cyralColors.grey.400',
        mb: 2,
        border: '1px solid',
        borderColor: '#CBD2D9',
        borderRadius: 0.5,
        padding: 2,
      }}
    >
      <Stack direction="row" sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h4">Configure Your SCIM Integration</Typography>
        <ExpandButton expanded={expanded} onClick={() => setExpanded(!expanded)} />
      </Stack>

      <Collapse in={expanded} unmountOnExit>
        <Stack spacing={2}>
          <Typography>
            In your Azure AD admin console, configure your Cyral app as follows:{' '}
          </Typography>

          <Stack spacing={2}>
            <Typography>
              1. Navigate to the <strong>Provisioning</strong> tab in the left sidebar and click Get
              Started.
            </Typography>
            <Typography>
              2. Set your <strong>Provisioning Mode</strong> to Automatic.
            </Typography>
            <Typography>
              3. Configure the <strong>Admin Credentials</strong> with these values:
            </Typography>
            <Stack component="ul" spacing={2} pl={2.25}>
              <Stack spacing={1} component="li">
                <Typography variant="h4">Tenant URL</Typography>
                <InlineCodeContainer direction="row">
                  <Typography variant="code">{integration?.scimBaseURL}</Typography>

                  <IconCopyButton text={integration?.scimBaseURL} />
                </InlineCodeContainer>
              </Stack>

              <Stack spacing={1} component="li">
                <Typography variant="h4">Bearer Token</Typography>
                <Typography>
                  {bearerToken ? (
                    <InlineCodeContainer direction="row">
                      <Typography variant="code" sx={{ overflow: 'auto', maxHeight: '16px' }}>
                        {bearerToken}
                      </Typography>

                      <IconCopyButton text={bearerToken} />
                    </InlineCodeContainer>
                  ) : !integration?.scimIntegration ? (
                    <span>
                      Please click <strong>Save</strong> to generate the Bearer Token.
                    </span>
                  ) : regenerateTokenClicked ? (
                    <span>
                      Please <strong>Save</strong> this integration to generate a new Bearer Token.
                      Note that this will invalidate the existing token and disrupt the SCIM
                      integration until the new Bearer Token is provided in your Azure AD console.
                    </span>
                  ) : (
                    <Stack spacing={1} sx={{ alignItems: 'flex-start' }}>
                      <Typography>
                        For security reasons, we can only show the bearer token when it was
                        generated.
                      </Typography>
                      <Typography>
                        This token will expire in{' '}
                        <strong>
                          {formatExpiresIn(integration?.scimIntegration?.authSecretExpiry)}
                        </strong>
                        .
                      </Typography>
                      <Button onClick={() => setRegenerateTokenClicked(true)}>
                        Regenerate Token
                      </Button>
                    </Stack>
                  )}
                </Typography>
                {bearerToken && (
                  <Stack spacing={1}>
                    <Typography variant="body2" sx={{ color: 'cyralColors.red.300' }}>
                      Please be sure to copy this token now because it will not be shown again.
                    </Typography>

                    <Typography variant="body2" sx={{ color: 'cyralColors.red.300' }}>
                      This token expires in <strong>{formatExpiresIn(expiresIn)}</strong>. Before
                      then, you must regenerate the token here and provide the new token in your
                      Azure AD console.
                    </Typography>
                  </Stack>
                )}
              </Stack>
            </Stack>
          </Stack>

          <Typography>
            4. Click <strong>Test Connection</strong> to verify that your credentials are authorized
            to enable provisioning. If the connection is unsuccessful, please reference the error
            message in Azure AD for troubleshooting.
          </Typography>

          <Typography>
            5. Click <strong>Save</strong> once your connection is successful.
          </Typography>

          <Typography>
            6. Refresh the page and click <strong>Edit Provisioning</strong>.
          </Typography>

          <Typography>
            7. Specify these configurations in the <strong>Settings</strong> section:
          </Typography>
          <Stack component="ul" spacing={2} pl={2.25}>
            <Stack spacing={1} component="li">
              <Typography variant="h4">Scope</Typography>
              <InlineCodeContainer direction="row">
                <Typography variant="code">Sync only assigned users and groups</Typography>
              </InlineCodeContainer>
            </Stack>

            <Stack spacing={1} componet="li">
              <Typography variant="h4">Provisioning Status</Typography>
              <InlineCodeContainer direction="row">
                <Typography variant="code">On</Typography>
              </InlineCodeContainer>
            </Stack>
          </Stack>
          <Typography>
            8. Click <strong>Save</strong>.
          </Typography>
        </Stack>
      </Collapse>
    </Stack>
  )
}

export default SCIM
