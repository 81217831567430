import sortBy from 'lodash/sortBy'

import { ALL_REPO_TYPES_WITH_DISPLAY_NAME } from '@jeeves/constants'
import { useAppConfig } from '@jeeves/hooks'
import { ServiceIcon } from '@jeeves/new-components'

const useRepoTypeSelectOptions = () => {
  const { license } = useAppConfig()

  const licenseRepositories =
    license.features
      ?.filter(
        (feature: { internalName: string }) => feature.internalName === 'AllowedRepositories'
      )
      .map((repo: { internalSubFeatureName: string }) => repo.internalSubFeatureName) ?? []

  const allOptions = ALL_REPO_TYPES_WITH_DISPLAY_NAME.map(({ typeName, displayName }) => ({
    icon: <ServiceIcon type={typeName} />,
    label: displayName,
    value: typeName,
    disabled: !licenseRepositories.includes(typeName),
  }))

  const sortedOptions = sortBy(allOptions, ['label'])

  const enabledOptions = sortedOptions.filter(option => !option.disabled)

  return {
    options: enabledOptions,
  }
}

export default useRepoTypeSelectOptions
