import { Link } from 'react-router-dom'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import { Link as MuiLink, Stack, Typography } from '@mui/material'

const SidecarHeaderButton = () => {
  return (
    <MuiLink component={Link} to="/sidecars" underline="none">
      <Stack direction="row" spacing={0.5} sx={{ alignItems: 'center', color: 'text.secondary' }}>
        <KeyboardArrowLeftIcon sx={{ fontSize: '16px' }} />
        <Typography variant="h6">Sidecars</Typography>
      </Stack>
    </MuiLink>
  )
}

export default SidecarHeaderButton
