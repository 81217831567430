import { gql } from '@apollo/client'
import { portNumberSchema } from '@jeeves/utils/helpers'
import { z } from 'zod'

export const EditRepoSchemas_repoFragment = gql`
  fragment EditRepoSchemas_repo on Repo {
    ... on ClusterRepo {
      numNodes
    }
  }
`

export const hostPortSchema = z.object({
  host: z.string().min(1, { message: 'Please enter a hostname.' }),
  port: portNumberSchema,
})

const tagsSchema = z.object({
  tags: z.array(z.object({ name: z.string() })).optional(),
})

const standardRepoSchema = hostPortSchema.merge(tagsSchema)

const mongoDBReplicaSetRepoSchema = repo => {
  const INVALID_NUM_NODES_MESSAGE = 'Please enter the number of nodes.'

  const mongoDBReplicaSetBaseSchema = z
    .object({
      replicaSetName: z.string().min(1, { message: 'Please enter a replica set name.' }),
      numNodes: z
        .number({
          required_error: INVALID_NUM_NODES_MESSAGE,
          invalid_type_error: INVALID_NUM_NODES_MESSAGE,
        })
        .min(repo.numNodes, {
          message: `The number of nodes should be greater than or equal to ${repo.numNodes}.`,
        }),
    })
    .merge(tagsSchema)

  const staticNodesSchema = mongoDBReplicaSetBaseSchema.merge(
    z.object({
      connectionFormat: z.enum(['StaticNodeSpecification']),
      nodes: z.array(hostPortSchema).min(1, {
        message: 'Please enter at least 1 host:port pair.',
      }),
    })
  )

  const SRVRecordSchema = mongoDBReplicaSetBaseSchema.merge(
    z.object({
      connectionFormat: z.enum(['SRVRecordSpecification']),
      SRVRecord: z.string().min(1, { message: 'Please enter the SRV record.' }),
    })
  )

  return z.discriminatedUnion('connectionFormat', [staticNodesSchema, SRVRecordSchema])
}

const mongoDBShardedClusterRepoSchema = repo => {
  const INVALID_NUM_NODES_MESSAGE = 'Please enter the number of mongos nodes.'

  const mongoDBReplicaSetBaseSchema = z
    .object({
      numNodes: z
        .number({
          required_error: INVALID_NUM_NODES_MESSAGE,
          invalid_type_error: INVALID_NUM_NODES_MESSAGE,
        })
        .min(repo.numNodes, {
          message: `The number of nodes should be greater than or equal to ${repo.numNodes}.`,
        }),
    })
    .merge(tagsSchema)

  const staticNodesSchema = mongoDBReplicaSetBaseSchema.merge(
    z.object({
      connectionFormat: z.enum(['StaticNodeSpecification']),
      nodes: z.array(hostPortSchema).min(1, {
        message: 'Please enter at least 1 host:port pair.',
      }),
    })
  )

  const SRVRecordSchema = mongoDBReplicaSetBaseSchema.merge(
    z.object({
      connectionFormat: z.enum(['SRVRecordSpecification']),
      SRVRecord: z.string().min(1, { message: 'Please enter the SRV record.' }),
    })
  )

  return z.discriminatedUnion('connectionFormat', [staticNodesSchema, SRVRecordSchema])
}

export const getFormSchema = repo => {
  switch (repo.__typename) {
    case 'MongoDBShardedCluster':
      return mongoDBShardedClusterRepoSchema(repo)
    case 'MongoDBReplicaSetRepo':
      return mongoDBReplicaSetRepoSchema(repo)
    default:
      return standardRepoSchema
  }
}
