import { Box, Stack, Typography } from '@mui/material'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import { useMutation } from '@apollo/client'

import { FragmentType, graphql, useFragment } from '@jeeves/graphql'
import { Button, InlineCode, useToast } from '@jeeves/new-components'
import { useSidecarDetailContext } from '@jeeves/pages/WrapperDetail/contexts/SidecarDetailContext'
import { getGraphQLErrorMessage } from '@jeeves/utils/helpers'

const SidecarVersionUpgradeCard_QueryFragment = graphql(`
  fragment SidecarVersionUpgradeCard_QueryFragment on Query {
    systemInfo {
      latestSidecarVersion
    }
    sidecar(id: $id) {
      id
      version
      instances {
        id
        versionIsDynamic
      }
    }
  }
`)

const UPDATE_SIDECAR_VERSION = graphql(`
  mutation UpdateSidecarVersion($updateSidecarVersionId: String!) {
    updateSidecarVersion(id: $updateSidecarVersionId) {
      sidecar {
        id
        version
      }
    }
  }
`)

interface Props {
  query: FragmentType<typeof SidecarVersionUpgradeCard_QueryFragment>
}

const SidecarVersionUpgradeCard = ({ query: queryProp }: Props) => {
  const query = useFragment(SidecarVersionUpgradeCard_QueryFragment, queryProp)
  const showSetSidecarVersion = query.sidecar.instances.some(instance => instance.versionIsDynamic)
  const { sidecarId } = useSidecarDetailContext()
  const { toast } = useToast()

  const [updateSidecarVersion, { loading }] = useMutation(UPDATE_SIDECAR_VERSION, {
    onError: error => {
      toast({
        variant: 'error',
        description:
          getGraphQLErrorMessage(error) ||
          'An error occurred while upgrading this sidecar version. Please try again.',
      })
    },
  })

  const handleUpgradeSidecar = async () => {
    await updateSidecarVersion({
      variables: {
        updateSidecarVersionId: sidecarId,
      },
    })
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        border: 1,
        borderColor: 'primary.main',
        padding: 4,
        borderRadius: theme => theme.radii.base,
      }}
    >
      <Stack spacing={2}>
        <Typography variant="h4" sx={{ color: 'text.primary' }}>
          New Sidecar Version Available
        </Typography>
        <Typography variant="body2" sx={{ display: 'flex', color: 'text.secondary' }}>
          Set the sidecar version from&nbsp;<InlineCode>{query?.sidecar.version}</InlineCode>&nbsp;
          <ArrowRightAltIcon fontSize="small" />
          &nbsp;
          <InlineCode>{query.systemInfo?.latestSidecarVersion}</InlineCode>
        </Typography>
      </Stack>
      {showSetSidecarVersion && (
        <Button onClick={handleUpgradeSidecar} loading={loading}>
          Set sidecar version
        </Button>
      )}
    </Box>
  )
}
export default SidecarVersionUpgradeCard
