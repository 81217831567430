import { useMutation } from '@apollo/client'
import { graphql } from '@jeeves/graphql'
import { useToast } from '@jeeves/new-components'

const DELETE_LOGGING_INTEGRATION = graphql(`
  mutation DeleteLoggingIntegration($deleteLoggingIntegrationId: String!) {
    deleteLoggingIntegration(id: $deleteLoggingIntegrationId) {
      deletedLoggingIntegrationId
    }
  }
`)

export const useLoggingIntegration = ({ integration }) => {
  const { toast } = useToast()

  const [deleteLoggingIntegration, { loading }] = useMutation(DELETE_LOGGING_INTEGRATION, {
    variables: {
      deleteLoggingIntegrationId: integration.id,
    },
    onError: () => {
      toast({
        variant: 'error',
        description: 'An error ocurred while deleting this integration, please try again.',
      })
    },
    update(cache, { data: { deleteLoggingIntegration } }) {
      const cacheId = cache.identify({
        id: deleteLoggingIntegration.deletedLoggingIntegrationId,
        __typename: integration.__typename,
      })
      cache.evict({ id: cacheId })
      cache.gc()
    },
  })

  return {
    loading,
    deleteLoggingIntegration,
  }
}
