import React from 'react'
import Table from '@mui/material/Table'

import { MappingHeader } from './MappingHeaders'
import { MappingRow } from './MappingRow'

export const MappingTable = ({ mappings, repoType }) => {
  return (
    <Table>
      <MappingHeader repoType={repoType}></MappingHeader>
      {mappings.map(mapping => (
        <MappingRow key={mapping.field.raw} mapping={mapping} repoType={repoType}></MappingRow>
      ))}
    </Table>
  )
}
