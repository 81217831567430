import { Stack, Typography } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'

const LoadingState = () => {
  return (
    <Stack spacing={2} sx={{ alignItems: 'center', pt: 4 }}>
      <CircularProgress size={64} />
      <Typography variant="h6" sx={{ color: 'text.secondary' }}>
        Loading access tokens...
      </Typography>
    </Stack>
  )
}

export default LoadingState
