import * as React from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Alert from '@mui/material/Alert'
import Typography from '@mui/material/Typography'

import { Button } from '@jeeves/new-components'

import EmptyState from './components/EmptyState'
import UpsertDatabaseAccountWizard from './components/UpsertDatabaseAccountWizard'
import ServiceAccountCard from './components/ServiceAccountCard/ServiceAccountCard'

import {
  AppsAndBiToolsProvider,
  useAppsAndBiToolsState,
  useAppsAndBiToolsDispatch,
} from './contexts/AppsAndBiToolsContext'

const AppsAndBiTools = ({
  repoType,
  serviceAccounts,
  samlIntegrations,
  selectedSidecarEdge,
  refetch,
}) => {
  const { isRegistering, isUpdating } = useAppsAndBiToolsState()
  const dispatch = useAppsAndBiToolsDispatch()

  return (
    <Box sx={{ px: 8, py: 4 }}>
      {!selectedSidecarEdge ? (
        <Alert
          severity="info"
          sx={{
            color: 'primary.main',
            backgroundColor: 'cyralColors.primary.100',

            '& .MuiAlert-icon': {
              color: 'inherit',
            },

            '& .MuiAlert-message': {
              typography: 'h6',
            },
          }}
        >
          Please ensure that this repository is accessible through a sidecar.
        </Alert>
      ) : isRegistering || isUpdating ? (
        <UpsertDatabaseAccountWizard
          repoType={repoType}
          samlIntegrations={samlIntegrations}
          serviceAccounts={serviceAccounts}
          selectedSidecarEdge={selectedSidecarEdge}
          refetch={refetch}
        />
      ) : serviceAccounts.length === 0 ? (
        <EmptyState />
      ) : (
        <Box>
          <Stack spacing={4}>
            <Stack direction="row" sx={{ justifyContent: 'flex-end' }}>
              <Button onClick={() => dispatch({ type: 'registerNewAccount' })}>
                Register Database Account
              </Button>
            </Stack>

            <Stack spacing={3}>
              {serviceAccounts.map(account => {
                return <ServiceAccountCard key={account.id} serviceAccount={account} />
              })}
            </Stack>
          </Stack>
        </Box>
      )}
    </Box>
  )
}

export default props => (
  <AppsAndBiToolsProvider>
    <AppsAndBiTools {...props} />
  </AppsAndBiToolsProvider>
)
