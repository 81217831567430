import React from 'react'

import { MongoHeader, SQLHeader, S3Header, DynamoHeader} from './index'
import { MappingsTableRow } from '../MappingTable.styles'
import TableHead from '@mui/material/TableHead'

const getMappingHeader = (repoType, actionsCol) => {
  switch (repoType) {
    case 'postgresql':
    case 'redshift':
    case 'mysql':
    case 'oracle':
      return <SQLHeader actionsCol={actionsCol}></SQLHeader>
    case 'mongodb':
      return <MongoHeader actionsCol={actionsCol}></MongoHeader>
    case 's3':
      return <S3Header actionsCol={actionsCol}></S3Header>
    case 'dynamodb':
    case 'dynamodbstreams':
        return <DynamoHeader actionsCol={actionsCol}></DynamoHeader>
    default:
      return <SQLHeader actionsCol={actionsCol}></SQLHeader>
  }
}

export const MappingHeader = ({ repoType, backgroundColor = '', actionsCol = false }) => {
  return (
    <TableHead>
      <MappingsTableRow
        sx={{
          pl: 4,
          '& > *': { borderBottom: actionsCol === true && 'unset' },
          bgcolor: backgroundColor,
        }}
      >
        {getMappingHeader(repoType, actionsCol)}
      </MappingsTableRow>
    </TableHead>
  )
}
