/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { useState, useContext } from 'react'

import {
  MenuItem,
  Table,
  TableBody,
  TableFooter,
  TableRow,
  TextField,
  withStyles,
} from '@material-ui/core'

import { stableSort, getSorting, isLoading, isError } from '@jeeves/utils'
import { TableHead, TablePagination } from '@jeeves/components/Table'
import { Button, Paper } from '@jeeves/components/Primitives'
import FiltersWrapper from '@jeeves/components/FiltersWrapper'
import Toolbar from '@jeeves/components/Toolbar'
import Loading from '@jeeves/components/Loading'
import RowSpan from '@jeeves/components/RowSpan'
import useInterval from '@jeeves/hooks/useInterval'
import SearchField from '@jeeves/components/SearchField'

import Row from './Row'
import { AlertsContext } from '../../contexts/AlertsContext'
import useAlerts from '../../hooks/useAlerts'

const styles = theme => ({
  grow: {
    flexGrow: 1,
  },
  mitreInfo: {
    fontSize: '14px',
    marginLeft: '12px',
  },
})

const AlertsTable = props => {
  const [state, setState] = useContext(AlertsContext)
  const [openAlert, setOpenAlert] = useState(null)
  const {
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
    ec,
    lodashGet,
    timeSpan,
    getTimeSpan,
    handleTimeSpan,
  } = useAlerts()

  const { classes } = props
  const { rowsPerPage, page, order, orderBy, alerts } = state

  useInterval(() => {
    try {
      return ec
        .get(`/threats/all`)
        .then(res => res.data)
        .then(data =>
          data.filter(d => new Date().getTime() - d.time * 1000 < getTimeSpan(timeSpan).value)
        )
        .then(data => setState(state => ({ ...state, alerts: data })))

      // .then(
      //   data =>data .filter(d => new Date().getTime() - d.time * 1000 < getTimeSpan(timeSpan).value)

      // .then(data => setState(state => ({ ...state, alerts: data })))
      // )
    } catch (e) {
      console.log('get alerts error: ', e)
      if (isLoading(alerts)) setState(state => ({ ...state, alerts: null }))
    }
  }, 3000)

  const columns = [
    { id: 'time', label: 'Time' },
    { id: 'severity', label: 'Severity' },
    { id: 'mitre.tactic.name', label: 'Tactic' },
    { id: 'mitre.technique.name', label: 'Technique' },

    { id: 'identity.user', label: 'Identity' },
    { id: 'details', label: null },
  ]

  const getTableBody = () => {
    if (isLoading(alerts)) {
      return (
        <RowSpan colSpan={columns.length}>
          <Loading />
        </RowSpan>
      )
    }

    if (isError(alerts)) {
      return <RowSpan colSpan={columns.length}>There was a problem loading threats</RowSpan>
    }
    if (alerts.length === 0) {
      return <RowSpan colSpan={columns.length}>No threats found</RowSpan>
    }

    return stableSort(alerts, getSorting(order, orderBy))
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((alert, index) => (
        <Row
          key={alert.id}
          alert={alert}
          key={index}
          expanded={openAlert === alert.id}
          greyedOut={openAlert && openAlert !== alert.id}
          handleExpandRow={() => setOpenAlert(openAlert === alert.id ? null : alert.id)}
          closeRow={() => setOpenAlert(null)}
        />
      ))
  }

  return (
    <Paper className={classes.mt4}>
      <Toolbar
        title="Threats"
        chip={alerts ? alerts.length : null}
        titleAction={
          <span
            css={t => css`
              position: relative;
              top: -10px;
              text-transform: uppercase;
              font-size: 10px;
              letter-spacing: 1px;
              background-color: ${t.palette.brightBlue};
              color: white;
              font-weight: bold;
              padding: 4px 8px;
              border-radius: 50px;
            `}
          >
            Beta
          </span>
        }
      >
        <FiltersWrapper>
          <SearchField label="Search" />
          <TextField
            id="standard-select-currency"
            select
            label="Time Period"
            value={timeSpan}
            onChange={e => handleTimeSpan(e.target.value)}
            css={() => css`
              min-width: 150px;
              margin-right: 16px;
            `}
          >
            {['FIFTEEN_MINS', 'HOUR', 'DAY', 'WEEK', 'MONTH'].map(option => (
              <MenuItem key={option} value={option}>
                {getTimeSpan(option).label}
              </MenuItem>
            ))}
          </TextField>
          <Button variant="contained" color="secondary">
            Filter
          </Button>
        </FiltersWrapper>
      </Toolbar>

      <Table className={classes.table} aria-labelledby="Wrappers">
        <TableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          columns={columns}
          rowCount={lodashGet(alerts, 'length', 0)}
        />

        <TableBody>{getTableBody()}</TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              count={lodashGet(alerts, 'length', 0)}
              colSpan={8}
              page={page}
              rowsPerPage={rowsPerPage}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </Paper>
  )
}

export default withStyles(styles)(AlertsTable)
