import * as React from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { useFragment_experimental, gql } from '@apollo/client'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import BasicSnackbar from '@jeeves/pages/WrapperDetail/components/BasicSnackbar'

import { Button, Dialog, DialogActions } from '@jeeves/new-components'

import SelectRepository from './SelectRepository'
import SelectPort from './SelectPort/SelectPort'

import useBindDataRepoDialog from './useBindDataRepoDialog'

const BindDataRepoDialog_queryFragment = gql`
  fragment BindDataRepoDialog_query on Query {
    ...SelectPort_query
    ...SelectRepository_query
    ...useBindDataRepoDialog_query
  }
  ${SelectPort.fragments.SelectPort_queryFragment}
  ${SelectRepository.fragments.SelectRepository_queryFragment}
  ${useBindDataRepoDialog.fragments.useBindDataRepoDialog_queryFragment}
`

const BindDataRepoDialog = ({ open, onClose, query, refreshCurrentTableViewCallbackRef }) => {
  const { data } = useFragment_experimental({
    fragment: BindDataRepoDialog_queryFragment,
    fragmentName: 'BindDataRepoDialog_query',
    from: query,
  })

  const { onSubmit, openSnackbar, handleCloseSnackbar, snackBarMessage, snackBarVariant } =
    useBindDataRepoDialog(data, refreshCurrentTableViewCallbackRef)
  const methods = useForm({
    defaultValues: {
      selectedRepo: '',
    },
  })

  const { watch } = methods
  const { isSubmitting } = methods.formState

  const selectedRepoId = watch('selectedRepo')

  React.useEffect(() => {
    // Reset entire form when the selected repo changes
    methods.reset(
      {
        selectedRepo: selectedRepoId,
      },
      {
        keepDefaultValues: true,
      }
    )
  }, [selectedRepoId, methods])

  React.useEffect(() => {
    if (methods.formState.isSubmitSuccessful) {
      onClose()
    }
  }, [methods.formState, onClose])

  return (
    <Dialog
      onClose={onClose}
      open={open}
      fullWidth
      TransitionProps={{
        onExited: () => methods.reset(),
      }}
    >
      <DialogTitle variant="h3">Bind Data Repo</DialogTitle>
      <FormProvider {...methods}>
        <Box
          component="form"
          onSubmit={e => {
            methods
              .handleSubmit(onSubmit)(e)
              .catch(e => {
                console.error(e)
                const values = methods.getValues()
                methods.reset(values)
              })
          }}
        >
          <DialogContent>
            <Stack spacing={2}>
              <SelectRepository query={data} />
              <SelectPort query={data} />
            </Stack>
          </DialogContent>

          <DialogActions>
            <Button variant="text" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit" loading={isSubmitting}>
              Bind
            </Button>
          </DialogActions>
          <BasicSnackbar
            open={openSnackbar}
            onClose={handleCloseSnackbar}
            severity={snackBarVariant}
            message={snackBarMessage}
          />
        </Box>
      </FormProvider>
    </Dialog>
  )
}

BindDataRepoDialog.fragments = {
  BindDataRepoDialog_queryFragment,
}

export default BindDataRepoDialog
