import { useMutation } from '@apollo/client'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

import { FragmentType, graphql, useFragment } from '@jeeves/graphql'
import { getGraphQLErrorMessage } from '@jeeves/utils/helpers'
import { useToast } from '@jeeves/new-components'

const useGeneral_queryFragment = graphql(`
  fragment useGeneral_query on Query {
    sidecar(id: $sidecarId) {
      id
      name
      userEndpoint
      labels
    }
  }
`)

const UPDATE_SIDECAR = graphql(`
  mutation UpdateSidecar($updateSidecarId: ID!, $input: UpdateSidecarInput!) {
    updateSidecar(id: $updateSidecarId, input: $input) {
      sidecar {
        id
        name
        userEndpoint
        labels
      }
    }
  }
`)

const schema = z.object({
  name: z.string().min(1, { message: 'Please enter a name.' }),
  alias: z.string().optional(),
  tags: z.array(z.string()).optional(),
})

type GeneralFormSchema = z.infer<typeof schema>

interface useGeneralProps {
  query: FragmentType<typeof useGeneral_queryFragment>
}

export const useGeneral = ({ query: queryProp }: useGeneralProps) => {
  const query = useFragment(useGeneral_queryFragment, queryProp)
  const { sidecar } = query

  const { toast } = useToast()

  const [updateSidecar, { loading }] = useMutation(UPDATE_SIDECAR, {
    onError: error => {
      toast({
        variant: 'error',
        description:
          getGraphQLErrorMessage(error) ||
          'An error ocurred while updating this sidecar, please try again.',
      })
    },
    onCompleted: () => {
      reset(getValues())
    },
  })

  const methods = useForm<GeneralFormSchema>({
    defaultValues: {
      name: sidecar.name,
      tags: sidecar.labels,
      alias: sidecar.userEndpoint,
    },
    resolver: zodResolver(schema),
  })

  const { getValues, handleSubmit, reset, setValue, watch } = methods

  const { tags = [] } = watch()

  const onSubmit = async ({ name, tags, alias }: GeneralFormSchema) => {
    await updateSidecar({
      variables: {
        updateSidecarId: sidecar.id,
        input: {
          name,
          tags,
          alias: alias || null,
        },
      },
    })
  }

  const handleUpdateTags = (updatedTags: string[]) => {
    setValue('tags', updatedTags, { shouldDirty: true })
  }

  return {
    methods,
    loading,
    tags,
    handleUpdateTags,
    onSubmit: handleSubmit(onSubmit),
  }
}
