import { Link } from 'react-router-dom'
import { Stack, Typography } from '@mui/material'
import { Description as DescriptionIcon } from '@mui/icons-material'
import { Button } from '@jeeves/new-components'
import { useRepositoryDetailContext } from '@jeeves/pages/RepositoryDetail/contexts/RepositoryDetailContext'

const EmptyState = () => {
  const { repoId } = useRepositoryDetailContext()

  return (
    <Stack spacing={2} sx={{ alignItems: 'center', justifyContent: 'center' }}>
      <DescriptionIcon sx={{ fontSize: 56, color: 'cyralColors.grey.300' }} />
      <Typography variant="h4" sx={{ color: 'cyralColors.grey.500' }}>
        Add a policy to manage data access for this repository.
      </Typography>
      <Button component={Link} to={`/repositories/${repoId}/policies/new`}>
        Add Policy
      </Button>
    </Stack>
  )
}

export default EmptyState
