import React from 'react'

import Collapse from '@mui/material/Collapse'

import Box from '@mui/material/Box'

import { RepoHeader } from './RepoHeader/RepoHeader'
import { MappingHeader } from './MappingTable/MappingHeaders'
import { MappingTable } from './MappingTable/MappingTable'

export const RepoRow = ({ children, props, mapping }) => {
  const [mappingsExpanded, setMappingsExpanded] = React.useState(false)

  return (
    <Box>
      <RepoHeader
        mappingsExpanded={mappingsExpanded}
        setMappingsExpanded={setMappingsExpanded}
        repoType={mapping.node.type}
        repoName={mapping.node.name}
        numMappings={mapping.datamapMappings.length}
      ></RepoHeader>
      <Collapse in={mappingsExpanded} timeout="auto" unmountOnExit>
        <MappingTable
          mappings={mapping.datamapMappings}
          repoType={mapping.node.type}
        ></MappingTable>
      </Collapse>
    </Box>
  )
}
