import React from 'react'
import { Stack, Typography, Divider } from '@mui/material'
import { LearnMore } from './LearnMore'
import { getDocsURL } from '@jeeves/utils/docsURL'

const WhatsNewItem = ({ title, url }) => {
  const unversionedDocsBaseURL = getDocsURL({ useVersionedDocsURL: false })
  const docsPath = url.replace(unversionedDocsBaseURL, '')
  return (
    <Stack spacing={2}>
      <Stack direction="row" sx={{ alignItems: 'center' }}>
        <Stack spacing={2}>
          <Typography variant="body2" sx={{ color: 'text.primary' }}>
            {title}
          </Typography>

          <LearnMore docsPath={docsPath} useVersionedDocsURL={false} />
        </Stack>
      </Stack>
      <Divider />
    </Stack>
  )
}

export const WhatsNewItems = ({ data }) => {
  if (!Array.isArray(data) || data.length < 1) return null
  return (
    <Stack spacing={4}>
      {data.slice(0, 2).map(d => (
        <WhatsNewItem title={d.body} url={d.learnURL} />
      ))}
      {data.length > 2 && (
        <LearnMore
          text="See all Updates"
          docsPath="/release-notes/whats-new"
          useVersionedDocsURL={false}
        />
      )}
    </Stack>
  )
}

export const WhatsNew = ({ queryResponse }) => {
  const { data } = queryResponse

  return (
    <Stack spacing={4}>
      <Stack spacing={2}>
        <Typography variant="h4" sx={{ color: 'text.primary' }}>
          What's New
        </Typography>
        <Divider />
      </Stack>
      <WhatsNewItems data={data} />
    </Stack>
  )
}
