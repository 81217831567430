import React from 'react'

import { InlineCode } from '@jeeves/new-components'
import { getDisplayedPassword } from '../utils'

import { DBeaver } from './components/DBeaver'

const getConnectionTypes = (connectionInfo, options) => {
  const { ports, ...rest } = connectionInfo
  const { showPassword } = options

  const sqlCmdCommandConnectionInfo = {
    ...rest,
    port: ports[0],
  }

  return [
    {
      label: 'sqlcmd',
      value: 'sqlcmd',
      content: (
        <InlineCode sx={{ backgroundColor: 'common.white' }} noWrap>
          {getSqlCmdCommand({
            connectionInfo: sqlCmdCommandConnectionInfo,
            redactPassword: !showPassword,
          })}
        </InlineCode>
      ),
      copyValue: getSqlCmdCommand({
        connectionInfo: sqlCmdCommandConnectionInfo,
      }),
    },
    {
      label: 'DBeaver',
      value: 'dbeaver',
      content: <DBeaver />,
      copyValue: null,
    },
    // {
    //   label: 'SSMS',
    //   value: 'ssms',
    //   content: <SSMS />,
    //   copyValue: null,
    // },
  ]
}

const getSqlCmdCommand = ({ connectionInfo, redactPassword = false }) => {
  const { username, password: connectionInfoPassword, host, port } = connectionInfo

  const password = getDisplayedPassword(connectionInfoPassword, redactPassword)

  return `sqlcmd -S "${host},${port}" -U ${username} -P ${password} -N`
}

export default getConnectionTypes
