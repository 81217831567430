import styled from '@emotion/styled'
import MuiButton from '@material-ui/core/Button'

export const Button = styled(MuiButton)`
  box-shadow: none;
  border-radius: 20px;
  font-size: 12px;
  letter-spacing: 1px;
  padding: 4px 12px;
  font-weight: bold;
  font-family: ${({ theme }) => theme.fontFamily};
`
