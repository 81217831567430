import { ReactNode } from 'react'
import { Typography } from '@mui/material'

const SetupWizardStep5Text = ({ children }: { children: ReactNode }) => (
  <Typography
    sx={{
      color: 'text.primary',
      fontSize: '1.125rem',
      fontWeight: 400,
      lineHeight: '28.8px',
    }}
  >
    {children}
  </Typography>
)

export default SetupWizardStep5Text
