import React from 'react'

import { ScreenshotLayout } from '../../../components'

import PgAdminConnectionScreen from './PgAdmin.png'

const PgAdmin = () => {
  return (
    <ScreenshotLayout
      imgSrc={PgAdminConnectionScreen}
      title="Connecting with PgAdmin"
      steps={[
        'In PgAdmin, connect to a new database',
        'Navigate to the Connection tab',
        'Fill out the highlighted fields with the connection info displayed above.',
      ]}
    />
  )
}

export default PgAdmin
