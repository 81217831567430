import React from 'react'
import { Button } from '@jeeves/new-components'
import { Menu, MenuItem } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

export const Settings = ({ handleOpenEdit, handleOpenDelete }) => {
  const [menuEl, setMenuEl] = React.useState(null)
  const menuOpen = Boolean(menuEl)
  const handleClickMenu = e => {
    setMenuEl(e.currentTarget)
  }
  const handleCloseMenu = e => {
    setMenuEl(null)
  }

  const handleClickEdit = () => {
    handleOpenEdit()
    handleCloseMenu()
  }

  const handleClickDelete = () => {
    handleOpenDelete()
    handleCloseMenu()
  }

  return (
    <React.Fragment>
      <Button
        onClick={handleClickMenu}
        variant="outlined"
        color="secondary"
        endIcon={<KeyboardArrowDownIcon />}
      >
        Settings
      </Button>
      <Menu open={menuOpen} anchorEl={menuEl} onClose={handleCloseMenu}>
        <MenuItem onClick={handleClickEdit}>Edit</MenuItem>
        <MenuItem onClick={handleClickDelete}>Delete</MenuItem>
      </Menu>
    </React.Fragment>
  )
}
