import * as React from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { Button } from '@jeeves/new-components'

import DeleteUserAccountModal from './DeleteUserAccountModal'

import { useUserAuthenticationContext } from '@jeeves/pages/RepositoryDetail/Tabs/UserAuthentication/contexts/UserAuthenticationContext'

const ActionsPopover = ({ disabled=false }) => {
  const { setIsUpdating } = useUserAuthenticationContext()
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOpenDeleteModal = () => {
    handleClose()
    setDeleteModalOpen(true)
  }

  const handleCloseDeleteModal = () => setDeleteModalOpen(false)

  const handleEdit = () => {
    handleClose()
    setIsUpdating(true)
  }

  return (
    <React.Fragment>
      {/* <Button
          variant={open ? 'contained' : 'outlined'}
          onClick={handleToggle}
          type="button"
          ref={anchorRef}
        >
          Advanced
        </Button> */}

      <Button
        //   variant={open ? 'contained' : 'outlined'}
        //     id="basic-button"
        //     aria-controls={open ? 'basic-menu' : undefined}
        variant="outlined"
        color="secondary"
        onClick={handleClick}
        endIcon={<ExpandMoreIcon />}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        disabled={disabled}
      >
        Actions
      </Button>

      <Menu
        // id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        // MenuListProps={{
        //   'aria-labelledby': 'basic-button',
        // }}
      >
        <MenuItem onClick={handleEdit}>Edit</MenuItem>
        <MenuItem onClick={handleOpenDeleteModal}>Delete</MenuItem>
      </Menu>

      <DeleteUserAccountModal open={deleteModalOpen} closeModal={handleCloseDeleteModal} />
    </React.Fragment>
  )
}

export default ActionsPopover
