/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import styled from '@emotion/styled'
import { useState, Fragment } from 'react'
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableRow,
  withStyles,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from '@material-ui/core'
import { Button, Dialog } from '@jeeves/components/Primitives'

// TO DO: componentize error theme
const styles = theme => ({
  deleteButton: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
    // border: `1px solid ${theme.palette.error.main}`,
  },
  label: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: 'rgba(0, 0, 0, 0.4)',
  },
})

// TO DO: componentize this

const Pair = withStyles(styles)(({ label, value, valueError, classes }) => {
  return (
    <div css={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
      <Typography className={classes.label}>{label}</Typography>
      <Typography
        css={t => ({ color: valueError ? t.palette.error.main : 'black' })}
        className={classes.value}
      >
        {value}
      </Typography>
    </div>
  )
})

// TO DO: componentize this
export const DeleteDialog = withStyles(styles)(
  ({
    open,
    handleClose,
    identityName,
    repoAccount,
    identityType,
    repoAccountUUID,
    handleDelete,
    classes,
    policyCategory,
    policyID,
  }) => {
    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography css={{ marginBottom: '12px' }}>
            Are you sure you want to delete this mapping?
          </Typography>
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '12px calc(12px + .5rem)',
              borderRadius: '5px',
              backgroundColor: 'rgba(0, 0, 0, 0.05)',
            }}
          >
            <div css={{ padding: '0 12px 0 0' }}>
              <Pair label="Identity" value={identityName}></Pair>
            </div>
            <div css={{ padding: '0 12px' }}>
              <Typography>{'\u2192'}</Typography>
            </div>
            <div css={{ padding: '0 0 0 12px' }}>
              <Pair label="Local Account" value={repoAccount}></Pair>
            </div>
          </div>
        </DialogContent>
        <DialogActions
          css={() => css`
            display: flex;
            padding: 24px;
            justify-content: flex-end;
          `}
        >
          <Button variant="outlined" color="primary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            className={classes.deleteButton}
            onClick={() => {
              handleDelete(identityType, identityName, repoAccountUUID, policyCategory, policyID)
              handleClose()
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
)
