import { get } from 'lodash'
import { valid as semverValid } from 'semver'

export const now = () => new Date().getTime()

const CACHE_DURATION = 5 * 60 * 1000

export const LocalStorage = {
  get: key => {
    const item = JSON.parse(window.localStorage.getItem(key))

    if (item && item.value && item.expiration) {
      const { value, expiration } = item

      if (now() <= expiration) {
        return value
      }
    }

    return null
  },

  set: (key, value = {}) => {
    const expiration = now() + CACHE_DURATION
    const payload = { value, expiration }

    window.localStorage.setItem(key, JSON.stringify(payload))
  },

  del: key => window.localStorage.removeItem(key),
}

function stableSort(array, cmp) {
  if (!array || !array.map) {
    return []
  }

  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

function getSorting(order, orderBy, customComparators = {}) {
  if (customComparators[orderBy]) {
    return customComparators[orderBy](order)
  }
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy)
}

function getValue(element, orderBy) {
  const value = get(element, orderBy)

  if (typeof value === 'string') {
    return value.toLowerCase()
  }

  if (Array.isArray(value)) {
    return value.sort().join(' ')
  }

  return value
}

function desc(a, b, orderBy) {
  if (!orderBy) {
    console.error('utils/index.desc.error: Received invalid orderBy:', JSON.stringify(orderBy))
    return 0
  }

  const v1 = getValue(a, orderBy)
  const v2 = getValue(b, orderBy)

  if (v1 === undefined || v2 === undefined) {
    return 0
  }
  if (v2 < v1) {
    return -1
  }
  if (v2 > v1) {
    return 1
  }
  return 0
}

function getDate(string, format) {
  const dateSeparator = /-|\//
  const arrayOfStrings = string.split(dateSeparator)
  const arrayOfFormats = format.split(dateSeparator)

  const dateDictionary = {
    DD: '',
    MM: '',
    YYYY: '',
  }

  arrayOfFormats.map((format, i) => (dateDictionary[format] = parseInt(arrayOfStrings[i])))

  return new Date(dateDictionary.YYYY, dateDictionary.MM - 1, dateDictionary.DD)
}

/**
 * @function getDaysBetweenDates
 * @param  {date} dateOne              {}
 * @param  {date} dateTwo              {}
 * @param  {boolean} notIncludeTheEndDate {Will not include the end date if is true}
 * @return {number} {return days between this dates}
 */
function getDaysBetweenDates(dateOne, dateTwo, notIncludeTheEndDate) {
  const timeDiff = Math.abs(dateOne.getTime() - dateTwo.getTime())
  const totalDays = Math.ceil(timeDiff / (1000 * 3600 * 24))
  return notIncludeTheEndDate ? totalDays - 1 : totalDays
}

function timestampToStringDate(timestamp) {
  const date = new Date(timestamp)
  const locale = 'en-US'
  return date.toLocaleString(locale, {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  })
}

// When an object is being fetched from an API, we set it to 'undefined' while it is loading
function isLoading(object) {
  return object === undefined
}

// When an object fails to be fetched from an API, we set it to 'null' so that we know there was an error
function isError(object) {
  return object === null
}

function hasSsoEnabled(repository) {
  const identityProvider = get(repository, 'auth.identityProvider', null)

  return identityProvider !== null && identityProvider !== '' && identityProvider !== 'native'
}

function isEphemeral(repository) {
  const { requireExplicitGrant: requireGrant = null } = repository.auth

  return requireGrant && true
}

function delay(duration) {
  return new Promise(resolve => setTimeout(resolve, duration))
}

// Validates both semantic versions with a leading "v" as well as without.
// e.g. "v2.19.2" and "2.19.2"
const isValidSemver = semanticVersion => {
  return Boolean(semverValid(semanticVersion))
}

export {
  hasSsoEnabled,
  isEphemeral,
  delay,
  stableSort,
  getSorting,
  desc,
  getDate,
  getDaysBetweenDates,
  timestampToStringDate,
  isLoading,
  isError,
  isValidSemver,
}
