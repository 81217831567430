import { BaseWizard } from '../common'

import { StepOne, StepThree, StepReview } from '../common/steps'
import { StepTwo } from './steps'

import useRateLimit from './useRateLimit'

const RateLimit = ({ policy = {}, template }) => {
  const {
    activeStep,
    snackBarOpen,
    loading,
    errorMessage,
    setRateLimitInfo,
    rateLimitInfo,
    prevStep,
    nextStep,
    handlePolicySubmit,
    handleCloseSnackbar,
  } = useRateLimit({ policy, template })

  const steps = [
    {
      title: 'Describe the Rate Limit policy',
      component: (
        <StepOne nextStep={nextStep} setPolicyInfo={setRateLimitInfo} policyInfo={rateLimitInfo} />
      ),
    },
    {
      title: 'Specify policy parameters',
      component: (
        <StepTwo
          rateLimitInfo={rateLimitInfo}
          prevStep={prevStep}
          nextStep={nextStep}
          setRateLimitInfo={setRateLimitInfo}
        />
      ),
    },
    {
      title: 'Specify who this policy applies to',
      component: (
        <StepThree
          policyInfo={rateLimitInfo}
          prevStep={prevStep}
          nextStep={nextStep}
          setPolicyInfo={setRateLimitInfo}
        />
      ),
    },
    {
      title: 'Review the policy',
      component: (
        <StepReview
          policyInfo={rateLimitInfo}
          prevStep={prevStep}
          handlePolicySubmit={handlePolicySubmit}
          isSubmitting={loading}
        />
      ),
    },
  ]

  return (
    <BaseWizard
      steps={steps}
      activeStep={activeStep}
      snackBarOpen={snackBarOpen}
      handleCloseSnackbar={handleCloseSnackbar}
      errorMessage={errorMessage}
    />
  )
}

export default RateLimit
