import React from 'react'
import { Tooltip, FormControlLabel, Checkbox } from '@material-ui/core/'

export const CheckBoxWithToolTip = ({ checked, onChange, value, label, helpText = '' }) => (
  <div>
    <Tooltip title={helpText} placement="left">
      <FormControlLabel
        control={<Checkbox checked={checked} onChange={onChange} value={value} color="primary" />}
        label={label}
      />
    </Tooltip>
  </div>
)
