import { useFormContext } from 'react-hook-form'
import { Controller } from 'react-hook-form'

import { FormControl, RadioGroup } from '@mui/material'

import { InputLabel } from '@jeeves/new-components'
import MongoDBStandaloneOption from './MongoDBStandaloneOption'
import MongoDBReplicaSetOption from './MongoDBReplicaSetOption'
import MongoDBShardedClusterOption from './MongoDBShardedClusterOption'

const AddRepoMongoDBFields = () => {
  const { control } = useFormContext()

  return (
    <FormControl variant="standard">
      <InputLabel htmlFor="clusterType">Cluster type</InputLabel>
      <Controller
        name="clusterType"
        control={control}
        defaultValue="standalone"
        render={({ field }) => (
          <RadioGroup aria-label="clusterType" {...field}>
            <MongoDBStandaloneOption />
            <MongoDBReplicaSetOption />
            <MongoDBShardedClusterOption />
          </RadioGroup>
        )}
      />
    </FormControl>
  )
}

export default AddRepoMongoDBFields
