import React, { useState } from 'react'

const WrappersContext = React.createContext([{}, () => {}])

const WrappersProvider = props => {
  const [state, setState] = useState({
    wrappers: undefined,
    crudCount: 0,
    page: 0,
    rowsPerPage: 25,
    order: 'asc',
    orderBy: 'sidecar.name',
    expanded: [],
    renameError: '',
  })

  return (
    <WrappersContext.Provider value={[state, setState]}>{props.children}</WrappersContext.Provider>
  )
}

export { WrappersContext, WrappersProvider }
