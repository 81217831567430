import { Stack, FormControl, FormHelperText } from '@mui/material'
import { Input, InputLabel } from '@jeeves/new-components'
import { useFormContext } from 'react-hook-form'
import { ConnectionFormatSelect, SRVRecordFields, StaticNodesFields } from './index'

const getConnectionFormatSpecificFields = connectionFormat => {
  switch (connectionFormat) {
    case 'StaticNodeSpecification':
      return <StaticNodesFields />
    case 'SRVRecordSpecification':
      return <SRVRecordFields />
    default:
      return null
  }
}

const ReplicaSetFields = () => {
  const { register, watch, formState } = useFormContext()

  const connectionFormat = watch('connectionFormat') || 'StaticNodeSpecification'
  const replicaSetNameError = formState.errors?.replicaSetName

  return (
    <Stack spacing={2}>
      <FormControl variant="standard" error={Boolean(replicaSetNameError)}>
        <InputLabel htmlFor="replicaSetName" required>
          Replica set name
        </InputLabel>
        <Input id="replicaSetName" inputProps={{ ...register('replicaSetName') }} />
        <FormHelperText
          sx={{
            typography: 'body2',
          }}
          error={Boolean(replicaSetNameError)}
        >
          {replicaSetNameError?.message}
        </FormHelperText>
      </FormControl>

      <ConnectionFormatSelect />

      {getConnectionFormatSpecificFields(connectionFormat)}
    </Stack>
  )
}

export default ReplicaSetFields
