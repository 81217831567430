import { useMutation, gql } from '@apollo/client'

const CREATE_SCIM_INTEGRATION = gql`
  mutation CreateSCIMIntegration($scimIntegration: CreateSCIMIntegrationInput!) {
    createSCIMIntegration(scimIntegration: $scimIntegration) {
      IDPID
      scimBaseURL
      clientId
      enabled
      authMode
      authSecret
      authSecretExpiry
    }
  }
`

const UPDATE_SCIM_INTEGRATION_STATUS = gql`
  mutation UpdateSCIMIntegrationStatus($IDPID: String!, $enabled: Boolean!) {
    updateSCIMIntegrationStatus(IDPID: $IDPID, enabled: $enabled) {
      message
    }
  }
`

const GENERATE_SCIM_INTEGRATION_CREDS = gql`
  mutation GenerateSCIMIntegrationCreds_SAML($IDPID: String!) {
    generateSCIMIntegrationCreds(IDPID: $IDPID) {
      authSecret
      authSecretExpiry
    }
  }
`

//=============================== GENERIC SAML ====================================================//
const CREATE_GENERIC_SAML_INTEGRATION_DRAFT = gql`
  mutation CreateGenericSAMLIntegrationDraft(
    $genericSamlIntegrationDraft: CreateGenericSAMLIntegrationDraftInput!
  ) {
    createGenericSAMLIntegrationDraft(genericSAMLIntegrationDraft: $genericSamlIntegrationDraft) {
      genericSAMLIntegrationDraft {
        spMetadataXMLDocument
        spMetadataURL
        id
      }
    }
  }
`

const useSAML = () => {
  const [
    createGenericSAMLIntegrationDraft,
    {
      data: createGenericSAMLIntegrationDraftData,
      loading: createGenericSAMLIntegrationDraftLoading,
      error: createGenericSAMLIntegrationDraftError,
    },
  ] = useMutation(CREATE_GENERIC_SAML_INTEGRATION_DRAFT)

  const [
    createSCIMIntegration,
    {
      data: createSCIMIntegrationData,
      loading: createSCIMIntegrationLoading,
      error: createSCIMIntegrationError,
    },
  ] = useMutation(CREATE_SCIM_INTEGRATION)

  const [
    updateSCIMIntegrationStatus,
    {
      data: updateSCIMIntegrationStatusData,
      loading: updateSCIMIntegrationStatusLoading,
      error: updateSCIMIntegrationStatusError,
    },
  ] = useMutation(UPDATE_SCIM_INTEGRATION_STATUS)

  const [
    generateSCIMIntegrationCreds,
    {
      data: generateSCIMIntegrationCredsData,
      loading: generateSCIMIntegrationCredsLoading,
      error: generateSCIMIntegrationCredsError,
    },
  ] = useMutation(GENERATE_SCIM_INTEGRATION_CREDS)

  return {
    createSCIMIntegrationData,
    createSCIMIntegration,
    updateSCIMIntegrationStatus,
    generateSCIMIntegrationCreds,
  }
}

export default useSAML
