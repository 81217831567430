/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Component } from 'react'

import TableCell from '@material-ui/core/TableCell'
import MuiTableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'

class TableHead extends Component {
  createSortHandler = property => event => {
    if (this.props.onRequestSort) {
      this.props.onRequestSort(event, property)
    }
  }

  render() {
    const { columns, order, orderBy } = this.props

    return (
      <MuiTableHead>
        <TableRow>
          {columns
            .filter(column => column !== null)
            .map(column => (
              <TableCell
                key={column.id}
                align={column.numeric ? 'right' : 'left'}
                padding={column.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === column.id ? order : false}
                css={column.css}
                style={column.styles}
              >
                {Boolean(this.props.onRequestSort) && !column.disableSort ? (
                  <Tooltip
                    title={!!this.props.onRequestSort ? 'Sort' : ''}
                    placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                    enterDelay={300}
                  >
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={order}
                      onClick={this.createSortHandler(column.id)}
                      css={() => css`
                        text-transform: uppercase;
                        font-weight: 600;
                        letter-spacing: 0.4px;
                        font-size: 12px;
                        line-height: 16px;
                        color: #393d74;
                      `}
                    >
                      {column.label}
                    </TableSortLabel>
                  </Tooltip>
                ) : (
                  <Typography
                    css={() => css`
                      text-transform: uppercase;
                      font-weight: 600;
                      letter-spacing: 0.4px;
                      font-size: 12px;
                      line-height: 16px;
                      color: #393d74;
                    `}
                  >
                    {column.label}
                  </Typography>
                )}
              </TableCell>
            ))}
        </TableRow>
      </MuiTableHead>
    )
  }
}

export { TableHead }
