import * as React from 'react'
import { isAfter, parseISO } from 'date-fns'
import { useMutation, useQuery, gql } from '@apollo/client'
import { Controller, useForm, useFormState, useWatch, FormProvider } from 'react-hook-form'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'

import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import {
  Button,
  DatePicker,
  Dialog,
  DialogActions,
  Input,
  FormLabel,
  InputGroup,
  Select,
} from '@jeeves/new-components'

import AccessRuleModalContent from './AccessRuleModalContent'

import { useRepositoryDetailContext } from '@jeeves/pages/RepositoryDetail/contexts/RepositoryDetailContext'
import { ACCESS_RULE_FIELDS } from '@jeeves/pages/RepositoryDetail/Tabs/UserAuthentication/graphql/fragments'

const CREATE_ACCESS_RULE = gql`
  mutation CreateAccessRule($repoId: ID!, $userAccountId: ID!, $accessRule: CreateAccessRuleInput!) {
    createAccessRule(repoId: $repoId, userAccountId: $userAccountId, accessRule: $accessRule) {
      userAccount {
        id
        accessRules {
          ...accessRuleFields
        }
      }
    }
  }
  ${ACCESS_RULE_FIELDS}
`

const AddAccessRuleModal = ({ open, onClose, userAccountId }) => {
  const { repoId } = useRepositoryDetailContext()
  const [createAccessRule, { data, loading, error }] = useMutation(CREATE_ACCESS_RULE)

  const methods = useForm({
    defaultValues: {
      identity: {
        type: 'group',
        name: '',
      },
      accessDurationType: 'unlimited',
      validFrom: new Date().toISOString(),
      pagerdutyIntegrations: [],
      duoIntegrations: [],
    },
  })

  const { isSubmitting } = methods.formState

  const handleClose = React.useCallback(() => {
    methods.reset()
    onClose()
  }, [methods, onClose])

  React.useEffect(() => {
    if (methods.formState.isSubmitSuccessful) {
      handleClose()
    }
  }, [methods.formState, handleClose])

  const onSubmit = async data => {
    console.log('onSubmit: ', data)

    try {
      await createAccessRule({
        variables: {
          repoId,
          userAccountId,
          accessRule: {
            duoIntegrationIds: data.duoIntegrations,
            identity: {
              name: data.identity.name,
              type: data.identity.type === 'user' ? data.identity.userField : data.identity.type,
            },
            pagerdutyIntegrationIds: data.pagerdutyIntegrations,
            validFrom: data.validFrom,
            validUntil: data?.validUntil,
          },
        },
      })
    } catch (error) {}
  }

  return (
    <Dialog onClose={handleClose} open={open} fullWidth>
      <DialogTitle variant="h3">Add Access Rule</DialogTitle>
      <FormProvider {...methods}>
        <Box component="form" onSubmit={methods.handleSubmit(onSubmit)}>
          <AccessRuleModalContent />

          <DialogActions>
            <Button variant="text" onClick={handleClose} autoFocus>
              Cancel
            </Button>
            <Button type="submit" loading={isSubmitting}>
              Add Rule
            </Button>
          </DialogActions>
        </Box>
      </FormProvider>
    </Dialog>
  )
}

export default AddAccessRuleModal
