import {
  EnhancedAuditLogActionCategory as Category,
  EnhancedAuditLogActionType as Type,
} from '@jeeves/graphql/graphql'

export const categoryTitleMap: { [key in Category]: string } = {
  [Category.AccessRules]: 'Access rule',
  [Category.Approval]: 'Approval',
  [Category.Datamap]: 'Data label mapping',
  [Category.NetworkAccess]: 'Network Shield rule',
  [Category.RegoPolicyInstance]: 'Policy',
}

export const typeTitleMap: { [key in Type]: string } = {
  [Type.Create]: 'created',
  [Type.Update]: 'updated',
  [Type.UpdatePriority]: 'priority updated',
  [Type.Delete]: 'deleted',
  [Type.Request]: 'requested',
  [Type.Grant]: 'granted',
  [Type.Reject]: 'rejected',
  [Type.Revoke]: 'revoked',
  [Type.Enable]: 'enabled',
  [Type.Disable]: 'disabled',
}
