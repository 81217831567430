import { MutationHookOptions, useMutation } from '@apollo/client'
import { graphql } from '@jeeves/graphql'
import {
  UpdateLoggingIntegrationMutation,
  UpdateLoggingIntegrationMutationVariables,
} from '@jeeves/graphql/graphql'
import { useToast } from '@jeeves/new-components'

const UPDATE_LOGGING_INTEGRATION = graphql(`
  mutation UpdateLoggingIntegration(
    $updateLoggingIntegrationId: ID!
    $input: UpdateLoggingIntegrationInput!
  ) {
    updateLoggingIntegration(id: $updateLoggingIntegrationId, input: $input) {
      loggingIntegration {
        id
        name
        ...useCloudwatch_cloudWatchIntegration
        ...useCustom_customLoggingIntegration
        ...useDatadog_datadogIntegration
        ...useELK_ELKIntegration
        ...useSplunk_splunkIntegration
        ...useSumologic_sumoLogicIntegration
      }
    }
  }
`)

type UpdateLoggingIntegrationOptions = MutationHookOptions<
  UpdateLoggingIntegrationMutation,
  UpdateLoggingIntegrationMutationVariables
>

type OnCompleted = UpdateLoggingIntegrationOptions['onCompleted']
type OnError = UpdateLoggingIntegrationOptions['onError']

const useUpdateLoggingIntegration = ({
  onCompleted,
  onError,
}: {
  onCompleted?: OnCompleted
  onError?: OnError
}) => {
  const { toast } = useToast()

  return useMutation(UPDATE_LOGGING_INTEGRATION, {
    onError:
      onError ||
      (_ => {
        toast({
          variant: 'error',
          description:
            'An error occurred while updating this logging integration, please try again.',
        })
      }),
    onCompleted,
  })
}

export default useUpdateLoggingIntegration
