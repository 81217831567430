/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { jsx, css } from '@emotion/react'

import { useAuth } from '@jeeves/components/Auth'

import Existing from './Existing'
import IntegrationForm from './IntegrationForm'
import useHashiCorpVault from './hooks/useHashiCorpVault'
import logo from './images/hashiCorpVault.png'
import {
  ErrorState,
  ZeroState,
  AddIntegrationButtonUnformatted,
  AddIntegrationButton,
  IntegrationDialogContent,
  IntegrationLoading,
  IntegrationList,
} from '../../primitives'

const HashiCorpVault = () => {
  const {
    updateIntegrations,
    integrations,
    addIntegration,
    putIntegration,
    deleteIntegration,
  } = useHashiCorpVault()
  const { hasPermission } = useAuth()
  const [adding, setAdding] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)

      try {
        await updateIntegrations()
      } catch (e) {
        setError(true)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, []) // eslint-disable-line

  const createIntegration = async payload => {
    await addIntegration(payload)
    setAdding(false)
  }

  return (
    <>
      <IntegrationDialogContent>
        <IntegrationList>
          {loading && <IntegrationLoading />}
          {!loading && error && <ErrorState />}
          {!loading && !error && integrations.length === 0 && <ZeroState />}
          {!loading &&
            !error &&
            integrations.length > 0 &&
            integrations.map(integration => (
              <Existing
                integration={integration}
                putIntegration={putIntegration}
                deleteIntegration={deleteIntegration}
                key={integration.id}
              />
            ))}
          {adding && (
            <IntegrationForm
              integration={null}
              creating={true}
              onSave={createIntegration}
              onCancel={() => setAdding(false)}
            />
          )}
        </IntegrationList>
        <div
          css={() => css`
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
          `}
        >
          <AddIntegrationButtonUnformatted
            isOpen={adding}
            setOpen={setAdding}
            disabled={!hasPermission('integrations:create') || adding}
          />
        </div>
      </IntegrationDialogContent>
    </>
  )
}

HashiCorpVault.metadata = {
  name: 'HashiCorp Vault',
  summary: 'Set up Cyral to use secrets stored in HashiCorp Vault.',
  enabled: true,
  category: 'Secrets',
  slug: 'hashiCorpVault',
  logo,
}

export default HashiCorpVault
