import * as React from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import moment from 'moment'
import moment_timezone from 'moment-timezone'

const InProgress = () => (
  <Stack direction="row" spacing={1} sx={{ color: 'cyralColors.grey.400' }}>
    <AutorenewIcon />
    <Typography variant="h4">In Progress</Typography>
  </Stack>
)

const Successful = () => (
  <Stack direction="row" spacing={1} sx={{ color: 'cyralColors.green.400' }}>
    <CheckCircleOutlineIcon />
    <Typography variant="h4">Successful</Typography>
  </Stack>
)

const Failed = () => (
  <Stack direction="row" spacing={1} sx={{ color: 'cyralColors.red.400' }}>
    <WarningAmberIcon />
    <Typography variant="h4">Failed</Typography>
  </Stack>
)

const renderStatus = status => {
  switch (status) {
    case 'STARTED':
      return <InProgress></InProgress>
    case 'COMPLETED':
      return <Successful></Successful>
    case 'FAILED':
      return <Failed></Failed>
    default:
      return null
  }
}

export const RecentRunCard = ({ run }) => {
  return (
    <Box sx={{ mb: 2 }}>
      <Stack
        direction="row"
        spacing={4}
        sx={{
          backgroundColor: 'cyralColors.grey.100',
          border: '1px solid',
          borderColor: 'cyralColors.grey.100',
          py: 2.5,
          px: 4,
        }}
      >
        <Typography variant="h6" sx={{ color: 'cyralColors.grey.400' }}>
          Initiated on {moment(run.createdAt).format('MMM DD, YYYY h:mm:ss a')}{' '}
          {moment_timezone().tz(moment.tz.guess()).format('z')}
        </Typography>
        <Typography variant="h6" sx={{ color: 'cyralColors.grey.400' }}>
          Completed on {moment(run.updatedAt).format('MMM DD, YYYY h:mm:ss a')}{' '}
          {moment_timezone().tz(moment.tz.guess()).format('z')}
        </Typography>
      </Stack>
      <Stack
        direction="row"
        sx={{ border: '1px solid', borderColor: 'cyralColors.grey.100', py: 2.5, px: 4 }}
      >
        {renderStatus(run.state)}
        {run?.data && Object.keys(run.data).length > 0 && (
          <Stack direction="row" spacing={1} sx={{ ml: 9, alignItems: 'center' }}>
            <Typography sx={{ color: 'cyralColors.grey.500' }}>Classified Fields:</Typography>
            <Box sx={{ backgroundColor: 'cyralColors.primary.100', px: 0.25, pb: 0.25 }}>
              <Typography variant="tag" sx={{ color: 'cyralColors.primary.500' }}>
                {run.data.numClassifications}
              </Typography>
            </Box>
          </Stack>
        )}
      </Stack>
    </Box>
  )
}
