import { useState } from 'react'

import { Box, Typography } from '@mui/material'

import { useRepositoryDetailContext } from '@jeeves/pages/RepositoryDetail/contexts/RepositoryDetailContext'

import SelectPolicyType from './components/SelectPolicyType'

import DataFirewall from './components/wizards/DataFirewall'
import DataMasking from './components/wizards/DataMasking'
import DataProtection from './components/wizards/DataProtection'
import RateLimit from './components/wizards/RateLimit'
import ReadLimit from './components/wizards/ReadLimit/ReadLimit'
import RepositoryProtection from './components/wizards/RepositoryProtection'
import ServiceAccountAbuse from './components/wizards/ServiceAccountAbuse'
import UserSegmentation from './components/wizards/UserSegmentation'

const EmptyWizard = () => {
  return (
    <Box>
      <Typography variant="h3" sx={{ color: 'text.primary' }}>
        Under Construction 🛠 🏗
      </Typography>
      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        The Policy Wizard is still being developed for this template. Thanks for being patient! 😄
      </Typography>
    </Box>
  )
}

const AddPolicy = () => {
  const { policyTemplates } = useRepositoryDetailContext()

  const [policyTemplateTypeId, setPolicyTemplateTypeId] = useState()

  const template = policyTemplates.find(template => template.id === policyTemplateTypeId) ?? {}

  const wizards = {
    dataMasking: <DataMasking template={template} />,
    dataProtection: <DataProtection template={template} />,
    dataFirewall: <DataFirewall template={template} />,
    rateLimit: <RateLimit template={template} />,
    readLimit: <ReadLimit template={template} />,
    repositoryProtection: <RepositoryProtection template={template} />,
    serviceAccountAbuse: <ServiceAccountAbuse template={template} />,
    userSegmentation: <UserSegmentation template={template} />,
  }

  const implementedTemplates = policyTemplates.filter(template =>
    Object.keys(wizards).includes(template.id)
  )

  return (
    <Box sx={{ mx: 8, my: 4 }}>
      {policyTemplateTypeId ? (
        wizards[policyTemplateTypeId] || <EmptyWizard />
      ) : (
        <SelectPolicyType
          setPolicyTemplateTypeId={setPolicyTemplateTypeId}
          implementedTemplates={implementedTemplates}
        />
      )}
    </Box>
  )
}

export default AddPolicy
