import * as React from 'react'
import { useMutation, gql } from '@apollo/client'
import { useForm, FormProvider } from 'react-hook-form'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'

import { Button, Dialog, DialogActions } from '@jeeves/new-components'

import { useRepositoryDetailContext } from '@jeeves/pages/RepositoryDetail/contexts/RepositoryDetailContext'
import { ACCESS_RULE_FIELDS } from '@jeeves/pages/RepositoryDetail/Tabs/UserAuthentication/graphql/fragments'

import AccessRuleModalContent from '../AccessRuleModalContent'

const UPDATE_ACCESS_RULE = gql`
  mutation UpdateAccessRule(
    $repoId: ID!
    $userAccountId: ID!
    $accessRuleId: ID!
    $accessRule: UpdateAccessRuleInput!
  ) {
    updateAccessRule(
      repoId: $repoId
      userAccountId: $userAccountId
      accessRuleId: $accessRuleId
      accessRule: $accessRule
    ) {
      accessRule {
        ...accessRuleFields
      }
    }
  }
  ${ACCESS_RULE_FIELDS}
`

const accessRuleFormTransformer = rule => {
  const identityType = rule.identity.type
  const name = rule.identity.name

  return {
    identity: {
      type: identityType !== 'group' ? 'user' : 'group',
      name,
      ...(identityType !== 'group' ? { userField: identityType } : {}),
    },
    accessDurationType: rule?.validUntil ? 'limited' : 'unlimited',
    validFrom: rule.validFrom,
    ...(rule?.validUntil ? { validUntil: rule.validUntil } : {}),
    pagerdutyIntegrations:
      rule?.accessRestrictions?.reduce((prev, restriction) => {
        return restriction.__typename === 'PagerdutyIntegration' ? [...prev, restriction.id] : prev
      }, []) ?? [],
    duoIntegrations:
      rule?.accessRestrictions?.reduce((prev, restriction) => {
        return restriction.__typename === 'DuoIntegration' ? [...prev, restriction.id] : prev
      }, []) ?? [],
  }
}

const EditAccessRuleModal = ({ open, onClose, accessRule, userAccountId }) => {
  const { repoId } = useRepositoryDetailContext()
  const [updateAccessRule, { data, loading, error, reset: resetMutation }] = useMutation(
    UPDATE_ACCESS_RULE,
    {
      variables: {
        repoId,
        userAccountId,
        accessRuleId: accessRule.id,
      },
    }
  )

  const methods = useForm({
    defaultValues: accessRuleFormTransformer(accessRule),
  })

  const { isSubmitting } = methods.formState
  const { reset, getValues } = methods

  React.useEffect(() => {
    if (methods.formState.isSubmitSuccessful) {
      const values = getValues()
      reset(values)
      //     resetMutation()
      onClose()
    }
  }, [methods.formState, onClose, reset, getValues])

  const onSubmit = async data => {
    try {
      await updateAccessRule({
        variables: {
          accessRule: {
            duoIntegrationIds: data.duoIntegrations,
            identity: {
              name: data.identity.name,
              type: data.identity.type === 'user' ? data.identity.userField : data.identity.type,
            },
            pagerdutyIntegrationIds: data.pagerdutyIntegrations,
            validFrom: data.validFrom,
            validUntil: data?.validUntil ?? null,
          },
        },
      })
    } catch (e) {}
  }

  const handleClose = React.useCallback(() => {
    reset()
    onClose()
  }, [reset, onClose])

  return (
    <Dialog onClose={handleClose} open={open} fullWidth>
      <DialogTitle variant="h3">Edit Access Rule</DialogTitle>
      <FormProvider {...methods}>
        <Box component="form" onSubmit={methods.handleSubmit(onSubmit)}>
          <AccessRuleModalContent />

          <DialogActions>
            <Button variant="text" onClick={handleClose} autoFocus>
              Cancel
            </Button>
            <Button type="submit" loading={isSubmitting}>
              Save
            </Button>
          </DialogActions>
        </Box>
      </FormProvider>
    </Dialog>
  )
}

export default EditAccessRuleModal
