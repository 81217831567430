import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import classNames from 'classnames'

import { get as lodashGet } from 'lodash'

const styles = theme => ({
  alternateRow: {
    backgroundColor: '#FAFAFA',
  },
  cell: {
    padding: theme.spacing.unit * 2,
    width: '100%',
  },
  notification: {
    display: 'inline-flex',
    alignItems: 'center',
    color: theme.palette.warning.main,
  },
  icon: {
    marginRight: theme.spacing.unit,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  details: {
    marginLeft: theme.spacing.unit * 2,
  },
  actions: {
    marginLeft: theme.spacing.unit * 5,
  },
  buttonSpacing: {
    marginLeft: theme.spacing.unit * 2,
  },
  incidentButton: {
    marginRight: theme.spacing.unit * 2,
  },
})

const ExpandedRow = ({
  classes,
  colSpan,
  expanded,
  row,
  tableConfig,
  colorIndex,
  location,
  formatDate,
}) => {
  if (!expanded) return null

  const markAsReadButton = flag => {
    if (flag) {
      return (
        <Button
          variant="outlined"
          color="primary"
          size="small"
          // onClick={() => setDialog('builder')}
        >
          Mark as Read
        </Button>
      )
    } else {
      return null
    }
  }

  const incidentURL = lodashGet(row, 'thehive.caseurl', null)

  return (
    <TableRow className={classNames({})}>
      <TableCell colSpan={100} className={classes.cell}>
        <Typography variant="h6" id="details" className={classes.details}>
          Details
        </Typography>
        <ul>
          {row.details.map((detail, index) => {
            return <li key={index}>{`[${formatDate(detail.time)}] ${detail.command}`}</li>
          })}
        </ul>
        <div className={classes.actions}>
          {incidentURL && (
            <Button
              className={classes.incidentButton}
              variant="outlined"
              color="primary"
              size="small"
              onClick={() => window.open(incidentURL, '_blank')}
            >
              View incident
            </Button>
          )}

          {markAsReadButton(true)}
          <Button
            className={classes.buttonSpacing}
            variant="outlined"
            color="primary"
            size="small"
            // onClick={() => setDialog('builder')}
          >
            Archive
          </Button>
        </div>
      </TableCell>
    </TableRow>
  )
}

export default withStyles(styles)(ExpandedRow)
