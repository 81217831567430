import { Stack, Typography } from '@mui/material'
import { ReplicaSetFields } from './components'

const EditMongoDBReplicaSetRepoFields = () => {
  return (
    <Stack spacing={2}>
      <Typography variant="h4" sx={{ color: 'text.primary' }}>
        Replica Set Settings
      </Typography>

      <ReplicaSetFields />
    </Stack>
  )
}

export default EditMongoDBReplicaSetRepoFields
