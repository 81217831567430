/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'

import { withStyles } from '@material-ui/core'
import MuiTablePagination from '@material-ui/core/TablePagination'

const styles = () => ({
  select: {
    minWidth: '30px',
  },
})

const TablePaginationInner = ({ classes, ...rest }) => (
  <MuiTablePagination
    {...rest}
    rowsPerPageOptions={[5, 10, 25, 50, 100]}
    backIconButtonProps={{
      'aria-label': 'Previous Page',
    }}
    nextIconButtonProps={{
      'aria-label': 'Next Page',
    }}
    classes={{
      select: classes.select,
    }}
  />
)

export const TablePagination = withStyles(styles)(TablePaginationInner)
