/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { TableRow, TableCell } from '@material-ui/core'

export const RowSpan = ({ colSpan = 99, children, last }) => (
  <TableRow>
    <TableCell
      colSpan={colSpan}
      css={css`
        position: relative;
        height: 90px;
        text-align: center;
        ${last ? 'border-bottom: none;' : ''}
      `}
    >
      {children}
    </TableCell>
  </TableRow>
)

export default RowSpan
