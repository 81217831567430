import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

const InlineCode = styled(props => <Typography {...props} component="code" variant="code" />)(
  ({ theme }) => ({
    color: theme.palette.text.primary,
    display: 'inline',
    padding: theme.spacing(0.25, 0.75),
    borderRadius: theme.radii.base,
    backgroundColor: theme.palette.cyralColors.grey[100],
  })
) as typeof Typography

export default InlineCode
