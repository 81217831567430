import { useState } from 'react'

import ExpressClient from '@jeeves/clients/express'
import { useAuth } from '@jeeves/hooks'
import { useToast } from '@jeeves/new-components'

const useSlackIntegration = () => {
  const { getTokenSilently, user } = useAuth()
  const { toast } = useToast()

  const [slackConnected, setSlackConnected] = useState(false)
  const [loading, setLoading] = useState(false)

  const ec = new ExpressClient(getTokenSilently)

  const postSlackInvite = async () => {
    try {
      setLoading(true)

      await ec.post('/wizard/slackInvite', {
        user_email: user.email,
        user_first_name: user.given_name,
        cp_link: window.location.href,
      })
      setSlackConnected(true)
    } catch (error: any) {
      if (error?.response?.data?.error?.message?.includes('already_in_team')) {
        toast({
          description: `You've already been invited to our Slack channel. Please check your email.`,
          variant: 'info',
        })
      } else {
        toast({
          description: `Something went wrong. Please try again!`,
          variant: 'error',
        })
      }
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  return {
    loading,
    slackConnected,
    postSlackInvite,
  }
}

export default useSlackIntegration
