import * as React from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import Stepper from './components/Stepper'
import useUpsertDatabaseAccountWizard from './useUpsertDatabaseAccountWizard'

import { useAppsAndBiToolsState } from '../../contexts/AppsAndBiToolsContext'

const UpsertDatabaseAccountWizard = ({
  repoType,
  samlIntegrations,
  selectedSidecarEdge,
  serviceAccounts,
  refetch,
}) => {
  const { activeStep, steps } = useUpsertDatabaseAccountWizard({
    repoType,
    samlIntegrations,
    serviceAccounts,
    selectedSidecarEdge,
    refetch,
  })
  const { isUpdating } = useAppsAndBiToolsState()

  const renderedStep = steps[activeStep]

  return (
    <Stack spacing={3}>
      <Typography variant="h4" sx={{ color: 'text.primary' }}>
        {isUpdating ? `Edit Database Account` : 'Register Database Account'}
      </Typography>
      <Stepper steps={steps} activeStep={activeStep} />
      {renderedStep}
    </Stack>
  )
}

export default UpsertDatabaseAccountWizard
