import { useState, useCallback } from 'react'

import { useRepositoryDetailContext } from '@jeeves/pages/RepositoryDetail/contexts/RepositoryDetailContext'
import { policyIdentities } from '@jeeves/pages/RepositoryDetail/Tabs/Policies/helpers/policyIdentities'

import { useCreatePolicy, useUpdatePolicy } from '../hooks'

const useDataMasking = ({ policy, template }) => {
  const { repoId } = useRepositoryDetailContext()

  const [snackBarOpen, setSnackBarOpen] = useState(false)
  const [activeStep, setActiveStep] = useState(0)
  const [dataMaskingInfo, setDataMaskingInfo] = useState({
    id: policy?.id ?? '',
    name: policy?.name ?? '',
    description: policy?.description ?? '',
    tags: policy?.tags ?? template.tags,
    template: policy?.template ?? template,
    parameters: {
      specifiedDataLabels: policy?.parameters?.specifiedDataLabels ?? [],
      maskingSpecification: {
        method: policy?.parameters?.maskingSpecification?.method ?? 'NULL',
        replacementValue: policy?.parameters?.maskingSpecification?.replacementValue ?? '',
      },
      databaseAccountsAppliedTo: {
        modifier: policy?.parameters?.databaseAccountsAppliedTo?.modifier ?? 'ALL',
        databaseAccounts: policy?.parameters?.databaseAccountsAppliedTo?.databaseAccounts ?? [],
      },
      identitiesAppliedTo: {
        modifier: policy?.parameters?.identitiesAppliedTo?.modifier ?? 'ALL',
        identities: policy?.parameters?.identitiesAppliedTo?.identities ?? [],
      },
    },
  })

  const isEditing = Boolean(policy?.id)

  const [createDataMaskingPolicy, { loading: createLoading, error: createError }] = useCreatePolicy(
    {
      onError: _ => {
        setSnackBarOpen(true)
      },
    }
  )

  const [updateDataMaskingPolicy, { loading: updateLoading, error: updateError }] = useUpdatePolicy(
    {
      onError: _ => {
        setSnackBarOpen(true)
      },
    }
  )

  const prevStep = () => setActiveStep(currStep => currStep - 1)
  const nextStep = useCallback(() => {
    setActiveStep(currStep => currStep + 1)
  }, [])

  const handleCloseSnackbar = () => {
    setSnackBarOpen(false)
  }

  const handlePolicySubmit = async () => {
    const shapedIdentities = dataMaskingInfo?.parameters?.identitiesAppliedTo?.identities?.map(
      identity => {
        return { type: policyIdentities[identity.__typename], name: identity.name }
      }
    )

    const input = {
      dataMasking: {
        name: dataMaskingInfo.name,
        description: dataMaskingInfo.description,
        tags: dataMaskingInfo.tags,
        dataLabels: dataMaskingInfo.parameters.specifiedDataLabels,
        databaseAccountsAppliedTo: dataMaskingInfo?.parameters?.databaseAccountsAppliedTo,
        maskingSpecification: dataMaskingInfo.parameters.maskingSpecification,
        identitiesAppliedTo: {
          modifier: dataMaskingInfo.parameters.identitiesAppliedTo.modifier,
          identities: shapedIdentities,
        },
      },
    }

    try {
      if (isEditing) {
        const policyId = policy.id
        return await updateDataMaskingPolicy({
          variables: {
            policyId,
            input,
          },
        })
      }

      return await createDataMaskingPolicy({
        variables: {
          repoId,
          input,
        },
      })
    } catch (e) {
      console.error(e)
    }
  }

  return {
    dataMaskingInfo,
    activeStep,
    snackBarOpen,
    loading: createLoading || updateLoading,
    errorMessage: createError?.message || updateError?.message,
    setDataMaskingInfo,
    prevStep,
    nextStep,
    handlePolicySubmit,
    handleCloseSnackbar,
  }
}

export default useDataMasking
