/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import styled from '@emotion/styled'
import { useEffect, useState, useContext } from 'react'
import { renderRoutes } from 'react-router-config'
import { Redirect, useRouteMatch, useLocation } from 'react-router-dom'
import { withRouter } from 'react-router'

import Header from './Header'
import Navigation from '../Navigation'
import PopupMessage from '../PopupMessage'

import { SetupContext } from '@jeeves/pages/Setup/SetupContext'

import usePopup from '../PopupMessage/hooks/usePopup'
import { StyledDashboard, Main, RightSide, NavigationContainer } from './styles'
import { useAuth } from '@jeeves/components/Auth'

const Dashboard = ({ route, location, children, displayChildren }) => {
  // TO DO: isProfile just to be safe, fix this?
  const { clearPopup } = usePopup()
  // eslint-disable-next-line
  useEffect(() => clearPopup(), [location.pathname])
  const [open, setOpen] = useState(true)
  const { wizardComplete, isRegistrant } = useContext(SetupContext)
  const { reinitialize, isDbUser } = useAuth()
  const { search } = useLocation()
  const minimized = new URLSearchParams(search).get('minimized') === 'true'

  if (useRouteMatch('/home')) {
    if (wizardComplete === null || isRegistrant === null) return null
    if (!wizardComplete && isRegistrant) {
      return <Redirect to="/setup" />
    }
  }

  return minimized ? (
    renderRoutes(route.routes)
  ) : isDbUser ? (
    <StyledDashboard>
      <RightSide navOpen={null}>
        <Header />
        <Main>{renderRoutes(route.routes)}</Main>
      </RightSide>
    </StyledDashboard>
  ) : (
    <StyledDashboard>
      <NavigationContainer navOpen={open}>
        <Navigation navOpen={open} setOpen={setOpen} />
      </NavigationContainer>
      <PopupMessage />
      <RightSide navOpen={open}>
        <Header />
        <Main>{renderRoutes(route.routes)}</Main>
      </RightSide>
    </StyledDashboard>
  )
}

export const PlainLayout = ({ children }) => {
  const { clearPopup } = usePopup()
  // eslint-disable-next-line
  useEffect(() => clearPopup(), [location.pathname])

  return (
    <StyledDashboard>
      <RightSide navOpen={null}>
        <PopupMessage />
        <Header />
        <Main>{children}</Main>
      </RightSide>
    </StyledDashboard>
  )
}

export default withRouter(Dashboard)
