import React from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'

import { ScreenshotLayout } from '../../../components'
import { InlineCode } from '@jeeves/new-components'
import { IconCopyButton } from '@jeeves/new-components'
import CompassConnectionScreen from './Compass.png'

const Compass = ({ connectionUri }) => {
  return (
    <ScreenshotLayout
      imgSrc={CompassConnectionScreen}
      title="Connecting with Compass"
      steps={['In Compass, create a new connection.', `Enter the following connection string.`]}
      inlineCode={
        <Stack direction="row">
          <Box
            sx={{
              overflow: 'auto',
              padding: 1,
              border: '1px solid',
              borderColor: 'cyralColors.grey.200',
              backgroundColor: 'cyralColors.grey.100',
            }}
          >
            <InlineCode noWrap>{connectionUri}</InlineCode>
          </Box>
          <IconCopyButton text={connectionUri} />
        </Stack>
      }
    />
  )
}

export default Compass
