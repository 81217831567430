import React, { Fragment } from 'react'
import { BrowserRouter as Router, withRouter } from 'react-router-dom'
import { renderRoutes } from 'react-router-config'
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import { ThemeProvider } from '@mui/material/styles'
import { v5Theme } from '@jeeves/theme'
import { Toaster } from '@jeeves/new-components'

import { MuiThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { SetupProvider } from '@jeeves/pages/Setup/SetupContext'

import { AppConfigurationProvider } from '../../contexts/AppContext'
import { PopupProvider } from '../../components/PopupMessage/contexts/PopupContext'
import { useMount } from '@jeeves/hooks'

import routes from '../../pages'
import { muiTheme } from '@jeeves/theme'

import { ApolloClient, createHttpLink } from '@apollo/client'
import { ApolloProvider } from '@apollo/client/react'
import { setContext } from '@apollo/client/link/context'

import { useAuth } from '@jeeves/components/Auth'
import { cache } from '@jeeves/lib/apollo'

const basePath = process.env.PUBLIC_URL

const ScrollToTop = withRouter(({ history, children }) => {
  useMount(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0)
    })
    return () => {
      unlisten()
    }
  })

  return <Fragment>{children}</Fragment>
})

const App = () => {
  const { loading, getTokenSilently } = useAuth()

  const httpLink = createHttpLink({
    uri: `${window.location.origin}/graphql`,
  })

  const authLink = setContext(async (_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = await getTokenSilently()
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    }
  })

  const apolloClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
      query: {
        fetchPolicy: 'cache-and-network',
      },
    },
  })

  return (
    <ApolloProvider client={apolloClient}>
      <MuiThemeProvider theme={muiTheme}>
        <EmotionThemeProvider theme={muiTheme}>
          <SetupProvider>
            <CssBaseline />
            <Router basename={basePath}>
              <ScrollToTop>{renderRoutes(routes)}</ScrollToTop>
              <ThemeProvider theme={v5Theme}>
                <Toaster />
              </ThemeProvider>
            </Router>
          </SetupProvider>
        </EmotionThemeProvider>
      </MuiThemeProvider>
    </ApolloProvider>
  )
}

export default props => (
  <AppConfigurationProvider>
    <PopupProvider>
      <App {...props} />
    </PopupProvider>
  </AppConfigurationProvider>
)
