import * as React from 'react'
import { useFragment_experimental, gql } from '@apollo/client'

import EditBindingDialog from './EditBindingDialog'

const EditBinding_queryFragment = gql`
  fragment EditBinding_queryFragment on Query {
    ...EditBindingDialog_queryFragment
  }
  ${EditBindingDialog.fragments.EditBindingDialog_queryFragment}
`

const EditBinding_BindingFragment = gql`
  fragment EditBinding_BindingFragment on Binding {
    ...EditBindingDialog_BindingFragment
  }
  ${EditBindingDialog.fragments.EditBindingDialog_BindingFragment}
`

const EditBinding = ({ renderButtonRef, query, binding }) => {
  const { data } = useFragment_experimental({
    fragment: EditBinding_queryFragment,
    fragmentName: 'EditBinding_queryFragment',
    from: query,
  })

  const [open, setOpen] = React.useState(false)

  const openDialog = () => setOpen(true)
  const closeDialog = () => setOpen(false)

  React.useEffect(() => {
    if (renderButtonRef.current) {
      renderButtonRef.current.addEventListener('click', openDialog)
    }
  }, [renderButtonRef.current])

  return <EditBindingDialog open={open} onClose={closeDialog} binding={binding} query={data} />
}

EditBinding.fragments = {
  EditBinding_queryFragment,
  EditBinding_BindingFragment,
}

export default EditBinding
