import { useState, useCallback } from 'react'

import { useRepositoryDetailContext } from '@jeeves/pages/RepositoryDetail/contexts/RepositoryDetailContext'
import { policyIdentities } from '@jeeves/pages/RepositoryDetail/Tabs/Policies/helpers/policyIdentities'

import { useCreatePolicy, useUpdatePolicy } from '../hooks'

const useRepositoryProtection = ({ policy, template }) => {
  const { repoId } = useRepositoryDetailContext()

  const [snackBarOpen, setSnackBarOpen] = useState(false)
  const [activeStep, setActiveStep] = useState(0)
  const [repositoryProtectionInfo, setRepositoryProtectionInfo] = useState({
    id: policy?.id ?? '',
    name: policy?.name ?? '',
    description: policy?.description ?? '',
    tags: policy?.tags ?? template.tags,
    template: policy?.template ?? template,
    parameters: {
      databaseAccountsAppliedTo: {
        modifier: policy?.parameters?.databaseAccountsAppliedTo?.modifier ?? 'ALL',
        databaseAccounts: policy?.parameters?.databaseAccountsAppliedTo?.databaseAccounts ?? [],
      },
      identitiesAppliedTo: {
        modifier: policy?.parameters?.identitiesAppliedTo?.modifier ?? 'ALL',
        identities: policy?.parameters?.identitiesAppliedTo?.identities ?? [],
      },
      restrictedOperations: {
        update: policy?.parameters?.restrictedOperations.update ?? true,
        delete: policy?.parameters?.restrictedOperations.delete ?? true,
      },
      limit: policy?.parameters?.limit ?? null,
    },
  })

  const isEditing = Boolean(policy?.id)

  const [createRepositoryProtectionPolicy, { loading: createLoading, error: createError }] =
    useCreatePolicy({
      onError: _ => {
        setSnackBarOpen(true)
      },
    })

  const [updateRepositoryProtectionPolicy, { loading: updateLoading, error: updateError }] =
    useUpdatePolicy({
      onError: _ => {
        setSnackBarOpen(true)
      },
    })

  const prevStep = () => setActiveStep(currStep => currStep - 1)
  const nextStep = useCallback(() => {
    setActiveStep(currStep => currStep + 1)
  }, [])

  const handleCloseSnackbar = () => {
    setSnackBarOpen(false)
  }

  const handlePolicySubmit = async () => {
    const shapedIdentities =
      repositoryProtectionInfo?.parameters?.identitiesAppliedTo?.identities?.map(identity => {
        return { type: policyIdentities[identity.__typename], name: identity.name }
      })

    const input = {
      repositoryProtection: {
        name: repositoryProtectionInfo.name,
        description: repositoryProtectionInfo.description,
        tags: repositoryProtectionInfo.tags,
        databaseAccountsAppliedTo: repositoryProtectionInfo?.parameters?.databaseAccountsAppliedTo,
        identitiesAppliedTo: {
          modifier: repositoryProtectionInfo.parameters.identitiesAppliedTo.modifier,
          identities: shapedIdentities,
        },
        restrictedOperations: {
          update: repositoryProtectionInfo.parameters.restrictedOperations.update,
          delete: repositoryProtectionInfo.parameters.restrictedOperations.delete,
        },
        limit: Number(repositoryProtectionInfo.parameters.limit),
      },
    }

    try {
      if (isEditing) {
        const policyId = policy.id
        return await updateRepositoryProtectionPolicy({
          variables: {
            policyId,
            input,
          },
        })
      }

      return await createRepositoryProtectionPolicy({
        variables: {
          repoId,
          input,
        },
      })
    } catch (e) {
      console.error(e)
    }
  }

  return {
    repositoryProtectionInfo,
    activeStep,
    snackBarOpen,
    loading: createLoading || updateLoading,
    errorMessage: createError?.message || updateError?.message,
    setRepositoryProtectionInfo,
    prevStep,
    nextStep,
    handlePolicySubmit,
    handleCloseSnackbar,
  }
}

export default useRepositoryProtection
