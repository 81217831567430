/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { useEffect, useState } from 'react'
import { Fade } from '@material-ui/core/'
import { IntegrationCard } from '../../../primitives'
import View from './View'
import Edit from './Edit'

export default props => {
  const {
    integration = null,
    putIntegration,
    deleteIntegration,
    creating = false,
    tenantName,
    tenantNameWithRegion,
  } = props
  const [editing, setEditing] = useState(false)
  const configureEditing = () => setEditing(creating)

  useEffect(() => {
    configureEditing()
  }, [])

  const onDelete = async () => {
    await deleteIntegration(integration.id)
    setEditing(false)
  }

  return (
    <IntegrationCard>
      {!editing && integration && (
        <Fade in={!editing}>
          <View integration={integration} setEditing={() => setEditing(true)} onDelete={onDelete} />
        </Fade>
      )}
      {editing && (
        <Fade in={editing}>
          <Edit
            integration={integration}
            handleSetEdit={setEditing}
            putIntegration={putIntegration}
            deleteIntegration={deleteIntegration}
            tenantName={tenantName}
            tenantNameWithRegion={tenantNameWithRegion}
          />
        </Fade>
      )}
    </IntegrationCard>
  )
}
