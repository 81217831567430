import { Box } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { Dialog } from '@material-ui/core'
import { v5Theme } from '@jeeves/theme'

import { Cloudwatch, Custom, Datadog, ELK, SelectIntegration, Splunk, Sumologic } from './forms'

const NewIntegrationDialog = ({ isOpen, onClose, integration, setIntegration }) => {
  const resetIntegration = () => setIntegration()

  const getIntegrationForm = integrationTypename => {
    switch (integrationTypename) {
      case 'CloudWatchIntegration':
        return (
          <Cloudwatch
            integration={integration}
            onClose={onClose}
            resetIntegration={resetIntegration}
          />
        )
      case 'CustomLoggingIntegration':
        return (
          <Custom integration={integration} onClose={onClose} resetIntegration={resetIntegration} />
        )
      case 'DatadogIntegration':
        return (
          <Datadog
            integration={integration}
            onClose={onClose}
            resetIntegration={resetIntegration}
          />
        )
      case 'ELKIntegration':
        return (
          <ELK integration={integration} onClose={onClose} resetIntegration={resetIntegration} />
        )
      case 'SplunkIntegration':
        return (
          <Splunk integration={integration} onClose={onClose} resetIntegration={resetIntegration} />
        )
      case 'SumoLogicIntegration':
        return (
          <Sumologic
            integration={integration}
            onClose={onClose}
            resetIntegration={resetIntegration}
          />
        )
      default:
        return <SelectIntegration setIntegration={setIntegration} onClose={onClose} />
    }
  }

  return (
    <Dialog open={isOpen} fullWidth maxWidth="md" aria-labelledby="dialog-title">
      <ThemeProvider theme={v5Theme}>
        <Box sx={{ padding: 4 }}>{getIntegrationForm(integration?.__typename)}</Box>
      </ThemeProvider>
    </Dialog>
  )
}

export default NewIntegrationDialog
