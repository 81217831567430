/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { useState, useEffect, Fragment } from 'react'
import styled from '@emotion/styled'
import {
  Typography,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  FormControl,
  FormLabel as MuiFormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  TextField,
  withStyles,
  Checkbox,
} from '@material-ui/core'
import { Button, Dialog } from '@jeeves/components/Primitives'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { red, green, yellow, grey } from '@material-ui/core/colors'
import { IntermediateLoading } from '@jeeves/components/Loading'
import { ServiceIcon } from '@jeeves/components/ServiceIcon'
import { IDPMenuItemContent } from '../../Advanced/components/IDPSelect'
import { durationObjectToIso } from '@jeeves/utils/functions'

import { useFormik } from 'formik'
import { DivingScuba } from 'mdi-material-ui'

// TO DO: this is the same as repositoryAccounts refactor theme so this doesnt repeat, make it so that formcontrol is a styled component
const styles = theme => ({
  formControl: {
    margin: '20px 0',
    display: 'block',
  },
  first: {
    marginTop: '0',
  },
  last: {
    marginBottom: '0',
  },
  formControlInput: {
    marginTop: '12px',
  },
  descriptionFormLabel: {
    fontSize: '1em',
  },
})

const FormLabel = styled(MuiFormLabel)`
  color: black;
`

export const DurationInput = withStyles(styles)(
  ({ durationObject = {}, handleChange = () => {}, classes, disabled }) => {
    return (
      <div
        css={{ display: 'flex', alignItems: 'center', marginTop: '-8px' }}
        className={classes.formControlInput}
      >
        {/* Set transitions for disabling greying */}
        <TextField
          key="daysInput"
          name="days"
          value={durationObject.days || ''}
          onChange={handleChange}
          margin="dense"
          variant="outlined"
          label="Days"
          type="number"
          disabled={disabled}
          css={{ marginRight: '12px', flexGrow: 1 }}
          InputProps={{
            inputProps: {
              style: {
                backgroundColor: disabled ? 'rgba(0, 0, 0, 0.12)' : 'rgba(0,0,0,0)',
                borderRadius: '4px',
              },
            },
          }}
        ></TextField>
        <TextField
          key="hoursInput"
          name="hours"
          value={durationObject.hours || ''}
          onChange={handleChange}
          margin="dense"
          variant="outlined"
          label="Hr"
          disabled={disabled}
          type="number"
          css={{ flexGrow: 1 }}
          InputProps={{
            inputProps: {
              min: 0,
              max: 23,
              style: {
                backgroundColor: disabled ? 'rgba(0, 0, 0, 0.12)' : 'rgba(0,0,0,0)',
                borderRadius: '4px',
              },
            },
          }}
        ></TextField>
        <div css={{ margin: '0 6px' }}>
          <Typography>:</Typography>
        </div>
        <TextField
          key="minutesInput"
          name="minutes"
          value={durationObject.minutes || ''}
          onChange={handleChange}
          margin="dense"
          variant="outlined"
          label="Min"
          disabled={disabled}
          type="number"
          css={{ flexGrow: 1 }}
          InputProps={{
            inputProps: {
              min: 0,
              max: 59,
              style: {
                backgroundColor: disabled ? 'rgba(0, 0, 0, 0.12)' : 'rgba(0,0,0,0)',
                borderRadius: '4px',
              },
            },
          }}
        ></TextField>
        <div css={{ margin: '0 6px' }}>
          <Typography>:</Typography>
        </div>
        <TextField
          key="secondsInput"
          name="seconds"
          value={durationObject.seconds || ''}
          onChange={handleChange}
          margin="dense"
          variant="outlined"
          label="Sec"
          disabled={disabled}
          type="number"
          css={{ flexGrow: 1 }}
          InputProps={{
            inputProps: {
              min: 0,
              max: 59,
              style: {
                backgroundColor: disabled ? 'rgba(0, 0, 0, 0.12)' : 'rgba(0,0,0,0)',
                borderRadius: '4px',
              },
            },
          }}
        ></TextField>
      </div>
    )
  }
)

const AddDialog = withStyles(styles)(
  ({
    open,
    onClose,
    createIdentityMap,
    createAccessRequest,
    repos,
    repoAccounts: argsRepoAccounts,
    idp,
    connections,
    classes,
    isRequestAccess,
    identityName: argsIdentityName,
    isDemo,
    authorizationPolicyInstances,
    repoType,
  }) => {
    const [identityType, setIdentityType] = useState(isRequestAccess ? 'User' : '')
    const [identityName, setIdentityName] = useState(argsIdentityName || '')
    const [repoAccountUUID, setRepoAccountUUID] = useState('')
    const [durationType, setDurationType] = useState('')
    const [durationObject, setDurationObject] = useState({})
    const [accessDuration, setAccessDuration] = useState('')
    const [repoID, setRepoID] = useState('')
    const [repoAccounts, setRepoAccounts] = useState(isRequestAccess ? [] : argsRepoAccounts)
    const [authorizationPolicyInstanceID, setAuthorizationPolicyInstanceID] = useState('')
    // Combine these two states
    const [submitting, setSubmitting] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [usingAuthorizationPolicy, setUsingAuthorizationPolicy] = useState(false)

    const supportedMFARepoTypes = [
      'mysql',
      'postgresql',
      'mongodb',
      'sqlserver',
      'oracle',
      'mariadb',
      'redshift',
    ]
    const displayedAuthPolicies = authorizationPolicyInstances.filter(
      policy =>
        policy.templateType === 'pagerduty' ||
        (policy.templateType === 'duoMfa' && supportedMFARepoTypes.includes(repoType))
    )

    const handleClose = () => {
      onClose()
      resetForm()
    }

    const handleChange = event => {
      event.persist()

      // TO DO: make this better
      if (event.target.name === 'identityType') {
        setIdentityType(event.target.value)
      } else if (event.target.name === 'identityName') {
        setIdentityName(event.target.value)
      } else if (event.target.name === 'repoID') {
        setRepoID(event.target.value)
        setRepoAccounts(repos.find(repo => repo.id === event.target.value).repoAccounts)
        setRepoAccountUUID('')
      } else if (event.target.name === 'repoAccountUUID') {
        setRepoAccountUUID(event.target.value)
      } else if (event.target.name === 'authorizationPolicyInstanceID') {
        setAuthorizationPolicyInstanceID(event.target.value)
      } else if (event.target.name === 'durationType') {
        setDurationType(event.target.value)
      } else if (event.target.name === 'accessDuration') {
        setAccessDuration(event.target.value)
      } else if (event.target.name === 'days') {
        setDurationObject(prevObj => ({ ...prevObj, days: event.target.value }))
      } else if (event.target.name === 'hours') {
        setDurationObject(prevObj => ({ ...prevObj, hours: event.target.value }))
      } else if (event.target.name === 'minutes') {
        setDurationObject(prevObj => ({ ...prevObj, minutes: event.target.value }))
      } else if (event.target.name === 'seconds') {
        setDurationObject(prevObj => ({ ...prevObj, seconds: event.target.value }))
      } else if (event.target.name === 'usingAuthorizationPolicy') {
        setUsingAuthorizationPolicy(event.target.checked)
        if (!event.target.checked) setAuthorizationPolicyInstanceID('')
      }
    }

    const handleSubmit = async () => {
      setSubmitting(true)
      if (isRequestAccess) {
        await createAccessRequest(
          repoID,
          repos.find(repo => repo.id === repoID)
            ? repos.find(repo => repo.id === repoID).repo.name
            : '',
          accessDuration,
          repoAccountUUID,
          repoAccounts.find(repoAccount => repoAccount.uuid === repoAccountUUID)
            ? repoAccounts.find(repoAccount => repoAccount.uuid === repoAccountUUID).username
            : ''
        )
      } else {
        await createIdentityMap(identityType, identityName, repoAccountUUID, {
          ...(accessDuration && { accessDuration }),
          ...(authorizationPolicyInstanceID && { authorizationPolicyInstanceID }),
        })
      }
      setSubmitting(false)
      setSubmitted(true)
      setTimeout(() => {
        handleClose()
        setTimeout(() => {
          setSubmitted(false)
        }, 500)
      }, 2000)
    }

    const resetForm = () => {
      setIdentityType(isRequestAccess ? 'User' : '')
      setIdentityName(argsIdentityName || '')
      setRepoID('')
      setRepoAccountUUID('')
      setDurationType('')
      setDurationObject({})
      setAccessDuration('')
      setAuthorizationPolicyInstanceID('')
      setUsingAuthorizationPolicy(false)
    }

    useEffect(() => {
      if (durationType === 'unlimited') {
        setAccessDuration('')
      } else {
        setAccessDuration(durationObjectToIso(durationObject))
      }
    }, [durationType, durationObject])

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        aria-labelledby="form-dialog-title"
        maxWidth="md"
      >
        <div
          css={{
            position: 'relative',
            minHeight: submitted ? '120px' : '391px',
            transition: 'min-height 0.5s ease-out',
          }}
        >
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'absolute',
              top: '50%',
              transform: 'translate(-50%, -50%)',
              left: '50%',
              opacity: submitted ? 1 : 0,
              transition: 'opacity 0.5s ease-out',
            }}
          >
            <CheckCircleIcon fontSize="large" style={{ color: green[500] }}></CheckCircleIcon>
            <Typography css={{ marginLeft: '12px', fontSize: '1.5rem' }}>
              {isRequestAccess ? 'Request submitted' : 'Mapping created'}
            </Typography>
          </div>
          {!submitted && (
            <Fragment>
              <IntermediateLoading loading={submitting}></IntermediateLoading>
              <DialogTitle id="form-dialog-title">
                {isRequestAccess ? 'Request Access' : 'Create Mapping'}
              </DialogTitle>
              <DialogContent>
                <div>
                  {!isRequestAccess && (
                    <Fragment>
                      <div
                        css={{ display: 'flex', alignItems: 'center' }}
                        className={classes.formControl}
                      >
                        <Typography css={{ marginRight: '12px', fontSize: '18.2857px' }}>
                          Identity Provider:
                        </Typography>
                        <IDPMenuItemContent
                          connection={
                            connections.find(connection => connection.name === idp) || { name: idp }
                          }
                          typographyProps={{ css: { fontSize: '18.2857px' } }}
                          standalone
                        />
                      </div>
                      <div
                        css={{ display: 'flex', alignItems: 'center' }}
                        className={classes.formControl}
                      >
                        <TextField
                          select
                          variant="outlined"
                          label="Identity Type"
                          type="text"
                          name="identityType"
                          value={identityType}
                          onChange={handleChange}
                          css={{ marginRight: '12px', width: '150px' }}
                        >
                          {['Group', 'User'].map(item => (
                            <MenuItem key={item} value={item.toLowerCase()}>
                              {item}
                            </MenuItem>
                          ))}
                        </TextField>
                        <TextField
                          variant="outlined"
                          label="Identity"
                          type="text"
                          name="identityName"
                          value={identityName}
                          onChange={handleChange}
                          css={{ flexGrow: 1 }}
                        />
                      </div>
                    </Fragment>
                  )}
                  {isRequestAccess && (
                    <TextField
                      select
                      fullWidth
                      variant="outlined"
                      className={classes.formControl}
                      label="Data Repository"
                      type="text"
                      name="repoID"
                      value={repoID}
                      onChange={handleChange}
                    >
                      {repos.map(repo => (
                        <MenuItem value={repo.id}>
                          <div
                            css={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                          >
                            <ServiceIcon type={repo.repo.type} />
                            <div>{repo.repo.name}</div>
                          </div>
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                  <TextField
                    select
                    fullWidth
                    variant="outlined"
                    className={classes.formControl}
                    label="Local Account"
                    type="text"
                    name="repoAccountUUID"
                    value={repoAccountUUID}
                    onChange={handleChange}
                    disabled={!repoID && isRequestAccess}
                    css={{
                      backgroundColor: !repoID && isRequestAccess ? 'rgba(0,0,0,0.12)' : 'none',
                      borderRadius: '4px',
                    }}
                  >
                    {repoAccounts.map(repoAccount => (
                      <MenuItem value={repoAccount.uuid}>{repoAccount.username}</MenuItem>
                    ))}
                  </TextField>
                  <div
                    css={{
                      border: '1px solid rgb(0, 0, 0, 0.15)',
                      borderRadius: '4px',
                      padding: '24px',
                    }}
                    className={classes.formControl}
                  >
                    <FormControl
                      className={`${classes.formControl} ${classes.first} ${classes.last}`}
                    >
                      <FormLabel css={{ marginBottom: '12px' }}>Duration</FormLabel>
                      <RadioGroup name="durationType" value={durationType} onChange={handleChange}>
                        <FormControlLabel
                          control={<Radio color="primary" />}
                          label={
                            <div css={{ display: 'flex', alignItems: 'center' }}>
                              <Typography css={{ marginRight: '12px' }}>Limited to </Typography>
                              <div css={{ paddingLeft: '34px' }}>
                                <DurationInput
                                  durationObject={durationObject}
                                  handleChange={handleChange}
                                  disabled={durationType !== 'limited'}
                                ></DurationInput>
                              </div>
                            </div>
                          }
                          value="limited"
                        ></FormControlLabel>
                        <FormControlLabel
                          control={<Radio color="primary" />}
                          label="Unlimited"
                          value="unlimited"
                        ></FormControlLabel>
                      </RadioGroup>
                    </FormControl>
                  </div>
                  {!isRequestAccess &&
                    authorizationPolicyInstances &&
                    authorizationPolicyInstances.length > 0 && (
                      <div
                        css={{
                          border: '1px solid rgb(0, 0, 0, 0.15)',
                          borderRadius: '4px',
                          padding: '24px',
                          paddingTop: '12px',
                        }}
                        className={`${classes.formControl} ${classes.last}`}
                      >
                        {/* <FormLabel css={{ marginBottom: '12px' }}>Authorization Policy</FormLabel> */}
                        <div css={{ marginBottom: '12px' }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={usingAuthorizationPolicy}
                                onChange={handleChange}
                                name="usingAuthorizationPolicy"
                                color="primary"
                              />
                            }
                            css={{ flexGrow: 1 }}
                            label="Restrict access using an access condition"
                          />
                        </div>
                        <TextField
                          fullWidth
                          select
                          variant="outlined"
                          type="text"
                          name="authorizationPolicyInstanceID"
                          value={authorizationPolicyInstanceID}
                          onChange={handleChange}
                          label="Access Condition"
                          disabled={!usingAuthorizationPolicy}
                          css={{
                            flexGrow: 1,
                            borderRadius: '4px',
                            backgroundColor: !usingAuthorizationPolicy
                              ? 'rgba(0, 0, 0, 0.12)'
                              : 'rgba(0,0,0,0)',
                          }}
                        >
                          {displayedAuthPolicies.map(instance => (
                            <MenuItem value={instance.id}>
                              <div css={{ display: 'flex', alignItems: 'center' }}>
                                <ServiceIcon
                                  type={instance.templateType.toLowerCase()}
                                ></ServiceIcon>
                                <Typography>{instance.name}</Typography>
                              </div>
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                    )}
                </div>
              </DialogContent>
              <DialogActions
                css={() => css`
                  display: flex;
                  padding: 24px;
                  justify-content: flex-end;
                `}
              >
                <Button onClick={handleClose}>Cancel</Button>
                {/* <div css={{ position: 'relative' }}> */}
                {/* TO DO: make the below disabled better */}
                <Button
                  onClick={handleSubmit}
                  disabled={
                    !identityType ||
                    !identityName ||
                    !repoAccountUUID ||
                    !durationType ||
                    (usingAuthorizationPolicy && !authorizationPolicyInstanceID) ||
                    (durationType === 'limited' &&
                      (!accessDuration || accessDuration === 'P0DT0H0M0S'))
                  }
                  variant="contained"
                  color="primary"
                >
                  {isRequestAccess ? 'Submit' : 'Create'}
                </Button>
                {/* </div> */}
              </DialogActions>
            </Fragment>
          )}
        </div>
      </Dialog>
    )
  }
)

export default AddDialog
