import { FormControlLabel, FormHelperText, Stack, Switch, Typography } from '@mui/material'
import { Controller } from 'react-hook-form'

const OfflineTokenValidation = () => {
  return (
    <Controller
      name="useOfflineTokenValidation"
      render={({ field: { onChange, value, name } }) => (
        <FormControlLabel
          control={<Switch name={name} onChange={onChange} checked={value} />}
          label={
            <Stack spacing={2}>
              <Typography variant="h4" sx={{ color: 'text.primary' }}>
                Use Offline Token Validation
              </Typography>

              <FormHelperText sx={{ typography: 'body2' }}>
                Allows the sidecar to validate tokens without online access to the control plane.
              </FormHelperText>
            </Stack>
          }
          sx={{ alignItems: 'baseline' }}
        />
      )}
    />
  )
}

export default OfflineTokenValidation
