/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import styled from '@emotion/styled'
import React from 'react'
import { Typography, CardContent, Paper, Grid } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import { Feature, WrapperCard } from './styles'

import moment from 'moment'

const Container = styled(Paper)`
  padding-bottom: 16px;
`

const Pricing = endDate => (
  <div>
    <Feature>{`Expires: ${moment(endDate).format('MMM D, YYYY')}`}</Feature>
  </div>
)

export const PlansHeader = ({ license, mapName }) => {
  return (
    <WrapperCard>
      <CardContent>
        <Grid container justify="space-between" spacing={32}>
          <Grid item>
            <Typography>
              <b>{mapName(license.userFriendlyName)}</b>
            </Typography>
          </Grid>
          <Grid item>
            <Pricing endDate={license.endDate}></Pricing>
          </Grid>
        </Grid>
      </CardContent>
    </WrapperCard>
  )
}
