import * as React from 'react'
import { useFragment_experimental, gql, useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { useDebouncedState } from '@react-hookz/web'
import Stack from '@mui/material/Stack'

import { SearchInput } from '@jeeves/new-components'

import BindingsTable from './BindingsTable/BindingsTable'
import useBindingsExist from './useBindingsExist'
import { BindingsTableProvider } from './BindingsTable/BindingsTableContext'

const Bindings_queryFragment = gql`
  fragment Bindings_query on Query {
    ...BindingsTable_query
  }
  ${BindingsTable.fragments.BindingsTable_queryFragment}
`

const BINDINGS_EXIST_QUERY = gql`
  query BindingsExist_Query($sidecarId: String!) {
    sidecar(id: $sidecarId) {
      id
      bindings(first: 1) {
        totalCount
      }
    }
  }
`

const Bindings = ({ query, registerRefreshCurrentTableViewCallback }) => {
  const { id: sidecarId } = useParams()
  const [searchValue, setSearchValue] = useDebouncedState('', 300)
  const { data } = useFragment_experimental({
    fragment: Bindings_queryFragment,
    fragmentName: 'Bindings_query',
    from: query,
  })

  const { data: bindingsExistData } = useQuery(BINDINGS_EXIST_QUERY, {
    variables: { sidecarId },
  })

  const bindingsExist = bindingsExistData?.sidecar?.bindings?.totalCount > 0

  return (
    <Stack spacing={2}>
      {bindingsExist && (
        <Stack direction="row" spacing={1}>
          <SearchInput
            onChange={e => setSearchValue(e.target.value)}
            placeholder="Search by repository name"
            sx={{ minWidth: '300px' }}
          />
        </Stack>
      )}

      <BindingsTableProvider
        searchValue={searchValue}
        registerRefreshCurrentTableViewCallback={registerRefreshCurrentTableViewCallback}
      >
        <BindingsTable query={data} />
      </BindingsTableProvider>
    </Stack>
  )
}

Bindings.fragments = {
  Bindings_queryFragment,
}

export default Bindings
