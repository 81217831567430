import * as React from 'react'

const AppsAndBiToolsStateContext = React.createContext()
const AppsAndBiToolsDispatchContext = React.createContext()

const initialState = {
  isRegistering: false,
  isUpdating: false,
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'registerNewAccount': {
      return {
        ...initialState,
        isRegistering: true,
        // databaseAccountInfo: undefined,
        // [action.fieldName]: action.payload,
      }
    }
    case 'editAccount': {
      // Info of service account that's being updated/edited
      //   const serviceAccount = action.payload

      return {
        ...initialState,
        isUpdating: true,
        databaseAccountInfo: action.payload,
      }
    }
    case 'reset': {
      return {
        ...initialState,
      }
    }
    default:
      return state
  }
}

export const AppsAndBiToolsProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  return (
    <AppsAndBiToolsDispatchContext.Provider value={dispatch}>
      <AppsAndBiToolsStateContext.Provider value={state}>
        {children}
      </AppsAndBiToolsStateContext.Provider>
    </AppsAndBiToolsDispatchContext.Provider>
  )
}

export const useAppsAndBiToolsState = () => {
  const context = React.useContext(AppsAndBiToolsStateContext)
  if (context === undefined) {
    throw new Error('useAppsAndBiToolsState must be used within a AppsAndBiToolsProvider')
  }

  return context
}

export const useAppsAndBiToolsDispatch = () => {
  const context = React.useContext(AppsAndBiToolsDispatchContext)
  if (context === undefined) {
    throw new Error('useAppsAndBiToolsDispatch must be used within a AppsAndBiToolsProvider')
  }

  return context
}
