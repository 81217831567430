import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const DremioIcon = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 1000 1000">
      <path
        fill="#3a3d43"
        d="M997.6 18.9c-7.8-9.4-28.2-9.4-54.9 3.1l-326 166.2-1.6-1.6-17.2-17.2c-9.4-7.8-26.7-18.8-37.6-23.5-70.5-36.1-153.6-32.9-227.3-11-83.1 25.1-161.5 75.2-221 138-26.7 28.2-50.2 61.1-69 95.6-23.6 43.9-37.7 91-42.4 139.6-7.8 95.6 18.8 219.5 84.7 291.6 15.7 18.8 32.9 34.5 50.2 51.7 12.5 12.5 26.7 25.1 39.2 39.2 23.5 28.2 36.1 56.4 47 92.5l1.6 4.7 4.7-3.1c67.4-48.6 92.5-112.9 43.9-185 28.2-23.5 42.3-56.4 42.3-92.5 0-34.5-14.1-72.1-43.9-92.5 12.5-4.7 31.4-14.1 43.9-18.8 0 11-9.4 28.2-1.6 32.9 15.7 9.4 75.2-15.7 95.6-69 18.8-6.3 29.8-11 50.2-18.8 76.8-31.4 161.5-75.2 208.5-130.1 15.7-18.8 48.6-73.7 12.5-87.8 12.5-14.1 20.4-37.6 15.7-53.3L974.1 66c20.4-12.6 32.9-36.1 23.5-47.1zM140.1 713.4c-6.3-34.5 53.3-64.3 84.7-78.4-7.8 25.1-23.5 50.2-42.3 69-9.5 7.9-33 29.8-42.4 9.4z"
      />
      <path
        fill="#55bfcf"
        d="M663.7 370.1c20.4-26.7 6.3-42.3-7.8-26.7-28.2 29.8-72.1 39.2-111.3 32.9-58-9.4-59.6-18.8-50.2-26.7 67.4 25.1 145.8-7.8 178.7-61.1 14.1-20.4-32.9-36.1-61.1-73.7C478.7 34.6 58.6 191.4 19.4 508.1c-12.5 95.6 14.1 200.7 80 274.3 48.6 54.9 101.9 78.4 131.7 166.2 58-50.2 50.2-106.6 6.3-156.8 73.7-37.6 62.7-136.4 11-167.7-23.5 87.8-109.7 153.6-130.1 90.9-12.5-64.3 92.5-106.6 260.2-169.3 141-54.9 231.9-98.8 285.2-175.6z"
      />
      <path
        fill="#fff"
        d="M663.7 370.1c20.4-26.7 6.3-42.3-7.8-26.7-25.1 25.1-61.1 36.1-95.6 34.5-58-4.7-103.5-15.7-145.8-11-83.1 7.8-164.6 61.1-208.5 105-73.7 75.2-153.6 232-72.1 261.8-6.3-3.1-11-11-14.1-20.4C107.3 649 212.3 606.7 380 544c139.5-53.2 230.4-97.1 283.7-173.9z"
      />
      <path
        fill="#55bfcf"
        d="M303.1 492.4c43.9 20.4 34.5 95.6 28.2 114.4 81.5-23.5 58-144.2 95.6-181.9-47-1.5-136.3 45.5-123.8 67.5z"
      />
      <path
        fill="#3a3d43"
        d="M442.6 272.9c11-6.3 26.7-1.6 32.9 9.4 6.3 11 1.6 25.1-9.4 32.9-11 6.3-26.7 1.6-32.9-9.4-6.2-12.5-1.5-26.6 9.4-32.9z"
      />
      <path
        fill="#55bfcf"
        d="M641.7 282.3 970.9 53.4c9.4-6.3 21.9-20.4 15.7-28.2-4.7-6.3-18.8-3.1-31.4 3.1L599.4 221.2c-11 23.5 12.5 73.7 42.3 61.1z"
      />
      <path
        fill="#fff"
        d="M613.5 236.8 956.8 34.6c15.7-9.4 20.4-9.4 29.8-9.4-4.7-6.3-18.8-1.6-31.4 3.1L599.4 221.2c-9.4 18.8 3.1 51.7 21.9 61.1-6.2-15.7-10.9-31.3-7.8-45.5z"
      />
      <path
        fill="#28abad"
        d="M105.6 327.8c11-1.6 20.4 6.3 20.4 17.2 0 11-9.4 21.9-20.4 23.5-11 1.6-20.4-6.3-18.8-17.2-1.6-11 7.8-22 18.8-23.5zm67.4-73.7c9.4-1.6 17.2 4.7 17.2 15.7s-7.8 20.4-18.8 21.9c-9.4 1.6-17.2-4.7-17.2-15.7s9.4-20.3 18.8-21.9zm202.2-97.2c9.4 0 15.7 9.4 14.1 18.8-3.1 9.4-12.5 17.3-21.9 17.3s-15.7-9.4-14.1-18.8c1.5-11 11-18.9 21.9-17.3zm61.2-11c7.8 1.6 12.5 9.4 9.4 17.2s-12.5 12.5-20.4 11c-7.8-1.6-12.5-9.4-9.4-17.2 3.1-7.8 12.5-12.5 20.4-11zM173 299.6c7.8-1.6 12.5 4.7 12.5 11 0 7.8-6.3 14.1-14.1 15.7-7.8 1.6-12.5-3.1-12.5-11 1.6-6.3 7.8-14.2 14.1-15.7zM69.5 385.8c7.8-1.6 14.1 4.7 14.1 12.5s-6.3 15.7-14.1 17.2c-7.8 1.6-14.1-4.7-14.1-11 0-9.3 6.3-17.2 14.1-18.7zm616.1-191.3c-1.6-7.8-1.6-14.1-1.6-20.4l-15.6 7.9c0 6.3 1.6 12.5 4.7 18.8 6.3 17.2 18.8 32.9 28.2 36.1-6.3-11-12.5-26.7-15.7-42.4zm81.5-48.6c0-6.3-1.6-11 0-15.7l-12.5 7.8c0 4.7 1.6 9.4 3.1 14.1C764 167.9 775 182 781.3 182c-6.3-6.3-11-22-14.2-36.1zm81.6-47c0-4.7-1.6-7.8 0-12.5l-11 6.3c0 3.1 0 7.8 1.6 11 3.1 11 9.4 21.9 18.8 23.5-3.2-6.4-7.9-15.8-9.4-28.3zm78.4-45.5c0-3.1-1.6-6.3 0-7.8l-6.3 3.1-3.1 1.6c0 1.6 1.6 4.7 3.1 7.8 3.1 7.8 9.4 17.2 14.1 20.4-3.1-6.3-6.3-15.7-7.8-25.1z"
      />
    </SvgIcon>
  )
}

export default DremioIcon
