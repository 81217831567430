import { useHistory } from 'react-router-dom'
import { useMutation, MutationHookOptions } from '@apollo/client'

import { useRepositoryDetailContext } from '@jeeves/pages/RepositoryDetail/contexts/RepositoryDetailContext'
import { graphql } from '@jeeves/graphql'
import { UpdatePolicyMutation, UpdatePolicyMutationVariables } from '@jeeves/graphql/graphql'

const UPDATE_POLICY = graphql(`
  mutation UpdatePolicy($policyId: ID!, $input: UpdatePolicyInput!) {
    updatePolicy(policyId: $policyId, input: $input) {
      policy {
        id
        name
        description
        tags
        ...DataMaskingFields
        ...DataProtectionFields
        ...DataFirewallFields
        ...ReadLimitFields
        ...ServiceAccountAbuseFields
        ...UserSegmentationFields
        ...RepositoryProtectionFields
        ...RateLimitFields
      }
    }
  }
`)

const useOnCompleted = () => {
  const history = useHistory()
  const { repoId } = useRepositoryDetailContext()

  const onCompleted = (policyId: string) => {
    history.push(`/repositories/${repoId}/policies/${policyId}`)
  }

  return onCompleted
}

type UpdatePolicyOptions = MutationHookOptions<UpdatePolicyMutation, UpdatePolicyMutationVariables>

type OnCompleted = UpdatePolicyOptions['onCompleted']
type OnError = UpdatePolicyOptions['onError']

const useUpdatePolicy = ({
  onCompleted,
  onError,
}: {
  onCompleted?: OnCompleted
  onError?: OnError
}) => {
  const defaultOnCompleted = useOnCompleted()

  const updatePolicyMutation = useMutation(UPDATE_POLICY, {
    onCompleted:
      onCompleted ||
      (data => {
        const policyId = data?.updatePolicy?.policy?.id

        if (policyId) {
          defaultOnCompleted(policyId)
        }
      }),
    ...(onError && { onError }),
  })

  return updatePolicyMutation
}

export default useUpdatePolicy
