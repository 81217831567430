import { useContext } from 'react'
import { WrappersContext } from '../contexts/WrappersContext'
import ExpressClient from '../../../clients/express'
import { useAuth } from '../../../components/Auth'

import usePopup from '../../../components/PopupMessage/hooks/usePopup'
import { get as lodashGet } from 'lodash'

const useWrappers = () => {
  const { getTokenSilently, user } = useAuth()
  const ec = new ExpressClient(getTokenSilently)
  const [state, setState] = useContext(WrappersContext)
  const { setPopup, popupTypes } = usePopup()

  // TABLE FOOTER/PAGINATION FUNCTIONS
  const handleRequestSort = (_, property) => {
    const orderBy = property
    let order = 'asc'
    if (state.orderBy === property && state.order === 'asc') {
      order = 'desc'
    }
    setState(state => ({ ...state, order, orderBy }))
  }

  const handleChangePage = (event, page) => {
    setState(state => ({ ...state, page }))
  }

  const handleChangeRowsPerPage = event => {
    setState(state => ({ ...state, rowsPerPage: event.target.value }))
  }

  const handleChange = event => {
    const { name, value } = event.target
    event.persist()
    setState(state => ({ ...state, [name]: [value] }))
  }

  const updateWrappers = async () => {
    try {
      const result = await ec.get('/sidecars/home')
      setState(state => ({
        ...state,
        wrappers: result.data,
      }))
    } catch (e) {}
  }

  const handleOnToggleWrapper = async (wrapperId, passThroughEnabled) => {
    try {
      await ec.request({
        method: 'PATCH',
        url: `/sidecars/${wrapperId}`,
        data: { sidecarStatus: !passThroughEnabled },
      })
    } catch (e) {
      const popupMessage = lodashGet(e, 'response.data.error.message', '')
      setPopup(popupTypes.ERROR, popupMessage)
    }
  }

  const setSidecarPassthrough = async (wrapperId, passthrough) => {
    try {
      const body = { passthrough: passthrough }
      console.log(`PUT /sidecars/${wrapperId}/passthrough ${JSON.stringify(body)}`)
      await ec.request({
        method: 'PUT',
        url: `/sidecars/${wrapperId}/passthrough`,
        data: body,
      })
    } catch (e) {
      const popupMessage = lodashGet(e, 'response.data.error.message', '')
      setPopup(popupTypes.ERROR, popupMessage)
    }
  }

  const handleOnUpdateTags = async (sidecarId, updatedTags) => {
    try {
      await ec.request({
        method: 'PATCH',
        url: `/sidecars/${sidecarId}/tags`,
        data: { updatedSidecarTags: updatedTags },
      })
    } catch (e) {
      const popupMessage = lodashGet(e, 'response.data.error.message', '')
      setPopup(popupTypes.ERROR, popupMessage)
      // console.log('handleOnToggle error: ', e.response.data.error.message)
    }
  }

  const handleOnToggleAdditionalListener = async (
    wrapperId,
    repoId,
    enabled,
    additionalListener,
    onAssign
  ) => {
    try {
      await ec.request({
        method: 'PATCH',
        url: `/sidecars/${wrapperId}/repos/${repoId}`,
        data: {
          additionalListeners: [
            {
              ...additionalListener,
              Disabled: !enabled,
            },
          ],
        },
      })
      if (onAssign) onAssign()
    } catch (e) {
      const popupMessage = lodashGet(e, 'response.data.error.message', '')
      setPopup(popupTypes.ERROR, popupMessage)
    }
  }

  const trackRepo = async (wrapperId, values) => {
    try {
      return ec.post(`/sidecars/${wrapperId}/repos`, values)
    } catch (e) {
      console.log('useWrappers.trackRepo error: ', e.response)
      const popupMessage = lodashGet(e, 'response.data.error.message', '')
      setPopup(popupTypes.ERROR, popupMessage)
    }
  }

  return {
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
    handleChange,
    updateWrappers,
    handleOnUpdateTags,
    ec,
    wrapperHandlers: {
      handleOnToggleWrapper,
      trackRepo,
    },
    setSidecarPassthrough,
    repoHandlers: {
      handleOnToggleAdditionalListener,
    },
    user,
    lodashGet,
  }
}

export default useWrappers
