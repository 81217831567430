import { Stack, Typography } from '@mui/material'

import SetupWizardStep5Number from './SetupWizardStep5Number'
import SetupWizardStep5Row from './SetupWizardStep5Row'
import SetupWizardStep5Text from './SetupWizardStep5Text'

import { ReactComponent as DatabaseAccessDiagram } from './DatabaseAccessDiagram.svg'

const Step5 = () => {
  return (
    <Stack spacing={4} sx={{ alignItems: 'center' }}>
      <Typography variant="h2" sx={{ color: 'text.primary' }}>
        How Cyral Brokers User Access to Databases
      </Typography>

      <DatabaseAccessDiagram />

      <Stack spacing={1}>
        <SetupWizardStep5Row>
          <SetupWizardStep5Number>1.</SetupWizardStep5Number>
          <SetupWizardStep5Text>
            User fetches access token from Cyral Control Plane.
          </SetupWizardStep5Text>
        </SetupWizardStep5Row>

        <SetupWizardStep5Row>
          <SetupWizardStep5Number>2.</SetupWizardStep5Number>
          <Stack spacing={1}>
            <SetupWizardStep5Text>
              User authenticates to database proxy using token from step 1.
            </SetupWizardStep5Text>
            <SetupWizardStep5Text>
              User specifies the account they want to access in connection string.
            </SetupWizardStep5Text>
          </Stack>
        </SetupWizardStep5Row>

        <SetupWizardStep5Row>
          <SetupWizardStep5Number>3.</SetupWizardStep5Number>
          <SetupWizardStep5Text>
            Access Rules in Control Plane determine if user can access the account.
          </SetupWizardStep5Text>
        </SetupWizardStep5Row>

        <SetupWizardStep5Row>
          <SetupWizardStep5Number>4.</SetupWizardStep5Number>
          <SetupWizardStep5Text>
            Proxy fetches account credentials from secrets manager (
            <Typography
              component="span"
              sx={{
                color: 'text.primary',
                fontSize: '1.125rem',
                fontStyle: 'italic',
                fontWeight: 400,
                lineHeight: '28.8px',
              }}
            >
              configured by admin
            </Typography>
            ).
          </SetupWizardStep5Text>
        </SetupWizardStep5Row>

        <SetupWizardStep5Row>
          <SetupWizardStep5Number>5.</SetupWizardStep5Number>
          <SetupWizardStep5Text>
            Cyral subsequently acts as forward proxy for all database requests.
          </SetupWizardStep5Text>
        </SetupWizardStep5Row>
      </Stack>
    </Stack>
  )
}

export default Step5
