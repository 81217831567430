/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'

import { ButtonLoading } from '@jeeves/components/Loading'
import ConfirmDialog from '@jeeves/components/ConfirmDialog'
import { Button } from '@jeeves/components/Primitives'
import ExpressClient from '@jeeves/clients/express'
import { useAuth, usePopup, useAppConfig } from '@jeeves/hooks'
import { Grid, Typography } from '@material-ui/core'

export const ContactDialog = ({ open, setOpen }) => {
  const { user, getTokenSilently } = useAuth()
  const { license } = useAppConfig()
  const { showSuccess } = usePopup()

  const ec = new ExpressClient(getTokenSilently)

  const handleSubmit = async () => {
    try {
      await ec.changePlanRequest(user, license)
      showSuccess(`Thank you! Your request has been sent.`)
      setOpen(false)
    } catch (e) {}
  }

  return (
    <ConfirmDialog
      maxWidth="sm"
      fullWidth
      title="Request changes"
      open={open}
      setOpen={setOpen}
      confirmButton={
        <div css={{ position: 'relative' }}>
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            // disabled={isSubmitting}
          >
            Confirm
          </Button>
          {/* {isSubmitting && <ButtonLoading />} */}
        </div>
      }
    >
      <Typography>
        Our team will contact you at <strong>{user.email}</strong> to discuss your plan.
      </Typography>
    </ConfirmDialog>
  )
}
