import * as React from 'react'
import { parseISO, isAfter } from 'date-fns'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { formatLocalizedTimestamp } from '@jeeves/utils/datetime'

const GrantedApprovalDates = ({ approval }) => {
  const effectiveStartDate = approval.effectiveStartDate
  const displayedEffectiveStartDate = formatLocalizedTimestamp(effectiveStartDate, {
    includeTime: true,
  })

  // validUntil might not exist since it's nullable
  const displayedValidUntil = approval.validUntil
    ? formatLocalizedTimestamp(approval.validUntil, { includeTime: true })
    : 'No expiry'

  return (
    <Stack direction="row" spacing={2}>
      <Stack direction="row" spacing={0.5} sx={{ alignItems: 'center' }}>
        <Typography variant="h6" sx={{ color: 'text.secondary' }}>
          {isAfter(parseISO(effectiveStartDate), new Date())
            ? 'Access starts on:'
            : 'Access started on:'}
        </Typography>

        <Typography component="span" variant="h6" sx={{ color: 'text.primary' }}>
          {displayedEffectiveStartDate}
        </Typography>
      </Stack>

      <Stack direction="row" spacing={0.5} sx={{ alignItems: 'center' }}>
        <Typography variant="h6" sx={{ color: 'text.secondary' }}>
          Access ends on:
        </Typography>

        <Typography component="span" variant="h6" sx={{ color: 'text.primary' }}>
          {displayedValidUntil}
        </Typography>
      </Stack>
    </Stack>
  )
}

export default GrantedApprovalDates
