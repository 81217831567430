import { jsonToCSV } from 'react-papaparse'
import { useToast } from '@jeeves/new-components'
import { useLazyQuery, NetworkStatus } from '@apollo/client'
import { graphql } from '@jeeves/graphql'
import { EnhancedAuditLog, RepoEnhancedAuditLogsArgs } from '@jeeves/graphql/graphql'
import { useRepositoryDetailContext } from '@jeeves/pages/RepositoryDetail/contexts/RepositoryDetailContext'

const MAX_PAGE_SIZE = 2 ** 31 - 1

const ENHANCED_AUDIT_LOGS_EXPORT = graphql(`
  query EnhancedAuditLogsExport(
    $repoId: String!
    $filter: EnhancedAuditLogFilterInput
    $first: Int
  ) {
    repo(id: $repoId) {
      enhancedAuditLogs(filter: $filter, first: $first) {
        edges {
          node {
            timestamp
            description {
              text
            }
            action {
              category
              type
            }
            subject {
              ... on EnhancedAuditLogUser {
                username
              }
              ... on APIClient {
                id
              }
            }
          }
        }
      }
    }
  }
`)

interface Variables {
  variables: RepoEnhancedAuditLogsArgs
}

const useAuditReport = ({ variables }: Variables) => {
  const { repoId } = useRepositoryDetailContext()
  const { toast } = useToast()

  const [getLogs, { data, loading }] = useLazyQuery(ENHANCED_AUDIT_LOGS_EXPORT, {
    variables: {
      repoId,
      first: MAX_PAGE_SIZE,
      ...variables,
    },
    onCompleted: () => {
      exportAsCSV()
    },
    onError: () => {
      toast({
        variant: 'error',
        description: 'An error occurred while fetching logs to export. Please try again later.',
      })
    },
  })

  const shapeDataToExport = () => {
    const logs =
      data?.repo?.enhancedAuditLogs?.edges?.map(edge => edge?.node as EnhancedAuditLog) ?? []

    return logs.map(log => {
      return {
        timestamp: log.timestamp,
        category: log.action.category,
        type: log.action.type,
        actor: log.subject.__typename === 'APIClient' ? log.subject.id : log.subject.username,
        description: log.description.text,
      }
    })
  }

  const exportAsCSV = () => {
    const shapedData = shapeDataToExport()

    try {
      const csv = jsonToCSV(shapedData)
      const downloadLink = document.createElement('a')

      const blob = new Blob(['\ufeff', csv], { type: 'text/csv;charset=utf-8;' })

      downloadLink.href = URL.createObjectURL(blob)
      downloadLink.download = 'auditReport.csv'
      downloadLink.click()
    } catch (error) {
      toast({
        variant: 'error',
        description: 'An error ocurred when exporting the logs, please try again.',
      })
    }
  }

  return {
    getData: () => getLogs(),
    loading: loading,
  }
}

export default useAuditReport
