import * as React from 'react'
import { styled } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'

export const TableContainer = styled(
  'div',
  {}
)(({ theme }) => ({
  table: {
    borderCollapse: 'separate',
    borderSpacing: 0,
    width: '100%',
  },

  'td, th': {
    textAlign: 'left',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  th: {
    ...theme.typography.h5,
    color: theme.palette.text.secondary,

    backgroundColor: theme.palette.cyralColors.grey[100],

    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderTopStyle: 'solid',
    borderBottomStyle: 'solid',
    borderColor: theme.palette.cyralColors.grey[200],
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}))
