/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { useState } from 'react'
import { ButtonTag } from '../Tag'
import { TextField, Tooltip } from '@material-ui/core'
import { Clear } from '@material-ui/icons'

const KeyCodes = {
  comma: 44,
  space: 32,
  enter: 13,
}

export const TagList = ({ tags = [], onDelete, onAdd, ...props }) => {
  const [tagName, setTagName] = useState('')
  const [feedback, setFeedback] = useState('')

  const setFeedbackMessage = msg => {
    setFeedback(msg)

    setTimeout(() => {
      setFeedback('')
    }, 3000)
  }

  const forceAddTag = () => {
    const tag = tagName.trim()
    const isEmpty = !tag || tag === ''
    const tagRepeated = tags.indexOf(tag) >= 0

    if (tagRepeated || isEmpty) {
      return false
    }

    onAdd(tag)

    return setTagName('')
  }

  const isDelimiter = charCode => Object.values(KeyCodes).indexOf(charCode) >= 0

  const handleKeyPress = event => {
    const tag = tagName.trim()
    const tagRepeated = tags.indexOf(tag) >= 0
    const isEmpty = !tag || tag === ''
    const delimiter = isDelimiter(event.charCode)

    if (delimiter && !tagRepeated && !isEmpty) {
      onAdd(tag)
      return setTagName('')
    }

    if (delimiter && tagRepeated && !isEmpty) {
      return setFeedbackMessage('Cannot add repeated tag')
    }

    if (delimiter && isEmpty) {
      return setFeedbackMessage('Tag cannot be empty')
    }
  }

  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-end;
      `}
    >
      {tags &&
        tags.length > 0 &&
        tags.map(tag => (
          <ButtonTag
            key={`${tag}-tag`}
            onClick={() => onDelete(tag)}
            icon={<Clear css={{ fontSize: '18px' }} />}
            css={{ marginLeft: 0, marginRight: '10px' }}
          >
            {tag}
          </ButtonTag>
        ))}

      <Tooltip
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={feedback}
        open={feedback !== ''}
      >
        <TextField
          label="Add Tags"
          onChange={e => {
            if (!isDelimiter(e.target.value.charCodeAt(0))) {
              setTagName(e.target.value)
            }
          }}
          onBlur={() => forceAddTag()}
          value={tagName}
          onKeyPress={handleKeyPress}
          {...props}
        />
      </Tooltip>
    </div>
  )
}
