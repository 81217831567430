import { Box, Stack, Typography } from '@mui/material'
import { FragmentType, graphql, useFragment } from '@jeeves/graphql'

import BackButton from './BackButton'
import UpgradeInstance from './UpgradeInstance'
import InstanceInfo from './InstanceInfo'

const SidecarInstanceDetailsHeader_SidecarInstanceFragment = graphql(`
  fragment SidecarInstanceDetailsHeader_SidecarInstanceFragment on SidecarInstance {
    id
    ...UpgradeInstance_SidecarInstanceFragment
    ...InstanceInfo_SidecarInstanceFragment
  }
`)

const SidecarInstanceDetailsHeader_SidecarFragment = graphql(`
  fragment SidecarInstanceDetailsHeader_SidecarFragment on Sidecar {
    id
    ...UpgradeInstance_SidecarFragment
  }
`)

interface Props {
  sidecarInstance: FragmentType<typeof SidecarInstanceDetailsHeader_SidecarInstanceFragment>
  sidecar: FragmentType<typeof SidecarInstanceDetailsHeader_SidecarFragment>
}

const SidecarInstanceDetailsHeader = ({
  sidecarInstance: sidecarInstanceProp,
  sidecar: sidecarProp,
}: Props) => {
  const sidecarInstance = useFragment(
    SidecarInstanceDetailsHeader_SidecarInstanceFragment,
    sidecarInstanceProp
  )
  const sidecar = useFragment(SidecarInstanceDetailsHeader_SidecarFragment, sidecarProp)

  return (
    <Stack spacing={2}>
      <Box sx={{ alignSelf: 'flex-start' }}>
        <BackButton />
      </Box>

      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h3" sx={{ color: 'text.primary' }}>
          {sidecarInstance.id}
        </Typography>

        <UpgradeInstance sidecarInstance={sidecarInstance} sidecar={sidecar} />
      </Box>

      <InstanceInfo sidecarInstance={sidecarInstance} />
    </Stack>
  )
}

export default SidecarInstanceDetailsHeader
