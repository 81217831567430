import React, { useReducer, useContext } from 'react'

const PoliciesContext = React.createContext()

const initialPoliciesState = {
  policies: [],
  crudCount: 0,
  page: 0,
  rowsPerPage: 25,
  order: 'asc',
  orderBy: 'name',
  expanded: [],
  error: '',
}

const policiesReducer = (state, action) => {
  switch (action.type) {
    case 'handleRequestSort': {
      const { order, orderBy } = action.payload

      return {
        ...state,
        order,
        orderBy,
      }
    }
    case 'handleChangePage': {
      const { page } = action.payload

      return {
        ...state,
        page,
      }
    }
    case 'handleChangeRowsPerPage': {
      const { rowsPerPage } = action.payload

      return {
        ...state,
        rowsPerPage,
      }
    }
    case 'updatePolicies': {
      return {
        ...state,
        crudCount: state.crudCount + 1,
      }
    }
    case 'setPolicies': {
      const { policies } = action.payload

      return {
        ...state,
        policies,
      }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

const PoliciesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(policiesReducer, initialPoliciesState)
  const value = { state, dispatch }

  return <PoliciesContext.Provider value={value}>{children}</PoliciesContext.Provider>
}

const usePoliciesContext = () => {
  const context = useContext(PoliciesContext)
  if (context === undefined) {
    throw new Error('usePoliciesContext must be used within a PoliciesProvider')
  }
  return context
}

export { PoliciesProvider, usePoliciesContext }
