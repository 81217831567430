import React, { useState, useEffect } from 'react'
import { ThemeProvider } from '@mui/material/styles'
import { v5Theme } from '@jeeves/theme'
import {
  Box,
  FormControl,
  Stack,
  Typography,
  Collapse,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material'
import {
  Input as BaseInput,
  InputLabel,
  Button,
  InlineCode,
  CodeBlock,
} from '@jeeves/new-components'
import { ExpandButton } from '@jeeves/components/Table'
import { styled } from '@mui/system'
import { useForm, Controller } from 'react-hook-form'
import SCIM from '../scim'
import { useQuery, useMutation, gql } from '@apollo/client'
import { FETCH_INTEGRATIONS } from '../GenericSAML'
import { getDocsURL } from '@jeeves/utils/docsURL'

const UPDATE_SAML = gql`
  mutation UpdateGenericSAMLIntegration(
    $updateGenericSamlIntegrationId: ID!
    $genericSamlIntegration: UpdateGenericSAMLIntegrationInput!
  ) {
    updateGenericSAMLIntegration(
      id: $updateGenericSamlIntegrationId
      genericSAMLIntegration: $genericSamlIntegration
    ) {
      genericSAMLIntegration {
        id
      }
    }
  }
`

const Input = styled(BaseInput)({
  '& .MuiInputBase-input': { padding: '4px 8px' },
})

const AttributeInput = styled(Box)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.cyralColors.grey[200],
  borderRadius: '4px',
  backgroundColor: theme.palette.cyralColors.grey[100],
  color: theme.palette.cyralColors.grey[500],
  flexBasis: '75%',
  padding: '4px 8px',
}))

const Edit = ({
  createSCIMIntegration,
  createSCIMIntegrationData,
  updateSCIMIntegrationStatus,
  generateSCIMIntegrationCreds,
  handleSetEdit,
  integration,
  refetchIntegrations,
  ...props
}) => {
  const [showAdditionalSettings, setShowAdditionalSettings] = useState(false)
  const [certExpanded, setCertExpanded] = useState(false)
  const [fieldsDirty, setFieldsDirty] = useState(false)

  // SCIM configuration
  const [showSCIM, setShowSCIM] = useState(integration?.scimIntegration?.enabled)
  const [regenerateTokenClicked, setRegenerateTokenClicked] = useState(false)
  const [bearerToken, setBearerToken] = useState(null)
  const [expiresIn, setExpiresIn] = useState(null)
  const {
    register,
    control,
    handleSubmit,

    formState: { errors, isSubmitting, isSubmitSuccessful, isDirty: SAMLisDirty },
  } = useForm({
    defaultValues: {
      displayName: integration?.displayName,
      singleSignOnServiceURL: integration?.singleSignOnServiceURL,
      signingCertificate: integration?.signingCertificate,
      singleLogoutServiceURL: integration?.singleLogoutServiceURL,
      enableForceAuthentication: integration?.enableForceAuthentication,
    },
  })

  const [
    updateGenericSAMLIntegration,
    {
      data: updateGenericSAMLIntegrationData,
      loading: updateGenericSAMLIntegrationLoading,
      error: updateGenericSAMLIntegrationError,
    },
  ] = useMutation(UPDATE_SAML, { refetchQueries: [FETCH_INTEGRATIONS] })

  const handleCancel = () => {
    handleSetEdit(false)
    if (!showSCIMSame()) {
      refetchIntegrations()
    }
  }

  const downloadFile = () => {
    const element = document.createElement('a')
    // eslint-disable-next-line
    const file = new Blob([integration?.spMetadataXMLDocument], { type: 'text/xml' })
    element.href = URL.createObjectURL(file)
    element.download = 'cyral-sp-metadata.xml'
    document.body.appendChild(element)
    element.click()
  }

  const updateSAMLInputs = async ({
    displayName,
    singleSignOnServiceURL,
    signingCertificate,
    singleLogoutServiceURL,
    enableForceAuthentication,
  }) => {
    try {
      await updateGenericSAMLIntegration({
        variables: {
          updateGenericSamlIntegrationId: integration.id,
          genericSamlIntegration: {
            displayName,
            singleSignOnServiceURL,
            signingCertificate,
            enableForceAuthentication,
            singleLogoutServiceURL,
          },
        },
      })
    } catch (e) {
      throw e
    }
  }

  const showSCIMSame = () => {
    return (
      (showSCIM && integration?.scimIntegration?.enabled) ||
      (!showSCIM && !integration?.scimIntegration?.enabled)
    )
  }

  useEffect(() => {
    setFieldsDirty(SAMLisDirty || !showSCIMSame() || (showSCIM && regenerateTokenClicked))
  }, [showSCIM, regenerateTokenClicked, SAMLisDirty])

  // primary handler for save button
  const handleSave = async ({
    displayName,
    singleSignOnServiceURL,
    signingCertificate,
    singleLogoutServiceURL,
    enableForceAuthentication,
  }) => {
    if (SAMLisDirty) {
      await updateSAMLInputs({
        displayName,
        singleSignOnServiceURL,
        signingCertificate,
        singleLogoutServiceURL,
        enableForceAuthentication,
      })
    }

    // creating SCIM && Generic SAML without scim
    if (!integration?.scimIntegration && showSCIM) {
      try {
        const data = await createSCIMIntegration({
          variables: {
            scimIntegration: { IDPID: integration.id, enabled: true, authMode: 'HTTPHeader' },
          },
        })

        setBearerToken(data?.data?.createSCIMIntegration?.authSecret)
        setExpiresIn(data?.data?.createSCIMIntegration?.authSecretExpiry)
      } catch (e) {
        console.error(e)
      }
    }

    // enabling SCIM && Generic SAML with disabled SCIM
    if (!integration?.scimIntegration?.enabled && showSCIM) {
      try {
        await updateSCIMIntegrationStatus({ variables: { IDPID: integration.id, enabled: true } })
        // refetchIntegrations()
      } catch (e) {
        console.error(e)
      }
    }

    //disabling SCIM && Generic SAML with enabled SCIM
    if (integration?.scimIntegration?.enabled == true && !showSCIM) {
      try {
        await updateSCIMIntegrationStatus({
          variables: { IDPID: integration.id, enabled: false },
        })
        // refetchIntegrations()
      } catch (e) {
        console.error(e)
      }
    }

    // regenerate bearer token
    if (regenerateTokenClicked) {
      try {
        const data = await generateSCIMIntegrationCreds({ variables: { IDPID: integration.id } })
        setBearerToken(data?.data?.generateSCIMIntegrationCreds?.authSecret)
        setExpiresIn(data?.data?.generateSCIMIntegrationCreds?.authSecretExpiry)
      } catch (e) {
        console.error(e)
      }
    }
  }

  const onSubmit = async ({
    displayName,
    singleSignOnServiceURL,
    signingCertificate,
    singleLogoutServiceURL,
    enableForceAuthentication,
  }) => {
    try {
      await handleSave({
        displayName,
        singleSignOnServiceURL,
        signingCertificate,
        singleLogoutServiceURL,
        enableForceAuthentication,
      })
      setFieldsDirty(false)
    } catch (e) {}
  }

  return (
    <ThemeProvider theme={v5Theme}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <Stack spacing={4}>
            <Stack>
              <Typography sx={{ color: 'text.secondary' }} variant="h6">
                Display Name
              </Typography>
              <Input inputProps={{ ...register('displayName') }}></Input>
            </Stack>

            <Stack spacing={2}>
              <Typography variant="body1" sx={{ color: 'cyralColors.grey.500' }}>
                Copy the SP metadata URL and use it to configure Cyral as a service provider in your
                IdP.
              </Typography>
              <CodeBlock>{integration.spMetadataURL}</CodeBlock>
              <Typography variant="body2" sx={{ color: 'cyralColors.grey.500' }}>
                Alternatively, you can{' '}
                <Typography
                  sx={{ cursor: 'pointer', color: 'cyralColors.primary.300' }}
                  onClick={downloadFile}
                  display="inline"
                  variant="body2"
                >
                  download the SP metadata file
                </Typography>
              </Typography>
            </Stack>

            <Stack spacing={1}>
              <Typography sx={{ color: 'text.secondary' }} variant="h6">
                Attribute Names in SAML Assertion
              </Typography>
              <Typography sx={{ color: 'text.secondary', display: 'inline' }} variant="body2">
                Your attribute names here should match the ones in your IdP. The nameID format for
                all attributes should be <InlineCode>unspecified</InlineCode>.
              </Typography>

              <Stack spacing={2.5}>
                <Stack direction="row" sx={{ alignItems: 'center' }}>
                  <Typography variant="body2" sx={{ color: 'text.primary', flexBasis: '25%' }}>
                    First Name
                  </Typography>
                  <AttributeInput>
                    <Typography variant="body2" sx={{ color: 'text.primary' }}>
                      {integration?.firstName}
                    </Typography>
                  </AttributeInput>
                </Stack>
                <Stack direction="row" sx={{ alignItems: 'center' }}>
                  <Typography variant="body2" sx={{ color: 'text.primary', flexBasis: '25%' }}>
                    Last Name
                  </Typography>
                  <AttributeInput>
                    <Typography variant="body2" sx={{ color: 'text.primary' }}>
                      {integration?.lastName}
                    </Typography>
                  </AttributeInput>
                </Stack>
                <Stack direction="row" sx={{ alignItems: 'center' }}>
                  <Typography variant="body2" sx={{ color: 'text.primary', flexBasis: '25%' }}>
                    Email
                  </Typography>
                  <AttributeInput>
                    <Typography variant="body2" sx={{ color: 'text.primary' }}>
                      {integration?.email}
                    </Typography>
                  </AttributeInput>
                </Stack>
                <Stack direction="row" sx={{ alignItems: 'center' }}>
                  <Typography variant="body2" sx={{ color: 'text.primary', flexBasis: '25%' }}>
                    SSO Groups
                  </Typography>
                  <AttributeInput>
                    <Typography variant="body2" sx={{ color: 'text.primary' }}>
                      {integration?.groups}
                    </Typography>
                  </AttributeInput>
                </Stack>
              </Stack>
            </Stack>

            <FormControl variant="standard">
              <InputLabel htmlFor="sso-url">Single Sign-On Service URL</InputLabel>
              <Input id="sso-url" inputProps={{ ...register('singleSignOnServiceURL') }}></Input>
            </FormControl>

            <Box
              sx={{
                border: '1px solid',
                borderColor: 'cyralColors.grey.200',
                borderRadius: 0.5,
                // padding: 1.5, 1,
                py: 1,
                px: 1.5,
              }}
            >
              <Stack spacing={0}>
                <Stack
                  direction="row"
                  sx={{ justifyContent: 'space-between', alignItems: 'center' }}
                >
                  <Typography sx={{ color: 'text.secondary' }} variant="h6">
                    IDP x.509 Signing Certificate
                  </Typography>
                  <ExpandButton
                    expanded={certExpanded}
                    onClick={() => setCertExpanded(!certExpanded)}
                  />
                </Stack>
                <Collapse in={certExpanded} unmountOnExit>
                  <Stack>
                    <Typography sx={{ color: 'text.secondary' }} variant="body2">
                      The signing certificate will always require signature validation.
                    </Typography>
                    <Stack>
                      <Input
                        multiline
                        rows={8}
                        inputProps={{ ...register('signingCertificate') }}
                      ></Input>
                    </Stack>
                  </Stack>
                </Collapse>
              </Stack>
            </Box>

            <Box
              sx={{
                border: '1px solid',
                borderColor: 'cyralColors.grey.200',
                borderRadius: 0.5,
                py: 1,
                px: 1.5,
              }}
            >
              <Stack spacing={2}>
                <Stack
                  direction="row"
                  sx={{ justifyContent: 'space-between', alignItems: 'center' }}
                >
                  <Typography sx={{ color: 'text.secondary' }} variant="h6">
                    Additional SAML Settings
                  </Typography>
                  <ExpandButton
                    expanded={showAdditionalSettings}
                    onClick={() => setShowAdditionalSettings(!showAdditionalSettings)}
                  />
                </Stack>
                <Collapse in={showAdditionalSettings} unmountOnExit>
                  <Stack spacing={2}>
                    <FormControl variant="standard">
                      <InputLabel htmlFor="slo-url"> Single Log-Out Service URL</InputLabel>
                      <Input
                        id="slo-url"
                        inputProps={{ ...register('singleLogoutServiceURL') }}
                      ></Input>
                    </FormControl>

                    <Controller
                      name="enableIdPInitiatedLogin"
                      control={control}
                      render={({ field: { onChange, onBlur, value, name, ref } }) => (
                        <FormGroup>
                          <FormControlLabel
                            control={<Checkbox name={name} onChange={onChange} checked={!value} />}
                            label="Disable Force Authentication"
                          />
                        </FormGroup>
                      )}
                    />
                  </Stack>
                </Collapse>
              </Stack>
            </Box>

            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showSCIM}
                    onChange={() => setShowSCIM(!showSCIM)}
                    value="showSCIM"
                    color="primary"
                  />
                }
                label={
                  <Typography>
                    Enable service account resolution.{' '}
                    <a
                      href={getDocsURL({
                        docsPath:
                          '/integrations/authentication/idp/generic#scim-with-a-saml-20-identity-provider',
                      })}
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: 'none', color: '#26459E', fontWeight: 600 }}
                    >
                      Learn More
                    </a>
                  </Typography>
                }
              />
            </FormGroup>
            {showSCIM && (
              <SCIM
                expiresIn={expiresIn}
                bearerToken={bearerToken}
                integration={integration}
                regenerateTokenClicked={regenerateTokenClicked}
                setRegenerateTokenClicked={setRegenerateTokenClicked}
              ></SCIM>
            )}

            <Stack direction="row" sx={{ justifyContent: 'flex-end', gap: 2.5 }}>
              <Button variant="text" onClick={handleCancel}>
                Cancel
              </Button>
              <Button
                variant="contained"
                type="submit"
                loading={isSubmitting}
                disabled={!fieldsDirty}
              >
                Save
              </Button>
            </Stack>
          </Stack>
        </Box>
      </form>
    </ThemeProvider>
  )
}

export default Edit
