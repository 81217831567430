import { Box, Stack, Typography } from '@mui/material'

import { FragmentType, graphql, useFragment } from '@jeeves/graphql'
import { Accordion, AccordionDetails, AccordionSummary } from '@jeeves/new-components'

import CaCertificate from './CaCertificate'
import CertificatesUnavailable from './CertificatesUnavailable'
import TlsCertificate from './TlsCertificate'

const Certificates_SidecarInstanceFragment = graphql(`
  fragment Certificates_SidecarInstanceFragment on SidecarInstance {
    id
    certificates {
      tls {
        __typename
      }
      ca {
        __typename
      }
    }
    ...TlsCertificate_SidecarInstanceFragment
    ...CaCertificate_SidecarInstanceFragment
    ...CertificatesUnavailable_SidecarInstanceFragment
  }
`)

interface Props {
  sidecarInstance: FragmentType<typeof Certificates_SidecarInstanceFragment>
}

const Certificates = ({ sidecarInstance: sidecarInstanceProp }: Props) => {
  const sidecarInstance = useFragment(Certificates_SidecarInstanceFragment, sidecarInstanceProp)

  const tlsCertificateAvailable = Boolean(sidecarInstance.certificates.tls)
  const caCertificateAvailable = Boolean(sidecarInstance.certificates.ca)
  const certificatesAvailable = tlsCertificateAvailable || caCertificateAvailable

  return (
    <Box>
      <Accordion>
        <AccordionSummary sx={{ p: 4 }}>
          <Typography variant="h4" sx={{ color: 'text.primary' }}>
            Certificates
          </Typography>
        </AccordionSummary>

        <AccordionDetails sx={{ px: 4, pb: 4, pt: 0 }}>
          {certificatesAvailable ? (
            <Stack spacing={4}>
              <TlsCertificate sidecarInstance={sidecarInstance} />

              <CaCertificate sidecarInstance={sidecarInstance} />
            </Stack>
          ) : (
            <CertificatesUnavailable sidecarInstance={sidecarInstance} />
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

export default Certificates
