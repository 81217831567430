import React, { forwardRef } from 'react'

import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'

export const StyledTag = styled(
  forwardRef((props, ref) => <Stack ref={ref} {...props} direction="row" spacing={0.25} />),
  {
    shouldForwardProp: prop => prop !== 'size' && prop !== 'color' && prop !== 'onRemove',
  }
)(({ theme, size, color, onRemove }) => ({
  backgroundColor: theme.palette.cyralColors?.[color]?.[100],
  color: theme.palette.cyralColors?.[color]?.[500],

  borderRadius: theme.radii.sm,
  display: 'inline-flex',
  maxWidth: '250px',

  ...(size === 'sm' && {
    padding: theme.spacing(0.25, 1),
  }),

  ...(size === 'md' && {
    padding: theme.spacing(0.5, 1),
  }),

  ...(onRemove && {
    paddingRight: theme.spacing(0.5),

    '& .MuiSvgIcon-root': {
      // TODO: Implement change color of icon on hover
      color: theme.palette.cyralColors?.[color]?.[300],
      fontSize: '1rem',
      cursor: 'pointer',
    },
  }),

  ...(color === 'white' && {
    backgroundColor: 'white',
    color: theme.palette.cyralColors.grey[500],
    border: `1px solid ${theme.palette.cyralColors.grey[500]}`,
    
  }),
}))
