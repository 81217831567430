import { Box, Stack, Typography } from '@mui/material'
import Tag from '@jeeves/pages/RepositoryDetail/Tabs/Policies/components/Tag'
import { usePolicyDetails } from '@jeeves/pages/RepositoryDetail/Tabs/Policies/views/PolicyDetails/contexts/PolicyDetailsContext'

const PolicyTypes = () => {
  const { policy } = usePolicyDetails()

  return (
    <Stack spacing={1}>
      <Typography variant="h6" sx={{ color: 'text.primary' }}>
        Policy Types ({policy?.tags?.length || 0})
      </Typography>
      <Box
        gap={1}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
        }}
      >
        {policy?.tags?.map((tag, index) => (
          <Tag key={index} text={tag} />
        ))}
      </Box>
    </Stack>
  )
}

export default PolicyTypes
