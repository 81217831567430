import * as React from 'react'

const SidecarDetailContext = React.createContext()

export const SidecarDetailProvider = ({ children, sidecarId }) => {
  const contextValue = {
    sidecarId,
  }

  return (
    <SidecarDetailContext.Provider value={contextValue}>{children}</SidecarDetailContext.Provider>
  )
}

export const useSidecarDetailContext = () => {
  const context = React.useContext(SidecarDetailContext)
  if (context === undefined) {
    throw new Error('useSidecarDetailContext must be used within a SidecarDetailProvider')
  }
  return context
}
