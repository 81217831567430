import * as React from 'react'
import Stack from '@mui/material/Stack'
import { DataLabelCard } from './DataLabelCard/DataLabelCard'
import { CreateLabel } from './Dialogs'

export const CustomLabels = ({ dataLabels }) => {
  return (
    <Stack spacing={5}>
      <Stack direction="row" sx={{ justifyContent: 'flex-end' }}>
        <CreateLabel />
      </Stack>

      <Stack spacing={3}>
        {dataLabels.map(dataLabel => (
          <DataLabelCard key={dataLabel.name} dataLabel={dataLabel} />
        ))}
      </Stack>
    </Stack>
  )
}
