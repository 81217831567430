import { useEffect, useRef } from 'react'

function useInterval(callback, delay, immediate = true) {
  const savedCallback = useRef(callback)
  const isMounted = useRef(true)

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    if (delay === null || delay === 0) {
      return // Refresh disabled
    }
    if (immediate) {
      // Run function imediately if is the first time...
      savedCallback.current()
    }
    const id = newTimer()
    return () => {
      isMounted.current = false
      id && clearTimeout(id)
    }
  }, [delay]) // eslint-disable-line

  function tick() {
    if (delay === null || delay === 0) {
      return // Refresh disabled
    }
    const ret = savedCallback.current()
    if (!(ret instanceof Promise)) {
      // Promise must have already resolved
      newTimer()
      return
    }
    // Wait for promise to resolve
    ret.then(() => {
      newTimer()
    })
  }

  function newTimer() {
    if (isMounted.current) {
      return setTimeout(tick, delay)
    }
  }
}
export default useInterval
