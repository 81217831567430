import React from 'react'
import Stack from '@mui/material/Stack'
import MuiDivider from '@mui/material/Divider'
import MuiTabs from '@mui/material/Tabs'
import MuiTab from '@mui/material/Tab'
import { styled } from '@mui/material/styles'

/* == CARD == */

const Card = styled(
  props => (
    <Stack
      {...props}
      divider={
        <MuiDivider
          sx={{
            borderBottomColor: 'cyralColors.grey.200',
          }}
        />
      }
    />
  ),
  {}
)(({ theme }) => ({
  overflow: 'hidden',
  backgroundColor: theme.palette.common.white,
  border: `1px solid ${theme.palette.cyralColors.grey[200]}`,
  borderRadius: theme.radii.xl,
  minHeight: '550px',
}))

const Header = styled(
  'div',
  {}
)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(3, 4),
}))

const Body = styled('div')(({ theme }) => ({
  flex: '1',
  display: 'flex',
  // justifyContent: 'space-between',
  // padding: theme.spacing(4, 5),
}))

Card.Header = Header
Card.Body = Body

/* == TABS == */

const Tabs = styled(MuiTabs)({
  minHeight: 0,
  alignItems: 'center',
  '& .MuiTabs-indicator': {
    visibility: 'hidden',
  },
})

const Tab = styled(props => <MuiTab disableRipple {...props} />)(({ theme }) => ({
  ...theme.typography.h5,
  padding: theme.spacing(1, 2),
  minHeight: 0,

  // minWidth: 0,
  // [theme.breakpoints.up('sm')]: {
  //   minWidth: 0,
  // },
  // marginRight: theme.spacing(1),
  '&:hover': {
    color: theme.palette.text.primary,
  },
  '&.Mui-selected': {
    backgroundColor: theme.palette.cyralColors.primary[100],
    borderRadius: theme.radii.base,

    // [`&:hover, &.Mui-focusVisible`]: {
    [`&:hover`]: {
      color: theme.palette.primary.main,
    },
  },
  // '&.Mui-focusVisible': {
  //   backgroundColor: '#d1eaff',
  // },
}))

export { Card, Tabs, Tab }
