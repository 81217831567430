import { Stack } from '@mui/material'

import PolicyRule from '../PolicyRule'
import useDataFirewallSummary from './useDataFirewallSummary'

const DataFirewall = () => {
  const { excludedIdentities, dataLabels, dataset, restriction } = useDataFirewallSummary()

  return (
    <Stack spacing={1}>
      <PolicyRule title="When anyone except the following users" content={excludedIdentities} />
      <PolicyRule title="reads data labeled" content={dataLabels} />
      <PolicyRule title="from" content={dataset} />
      <PolicyRule title="the following filter condition is applied" content={restriction} />
    </Stack>
  )
}

export default DataFirewall
