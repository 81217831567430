import * as React from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

import { InlineCode, CodeBlock } from '@jeeves/new-components'

const TableauConfiguration = ({
  checked,
  handleChange,
  serviceAccountName,
  host,
  port,
  repoType,
}) => {
  return (
    <Stack spacing={4}>
      <Stack spacing={2}>
        <Typography variant="h4" sx={{ color: 'text.primary' }}>
          Configure your database connection in your Tableau console:
        </Typography>

        <Typography variant="body1" sx={{ color: 'text.primary' }}>
          For your data source(s) that connect to this repository using{' '}
          <InlineCode noWrap>{serviceAccountName}</InlineCode>, edit the connections as instructed
          below.
        </Typography>

        <ConfigurationSteps host={host} port={port} repoType={repoType} />
      </Stack>

      <FormControlLabel
        checked={checked}
        onChange={handleChange}
        control={<Checkbox disableRipple />}
        label="I have configured my Tableau data source(s) as described above."
        componentsProps={{
          typography: {
            variant: 'h4',
            sx: {
              color: 'text.primary',
            },
          },
        }}
      />
    </Stack>
  )
}

const ConfigurationSteps = ({ host, port, repoType }) => {
  // See doc below for more information on the initial SQL required for Tableau identity attribution
  // https://cyralinc.atlassian.net/wiki/spaces/EN/pages/1689321492/Tableau+configuration+for+Identity+Attribution
  const getInitialSQL = repoType => {
    switch (repoType) {
      case 'snowflake':
        return `SET "cyral.enduser" = [TableauServerUser]`
      case 'postgresql':
      case 'redshift':
      case 'denodo':
      case 'mysql':
      case 'sqlserver':
        return `SELECT 'cyral.enduser', [TableauServerUser]`
      default:
        return ''
    }
  }

  return (
    <Stack spacing={2} component="ol">
      <Typography component="li" variant="body1" sx={{ color: 'text.primary' }}>
        <Stack spacing={2}>
          <Box>
            In the <Box component="strong">General</Box> tab, set{' '}
            <Box component="strong">Server</Box> and <Box component="strong">Port</Box> as follows:
          </Box>
          <Box display="grid" gridTemplateColumns="repeat(4, 1fr)" gap={2}>
            <Stack spacing={1} gridColumn="span 3">
              <Typography variant="h6" sx={{ color: 'text.secondary' }}>
                Server
              </Typography>
              <CodeBlock>{host}</CodeBlock>
            </Stack>
            <Stack spacing={1} gridColumn="span 1">
              <Typography variant="h6" sx={{ color: 'text.secondary' }}>
                Port
              </Typography>
              <CodeBlock>{port}</CodeBlock>
            </Stack>
          </Box>
        </Stack>
      </Typography>

      <Typography component="li" variant="body1" sx={{ color: 'text.primary' }}>
        <Stack spacing={2}>
          <Box>
            Switch to the <Box component="strong">Initial SQL</Box> tab and insert the following as
            the first query:
          </Box>
          <Stack spacing={1}>
            <Typography variant="h6" sx={{ color: 'text.secondary' }}>
              Initial SQL
            </Typography>
            <CodeBlock>{getInitialSQL(repoType)}</CodeBlock>
          </Stack>
        </Stack>
      </Typography>

      <Typography component="li" variant="body1" sx={{ color: 'text.primary' }}>
        Click <Box component="strong">Sign In</Box> to complete the connection configuration.
      </Typography>

      <Typography component="li" variant="body1" sx={{ color: 'text.primary' }}>
        Publish your data source to apply your configuration changes.
      </Typography>
    </Stack>
  )
}

export default TableauConfiguration
