/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { IconButton, Tooltip } from '@material-ui/core/'
import EditIcon from '@material-ui/icons/Edit'
import GetAppIcon from '@material-ui/icons/GetApp'
import { useAuth } from '@jeeves/components/Auth'
import { Button } from '@jeeves/components/Primitives'
import useForgeRock from '../hooks/useForgeRock'
import {
  DeleteIntegrationButton,
  IntegrationHeader,
  IntegrationTitle,
  Label,
  LabelValuePair,
  Value,
} from '../../../primitives'
import { get } from 'lodash'

export default ({ setEditing, onDelete, integration }) => {
  const { hasPermission } = useAuth()
  const { getSAMLMetadata } = useForgeRock()
  // const { idpDomains, name, signInEndpoint } = integration.integration.samlp

  // const accountNameRegex = /:\/\/(?<accountName>.*).okta.com+/g
  // const accountName = get(accountNameRegex.exec(signInEndpoint), 'groups.accountName')

  return (
    <div>
      <IntegrationHeader>
        <IntegrationTitle gutterBottom component="h2">
          {integration.integration.samlSetting.samlp.displayName}
        </IntegrationTitle>
        <div css={{ flexGrow: 1 }} />

        <IconButton
          onClick={() => setEditing(true)}
          disabled={!hasPermission('integrations:update')}
        >
          <EditIcon />
        </IconButton>
        <DeleteIntegrationButton
          onDelete={onDelete}
          disabled={!hasPermission('integrations:delete')}
        />
      </IntegrationHeader>

      <LabelValuePair>
        <Label>Single Sign-On Endpoint</Label>
        <Value>{integration.integration.samlSetting.samlp.config.singleSignOnServiceURL}</Value>
      </LabelValuePair>
      <Tooltip
        title={
          'Export the SAML Service Provider metadata, which contains the entity descriptor for Cyral.'
        }
        placement="left"
      >
        <Button
          variant="contained"
          onClick={() => getSAMLMetadata(integration.integration.samlSetting.samlp.alias)}
          disabled={!hasPermission('integrations:update')}
          css={{ marginTop: '2.5rem' }}
        >
          Export SP Metadata
        </Button>
      </Tooltip>
      {/* <LabelValuePair>
        <Label>Email Domains</Label>
        <Value>{idpDomains.join(', ')}</Value>
      </LabelValuePair> */}
    </div>
  )
}
