import React from 'react'
import { gql } from '@apollo/client'
import { ThemeProvider } from '@mui/material/styles'
import { v5Theme } from '@jeeves/theme'
import { Box, Stack, Typography, Menu, MenuItem, Popper } from '@mui/material'

import { SidecarIcon } from '@jeeves/components/Navigation/components/SidecarIcon'
import { Link } from 'react-router-dom'

import { ServiceIcon } from '@jeeves/components/ServiceIcon'
import { isMongoDbClusterRepo, isStandaloneRepo } from '@jeeves/graphql/utils'
import { Tag, Button } from '@jeeves/new-components'
import Edit from '../Edit'
import Delete from '../Delete'
import { Settings } from './Settings'
import { getProperties } from './helpers'
import { EditRepo_repoFragment } from '../Edit/Edit'

export const RepositoryHeader_repoFragment = gql`
  fragment RepositoryHeader_repo on Repo {
    ... on MongoDBCluster {
      clusterSpecification {
        ... on StaticNodeSpecification {
          staticNodes {
            host
            port
          }
        }
        ... on SRVRecordSpecification {
          SRVRecord
        }
      }
    }
    ... on StandaloneRepo {
      endpoint {
        host
        port
      }
    }
    ...EditRepo_repo
  }
  ${EditRepo_repoFragment}
`

export const RepositoryHeader = ({ repo }) => {
  // Sidecar Menu
  const [sidecarListEl, setSidecarListEl] = React.useState(null)
  const sidecarListOpen = Boolean(sidecarListEl)
  const handleClickSidecarList = e => {
    setSidecarListEl(e.currentTarget)
  }
  const handleCloseSidecarList = e => {
    setSidecarListEl(null)
  }

  const displayedSidecar = repo.sidecars?.[0]
  const overflowSidecars = repo?.sidecars?.slice(1)

  // Additional tags popper
  const [popperEl, setPopperEl] = React.useState(null)
  const handlePopoverOpen = e => {
    setPopperEl(e.currentTarget)
  }

  const handlePopoverClose = () => {
    setPopperEl(null)
  }

  const overflowTagsPopperOpen = Boolean(popperEl)
  const id = overflowTagsPopperOpen ? 'simple-popper' : undefined

  const displayedTags = Array.isArray(repo?.tags) ? repo.tags.slice(0, 5) : []
  const overflowTags = Array.isArray(repo?.tags) ? repo.tags.slice(5) : []

  // Edit and delete
  const [editOpen, setEditOpen] = React.useState(false)
  const [deleteOpen, setDeleteOpen] = React.useState(false)

  const handleOpenEdit = () => {
    setEditOpen(true)
  }

  const handleCloseEdit = () => {
    setEditOpen(false)
  }

  const handleOpenDelete = () => {
    setDeleteOpen(true)
  }

  const handleCloseDelete = () => {
    setDeleteOpen(false)
  }

  const getMongoDBClusterRepoEndpoint = () => {
    const { clusterSpecification } = repo
    if (clusterSpecification.__typename === 'StaticNodeSpecification') {
      const firstNode = clusterSpecification.staticNodes[0]
      return `${firstNode.host}:${firstNode.port}`
    }
    return clusterSpecification.SRVRecord
  }

  const getStandaloneRepoEndpoint = () => {
    const { endpoint } = repo
    return `${endpoint.host}:${endpoint.port}`
  }

  const getEndpoint = () => {
    const isRepoMongoDbClusterRepo = isMongoDbClusterRepo(repo)
    if (isRepoMongoDbClusterRepo) {
      return getMongoDBClusterRepoEndpoint()
    }

    const isRepoStandaloneRepo = isStandaloneRepo(repo)
    if (isRepoStandaloneRepo) {
      return getStandaloneRepoEndpoint()
    }

    return ''
  }

  return (
    <ThemeProvider theme={v5Theme}>
      <Box sx={{ px: 3, pt: 3, pb: 8 }}>
        <Stack direction="row" sx={{ justifyContent: 'space-between', minHeight: '100px' }}>
          <Stack direction="row" spacing={2}>
            <ServiceIcon
              style={{ maxWidth: '60px', alignSelf: 'start' }}
              type={repo?.type}
            ></ServiceIcon>
            <Stack spacing={2}>
              <Stack direction="row" spacing={2}>
                <Typography variant="h3" sx={{ color: 'text.primary' }}>
                  {repo?.name}
                </Typography>
                <Box>
                  <Stack direction="row" spacing={1} sx={{ mt: 0.75 }}>
                    {getProperties(repo).map(tag => (
                      <Tag
                        key={tag}
                        sx={{ maxWidth: '150px', borderColor: 'cyralColors.grey.300' }}
                        color="white"
                      >
                        {tag}
                      </Tag>
                    ))}
                  </Stack>
                </Box>
              </Stack>
              <Stack direction="row">
                <Typography variant="code" sx={{ color: 'text.primary' }}>
                  {getEndpoint()}
                </Typography>
              </Stack>
              {displayedTags.length > 0 && (
                <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
                  <Stack direction="row" spacing={1}>
                    {displayedTags.map((tag, idx) => (
                      <Tag key={tag} sx={{ maxWidth: '150px' }}>
                        {tag}
                      </Tag>
                    ))}
                    {overflowTags.length > 0 && (
                      <Tag
                        color="grey"
                        onMouseEnter={handlePopoverOpen}
                        onMouseLeave={handlePopoverClose}
                      >{`+ ${overflowTags.length}`}</Tag>
                    )}
                  </Stack>
                  <Popper id={id} open={overflowTagsPopperOpen} anchorEl={popperEl}>
                    <Box
                      sx={{
                        border: 1,
                        borderRadius: theme => theme.radii.base,
                        borderColor: 'cyralColors.grey.200',
                        p: 1,
                        backgroundColor: 'common.white',
                      }}
                    >
                      <Stack direction="row" sx={{ gap: 1, flexWrap: 'wrap' }}>
                        {overflowTags.map(tag => (
                          <Tag key={tag}>{tag}</Tag>
                        ))}
                      </Stack>
                    </Box>
                  </Popper>
                </Stack>
              )}
            </Stack>
          </Stack>

          <Stack lastCol sx={{ justifyContent: 'space-between', alignItems: 'flex-end' }}>
            <Stack sx={{ alignSelf: 'flex-end' }}>
              <Settings handleOpenDelete={handleOpenDelete} handleOpenEdit={handleOpenEdit} />
            </Stack>
            {displayedSidecar && (
              <Stack spacing={1}>
                <Stack direction="row" spacing={0.5} sx={{ alignItems: 'center' }}>
                  <SidecarIcon color="primary" />
                  <Link to={`/sidecars/${displayedSidecar?.id}`} style={{ textDecoration: 'none' }}>
                    <Typography variant="code" sx={{ color: 'primary.main' }}>
                      {displayedSidecar?.name}
                    </Typography>
                  </Link>
                </Stack>

                {overflowSidecars.length > 0 && (
                  <Stack sx={{ alignItems: 'flex-end' }}>
                    <Typography
                      onClick={handleClickSidecarList}
                      variant="code"
                      sx={{ color: 'text.secondary', cursor: 'pointer' }}
                    >
                      {`+ ${overflowSidecars.length} more`}
                    </Typography>

                    <Menu
                      open={sidecarListOpen}
                      anchorEl={sidecarListEl}
                      onClose={handleCloseSidecarList}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                    >
                      {overflowSidecars.map(overflowSidecar => (
                        <MenuItem component={Link} to={`/sidecars/${overflowSidecar?.id}`}>
                          <Typography variant="code" sx={{ color: 'text.primary' }}>
                            {overflowSidecar?.name}
                          </Typography>
                        </MenuItem>
                      ))}
                    </Menu>
                  </Stack>
                )}
              </Stack>
            )}
          </Stack>
        </Stack>

        {<Edit open={editOpen} repo={repo} onClose={handleCloseEdit}></Edit>}
        {<Delete open={deleteOpen} repo={repo} onClose={handleCloseDelete}></Delete>}
      </Box>
    </ThemeProvider>
  )
}
