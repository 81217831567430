import styled from '@emotion/styled'

export * from './ActionButton'
export * from './Button'
export * from './Dialog'
export * from './Flex'
export * from './Paper'
export * from './Select'
export * from './StatusIcon'
export * from './TableLink'
export * from './Tabs'
export { default as TextField } from './TextField'
export * from './EllipsisTypography'

export const FlexGrow = styled.div`
  flex-grow: 1;
`
