import { ReactNode } from 'react'
import { Typography } from '@mui/material'

const SetupWizardStep5Number = ({ children }: { children: ReactNode }) => (
  <Typography
    sx={{
      color: 'primary.main',
      fontSize: '1.125rem',
      fontWeight: 600,
      lineHeight: '28.8px',
    }}
  >
    {children}
  </Typography>
)

export default SetupWizardStep5Number
