import { FragmentType, graphql, useFragment } from '@jeeves/graphql'
import { Alert, Stack, Typography } from '@mui/material'
import { satisfies } from 'semver'

import CertificateNotFound from './CertificateNotFound'

const MIN_SIDECAR_INSTANCE_VERSION = '4.11.0'

const CertificatesUnavailable_SidecarInstanceFragment = graphql(`
  fragment CertificatesUnavailable_SidecarInstanceFragment on SidecarInstance {
    id
    version
  }
`)

interface Props {
  sidecarInstance: FragmentType<typeof CertificatesUnavailable_SidecarInstanceFragment>
}

const CertificatesEmptyState = () => (
  <Stack spacing={4}>
    <CertificateNotFound certificateType="TLS" />
    <CertificateNotFound certificateType="CA" />
  </Stack>
)

const CertificatesNotReportedAlert = () => (
  <Alert
    severity="info"
    sx={{
      color: 'cyralColors.yellow.500',
      backgroundColor: 'cyralColors.yellow.100',
      '& .MuiAlert-icon': {
        color: 'inherit',
      },
    }}
  >
    <Typography variant="h6" sx={{ color: 'cyralColors.yellow.500' }}>
      Certificate details are only available for sidecar instances running on v4.11 and later.
    </Typography>
  </Alert>
)

const CertificatesUnavailable = ({ sidecarInstance }: Props) => {
  const { version } = useFragment(CertificatesUnavailable_SidecarInstanceFragment, sidecarInstance)

  // Only sidecar instances >= v4.11 will report certificates
  const certificatesNotReported = satisfies(version, `< ${MIN_SIDECAR_INSTANCE_VERSION}`)
  if (certificatesNotReported) {
    return <CertificatesNotReportedAlert />
  }

  return <CertificatesEmptyState />
}

export default CertificatesUnavailable
