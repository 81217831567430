import { useQuery, NetworkStatus } from '@apollo/client'
import { graphql } from '@jeeves/graphql'
import { EnhancedAuditLog, RepoEnhancedAuditLogsArgs } from '@jeeves/graphql/graphql'
import { useRepositoryDetailContext } from '@jeeves/pages/RepositoryDetail/contexts/RepositoryDetailContext'

const ENHANCED_AUDIT_LOGS = graphql(`
  query EnhancedAuditLogs(
    $repoId: String!
    $after: String
    $before: String
    $filter: EnhancedAuditLogFilterInput
    $first: Int
    $last: Int
    $timezone: String
  ) {
    repo(id: $repoId) {
      id
      enhancedAuditLogs(
        after: $after
        before: $before
        filter: $filter
        first: $first
        last: $last
        timezone: $timezone
      ) {
        edges {
          node {
            timestamp
            description {
              textWithMarkdown
            }
            action {
              category
              type
            }
            id
          }
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
      }
    }
  }
`)

interface Variables {
  variables: RepoEnhancedAuditLogsArgs
}

const useAuditReport = ({ variables }: Variables) => {
  const { repoId } = useRepositoryDetailContext()
  const { data, networkStatus, fetchMore } = useQuery(ENHANCED_AUDIT_LOGS, {
    notifyOnNetworkStatusChange: true,
    variables: {
      repoId,
      ...variables,
    },
  })

  return {
    logs: data?.repo?.enhancedAuditLogs?.edges?.map(edge => edge?.node as EnhancedAuditLog) ?? [],
    loading: [NetworkStatus.loading, NetworkStatus.setVariables].includes(networkStatus),
    fetchingMore: networkStatus === NetworkStatus.fetchMore,
    hasMoreLogs: Boolean(data?.repo?.enhancedAuditLogs?.pageInfo?.hasNextPage),
    pageInfo: data?.repo?.enhancedAuditLogs?.pageInfo,
    fetchMore,
  }
}

export default useAuditReport
