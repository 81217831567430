/** @jsxRuntime classic */
/** @jsx jsx */
import styled from '@emotion/styled'
import { jsx } from '@emotion/react'
import { Button } from '@jeeves/components/Primitives'
import SearchField from '@jeeves/components/SearchField'

const FiltersWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 10px;
`

const Filters = () => (
  <FiltersWrapper>
    <SearchField label="Search - Description" name="searchDescription" />
    <SearchField label="Search - Name" name="searchName" />
    <Button variant="contained" color="secondary">
      Filter
    </Button>
  </FiltersWrapper>
)

export default Filters
