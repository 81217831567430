import { isAfter, parseISO, isFuture, subYears, subMonths, subDays } from 'date-fns'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import { Box, FormControl, FormHelperText, Stack, Typography } from '@mui/material'
import { DatePicker, InputLabel, Select } from '@jeeves/new-components'

const futureDateMessage = 'Please enter a date in the past'

const isFutureValidation = date => !isFuture(date) || futureDateMessage

const options = [
  { label: 'Past 7 days', value: 'sevenDays' },
  { label: 'Past 30 days', value: 'thirtyDays' },
  { label: 'Past 6 months', value: 'sixMonths' },
  { label: 'Past 1 year', value: 'oneYear' },
  { label: 'Custom', value: 'custom' },
]

const DateRange = () => {
  const { control, formState, setValue } = useFormContext()
  const { errors } = formState

  const dateRangeFrom = useWatch({
    name: 'dateRangeFrom',
  })

  const dateRange = useWatch({
    name: 'dateRange',
  })

  const handleDateRangeUpdate = period => {
    if (period !== 'custom') {
      const currentDate = new Date()

      const rangesFrom = {
        sevenDays: subDays(currentDate, 7).toISOString(),
        thirtyDays: subMonths(currentDate, 1).toISOString(),
        sixMonths: subMonths(currentDate, 6).toISOString(),
        oneYear: subYears(currentDate, 1).toISOString(),
      }

      setValue('dateRangeFrom', rangesFrom[period])
      setValue('dateRangeTo', currentDate.toISOString())
    }
    setValue('dateRange', period)
  }

  return (
    <Stack spacing={2}>
      <FormControl variant="standard" fullWidth>
        <InputLabel htmlFor="date-range-select">Date Range</InputLabel>
        <Controller
          name="dateRange"
          control={control}
          render={({ field }) => (
            <Select
              id="date-range-select"
              defaultValue="thirtyDays"
              options={options}
              {...field}
              onChange={e => handleDateRangeUpdate(e.target.value)}
            />
          )}
        />
      </FormControl>

      {dateRange === 'custom' && (
        <Stack spacing={1} direction="row" sx={{ alignItems: 'baseline' }}>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            From
          </Typography>

          <FormControl variant="standard">
            <Controller
              name="dateRangeFrom"
              control={control}
              render={({ field: { onChange, value } }) => {
                return <DatePicker onChange={onChange} value={value} selectTime />
              }}
              rules={{
                validate: dateRangeFrom => {
                  const dateFrom = parseISO(dateRangeFrom)
                  return isFutureValidation(dateFrom)
                },
              }}
            />
          </FormControl>

          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            to
          </Typography>

          <FormControl error={Boolean(errors?.dateRangeTo)} variant="standard">
            <Controller
              name="dateRangeTo"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Box>
                    <DatePicker
                      onChange={onChange}
                      value={value}
                      error={Boolean(errors?.dateRangeTo)}
                      selectTime
                    />
                    {errors?.dateRangeTo && (
                      <FormHelperText
                        sx={{
                          typography: 'body2',
                        }}
                      >
                        {errors.dateRangeTo.message}
                      </FormHelperText>
                    )}
                  </Box>
                )
              }}
              rules={{
                validate: dateRangeTo => {
                  const dateFrom = parseISO(dateRangeFrom)
                  const dateTo = parseISO(dateRangeTo)
                  if (isAfter(dateFrom, dateTo)) {
                    return 'End date must be after start date.'
                  }
                  return isFutureValidation(dateTo)
                },
              }}
            />
          </FormControl>
        </Stack>
      )}
    </Stack>
  )
}

export default DateRange
