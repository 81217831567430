import * as React from 'react'

import { Typography, Box } from '@mui/material'
import LaunchIcon from '@mui/icons-material/Launch'
import { getDocsURL } from '@jeeves/utils/docsURL'

export const LearnMore = ({ text = 'Learn More', docsPath, useVersionedDocsURL }) => {
  return (
    <Box
      component="a"
      href={getDocsURL({ docsPath, useVersionedDocsURL })}
      target="_blank"
      rel="noreferrer"
      sx={{
        display: 'inline-flex',
        gap: '2px',
        alignItems: 'center',
        cursor: 'pointer',
        textDecoration: 'none',
      }}
    >
      <Typography component="span" variant="h6" sx={{ color: 'primary.main' }}>
        {text}
      </Typography>
      <LaunchIcon sx={{ color: 'primary.main', fontSize: '20px' }} />
    </Box>
  )
}
