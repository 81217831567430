import { Box, Stack, Typography } from '@mui/material'

import { FragmentType, graphql, useFragment } from '@jeeves/graphql'

import CertificateDetails from './CertificateDetails'
import CertificateNotFound from './CertificateNotFound'
import DownloadCertificate from './DownloadCertificate'

const TlsCertificate_SidecarInstanceFragment = graphql(`
  fragment TlsCertificate_SidecarInstanceFragment on SidecarInstance {
    id
    certificates {
      tls {
        certificates {
          expirationDate
          fingerprint
          subject
          issuer
        }
        pemEncodedString
      }
    }
  }
`)

const TLS_CERTIFICATE_FILENAME = 'sidecar_tls_certificate'

interface Props {
  sidecarInstance: FragmentType<typeof TlsCertificate_SidecarInstanceFragment>
}

const TlsCertificate = ({ sidecarInstance: sidecarInstanceProp }: Props) => {
  const sidecarInstance = useFragment(TlsCertificate_SidecarInstanceFragment, sidecarInstanceProp)

  if (!sidecarInstance.certificates.tls) {
    return <CertificateNotFound certificateType="TLS" />
  }

  const tlsCertificates = sidecarInstance.certificates.tls.certificates
  const tlsCertificate = tlsCertificates[tlsCertificates.length - 1]

  return (
    <Stack spacing={2}>
      <Typography variant="h6" sx={{ color: 'text.primary' }}>
        TLS Certificate
      </Typography>

      <CertificateDetails certificate={tlsCertificate} />

      <Box>
        <DownloadCertificate
          filename={TLS_CERTIFICATE_FILENAME}
          certificate={sidecarInstance.certificates.tls.pemEncodedString}
        />
      </Box>
    </Stack>
  )
}

export default TlsCertificate
