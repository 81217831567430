import React from 'react'

import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  container: {
    padding: theme.spacing.unit * 2,
  },
})

const Unauthorized = props => {
  const { classes } = props

  return (
    <div className={classes.container}>
      <Typography variant="h5" component="h3">
        Unauthorized
      </Typography>
      <Typography component="p">
        Sorry, you do not have the required permissions to access this page
      </Typography>
    </div>
  )
}

export default withStyles(styles)(Unauthorized)
