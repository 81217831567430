import { FragmentType, graphql, useFragment } from '@jeeves/graphql'
import { ServiceIcon } from '@jeeves/new-components'

const SidecarHeaderIcon_queryFragment = graphql(`
  fragment SidecarHeaderIcon_query on Query {
    sidecar(id: $sidecarId) {
      id
      cloud
    }
  }
`)

interface SidecarHeaderIconProps {
  query: FragmentType<typeof SidecarHeaderIcon_queryFragment>
}

const SidecarHeaderIcon = ({ query: queryProp }: SidecarHeaderIconProps) => {
  const query = useFragment(SidecarHeaderIcon_queryFragment, queryProp)

  const { cloud } = query.sidecar

  if (!cloud) {
    return null
  }

  return <ServiceIcon type={cloud.toLowerCase()} sx={{ fontSize: 64 }} />
}

export default SidecarHeaderIcon
