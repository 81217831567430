import { FormControl, FormHelperText, Stack } from '@mui/material'

import { Input, InputLabel } from '@jeeves/new-components'

import { LoggingIntegrationIcon } from '@jeeves/pages/Integrations/Services/Logging/components'

import Header from '@jeeves/pages/Integrations/Services/Logging/components/NewIntegrationDialog/components/Header'
import Footer from '@jeeves/pages/Integrations/Services/Logging/components/NewIntegrationDialog/components/Footer'

import { useSumologic } from './useSumologic'

const SUMOLOGIC_PLACEHOLDER_URL =
  'https://collectors.sumologic.com/receiver/v1/http/ZaVnC4dhaV1IQNR0Ds89_3lHTrWAO-K1TOLv4j8xj-4t5FGVj'

const Sumologic = ({ integration, resetIntegration, onClose }) => {
  const isEditing = Boolean(integration?.id)

  const { loading, methods, onSubmit } = useSumologic({ integration, onClose, isEditing })

  const {
    formState: { errors },
    register,
  } = methods

  return (
    <Stack spacing={4} component="form" onSubmit={onSubmit}>
      <Header
        icon={<LoggingIntegrationIcon integrationType="SumoLogicIntegration" />}
        title="Sumo Logic"
        onClose={onClose}
      />

      <FormControl variant="standard" error={Boolean(errors?.name)}>
        <InputLabel htmlFor="name" required>
          Name
        </InputLabel>
        <Input
          id="name"
          inputProps={{
            ...register('name'),
          }}
        />
        <FormHelperText
          sx={{
            typography: 'body2',
          }}
          error={Boolean(errors?.name)}
        >
          {errors?.name?.message}
        </FormHelperText>
      </FormControl>

      <FormControl variant="standard" error={Boolean(errors?.address)}>
        <InputLabel htmlFor="address" required>
          HTTP Collector Address
        </InputLabel>
        <Input
          id="address"
          inputProps={{
            ...register('address'),
          }}
          placeholder={`e.g. ${SUMOLOGIC_PLACEHOLDER_URL}`}
        />
        <FormHelperText
          sx={{
            typography: 'body2',
          }}
        >
          {errors?.address?.message || 'Please provide the full URL.'}
        </FormHelperText>
      </FormControl>

      <Footer
        loading={loading}
        isEditing={isEditing}
        resetIntegration={resetIntegration}
        onClose={onClose}
      />
    </Stack>
  )
}

export default Sumologic
