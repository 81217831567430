import { useLocation, Switch, Route, useRouteMatch } from 'react-router-dom'

import { EmbeddedAccessToken } from './components/AccessToken'
import AccessPortalMain from './components/AccessPortalMain'
import { RequestAccessDialog } from './components/RequestAccessDialog'

const AccessPortal = () => {
  const { path } = useRouteMatch()
  const { search } = useLocation()

  const isMinimized = new URLSearchParams(search).get('minimized') === 'true'

  return (
    <Switch>
      {isMinimized && (
        <Route exact path={path}>
          <EmbeddedAccessToken />
        </Route>
      )}

      <Route path={path}>
        <AccessPortalMain />
        <RequestAccessDialog />
      </Route>
    </Switch>
  )
}

export default AccessPortal
