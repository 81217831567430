/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import styled from '@emotion/styled'
import { SetupContext } from '@jeeves/pages/Setup'
import { useContext } from 'react'

const Dot = styled.li`
  display: block;
  border-radius: 25px;
  height: 16px;
  width: 16px;
  margin: 0 16px;
  transition: background-color 0.1s linear;
  cursor: pointer;
  background-color: ${({ theme, active }) => (active ? theme.palette.primary.main : '#e1eaee')};
`

export const CircleProgress = ({ absoluteIndex }) => {
  const { setupStep, setSetupStep, Steps } = useContext(SetupContext)
  const MAX_STEPS = Object.keys(Steps).length

  return (
    <ul
      css={() => css`
        list-style-type: none;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0;
        width: 100%;

        ${absoluteIndex
          ? css`
              position: absolute;
              bottom: 32px;
              left: 0;
              right: 0;
            `
          : css`
              margin-bottom: 48px;
            `}
      `}
    >
      {Array.from({ length: MAX_STEPS }, (_, index) => (
        <Dot
          key={`step-${index + 1}`}
          active={setupStep === index + 1}
          onClick={() => setSetupStep(index + 1)}
        />
      ))}
    </ul>
  )
}
