import { Box, Stack, Typography, CircularProgress } from '@mui/material'
import useSmartPorts from '@jeeves/pages/WrapperDetail/hooks/useSmartPorts'
import { useSidecarDetailContext } from '@jeeves/pages/WrapperDetail/contexts/SidecarDetailContext'
import SmartPort from './SmartPort'

const SmartPorts = () => {
  const { sidecarId } = useSidecarDetailContext()
  const {
    loading,
    error,
    data: { smartPorts },
  } = useSmartPorts({
    variables: {
      sidecarId,
      firstBindings: 1,
    },
  })

  return (
    <Box
      sx={{
        border: 1,
        borderColor: 'cyralColors.grey.200',
        p: 4,
        borderRadius: theme => theme.radii.base,
      }}
    >
      <Stack spacing={6}>
        <Typography variant="h3" sx={{ color: 'text.primary' }}>
          Smart Ports
        </Typography>
        {loading ? (
          <LoadingState />
        ) : error ? (
          <ErrorState />
        ) : smartPorts.length === 0 ? (
          <EmptyState />
        ) : (
          <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
            {smartPorts.map(smartPort => (
              <Box gridColumn="span 4" key={smartPort.id}>
                <SmartPort smartPort={smartPort} />
              </Box>
            ))}
          </Box>
        )}
      </Stack>
    </Box>
  )
}

const LoadingState = () => (
  <Stack spacing={2} sx={{ alignItems: 'center' }}>
    <CircularProgress size={64} />
    <Typography variant="h6" sx={{ color: 'text.secondary' }}>
      Loading smart ports...
    </Typography>
  </Stack>
)

const ErrorState = () => (
  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
    An error occurred while fetching smart ports.
  </Typography>
)

const EmptyState = () => (
  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
    No Smart Ports have been configured.
  </Typography>
)

export default SmartPorts
