/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import styled from '@emotion/styled'

import { IconCopyButton } from '@jeeves/components/CopyButton'
import { Flex } from '@jeeves/components/Primitives'
import { useAuth } from '@jeeves/hooks'
import {
  CodeSnippet,
  CodeSpan,
  SandboxLayout,
  Tooltip,
  Typography,
  SandboxList,
  SandboxListItem,
  ViewLogsButton,
} from '@jeeves/pages/Setup/components'

const dockerCommand = (email, token, sidecarHost) =>
  `docker run --rm -it -e PGPASSWORD=${token} -e PGSSLMODE=require postgres ` +
  `psql -h ${sidecarHost} -p 5433 -d patientdata -U ${email}`

const SQL_COMMAND = 'select * from public.inpatient_charges limit 10;'

const StyledPoint = styled(Typography)`
  font-size: 18px;
`

const Notes = ({ user }) => (
  <ul>
    <li>
      <StyledPoint>
        Now with Cyral, you're ready to use an IDP to access the PostgreSQL instance.
      </StyledPoint>
    </li>
    <li>
      <StyledPoint>
        When connecting to PostgreSQL, replace the database user with your email (the identity we
        created for you in Auth0), and the password with a short-lived access token generated by
        Auth0.
      </StyledPoint>
    </li>
    <li>
      <StyledPoint>
        When you run <em>select user();</em>, you see yourself logged in as database user "analyst".
        We've created a mapping in Cyral from your SSO group "Data Analysts”, to the user "analyst".
        Once you’re authenticated as part of “Data Analysts” SSO group, you’re logged in as an
        “analyst”.
      </StyledPoint>
    </li>
    <li>
      <StyledPoint css={{ marginBottom: 0 }}>
        Check out the logs in Kibana and look at the following fields:
      </StyledPoint>
      <ul css={{ lineHeight: '0.5' }}>
        <li>
          <StyledPoint css={{ margin: 0 }}>
            <em>endUser: {user.email}</em>
          </StyledPoint>
        </li>
        <li>
          <StyledPoint css={{ margin: 0 }}>
            <em>ssoGroup: Data Analysts</em>
          </StyledPoint>
        </li>
        <li>
          <StyledPoint css={{ margin: 0 }}>
            <em>repoUser: analyst</em>
          </StyledPoint>
        </li>
      </ul>
    </li>
  </ul>
)

export const SandboxIdp = ({ onNext, token, sidecarHost }) => {
  const { user } = useAuth()

  return (
    <SandboxLayout
      title="Play in your Sandbox"
      subtitle="Use an Identity Provider (IDP) to access the Cyral-provided database"
      tooltip='We have set up an Auth0-based IDP in the sandbox, created an SSO group
      called "Data Analysts" and added you to it. We show you how to enable
      SAML-based authentication using this group instead of native database
      authentication.'
      onNext={onNext}
      notesComponent={<Notes user={user} />}
      idp
    >
      <SandboxList start="4">
        <SandboxListItem>
          <Typography css={{ marginTop: 0 }}>
            Access the PostgreSQL with the IDP credentials&nbsp;
            <Tooltip
              interactive
              placement="right"
              leaveDelay={2500}
              title={
                <span>
                  You can run these commands anywhere that you have Docker installed.
                  {/* <a href="https://docs.docker.com/get-docker/" target="_blank">
                    Get Docker here.
                  </a> */}
                </span>
              }
            />
          </Typography>
          <Flex>
            <CodeSnippet css={{ marginRight: '8px' }}>
              {dockerCommand(user.email, token, sidecarHost)}
            </CodeSnippet>
            <IconCopyButton copyText={dockerCommand(user.email, token, sidecarHost)} />
          </Flex>
        </SandboxListItem>
        <SandboxListItem>
          <Typography>Check the currently logged in user</Typography>
          <Flex>
            <CodeSnippet css={{ marginRight: '8px' }}>select user();</CodeSnippet>
            <IconCopyButton copyText={'select user();'} />
          </Flex>
        </SandboxListItem>
        <SandboxListItem>
          <Typography css={{ marginTop: 0 }}>Run a query:</Typography>
          <Flex>
            <CodeSnippet css={{ marginRight: '8px' }}>{SQL_COMMAND}</CodeSnippet>
            <IconCopyButton copyText={SQL_COMMAND} />
          </Flex>
        </SandboxListItem>
        <SandboxListItem>
          <Typography css={{ marginTop: 0 }}>
            View identity enriched data activity logs in Kibana
          </Typography>
          <ViewLogsButton onClick={() => console.log('view logs')} />
        </SandboxListItem>
      </SandboxList>
    </SandboxLayout>
  )
}
