import { useHistory, useLocation } from 'react-router-dom'

const useQueryParams = () => {
  const history = useHistory()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)

  const setQueryParam = (key: string, value: string) => {
    queryParams.set(key, value)
    history.push({
      search: `?${queryParams}`,
    })
  }

  const removeQueryParam = (key: string) => {
    queryParams.delete(key)
    history.push({
      search: `?${queryParams}`,
    })
  }

  return {
    queryParams,
    setQueryParam,
    removeQueryParam,
  }
}

export default useQueryParams
