import {
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  IconButton,
  Box,
  FormHelperText,
  FormControl,
} from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'
import { Dialog, Button, DialogActions, Input, InputLabel } from '@jeeves/new-components'
import { useDeleteSidecarModal } from './useDeleteSidecarModal'
import { FragmentType, graphql, useFragment } from '@jeeves/graphql'

const DeleteSidecarModal_queryFragment = graphql(`
  fragment DeleteSidecarModal_query on Query {
    sidecar(id: $sidecarId) {
      id
      name
    }
  }
`)

interface DeleteSidecarModalProps {
  open: boolean
  closeModal: () => void
  query: FragmentType<typeof DeleteSidecarModal_queryFragment>
}

const DeleteSidecarModal = ({ open, query: queryProp, closeModal }: DeleteSidecarModalProps) => {
  const { loading, methods, onSubmit } = useDeleteSidecarModal()
  const query = useFragment(DeleteSidecarModal_queryFragment, queryProp)

  const { register, reset, watch } = methods

  const { confirmation } = watch()

  const sidecarName = query.sidecar.name
  const isDeleteButtonDisabled = confirmation.trim() !== sidecarName.trim()

  const handleClose = () => {
    reset()
    closeModal()
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <Box component="form" onSubmit={onSubmit}>
        <DialogTitle
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h3" sx={{ color: 'text.primary' }}>
            Delete Sidecar
          </Typography>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Are you sure you want to delete this sidecar?{' '}
              <Typography component="span" variant="h6" sx={{ color: 'error.main' }}>
                This action cannot be undone.
              </Typography>
            </Typography>
            <FormControl variant="standard">
              <InputLabel>Delete Confirmation</InputLabel>
              <Input
                type="text"
                inputProps={{
                  ...register('confirmation'),
                }}
              />
              <FormHelperText sx={{ typography: 'body2' }}>
                Please type{' '}
                <Box component="span" sx={{ fontWeight: 'bold' }}>
                  {sidecarName}
                </Box>{' '}
                to confirm you want to delete this sidecar.
              </FormHelperText>
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Stack direction="row" spacing={2} sx={{ justifyContent: 'flex-end' }}>
            <Button variant="text" color="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="outlined"
              type="submit"
              color="error"
              disabled={isDeleteButtonDisabled}
              loading={loading}
            >
              Delete
            </Button>
          </Stack>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default DeleteSidecarModal
