import { Button } from '@jeeves/new-components'
import { Box } from '@mui/material'

const Footer = ({ isEditing, loading, resetIntegration, onClose }) => {
  const onClick = () => {
    if (isEditing) {
      onClose()
    } else {
      resetIntegration()
    }
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'end', gap: 2 }}>
      <Button disabled={loading} variant="text" color="secondary" onClick={onClick}>
        {isEditing ? 'Cancel' : 'Back'}
      </Button>
      <Button loading={loading} type="submit" variant="contained" color="primary">
        {isEditing ? 'Save' : 'Create'}
      </Button>
    </Box>
  )
}

export default Footer
