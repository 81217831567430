import { Controller, useFormContext } from 'react-hook-form'
import { Box, Typography, FormControlLabel, Checkbox } from '@mui/material'

import { LearnMore } from '@jeeves/new-components'

const EnableBrokeredAuthenticationDefault = () => {
  const { control } = useFormContext()

  return (
    <Box>
      <Controller
        name="cyralBrokeredAuthentication"
        control={control}
        render={({ field: { onChange, value, name } }) => (
          <FormControlLabel
            control={<Checkbox name={name} onChange={onChange} checked={value} />}
            label={
              <Typography variant="h4" sx={{ color: 'text.primary' }}>
                Enable Cyral brokered authentication to this database.
              </Typography>
            }
          />
        )}
      />
      <Typography variant="body2" sx={{ color: 'text.secondary', marginLeft: 4 }}>
        Users will use their{' '}
        <LearnMore text="Cyral Access Tokens" docsPath="/how-to/manage-access-tokens/" /> to connect
        to their databases.
      </Typography>
    </Box>
  )
}

export default EnableBrokeredAuthenticationDefault
