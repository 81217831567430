import { useState } from 'react'
import { getLocalTimeZone } from '@internationalized/date'
import { subMonths } from 'date-fns'

import { Stack, Typography, CircularProgress } from '@mui/material'

import { AuditFooter, AuditHeader, LogList } from './components'

import useAuditReport from './hooks/useAuditReport'

import { FilterData, parseFilter } from './components/AuditFilter/utils'

const getFilterDefaultValues = (): FilterData => {
  const currentDate = new Date()
  const oneMonthAgo = subMonths(currentDate, 1)
  return {
    eventType: 'all',
    cyralUser: '',
    dateRangeFrom: oneMonthAgo.toISOString(),
    dateRangeTo: currentDate.toISOString(),
    dateRange: 'thirtyDays',
  }
}

const PAGE_SIZE = 10

const AuditReport = () => {
  const [filterInitialState, setFilterInitialState] = useState(getFilterDefaultValues())
  const [filter, setFilter] = useState(filterInitialState)

  const { logs, loading, fetchingMore, hasMoreLogs, pageInfo, fetchMore } = useAuditReport({
    variables: {
      first: PAGE_SIZE,
      filter: parseFilter(filter),
      timezone: getLocalTimeZone(),
    },
  })

  const handleLoadMore = () => {
    fetchMore({
      variables: {
        after: pageInfo?.endCursor,
      },
    })
  }

  const handleFilter = (newFilter: FilterData) => {
    setFilter(newFilter)
  }

  const handleRefetch = () => {
    const dateRangeTo = new Date().toISOString()
    setFilterInitialState({ ...filterInitialState, dateRangeTo })
    setFilter({ ...filter, dateRangeTo })
  }

  if (loading) {
    return (
      <Stack spacing={2} sx={{ alignItems: 'center' }}>
        <CircularProgress size={64} />
        <Typography variant="h6" sx={{ color: 'text.secondary' }}>
          Loading logs...
        </Typography>
      </Stack>
    )
  }

  return (
    <Stack spacing={4} sx={{ mx: 8, my: 4 }}>
      <AuditHeader
        hasLogs={logs.length > 0}
        filter={filter}
        filterInitialState={filterInitialState}
        handleFilter={handleFilter}
        handleRefetch={handleRefetch}
      />
      <LogList logs={logs} />
      {logs.length > 0 && (
        <AuditFooter
          hasMoreLogs={hasMoreLogs}
          fetchingMore={fetchingMore}
          handleLoadMore={handleLoadMore}
        />
      )}
    </Stack>
  )
}

export default AuditReport
