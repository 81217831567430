import { Typography } from '@mui/material'

import { usePolicySummary } from '@jeeves/pages/RepositoryDetail/Tabs/Policies/components/PolicySummary/PolicySummaryContext'

import { Variation1 } from './components/Variations/Variation1'
import { Variation2 } from './components/Variations/Variation2'
import { Variation3 } from './components/Variations/Variation3'
import { Variation4 } from './components/Variations/Variation4'
import { Variation5 } from './components/Variations/Variation5'
import { Variation6 } from './components/Variations/Variation6'
import { Variation7 } from './components/Variations/Variation7'
import { Variation8 } from './components/Variations/Variation8'
import { Variation9 } from './components/Variations/Variation9'
import { PolicyScopeModifier } from '@jeeves/graphql/graphql'

const Error = (
  <Typography variant="h6" sx={{ color: 'text.secondary' }}>
    An error occurred rendering this policy
  </Typography>
)

const repositoryProtectionVariationsMap = {
  ALL: {
    ALL: <Variation1 />,
    ALL_EXCEPT: <Variation7 />,
    ONLY: <Variation4 />,
  },
  ALL_EXCEPT: {
    ALL: <Variation3 />,
    ALL_EXCEPT: <Variation9 />,
    ONLY: <Variation6 />,
  },
  ONLY: {
    ALL: <Variation2 />,
    ALL_EXCEPT: <Variation8 />,
    ONLY: <Variation5 />,
  },
}

const RepositoryProtection = () => {
  const { policy } = usePolicySummary()

  const { databaseAccountsAppliedTo, identitiesAppliedTo } = policy.parameters

  const databaseAccountsModifier: PolicyScopeModifier = databaseAccountsAppliedTo.modifier
  const identitiesModifier: PolicyScopeModifier = identitiesAppliedTo.modifier

  return repositoryProtectionVariationsMap[databaseAccountsModifier][identitiesModifier] ?? Error
}

export default RepositoryProtection
