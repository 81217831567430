import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { gql, useMutation } from '@apollo/client'
import { Stack, Box, DialogContent, DialogTitle, Typography } from '@mui/material'
import CloseIcon from '@material-ui/icons/Close'

import BasicSnackbar from '@jeeves/pages/WrapperDetail/components/BasicSnackbar'
import { usePolicyDetails } from '@jeeves/pages/RepositoryDetail/Tabs/Policies/views/PolicyDetails/contexts/PolicyDetailsContext'
import { Dialog, Button, DialogActions, IconButton } from '@jeeves/new-components'

const TOGGLE_POLICY = gql`
  mutation TogglePolicy($policyId: ID!, $enabled: Boolean!) {
    togglePolicy(policyId: $policyId, enabled: $enabled) {
      policy {
        id
      }
    }
  }
`

const TogglePolicyModal = ({ isOpen, setIsOpen }) => {
  const { policy } = usePolicyDetails()
  const [snackBarOpen, setSnackBarOpen] = useState(false)

  const [togglePolicy, { error }] = useMutation(TOGGLE_POLICY, {
    onError: e => {
      const values = getValues()
      reset(values)
      setSnackBarOpen(true)
      console.error(e)
    },
    onCompleted: () => {
      handleCloseSnackbar()
      handleCloseModal()
    },
    update(cache, response) {
      const { id, __typename } = response.data.togglePolicy.policy
      const cacheId = cache.identify({
        id,
        __typename,
      })
      cache.evict({ id: cacheId })
      cache.gc()
    },
  })

  const {
    handleSubmit,
    formState: { isSubmitting },
    reset,
    getValues,
  } = useForm()

  const handleCloseSnackbar = () => {
    setSnackBarOpen(false)
  }

  const handleCloseModal = () => {
    setIsOpen(false)
  }

  const onSubmit = async () => {
    await togglePolicy({
      variables: {
        policyId: policy.id,
        enabled: !policy.enabled,
      },
    })
  }

  const errorMessage = error?.networkError?.result?.errors?.[0]?.message || error?.message

  return (
    <Dialog open={isOpen} onClose={handleCloseModal}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ minWidth: '635px' }}>
        <DialogTitle
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h3" sx={{ color: 'text.primary' }}>
            {policy.enabled ? 'Disable' : 'Enable'} Policy
          </Typography>
          <IconButton aria-label="close" onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Stack spacing={2}>
            <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
              Are you sure you want to {policy.enabled ? 'disable' : 'enable'} this policy?
            </Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Stack direction="row" spacing={2} sx={{ justifyContent: 'flex-end' }}>
            <Button variant="text" onClick={handleCloseModal}>
              Cancel
            </Button>
            {policy.enabled ? (
              <Button loading={isSubmitting} variant="outlined" type="submit" color="error">
                Disable
              </Button>
            ) : (
              <Button loading={isSubmitting} variant="contained" type="submit" color="primary">
                Enable
              </Button>
            )}
          </Stack>
        </DialogActions>
        <BasicSnackbar
          open={snackBarOpen}
          onClose={handleCloseSnackbar}
          severity="error"
          message={`Failed to ${policy.enable ? 'disable' : 'enable'} policy: ${errorMessage}`}
        />
      </Box>
    </Dialog>
  )
}

export default TogglePolicyModal
