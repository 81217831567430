import { useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'

import {
  Box,
  Divider,
  FormControl,
  Stack,
  FormHelperText,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material'
import { useRepositoryDetailContext } from '@jeeves/pages/RepositoryDetail/contexts/RepositoryDetailContext'

import { Input, InputLabel } from '@jeeves/new-components'

import InputTags from '@jeeves/pages/RepositoryDetail/Tabs/Policies/views/AddPolicy/components/InputTags'
import Footer from '@jeeves/pages/RepositoryDetail/Tabs/Policies/views/AddPolicy/components/Footer'

const StepTwo = ({ rateLimitInfo, nextStep, prevStep, setRateLimitInfo }) => {
  const { repoId } = useRepositoryDetailContext()
  const {
    setValue,
    handleSubmit,
    watch,
    control,
    register,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      action: rateLimitInfo.parameters.action,
      dataLabels: rateLimitInfo.parameters.dataLabels,
      rateLimit: rateLimitInfo.parameters.rateLimit,
    },
  })

  const onSubmit = data => {
    setRateLimitInfo(info => {
      return {
        ...info,
        parameters: {
          ...info.parameters,
          action: data.action,
          dataLabels: data.dataLabels,
          rateLimit: data.rateLimit,
        },
      }
    })
  }

  useEffect(() => {
    if (isSubmitSuccessful) {
      nextStep()
    }
  }, [isSubmitSuccessful, nextStep])

  const { dataLabels = [] } = watch()

  const onAddDataLabel = updatedTags => {
    setValue('dataLabels', updatedTags, { shouldValidate: true, shouldDirty: true })
  }

  const onDeleteDataLabel = updatedTags => {
    setValue('dataLabels', updatedTags, { shouldDirty: true })
  }

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4}>
        <Stack spacing={4} sx={{ maxWidth: '32rem' }}>
          <FormControl variant="standard" error={Boolean(errors?.rateLimit)}>
            <InputLabel htmlFor="rateLimit" required>
              Maximum number of rows that can be returned per hour
            </InputLabel>
            <Input
              id="rateLimit"
              placeholder="e.g. 20"
              type="number"
              inputProps={{
                ...register('rateLimit', {
                  required:
                    'Please define the Maximum number of rows that can be returned per hour',
                  min: {
                    value: 1,
                    message: 'Please insert a number greater than zero',
                  },
                }),
              }}
            />
            <FormHelperText
              sx={{
                typography: 'body2',
              }}
              error={!!errors?.rateLimit}
            >
              {errors?.rateLimit?.message}
            </FormHelperText>
          </FormControl>

          <FormControl
            variant="standard"
            error={Boolean(errors?.dataLabels)}
            sx={{ display: 'flex' }}
          >
            <Controller
              name="dataLabels"
              control={control}
              rules={{
                validate: tags => {
                  return tags?.length > 0 || 'Please enter at least one data label'
                },
              }}
              render={() => (
                <InputTags
                  inputLabel="Data Labels"
                  required
                  tags={dataLabels}
                  isSubmitting={isSubmitting}
                  onAdd={onAddDataLabel}
                  onDelete={onDeleteDataLabel}
                />
              )}
            />
            <FormHelperText sx={{ typography: 'body2' }} error={!!errors?.dataLabels}>
              {errors?.dataLabels?.message}
            </FormHelperText>
          </FormControl>
          <FormControl variant="standard" sx={{ flex: 1 }}>
            <InputLabel htmlFor="action" component="legend" required>
              Policy Actions
            </InputLabel>
            <Controller
              name="action"
              control={control}
              render={({ field }) => (
                <RadioGroup row aria-label="action" {...field}>
                  <Stack sx={{ flexGrow: 1 }}>
                    <FormControlLabel
                      value="alert"
                      control={<Radio size="small" disableRipple />}
                      label="Alert"
                      slotProps={{
                        typography: {
                          variant: 'h6',
                          sx: {
                            color: 'text.primary',
                          },
                        },
                      }}
                    />
                    <FormControlLabel
                      value="alertAndBlock"
                      control={<Radio size="small" disableRipple />}
                      label="Enforce"
                      slotProps={{
                        typography: {
                          variant: 'h6',
                          sx: {
                            color: 'text.primary',
                          },
                        },
                      }}
                    />
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </Stack>
        <Divider />
        <Footer repoId={repoId} prevStep={prevStep} policyId={rateLimitInfo.id} />
      </Stack>
    </Box>
  )
}

export default StepTwo
