import * as React from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import List from '@mui/material/List'

import UserAccountListItem from './UserAccountListItem'

import { useRepositoryDetailContext } from '@jeeves/pages/RepositoryDetail/contexts/RepositoryDetailContext'
import { useUserAuthenticationContext } from '@jeeves/pages/RepositoryDetail/Tabs/UserAuthentication/contexts/UserAuthenticationContext'

const UserAccountList = () => {
  const { repoType } = useRepositoryDetailContext()
  const { isRegistering, userAccounts, selectedUserAccountId, onSelectUserAccount } =
    useUserAuthenticationContext()

  return (
    <Stack spacing={2}>
      <Typography variant="h5" sx={{ color: 'text.primary' }}>
        {repoType === 's3' ? 'IAM Roles' : 'Database Accounts'} (
        {isRegistering ? userAccounts.length + 1 : userAccounts.length})
      </Typography>

      <List sx={{ display: 'flex', flexDirection: 'column', gap: 1 }} dense>
        {isRegistering && (
          <UserAccountListItem name={repoType === 's3' ? 'New Role' : 'New Account'} isActive />
        )}

        {userAccounts.map(account => {
          return (
            <UserAccountListItem
              key={account.id}
              name={account.name}
              isActive={account.id === selectedUserAccountId}
              onClick={() => onSelectUserAccount(account.id)}
            />
          )
        })}
      </List>
    </Stack>
  )
}

export default UserAccountList
