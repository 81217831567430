/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import styled from '@emotion/styled'
import { useEffect, useRef, useState } from 'react'
import { Controller } from 'react-hook-form'
import { grey } from '@material-ui/core/colors'
import useDeepCompareEffect from 'use-deep-compare-effect'

import MonacoEditor from 'react-monaco-editor'
// import { languages } from 'monaco-editor/esm/vs/editor/editor.api'
import { setDiagnosticsOptions } from 'monaco-yaml'
import YAML from 'yaml'

import DatamapYamlSchema from './datamap.schema.json'

const CodeWrapper = styled.div(
  ({ editing }) => css`
    padding: 0;
    border: 1px solid ${editing ? grey[400] : grey[100]};
    transition: border 0.2s ease-in-out;
    border-radius: 4px;
    overflow: hidden;
    height: 100%;

    & .monaco-editor {
      & .cursors-layer > .cursor {
        ${!editing && 'display: none !important;'}
      }

      & .view-overlays .current-line {
        ${!editing && 'display: none !important;'}
      }
    }
  `
)

const DatamapViewer = ({ editing = false, control, trigger }) => {
  const [isEditorReady, setIsEditorReady] = useState(false)
  const [modelMarkersSetter, setModelMarkersSetter] = useState(null)
  const [monacoEditorMarkers, setMonacoEditorMarkers] = useState([])
  const monacoEditorRef = useRef(null)

  useEffect(
    () =>
      // languages?.yaml?.yamlDefaults?.setDiagnosticsOptions({
      setDiagnosticsOptions({
        validate: false,
        enableSchemaRequest: true,
        hover: true,
        completion: true,
        schemas: [
          {
            uri: 'http://datamap/schema.json', // id of the schema
            fileMatch: ['*'],
            schema: {
              id: 'http://datamap/schema.json',
              ...DatamapYamlSchema,
            },
          },
        ],
      }),
    []
  )

  useDeepCompareEffect(() => {
    const triggerValidation = async () => {
      await trigger('datamapViewer')
    }

    triggerValidation()
  }, [trigger, monacoEditorMarkers])

  useEffect(() => {
    if (isEditorReady) {
      monacoEditorRef.current.editor.setModelMarkers = function(model, owner, markers) {
        modelMarkersSetter.call(monacoEditorRef.current.editor, model, owner, markers)
        setMonacoEditorMarkers(markers)
      }
    }
  }, [isEditorReady, modelMarkersSetter])

  const editorDidMount = (_, monaco) => {
    monacoEditorRef.current = monaco

    setModelMarkersSetter(() => monacoEditorRef.current.editor.setModelMarkers)
    setIsEditorReady(true)
  }

  return (
    <Controller
      name="datamapViewer"
      control={control}
      // rules={{
      //   validate: () => monacoEditorMarkers.length === 0,
      // }}
      rules={{
        validate: v => {
          try {
            YAML.parse(v)
            return true
          } catch {
            return false
          }
        },
      }}
      render={({ field: { onChange, value } }) => {
        return (
          <CodeWrapper editing={editing}>
            <MonacoEditor
              language="yaml"
              defaultValue={value}
              value={value}
              onChange={onChange}
              editorDidMount={editorDidMount}
              options={{
                readOnly: !editing,
                minimap: {
                  enabled: false,
                },
              }}
            />
          </CodeWrapper>
        )
      }}
    />
  )
}

export default DatamapViewer
