import { Box, Stack, Typography } from '@mui/material'

import { Button, SearchInput } from '@jeeves/new-components'
import { useAuth } from '@jeeves/hooks'
import { useUsers } from '@jeeves/pages/Account/Users/context'

interface Props {
  onClickAddUser: () => void
  onClickManageRoles: () => void
}

const UsersHeader = ({ onClickManageRoles, onClickAddUser }: Props) => {
  const { hasPermission } = useAuth()
  const { onSearch } = useUsers()

  const hasManageRoles = !!hasPermission('roles:read')
  const notOutboundInternet = (window as any)._env_.outbound_internet !== 'false'
  const userCreatingDisabled = !hasPermission('users:create')

  return (
    <Stack spacing={3}>
      <Typography variant="h2" sx={{ color: 'text.primary' }}>
        Cyral Users
      </Typography>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <SearchInput sx={{ width: '368px' }} onChange={onSearch} placeholder="Search users" />

        <Stack direction="row" spacing={1}>
          {hasManageRoles && (
            <Button variant="outlined" color="secondary" onClick={onClickManageRoles}>
              Manage Roles
            </Button>
          )}

          {notOutboundInternet && (
            <Button
              variant="contained"
              color="primary"
              onClick={onClickAddUser}
              disabled={userCreatingDisabled}
            >
              Add User
            </Button>
          )}
        </Stack>
      </Box>
    </Stack>
  )
}

export default UsersHeader
