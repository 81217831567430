import React from 'react'
import { FormControl, Autocomplete } from '@mui/material'
import { NetworkStatus } from '@apollo/client'
import debounce from 'lodash/debounce'

import { graphql, useFragment } from '@jeeves/graphql'
import { SelectOption, InputLabel, Input } from '@jeeves/new-components'

import InfiniteLoad from './InfiniteLoad'
import useQueryParams from '../useQueryParams'

export const RepositoryAutocomplete_ControlPlaneUserRepos_ConnectionFragment = graphql(`
  fragment RepositoryAutocomplete_ControlPlaneUserRepos_ConnectionFragment on ControlPlaneUserRepos_Connection {
    pageInfo {
      hasNextPage
      endCursor
    }
  }
`)

const RepositoryAutocomplete = ({
  setSearchValue,
  networkStatus,
  loading,
  fetchMore,
  options,
  selectedRepo,
  controlPlaneUserRepos_Connection: controlPlaneUserRepos_ConnectionProp,
}) => {
  const controlPlaneUserRepos_Connection = useFragment(
    RepositoryAutocomplete_ControlPlaneUserRepos_ConnectionFragment,
    controlPlaneUserRepos_ConnectionProp
  )

  const { setQueryParam, removeQueryParam } = useQueryParams()

  const hasNextPage = controlPlaneUserRepos_Connection?.pageInfo?.hasNextPage

  const getNextPage =
    hasNextPage &&
    (() =>
      fetchMore({ variables: { after: controlPlaneUserRepos_Connection?.pageInfo?.endCursor } }))

  const debouncedSearch = React.useMemo(() => {
    return debounce(value => setSearchValue(value), 300)
  }, [setSearchValue])

  return (
    <FormControl variant="standard" sx={{ display: 'flex' }}>
      <InputLabel id="repoName-select-label" htmlFor="repoName-select" required>
        Repository Name
      </InputLabel>

      <Autocomplete
        sx={{ mt: 1 }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        labelId="repoName-select-label"
        id="repoName-select"
        loading={loading}
        ListboxComponent={InfiniteLoad}
        ListboxProps={{
          hasNextPage,
          isNextPageLoading: networkStatus === NetworkStatus.fetchMore,
          getNextPage,
          sx: {
            '& li': {
              typography: 'body2',
            },
          },
        }}
        options={options}
        filterOptions={options => options}
        value={selectedRepo}
        onChange={(e, data) => {
          removeQueryParam('databaseAccount')

          if (data) {
            setQueryParam('repositoryName', data.label)
          } else {
            removeQueryParam('repositoryName')
          }
        }}
        onInputChange={(e, value, reason) => {
          if (reason === 'input') {
            debouncedSearch(value)
          }
        }}
        renderOption={(props, option) => (
          <SelectOption
            value={option.id}
            icon={option.icon}
            label={option.label}
            selectSize="medium"
            {...props}
          />
        )}
        renderInput={params => {
          return (
            <Input
              onBlur={() => {
                setSearchValue(selectedRepo?.label ?? '')
              }}
              inputRef={params.InputProps.ref}
              inputProps={{ ...params.inputProps, ...params.InputProps }}
              endAdornment={params.InputProps.endAdornment}
              {...params}
              margin="normal"
            />
          )
        }}
        freeSolo
        clearOnBlur
      />
    </FormControl>
  )
}

export default RepositoryAutocomplete
