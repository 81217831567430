/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { useState, Fragment } from 'react'

import { ActionButton } from '@jeeves/components/Primitives'
import ServiceAccountDialog from './ServiceAccountDialog'

const CreateServiceAccount = ({ roles, refresh }) => {
  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Fragment>
      <ActionButton onClick={() => setOpen(true)} />
      <ServiceAccountDialog roles={roles} open={open} onClose={handleClose} refresh={refresh} />
    </Fragment>
  )
}

export default CreateServiceAccount
