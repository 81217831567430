import React from 'react'
import Stack from '@mui/material/Stack'

import { Button } from '@jeeves/new-components'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { useHistory } from 'react-router-dom'

const FileBrowser = () => {
  return (
    <Stack sx={{ minHeight: '248px', alignItems: 'center', justifyContent: 'center' }}>
      <Button endIcon={<OpenInNewIcon />} onClick={() => window.open('s3Browser', '_blank')}>
        Access S3
      </Button>
    </Stack>
  )
}

export default FileBrowser
