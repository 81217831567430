import React from 'react'
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import useRepos from '../../pages/Repositories/hooks/useRepos'
import { Tooltip } from '@material-ui/core'

import {
  MariaDBIcon,
  MongoDBIcon,
  MySQLIcon,
  OracleIcon,
  PostgreSQLIcon,
  RedshiftIcon,
  S3Icon,
  SnowflakeIcon,
  SQLServerIcon,
  DenodoIcon,
  DremioIcon,
  DynamoDBIcon,
  DynamoDBStreamsIcon,
} from './index'

const getRepoIcon = repoType => {
  switch (repoType) {
    case 'postgresql':
      return <PostgreSQLIcon />
    case 'sqlserver':
      return <SQLServerIcon />
    case 'mariadb':
      return <MariaDBIcon />
    case 'mysql':
      return <MySQLIcon />
    case 'oracle':
      return <OracleIcon />
    case 'mongodb':
      return <MongoDBIcon />
    case 's3':
      return <S3Icon />
    case 'oracle':
      return <OracleIcon />
    case 'redshift':
      return <RedshiftIcon />
    case 'snowflake':
      return <SnowflakeIcon />
    case 'denodo':
      return <DenodoIcon />
    case 'dremio':
      return <DremioIcon />
    case 'dynamodb':
      return <DynamoDBIcon />
    case 'dynamodbstreams':
      return <DynamoDBStreamsIcon />
    default:
      return null
  }
}

export const RepoIcon = ({ repoType }) => {
  return <React.Fragment>{getRepoIcon(repoType)}</React.Fragment>
}
