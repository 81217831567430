import { Stack, Typography } from '@mui/material'
import Confetti from 'react-confetti'

import DrHooThumbs from './DrHooThumbs.png'

const SetupWizardStep7 = () => {
  return (
    <>
      <Stack spacing={4} sx={{ alignItems: 'center', marginTop: 'auto' }}>
        <Stack spacing={2} sx={{ alignItems: 'center' }}>
          <Typography variant="h2" sx={{ color: 'text.primary' }}>
            Hooray!
          </Typography>

          <Typography
            sx={{ color: 'text.primary', fontSize: '1rem', fontWeight: 400, lineHeight: '22.8px' }}
          >
            Enjoy using our service.
          </Typography>
        </Stack>

        <img src={DrHooThumbs} alt="Dr. Hoo Thumbs" />
      </Stack>

      <Confetti recycle={false} numberOfPieces={400} gravity={0.25} />
    </>
  )
}

export default SetupWizardStep7
