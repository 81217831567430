/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { Fragment, Component } from 'react'
import { withStyles } from '@material-ui/core/styles'

import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'

const styles = theme => ({
  list: {
    '& *': {
      fontSize: '12px',
    },
  },
  item: {
    paddingLeft: 0,
    paddingRight: 0,
    '& svg': {
      color: theme.palette.secondary.black,
    },
    '& *': {
      paddingRight: 0,
      marginRight: 0,
      color: theme.palette.secondary.main,
    },
  },
})

// eslint-disable-next-line
const PERMISSIONS = [
  { value: 'User', level: 1, label: 'Diagnose the service' },
  { value: 'User', level: 1, label: 'View documentation' },
  { value: 'User', level: 1, label: 'Engage with support' },
  { value: 'Admin', level: 2, label: 'Configure the service' },
  { value: 'Admin', level: 2, label: 'User management on Users' },
  { value: 'Super Admin', level: 3, label: 'Handle Billing and Subscription' },
  { value: 'Super Admin', level: 3, label: 'User management on Admins' },
]

class Permissions extends Component {
  state = {
    open: false,
  }

  handleClickOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  getRoleName = roleId => {
    if (!roleId) {
      return ''
    }
    const { roles } = this.props
    const role = roles.find(role => role.id === roleId)
    return role.name
  }

  handleActivePermission = level => {
    const { roleId } = this.props
    const roleName = this.getRoleName(roleId)

    if (roleName === 'User' && level === 1) return true
    if (roleName === 'Admin' && level >= 1 && level <= 2) return true
    if (roleName === 'Super Admin' && level >= 1) return true
  }

  sortRoles = (a, b) => (a.index > b.index ? 1 : a.index < b.index ? -1 : 0)

  render() {
    const { roleId, name, onChange, roles } = this.props
    return (
      <Fragment>
        <InputLabel htmlFor="permition-edit">Role</InputLabel>
        <Select value={roleId} onChange={onChange} name={name} id="permissions">
          {roles.map(role => {
            return (
              <MenuItem value={role.id} key={role.id}>
                {role.name}
              </MenuItem>
            )
          })}
        </Select>
        {/*
        <Grid container>
          <Grid item>
            <List className={classes.list}>
              <ListItem dense className={classes.item}>
                <ListItemIcon>
                  <IconAccountBox />
                </ListItemIcon>
                <ListItemText primary="Permissions details:" />
              </ListItem>

              {PERMISSIONS.map(permission => (
                <ListComponent
                  permission={permission}
                  key={permission.label}
                  active={this.handleActivePermission(permission.level)}
                />
              ))}
            </List>
          </Grid>
        </Grid> */}
      </Fragment>
    )
  }
}

export default withStyles(styles)(Permissions)
