import * as React from 'react'
import { useFragment_experimental, gql } from '@apollo/client'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { BindDataRepo, Bindings } from './components'
import { LearnMore } from '@jeeves/new-components'

const Repositories_queryFragment = gql`
  fragment Repositories_query on Query {
    ...BindDataRepo_query
    ...Bindings_query
  }
  ${BindDataRepo.fragments.BindDataRepo_queryFragment}
  ${Bindings.fragments.Bindings_queryFragment}
`

const Repositories = ({ query }) => {
  const { data } = useFragment_experimental({
    fragment: Repositories_queryFragment,
    fragmentName: 'Repositories_query',
    from: query,
  })

  const refreshCurrentTableViewCallbackRef = React.useRef();
    function registerRefreshCurrentTableViewCallback(callback)
    {
      refreshCurrentTableViewCallbackRef.current = callback;
    }

  return (
    <Stack spacing={4}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Typography variant="body1" sx={{ color: 'text.secondary' }}>
          Bind data repositories to this sidecar.{' '}
          <LearnMore docsPath="sidecars/sidecar-bind-repo#bind-a-repository-to-a-sidecar" />
        </Typography>

        <BindDataRepo query={data} refreshCurrentTableViewCallbackRef={refreshCurrentTableViewCallbackRef} />
      </Box>

      <Bindings query={data} registerRefreshCurrentTableViewCallback={registerRefreshCurrentTableViewCallback} />
    </Stack>
  )
}

Repositories.fragments = {
  Repositories_queryFragment,
}

export default Repositories
