/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import styled from '@emotion/styled'
import { useEffect, useRef, useState } from 'react'
import { Controller } from 'react-hook-form'
import { grey } from '@material-ui/core/colors'
import useDeepCompareEffect from 'use-deep-compare-effect'

import MonacoEditor from 'react-monaco-editor'
import { setDiagnosticsOptions } from 'monaco-yaml'
import YAML from 'yaml'

export const CodeWrapper = styled.div(
  ({ editing }) => css`
    padding: 0;
    margin: 0;
    border: 1px solid ${editing ? grey[400] : grey[100]};
    transition: border 0.2s ease-in-out;
    border-radius: 4px;
    overflow: hidden;
    height: 1px;
    min-height: 400px;
  `
)

const CodeEditor = ({ control, trigger, name }) => {
  return (
    <Controller
      name={name}
      control={control}
      // rules={{
      //   validate: () => monacoEditorMarkers.length === 0,
      // }}
      render={({ field: { onChange, value } }) => {
        return (
          <CodeWrapper>
            <MonacoEditor
              language="yaml"
              defaultValue={value}
              value={value}
              onChange={onChange}
              // editorDidMount={editorDidMount}
              options={{
                minimap: {
                  enabled: false,
                },
              }}
            />
          </CodeWrapper>
        )
      }}
    />
  )
}

export default CodeEditor
