import { useContext } from 'react'

import { DataMapContext } from '../contexts/DataMapContext'
import ExpressClient from '../../../clients/express'
import { useAuth } from '../../../components/Auth'
import usePopup from '../../../components/PopupMessage/hooks/usePopup'

import { get as lodashGet } from 'lodash'
import YAML from 'yaml'

const useDataMap = () => {
  const { setPopup, popupTypes } = usePopup()
  const { user, getTokenSilently } = useAuth()
  const [state, setState] = useContext(DataMapContext)

  const { currentDialog, reports } = state

  const getAuthor = () => {
    let author
    if (user.given_name === '' || user.family_name === '') {
      author = user.email
    } else {
      author = [user.given_name, user.family_name].join(' ')
    }
    return author
  }

  const ec = new ExpressClient(getTokenSilently)

  const handleRequestSort = (event, property) => {
    const orderBy = property
    let order = 'asc'
    if (state.orderBy === property && state.order === 'asc') {
      order = 'desc'
    }
    setState(state => ({ ...state, order, orderBy }))
  }

  const handleChangePage = (event, page) => {
    setState(state => ({ ...state, page }))
  }

  const handleChangeRowsPerPage = event => {
    setState(state => ({ ...state, rowsPerPage: event.target.value }))
  }

  const updateDataMap = () => {
    setState(state => ({ ...state, crudCount: state.crudCount + 1 }))
  }

  const setCurrentDialog = dialog => {
    setState(state => ({ ...state, currentDialog: dialog }))
  }

  const getRepoNames = async () => {
    try {
      const repoNames = await ec.get('/repos/names').then(res => res.data)
      return repoNames.sort()
    } catch (e) {
      console.log('useDataMap.getRepoNames error: ', e)
    }
  }

  const getDataMap = async () => {
    try {
      const datamap = await ec.get('/datamap').then(res => res.data)
      return datamap
    } catch (e) {
      const popupMessage = lodashGet(e, 'response.data.error.message.Message', '')
      setPopup(popupTypes.ERROR, popupMessage)
    }
  }

  const deleteDataMap = async () => {
    try {
      const datamap = await ec.delete('/datamap').then(res => res.data)
      return datamap
    } catch (e) {
      const popupMessage = lodashGet(e, 'response.data.error.message.Message', '')
      setPopup(popupTypes.ERROR, popupMessage)
    }
  }

  const putDataMap = async input => {
    const datamapObj = { yamlDatamap: input }
    try {
      const datamap = await ec.put('/datamap', datamapObj).then(res => res.data)
      return datamap
    } catch (e) {
      console.log('put datamap: ', e.response.data.error.message)
      const popupMessage = lodashGet(e, 'response.data.error.message.Message', '')
      setPopup(popupTypes.ERROR, popupMessage)
      throw e
    }
  }

  const triggerSearch = async (repos, searchTerms, endpoint, isChangedEndpoint) => {
    const sanitizedSearchTerms = searchTerms.replace(/\s/g, '').split(',')

    try {
      let searchIssuer
      if (user.given_name === '' || user.family_name === '') {
        searchIssuer = user.email
      } else {
        searchIssuer = [user.given_name, user.family_name].join(' ')
      }

      if (isChangedEndpoint) {
        await ec.request({
          method: 'POST',
          url: `/discovery/explorer/register`,
          data: { endpoint },
        })
      }

      await ec.request({
        method: 'POST',
        url: `/discovery/reports`,
        data: { searchIssuer: searchIssuer, searchTerms: sanitizedSearchTerms, repos },
      })
      updateDataMap()
    } catch (e) {
      console.log('useDataMap.handleOnSearch error: ', e)
      // TODO: return sanitized error message from express
      const popupMessage = 'Invalid input. Please try again'
      setPopup(popupTypes.ERROR, popupMessage)
      throw e
    }
  }

  const generatePolicyTemplate = async (reportId, policyName, locations, roles) => {
    const locationsObj = YAML.parse(locations)
    const rolesObj = YAML.parse(roles)
    const author = getAuthor()
    const reqObj = { policyName, author, locations: locationsObj, roles: rolesObj }
    try {
      await ec.request({
        method: 'POST',
        url: `/discovery/reports/${reportId}/policy`,
        data: reqObj,
      })
    } catch (e) {
      const popupMessage = lodashGet(e, 'response.data.error.message', '')
      setPopup(popupTypes.ERROR, popupMessage)
      throw e
    }
  }

  return {
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
    triggerSearch,
    getRepoNames,
    setCurrentDialog,
    currentDialog,
    reports,
    updateDataMap,
    getAuthor,
    generatePolicyTemplate,
    ec,
    setPopup,
    popupTypes,

    getDataMap,
    putDataMap,
    deleteDataMap,

    lodashGet,
    user,
  }
}

export default useDataMap
