import * as React from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import RejectPendingApprovalDialog from '../RejectPendingApprovalDialog'
import RejectPendingAmendmentDialog from '../RejectPendingAmendmentDialog'
import GrantApprovalDialog from '../GrantApprovalDialog'
import HistoryItem from './HistoryItem'
import ActionableRequestDetailsContainer from './ActionableRequestDetailsContainer'

const ApprovalHistory = ({ approval }) => {
  const [rejectPendingApprovalDialogOpen, setRejectPendingApprovalDialogOpen] =
    React.useState(false)
  const [rejectPendingAmendmentDialogOpen, setRejectPendingAmendmentDialogOpen] =
    React.useState(false)
  const [grantApprovalDialogOpen, setGrantApprovalDialogOpen] = React.useState(false)

  const approvalHistory = [...approval.history, ...(approval?.pendingAmendment?.history ?? [])]
  const ssoUser = approval.requester.name

  // Display history items from newest to oldest
  const sortedHistory = [...approvalHistory].sort((a, b) => {
    if (a.timestamp > b.timestamp) {
      return -1 // or 1 for oldest to most recent
    } else if (a.timestamp < b.timestamp) {
      return 1 // or -1 for oldest to most recent
    } else {
      return 0
    }
  })

  const openRejectApprovalDialog = () => {
    if (approval.__typename === 'PendingApproval') {
      setRejectPendingApprovalDialogOpen(true)
    } else {
      setRejectPendingAmendmentDialogOpen(true)
    }
  }

  return (
    <React.Fragment>
      <Stack spacing={2}>
        <Typography variant="h4" sx={{ color: 'text.secondary' }}>
          History
        </Typography>

        <Stack spacing={2}>
          {sortedHistory.map((historyItem, index) => {
            const isFirstItem = index === 0
            const action = historyItem.action

            return (
              <Stack key={historyItem.timestamp} spacing={2}>
                <HistoryItem historyItem={historyItem} />

                {isFirstItem && (action === 'REQUEST' || action === 'AMEND') && (
                  <ActionableRequestDetailsContainer
                    approval={approval}
                    openRejectApprovalDialog={openRejectApprovalDialog}
                    openGrantApprovalDialog={() => setGrantApprovalDialogOpen(true)}
                  />
                )}
              </Stack>
            )
          })}
        </Stack>
      </Stack>

      <RejectPendingAmendmentDialog
        open={rejectPendingAmendmentDialogOpen}
        closeDialog={() => setRejectPendingAmendmentDialogOpen(false)}
        ssoUser={ssoUser}
        approvalId={approval?.pendingAmendment?.id}
      />

      <RejectPendingApprovalDialog
        open={rejectPendingApprovalDialogOpen}
        closeDialog={() => setRejectPendingApprovalDialogOpen(false)}
        ssoUser={ssoUser}
        approvalId={approval.id}
      />

      <GrantApprovalDialog
        open={grantApprovalDialogOpen}
        closeDialog={() => setGrantApprovalDialogOpen(false)}
        ssoUser={ssoUser}
        approvalId={
          approval.__typename === 'PendingApproval' ? approval.id : approval?.pendingAmendment?.id
        }
      />
    </React.Fragment>
  )
}

export default ApprovalHistory
