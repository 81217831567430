import { Link, useParams } from 'react-router-dom'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import { Link as MuiLink, Stack, Typography } from '@mui/material'

const BackButton = () => {
  const { id: sidecarId } = useParams<{ id: string }>()

  return (
    <MuiLink component={Link} to={`/sidecars/${sidecarId}/instances`} underline="none">
      <Stack direction="row" spacing={0.5} sx={{ alignItems: 'center', color: 'text.secondary' }}>
        <KeyboardArrowLeftIcon sx={{ fontSize: '16px' }} />
        <Typography variant="h6">Back to Sidecar Instances</Typography>
      </Stack>
    </MuiLink>
  )
}

export default BackButton
