/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import styled from '@emotion/styled'

const SimpleDashboard = styled.div`
  background-color: #f0f2f5;
  height: 100vh;
  padding: 0;
  margin: 0;
  display: block;
  display: ${({ centered }) => (centered ? 'flex' : 'block')};
  overflow: auto;
  position: relative;

  ${({ centered }) =>
    centered &&
    css`
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-content: center;
    `}
`

export default SimpleDashboard
