import MuiTabs from '@mui/material/Tabs'
import MuiTab from '@mui/material/Tab'
import { styled } from '@mui/material'

export const Tabs = styled(MuiTabs)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.cyralColors.grey[200]}`,
})) as typeof MuiTabs

export const Tab = styled(MuiTab)(({ theme }) => ({
  padding: theme.spacing(1, 3),
})) as typeof MuiTab
