import React from 'react'
import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Tooltip, Tag } from '@jeeves/new-components'

const createRolesList = roles => {
  return (
    <div style={{ flexDirection: 'column', 'overflowY': 'scroll', maxHeight: '130px' }}>
      {roles.map((element, index) => {
        if (index > 0) {
          return <Typography variant="body2">{element}</Typography>
        }
      })}
    </div>
  )
}

const LocalAccountDiscoveryRole = ({ roles, lineColor, setIsHoveringOtherElement }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Typography variant="body2" sx={{ color: lineColor }}>
        {roles.length == 0 && 'None'}
        {roles.length >= 1 && roles[0]}
      </Typography>
      {roles.length > 1 && (
        <div>
          <Tooltip
            onClose={() => setIsHoveringOtherElement(false)}
            onMouseEnter={() => setIsHoveringOtherElement(true)}
            title={createRolesList(roles)}
          >
            <Tag
              key="customRolesSize"
              sx={{ maxWidth: '50px', borderColor: 'cyralColors.grey.300' }}
              color="grey"
            >
              {'+ ' + (roles.length - 1)}
            </Tag>
          </Tooltip>
        </div>
      )}
    </div>
  )
}

export default LocalAccountDiscoveryRole
