import { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { FilterData } from './utils'

export const useAuditFilter = (
  filterDefaultValues: FilterData,
  handleFilter: (newFilter: FilterData) => void
) => {
  const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLButtonElement) | null>(null)
  const [open, setOpen] = useState(false)

  const methods = useForm({ defaultValues: filterDefaultValues })

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget)
    setOpen(true)
  }

  const handleReset = useCallback(() => {
    methods.reset(filterDefaultValues)
  }, [filterDefaultValues, methods])

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const onSubmit = async (data: FilterData) => {
    handleFilter(data)
    handleClose()
  }

  return {
    methods,
    anchorEl,
    open,
    handleOpen,
    handleClose,
    handleReset,
    onSubmit: methods.handleSubmit(onSubmit),
  }
}
