import { ReactNode } from 'react'
import { Stack, Typography } from '@mui/material'

interface Props {
  icon: ReactNode
  title: string
  content: ReactNode
}

const SetupWizardStep4Card = ({ icon, title, content }: Props) => (
  <Stack
    spacing={4}
    sx={{
      alignItems: 'center',
      border: 1,
      borderRadius: theme => theme.radii.sm,
      borderColor: `primary.main`,
      padding: 4,
      width: '293.33px',
      height: '304.09px',
    }}
  >
    {icon}

    <Stack spacing={1} sx={{ alignItems: 'center' }}>
      <Typography
        sx={{
          color: 'text.primary',
          fontSize: '1.375rem',
          fontWeight: 600,
          lineHeight: '35.2px',
        }}
      >
        {title}
      </Typography>

      <Typography
        sx={{
          color: 'text.primary',
          fontSize: '1.125rem',
          fontWeight: 400,
          lineHeight: '28.8px',
          ul: {
            margin: 0,
          },
        }}
      >
        {content}
      </Typography>
    </Stack>
  </Stack>
)

export default SetupWizardStep4Card
