import React, { useState } from 'react'
const WatchesContext = React.createContext([{}, () => {}])

const WatchesProvider = props => {
  const [state, setState] = useState({
    watches: [],
    crudCount: 0,
    page: 0,
    rowsPerPage: 10,
    order: 'asc',
    orderBy: '',
    expanded: [],
    renameError: '',
    dialog: '',
  })

  return (
    <WatchesContext.Provider value={[state, setState]}>{props.children}</WatchesContext.Provider>
  )
}

export { WatchesContext, WatchesProvider }
