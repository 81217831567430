import React from 'react'
import { gql, useMutation } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { useLatest } from 'react-use'

import StepOne from './Steps/StepOne'
import { StepTwo } from './Steps/StepTwo'
import StepThree from './Steps/StepThree'
import { StepFour } from './Steps/StepFour'
import StepFive from './Steps/StepFive'

import {
  useAppsAndBiToolsState,
  useAppsAndBiToolsDispatch,
} from '../../contexts/AppsAndBiToolsContext'

import { SERVICE_ACCOUNT_FIELDS } from '../../../../hooks/useRepositoryDetail'

import { CyralIcon, LookerIcon, TableauIcon } from '@jeeves/new-components'

const REPO_FIELDS = gql`
  fragment repoFields on Repo {
    id
    ... on RepoWithServiceAccountsField {
      serviceAccounts {
        ...serviceAccountFields
      }
    }
  }
  ${SERVICE_ACCOUNT_FIELDS}
`

const CREATE_SERVICE_ACCOUNT = gql`
  mutation CreateServiceAccount($repoId: String!, $serviceAccount: CreateServiceAccountInput!) {
    createServiceAccount(repoId: $repoId, serviceAccount: $serviceAccount) {
      serviceAccount {
        id
        name
        fetchGroups
        connectionDriverInstance {
          id
          name
          type
        }
      }
      repo {
        ...repoFields
      }
    }
  }
  ${REPO_FIELDS}
`

const UPDATE_SERVICE_ACCOUNT = gql`
  mutation UpdateServiceAccount(
    $repoId: String!
    $serviceAccountId: String!
    $serviceAccount: CreateServiceAccountInput!
  ) {
    updateServiceAccount(
      repoId: $repoId
      serviceAccountId: $serviceAccountId
      serviceAccount: $serviceAccount
    ) {
      serviceAccount {
        # ...serviceAccountFields
        id
        name
        fetchGroups
        connectionDriverInstance {
          id
          name
          type
        }
      }
      repo {
        ...repoFields
      }
    }
  }
  ${REPO_FIELDS}
  # ${SERVICE_ACCOUNT_FIELDS}
`

const allConnectionDrivers = [
  {
    label: 'Looker',
    value: 'looker',
    avatar: <LookerIcon sx={{ fontSize: 48 }} />,
  },
  {
    label: 'Tableau',
    value: 'tableau',
    avatar: <TableauIcon sx={{ fontSize: 48 }} />,
  },
  {
    label: 'Custom Application',
    value: 'cyralContext',
    avatar: <CyralIcon sx={{ fontSize: 48 }} />,
  },
]

const getConnectionDrivers = repoType => {
  return {
    sqlserver: allConnectionDrivers.filter(instanceType => {
      return ['looker', 'tableau', 'cyralContext'].includes(instanceType.value)
    }),
    mysql: allConnectionDrivers.filter(instanceType => {
      return ['looker', 'tableau', 'cyralContext'].includes(instanceType.value)
    }),
    postgresql: allConnectionDrivers.filter(instanceType => {
      return ['looker', 'tableau', 'cyralContext'].includes(instanceType.value)
    }),
    redshift: allConnectionDrivers.filter(instanceType => {
      return ['looker', 'tableau', 'cyralContext'].includes(instanceType.value)
    }),
    snowflake: allConnectionDrivers.filter(instanceType => {
      return ['looker', 'tableau', 'cyralContext'].includes(instanceType.value)
    }),
    denodo: allConnectionDrivers.filter(instanceType => {
      return ['tableau', 'cyralContext'].includes(instanceType.value)
    }),
  }[repoType]
}

const useUpsertDatabaseAccountWizard = ({
  repoType,
  samlIntegrations,
  selectedSidecarEdge,
  serviceAccounts,
  refetch,
}) => {
  const { id: repoId } = useParams()
  const [activeStep, setActiveStep] = React.useState(0)
  const [createServiceAccount, { data, loading, error }] = useMutation(CREATE_SERVICE_ACCOUNT)
  const connectionDrivers = getConnectionDrivers(repoType)

  const [
    updateServiceAccount,
    {
      data: updateServiceAccountData,
      loading: updateServiceAccountLoading,
      error: updateServiceAccountError,
    },
  ] = useMutation(UPDATE_SERVICE_ACCOUNT)

  const {
    isRegistering,
    isUpdating,
    databaseAccountInfo: initialDatabaseAccountInfo,
  } = useAppsAndBiToolsState()
  const dispatch = useAppsAndBiToolsDispatch()

  const initialDatabaseAccountInfoState = {
    id: initialDatabaseAccountInfo?.id || '',
    serviceAccountName: initialDatabaseAccountInfo?.name || '',
    connectionDriverType: isUpdating
      ? initialDatabaseAccountInfo.connectionDriverInstance?.type ?? 'cyralContext'
      : connectionDrivers[0].value,
    fetchGroups: initialDatabaseAccountInfo?.fetchGroups ?? true,
    applicationName: initialDatabaseAccountInfo?.connectionDriverInstance?.name || '',
  }

  const [databaseAccountInfo, setDatabaseAccountInfo] = React.useState(
    initialDatabaseAccountInfoState
  )
  const latestDatabaseAccountInfo = useLatest(databaseAccountInfo)

  const prevStep = () => setActiveStep(currStep => currStep - 1)
  const nextStep = React.useCallback(() => {
    setActiveStep(currStep => currStep + 1)
  }, [])

  const handleMutateServiceAccount = async () => {
    // console.log('handleMutateServiceAccount: ', latestDatabaseAccountInfo.current)
    const { id, serviceAccountName, connectionDriverType, fetchGroups, applicationName } =
      latestDatabaseAccountInfo.current

    const serviceAccount = {
      name: serviceAccountName,
      fetchGroups,
      connectionDriverInstance: {
        name: applicationName,
        type: connectionDriverType,
      },
    }

    try {
      if (isUpdating) {
        await updateServiceAccount({
          variables: {
            repoId,
            serviceAccountId: id,
            serviceAccount,
          },
        })
      }

      if (isRegistering) {
        await createServiceAccount({
          variables: {
            repoId,
            serviceAccount,
          },
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const closeWizard = () => {
    dispatch({ type: 'reset' })
  }

  const STEPS = [
    <StepOne
      onCancel={closeWizard}
      nextStep={nextStep}
      serviceAccountName={databaseAccountInfo.serviceAccountName}
      initialServiceAccountName={initialDatabaseAccountInfoState.serviceAccountName}
      setDatabaseAccountInfo={setDatabaseAccountInfo}
      serviceAccounts={serviceAccounts}
    />,
    <StepTwo
      connectionDrivers={connectionDrivers}
      prevStep={prevStep}
      nextStep={nextStep}
      selectedConnectionDriver={databaseAccountInfo.connectionDriverType}
      setDatabaseAccountInfo={setDatabaseAccountInfo}
    />,
    <StepThree
      prevStep={prevStep}
      nextStep={nextStep}
      setDatabaseAccountInfo={setDatabaseAccountInfo}
      fetchGroups={databaseAccountInfo.fetchGroups}
      samlIntegrations={samlIntegrations}
      refetch={refetch}
    />,
    <StepFour
      prevStep={prevStep}
      nextStep={nextStep}
      serviceAccountName={databaseAccountInfo.serviceAccountName}
      selectedConnectionDriverType={databaseAccountInfo.connectionDriverType}
      selectedSidecarEdge={selectedSidecarEdge}
      repoType={repoType}
    />,
    <StepFive
      prevStep={prevStep}
      closeWizard={closeWizard}
      applicationName={databaseAccountInfo.applicationName}
      setDatabaseAccountInfo={setDatabaseAccountInfo}
      handleMutateServiceAccount={handleMutateServiceAccount}
    />,
  ]

  return {
    activeStep,
    steps: STEPS,
  }
}

export default useUpsertDatabaseAccountWizard
