import { Controller } from 'react-hook-form'
import { Box, Stack, FormControlLabel, Radio, FormHelperText, FormControl } from '@mui/material'

import InputTagsIdentity from '@jeeves/pages/RepositoryDetail/Tabs/Policies/views/AddPolicy/components/InputTagsIdentity'

const IdentitiesCustomOption = ({
  value,
  label,
  isSubmitting,
  valueKey,
  tags,
  isSelected,
  control,
  error,
  onAddSpecificIdentities,
  onDeleteSpecificIdentities,
}) => {
  return (
    <Stack spacing={1}>
      <FormControlLabel
        value={value}
        control={<Radio size="small" disableRipple />}
        label={label}
        slotProps={{
          typography: {
            variant: 'h6',
            sx: {
              color: 'text.primary',
            },
          },
        }}
      />
      {isSelected && (
        <Box sx={{ paddingLeft: 3 }}>
          <FormControl variant="standard" error={Boolean(error)} sx={{ display: 'flex' }}>
            <Controller
              name={valueKey}
              control={control}
              rules={{
                validate: tags => {
                  return (isSelected && tags?.length > 0) || 'Please enter at least one identity'
                },
              }}
              render={() => (
                <InputTagsIdentity
                  required
                  tags={tags}
                  isSubmitting={isSubmitting}
                  control={control}
                  onAdd={onAddSpecificIdentities}
                  onDelete={onDeleteSpecificIdentities}
                />
              )}
            />
            <FormHelperText sx={{ typography: 'body2' }} error={!!error}>
              {error?.message}
            </FormHelperText>
          </FormControl>
        </Box>
      )}
    </Stack>
  )
}

export default IdentitiesCustomOption
