/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import React from 'react'
import { jsx, css } from '@emotion/react'

import cloudWatchIcon from '../assets/cloudWatchIcon.svg'
import customIcon from '../assets/customIcon.svg'
import datadogIcon from '../assets/datadogIcon.svg'
import elkIcon from '../assets/elkIcon.png'
import splunkIcon from '../assets/splunkIcon.svg'
import sumoLogicIcon from '../assets/sumoLogicIcon.svg'

const iconSrcMap = {
  CloudWatchIntegration: cloudWatchIcon,
  CustomLoggingIntegration: customIcon,
  DatadogIntegration: datadogIcon,
  ELKIntegration: elkIcon,
  SplunkIntegration: splunkIcon,
  SumoLogicIntegration: sumoLogicIcon,
}

const LoggingIntegrationIcon = ({ integrationType }) => {
  const src = iconSrcMap[integrationType]

  if (!src) {
    return null
  }

  return <img alt={integrationType} src={src} height="24px" />
}

export default LoggingIntegrationIcon
