import * as React from 'react'
import { useMutation, gql } from '@apollo/client'
import Typography from '@mui/material/Typography'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

import { Button, Dialog, DialogActions } from '@jeeves/new-components'

const DELETE_NETWORK_SHIELD_RULE = gql`
  mutation DeleteNetworkShieldRule($ruleId: ID!) {
    deleteNetworkShieldRule(ruleId: $ruleId) {
      deletedRuleId
    }
  }
`

const DeleteNetworkShieldRuleModal = ({ open, closeModal, rule }) => {
  const [deleteNetworkShieldRule, { data, loading, error }] = useMutation(
    DELETE_NETWORK_SHIELD_RULE,
    {
      variables: {
        ruleId: rule.id,
      },
      update(cache, { data: { deleteNetworkShieldRule } }) {
        const normalizedId = cache.identify({
          id: deleteNetworkShieldRule.deletedRuleId,
          __typename: 'NetworkShieldRule',
        })
        cache.evict({ id: normalizedId })
        cache.gc()
      },
      onCompleted: data => {
        closeModal()
      },
    }
  )

  const onDelete = async () => {
    try {
      await deleteNetworkShieldRule()
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <Dialog onClose={closeModal} open={open}>
      <DialogTitle variant="h3">Delete Rule</DialogTitle>
      <DialogContent>
        <Typography variant="body1" sx={{ color: 'text.secondary' }}>
          Are you sure you want to delete{' '}
          <Typography component="span" variant="h6" sx={{ color: 'cyralColors.primary.300' }}>
            {rule.name}
          </Typography>
          ?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={closeModal} autoFocus>
          Cancel
        </Button>
        <Button variant="outlined" color="error" onClick={onDelete} loading={loading} autoFocus>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteNetworkShieldRuleModal
