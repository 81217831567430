import { useContext } from 'react'
import { get as lodashGet } from 'lodash'

import { useAuth } from '@jeeves/components/Auth'
import ExpressClient from '@jeeves/clients/express'
import usePopup from '@jeeves/components/PopupMessage/hooks/usePopup'
import useRepos from '@jeeves/pages/Repositories/hooks/useRepos'
import { WatchesContext } from '../contexts/WatchesContext'
import { REPO_TYPES_META } from '../../Repositories/constants/repoTypes'

const useWatches = () => {
  const { getTokenSilently } = useAuth()
  const ec = new ExpressClient(getTokenSilently)

  const { showError } = usePopup()
  const { logsLink, upd } = useRepos()

  const [state, setState] = useContext(WatchesContext)
  const { watches, dialog } = state

  // TABLE FOOTER/PAGINATION FUNCTIONS
  const handleRequestSort = (_, property) => {
    const orderBy = property
    let order = 'asc'
    if (state.orderBy === property && state.order === 'asc') {
      order = 'desc'
    }
    setState(state => ({ ...state, order, orderBy }))
  }

  const handleChangePage = (_, page) => {
    setState(state => ({ ...state, page }))
  }

  const handleChangeRowsPerPage = event => {
    setState(state => ({ ...state, rowsPerPage: event.target.value }))
  }

  const handleChange = event => {
    const { name, value } = event.target
    event.persist()
    setState(state => ({ ...state, [name]: [value] }))
  }

  const updateWatches = () => {
    setState(state => ({ ...state, crudCount: state.crudCount + 1 }))
  }

  const setCurrentDialog = dialog => {
    setState(state => ({ ...state, dialog }))
  }

  const getWatchMeta = type => {
    return REPO_TYPES_META[type] ? REPO_TYPES_META[type].watches : null
  }

  const handleOnCreate = async (repoId, inputs, repoType) => {
    try {
      const isArray = key =>
        Array.isArray(
          REPO_TYPES_META[repoType].watches.columns.find(col => col.id === key).defaultValue
        )
      const listifiedInputs = Object.entries(inputs).reduce(
        (prevObj, [key, value]) => ({
          ...prevObj,
          [key]: isArray(key) ? value.replace(/\s/g, '').split(',') : value,
        }),
        {}
      )
      const watch = await ec.post(`/repos/${repoId}/watches`, listifiedInputs).then(res => res.data)

      return watch
    } catch (e) {
      const popupMessage = lodashGet(e, 'response.data.error.message', '')
      showError(popupMessage)
    }
  }

  const handleViewLogs = async (watch = undefined, repoName, repoType) => {
    let queryParams = undefined
    if (watch) {
      const [{ id: schemaType }, { id: tableType }] = REPO_TYPES_META[repoType].watches.columns
      const schemaName = watch[schemaType]
      const tableName = watch[tableType]
      if (!logsLink) {
        return
      }
      queryParams = `_g=(filters:!(),refreshInterval:(pause:!f,value:5000),time:(from:now%2Fd,to:now%2Fd))&_a=(columns:!(_source),filters:!(('$state':(store:appState),meta:(alias:!n,disabled:!f,key:repo.name,negate:!f,params:(query:${repoName}),type:phrase),query:(match_phrase:(repo.name:${repoName}))),('$state':(store:appState),meta:(alias:!n,disabled:!f,key:request.datasetsAccessed.dataset,negate:!f,params:(query:${schemaName}.${tableName}),type:phrase),query:(match_phrase:(request.datasetsAccessed.dataset:${schemaName}.${tableName})))),interval:auto,query:(language:kuery,query:''))`
    }
    window.open(generateKibanaLink(logsLink, queryParams))
  }

  // const toggleAuditLogging = async (repoId, enableAuditLogging) => {
  //   const body = { enableAuditLogging: enableAuditLogging }
  //   try {
  //     const res = await ec.patch(`/repos/${repoId}/conf/analysis`, body).then(res => res.data)
  //     return res
  //   } catch (e) {
  //     const popupMessage = lodashGet(e, 'response.data.error.message', '')
  //     showError(popupMessage)
  //     throw e
  //   }
  // }

  const handleOnDelete = async (watch, watchMeta, repoId, getRepo) => {
    try {
      const colKeys = watchMeta.columns.map(col => col.id)
      await ec.request({
        method: 'DELETE',
        url: `/repos/${repoId}/watches`,
        params: {
          [colKeys[0]]: watch[colKeys[0]],
          [colKeys[1]]: watch[colKeys[1]],
        },
      })
      getRepo()
    } catch (e) {
      const popupMessage = lodashGet(e, 'response.data.error.message', '')
      showError(popupMessage)
    }
  }

  const generateKibanaLink = (path, queryParams) => {
    //queryParams is a string of query parameters separated by ampersand, but with no leading or trailing ampersand
    if (!path) return null

    // TO DO: make the id of saved search dynamic
    const discoverPath = '/app/discover#/view/4d38dd40-5f65-11eb-98ab-19f53b7b8041/'

    const httpPattern = /(http|https):\/\//

    path = httpPattern.test(path) ? path : `http://${path}`

    const securityTenantPattern = /\/\?security_tenant=global/

    path = securityTenantPattern.test(path)
      ? `${path.slice(
          0,
          securityTenantPattern.exec(path).index
        )}${discoverPath}?security_tenant=global${queryParams ? `&${queryParams}` : ''}`
      : `${path}${discoverPath}`

    console.log(path)
    return path
  }

  return {
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
    handleChange,
    updateWatches,
    watches,
    getWatchMeta,
    dialog,
    setCurrentDialog,
    handleOnCreate,
    handleOnDelete,
    handleViewLogs,
    ec,
    // toggleAuditLogging,
  }
}

export default useWatches
