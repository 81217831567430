import React, { useState, useEffect } from 'react'
import { Box, Stack, Typography, FormControlLabel, Checkbox, Switch } from '@mui/material'
import { Button, Select } from '@jeeves/new-components'
import { LearnMore } from './LearnMore'
import { gql, useMutation } from '@apollo/client'

const UPDATE_LOGS = gql`
  mutation UpdateRepoDataActivityLogsConfig(
    $repoId: ID!
    $input: UpdateRepoDataActivityLogsConfigInput!
  ) {
    updateRepoDataActivityLogsConfig(repoId: $repoId, input: $input) {
      repo {
        id
        name
        config {
          dataActivityLogs {
            logSettings
            redactLiteralValues
            enhanceDatabaseLogs
          }
        }
      }
    }
  }
`

const getCurrentDataAccessState = ({ readsState, DMLState, DDLState, privilegedState }) => {
  if (
    readsState.checkbox === 'checked' &&
    readsState.logFor === 'all' &&
    DMLState.checkbox === 'checked' &&
    DMLState.logFor === 'all' &&
    DDLState.checkbox === 'checked' &&
    DDLState.logFor === 'all' &&
    privilegedState
  ) {
    return 'checked'
  } else if (
    readsState.checkbox === 'unchecked' &&
    DMLState.checkbox === 'unchecked' &&
    DDLState.checkbox === 'unchecked' &&
    !privilegedState
  ) {
    return 'unchecked'
  } else {
    return 'indeterminate'
  }
}

const getInitialDataAccessState = logsData => {
  const readsState = getInitialReadsState(logsData)
  const DMLState = getInitialDMLState(logsData)
  const DDLState = getInitialDDLState(logsData)
  const privilegedState = logsData.includes('privilegedCommands')

  return getCurrentDataAccessState({ readsState, DMLState, DDLState, privilegedState })
}

const getInitialReadsState = logsData => {
  let readsState = {}
  if (logsData.includes('reads')) {
    readsState.checkbox = 'checked'
    readsState.logFor = 'all'
  } else if (logsData.includes('readsLabeledData')) {
    readsState.checkbox = 'checked'
    readsState.logFor = 'labeled'
  } else {
    readsState.checkbox = 'unchecked'
    readsState.logFor = 'all'
  }
  return readsState
}

const getInitialDMLState = logsData => {
  let DMLState = {}
  if (logsData.includes('DML')) {
    DMLState.checkbox = 'checked'
    DMLState.logFor = 'all'
  } else if (logsData.includes('DMLLabeledData')) {
    DMLState.checkbox = 'checked'
    DMLState.logFor = 'labeled'
  } else {
    DMLState.checkbox = 'unchecked'
    DMLState.logFor = 'all'
  }
  return DMLState
}

const getInitialDDLState = logsData => {
  let DDLState = {}
  if (logsData.includes('DDL')) {
    DDLState.checkbox = 'checked'
    DDLState.logFor = 'all'
  } else if (logsData.includes('DDLLabeledData')) {
    DDLState.checkbox = 'checked'
    DDLState.logFor = 'labeled'
  } else {
    DDLState.checkbox = 'unchecked'
    DDLState.logFor = 'all'
  }

  return DDLState
}

const SELECT_OPTIONS = [
  { label: 'all requests', value: 'all' },
  { label: 'labeled data only', value: 'labeled' },
]

export const Logs = ({ repoId, logsData, repoType }) => {
  const [updateLogs, { data, loading, error }] = useMutation(UPDATE_LOGS)

  const [redactLiteralValues, setRedactLiteralValues] = useState(logsData?.redactLiteralValues)
  const [enhanceDatabaseLogs, setEnhanceDatabaseLogs] = useState(logsData?.enhanceDatabaseLogs)

  const [dataAccessState, setDataAccessState] = useState(
    getInitialDataAccessState(logsData?.logSettings)
  )
  const [readsState, setReadsState] = useState(getInitialReadsState(logsData?.logSettings))
  const [DMLState, setDMLState] = useState(getInitialDMLState(logsData?.logSettings))
  const [DDLState, setDDLState] = useState(getInitialDDLState(logsData?.logSettings))
  const [privilegedState, setPrivilegedState] = useState(
    logsData?.logSettings.includes('privilegedCommands')
  )

  const [portScans, setPortScans] = useState(logsData?.logSettings.includes('portScans'))
  const [authenticationFailures, setAuthenticationFailures] = useState(
    logsData?.logSettings.includes('authenticationFailures')
  )
  const [fullTableScans, setFullTableScans] = useState(
    logsData?.logSettings.includes('fullTableScans')
  )
  const [connectionEvents, setConnectionEvents] = useState(
    logsData?.logSettings.includes('connectionEvents')
  )

  const [fieldsDirty, setFieldsDirty] = useState(false)

  const shallowCompare = (obj1, obj2) =>
    Object.keys(obj1).length === Object.keys(obj2).length &&
    Object.keys(obj1).every(key => obj1[key] === obj2[key])

  const resetState = () => {
    setRedactLiteralValues(logsData?.redactLiteralValues)
    setEnhanceDatabaseLogs(logsData?.enhanceDatabaseLogs)

    setDataAccessState(getInitialDataAccessState(logsData?.logSettings))
    setReadsState(getInitialReadsState(logsData?.logSettings))
    setDMLState(getInitialDMLState(logsData?.logSettings))
    setDDLState(getInitialDDLState(logsData?.logSettings))
    setPrivilegedState(logsData?.logSettings.includes('privilegedCommands'))
    setPortScans(logsData?.logSettings.includes('portScans'))
    setAuthenticationFailures(logsData?.logSettings.includes('authenticationFailures'))
    setFullTableScans(logsData?.logSettings.includes('fullTableScans'))
    setConnectionEvents(logsData?.logSettings.includes('connectionEvents'))
  }

  const fieldsSame = () => {
    return (
      redactLiteralValues == logsData?.redactLiteralValues &&
      enhanceDatabaseLogs == logsData?.enhanceDatabaseLogs &&
      dataAccessState == getInitialDataAccessState(logsData?.logSettings) &&
      shallowCompare(readsState, getInitialReadsState(logsData?.logSettings)) &&
      shallowCompare(DMLState, getInitialDMLState(logsData?.logSettings)) &&
      shallowCompare(DDLState, getInitialDDLState(logsData?.logSettings)) &&
      privilegedState == logsData?.logSettings.includes('privilegedCommands') &&
      portScans == logsData?.logSettings.includes('portScans') &&
      authenticationFailures == logsData?.logSettings.includes('authenticationFailures') &&
      fullTableScans == logsData?.logSettings.includes('fullTableScans') &&
      connectionEvents == logsData?.logSettings.includes('connectionEvents')
    )
  }

  const handleCancel = () => {
    resetState()
  }

  const handleDataAccessCheck = () => {
    if (dataAccessState === 'checked') {
      setDataAccessState('unchecked')
      setReadsState({ ...readsState, checkbox: 'unchecked' })
      setDMLState({ ...DMLState, checkbox: 'unchecked' })
      setDDLState({ ...DDLState, checkbox: 'unchecked' })
      setPrivilegedState(false)
    } else {
      setDataAccessState('checked')
      setReadsState({ logFor: 'all', checkbox: 'checked' })
      setDMLState({ logFor: 'all', checkbox: 'checked' })
      setDDLState({ logFor: 'all', checkbox: 'checked' })
      setPrivilegedState(true)
    }
  }

  const handleReadsSelect = e => {
    const updatedReadsState = { ...readsState, logFor: e.target.value }
    setReadsState(updatedReadsState)
    setDataAccessState(
      getCurrentDataAccessState({
        readsState: updatedReadsState,
        DMLState,
        DDLState,
        privilegedState,
      })
    )
  }

  const handleReadsCheck = () => {
    const newCheckState = readsState.checkbox === 'checked' ? 'unchecked' : 'checked'
    const updatedReadsState = { ...readsState, checkbox: newCheckState }
    setReadsState(updatedReadsState)
    setDataAccessState(
      getCurrentDataAccessState({
        readsState: updatedReadsState,
        DMLState,
        DDLState,
        privilegedState,
      })
    )
  }

  const handleDMLSelect = e => {
    const updatedDMLState = { ...DMLState, logFor: e.target.value }
    setDMLState(updatedDMLState)
    setDataAccessState(
      getCurrentDataAccessState({
        readsState,
        DMLState: updatedDMLState,
        DDLState,
        privilegedState,
      })
    )
  }

  const handleDMLCheck = () => {
    const newCheckState = DMLState.checkbox === 'checked' ? 'unchecked' : 'checked'
    const updatedDMLState = { ...DMLState, checkbox: newCheckState }
    setDMLState(updatedDMLState)
    setDataAccessState(
      getCurrentDataAccessState({
        readsState,
        DMLState: updatedDMLState,
        DDLState,
        privilegedState,
      })
    )
  }

  const handleDDLSelect = e => {
    const updatedDDLState = { ...DDLState, logFor: e.target.value }
    setDDLState(updatedDDLState)
    setDataAccessState(
      getCurrentDataAccessState({
        readsState,
        DMLState,
        DDLState: updatedDDLState,
        privilegedState,
      })
    )
  }

  const handleDDLCheck = () => {
    const newCheckState = DDLState.checkbox === 'checked' ? 'unchecked' : 'checked'
    const updatedDDLState = { ...DDLState, checkbox: newCheckState }
    setDDLState(updatedDDLState)
    setDataAccessState(
      getCurrentDataAccessState({
        readsState,
        DMLState,
        DDLState: updatedDDLState,
        privilegedState,
      })
    )
  }

  const handlePrivilegedCheck = () => {
    const newPrivilegedState = !privilegedState
    setPrivilegedState(newPrivilegedState)
    setDataAccessState(
      getCurrentDataAccessState({
        readsState,
        DMLState,
        DDLState,
        privilegedState: newPrivilegedState,
      })
    )
  }

  const getLogSettingsToSubmit = () => {
    let logSettingsToSubmit = []

    if (isAllLogSettingsSelected()) {
      logSettingsToSubmit.push('everything')
      return logSettingsToSubmit
    }

    if (readsState.checkbox === 'checked') {
      if (readsState.logFor === 'all') {
        logSettingsToSubmit.push('reads')
      } else {
        logSettingsToSubmit.push('readsLabeledData')
      }
    }
    if (DMLState.checkbox === 'checked') {
      if (DMLState.logFor === 'all') {
        logSettingsToSubmit.push('DML')
      } else {
        logSettingsToSubmit.push('DMLLabeledData')
      }
    }
    if (DDLState.checkbox === 'checked') {
      if (DDLState.logFor === 'all') {
        logSettingsToSubmit.push('DDL')
      } else {
        logSettingsToSubmit.push('DDLLabeledData')
      }
    }
    if (privilegedState) {
      logSettingsToSubmit.push('privilegedCommands')
    }
    if (portScans) {
      logSettingsToSubmit.push('portScans')
    }
    if (authenticationFailures) {
      logSettingsToSubmit.push('authenticationFailures')
    }
    if (fullTableScans) {
      logSettingsToSubmit.push('fullTableScans')
    }
    if (connectionEvents) {
      logSettingsToSubmit.push('connectionEvents')
    }

    // Manually remove unsupported settings for specific repo types
    const unsupportedS3 = setting => {
      return setting !== 'DML'
    }

    if (repoType === 's3') {
      return logSettingsToSubmit.filter(unsupportedS3)
    }

    return logSettingsToSubmit
  }

  const isAllLogSettingsSelected = () => {
    return (
      dataAccessState === 'checked' &&
      portScans &&
      authenticationFailures &&
      fullTableScans &&
      connectionEvents
    )
  }

  const handleSubmit = async () => {
    const logSettingsToSubmit = getLogSettingsToSubmit()
    try {
      await updateLogs({
        variables: {
          repoId,
          input: {
            logSettings: logSettingsToSubmit,
            redactLiteralValues,
            enhanceDatabaseLogs,
          },
        },
      })
    } catch (e) {}
  }

  useEffect(() => {
    setFieldsDirty(!fieldsSame())
  }, [
    redactLiteralValues,
    enhanceDatabaseLogs,
    dataAccessState,
    readsState,
    DMLState,
    DDLState,
    privilegedState,
    portScans,
    authenticationFailures,
    fullTableScans,
    connectionEvents,
    fieldsSame,
  ])

  return (
    <Box>
      <Stack sx={{ justifyContent: 'space-between' }}>
        <Stack spacing={4} sx={{ pb: 7 }}>
          <Box>
            <FormControlLabel
              control={
                <Switch
                  onChange={() => setRedactLiteralValues(!redactLiteralValues)}
                  checked={redactLiteralValues}
                />
              }
              label={
                <Typography variant="h4" sx={{ color: 'text.primary' }}>
                  Redact literal values in data activity logs.{' '}
                  <LearnMore docsPath="/manage-repositories/repo-advanced-settings#redact-literal-values" />
                </Typography>
              }
            />
          </Box>
          <Stack spacing={1}>
            <Typography sx={{ color: 'text.primary' }} variant="h4">
              Log Settings <LearnMore docsPath="/manage-repositories/repo-log-volume" />
            </Typography>
            <Stack>
              <Stack spacing={1}>
                <Box>
                  <FormControlLabel
                    label={'Log all data access'}
                    control={
                      <Checkbox
                        onChange={handleDataAccessCheck}
                        checked={dataAccessState === 'checked'}
                        indeterminate={dataAccessState === 'indeterminate'}
                      />
                    }
                  ></FormControlLabel>

                  <Box sx={{ pl: 3, borderLeft: '2.5px solid #CBD2D9', ml: 1 }}>
                    <Stack spacing={1}>
                      <Stack direction="row">
                        <FormControlLabel
                          label={'Log reads for'}
                          control={
                            <Checkbox
                              onChange={handleReadsCheck}
                              checked={readsState.checkbox === 'checked'}
                            />
                          }
                        ></FormControlLabel>
                        <span></span>
                        <Select
                          sx={{ mt: 0 }}
                          disabled={readsState.checkbox !== 'checked'}
                          labelId="read-select-label"
                          id="read-select"
                          options={SELECT_OPTIONS.map(option => {
                            return {
                              label: option.label,
                              value: option.value,
                            }
                          })}
                          value={readsState.logFor}
                          onChange={handleReadsSelect}
                        />
                      </Stack>
                      {repoType !== 's3' && (
                        <Stack direction="row">
                          <FormControlLabel
                            label={'Log DMLs for'}
                            control={
                              <Checkbox
                                onChange={handleDMLCheck}
                                checked={DMLState.checkbox === 'checked'}
                              />
                            }
                          ></FormControlLabel>
                          <span></span>
                          <Select
                            sx={{ mt: 0 }}
                            disabled={DMLState.checkbox !== 'checked'}
                            labelId="DML-select-label"
                            id="DML-select"
                            options={SELECT_OPTIONS.map(option => {
                              return {
                                label: option.label,
                                value: option.value,
                              }
                            })}
                            value={DMLState.logFor}
                            onChange={handleDMLSelect}
                          />
                        </Stack>
                      )}

                      <Stack direction="row">
                        <FormControlLabel
                          label={'Log DDLs for'}
                          control={
                            <Checkbox
                              onChange={handleDDLCheck}
                              checked={DDLState.checkbox === 'checked'}
                            />
                          }
                        ></FormControlLabel>
                        <span></span>
                        <Select
                          sx={{ mt: 0 }}
                          disabled={DDLState.checkbox !== 'checked'}
                          labelId="DDL-select-label"
                          id="DDL-select"
                          options={SELECT_OPTIONS.map(option => {
                            return {
                              label: option.label,
                              value: option.value,
                            }
                          })}
                          value={DDLState.logFor}
                          onChange={handleDDLSelect}
                        />
                      </Stack>
                      <Box>
                        <FormControlLabel
                          label={'Log all privileged commands'}
                          control={
                            <Checkbox onChange={handlePrivilegedCheck} checked={privilegedState} />
                          }
                        ></FormControlLabel>
                      </Box>
                    </Stack>
                  </Box>

                  <Stack spacing={1}>
                    <Box>
                      <FormControlLabel
                        label={'Log port scans'}
                        control={
                          <Checkbox onChange={() => setPortScans(!portScans)} checked={portScans} />
                        }
                      ></FormControlLabel>
                    </Box>
                    <Box>
                      <FormControlLabel
                        label={'Log authentication failures'}
                        control={
                          <Checkbox
                            onChange={() => setAuthenticationFailures(!authenticationFailures)}
                            checked={authenticationFailures}
                          />
                        }
                      ></FormControlLabel>
                    </Box>
                    <Box>
                      <FormControlLabel
                        label={'Log full table scans'}
                        control={
                          <Checkbox
                            onChange={() => setFullTableScans(!fullTableScans)}
                            checked={fullTableScans}
                          />
                        }
                      ></FormControlLabel>
                    </Box>

                    <Box>
                      <FormControlLabel
                        label={'Log connection events'}
                        control={
                          <Checkbox
                            onChange={() => setConnectionEvents(!connectionEvents)}
                            checked={connectionEvents}
                          />
                        }
                      ></FormControlLabel>
                    </Box>
                  </Stack>
                </Box>
              </Stack>
            </Stack>
          </Stack>
          {(repoType === 'postgresql' || repoType === 'redshift' || repoType === 'denodo') && (
            <Box>
              <FormControlLabel
                control={
                  <Switch
                    onChange={() => setEnhanceDatabaseLogs(!enhanceDatabaseLogs)}
                    checked={enhanceDatabaseLogs}
                  />
                }
                label={
                  <Typography variant="h4" sx={{ color: 'text.primary' }}>
                    Enhance database logs.{' '}
                    <LearnMore docsPath="/manage-repositories/repo-advanced-settings#enhance-database-logs" />
                  </Typography>
                }
              />
            </Box>
          )}
        </Stack>
        <Stack direction="row" spacing={2.5} sx={{ justifyContent: 'flex-end' }}>
          <Button variant="text" onClick={handleCancel} disabled={!fieldsDirty}>
            Cancel
          </Button>
          <Button variant="contained" disabled={!fieldsDirty} onClick={handleSubmit}>
            Save
          </Button>
        </Stack>
      </Stack>
    </Box>
  )
}
