import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import { graphql } from '@jeeves/graphql'
import {
  useCreateLoggingIntegration,
  useUpdateLoggingIntegration,
} from '@jeeves/pages/Integrations/Services/Logging/components/NewIntegrationDialog/hooks'

const getDefaultValues = integration => ({
  name: integration?.name || '',
  config: integration?.config || '',
})

const schema = z.object({
  name: z.string().min(1, { message: 'Please enter a name.' }),
  config: z.string().min(1, { message: 'Please enter a config.' }),
})

export const useCustom_customLoggingIntegrationFragment = graphql(`
  fragment useCustom_customLoggingIntegration on CustomLoggingIntegration {
    config
  }
`)

export const useCustom = ({ integration, onClose, isEditing }) => {
  const methods = useForm({
    defaultValues: getDefaultValues(integration),
    resolver: zodResolver(schema),
  })

  const [createLoggingIntegration, { loading: loadingCreateLoggingIntegration }] =
    useCreateLoggingIntegration({
      onCompleted: () => {
        onClose()
      },
    })

  const [updateLoggingIntegration, { loading: loadingUpdateLoggingIntegration }] =
    useUpdateLoggingIntegration({
      onCompleted: () => {
        onClose()
      },
    })

  const createCustomIntegration = async ({ name, config }) => {
    await createLoggingIntegration({
      variables: {
        integration: {
          name,
          config: {
            custom: {
              config,
            },
          },
        },
      },
    })
  }

  const updateCustomIntegration = async ({ name, config }) => {
    await updateLoggingIntegration({
      variables: {
        updateLoggingIntegrationId: integration.id,
        input: {
          name,
          config: {
            custom: {
              config,
            },
          },
        },
      },
    })
  }

  const onSubmit = async formData => {
    if (isEditing) {
      await updateCustomIntegration(formData)
    } else {
      await createCustomIntegration(formData)
    }
  }

  return {
    isEditing,
    loading: loadingCreateLoggingIntegration || loadingUpdateLoggingIntegration,
    methods,
    onSubmit: methods.handleSubmit(onSubmit),
  }
}
