import { useState } from 'react'

import { FragmentType, graphql, useFragment } from '@jeeves/graphql'
import { instanceCanUpgrade } from '@jeeves/pages/WrapperDetail/components/Instances/helpers'
import { Button, Tooltip } from '@jeeves/new-components'

import { UpgradeInstanceModal } from '../../../../components'

const UpgradeInstance_SidecarFragment = graphql(`
  fragment UpgradeInstance_SidecarFragment on Sidecar {
    id
    version
    ...UpgradeInstanceModal_SidecarFragment
  }
`)

const UpgradeInstance_SidecarInstanceFragment = graphql(`
  fragment UpgradeInstance_SidecarInstanceFragment on SidecarInstance {
    id
    recyclable
    versionIsDynamic
    version
    isRecycling
  }
`)

interface Props {
  sidecarInstance: FragmentType<typeof UpgradeInstance_SidecarInstanceFragment>
  sidecar: FragmentType<typeof UpgradeInstance_SidecarFragment>
}

const UpgradeInstance = ({ sidecarInstance: sidecarInstanceProp, sidecar: sidecarProp }: Props) => {
  const [selectedInstanceToUpgrade, setSelectedInstanceToUpgrade] = useState('')

  const sidecarInstance = useFragment(UpgradeInstance_SidecarInstanceFragment, sidecarInstanceProp)
  const sidecar = useFragment(UpgradeInstance_SidecarFragment, sidecarProp)

  const { canUpgrade, reason } = instanceCanUpgrade({
    instance: sidecarInstance,
    sidecarVersion: sidecar.version,
  })

  const upgradeInstanceButton = (
    <Button
      color="secondary"
      variant="outlined"
      disabled={!canUpgrade}
      loading={sidecarInstance.isRecycling}
      onClick={() => {
        setSelectedInstanceToUpgrade(sidecarInstance.id)
      }}
    >
      Upgrade Instance
    </Button>
  )

  return (
    <>
      {!canUpgrade ? (
        <Tooltip title={reason} placement="bottom-end">
          <span>{upgradeInstanceButton}</span>
        </Tooltip>
      ) : (
        upgradeInstanceButton
      )}
      <UpgradeInstanceModal
        sidecar={sidecar}
        selectedInstanceToUpgrade={selectedInstanceToUpgrade}
        setSelectedInstanceToUpgrade={setSelectedInstanceToUpgrade}
      />
    </>
  )
}

export default UpgradeInstance
