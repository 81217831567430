import { Stack, Typography } from '@mui/material'
import HexagonIcon from '@mui/icons-material/Hexagon'

import { Button } from '@jeeves/new-components'

interface Props {
  setCreateSidecarModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const SidecarsEmptyState = ({ setCreateSidecarModalOpen }: Props) => {
  return (
    <Stack
      spacing={2}
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        py: '150px',
        border: 1,
        borderColor: 'cyralColors.grey.200',
        borderRadius: theme => theme.radii.base,
      }}
    >
      <HexagonIcon sx={{ rotate: '90deg', color: 'secondary.main', fontSize: '42px' }} />
      <Typography variant="h4" sx={{ color: 'text.primary' }}>
        You don’t have any sidecars.
      </Typography>
      <Button variant="contained" color="primary" onClick={() => setCreateSidecarModalOpen(true)}>
        Create Sidecar
      </Button>
    </Stack>
  )
}

export default SidecarsEmptyState
