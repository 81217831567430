import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import InputAdornment from '@mui/material/InputAdornment'
import { IconCopyButton, Input, InputGroup } from '@jeeves/new-components'

import { getDocsURL } from '@jeeves/utils/docsURL'
import { graphql } from '@jeeves/graphql'
import { useAuth } from '@jeeves/components/Auth'
import { ConnectionTypesTabs } from '../../../../components'
import ConnectionFields, {
  Host,
  Port,
  Username,
  Password,
  Database,
  INPUT_ADDON_WIDTH,
} from '../../ConnectionInfoFields'

import getConnectionTypes from './connectionTypes'
import { getUsername, getHost, getPorts } from '../../../../utils'

export const MongoAccordionContent_RepoFragment = graphql(`
  fragment MongoAccordionContent_RepoFragment on Repo {
    ... on MongoDBRepo {
      config {
        TLS {
          enableClientSidecarTLS
        }
        authentication {
          authenticationMethod {
            ... on IdentityProvider {
              id
            }
            ... on AwsIamIntegration {
              id
            }
          }
        }
      }
    }
  }
`)

const MongoAccordionContent = ({
  repo,
  accessPortalSidecar,
  accessPortalSidecarBinding,
  userAccountEdge,
}) => {
  const [database, setDatabase] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [accessToken, setAccessToken] = useState('')
  const { user } = useAuth()

  const connectionInfo = {
    username: getUsername(user, repo, userAccountEdge),
    host: getHost(accessPortalSidecar),
    ports: getPorts(accessPortalSidecarBinding),
    password: accessToken,
    database,
    ...(repo?.__typename === 'MongoDBReplicaSetRepo' && {
      replicaSetName: repo.replicaSetName,
    }),
    tls: repo.config.TLS.enableClientSidecarTLS,
  }

  const connectionTypes = getConnectionTypes(connectionInfo, {
    showPassword,
  })

  const isConfiguredWithAwsIamAuthentication =
    repo.config.authentication.authenticationMethod?.__typename === 'AwsIamIntegration'

  return (
    <Stack spacing={3}>
      <Box display="grid" gridTemplateColumns="repeat(2, 1fr)">
        <Stack spacing={3}>
          <Stack spacing={0.5}>
            <Typography
              variant="h6"
              sx={{
                color: 'text.secondary',
              }}
            >
              Connection Info
            </Typography>

            <ConnectionFields connectionInfo={connectionInfo}>
              {!isConfiguredWithAwsIamAuthentication && (
                <>
                  <Username />
                  <Password
                    showPassword={showPassword}
                    setShowPassword={setShowPassword}
                    setAccessToken={setAccessToken}
                  />
                </>
              )}
              <Host />
              <Port />
              {!isConfiguredWithAwsIamAuthentication && <Database setDatabase={setDatabase} />}
            </ConnectionFields>
          </Stack>

          {connectionInfo.replicaSetName && (
            <Stack spacing={0.5}>
              <Typography
                variant="h6"
                sx={{
                  color: 'text.secondary',
                }}
              >
                Replica Set Info
              </Typography>

              <Stack spacing={0.5}>
                <ReplicaSetName name={connectionInfo.replicaSetName} />
              </Stack>
            </Stack>
          )}
        </Stack>
      </Box>

      {!isConfiguredWithAwsIamAuthentication && (
        <ConnectionTypesTabs connectionTypes={connectionTypes} />
      )}

      {isConfiguredWithAwsIamAuthentication && (
        <Typography variant="body2" sx={{ color: 'text.primary' }}>
          This repository is configured with AWS IAM authentication. For more information about how
          to connect to this repository,{' '}
          <Link
            href={getDocsURL({ docsPath: 'connect/connect-mongodb' })}
            underline="none"
            target="_blank"
            variant="h6"
            sx={{ color: 'primary.main' }}
          >
            visit our docs
          </Link>
          .
        </Typography>
      )}
    </Stack>
  )
}

const ReplicaSetName = ({ name }) => {
  return (
    <InputGroup>
      <InputGroup.Text
        sx={{
          minWidth: INPUT_ADDON_WIDTH,
        }}
      >
        Name
      </InputGroup.Text>
      <Input
        defaultValue={name}
        endAdornment={
          <InputAdornment position="end" sx={{ marginLeft: 0 }}>
            <IconCopyButton text={name} />
          </InputAdornment>
        }
        sx={{
          typography: 'code',
        }}
        fullWidth
        readOnly
      />
    </InputGroup>
  )
}

export default MongoAccordionContent
