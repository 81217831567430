import React from 'react'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import MUIInputLabel from '@mui/material/InputLabel'
import MUIInput from '@jeeves/new-components/Input'
import MUIDialogTitle from '@mui/material/DialogTitle'
import MUIDialogActions from '@mui/material/DialogActions'
import MUIDialogContent from '@mui/material/DialogContent'
import Divider from '@mui/material/Divider'

export const SuggestionsField = styled(
  props => <Typography variant="h5" {...props} />,
  {}
)(({ theme }) => ({
  color: theme.palette.cyralColors.grey[400],
}))

export const SuggestionsValue = styled(
  props => <Typography variant="data" {...props} />,
  {}
)(({ theme }) => ({
  color: theme.palette.cyralColors.grey[400],
}))

export const InputLabel = styled(
  props => <MUIInputLabel {...props} />,
  {}
)(({ theme }) => ({
  color: theme.palette.cyralColors.grey[400],
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '20px',
}))

export const Input = styled(
  props => <MUIInput {...props} />,
  {}
)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(0.25),
  },
  marginBottom: theme.spacing(2),
}))

export const DialogTitle = styled(
  props => <MUIDialogTitle {...props} />,
  {}
)(({ theme }) => ({
  ...theme.typography.h3,
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(2),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
}))

export const DialogActions = styled(
  props => <MUIDialogActions {...props} />,
  {}
)(({ theme }) => ({
  ...theme.typography.h3,
  marginTop: theme.spacing(3.5),
  marginBottom: theme.spacing(3),

  marginRight: theme.spacing(4),
}))

export const DialogContent = styled(
  props => <MUIDialogContent {...props} />,
  {}
)(({ theme }) => ({
  padding: theme.spacing(4),
  paddingBottom: theme.spacing(8),
}))

export const LearnMore = styled(
  props => <Typography variant="body2" {...props} />,
  {}
)(({ theme }) => ({
  color: theme.palette.cyralColors.grey[400],
  marginBottom: theme.spacing(3),
}))

export const ActionsDivider = styled(
  props => <Divider {...props} />,
  {}
)(({ theme }) => ({
  margin: '0px auto',
  width: '90%',
}))
