import { Button } from '@jeeves/new-components'

interface Props {
  filename: string
  certificate: string
}

const REVOKE_OBJECT_URL_DELAY = 30000

const DownloadCertificate = ({ filename, certificate }: Props) => {
  const downloadCertificate = () => {
    const blob = new Blob([certificate], { type: 'application/x-pem-file' })

    const downloadLink = document.createElement('a')
    downloadLink.href = URL.createObjectURL(blob)
    downloadLink.download = `${filename}.pem`
    downloadLink.addEventListener('click', () => {
      setTimeout(() => URL.revokeObjectURL(downloadLink.href), REVOKE_OBJECT_URL_DELAY)
    })
    downloadLink.click()
  }

  return (
    <Button variant="outlined" color="secondary" onClick={downloadCertificate}>
      Download Certificate
    </Button>
  )
}

export default DownloadCertificate
