import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'

import { useAuth } from '@jeeves/components/Auth'
import { ConnectionTypesTabs } from '../../../../components'
import ConnectionFields, { Host, Port, Username, Password } from '../../ConnectionInfoFields'

import getConnectionTypes from './connectionTypes'
import { getUsername, getHost, getPorts } from '../../../../utils'

const MySqlAccordionContent = ({
  repo,
  accessPortalSidecar,
  accessPortalSidecarBinding,
  userAccountEdge,
}) => {
  const [showPassword, setShowPassword] = useState(false)
  const [accessToken, setAccessToken] = useState('')
  // TODO: We need to make sure currentConnectionTypesTab has proper initial value
  const [currentConnectionTypesTab, setCurrentConnectionTypesTab] = useState()
  const [selectedJdbcDriverVersion, setSelectedJdbcDriverVersion] = useState(8)
  const { user } = useAuth()

  const connectionInfo = {
    username: getUsername(user, repo, userAccountEdge, accessPortalSidecarBinding),
    host: getHost(accessPortalSidecar),
    ports: getPorts(accessPortalSidecarBinding),
    password: accessToken,
  }

  const connectionTypes = getConnectionTypes(connectionInfo, {
    showPassword,
    jdbcDriverVersion: selectedJdbcDriverVersion,
  })

  const onConnectionTypesTabChange = newTab => {
    setCurrentConnectionTypesTab(newTab)
  }

  const toggleJdbcDriverVersion = () => {
    // The driver version can either be version "5" or version "8"
    // More info here: https://cyralinc.atlassian.net/wiki/spaces/EN/pages/1718780051/Federated+Authentication+for+MySQL+using+DataGrip+IntelliJ+IDEA#JDBC-Connection-URL-Details
    setSelectedJdbcDriverVersion(currVersion => {
      return currVersion === 8 ? 5 : 8
    })
  }

  return (
    <Stack spacing={3}>
      <Box display="grid" gridTemplateColumns="repeat(2, 1fr)">
        <Stack spacing={0.5}>
          <Typography
            variant="h6"
            sx={{
              color: 'text.secondary',
            }}
          >
            Connection Info
          </Typography>

          <ConnectionFields connectionInfo={connectionInfo}>
            <Username />
            <Password
              showPassword={showPassword}
              setShowPassword={setShowPassword}
              setAccessToken={setAccessToken}
            />
            <Host />
            <Port />
          </ConnectionFields>
        </Stack>
      </Box>

      <Stack spacing={1}>
        <ConnectionTypesTabs
          connectionTypes={connectionTypes}
          onTabChange={onConnectionTypesTabChange}
        />
        {currentConnectionTypesTab === 'jdbc' && (
          <Link
            component="button"
            underline="hover"
            variant="body2"
            onClick={toggleJdbcDriverVersion}
            sx={{
              alignSelf: 'flex-start',
            }}
          >
            {selectedJdbcDriverVersion === 8
              ? 'This connection URL is intended for use with the MySQL 8 JDBC connection driver, please click here if you’re using the MySQL 5 JDBC connection driver.'
              : 'This connection URL is intended for use with the MySQL 5 JDBC connection driver, please click here if you’re using the MySQL 8 JDBC connection driver.'}
          </Link>
        )}
      </Stack>
    </Stack>
  )
}

export default MySqlAccordionContent
