/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import React, { useState, useContext } from 'react'
import { DialogActions, DialogContent, Divider, withStyles } from '@material-ui/core'

import { ButtonLoading } from '@jeeves/components/Loading'
import { Button, Dialog, FlexGrow } from '@jeeves/components/Primitives'
import { useAuth } from '@jeeves/hooks'

import PermissionsForm from '../EditGroup/components/PermissionsForm'
import MappingsForm from '../EditGroup/components/MappingsForm'
import AddRoleForm from './AddRoleForm'
import useUsers from '../../hooks/useUsers'
import { UsersContext } from '../../contexts/UsersContext'

const styles = theme => ({
  content: {
    marginBottom: theme.spacing.unit * 4,
  },
  divider: {
    marginTop: '15px',
    marginBottom: '15px',
  },
  DialogActions: {
    paddingBottom: theme.spacing.unit * 3,
    paddingLeft: '45px',
    paddingRight: '30px',
    paddingTop: 0,
  },
})

const CreateGroup = ({ classes }) => {
  const [open, setOpen] = useState(false)
  const onClose = () => setOpen(false)
  const onOpen = () => setOpen(true)

  const [groupNameInput, setGroupNameInput] = useState('')
  const [mappingsNameInput, setMappingsNameInput] = useState('')
  const [mappingsConnectionInput, setMappingsConnectionInput] = useState('')
  const [checkedPermissions, setCheckedPermissions] = useState([])
  const [addingMapping, setAddingMapping] = useState(false)
  const [mappingList, setMappingList] = useState([])

  const { hasPermission } = useAuth()
  const [state, setState] = useContext(UsersContext) // eslint-disable-line

  const [saving, setSaving] = useState(false)

  const { postUserGroup, refreshGroups, refreshRoles } = useUsers()

  const handleOnToggle = value => {
    const currentIndex = checkedPermissions.indexOf(value)
    const newCheckedPermissions = [...checkedPermissions]
    if (currentIndex === -1) {
      newCheckedPermissions.push(value)
    } else {
      newCheckedPermissions.splice(currentIndex, 1)
    }
    setCheckedPermissions(newCheckedPermissions)
  }

  const setInitialChecked = () => {
    setCheckedPermissions([])
  }

  const clearGroupNameInput = () => {
    setGroupNameInput('')
  }

  const clearMappingsInput = () => {
    setMappingsNameInput('')
    setMappingsConnectionInput('')
  }

  const handleOnAddMapping = async () => {
    const newMappingList = mappingList.concat({
      groupName: mappingsNameInput,
      connectionName: mappingsConnectionInput,
    })
    setMappingList(newMappingList)
    clearMappingsInput()
  }

  const handleOnDeleteMapping = async mappingName => {
    const newMappingList = mappingList.filter(item => item.groupName !== mappingName)
    setMappingList(newMappingList)
  }

  const handleOnSave = async () => {
    try {
      setSaving(true)
      await postUserGroup(groupNameInput, checkedPermissions, mappingList)
      await refreshGroups()
      await refreshRoles()
      handleOnCancel()
    } catch (e) {
      // console.log(e.response)
    } finally {
      setSaving(false)
    }
  }

  const handleOnCancel = async () => {
    onClose()
    setInitialChecked()
    clearMappingsInput()
    clearGroupNameInput()
    setMappingList([])
  }

  return (
    <React.Fragment>
      <Button
        variant="outlined"
        color="primary"
        onClick={onOpen}
        style={{ marginRight: '16px' }}
        disabled={!hasPermission('roles:create')}
      >
        Add new role
      </Button>

      <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
        <DialogContent className={classes.content}>
          <AddRoleForm groupNameInput={groupNameInput} setGroupNameInput={setGroupNameInput} />

          <Divider variant="middle" className={classes.divider} />

          <PermissionsForm
            handleOnToggle={handleOnToggle}
            permissions={state.roles || []}
            checked={checkedPermissions || []}
            setInitialChecked={setInitialChecked}
          />
          <Divider variant="middle" className={classes.divider} />

          <MappingsForm
            connectionNames={state.connectionNames}
            mappings={mappingList}
            setMappingsNameInput={setMappingsNameInput}
            setMappingsConnectionInput={setMappingsConnectionInput}
            mappingsNameInput={mappingsNameInput}
            mappingsConnectionInput={mappingsConnectionInput}
            handleOnDeleteMapping={handleOnDeleteMapping}
            handleOnAddMapping={handleOnAddMapping}
            handleOnCancelMappingInput={clearMappingsInput}
            adding={addingMapping}
            setAdding={mapping => setAddingMapping(mapping)}
          />
        </DialogContent>

        <DialogActions className={classes.DialogActions}>
          <FlexGrow />

          <Button color="primary" onClick={handleOnCancel}>
            Cancel
          </Button>
          <div css={{ position: 'relative' }}>
            <Button
              color="primary"
              variant="contained"
              onClick={handleOnSave}
              disabled={addingMapping || saving}
            >
              Save
            </Button>
            {saving && <ButtonLoading />}
          </div>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default withStyles(styles)(CreateGroup)
