import * as React from 'react'
import Box from '@mui/material/Box'

import { useDateSegment, useLocale, useTimeField } from 'react-aria'
import { useTimeFieldState } from 'react-stately'

const TimeField = props => {
  let { locale } = useLocale()
  let state = useTimeFieldState({
    ...props,
    locale,
  })

  let ref = React.useRef()
  let { labelProps, fieldProps, descriptionProps, errorMessageProps } = useTimeField(
    props,
    state,
    ref
  )

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}
    >
      {/* <span {...labelProps}>{props.label}</span> */}
      <Box
        {...fieldProps}
        ref={ref}
        sx={{
          display: 'inline-flex',
          padding: '7px 12px',

          borderRadius: theme => theme.radii.base,
          border: 1,
          borderColor: 'cyralColors.grey.200',
        }}
      >
        {state.segments.map((segment, i) => (
          <DateSegment key={i} segment={segment} state={state} />
        ))}

        {state.validationState === 'invalid' && <span aria-hidden="true">🚫</span>}
      </Box>
    </Box>
  )
}

const DateSegment = ({ segment, state }) => {
  let ref = React.useRef()
  let { segmentProps } = useDateSegment(segment, state, ref)

  return (
    <Box
      {...segmentProps}
      ref={ref}
      sx={{
        px: 0.25,
        textAlign: 'right',
        fontVariantNumeric: 'tabular-nums',
        minWidth: 'maxValue' in segment && `${String(segment.maxValue).length}ch`,
        outline: 'none',
        borderRadius: theme => theme.radii.sm,
        typography: 'body2',

        '&:focus': {
          bgcolor: 'primary.main',
          color: 'white',

          '& > :first-child': {
            color: 'white',
          },
        },
      }}
    >
      {/* Always reserve space for the placeholder, to prevent layout shift when editing. */}
      <Box
        component="span"
        aria-hidden="true"
        sx={{
          display: 'block',
          width: '100%',
          textAlign: 'center',

          fontStyle: 'italic',
          color: 'text.secondary',

          visibility: segment.isPlaceholder ? '' : 'hidden',
          height: segment.isPlaceholder ? '' : 0,
          pointerEvents: 'none',
        }}
      >
        {segment.placeholder}
      </Box>

      {segment.isPlaceholder ? '' : segment.text}
    </Box>
  )
}

export default TimeField
