import React from 'react'
import styled from '@emotion/styled'
import MuiTableCell from '@material-ui/core/TableCell'

/**
 * This is a way to avoid passing preserveHeight, textAlign and numeric props
 * to the dom element.
 */
export const TableCell = styled(({ preserveHeight, textAlign, numeric, ...rest }) => (
  <MuiTableCell {...rest} />
))`
  height: ${({ preserveHeight }) => (preserveHeight ? 'inherit' : '100px')};
  text-align: ${({ textAlign = 'left' }) => textAlign};
  font-size: ${({ numeric }) => (numeric ? '17px' : 'inherit')};
`
