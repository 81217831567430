import { ArrowCircleUpRounded, Autorenew } from '@mui/icons-material'
import { Button } from '@jeeves/new-components'
import { FragmentType, graphql, useFragment } from '@jeeves/graphql'

const UpgradeInstanceButton_SidecarInstanceFragment = graphql(`
  fragment UpgradeInstanceButton_SidecarInstanceFragment on SidecarInstance {
    isRecycling
  }
`)

interface Props {
  disabled: boolean
  onClick: () => void
  sidecarInstance: FragmentType<typeof UpgradeInstanceButton_SidecarInstanceFragment>
}

const UpgradeInstanceButton = ({
  disabled,
  onClick,
  sidecarInstance: sidecarInstanceProp,
}: Props) => {
  const instance = useFragment(UpgradeInstanceButton_SidecarInstanceFragment, sidecarInstanceProp)

  return (
    <Button
      disabled={disabled}
      variant="outlined"
      color="secondary"
      sx={{ minWidth: 0, padding: '7px !important' }}
      onClick={onClick}
    >
      {instance.isRecycling ? (
        <Autorenew
          fontSize="small"
          sx={{
            animation: 'spin-ccw 2s linear infinite',
            '@keyframes spin-ccw': {
              '0%': {
                transform: 'rotate(0deg)',
              },
              '100%': {
                transform: 'rotate(360deg)',
              },
            },
          }}
        />
      ) : (
        <ArrowCircleUpRounded fontSize="small" />
      )}
    </Button>
  )
}

export default UpgradeInstanceButton
