import * as React from 'react'
// import Button from '@mui/material/Button'
import Button from '@jeeves/new-components/Button'
import Dialog from '@mui/material/Dialog'

import Stack from '@mui/material/Stack'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

import { useForm, Controller } from 'react-hook-form'

import {
  InputLabel,
  Input,
  DialogTitle,
  DialogActions,
  DialogContent,
  ActionsDivider,
} from '@jeeves/pages/DataLabels/Custom/Dialogs/Dialogs.styles.js'

import { LearnMore } from '../components/LearnMore'
import { gql, useMutation } from '@apollo/client'
import { FETCH_MAPPINGS } from '../../Mappings'
import { getAttributeInput } from '../../utils'

const CREATE_MAPPING = gql`
  mutation UpsertDatamapMapping(
    $repoId: String!
    $datalabelName: String!
    $attribute: UpsertDatamapMappingAttributeInput!
  ) {
    upsertDatamapMapping(repoId: $repoId, datalabelName: $datalabelName, attribute: $attribute) {
      repo {
        id
      }
    }
  }
`

export const getRepoFieldConstants = repoType => {
  switch (repoType) {
    case 'postgresql':
    case 'redshift':
    case 'mysql':
    case 'oracle':
      return RepoFieldConstants.sql
    case 'mongodb':
      return RepoFieldConstants.mongo
    case 's3':
      return RepoFieldConstants.s3
    case 'dynamodb':
    case 'dynamodbstreams':
      return RepoFieldConstants.dynamodb
    default:
      return RepoFieldConstants.sql
  }
}

const RepoFieldConstants = {
  mongo: {
    inputName: ['database', 'collection', 'field'],
    inputLabel: ['Database', 'Collection', 'Field'],
  },
  sql: {
    inputName: ['schema', 'table', 'field'],
    inputLabel: ['Schema', 'Table', 'Field'],
  },
  s3: {
    inputName: ['bucket', 'key'],
    inputLabel: ['Bucket', 'Key'],
  },
  dynamodb: {
    inputName: ['table', 'field'],
    inputLabel: ['Table', 'Field'],
  },
}

export const AddMapping = ({ datalabels, repoId, repoType }) => {
  const [open, setOpen] = React.useState(false)

  const {
    register,
    watch,
    getValues,
    reset,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm({ defaultValues: { dataLabels: '', location0: '', location1: '', location2: '' } })

  const [createMapping, { data, loading, error }] = useMutation(CREATE_MAPPING, {
    refetchQueries: [FETCH_MAPPINGS],
  })

  const { dataLabelRef, ...dataLabelRest } = register('datalabel')

  const repoFieldConstants = getRepoFieldConstants(repoType)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleOnClose = React.useCallback(() => {
    setOpen(false)
    reset()
  }, [reset])

  React.useEffect(() => {
    if (isSubmitSuccessful) {
      handleOnClose()
    }
  }, [isSubmitSuccessful, reset, handleOnClose])

  const onSubmit = async () => {
    const { datalabel, location0, location1, location2 } = getValues()

    try {
      await createMapping({
        variables: {
          repoId,
          datalabelName: datalabel,
          attribute: getAttributeInput([location0, location1, location2], repoType),
        },
      })
      handleOnClose()
    } catch (e) {
      console.error(e)
    }
  }

  const datalabelWatch = watch('datalabel')
  const location0Watch = watch('location0')
  const location1Watch = watch('location1')
  const location2Watch = watch('location2')

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Add Mapping
      </Button>

      <Dialog open={open} onClose={handleOnClose} fullWidth sx={{ radius: '12px' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>Add Mapping</DialogTitle>

          <DialogContent>
            <LearnMore />
            <Stack sx={{ mt: 0.5 }}>
              <InputLabel>Data Label</InputLabel>
              <Select
                fullWidth
                inputRef={dataLabelRef}
                {...dataLabelRest}
                name="datalabel"
                input={<Input />}
              >
                {datalabels.map(datalabel => (
                  <MenuItem value={datalabel.name}>{datalabel.name}</MenuItem>
                ))}
              </Select>

              <InputLabel>{repoFieldConstants.inputLabel[0]}</InputLabel>
              <Input
                fullWidth
                inputProps={{
                  ...register('location0', {
                    required: true,
                  }),
                }}
              ></Input>

              <InputLabel>{repoFieldConstants.inputLabel[1]}</InputLabel>
              <Input
                name="location1"
                fullWidth
                inputProps={{
                  ...register('location1', {
                    required: repoType === 's3' ? false : true,
                  }),
                }}
              ></Input>

              {repoType !== 's3' && repoType !== 'dynamodb' && repoType !== 'dynamodbstreams' && (
                <React.Fragment>
                  <InputLabel>{repoFieldConstants.inputLabel[2]}</InputLabel>
                  <Input
                    fullWidth
                    inputProps={{
                      ...register('location2', {
                        required: true,
                      }),
                    }}
                  ></Input>
                </React.Fragment>
              )}
            </Stack>
          </DialogContent>
          <ActionsDivider />
          <DialogActions>
            <Button onClick={handleOnClose} variant="text">
              Cancel
            </Button>

            {/* <Button variant="contained" color="primary" type="submit" disabled={isSubmitting}> */}
            <Button
              variant="contained"
              color="primary"
              disabled={
                !datalabelWatch ||
                !location0Watch ||
                (repoType !== 's3' && !location1Watch) ||
                (repoType !== 's3' &&
                  repoType !== 'dynamodb' &&
                  repoType !== 'dynamodbstreams' &&
                  !location2Watch)
              }
              onClick={onSubmit}
            >
              Create
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  )
}
