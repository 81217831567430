/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import styled from '@emotion/styled'
import React from 'react'
import { TextField, Tooltip } from '@material-ui/core/'
import { useController } from 'react-hook-form'
import { isEmpty } from 'lodash'

// This component should replace "TextFieldWithToolTip" component. That component still
// exists because there wasn't enough time to refactor the "Edit" component to use
// "TextFieldWithToolTipController".
const TextFieldWithToolTipController = ({ tooltipText = '', name, control, ...props }) => {
  const {
    field: { ref, ...inputProps },
    fieldState: { invalid, isTouched, isDirty, error },
    formState: { touchedFields, dirtyFields },
  } = useController({
    name,
    control,
    rules: { required: true },
    defaultValue: '',
  })

  let errorText = ''

  if (error && error.type) {
    switch (error.type) {
      case 'required':
        errorText = 'This field is required.'
        break
      default:
        break
    }
  }

  return (
    <Tooltip title={tooltipText} placement="left">
      <TextField
        error={!isEmpty(error)}
        helperText={errorText}
        css={{ marginBottom: '15px' }}
        variant="outlined"
        fullWidth={true}
        {...props}
        inputRef={ref}
        {...inputProps}
      />
    </Tooltip>
  )
}

export default TextFieldWithToolTipController
