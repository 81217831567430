import React from 'react'
import axios from 'axios'
import { withStyles } from '@material-ui/core/styles'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'

import { useAuth } from '../../../components/Auth'

const styles = theme => ({
  main: {
    padding: theme.spacing.unit * 4,
  },
  header: {
    borderBottomColor: theme.palette.background.secondary,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
  },
  actions: {
    borderTopColor: theme.palette.background.secondary,
    borderTopWidth: 1,
    borderTopStyle: 'solid',
  },
})

const Agreement = ({ classes, history }) => {
  const { getTokenSilently } = useAuth()

  const acceptAgreement = async () => {
    try {
      const token = await getTokenSilently()

      // TODO: REPLACE CLIENT ID
      await axios.request({
        method: 'patch',
        url: `/api/clients/${window._env_.client_id}`,
        data: {
          client_metadata: {
            agreement: '1',
          },
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      return (window.location = '/app')
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Grid container justify="center" className={classes.main}>
      <Grid item xs={12} md={8} lg={6}>
        <Card>
          <CardHeader className={classes.header} title="CYRAL SERVICE TERMS" />
          <CardContent className={classes.content}>
            <Typography>
              <strong>SCOPE OF SERVICE AND RESTRICTIONS</strong>
            </Typography>

            <Typography>
              <strong>Access to and Scope of Service. </strong>
              Object to Cyral’s receipt of the applicable Fees with respect to the service(s)
              specified in the corresponding Order Form (the “Service”), Cyral will use commercially
              reasonable efforts to make the Service available to Customer as set forth in this
              Agreement and the Order Form. Subject to Customer’s compliance with the terms and
              conditions of the Agreement and the Order Form, Customer may access and use the
              Service during the period specified in the Order Form and solely in accordance with
              the Entitlement. Any such use of the Service by Customer is authorized solely for
              Customer’s internal business and is subject to Customer’s compliance with the
              Entitlement and any additional limitations and restrictions specified in the Order
              Form.
            </Typography>
          </CardContent>

          <CardActions className={classes.actions}>
            <Grid container direction="row" justify="center" alignItems="center" spacing={16}>
              <Grid item xs={12}>
                <Typography align="center" variant="caption">
                  <em>To continue using service you must agree to this terms and conditions</em>
                </Typography>
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" type="submit" onClick={acceptAgreement}>
                  AGREE
                </Button>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(Agreement)
