import React from 'react'
import { styled } from '@mui/material/styles'
import Stack from '@mui/material/Stack'
import MuiDivider from '@mui/material/Divider'
import MuiTabs from '@mui/material/Tabs'
import MuiTab from '@mui/material/Tab'

const StyledCodeTabsContainer = styled(
  props => (
    <Stack
      {...props}
      divider={
        <MuiDivider
          sx={{
            borderBottomColor: 'cyralColors.grey.200',
          }}
        />
      }
    />
  ),
  {}
)(({ theme }) => ({
  overflow: 'hidden',
  backgroundColor: theme.palette.common.white,
  border: `1px solid ${theme.palette.cyralColors.grey[200]}`,
  borderRadius: theme.radii.base,
  flexShrink: 0,
}))

const Body = styled('div')(({ theme }) => ({
  flex: '1',
  display: 'flex',
  // justifyContent: 'space-between',
  // padding: theme.spacing(4, 5),
}))

const StyledTabs = styled(MuiTabs)({
  minHeight: '36px',

  '& .MuiTabs-indicator': {
    // backgroundColor: '#1890ff',
    display: 'none',
  },
})

const StyledTab = styled(props => <MuiTab disableRipple {...props} />)(({ theme }) => ({
  ...theme.typography.h6,
  padding: theme.spacing(0, 1),
  minHeight: '36px',
  minWidth: 0,
  //   color: 'rgba(255, 255, 255, 0.7)',
  //   '&.Mui-focusVisible': {
  //     backgroundColor: 'rgba(100, 95, 228, 0.32)',
  //   },
}))

// StyledCodeTabsContainer.Body = Body

export { StyledCodeTabsContainer, StyledTab, StyledTabs }
