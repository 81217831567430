import * as React from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'

import { formatLocalizedTimestamp } from '@jeeves/utils/datetime'

const getDescriptiveActionPhrase = action => {
  switch (action) {
    case 'REQUEST':
      return 'requested access'
    case 'AMEND':
      return 'requested to modify their approval'
    case 'GRANT':
      return 'granted this request'
    case 'REVOKE':
      return 'revoked access'
    case 'REJECT':
      return 'rejected this request'
    default:
      break
  }
}

const HistoryItem = ({ historyItem }) => {
  const action = historyItem.action
  const actor = historyItem?.actor?.name
  const comment = historyItem.comment
  const timestamp = historyItem.timestamp

  return (
    <Stack spacing={2}>
      <DescriptiveApprovalAction actor={actor} action={action} timestamp={timestamp} />

      {comment && (
        <Typography component="q" variant="body2" sx={{ color: 'text.secondary' }}>
          {comment}
        </Typography>
      )}
    </Stack>
  )
}

const DescriptiveApprovalAction = ({ actor, action, timestamp }) => {
  const descriptiveActionPhrase = getDescriptiveActionPhrase(action)
  const formattedTimeStamp = formatLocalizedTimestamp(timestamp, { includeTime: true })

  if (!actor) {
    return (
      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        <Typography component="strong" variant="h6" sx={{ color: 'text.primary' }}>
          Auto-approved
        </Typography>{' '}
        request on {formattedTimeStamp}.
      </Typography>
    )
  }

  return (
    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
      <Typography component="strong" variant="h6" sx={{ color: 'text.primary' }}>
        {actor}
      </Typography>{' '}
      {descriptiveActionPhrase} on {formattedTimeStamp}.
    </Typography>
  )
}

export default HistoryItem
