import * as React from 'react'
import { styled } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'

export const TableContainer = styled('div', {
  name: 'RulesTable',
  slot: 'Root',
})(({ theme }) => ({
  table: {
    borderCollapse: 'separate',
    borderSpacing: 0,
    width: '100%',
  },

  'td, th': {
    textAlign: 'left',
    padding: theme.spacing(1, 2),
  },
  th: {
    ...theme.typography.h5,
    color: theme.palette.text.secondary,

    backgroundColor: theme.palette.cyralColors.grey[100],

    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderTopStyle: 'solid',
    borderBottomStyle: 'solid',
    borderColor: theme.palette.cyralColors.grey[200],
  },
}))

export const RowActionButton = styled(
  React.forwardRef((props, ref) => (
    <IconButton ref={ref} {...props} disableRipple disableFocusRipple />
  )),
  {}
)(({ theme }) => ({
  borderRadius: theme.radii.sm,
  padding: 0,

  '&:hover': {
    backgroundColor: theme.palette.cyralColors.grey[100],
  },
}))
