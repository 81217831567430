/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import React, { useState, useEffect } from 'react'
import { usePopup } from '@jeeves/hooks'
import ExpressClient from '@jeeves/clients/express'
import { useAuth } from '@jeeves/components/Auth'
import { ServiceIcon } from '@jeeves/components/ServiceIcon'
import { TextField, MenuItem, Typography } from '@material-ui/core'

export const IDPMenuItemContent = ({ connection, standalone, typographyProps }) => {
  const connectionName = connection.displayName || connection.name

  return (
    <div css={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <ServiceIcon type={connection.ssoProviderType || connection.type} />
      {standalone ? (
        <Typography {...typographyProps}>{connectionName}</Typography>
      ) : (
        <div>{connectionName}</div>
      )}
    </div>
  )
}

const IDPSelect = ({
  setIDP,
  includeNative,
  idp,
  label,
  connections: argsConnections,
  ...props
}) => {
  const { getTokenSilently } = useAuth()
  const { setPopup, popupTypes } = usePopup()
  const [connections, setConnections] = useState([])

  // TO DO: create and draw this from connections context

  const fetchConnections = async () => {
    try {
      const ec = new ExpressClient(getTokenSilently)

      const { data } = await ec.get('/users/connections/names')
      setConnections([...data, ...(includeNative ? [{ name: 'Native', displayName: 'None' }] : [])])
    } catch (e) {
      setPopup(popupTypes.ERROR, 'Failed to get connections')
    }
  }

  useEffect(() => {
    if (argsConnections) {
      setConnections(argsConnections)
    } else {
      fetchConnections()
    }
  }, [argsConnections])

  return (
    <TextField
      fullWidth
      label={label}
      id="auth-method"
      select
      variant="outlined"
      margin="dense"
      value={idp || 'None'}
      onChange={e => {
        if (setIDP) {
          setIDP(e.target.value)
        }
      }}
      css={{ width: '300px' }}
      {...props}
    >
      {connections.map(connection => (
        <MenuItem key={connection.name} value={connection.name}>
          <IDPMenuItemContent connection={connection} includeNative={includeNative} />
        </MenuItem>
      ))}
    </TextField>
  )
}

export default IDPSelect
