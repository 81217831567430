import * as React from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import PeopleIcon from '@mui/icons-material/People'

import { Button, LearnMore } from '@jeeves/new-components'
import { useAppsAndBiToolsDispatch } from '../contexts/AppsAndBiToolsContext'

const EmptyState = () => {
  const dispatch = useAppsAndBiToolsDispatch()

  return (
    <Box>
      <Stack spacing={1} sx={{ alignItems: 'center' }}>
        <PeopleIcon sx={{ fontSize: 56, color: 'cyralColors.grey.300' }} />

        <Stack spacing={2} sx={{ alignItems: 'center' }}>
          <Box>
            <Typography variant="h4" sx={{ color: 'text.primary' }}>
              Identify users of Apps and BI Tools hidden behind service accounts.{' '}
              <LearnMore
                docsPath="how-to/identify-users-behind-apps/#what-is-service-account-resolution"
                variant="h4"
              />
            </Typography>
          </Box>
          <Button onClick={() => dispatch({ type: 'registerNewAccount' })}>
            Register Database Account
          </Button>
        </Stack>
      </Stack>
    </Box>
  )
}

export default EmptyState
